import React from "react";
import Loader from "react-loader-spinner";
import { connect } from 'react-redux';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown
} from "reactstrap";
import DottedVerticalSvg from "../../../components/svgComponents/DottedVerticalSvg";
import MessageCircle from "../../../components/svgComponents/MessageCircle";
import CaretDown from "../../SVGs/CaretDown";
import TribesPermission from "../TribesPermission/TribesPermission";
import "./TribesCard.scss";

function TribesCard({
  avatar,
  name,
  email,
  description,
  isOwner,
  isInvited,
  members,
  onClickComment,
  permissions,
  userPermissions,
  tribe,
  toggleCheck,
  tribeMembers,
  onDeleteTribeMember,
  permissionsAllowed,
  isLoading,
  dispatch,
  ...props
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <div className={`tribesCard ${open ? "expanded" : ""}`}>
      <div className="mainContent">
        <div className="left">
          <div className="avatar"> {avatar}</div>
          <div>
            <h6> {name} </h6>
            <div className={isInvited || email.toLowerCase().trim() === 'invited' ? 'invited' : ''}>{email}</div>
          </div>
        </div>
        <div>
          {
            !isLoading && (isOwner || permissionsAllowed === '*' || permissionsAllowed?.length && permissionsAllowed?.length > 0 && permissionsAllowed?.includes('EDIT_TRIBE')) ?
              <UncontrolledButtonDropdown className="tribesDropdown">
                <DropdownToggle>
                  <DottedVerticalSvg color="#6C6C6C" width="24" height="24" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => onDeleteTribeMember(tribe)}>Remove Tribe Member</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              : ''
          }
          {
            !isLoading && (permissionsAllowed === '*' || permissionsAllowed?.length && permissionsAllowed?.length > 0 && permissionsAllowed?.includes('VIEW_TRIBE_ACTIVITY')) ?
              <Button
                color=""
                className="commentBtn"
                size="sm"
                onClick={() => onClickComment()}
              >
                <MessageCircle width="24" height="24" color="#FF8D4E" />{" "}
              </Button> :
              isLoading ?
                <div style={{ height: 'auto' }} className="loader">
                  <Loader type="TailSpin" color="#FF8D4E" width={32} height={32} />
                </div> : ''
          }
        </div>
      </div>
      {
        !isLoading && (permissionsAllowed === '*' || permissionsAllowed?.length && permissionsAllowed?.length > 0 && permissionsAllowed?.includes('EDIT_TRIBE')) ?
          <><div className="bottom" onClick={() => setOpen(!open)}>
            PERMISSIONS
            <Button
              color=""
              className={` ${open ? "open" : "close"}`}
              size="sm"
            >
              <CaretDown />
            </Button>
          </div>
            <div className={`dropdown ${open ? "expanded" : ""}`}>
              {open &&
                permissions.map((item, index) => {
                  if (item.name === 'REACHOUT_THROUGH_MEMBERS' || item.label === 'false')
                    return ''
                  return <TribesPermission item={item.label} permission={item} key={index} userPermissions={userPermissions} tribe={tribe} toggleCheck={toggleCheck} />;
                })}
            </div></> : ''
      }
    </div>
  );
}

export default connect(state => {
  return {
    tribeMembers: state.prospectsView.tribeMembers.tribeMembers.tribeMembers,
    permissions: state.prospectsView.tribeMembers.tribeMembers.permissions,
    isLoading: state.prospectsView.candidates.isLoading,
  }
})(TribesCard);
