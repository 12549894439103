// import { API_HOST, PENCILIT_API_KEY, API_HOST_PENCILIT } from '../../config/index.js'
// import { getToken, getCsrfToken } from '../utils'
// import qs from 'query-string'
import { get } from 'lodash'
import { logout } from '../actions/auth'
import { manualApiCall } from '../utils'

export const CALL_API = Symbol('CALL_API')

// export const CALL_LINKEDIN_API = Symbol('CALL_LINKEDIN_API')
// export const CALL_PENCILIT_API = Symbol('CALL_PENCILIT_API')


const apiSourceDefault = process.env.REACT_APP_API_HOST
// const apiSourceLinkedin = 'https://www.linkedin.com'
// const pencilitApikey = PENCILIT_API_KEY
// const apiSourcePencilit = API_HOST_PENCILIT

export const apiMiddleware = store => next => async action => {
  if (action[CALL_API]) {
    const {
      endpoint,
      types,
      apiSource = null,
      options,
      meta = null,
      aT = false
      // adminToken = null
    } = action[CALL_API]
    // console.log("===================action[CALL_API]===========================",  action[CALL_API])
    const source = apiSource || apiSourceDefault

    const url = `${source}${endpoint, options}`

    // console.log("===================action[CALL_API]url===========================",  url)

    const [FETCH, SUCCESS, FAILURE] = types

    /**
     * todo:
     * add auth headers if api source is default
     */

    try {

      store.dispatch({ type: FETCH, meta })
      const user = JSON.parse(localStorage.getItem('user'))

      const resp = await manualApiCall(endpoint, options, aT)
      console.log("===============apiMiddleware=======================", {endpoint, resp})
      if(!resp?.isAuthorized && resp?.reason==="user not found"){
        store.dispatch(logout())
        throw new Error(resp?.reason)
      }
      else if(resp?.isAuthorized || resp?.message==="Api error"){
        throw new Error(resp?.message)
      }
      else{
        // fetch(url, { ...options, headers: { ...get(options, 'headers', options.method === 'POST' ? { 'Content-Type': 'application/json' } : {}), 'Authorization': user?.token } })
          // .then(res => {
          //   if(res.status > 199 && res.status < 300){ 
          //   return res.json()
          // } else {
          //   throw new Error('Unauthorized')
          // }
          // })
        store.dispatch({ type: SUCCESS, payload: resp, meta })
      }
      

    } catch (e) {
      console.log('AN ERROR OCCURED: ', e.message)
      store.dispatch({ type: FAILURE, error: e, meta })
    }
  }
  else {
    next(action)
  }

}