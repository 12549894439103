import React from 'react'

import './style.scss'

const SecondaryButton = ({
    style={},
    className='',
    onClick=e=>false,
    onMouseEnter=e=>false,
    onMouseLeave=e=>false,
    disabled=false,
    idx,
    children
}) => {
    return (
        <button
            id={idx}
            style={style}
            className={`secondaryQluButton ${className}`}
            onClick={onClick}
            disabled={disabled}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
        </ button>
    )
}

export default SecondaryButton