import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { changeCampaignRound } from '../../../../actions/outbox'
import ProfileSummaryCard from '../../../../components/ProfileSummaryCard/ProfileSummaryCard'
import DropdownComponent from '../../../../uiLibrary/DropdownComponent'
import EmailTag from '../../../../uiLibrary/EmailTag'
import LabelButton from '../../../../uiLibrary/LabelButton'
import CrossSvg from '../../../../uiLibrary/SVGs/CrossSvg'
import InfoSvg from '../../../../uiLibrary/SVGs/InfoSvg'
import { manualApiCall } from '../../../../utils'
import './style.scss'
const OutboxReviewMessage = ({
    selectedProspects,
    sendersName,
    round,
    rounds,
    message,
    is_personalised,
    emailType,
    sendersEmail,
    errorCheck,
    consentCheck,
    consentRequested,
    emailCheck,
    hasConsent,
    setConsentRequested,
    handleChange,
    jobprofile_id,
    dispatch,
    updateSelected,
    missingTags=[],
    isMessageUnderLimit=true,
    emailPresent=true,
    ...rest
}) => {

    const [isAffordanceOpen, setIsAffordanceOpen] = useState(true)

    const {
        id
    } = round 

    const {
        channel,
        subject
    } = is_personalised ? 
        rest : round

    const errorTags = [
        ...missingTags,
    ]


    if(!isMessageUnderLimit){
        errorTags.push('Length exceeded')
    }
    if(!emailPresent){
        errorTags.push('Email missing')
    }
    else if(!errorTags.includes('Consent missing') && !hasConsent){
        errorTags.push('Consent missing')
    }

    const recipient = selectedProspects[Object.keys(selectedProspects).filter(key=>selectedProspects[key].jobProfileId===jobprofile_id)?.[0]]

    const email_address = emailType==='Personal Email' ? 
        recipient?.primaryEmail || recipient?.personal_email :
        emailType==='Work Email' ? 
            recipient?.work_email : 
            'Email Address Missing'
        
    const emailOptions = [
        'Personal Email',
        'Work Email'
    ].filter(opt=>{
        if(emailCheck(jobprofile_id)){
            if(opt==='Personal Email'){
                return (recipient?.primaryEmail || recipient?.personal_email) && emailType!==opt
            }
            return  recipient?.work_email &&  emailType!==opt
        }
        return emailType!==opt
    })

    useEffect(async()=>{
        // console.log("====================consentRequest 0==========================", {recipient})
        if(emailCheck(jobprofile_id) && emailType){
            const messages = await Promise.all(round.messages.map( async m=>{
                if(m.jobprofile_id===jobprofile_id){
                    const hasConsent = await consentCheck(jobprofile_id, email_address)
                    // console.log("===============consentRequest===================", {m, email_address, hasConsent, consentRequested})
                    return{
                        ...m,
                        hasConsent
                    }
                }
                return m
            }))
            handleChange(messages, id,  'messages')
        }
    }, [emailType])

    const onChange = value => {
        // console.log("======================onChange====================================", {value, recipient})
        const messages = round.messages.map(m=>{
            if(m.jobprofile_id===jobprofile_id){
                const errorCheckPayload = errorCheck(value, recipient, channel==='linkedin', email_address)
                return{
                    ...m,
                    message: value,
                    ...errorCheckPayload
                }
            }
            return m
        })
        handleChange(messages, id,  'messages')
    }

    const {
        primaryEmail,
        personal_email,
        work_email
    } = recipient
    // console.log("=================personalEmail========================",  {
    //     primaryEmail,
    //     personal_email,
    //     work_email,
    //     recipient
    // })
    const personalEmail = primaryEmail || personal_email
    const handleAdd = (e, value, property, type) => {
           e.stopPropagation()
           const regexMatch = value.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi)
           if(value==='' || !regexMatch){
               alert('Invalid Email!')
           }
           else{
               updateSelected(recipient.id, property, value)
               if(!personalEmail && !work_email){
                    rounds.map(r=>{
                        dispatch(changeCampaignRound({
                            id: r.id,
                            value:{
                                messages: r.messages.map(m=>{
                                    if(m.jobprofile_id===jobprofile_id && !m.emailType){
                                        return{
                                            ...m,
                                            emailType: type,
                                            emailPresent: true
                                        }
                                    }
                                    return m
                                })
                            }
                        }))
                    })
               }
           }
       }

    const selectEmailType = type => {

        dispatch(changeCampaignRound({
            id,
            value:{
                messages: round.messages.map(m=>{
                    if(m.jobprofile_id===jobprofile_id){
                        return{
                            ...m,
                            emailType: type,
                            emailPresent: true
                        }
                    }
                    return m
                })
            }
        }))
    }

    const requestConsent = e => {
        // console.log("===============requestConsent=======================",  recipient?.primaryEmail, recipient?.personal_email, recipient?.work_email, email_address, emailType  )
        if(email_address==='Email Address Missing'){
            return false
        }
        manualApiCall('/ds/consentRequest/request', {
            method: 'POST',
            body: JSON.stringify({
                "recruiterName": sendersName,
                "subjects": [
                    {
                        "name": recipient?.fullName,
                        "email": email_address
                    }
                ]
            })
        })
        setConsentRequested(prev=>[...prev, email_address])

    }

    return (
        <div
            className='outboxReviewMessageContainer'
        >
            <div
                className='outboxReviewMessageHeader'
            >
                <div>
                    <ProfileSummaryCard
                        imgSrc={recipient.imageUrl}
                        name={recipient.fullName}
                        url={`https://www.linkedin.com/in/${recipient.publicIdentifier}`}
                        headline={recipient?.headline}
                    />
                    {is_personalised &&
                        <h5>
                            Customized
                        </h5>
                    }
                </div>
                <span>
                    {errorTags.length>0 ?
                        errorTags.map(tag=>(
                            <p>
                                {tag}
                            </p>
                        )) : 
                        <h6>
                            All errors fixed
                        </h6>
                    }
                </span>
            </div>
            {errorTags.includes("Consent missing") &&
                <div className='affordance'>
                    <InfoSvg
                        Recommended={true}
                        width='32'
                        height='32'
                    />
                    <p>This candidate is a resident of the EU or EEA. You will need to obtain their consent in order to contact them. <a onClick={requestConsent} >Click here to ask for consent</a></p>
                </div>} 
            {errorTags.length>0 && !errorTags.every(tag=>tag==="Consent missing") &&
                <div
                    className='outboxReviewMessageBody'
                >
                    {channel==='email' &&
                        <div
                            className='outboxReviewMessageSubject'
                        >
                            <span>
                                <div>
                                    <h4>
                                        From
                                    </h4>
                                    <p>
                                        {sendersEmail}
                                    </p>
                                </div>
                                <div>
                                    <h4>
                                        Subject
                                    </h4>
                                    <input
                                        value={subject}
                                    />
                                </div>
                            </span>
                            <span>
                                <div>
                                    <h4>
                                        To
                                    </h4>
                                    <span>
                                        {(!(personalEmail && personalEmail) || emailType==='Personal Email') &&
                                            <EmailTag
                                                style={{
                                                    order: personalEmail ? 1 : 3
                                                }}
                                                found={personalEmail}
                                                onAdd={(e, value)=>handleAdd(e, value, 'personal_email', 'Personal Email')}
                                            >
                                                Personal email
                                            </EmailTag>
                                        }
                                        {(!(personalEmail && work_email) || emailType==='Work Email') &&
                                            <EmailTag
                                                style={{
                                                    order: work_email ? 2 : 4
                                                }}
                                                found={work_email}
                                                onAdd={(e, value)=>handleAdd(e, value, 'work_email', 'Work Email')}
                                            >
                                                Work email
                                            </EmailTag>
                                        }
                                        {personalEmail && work_email &&
                                            <DropdownComponent

                                                style={{
                                                    order: 3
                                                }}
                                                options={emailOptions}
                                                selected={emailType}
                                                onSelect={selectEmailType}
                                            />  
                                        }
                                    </span>
                                </div>
                            </span>
                        </div>
                    }
                    <textarea
                        rows='9'
                        value={message}
                        onChange={e=>onChange(e.target.value)}
                    />
                    {channel==='linkedin' &&
                        <p
                            className={message.length>280 ? 'overLimitError' : ''}
                        >
                            {`${280-message.length>=0?280-message.length:0} characters left`}
                        </p>
                    }
                </div>
            }
        </div>
    )
}

export default connect(state=>({
    rounds: state.outbox.campaigns.rounds,
    selectedProspects: state.prospectsView.candidates.selectedProspects,
    sendersEmail: state.auth.user.email,
    sendersName: state.auth.user.name,
}))(OutboxReviewMessage)
