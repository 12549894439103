import React, { useEffect, useState } from 'react'
import DefualtScreen from './components/DefaultScreen'
import Home from './components/Home'
import "./style.scss"
import background from './assets/Vector@2x.svg'
import { Player } from '@lottiefiles/react-lottie-player'


function DemoChatFlow() {
    const [isLoading, setIsLoading] = useState(false)
    const [result, setResult] = useState('')
    const [error, setError] = useState(false)
    const [chatLogs, setChatLogs] = useState([])

    // const onClick = (query) => {


    // }

    const onSubmit = async (query) => {
        try {
            if (query?.trim()) {
                setChatLogs([...chatLogs, { q: true, value: query }, { q: false, value: '' }])
                const url = process.env.REACT_APP_DEMO_API_URL

                setIsLoading(true)
                console.log("first :", query)

                for (let i = 0; i < 3; i++) {
                    let val = await fetch(url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            query
                        })

                    }).then(res => {
                        console.log({ res })
                        if (res?.ok) {
                            return res.json()
                        }
                        else {
                            return 0
                            // setChatLogs([...chatLogs, { q: true, value: query }, { q: false, value: "No response from server" }])
                        }
                    })
                        .then(data => {
                            console.log({ data });
                            setIsLoading(false);
                            // setResult(data?.answer);
                            setChatLogs([...chatLogs, { q: true, value: query }, { q: false, value: data?.answer }])
                            return 1
                        })
                        .catch(err => { return 0 })

                    console.log("Res api :", val)
                    if (val == 1) {
                        break
                    }
                    else {
                        if (i == 2) {
                            setIsLoading(false);
                            setChatLogs([...chatLogs, { q: true, value: query }, { q: false, value: "Oops something went wrong!" }])
                        }
                        continue
                    }

                }
            }
            else {
                alert("add query into textfeild")
            }

        } catch (error) {
            setIsLoading(false)
            setChatLogs([...chatLogs, { q: true, value: query }, { q: false, value: "Oops something went wrong!" }])
            // setError(true)
            console.log("errror: ", error?.message)
        }
    }
    useEffect(() => {
        console.log("chatLogs", chatLogs)
    }, [chatLogs])

    return (
        <div className="DemoChatFlow" >
            {/* <div className='imgbg' style={{ position: 'absolute', background: `url(${background})` }}>

            </div> */}
            {
                chatLogs?.length === 0 ?
                    <DefualtScreen onSubmit={onSubmit} /> :
                    <Home onSubmit={onSubmit} chatLogs={chatLogs} isLoading={isLoading} />
            }

        </div >
    );
}

export default DemoChatFlow