import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFeedbackEntity } from "../../../actions/QuickSetup";
import { getEntityByProfileID } from "../../../reducers/QuickSetup/profileFeedback";
import LikeableSection from "./LikeableSection";
import SectionWrapper from "./SectionWrapper";

export default function OtherExperience({
  title,
  id,
  profile: { experience = [] },
}) {
  const dispatch = useDispatch();
  const currentFeedback = useSelector((state) =>
    getEntityByProfileID(state.QuickSetup.profileFeedback, id)
  );

  const getTotalExperience = React.useCallback(() => {
    const startYear = getStartYear();
    const endYear = getEndYear();

    const totalExperience = endYear - startYear;

    return totalExperience;
  }, [experience]);

  const getStartYear = React.useCallback(() => {
    const allDates = experience
      .filter((exp) => !!exp["start"])
      .map((ex) => ex["start"]);

    const allYears = allDates.map((year) =>
      year.includes("-") ? year.split("-")[1] : year
    );

    const uniqueYears = [...new Set(allYears.map((year) => Number(year)))];

    return Math.min(...uniqueYears);
  }, [experience]);

  const getEndYear = React.useCallback(() => {
    const allDates = experience.map((ex) => ex["end"]);

    if (!allDates[0]) {
      return moment().get("year");
    }

    const allYears = allDates.map((year) =>
      year.includes("-") ? year.split("-")[1] : year
    );

    const uniqueYears = [...new Set(allYears.map((year) => Number(year)))];

    return Math.max(...uniqueYears);
  }, [experience]);

  const isLiked = currentFeedback?.data?.totalExperience?.isLiked;

  return (
    <SectionWrapper name={title}>
      <LikeableSection
        isLiked={isLiked}
        handleLike={(liked) =>
          dispatch(
            updateFeedbackEntity(id, "totalExperience", {
              isLiked: Boolean(liked),
            })
          )
        }
      >
        <span>{`${getTotalExperience()} years`}</span>
      </LikeableSection>
    </SectionWrapper>
  );
}
