import * as _ from "lodash";
import { io } from "socket.io-client";
import * as ActionTypes from "../../actions/prospectsView";

const initialState = {
  prospects: [],
  allProspects: [],
  buckets: [],
  firstBucket: {},
  funnel: {},
  folders: {},
  currentBucket: "All Titles",
  selectedProspects: {},
  page: 0,
  isLoaded: false,
  isLoading: false,
  areEmailsFetched: false,
  areEmailsFetching: false,
  job: {},
  comments: [],
  tribeMembers: [],
  commentsLoading: false,
  commentsLoaded: false,
  showEmojiPickerCommentID: null,
  showEmojiPickerProspectID: null,
  collabSocket: null,
  selectedProspectComments: null,
  labelLoading: false,
  profileLikedStatuses: {},
  recommendedProfiles: [],
  currentRecommendedProfile: null,
  recommendationType: "p",
  currentHoverElement: null,
  hasSavedProfiles: false,
  permissions: "*",
  deleteCount: 0,
  likedStatus: {},
};

const candidates = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PROSPECT_RECOMMENDED_OBJECT: {
      let prospects = state.prospects;
      let index = prospects.findIndex(
        (prospect) => prospect.jobProfileId === action.payload.jobProfileId
      );
      prospects[index].recommendationObject =
        action.payload.recommendationObject;
      return {
        ...state,
        prospects: prospects,
      };
    }
    case ActionTypes.ADD_PROSPECTS: {
      return {
        ...state,
        prospects: [...state.prospects, ...action.payload],
      };
    }
    case ActionTypes.FETCH_EMAILS: {
      return {
        ...state,
        areEmailsFetched: false,
        areEmailsFetching: true,
      };
    }
    case ActionTypes.FETCH_EMAILS_SUCCESS: {
      return {
        ...state,
        prospects: action.payload.prospectEmails,
        selectedProspects: action.payload.selectedProspectsEmails,
        areEmailsFetched: true,
        areEmailsFetching: false,
      };
    }
    case ActionTypes.FETCH_EMAILS_FAILURE: {
      return {
        ...state,
        areEmailsFetched: false,
        areEmailsFetching: false,
      };
    }
    case ActionTypes.NEXT_PAGE: {
      return {
        ...state,
        page: state.page + 1,
      };
    }

    case ActionTypes.INIT_PAGE: {
      return {
        ...state,
        page: 0,
      };
    }

    case ActionTypes.FETCH_PROSPECTS:
      return { ...state, isLoading: true };

    case ActionTypes.FETCH_PROSPECTS_SUCCESS:
      // Adding hidden elements to fix flex issues on last row
      // let tempProspect = { ...action.payload.candidates[action.payload.candidates.length - 1] };
      // if (!isEmpty(tempProspect)) {
      //   action.payload.candidates.push({
      //     ...tempProspect,
      //     id: "1temp",
      //     hidden: true,
      //   });
      //   action.payload.candidates.push({
      //     ...tempProspect,
      //     id: "2temp",
      //     hidden: true,
      //   });
      //   action.payload.candidates.push({
      //     ...tempProspect,
      //     id: "3temp",
      //     hidden: true,
      //   });
      //   action.payload.candidates.push({
      //     ...tempProspect,
      //     id: "4temp",
      //     hidden: true,
      //   });
      // }
      if (action.batch) {
        const p = [...state.prospects]
        p.push(...action.payload.candidates)
        return {
          ...state,
          prospects: p,
        };
      } else if (action.rev) {
        const p = [...state.prospects]
        p.unshift(...action.payload.candidates)
        return {
          ...state,
          prospects: p,
        };
      } else {
        return {
          ...state,
          prospects: [...action.payload.candidates],
          buckets: action.payload.buckets,
          funnel: action.payload.funnelCounts,
          folders: action.payload.folderCounts,
          currentBucket: action.payload.currentBucket,
          firstBucket: action.payload?.buckets[0],
          job: action.payload.job,
          permissions: action.payload?.permissions,
          recommendedCount: action.payload?.recommendedCounts?.counts?.length
            ? action.payload?.recommendedCounts?.counts[0]
            : {},
          hasSavedProfiles: action.payload.hasSavedProfiles,
          isLoading: false,
          isLoaded: true,
        };
      }

    case ActionTypes.FETCH_PROSPECTS_FAILURE:
      return initialState;

    case ActionTypes.FETCH_ALL_PROSPECTS_SUCCESS:
      return {
        ...state,
        allProspects: [...action.payload.candidates]
      };

    case ActionTypes.FETCH_ALL_PROSPECTS_FAILURE:
      return initialState;

    case ActionTypes.SELECT_PROSPECTS: {
      return {
        ...state,
        selectedProspects: action.payload,
      };
    }
    case ActionTypes.UPDATE_PROSPECT: {
      return {
        ...state,
        prospects: state.prospects.map(itm => (itm?.jobProfileId === action.payload?.key ? { ...itm, ...action.payload?.values } : itm)),
        allProspects: state.allProspects.map(itm => (itm?.jobProfileId === action.payload?.key ? { ...itm, ...action.payload?.values } : itm)),
        folders: {
          ...(state?.folders || {}),
          maybe: action.payload?.values?.maybe ? (state?.folders?.maybe || 0) + 1 : (state?.folders?.maybe || 1) - 1
        }
      };
    }
    case ActionTypes.UPDATE_RECOMMENDED_PROSPECT: {
      return {
        ...state,
        currentRecommendedProfile: {
          ...state.currentRecommendedProfile,
          ...action.payload
        }
      };
    }
    case ActionTypes.CLEAR_SELECTED_PROSPECTS: {
      return {
        ...state,
        selectedProspects: {},
      };
    }
    case ActionTypes.CLEAR_PROSPECTS: {
      return {
        ...state,
        prospects: [],
        // selectedProspects: {},
      };
    }

    case ActionTypes.REQUEST_SAVE_PROSPECTS: {
      return action.meta.profiles.length === 1
        ? {
          ...state,
          prospects: state.prospects.map((item) =>
            action.meta.profiles.includes(item.jobProfileId)
              ? { ...item, saved: action?.meta?.rollback ? false : true }
              : item
          ),
          allProspects: state.allProspects.map((item) =>
            action.meta.profiles.includes(item.jobProfileId)
              ? { ...item, saved: action?.meta?.rollback ? false : true }
              : item
          ),
        }
        : state;
    }
    case ActionTypes.REQUEST_SAVE_PROSPECTS_SUCCESS:
      console.log("============================================", action, state?.folders)
      return {
        ...state,
        prospects: state.prospects.map((item) =>
          action.meta.profiles.includes(item.jobProfileId)
            ? { ...item, maybe: action?.meta?.rollback ? item?.maybe : false, saved: action?.meta?.rollback ? false : true }
            : item
        ),
        allProspects: state.allProspects.map((item) =>
          action.meta.profiles.includes(item.jobProfileId)
            ? { ...item, maybe: action?.meta?.rollback ? item?.maybe : false, saved: action?.meta?.rollback ? false : true }
            : item
        ),
        folders: {
          ...(state?.folders || {}),
          saved: action?.meta?.rollback ? (state?.folders?.saved || 1) - 1 : (state?.folders?.saved || 0) + 1
        }
      };
    // case ActionTypes.REQUEST_SAVE_PROSPECTS_FAILURE:
    //   console.log("============================================", action, state?.folders)
    //   return {
    //     ...state,
    //     prospects: state.prospects.map((item) =>
    //       action.meta.profiles.includes(item.jobProfileId)
    //         ? { ...item, maybe: action?.meta?.rollback ? item?.maybe : false, saved: action?.meta?.rollback ? false : true }
    //         : item
    //     ),
    //     allProspects: state.allProspects.map((item) =>
    //       action.meta.profiles.includes(item.jobProfileId)
    //         ? { ...item, maybe: action?.meta?.rollback ? item?.maybe : false, saved: action?.meta?.rollback ? false : true }
    //         : item
    //     ),
    //     folders: {
    //       ...(state?.folders || {}),
    //       saved: action?.meta?.rollback ? (state?.folders?.saved || 1) - 1 : (state?.folders?.saved || 0) + 1
    //     }
    //   };
    case ActionTypes.REQUEST_ARCHIVE_PROSPECTS_SUCCESS:
      return {
        ...state,
        prospects: state.prospects.filter(
          (item) => !action.meta.profiles.includes(item.jobProfileId)
        ),
        allProspects: state.allProspects.filter(
          (item) => !action.meta.profiles.includes(item.jobProfileId)
        ),
        folders: {
          ...(state?.folders || {}),
          all: (state?.folders?.all || 0) - 1
          // archived: (state?.folders?.archived || 0) + 1
        }
      };

    // case ActionTypes.REQUEST_DOWNLOAD_PROSPECTS_SUCCESS:
    //   return {
    //     ...state,
    //     prospects: state.prospect
    //   }

    case ActionTypes.INITIALIZE_PROSPECTS_CONTROLLER: {
      return initialState;
    }

    case ActionTypes.FETCH_COMMENTS:
      return { ...state, comments: [], commentsLoading: true };

    case ActionTypes.FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: [...action.payload],
        tribeMembers:
          action?.tribeMembers?.length > 0 ? action?.tribeMembers : [],
        commentsLoading: false,
        commentsLoaded: true,
      };

    case ActionTypes.FETCH_COMMENTS_FAILURE:
      return state;

    case ActionTypes.ADD_COMMENT:
      return { ...state, commentsLoading: true };

    case ActionTypes.ADD_COMMENT_SUCCESS: {
      let newState = {};
      if (action?.payload?.comment) {
        let replyUser = "";
        let replyText = "";
        let replyUserColor = "";

        if (action.payload?.comment?.parentId) {
          let parentComment = state.comments.find(
            (parent) => parent.id === action.payload?.comment?.parentId
          );

          if (parentComment?.id) {
            replyUser = parentComment?.senderName;
            replyText = parentComment?.messageText;
            replyUserColor = parentComment?.senderColor;
          }
        }

        let comments = [
          ...state.comments,
          { ...action.payload.comment, replyUser, replyText, replyUserColor },
        ];

        newState = {
          ...state,
          comments: _.uniqBy(comments, "id"),
          commentsLoading: false,
          commentsLoaded: true,
        };
      } else if (action.payload) {
        let replyUser = "";
        let replyText = "";
        let replyUserColor = "";

        if (action.payload?.parentId) {
          let parentComment = state.comments.find(
            (parent) => parent.id === action.payload?.parentId
          );

          if (parentComment?.id) {
            replyUser = parentComment?.senderName;
            replyText = parentComment?.messageText;
            replyUserColor = parentComment?.senderColor;
          }
        }

        let comments = [
          ...state.comments,
          { ...action.payload, replyUser, replyText, replyUserColor },
        ];

        newState = {
          ...state,
          comments: _.uniqBy(comments, "id"),
          commentsLoading: false,
          commentsLoaded: true,
        };
      } else {
        let comments = [...state.comments];
        newState = {
          ...state,
          comments: _.uniqBy(comments, "id"),
          commentsLoading: false,
          commentsLoaded: true,
        };
      }
      return newState;
    }

    case ActionTypes.ADD_COMMENT_FAILURE:
      return state;

    case ActionTypes.DELETE_COMMENT:
      return { ...state, commentsLoading: true };

    case ActionTypes.DELETE_COMMENT_SUCCESS: {
      let newState = {};

      if (action.payload) {
        let comments = state.comments.filter(
          (comment) => comment.id != action.payload
        );
        newState = {
          ...state,
          comments: _.uniqBy(comments, "id"),
          commentsLoading: false,
          commentsLoaded: true,
        };
      }

      return newState;
    }

    case ActionTypes.DELETE_COMMENT_FAILURE:
      return state;

    case ActionTypes.SHOW_EMOJI_COMMENT:
      return { ...state, showEmojiPickerCommentID: action.payload.id };

    case ActionTypes.SHOW_EMOJI_PROSPECT:
      return { ...state, showEmojiPickerProspectID: action.payload.id };

    case ActionTypes.SAVE_NEW_EMOJI:
      return {
        ...state,
        showEmojiPickerCommentID: null,
        showEmojiPickerProspectID: null,
      };

    case ActionTypes.SAVE_NEW_EMOJI_SUCCESS: {
      // let comments = state.comments

      // comments = comments.map((comment) => {
      //   if(comment?.id === action.payload.emoji.noteId) {
      //     let exists = comment?.rawEmojiData?.find(emoji => emoji.id === action.payload.emoji.id)
      //     comment.rawEmojiData = comment.rawEmojiData ? comment.rawEmojiData : []
      //     if(!exists)
      //       comment?.rawEmojiData?.push(action.payload.emoji)

      //       comment.emojies = ActionTypes.getUniqueEmojiCounts(comment?.rawEmojiData)
      //   }

      //   return comment
      // })

      return { ...state };
    }

    case ActionTypes.SAVE_NEW_EMOJI_FAILURE:
      return {
        ...state,
        showEmojiPickerCommentID: null,
        showEmojiPickerProspectID: null,
      };

    case ActionTypes.APPEND_NEW_EMOJI_SUCCESS: {
      let comments = state.comments;
      let prospects = state.prospects;

      action.payload = action?.payload?.emoji
        ? action?.payload?.emoji
        : action?.payload;

      if (action?.payload?.noteId) {
        comments = comments.map((comment) => {
          if (comment?.id === action.payload.noteId) {
            let exists = comment?.emojies?.find(
              (emoji) => emoji.id === action.payload.id
            );
            comment.emojies = comment.emojies ? comment.emojies : [];
            if (!exists) comment?.emojies?.push(action.payload);
          }

          return comment;
        });
      } else if (action?.payload?.jobProfileId) {
        prospects = prospects.map((prospect) => {
          if (prospect?.jobProfileId === action.payload.jobProfileId) {
            let exists = prospect?.emojies?.find(
              (emoji) => emoji.id === action.payload.id
            );
            prospect.emojies = prospect.emojies ? prospect.emojies : [];
            if (!exists) prospect?.emojies?.push(action.payload);
          }

          return prospect;
        });
      }
      return {
        ...state,
        comments,
        prospects,
        showEmojiPickerCommentID: null,
        showEmojiPickerProspectID: null,
      };
    }

    case ActionTypes.DELETE_EMOJI_SUCCESS: {
      let comments = state.comments;
      let prospects = state.prospects;
      action.payload = action?.payload?.emoji
        ? action?.payload?.emoji
        : action?.payload;

      if (action?.payload?.noteId) {
        comments = comments.map((comment) => {
          if (comment?.id === action?.payload?.noteId) {
            comment.emojies = comment?.emojies?.filter(
              (emoji) => emoji.id !== action.payload.id
            );
          }

          return comment;
        });
      } else if (action?.payload?.jobProfileId) {
        prospects = prospects.map((prospect) => {
          if (prospect?.jobProfileId === action?.payload?.jobProfileId) {
            prospect.emojies = prospect?.emojies?.filter(
              (emoji) => emoji.id !== action.payload.id
            );
          }

          return prospect;
        });
      }

      return { ...state, comments, prospects };
    }

    case ActionTypes.DELETE_COMMENT_PROSPECT_SUCCESS: {
      let prospects = state.prospects;

      if (action.payload) {
        prospects = prospects.map((prospect) => {
          if (prospect?.jobProfileId === action.payload?.jobProfileId) {
            prospect.noteCount = action.payload?.remainingCount;
          }

          return prospect;
        });
      }

      return { ...state, prospects };
    }

    case ActionTypes.APPEND_PROSPECT_COMMENT_SUCCESS: {
      let prospects = state.prospects;

      if (action.payload?.comment) {
        prospects = prospects.map((prospect) => {
          if (
            prospect?.jobProfileId === action.payload?.comment?.jobProfileId
          ) {
            prospect.noteCount = action.payload?.comment?.count;
          }

          return prospect;
        });
      }

      return { ...state, prospects };
    }

    case ActionTypes.APPEND_PROSPECT_EMOJI_SUCCESS: {
      // let prospects = state.prospects
      // if(action.payload?.emoji) {
      //   prospects = prospects.map((prospect) => {
      //     if(prospect?.jobProfileId === action.payload?.emoji?.jobProfileId &&
      //       action.payload?.emoji?.noteId == null) {
      //         let index = prospect.emojies.findIndex((emoji) => emoji.emojiText === action.payload?.emoji?.emojiText)
      //         if(index > -1) {
      //           if(prospect.emojies[index].emojiText === action.payload?.emoji?.emojiText)
      //             prospect.emojies[index].count += 1
      //         } else {
      //           prospect.emojies.push({'emojiText': action.payload?.emoji?.emojiText, count: 1})
      //         }
      //         prospect.rawEmojiData = prospect.emojies
      //       }

      //     return prospect
      //   })
      // }

      return { ...state };
    }

    case ActionTypes.DELETE_PROSPECT_EMOJI_SUCCESS: {
      // let prospects = state.prospects
      // if(action.payload?.emoji) {
      //   prospects = prospects.map((prospect) => {
      //     if(prospect?.jobProfileId === action.payload?.emoji?.jobProfileId &&
      //       action.payload?.emoji?.noteId == null) {
      //         let index = prospect.emojies.findIndex((emoji) => emoji.emojiText === action.payload?.emoji?.emojiText)
      //         if(index > -1) {
      //           if(prospect.emojies[index].emojiText === action.payload?.emoji?.emojiText) {
      //             prospect.emojies[index].count -= 1
      //             if(prospect.emojies[index].count < 1) {
      //               prospect.emojies.splice(index, 1);
      //             }
      //           }
      //         }
      //         prospect.rawEmojiData = prospect.emojies
      //       }

      //     return prospect
      //   })
      // }

      return { ...state };
    }

    case ActionTypes.CONNECT_SOCKET: {
      const collabUrl = `${process.env.REACT_APP_API_HOST}/colab`;

      if (
        state?.collabSocket?.connected == false ||
        state?.collabSocket == null
      ) {
        if (action.payload.id) {
          state.collabSocket = io(collabUrl, {
            transports: ["websocket"],
            query: `jobId=${action.payload.id}`,
          });
        }
      }
      return { ...state };
    }

    case ActionTypes.SET_SELECTED_PROSPECT_COMMENTS: {
      return { ...state, selectedProspectComments: action.payload };
    }

    case ActionTypes.ADD_LABEL_SUCCESS:
      return {
        ...state,
        job: action.payload,
        labelLoading: false,
      };

    case ActionTypes.ADD_LABEL_LOADING:
      return {
        ...state,
        labelLoading: true,
      };
    case ActionTypes.LIKED_PROFILE: {
      let prospects = state.prospects;
      let index = action?.payload?.index;
      let likedStatus = {
        ...state.likedStatus,
        [index]: action?.payload?.status,
      };
      if (action?.payload?.status == "RECOMMENDED_NO") {
        delete likedStatus?.[index];
      }
      if (prospects?.length > 0) {
        if (prospects[index]) {
          prospects[index].recommendedStatus = action?.payload?.status
        }
      }
      return {
        ...state,
        prospects: prospects,
        likedStatus: likedStatus,
      };
    }
    case ActionTypes.LIKED_PROFILE_ADDING_PROSPECTS: {
      return {
        ...state,
        currentRecommendedProfile: null,
      };
    }
    case ActionTypes.GET_RECOMMENDATION_CANDIDATE: {
      let recommendedProfiles = state.recommendedProfiles;
      if (action.payload.data[0]) {
        recommendedProfiles.push(action.payload.data[0]);
      }
      let newCurrentRecommendedProfile = action.payload.data.length
        ? recommendedProfiles[recommendedProfiles.length - 1]
        : null;
      return {
        ...state,
        recommendedProfiles,
        currentRecommendedProfile: newCurrentRecommendedProfile,
        recommendationType:
          state.recommendationType === "p"
            ? "n"
            : state.recommendationType === "n"
              ? "c"
              : "p",
      };
    }
    case ActionTypes.CLEAR_RECOMMENDED_CANDIDATES: {
      return {
        ...state,
        recommendedProfiles: [],
        currentRecommendedProfile: null,
      };
    }
    case ActionTypes.ON_HOVER_CHANGE_PROSPECT_ELEMENT: {
      let prospectIndex = state.prospects.findIndex(
        (prospect) =>
          prospect.jobProfileId ===
          action.payload.newProspectElement.jobProfileId
      );
      let newProspects = state.prospects;
      if (prospectIndex > -1) {
        newProspects[prospectIndex] = action.payload.newProspectElement;
      }
      return {
        ...state,
        prospects: newProspects,
        currentHoverElement: action.payload.currentHoverElement,
      };
    }
    case ActionTypes.SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case ActionTypes.DELETE_PROSPECT: {
      let prospects = state.prospects;
      let index = action?.payload;
      prospects.splice(index, 1);
      return {
        ...state,
        prospects: prospects,
      };
    }
    case ActionTypes.DELETE_COUNTER: {
      return {
        ...state,
        deleteCount: action.payload,
      }
    }
    default:
      return state;
  }
};

export default candidates;
