import { template } from 'lodash'
import React, { useState, useEffect, useRef, memo } from 'react'

import {
    Editor,
} from 'draft-js'
import 'draft-js/dist/Draft.css'
import './style.scss'

const StyleableTextArea = memo(({
    placeholder='',
    onChange,
    editorState,
}) => {

    return  <div className='draftEditorContainer'>
        <Editor
            editorState={editorState}
            onChange={onChange}
            placeholder={placeholder}
        />
    </div>
})

export default StyleableTextArea
