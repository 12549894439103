import { CALL_API } from "../middlewares/apiMiddleware"
import { isRecruiterUrnFetchable, manualApiCall } from "../utils"
import { callQluLinkedinApi, pingQlu } from "../utils/MessagePassing"
import { removeProspects } from "./prospectsView"

export const FETCH_CAMPAIGN_DRAFTS = 'FETCH_CAMPAIGN_DRAFTS'
export const FETCH_CAMPAIGN_DRAFTS_SUCCESS = 'FETCH_CAMPAIGN_DRAFTS_SUCCESS'
export const FETCH_CAMPAIGN_DRAFTS_FAILURE = 'FETCH_CAMPAIGN_DRAFTS_FAILURE'
export const fetchCampaignDrafts = (jobId) => ({
    [CALL_API]: {
        types: [FETCH_CAMPAIGN_DRAFTS, FETCH_CAMPAIGN_DRAFTS_SUCCESS, FETCH_CAMPAIGN_DRAFTS_FAILURE],
        endpoint: `/api/v2/auth/jobs/${jobId}/campaigns`,
        options: {
            method: 'GET',
        },
    }
})

export const TOGGLE_SHARE_WITH_TRIBE = 'TOGGLE_SHARE_WITH_TRIBE'
export const toggleShareWithTribe = () => ({
    type: TOGGLE_SHARE_WITH_TRIBE
})

export const CHANGE_CAMPAIGN_NAME = 'CHANGE_CAMPAIGN_NAME'
export const changeCampaignName = payload => ({
    type: CHANGE_CAMPAIGN_NAME,
    payload
})

export const SET_OUTBOX_VIEW = 'SET_OUTBOX_VIEW'
export const setOutboxView = payload => ({
    type: SET_OUTBOX_VIEW,
    payload
})

export const INIT_OUTBOX_VIEW= 'INIT_OUTBOX_VIEW'
export const initOutboxView = () => ({
    type: INIT_OUTBOX_VIEW
})

export const TOGGLE_OUTBOX_DROPDOWN_OPEN = 'TOGGLE_OUTBOX_DROPDOWN_OPEN'
export const toggleOutboxDropdownOpen = () => ({
    type: TOGGLE_OUTBOX_DROPDOWN_OPEN
})

export const CHANGE_CAMPAIGN_ROUND = 'CHANGE_CAMPAIGN_ROUND'
export const changeCampaignRound = payload => ({
    type: CHANGE_CAMPAIGN_ROUND,
    payload
})

export const INIT_OUTBOX = 'INIT_OUTBOX'
export const initOutbox = () => ({
    type: INIT_OUTBOX,
})

export const ADD_CAMPAIGN_ROUND = 'ADD_CAMPAIGN_ROUND'
export const addCampaignRound = (payload) => ({
    type: ADD_CAMPAIGN_ROUND,
    payload
})
export const REMOVE_CAMPAIGN_ROUND = 'REMOVE_CAMPAIGN_ROUND'
export const removeCampaignRound = payload => ({
    type: REMOVE_CAMPAIGN_ROUND,
    payload
})

export const SET_EXTENSION_INSTALLED = 'SET_EXTENSION_INSTALLED'
export const setExtensionInstalled = payload => ({
    type: SET_EXTENSION_INSTALLED,
    payload
})

export const SET_LINKEDIN_LOGGED_IN = 'SET_LINKEDIN_LOGGED_IN'
export const setLinkedInLoggedIn = payload => ({
    type: SET_LINKEDIN_LOGGED_IN,
    payload
})




export const PUBLISH_OUTBOX_CAMPAIGN = 'PUBLISH_OUTBOX_CAMPAIGN'
export const PUBLISH_OUTBOX_CAMPAIGN_SUCCESS = 'PUBLISH_OUTBOX_CAMPAIGN_SUCCESS'
export const PUBLISH_OUTBOX_CAMPAIGN_FAILURE = 'PUBLISH_OUTBOX_CAMPAIGN_FAILURE'
export const publishOutboxCampaign = ( jobId ) => async (dispatch, getState) => { 
    try{    
        dispatch({
            type: PUBLISH_OUTBOX_CAMPAIGN,
        })


        const rounds = getState().outbox.campaigns.rounds
        const name = getState().outbox.campaigns.name
        const selectedProspects = {...getState().prospectsView.candidates.selectedProspects}

        Object.entries(selectedProspects).map(prospect => {
            if(prospect[1].isRemoved){
                rounds.map(round => {
                    round.messages = round.messages?.filter(msg => msg.jobprofile_id != prospect[1].jobProfileId)
                })
                delete selectedProspects[prospect[1].id]
            }
        })
        await dispatch(removeProspects(selectedProspects))
        



        const campaignRounds = rounds.map(({
            messages,  
            send_after_days,
            send_after_hours,
            channel,
            template,
            subject,
            sender
        }, round ) => {

            const campaignMessages = messages.map(roundMessage=>{
                
                let email_address = ''
                const profile = selectedProspects[Object.keys(selectedProspects).filter(key=>selectedProspects[key].jobProfileId===roundMessage.jobprofile_id)?.[0]]
                if(roundMessage.emialType){
                    if(roundMessage.emialType==='Personal Email'){
                        email_address = profile.primaryEmail || profile.personal_email
                    }
                    else{

                        email_address = profile.work_email 
                    }
                }
                else{
                    email_address = profile.primaryEmail || profile.personal_email ||  profile.work_email 
                }

                if(roundMessage.is_personalised){
                    return {
                        send_after_days: roundMessage.send_after_days,
                        send_after_hours: roundMessage.send_after_hours,
                        message: roundMessage.message || null ,
                        subject:  roundMessage.subject || null,
                        template:  roundMessage.template || null ,
                        email_address,
                        jobprofile_id: roundMessage.jobprofile_id,
                        sender: roundMessage.sender,
                        channel: roundMessage.channel,
                        is_personalised: roundMessage.is_personalised,
                    }
                }
                else{
                    return {
                        send_after_days: send_after_days,
                        send_after_hours: send_after_hours,
                        message: roundMessage.message || null,
                        subject:  subject || null ,
                        template:  template || null,
                        email_address,
                        jobprofile_id: roundMessage.jobprofile_id,
                        sender: sender,
                        channel,
                        is_personalised: roundMessage.is_personalised,
                    }
                }
            })

            return {
                sender,
                rounds: round + 1,
                template,
                subject,
                send_after_days,
                send_after_hours,
                channel,
                campaignMessages
            }
        })

        console.log("=============campaignRounds=================", campaignRounds)

        const res = await manualApiCall('/api/v2/auth/campaigns/publish', {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                campaignRounds,
                name,
                jobId 
            })
        })
            
        if(res?.success && Array.isArray(res?.data) && res?.data.length>0){
            const campaignMessageIds = res.data
            dispatch({
                type: PUBLISH_OUTBOX_CAMPAIGN_SUCCESS, 
                payload: campaignRounds?.[0]?.campaignMessages.map((message, idx)=>({
                    ...message,
                    recepient: selectedProspects[Object.keys(selectedProspects).filter(key=>selectedProspects[key].jobProfileId===message.jobprofile_id)?.[0]],
                    campaignMessageId: campaignMessageIds.filter(({jobProfileId})=>message.jobprofile_id===jobProfileId)?.[0]?.campaignMessageId
                }))
            })
        }
        else
            throw new Error("Publishing api failed!")
    }
    catch(e) {
        console.log("ERROR: ", e)
        dispatch({
            type: PUBLISH_OUTBOX_CAMPAIGN_FAILURE
        })
    }
}

export const SELECT_OUTBOX_DRAFT = 'SELECT_OUTBOX_DRAFT'
export const selectOutboxDraft = payload => ({
    type: SELECT_OUTBOX_DRAFT,
    payload
})

export const SET_CAMPAIGN_PENDING_STATUS = 'SET_CAMPAIGN_PENDING_STATUS'
export const setCampaignsPendingStatus = payload => ({
    type: SET_CAMPAIGN_PENDING_STATUS,
    payload
})

export const FETCH_CAMPAIGN_PENDING_STATUS = 'FETCH_CAMPAIGN_PENDING_STATUS'
export const FETCH_CAMPAIGN_PENDING_STATUS_SUCCESS = 'FETCH_CAMPAIGN_PENDING_STATUS_SUCCESS'
export const FETCH_CAMPAIGN_PENDING_STATUS_FAILURE = 'FETCH_CAMPAIGN_PENDING_STATUS_FAILURE'
export const fetchCampaignPendingStatus = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: FETCH_CAMPAIGN_PENDING_STATUS
        })
        
        const resPingQlu = await new Promise(resolve=>pingQlu(r=>resolve(r)))    
        dispatch(setExtensionInstalled(resPingQlu && 'me' in resPingQlu))

        const res = await manualApiCall('/api/v2/auth/campaigns/channels/pending', {
            method: 'GET'
        })
        
        if(res.success){
            const payload = []
            await Promise.all(res.data.map( async channel=>{
                switch (channel) {
                    case 'email':
                        const emailTokenRes = await manualApiCall('/api/auth/emailing/validityOfTokens',
                            {
                            method: 'GET',
                            })
                        if (emailTokenRes.success && !emailTokenRes.data){
                            payload.push(channel)
                        }
                        break;         
                    default:   
                        if(resPingQlu && 'me' in resPingQlu){
                            dispatch(setExtensionInstalled(true))
                            switch (channel) {
                                case 'recruiter':
                                    const {creditBalance} = await new Promise((resolve, reject)=>callQluLinkedinApi(
                                        '/talent/api/talentMessageComposeInfo',
                                        {
                                            method: 'GET',
                                            headers: {
                                                'x-restli-protocol-version': "2.0.0"
                                            }
                                        }, 
                                        r=>resolve(r)))
                                    if(creditBalance===undefined){
                                        payload.push(channel)
                                    }
                                    break;
                                default:
                                    if(!('miniProfile' in resPingQlu.me)){
                                        payload.push(channel)
                                    }
                                    break;
                            }
                        }
                        else{
                            payload.push(channel)                            
                        }
                        break;
                }
            }))
            dispatch({
                type: FETCH_CAMPAIGN_PENDING_STATUS_SUCCESS,
                payload
            })
        }
        else{
            throw new Error("Pending channels api failed")
        }
    }
    catch (err) {
        console.log("ERROR: ", err.message)
        dispatch({
            type: FETCH_CAMPAIGN_PENDING_STATUS_FAILURE
        })
    }
}


export const FETCH_RECRUITER = 'FETCH_RECRUITER'
export const FETCH_RECRUITER_SUCCESS = 'FETCH_RECRUITER_SUCCESS'
export const FETCH_RECRUITER_FAILED = 'FETCH_RECRUITER_FAILED'
export const fetchRecruiter = () => async (dispatch, getState) => {
    try{
        dispatch({
            type: FETCH_RECRUITER
        })
        const {creditBalance} = await new Promise((resolve, reject)=>callQluLinkedinApi(
            '/talent/api/talentMessageComposeInfo',
            {
                method: 'GET',
                headers: {
                    'x-restli-protocol-version': "2.0.0"
                }
            }, 
            r=>resolve(r)))
        if(creditBalance>0){
            dispatch({
                type: FETCH_RECRUITER_SUCCESS,
                payload: creditBalance
            })
        }

        else{
            throw new Error("fetch recruiter api Failed")
        }
    }
    catch (err) {
        console.log("ERROR: ", err.message)
        dispatch({
            type: FETCH_RECRUITER_FAILED
        })
    }
}