import * as ActionTypes from '../../actions/hybridCampaign'

const initialState = {
  view: 'REACHOUT',
  round: 0,
  messageType: 'Li',
  connected: false,
  isLoading: false,
  isLoaded: false,

}


// export const LOAD_HYBRID_CAMPAIGN = 'LOAD_HYBRID_CAMPAIGN'
// export const LOAD_HYBRID_CAMPAIGN_SUCCESS = 'LOAD_HYBRID_CAMPAIGN_SUCCESS'
// export const LOAD_HYBRID_CAMPAIGN_FAILURE = 'LOAD_HYBRID_CAMPAIGN_FAILURE'

const viewController = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_HYBRID_CAMPAIGN_VIEW: {
        return {
            ...state,
            ...action.payload
        }
    }
    case ActionTypes.LOAD_HYBRID_CAMPAIGN: {
        return {
            ...state,
            isLoading: true,
            isLoaded: false,
        }
    }
    case ActionTypes.LOAD_HYBRID_CAMPAIGN_SUCCESS: {
        return {
            ...state,
            isLoading: false,
            isLoaded: true,
        }
    }
    case ActionTypes.LOAD_HYBRID_CAMPAIGN_FAILURE: {
        return {
            ...state,
            isLoading: false,
            isLoaded: false,
        }
    }
    case ActionTypes.INIT_HYBRID_CAMPAIGN_VIEW: {
        return initialState
    }
    default:
      return state
  }
}

export default viewController
