import React from "react";
import "../style.scss";

const SearchSvg = ({
  color = "#CDD1D7",
  width = "24",
  height = "24",
  style = {},
  stroke = 1.5,
  className = "",
  fill = "none",
  selected = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      color={color}
      className={`${className} 
        ${selected ? "qluSvgFillSelected" : ""}`}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9984 19.0004L14.6484 14.6504"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchSvg;
