import React from "react";
import "../style.scss";

const SortSmallSvg = ({
  color = "#CDD1D7",
  width = "16",
  height = "16",
  style = {},
  stroke = 1.5,
  className = "",
  fill = "none",
  selected = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      color={color}
      className={`${className} ${selected ? "qluSvgFillSelected" : ""}`}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
        d="M9.33268 8H2.66602"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
        d="M13.3327 4H2.66602"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
        d="M5.33268 12H2.66602"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SortSmallSvg;
