import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import LabelButton from "../LabelButton";
import SeconadryInput from "../SecondaryInput";
import PlusSvg from "../SVGs/PlusSvg";
import TickSvg from "../SVGs/TickSvg";
import "./style.scss";
const EmailTag = ({
  style,
  found = false,
  selectedTick = false,
  onAdd = (e) => false,
  children,
  canAddEmail = true,
}) => {
  const [inputOpen, setInputOpen] = useState(false);

  const toggle = (e) => {
    e.stopPropagation();
    setInputOpen((prev) => !prev);
  };

  return (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: inputOpen ? "8px" : "0",
        ...style,
      }}
    >
      <div
        className={`emailTagConatiner ${
          found ? "emailTagConatinerFound" : "emailTagConatinerMissing"
        }`}
      >
        {children}
        <CSSTransition
          in={found}
          unmountOnExit={true}
          timeout={300}
          classNames="tagTickFade"
        >
          <TickSvg selected={selectedTick} />
        </CSSTransition>
      </div>
      <CSSTransition
        in={found}
        unmountOnExit={true}
        timeout={300}
        classNames="tagInputFade"
      >
        {inputOpen ? (
          <SeconadryInput
            value={found}
            onCrossClick={toggle}
            onTickClick={(e, value) => {
              toggle(e);
              onAdd(e, value);
            }}
          />
        ) : canAddEmail ? (
          <LabelButton
            style={{ paddingTop: "0", paddingBottom: "0" }}
            onClick={toggle}
          >
            <PlusSvg /> Edit
          </LabelButton>
        ) : (
          <></>
        )}
      </CSSTransition>
      <CSSTransition
        in={!found}
        unmountOnExit={true}
        timeout={300}
        classNames="tagInputFade"
      >
        {inputOpen ? (
          <SeconadryInput
            onCrossClick={toggle}
            onTickClick={(e, value) => {
              toggle(e);
              onAdd(e, value);
            }}
          />
        ) : canAddEmail ? (
          <LabelButton
            style={{ paddingTop: "0", paddingBottom: "0" }}
            onClick={toggle}
          >
            <PlusSvg /> Add
          </LabelButton>
        ) : (
          <></>
        )}
      </CSSTransition>
    </span>
  );
};

export default EmailTag;
