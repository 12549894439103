import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loading from '../../uiLibrary/Loading';
import PrimaryButton from '../../uiLibrary/PrimaryButton';
import PrimaryCheckBox from '../../uiLibrary/PrimaryCheckBox';
import { manualApiCall, transformCsvData } from '../../utils';
import './CsvExport.scss';

const OptionsDialog = ({
  count = 0,
  csvOptionsList,
  setCsvOptionsList,
  isLoading = false,
  onDownloadClick,
}) => {
  const [options, setOptions] = useState(csvOptionsList)
  useEffect(() => {
    return () => {
      setCsvOptionsList(options)
    }
  }, [])

  const onSubmit = () => {
    onDownloadClick()
  }

  const onChangeCheckbox = e => {
    if (isLoading) {
      e.preventDefault()
      return
    }
    const selected = e?.target?.checked;
    const title = e?.target?.name
    setOptions(prev => prev.map(item => {
      if (item.title === title)
        item.selected = selected
      return item
    }))
  }

  return (
    <div className='options'>
      <h2>
        Download prospects
      </h2>
      <p>
        Choose the information you want to download from the prospects:
      </p>
      <form className='options-grid'>
        {
          options.map((item, idx) => (
            <div className='input-group' key={idx}>
              <PrimaryCheckBox
                name={item.title}
                style={{ width: '16px', height: '16px', borderRadius: '2px' }}
                checked={item.selected}
                onChange={onChangeCheckbox} />
              <label htmlFor={item.title}>{item.title}</label>
            </div>
          ))
        }
      </form>
      <div className='options-footer'>
        <p>
          {count} prospect{count > 1 && 's'} selected
        </p>
        <PrimaryButton
          isLoading={isLoading}
          disabled={isLoading}
          onClick={onSubmit}
        >
          Download
        </PrimaryButton>
      </div>
    </div>
  )
}

const CsvExport = ({
  children,
  disable = false,
  selectedProspects,
  prospectsArray,
  jobTitle,
  dispatch,
  jobId,
  csvOptionsList = [],
  setCsvOptionsList = [],
  candidatesData = []
}) => {
  // console.log('SELECTED PROSPECTS IN CSV EXPORT COMPONENT IS: ', selectedProspects)
  const [csvData, setCsvData] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const csvInstance = useRef();
  const [showDownloadOptions, setShowDownloadOptions] = useState(false)
  const ref = useRef()

  const mustHave = [
    "Index",
    "Full Name",
    "Title",
    "Company",
  ]

  useEffect(() => {
    // console.log("===================csvData Mount===================")
  }, []);

  useEffect(() => {
    if (csvData && csvInstance.current && csvInstance.current.link) {
      // console.log("===================csvData===================", {csvData})
      if (csvData) {
        setTimeout(() => {
          csvInstance.current.link.click();
          // console.log("================setCsvData(false);======================")
          // dispatch(downloadProspects(jobId))
          setCsvData(false);
          setIsLoading(false)
        }, 1000);
      }
    }
  }, [csvData]);


  const omitFromArray = (data, values) => {
    if (data && values)
      return data.filter(t => !values.includes(t))
    return []
  }

  const filterDataByOptions = (inputData, options) => {
    const data = []
    inputData.forEach(object => {
      const newObject = {}
      options.forEach(option => {
        switch (option) {
          case "Index":
            newObject["Index"] = object?.index
            break;
          case "Full Name":
            newObject["Full Name"] = object?.Full_Name
            break;
          case "Title":
            newObject["Title"] = object?.Title
            break;
          case "Company":
            newObject["Company"] = object?.Company
            break;
          case "Linkedin URL":
            newObject["Linkedin URL"] = object?.ProfileURL
            break;
          case "Headline":
            newObject["Headline"] = object?.Headline
            break;
          case "All Experience":
            newObject["All Experience"] = object?.Total_Experience
            break;
          case "Relevant Experience":
            newObject["Relevant Experience"] = object?.Relevant_Experience
            break;
          case "Skills":
            newObject["High Importance Skills"] = object?.Must_Haves_Skills
            newObject["Medium Importance Skills"] = object?.Important_Skills
            newObject["Low Importance Skills"] = object?.Nice_To_Haves_Skills
            newObject["Other Skills"] = omitFromArray(object?.Total_Skills,
              [...object?.Important_Skills,
              ...object?.Must_Haves_Skills,
              ...object?.Nice_To_Haves_Skills
              ])
            newObject["All Skills"] = object?.Total_Skills
            break;
          case "Industry":
            newObject["Industry"] = object?.Industry
            break;
          case "Contact Info":
            newObject["Personal Email"] = object?.Personal_Email
            newObject["Work Email"] = object?.Work_Email
            break;
          case `"About" Info`:
            newObject[`About Info`] = object?.Summary
            break;
          case "Profile Scores":
            newObject["Skill Score"] = object?.Skill_Score
            newObject["Education Scores"] = object?.Education_Score
            newObject["Experience Scores"] = object?.Experience_Score
            newObject["Title Scores"] = object?.Title_Score
            newObject["Company Score"] = object?.Company_Score
            newObject["Business Score"] = object?.Specialty_Score
            break;
          case "Demographics":
            newObject["Age"] = object?.Age
            newObject["Gender"] = object?.Gender
            newObject["Ethnicity"] = object?.Ethnicity
            break;
          case "Location":
            newObject["Location"] = object?.Location
            break;

          default:
            break;
        }
      });
      data.push(newObject)
    })
    return data
  }

  const asyncExportMethod = async (options) => {
    try {
      const list = candidatesData?.length > 0 ? candidatesData : prospectsArray
      let selectedProspectsEmails = {}
      const ids = Object.keys(selectedProspects).filter((key) => !(
        ("work_email" in selectedProspects[key] && selectedProspects[key]?.work_email) ||
        ("personal_email" in selectedProspects[key] && selectedProspects[key]?.personal_email)
      ));
      if (ids.length > 0) {
        const { success, data } = await manualApiCall(
          "/api/v2/auth/candidates/emails",
          {
            method: "POST",
            body: JSON.stringify({
              ids: ids.map(id => ({
                id,
                firstName: selectedProspects[id]?.firstName,
                lastName: selectedProspects[id]?.lastName,
                fullName: selectedProspects[id]?.fullName,
                companyName: selectedProspects[id]?.experience?.[0]?.company_name,
                companyDomain: selectedProspects[id]?.experience?.[0]?.companyWebsite,
              })),
            }),
          }
        );

        if (!success) {
          console.ERROR("ERROR OCCURRED -> asyncExportMethod -> ELSE 2 API CALL NOT SUCCESS", { success })
          return []
        }
        if (!data || data.length == 0) {
          console.log("ERROR OCCURRED -> asyncExportMethod -> ELSE NO DATA")
          return []
        }
        const prospectEmails = list.filter(({ id }) => typeof id === 'string' ? !id.includes("temp") : id).map((profile) => {
          return {
            ...profile,
            ...(data.filter(({ id }) => profile.id == id)?.[0] || {}),
            id: profile?.id
          };
        });
        selectedProspectsEmails = prospectEmails.filter(item => item.id in selectedProspects)
      }
      const prospects = list.filter(({ id }) => typeof id === 'string' ? !id.includes("temp") && !!selectedProspects[id] : !!selectedProspects[id]).map(itm => {
        return { ...itm, ...selectedProspectsEmails.filter(t => t.id == itm.id)[0] }
      })

      const data1 = transformCsvData(prospects)
      const data = filterDataByOptions(data1, options)
      return data
    } catch (e) {
      console.error("ERROR OCCURRED -> asyncExportMethod -> e", e)
      return []
    }
  }

  const onDownloadClick = async () => {
    if (disable || isLoading) {
      return;
    }
    setIsLoading(true)
    // setShowDownloadOptions(false)

    const options = [...mustHave, ...csvOptionsList.filter(i => i.selected).map(i => i.title)]

    const newCsvData = await asyncExportMethod(options);
    setCsvData({
      data: newCsvData,
      filename: `${jobTitle}-${moment().format('MM/DD/YYYY')}.csv`
    });
  }


  useEffect(() => {
    /**
     * Close dialog if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDownloadOptions(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      style={{ display: 'inline' }}
      onClick={() => setShowDownloadOptions(true)}
      className="CsvExport"
      ref={ref}
    >
      {csvData &&
        <CSVLink
          filename={csvData.filename}
          data={csvData.data}
          ref={csvInstance}
        />}
      {isLoading ? <Loading /> : children}
      {showDownloadOptions && <OptionsDialog
        count={Object.keys(selectedProspects).length}
        isLoading={isLoading}
        csvOptionsList={csvOptionsList}
        setCsvOptionsList={setCsvOptionsList}
        onDownloadClick={onDownloadClick}
      />}
    </div>

  );
};

export default connect(state => ({
  selectedProspects: state.prospectsView.candidates.selectedProspects,
  prospectsArray: state.prospectsView.candidates.prospects,
  jobTitle: state.prospectsView.candidates.job.jobTitle,
  jobId: state.prospectsView.candidates.job.id,
  firstBucket: state.prospectsView.candidates.firstBucket,
  prospectsView: state.prospectsView,
}))(withRouter(CsvExport));
