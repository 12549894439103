import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import ActionIcon from "../../views/QuickSetup/components/ActionIcon";
import SuperLike from "../SuperLike";
import "./ProfileLikedSelectors.scss";
export default function ProfileLikedSelectors({
  className,
  id,
  title,
  elementIndex,
  index,
  onClick,
  active,
  val,
}) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment key={index}>
      <Button
        className={`profileButtons ${className} ${active ? "active" : ""}`}
        id={`${id}_${elementIndex}`}
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick(val);
        }}
      >
        {title == "Yes" ? (
          <SuperLike
            toggleLike={(likeCount, e) => {
              e.stopPropagation();
              onClick && onClick(val);
            }}
            totalHearts={1}
            likedHearts={Number(active)}
          />
        ) : title == "Maybe" ? (
          <ActionIcon
            hasContainer={false}
            isSelected={active}
            id={index}
            type="isMaybe"
            onClick={(e) => {
              e.stopPropagation();
              onClick && onClick(val);
            }}
          />
        ) : title == "No" ? (
          <ActionIcon
            hasContainer={false}
            isSelected={active}
            id={index}
            type="isDisliked"
            onClick={(e) => {
              e.stopPropagation();
              onClick && onClick(val);
            }}
          />
        ) : null}
      </Button>
      {document.getElementById(`${id}_${elementIndex}`) &&
        <Tooltip
        hideArrow={false}
        placement="bottom"
        isOpen={open}
        target={`${id}_${elementIndex}`}
        toggle={() => setOpen(!open)}
        style={{ zIndex: 9 }}
        className="primaryToolTip"
      >
        {title}
      </Tooltip>}
    </React.Fragment>
  );
}
