import React from 'react'

const Emoji = ({
    style={},
    stroke='2',
    width = "24",
    height = "24",
    className='',
    selected=false
}) => {
  return (
        <svg 
            width={width}
            height={height} 
            className={className}
            viewBox="0 0 20 24" 
            fill='none'
            xmlns="http://www.w3.org/2000/svg"
            style={style}>
                <path d="M17.6809 9.30919C18.7185 11.0081 19.1635 13.0031 18.9463 14.982C18.7291 16.9608 17.8619 18.8118 16.4806 20.2452C15.0992 21.6786 13.2815 22.6136 11.3121 22.9038C9.34267 23.1941 7.33258 22.8231 5.59644 21.8491C3.86029 20.8751 2.49607 19.3529 1.71729 17.5209C0.938502 15.6888 0.789103 13.6503 1.29247 11.7243C1.79584 9.79825 2.92357 8.09347 4.49916 6.87674C6.07476 5.66001 8.00929 5 10 5"
                    className={`qluSvgStroke${selected ? 'Selected' : ''}`}
                    strokeWidth={stroke} strokeLinejoin="round"/>
                <path d="M6 16C6 16 7.5 18 10 18C12.5 18 14 16 14 16" 
                    className={`qluSvgStroke${selected ? 'Selected' : ''}`}
                    strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7 11H7.01" 
                    className={`qluSvgStroke${selected ? 'Selected' : ''}`}
                    strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13 11H13.01" 
                    className={`qluSvgStroke${selected ? 'Selected' : ''}`}
                    strokeWidth={stroke}  strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16 1V7" 
                    className={`qluSvgStroke${selected ? 'Selected' : ''}`} 
                    strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13 4H19" 
                    className={`qluSvgStroke${selected ? 'Selected' : ''}`} 
                    strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
  )
}

export default Emoji
