import React, { useRef } from 'react'
import { withRouter } from "react-router-dom";

import LinkedInLogo from '../../../img/linkedIn-logo-sm.svg'
import GoogleLogo from '../../../img/googleLogo.svg'
import CopyLogo from '../../../img/copy.svg'
import ProfileDisplayImage from '../../../components/ProfileDisplayImage/ProfileDisplayImage'
import './overview.scss'

const moment = require('moment-timezone');

const Overview = ({ history: { push }, user, currentUser, selectedEvent }) => {

    const textArea = useRef(null);

    let attendeeEmails = [];
    if (selectedEvent.attendees) {
        selectedEvent.attendees.map(item => {
            if (!item.self) {
                let attendee = {'name': item.displayName ? item.displayName : "" ,
                                'email': item.email ? item.email : ""}
                attendeeEmails.push(attendee);
            }
        })
    }

    const getDate = (date) => {
        return moment(date).format('ddd, DD MMM');
    }

    const getTime = (date) => {
        return moment(date).format('HH:mm');
    }

    const getEndTime = (date) => {
        return moment(date).format('HH:mm A');
    }

    const getDateDiff = (date1, date2 = new Date()) => {
        if (new Date(date1).getTime() - new Date(date2).getTime() < 0) {
            return "";
        }
        return Math.round(Math.abs(new Date(date2).getTime() - new Date(date1).getTime()) / 36e5) + " hours from now";
    }

    const onCopyToClipboard = () => {
        textArea.current.select();
        document.execCommand("copy")
    }

    const onJoinMeeting = () => {

    }

    return (
        <div className="overview">
            <div className="borderBottom">
                <div className="detailsRow">
                    <div className="tag">When</div>
                    <div>
                        <div>{getDate(selectedEvent.startMeeting)}, {getTime(selectedEvent.startMeeting)} - {getEndTime(selectedEvent.endMeeting)}</div>
                        <div className="subHeading">{getDateDiff(selectedEvent.startMeeting)}</div>
                    </div>
                </div>
                <div className="detailsRow">
                    <div className="tag">Where</div>
                    <div>
                        <div>
                            <img
                                width="20"
                                height="20"
                                src={GoogleLogo}
                                className="linkedInLogo"
                                onClick={onJoinMeeting}
                            />
                            Google Meet
                        </div>
                        {
                            selectedEvent.hangoutLink == null || selectedEvent.hangoutLink == "" ?
                                [] :
                                <div>
                                    <div className="flexDisplay" target='_blank' >
                                        <textarea ref={textArea} value={selectedEvent.hangoutLink} />
                                        <img
                                            width="20"
                                            height="20"
                                            src={CopyLogo}
                                            className="linkedInLogo"
                                            onClick={onCopyToClipboard}
                                        />
                                    </div>
                                </div>
                        }
                    </div>
                </div>

                <div className="detailsRow">
                    <div className="tag">Attendees</div>
                    <div>
                        {
                            selectedEvent.profile ?
                                <div className="flexDisplay">
                                    <ProfileDisplayImage
                                        width="46"
                                        height="46"
                                        src={selectedEvent.profile?.imageUrl}
                                    />
                                    <div>
                                        <div>
                                            {selectedEvent.profile?.full_name}
                                            <a href={selectedEvent.profile?.profileUrl}>
                                                <img
                                                    width="20"
                                                    height="20"
                                                    src={LinkedInLogo}
                                                    className="linkedInLogo"
                                                />
                                            </a></div>
                                        <div className="subHeading">{selectedEvent.profile?.primaryEmail}</div>
                                    </div>
                                </div>
                                :
                                <div className="attendees">
                                    {
                                        attendeeEmails.map((item) => (
                                            <div className="flexDisplay">
                                                <ProfileDisplayImage
                                                    width="46"
                                                    height="46"
                                                    src={""}
                                                />
                                                <div>
                                                    <div>{item.name}</div>
                                                    <div className="subHeading">{item.email}</div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                        }
                        <div className="flexDisplay">
                            <ProfileDisplayImage
                                width="46"
                                height="46"
                                src={currentUser?.imageUrl}
                            />
                            <div>
                                <div>{currentUser?.name}<span className="you">(you)</span></div>
                                <div className="subHeading">{selectedEvent?.organizer.email}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                selectedEvent.profile ?
                    <div className="detailsRow paddingTop">
                        <div className="tag">Job</div>
                        <div>{selectedEvent.profile?.jobTitle}</div>
                        <div className="orangeView" onClick={(e) => push("/jobs/" + selectedEvent.profile?.jobId)}>
                            <span className="globalDotStyleSecondary overviewDotStyle">•</span>
                            View
                        </div>
                    </div> :
                    []
            }
        </div >
    )
}

export default withRouter(Overview)