import { isEmpty } from "lodash";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Button
} from "reactstrap";
import { setPageNumber } from "../../actions/prospectsView";
import DottedVerticalSvg from "../../components/svgComponents/DottedVerticalSvg";
import { useDebouncedEffect } from "../../utils/DebounceUseEffect";
import CaretDown from "../SVGs/CaretDown";
import "./ProspectCategoryMenu.scss";

const ProspectCategoryMenu = ({
  buckets = [],
  currentBucket,
  onClickBucket,
  deleteCount,
  location: { pathname, search },
  dispatch,
  isLoading
}) => {
  const qParams = QueryString.parse(search.split("?").pop());

  const [scrollValues, setScrollValues] = useState(0);
  const [rightVisible, setRightVisible] = useState(false);
  const [show, setShow] = useState({ index: 0, show: false });
  const ref = React.useRef();

  const firstUpdate = React.useRef(true);
  const requestRef = React.useRef();
  useEffect(() => {
    if (qParams?.b != currentBucket) {
      const filteredBucket = buckets.filter(
        (bucket) => bucket.name === qParams?.b
      );
    }
    const categoryContainer = document.getElementsByClassName(
      "categoryContainer"
    );
    categoryContainer[0].addEventListener("scroll", () =>
      setScrollValues(categoryContainer[0].scrollLeft)
    );
    if (categoryContainer[0].scrollWidth > categoryContainer[0].clientWidth) {
      setRightVisible(true);
    }
  }, [buckets.length]);
  const scrollToLeft = () => {
    setRightVisible(true);
    const categoryContainer = document.getElementsByClassName(
      "categoryContainer"
    );
    if (categoryContainer[0].scrollLeft <= 0) return;
    categoryContainer[0].scrollLeft -= 600;
  };

  const scrollToRight = () => {
    const categoryContainer = document.getElementsByClassName(
      "categoryContainer"
    );
    if (
      categoryContainer[0].scrollLeft >=
      categoryContainer[0].scrollWidth - categoryContainer[0].clientWidth
    )
      return setRightVisible(false);
    categoryContainer[0].scrollLeft += 600;
  };
  useDebouncedEffect(
    () => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
      const categoryContainer = document.getElementsByClassName(
        "categoryContainer"
      );
      if (
        categoryContainer[0].scrollWidth != undefined &&
        scrollValues >=
        categoryContainer[0].scrollWidth -
        categoryContainer[0].clientWidth -
        30
      ) {
        setRightVisible(false);
      } else {
        setRightVisible(true);
      }
    },
    [scrollValues],
    100
  );
  return (
    <React.Fragment>
      <Button
        className={`categoryScrollLeft ${scrollValues > 0 ? "visible" : ""}`}
        onClick={scrollToLeft}
      >
        <CaretDown style={{ transform: "rotate(90deg)" }} />
      </Button>
      <div className="categoryContainer">
        {buckets.map((bucket, index) => (
          <div
            className={`categoryMenu ${[bucket?.name, bucket?.id].includes(currentBucket) ? "selected" : ""
              }`}
            style={isLoading && currentBucket !== bucket?.name ? {
              opacity: 0.5,
              pointerEvents: 'none'
            } : {}}
            onClick={() => {
              onClickBucket(bucket);
              dispatch(setPageNumber(0));
            }}
            key={bucket.name || index}
          >
            <div className="header">
              <p className="title">
                {bucket.name} <span className="count">{bucket?.name?.toLowerCase() == 'all titles' ? (bucket?.count - deleteCount) || 0 : bucket.count}</span>
              </p>

              {/* <UncontrolledButtonDropdown>
              <DropdownToggle>
                <DottedVerticalSvg />
              </DropdownToggle>
              <DropdownMenu className="dropdownMenu">
                <DropdownItem onClick={() => {}}>Download</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown> */}
              {false && (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setShow((s) => ({ index: index, show: !s.show }));
                  }}
                  onBlurCapture={() =>
                    setShow((s) => ({ index: index, show: false }))
                  }
                // onBlur={(e) => {
                //   console.log("onBlur", e.target);
                //   setShow((s) => ({ index: index, show: false }));
                // }}
                >
                  <DottedVerticalSvg width="16" height="16" color="#CDD1D7" />
                </span>
              )}
              {show.index == index && show.show && false && (
                <div className="myDropDown">
                  <div className="myDropDown-item" onClick={() => { }}>
                    Download
                  </div>
                </div>
              )}
            </div>
            <div className="horizontalLine"></div>
            {(!isEmpty(bucket?.company_size) || bucket.company_size_min) ?
              <div className="footerParent">
                <div className="footer" style={{ border: 'none' }}>
                  {!isEmpty(bucket?.company_size) && (
                    <p>{`Employee Count ${bucket.company_size
                      .map((item) => `${item.min}-${item.max == '10001' ? '10000+' : item.max}`)
                      .join(", ")}`}</p>
                  )}
                  {isEmpty(bucket.company_size) && bucket.company_size_max && bucket.company_size_min && (
                    <p>{`Employee Count ${bucket.company_size_min}-${bucket.company_size_max == '10001' ? '10000+' : bucket.company_size_max}`}</p>
                  )}
                  {bucket.name.toLowerCase().trim() === "all titles" && (
                    <p>For all Employee Count</p>
                  )}
                </div>
              </div> : null
            }
          </div>
        ))}
      </div>
      <Button
        className={`categoryScrollRight ${rightVisible ? "visible" : ""}`}
        onClick={scrollToRight}
      >
        <CaretDown style={{ transform: "rotate(-90deg)" }} />
      </Button>
    </React.Fragment>
  );
};

export default withRouter(
  connect((state) => ({
    currentTab: state.prospectsView.subDropDownReducer.currentTab,
    deleteCount: state.prospectsView.candidates.deleteCount,
    isLoading: state.prospectsView.candidates.isLoading
  }))(ProspectCategoryMenu)
);
