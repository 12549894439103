import * as ActionTypes from '../../../actions/landingPage/home'

const initialState = {
  data: {},
  isLoading: false,
  isDataAvailable: false
}

const stats = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_STATS:
      return { ...state, isLoading: true }

    case ActionTypes.FETCH_STATS_SUCCESS:
      return { ...state, isLoading: false, isDataAvailable: true, data: action.payload }

    case ActionTypes.FETCH_STATS_FAILURE:
      return initialState
      
    default:
      return state
  }
}

export { stats }
