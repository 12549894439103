import { connect } from 'formik'
import { conforms } from 'lodash'
import React, {useState} from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { setOutboxView } from '../../actions/outbox'
import { GoogleButton } from '../../components/SocialLoginButton'
import CaretDown from '../SVGs/CaretDown'
import './style.scss'
const DropdownComponent = ({
    style,
    selected='placeholder',
    options=[],
    onSelect=e=>false,
    onMouseEnter=e=>false,
    onMouseLeave=e=>false,
    onGoogleClick=e=>false,
    onRecruiterClick=r=>false,
    disabledOn=e=>false
}) => {
    const [open, setOpen] = useState(false)
    const toggle = () => setOpen(prev=>!prev)
    
    const optionToRender = (opt, i=0) => {
        if(disabledOn(opt)){
            return (<p
                        className='disabledOption'
                    >
                        {opt}
                    </p>)
        }
        switch (opt) {
            case 'email permission flow':
                return  (<GoogleButton                            
                            key={`${opt}${i}`}
                            onClick={onGoogleClick}
                        >
                            Link your mailbox for email channel
                        </GoogleButton>) 
            case 'recruiter permission flow':
                return  (<a
                            className='dropdown-item recruiterLoginButton'
                            onClick={e=>{
                                toggle()
                                onRecruiterClick(e)
                            }}
                            target='_blank'
                            href='https://www.linkedin.com/talent/contract-chooser'
                        >
                            Click here to Login to Recruiter
                        </a>)
            default:
                return (<DropdownItem
                            key={`${opt}${i}`}
                            onClick={e=>onSelect(opt, i)}
                        >
                            
                                {opt}
                        </DropdownItem>)
        }
    }

    // console.log("================DropdownComponent======================", {options, selected})

    return (
        <Dropdown
            style={style}
            className='DropdownContainer'
            isOpen={open}
            toggle={toggle}
        >
            <DropdownToggle
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className='DropdownToggle'
            >
                {selected} <CaretDown style={
                    open ? 
                    {
                        transform: 'rotate(180deg)',
                        transition: 'transform 300ms ease-in-out'
                    }   :
                    {
                        transition: 'transform 300ms ease-in-out'
                    }
                } />
            </DropdownToggle>
            <DropdownMenu    
                className='DropdownMenu'
            >
                {options.map((opt, i)=>optionToRender(opt, i))}
            </DropdownMenu>
        </Dropdown>
    )
}

export default DropdownComponent
