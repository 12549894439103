import * as ActionTypes from "../../actions/JobSetup";

const initialState = {
  jobSetup: {
    competitorCompanies: [],
    companyHiringFor: [],
    company_speciality: [],
    likedCompanies: [],
    education: [],
    functions: [],
    ideal_company: [],
    ideal_education: [],
    ideal_experience: [],
    ideal_skill: [],
    ideal_title: [],
    job_location: [],
    job_position: [],
    skill: [],
    skills: [],
    speciality: [],
    suggested_skills: [],
    suggested_titles: [],
    technology_work_experience: [],
    total_experience: [],
    suggested_titles_copy: [],
    ideal_specialities: [],
    incentive_section: "",
    company_section: "",
    similarCompanies: [],
  },
  similarCompaniesLoading: false,
  similarCompaniesLoaded: false,
  isLoaded: false,
  isLoading: false,
  isIncentiveLoading: false,
  isIncentiveLoaded: false,
  newJobAdding: false,
  newJobAdded: false,
  addedJobId: null,
  isReview: "",
  isSpecialtyEdited: false,
  isJobEditSuccess: false,
  isEdited: false,
  generateJD: false,
  jdChatList: [{ text: "What key responsibilities and qualifications would you like to include in the JD? Please include any requirements regarding the title, past titles, responsibilities, and company details or other important information", bot: true, intro: true }],
  isJDChatLoading: false,
  jobDescription: "",
  isEnterBotPressed: false
};

const jobSetup = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_JOBSETUP: {
      return initialState;
    }
    case ActionTypes.SENDING_TO_INCENTIVE: {
      return {
        ...state,
        isIncentiveLoading: true,
        isIncentiveLoaded: false,
      };
    }
    case ActionTypes.SENDING_TO_INCENTIVE_SUCCESS: {
      return {
        ...state,
        isIncentiveLoading: false,
        isIncentiveLoaded: true,
        jobSetup: {
          ...state.jobSetup,
          ...action.payload,
        },
      };
    }
    case ActionTypes.SENDING_TO_INCENTIVE_FAILURE: {
      return {
        ...state,
        isIncentiveLoading: false,
        isIncentiveLoaded: false,
      };
    }
    case ActionTypes.SENDING_TO_NER: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    }
    case ActionTypes.REMOVE_SELECTED_IDEAL_PROFILE: {
      return {
        ...state,
        jobSetup: Object.keys(state.jobSetup).reduce((total, key) => {
          if (["company_speciality", "suggested_titles", "skill"].includes(key)) {
            const entities = state.jobSetup[key].filter((entity) => !entity?.sourceId?.includes(action.payload))
            return {
              ...total,
              [key]: entities,
            };
          }
          return {
            ...total,
            [key]: state.jobSetup?.[key],
          };
        }, {})
      }
    }
    case ActionTypes.SENDING_TO_NER_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        jobSetup: Object.keys(state.jobSetup).reduce((total, key) => {
          if (
            [
              "total_experience",
              "companyHiringFor",
              "job_position",
              "suggested_titles",
              "competitorCompanies",
              "likedCompanies",
              "suggested_titles_copy",
              "skill",
              "ideal_specialities",
              "speciality",
              "technology_work_experience",
              "company_speciality",
              "functions",
              "job_location",
              "job_position",
              "skill",
            ].includes(key) &&
            action.payload?.[key]?.length > 0
          ) {
            return {
              ...total,
              [key]: action.payload?.[key],
            };
          }
          // else if([
          //   // 'suggested_titles',
          // ].includes(key)){
          //   return{
          //     ...total,
          //     [key]: [...state.jobSetup?.[key], ...((action.payload?.[key] || []).filter(({name})=>!state.jobSetup?.[key].map(({name})=>name).includes(name)))]
          //       .map((itm)=>{
          //         if((action.payload?.[key] || []).map(({name})=>name).includes(itm.name)){
          //           return {
          //             ...itm,
          //             selected: action.payload?.[key].filter(({name})=>name===itm.name)?.[0]?.selected
          //           }
          //         }
          //         else{
          //           return itm
          //         }
          //       })
          //   }
          // }
          else if (key in action.payload) {
            return {
              ...total,
              [key]: action.payload?.[key],
            };
          }
          return {
            ...total,
            [key]: state.jobSetup?.[key],
          };
        }, {}),
      };
    }
    case ActionTypes.SENDING_TO_NER_FAILURE: {
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
      };
    }
    case ActionTypes.SETTING_SIMILAR_COMPANIES: {
      return {
        ...state,
        similarCompaniesLoading: true,
        similarCompaniesLoaded: false
      };
    }
    case ActionTypes.SET_SIMILAR_COMPANIES: {
      return {
        ...state,
        similarCompaniesLoading: false,
        similarCompaniesLoaded: true,
        jobSetup: {
          ...state.jobSetup,
          similarCompanies: action.payload,
        }
      };
    }
    case ActionTypes.RESET_SIMILAR_COMPANIES_LOADING: {
      return {
        ...state,
        similarCompaniesLoading: false,
        similarCompaniesLoaded: false
      };
    }
    case ActionTypes.SET_IDEAL_PROFILE_IN_JOB_SETUP: {
      return {
        ...state,
        jobSetup: {
          ...state.jobSetup,
          ...action.payload,
        },
      };
    }
    case ActionTypes.ADD_NEW_JOB: {
      return {
        ...state,
        newJobAdded: false,
        newJobAdding: true,
      };
    }
    case ActionTypes.ADD_NEW_JOB_SUCCESS: {
      return {
        ...state,
        newJobAdded: true,
        newJobAdding: false,
        addedJobId: action.payload.data.id,
      };
    }
    case ActionTypes.ADD_NEW_JOB_FAILURE: {
      return {
        ...state,
        newJobAdded: false,
        newJobAdding: false,
      };
    }
    case ActionTypes.SET_IS_REVIEW: {
      return {
        ...state,
        isReview: action.payload,
      };
    }
    case ActionTypes.SET_IS_EDITED: {
      return {
        ...state,
        isEdited: action.payload,
      };
    }
    case ActionTypes.SET_IS_SPECIALTY_EDITED: {
      return {
        ...state,
        isSpecialtyEdited: action.payload,
      };
    }
    case ActionTypes.SET_IS_JOB_EDIT_SUCCESS: {
      return {
        ...state,
        isJobEditSuccess: action.payload,
      };
    }
    case ActionTypes.SET_GENERATE_JOB_DESCRIPTION: {
      return {
        ...state,
        generateJD: action.payload,
      };
    }
    case ActionTypes.SET_IS_JD_CHAT_GENERATING: {
      return {
        ...state,
        isJDChatLoading: action.payload
      };
    }
    case ActionTypes.SET_JD_CHAT_LIST: {
      return {
        ...state,
        jdChatList: [...state.jdChatList, action.payload],
        isJDChatLoading: false
      };
    }
    case ActionTypes.SET_JOB_DESCRIPTION: {
      console.log("reducer payload", action.payload)
      return {
        ...state,
        jobDescription: action.payload,
      };
    }
    case ActionTypes.SET_ENTER_BOT_PRESSED: {
      console.log("reducer payload", action.payload)
      return {
        ...state,
        isEnterBotPressed: action.payload,
      };
    }
    default:
      return state;
  }
};

export default jobSetup;
