import { CREATE_TRIBE_MEMBER, FETCH_TRIBE_MEMBERS_SUCCESS, GET_MEMBERS_LIST, REVERT_TRIBES_UPDATE, SET_TRIBES_UPDATE } from "../../actions/prospectsView";

const initialState = {
  tribeMembers: [],
  isPermissionsChanged: false,
  suggestionMembersList: [],
  isLoading: false
};

const tribeMembers = (state = initialState, action) => {
  switch (action.type) {

    case CREATE_TRIBE_MEMBER: {
      return { ...state, isLoading: true }
    }

    case FETCH_TRIBE_MEMBERS_SUCCESS: {
      if (action.payload.permissions) {
        action.payload.permissions = action.payload.permissions.filter((item) => item.label != null)
      }
      return { ...state, tribeMembers: action.payload, isLoading: false }
    }

    case SET_TRIBES_UPDATE: {
      state.tribeMembers.tribeMembers = action.payload
      return { ...state, isPermissionsChanged: !state.isPermissionsChanged }
    }

    case REVERT_TRIBES_UPDATE: {
      state.tribeMembers.tribeMembers = action.payload
      return { ...state, isPermissionsChanged: !state.isPermissionsChanged }
    }

    case GET_MEMBERS_LIST: {
      return { ...state, suggestionMembersList: action.payload }
    }

    default:
      return state;
  }
};
export default tribeMembers;
