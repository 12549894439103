import React, { useEffect, useState } from "react";

import CaretDown from "../SVGs/CaretDown";
import "./DumbPagination.scss";

const DumbPagination = ({ end, handleClick, cardPerPage, page = 0, disabled = false, type }) => {

  const totalPages = Math.ceil(end / cardPerPage);

  const [currentPage, setCurrentPage] = useState(page);
  const [arr, setArr] = useState([0]);
  const [leftVisible, setLeftVisible] = useState(false);
  const [rightVisible, setRightVisible] = useState(true);

  const checkPagination = (number) => {
    if (arr[currentPage] < 2) return number <= 4
    if (arr[currentPage] > arr.length - 3) return number >= arr.length - 5
    return number >= arr[currentPage] - 2 && number <= arr[currentPage] + 2
  }
  const handleMainClick = (e, index) => {
    if (index < 0) {
      return;
    }
    if (totalPages === 0) return;
    if (totalPages <= index) {
      return;
    }
    e.preventDefault();

    handleClick(index);
    setCurrentPage(index);
  };
  const handleLeftClick = () => {
    console.log("HANDLE CLICK LEFT: ",)
    if (totalPages === 0) return;
    if (currentPage === 0) return;

    handleClick(currentPage - 1);
    setCurrentPage((c) => c - 1);
  };

  useEffect(() => {
    if (totalPages) {
      const initArr = []
      for (let i = 0; i < totalPages; i++) {
        initArr.push(i)
      }
      setArr(initArr)
    }
  }, [totalPages])

  useEffect(() => {
    setCurrentPage(page)
  }, [type])

  useEffect(() => {
    setCurrentPage(currentPage);
    if (currentPage < 1) {
      setLeftVisible(false);
    } else {
      setLeftVisible(true);
    }

    if (currentPage >= arr.length - 1) {
      setRightVisible(false)
    } else {
      setRightVisible(true)
    }

    window.scrollTo(0, 0);
  }, [currentPage, arr]);

  const handleRightClick = () => {
    if (totalPages === 0) return;
    if (totalPages <= currentPage + 1) return;

    if (currentPage === arr[arr.length - 1]) {
      setLeftVisible(true);

    }
    handleClick(currentPage + 1);
    setCurrentPage((c) => c + 1);
  };
  return (
    <div className="numbers">
      {/* {arr[0] > 4 && ( */} {/* TODO: Hide Logic */}
      <span
        className={`leftPaginate ${leftVisible ? "show" : ""}`}
        disabled={disabled}
        onClick={() => disabled ? false : handleLeftClick()}
        style={{ cursor: totalPages === 0 || disabled ? "no-drop" : "pointer" }}
      >
        <CaretDown height="16" width="16" />
      </span>
      {/* )} */}
      {arr.map((number, index) => (
        checkPagination(number) &&
        <div div
          key={index}
          disabled={disabled}
          onClick={(e) => disabled ? false : handleMainClick(e, number)}
          className={`number ${currentPage === number && "active"}`}
          style={{
            cursor:
              totalPages === 0 || totalPages <= number || disabled ? "no-drop" : "pointer",
          }}
        >
          {number + 1}
        </div>
      ))
      }
      <span
        className={`rightPaginate ${rightVisible ? "show" : ""}`}
        disabled={disabled}
        onClick={() => disabled ? false : handleRightClick()}
        style={{
          cursor:
            totalPages === 0 || totalPages <= currentPage + 1 || disabled ? "no-drop" : "pointer",
        }}
      >
        <CaretDown height="16" width="16" />
      </span>
    </div >
  );
};

export default DumbPagination
