import React, { useEffect, useState } from "react";

import moment from "moment";
import qs from "querystring";
import { connect, useDispatch } from "react-redux";
import { fetchMyJobs, fetchTribeJobs } from "../../../actions/landingPage";
import JobCard from "../../../components/JobCard/JobCard";
import Loading from "../../../components/Loading";
import { PER_PAGE_COUNT } from "../../../constants/api";
import usePrevious from "../../../customHooks/usePrevious";
import DumbPagination from '../../../uiLibrary/Pagination/DumbPagination';
import { getDefaultFilters } from "../../../utils";
import { ActivityTypes, loggingActivity } from "../../../utils/activityLogger";
import Header from "../Header";
import NotificationDropdownPanel from "../Home/NotificationDropdownPanel";

const JobsContainer = ({
  isLoading,
  isDataAvailable,
  data,
  history,
  jobCardCtaText,
}) => {
  const dispatch = useDispatch();
  const onViewJob = ({ id, meta: { isQuick }, jobArray }) => {
    loggingActivity(ActivityTypes.VIEW_JOB, id);
    const { fS, fSO, secS, secSO, hCSR } = getDefaultFilters(jobArray)

    return isQuick ? history.push(
      `/job/${id}/${isQuick ? true : false}?fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&fS=${fS}&fSO=${fSO}&f=a&ac=true${ //&c1=1
      jobCardCtaText.toLowerCase().trim() === "select" ? "&c=true" : ""
      }`) : history.push(
        `/job/${id}/${isQuick ? true : false}?fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&ac=true&fS=${fS}&fSO=${fSO}&secS=${secS}&secSO=${secSO}&f=a&hCSR=${encodeURIComponent(hCSR)}${ //&c1=1
        jobCardCtaText.toLowerCase().trim() === "select" ? "&c=true" : ""
        }`
      );
  };
  return (
    <div>
      {/* <SectionActionBar
        label={headerLabel}
        onPageChange={onPageChange}
        pageCount={data.count ? Math.ceil(parseInt(data.count) / 3) : 0}
        onSearch={onSearch}
      /> */}
      {isLoading && (
        <div className="dflex-cc" style={{ height: "350px" }}>
          <Loading />
        </div>
      )}
      {!isLoading && !isDataAvailable && (
        <div className="dflex-cc" style={{ height: "350px" }}>
          <p>No Jobs Found</p>
        </div>
      )}
      {!isLoading && isDataAvailable && (
        <div
          style={{
            gap: "24px",
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            marginTop: "16px",
            width: "100%",
            // display: 'flex', marginTop: '16px'
          }}
        >
          {data.jobs.map((job, i) => (
            <JobCard
              isQuick={job?.meta?.isQuick}
              key={job.id || i}
              jobTitle={job?.jobTitle}
              jobDescription={job?.jobDescription}
              jobLocationsArray={job.jobArray.job_location.map(
                (item) => item.name
              )}
              companyHiringFor={job?.jobArray?.companyHiringFor?.[0]?.name}
              createdDate={moment(job?.createdAt).format("MMM DD, YYYY")}
              jobCreatorName={job?.createdBy}
              totalProspects={job?.added}
              messagedProspects={job.messageCounts[0]?.messaged}
              repliedProspects={job.messageCounts[0]?.replied}
              // jobStatus={job.jobStatus}
              jobStatus={""}
              // tribeCount={job.tribeCount}
              tribeCount={0}
              onClickViewJob={() => {
                onViewJob(job);
              }}
              ctaText={jobCardCtaText}
              label={job?.meta?.label}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Jobs = ({ dispatch, myJobs, tribeJobs, history, location, polling }) => {
  // console.log("SEARCH IS: ", search)
  const params = qs.parse(location.search.substring(1, location.search.length));
  console.log("PARAMS ARE: ", params);

  const [searchValue, setSearchValue] = useState("");
  const [searchTimeout, setSearchTimout] = useState(null);

  // useEffect(() => {
  //   dispatch(fetchMyJobs())
  //   dispatch(fetchTribeJobs())
  // }, [])

  useEffect(() => {
    if (!params.type) {
      history.push("?type=m");
    }

    return () => {
      clearTimeout(polling);
    };
  }, []);

  useEffect(() => {
    if (params.type === "m") {
      dispatch(fetchMyJobs());
    }
    if (params.type === "t") {
      dispatch(fetchTribeJobs());
    }
  }, [location.search]);

  const prevSearchValue = usePrevious(searchValue);

  useEffect(() => {
    console.log("INSIDE USEEFFECT FOR SEARCH VALUE");
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimout(
      setTimeout(() => {
        // console.log('PREVIOUS AND CURRENT SEARCH VALUES: ', { prevSearschValue, search })
        if (prevSearchValue && prevSearchValue !== location.search) {
          dispatch(
            params.type === "m"
              ? fetchMyJobs(0, searchValue)
              : fetchTribeJobs(0, searchValue)
          );
        }
      }, 500)
    );
  }, [searchValue]);

  const handlePageChange = (type, args) => {
    dispatch(
      type === "m" ? fetchMyJobs(args.selected) : fetchTribeJobs(args.selected)
    );
  };

  const onSearch = (type, searchValue) => {
    dispatch(
      type === "m"
        ? fetchMyJobs(0, searchValue)
        : fetchTribeJobs(0, searchValue)
    );
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleChangePage = (args) => {
    dispatch(
      params.type === "m"
        ? fetchMyJobs(args.selected)
        : fetchTribeJobs(args.selected)
    );
    console.log("args are: ", args);
  };
  const handlePaginate = (page) => {
    dispatch(params.type === "m"
      ? fetchMyJobs(page)
      : fetchTribeJobs(page));
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {!params.c && (
          <Header
            label="Jobs"
            actionText="New Job"
            onClickAction={() => {
              history.push("/jobsetup");
            }}
          />
        )}
        <NotificationDropdownPanel
          historyPush={history.push}
          location={location}
        />
      </div>
      {params.c && (
        <div className="campaignPrompt">
          What job do you want to create a campaign for?
        </div>
      )}

      <div className="jobHeaderTabs">
        <div className="jobtabs">
          <p
            onClick={() => history.push(`?type=m`)}
            className={params.type === "m" ? "active" : ""}
          >
            My Jobs
          </p>
          <p
            onClick={() => history.push(`?type=t`)}
            className={params.type === "t" ? "active" : ""}
          >
            Jobs I'm a tribe member of
          </p>
        </div>
        <div className="searchcontainer">
          {/* <ReactPaginate
            className="sectionPagination"
            pageCount={
              params.type === "m"
                ? Math.ceil(myJobs?.data?.count / 9)
                : Math.ceil(tribeJobs?.data?.count / 9)
            }
            nextLabel=">"
            previousLabel="<"
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            onPageChange={handleChangePage}
            renderOnZeroPageCount={null}
          /> */}

          <DumbPagination
            end={params.type === "m"
              ? myJobs?.data?.count
              : tribeJobs?.data?.count}
            cardPerPage={9}
            handleClick={handlePaginate}
            page={0}
            type={params.type}
            params={params}
          />

          {/* onPageChange={onPageChange}
        pageCount={data.count ? Math.ceil(parseInt(data.count) / 3) : 0} */}

          <input
            placeholder="Search"
            value={searchValue}
            onChange={handleChange}
          />
        </div>
      </div>
      {params.type === "m" && (
        <JobsContainer
          {...myJobs}
          headerLabel="My Jobs"
          history={history}
          onPageChange={(args) => handlePageChange("m", args)}
          onSearch={(value) => onSearch("m", value)}
          jobCardCtaText={params.c ? "Select" : "View Prospects"}
        />
      )}

      {params.type === "t" && (
        <JobsContainer
          {...tribeJobs}
          headerLabel="Tribe Jobs"
          history={history}
          onPageChange={(args) => handlePageChange("t", args)}
          onSearch={(value) => onSearch("t", value)}
          jobCardCtaText={params.c ? "Select" : "View Prospects"}
        />
      )}
    </div>
  );
};

export default connect((state) => ({
  myJobs: state.landingPage.jobs.myJobs,
  tribeJobs: state.landingPage.jobs.tribeJobs,
  polling: state?.notifications?.notifications?.polling,
}))(Jobs);
