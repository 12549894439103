import React from "react";

export const BestFitFAQ1 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            You can search for a best fit profile by their name, keyword or
            Linkedin profile URL
          </li>
          <li>When the profile appears in the dropdown click on it to add </li>
        </ul>
      </p>
    </div>
  );
};

export const BestFitFAQ2 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            A best fit profile is the profile of someone who you think is an
            ideal profile and possesses all the traits you look for in a
            prospect
          </li>
        </ul>
      </p>
    </div>
  );
};

export const BestFitFAQ3 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            It's not mandatory to add a best fit you can skip this section if
            you do not have one
          </li>
        </ul>
      </p>
    </div>
  );
};

export const BestFitFAQ4 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Upto 5</li>
        </ul>
      </p>
    </div>
  );
};
export const BestFitFAQ5 = () => {
  return (
    <div>
      <p>
        It helps in providing the following for you to choose from when setting
        up a job:
        <ul>
          <li>Business, Specialty or Industry</li>
          <li>Additional Job Titles</li>
          <li>Experience</li>
          <li>Skills</li>
        </ul>
      </p>
    </div>
  );
};
export const BestFitFAQ6 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            It could be someone employed in the company you’re hiring for who
            possesses all the qualities required
          </li>
          <li>
            It could be someone in the same field of the job you’re hiring for
            with the required skill set
          </li>
        </ul>
      </p>
    </div>
  );
};
