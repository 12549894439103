import * as ActionTypes from "../../actions/JobSetup";

const initialState = {
  suggestionInput: "",
  suggestions: [],
  memberList: [],
  tribeCards: [],
  isLoadingFavTribes: false,
  isLoadedFavTribes: false,
  isLoadingSuggestions: false,
  isLoadedSuggestions: false,
};

const tribeMembers = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_TRIBE_MEMBERS: {
      return initialState
    }
    case ActionTypes.CHANGE_TRIBE_SUGGESTION_INPUT: {
      return {
        ...state,
        suggestionInput: action.payload,
      };
    }
    case ActionTypes.CHANGE_TRIBE_MEMBER: {
      return {
        ...state,
        tribeCards: state.tribeCards.map(item => item.email===action.payload.key ? ({...item, ...action.payload.values}) : item)
      }
    }
    case ActionTypes.SET_TRIBE_SUGGESTIONS:{
      return {
        ...state,
        suggestions: action.payload,
      }
    }
    case ActionTypes.FETCH_FAV_TRIBE_MEMBERS: {
      return {
        ...state,
        isLoadingFavTribes: true,
        isLoadedFavTribes: false,
      }
    }
    case ActionTypes.SELECT_TRIBE_MEMBER:{
      return {
        ...state,
        tribeCards: [
          { 
            ...action.payload, 
            added: true,
            reachoutPermission: true,
            viewPermission: true, 
          }, ...state.tribeCards]
      }
    }
    case ActionTypes.FETCH_FAV_TRIBE_MEMBERS_SUCCESS: {
      return {
        ...state,
        isLoadingFavTribes: false,
        isLoadedFavTribes: true,
        tribeCards: action.payload?.members?.map(mem => ({
          ...mem,
          added: false,
          reachoutPermission: false,
          viewPermission: false,
        })) || []
      }
    }
    case ActionTypes.FETCH_FAV_TRIBE_MEMBERS_FAILURE: {
      return {
        ...state,
        isLoadingFavTribes: false,
        isLoadedFavTribes: false,
      }
    }
    case ActionTypes.FETCH_TRIBE_MEMBERS_LIST:{
      return {
        ...state,
        isLoadingSuggestions: true,
        isLoadedSuggestions: false,
      }
    }
    case ActionTypes.FETCH_TRIBE_MEMBERS_LIST_SUCCESS:{
      return {
        ...state,
        memberList: action.payload?.data?.map(mem=>({
          ...mem, 
          added: false,
          reachoutPermission: false,
          viewPermission: false,
        })) || [],
        isLoadingSuggestions: false,
        isLoadedSuggestions: true,
      }
    }
    case ActionTypes.FETCH_TRIBE_MEMBERS_LIST_FAILURE:{
      return {
        ...state,
        isLoadingSuggestions: false,
        isLoadedSuggestions: false,
      }

    }
    case ActionTypes.FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_SUCCESS: {
      return {
        ...state,
        suggestionAraay: action.payload,
      };
    }
    case ActionTypes.GET_TRIBE_MEMBERS_CARDS_ON_SELECT: {
      const temp = [action.payload]
      const newTribeMembers = temp.filter((item) => [...state.tribeCards].every((simiMember) => item.tribeEmail !== simiMember.tribeEmail))
      console.log("**********newTribeMembers********", newTribeMembers)
      return {
        ...state,
        tribeCards: [...state.tribeCards, ...newTribeMembers],
      };
    }
    case ActionTypes.DELETE_TRIBE_MEMBER_CARD: {
      return {
        ...state,
        tribeCards: state.tribeCards.filter(
          (item) => item.tribeEmail !== action.payload
        ),
      };
    }
    case ActionTypes.GET_NOT_ADDED_TRIBE_MEMBERS: {
      const temp = [action.payload]
      const newTribeMembers = temp.filter((item) => [...state.tribeCards].every((simiMember) => item.tribeEmail !== simiMember.tribeEmail))
      console.log("********tribeCardsstateeeeeUnadded*****", action.payload);
      return {
        ...state,
        tribeCards: [...state.tribeCards, ...newTribeMembers],
      };
    }
    case ActionTypes.EMPTY_INPUT_VALUE_TRIBE_MEMBER: {
      console.log("nerd helllo kiddo")
      return {
        ...state,
        InputValue: ""
      }
    }
    default:
      return state;
  }
};


export default tribeMembers