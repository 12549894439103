// import { CALL_LINKEDIN_API } from "../middlewares/apiMiddleware";
// import {
//   callLinkedinApi,
//   callCompanyRecommenderApi,
//   getCompanyDetails,
// } from "../utils";
import { get, isEmpty } from "lodash";
import {
  callLiApi,
  extractPublicIdentifier,
  getImgSrcURL,
  manualApiCall,
  prepareIdealProfiles
} from "../utils";
import {
  getCompanyDetails
} from "../utils/visitApiProfile";

import { CALL_API } from "../middlewares/apiMiddleware";
import { visitApiProfileLiNew } from "../utils/visitApiProfileNew";

import { PER_PAGE_COUNT } from "../constants/api";
import { getCalibrationCycle } from "../reducers/QuickSetup/calibrationProfiles";
import { getFeedbackEntityData } from "../reducers/QuickSetup/profileFeedback";

export const TOGGLE_DETAIL_VIEW = "TOGGLE_DETAIL_VIEW";
export const toggleDetailView = (isOpen) => ({
  type: TOGGLE_DETAIL_VIEW,
  isOpen,
});

export const SET_SELECTED_PROFILE = "SET_SELECTED_PROFILE";
export const setSelectedProfile = (profile) => async (dispatch) => {
  await dispatch({
    type: SET_SELECTED_PROFILE,
    profile,
  });
  dispatch(toggleDetailView(true));
};

export const updateCalibrationCycle = (id) => async (dispatch) => {
  const UPDATE_CALIBRATION_CYCLE_ENDPOINT = `/api/v2/quick-search/fetchNext/${id}`;

  try {
    const results = await manualApiCall(UPDATE_CALIBRATION_CYCLE_ENDPOINT, {
      method: "GET",
    });
    dispatch(updateCycle(results.calibrationCycle));
  } catch (error) { }
};

export const findNextCalibrationCycle = (id) => async (dispatch) => {
  const FIND_NEXT_CYCLE_ENDPOINT = `/api/v2/quick-search/fetchNextCycle/${id}`;

  try {
    const { timer } = await manualApiCall(FIND_NEXT_CYCLE_ENDPOINT, {
      method: "GET",
    });

    dispatch({
      type: SET_TIMER_VALUE,
      timer,
    });
  } catch (error) {
    dispatch({
      type: SET_TIMER_VALUE,
      timer: 120,
    });
  }
};

export const SET_TIMER_VALUE = "SET_TIMER_VALUE";

export const CLEAR_CALIBRATION_DATA = "CLEAR_CALIBRATION_DATA";
export const clearCalibrationData = () => ({
  type: CLEAR_CALIBRATION_DATA,
});
export const fetchLatestCC = (id) => async (dispatch) => {
  const FETCH_CALIBRATION_CYCLE_ENDPOINT = `/api/v2/quick-search/getLatestCalibratonCycle?jobId=${id}`;
  try {
    const { cycle } = await manualApiCall(FETCH_CALIBRATION_CYCLE_ENDPOINT, {
      method: "GET",
    });
    dispatch(updateCycle(cycle));
  } catch (error) { }
};

export const UPDATE_CALIBRATION_CYCLE = "UPDATE_CALIBRATION_CYCLE";
export const updateCycle = (cycle) => ({
  type: UPDATE_CALIBRATION_CYCLE,
  calibrationCycle: cycle,
});

export const SET_IS_DISABLE_FEEDBACK = "SET_IS_DISABLE_FEEDBACK";
export const setIsDisableFeedback = (isViewOnly) => ({
  type: SET_IS_DISABLE_FEEDBACK,
  isViewOnly,
});

export const fetchLikedProfiles = (jobID) => async (dispatch) => {
  const FETCH_CALIBRATION_PROFILES_ENDPOINT = `/api/v2/quick-search/get-calibration-profiles/${jobID}?isLiked=true`;
  try {
    dispatch({ type: FETCH_CALIBRATION_PROFILES_REQUEST });
    const results = await manualApiCall(FETCH_CALIBRATION_PROFILES_ENDPOINT, {
      method: "GET",
    });
    dispatch(setCalibrationProfiles(results));
    dispatch(setIsDisableFeedback(true));
  } catch (error) {
    dispatch({ type: FETCH_CALIBRATION_PROFILES_FAILURE, payload: error });
  }
};

export const fetchCalibrationProfiles = (jobID) => async (
  dispatch,
  getState
) => {
  const calibrationCycle = getCalibrationCycle(
    getState().QuickSetup.calibrationProfiles
  );
  const FETCH_CALIBRATION_PROFILES_ENDPOINT = `/api/v2/quick-search/get-calibration-profiles/${jobID}?cC=${calibrationCycle + 1
    }`;
  try {
    dispatch({ type: FETCH_CALIBRATION_PROFILES_REQUEST });
    const results = await manualApiCall(FETCH_CALIBRATION_PROFILES_ENDPOINT, {
      method: "GET",
    });
    dispatch(setCalibrationProfiles(results));
    dispatch(initFeedbackEnity(results));
    dispatch(setIsDisableFeedback(false));
  } catch (error) {
    dispatch({ type: FETCH_CALIBRATION_PROFILES_FAILURE, payload: error });
  }
};

export const fetchCalibrationProfilesV2 = (
  jobID,
  isDeep = true,
  history
) => async (dispatch) => {
  const queryParams = `?type=${isDeep ? "DEEP" : "CALIB"}`;
  const FETCH_CALIBRATION_PROFILES_ENDPOINT = `/api/v2/quick-search/candidates/${jobID}${queryParams}`;
  try {
    dispatch({ type: FETCH_CALIBRATION_PROFILES_REQUEST });

    const {
      data: { profiles, timer },
      shouldContinue = false,
    } = await manualApiCall(FETCH_CALIBRATION_PROFILES_ENDPOINT, {
      method: "GET",
    });

    dispatch(setCalibrationProfiles(profiles, shouldContinue, timer));
    dispatch(setIsDisableFeedback(isDeep));

    if (timer > 0) {
      return dispatch(updateViewPort({ view: "count-down" }));
    }

    if (isDeep) {
      dispatch(clearCalibrationData());
      history.push(
        `/job/${jobID}/true?fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&fS=title_score&fSO=desc&f=a`
      );
      return dispatch(updateViewPort({ view: "setup" }));
    }

    if (!!profiles.length) {
      dispatch(initFeedbackEnity(profiles));
      return dispatch(updateViewPort({ view: "calibration" }));
    }

    if (!profiles.length) {
      dispatch(fetchCalibrationProfilesV2(jobID, true, history));
    }
  } catch (error) {
    dispatch({ type: FETCH_CALIBRATION_PROFILES_FAILURE, payload: error });
  }
};

// export const fetchCalibrationProfilesV2 = (jobID) => async (
//   dispatch,
//   getState
// ) => {
//   const FETCH_CALIBRATION_PROFILES_ENDPOINT = `/api/v2/quick-search/profiles/${jobID}`;
//   try {
//     dispatch({ type: FETCH_CALIBRATION_PROFILES_REQUEST });
//     const { profiles, shouldContinue, timer } = await manualApiCall(
//       FETCH_CALIBRATION_PROFILES_ENDPOINT,
//       {
//         method: "GET",
//       }
//     );
//     dispatch(setCalibrationProfiles(profiles, shouldContinue, timer));
//     dispatch(initFeedbackEnity(profiles));
//     dispatch(setIsDisableFeedback(false));

//     if (timer > 0) {
//       return dispatch(updateViewPort({ view: "count-down" }));
//     }

//     if (!!profiles.length) {
//       return dispatch(updateViewPort({ view: "calibration" }));
//     }
//   } catch (error) {
//     dispatch({ type: FETCH_CALIBRATION_PROFILES_FAILURE, payload: error });
//   }
// };

export const INIT_FEEDBACK_ENTITY = "INIT_FEEDBACK_ENTITY";
export const initFeedbackEnity = (data) => ({
  type: INIT_FEEDBACK_ENTITY,
  data,
});

export const UPDATE_FEEDBACK_ENTITY = "UPDATE_FEEDBACK_ENTITY";
export const updateFeedbackEntity = (profileId, key, value) => async (
  dispatch,
  getState
) => {
  const entities = getFeedbackEntityData(getState().QuickSetup.profileFeedback);

  const selectedProfile = entities?.find(({ id }) => id === profileId);

  const updateEntity = {
    ...selectedProfile,
    data: { ...selectedProfile?.data, [key]: value },
  };

  const updatedEntities = entities?.map(({ id, ...entity }) =>
    profileId === id ? updateEntity : { id, ...entity }
  );

  dispatch({
    type: UPDATE_FEEDBACK_ENTITY,
    data: updatedEntities,
  });

  try {
    const UPDATE_FEEDBACK_ENTITY_ENDPOINT = `/api/v2/quick-search/addFeedback`;
    await manualApiCall(UPDATE_FEEDBACK_ENTITY_ENDPOINT, {
      method: "POST",
      body: JSON.stringify(updateEntity),
    });
  } catch (error) { }
};

export const FETCH_CALIBRATION_PROFILES_SUCCESS =
  "FETCH_CALIBRATION_PROFILES_SUCCESS";
export const FETCH_CALIBRATION_PROFILES_REQUEST =
  "FETCH_CALIBRATION_PROFILES_REQUEST";
export const FETCH_CALIBRATION_PROFILES_FAILURE =
  "FETCH_CALIBRATION_PROFILES_FAILURE";
export const setCalibrationProfiles = (payload, shouldContinue, timer) => ({
  type: FETCH_CALIBRATION_PROFILES_SUCCESS,
  payload,
  shouldContinue,
  timer,
});

export const SET_JOB_SETUP_VIEW = "SET_JOB_SETUP_VIEW";
export const setJobSetupView = (payload) => ({
  type: SET_JOB_SETUP_VIEW,
  payload,
});

export const INIT_JOB_SETUP_VIEW = "INIT_JOB_SETUP_VIEW";
export const initJobSetupView = () => ({
  type: INIT_JOB_SETUP_VIEW,
});

export const CHANGE_JOB_DESCRIPTION = "CHANGE_JOB_DESCRIPTION";
export const changeJobDescription = (payload) => ({
  type: CHANGE_JOB_DESCRIPTION,
  payload,
});

export const CHANGE_JOB_DETAIL_TITLE = "CHANGE_JOB_DETAIL_TITLE";
export const changeJobDetailTitle = (payload) => ({
  type: CHANGE_JOB_DETAIL_TITLE,
  payload,
});

export const CLEAR_JOB_LOCATION_SUGGESTION = "CLEAR_JOB_LOCATION_SUGGESTION";
export const clearJobLocationSuggestions = () => ({
  type: CLEAR_JOB_LOCATION_SUGGESTION,
});

export const SET_JOB_LOCATION = "SET_JOB_LOCATION";
export const setJobLocation = (payload) => ({
  type: SET_JOB_LOCATION,
  payload,
});

export const FETCH_JOB_LOCATIONS = "FETCH_JOB_LOCATIONS";
export const FETCH_JOB_LOCATIONS_SUCCESS = "FETCH_JOB_LOCATIONS_SUCCESS";
export const FETCH_JOB_LOCATIONS_FAILURE = "FETCH_JOB_LOCATIONS_FAILURE";

export const fetchJobLocations = (value) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_JOB_LOCATIONS,
    });

    const resp = await callLiApi(
      `/voyager/api/typeahead/hitsV2?keywords=${value}&origin=OTHER&q=type&queryContext=List(geoVersion-%3E3,bingGeoSubTypeFilters-%3EMARKET_AREA%7CCOUNTRY_REGION%7CADMIN_DIVISION_1%7CCITY)&type=GEO&useCase=GEO_ABBREVIATED`,
      {
        headers: {
          "x-li-lang": "en_US",
          "x-restli-protocol-version": "2.0.0",
        },
      }
    );
    console.log("CALL LI RESP: ", resp);
    const elements = resp.data.elements;

    const payload = Array.isArray(elements)
      ? elements
        .map(({ text, targetUrn }) => ({
          name: text?.text,
          id: targetUrn.split(":").pop(),
        }))
        .slice(0, 5)
      : [];

    dispatch({
      type: FETCH_JOB_LOCATIONS_SUCCESS,
      payload,
    });

    // callLinkedinApi(`/voyager/api/typeahead/hitsV2?keywords=${value}&origin=OTHER&q=type&queryContext=List(geoVersion-%3E3,bingGeoSubTypeFilters-%3EMARKET_AREA%7CCOUNTRY_REGION%7CADMIN_DIVISION_1%7CCITY)&type=GEO&useCase=GEO_ABBREVIATED`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "x-li-lang": "en_US",
    //       "x-restli-protocol-version": "2.0.0",
    //     },
    //   },
    //   ({ elements }) => {
    //     // console.log("=================FETCH_JOB_LOCATIONS_SUCCESS=======================", elements)
    //     const payload = Array.isArray(elements) ?
    //       elements.map(({
    //         text,
    //         targetUrn
    //       }) => ({
    //         name: text?.text,
    //         id: targetUrn.split(':').pop()
    //       })).slice(0, 5) :
    //       []

    //     dispatch({
    //       type: FETCH_JOB_LOCATIONS_SUCCESS,
    //       payload
    //     })
    //   }
    // )
  } catch (err) {
    console.log("Error in fecth location: ", err.message);
    dispatch({
      type: FETCH_JOB_LOCATIONS_FAILURE,
    });
  }
};

export const FETCH_JOB_COMPANY_SUGGESTION = "FETCH_JOB_COMPANY_SUGGESTION";
export const FETCH_JOB_COMPANY_SUGGESTION_SUCCESS =
  "FETCH_JOB_COMPANY_SUGGESTION_SUCCESS";
export const FETCH_JOB_COMPANY_SUGGESTION_FAILURE =
  "FETCH_JOB_COMPANY_SUGGESTION_FAILURE";

export const fetchJobCompanySuggestion = (
  value,
  urlFlag = false,
  url = ""
) => async (dispatch, getState) => {
  dispatch({
    type: FETCH_JOB_COMPANY_SUGGESTION,
  });
  try {
    // const response = await
    const fetchedCompanies = getState().jobSetup.companyDetails.similarCompanies.map(
      ({ universalName }) => universalName
    );
    const companyHiringFor = getState().jobSetup.companyDetails
      .companyHiringFor;

    if (companyHiringFor?.universalName) {
      fetchedCompanies.push(companyHiringFor?.universalName);
    }
    if (urlFlag) {
      dispatch({
        type: FETCH_JOB_COMPANY_SUGGESTION_SUCCESS,
        payload: [
          {
            active: true,
            recommeded: true,
            details: {},
            name: url,
            urn: false,
            entityURN: "",
            industry: "",
            logo: "",
            universalName: value,
            isUrl: true,
          },
        ],
      });
    } else {
      const resp = await callLiApi(
        `/voyager/api/typeahead/hitsV2?keywords=${encodeURIComponent(
          value
        )}&origin=OTHER&q=type&type=COMPANY`,
        {
          headers: {
            "x-li-lang": "en_US",
            "x-restli-protocol-version": "2.0.0",
          },
        }
      );

      const response = resp.data;

      const companySuggestionsArray = response.elements
        .map((item) => ({
          active: true,
          recommeded: true,
          details: {},
          name: item?.text.text ? item.text.text : false,
          urn: item?.dashTargetUrn ? item.dashTargetUrn : false,
          entityURN: item?.objectUrn || "",
          industry: item?.subtext?.text.split("•")[1]
            ? item.subtext.text.split("•")[1].trim()
            : "",
          logo:
            Array.isArray(item?.image?.attributes) &&
              item?.image?.attributes.length > 0 &&
              item?.image?.attributes[0]?.miniCompany?.logo[
                "com.linkedin.common.VectorImage"
              ]?.artifacts &&
              item?.image?.attributes[0]?.miniCompany?.logo[
                "com.linkedin.common.VectorImage"
              ]?.rootUrl &&
              item?.image?.attributes[0]?.miniCompany?.logo[
                "com.linkedin.common.VectorImage"
              ].artifacts.filter((itm) => itm.width > 200).length > 0
              ? `${item?.image?.attributes[0]?.miniCompany?.logo[
                "com.linkedin.common.VectorImage"
              ].rootUrl
              }${item?.image?.attributes[0]?.miniCompany?.logo[
                "com.linkedin.common.VectorImage"
              ].artifacts.filter((itm) => itm.width > 200)[0]
                .fileIdentifyingUrlPathSegment
              }`
              : "",
          universalName:
            Array.isArray(item?.image?.attributes) &&
              item?.image?.attributes.length > 0 &&
              item?.image?.attributes[0]?.miniCompany?.universalName
              ? item?.image?.attributes[0]?.miniCompany?.universalName
              : false,
        }))
        .filter(
          (itm) => itm.name && itm.urn && itm.entityURN && itm.universalName
        )
        .filter((itm) => !fetchedCompanies.includes(itm.universalName))
        .slice(0, 5);

      dispatch({
        type: FETCH_JOB_COMPANY_SUGGESTION_SUCCESS,
        payload: companySuggestionsArray,
      });
    }
  } catch (e) {
    console.log("ERROR: ", e);
    dispatch({
      type: FETCH_JOB_COMPANY_SUGGESTION_FAILURE,
    });
  }
};

export const SELECT_COMPANY = "SELECT_COMPANY";
export const SELECT_COMPANY_SUCCESS = "SELECT_COMPANY_SUCCESS";
export const SELECT_COMPANY_FAILURE = "SELECT_COMPANY_FAILURE";
export const selectHiringCompany = (companyData, initLoading = e => false, finshLoading = e => false) => async (dispatch) => {
  try {
    initLoading()
    dispatch({
      type: SELECT_COMPANY,
    });

    // const univeralName = companyData.univeralName
    console.log("company data: ", companyData)
    let universalName

    if (companyData.universalName.trim()) {
      universalName = companyData.universalName
    } else {
      universalName = get(companyData, ['urn'], '').split(':').pop()
    }

    // const universalName = get(companyData, ['universal'])

    const resp = await getCompanyDetails(universalName);

    if (!isEmpty(resp)) {
      // dispatch(
      //   fetchJobSimilarCompanies(
      //     `urn:li:fsd_company:${resp?.entityUrn?.split(":")?.pop()}`
      //   )
      // );
      dispatch({
        type: SELECT_COMPANY_SUCCESS,
        payload: {
          ...companyData,
          urn: `urn:li:fsd_company:${resp?.entityUrn?.split(":")?.pop()}`,
          logo: companyData.logo || resp.companyLogoUrl,
          industry:
            companyData.industry || resp?.industries?.localizedName || "",
          ...resp,
        },
      });
    } else {
      console.log("Error: company data not available");
      dispatch({
        type: SELECT_COMPANY_FAILURE,
      });
    }
  } catch (err) {
    console.log("Error: ", err.message);
    dispatch({
      type: SELECT_COMPANY_FAILURE,
    });
  }
  finally {
    finshLoading()
  }
};

export const FETCH_COMPANY_STAFF_COUNT = "FETCH_COMPANY_STAFF_COUNT";
export const FETCH_COMPANY_STAFF_COUNT_SUCCESS =
  "FETCH_COMPANY_STAFF_COUNT_SUCCESS";
export const FETCH_COMPANY_STAFF_COUNT_FAILURE =
  "FETCH_COMPANY_STAFF_COUNT_FAILURE";

export const getJobCompanyProfileEmployeeCount = (universalName) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: FETCH_COMPANY_STAFF_COUNT,
    });

    console.log(
      "==================getJobCompanyProfifetchSimilarCompanyDetailsleEmployeeCount===================",
      universalName
    );
    const response = await getCompanyDetails(universalName);
    const res = response.data;
    dispatch({
      type: FETCH_COMPANY_STAFF_COUNT_SUCCESS,
      payload: res,
    });
  } catch (e) {
    dispatch({
      type: FETCH_COMPANY_STAFF_COUNT_FAILURE,
    });
  }
};

export const FETCH_COMPANY_SUGGESTION_PROFILE =
  "FETCH_COMPANY_SUGGESTION_PROFILE";
export const FETCH_COMPANY_SUGGESTION_PROFILE_SUCCESS =
  "FETCH_COMPANY_SUGGESTION_PROFILE_SUCCESS";
export const FETCH_COMPANY_SUGGESTION_PROFILE_FAILURE =
  "FETCH_COMPANY_SUGGESTION_PROFILE_FAILURE";
export const getJobSuggestionCompanyProfile = (universalName) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: FETCH_COMPANY_SUGGESTION_PROFILE,
    });

    // const res = await getCompanyDetails(
    //   encodeURIComponent(universalName),
    //   {}
    // );
    dispatch({
      type: FETCH_COMPANY_SUGGESTION_PROFILE_SUCCESS,
      // payload: res,
    });
  } catch (e) {
    dispatch({
      type: FETCH_COMPANY_SUGGESTION_PROFILE_FAILURE,
    });
  }
};

export const ADD_SELECTED_JOB_LOCATION = "ADD_SELECTED_JOB_LOCATION";
export const addSelectedJobLocation = (payload) => ({
  type: ADD_SELECTED_JOB_LOCATION,
  payload,
});

export const REMOVE_SELECTED_JOB_LOCATION = "REMOVE_SELECTED_JOB_LOCATION";
export const removeSelectedJobLocation = (payload) => ({
  type: REMOVE_SELECTED_JOB_LOCATION,
  payload,
});

export const JOB_COMPANY_PROFILE = "JOB_COMPANY_PROFILE";

export const getJobCompanyProfile = (payload) => ({
  type: JOB_COMPANY_PROFILE,
  payload,
});

export const CHANGE_JOB_COMPANY_NAME = "CHANGE_JOB_COMPANY_NAME";
export const changeJobCompanyName = (value) => ({
  type: CHANGE_JOB_COMPANY_NAME,
  payload: value,
});

export const CLEAR_JOB_COMPANY_SUGGESTION = "CLEAR_JOB_COMPANY_SUGGESTION";
export const clearJobCompanySuggestion = () => ({
  type: CLEAR_JOB_COMPANY_SUGGESTION,
});

export const JOB_COMPANY_PROFILE_DELETE = "JOB_COMPANY_PROFILE_DELETE";
export const companyProfileDelete = () => ({
  type: JOB_COMPANY_PROFILE_DELETE,
});

// export const FETCH_JOB_SIMILAR_COMPANY = "FETCH_JOB_SIMILAR_COMPANY";
// export const FETCH_JOB_SIMILAR_COMPANY_SUCCESS =
//   "FETCH_JOB_SIMILAR_COMPANY_SUCCESS";
// export const FETCH_JOB_SIMILAR_COMPANY_FAILURE =
//   "FETCH_JOB_SIMILAR_COMPANY_FAILURE";

// export const fetchJobSimilarCompanies = (urn, universalName = false) => async (
//   dispatch,
//   getState
// ) => {
//   try {
//     dispatch({
//       type: FETCH_JOB_SIMILAR_COMPANY,
//     });
//     console.log(
//       "==================FETCH_JOB_SIMILAR_COMPANY====================",
//       { urn, universalName }
//     );
//     const res = await callCompanyRecommenderApi(urn);

//     console.log(
//       "==================FETCH_JOB_SIMILAR_COMPANY_Res====================",
//       { urn, universalName, res }
//     );
//     dispatch({
//       type: FETCH_JOB_SIMILAR_COMPANY_SUCCESS,
//       payload: {
//         similarComp: res.filter(({ universalName }) => universalName),
//         universalName,
//       },
//     });
//   } catch (e) {
//     dispatch({
//       type: FETCH_JOB_SIMILAR_COMPANY_FAILURE,
//     });
//   }
// };
// export const FETCH_SIMILAR_COMPANY_DETAILS = "FETCH_SIMILAR_COMPANY_DETAILS";
// export const FETCH_SIMILAR_COMPANY_DETAILS_SUCCESS =
//   "FETCH_SIMILAR_COMPANY_DETAILS_SUCCESS";
// export const FETCH_SIMILAR_COMPANY_DETAILS_FAILURE =
//   "FETCH_SIMILAR_COMPANY_DETAILS_FAILURE";

// export const fetchSimilarCompanyDetails = (universalName) => async (
//   dispatch,
//   getState
// ) => {
//   try {
//     const thisCompany = getState()
//       .jobSetup.companyDetails.similarCompanies.filter(
//         (item) => item.universalName === universalName
//       )
//       .pop();
//     if (thisCompany.detailFetched) {
//       // console.log("=============detailFetched================");
//       return false;
//     }
//     dispatch({
//       type: FETCH_SIMILAR_COMPANY_DETAILS,
//       payload: universalName,
//     });
//     const resp = await getCompanyDetails(universalName);
//     // const res = response.data
//     if (!isEmpty(resp)) {
//       dispatch({
//         type: FETCH_SIMILAR_COMPANY_DETAILS_SUCCESS,
//         payload: { universalName, ...resp },
//       });
//     } else {
//       console.log("Error: fetchSimilarCompanyDetails empty");
//       dispatch({
//         type: FETCH_SIMILAR_COMPANY_DETAILS_FAILURE,
//         payload: universalName,
//       });
//     }
//   } catch (e) {
//     dispatch({
//       type: FETCH_SIMILAR_COMPANY_DETAILS_FAILURE,
//       payload: universalName,
//     });
//   }
// };
// export const ADD_SIMILAR_COMPANY_DETAILS = "ADD_SIMILAR_COMPANY_DETAILS";
// export const ADD_SIMILAR_COMPANY_DETAILS_SUCCESS =
//   "ADD_SIMILAR_COMPANY_DETAILS_SUCCESS";
// export const ADD_SIMILAR_COMPANY_DETAILS_FAILURE =
//   "ADD_SIMILAR_COMPANY_DETAILS_FAILURE";

// export const addSimilarCompanyDetails = (companyData) => async (
//   dispatch,
//   getState
// ) => {
//   try {
//     const thisCompany = {
//       ...companyData,
//       liked: true,
//       detailFetched: true,
//     };
//     dispatch({
//       type: ADD_SIMILAR_COMPANY_DETAILS,
//     });
//     const response = await getCompanyDetails(thisCompany.universalName);
//     // const res = response.data

//     if (!isEmpty(response)) {
//       dispatch({
//         type: ADD_SIMILAR_COMPANY_DETAILS_SUCCESS,
//         payload: { ...thisCompany, ...response },
//       });
//       // dispatch(
//       //   fetchJobSimilarCompanies(thisCompany.urn, thisCompany.universalName)
//       // );
//     } else {
//       console.log("Error: fetchSimilarCompanyDetails empty");
//       dispatch({
//         type: ADD_SIMILAR_COMPANY_DETAILS_FAILURE,
//       });
//     }

//     // if (Array.isArray(res) && res.length > 2) {
//     //   dispatch({
//     //     type: ADD_SIMILAR_COMPANY_DETAILS_SUCCESS,
//     //     payload: { ...thisCompany, ...res?.[1] },
//     //   });
//     //   dispatch(fetchJobSimilarCompanies(thisCompany.urn, thisCompany.universalName))
//     // }
//     // else {
//     //   console.log("Error: fetchSimilarCompanyDetails returned array less than 3")
//     //   dispatch({
//     //     type: ADD_SIMILAR_COMPANY_DETAILS_FAILURE
//     //   })
//     // }

//     // getCompanyDetails(thisCompany.universalName,
//     //   res => {
//     //     // console.log("==================selectHiringCompany===================", res)
//     //     if (Array.isArray(res) && res.length > 2) {
//     //       dispatch({
//     //         type: ADD_SIMILAR_COMPANY_DETAILS_SUCCESS,
//     //         payload: { ...thisCompany, ...res?.[1] },
//     //       });
//     //       dispatch(fetchJobSimilarCompanies(thisCompany.urn, thisCompany.universalName))
//     //     }
//     //     else {
//     //       console.log("Error: fetchSimilarCompanyDetails returned array less than 3")
//     //       dispatch({
//     //         type: ADD_SIMILAR_COMPANY_DETAILS_FAILURE
//     //       })
//     //     }

//     //   }
//     // );
//   } catch (e) {
//     dispatch({
//       type: ADD_SIMILAR_COMPANY_DETAILS_FAILURE,
//     });
//   }
// };

export const CHANGE_JOB_SPECIALITY = "CHANGE_JOB_SPECIALITY";
export const changeJobSpeciality = (payload) => ({
  type: CHANGE_JOB_SPECIALITY,
  payload,
});

export const CHANGE_ADDITIONAL_JOB_SPECIALITY =
  "CHANGE_ADDITIONAL_JOB_SPECIALITY";
export const changeAdditionalJobSpecialty = (payload) => ({
  type: CHANGE_ADDITIONAL_JOB_SPECIALITY,
  payload,
});

export const ADD_ADDITIONAL_JOB_SPECIALITY = "ADD_ADDITIONAL_JOB_SPECIALITY";
export const addAdditionalSpecialty = (payload) => ({
  type: ADD_ADDITIONAL_JOB_SPECIALITY,
  payload,
});

export const INIT_ADDITIONAL_JOB_SPECIALITY = "INIT_ADDITIONAL_JOB_SPECIALITY";
export const initAdditionalSpecialty = () => ({
  type: INIT_ADDITIONAL_JOB_SPECIALITY,
});

export const REMOVE_ADDITIONAL_JOB_SPECIALITY =
  "REMOVE_ADDITIONAL_JOB_SPECIALITY";
export const removeAdditionalSpecialty = (payload) => ({
  type: REMOVE_ADDITIONAL_JOB_SPECIALITY,
  payload,
});

export const CHANGE_ADDITIONAL_JOB_TITLE = "CHANGE_ADDITIONAL_JOB_TITLE";
export const changeAdditionalJobTitle = (payload) => ({
  type: CHANGE_ADDITIONAL_JOB_TITLE,
  payload,
});

export const ADD_ADDITIONAL_JOB_TITLE = "ADD_ADDITIONAL_JOB_TITLE";
export const addAdditionalTitle = (payload) => ({
  type: ADD_ADDITIONAL_JOB_TITLE,
  payload,
});

export const INIT_ADDITIONAL_JOB_TITLE = "INIT_ADDITIONAL_JOB_TITLE";
export const initAdditionalTitle = () => ({
  type: INIT_ADDITIONAL_JOB_TITLE,
});

export const REMOVE_ADDITIONAL_JOB_TITLE = "REMOVE_ADDITIONAL_JOB_TITLE";
export const removeAdditionalTitle = (payload) => ({
  type: REMOVE_ADDITIONAL_JOB_TITLE,
  payload,
});

export const CHANGE_ADDITIONAL_JOB_SKILLS = "CHANGE_ADDITIONAL_JOB_SKILLS";
export const changeAdditionalJobSkills = (payload) => ({
  type: CHANGE_ADDITIONAL_JOB_SKILLS,
  payload,
});

export const ADD_ADDITIONAL_JOB_SKILLS = "ADD_ADDITIONAL_JOB_SKILLS";
export const addAdditionalSkills = (payload) => ({
  type: ADD_ADDITIONAL_JOB_SKILLS,
  payload,
});

export const INIT_ADDITIONAL_JOB_SKILLS = "INIT_ADDITIONAL_JOB_SKILLS";
export const initAdditionalSkills = () => ({
  type: INIT_ADDITIONAL_JOB_SKILLS,
});

export const REMOVE_ADDITIONAL_JOB_SKILLS = "REMOVE_ADDITIONAL_JOB_SKILLS";
export const removeAdditionalSkills = (payload) => ({
  type: REMOVE_ADDITIONAL_JOB_SKILLS,
  payload,
});

export const ADD_JOB_SPECIALITY = "ADD_JOB_SPECIALITY";
export const addJobSpeciality = (payload) => ({
  type: ADD_JOB_SPECIALITY,
  payload,
});

export const CHANGE_JOB_TOTAL_EXPERIENCE = "CHANGE_JOB_TOTAL_EXPERIENCE";
export const changeJobTotalExperience = (payload) => ({
  type: CHANGE_JOB_TOTAL_EXPERIENCE,
  payload,
});

export const REMOVE_JOB_SPECIALITY = "REMOVE_JOB_SPECIALITY";
export const removeJobSpeciality = (payload) => ({
  type: REMOVE_JOB_SPECIALITY,
  payload,
});

export const ADD_JOB_TITLE = "ADD_JOB_TITLE";
export const addJobTitle = (payload) => ({
  type: ADD_JOB_TITLE,
  payload,
});

export const CHANGE_JOB_TITLE = "CHANGE_JOB_TITLE";
export const changeJobTitle = (payload) => ({
  type: CHANGE_JOB_TITLE,
  payload,
});

export const CHANGE_MAIN_JOB_TITLE = "CHANGE_MAIN_JOB_TITLE";
export const changeMainJobTitle = (payload = {}) => ({
  type: CHANGE_MAIN_JOB_TITLE,
  payload,
});

export const REMOVE_JOB_TITLE = "REMOVE_JOB_TITLE";
export const removeJobTitle = (payload) => ({
  type: REMOVE_JOB_TITLE,
  payload,
});
// export const SIMILAR_COMPANY_THUMBS_UP_HANDLER =
//   "SIMILAR_COMPANY_THUMBS_UP_HANDLER";
// export const similarCompanyThumbsUpHandler = (value) => ({
//   type: SIMILAR_COMPANY_THUMBS_UP_HANDLER,
//   payload: value,
// });

// export const SIMILAR_COMPANY_THUMBS_DOWN_HANDLER =
//   "SIMILAR_COMPANY_THUMBS_DOWN_HANDLER";
// export const similarCompanyThumbsDownHandler = (value) => ({
//   type: SIMILAR_COMPANY_THUMBS_DOWN_HANDLER,
//   payload: value,
// });

// export const ADD_TO_SIMILAR_COMPANIES_FROM_LIKED =
//   "ADD_TO_SIMILAR_COMPANIES_FROM_LIKED";
// export const addToSimilarCompaniesFromLiked = (value) => ({
//   type: ADD_TO_SIMILAR_COMPANIES_FROM_LIKED,
//   payload: value,
// });

// export const ADD_TO_SIMILAR_COMPANIES_FROM_UNLIKED =
//   "ADD_TO_SIMILAR_COMPANIES_FROM_UNLIKED";
// export const addToSimilarCompaniesFromUnliked = (value) => ({
//   type: ADD_TO_SIMILAR_COMPANIES_FROM_UNLIKED,
//   payload: value,
// });

// export const ADD_TO_UNLIKED_COMPANIES_FROM_LIKED =
//   "ADD_TO_UNLIKED_COMPANIES_FROM_LIKED";
// export const addToUnlikedCompaniesFromLiked = (value) => ({
//   type: ADD_TO_UNLIKED_COMPANIES_FROM_LIKED,
//   payload: value,
// });

// export const ADD_TO_LIKED_COMPANIES_FROM_UNLIKED =
//   "ADD_TO_LIKED_COMPANIES_FROM_UNLIKED";
// export const addToLikedCompaniesFromunLiked = (value) => ({
//   type: ADD_TO_LIKED_COMPANIES_FROM_UNLIKED,
//   payload: value,
// });

// export const ADD_MORE_JOB_COMPANY_TO_SIMILAR_COMPANIES =
//   "ADD_MORE_JOB_COMPANY_TO_SIMILAR_COMPANIES";
// export const addToMoreJobcompany = (value) => ({
//   type: ADD_MORE_JOB_COMPANY_TO_SIMILAR_COMPANIES,
//   payload: value,
// });
export const CHANGE_JOB_PROFILE_URL = "CHANGE_JOB_PROFILE_URL";
export const changeJobProfileUrl = (payload) => ({
  type: CHANGE_JOB_PROFILE_URL,
  payload,
});

export const CHANGE_JOB_PROFILE_NAME = "CHANGE_JOB_PROFILE_NAME";
export const changeIdealProfileName = (payload) => ({
  type: CHANGE_JOB_PROFILE_NAME,
  payload,
});

export const CLEAR_IDEAL_PROFILE_SUGGESTIONS =
  "CLEAR_IDEAL_PROFILE_SUGGESTIONS";
export const clearIdealProfileSuggestions = () => ({
  type: CLEAR_IDEAL_PROFILE_SUGGESTIONS,
});

export const FETCH_IDEAL_PROFILE_SUGGESTION = "FETCH_IDEAL_PROFILE_SUGGESTION";
export const FETCH_IDEAL_PROFILE_SUGGESTION_FAILURE =
  "FETCH_IDEAL_PROFILE_SUGGESTION_FAILURE";
export const FETCH_IDEAL_PROFILE_SUGGESTION_SUCCESS =
  "FETCH_IDEAL_PROFILE_SUGGESTION_SUCCESS";
export const fetchIdealProfileSuggestions = (name) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: FETCH_IDEAL_PROFILE_SUGGESTION,
    });
    const pubId = extractPublicIdentifier(name);
    const profiles = getState().jobSetup.jobIdealProfile.profiles;
    if (pubId) {
      dispatch({
        type: FETCH_IDEAL_PROFILE_SUGGESTION_SUCCESS,
        payload: [
          {
            name: name,
            headline: pubId,
            url: name,
            urn: "",
            imgSrc: "",
          },
        ],
      });
    } else {
      try {
        const {
          data: { elements },
        } = await callLiApi(
          `/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=GLOBAL_SEARCH_HEADER&q=all&query=(keywords:${encodeURIComponent(
            name.trim()
          )},flagshipSearchIntent:SEARCH_SRP,queryParameters:(resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`,
          {
            headers: {
              "x-li-lang": "en_US",
              "x-restli-protocol-version": "2.0.0",
            },
          },
          true
        );

        const payload = (Array.isArray(elements)
          ? elements
            .filter((elem) => "results" in elem)
            ?.pop()
            ?.results.filter(({ title }) => title?.text !== "LinkedIn Member")
            .map(
              ({
                title,
                primarySubtitle,
                navigationUrl,
                trackingUrn,
                image,
              }) => {
                const vectorImage = image?.attributes?.pop()?.detailData
                  ?.profilePicture?.profilePicture?.displayImageReference
                  ?.vectorImage;
                const imgSrc = getImgSrcURL(
                  vectorImage?.rootUrl,
                  vectorImage?.artifacts
                );
                return {
                  name: title?.text,
                  headline: primarySubtitle?.text?.replace(/at/, "@"),
                  url: navigationUrl,
                  urn: trackingUrn?.split(":")?.pop(),
                  imgSrc,
                };
              }
            )
          : []
        ).filter(
          ({ url }) =>
            !profiles
              .map(({ publicIdentifier }) => publicIdentifier)
              .includes(extractPublicIdentifier(url))
        );

        dispatch({
          type: FETCH_IDEAL_PROFILE_SUGGESTION_SUCCESS,
          payload,
        });
      } catch (e) {
        console.log(
          "Error in FETCH_IDEAL_PROFILE_SUGGESTION call back:",
          e?.message
        );
        throw e;
      }
    }
  } catch (err) {
    console.log("ERROR: ", err?.message);
    dispatch({
      type: FETCH_IDEAL_PROFILE_SUGGESTION_FAILURE,
    });
  }
};

export const SET_IDEAL_PROFILE_VIEW_CONTROLLER =
  "SET_IDEAL_PROFILE_VIEW_CONTROLLER";
export const setIdealProfileViewController = (payload) => ({
  type: SET_IDEAL_PROFILE_VIEW_CONTROLLER,
  payload,
});

export const FETCH_IDEAL_PROFILE_DATA = "FETCH_IDEAL_PROFILE_DATA";
export const FETCH_IDEAL_PROFILE_DATA_SUCCESS =
  "FETCH_IDEAL_PROFILE_DATA_SUCCESS";
export const FETCH_IDEAL_PROFILE_DATA_FAILURE =
  "FETCH_IDEAL_PROFILE_DATA_FAILURE";
export const fetchIdealProfileData = (pubId) => async (dispatch) => {
  try {
    console.log(
      "==============FETCH_IDEAL_PROFILE_DATA_pubId=================",
      pubId
    );

    dispatch({
      type: FETCH_IDEAL_PROFILE_DATA,
    });

    const payload = await visitApiProfileLiNew(pubId);

    dispatch({
      type: FETCH_IDEAL_PROFILE_DATA_SUCCESS,
      payload,
    });

  } catch (err) {
    console.log("ERROR: ", err?.message);
    dispatch({
      type: FETCH_IDEAL_PROFILE_DATA_FAILURE,
    });
  }
};
export const REMOVE_SELECTED_IDEAL_PROFILE = "REMOVE_SELECTED_IDEAL_PROFILE";
export const removeSelectedIdealProfile = (payload) => ({
  type: REMOVE_SELECTED_IDEAL_PROFILE,
  payload,
});

export const ADD_JOB_SKILL = "ADD_JOB_SKILL";
export const OVERWRITE_JOB_SKILL = "OVERWRITE_JOB_SKILL";
export const addJobSkill = (payload, overwrite = false) => ({
  type: overwrite ? OVERWRITE_JOB_SKILL : ADD_JOB_SKILL,
  payload,
});

export const CHANGE_JOB_SKILL = "CHANGE_JOB_SKILL";
export const changeJobSkill = (payload) => ({
  type: CHANGE_JOB_SKILL,
  payload,
});

export const REMOVE_JOB_SKILL = "REMOVE_JOB_SKILL";
export const removeJobSkill = (payload) => ({
  type: REMOVE_JOB_SKILL,
  payload,
});

export const ADD_JOB_FUNCTION = "ADD_JOB_FUNCTION";
export const addJobFunction = (payload) => ({
  type: ADD_JOB_FUNCTION,
  payload,
});

export const CHANGE_JOB_FUNCTION = "CHANGE_JOB_FUNCTION";
export const changeJobFunction = (payload) => ({
  type: CHANGE_JOB_FUNCTION,
  payload,
});

export const REMOVE_JOB_FUNCTION = "REMOVE_JOB_FUNCTION";
export const removeJobFunction = (payload) => ({
  type: REMOVE_JOB_FUNCTION,
  payload,
});

export const ADD_JOB_EDUCATION = "ADD_JOB_EDUCATION";
export const addJobEducation = (payload) => ({
  type: ADD_JOB_EDUCATION,
  payload,
});

export const CHANGE_JOB_EDUCATION = "CHANGE_JOB_EDUCATION";
export const changeJobEducation = (payload) => ({
  type: CHANGE_JOB_EDUCATION,
  payload,
});

export const REMOVE_JOB_EDUCATION = "REMOVE_JOB_EDUCATION";
export const removeJobEducation = (payload) => ({
  type: REMOVE_JOB_EDUCATION,
  payload,
});

export const ADD_LIKED_ATTRIBUTES = "ADD_LIKED_ATTRIBUTES";
export const addLikedAttributes = (payload) => ({
  type: ADD_LIKED_ATTRIBUTES,
  payload,
});

//Tribe Member (New Job)

export const CHANGE_TRIBE_SUGGESTION_INPUT = "CHANGE_TRIBE_SUGGESTION_INPUT";
export const changeSuggestionInput = (value) => ({
  type: CHANGE_TRIBE_SUGGESTION_INPUT,
  payload: value,
});

export const SELECT_TRIBE_MEMBER = "SELECT_TRIBE_MEMBER";
export const selectTribeMember = (payload) => ({
  type: SELECT_TRIBE_MEMBER,
  payload,
});

export const SET_TRIBE_SUGGESTIONS = "SET_TRIBE_SUGGESTIONS";
export const setTribeSuggestions = (payload) => (dispatch, getState) => {
  const addedMember = getState()
    .jobSetup.tribeMembers.tribeCards.filter(({ added }) => added)
    .map(({ userId }) => userId);
  dispatch({
    type: SET_TRIBE_SUGGESTIONS,
    payload: payload.isEmpty
      ? []
      : payload.isEmail
        ? [
          {
            name: payload.inputValue,
            email: payload.inputValue,
            type: "invite",
            added: false,
            reachoutPermission: true,
            viewPermission: true,
          },
        ]
        : getState().jobSetup.tribeMembers.memberList.filter(
          (member) =>
            member.name.toLowerCase().trim().slice(0, payload.inputLength) ===
            payload.inputValue && !addedMember.includes(member.userId)
        ),
  });
};

export const FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS =
  "FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS";
export const FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_SUCCESS =
  "FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_SUCCESS";
export const FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_FAILURE =
  "FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_FAILURE";

export const fetchSuggestionForTribeMembers = (value) => (
  dispatch,
  getstate
) => {
  try {
    const languages = [
      {
        tribeName: "anas chaudhary",
        tribeEmail: "anasbinarif@gmail.com",
      },
      {
        tribeName: "ali raza",
        tribeEmail: "hey126@gmail.com",
      },
    ];
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    dispatch({
      type: FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS,
    });
    const temp =
      inputLength === 0
        ? []
        : languages.filter(
          (lang) =>
            lang.tribeName.toLowerCase().slice(0, inputLength) ===
            inputValue ||
            lang.tribeEmail.toLowerCase().slice(0, inputLength) === inputValue
        );
    console.log("+++++++++++++lan++++++", temp);
    dispatch({
      type: FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_SUCCESS,
      payload: temp,
    });
  } catch (e) {
    dispatch({
      type: FETCH_SUGGESTIONS_FOR_TRIBE_MEMBERS_FAILURE,
    });
  }
};

export const GET_TRIBE_MEMBERS_CARDS_ON_SELECT =
  "GET_TRIBE_MEMBERS_CARDS_ON_SELECT";
export const getTribeMembersCardsOnSelect = (value) => ({
  type: GET_TRIBE_MEMBERS_CARDS_ON_SELECT,
  payload: value,
});

export const DELETE_TRIBE_MEMBER_CARD = "DELETE_TRIBE_MEMBER_CARD";
export const deleteTribeMemberCard = (value) => ({
  type: DELETE_TRIBE_MEMBER_CARD,
  payload: value,
});

export const GET_NOT_ADDED_TRIBE_MEMBERS = "GET_NOT_ADDED_TRIBE_MEMBERS";
export const getNotAddedTribeMember = (value) => (dispatch, getstate) => {
  const temp = { tribeName: "", tribeEmail: value };

  dispatch({
    type: GET_NOT_ADDED_TRIBE_MEMBERS,
    payload: temp,
  });
};

export const EMPTY_INPUT_VALUE_TRIBE_MEMBER = "EMPTY_INPUT_VALUE_TRIBE_MEMBER";
export const emptyInputTribeMember = () => ({
  type: EMPTY_INPUT_VALUE_TRIBE_MEMBER,
});

export const FETCH_FAV_TRIBE_MEMBERS = "FETCH_FAV_TRIBE_MEMBERS";
export const FETCH_FAV_TRIBE_MEMBERS_SUCCESS =
  "FETCH_FAV_TRIBE_MEMBERS_SUCCESS";
export const FETCH_FAV_TRIBE_MEMBERS_FAILURE =
  "FETCH_FAV_TRIBE_MEMBERS_FAILURE";
export const fetchFavTribeMembers = () => ({
  [CALL_API]: {
    types: [
      FETCH_FAV_TRIBE_MEMBERS,
      FETCH_FAV_TRIBE_MEMBERS_SUCCESS,
      FETCH_FAV_TRIBE_MEMBERS_FAILURE,
    ],
    endpoint: `/api/auth/tribes/favourite/tribe`,
    options: {
      method: "GET",
    },
  },
});

export const CHANGE_TRIBE_MEMBER = "CHANGE_TRIBE_MEMBER";
export const changeTribeMember = (payload) => ({
  type: CHANGE_TRIBE_MEMBER,
  payload,
});

export const FETCH_TRIBE_MEMBERS_LIST = "FETCH_TRIBE_MEMBERS_LIST";
export const FETCH_TRIBE_MEMBERS_LIST_SUCCESS =
  "FETCH_TRIBE_MEMBERS_LIST_SUCCESS";
export const FETCH_TRIBE_MEMBERS_LIST_FAILURE =
  "FETCH_TRIBE_MEMBERS_LIST_FAILURE";

export const fetchTribeMembersList = () => ({
  [CALL_API]: {
    types: [
      FETCH_TRIBE_MEMBERS_LIST,
      FETCH_TRIBE_MEMBERS_LIST_SUCCESS,
      FETCH_TRIBE_MEMBERS_LIST_FAILURE,
    ],
    endpoint: `/api/auth/tribes/team/list`,
    options: {
      method: "GET",
    },
  },
});

export const SENDING_TO_INCENTIVE = "SENDING_TO_INCENTIVE";
export const SENDING_TO_INCENTIVE_SUCCESS = "SENDING_TO_INCENTIVE_SUCCESS";
export const SENDING_TO_INCENTIVE_FAILURE = "SENDING_TO_INCENTIVE_FAILURE";

export const getIncentives = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SENDING_TO_INCENTIVE,
    });

    const jobdescription = getState().jobSetup.jobDetails.description;

    const payload = await fetch(
      `${process.env.REACT_APP_NER_INCENTIVE_HOST}/incentive_detection`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jobdescription,
        }),
      }
    ).then((r) => r.json());

    dispatch({
      type: SENDING_TO_INCENTIVE_SUCCESS,
      payload,
    });
  } catch (err) {
    console.log("Error: ", err.message);
    dispatch({
      type: SENDING_TO_INCENTIVE_FAILURE,
    });
  }
};

export const INIT_GENERATED_SPECIALTY = "INIT_GENERATED_SPECIALTY";
export const initGeneratedSpecialty = () => ({
  type: INIT_GENERATED_SPECIALTY,
});

export const SENDING_TO_NER = "SENDING_TO_NER";
export const SENDING_TO_NER_SUCCESS = "SENDING_TO_NER_SUCCESS";
export const SENDING_TO_NER_FAILURE = "SENDING_TO_NER_FAILURE";

export const sendToNer = (callBack, view, viewFallback) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: FETCH_CALIBRATION_PROFILES_REQUEST });
    dispatch({
      type: SENDING_TO_NER,
    });

    dispatch({
      type: SET_JOB_SETUP_VIEW,
      payload: {
        ...view,
      },
    });

    const jobLocation = getState().QuickSetup.jobLocation.selectedLocations;
    const jobdescription = getState().QuickSetup.jobDetails.description;
    const jobTitle = getState().QuickSetup.jobDetails.title;
    const companyHiringFor = getState().QuickSetup.companyDetails
      .companyHiringFor;
    const ideal = getState().QuickSetup.jobIdealProfile?.profiles || [];

    const jobArray = await fetch(
      `${process.env.REACT_APP_QUICK_NER_HOST}/ner_quick_search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal,
          jobdescription,
          jobSetup: {
            companyHiringFor: [companyHiringFor],
            job_position: [{ name: jobTitle }],
          },
        }),
      }
    ).then((r) => r.json());

    // console.log("===============sendToNer===============", payload)

    if (jobArray) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload: jobArray,
      });

      const payload = {
        isQuick: true,
        jdText: jobdescription,
        jobLocation,
        jobTitle,
        jobArray: {
          ...jobArray,
          job_location: jobLocation,
        },
        benchmarkProspects: {
          similar: null,
          ideal,
        },
        oldP2P: process.env.REACT_APP_OLD_P2P === "true",
      };

      const res = await manualApiCall("/api/auth/tribes/store?v2=true", {
        method: "POST",
        body: JSON.stringify(payload),
      });
      if (res.success) {
        dispatch({
          type: ADD_NEW_JOB_SUCCESS,
          payload: res,
        });
        dispatch(initCompanyDetails());
        dispatch(initJobDetails());
        dispatch(initJobIdealProfile());
        dispatch(initJobLocation());
        dispatch(initJobsetup());
        callBack(res.data.id);
      } else {
        throw new Error("new job api failed!");
      }
    } else {
      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          ...view,
        },
      });
      throw new Error("NER returned Empty");
    }
  } catch (err) {
    console.log("Error: ", err.message);
    dispatch({ type: FETCH_CALIBRATION_PROFILES_FAILURE });
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const updateViewPort = (view) => async (dispatch) => {
  dispatch({
    type: SET_JOB_SETUP_VIEW,
    payload: {
      ...view,
    },
  });
};

export const sendJobDescriptionToNer = (view, viewFallback) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    dispatch({
      type: SET_JOB_SETUP_VIEW,
      payload: {
        ...view,
      },
    });

    const job_location = getState().jobSetup.jobLocation.selectedLocations;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const url = process.env.REACT_APP_NER_ADDITIONAL_TITLE_SUGGESTED_TITLES
    fetch(url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal: [],
          jobdescription: "",
          jobSetup,
          isEdit: false,
        }),
      }
    );

    fetch(
      `${process.env.REACT_APP_NER_ADDITIONAL_SPECIALITY_HOST}/similar_specialities`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal: [],
          jobdescription,
          jobSetup,
          isEdit: false,
        }),
      }
    );

    const payload = await fetch(process.env.REACT_APP_NER_HOST_JOB_DESCRIPTION,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jobdescription,
          job_location,
        }),
      }
    ).then((r) => r.json());

    // console.log("===============sendJobDescriptionToNer===============", res)

    if (payload) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });
    } else {
      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          ...viewFallback,
        },
      });
      throw new Error("NER returned Empty");
    }
  } catch (err) {
    console.log("Error: ", err.message);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const TOOGLE_lIKE_ATTRIBUTES = "TOOGLE_lIKE_ATTRIBUTES";
export const toggleLikeAttributes = (state, value, type, key = "value") => (
  dispatch,
  getState
) => {
  const payload = {
    ...getState().jobSetup.jobIdealProfile.likedAttributes,
  };
  if (state) {
    payload[type] = payload[type].filter(
      (attribute) => attribute[key] !== value
    );
  } else {
    const newAttribute = {};
    newAttribute[key] = value;
    payload[type] = [...payload[type], newAttribute];
  }
  dispatch({
    type: TOOGLE_lIKE_ATTRIBUTES,
    payload,
  });
};

export const SET_IDEAL_PROFILES = "SET_IDEAL_PROFILES";

export const sendIdealProfilesToNer = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    // const joblocation = getState().jobSetup.jobLocation.selectedLocations[0].name
    const ideal = getState().jobSetup.jobIdealProfile.profiles;
    const jobSetup = getState().jobSetup.jobSetup.jobSetup;

    const res = await fetch(process.env.REACT_APP_NER_HOST_IDEAL_PROFILE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ideal,
        jobSetup,
        isEdit,
      }),
    }).then((r) => r.json());

    if (Array.isArray(res?.ideal) && (res?.ideal.length > 0 || isEdit)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload: res?.jobSetup || jobSetup,
      });
      dispatch({
        type: SET_IDEAL_PROFILES,
        payload: res?.ideal,
      });
      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err.message);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const SET_IDEAL_PROFILE_IN_JOB_SETUP = "SET_IDEAL_PROFILE_IN_JOB_SETUP";
export const setIdealProfileInJobSetup = (view) => (dispatch, getState) => {
  const ideal_company = getState().jobSetup.jobIdealProfile.likedAttributes.companies.map(
    ({ value }) => value
  );

  const ideal_education = getState().jobSetup.jobIdealProfile.likedAttributes.educations.map(
    (ed) => ed
  );

  const ideal_experience = getState().jobSetup.jobIdealProfile.likedAttributes.experienceSpans.map(
    ({ value }) => parseInt(value)
  );

  const ideal_skill = getState().jobSetup.jobIdealProfile.likedAttributes.skills.map(
    ({ value }) => value
  );

  const ideal_title = getState().jobSetup.jobIdealProfile.likedAttributes.titles.map(
    ({ value }) => value
  );

  const ideal_specialities = getState().jobSetup.jobIdealProfile.likedAttributes.specialities.map(
    ({ value }) => value
  );

  dispatch({
    type: SET_IDEAL_PROFILE_IN_JOB_SETUP,
    payload: {
      ideal_company,
      ideal_education,
      ideal_experience,
      ideal_skill,
      ideal_title,
      ideal_specialities,
    },
  });
  dispatch({
    type: SET_JOB_SETUP_VIEW,
    payload: {
      view,
    },
  });
};

export const setCompaniesInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const { companyDetails } = getState().jobSetup;
    const likedCompanies = [];
    const competitorCompanies = [];
    companyDetails.similarCompanies.map((company) =>
      company.liked
        ? likedCompanies.push(company)
        : competitorCompanies.push(company)
    );

    const { companyHiringFor } = companyDetails;

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const payload = await fetch(process.env.REACT_APP_NER_HOST_SPECIALITY,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal,
          jobdescription,
          jobSetup: {
            ...jobSetup,
            companyHiringFor: [companyHiringFor],
            likedCompanies,
            competitorCompanies,
          },
          isEdit,
        }),
      }
    ).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const setAddtionalSpecialitiesInJobSetup = (isEdit = false) => async (
  dispatch,
  getState
) => {
  const { currentTab, tabs } = getState().jobSetup.viewController;
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const { specialities } = getState().jobSetup.jobSpecialities;

    const company_speciality = Object.keys(specialities)
      .filter((key) => specialities[key].selected)
      .map((key) => ({
        ...specialities[key],
        min: specialities[key].experienceSelected
          ? specialities[key].experience.min
          : 0,
        max: specialities[key].experienceSelected
          ? specialities[key].experience.max
          : 0,
      }));

    const suggestedSpecialty = company_speciality
      .filter(({ source }) => source.includes("generated"))
      .map(({ name }) => name);

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const payload = await fetch(
      `${process.env.REACT_APP_NER_ADDITIONAL_SPECIALITY_HOST}/similar_specialities`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal,
          jobdescription,
          jobSetup: {
            ...jobSetup,
            company_speciality: company_speciality.filter(
              ({ source }) =>
                !(source.length === 1 && source.includes("generated"))
            ),
          },
          isEdit,
        }),
      }
    ).then((r) => r.json());

    let newTabs = tabs;

    if (Object.keys(payload || {}).length > 0) {
      const newSpecialty = (payload?.company_speciality || []).filter(
        ({ name, source = [] }) =>
          source.includes("generated") && !suggestedSpecialty.includes(name)
      );
      if (newSpecialty.length > 0) {
        if (!tabs.includes("Suggested")) {
          if (newTabs.includes("From Best Fits")) {
            newTabs = [
              ...tabs.slice(0, tabs.indexOf("From Best Fits") + 1),
              "Suggested",
              ...tabs.slice(tabs.indexOf("From Best Fits") + 1),
            ];
          } else if (newTabs.includes("From Companies")) {
            newTabs = [
              ...tabs.slice(0, tabs.indexOf("From Companies") + 1),
              "Suggested",
              ...tabs.slice(tabs.indexOf("From Companies") + 1),
            ];
          }
        }

        newSpecialty.map(
          (
            {
              name,
              min,
              max,
              experienceSelected,
              selected,
              source = [],
              sourceId,
              spans,
              synonym = [],
            },
            i
          ) => {
            dispatch(
              addJobSpeciality({
                name,
                spans,
                experienceSelected,
                experience: {
                  min,
                  max,
                },
                selected,
                synonym,
                source,
                sourceId,
              })
            );
          }
        );
        dispatch({
          type: SET_JOB_SETUP_VIEW,
          payload: {
            tabs: newTabs,
            currentTab: currentTab + 1,
          },
        });
      } else if (tabs.includes("Suggested")) {
        dispatch({
          type: SET_JOB_SETUP_VIEW,
          payload: {
            tabs:
              suggestedSpecialty.length > 0
                ? tabs
                : tabs.filter((itm) => itm !== "Suggested"),
            currentTab: currentTab + 1,
          },
        });
      } else {
        dispatch({
          type: SET_JOB_SETUP_VIEW,
          payload: {
            currentTab: currentTab + 1,
          },
        });
      }

      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SET_JOB_SETUP_VIEW,
      payload: {
        currentTab: currentTab + 1,
      },
    });
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const saveAdditionalSpecialties = () => (dispatch, getState) => {
  const {
    specialities,
    additionalSpecialties,
  } = getState().jobSetup.jobSpecialities;
  const specialitiesToFilter = Object.keys(specialities)
    .filter((key) => specialities[key].selected)
    .map((key) => specialities[key].name.trim().toLowerCase());

  for (let index = 0; index < additionalSpecialties.length; index++) {
    const specialty = additionalSpecialties[index];
    if (
      specialty.name.trim() !== "" &&
      !specialitiesToFilter.includes(specialty.name.trim().toLowerCase())
    ) {
      specialitiesToFilter.push(specialty.name.trim().toLowerCase());
      dispatch({
        type: ADD_JOB_SPECIALITY,
        payload: { ...specialty, selected: true },
      });
    }
  }

  dispatch({
    type: INIT_ADDITIONAL_JOB_SPECIALITY,
  });
};

export const setSpecialitiesInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const {
      specialities,
      additionalSpecialties,
    } = getState().jobSetup.jobSpecialities;
    const specialitiesToFilter = [];
    const company_speciality = Object.keys(specialities)
      .filter((key) => specialities[key].selected)
      .map((key) => {
        specialitiesToFilter.push(specialities[key].name.trim().toLowerCase());
        return {
          ...specialities[key],
          min: specialities[key].experienceSelected
            ? specialities[key].experience.min
            : 0,
          max: specialities[key].experienceSelected
            ? specialities[key].experience.max
            : 0,
        };
      });

    for (let index = 0; index < additionalSpecialties.length; index++) {
      const specialty = additionalSpecialties[index];
      if (
        specialty.name.trim() !== "" &&
        !specialitiesToFilter.includes(specialty.name.trim().toLowerCase())
      ) {
        company_speciality.push(specialty);
        specialitiesToFilter.push(specialty.name.trim().toLowerCase());
        dispatch({
          type: ADD_JOB_SPECIALITY,
          payload: { ...specialty, selected: true },
        });
      }
    }

    dispatch({
      type: INIT_ADDITIONAL_JOB_SPECIALITY,
    });

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const payload = await fetch(process.env.REACT_APP_NER_HOST_MAIN_TITLE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal,
          jobdescription,
          jobSetup: {
            ...jobSetup,
            company_speciality,
          },
          isEdit,
        }),
      }
    ).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const setExperienceInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const total_experience = getState().jobSetup.jobCriteria.totalExperience;

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const payload = await fetch(process.env.REACT_APP_NER_HOST_SKILLS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ideal,
        jobdescription,
        jobSetup: {
          ...jobSetup,
          total_experience,
        },
        isEdit,
      }),
    }).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const setMainTitleInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const job_position = [getState().jobSetup.jobCriteria.mainTitle];
    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const url = process.env.REACT_APP_NER_ADDITIONAL_TITLE_SUGGESTED_TITLES

    const payload = await fetch(url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal,
          jobdescription,
          jobSetup: {
            ...jobSetup,
            job_position,
          },
          isEdit,
        }),
      }
    ).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      if (
        isEdit &&
        payload?.job_position?.length > 0 &&
        payload?.job_position?.[0]?.rank
      ) {
        dispatch(
          changeMainJobTitle({
            rank: payload?.job_position?.[0]?.rank,
          })
        );
      }
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const saveAdditionalTitles = () => (dispatch, getState) => {
  const { titles, additionalTitles } = getState().jobSetup.jobCriteria;
  const titlesToFilter =
    Object.keys(titles)
      .filter(
        (key) =>
          titles[key].selected &&
          !titles[key].isMainTitle &&
          titles[key].name?.trim() !== ""
      )
      .map((key) => titles[key].name.trim().toLowerCase()) || [];

  for (let index = 0; index < additionalTitles.length; index++) {
    const ttl = additionalTitles[index];
    if (
      ttl.name.trim() !== "" &&
      !titlesToFilter.includes(ttl.name.trim().toLowerCase())
    ) {
      titlesToFilter.push(ttl.name.trim().toLowerCase());
      console.log("DISPATCHING ADD JOB TITLE")
      dispatch({ type: ADD_JOB_TITLE, payload: { ...ttl, selected: true } });
    }
  }

  dispatch({
    type: INIT_ADDITIONAL_JOB_TITLE,
  });
};

export const setTitlesInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    // const { titles } = getState().jobSetup.jobCriteria;
    // const suggested_titles =
    //   Object.keys(titles)
    //     .filter(
    //       (key) =>
    //         titles[key].selected &&
    //         !titles[key].isMainTitle &&
    //         titles[key].name?.trim() !== ""
    //     )
    //     .map((key) => titles[key]) || [];

    const { titles, additionalTitles } = getState().jobSetup.jobCriteria;
    const titlesToFilter = [];
    const suggested_titles =
      Object.keys(titles)
        .filter(
          (key) =>
            titles[key].selected &&
            !titles[key].isMainTitle &&
            titles[key].name?.trim() !== ""
        )
        .map((key) => {
          titlesToFilter.push(titles[key].name.trim().toLowerCase());
          return titles[key];
        }) || [];

    for (let index = 0; index < additionalTitles.length; index++) {
      const ttl = additionalTitles[index];
      if (
        ttl.name.trim() !== "" &&
        !titlesToFilter.includes(ttl.name.trim().toLowerCase())
      ) {
        suggested_titles.push(ttl);
        titlesToFilter.push(ttl.name.trim().toLowerCase());
        dispatch({ type: ADD_JOB_TITLE, payload: { ...ttl, selected: true } });
      }
    }

    dispatch({
      type: INIT_ADDITIONAL_JOB_TITLE,
    });

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const payload = await fetch(process.env.REACT_APP_NER_HOST_EXPIRENCE,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ideal,
          jobdescription,
          jobSetup: {
            ...jobSetup,
            suggested_titles,
          },
          isEdit,
        }),
      }
    ).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const setFunctionsInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const functionsData = getState().jobSetup.jobCriteria.functions;
    const functions = Object.keys(functionsData)
      .filter((key) => functionsData[key].selected)
      .map((key) => ({
        experienceSelected: functionsData[key].experienceSelected,
        min: functionsData[key].experienceSelected
          ? functionsData[key].experience.min
          : 0,
        max: functionsData[key].experienceSelected
          ? functionsData[key].experience.max
          : 0,
        name: functionsData[key].name,
      }));

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const payload = await fetch(process.env.REACT_APP_NER_HOST_SKILLS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ideal,
        jobdescription,
        jobSetup: {
          ...jobSetup,
          functions,
        },
        isEdit,
      }),
    }).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};

export const saveAdditionalSkills = () => (dispatch, getState) => {
  const skills = getState().jobSetup.jobCriteria.skills;
  const additionalSkills = getState().jobSetup.jobCriteria.additionalSkills;

  const skillsToFilter = Object.keys(skills)
    .filter((key) => skills[key].selected)
    .map((key) => skills[key].name.trim().toLowerCase());

  for (let index = 0; index < additionalSkills.length; index++) {
    const skl = additionalSkills[index];
    if (
      skl.name.trim() !== "" &&
      !skillsToFilter.includes(skl.name.trim().toLowerCase())
    ) {
      skillsToFilter.push(skl.name.trim().toLowerCase());
      dispatch({ type: ADD_JOB_SKILL, payload: { ...skl, selected: true } });
    }
  }

  dispatch({
    type: INIT_ADDITIONAL_JOB_SKILLS,
  });
};

export const setSkillsInJobSetup = (view, isEdit = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: SENDING_TO_NER,
    });

    const importanceMap = {
      Low: "1",
      Medium: "3",
      High: "5",
    };

    const skillsData = getState().jobSetup.jobCriteria.skills;
    const additionalSkillsData = getState().jobSetup.jobCriteria
      .additionalSkills;
    const additionalSkills = additionalSkillsData.map((itm) => ({
      ...itm,
      importance:
        importanceMap[
        Object.keys(itm.importance)
          .filter((k) => itm.importance[k])
          .pop()
        ],
    }));
    const skillsToFilter = [];
    const skills = Object.keys(skillsData)
      .filter((key) => skillsData[key].selected)
      .map((key) => {
        skillsToFilter.push(skillsData[key].name.trim().toLowerCase());
        return {
          ...skillsData[key],
          min: skillsData[key].experienceSelected
            ? skillsData[key].experience.min
            : 0,
          max: skillsData[key].experienceSelected
            ? skillsData[key].experience.max
            : 0,
          importance:
            importanceMap[
            Object.keys(skillsData[key].importance)
              .filter((k) => skillsData[key].importance[k])
              .pop()
            ],
          experienceSelected: skillsData[key].experienceSelected,
        };
      });

    for (let index = 0; index < additionalSkills.length; index++) {
      const skl = additionalSkills[index];
      if (
        skl.name.trim() !== "" &&
        !skillsToFilter.includes(skl.name.trim().toLowerCase())
      ) {
        skills.push(skl);
        skillsToFilter.push(skl.name.trim().toLowerCase());
        dispatch({
          type: ADD_JOB_SKILL,
          payload: {
            ...skl,
            importance: additionalSkillsData[index].importance,
            selected: true,
          },
        });
      }
    }

    dispatch({
      type: INIT_ADDITIONAL_JOB_SKILLS,
    });

    const jobSetup = getState().jobSetup.jobSetup.jobSetup;
    const jobdescription = getState().jobSetup.jobDetails.description;
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    const url = process.env.REACT_APP_NER_HOST_EDUCATION

    const payload = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ideal,
        jobdescription,
        jobSetup: {
          ...jobSetup,
          skills,
        },
        isEdit,
      }),
    }).then((r) => r.json());

    if (!("isSuccess" in payload)) {
      dispatch({
        type: SENDING_TO_NER_SUCCESS,
        payload,
      });

      dispatch({
        type: SET_JOB_SETUP_VIEW,
        payload: {
          view,
        },
      });
    } else {
      throw new Error("NER returned empty array");
    }
  } catch (err) {
    console.log("Error: ", err);
    dispatch({
      type: SENDING_TO_NER_FAILURE,
    });
  }
};
export const SET_IS_JOB_EDIT_SUCCESS = "SET_IS_JOB_EDIT_SUCCESS";
export const _resetIsJobEditSuccess = () => (dispatch) => {
  dispatch({
    type: SET_IS_JOB_EDIT_SUCCESS,
    payload: false,
  });
};
export const setEducationInJobSetup = (view, edit = false, jobId = null) => (
  dispatch,
  getState
) => {
  const importanceMap = {
    Low: "1",
    Medium: "3",
    High: "5",
  };

  const educationsData = getState().jobSetup.jobCriteria.educations;
  const education = Object.keys(educationsData)
    .filter((key) => educationsData[key].selected)
    .map((key) => ({
      ...educationsData[key],
      importance:
        importanceMap[
        Object.keys(educationsData[key].importance)
          .filter((k) => educationsData[key].importance[k])
          .pop()
        ],
    }));

  const jobSetup = getState().jobSetup.jobSetup.jobSetup;

  const payload = {
    ...jobSetup,
    education,
  };

  dispatch({
    type: SENDING_TO_NER_SUCCESS,
    payload,
  });
  console.log("SAVE BUTTON CLICKED", getState().jobSetup.jobSetup.jobSetup);
  if (edit) {
    const specialitiesData = getState().jobSetup.jobSpecialities.specialities;
    const company_speciality = Object.keys(specialitiesData)
      .filter((key) => specialitiesData[key].selected)
      .map((key) => ({
        ...specialitiesData[key],
        min: specialitiesData[key].experienceSelected
          ? specialitiesData[key].experience.min
          : 0,
        max: specialitiesData[key].experienceSelected
          ? specialitiesData[key].experience.max
          : 0,
      }));

    const mainTitle = getState().jobSetup.jobCriteria.mainTitle;
    const titlesData = getState().jobSetup.jobCriteria.titles;

    const job_position = [mainTitle];

    const suggested_titles =
      Object.keys(titlesData)
        .filter(
          (key) =>
            titlesData[key].selected &&
            !titlesData[key].isMainTitle &&
            titlesData[key].name?.trim() !== ""
        )
        .map((key) => titlesData[key]) || [];

    const total_experience = getState().jobSetup.jobCriteria.totalExperience;

    const functionsData = getState().jobSetup.jobCriteria.functions;
    const functions =
      Object.keys(functionsData)
        .filter((key) => functionsData[key].selected)
        .map((key) => ({
          experienceSelected: functionsData[key].experienceSelected,
          min: functionsData[key].experienceSelected
            ? functionsData[key].experience.min
            : 0,
          max: functionsData[key].experienceSelected
            ? functionsData[key].experience.max
            : 0,
          name: functionsData[key].name,
        })) || [];

    const skillsData = getState().jobSetup.jobCriteria.skills;
    const skills =
      Object.keys(skillsData)
        .filter((key) => skillsData[key].selected)
        .map((key) => ({
          ...skillsData[key],
          min: skillsData[key].experienceSelected
            ? skillsData[key].experience.min
            : 0,
          max: skillsData[key].experienceSelected
            ? skillsData[key].experience.max
            : 0,
          importance:
            importanceMap[
            Object.keys(skillsData[key].importance)
              .filter((k) => skillsData[key].importance[k])
              .pop()
            ],
          experienceSelected: skillsData[key].experienceSelected,
        })) || [];

    const jobArray = {
      competitorCompanies: [],
      companyHiringFor: [],
      company_speciality: [],
      likedCompanies: [],
      education: [],
      functions: [],
      ideal_company: [],
      ideal_education: [],
      ideal_experience: [],
      ideal_skill: [],
      ideal_title: [],
      job_location: [],
      job_position: [],
      skill: [],
      skills: [],
      speciality: [],
      suggested_skills: [],
      suggested_titles: [],
      technology_work_experience: [],
      total_experience: [],
      suggested_titles_copy: [],
      ideal_specialities: [],
      incentive_section: "",
      company_section: "",
      ...getState().jobSetup.jobSetup.jobSetup,
      company_speciality,
      job_position,
      suggested_titles,
      total_experience,
      functions,
      skills,
      education,
      job_location: getState().jobSetup.jobLocation.selectedLocations,
    };

    // console.log("=================Edit Job Array=========================", jobArray)
    const ideal = getState().jobSetup.jobIdealProfile.profiles;

    manualApiCall(`/api/v2/auth/jobs/${jobId}/edit`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        jobSetup: jobArray,
        benchmarkProspects: {
          similar: null,
          ideal,
        },
      }),
    })
      .then((r) => {
        console.log("===========Edit Job Response============", r);
        dispatch({
          type: SET_IS_JOB_EDIT_SUCCESS,
          payload: true,
        });
      })
      .catch((err) => {
        console.log("===========Edit Job Error============", err);
        dispatch({
          type: SET_IS_JOB_EDIT_SUCCESS,
          payload: false,
        });
      });
  }
  if (!edit) {
    dispatch({
      type: SET_JOB_SETUP_VIEW,
      payload: {
        view,
      },
    });
  }
};

export const INITIALISING_JOB_TITLES = "INITIALISING_JOB_TITLES";
export const INITIALISING_JOB_TITLES_SUCCESS =
  "INITIALISING_JOB_TITLES_SUCCESS";
export const INITIALISING_JOB_TITLES_FAILURE =
  "INITIALISING_JOB_TITLES_FAILURE";
// export const ADD_MAIN_JOB_TITLE = "ADD_MAIN_JOB_TITLE"
export const initialiseTitles = () => async (dispatch, getState) => {
  try {
    const companySizeOptions = [
      0,
      1,
      10,
      50,
      200,
      500,
      1000,
      5000,
      10000,
      10001,
    ];

    dispatch({ type: INITIALISING_JOB_TITLES });
    const locations = getState().jobSetup.jobLocation.selectedLocations;
    const titles = [
      ...getState().jobSetup.jobSetup.jobSetup.job_position.map((item) => ({
        ...item,
        isMainTitle: true,
      })),
    ];

    const mainTitle = getState().jobSetup.jobCriteria.mainTitle;
    // console.log(
    //   "===================jobCriteriaTitles==============================",
    //   jobCriteriaTitles
    // );
    // console.log(
    //   "===================titles==============================",
    //   titles
    // );
    // const mainTitles = Object.keys(jobCriteriaTitles)
    //   .filter((key) => jobCriteriaTitles[key].isMainTitle)
    //   .map((key) => jobCriteriaTitles[key]);
    let payload = {
      name: "",
      company_size: [
        {
          min: 1,
          max: 10001,
        },
      ],
      spans: [],
      synonym: [],
      results: 0,
      selected: true,
      initial: false,
      rank: 3,
    };

    if (mainTitle?.initial && titles?.length > 0) {
      const {
        name,
        company_size_max,
        company_size_min,
        spans,
        synonym,
        isMainTitle = false,
      } = titles?.[0];
      const min =
        company_size_min >= 10000
          ? 10000
          : companySizeOptions.includes(company_size_min)
            ? companySizeOptions.filter((n) => company_size_min === n)?.[0]
            : 0;

      const company_size =
        titles?.[0]?.company_size?.length > 0
          ? titles?.[0]?.company_size
          : [
            {
              min,
              max:
                company_size_max > 10000
                  ? 10001
                  : companySizeOptions.includes(company_size_max)
                    ? companySizeOptions.filter(
                      (n) => company_size_max === n
                    )?.[0]
                    : companySizeOptions.includes(company_size_min)
                      ? companySizeOptions[companySizeOptions.indexOf(min) + 1]
                      : 10001,
            },
          ];

      // USCode: 103644278

      // const resInSelectedLocation = name?.length>0 ? await callLiApi(`/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:${encodeURIComponent(name)},flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(${locations.map(({ id }) => id).join(',')}),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`, {
      //   headers: {
      //     "x-li-lang": "en_US",
      //     "x-restli-protocol-version": "2.0.0",
      //   }
      // }).then(r => r.data) : undefined

      // const results = resInSelectedLocation?.metadata?.totalResultCount || 0

      payload = {
        rank: 3,
        ...titles?.[0],
        name,
        company_size,
        spans,
        synonym,
        results: 0,
        selected: true,
        isMainTitle,
        isPredicting: false,
        initial: false,
      };
    } else if (!mainTitle?.initial && mainTitle?.name?.length > 0) {
      dispatch({
        type: INITIALISING_JOB_TITLES_SUCCESS,
      });
      return true;
    }
    dispatch({
      type: CHANGE_MAIN_JOB_TITLE,
      payload,
    });
    dispatch({
      type: INITIALISING_JOB_TITLES_SUCCESS,
    });
  } catch (err) {
    console.log("ERROR: ", err.message);
    dispatch({ type: INITIALISING_JOB_TITLES_FAILURE });
  }
};

export const PREDICT_JOB_TITLE_RESULT = "PREDICT_JOB_TITLE_RESULT";
export const PREDICT_JOB_TITLE_RESULT_SUCCESS =
  "PREDICT_JOB_TITLE_RESULT_SUCCESS";
export const PREDICT_JOB_TITLE_RESULT_FAILURE =
  "PREDICT_JOB_TITLE_RESULT_FAILURE";
export const predictTitleResult = (key) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PREDICT_JOB_TITLE_RESULT,
      payload: {
        key,
      },
    });

    const locations = getState().jobSetup.jobLocation.selectedLocations;
    const title = getState().jobSetup.jobCriteria.titles[key];

    const companySize = title?.companySize;
    const name = title?.name;

    const resInSelectedLocation = await callLiApi(
      `/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:${encodeURIComponent(
        name
      )},flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(${locations
        .map(({ id }) => id)
        .join(
          ","
        )}),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`,
      {
        headers: {
          "x-li-lang": "en_US",
          "x-restli-protocol-version": "2.0.0",
        },
      }
    ).then((r) => r.data);

    const resInUS = await callLiApi(
      `/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:${encodeURIComponent(
        name
      )},flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(103644278),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`,
      {
        headers: {
          "x-li-lang": "en_US",
          "x-restli-protocol-version": "2.0.0",
        },
      }
    ).then((r) => r.data);

    // const resInUS = await callLiApi(`/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:${encodeURIComponent(name)},flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(103644278),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`, {
    //   headers: {
    //     "x-li-lang": "en_US",
    //     "x-restli-protocol-version": "2.0.0",
    //   }
    // }).then(r => r.data)

    // const resWithCompanySize = await manualApiCall('/api/v2/auth/jobs/title/results', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     title: name,
    //     companySize,
    //     locations: locations.map(({ name }) => name)
    //   })
    // })

    // let resultsWithCompanySize = 0
    // if(resWithCompanySize.success){
    //   resultsWithCompanySize = resWithCompanySize?.data?.withCompanySize || 0
    // }

    const resultsInSelectedLocation =
      resInSelectedLocation?.metadata?.totalResultCount || 0;
    // const resultsInUS = resInUS?.metadata?.totalResultCount || 0

    const results = resultsInSelectedLocation;
    // titleHealthPrediction(resultsInSelectedLocation, resultsInUS, resultsWithCompanySize)

    const payload = {
      key,
      values: {
        results,
        // resultsMessage: results?.message,
        // resultsHealth: results?.health,
      },
    };

    // console.log("======================predictTitleResult=============================", {
    //   payload,
    //   resultsInSelectedLocation,
    //   resultsInUS,
    //   resultsWithCompanySize
    // })

    dispatch({
      type: PREDICT_JOB_TITLE_RESULT_SUCCESS,
      payload,
    });
  } catch (err) {
    console.log("ERROR: ", err.message);
    dispatch({
      type: PREDICT_JOB_TITLE_RESULT_FAILURE,
      payload: {
        key,
      },
    });
  }
};

export const initTitleResult = () => ({
  type: PREDICT_JOB_TITLE_RESULT_FAILURE,
  payload: 0,
});

export const updateJobTitleResults = (titlePayload) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PREDICT_JOB_TITLE_RESULT,
    });

    const locations = getState().jobSetup.jobLocation.selectedLocations;

    const { metadata = {} } = await callLiApi(
      `/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:"${encodeURIComponent(
        titlePayload.name
      )}",flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(${locations
        .map(({ id }) => id)
        .join(
          ","
        )}),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`,
      {
        headers: {
          "x-li-lang": "en_US",
          "x-restli-protocol-version": "2.0.0",
        },
      }
    ).then((r) => r.data);
    // console.log("============metadata=================", metadata);
    if ("totalResultCount" in metadata) {
      const { totalResultCount } = metadata;

      const payload = totalResultCount;

      dispatch({
        type: PREDICT_JOB_TITLE_RESULT_SUCCESS,
        payload,
      });
      // resolve()
      return true;
    }
    dispatch({
      type: PREDICT_JOB_TITLE_RESULT_SUCCESS,
      payload: 0,
    });
  } catch (err) {
    console.log("ERROR: ", err.message);
    dispatch({
      type: PREDICT_JOB_TITLE_RESULT_FAILURE,
      payload: 0,
    });
  }
};

export const FETCH_TITLE_RESULT = "FETCH_TITLE_RESULT";
export const FETCH_TITLE_RESULT_SUCCESS = "FETCH_TITLE_RESULT_SUCCESS";
export const FETCH_TITLE_RESULT_FAILURE = "FETCH_TITLE_RESULT_FAILURE";

export const fetchTitleResult = (key) => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_TITLE_RESULT });
    const locations = getState().jobSetup.jobLocation.selectedLocations;
    const { name } = getState().jobSetup.jobCriteria.titles[key];

    const { metadata } = await callLiApi(
      `/voyager/api/search/dash/clusters?decorationId=com.linkedin.voyager.dash.deco.search.SearchClusterCollection-122&origin=FACETED_SEARCH&q=all&query=(keywords:"${encodeURIComponent(
        name
      )}",flagshipSearchIntent:SEARCH_SRP,queryParameters:(geoUrn:List(${locations
        .map(({ id }) => id)
        .join(
          ","
        )}),resultType:List(PEOPLE)),includeFiltersInResponse:false)&start=0`,
      {
        headers: {
          "x-li-lang": "en_US",
          "x-restli-protocol-version": "2.0.0",
        },
      }
    ).then((r) => r.data);

    const value = {
      results: "totalResultCount" in metadata ? metadata.totalResultCount : 0,
    };
    dispatch({
      type: CHANGE_JOB_TITLE,
      payload: {
        key,
        value,
      },
    });

    dispatch({
      type: FETCH_TITLE_RESULT_SUCCESS,
    });
  } catch (err) {
    console.log("ERROR: ", err.message);
    dispatch({ type: FETCH_TITLE_RESULT_FAILURE });
  }
};

export const SET_SHOULD_SELECT_LAST_TAB = "SET_SHOULD_SELECT_LAST_TAB";
export const setShouldSelectLastTab = (payload = false) => ({
  type: SET_SHOULD_SELECT_LAST_TAB,
  payload,
});

export const ADD_NEW_JOB = "ADD_NEW_JOB";
export const ADD_NEW_JOB_SUCCESS = "ADD_NEW_JOB_SUCCESS";
export const ADD_NEW_JOB_FAILURE = "ADD_NEW_JOB_FAILURE";
export const addNewJob = (callBack, isDuplicate = false) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: ADD_NEW_JOB,
    });

    const toBeInvited = [];
    const tribeData = [];
    getState().jobSetup.tribeMembers.tribeCards.map(
      ({ added, type, email, userId, reachoutPermission, viewPermission }) => {
        if (added) {
          const permissions = [];
          if (reachoutPermission) {
            permissions.push(2);
          }
          if (viewPermission) {
            permissions.push(3);
          }
          if (type === "invite") {
            toBeInvited.push({
              email,
              permissions,
            });
          } else {
            tribeData.push({
              userId,
              permissions,
            });
          }
        }
      }
    );

    let ideal;
    let jdText;
    let jobLocation;
    let jobArray;
    const importanceMap = {
      Low: "1",
      Medium: "3",
      High: "5",
    };

    const educationsData = getState().jobSetup.jobCriteria.educations;
    const education = Object.keys(educationsData)
      .filter((key) => educationsData[key].selected)
      .map((key) => ({
        ...educationsData[key],
        importance:
          importanceMap[
          Object.keys(educationsData[key].importance)
            .filter((k) => educationsData[key].importance[k])
            .pop()
          ],
      }));

    if (isDuplicate === true) {
      const specialitiesData = getState().jobSetup.jobSpecialities.specialities;
      const company_speciality = Object.keys(specialitiesData)
        .filter((key) => specialitiesData[key].selected)
        .map((key) => ({
          ...specialitiesData[key],
          min: specialitiesData[key].experienceSelected
            ? specialitiesData[key].experience.min
            : 0,
          max: specialitiesData[key].experienceSelected
            ? specialitiesData[key].experience.max
            : 0,
        }));

      const mainTitle = getState().jobSetup.jobCriteria.mainTitle;
      const titlesData = getState().jobSetup.jobCriteria.titles;

      const job_position = [mainTitle];

      const suggested_titles =
        Object.keys(titlesData)
          .filter(
            (key) =>
              titlesData[key].selected &&
              !titlesData[key].isMainTitle &&
              titlesData[key].name?.trim() !== ""
          )
          .map((key) => titlesData[key]) || [];

      const total_experience = getState().jobSetup.jobCriteria.totalExperience;

      const functionsData = getState().jobSetup.jobCriteria.functions;
      const functions =
        Object.keys(functionsData)
          .filter((key) => functionsData[key].selected)
          .map((key) => ({
            experienceSelected: functionsData[key].experienceSelected,
            min: functionsData[key].experienceSelected
              ? functionsData[key].experience.min
              : 0,
            max: functionsData[key].experienceSelected
              ? functionsData[key].experience.max
              : 0,
            name: functionsData[key].name,
          })) || [];

      const skillsData = getState().jobSetup.jobCriteria.skills;
      const skills =
        Object.keys(skillsData)
          .filter((key) => skillsData[key].selected)
          .map((key) => ({
            ...skillsData[key],
            min: skillsData[key].experienceSelected
              ? skillsData[key].experience.min
              : 0,
            max: skillsData[key].experienceSelected
              ? skillsData[key].experience.max
              : 0,
            importance:
              importanceMap[
              Object.keys(skillsData[key].importance)
                .filter((k) => skillsData[key].importance[k])
                .pop()
              ],
            experienceSelected: skillsData[key].experienceSelected,
          })) || [];

      jobArray = {
        competitorCompanies: [],
        companyHiringFor: [],
        company_speciality: [],
        likedCompanies: [],
        education: [],
        functions: [],
        ideal_company: [],
        ideal_education: [],
        ideal_experience: [],
        ideal_skill: [],
        ideal_title: [],
        job_location: [],
        job_position: [],
        skill: [],
        skills: [],
        speciality: [],
        suggested_skills: [],
        suggested_titles: [],
        technology_work_experience: [],
        total_experience: [],
        suggested_titles_copy: [],
        ideal_specialities: [],
        ...getState().jobSetup.jobSetup.jobSetup,
        company_speciality,
        job_position,
        suggested_titles,
        total_experience,
        functions,
        skills,
        education,
        version: 2,
        job_location: getState().jobSetup.jobLocation.selectedLocations,
      };

      // console.log("=================Edit Job Array=========================", jobArray)
      ideal = getState().jobSetup.jobIdealProfile.profiles;

      jdText = getState().jobSetup.jobDetails.description || "";
      jobLocation = getState().jobSetup.jobLocation.selectedLocations || [];
    } else {
      ideal = prepareIdealProfiles(
        getState().jobSetup.jobIdealProfile.profiles || []
      );
      jdText = getState().jobSetup.jobDetails.description || "";
      jobLocation = getState().jobSetup.jobLocation.selectedLocations || [];

      const setup = {
        competitorCompanies: [],
        companyHiringFor: [],
        company_speciality: [],
        likedCompanies: [],
        education: [],
        functions: [],
        ideal_company: [],
        ideal_education: [],
        ideal_experience: [],
        ideal_skill: [],
        ideal_title: [],
        job_location: [],
        job_position: [],
        skill: [],
        skills: [],
        speciality: [],
        suggested_skills: [],
        suggested_titles: [],
        technology_work_experience: [],
        total_experience: [],
        suggested_titles_copy: [],
        ideal_specialities: [],
        ...getState().jobSetup.jobSetup.jobSetup,
      };

      jobArray = {
        ...Object.keys(setup).reduce((total, key) => {
          if (["company_speciality"].includes(key)) {
            return {
              ...total,
              [key]: setup[key].filter(({ selected }) => selected),
            };
          }
          return {
            ...total,
            [key]: setup[key],
          };
        }, {}),
        job_location: jobLocation,
        version: 2,
      };
    }

    const jobTitle = jobArray?.job_position?.[0]?.name || "";

    const payload = {
      toBeInvited,
      tribeData,
      jdText,
      jobLocation,
      jobTitle,
      jobArray,
      benchmarkProspects: {
        similar: null,
        ideal,
      },
      oldP2P: process.env.REACT_APP_OLD_P2P === "true",
    };

    const res = await manualApiCall("/api/auth/tribes/store?v2=true", {
      method: "POST",
      body: JSON.stringify(payload),
    });
    if (res.success) {
      dispatch({
        type: ADD_NEW_JOB_SUCCESS,
        payload: res,
      });
      dispatch(initCompanyDetails());
      dispatch(initJobCriteria());
      dispatch(initJobDetails());
      dispatch(initJobIdealProfile());
      dispatch(initJobLocation());
      dispatch(initJobSpecialities());
      dispatch(initTribeMembers());
      dispatch(initJobsetup());
      dispatch(initJobSetupView());
      callBack(res.data.id);
    } else {
      throw new Error("new job api failed!");
    }
    // dispatch({[CALL_API]: {
    //   types: [ADD_NEW_JOB, ADD_NEW_JOB_SUCCESS, ADD_NEW_JOB_FAILURE],
    //   endpoint: '/api/auth/tribes/store',
    //   options: {
    //     method: 'POST',
    //     body: JSON.stringify(payload)
    //   },
    // }})
  } catch (err) {
    dispatch({
      type: ADD_NEW_JOB_FAILURE,
    });
    console.log("Error: ", err.message);
  }
};

export const INIT_COMPANY_DETAILS = "INIT_COMPANY_DETAILS";
export const initCompanyDetails = () => ({
  type: INIT_COMPANY_DETAILS,
});

export const INIT_JOB_CRITERIA = "INIT_JOB_CRITERIA";
export const initJobCriteria = () => ({
  type: INIT_JOB_CRITERIA,
});

export const INIT_JOB_DETAILS = "INIT_JOB_DETAILS";
export const initJobDetails = () => ({
  type: INIT_JOB_DETAILS,
});

export const INIT_JOB_IDEAL_PROFILE = "INIT_JOB_IDEAL_PROFILE";
export const initJobIdealProfile = () => ({
  type: INIT_JOB_IDEAL_PROFILE,
});

export const INIT_JOB_LOCATION = "INIT_JOB_LOCATION";
export const initJobLocation = () => ({
  type: INIT_JOB_LOCATION,
});

export const INIT_JOBSETUP = "INIT_JOBSETUP";
export const initJobsetup = () => ({
  type: INIT_JOBSETUP,
});

export const INIT_JOB_SEPCIALITIES = "INIT_JOB_SEPCIALITIES";
export const initJobSpecialities = () => ({
  type: INIT_JOB_SEPCIALITIES,
});

export const INIT_TRIBE_MEMBERS = "INIT_TRIBE_MEMBERS";
export const initTribeMembers = () => ({
  type: INIT_TRIBE_MEMBERS,
});

export const INIT_JOB_EDIT_SIMILAR_COMPANY = "INIT_JOB_EDIT_SIMILAR_COMPANY";
export const initJobEditSimilarCompany = (payload) => ({
  type: INIT_JOB_EDIT_SIMILAR_COMPANY,
  payload,
});

export const INIT_JOB_EDIT = "INIT_JOB_EDIT";

export const initJobEdit = () => async (dispatch, getState) => {
  const jobdescription =
    getState()?.prospectsView?.candidates?.job?.jdText || "";
  if (jobdescription && jobdescription?.length > 0) {
    dispatch({
      type: CHANGE_JOB_DESCRIPTION,
      payload: jobdescription,
    });
  }
  const locations =
    getState().prospectsView?.candidates?.job?.jobArray?.job_location || [];
  if (locations?.length > 0) {
    locations?.map((location) => {
      dispatch({
        type: ADD_SELECTED_JOB_LOCATION,
        payload: location,
      });
    });
  }

  const ideal =
    getState()?.prospectsView?.candidates?.job?.benchmarkProspects?.ideal || [];

  if (ideal.length > 0) {
    ideal.map((idealProfile) =>
      dispatch({
        type: FETCH_IDEAL_PROFILE_DATA_SUCCESS,
        payload: idealProfile,
      })
    );
  }
  const companyHiringFor =
    getState()?.prospectsView?.candidates?.job?.jobArray?.companyHiringFor ||
    [];

  if (companyHiringFor.length > 0 && companyHiringFor?.[0]?.universalName) {
    dispatch({
      type: SELECT_COMPANY_SUCCESS,
      payload: companyHiringFor[0],
    });
  }
  const competitorCompanies =
    getState()?.prospectsView?.candidates?.job?.jobArray?.competitorCompanies ||
    [];
  const likedCompanies =
    getState()?.prospectsView?.candidates?.job?.jobArray?.likedCompanies || [];
  const similarCompanies = competitorCompanies?.concat(likedCompanies);
  if (similarCompanies?.length > 0) {
    dispatch(initJobEditSimilarCompany(similarCompanies));
  }
  const jobArray = getState()?.prospectsView?.candidates?.job?.jobArray || [];

  // const specialities = jobArray.specialities.map((speciality) => {

  // });
  /*
{
        name,
        company_size_min,
        company_size_max,
        source = [],
        sourceId = [],
        selected,
      }
*/

  // const suggestedTitles = jobArray?.suggested_titles

  /*
          max: 5
          min: 3
          name: "logistics"
          selected: true
          source: ['companyHiring']
          sourceId: []
            */
  // const company_speciality = jobArray?.company_speciality
  // const newJobArray = {
  //   ...jobArray,
  //   company_speciality: company_speciality,
  //   suggested_titles: suggestedTitles,
  // };

  dispatch({
    type: SENDING_TO_NER_SUCCESS,
    payload: jobArray,
  });
};

export const SET_IS_REVIEW = "SET_IS_REVIEW";

export const setIsReview = (isReview) => async (dispatch, getState) => {
  dispatch({
    type: SET_IS_REVIEW,
    payload: isReview,
  });
};

export const SET_IS_EDITED = "SET_IS_EDITED";
export const setIsEdited = (payload) => ({
  type: SET_IS_EDITED,
  payload,
});

export const SET_IS_SPECIALTY_EDITED = "SET_IS_SPECIALTY_EDITED";
export const setIsSpecialtyEdited = (payload) => ({
  type: SET_IS_SPECIALTY_EDITED,
  payload,
});
