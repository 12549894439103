import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import SecondaryButton from '../../../../uiLibrary/SecondaryButton';
import DropdownComponent from '../../../../uiLibrary/DropdownComponent';
import Cross from "../../../../components/svgComponents/CrossSvg";
import LabelButton from '../../../../uiLibrary/LabelButton';
import GoogleLogo from '../../../../img/googleLogo.svg';

import "react-datepicker/dist/react-datepicker.css";
import './messagingSettings.scss'

import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { manualApiCall } from '../../../../utils';
import { validateEmailPermissionToken } from '../../../../actions/hybridCampaign';
import { Tooltip } from 'reactstrap';
import { GoogleButton } from '../../../../components/SocialLoginButton';
import { setOutboxView } from '../../../../actions/outbox';
import Loading from '../../../../uiLibrary/Loading';
import { Mixpanel } from '../../../../utils/Mixpanel';

const moment = require('moment-timezone');

const recepientOptions = ["Personal Email", "Work Email"]

const MessagingSettings = ({ 
    emailState,
    emailProvider,
    tokenLoading,
    tokenLoaded,
    push,
    settings, onChange, mailboxLinked, emailaddress, dispatch }) => {
    const [isOpen, setIsOpen] = useState(false)
    let [recepient, setRecepient] = useState("Personal email");
    let [linkedMailbox, setLinkedMailbox] = useState(settings && settings.linkedCalendar ? (settings.linkedCalendar.split(',')) : []);
    let [newCalendar, setNewCalendar] = useState("");
    let [isAddNew, setIsAddNew] = useState(false);
    let [allowSend, setAllowSend] = useState("true");

    if (settings && settings.linkedCalendar) {
        let _array = settings.linkedCalendar.split(',');
        linkedMailbox = _array[0];
    }
    const onMessagingChange = () => {
        let payload = {
            "linkedCalendar": linkedMailbox,
            "recepient": recepient,
            "allowSend": allowSend
        }
        onChange(payload, "messaging");
    }

    const onRecepientChange = (e) => {
        setRecepient(e);
        onMessagingChange();
    }

    const onUnlink = async () => {
        await manualApiCall('/api/auth/emailing/emailRevoke',
            {
                method: 'GET'
            })
        dispatch(validateEmailPermissionToken())
    }

    const handleSubjectChange = (e) => {
        setNewCalendar(e.target.value);
    }

    const onDiscardCalendar = () => {
        setNewCalendar("");
        setIsAddNew(false);
    }

    const onClickOutside = (e) => {
        if (e && e.relatedTarget && e.relatedTarget.className == "crossIconButton") {
            onDiscardCalendar();
        }
        else {
            if (newCalendar != "") {
                setNewCalendar(newCalendar);
                onMessagingChange();
            }
            setIsAddNew(false);
        }
    }

    const onMailBoxClick = () => {
        // console.log("===========================onMailBoxClick==============================", {emailState, emailProvider})
        if (!emailState && (emailaddress?.split("@")?.pop()?.toLowerCase()?.includes("gmail") || emailProvider==="GOOGLE")) {
            Mixpanel.track("Link your mailbox - settings")
            dispatch(
                setOutboxView({
                    view: "CONSENT",
                    redirectPath: '/settings',  
                    fte: true
                })
            )
        }
    }

    const handleAllowSendChange = (event) => {
        setAllowSend(event.target.checked)
        onMessagingChange();
    }

    if((emailProvider !== "GOOGLE" && !emailaddress?.split("@")?.pop()?.toLowerCase()?.includes("gmail")) && !tokenLoading){
        return ""
    }

    return (
        <div className="messagingSettings">
            <div className="heading">
                Messaging
            </div>
            <div className="flexAlignDisplay">
                <div className="header">
                    Linked Mailbox
                </div>
                <div className="content">
                    {!tokenLoaded && tokenLoading ?
                        <Loading/> :   
                        emailState ? 
                        <div className="flexDisplay">
                            <SecondaryButton className="googleBtn">
                                <img
                                    width="20"
                                    height="20"
                                    src={GoogleLogo}
                                />
                                Google
                            </SecondaryButton>
                            <div id="mailboxLinkedAdress" className="email">
                                {emailaddress}
                            </div>
                            <Tooltip
                                placement="bottom"
                                isOpen={isOpen}
                                target={`mailboxLinkedAdress`}
                                toggle={() => setIsOpen(prev => !prev)}
                                fade={false}
                            >
                                {emailaddress}
                            </Tooltip>
                            <LabelButton className="link" onClick={onUnlink}>
                                Unlink
                            </LabelButton>
                        </div> :
                        <GoogleButton
                            style={{
                                margin: '0'
                            }}
                            onClick={onMailBoxClick}
                        >
                            Link your mailbox
                        </GoogleButton>}
                </div>
            </div>

            {/* <div className="flexAlignDisplay">
                <div className="header">
                    <div>Default Recipient Email</div>
                    <div className="subHeader">For every email sent by you, this is where recipients will receive it by default. If not found, Qlu will send it to their other email, or ask you to add it manually.</div>
                </div>
                <div className="content">
                    <DropdownComponent
                        selected={recepient}
                        options={recepientOptions}
                        onSelect={onRecepientChange}>
                    </DropdownComponent>
                </div>
            </div> */}

            {/* <div className="flexAlignDisplay tribes">
                <div className="header">
                    <div>Allow other tribe members to send messages on my behalf</div>
                    <div className="subHeader">Qlu will always ask you for approval before any campaign is published.</div>
                </div>
                <div className="content">
                    <Toggle
                        defaultChecked={allowSend}
                        aria-label='No label tag'
                        onChange={handleAllowSendChange} />
                    <span>{allowSend ? "Yes" : "No"}</span>
                </div>
            </div> */}
        </div>
    )
}

export default connect(state=>({
    emailState: state.outbox.campaignOperations.emailState,
    emailProvider: state.outbox.campaignOperations.emailProvider,
    tokenLoading: state.outbox.campaignOperations.tokenLoading,
    tokenLoaded: state.outbox.campaignOperations.tokenLoaded,  
    consentWindow: state.outbox.campaignOperations.consentWindow,
}))(MessagingSettings)