import React, { useState } from "react";
import Picker, {
  SKIN_TONE_MEDIUM_DARK,
  SKIN_TONE_NEUTRAL,
} from "emoji-picker-react";

const EmojiPicker = ({
  handleEmojiPicked = () => false,
  comment = {},
  prospect = {},
  emojiRef = null,
}) => {
  const [chosenEmoji, setChosenEmoji] = useState(null);

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    console.log(emojiObject);
    if (comment.id) handleEmojiPicked(emojiObject, comment);
    else handleEmojiPicked(emojiObject, prospect);
  };

  return (
    <div>
      {/* {chosenEmoji ? (
        <span>You chose: {chosenEmoji.emoji}</span>
      ) : (
        <span>No emoji Chosen</span>
      )} */}
      <Picker
        onEmojiClick={onEmojiClick}
        disableSkinTonePicker={true}
        disableSearchBar={false}
        disableAutoFocus={false}
        skinTone={SKIN_TONE_NEUTRAL}
        native="true"
        ref={emojiRef}
        groupVisibility={{
          flags: false,
        }}
      />
    </div>
  );
};

export default EmojiPicker;
