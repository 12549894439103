import qs from "qs";
import React from "react";
import { withRouter } from "react-router-dom";

import { omit } from "lodash";
import { useSelector } from "react-redux";
import { NavItem, NavLink } from "reactstrap";
import { commentNotificationList } from "../../constants/notification";
import { Mixpanel } from "../../utils/Mixpanel";
import CrossSvg from "../SVGs/CrossSvg";
import FavouriteSVG from "../SVGs/FavoriteSVG";
import QuestionSvg from "../SVGs/QuestionSvg/QuestionSvg";
import UsersIconSvg from "../SVGs/UsersIcon";
import "./TabsComponent.css";
import "./TabsComponent.scss";

const CollapsedSideBar = ({
  expanded = false,
  // toggle = () => false,
  folders,
  // onClickFolder,
  // activeFolder,
  history: { push },
  location: { pathname, search },
}) => {
  const qParams = qs.parse(search.split("?").pop());

  const icons = [
    // { name: 'user', title: 'Recommended', count: 3, icon: <UserIcon className="icon" /> },
    // { name: 'company', title: 'Company', count: 0, icon: <CompanyIconSvg className="icon" /> },
    // { name: 'messageCircle', title: 'Commented', count: 0, icon: <MessageCircle className="icon" /> },
    {
      className: "bookmark",
      value: "s",
      title: "saved",
      count: folders?.saved,
      icon: <FavouriteSVG width="24" height="24" className="icon" stroke="2" />,
    },
    {
      className: "maybe",
      value: "mb",
      title: "maybe",
      count: folders?.maybe,
      icon: <QuestionSvg width="24" height="24" className="icon" stroke="2" />,
    },
    // {
    //   className: "downloadIcon",
    //   value: "d",
    //   title: "downloaded",
    //   count: folders?.downloaded,
    //   icon: <DownloadIconSvg width="24" height="24" className="icon" />,
    // },
    // { name: 'folderIcon', title: 'Tier A', count: 0, icon: <FolderIcon width="24" height="24" className="icon" /> }
  ];

  const subIcons = [
    {
      className: "trashBin",
      title: "archived",
      value: "ar",
      count: folders?.archived,
      icon: (
        <CrossSvg width="24" height="24" color="#CDD1D7" className="icon" />
      ),
    },
  ];

  const folderMap = {
    "": "all",
    a: "all",
    s: "saved",
    d: "downloaded",
    ar: "archived",
    mb: "maybe",
  };



  const currentFolder = folderMap[qParams["fF"]];
  const flagsList = ["rR", "nA", "tc", "cm", "oc", "ac"]
  const newParams = { ...omit(qParams, [...commentNotificationList, ...flagsList]) };
  const handleClickFolder = (value) => {
    const folderMap = {
      a: 'All Profiles',
      s: 'Liked Profile',
      mb: 'Maybe Profile',
      ar: 'Disliked Profile',
    }
    if (Object.keys(folderMap).includes(value)) {
      Mixpanel.track(`${folderMap[value]} - icon button`)
    }
    push(`${pathname}?${qs.stringify({ ...newParams, fF: value, p: 0, b: 'all', ac: true })}`);
  };
  const deleteCount = useSelector(state => state.prospectsView.candidates.deleteCount);
  return (
    <>
      <NavItem
        onClick={() => handleClickFolder("a")}
        className={`${currentFolder === "all" ? "selected" : ""} ${expanded ? "expanded" : ""
          }`}
      >
        <NavLink>
          <div className="prospectIcon">
            <div className="collapsedIcons">
              <UsersIconSvg className="icon" stroke="2" />
            </div>

            {expanded ? (
              <>
                <div className="prospectText">
                  <p className="prospect">all</p>
                  <p className="prospectCount">{folders?.all}</p>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </NavLink>
      </NavItem>

      {icons.map((icon, index) => (
        <NavItem
          key={index}
          className={`${icon.className} ${icon.title === currentFolder ? "selected" : ""
            }`}
          onClick={() => handleClickFolder(icon.value)}
        >
          <NavLink>
            <div className="prospectIcon">
              <div className="collapsedIcons">{icon.icon}</div>

              {expanded ? (
                <>
                  <div className="prospectText">
                    <p className="prospect">{icon.title}</p>
                    <p className="prospectCount">
                      {icon?.count || 0}
                    </p>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </NavLink>
        </NavItem>
      ))}

      <div
        className="horizontalLine"
        style={{ maxWidth: "236px", height: "2px", margin: "1rem 1rem" }}
      ></div>

      {subIcons.map((icon, index) => (
        <NavItem
          index={index}
          className={`${icon.className} ${icon.title === currentFolder ? "selected" : ""
            }`}
          onClick={() => handleClickFolder(icon.value)}
        >
          <NavLink>
            <div className="prospectIcon">
              <div className="collapsedIcons">{icon.icon}</div>

              {expanded ? (
                <>
                  <div className="prospectText">
                    <p className="prospect">{icon.title}</p>
                    <p className="prospectCount">
                      {icon.value == "ar" ? (icon.count + deleteCount) || 0 : icon.count}
                    </p>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </NavLink>
        </NavItem>
      ))}
    </>
  );
};

export default withRouter(CollapsedSideBar);
