import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { clearConsentWindow, validateEmailPermissionToken } from '../../../actions/hybridCampaign'
import { fetchCampaignPendingStatus, fetchRecruiter, setCampaignsPendingStatus, setOutboxView } from '../../../actions/outbox'
import Loader from '../../../components/Loader'
import { GoogleButton } from '../../../components/SocialLoginButton'
import Loading from '../../../uiLibrary/Loading'
import InfoSvg from '../../../uiLibrary/SVGs/InfoSvg'
import WarningButton from '../../../uiLibrary/WarningButton'
import { pingQlu } from '../../../utils/MessagePassing'
import './style.scss'
const PendingNotificationPanel = ({
    dispatch,
    recruiterInMailCredits,
    channels=[],
    emailState,
    emailProvider,
    tokenLoading,
    tokenLoaded,
    consentWindow,
    channelsPendingLoading,
    channelsPendingLoaded
}) => {

    const [recruiterInMailState, setRecruiterInMailState] = useState(recruiterInMailCredits)
    const [linkedinState, setLinkedinState] = useState(false)
    const [recruiterInterval, setRecruiterInterval] = useState(false)
    const [linkedinInterval, setLinkedinInterval] = useState(false)


    useEffect(async () => {
        dispatch(fetchCampaignPendingStatus())
        if (consentWindow) {
            consentWindow.close()
            dispatch(clearConsentWindow())
        }
        dispatch(validateEmailPermissionToken());
    }, [])

    useEffect(()=>{
        setRecruiterInMailState(recruiterInMailCredits)
        if(recruiterInMailCredits>0 && recruiterInterval){
            clearInterval(recruiterInterval)
            setRecruiterInterval(false)
            dispatch(setCampaignsPendingStatus(channels.filter(chnl=>'recruiter'!==chnl)))
        }
    }, [recruiterInMailCredits])

    useEffect(()=>{
        if(emailState){
            dispatch(setCampaignsPendingStatus(channels.filter(chnl=>'email'!==chnl)))
        }
    }, [emailState])

    useEffect(()=>{
        if(linkedinState && linkedinInterval){
            clearInterval(linkedinInterval)
            setLinkedinState(false)
            dispatch(setCampaignsPendingStatus(channels.filter(chnl=>!['linkedin', 'inmail'].includes(chnl))))
        }
    }, [linkedinState])


    const getLiChannels = () => {
        if(channels.includes('recruiter') && (channels.includes('linkedin') || channels.includes('inmail'))){
            return 'LinkedIn and Recruiter'
        }
        else if(channels.includes('recruiter')){
            return 'Recruiter'
        }
        else{
            return 'LinkedIn'
        }
    }

    const getDisclaimer = () => {
        if(channels.includes('email') && (['linkedin', 'inmail', 'recruiter'].some(chnl => channels.includes(chnl)))){
            return `be logged in to ${getLiChannels()} and grant Email permissions`
        }
        else if(channels.includes('email')){
            return 'grant Email permissions'
        }
        else{
            return `be logged in to ${getLiChannels()}`
        }
    }


    const isLinkedinSignedIn = ()=>{ 
        setLinkedinState(async prev=>{
            if(prev){      
                const resPingQlu = await new Promise(resolve=>pingQlu(r=>resolve(r)))  
                return (resPingQlu && 'me' in resPingQlu)
            } 
            return prev
        })   
    }

    const handleLiClick = e => {
        if(!recruiterInterval){
            setLinkedinInterval(setInterval(isLinkedinSignedIn, 5000))
        } 
    } 

    const isRecruiterSignedIn = ()=>{ 
        setRecruiterInMailState(recruiterInMailCredits=>{
            if(recruiterInMailCredits===0){
                dispatch(fetchRecruiter())
            } 
            return recruiterInMailCredits
        })   
    }

    const handleRecClick = e => {
        if(!recruiterInterval){
            setRecruiterInterval(setInterval(isRecruiterSignedIn, 5000))
        } 
    } 


    const onMailBoxClick = () => {
        if (tokenLoaded && !tokenLoading && !emailState && emailProvider === "GOOGLE") {
            dispatch(
                setOutboxView({
                view: "CONSENT",
                fte: true
                })
            )
        }
    }

    if(channelsPendingLoading){
        return  <div
                    className='PendingNotificationPanelLoader'
                >
                    <Loading/>
                </div>
    }
    if(channels.length===0){
        return <div/>
    }

    return (
        <div
            className='PendingNotificationPanel'
        >
            <div>
                <span>
                    <InfoSvg  
                        Recommended={true} 
                        width='38px'
                        height='38px'
                    />
                </span>
                <span>
                    {['linkedin', 'inmail', 'recruiter'].some(chnl => channels.includes(chnl)) &&
                        <h3>
                            {`You’re logged out of your ${getLiChannels()}`}
                        </h3>
                    }
                    {channels.includes('email') &&
                        <h3>
                            Your Email permissions are expired
                        </h3>
                    }
                    <p>
                        {`Qlu needs you to ${getDisclaimer()} to send campaign followups on your behalf.`}
                    </p>  
                </span>  
            </div>
            <div>
                {['linkedin', 'inmail'].some(chnl => channels.includes(chnl)) &&
                    <a
                        style={{
                            marginTop: '20px',
                            textDecoration:'none'
                        }}
                        target='_blank'
                        href='https://www.linkedin.com/login'
                    >
                        <WarningButton
                            onClick={handleLiClick}
                        >
                            Log In to LinkedIn
                        </WarningButton>
                    </a>
                }
                {channels.includes('recruiter') &&
                    <a
                        style={{
                            marginTop: '20px',
                            textDecoration:'none'
                        }}
                        target='_blank'
                        href='https://www.linkedin.com/talent/contract-chooser'
                    >
                        <WarningButton
                            onClick={handleRecClick}
                        >
                            Log In to Recruiter
                        </WarningButton>
                    </a>
                }
                {!emailState && emailProvider === "GOOGLE" && channels.includes('email') &&
                    <GoogleButton
                        onClick={onMailBoxClick}
                    >
                        Link your mailbox
                    </GoogleButton>
                }
            </div>
        </div>
    )
}

export default  connect(state=>({
    emailState: state.outbox.campaignOperations.emailState,
    emailProvider: state.outbox.campaignOperations.emailProvider,
    tokenLoading: state.outbox.campaignOperations.tokenLoading,
    tokenLoaded: state.outbox.campaignOperations.tokenLoaded,  
    consentWindow: state.outbox.campaignOperations.consentWindow,
    recruiterInMailCredits:  state.outbox.campaignOperations.recruiterInMailCredits,
    channels: state.outbox.campaignOperations.channelsPending,
    channelsPendingLoading: state.outbox.campaignOperations.channelsPendingLoading,
    channelsPendingLoaded: state.outbox.campaignOperations.channelsPendingLoaded,
  }))(PendingNotificationPanel)