import React, { useEffect } from 'react'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Header from '../../views/LandingPage/Header'
import { CampaignsContainer } from '../../views/LandingPage/Campaigns'
import { fetchJobCampaigns, fetchJobTribeCampaigns } from '../../actions/prospectsView'


const JobCampaigns = ({
  campaigns: {
    myCampaigns,
    tribeCampaigns
  },
  dispatch,
  location,
  match
}) => {

  console.log('MATCH: ', match)

  useEffect(() => {
    dispatch(fetchJobCampaigns(match.params.jId))
    dispatch(fetchJobTribeCampaigns(match.params.jId))
  }, [])

  return (
    <Container>
      <CampaignsContainer
        label='My Campaigns'
        onSearch={(args) => { dispatch(fetchJobCampaigns(match.params.jId, args)) }}
        {...myCampaigns}
      />
      <div style={{marginTop: '64px'}} />
      <CampaignsContainer
        label={`Tribe members campaigns`}
        onSearch={(args) => { dispatch(fetchJobTribeCampaigns(match.params.jId, args)) }}
        {...tribeCampaigns}
      />

    </Container>
  )
}

export default connect(state => ({
  campaigns: state.prospectsView.campaigns
}))(withRouter(JobCampaigns))