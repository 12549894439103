import React from "react";
import "./chartCard.scss";
import { AreaChart, Area, ResponsiveContainer } from "recharts";

const ChartCard = ({ label = "sample label", stat = "12%", chart }) => {
  return (
    <div className="chartCardRoot">
      <p>{label}</p>
      <div>
        <p>{stat}</p>
        <div>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={chart}>
              <Area
                type="monotone"
                dataKey="point"
                stroke="#FF8D4E"
                fill="#FF8D4E"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ChartCard;
