import {
  AdditionalJobTitleFAQ1,
  AdditionalJobTitleFAQ2,
  AdditionalJobTitleFAQ3,
  AdditionalJobTitleFAQ4,
  AdditionalJobTitleFAQ5,
  AdditionalJobTitleFAQ6,
  AdditionalJobTitleFAQ7,
  AdditionalJobTitleFAQ8,
  AdditionalJobTitleFAQ9,
  AdditionalJobTitleFAQ10,
} from "../Answers/AdditionalJobTitle";

export const AdditionalJobTitleFAQS = [
  {
    heading:
      "If I have already selected a job title why do I need to select additional titles?",
    content: <AdditionalJobTitleFAQ1 />,
  },
  {
    heading: "How are additional titles generated?",
    content: <AdditionalJobTitleFAQ2 />,
  },
  {
    heading: "Should I select a few/or many titles?",
    content: <AdditionalJobTitleFAQ3 />,
  },
  {
    heading:
      "Why do I need to add employee count again? I have already added it?",
    content: <AdditionalJobTitleFAQ4 />,
  },
  {
    heading: "Some of the titles are not relevant? Should I select them?",
    content: <AdditionalJobTitleFAQ5 />,
  },
  {
    heading: "What are additional titles from best fit profiles?",
    content: <AdditionalJobTitleFAQ6 />,
  },
  {
    heading: "How do I select the title of the profile?",
    content: <AdditionalJobTitleFAQ7 />,
  },
  {
    heading:
      "The title of one or more of the best fit profiles is not selectable? ",
    content: <AdditionalJobTitleFAQ8 />,
  },
  {
    heading:
      "How did the tool select the employee count for additional titles?",
    content: <AdditionalJobTitleFAQ9 />,
  },
  {
    heading: "How many titles can I have?",
    content: <AdditionalJobTitleFAQ10 />,
  },
];
