import React from "react";
import "./ProfileLiked.scss";

function ProfileLiked({ header, description, children }) {
  return (
    <div className="ProfileLiked">
      <div className="left">
        <div className="toast-header">{header}</div>
        <div className="toast-description">{description}</div>
      </div>
      <div className="right">{children}</div>
    </div>
  );
}

export default ProfileLiked;
