import React from 'react'
import ProfileDisplayImage from '../../../../components/ProfileDisplayImage/ProfileDisplayImage'
import PrimaryCheckBox from '../../../../uiLibrary/PrimaryCheckBox'
import { DelimitatedTitle } from '../ProfileCardExperienceDetails'
import ProfileColabActions from '../ProfileColabActions'
import './style.scss'


const ProfileSummary = ({
  name,
  title,
  company,
  location,
  src,
  index = 0,
  emojies,
  isSelected = false,
  onSelect = e => false,
  jobProfileId,
  profileData,
  onClickCompany,
  onClickComments,
  qParams,
  archRequestCount
}) => {

  // console.log("PROFILE DATA: ", profileData)

  const subtractFromIndex = qParams.p > 0 ? archRequestCount : 0

  return (
    <div
      className='profileSummaryContainer'
    >
      <div>
        <p>
          {(index + 1) - subtractFromIndex} {profileData.fromEs ? '.' : ''}
        </p>
        <PrimaryCheckBox
          style={{ width: '16px', height: '16px' }}
          onChange={onSelect}
          checked={isSelected}
        />
      </div>
      <ProfileDisplayImage
        width='128px'
        height='128px'
        src={src}
      />
      <span>
        <p>
          {name}
        </p>
      </span>
      <span>
        <DelimitatedTitle
          onClick={onClickCompany}
          title={profileData?.headline}
          delimiter={profileData?.headline && company && !profileData?.headline?.includes(company) ? '@' : ''}
          name={profileData?.headline?.includes(company) ? '' : company} //company
          style={{ textAlign: 'center' }}
        />
      </span>
      <span>
        <p>
          {location}
        </p>
      </span>
      <span>
        <ProfileColabActions
          emojies={emojies}
          onClickComments={onClickComments}
          jobProfileId={jobProfileId}
          profileData={profileData}
        />
      </span>
    </div>
  )
}

export default ProfileSummary