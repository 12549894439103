import React, { useState } from 'react'
import { connect } from 'react-redux';
import { likedProfile } from '../../actions/prospectsView';
import { Mixpanel } from '../../utils/Mixpanel';
import './style.scss'
const SocialMaybeButton = ({
    dispatch,
    permissionsAllowed,
    index,
    jobProfileId,
    profileId,
    maybe=false,
    saved,
    onClickSave=e=>false,
    onClickMaybe=e=>false,
    mixpanelActionType=false,
    isAddingProfile = false,
    onClick=e=>{
        if(permissionsAllowed === "*" || (permissionsAllowed?.length &&  permissionsAllowed?.length > 0 && (permissionsAllowed?.includes("GIVE_FEEDBACK") || permissionsAllowed?.includes("CAN_SAVE")))){
            const data = {
                index: index,
                status: maybe?null:"RECOMMENDED_MAYBE",
                jobProfileId: jobProfileId,
            };
            if(mixpanelActionType){
                Mixpanel.track(`Maybe - ${mixpanelActionType}`)
            }
            dispatch(likedProfile(data));
            if(saved){
                onClickSave(
                    [jobProfileId],
                    saved
                );
            }
            onClickMaybe(jobProfileId, !maybe);
        }
    },
    ...props
}) => {
    const [disabled, setDisabled] = useState(false)
    return (
        <button
            className={`socialMaybeButton ${maybe ?'socialMaybeButton-activate' : ''}`}
            onClick={(e) => {
                if (isAddingProfile) {
                  setDisabled(true)
                }
                onClick(e)
              }}
              disabled={disabled}
            {...props}
        >
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.16675 9.72802C8.63722 8.42945 9.56585 7.33445 10.7882 6.63697C12.0105 5.93948 13.4476 5.68452 14.8449 5.91724C16.2423 6.14996 17.5097 6.85534 18.4228 7.90845C19.3358 8.96156 19.8355 10.2944 19.8334 11.671C19.8334 15.557 13.83 17.5 13.83 17.5" 
                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14 23.3333H14.0117" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </button>
    )
}

export default connect(state=>({
    permissionsAllowed: state?.prospectsView?.candidates?.permissions,
}))(SocialMaybeButton)