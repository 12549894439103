import React from "react";
import "../style.scss";

const QuestionSvg = ({
  className = "",
  width = "16",
  height = "16",
  strokeWidth = "2",
  selected = false,
  stroke = "#6C6C6C",
  style = {},
}) => {
  //#FFC321
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        style={style}
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          className={`qluSvgStroke${selected ? "Selected" : ""}`}
          d="M4.19983 4.49163C4.52244 3.60118 5.15921 2.85033 5.99736 2.37205C6.83551 1.89378 7.82095 1.71895 8.77914 1.87852C9.73733 2.0381 10.6064 2.52179 11.2325 3.24393C11.8586 3.96606 12.2013 4.88003 12.1998 5.82397C12.1998 8.48864 8.08319 9.82098 8.08319 9.82098"
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className={`qluSvgStroke${selected ? "Selected" : ""}`}
          d="M8 13.3333H8.00667"
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default QuestionSvg;
