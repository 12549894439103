import { omit } from "lodash";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  DropdownItem, DropdownMenu, DropdownToggle, InputGroupButtonDropdown, Nav,
  Row, TabContent,
  TabPane
} from "reactstrap";
import DottedVerticalSvg from "../../components/svgComponents/DottedVerticalSvg";
import { commentNotificationList } from "../../constants/notification";
import { advFilterList, scrFilterList } from "../../views/ScoreAndConnect/FilterController/AdvanceFilterConstants";
import ProspectCategoryMenu from "../ProspectCategoryMenu/ProspectCategoryMenu";
import RevampActionBar from "../RevampActionBar/RevampActionBar";
import CompanySvg from "../SVGs/CompanySvg";
import MenuOpenedSvg from "../SVGs/MenuOpen";
import UnlockRecommendation from "../UnlockRecommendation/UnlockRecommendation";
import CollapsedSideBar from "./CollapsedSideBar";
import "./TabsComponent.css";
import "./TabsComponent.scss";
// import Loader from "react-loader-spinner";
// import RevampHarryPotterLoader from "../RevampHarryPotterLoader/RevampHarryPotterLoader";

const TabsComponent = ({
  children,
  showFullCard,
  filterCount,
  recommended,
  isLoading,
  handleClearFilters,
  handleSortToggle,
  handleFilterToggle,
  sortDefault,
  topMin,
  topMax,
  onSelectAll,
  onDeSelectAll,
  onSelectApply,
  isAllSelected,
  showActions,
  buckets,
  currentBucket,
  onClickBucket,
  folders,
  onClickSave,
  isSavingProspect,
  onClickArchive,
  isArchivingProspect,
  isDownloadingProspect,
  commentsExpanded,
  onClickComments,
  selectedProspectComments,
  activeProspectVariant,
  _fetchProspects,
  history,
  location: { search },
  match: { params },
  recommendedCount,
  recommendedProfiles,
  setShowMoreProspect,
  harryPotterMode,
  shouldShowHarryPotterMode,
  harryPotterLoader,
  harryPotterCount,
  dispatch,
  applyInitialFilterSort,
  filterData,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [expanded, setExpanded] = useState(false);
  const [expandedTimer, setExpandedTimer] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // console.log("SEARCH AND PARAMS ARE: ", { search, params });

  const similarCompanies = filterData.companies.join("||")

  const qParams = qs.parse(search.substring(1));

  let { isQuick } = params

  if (isQuick === 'false') {
    isQuick = false
  }

  const sortingToggle = useSelector(
    (state) =>
      state.prospectsView.sorting.fS !== "title_score" ||
      state.prospectsView.sorting.secS !== "skill_score"
  );

  const toggleSideBar = () => {
    onClickComments(false);
    return setExpanded(!expanded);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (commentsExpanded) {
      setExpanded(false);
    }
  }, [commentsExpanded]);

  useEffect(() => {
    if (expanded) {
      const timer = setTimeout(() => {
        setExpandedTimer(expanded);
      }, 450);
    } else {
      setExpandedTimer(expanded);
    }
    return () => clearTimeout();
  }, [expanded]);

  const [showRecommended, setShowRecommended] = useState(false);

  const advFilterListExceptYCC = advFilterList.filter(e => e != 'yCC')

  const flagsList = ["rR", "nA", "tc", "cm", "oc", "ac"]
  const rm = (list, rmList) => {
    return list.filter(e => !rmList.includes(e))
  }
  const recommendedDropdownItems = [
    {
      name: "all companies",
      icon: <CompanySvg width="24" height="24" selected="true" />,
      onClick: () => {
        if (![true, 'true'].includes(qParams.ac)) {
          handleClearFilters && handleClearFilters();
          setRecommendedActive(recommendedDropdownItems[0]);
          history.replace(
            `/job/${params.jId}?${qs.stringify(
              omit(
                {
                  ...qParams,
                  p: 0,
                  ac: true,
                },
                [
                  ...commentNotificationList,
                  ...advFilterListExceptYCC,
                  ...scrFilterList,
                  ...rm(flagsList, ["ac"])
                ]
              )
            )}`
          );
        }
      },
    },
    {
      name: "from liked companies",
      icon: <CompanySvg width="24" height="24" selected="true" />,
      onClick: () => {
        if (![true, 'true'].includes(qParams.tc)) {
          handleClearFilters && handleClearFilters();
          setRecommendedActive(recommendedDropdownItems[1]);
          history.replace(
            `/job/${params.jId}?${qs.stringify(
              omit(
                {
                  ...qParams,
                  p: 0,
                  tc: true,
                },
                [
                  ...commentNotificationList,
                  ...advFilterListExceptYCC,
                  ...scrFilterList,
                  ...rm(flagsList, ["tc"])
                ]
              )
            )}`
          );
        }
      },
    },
    {
      name: "from relevant companies",
      icon: <CompanySvg width="24" height="24" selected="true" />,
      onClick: () => {
        if (![true, 'true'].includes(qParams.cm)) {
          handleClearFilters && handleClearFilters();
          setRecommendedActive(recommendedDropdownItems[2]);
          history.replace(
            `/job/${params.jId}?${qs.stringify(
              omit(
                {
                  ...qParams,
                  p: 0,
                  cm: true,
                },
                [
                  ...commentNotificationList,
                  ...advFilterListExceptYCC,
                  ...scrFilterList,
                  ...rm(flagsList, ["cm"])
                ]
              )
            )}`
          );
        }
      },
    },
    {
      name: "from other companies",
      icon: <CompanySvg width="24" height="24" selected="true" />,
      onClick: () => {
        if (![true, 'true'].includes(qParams.oc)) {
          handleClearFilters && handleClearFilters();
          setRecommendedActive(recommendedDropdownItems[3]);
          history.replace(
            `/job/${params.jId}?${qs.stringify(
              omit(
                {
                  ...qParams,
                  p: 0,
                  oc: true
                },
                [
                  ...commentNotificationList,
                  ...advFilterListExceptYCC,
                  ...scrFilterList,
                  ...rm(flagsList, ["oc"])
                ]
              )
            )}`
          );
        }
      },
    },
    {
      name: "deficient profiles",
      icon: <CompanySvg width="24" height="24" selected="true" />,
      onClick: () => {
        if (![true, 'true'].includes(qParams.nA)) {
          handleClearFilters && handleClearFilters();
          setRecommendedActive(recommendedDropdownItems[4]);
          history.replace(
            `/job/${params.jId}?${qs.stringify(
              omit(
                {
                  ...qParams,
                  p: 0,
                  nA: true,
                },
                [
                  ...commentNotificationList,
                  ...advFilterListExceptYCC,
                  ...scrFilterList,
                  ...rm(flagsList, ["nA"])
                ]
              )
            )}`
          );
        }
      },
    },
  ];
  const [recommendedActive, setRecommendedActive] = useState(
    recommendedDropdownItems[0]
  );


  useEffect(() => {
    if ([true, "true"].includes(qParams.ac)) {
      setRecommendedActive(recommendedDropdownItems[0]);
    }
    else if ([true, "true"].includes(qParams.tc)) {
      setRecommendedActive(recommendedDropdownItems[1]);
    }
    else if ([true, "true"].includes(qParams.cm)) {
      setRecommendedActive(recommendedDropdownItems[2]);
    }
    else if ([true, "true"].includes(qParams.oc)) {
      setRecommendedActive(recommendedDropdownItems[3]);
    }
    else if ([true, "true"].includes(qParams.nA)) {
      setRecommendedActive(recommendedDropdownItems[4]);
    } else {
      setRecommendedActive(recommendedDropdownItems[0]);
    }
  }, [search])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
      className={`${showFullCard ? "showFullCard" : ""}`}
    >
      <Nav
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        onClick={() => onClickComments(false)}
        className={`sideNav ${expanded ? "expanded" : ""} ${!expanded && commentsExpanded ? "commentsSection" : ""
          }`}
      >
        {
          <div
            onClick={toggleSideBar}
            className={`menuIcon ${expanded ? "expanded" : ""}`}
          >
            <p style={{ width: '0px' }}>PROSPECTS</p>
            <div className="icon">
              <MenuOpenedSvg />
            </div>
          </div>
        }
        <div className="collapsedSideBar">
          <div className={expanded ? "unCollapsed expanded" : "collapsed"}>
            <CollapsedSideBar
              expanded={expandedTimer}
              toggle={toggle}
              folders={folders}
            />
          </div>
          {/* <UserAvt
            style={
              expanded
                ? { left: "7%", bottom: "-70px" }
                : { left: "25%", bottom: "-70px" }
            }
          /> */}
          {/* harry potter mode turned off */}
          {/* <NavItem
            style={{
              display: "flex",
              justifyContent: expandedTimer ? "flex-start" : "center",
              alignItems: expandedTimer ? "flex-start" : "center",
              flexDirection: "column",
              flexWrap: "wrap",
              padding: "0px",
              // marginTop: "10px",
              marginLeft: expandedTimer ? "20px" : "0px",
            }}
          >
            {harryPotterLoader && (
              <RevampHarryPotterLoader
                action={() => {
                  dispatch(_fetchHarryPotterLoader(false));
                }}
                _fetchProspects={_fetchProspects}
              />
            )}

            <HarryPotterSVG
              style={{ marginTop: harryPotterMode ? "-25px" : "0px" }}
            />

            <Toggle
              icons={false}
              defaultChecked={false}
              checked={harryPotterMode}
              onChange={() => {
                if (!harryPotterMode && harryPotterCount < 5) {
                  dispatch(_fetchHarryPotterLoader(true));
                }
                if (!harryPotterMode) {
                  dispatch(_fetchHarryPotterMode());
                }
                if (harryPotterCount >= 5) {
                  dispatch(_shouldShowHarryPotterMode(true));
                }
                dispatch(_harryPotterMode(!harryPotterMode));
              }}
            />
          </NavItem> */}
        </div>
      </Nav>
      <TabContent
        activeTab={activeTab}
        className={`${expanded ? "expanded prospectPage" : ""} ${!expanded && commentsExpanded ? "commentsSection" : ""
          }`}
      >
        <TabPane tabId="1">
          <RevampActionBar
            filterCount={filterCount}
            handleClearFilters={handleClearFilters}
            handleFilterToggle={handleFilterToggle}
            handleSortToggle={handleSortToggle}
            sortDefault={sortDefault}
            topMin={topMin}
            topMax={topMax}
            currentBucket={currentBucket}
            onSelectAll={onSelectAll}
            onDeSelectAll={onDeSelectAll}
            onSelectApply={onSelectApply}
            isAllSelected={isAllSelected}
            showActions={showActions}
            onClickSave={onClickSave}
            isSavingProspect={isSavingProspect}
            onClickArchive={onClickArchive}
            isArchivingProspect={isArchivingProspect}
            isDownloadingProspect={isDownloadingProspect}
            onClickComments={onClickComments}
            setShowMoreProspect={setShowMoreProspect}
            expanded={expanded}
          />
          <Row>
            {!['s', 'ar'].includes(qParams.fF) ?
              <div
                className="prospectCategoryMenuContainer"
                onClick={() => onClickComments(false)}
              >
                <div className="prospectCategorySubContainer">
                  <ProspectCategoryMenu
                    buckets={buckets}
                    currentBucket={currentBucket}
                    onClickBucket={onClickBucket}
                  />

                  {!isQuick && <div className="categoryLineSpacing">|</div>}

                  {!isQuick &&
                    <div className="categoryRecommended">
                      <div className="categoryRecommendedSvg">
                        {recommendedActive.icon}
                      </div>
                      <InputGroupButtonDropdown
                        addonType="append"
                        className="addedfilterButtonDropdown"
                        isOpen={dropdownOpen}
                        toggle={() => setDropdownOpen(!dropdownOpen)}
                      >
                        <DropdownToggle caret disabled={isLoading}>
                          {/* {search.includes("cm")
                          ? "From Liked Companies"
                          : "All Companies"} */}
                          {recommendedActive.name.toUpperCase()}
                        </DropdownToggle>
                        <DropdownMenu right>
                          {recommendedDropdownItems.map((item, index) => {
                            return (
                              <DropdownItem
                                key={index}
                                disabled={isLoading}
                                onClick={() => {
                                  setRecommendedActive(item);
                                  setDropdownOpen(false);
                                  item.onClick();
                                }}
                              >
                                {item.name.toUpperCase()}
                              </DropdownItem>
                            );
                          })}
                          {/* <DropdownItem
                          key={1}
                          onClick={() => {
                            history.push(
                              `/job/${params.jId}?${qs.stringify(
                                omit(qParams, "cm")
                              )}`
                            );
                          }}
                        >
                          All Companies
                        </DropdownItem>
                        <DropdownItem
                          key={2}
                          onClick={() => {
                            history.push(
                              `/job/${params.jId}?${qs.stringify({
                                ...qParams,
                                cm: true,
                              })}`
                            );
                          }}
                        >
                          From Liked Companies
                        </DropdownItem>
                        <DropdownItem
                          key={3}
                          // onClick={() => {
                          //   history.push(
                          //     `/job/${params.jId}?${qs.stringify(
                          //       omit(qParams, "cm")
                          //     )}`
                          //   );
                          // }}
                        >
                          Recommended
                        </DropdownItem> */}
                        </DropdownMenu>
                      </InputGroupButtonDropdown>

                      {false && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowRecommended((s) => !s);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <DottedVerticalSvg
                            width="16"
                            height="16"
                            color="#CDD1D7"
                          />
                        </span>
                      )}
                      {showRecommended && false && (
                        <div className="myDropDown">
                          <div className="myDropDown-item" onClick={() => { }}>
                            Download
                          </div>
                        </div>
                      )}
                    </div>}
                </div>
              </div> : null
            }

            <div
              className={`${expanded ? "prospect" : ""} prospectContainer ${!expanded && commentsExpanded ? "commentSection" : ""
                } `}
            >
              {search.includes("rR") &&
                activeProspectVariant == 2 &&
                recommendedCount?.recommended +
                (recommendedProfiles?.length
                  ? recommendedProfiles?.length - 1
                  : 0) <
                11 ? (
                <>
                  <div className="unlockRecommendationContainer">
                    <UnlockRecommendation
                      value={
                        recommendedCount?.recommended > -1
                          ? (recommendedCount?.recommended +
                            (recommendedProfiles?.length - 1)) *
                          10
                          : 0 * 10
                      }
                    />
                  </div>
                </>
              ) : null}
              <div className="prospects">{children}</div>
            </div>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    recommendedProfiles: state.prospectsView.candidates.recommendedProfiles,
    filterData: state.prospectsView.advFiltersData.data,
    isLoading: state.prospectsView.candidates.isLoading,
    harryPotterMode: state.prospectsView.prospectVariant.harryPotterMode,
    shouldShowHarryPotterMode:
      state.prospectsView.prospectVariant.shouldShowHarryPotterMode,
    harryPotterLoader: state.prospectsView.prospectVariant.harryPotterLoader,
    harryPotterCount: state.prospectsView.prospectVariant.harryPotterCount,
  }))(TabsComponent)
);
