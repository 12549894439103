import React from 'react'


const TickSvg = ({
    
    color = '#297AF7',
    width = '14',
    height = '10',
    strokeWidth="2",
    style = {},

}) => {
    return (
        <svg style={style} width={width} height={height} viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3337 1.5L5.00033 8.83333L1.66699 5.5" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}


export default TickSvg
