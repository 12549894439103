export const visaArray = [
  {
    id: 0,
    name: "H1b",
  },
  {
    id: 1,
    name: "Green Card",
  },
  {
    id: 2,
    name: "Citizen",
  },
  {
    id: 3,
    name: "OPT",
  },
];

export const compnaySizeRangeArray = [
  {
    id: 0,
    value: {
      min: 1,
      max: 10,
    },
  },
  {
    id: 1,
    value: {
      min: 11,
      max: 50,
    },
  },
  {
    id: 2,
    value: {
      min: 51,
      max: 200,
    },
  },
  {
    id: 3,
    value: {
      min: 201,
      max: 500,
    },
  },
  {
    id: 4,
    value: {
      min: 501,
      max: 1000,
    },
  },
  {
    id: 5,
    value: {
      min: 1001,
      max: 5000,
    },
  },
  {
    id: 6,
    value: {
      min: 5001,
      max: 10000,
    },
  },
  {
    id: 7,
    value: {
      min: 10001,
      max: 999999999,
    },
  },
];
export const advFilterList = [
  "lS", // locationStrict
  "PlS", // pastLocationStrict
  "sS", //strictSkills
  "sSP", // strictSpecialty
  "eS", // eliminateSkills
  "eSP", // eliminateSpecialty
  "eSS", // eliminateSchool
  "eCT", // eliminateCurrentTitle
  "ePT", // eliminatePastTitle
  "eCC", // eliminateCurrentCompany
  "ePC", // eliminatePastCompany
  "eCI", // eliminateCurrentIndustry
  "ePI", // eliminatePastIndustry
  "eL", // eliminateLocation
  "ePL", // eliminatePastLocation
  "eV", // eliminateVisa
  "hS", //highlightSkills
  "hSP", // highlightSpecialty
  "hSS", // highlightSchool
  "eE", // eliminateEducation
  "hE", // highlightEducation
  "hEt", // highlightEducation
  "hCT", // highlightCurrentTitle
  "hPT", // highlightPastTitle
  "hCC", // highlightCurrentCompany
  "hPC", // highlightPastCompany
  "hCI", // highlightCurrentIndustry
  "hPI", // highlightPastIndustry
  "hL", // highlightLocation
  "hPL", // highlightPastLocation
  "hV", // highlightVisa
  "tE", // totalExperience
  "rE", // relevantExperience
  "eCSR", //eliminateCompanySizeRange
  "hCSR", //highlightCompanySizeRange
  "cTS", //currentTitleStrict
  "pTS", //pastTitleStrict
  "eDS", //EducationStrict

  "hSL", // highlight spoken language
  "eSL", //eliminate spoken language
  "hFN", // highlight first name
  "eFN", // eliminate first name
  "hLN", // highlight last name
  "eLN", // eliminate last name
  "yCC", // yearCurrentCompany
  "yCP", // yearCurrentPosition
  "sST", // skill status
  "sPST", // specialty status
  "LTS", // location status
  "PLTS", // location status
  "YOG", // year of graduation

];
export const scrFilterList = ["tSF", "sSF", "expSF", "edSF", "iSF", "spSF"];
// nT = COMMENT_ADD & nPs = 1412926 & nM = 220;

export const filterTemplate = "no templates";
export const getSuggestionValue = (suggestion) => suggestion;
export const renderSuggestion = (suggestion) => (
  <div className="renderedSuggestionContainer"> {suggestion} </div>
);
