import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./AlertModal.scss";
import CloseButton from "../CloseButton/CloseButton";
import CrossSvg from "../svgComponents/CrossSvg";

const AlertModal = ({
  toggle,
  togglePrimary,
  toggleSecondary,
  primaryBtnValue,
  secondaryBtnValue,
  modalBodyData,
  modalHeaderTAg,
  modalOpened,
}) => {
  const strokeWidth=3;
  const width=16;
  const height=16;
  return (
    <Modal
      isOpen={modalOpened}
      fade={false}
      toggle={toggle}
      togglePrimary={togglePrimary}
      toggleSecondary={toggleSecondary}
      modalClassName="allertModal"
      className="allertModalClass"
      backdropClassName="allertModalBackdrop"
      contentClassName="allertModalContent"
    >
      <ModalBody className="alertModalBody">
        <div className="alertModalHeader">
          <p>{modalHeaderTAg}</p>
          {/* <CloseButton onClick={toggle} strokeWidth={strokeWidth} width={width} height={height}/> */}
          <div className="alertModalBodayData">{modalBodyData}</div>
        </div>
        <div className="alertModalFooterBtns">
          <button className="alertModalSecondary" onClick={toggleSecondary}>
            {secondaryBtnValue}
          </button>
          <button className="alertModalPrimary" onClick={togglePrimary}>
            {primaryBtnValue}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AlertModal;
