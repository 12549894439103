import React from "react";

export const EducationFAQ1 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            The education, specifically degree, that you would like your
            prospect to possess
          </li>
        </ul>
      </p>
    </div>
  );
};

export const EducationFAQ2 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>From the job description that you have provided</li>
        </ul>
      </p>
    </div>
  );
};

export const EducationFAQ3 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            You hover on the education and Qlu will highlight and scroll to
            where it is in the JD
          </li>
        </ul>
      </p>
    </div>
  );
};

export const EducationFAQ4 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>That means education was not mentioned in the job description</li>
        </ul>
      </p>
    </div>
  );
};

export const EducationFAQ5 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            Based on the job description if only one degree is mentioned it is
            considered of high importance
          </li>
          <li>
            If more than one are mentioned then the most junior level degree is
            considered of high importance, the one senior to it is of medium
            importance and the most senior is considered of low importance
          </li>
        </ul>
      </p>
    </div>
  );
};

export const EducationFAQ6 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            It is what your prospect has specialized in the degree they studied.
            For example, computer science, electrical engineering, etc.
          </li>
        </ul>
      </p>
    </div>
  );
};

export const EducationFAQ7 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>From the job description that you have provided</li>
        </ul>
      </p>
    </div>
  );
};

export const EducationFAQ8 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Click on add a major</li>
          <li>Type whatever major you wish to enter</li>
          <li>Select it from the dropdown</li>
        </ul>
      </p>
    </div>
  );
};

export const EducationFAQ9 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Add all the degrees required in the degree section</li>
          <li>Now add all the majors that you require in the major section</li>
        </ul>
      </p>
    </div>
  );
};

export const EducationFAQ10 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Yes, it's not mandatory you can skip it</li>
        </ul>
      </p>
    </div>
  );
};
