import React from 'react'
import Switch from "react-switch";
import './style.scss'
const SwitchComponent = ({
    onChange=e=>false,
    checked=false,
    uncheckedIcon=false,
    checkedIcon=false,
    handleDiameter=12,
    borderRadius=14,
    width=32,
    height=20,
}) => {
    return (
        <div
            className={`reactSwitchContainer${checked?'Active':''}`}
        >
            <Switch
                onChange={onChange}
                checked={checked}
                uncheckedIcon={uncheckedIcon}
                checkedIcon={checkedIcon}
                handleDiameter={handleDiameter}
                borderRadius={borderRadius}
                width={width}
                height={height}
                className="react-switch"
            />
        </div>
    )
}

export default SwitchComponent
