import { ErrorMessage, Field, Form, Formik } from 'formik'
import qs from "qs"
import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import FormikInput from '../../components/FormikInput'
import eyeOffIcon from '../../img/eye-off.svg'
import eyeIcon from '../../img/eye.svg'
import Loading from '../../uiLibrary/Loading'
import PrimaryButton from '../../uiLibrary/PrimaryButton'
import { manualApiCall, manualPublicApiCall, validatePassword } from '../../utils'



function SetNewPassword({
    email,
    dispatch,
    history,
    location
}) {

    const [email_address, set_email_address] = useState("")
    const [reset_token, set_reset_token] = useState("")
    const [passwordSaved, setPasswordSaved] = useState(false)
    const [isExpired, setIsExpired] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    useEffect(async () => {
        try {
            const { token } = qs.parse(location.search.split("?").pop())
            if (token) {
                setIsLoading(true)
                const tokenRes = await manualApiCall('/api/v2/validate/reset/token', {
                    method: 'POST',
                    body: JSON.stringify({
                        reset_token: token
                    })
                })
                setIsLoading(false)
                if (tokenRes?.data?.isExpired || tokenRes?.data?.resetTokenUsed) { setIsExpired(true) }

                if (tokenRes?.data?.success) {
                    set_email_address(String(tokenRes?.data.email))
                    set_reset_token(String(token))
                }
                else {
                    history.push('/auth/login')
                }
            }
        }
        catch (err) {
            console.log("ERROR: ", err.message)
            history.push('/auth/login')
        }
    }, [])

    const handleClickReset = () => {
        console.log("RESEND LINK ON: ", email_address)
        fetch(`${process.env.REACT_APP_API_HOST}/api/v2/send/reset/password`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email_address })
        })
        history.push('/auth/verify-email')
    }

    const validateForm = values => {
        const errors = {};

        if (!values.password) {
            errors.password = 'Password is required';
        } else if (!validatePassword(values.password)) {
            errors.password = 'Invalid Password'
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'Password is required';
        } else if (values.password != values.confirmPassword) {
            errors.confirmPassword = 'Password not matched'
        }

        return errors;
    }
    const submitForm = async (values, { props, resetForm, setErrors, setSubmitting }) => {
        if (passwordSaved) {
            history.push('/auth/login')
            return
        }
        try {
            setSubmitting(true)
            const tokenRes = await manualPublicApiCall('/api/v2/reset/token', {
                method: 'POST',
                body: JSON.stringify({
                    email: email_address,
                    password: values.password,
                    reset_token
                })
            })
            // console.log("=========setSubmitting==============", {data, email_address, values, reset_token})
            if (tokenRes.data) {
                setSubmitting(false)
                setPasswordSaved(true)
            }
            else {
                alert(tokenRes?.error || "Token has expired")
            }
        } catch (e) {
            alert("Token has expired")
            console.log('AN ERROR OCCURRED: ', e.message)
        }
        finally {
            setSubmitting(false)
        }
    }

    const [isVisiblePassword, setIsVisiblePassword] = useState(false)
    const [isVisiblePassword1, setIsVisiblePassword1] = useState(false)
    return (
        <>
            {isLoading ? <Loading /> : null}
            {!isExpired && !isLoading ?
                <Formik
                    initialValues={{ confirmPassword: '', password: '' }}
                    validate={validateForm}
                    onSubmit={submitForm}
                >
                    {({
                        errors,
                        isSubmitting
                    }) => (
                        <div className='signin_root authElemContainer'>
                            <h1>Choose new password</h1>
                            <Form>
                                <div className='mb32 '>
                                    <p className='input_label'>NEW PASSWORD</p>
                                    <Field
                                        type={isVisiblePassword ? 'text' : 'password'}
                                        name="password" placeholder={'At least 8 characters'}
                                        component={FormikInput} />
                                    <span className='password_show_hide' onClick={() => setIsVisiblePassword(p => !p)}>
                                        {
                                            isVisiblePassword
                                                ? <img src={eyeOffIcon} alt='hide' />
                                                : <img src={eyeIcon} alt='show' />
                                        }
                                    </span>
                                    <ErrorMessage name="password" render={msg => <p className='formik_err_msg'>{msg}</p>} />
                                </div>
                                <div className='mb32 '>
                                    <p className='input_label'>REPEAT NEW PASSWORD</p>
                                    <Field
                                        type={isVisiblePassword1 ? 'text' : 'password'}
                                        name="confirmPassword" placeholder={'At least 8 characters'}
                                        component={FormikInput} />
                                    <span className='password_show_hide' onClick={() => setIsVisiblePassword1(p => !p)}>
                                        {
                                            isVisiblePassword1
                                                ? <img src={eyeOffIcon} alt='hide' />
                                                : <img src={eyeIcon} alt='show' />
                                        }
                                    </span>
                                    <ErrorMessage name="confirmPassword" render={msg => <p className='formik_err_msg'>{msg}</p>} />
                                </div>
                                {passwordSaved
                                    ?
                                    <>
                                        <p style={{ width: '100%', textAlign: 'center', marginBottom: '5px' }}>Password Saved</p>
                                        <PrimaryButton className='auth-submit-btn' type="submit">
                                            Login To Continue
                                        </PrimaryButton>
                                    </>
                                    : <PrimaryButton className='auth-submit-btn' type="submit"
                                        disabled={isSubmitting || errors.password || errors.confirmPassword}>
                                        {isSubmitting ?
                                            <Loader
                                                type="TailSpin"
                                                color="white"
                                                height={18}
                                                width={18}
                                            /> :
                                            'Continue'}
                                    </PrimaryButton>
                                }
                            </Form>
                        </div>
                    )}
                </Formik> : null
            }
            {isExpired && !isLoading ?
                <div className='signin_root authElemContainer'>
                    <div style={{ textAlign: 'center' }}>
                        <h5 style={{ color: 'white' }}>Link has expired!</h5>
                        <p onClick={handleClickReset} style={{ color: '#FF8D4E', textDecoration: 'underline', cursor: 'pointer', fontSize: '14px' }}>Resend Link</p>
                    </div>
                </div> : null
            }
        </>
    )
}


export default connect(state => ({
    email: state.auth.emailStep.email
}))(withRouter(SetNewPassword))
