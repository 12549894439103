import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import RangeComponent from "../../../components/RangeComponent/RangeComponent";
import {
  changeJobTotalExperience,
  setIsEdited,
  setJobSetupView,
} from "../../../actions/JobSetup";

import "./criteriaTotalExperience.scss";
import JobSetupStep07 from "../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep07";
import TimeInput from "../../../uiLibrary/TimeInput";
import NumberInput from "../../../uiLibrary/NumberInput";
import { Mixpanel } from "../../../utils/Mixpanel";

const CriteriaTotalExperience = ({
  totalExperience = [
    { min: 2, max: 5 },
    { min: 2, max: 5 },
  ],
  total_experience = [],
  cards,
  isReview,
  dispatch,
  jobCriteria,
}) => {
  const [myTimeout, setMyTimeout] = useState(null);

  const relMin = totalExperience?.[0]?.min ?? 2;
  const relMax = totalExperience?.[0]?.max ?? 5;
  const totMin = totalExperience?.[1]?.min ?? 2;
  const totMax = totalExperience?.[1]?.max ?? 5;

  useEffect(() => {
    Mixpanel.track("Experience");
    dispatch(
      setJobSetupView({
        selectedValues: [],
      })
    );
    console.log("DISPATCHING FROM HERE");

    console.log("TOTAL EXPERIENCE IS: ", totalExperience);

    const data = total_experience.map(
      (
        {
          min,
          max,
          detectedMax,
          detectedMin,
          source = [],
          sourceId = [],
          type,
          spans = [],
        },
        i
      ) => {
        console.log("<<<here")
        if (source.includes("jobDescription") && spans.length > 0 && i === 0) {
          dispatch(
            setJobSetupView({
              selectedValues: [
                {
                  detectedMax,
                  detectedMin,
                  spans,
                },
              ],
            })
          );
        } else if (i > 0) {
          dispatch(
            setJobSetupView({
              profileSelectedValues: { min, max },
            })
          );
        }
        return {
          min,
          max,
          detectedMax,
          detectedMin,
          spans,
          source,
          sourceId,
          type,
        };
      }
    );

    // console.log("DATA IS: ", data)
    // console.log("TOTAL_EXPERIENCE: ", total_experience)

    const picked = ["relevant", "total"].includes(totalExperience[0].type)
      ? totalExperience
      : total_experience;
    // console.log("PICKED IS: ", picked)

    console.log({ totalExperience, total_experience, picked });

    dispatch(changeJobTotalExperience(picked));
  }, []);

  const handleChange = ({ min, max }, index) => {
    dispatch(setIsEdited(true))
    dispatch(
      setJobSetupView({
        profileSelectedValues: { min, max },
      })
    );
    dispatch(
      changeJobTotalExperience([
        {
          ...totalExperience?.[0],
          min,
          max,
        },
        {
          ...totalExperience?.[1],
          min,
          max,
        },
      ])
    );
  };

  return (
    <div className="criteriaTotalExperienceContainer">
      <div className="criteriaTotalExperiencesHeader">
        <JobSetupStep07 selected={true} />
        <h2>Experience</h2>
      </div>
      <div className="criteriaTotalExperiencesBody">
        <span
          className="criteriaTotalExperiencesCounterContainer"
          onMouseEnter={(e) => {
            if (totalExperience?.[0]?.source?.includes("jobDescription")) {
              dispatch(
                setJobSetupView({
                  highlightValue: {
                    detectedMax: totalExperience?.[0]?.detectedMax,
                    detectedMin: totalExperience?.[0]?.detectedMin,
                  },
                })
              );
            }
          }}
          onMouseLeave={(e) => {
            if (totalExperience?.[0]?.source?.includes("jobDescription")) {
              dispatch(
                setJobSetupView({
                  highlightValue: {
                    detectedMax: "",
                    detectedMin: "",
                  },
                })
              );
            }
          }}
        >
          <p>From</p>
          <TimeInput
            onChange={(e, value) =>
              isReview === "1"
                ? false
                : handleChange({ min: parseInt(e.target.value), max: relMax })
            }
            style={{}}
            value={relMin}
            min={0}
            max={relMax}
            onFocus={(e) => false}
            onBlur={(e) => false}
            onIncrement={(e, value) =>
              isReview === "1"
                ? false
                : handleChange({
                    min: isNaN(value)
                      ? 0
                      : (isNaN(relMax) && value < 99) || value < relMax
                      ? value
                      : value - 1,
                    max: relMax,
                  })
            }
            onDecrement={(e, value) =>
              isReview === "1"
                ? false
                : handleChange({
                    min: isNaN(value) ? 0 : value >= 0 ? value : value + 1,
                    max: relMax,
                  })
            }
          />
          <p>to</p>
          <TimeInput
            onChange={(e, value) =>
              isReview === "1"
                ? false
                : handleChange({ max: parseInt(e.target.value), min: relMin })
            }
            style={{}}
            value={relMax}
            min={relMin}
            max={100}
            onFocus={(e) => false}
            onBlur={(e) => false}
            onIncrement={(e, value) =>
              isReview === "1"
                ? false
                : handleChange({
                    max:
                      isNaN(value) && isNaN(relMin)
                        ? 1
                        : isNaN(value)
                        ? relMin + 1
                        : value < 100
                        ? value
                        : value - 1,
                    min: relMin,
                  })
            }
            onDecrement={(e, value) =>
              isReview === "1"
                ? false
                : handleChange({
                    max:
                      isNaN(value) && isNaN(relMin)
                        ? 1
                        : isNaN(value)
                        ? relMin + 1
                        : (isNaN(relMin) && value > 0) || value > relMin
                        ? value
                        : value + 1,
                    min: relMin,
                  })
            }
          />
        </span>
        {/* <RangeInput
                    onMouseEnter={e => {
                        if (totalExperience?.[0]?.source?.includes('jobDescription')){
                            dispatch(setJobSetupView({
                                highlightValue: {
                                    detectedMax: totalExperience?.[0]?.detectedMax,
                                    detectedMin: totalExperience?.[0]?.detectedMin,
                                }
                            }))
                        }
                    }}
                    onMouseLeave={e => {
                        if (totalExperience?.[0]?.source?.includes('jobDescription')){
                            dispatch(setJobSetupView({
                                highlightValue: {
                                    detectedMax: "",
                                    detectedMin: "",
                                }
                            }))                            
                        }
                    }}
                    value={{
                        min: relMin,
                        max: relMax
                    }}
                    onChange={isReview === '1' ? () => false : e=>handleChange(e, 0)}
                /> */}
        {/* <p>
                    {`${relMin} to ${relMax} years`}
                </p> */}
      </div>
      {/* <div
                className='criteriaTotalExperiencesBody'
            >
                <h3>
                    Total Experience
                </h3>
                <RangeInput
                    onMouseEnter={e => {
                        dispatch(setJobSetupView({
                            profileHighlightValue: true
                        }))
                        if (totalExperience?.[1]?.source?.includes('jobDescription')){
                            dispatch(setJobSetupView({
                                highlightValue: {
                                    detectedMax: totalExperience?.[1]?.detectedMax,
                                    detectedMin: totalExperience?.[1]?.detectedMin,
                                }
                            }))
                        }
                    }}
                    onMouseLeave={e => {
                        dispatch(setJobSetupView({
                            profileHighlightValue: false
                        }))
                        if (totalExperience?.[1]?.source?.includes('jobDescription')){
                            dispatch(setJobSetupView({
                                highlightValue: {
                                    detectedMax: "",
                                    detectedMin: "",
                                }
                            }))                            
                        }
                    }}
                    value={{
                        min: totMin,
                        max: totMax
                    }}
                    onChange={isReview === '1' ? () => false : e=>handleChange(e, 1)}
                />
                <p>
                    {`${totMin} to ${totMax} years`}
                </p>
            </div> */}
    </div>
  );
};

export default connect((state) => ({
  totalExperience: state.jobSetup.jobCriteria.totalExperience,
  total_experience: state.jobSetup.jobSetup.jobSetup?.total_experience || [],
  cards: state.jobSetup.viewController.cards,
  isReview: state.jobSetup?.jobSetup?.isReview,
  jobCriteria: state.jobSetup.jobCriteria,
}))(CriteriaTotalExperience);
