import { flattenDeep, isEmpty, omit, uniqBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { callCompanyRecommenderApi } from '../../../utils/visitApiProfile'
import FindMoreCompaniesCards from '../components/FindMoreCompaniesCards'
import "./style.scss"
const FindMoreCompanies = ({
  jobCompanies = [],
  profileCompanies = [],
  newCompanies = [],
  onCompaniesChange = e => false,
  onAddCompanies = e => false,
  onRemoveCompanies = e => false,
  onFetchSimilarCompaniesProfile = () => { },
  // profileCompaniesLiked,
  // setProfileCompaniesLiked
}) => {

  // const [likedComps, setLikedComps] = useState([])
  // const [customTimeout, setCustomTimeout] = useState(null)

  // console.log("PROFILE COMPANIES ARE: ", profileCompanies)

  // const [lastLiked, setLastLiked] = useState('')
  // useEffect(async () => {
  //   const lastLikedCompany = profileCompanies.filter(item => item.urn == lastLiked)
  //   if (lastLikedCompany.length > 0) {
  //     const similarCompanies = await callCompanyRecommenderApi(lastLiked.split(':').pop())
  //     const profileCompanyUrns = profileCompanies.map(item => item.urn)
  //     const newSimilar = similarCompanies.filter(item => !profileCompanyUrns.includes(item.urn))
  //     onFetchSimilarCompaniesProfile([
  //       ...profileCompanies,
  //       ...newSimilar
  //     ])
  //     setLastLiked(null)
  //   }
  // }, [profileCompanies])

  // callCompanyRecommenderApi()
  // const fetchSimilarCompaniesLi = async (company) => {
  //   try {
  //     console.log("COMPANY URN: ", company.urn)

  //     // const urns = likedComps.map(item => item.split(':').pop())
  //     // setLikedComps([])
  //     // const similarCompanies = await callCompanyRecommenderApi(company.urn.split(':').pop())
  //     const similarCompanies = await callCompanyRecommenderApi(company.urn.split(':').pop())

  //     const updated = profileCompanies

  //     similarCompanies.forEach(c => {
  //       const existingUrns = profileCompanies.map(item => item.urn)
  //       if (!existingUrns.includes(c.urn)) {
  //         updated.push(c)
  //       }
  //     })

  //     console.log("UPDATED COMPANIES ARE: ", updated)

  //     onFetchSimilarCompaniesProfile(updated)

  //   } catch (e) {
  //     console.log("An error occured: ", e.message)
  //   }
  // }

  // useEffect(() => {
  //   if (!isEmpty(likedComps)) {
  //     // console.log("USE EFFECT OHOHO")
  //     if (customTimeout) {
  //       clearTimeout(customTimeout)
  //       setCustomTimeout(setTimeout(() => {
  //         fetchSimilarCompaniesLi()
  //       }, 500))
  //     } else {
  //       setCustomTimeout(setTimeout(() => {
  //         fetchSimilarCompaniesLi()
  //       }, 500))
  //     }
  //   }
  // }, [likedComps])

  return (
    <div
      className='findMoreTitles'
    >
      <FindMoreCompaniesCards
        heading="Previously Selected companies"
        companies={jobCompanies}
        onUpdate={(index, payload) => onCompaniesChange('jobCompanies', index, payload)}
      />
      <FindMoreCompaniesCards
        heading="Newly selected companies"
        // disabled={isLoadingSimilar}
        hasAddMore={true}
        onAdd={payload => onAddCompanies('profileCompanies', 0, payload)}
        companies={profileCompanies}
        previousCompanies={jobCompanies}
        onUpdate={(index, payload) => {
          console.log("on update payload: ", payload)
          onCompaniesChange('profileCompanies', index, payload)
          // if (payload.liked) {
          // setLikedComps([...likedComps, payload.urn])
          // fetchSimilarCompaniesLi(payload)
          // }
        }}
      />
      {/* <FindMoreCompaniesCards
                heading="Similar companies"
                companies={newCompanies}
                onUpdate={(index, payload)=>onCompaniesChange('newCompanies', index, payload)}
                onAdd={payload=>onAddCompanies('newCompanies', 0, payload)}
                hasAddMore={true}
            /> */}
    </div>
  )
}

export default connect(state => ({

}))(FindMoreCompanies)
