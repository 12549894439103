import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'
import { v4 } from 'uuid'
import LocationSvg from '../../svgComponents/LocationSvg'

import './SvgAndDescription.scss'

const SvgAndDescription = ({
    
    svg,
    description,
    initials,
    createdDate,
    jobCreatorName,
    tooltipDescription=false
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [tooltipKey, setTooltipKey] = useState(`svgAndDescription${v4()}`)

    return (
        <React.Fragment>
            <div
                className="svgAndDescription"
                id={tooltipKey}
            >
                {svg}
                {initials ?
                    <p>
                        <span>{`Created ${createdDate} by `}</span>
                        {(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(jobCreatorName)) && <br/>}
                        <span  className="initials">
                             {initials}
                        </span>
                        <span> {jobCreatorName}</span>
                    </p>
                    :
                    <p>
                        {description}
                     </p>    
            }
            </div>
            {tooltipDescription && <Tooltip
                placement="bottom"
                isOpen={isOpen}
                target={tooltipKey}
                toggle={()=>setIsOpen(prev=>!prev)}
                fade={false}
            >
                {tooltipDescription}
            </Tooltip>}
       </React.Fragment>
    )
}

export default SvgAndDescription
