import React, { useState } from "react";
import { connect } from "react-redux";
import EditFlowTabs from "../EditFlowTabs/EditFlowTabs";
import "./ScoreAndConnectHeader.scss";
import LogoSvg from "../../components/svgComponents/LogoSvg";
import qluIcon from "../../img/qlu-icon.png";
import ScoreAndConnectDropdown from "./ScoreAndConnectDropdown";
import { useParams } from "react-router-dom";
import { Navbar, NavbarToggler } from "reactstrap";
import qs from "qs";
import { withRouter } from "react-router-dom";
import { setSubDropdownView } from "../../actions/prospectsView";
import Loader from "react-loader-spinner";
import { commentNotificationList } from "../../constants/notification";
import { omit } from "lodash";
import { Mixpanel } from "../../utils/Mixpanel";

const ScoreAndConnectHeader = ({
  headerToggle,
  headerIsOpen,
  history: { push, goBack },
  location: { pathname, search },
  funnel,
  jobTitle,
  currentTab,
  isEdit = false,
  jobId = null,
  label,
  labelLoading,
  permissionsAllowed,
  prospects,
  isLoading,
  dispatch,
}) => {
  const qParams = qs.parse(search.split("?").pop());
  const funnelMap = {
    "": "added",
    a: "added",
    r: "replied",
    m: "messaged",
  };

  // const profileTypes = Object.keys(funnel).map((key, i) => ({ name: key, id: i, count: key === 'messaged'?  0 :  }))

  const profileTypes = [
    {
      name: "added",
      count: funnel?.added,
      id: 1,
      value: "a",
    },
    {
      name: "messaged",
      count: funnel?.messagedCount,
      id: 2,
      value: "m",
    },
    {
      name: "replied",
      count: funnel?.replied,
      id: 3,
      value: "r",
    },
  ];
  let hasIsQuick = useParams()?.isQuick
  if(hasIsQuick === 'false'){
    hasIsQuick = false
  }
  const messageTypes = funnel?.messaged?.map((item) => ({
    name: item.round_no === 0 ? "First Message" : `Followup ${item.round_no}`,
    count: item.count,
    id: item.round_no,
    value: item.round_no,
  }));

  const [msgFilter, setMsgFilter] = useState(true);
  const newParams = {
    ...omit(qParams, [...commentNotificationList]),
  };
  console.log("newParams: ", newParams);
  const messageBtnClicked = (activeFunnel) => {
    const params = qs.parse(search.split("?").pop());

    // if (search.includes("f") && params.f === activeFunnel) {
    //   push(`${pathname}?${qs.stringify({ ...omit(newParams, 'f'), p: 0 })}`);
    // } else {
    push(
      `${pathname}?${qs.stringify({ ...newParams, f: activeFunnel, p: 0 })}`
    );
    // }
  };
  const backMessageBtn = (item) => {
    setMsgFilter(true);
  };

  const subDropDownMenu = ["Prospects", "Job Setup", "My Tribe", "Campaigns"].filter(link => link === "Job Setup" ? !hasIsQuick : true);
  const [currValue, setcurrValue] = useState("Prospects");
  const dotedDropDownMenu = [
    "Add a label",
    // "Download all prospects",
    "Edit",
    "Duplicate",
    "Delete",
  ];
  const [selectedValueOfDotted, setselectedValueOfDotted] = useState("");
  let { jId, isQuick = false } = useParams();
  if (isQuick === 'false') { isQuick = false }
  const activeFunnel = funnelMap[qParams?.f];
  const handlePaginate = (page) => {
    const p = parseInt(qParams?.p) || 0;

    push(`/job/${jId}?${qs.stringify({ ...newParams, p: page + 1 })}`);
  };

  return (
    <div>
      <Navbar expand="lg" className="headerNavbar">
        <div className="leftSideHeader">
          <span onClick={(e) => push("/")} className="leftSideHeaderLogo">
            <img src={qluIcon} alt="N/A" />
          </span>
          <div>
            <div className="headerLeftInfo">
              <p>{jobTitle}</p>
              {labelLoading ? (
                <span style={{ marginLeft: "15px" }}>
                  <Loader
                    type="TailSpin"
                    color="#FF8D4E"
                    width={32}
                    height={32}
                  />
                </span>
              ) : (
                  ""
                )}
              {label && labelLoading === false ? (
                <div className="tribeCharacter">
                  {/* <TribeCharacterScg
                  style={{ marginLeft: "3px", marginRight: "3px" }}
                /> */}
                  <p>{label}</p>
                </div>
              ) : (
                  ""
                )}
              <ScoreAndConnectDropdown
                qParams={qParams}
                currValue={selectedValueOfDotted}
                dropDownMenu={dotedDropDownMenu}
                setcurrValue={setselectedValueOfDotted}
                isDotted={true}
                permissionsAllowed={permissionsAllowed}
              />
            </div>
            {console.log('LOGGED ON LINE 155')}
            <div className="subDropDownMenuLink">
              {/* <UncontrolledButtonDropdown>
                <DropdownToggle>
                  {currValue} <CaretDown color="#6C6C6C" />
                </DropdownToggle>
                <DropdownMenu>
                  {subDropDownMenu.map((link, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() => {
                        setcurrValue(link);
                        dispatch(setSubDropdownView(link));
                      }}
                    >
                      {link}
                    </DropdownItem>
                  ))}
                  <DropdownItem onClick={() => {}}>Delete</DropdownItem> 
                </DropdownMenu>
              </UncontrolledButtonDropdown> */}
              {subDropDownMenu.map((link, index) => (
                <div
                  key={index}
                  className={`${(currentTab == link && isEdit === false) ||
                      (isEdit && link === "Job Setup")
                      ? "active"
                      : ""
                    }`}
                  onClick={(e) => {
                    if (!jobTitle) {
                      return false;
                    }
                    if(subDropDownMenu.filter(l=>l!=='Prospects').includes(link)){
                      Mixpanel.track(link)
                    }

                    // if (hasIsQuick) {
                    //   return push("/");
                    // }
                    // if (
                    //   currentTab.trim().toLowerCase() ==
                    //   e?.target?.textContent.trim().toLowerCase()
                    // ) {
                    //   console.log("onClick");
                    //   return;
                    // }

                    if (isLoading) {
                      return;
                    }
                    if (isEdit && link === "Job Setup") {
                      return;
                    }
                    if (link === "Job Setup") {
                      // {console.log('CALL THE LINES BELOW')}
                      dispatch(setSubDropdownView(link));
                      push(`/jobSetup?edit=true&jId=${jId}${qParams?.aT?`&aT=${qParams?.aT}`:""}`);
                    } else {
                      isEdit
                        ? (() => {
                          goBack();
                          dispatch(setSubDropdownView(link));
                        })()
                        : dispatch(setSubDropdownView(link));
                    }
                  }}
                >
                  {link}
                  {console.log(isEdit, "iSeDIT")}
                  {console.log(link,"LINK")}
                </div>
              ))}
            </div>
          </div>
        </div>

        {isEdit || (
          <EditFlowTabs
            profileViewTypes={profileTypes}
            setProfileViewTypes={messageBtnClicked}
            messageType={messageTypes}
            msgfilter={msgFilter}
            historyPush={push}
            location={{ pathname, search }}
            backMessageBtn={backMessageBtn}
            activeFunnel={activeFunnel}
          />
        )}

        {/* <div className="headerRightButtons" style={{ borderRight: "nones" }}>
          <button>
            <CalanderScg />
          </button>
          <button>
            <BriefcaseSvg />
          </button>
          <RectangleSvg width="1px" height="24px" />
          <span />
          <button>
            <SearchSvg />
          </button>
          
        </div> */}

        <NavbarToggler onClick={headerToggle} />
      </Navbar>
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    currentTab: state.prospectsView.subDropDownReducer.currentTab,
    label: state.prospectsView.candidates.job.meta?.label,
    labelLoading: state.prospectsView.candidates.labelLoading,
    permissionsAllowed: state.prospectsView.candidates.permissions,
    prospects: state.prospectsView.candidates.prospects,
    isLoading: state.prospectsView.candidates.isLoading,
  }))(ScoreAndConnectHeader)
);
