import React, { useState } from "react";
import ThumbButton from "../ThumbButton/ThumbButton";
import "./IdealProfileTopContainer.scss";
import ProfileDisplayImage from "../../ProfileDisplayImage/ProfileDisplayImage";
import CarouselTag from "../../../uiLibrary/CarouselTag";

const IdealProfileTopContainer = ({
  image_url,
  full_name,
  experience,
  title,
  current_company_name,
  education,
  locationName,
  getState,
  toggleState,
  isMainTitle = false,
}) => {
  const currentTitleState = getState(title, "titles");
  const currentCompanyState = getState(current_company_name, "companies");
  const experienceSpanState = getState(experience, "experienceSpans");
  const educationState = getState(education, "educations", "schoolName");

  console.log(
    "============currentTitleState===============",
    currentTitleState
  );
  return (
    <span className="idealProfile_TopPanel">
      <span className="idealProfile_LeftPanel">
        <ProfileDisplayImage src={image_url} width="64px" height="64px" />
        <div className="idealProfile_CurrentDetails">
          <h1>{full_name}</h1>
          <span>
            {title && (
              <CarouselTag
                type="experienceTitle"
                greyTheme={currentTitleState?.greyTheme}
                selected={currentTitleState?.selected}
                highlight={currentTitleState?.highlight}
                onClick={(e) => toggleState(currentTitleState, title, "titles")}
                isMainTitle={isMainTitle}
              >
                {title}
              </CarouselTag>
            )}
            {title && current_company_name && (
              <p
                style={{
                  margin: `0 ${currentCompanyState?.selected ? "8px" : "0"} 0 ${
                    currentTitleState?.selected || currentTitleState?.greyTheme
                      ? "8px"
                      : "0"
                  }`,
                }}
              >
                @
              </p>
            )}
            {current_company_name && (
              <CarouselTag
                type="experienceCompanyName"
                description={current_company_name}
                selected={currentCompanyState?.selected}
                highlight={currentCompanyState?.highlight}
                onClick={(e) =>
                  toggleState(
                    currentCompanyState,
                    current_company_name,
                    "companies"
                  )
                }
              >
                {current_company_name}
              </CarouselTag>
            )}
          </span>
          <h5>{locationName}</h5>
        </div>
      </span>

      <div className="idealProfile_GeneralInfo">
        <CarouselTag
          type="yearsOfExperience"
          description={`${experience} year(s) of total experience`}
          selected={experienceSpanState?.selected}
          highlight={experienceSpanState?.highlight}
          onClick={(e) =>
            toggleState(experienceSpanState, experience, "experienceSpans")
          }
        >
          {`${experience ?? 0} year(s) of total experience`}
        </CarouselTag>
        <CarouselTag
          type="educationSchoolName"
          description={education !== null ? `Studied from ${education}` : ""}
          selected={educationState?.selected}
          highlight={educationState?.highlight}
          onClick={(e) =>
            toggleState(educationState, education, "educations", "schoolName")
          }
        >
          {education !== null ? `Studied from ${education}` : ""}
        </CarouselTag>
      </div>
    </span>
  );
};

export default IdealProfileTopContainer;
