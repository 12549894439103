import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  Input,
  FormGroup,
  Modal,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Toggle from "react-toggle";
import "react-toggle/style.css";

import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

import ScoringFilterSection from "./ScoringFilterSection";
// import FilterTemplate from "./FilterTemplate"

import "./advanceFilterSection.scss";
import "./filterByBoolean.scss";

import qs from "query-string";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PlusSvg from "../../../uiLibrary/SVGs/PlusSvg";

import {
  setSkillsFilters,
  toggleStrictSkills,
  toggleStrictSpecialties,
  setStrictSkills,
  setSchoolFilters,
  setEducationFilters,
  toggleStrictEducation,
  setCurrentTitleFilters,
  toggleStrictCurrentTitle,
  setPastTitleFilters,
  toggleStrictPastTitle,
  setCurrentCompanyFilters,
  setPastCompanyFilters,
  setCompanySizeFilter,
  toggleCompanySizeFilter,
  setCurrentIndustryFilters,
  setPastIndustryFilters,
  setLocationFilters,
  toggleStrictLocation,
  setVisaFilters,
  setTotalExperienceFilter,
  setRelevantExperienceFilter,
  toggleTotalExperienceFilter,
  toggleRelevantExperienceFilter,
  clearAdvancedFilters,
  applyADVFiltersTemplate,
  setTemplate,
  clearProspects,
  clearScoringFilters,
  toggleAdvFiters,
  setBooleanFilters,
} from "../../../actions/prospectsView";

import Autosuggest from "react-autosuggest";

import { manualApiCall } from "../../../utils";

import EliminateIcon from "../../../components/svgComponents/EliminateIcon";
import TickSvg from "../../../components/svgComponents/TickSvg";
import { callLinkedinApi } from "../../../utils/MessagePassing";
import { debounce, omit } from "lodash";
import { commentNotificationList } from "../../../constants/notification";

const visaArray = [
  {
    id: 0,
    name: "H1b",
  },
  {
    id: 1,
    name: "Green Card",
  },
  {
    id: 2,
    name: "Citizen",
  },
  {
    id: 3,
    name: "OPT",
  },
];

const compnaySizeRangeArray = [
  {
    id: 0,
    value: {
      min: 1,
      max: 10,
    },
  },
  {
    id: 1,
    value: {
      min: 11,
      max: 50,
    },
  },
  {
    id: 2,
    value: {
      min: 51,
      max: 200,
    },
  },
  {
    id: 3,
    value: {
      min: 201,
      max: 500,
    },
  },
  {
    id: 4,
    value: {
      min: 501,
      max: 1000,
    },
  },
  {
    id: 5,
    value: {
      min: 1001,
      max: 5000,
    },
  },
  {
    id: 6,
    value: {
      min: 5001,
      max: 10000,
    },
  },
  {
    id: 7,
    value: {
      min: 10001,
      max: 999999999,
    },
  },
];

const getSuggestionValue = (suggestion) => suggestion;
const renderSuggestion = (suggestion) => (
  <div className="renderedSuggestionContainer"> {suggestion} </div>
);

const AdvancedFilterPillButton = (props) => {
  const {
    keyId,
    activeState = 0,
    filterName = "",
    handleAdvancedFilterPillHighlight,
    handleAdvancedFilterPillEliminate,
    filterIndex,
  } = props;

  return (
    <div
      key={keyId}
      className={`${
        activeState === 2 ? "AdvancedFilterPillContainerEliminate " : ""
      }AdvancedFilterPillButtonContainer`}
    >
      <Button
        className={`${
          activeState === 1
            ? "AdvancedFilterPillHighlight "
            : activeState === 2
            ? "AdvancedFilterPillEliminate "
            : ""
        }AdvancedFilterPillButtonText`}
        outline
        color="primary"
        onClick={(e) =>
          handleAdvancedFilterPillHighlight(activeState, filterName)
        }
      >
        {filterName}
      </Button>
      <Button
        className={`${
          activeState === 1
            ? "AdvancedFilterEliminateInactive "
            : activeState === 2
            ? "AdvancedFilterEliminateActive "
            : ""
        }AdvancedFilterPillButtonEliminate`}
        outline
        color="danger"
        onClick={(e) =>
          handleAdvancedFilterPillEliminate(activeState, filterName)
        }
      >
        {activeState === 2 ? (
          <EliminateIcon color="white" />
        ) : (
          <EliminateIcon />
        )}
      </Button>
      <Button
        className={`${
          activeState === 1
            ? "AdvancedFilterPillHighlight "
            : activeState === 2
            ? "AdvancedFilterPillEliminate "
            : ""
        }AdvancedFilterPillButtonHighlight`}
        outline
        color="primary"
        onClick={(e) =>
          handleAdvancedFilterPillHighlight(activeState, filterName)
        }
      >
        {activeState === 1 ? <TickSvg /> : <TickSvg color="#A7ABB0" />}
      </Button>
    </div>
  );
};

const FilterByBoolean = ({
  Show,
  setShow,
  history: { push },
  location: { pathname, search },
  dispatch,
  setSelectAllProspectsFlag,

  locations = [],
  skills,
  industries,
  educations,
  companies,
  schools,
  titles,
  templates,
  ActiveTemplate,

  AdvFilters,
  activeTab,

  ScoringFilters,

  titleFlag,
  skillFlag,
  industryFlag,
  experienceFlag,
  educationFlag,
  adminFlag,
  filters,
  booleanTerm,
}) => {
  const [schoolsTextBox, setSchoolsTextBox] = useState("");
  const [schoolsSuggestions, setSchoolsSuggestions] = useState([]);
  const [skillsTextBox, setSkillsTextBox] = useState("");
  const [titleCurrentTextBox, setCurrentTitleTextBox] = useState("");
  const [titlePastTextBox, setPastTitleTextBox] = useState("");
  const [companyCurrentTextBox, setCurrentCompanyTextBox] = useState("");
  const [companyPastTextBox, setPastCompanyTextBox] = useState("");
  const [industryCurrentTextBox, setCurrentIndustryTextBox] = useState("");
  const [industryPastTextBox, setPastIndustryTextBox] = useState("");
  const [LocationTextBox, setLocationTextBox] = useState("");
  const [EducationTextBox, setEducationTextBox] = useState("");

  const [skillsSuggestions, setSkillsSuggestions] = useState([]);
  const [CurrentTitleSuggestions, setCurrentTitleSuggestions] = useState([]);
  const [PastTitleSuggestions, setPastTitleSuggestions] = useState([]);
  const [CurrentCompanySuggestions, setCurrentCompanySuggestions] = useState(
    []
  );
  const [PastCompanySuggestions, setPastCompanySuggestions] = useState([]);
  const [CurrentIndustrySuggestions, setCurrentIndustrySuggestions] = useState(
    []
  );
  const [PastIndustrySuggestions, setPastIndustrySuggestions] = useState([]);
  const [EducationSuggestions, setEducationSuggestions] = useState([]);
  const [LocationSuggestions, setLocationSuggestions] = useState([]);

  const [Drop1, setDrop1] = useState(false);

  const sSOrRef = useRef(null);
  const sSAndRef = useRef(null);

  const advFilterList = [
    "lS",
    "sS",
    "eS",
    "eSS",
    "eCT",
    "ePT",
    "eCC",
    "ePC",
    "eCI",
    "ePI",
    "eL",
    "eV",
    "hS",
    "hSS",
    "eE",
    "hE",
    "hCT",
    "hPT",
    "hCC",
    "hPC",
    "hCI",
    "hPI",
    "hL",
    "hV",
    "tE",
    "rE",
    "eCSR",
    "hCSR",
    "cTS",
    "pTS",
    "eDS",
  ];
  const scrFilterList = ["tSF", "sSF", "expSF", "edSF", "iSF"];

  const filterTemplate = "no templates";

  useEffect(() => {
    dispatch(
      setLocationFilters(
        locations.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setSkillsFilters(
        skills.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(setSchoolFilters(schools.map((itm) => ({ name: itm, value: 0 }))));
    dispatch(
      setEducationFilters(
        educations.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setCurrentTitleFilters(
        titles.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setPastTitleFilters(
        titles.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setCurrentCompanyFilters(
        companies.map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setPastCompanyFilters(companies.map((itm) => ({ name: itm, value: 0 })))
    );
    dispatch(
      setCurrentIndustryFilters(
        industries.map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setPastIndustryFilters(industries.map((itm) => ({ name: itm, value: 0 })))
    );
  }, []);

  useEffect(() => {
    console.log(filters);
  }, [filters]);

  const getTitleSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : [
          value,
          ...titles.filter(
            (itm) =>
              itm.trim().toLowerCase().startsWith(inputValue) &&
              itm.trim().toLowerCase() !== inputValue
          ),
        ];
  };
  const getSkillsSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : skills.filter((itm) => itm.trim().toLowerCase().startsWith(inputValue));
  };

  const getLocationSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : [
          value,
          ...locations.filter(
            (itm) =>
              itm.trim().toLowerCase().startsWith(inputValue) &&
              itm.trim().toLowerCase() !== inputValue
          ),
        ];
  };

  const getEducationSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : [
          value,
          ...educations.filter(
            (itm) =>
              itm.trim().toLowerCase().startsWith(inputValue) &&
              itm.trim().toLowerCase() !== inputValue
          ),
        ];
  };

  const onSkillsSuggestionsFetchRequested = ({ value }) =>
    setSkillsSuggestions(getSkillsSuggestions(value));
  const onSkillsSuggestionsClearRequested = () => setSkillsSuggestions([]);

  const onSkillsSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    dispatch(
      setSkillsFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.skills.filter((itm) => itm.name !== suggestionValue),
      ])
    );

    setSkillsTextBox("");
  };
  const inputSkillsProps = {
    placeholder: `Search for a ${"Skill"}`,
    value: skillsTextBox,
    onChange: (e, { newValue }) => {
      setSkillsTextBox(newValue);
    },
  };

  const inputCurrentTitleProps = {
    placeholder: `Search for a ${"title"}`,
    value: titleCurrentTextBox,
    onChange: (e, { newValue }) => setCurrentTitleTextBox(newValue),
  };
  const inputPastTitleProps = {
    placeholder: `Search for a ${"title"}`,
    value: titlePastTextBox,
    onChange: (e, { newValue }) => setPastTitleTextBox(newValue),
  };

  const onLocationSuggestionsFetchRequested = ({ value }) =>
    setLocationSuggestions(getLocationSuggestions(value));
  const onLocationSuggestionsClearRequested = () => setLocationSuggestions([]);

  const onLocationSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    dispatch(
      setLocationFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.location.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    setLocationTextBox("");
  };

  const inputLocationProps = {
    placeholder: `Search for a ${"Location"}`,
    value: LocationTextBox,
    onChange: (e, { newValue }) => setLocationTextBox(newValue),
  };

  const onEducationSuggestionsFetchRequested = ({ value }) =>
    setEducationSuggestions(getEducationSuggestions(value));
  const onEducationSuggestionsClearRequested = () =>
    setEducationSuggestions([]);

  const onEducationSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    dispatch(
      setEducationFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.education.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    setEducationTextBox("");
  };

  const inputEducationProps = {
    placeholder: `Search for a ${"Education Degree"}`,
    value: EducationTextBox,
    onChange: (e, { newValue }) => setEducationTextBox(newValue),
  };

  const onSchoolsSuggestionsFetchRequested = useCallback(
    debounce(async ({ value }) => {
      try {
        const response = await new Promise((resolve) =>
          callLinkedinApi(
            `/voyager/api/typeahead/hitsV2?keywords=${encodeURIComponent(
              value
            )}&origin=OTHER&q=type&type=SCHOOL`,
            {
              method: "GET",
              headers: {
                "x-li-lang": "en_US",
                "x-restli-protocol-version": "2.0.0",
              },
            },
            (r) => resolve(r)
          )
        );

        const schoolSuggestions = response.elements.map(
          (item) => item.text.text
        );
        // setIndustrySuggestions(industrySuggestions)
        setSchoolsSuggestions(schoolSuggestions);
      } catch (e) {
        throw e;
      }
    }, 200),
    []
  );

  const onSchoolsSuggestionsClearRequested = () => setSchoolsSuggestions([]);

  const onSchoolsSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    dispatch(
      setSchoolFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.school.filter((itm) => itm.name !== suggestionValue),
      ])
    );

    setSchoolsTextBox("");
  };
  const inputSchoolsProps = {
    placeholder: `Search for a ${"school"}`,
    value: schoolsTextBox,
    onChange: (e, { newValue }) => setSchoolsTextBox(newValue),
  };

  const onCurrentCompanySuggestionsFetchRequested = useCallback(
    debounce(async ({ value }) => {
      try {
        const response = await new Promise((resolve) =>
          callLinkedinApi(
            `/voyager/api/typeahead/hitsV2?keywords=${encodeURIComponent(
              value
            )}&origin=OTHER&q=type&type=COMPANY`,
            {
              method: "GET",
              headers: {
                "x-li-lang": "en_US",
                "x-restli-protocol-version": "2.0.0",
              },
            },
            (r) => resolve(r)
          )
        );

        const companySuggestions = response.elements.map(
          (item) => item.text.text
        );
        // setIndustrySuggestions(industrySuggestions)
        setCurrentCompanySuggestions(companySuggestions);
      } catch (e) {
        throw e;
      }
    }, 200),
    []
  );

  const onPastCompanySuggestionsFetchRequested = useCallback(
    debounce(async ({ value }) => {
      try {
        const response = await new Promise((resolve) =>
          callLinkedinApi(
            `/voyager/api/typeahead/hitsV2?keywords=${encodeURIComponent(
              value
            )}&origin=OTHER&q=type&type=COMPANY`,
            {
              method: "GET",
              headers: {
                "x-li-lang": "en_US",
                "x-restli-protocol-version": "2.0.0",
              },
            },
            (r) => resolve(r)
          )
        );

        const companySuggestions = response.elements.map(
          (item) => item.text.text
        );
        // setIndustrySuggestions(industrySuggestions)
        setPastCompanySuggestions(companySuggestions);
      } catch (e) {
        throw e;
      }
    }, 200),
    []
  );

  const onCurrentCompanySuggestionsClearRequested = () =>
    setCurrentCompanySuggestions([]);

  const onCurrentCompanySuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    dispatch(
      setCurrentCompanyFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.currentCompany.filter(
          (itm) => itm.name !== suggestionValue
        ),
      ])
    );
    setCurrentCompanyTextBox("");
  };

  const onPastCompanySuggestionsClearRequested = () =>
    setPastCompanySuggestions([]);

  const onPastCompanySuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    dispatch(
      setPastCompanyFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.pastCompany.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    setPastCompanyTextBox("");
  };

  const inputCurrentCompanyProps = {
    placeholder: `Search for a ${"company"}`,
    value: companyCurrentTextBox,
    onChange: (e, { newValue }) => setCurrentCompanyTextBox(newValue),
  };

  const inputPastCompanyProps = {
    placeholder: `Search for a ${"company"}`,
    value: companyPastTextBox,
    onChange: (e, { newValue }) => setPastCompanyTextBox(newValue),
  };

  const onCurrentTitleSuggestionsFetchRequested = ({ value }) =>
    setCurrentTitleSuggestions(getTitleSuggestions(value));
  const onCurrentTitleSuggestionsClearRequested = () =>
    setCurrentTitleSuggestions([]);

  const onCurrentTitleSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    dispatch(
      setCurrentTitleFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.currentTitle.filter(
          (itm) => itm.name !== suggestionValue
        ),
      ])
    );
    setCurrentTitleTextBox("");
  };
  const onPastTitleSuggestionsFetchRequested = ({ value }) =>
    setPastTitleSuggestions(getTitleSuggestions(value));
  const onPastTitleSuggestionsClearRequested = () =>
    setPastTitleSuggestions([]);

  const onPastTitleSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    dispatch(
      setPastTitleFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.pastTitle.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    // setTitleFilterList([
    //     {
    //         name: suggestionValue,
    //         value: true
    //     },
    //     ...titleFilterList.filter(itm => itm.name !== suggestionValue)
    // ])
    setPastTitleTextBox("");
  };

  const handleAdvancedFilterClear = () => {
    dispatch(clearAdvancedFilters());
    dispatch(clearScoringFilters());
    // dispatch(setTemplate(''))

    manualApiCall("/api/auth/user/activity/store", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ actionName: "CLEAR_ADVANCED_FILTER" }),
    });

    const params = qs.parse(search);

    const newParams = omit(params, [
      ...advFilterList,
      ...scrFilterList,
      ...commentNotificationList,
    ]);

    dispatch(clearProspects());

    push(`${pathname}?${qs.stringify(newParams)}`);
  };

  const handleAdvancedFilterCancel = () => {
    dispatch(clearAdvancedFilters());
    dispatch(clearScoringFilters());
    // // dispatch(setTemplate(''))

    dispatch(toggleAdvFiters());
  };

  const handleClickApply = () => {};

  const filterSelections = [
    { name: "Skills", type: "typeahead" },
    { name: "Schools", type: "typeahead" },
    { name: "Education", type: "typeahead" },
    { name: "Current Title", type: "typeahead" },
    { name: "Past Title", type: "typeahead" },
    { name: "Current Company", type: "typeahead" },
    { name: "Past Company", type: "typeahead" },
    { name: "Current Employee Count", type: "pills" },
    // { name: 'Total Experience', type: 'range' },
    // { name: 'Relevant Experience', type: 'range' },
    { name: "Location", type: "typeahead" },
    { name: "Visa Type", type: "pills" },
  ];

  const suggestionBoxFilters = (
    type,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    getSuggestionValue,
    renderSuggestion,
    inputProps,
    data,
    handleAdvancedFilterPillEliminate,
    handleAdvancedFilterPillHighlight,
    value,
    index,
    label,
    filterType,
    outerIndex
  ) => {
    const checkActiveState = (name) => {
      let eliminateIndex = filters[outerIndex][index]["eliminate"].findIndex(
        (item) => item === name
      );
      let highlightIndex = filters[outerIndex][index]["highlight"].findIndex(
        (item) => item === name
      );

      return eliminateIndex > -1 ? 2 : highlightIndex > -1 ? 1 : 0;
    };

    return (
      <FormGroup className="suggestionFormGroupContainer">
        {filters[outerIndex][index].selectedOne === false ? (
          <>
            {filterType === "typeahead" ? (
              <Autosuggest
                theme={{
                  input: "adv-filter-ip",
                  suggestion: "suggestion-item",
                  suggestionsContainer: "autosuggest-suggestions-container",
                }}
                suggestions={type}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{
                  placeholder: `Search for a ${label}`,
                  value: value,
                  onChange: (e, { newValue }) => {
                    filters[outerIndex][index]["value"] = newValue;
                    dispatch(setBooleanFilters(filters));
                  },
                }}
              />
            ) : (
              <></>
            )}

            {filterType === "typeahead" || filterType === "pills" ? (
              <div className="AdvancedVisaFilterPillContainer">
                {data.map((itm, idx) => (
                  <AdvancedFilterPillButton
                    key={idx}
                    activeState={checkActiveState(itm.name)}
                    filterName={itm.name}
                    filterIndex={index}
                    handleAdvancedFilterPillEliminate={
                      handleAdvancedFilterPillEliminate
                    }
                    handleAdvancedFilterPillHighlight={
                      handleAdvancedFilterPillHighlight
                    }
                  />
                ))}
              </div>
            ) : (
              ""
            )}

            {filterType === "range" ? (
              <>
                <Label className="sliderFilterLablePosition sliderFilterLable">
                  {`${filters[index]["range"]?.min} to ${filters[index]["range"]?.max} Years`}
                </Label>
                <FormGroup className="AdvancedVisaFilterPillContainer">
                  <div className="advExpSliderContainer">
                    <InputRange
                      maxValue={30}
                      minValue={0}
                      // formatLabel={value => `${value} Yrs`}
                      value={filters[outerIndex][index]["range"]}
                      onChange={(value) => {
                        filters[outerIndex][index]["range"] = value;
                        dispatch(setBooleanFilters(filters));
                      }}
                    />
                  </div>
                </FormGroup>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            {filterType === "typeahead" || filterType === "pills" ? (
              <div className="AdvancedVisaFilterPillContainer">
                {data.map((itm, idx) => (
                  <>
                    {checkActiveState(itm.name) === 1 ||
                    checkActiveState(itm.name) === 2 ? (
                      <AdvancedFilterPillButton
                        key={idx}
                        activeState={checkActiveState(itm.name)}
                        filterName={itm.name}
                        filterIndex={index}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterPillEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterPillHighlight
                        }
                      />
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </FormGroup>
    );
  };

  const uiRendererFilters = (type, value, index, outerIndex) => {
    let filterType = filterSelections.find((item) => item.name === type);

    const eliminateHandler = (activeState, newValue) => {
      let foundIndex = filters[outerIndex][index]["eliminate"].findIndex(
        (item) => item === newValue
      );

      if (foundIndex < 0) {
        filters[outerIndex][index].highlight = filters[outerIndex][
          index
        ].highlight.filter((item) => item !== newValue);
        filters[outerIndex][index].eliminate.push(newValue);
        filters[outerIndex][index].selectedOne = true;
        dispatch(setBooleanFilters(filters));
      } else {
        filters[outerIndex][index].eliminate = filters[outerIndex][
          index
        ].eliminate.filter((item) => item !== newValue);
        filters[outerIndex][index].selectedOne = false;
        dispatch(setBooleanFilters(filters));
      }
    };

    const highlightHandler = (activeState, newValue) => {
      let foundIndex = filters[outerIndex][index]["highlight"].findIndex(
        (item) => item === newValue
      );

      if (foundIndex < 0) {
        filters[outerIndex][index].eliminate = filters[outerIndex][
          index
        ].eliminate.filter((item) => item !== newValue);
        filters[outerIndex][index].highlight.push(newValue);
        filters[outerIndex][index].selectedOne = true;
        dispatch(setBooleanFilters(filters));
      } else {
        filters[outerIndex][index].highlight = filters[outerIndex][
          index
        ].highlight.filter((item) => item !== newValue);
        filters[outerIndex][index].selectedOne = false;
        dispatch(setBooleanFilters(filters));
      }
    };

    switch (type) {
      case "Skills":
        return suggestionBoxFilters(
          skillsSuggestions,
          onSkillsSuggestionsFetchRequested,
          onSkillsSuggestionsClearRequested,
          onSkillsSuggestionSelected,
          getSuggestionValue,
          renderSuggestion,
          inputSkillsProps,
          AdvFilters.skills,
          eliminateHandler,
          highlightHandler,
          value,
          index,
          type,
          filterType.type,
          outerIndex
        );
        break;
      case "Schools":
        return suggestionBoxFilters(
          schoolsSuggestions,
          onSchoolsSuggestionsFetchRequested,
          onSchoolsSuggestionsClearRequested,
          onSchoolsSuggestionSelected,
          getSuggestionValue,
          renderSuggestion,
          inputSchoolsProps,
          AdvFilters.school,
          eliminateHandler,
          highlightHandler,
          value,
          index,
          type,
          filterType.type,
          outerIndex
        );
        break;
      case "Education":
        return suggestionBoxFilters(
          EducationSuggestions,
          onEducationSuggestionsFetchRequested,
          onEducationSuggestionsClearRequested,
          onEducationSuggestionSelected,
          getSuggestionValue,
          renderSuggestion,
          inputEducationProps,
          AdvFilters.education,
          eliminateHandler,
          highlightHandler,
          value,
          index,
          type,
          filterType.type,
          outerIndex
        );
        break;
      case "Current Title":
        return suggestionBoxFilters(
          CurrentTitleSuggestions,
          onCurrentTitleSuggestionsFetchRequested,
          onCurrentTitleSuggestionsClearRequested,
          onCurrentTitleSuggestionSelected,
          getSuggestionValue,
          renderSuggestion,
          inputCurrentTitleProps,
          AdvFilters.currentTitle,
          eliminateHandler,
          highlightHandler,
          value,
          index,
          type,
          filterType.type,
          outerIndex
        );
        break;
      case "Past Title":
        return suggestionBoxFilters(
          PastTitleSuggestions,
          onPastTitleSuggestionsFetchRequested,
          onPastTitleSuggestionsClearRequested,
          onPastTitleSuggestionSelected,
          getSuggestionValue,
          renderSuggestion,
          inputPastTitleProps,
          AdvFilters.pastTitle,
          eliminateHandler,
          highlightHandler,
          value,
          index,
          type,
          filterType.type,
          outerIndex
        );
        break;
      case "Current Company":
        return suggestionBoxFilters(
          CurrentCompanySuggestions,
          onCurrentCompanySuggestionsFetchRequested,
          onCurrentCompanySuggestionsClearRequested,
          onCurrentCompanySuggestionSelected,
          getSuggestionValue,
          renderSuggestion,
          inputCurrentCompanyProps,
          AdvFilters.currentCompany,
          eliminateHandler,
          highlightHandler,
          value,
          index,
          type,
          filterType.type,
          outerIndex
        );
        break;
      case "Past Company":
        return suggestionBoxFilters(
          PastCompanySuggestions,
          onPastCompanySuggestionsFetchRequested,
          onPastCompanySuggestionsClearRequested,
          onPastCompanySuggestionSelected,
          getSuggestionValue,
          renderSuggestion,
          inputPastCompanyProps,
          AdvFilters.pastCompany,
          eliminateHandler,
          highlightHandler,
          value,
          index,
          type,
          filterType.type,
          outerIndex
        );
        break;
      case "Current Employee Count":
        return suggestionBoxFilters(
          null,
          null,
          null,
          null,
          getSuggestionValue,
          renderSuggestion,
          null,
          AdvFilters.companySize,
          eliminateHandler,
          highlightHandler,
          value,
          index,
          type,
          filterType.type,
          outerIndex
        );
        break;
      case "Location":
        return suggestionBoxFilters(
          LocationSuggestions,
          onLocationSuggestionsFetchRequested,
          onLocationSuggestionsClearRequested,
          onLocationSuggestionSelected,
          getSuggestionValue,
          renderSuggestion,
          inputLocationProps,
          AdvFilters.location,
          eliminateHandler,
          highlightHandler,
          value,
          index,
          type,
          filterType.type,
          outerIndex
        );
        break;
      case "Visa Type":
        return suggestionBoxFilters(
          null,
          null,
          null,
          null,
          getSuggestionValue,
          renderSuggestion,
          null,
          AdvFilters.visa,
          eliminateHandler,
          highlightHandler,
          value,
          index,
          type,
          filterType.type,
          outerIndex
        );
        break;
      case "Relevant Experience":
        return suggestionBoxFilters(
          null,
          null,
          null,
          null,
          getSuggestionValue,
          renderSuggestion,
          null,
          AdvFilters.relevantExperience,
          null,
          null,
          value,
          index,
          type,
          filterType.type,
          outerIndex
        );
        break;
    }
  };

  const isAddMoreDisabled = (outerIndex, index) => {
    return (
      filters[outerIndex][index].highlight.length > 0 ||
      filters[outerIndex][index].eliminate.length > 0
    );
  };

  const handleInnerPlusClick = (outerIndex, index) => {
    let lastItem = filters[outerIndex][index];

    lastItem.relation = "AND";

    filters[outerIndex].push({
      name: "Skills",
      value: "",
      range: { min: 0, max: 5 },
      isStrict: false,
      eliminate: [],
      highlight: [],
      selectedOne: false,
      relation: "",
      outerRelation: "",
    });

    dispatch(setBooleanFilters(filters));
  };

  const handleOuterPlusClick = (outerIndex) => {
    let lastItem = filters[outerIndex];

    lastItem[0].outerRelation = "AND";

    filters.push([
      {
        name: "Skills",
        value: "",
        range: { min: 0, max: 5 },
        isStrict: false,
        eliminate: [],
        highlight: [],
        selectedOne: false,
        relation: "",
        outerRelation: "",
      },
    ]);

    dispatch(setBooleanFilters(filters));
  };

  return (
    <React.Fragment>
      <CardBody className="cardBoolean">
        <div className="innerBooleanContainer">
          <div
            className={
              filters[0]?.length > 2 ? "multipleBooleanContainers" : ""
            }
          >
            {
              //multipleBooleanContainers
            }
            {filters.map((outerFilter, outerIndex) => (
              <>
                <div
                  className={`bottomFilterBodyContainer ${
                    filters[0]?.length > 2 ? "multiple" : ""
                  }`}
                >
                  {filters[outerIndex]?.map((filter, index) => (
                    <>
                      <div
                        className={`boxFilterSelections ${
                          filters[0]?.length > 2 ? "hasMultiple" : ""
                        }`}
                      >
                        {
                          //hasMultiple
                        }
                        <div className="header">
                          <UncontrolledDropdown>
                            <DropdownToggle caret>{filter.name}</DropdownToggle>
                            <DropdownMenu>
                              {filterSelections?.map((item) => (
                                <DropdownItem
                                  onClick={() => {
                                    filters[outerIndex][index]["name"] =
                                      item.name;
                                    filters[outerIndex][index][
                                      "selectedOne"
                                    ] = false;
                                    filters[outerIndex][index][
                                      "eliminate"
                                    ] = [];
                                    filters[outerIndex][index][
                                      "highlight"
                                    ] = [];
                                    dispatch(setBooleanFilters(filters));
                                  }}
                                >
                                  {item.name}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>

                          <label className="TitleSwitchContainer">
                            <span>Strict Match</span>
                            <Toggle
                              icons={false}
                              defaultChecked={filter.isStrict}
                              onChange={() => {
                                filters[outerIndex][index][
                                  "isStrict"
                                ] = !filters[outerIndex][index]["isStrict"];
                                dispatch(setBooleanFilters(filters));
                                // setTimeout(() => setFilters(filters))
                              }}
                            />
                          </label>
                        </div>
                        {uiRendererFilters(
                          filter.name,
                          filter.value,
                          index,
                          outerIndex
                        )}
                      </div>

                      {filter.relation === "" ? (
                        <div className="addMore inside">
                          <div className="verticalLine"></div>
                          <Button
                            color=""
                            className="plusBtn"
                            disabled={!isAddMoreDisabled(outerIndex, index)}
                            onClick={() =>
                              handleInnerPlusClick(outerIndex, index)
                            }
                          >
                            <PlusSvg></PlusSvg>
                          </Button>
                        </div>
                      ) : (
                        <div className="addMore inside">
                          <div className="verticalLine"></div>
                          <UncontrolledDropdown>
                            <DropdownToggle caret>
                              {filter.relation}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  filters[outerIndex][index]["relation"] =
                                    "AND";
                                  dispatch(setBooleanFilters(filters));
                                }}
                              >
                                AND
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  filters[outerIndex][index]["relation"] = "OR";
                                  dispatch(setBooleanFilters(filters));
                                }}
                              >
                                OR
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          <div className="verticalLine"></div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
                {filters[outerIndex].length > 2 ? (
                  <div className="addMore outside">
                    <div className="horizontalLine"></div>
                    {filters[outerIndex][0]?.outerRelation === "" ? (
                      <Button
                        color=""
                        className="plusBtn"
                        onClick={() => handleOuterPlusClick(outerIndex)}
                      >
                        <PlusSvg></PlusSvg>
                      </Button>
                    ) : (
                      <>
                        <UncontrolledDropdown>
                          <DropdownToggle caret>
                            {filters[outerIndex][0]?.outerRelation}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                filters[outerIndex][0].outerRelation = "AND";
                                dispatch(setBooleanFilters(filters));
                              }}
                            >
                              AND
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                filters[outerIndex][0].outerRelation = "OR";
                                dispatch(setBooleanFilters(filters));
                              }}
                            >
                              OR
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <div className="horizontalLine"></div>
                      </>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            ))}
          </div>
        </div>
        <div className="booleanTerm">
          <p className="label">Boolean Term</p>

          <p
            className={`terms ${booleanTerm.length < 1 ? "greyedOut" : ""}`}
            dangerouslySetInnerHTML={{
              __html: booleanTerm.length > 0 ? booleanTerm : "Not defined yet",
            }}
          />
        </div>
      </CardBody>
    </React.Fragment>
  );
};

export default withRouter(
  connect((state) => {
    return {
      ...state.prospectsView.advFiltersData.data,
      AdvFilters: state.prospectsView.advFiltersMeta,
      ScoringFilters: state.prospectsView.scoringFiltersMeta,
      filters: state.prospectsView.advBooleanFilters.filters,
      booleanTerm: state.prospectsView.advBooleanFilters.booleanTerm,
    };
  })(FilterByBoolean)
);
