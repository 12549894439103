import React from "react";

const RectangleSvg = ({
  color = "#E6E9ED",
  width = "2",
  height = "28",
  style = {},
}) => {
  return (
    <svg
      color={color}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 2 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="2" height="28" rx="1" fill="#E6E9ED" />
    </svg>
  );
};

export default RectangleSvg;
