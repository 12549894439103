import React, { useState, useEffect } from "react";
import "./TribesHeader.scss";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import { validateEmail } from "../../../utils";
import ProfileLiked from "../../ProfileLiked/ProfileLiked";
import { toast } from "react-toastify";

function TribesHeader({
  userEmail,
  tribeMembers,
  handleInputSubmit,
  handleInputChange,
  showInput,
  setShowInput,
  membersTextBox,
  setMembersTextBox,
  suggestionMembersList,
  permissionsAllowed,
}) {
  const [membersSuggestions, setMembersSuggestions] = useState([]);

  useEffect(() => {
    toast.dismiss();
    if (membersTextBox.toLowerCase() === userEmail) {
      toast(
        ({ closeToast }) => (
          <ProfileLiked
            header={`You can't add yourself as tribe member`}
          ></ProfileLiked>
        ),
        {
          closeButton: false,
          hideProgressBar: true,
          style: {
            backgroundColor: "#A0A0A0",
            borderRadius: "8px",
            width: "514px",
          },
        }
      );
    }
  }, [membersTextBox]);
  const getSuggestionValue = (suggestion) => suggestion.email;
  const renderSuggestion = (value) => {
    return value?.type === "invite" ? (
      value.email.toLowerCase() !== userEmail && (
        <div className="renderedSuggestionContainer">
          Invite <b>{`${value.email}`}</b> to your tribe
        </div>
      )
    ) : (
      <div className="renderedSuggestionContainer">
        {typeof value === "string" ? value : value?.email}
      </div>
    );
  };
  const inputMembersProps = {
    placeholder: `Search`,
    value: membersTextBox,
    maxLength: "40",
    onChange: (e, { newValue }) => setMembersTextBox(newValue),
  };

  const getMembersSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const isEmail = validateEmail(inputValue);
    const inputLength = inputValue.length;
    const alreadyExists = tribeMembers.filter((member) =>
      member?.tribeUser?.tribeUserEmail
        .trim()
        .toLowerCase()
        .startsWith(inputValue)
    );
    const filtered = suggestionMembersList.filter((itm) =>
      itm.email.trim().toLowerCase().startsWith(inputValue)
    );
    //     added: false
    // email: "jasd@gad.ce"
    // name: "jasd@gad.ce"
    // reachoutPermission: true
    // type: "invite"
    // viewPermission: true
    return inputLength === 0 || alreadyExists.length > 0
      ? []
      : filtered.length > 0
      ? filtered
      : isEmail
      ? [
          {
            email: value,
            name: value,
            type: "invite",
            reachoutPermission: true,
            viewPermission: true,
          },
        ]
      : [];
  };

  const onMembersSuggestionsFetchRequested = ({ value }) =>
    setMembersSuggestions(getMembersSuggestions(value));
  const onMembersSuggestionsClearRequested = () => setMembersSuggestions([]);

  const onMembersSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    toast.dismiss();
    toast(
      ({ closeToast }) => (
        <ProfileLiked
          header={`Add Tribe Member?`}
          description="Are you sure you want to add this member to your tribe?"
        >
          <Button
            className="reviewProfileBtn"
            onClick={() => {
              handleInputSubmit(suggestionValue);
            }}
          >
            Yes
          </Button>
          {/* <Button className="closeButtonPopup" onClick={closeToast}>
            cancel
          </Button> */}
        </ProfileLiked>
      ),
      {
        closeButton: true,
        hideProgressBar: true,

        style: {
          backgroundColor: "#A0A0A0",
          borderRadius: "8px",
          width: "400px",
        },
      }
    );
  };

  return (
    <div className="tribesHeader">
      <div className="left">
        <div className="mainHeading">My Tribe</div>
        <div className="tag">{tribeMembers ? tribeMembers.length : 0}</div>
      </div>
      <div className="suggestionsTribes">
        {showInput &&
        (permissionsAllowed === "*" ||
          (permissionsAllowed?.length &&
            permissionsAllowed?.length > 0 &&
            permissionsAllowed?.includes("EDIT_TRIBE"))) ? (
          <Autosuggest
            theme={{
              input: "newAutoSuggestionInput",
              suggestion: "newAutoSuggestion",
              suggestionsContainer: "newAutoSuggestionContainerB",
            }}
            suggestions={membersSuggestions}
            onSuggestionsFetchRequested={onMembersSuggestionsFetchRequested}
            onSuggestionsClearRequested={onMembersSuggestionsClearRequested}
            onSuggestionSelected={onMembersSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputMembersProps}
            highlightFirstSuggestion={true}
          />
        ) : (
          // <input type="email" name="email" id="tribeUserEmail" placeholder="Enter email to add member" value={inputText} onChange={(e) => handleInputChange(e)} />
          ""
        )}
        {permissionsAllowed === "*" ||
        (permissionsAllowed?.length &&
          permissionsAllowed?.length > 0 &&
          permissionsAllowed?.includes("EDIT_TRIBE")) ? (
          <Button
            className="inviteBtn"
            onClick={() =>
              membersTextBox.length > 0
                ? (() => {
                    setShowInput(!showInput);
                    setMembersTextBox("");
                  })()
                : setShowInput(!showInput)
            }
          >
            {showInput
              ? membersTextBox.length > 0
                ? "Cancel"
                : "Cancel"
              : "Add / Invite"}
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default connect((state) => {
  return {
    tribeMembers: state.prospectsView.tribeMembers.tribeMembers.tribeMembers,
    userEmail: state.auth.user.email,
  };
})(TribesHeader);
