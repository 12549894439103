import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFeedbackEntity } from "../../../actions/QuickSetup";
import ProfileDisplayImage from "../../../components/ProfileDisplayImage/ProfileDisplayImage";
import { getIsViewOnly } from "../../../reducers/QuickSetup/calibrationProfiles";
import SuperLike from "../../../uiLibrary/SuperLike";
import Position from "../ProfileCard/Position";
import ActionIcon from "./ActionIcon";
import "./styles.scss";

export default function Header({
  profile: { imageUrl, fullName },
  id,
  experience = [],
  setIsDetailView,
  isDetailView,
  isDrawerHeader,
  data = {},
}) {
  const isViewOnly = useSelector((state) =>
    getIsViewOnly(state.QuickSetup.calibrationProfiles)
  );
  const dispatch = useDispatch();

  const currentExperience = experience.find(({ end }) => !end);
  const isPositionVisible = !!(
    currentExperience?.title && currentExperience?.company_name
  );

  const getIsLikedCount = (isLiked) => {
    if (!isLiked?.count) {
      return 0;
    }

    return Number(isLiked?.count);
  };

  return (
    <div className="profileHeader">
      <div className="profileHeaderLeft">
        <ProfileDisplayImage src={imageUrl} width="75px" height="75px" />
        <div className="profileHeaderInfo">
          <span>{fullName}</span>
          {isPositionVisible && (
            <Position
              title={currentExperience?.title}
              companyName={currentExperience?.company_name}
            />
          )}
          {/* <p>{currentExperience?.location}</p> */}
        </div>
      </div>
      <div className="profileHeaderRight">
        {!isViewOnly && (
          <div className="profileFeedback">
            <div
              style={{
                backgroundColor:
                  getIsLikedCount(data?.isLiked) > 0 ? "#331D25" : "#454545",
                borderColor:
                  getIsLikedCount(data?.isLiked) > 0 ? "#E63B7F" : "#454545",
              }}
              className="superLikeContainer"
            >
              <SuperLike
                toggleLike={(likeCount) => {
                  dispatch(
                    updateFeedbackEntity(id, "isLiked", { count: likeCount })
                  );
                  dispatch(updateFeedbackEntity(id, "isMaybe", false));
                  dispatch(updateFeedbackEntity(id, "isDisliked", false));
                }}
                totalHearts={3}
                likedHearts={getIsLikedCount(data?.isLiked)}
              />
            </div>
            <ActionIcon isSelected={data?.isMaybe} id={id} type="isMaybe" />
            <ActionIcon
              isSelected={data?.isDisliked}
              id={id}
              type="isDisliked"
            />
          </div>
        )}
        <p onClick={() => setIsDetailView(!isDetailView)}>
          {isDetailView && isDrawerHeader
            ? "Close Detailed View"
            : "Detailed View"}
        </p>
      </div>
    </div>
  );
}
