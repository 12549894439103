import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'reactstrap'
import SecondaryButton from '../../uiLibrary/SecondaryButton'

import { logout } from '../../actions/auth'
import { getInitials } from '../../utils'
import { Mixpanel } from '../../utils/Mixpanel'
import './UserAvt.scss'


const UserAvt = ({ user, dispatch, style }) => {

  const s = {
    ...style,
    border: `1px solid ${user?.color && user?.color?.length > 3 ? user?.color : "ff8d4e"}`, color: `${user?.color && user?.color?.length > 3 ? user?.color : "ff8d4e"}`
  }


  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const handleClickLogout = () => {
    Mixpanel.track("Logout")
    setIsTooltipOpen(false)
    dispatch(logout())
  }
  return (
    <>
      <div
        id='useravatar'
        className='useravt'
        style={{
          ...style,
          background:'#29382E', color:'#60B17C' 
        }}
        onClick={() => { setIsTooltipOpen(!isTooltipOpen) }}
      >
        {getInitials(user?.name ? user?.name: user?.email )}
      </div>
      <Tooltip
        autohide={false}
        target='useravatar'
        toggle={function noRefCheck() { }}
        isOpen={isTooltipOpen}
        style={{ backgroundColor: 'transparent' }}
        placement= 'bottom'
      >
        <SecondaryButton
          onClick={handleClickLogout}
          onMouseLeave={() => { setIsTooltipOpen(false) }}
        >Logout</SecondaryButton>
      </Tooltip>
    </>
  )
}

export default connect(state => ({ user: state.auth.user }))(UserAvt)