import React from "react";

export const JDFAQ1 = () => {
  return (
    <div>
      <p>
        The following are needed to set up a new search:
        <ul>
          <li>
            A job description including everything you require in a prospect
          </li>
          <li>Location from where you will be hiring from</li>
          <li>
            List of best fit profiles ranging from (1-5 profiles) to help our
            system better understand what you are looking for. (This step is not
            necessary)
          </li>
          <li>
            Name of company you are hiring for. (This step is not necessary)
          </li>
        </ul>
      </p>
    </div>
  );
};

export const JDFAQ2 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            You can just add a job title in the job description section and add
            more specifications as you create the job
          </li>
          <li>
            You can create your own job description by including everything you
            might require in a prospect. You can also format it with the tools
            provided in the job description section
          </li>
        </ul>
      </p>
    </div>
  );
};

export const JDFAQ3 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>No, but a properly formatted JD is preferred</li>
        </ul>
      </p>
    </div>
  );
};