
import { combineReducers } from 'redux'
import { emailStep } from './emailStep'
import { user } from './user'
import calendar from './calendar'

export default combineReducers({
  emailStep,
  user,
  calendar
})


