import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './home.scss'
import ChartCard from '../../../components/ChartCard'
import { fetchCampaignsHome, fetchJobsHome, fetchStats } from '../../../actions/landingPage'
import Stats from './Stats'
import RecentJobs from './RecentJobs'
import RecentCampaigns from './RecentCampaigns'
import NotificationPanel from './NotificationPanel'
import Header from '../Header'
import Fte from './Fte'
import HybridConsentScreen from '../../Outbox/HybridConsentScreen'
import ShowMoreWidget from '../../../components/ShowMoreWidget'
import { fetchCampaignPendingStatus } from '../../../actions/outbox'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PendingNotificationPanel from '../PendingNotificationPanel'
import { Mixpanel } from '../../../utils/Mixpanel'
import { logout } from '../../../actions/auth'
import NotificationDropdownPanel from '../../../views/LandingPage/Home/NotificationDropdownPanel'

const Home = ({
  dispatch,
  stats,
  jobs,
  campaigns,
  view,
  location,
  history
}) => {
  console.log('CAMPAIGNS ARE: ', campaigns)
  console.log('JOBS ARE: ', jobs)
  useEffect(() => {
    dispatch(fetchStats())
    dispatch(fetchJobsHome())
    dispatch(fetchCampaignsHome())
    const user = JSON.parse(localStorage.getItem("user"));
    if('isBlocked' in user && !user?.isBlocked){
      Mixpanel.track("Dashboard")
    }
    if(user?.aot){
      dispatch(logout())
    }
  }, [])

  if(view==='CONSENT'){
    return(
      <HybridConsentScreen
        style={{width:'100%'}}
      />
    )
  }

  return (
    <div className='landinghome'>
      {
        (!jobs.isLoading && !jobs.isDataAvailable) ?
          <div style={{ border: 'none', width: '100%' }} className='landinghome-content'>
            <Header showAction={false} label='Home' />
            <Fte />
          </div> :
          <>
            <ToastContainer/>
            <div className='landinghome-content'>
              <Header showAction={false} label='Home' />
              {/* <div className='charts-container' style={{ marginTop: '16px' }}>
                <Stats {...stats} />
              </div> */}
              <div className='section-container'>
                <RecentJobs {...jobs} />
              </div>
              <div className='section-container'>
                <RecentCampaigns {...campaigns} />
              </div>
            </div>
            <div className='landinghome-notif'>
              {/* <NotificationPanel /> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                }}
              >
                <NotificationDropdownPanel
                  historyPush={history.push}
                  location={location}
                  style={{marginBottom:'20px'}}
                />
              </div>
              <PendingNotificationPanel/>
              <Fte
                showHeader={false}
                isJobCreated={(!jobs.isLoading && jobs.isDataAvailable)}
                slim={true}
              />
            </div>
          </>
      }


    </div>
  )
}

export default connect(state => ({
  stats: state.landingPage.home.stats,
  jobs: state.landingPage.home.jobs,
  campaigns: state.landingPage.home.campaigns,
  view: state.outbox.viewController.view
}))(Home)