import React from 'react'
import './style.scss'

import { connect } from 'react-redux'
import schooldPlaceholder from '../../../../img/edu_placeholder.svg'
import SocialHeartButton from '../../../../uiLibrary/SocialHeartButton'
import SocialMaybeButton from '../../../../uiLibrary/SocialMaybeButton'
import SocialRejectButton from '../../../../uiLibrary/SocialRejectButton'
import { getValidDate } from '../../../../utils'
import ProfileCardExperienceDetails from '../ProfileCardExperienceDetails'
import ProfileCardTagsDetails from '../ProfileCardTagsDetails'
import ProfileSummaryVariant1 from '../ProfileSummaryVariant1'
const QuickDetailProfileCard = ({
    style = {},
    index,
    profileData,
    isSelected,
    onSelect,
    recommended,
    showReview,
    showCompanyModal = e => false,
    closeCompanyModal,
    handleRecommendedCard,
    removeHoverHighlighter,
    likedStatus,
    onClickCard = e => false,
    onClickComments = e => false,
    onClickSave = e => false,
    onClickMaybe = e => false,
    isCalibrating = false,
    disableColab = false,
    disableFeedback = false,
    disableLogo = true,
    isAddingProfile = false,
    disableSelect = false,
    overrideHeartProfile,
    overrideMaybeProfile,
    overrideRejectProfile
}) => {
    // const profileData = profiles[0]
    // console.log("========================DeepDetailProfileCard=============================", {profileData})
    const { skill_importance = {}, skills_required = {}, specialties_found = {}, specialties_required = [] } = (profileData?.scoring || {})
    const currentExperience = profileData?.experience?.[0]
    const skillsFound = [
        ...(skill_importance?.must_haves || []),
        ...(skill_importance?.important || []),
        ...(skill_importance?.nice_to_haves || [])
    ]
    const validEduDate = (start = '', end = '') => {
        let s = getValidDate(start)
        let e = getValidDate(end)
        if (s === '' && e === '') return { start: "", end: "" }
        if (e === '') return { start: s, end: "Now" }
        return { start: s, end: e }
    }

    const skillsMissing = [
        ...(skills_required?.must_haves || []),
        ...(skills_required?.important || []),
        ...(skills_required?.nice_to_haves || [])
    ].filter(skl => !skillsFound.includes(skl))

    const specialtiesFound = Object.keys(specialties_found)

    const specialtiesMissing = specialties_required.filter(itm => !specialtiesFound.includes(itm))

    const specialties = (profileData?.experience || []).reduce((prev, { companySpecialities = [], industries = [] }) => ([...prev, ...(industries ? industries : []), ...(companySpecialities ? companySpecialities : [])]), [])

    return (
        <div
            className='quickDetailProfileCard'
            style={style}
        >
            <div>
                <span>
                    <h6
                        onClick={onClickCard}
                    >
                        View details
                    </h6>
                </span>
                <ProfileSummaryVariant1
                    isSelected={isSelected}
                    onSelect={onSelect}
                    name={profileData.fullName}
                    title={profileData.title}
                    company={profileData.currentCompanyName || profileData.companyName}
                    location={profileData.locality || profileData.location}
                    src={profileData?.imageUrl}
                    emojies={profileData?.emojies}
                    index={index}
                    onClickComments={onClickComments}
                    jobProfileId={profileData?.jobProfileId}
                    profileData={profileData}
                    isCalibrating={isCalibrating}
                    disableColab={disableColab}
                    disableSelect={disableSelect}
                    onClick={(e, init, fin) => showCompanyModal(currentExperience, init, fin)}
                    mixpanelActionType={'concise view'}
                />
            </div>
            <div
                className='socialButtonContainer'
                style={(isCalibrating || disableFeedback) ? { borderBottom: 'none', padding: '0' } : {}}
            >
                {(!isCalibrating && !disableFeedback) && <SocialHeartButton
                    index={index}
                    jobProfileId={profileData?.jobProfileId}
                    profileId={profileData?.id}
                    maybe={profileData?.maybe}
                    saved={profileData?.saved}
                    onClickSave={onClickSave}
                    mixpanelActionType={isAddingProfile ? 'Find More Card' : 'concise view'}
                    isAddingProfile={isAddingProfile}
                    onClick={overrideHeartProfile}
                />}
                {(!isCalibrating && !disableFeedback) && <SocialMaybeButton
                    index={index}
                    jobProfileId={profileData?.jobProfileId}
                    profileId={profileData?.id}
                    maybe={profileData?.maybe}
                    saved={profileData?.saved}
                    onClickSave={onClickSave}
                    onClickMaybe={onClickMaybe}
                    mixpanelActionType={isAddingProfile ? 'Find More Card' : 'concise view'}
                    isAddingProfile={isAddingProfile}
                    onClick={overrideMaybeProfile}
                />}
                {(!isCalibrating && !disableFeedback) && <SocialRejectButton
                    index={index}
                    jobProfileId={profileData?.jobProfileId}
                    archived={profileData?.archived}
                    saved={profileData?.saved}
                    onClickSave={onClickSave}
                    mixpanelActionType={isAddingProfile ? 'Find More Card' : 'concise view'}
                    isAddingProfile={isAddingProfile}
                    onClick={overrideRejectProfile}
                />}
            </div>
            <div>
                <h3>
                    Experience
                </h3>
                <span>
                    <ProfileCardExperienceDetails
                        style={{ padding: '24px 36px 20px 0px' }}
                        disableLogo={disableLogo}
                        experience={profileData?.experience?.slice(0, 3).map((exp, i) => ({ title: exp.title, onClick: (e, init, fin) => showCompanyModal(exp, init, fin, i), name: exp.company_name, logo: exp.logo, start: getValidDate(exp.start), end: i === 0 ? "Now" : getValidDate(exp.end) }))}
                        seeMore={profileData?.experience?.slice(3).map((exp, i) => ({ title: exp.title, onClick: (e, init, fin) => showCompanyModal(exp, init, fin, i + 3), name: exp.company_name, logo: exp.logo, start: getValidDate(exp.start), end: i === 0 ? "Now" : getValidDate(exp.end) }))}
                    />
                </span>
            </div>
            <div>
                <h3>
                    Education
                </h3>
                <span>
                    <ProfileCardExperienceDetails
                        style={{ padding: '24px 36px 20px 0px' }}
                        disableLogo={disableLogo}
                        delimiter="from"
                        defaultPlaceholder={schooldPlaceholder}
                        experience={profileData?.education?.slice(0, 3).map((ed, i) => ({ title: `${ed.degreeName || ""}${ed.degreeName && ed.fieldOfStudy ? " in " : ""}${ed.fieldOfStudy || ""}`, name: ed.schoolName, logo: ed.logo, start: validEduDate(ed.start, ed.end).start, end: validEduDate(ed.start, ed.end).end }))}
                        seeMore={profileData?.education?.slice(3).map((ed, i) => ({ title: `${ed.degreeName || ""}${ed.degreeName && ed.fieldOfStudy ? " in " : ""}${ed.fieldOfStudy || ""}`, name: ed.schoolName, logo: ed.logo, start: validEduDate(ed.start, ed.end).start, end: validEduDate(ed.start, ed.end).end }))}
                    />
                </span>
            </div>
            <div>
                <h3
                // style={{padding: '20px 32px'}}
                >
                    Skills
                </h3>
                <span>
                    <ProfileCardTagsDetails
                        style={{ padding: '24px 36px 20px 0px' }}
                        found={isCalibrating ? (profileData?.skills || []) : skillsFound}
                        missing={skillsMissing}
                        isCalibrating={isCalibrating}
                    />
                </span>
            </div>
            <div>
                <h3
                // style={{padding: '20px 32px 40px 32px'}}
                >
                    Business
                </h3>
                <span
                    style={{ paddingBottom: '20px' }}
                >
                    <ProfileCardTagsDetails
                        style={{ padding: '24px 36px 20px 0px' }}
                        found={isCalibrating ? specialties : specialtiesFound}
                        missing={specialtiesMissing}
                        isCalibrating={isCalibrating}
                    />
                </span>
            </div>
        </div>
    )
}

export default connect(state => ({
    likedStatus: state.prospectsView.candidates.likedStatus,
}))(QuickDetailProfileCard)