import React, { useState } from 'react'
import './style.scss'
import Collapsible from 'react-collapsible';
import { jsonParse } from '../../../../utils';

export const ProfileCardTagsDetailsTag = ({
    tag,
    found
}) => {
    return(
        <p
            className={found ? 'profileCardTagsDetailsTagFound' : 'profileCardTagsDetailsTagMissing'}
        >
            {tag}
        </p>
    )
}

const ProfileCardTagsDetails = ({
    found=[],
    missing=[],
    style={},
    isCalibrating=false
}) => {

    const [isOpen, setIsOpen] = useState(false)
    
    const seeMore = [...found.slice(4).map(tag=>({name:tag, found:true})), ...missing?.slice(found.slice(0,4).length>0?5-found.slice(0,4).length:5).map(tag=>({name:tag, found:false}))]

    // console.log("=====================ProfileCardTagsDetails===========================", {found, missing})

    return (
        <div
            className='profileCardTagsDetails'
            style={style}
        >
            <div style={isCalibrating ? {
                marginBottom: '-8px'
            } : {}} >
                {!isCalibrating && <h4>
                    {`${found.length} found`}
                </h4>}
                {!isCalibrating && <h5>
                    ·
                </h5>}
                {!isCalibrating && <h6>
                    {`${missing.length} missing`}
                </h6>}
            </div>
            <div>
                {found.length===0 && missing.length===0 && <h6>
                    Not Available
                </h6>}
                {[...found.slice(0,4).map(tag=>({name:tag, found:true})), ...missing?.slice(0,found.slice(0,4).length>0?5-found.slice(0,4).length:5).map(tag=>({name:tag, found:false}))].map(({name, found}, i)=>(
                    <ProfileCardTagsDetailsTag
                        key={`tags${i}`}
                        tag={name}
                        found={found}
                    />
                ))}
            </div>
            {seeMore.length>0 &&
                <Collapsible transitionTime={200} onOpen={()=>setIsOpen(true)} onClose={()=>setIsOpen(false)} trigger={`${seeMore.length} ${isOpen ? "less" : "more"}`}>
                    {seeMore.map(({name, found}, i)=>(
                        <ProfileCardTagsDetailsTag
                            key={`moreTags${i}`}
                            tag={name}
                            found={found}
                        />
                    ))}
                </Collapsible>}
        </div>
    )
}

export default ProfileCardTagsDetails