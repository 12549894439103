import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  clearAdvancedFilters,
  setBatchSize,
  setMoreProspectCurrentTab,
  setMoreProspectSkillsArray,
  setMoreProspectSpecialitiesArray,
  setPriorityArray,
} from "../../../actions/prospectsView";
import { Mixpanel } from "../../../utils/Mixpanel";
import AddDetails from "../AddDetails/AddDetails";
import MoreProspectsFooter from "../MoreProspectsFooter/MoreProspectsFooter";
import Prioritize from "../Prioritize/Prioritize";
import SetBatchSize from "../SetBatchSize/SetBatchSize";
import "./MoreProspectsLayout.scss";
function MoreProspectsLayout({ setShowMoreProspect, children, dispatch }) {
  useEffect(() => {

    Mixpanel.track(`Find More Prospects - Score and Connect`)

    return () => {
      dispatch(setBatchSize(0));
      dispatch(setPriorityArray([]));
      dispatch(setMoreProspectSkillsArray([]));
      dispatch(setMoreProspectSpecialitiesArray([]));
      dispatch(setMoreProspectCurrentTab(null));
    };
  }, []);
  const [active, setActive] = React.useState("Set Batch Size");
  return (
    <div className="MoreProspectsLayoutContainer">
      {active == "Set Batch Size" ? (
        <SetBatchSize />
      ) : active == "Prioritize" ? (
        <Prioritize />
      ) : active == "Add Details" ? (
        <AddDetails />
      ) : null}
      <div className="footer">
        <MoreProspectsFooter
          onCancel={() => setShowMoreProspect(false)}
          active={active}
          setActive={setActive}
        />
      </div>
    </div>
  );
}

export default connect()(MoreProspectsLayout);
