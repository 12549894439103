import React from "react";

// import CCPAProfilea from "../component/CCPA-Profiles";
// import Footer from "../component/Footer";

// import Header from "../component/Header";
// import style from "../styles/DataProtection.module.scss";
import screenshot1 from '../../img/ConsentScreenshot1.png'
import screenshot2 from '../../img/consentss2.png'
import "./style.scss"

export default function DataProtection() {
  const [index, setIndex] = React.useState(0);
  return (
    <div className={'dataProtectionContainer'}>
      <div className={'dataProtectionSection'}>
        <ul>
          <li style={index === 0 ? { color: '#FF8D4E', background: '#402F27' } : {}} onClick={() => setIndex(0)}>
            CCPA
          </li>
          <li style={index === 1 ? { color: '#FF8D4E', background: '#402F27' } : {}} onClick={() => setIndex(1)}>
            GDPR
          </li>
          <li style={index === 2 ? { color: '#FF8D4E', background: '#402F27' } : {}} onClick={() => setIndex(2)}>
            Where Does QLU.ai Gather Candidate's Personal Contact Information?
          </li>
        </ul>
        {(() => {
          switch (index) {
            case 0:
              return <CCPAProfilea />;
            case 1:
              return <GDPRInfo />;
            case 2:
              return <CandidateReply />;
          }
        })()}
      </div>
    </div>
  );
}

const GDPRInfo = () => {
  return (
    <div>
      <h3>GDPR</h3>
      <p>
        The European Union’s (“EU”) wide-ranging data privacy law, the General Data Protection Regulation (“GDPR”), became applicable on 25 May 2018. It updated and harmonized the EU framework for processing personal data, as well as introduced fresh obligations for organizations and new rights for individuals.
      </p>
      <p>
        QLU.ai is fully committed to complying with the requirements of the GDPR. We have closely analyzed the law and continue to monitor new regulatory guidance. Consequently, amongst other steps, we have made changes to our products, contracts and policies to reflect the GDPR’s obligations.
      </p>
      <p>
        These FAQs answer the most common questions we are asked about our privacy and security practices but, if you have further questions then please e-mail
      {" "}<a
          href="mailto:admin@qlu.ai"
        >admin@qlu.ai</a>.
      </p>
      <h2>Overview of GDPR</h2>
      <p>
        In essence, GDPR demands data controllers and processors provide greater transparency with E.U. residents on how their personal data is lawfully, fairly and transparently collected and processed, which means companies in, and outside of the E.U. if they handle personal data of E.U. residents, must make sure they comply with GDPR when processing personal information of EU residents. Entities may face harsh penalties for violations of GDPR obligations.
      </p>
      <h2>Who does GDPR apply to?</h2>
      <p>
        GDPR applies to both controllers and processors of personal information. The data controller determines the purpose and means of processing personal data from any E.U. natural person, who is called "data subject", while the processor processes personal data on behalf of the controller. Examples of data controller and processor obligations include implementing appropriate security measures, both on a technical and organization level, to ensure that when personal data is collected it is only used for the specific purpose mentioned.
      </p>
      <h2>What rights do data subjects have under GDPR?</h2>
      <p>Data subjects' rights under GDPR include the following:</p>
      <ul>
        <li>
          Right to Data Portability - the right to receive data from a controller and transmit such data to a new data controller, owning control of their personal data.
        </li>
        <li>
          Right to be Forgotten - the right to have one’s personal data erased or removed if, among other possible reasons, there is no compelling reason for its continued processing.
        </li>
        <li>
          Right to Restrict Processing - the right to block or suppress processing of personal data. If the personal data in question has been disclosed to third parties, they must be informed about the restriction on the processing of the personal data, unless it is impossible or involves disproportionate effort to d
        </li>
        <li>
          Right to Information Regarding Processing - the right to receive information that explains how a data subject’s personal data are to be used and for what purposes.
        </li>
        <li>
          Right to Rectification - the right to have incomplete personal data corrected and/or completed.
        </li>
        <li>
          Right of Access - the right to access their personal data so that they are aware of and can verify the lawfulness of the processing.
        </li>
        <li>
          Right to Object - the right to object to the use of personal information in certain circumstances including profiling and marketing unless the data controller has compelling legitimate grounds.
        </li>
        <li>
          Right to not be subject to automatic decision making or profiling if it would produce legal effects or significantly affects the data subject - the right is to safeguard against potentially damaging decisions taken without human intervention.
        </li>
      </ul>
      <h2>
        GDPR Profiles in QLU.ai
      </h2>
      <p>
        Candidates requiring GDPR consent are mainly in the European Union. When you do come across a yellow notice, it means you need to notify the candidate first in order to view their contact information and the blue link will allow you to do so.
      </p>
      <img
        style={{ width: "100%" }}
        src={screenshot1}
        alt="placeholder"
      />
      <p>
        The consent message will be sent on behalf of QLU.ai instead of on your organization. You can click on the link in the brackets to preview the message.
      </p>
      <img
        style={{ width: "100%" }}
        src={screenshot2}
        alt="placeholder"
      />
      <p>
        After clicking Send, an email for consent from QLU.ai will be sent to candidates. Once the candidates agree to the request you will have in-app and email notifications. If the candidates consent to the invitation, their information will be available to view and unlock by you and all other QLU.ai users when they come across these profiles.
      </p>
      <h2>
        Personalize GDPR Consent Message
      </h2>
      <p>
        If you want to modify and personalize your GDPR consent request to your organization style, please let your QLU.ai team admin know. If you are the Admin of the team, you can find the GDPR Compliance page under the Security & Compliance tab of the Admin Console.
      </p>
      <p>
        You need to turn on the button on the upper right to activate customized messages after uploading your company logo and composing your GDPR consent email.
      </p>
      {/* <img
        src=""
        alt="Placeholder"
      /> */}
      <p>
        You can always preview your message on the left.
      </p>
    </div>
  );
}




const CandidateReply = () => {
  return (
    <div>
      <h1 className="heading">
        QLU.ai pulls the candidate's contact information in two ways:
      </h1>
      <p>
        <span>
          (1) We source the public-facing data from over 45 platforms on the open
          web, including Google, social media, career sites, and job boards to
          see if they're listed on their LinkedIn profiles, email signatures,
          Facebook, Indeed, or any other sites.&nbsp;
        </span>
      </p>
      <div>
        {/* <img
          src=""
          alt="Placeholder"
        /> */}
      </div>
      <p>
        (2) We work with different data vendors that we have data processing
        agreements with that we purchase the data from.
      </p>
      <p>
        <strong>
          Last but not least, all data on QLU.ai are GDPR and CCPA compliant.{" "}
        </strong>
      </p>
      <img
        src="https://downloads.intercomcdn.com/i/o/230017016/42359417ffc514363c3604f1/image.png"
        width={"100%"}
        height={132}
      />
    </div>
  );
}


const CCPAProfilea = () => {
  return (
    <div>
      <h1 className="heading">CCPA</h1>
      <h1 className="heading">What is CCPA?</h1>
      <p>
        <span>
          The California Consumer Privacy Act is a state statute intended to enhance privacy rights and consumer protection for residents of California, United States.
        </span>
      </p>
      <h1 className="intercom-interblocks-paragraph no-margin intercom-interblocks-align-left">
        QLU.ai and CCPA Compliance
      </h1>
      <p>
        Under the California Consumer Privacy Act (CCPA), any California consumer can request to view all personal data a company has saved on them along with a full list of all third parties that data is shared with. QLU.ai values consumer trust and is CCPA compliant.
      </p>
      <h1 className="intercom-interblocks-paragraph no-margin intercom-interblocks-align-left">
        Definition of personal data
      </h1>
      <p>
        CCPA defines personal information as information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked (directly or indirectly) with a particular consumer or household such as a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, job-related information, or other similar identifiers.
      </p>
      <h1 className="intercom-interblocks-paragraph no-margin intercom-interblocks-align-left">
        CCPA Profiles in QLU.ai
      </h1>
      <p>
        The total # of candidates that require a CCPA notice in QLU.ai is very small.
      </p>
      <p>
        When you do come across a yellow notice, you need to notify the candidate first in order to contact them. Simply select the blue "Click here to send CCPA notice".
      </p>
      <img
        style={{ width: '100%' }}
        src={screenshot1}
      />
      <p>
        If you want to modify your CCPA notice message, please let your team admin know. If you are the admin of the team, you can go
        {" "}<a
          href="mailto:admin@qlu.ai"
          target="_blank"
        // rel="undefined"
        >here</a>.
      </p>
      <img
        style={{ width: '100%' }}
        src={screenshot2}
        alt="placeholder"
      />
      <p>
        Immediately after you click Send, the candidate's contact information will appear as well as their name and profile pictu
      </p>
      <h1 className="intercom-interblocks-paragraph no-margin intercom-interblocks-align-left">
        Personalize CCPA Notice Message
      </h1>
      <p>
        If you want to modify and personalize your CCPA consent request to your organization style, please let your QLU.ai team admin know. If you are an admin, please contact your local support team to activate custom message functionality.
      </p>
      {/* <img
        style={{ width: '100%' }}
        src=""
        alt="placeholder"
      /> */}
      <p>
        You can always preview your message on the left.
      </p>
      <p>
        If you have any further questions, please email
        {" "}<a
          href="mailto:admin@qlu.ai"
          target="_blank"
        // rel="undefined"
        >admin@qlu.ai</a>
      </p>
    </div>
  );
}
