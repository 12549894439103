import React from 'react'
import LabelButton from '../LabelButton'
import Loading from '../Loading'
import PrimaryButton from '../PrimaryButton'
import SecondaryButton from '../SecondaryButton'
import CrossSvg from '../SVGs/CrossSvg'
import './style.scss'
const FooterActionBar = ({
    handleBackClick=e=>false,
    handleCancelClick=e=>false,
    handleNextClick=e=>false,
    isBackDisabled=false,
    isCancelDisabled=false,
    isNextDisabled=false,
    isLoading=false
}) => {
    return (
        <div
            className='footerActionBar'
        >
            <div>
                <span>
                    <SecondaryButton
                        onClick={handleBackClick}
                        disabled={isBackDisabled}
                    >
                        Back
                    </SecondaryButton>
                    <LabelButton    
                        onClick={handleCancelClick}
                        disabled={isCancelDisabled}
                    >
                        <CrossSvg/>
                        Cancel
                    </LabelButton>
                </span>
                <span>
                    <PrimaryButton
                        onClick={handleNextClick}
                        disabled={isNextDisabled}
                    >
                        {isLoading?<Loading/>:"Next"}
                    </PrimaryButton>
                </span>
            </div>
        </div>
    )
}

export default FooterActionBar