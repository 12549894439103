import React, { useState} from 'react'


import './style.scss'
import { Input } from 'reactstrap';
import CaretDown from '../SVGs/CaretDown';
import { parse } from 'qs';

const TimeInput = ({
    disabled=false,
    unit=false, 
    min, 
    max,
    placeholder,
    onChange=e=>false,
    value,
    onIncrement=e=>false,
    onDecrement=e=>false,
    onFocus,
    onBlur,
    style={
        caretColor: 'transparent'
    },
    defaultDays,
    defaultHours,
}) => {
    const [blurred, setBlurred] = useState(true)
    return (
        <div 
            className='inputOuter_Container'
        >
            <Input 
                style={style}
                disabled={disabled}
                onFocus={onFocus?onFocus:()=>setBlurred(false)}
                onBlur={onBlur?onBlur:()=>setBlurred(true)}
                onClick={e=>e.stopPropagation()}
                placeholder={placeholder} 
                min={min} max={max} 
                type="number" 
                step="1" 
                className="input_Container"
                value={value}
                onChange={e=>onChange(e, parseInt(e.target.value)<=max && parseInt(e.target.value)>=min ? parseInt(e.target.value) : value)}
            />
            {(unit && (blurred || disabled)) &&
                <p
                    className={`unitLabel${disabled ? '' : ' unitLabelActive'}`}
                >
                    {unit}
                </p> 
            }
            <span
                style={!blurred?{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0,
                }:{}}
                className={`spinnerButtons`}
            >
                <div
                    onClick={e=>onIncrement(e,  parseInt(value)+1)}
                >
                    <CaretDown
                        width='12'
                        height='6'
                        style={{transform: 'rotate(180deg)'}}
                    />
                </div>
                <div
                    onClick={e=>onDecrement(e, parseInt(value)-1)}
                >
                    <CaretDown
                        width='12'
                        height='6'
                    />
                </div>
            </span>
        </div>
    )
}

export default TimeInput
