import * as ActionTypes from '../../actions/JobSetup'
import { v4 } from 'uuid'

const initialSpeciality = {
    selected: false,
    synonym: [],
    experienceSelected: false,
    experience: {
        min: 2,
        max: 5,
    },
    min: 0,
    max: 0,
    source: [],
    sourceId: [],
    synonym: [],
    name: ""
}

const initialState = {
    loading: false,
    loaded: false,
    specialities: {},
    additionalSpecialties: []
}


const jobSpecialities = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.INIT_JOB_SEPCIALITIES: {
            return initialState

        }

        case ActionTypes.REMOVE_SELECTED_IDEAL_PROFILE: {
          return {
              ...state,
              specialities: Object.keys(state?.specialities).reduce((total, key) => {
                return (state?.specialities[key].sourceId.includes(action.payload) ? {
                  ...total,
              } : {
                ...total,
                [key]: state?.specialities[key]
              })}, {})
          }
      }

        case ActionTypes.FETCH_ADDITIONAL_SPECIALTY_RESULTS: {
            return {
                ...state,
                additionalSpecialties: state.additionalSpecialties.map(item =>
                    item.name.toLowerCase().trim() === action.payload.name.toLowerCase().trim() ?
                        { ...item, fetchingResults: true } :
                        item
                )
            }
        }

        case ActionTypes.FETCH_ADDITIONAL_SPECIALTY_RESULTS_SUCCESS: {
            return {
                ...state,
                additionalSpecialties: state.additionalSpecialties.map(item =>
                    item.name.toLowerCase().trim() === action.payload.specialty.name.toLowerCase().trim() ? {
                        ...item,
                        results: action.payload.results,
                        variantSuggestions: action.payload.variantSuggestions,
                        fetchingResults: false
                    } : item
                )
            }
        }


        case ActionTypes.FETCH_ADDITIONAL_SPECIALTY_RESULTS_FAILURE: {
            return {
                ...state,
                additionalSpecialties: state.additionalSpecialties.map(item =>
                    item.name.toLowerCase().trim() === action.payload.name.toLowerCase().trim() ?
                        { ...item, fetchingResults: false } :
                        item
                )
            }
        }


        case ActionTypes.CHANGE_JOB_SPECIALITY: {
            const changededSpecialities = Object.keys(state.specialities).reduce((prevKeys, nextKey) => {
                if ('name' in action.payload.value &&
                    action.payload.value?.name?.toLowerCase() === state.specialities[nextKey]?.name?.toLowerCase() &&
                    !(nextKey === action.payload.key)) {
                    // console.log("==========CHANGE_JOB_SPECIALITY_REMOVAL=================")
                    return prevKeys
                }
                else {
                    if (nextKey === action.payload.key) {

                        // console.log("==========CHANGE_JOB_SPECIALITY_UPDATE=================")
                        prevKeys[nextKey] = {
                            ...state.specialities[nextKey],
                            ...action.payload.value
                        }
                        return prevKeys
                    }
                    else {
                        // console.log("==========CHANGE_JOB_SPECIALITY_DEFAULT=================")
                        prevKeys[nextKey] = state.specialities[nextKey]
                        return prevKeys
                    }
                }
            }, {})
            return {
                ...state,
                specialities: changededSpecialities
            }
        }

        case ActionTypes.INIT_GENERATED_SPECIALTY: {
            const newSpecialities = Object.keys(state.specialities).reduce((prev, key) => {
                const source = state.specialities[key]?.source || []
                const synonym = state.specialities[key]?.synonym || []
                const selected = state.specialities[key]?.selected
                if ((source).includes("generated") && (source.length > 1 || selected)) {
                    return {
                        ...prev,
                        [key]: selected ? state.specialities[key] : {
                            ...state.specialities[key],
                            source: source.filter(src => src !== "generated"),
                            synonym: synonym.map(syn => ({ ...syn, source: (syn?.source || [])?.filter(src => src !== "generated") }))
                        }
                    }
                }
                else if ((source).includes("generated")) {
                    return prev
                }
                return {
                    ...prev,
                    [key]: state.specialities[key]
                }
            }, {})
            return {
                ...state,
                specialities: newSpecialities
            }
        }

        case ActionTypes.ADD_ADDITIONAL_JOB_SPECIALITY: {
            return {
                ...state,
                additionalSpecialties: [
                    ...state.additionalSpecialties,
                    {
                        ...initialSpeciality,
                        ...action.payload
                    }
                ]
            }
        }

        case ActionTypes.REMOVE_ADDITIONAL_JOB_SPECIALITY: {
            return {
                ...state,
                additionalSpecialties: state.additionalSpecialties.filter((itm, index) => index !== action.payload)
            }
        }

        case ActionTypes.INIT_ADDITIONAL_JOB_SPECIALITY: {
            return {
                ...state,
                additionalSpecialties: []
            }
        }

        case ActionTypes.CHANGE_ADDITIONAL_JOB_SPECIALITY: {
            return {
                ...state,
                additionalSpecialties: state.additionalSpecialties.map((specialty, index) => index === action.payload.index ? { ...specialty, ...action.payload.values } : specialty)
            }
        }

        case ActionTypes.ADD_JOB_SPECIALITY: {
            const newKey = v4()
            let isReplaced = false
            const newSpecialities = Object.keys(state.specialities).reduce((prevKeys, nextKey) => {
                if (action.payload?.name === state.specialities[nextKey].name) {
                    isReplaced = true
                    prevKeys[newKey] = {
                        ...initialSpeciality,
                        ...action.payload,
                        selected: state.specialities[nextKey].selected
                    }
                    return prevKeys
                }
                else {
                    prevKeys[nextKey] = state.specialities[nextKey]
                    return prevKeys

                }
            }, {})
            if (!isReplaced) {
                newSpecialities[newKey] = {
                    ...initialSpeciality,
                    ...action.payload
                }
            }
            return {
                ...state,
                specialities: newSpecialities
            }
        }
        case ActionTypes.REMOVE_JOB_SPECIALITY: {
            const filteredSpecialities = {
                ...state.specialities
            }
            console.log("==========before removal===========", filteredSpecialities)
            delete filteredSpecialities[action.payload]
            console.log("==========after removal===========", filteredSpecialities)
            return {
                ...state,
                specialities: filteredSpecialities
            }
        }
        default:
            return state
    }
}

export default jobSpecialities
