import React from 'react'
import './style.scss'
const RadioButton = ({
    checked=false,
    onClick=e=>false,
    children
}) => {
    const handleClick = e =>{
        onClick(e, checked)
    }
    return (
        <div
            className='radioButtonContainer'
            onClick={handleClick}
        >
            <div className={`radioButton ${checked ? 'radioButtonChecked' : ''}`}  />
            <h3>{children}</h3>
        </div>
    )
}

export default RadioButton
