import React, { useEffect, useState } from "react";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { connect } from "react-redux";
import { addJobSkill, addJobSpeciality } from "../../../actions/JobSetup";
import CardContainer from "../../../uiLibrary/CardContainer";
import CarouselTag from "../../../uiLibrary/CarouselTag";
import ContextMenuContainer from "../../../uiLibrary/ContextMenuContainer";
import LabelButton from "../../../uiLibrary/LabelButton";
import CrossSvg from "../../../uiLibrary/SVGs/CrossSvg";
import InfoSvg from "../../../uiLibrary/SVGs/InfoSvg";
import JobSetupStep01 from "../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep01";
import { findAllIndices, getSelectionText } from "../../../utils";
import "./style.scss";
const JobDescriptionCard = ({
  selectedValues,
  highlightValue,
  jobDescription,
  contextMenuDisabled = true,
  hasExperience = false,
  hasSynonym = false,
  contextMenuLabel = "",
  specialities,
  skills,
  cardStyle = {},
  containerStyle = {},
  isReview,
  dispatch,
  education,
}) => {
  const [selectedText, setSelectedText] = useState("");
  const [isAffordanceOpen, setIsAffordanceOpen] = useState(true);

  useEffect(() => {
    // if degree present then do all this else do not highlight
    if (education !== null) {
      if (hasExperience) {
        if (
          selectedValues
            .map(({ detectedMax = "" }) => detectedMax)
            .includes(highlightValue.detectedMax) &&
          document.getElementById(`${highlightValue.detectedMax}0`)
        ) {
          document
            .getElementById(`${highlightValue.detectedMax}0`)
            .scrollIntoView();
        } else if (
          selectedValues
            .map(({ detectedMin = "" }) => detectedMin)
            .includes(highlightValue.detectedMin) &&
          document.getElementById(`${highlightValue.detectedMin}0`)
        ) {
          document
            .getElementById(`${highlightValue.detectedMin}0`)
            .scrollIntoView();
        }
      } else if (
        selectedValues
          .map(({ name = "" }) => name.trim().toLowerCase())
          .includes(highlightValue) &&
        document.getElementById(`${highlightValue}0`)
      ) {
        document.getElementById(`${highlightValue}0`).scrollIntoView();
      }
    }
  }, [highlightValue]);

  const listOfIndices = [];
  // console.log("=============TaggedJobDescription==================", entities)
  selectedValues.map(
    ({
      name,
      spans = [],
      selected,
      onClick,
      detectedMax,
      detectedMin,
      synonym = [],
    }) => {
      // const indices = findAllIndices(jobDescription.toLowerCase().trim(), name) || []
      const indices = hasSynonym
        ? [
            ...spans,
            ...synonym
              .filter(({ source = [] }) => source.includes("jobDescription"))
              .map(({ spans }) => spans)
              .reduce((total, next) => [...total, ...next], []),
          ]
        : spans;

      indices.sort((a, b) => a.start - b.start);

      indices.map((index, i) =>
        listOfIndices.push({
          name,
          synonym,
          detectedMax,
          detectedMin,
          selected,
          onClick,
          position: i,
          ...index,
        })
      );
    }
  );

  listOfIndices.sort((a, b) => a.start - b.start);

  const isHighlighted = (name) => {
    if (hasExperience) {
      const { detectedMax = "", detectedMin = "" } = name;
      return (
        detectedMax === highlightValue?.detectedMax ||
        detectedMin === highlightValue?.detectedMin
      );
    }
    return name.trim().toLowerCase() === highlightValue.trim().toLowerCase();
  };

  const listOfComponents = [];
  let startIndex = 0;
  listOfIndices.map(
    (
      {
        name,
        detectedMax,
        detectedMin,
        start,
        end,
        selected,
        synonym = [],
        onClick,
        position,
      },
      index
    ) => {
      const jdSplit = jobDescription.slice(startIndex, start).split(/\n/g);

      if (jobDescription.split(/\n/g).length > 1) {
        jdSplit.forEach((element, index) => {
          listOfComponents.push(<span>{element}</span>);
          if (index < jdSplit.length - 1) {
            listOfComponents.push(<br />);
          }
        });
      } else {
        listOfComponents.push(
          <span>{jobDescription.slice(startIndex, start)}</span>
        );
      }
      listOfComponents.push(
        <span
          id={`${
            hasExperience
              ? jobDescription.slice(start, end) === detectedMax
                ? detectedMax
                : jobDescription.slice(start, end) === detectedMin
                ? detectedMin
                : "unidentified"
              : name.trim().toLowerCase()
          }${position}`}
          className="carouselTagContainer"
        >
          <CarouselTag
            hasHover={["skill"].includes(contextMenuLabel)}
            selected={true}
            highlight={isHighlighted(
              hasExperience ? { detectedMax, detectedMin } : name
            )}
            greyTheme={!hasExperience && !selected}
            onClick={isReview === "1" ? () => false : onClick || ((e) => false)}
          >
            {hasExperience
              ? jobDescription.slice(start, end) === detectedMax
                ? detectedMax
                : jobDescription.slice(start, end) === detectedMin
                ? detectedMin
                : jobDescription.slice(start, end)
              : hasSynonym
              ? synonym.filter(
                  (syn) =>
                    syn.source.includes("jobDescription") &&
                    syn.name.trim().toLowerCase() ===
                      jobDescription.slice(start, end).trim().toLowerCase()
                )?.[0]?.name || name
              : name}
          </CarouselTag>
        </span>
      );
      startIndex = end;
    }
  );

  if (listOfComponents.length === 0) {
    const jdSplit = jobDescription.split(/\n/g);
    if (jdSplit.length > 1) {
      jdSplit.forEach((element, index) => {
        listOfComponents.push(<span>{element}</span>);
        if (index < jdSplit.length - 1) {
          listOfComponents.push(<br />);
        }
      });
    } else {
      listOfComponents.push(<span>{jobDescription}</span>);
    }
  } else if (startIndex > 0 && startIndex < jobDescription.length) {
    const restOfJd = jobDescription
      .slice(startIndex, jobDescription.length)
      .split(/\n/g);
    restOfJd.forEach((element, index) => {
      listOfComponents.push(<span>{element}</span>);
      if (index < restOfJd.length - 1) {
        listOfComponents.push(<br />);
      }
    });
  }

  const onContextMenuClick = () => {
    const name = getSelectionText();
    if (name.length > 25) {
      alert(`Can not add ${contextMenuLabel} more than 25 charecters`);
      return false;
    }
    switch (contextMenuLabel) {
      case "speciality":
        const selectedSpecialities = Object.keys(specialities)
          .filter((key) =>
            specialities[key]?.source?.includes("jobDescription")
          )
          .map((key) => specialities[key].name);
        if (
          selectedSpecialities.every((speciality) => {
            const specialityTrimmed = speciality.trim().toLowerCase();
            const nameTrimmed = name.trim().toLowerCase();
            return specialityTrimmed !== nameTrimmed;
          })
        ) {
          const specialitiesSpans =
            findAllIndices(
              jobDescription.toLowerCase().trim(),
              name.trim().toLowerCase()
            ) || [];
          dispatch(
            addJobSpeciality({
              name,
              selected: true,
              source: ["jobDescription"],
              spans: specialitiesSpans,
            })
          );
        }

        break;
      case "skill":
        const selectedSkills = Object.keys(skills)
          .filter((key) => skills[key]?.source?.includes("jobDescription"))
          .map((key) => skills[key].name);
        if (
          selectedSkills.every((skill) => {
            const skillTrimmed = skill.trim().toLowerCase();
            const nameTrimmed = name.trim().toLowerCase();
            return skillTrimmed !== nameTrimmed;
          })
        ) {
          const skillsSpans =
            findAllIndices(
              jobDescription.toLowerCase().trim(),
              name.trim().toLowerCase()
            ) || [];
          dispatch(
            addJobSkill({
              name,
              selected: true,
              importance: {
                Low: false,
                Medium: false,
                High: true,
              },
              source: ["jobDescription"],
              spans: skillsSpans,
            })
          );
        }
        break;

      default:
        break;
    }
  };
  return (
    <React.Fragment>
      {!(contextMenuDisabled || isReview === "1") && isAffordanceOpen && (
        <div className="jobDescriptionAffordance">
          <InfoSvg Recommended={true} width="32" height="32" />
          <p>{`If you don’t see a ${contextMenuLabel} marked on the JD, highlight and right click any word or phrase to mark it as one.`}</p>
          <LabelButton onClick={(e) => setIsAffordanceOpen((prev) => !prev)}>
            <CrossSvg width="16" height="16" />
          </LabelButton>
        </div>
      )}
      <div className="jobDescriptionCardContainer" style={containerStyle}>
        <CardContainer icon={<JobSetupStep01 />} heading="Job Description">
          <div className="jobDescriptionCard" style={cardStyle}>
            {contextMenuDisabled || isReview === "1" ? (
              listOfComponents
            ) : (
              <ContextMenuContainer
                onShow={(e) => setSelectedText(getSelectionText())}
                onHide={(e) => setSelectedText("")}
                disabled={contextMenuDisabled}
                menuItems={
                  selectedText === "" ? (
                    <MenuItem disabled={contextMenuDisabled}>
                      <p>{`Select text before marking a ${contextMenuLabel}`}</p>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={onContextMenuClick}
                      disabled={contextMenuDisabled}
                    >
                      <LabelButton>{`Add ${contextMenuLabel}`}</LabelButton>
                    </MenuItem>
                  )
                }
              >
                {listOfComponents}
              </ContextMenuContainer>
            )}
          </div>
        </CardContainer>
      </div>
    </React.Fragment>
  );
};

export default connect((state) => ({
  selectedValues: state.jobSetup.viewController.selectedValues,
  highlightValue: state.jobSetup.viewController.highlightValue,
  jobDescription: state.jobSetup.jobDetails.description,
  skills: state.jobSetup.jobCriteria.skills,
  specialities: state.jobSetup.jobSpecialities.specialities,
  isReview: state.jobSetup?.jobSetup?.isReview,
  education: state.jobSetup.education,
}))(JobDescriptionCard);
