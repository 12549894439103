import { callQluLinkedinApi } from '../utils/MessagePassing'
import { CALL_API } from '../middlewares/apiMiddleware'
import { manualApiCall } from "../utils"

export const FETCH_LINKEDIN_MESSAGES = 'FETCH_LINKEDIN_MESSAGES'
export const FETCH_LINKEDIN_MESSAGES_SUCCESS = 'FETCH_LINKEDIN_MESSAGES_SUCCESS'
export const FETCH_LINKEDIN_MESSAGES_FAILURE = 'FETCH_LINKEDIN_MESSAGES_FAILURE'

export const getLinkedInMessages = ({ firstName, lastName }) => async (dispatch) => {
    dispatch({ type: FETCH_LINKEDIN_MESSAGES })
    let resUrn = await new Promise((resolve, reject) => callQluLinkedinApi(`/voyager/api/messaging/conversations?keywords=${firstName}%20${lastName}&q=search`, {
        method: 'GET'
    }, r => resolve(r)))

    console.log('=====================resUrnInfo===========================')
    console.log(resUrn);

    let urn = "";
    if (resUrn && resUrn.elements && resUrn.elements.length > 0) {
        urn = (resUrn.elements[0].backendUrn).split(":")[3];


        let resCon = await new Promise((resolve, reject) => callQluLinkedinApi(`/voyager/api/messaging/conversations/${encodeURIComponent(urn)}/events?q=syncToken`, {
            method: 'GET'
        }, r => resolve(r)))

        console.log('=====================resConInfo===========================')
        console.log(resCon);

        let messages = [];
        if (resCon) {
            resCon.elements?.reverse().map(message => {
                let linkedInMessage = {
                    "message": message.eventContent["com.linkedin.voyager.messaging.event.MessageEvent"].attributedBody.text,
                    "creationDateTime": message.createdAt,
                    "senderName": message.from["com.linkedin.voyager.messaging.MessagingMember"].miniProfile.firstName + " " + message.from["com.linkedin.voyager.messaging.MessagingMember"].miniProfile.lastName,
                    "publicIdentifier": message.from["com.linkedin.voyager.messaging.MessagingMember"].miniProfile.publicIdentifier,
                    "channel": "linkedIn"
                }
                messages.push(linkedInMessage);
            })
        }
        dispatch({
            type: FETCH_LINKEDIN_MESSAGES_SUCCESS,
            payload: messages
        })
    }
    else {
        dispatch({
            type: FETCH_LINKEDIN_MESSAGES_FAILURE,
            payload: []
        })
    }
}

export const FETCH_JOBS_WITH_CAMPAIGNS = "FETCH_JOBS_WITH_CAMPAIGNS";
export const FETCH_JOBS_WITH_CAMPAIGNS_SUCCESS = "FETCH_JOBS_WITH_CAMPAIGNS_SUCCESS";
export const FETCH_JOBS_WITH_CAMPAIGNS_FAILURE = "FETCH_JOBS_WITH_CAMPAIGNS_FAILURE";

export const fetchJobCampaigns = () => ({
    [CALL_API]: {
        types: [FETCH_JOBS_WITH_CAMPAIGNS, FETCH_JOBS_WITH_CAMPAIGNS_SUCCESS, FETCH_JOBS_WITH_CAMPAIGNS_FAILURE],
        endpoint: `/api/v2/auth/messaging/jobs/with/campaigns`,
        options: {
            method: 'POST'
        }
    }
});

// export const FETCH_MESSAGING_JOB_CAMPAIGNS = "FETCH_MESSAGING_JOB_CAMPAIGNS";
// export const FETCH_MESSAGING_JOB_CAMPAIGNS_SUCCESS = "FETCH_MESSAGING_JOB_CAMPAIGNS_SUCCESS";
// export const FETCH_MESSAGING_JOB_CAMPAIGNS_FAILURE = "FETCH_MESSAGING_JOB_CAMPAIGNS_FAILURE";

// export const fetchJobCampaigns = (jobId = null, search = null) => ({
//     [CALL_API]: {
//         types: [FETCH_MESSAGING_JOB_CAMPAIGNS, FETCH_MESSAGING_JOB_CAMPAIGNS_SUCCESS, FETCH_MESSAGING_JOB_CAMPAIGNS_FAILURE],
//         endpoint: `/api/v2/auth/campaigns?jobId=${jobId}&type=m${search ? `&s=${search}` : ""}`,
//         options: {
//             method: 'GET'
//         }
//     }
// });