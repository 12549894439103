import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import NewAutoSuggestion from '../../../components/NewAutoSuggestion/NewAutoSuggestion'
import PillButton from '../../../uiLibrary/PillButton'
import { 
    addSelectedJobLocation, clearJobLocationSuggestions, fetchJobLocations, removeSelectedJobLocation,  setJobLocation , fetchDbJobLocations , fetchSuggestedLocation , removeSuggestedJobLocation, fetchRegionArea, setIsEdited, setJobLocationEdited
} from '../../../actions/JobSetup'

import './jobDetailsLocation.scss'
import { Mixpanel } from '../../../utils/Mixpanel'
const JobDetailsLocation = ({
    specificLocation,
    similarLocations,
    location='',
    suggestions=[],
    selectedLocations=[],
    locationSuggestions=[],
    dispatch,
    isReview
}) => {

    useEffect(()=>{    
        Mixpanel.track("Location")
    }, [])


    const handleChangeInput = (e) => {
    // selectedLocations.length<15 ? 
        dispatch(setJobLocation(e.target.value)) 
        // : alert("Reached Max location!")
}

    const handleSuggestionsFetchRequested=(value)=>{
        dispatch(fetchJobLocations(value))
        // dispatch(fetchDbJobLocations(value))
    }

    const handleSuggestionsClearRequested=()=>dispatch(clearJobLocationSuggestions())

    const handleSuggestionSelected=(value)=>{
        // console.log("==============selectedLocations.every(loc=>parseLocation(loc)!==parseLocation(value))================", selectedLocations.every(loc=>parseLocation(loc)!==parseLocation(value)))
        // console.log("==============parseLocation(value)================", parseLocation(value))

        if(selectedLocations.every(loc=>parseLocation(loc)!==parseLocation(value))){
            // if(value.id == null){
            //     dispatch(fetchSuggestedLocation(value))
            //     dispatch(fetchRegionArea(value))
            // }
            // else{
            //     dispatch(addSelectedJobLocation(value))
            //     dispatch(fetchSuggestedLocation(value))
            //     dispatch(fetchRegionArea(value))
            // }
            // dispatch(addSelectedJobLocation(value))
            // dispatch(fetchSuggestedLocation(value))
            // FOR REGIONS ONLY
            if(value.id==0){
                dispatch(fetchRegionArea(value))
            }else{
                if(value.name == 'Brazil' || value.name == 'Argentina')  dispatch(fetchRegionArea(value))
                dispatch(addSelectedJobLocation(value))
                dispatch(fetchSuggestedLocation(value))
                dispatch(setJobLocationEdited(true))
            }
        }
        dispatch(setJobLocation(''))
    }

    const handleAddSuggestion=(value)=>{
        // if(selectedLocations.length<15){
            if(selectedLocations.every(loc=>parseLocation(loc)!==parseLocation(value))){
                dispatch(addSelectedJobLocation(value))
                dispatch(fetchSuggestedLocation(value))
                // dispatch(fetchRegionArea(value))
                // dispatch(removeSuggestedJobLocation(value.name))
            }
            dispatch(setJobLocation(''))
        // }else{alert("Reached Max location!")}       
    }


    const handlePillClick = (value) => {
        dispatch(removeSelectedJobLocation(value))

        dispatch(setJobLocationEdited(true))
        // //dispatch(setIsEdited(true))
    }

    const parseLocation = (value) => (
        typeof value === 'string' ?
            value : 
            value?.name
    )

    return (
        <div
            className='jobDetailsLocationContainer'
        >
            <h1>
                Where are you looking to hire from?
            </h1>
            <p>
                City, state, Zone or Country
            </p>
            <NewAutoSuggestion
                inputValue={location}
                suggestionsArray={suggestions}
                placeholder='Pacific'
                handleChangeInput={isReview === '1' ? () => false : handleChangeInput}
                handleSuggestionSelected={isReview === '1' ? () => false : handleSuggestionSelected}
                handleSuggestionsFetchRequested={isReview === '1' ? () => false : handleSuggestionsFetchRequested}
                handleSuggestionsClearRequested={isReview === '1' ? () => false : handleSuggestionsClearRequested}
            />
            <div
                className='selectedLocationContainer'
            >
                {selectedLocations.map(location=>(
                    <PillButton
                        key={parseLocation(location)}
                        selected={true}
                        highlight={true}
                        onClick={() => isReview === '1' ? () => false : handlePillClick(parseLocation(location))}
                    >
                        {parseLocation(location)}
                    </PillButton>
                ))}
                {similarLocations.filter(o1 => !selectedLocations.some(o2 => o1.id === o2.id)).map(location=>(
                    <PillButton
                        key={parseLocation(location)}
                        selected={false}
                        highlight={true}
                        onClick={() => isReview === '1' ? () => false : handleAddSuggestion(location)}
                    >
                        {parseLocation(location)}
                    </PillButton>
                ))}
                {(specificLocation[selectedLocations[0]?.id] && selectedLocations.length == 1) ? <div className='specificLocation'>Bad location, too few results</div> : false}
            </div>
            {locationSuggestions.filter(o1 => !selectedLocations.some(o2 => o1.id === o2.id)).length && selectedLocations.length ? <>
                <p>Suggested - Nearest or broader locations</p>
                <div
                    className='selectedLocationContainer'
                >
                    {locationSuggestions.filter(o1 => !selectedLocations.some(o2 => o1.id === o2.id)).map(location=>(
                        <PillButton
                            key={parseLocation(location)}
                            selected={false}
                            highlight={true}
                            onClick={() => isReview === '1' ? () => false : handleAddSuggestion(location)}
                        >
                            {parseLocation(location)}
                        </PillButton>
                    ))}
                </div>
            </> : false}
        </div>
    )
}

export default connect(state => (
    {
        location: state.jobSetup.jobLocation.location,
        suggestions: state.jobSetup.jobLocation.suggestions,
        selectedLocations: state.jobSetup.jobLocation.selectedLocations,
        locationSuggestions: state.jobSetup.jobLocation.locationSuggestions,
        isReview: state.jobSetup?.jobSetup?.isReview,
        specificLocation: state.jobSetup.jobLocation.specificLocation,
        similarLocations: state.jobSetup.jobLocation.similarLocations
    }
))(JobDetailsLocation)
