import React, { useEffect } from 'react'
import { Button, UncontrolledButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import { connect } from 'react-redux'
import DottedVerticalSvg from "../../../components/svgComponents/DottedVerticalSvg";
import SecondaryButton from '../../../uiLibrary/SecondaryButton';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Overview from '../Overview/index';
import Profile from '../Profile/index';
import './eventDetails.scss'
import Comments from '../Comments/index';
import Messages from '../Messages/index';

const EventDetails = ({ currentUser, selectedEvent, onDelete }) => {

    const onJoinMeeting = () => {
        localStorage.setItem("pageData", selectedEvent?.hangoutLink)
        window.open(selectedEvent?.hangoutLink);
    }

    const onDeleteClick = () => {
        onDelete();
    }

    const getFirstName = () => {
        let name = null;
        if (selectedEvent && selectedEvent.profile) {
            name = selectedEvent.profile.firstName;
        }
        else if (selectedEvent.attendees) {
            selectedEvent.attendees.map(item => {
                if (!item.self) {
                    name = item.displayName ? item.displayName : item.email;
                }
            })
        }
        return name;
    }

    const getTitle = () => {
        let title = null;
        if (selectedEvent && selectedEvent.profile) {
            title = selectedEvent.profile.title
        }
        else {
            title = selectedEvent.summary;
        }
        return title;
    }

    return (
        <div className="eventDetailsContainer">
            <div className="eventDetailsHeader">
                <div>
                    <div className="heading1">You - {getFirstName()}</div>
                    <div className="heading2">Job - {getTitle()}</div>
                </div>
                <div className="btnsContainer">
                    {
                        <SecondaryButton className="meetingButton" onClick={onJoinMeeting}>
                            Join
                        </SecondaryButton>
                    }
                    <UncontrolledButtonDropdown>
                        <DropdownToggle className="iconButton">
                            <DottedVerticalSvg />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => onDeleteClick()}>
                                Delete
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div>
            <div className="tabsContainer">
                <Tabs>
                    <TabList>
                        <Tab>OVERVIEW</Tab>
                        {
                            selectedEvent?.profile ?
                                <React.Fragment>
                                    <Tab>PROFILE</Tab>
                                    <Tab>MESSAGES</Tab>
                                    <Tab>COMMENTS</Tab>
                                </React.Fragment> :
                                []
                        }
                    </TabList>

                    <TabPanel>
                        <Overview currentUser={currentUser} selectedEvent={selectedEvent}></Overview>
                    </TabPanel>

                    {
                        selectedEvent?.profile ?
                            <React.Fragment>
                                <TabPanel>
                                    <Profile currentUser={currentUser} selectedEvent={selectedEvent}></Profile>
                                </TabPanel>

                                <TabPanel>
                                    <Messages profile={selectedEvent.profile} messages={selectedEvent.profile?.sharedmessages} currentUser={currentUser}></Messages>
                                </TabPanel>

                                <TabPanel>
                                    <Comments clickedProspectDetails={selectedEvent?.profile}></Comments>
                                </TabPanel>
                            </React.Fragment> :
                            []
                    }
                </Tabs>
            </div >
        </div>
    )
}

export default connect()(EventDetails)
