import React from 'react'
import '../style.scss'
const JobSetupStep03 = ({
    strokeWidth='2',
    width='24',
    height='24',
    selected=false,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}  
                d="M6 5C6 3.89543 6.89543 3 8 3H16C17.1046 3 18 3.89543 18 5V20H6V5Z" 
                strokeWidth={strokeWidth}/>
            <path
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}   
                d="M10 17C10 15.8954 10.8954 15 12 15C13.1046 15 14 15.8954 14 17V20H10V17Z" 
                strokeWidth={strokeWidth}/>
            <line
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}   
                x1="4" y1="20" x2="20" y2="20"
                strokeWidth={strokeWidth} strokeLinecap="round"/>
            <line
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}   
                x1="10" y1="11" x2="14" y2="11" 
                strokeWidth={strokeWidth} strokeLinecap="round"/>
            <line
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}   
                x1="10" y1="7" x2="14" y2="7" 
                strokeWidth={strokeWidth} strokeLinecap="round"/>
        </svg>
    )
}

export default JobSetupStep03
