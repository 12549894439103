import { CALL_API } from "../../middlewares/apiMiddleware"

export const FETCH_MY_JOBS = 'FETCH_MY_JOBS'
export const FETCH_MY_JOBS_SUCCESS = 'FETCH_MY_JOBS_SUCCESS'
export const FETCH_MY_JOBS_FAILURE = 'FETCH_MY_JOBS_FAILURE'


export const fetchMyJobs = (page = 0, search = null) => ({
  [CALL_API]: {
    types: [FETCH_MY_JOBS, FETCH_MY_JOBS_SUCCESS, FETCH_MY_JOBS_FAILURE],
    endpoint: `/api/v2/auth/jobs?p=${page + 1}&pP=9&type=m${search ? `&s=${search.trim()}` : ''}`,
    options: {
      method: 'GET'
    }
  }
})


export const FETCH_TRIBE_JOBS = 'FETCH_TRIBE_JOBS'
export const FETCH_TRIBE_JOBS_SUCCESS = 'FETCH_TRIBE_JOBS_SUCCESS'
export const FETCH_TRIBE_JOBS_FAILURE = 'FETCH_TRIBE_JOBS_FAILURE'


export const fetchTribeJobs = (page = 0, search = null) => ({
  [CALL_API]: {
    types: [FETCH_TRIBE_JOBS, FETCH_TRIBE_JOBS_SUCCESS, FETCH_TRIBE_JOBS_FAILURE],
    endpoint: `/api/v2/auth/jobs?p=${page + 1}&pP=9&type=t${search ? `&s=${search.trim()}` : ''}`,
    options: {
      method: 'GET'
    }
  }
})

