import React from "react";

export default function SectionWrapper({ name, children, isHideBorder }) {
  return (
    <div className="profileBodySection">
      <p className="profileBodySectionName">{name}</p>
      <div
        className={
          isHideBorder
            ? "profileBodySectionDataWithoutBorder"
            : "profileBodySectionData"
        }
      >
        {children}
      </div>
    </div>
  );
}
