import React from 'react'
import { connect } from 'react-redux'
import { 
    changeJobDetailTitle
} from '../../../actions/JobSetup'

import './jobDetailsTitle.scss'
const JobDetailsTitle = ({
    title='',
    dispatch
}) => {

    const handleChangeInput = (e) => dispatch(changeJobDetailTitle(e.target.value)) 

    return (
        <div
            className='jobDetailsTitleContainer'
        >
            <h1>
                What title are you hiring for?
            </h1>
            <p>
                Enter job title
            </p>
            <input
                value={title}
                placeholder='Machine Learning Engineer'
                onChange={handleChangeInput}
            />
        </div>
    )
}

export default connect(state => (
    {
        title: state.jobSetup.jobDetails.title,
    }
))(JobDetailsTitle)
