import React from "react";

const MessageCircle = ({ 
    color = "#CDD1D7",
    width = "24",
    height = "24",
    style = {},
    stroke = 1.5, className = '',
    fill = "none" }) => {
    return (
        <svg width={width} height={height} className={className} viewBox="0 0 24 24" style={style} fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7117 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0034 9.87812 19.6951 8.7 19.1L3 21L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92176 4.44061 8.37485 5.27072 7.03255C6.10083 5.69025 7.28825 4.60557 8.7 3.9C9.87812 3.30493 11.1801 2.99656 12.5 3H13C15.0843 3.11499 17.053 3.99476 18.5291 5.47086C20.0052 6.94695 20.885 8.91565 21 11V11.5Z" stroke={color} stroke-width={stroke} stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default MessageCircle;
