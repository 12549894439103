import React from 'react'

const CrossSvg = ({
    width='24', 
    height='24',
    color='#A7ABB0',
    strokeWidth="2",
    style={}
}) => {
    return (
        <svg style={style} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 6L18 18" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default CrossSvg
