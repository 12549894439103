import React, { useState } from 'react'
import ProfileDisplayImage from '../../../../components/ProfileDisplayImage/ProfileDisplayImage'
import Loading from '../../../../uiLibrary/Loading'
import PrimaryCheckBox from '../../../../uiLibrary/PrimaryCheckBox'
import ProfileColabActions from '../ProfileColabActions'
import './style.scss'
const ProfileSummaryVariant1 = ({
  name,
  title,
  company,
  location,
  src,
  index,
  emojies,
  isSelected=false,
  onSelect=e=>false,
  jobProfileId,
  profileData,
  isCalibrating,
  onClick,
  onClickComments,
  mixpanelActionType,
  disableColab=false,
  disableSelect=false
}) => {
  const [isLoading, setIsLoading] = useState(false)
  return (
    <div
      className='profileSummaryVariant1Container'
    >
      <div>
        {!isCalibrating && <p>
          {index || index===0?index+1:""}
        </p>}
        {(!isCalibrating && !disableSelect) && <PrimaryCheckBox
          style={{width: '16px', height: '16px'}}
          onChange={onSelect}
          checked={isSelected}
        />}
      </div>
      <ProfileDisplayImage
        width='110px'
        height='110px'
        src={src}
        style={{marginRight: '34px'}}
      />
      <span>
        <h1>
          {name}
        </h1>
        <div>
          <h2>
            {`${title} ${title && company ? '@' : ''}`}
          </h2>
          <h3
            onClick={e=>onClick(e, e=>setIsLoading(true), e=>setIsLoading(false))}
          >
            {company}
          </h3>
          {isLoading && <Loading width='20px' height='20px' />}
        </div>
        {!isCalibrating && <h4>
          {location}
        </h4>}
        {(!isCalibrating && !disableColab) && <ProfileColabActions
          emojies={emojies}
          onClickComments={onClickComments}
          jobProfileId={jobProfileId}
          profileData={profileData}
          mixpanelActionType={mixpanelActionType}
        />}
      </span>
    </div>
  )
}

export default ProfileSummaryVariant1