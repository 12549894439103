import React, { useEffect, useState } from "react";
import "./EditJobWrapper.scss";
import { connect } from "react-redux";
import qs from "qs";
import { setIsReview } from "../../../actions/JobSetup";
import EyeSvg from "../../../uiLibrary/SVGs/EyeSvg";
import PenSvg from "../../../uiLibrary/SVGs/PenSvg";
import { Mixpanel } from "../../../utils/Mixpanel";

function EditJobWrapper({
  children,
  isReview = "",
  search,
  isEdit,
  isDuplicate,
  permissionsAllowed,
  dispatch,
}) {
  const qParams = qs.parse(search.split("?").pop());
  useEffect(() => {
    // if (qParams?.review && qParams?.review === "true") {
    //   dispatch(setIsReview(true));
    // } else {
    //   dispatch(setIsReview(false));
    // }

    return () => {
      dispatch(setIsReview(""));
    };
  }, []);

  useEffect(() => {}, [isReview]);

  const onClickHandler = (action) => {
    console.log("==================onClickHandler=========================", {action})
    dispatch(setIsReview(action));
  };
  return (
    <div className={isReview === "1" ? "JobInReview" : ""}>
      {isEdit && !isDuplicate
        ? isReview == "" && (
            <div className={`editJobWrapper`}>
              <div onClick={() => {                    
                  Mixpanel.track(`View Job Setup`)
                  onClickHandler("1")}}>
                <span>
                  <EyeSvg width="24" height="24" />
                </span>
                <p>View Job Setup</p>
              </div>
              <div
                onClick={() =>
                  permissionsAllowed === "*" ||
                  (permissionsAllowed?.length &&
                    permissionsAllowed?.length > 0 &&
                    permissionsAllowed?.includes("EDIT_JOB_SETUP")) && !qParams?.aT
                    ? onClickHandler("0")
                    : false
                }
                className={`${
                  permissionsAllowed === "*" ||
                  (permissionsAllowed?.length &&
                    permissionsAllowed?.length > 0 &&
                    permissionsAllowed?.includes("EDIT_JOB_SETUP"))
                    ? ""
                    : "notAllowed"
                }`}
              >
                <span>
                  <PenSvg width="24" height="24" />
                </span>
                <p>Edit Job Setup</p>
              </div>
            </div>
          )
        : ""}
      {isEdit && !isDuplicate ? isReview != "" && children : children}
    </div>
  );
}

export default connect((state) => {
  return {
    isReview: state.jobSetup?.jobSetup?.isReview,
    permissionsAllowed: state.prospectsView.candidates.permissions,
  };
})(EditJobWrapper);
