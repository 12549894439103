import {
  IndustryFAQ1,
  IndustryFAQ2,
  IndustryFAQ3,
  IndustryFAQ4,
  IndustryFAQ5,
  IndustryFAQ6,
  IndustryFAQ7,
  IndustryFAQ8,
  IndustryFAQ9,
  IndustryFAQ10,
  IndustryFAQ11,
  IndustryFAQ12,
  IndustryFAQ13,
  IndustryFAQ14,
} from "../Answers/Industry";

export const IndustryFAQS = [
  {
    heading: "What is Business, Specialties and Industries?",
    content: <IndustryFAQ1 />,
  },
  {
    heading: "What do the gray and orange pills mean?",
    content: <IndustryFAQ2 />,
  },
  {
    heading: "What is the hiring company Business Speciality?",
    content: <IndustryFAQ3 />,
  },
  {
    heading: "Where is Qlu getting the information about business speciality?",
    content: <IndustryFAQ4 />,
  },
  {
    heading:
      "What is Similar companies’ businesses/specialties and industries?",
    content: <IndustryFAQ5 />,
  },
  {
    heading:
      "What is the best fit profile’s business specialties and industries?",
    content: <IndustryFAQ6 />,
  },
  {
    heading:
      "What is the best fit profile’s business specialties and industries?",
    content: <IndustryFAQ7 />,
  },
  {
    heading: "What do the logos showing in the best fit profile mean?",
    content: <IndustryFAQ8 />,
  },
  {
    heading: "What is the list shown in the end?",
    content: <IndustryFAQ9 />,
  },
  {
    heading: "Can I add a business speciality or industry?",
    content: <IndustryFAQ10 />,
  },
  {
    heading: "What should I not add here?",
    content: <IndustryFAQ11 />,
  },
  {
    heading: "What can I add here?",
    content: <IndustryFAQ12 />,
  },
  {
    heading:
      "I’m adding a Business/Specialty but it isn’t adding. What am I doing wrong?",
    content: <IndustryFAQ13 />,
  },
  {
    heading: "How do you use the data of business speciality and industry?",
    content: <IndustryFAQ14 />,
  },
];
