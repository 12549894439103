import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeJobDescription,
  setIsJDChatLoading,
  setIsJDGenerated,
  setJDChatList,
  setJobDescription,
} from "../../../../../../actions/JobSetup";
import { manualApiCall } from "../../../../../../utils";
import ChatBubble from "../ChatBubble/ChatBubble";
import ChatInput from "../ChatInput/ChatInput";
import "./GenerateJD.scss";

const ChatBubbleList = ({ data, onFinish, isLoading, setIsAnimating }) => {
  const flexRef = useRef();
  const scrollToBottom = () => {
    if (flexRef.current) {
      let elem = flexRef?.current;
      while (elem?.lastElementChild) {
        elem = elem?.lastElementChild;
      }
      elem?.scrollIntoView();
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [flexRef?.current?.scrollHeight]);

  return (
    <>
      <div className="flexInput" ref={flexRef}>
        {data.map((c) => (
          <ChatBubble
            key={c.id}
            text={c.text}
            fromBot={c.bot}
            onFinish={onFinish}
            intro={c.intro}
            isGibberish={c.isGibberish}
            onTyping={scrollToBottom}
            setIsAnimating={setIsAnimating}
          />
        ))}
        {isLoading && (
          <ChatBubble key={"Load"} text={""} fromBot={true} loader={true} />
        )}
      </div>
    </>
  );
};

const Wrapper = () => {
  const [jdConversation,setJdConversation] = useState([
    {
      role: "system",
      content: "You are a hiring manager that creates job descriptions.",
    },
    {
      role: "assistant",
      content:
        "Create a detailed formatted Job Description in bullet form given the following:",
    },
  ]);
  const { jdChatList, isJDChatLoading } = useSelector((state) => ({
    jdChatList: state.jobSetup.jobSetup.jdChatList,
    isJDChatLoading: state.jobSetup.jobSetup.isJDChatLoading,
  }));
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState("");
  const [isAnimating, setIsAnimating] = useState(true);

  const handleRequest = async (payload) => {
    setErrorMsg("");
    dispatch(setIsJDChatLoading(true));
    const response = await manualApiCall("/api/v2/generate-job-description", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
    console.log("response payload",payload)
    console.log("response from jd", response)

    if (response?.error) {
      console.log("An error occurred", response.error);
      setErrorMsg("An error occurred");
      dispatch(setIsJDChatLoading(false));
      return;
    }
    if (response?.content && response?.content?.trim() !== "") {
      console.log("prev conversatio:",jdConversation)
      console.log("setting jd to output",response)
      setJdConversation(jdConversation=>[...jdConversation  ,response])

      dispatch(
        setJDChatList({
          text: response?.content,
          bot: true,
          id: payload.length,
          isGibberish: response?.isGibberish,
        })
      );
      setIsAnimating(true);
    } else {
      dispatch(setIsJDChatLoading(false));
    }
  };

  const sendRequest = (text) => {
    text = text.trim();
    let usertxt = {
      "role":"user",
      "content":text
    } 
    console.log("setting jd with user txt", usertxt)
    setJdConversation(jdConversation=>[...jdConversation,usertxt])
    if (text === "") return;
    const req = { text , bot: false, id: jdChatList.length };
    const req2 = { text:[...jdConversation,usertxt] , bot: false, id: jdChatList.length };
    dispatch(setJDChatList(req));
    handleRequest([...jdChatList,req2]);
  };

  return (
    <div className={"fullScreenAccordion"}>
      <div className="content">
        <ChatBubbleList
          data={jdChatList}
          isLoading={isJDChatLoading}
          setIsAnimating={setIsAnimating}
          onFinish={(JD) => {
            dispatch(setJobDescription(JD));
            dispatch(changeJobDescription(JD));
            dispatch(setIsJDGenerated(false));
          }}
        />
      </div>
      {errorMsg.trim() !== "" && !isJDChatLoading && (
        <span className="jd-chat-error">
          {errorMsg} Please
          <span onClick={() => handleRequest(jdChatList)}>Retry</span>
        </span>
      )}
      <div className="text-box">
        <ChatInput
          onEnter={sendRequest}
          isLoading={isJDChatLoading || isAnimating}
        />
      </div>
    </div>
  );
};

export const GenerateJD = [<Wrapper />];
export const ChatScreen = ({ data }) => data[0];
