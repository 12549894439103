import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { connect, useDispatch } from "react-redux";
import { Transition } from "react-transition-group";
import { setIsJDGenerated } from "../../../actions/JobSetup";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import SlideDrawer from "../../../uiLibrary/SlideDrawer";
import SupportSvg from "../../../uiLibrary/SVGs/SupportSvg";
import Chat from "./Chat";
import { ChatScreen } from "./FAQs/JobDescription/GenerateJD/GenerateJD";
import FAQScreen from "./FAQScreen";
import Header from "./Header";
import "./style.scss";
const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default connect((state) => {
  return ({
    generateJD: state.jobSetup?.jobSetup?.generateJD
  })
})
  (function Support({ data, generateJD }) {
    const [isSupportOpen, setIsSupportOpen] = useState(false);
    const [isFullScreenOpen, setIsFullScreenOpen] = useState(generateJD);
    const [isPrimaryScreen, setIsPrimaryScreen] = useState(true);
    const [shouldHighlightBot, setShouldHighlightBot] = useState(true);
    const { height, width } = useWindowDimensions();
    const windowCenter = { x: 0, y: 0 };

    const [position, setPosition] = useState(windowCenter);

    useEffect(() => {
      handleHorizontalPosition(position.x);
      handleVerticalPosition(position.y);
    }, [width, height]);

    useEffect(() => {
      setShouldHighlightBot(true);
      setIsSupportOpen(false);
    }, [data]);
    const dispatch = useDispatch()

    useEffect(() => {
      setShouldHighlightBot(true);
      setIsSupportOpen(generateJD);
    }, [generateJD]);

    const handleHorizontalPosition = (x) => {
      if (x > -width * 0.425) {
        return setPosition((prev) => ({ ...prev, x: 0 }));
      }
      if (x < -width * 0.425) {
        return setPosition((prev) => ({ ...prev, x: -width * 0.85 }));
      }
    };

    const handleVerticalPosition = (y) => {
      if (y > -height / 2) {
        return setPosition((prev) => ({ ...prev, y: 0 }));
      }
      if (y < -height / 2) {
        return setPosition((prev) => ({ ...prev, y: -height * 0.8 }));
      }
    };

    const onStop = (e, { x, y }) => {
      handleHorizontalPosition(x);
      handleVerticalPosition(y);
    };

    const onDrag = (e, { x, y }) => {
      //setIsSupportOpen(false);
      setPosition({ x, y });
    };


    if (generateJD) {
      return (
        <>
          <div className="supportBoxContainer JD">
            <SlideDrawer
              heading="QLU BOT"
              isPrimaryScreen={true}
              isOpen={true}
              handleClose={() => {
                dispatch(setIsJDGenerated(false))
              }}
              setIsPrimaryScreen={() => { }}
              setIsOpen={() => { }}
            >
              <ChatScreen data={data} />
            </SlideDrawer>
          </div>
        </>
      )
    }

    return (
      <>
        {isFullScreenOpen && (
          <SlideDrawer
            heading="FAQs"
            isPrimaryScreen={isPrimaryScreen}
            setIsPrimaryScreen={setIsPrimaryScreen}
            setIsOpen={setIsFullScreenOpen}
            isOpen={isFullScreenOpen}
            canMinimize
            handleClose={() => {
              setIsFullScreenOpen(false);
              setIsSupportOpen(false);
            }}
            secondaryChildren={
              <Chat fullScreen setIsPrimaryScreen={setIsPrimaryScreen} />
            }
          >
            <FAQScreen
              data={data}
              fullScreen
              setIsPrimaryScreen={setIsPrimaryScreen}
            />
          </SlideDrawer>
        )}
        <Draggable
          position={position}
          onDrag={onDrag}
          onStop={onStop}
          bounds={{
            top: -height * 0.8,
            left: -width * 0.86,
            right: 0,
            bottom: 40,
          }}
        >
          <div
            style={{
              bottom: 40,
              right: 50,
              position: "absolute",
              zIndex: 30,
            }}
          >
            <Transition
              in={isSupportOpen && !isFullScreenOpen}
              timeout={duration}
            >
              {(state) =>
                isSupportOpen &&
                !isFullScreenOpen && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: position.y < -height / 2 ? -400 : 93,
                      right: position.x < -700 ? -220 : 20,
                      ...defaultStyle,
                      ...transitionStyles[state],
                    }}
                    className="supportBoxContainer"
                  >
                    <Header
                      isPrimaryScreen={isPrimaryScreen}
                      setIsSupportOpen={setIsSupportOpen}
                      setIsPrimaryScreen={setIsPrimaryScreen}
                      setIsFullScreenOpen={setIsFullScreenOpen}
                    />
                    {isPrimaryScreen ? (
                      <Transition in={isPrimaryScreen} timeout={1000}>
                        <FAQScreen
                          data={data}
                          setIsPrimaryScreen={setIsPrimaryScreen}
                        />
                      </Transition>
                    ) : (
                      <Transition in={!isPrimaryScreen} timeout={1000}>
                        <Chat setIsPrimaryScreen={setIsPrimaryScreen} />
                      </Transition>
                    )}
                  </div>
                )
              }
            </Transition>
            <div
              className={!shouldHighlightBot ? "botIcon" : "botIconOpen"}
              onClick={() => {
                setIsSupportOpen(!isSupportOpen);
                setShouldHighlightBot(!shouldHighlightBot);
              }}
            >
              <SupportSvg />
            </div>
          </div>
        </Draggable>
      </>
    );
  }
  )