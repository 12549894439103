import React from "react";
import RenderInlineStyles from "./RenderInlineStyles";
import "./Toolbar.scss"

const Toolbar = ({ editorState, updateEditorState, pasteHandler, value }) => {
  return (
    <div className="textEditortToolBarContainer">
      <RenderInlineStyles
        editorState={editorState}
        updateEditorState={updateEditorState}
        pasteHandler={pasteHandler}
        value={value}
      />
    </div>
  );
};

export default Toolbar;
