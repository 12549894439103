import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import JobCard from "../../../components/JobCard/JobCard";
import Loading from "../../../components/Loading";
import SectionHeader from "../../../components/SectionHeader";
import { PER_PAGE_COUNT } from "../../../constants/api";
import { getDefaultFilters } from "../../../utils";
import { ActivityTypes, loggingActivity } from "../../../utils/activityLogger";
import { Mixpanel } from '../../../utils/Mixpanel';
import BlankSlate from "./BlankSlate";

const RecentJobs = ({ isLoading, isDataAvailable, data, history }) => {
  const dispatch = useDispatch();
  const onViewJob = ({ id, meta: { isQuick }, jobArray }) => {
    loggingActivity(ActivityTypes.VIEW_JOB, id);
    const { fS, fSO, secS, secSO, hCSR } = getDefaultFilters(jobArray)

    return isQuick ? history.push(
      `/job/${id}/true?fF=a&p=0&b=all&ac=true&pPP=${PER_PAGE_COUNT}&fS=${fS}&fSO=${fSO}&f=a`
    ) : history.push(
      `/job/${id}?fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&ac=true&fS=${fS}&fSO=${fSO}&secS=${secS}&secSO=${secSO}&f=a&hCSR=${encodeURIComponent(hCSR)}`
    );
  };
  return (
    <div>
      <SectionHeader
        label="Recent Jobs"
        onClickViewAll={() => {
          Mixpanel.track("View All Jobs")
          history.push("/jobs");
        }}
        actions={[
          {
            text: "New Job",
            onClick: () => {
              Mixpanel.track('Deep Search');
              history.push("/jobsetup");
            },
          },
          // {
          //   text: "⚡ Quick Search",
          //   onClick: () => {
          //     Mixpanel.track("Quick Search")
          //     history.push("/QuickSetup");
          //   },
          // },
        ]}
        showAction={!isLoading && isDataAvailable}
        showViewAll={!isLoading && isDataAvailable}
      />
      <div className="jobItemsContainer">
        {isLoading && (
          <div className="dflex-cc" style={{ height: "280px", width: "100%" }}>
            <Loading />
          </div>
        )}
        {!isLoading && isDataAvailable && (
          <>
            {data.jobs.map((item, i) => (
              <JobCard
                isQuick={item?.meta?.isQuick}
                key={item.id || i}
                jobTitle={item?.jobTitle}
                jobDescription={item?.jobDescription}
                jobLocationsArray={item.jobArray.job_location.map(
                  (item) => item.name
                )}
                createdDate={moment(item?.createdAt).format("MMM DD, YYYY")}
                jobCreatorName={item?.createdBy}
                totalProspects={item?.added}
                messagedProspects={item.messageCounts[0]?.messaged}
                repliedProspects={item.messageCounts[0]?.replied}
                companyHiringFor={item?.jobArray?.companyHiringFor?.[0]?.name}
                // jobStatus={job.jobStatus}
                jobStatus={""}
                // tribeCount={job.tribeCount}
                tribeCount={0}
                // onClickViewJob={() => {
                //   history.push(`/job/${job.id}?fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}${jobCardCtaText.toLowerCase().trim() === 'select' ? '&c=true' : ''}`);
                // }}
                onClickViewJob={() => onViewJob(item)}
                // ctaText={jobCardCtaText}
                label={item?.meta?.label}
              />
              // <JobItem
              //   onClick={() => {
              //     history.push(
              //       `/job/${item.id}?fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&fS=title_score&fSO=desc&secS=skill_score&secSO=desc&f=a`
              //     );
              //     // fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&fS=title_score&fSO=desc&secS=skill_score&secSO=desc&f=a
              //   }}
              //   key={item.id || i}
              //   {...item}
              // />
            ))}
          </>
        )}
        {!isLoading && !isDataAvailable && (
          <BlankSlate
            label="No jobs created yet"
            ctaText="Create Job"
            onClickCta={() => {
              history.push("/jobsetup");
            }}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(RecentJobs);
