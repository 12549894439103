import React from "react";
import { useParams } from "react-router-dom";
import { PER_PAGE_COUNT } from "../../../constants/api";
import BigRoundButton from "../../../uiLibrary/BigRoundButton";
import CaretLeft from "../../../uiLibrary/SVGs/CaretLeft";
import MessageSequenceSVG from "../../../uiLibrary/SVGs/MessageSequenceSvg/index";

import "./style.scss";

const OutboxHeader = ({ push, rank }) => {
  let { jId, isQuick } = useParams()
  if (isQuick === 'false') { isQuick = false }

  // console.log("=============================OutboxHeader=================================", {jId, isQuick}, useParams() )
  const handleBackClick = () => {
    // console.log("=============================handleBackClick=================================", {jId, isQuick})
    const fS = 'title_score'
    const fSO = 'desc'
    const secS = rank > 8 ? 'industry_score' : 'skill_score'
    const secSO = 'desc'
    push(`/job/${jId}?f=a&fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&fS=${fS}&fSO=${fSO}&secS=${secS}&secSO=${secSO}`)
  };

  return (
    <div className="outboxHeader">
      <div
        style={{
          width: "min(100%, 1440px)",
        }}
      >
        <BigRoundButton
          style={{ width: "32px", height: "32px" }}
          onClick={handleBackClick}
        >
          <CaretLeft width={7} height={12} />
        </BigRoundButton>
        <span>
          <MessageSequenceSVG selected={true} />
          <h2>Create Message Campaign</h2>
        </span>
      </div>
    </div>
  );
};

export default OutboxHeader;
