import React, { useEffect, useState } from 'react'
import ProfileDisplayImage from '../../../../components/ProfileDisplayImage/ProfileDisplayImage'
import './style.scss'
import companyPlaceholder from '../../../../img/coy_placeholder.svg'
import Collapsible from 'react-collapsible';
import Loading from '../../../../uiLibrary/Loading';

export const DelimitatedTitle = ({
    title,
    delimiter,
    name,
    onClick = e => false, ...props }) => {
    const [isLoading, setIsLoading] = useState(false)
    return (
        <div
            className='delimitatedTitle'
            {...props}
        >
            <p>
                {title}
            </p>
            {title && name &&
                <p>
                    {delimiter}
                </p>}
            <h6
                onClick={e => !isLoading && onClick(e, e => setIsLoading(true), e => setIsLoading(false))}
            >
                {name}
            </h6>
            {isLoading &&
                <div>
                    <Loading
                        width='20'
                        height='20'
                    />
                </div>}
        </div>
    )
}

export const ProfileCardExperienceDetailsItem = (({
    placeholder,
    disableLogo,
    logo,
    delimiter = "at",
    name,
    title = '',
    start,
    end,
    isCalibrating,
    onClick = e => false
}) => {

    if (end === '') {
        // end = 'Now'
    }

    return (
        <div
            className={`profileCardExperienceDetailsItem ${isCalibrating ? 'profileCardExperienceDetailsCalibratable' : ""}`}
        >
            {!disableLogo && <ProfileDisplayImage
                style={{ borderRadius: '8px' }}
                src={logo}
                defaultPlaceholder={placeholder}
            />}
            <span>
                <DelimitatedTitle
                    title={title}
                    delimiter={delimiter}
                    name={name}
                    onClick={onClick}
                />
                <div>
                    <p>
                        {start}
                    </p>
                    {end && start &&
                        <p>
                            -
                        </p>}
                    <p>
                        {end}
                    </p>
                </div>
            </span>
        </div>
    )
})

const ProfileCardExperienceDetails = ({
    style = {},
    disableLogo = false,
    delimiter = "at",
    experience = [],
    seeMore = [],
    defaultPlaceholder = companyPlaceholder,
    isCalibrating = false
}) => {
    const [isOpen, setIsOpen] = useState(false)

    // console.log("==================ProfileCardExperienceDetails=====================", {experience, seeMore})

    const experienceArray = experience.filter(({ title, name }) => name || title)
    const seeMoreArray = seeMore.filter(({ title, name }) => name || title)

    return (
        <div
            className='profileCardExperienceDetails'
            style={style}
        >
            {experienceArray.length === 0 && <p>
                Not Available
            </p>}
            {experienceArray.map((exp, i) => (
                <ProfileCardExperienceDetailsItem
                    key={`experience${i}`}
                    isCalibrating={isCalibrating}
                    disableLogo={disableLogo}
                    delimiter={delimiter}
                    placeholder={defaultPlaceholder}
                    {...exp}
                />
            ))}
            {seeMoreArray.length > 0 &&
                <Collapsible onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)} trigger={`${seeMoreArray.length} ${isOpen ? "less" : "more"}`}>
                    {seeMoreArray.map((exp, i) => (
                        <ProfileCardExperienceDetailsItem
                            key={`seeMore${i}`}
                            isCalibrating={isCalibrating}
                            disableLogo={disableLogo}
                            delimiter={delimiter}
                            placeholder={defaultPlaceholder}
                            {...exp}
                        />
                    ))}
                </Collapsible>}

        </div>
    )
}

export default ProfileCardExperienceDetails