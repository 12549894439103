import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Label } from 'reactstrap'
import { FETCH_PROVIDER_SUCCESS } from '../../actions/auth'
import FormikInput from '../../components/FormikInput'
import PrimaryButton from '../../uiLibrary/PrimaryButton'
import { validatePhone } from '../../utils'

function Enable2FA({
    location,
    history,
    dispatch,
    email
}) {

    // useEffect(()=>{
    //   localStorage.removeItem('mfa_phone')
    // }, [])

    const validateForm = values => {
        const errors = {};
        if (values.enable) {
            if (!values.phone) {
                errors.phone = 'Phone number is required';
            } else if (!validatePhone(values.phone)) {
                errors.phone = 'Invalid phone number';
            }
        }
        return errors;
    }

    const submitForm = async (values, { props, resetForm, setErrors, setSubmitting }) => {
        setSubmitting(true)
        try {
            if (!values.enable) {
                history.push('/auth/login')
                alert("Please login to continue")
            } else {
                dispatch({ type: FETCH_PROVIDER_SUCCESS, meta: email, phone: values.phone })
                // localStorage.setItem('mfa_phone', values.phone)
                history.push('/auth/verify-2fa')
            }
        } catch (e) {
            console.log("ERROR OCCURRED:", e)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <>
            <Formik
                initialValues={{ phone: '' }}
                validate={validateForm}
                onSubmit={submitForm}
            >
                {({
                    errors,
                    values,
                    isSubmitting
                }) => (
                    <div className='enable_2fa_root authElemContainer'>
                        <h1>Sign up</h1>
                        <Form>
                            <div className='mb32'>
                                <div className='check_group'>
                                    <Field type="checkbox" name="enable" className="checkbox" />
                                    <Label for="enable">Enable 2 Factor Authentication</Label>
                                </div>
                                <ErrorMessage name="enable" render={msg => <p className='formik_err_msg'>{msg}</p>} />
                            </div>
                            <div className='mb32'>
                                <p className='input_label'>PHONE NUMBER</p>
                                <Field type="phone" name="phone" placeholder={'+1 123 456 7890'} component={FormikInput} />
                                <ErrorMessage name="phone" render={msg => <p className='formik_err_msg'>{msg}</p>} />
                            </div>
                            <div className='mb32'>
                                <p>
                                    For extra security, we will send you a 6 digit PIN at your phone number
                                </p>
                            </div>
                            <PrimaryButton className='auth-submit-btn' type="submit"
                                disabled={isSubmitting || errors.phone}>
                                {isSubmitting ?
                                    <Loader
                                        type="TailSpin"
                                        color="white"
                                        height={18}
                                        width={18}
                                    /> :
                                    values.enable ? 'Continue' : 'Skip'}
                            </PrimaryButton>
                        </Form>
                    </div>
                )}
            </Formik>
        </>
    )
}


export default connect(state => ({ ...state.auth.emailStep }))(withRouter(Enable2FA))