import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import ChevronDownSvg from "../SVGs/ChevronDownSvg";
import "./style.scss";

const duration = 400;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const Accordion = ({ heading, content , generateJD}) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(false);
  }, [content]);

  return (
    <li className="accordion-item">
      {!generateJD?<><div className="accordion-toggle" onClick={() => setIsActive(prev=>!prev)}>
        <p className="accordion-label">{heading}</p>
        <span>
          {isActive ? (
            <ChevronDownSvg className="caretUp" />
          ) : (
            <ChevronDownSvg />
          )}
        </span>
      </div>
      <Transition in={isActive} timeout={duration}>
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {isActive && <div className="accordion-content">{content}</div>}
          </div>
        )}
      </Transition></> :
       <Transition in={generateJD} timeout={duration}>
       {(state) => (
         <div
           style={{
             ...defaultStyle,
             ...transitionStyles[state],
           }}
         >
           {generateJD && <div className="accordion-content">{content}</div>}
         </div>
       )}
     </Transition>      
    
    }
      
     
    </li>
  );
};

export default Accordion;
