import React from "react";
import MessageSequenceSVG from "../SVGs/MessageSequenceSVG";
import CaretLeft from "../SVGs/CaretLeft";
import "./styles.scss";
import { useHistory } from "react-router-dom";

const IconHeader = () => {
  const history = useHistory();
  return (
    <div className="iconHeaderContainer">
      <div>
        <div className="caret" onClick={() => history.goBack()}>
          <CaretLeft />
        </div>
        <MessageSequenceSVG style={{ marginLeft: 10 }} color="#FF8D4E" />
        <span>View Message Campaign</span>
      </div>
    </div>
  );
};

export default IconHeader;
