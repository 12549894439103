import React from "react";

export const LocationFAQ1 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            The location of where you’re interested in hiring prospects from
          </li>
        </ul>
      </p>
    </div>
  );
};

export const LocationFAQ2 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Upto 15</li>
        </ul>
      </p>
    </div>
  );
};

export const LocationFAQ3 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>No</li>
        </ul>
      </p>
    </div>
  );
};

export const LocationFAQ4 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Yes</li>
        </ul>
      </p>
    </div>
  );
};

export const LocationFAQ5 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            It depends on if you are very specific in where you want to hire
            from or if you want a wider range of prospects to choose from
          </li>
        </ul>
      </p>
    </div>
  );
};

export const LocationFAQ6 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Add countries from which you are allowing remote work</li>
        </ul>
      </p>
    </div>
  );
};
