import React, { useEffect, useRef, useState } from 'react';
import './ChatInput.scss';

const SendIcon = ({ color = "#7A7A7A" }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22 2L11 13"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round" />
        <path
            d="M22 2L15 22L11 13L2 9L22 2Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>
)


function ChatInput({
    onEnter = () => { },
    isLoading = false
}) {

    const ref = useRef(null)
    const [currentValue, setCurrentValue] = useState("");

    useEffect(() => {
        ref.current.style.height = "0px";
        const scrollHeight = ref.current.scrollHeight;
        ref.current.style.height = scrollHeight + "px";
    }, [currentValue]);
    return (
        <div className='ChatInput'>
            <textarea
                ref={ref}
                value={currentValue}
                onChange={e => {
                    setCurrentValue(e.target.value);
                }}
            ></textarea>
            <button onClick={() => {
                const val = currentValue;
                setCurrentValue('');
                onEnter(val.trim())
            }}>
                {isLoading ? '' :
                    <SendIcon color={currentValue.trim().length > 0 ? '#ff8d4e' : "#7A7A7A"} />
                }
            </button>

        </div >
    )
}

export default ChatInput