import React from 'react'

import './style.scss'
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu'

const MENU_TYPE = 'SIMPLE';


const ContextMenuContainer = ({
    children,
    handleCollect=e=>({}),
    onShow=e=>false,
    onHide=e=>false,
    menuItems,
    disabled=false,
}) => {
    // console.log("=========================ContextMenuContainer============================", {disabled})

    return (
        <React.Fragment>
            <ContextMenuTrigger 
                disable={disabled}
                id={MENU_TYPE} 
                holdToDisplay={1000} 
                preventHideOnContextMenu={false}
                collect={handleCollect}>
                    {children}
            </ContextMenuTrigger>
            <ContextMenu 
                className='ContextMenuContainer'
                id={MENU_TYPE}
                hideOnLeave={true}
                onShow={onShow}
                onHide={onHide}
            >
                {menuItems}
            </ContextMenu>
        </React.Fragment>
    )
}

export default ContextMenuContainer
