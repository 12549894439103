import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { omit } from 'lodash'

const ProtectedRoute = (props) => {
  console.log('PROTECTED ROUTE PROPS: ', props)
  const { user: { isAuthenticated, isAdmin } } = props
  return (
    <>
      {(isAuthenticated || isAdmin) && <Route {...omit(props, ['user'])} />}
      {(!isAuthenticated && !isAdmin) && <Redirect to='/auth/login' />}
    </>
  )
}

export default connect(state => ({ user: state.auth.user }))(ProtectedRoute)