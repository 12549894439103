import React from "react";
import Experience from "./Experience";
import OtherExperience from "./OtherExperience";
import TagSection from "./TagSection";
import "./styles.scss";
import Education from "./Education";
import Header from "../components/Header";
import { getEntityByProfileID } from "../../../reducers/QuickSetup/profileFeedback";
import { useSelector } from "react-redux";

export default function ProfileCard({
  profile: { experience, skills, education, id, ...profile },
  setIsDetailView,
  isDetailView,
  setSelectedProfile,
}) {
  const prospectExperience = experience;
  const prospectSkills = skills;
  const prospectEducation = education;
  const allSpecialties = prospectExperience?.map(
    ({ companySpecialities = [] }) => companySpecialities
  );
  const prospectSpecialties = [...new Set(allSpecialties?.flat())];

  const currentFeedback = useSelector((state) =>
    getEntityByProfileID(state.QuickSetup.profileFeedback, id)
  );

  const openDetailView = (toggle) => {
    setIsDetailView(toggle);
    setSelectedProfile({ experience, skills, education, id, ...profile });
  };

  return (
    <div onClick={() => openDetailView(true)} className="profileContainer">
      <Header
        setIsDetailView={openDetailView}
        profile={profile}
        experience={prospectExperience}
        isDetailView={isDetailView}
        id={id}
        data={currentFeedback?.data}
      />
      <Experience
        profile={{ experience, skills, education, id, ...profile }}
        id={id}
        experience={prospectExperience}
      />
      <OtherExperience
        profile={{ experience, skills, education, id, ...profile }}
        id={id}
        title={"Total Experience"}
      />
      {!!prospectSkills && prospectSkills?.length && (
        <TagSection
          isLiked={currentFeedback?.data?.skills?.isOverallLiked}
          profile={{ experience, skills, education, id, ...profile }}
          id={id}
          name="Skills"
          sectionData={prospectSkills}
        />
      )}
      {!!prospectSpecialties && !!prospectSpecialties?.length && (
        <TagSection
          isLiked={currentFeedback?.data?.business?.isOverallLiked}
          profile={{ experience, skills, education, id, ...profile }}
          id={id}
          name="Business"
          sectionData={prospectSpecialties}
        />
      )}
      {!!prospectEducation && !!prospectEducation?.length && (
        <Education
          profile={{ experience, skills, education, id, ...profile }}
          id={id}
          data={prospectEducation}
        />
      )}
    </div>
  );
}
