import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import qs from 'qs'
// import Loader from '../../components/Loader'

import Loader from 'react-loader-spinner'
import { setUser } from '../../actions/auth'
import { manualApiCall, signInToFirebase } from '../../utils'


const SocialLoginCallback = ({ location, history, dispatch, ...rest }) => {
  console.log('PROPS ARE: ', rest)

  useEffect(async () => {
    if (location.search) {
      const params = qs.parse(location.search.slice(1))
      const userData = {
        ...params,
        is_2fa_enabled: false,
      }
      const user = await signInToFirebase(userData)
      if(user){
        const token = await user.getIdToken(true)
        // console.log("===================SocialLoginCallback==========================", {token, user, userData})
        localStorage.setItem('user', JSON.stringify({...userData, token, sw: user.stsTokenManager.refreshToken}))
        manualApiCall('/api/v2/auth/updateUserData', {
          method: 'POST',
          body: JSON.stringify({sw: user.stsTokenManager.refreshToken})
      })
        dispatch(setUser(userData))
      }
      // localStorage.setItem('user', JSON.stringify(params))
      // dispatch(setUser(params))
    }
  }, [location.search])


  useEffect(() => {
    if (rest.isAuthenticated) {
      console.log('PUSH TO HOME')
      history.push('/')
    }
  }, [rest.isAuthenticated])


  useEffect(() => {
    console.log('REST PROPS ARE: ', rest)
  }, [rest])


  return (
    <div>
      <Loader type='TailSpin' color='#297AF7' width={32} height={32} />
    </div>
  )
}

export default connect(state => ({ ...state.auth.user }))(SocialLoginCallback)