import React from 'react'
import '../style.scss'
const JobSetupStep09 = ({
    strokeWidth='2',
    width='24',
    height='24',
    selected=false
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_112:7975)">
                <path
                    className={`qluSvgStroke${selected ? 'Selected' : ''}`}  
                    d="M14.8172 6.2085C14.8198 6.47024 14.925 6.72052 15.1101 6.90561C15.2951 7.0907 15.5454 7.19584 15.8072 7.19845L18.0699 7.19846C18.3316 7.19584 18.5819 7.0907 18.767 6.90561C18.9521 6.72052 19.0572 6.47024 19.0599 6.2085L19.0599 0.876921C20.2011 1.30709 21.1842 2.07487 21.8781 3.07792C22.572 4.08098 22.9437 5.27168 22.9437 6.49135C22.9437 7.71101 22.572 8.90172 21.8781 9.90477C21.1842 10.9078 20.2011 11.6756 19.0599 12.1058L19.0599 20.878C19.0599 21.4406 18.8364 21.9802 18.4385 22.378C18.0407 22.7758 17.5011 22.9993 16.9385 22.9993C16.3759 22.9993 15.8364 22.7758 15.4385 22.378C15.0407 21.9802 14.8172 21.4406 14.8172 20.878L14.8172 12.1058C13.6759 11.6756 12.6928 10.9078 11.999 9.90477C11.3051 8.90171 10.9334 7.71101 10.9334 6.49135C10.9334 5.27168 11.3051 4.08098 11.999 3.07792C12.6928 2.07486 13.6759 1.30709 14.8172 0.87692L14.8172 6.19436V6.2085Z" 
                    strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    className={`qluSvgStroke${selected ? 'Selected' : ''}`}   
                    d="M7.2279 20.8546C7.2279 21.4172 7.00441 21.9567 6.60658 22.3546C6.4096 22.5515 6.17575 22.7078 5.91838 22.8144C5.66101 22.921 5.38516 22.9759 5.10658 22.9759C4.82801 22.9759 4.55216 22.921 4.29479 22.8144C4.03742 22.7078 3.80357 22.5515 3.60658 22.3546C3.4096 22.1576 3.25335 21.9237 3.14674 21.6663C3.04013 21.409 2.98526 21.1331 2.98526 20.8546L2.98528 4.53646L5.10659 1.00093L7.22792 4.53646L7.2279 20.8546Z" 
                    strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_112:7975">
                    <rect
                        className={`qluSvgFill${selected ? 'Selected' : ''}`}  
                        width={width} height={height}/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default JobSetupStep09
