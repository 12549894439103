import React from 'react'
import {
    Input
} from 'reactstrap'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import './scoringFiltersSliderModule.scss'

import InputRange from "react-input-range"
import "react-input-range/lib/css/index.css"

import {
    setEducationScoringFilter, setExperienceScoringFilter, setIndustryScoringFilter, setSkillScoringFilter, setSpecialtyScoringFilter, setTemplate, setTitleScoringFilter, toggleEducationScoringFilter, toggleExperienceScoringFilter, toggleIndustryScoringFilter, toggleSkillScoringFilter, toggleSpecialtyScoringFilter, toggleTitleScoringFilter
} from '../../../actions/prospectsView'

const ScoringFiltersSliderModule = ({
    dispatch,
    scoreValue,
    scoreActive,
    heading,
    scoreFlag = false,
    FilterCount,
    setFilterCount
}) => {

    const handleChangeRange = (value) => {
        if (scoreActive === false && (scoreValue !== 70)) {
            setFilterCount((prev) => prev + 1);

            switch (heading) {
                case "Current Title":
                    dispatch(toggleTitleScoringFilter())
                    break;
                case "Skills":
                    dispatch(toggleSkillScoringFilter())
                    break;
                case "Experience":
                    dispatch(toggleExperienceScoringFilter())
                    break;
                case "Education":
                    dispatch(toggleEducationScoringFilter())
                    break;
                case "Industry":
                    dispatch(toggleIndustryScoringFilter())
                    break;
                case "Business":
                    dispatch(toggleSpecialtyScoringFilter())
                    break;
                default:
                    console.log("INVALID SCORING FILTER TYPE")
                    break;
            }
        }
        switch (heading) {
            case "Current Title":
                dispatch(setTitleScoringFilter(value.max))
                break;
            case "Skills":
                dispatch(setSkillScoringFilter(value.max))
                break;
            case "Experience":
                dispatch(setExperienceScoringFilter(value.max))
                break;
            case "Education":
                dispatch(setEducationScoringFilter(value.max))
                break;
            case "Industry":
                dispatch(setIndustryScoringFilter(value.max))
                break;
            case "Business":
                dispatch(setSpecialtyScoringFilter(value.max))
                break;
            default:
                console.log("INVALID SCORING FILTER TYPE")
                break;
        }
    }
    const handleToggleScoreFilter = (e) => {

        setFilterCount(scoreActive ?
            FilterCount - 1 :
            FilterCount + 1)
        dispatch(setTemplate(''))

        switch (heading) {
            case "Current Title":
                dispatch(toggleTitleScoringFilter())
                break;
            case "Skills":
                dispatch(toggleSkillScoringFilter())
                break;
            case "Experience":
                dispatch(toggleExperienceScoringFilter())
                break;
            case "Education":
                dispatch(toggleEducationScoringFilter())
                break;
            case "Industry":
                dispatch(toggleIndustryScoringFilter())
                break;
            case "Business":
                dispatch(toggleSpecialtyScoringFilter())
                break;
            default:
                console.log("INVALID SCORING FILTER TYPE")
                break;
        }
    }
    return (
        <div className="ScoringFiltersSliderContainer" >
            <div className="advFilterCheckboxContainer">
                <Input
                    disabled={!scoreFlag}
                    className='checkBox'
                    type='checkbox'
                    onChange={handleToggleScoreFilter}
                    checked={scoreActive}
                />
                <h3 className="filterSubHeading">{heading}</h3>
                <h3 className="filterSliderValue">{scoreValue}%</h3>
            </div>
            <div className="sliderFilterContainer">
                <InputRange
                    disabled={!scoreFlag}
                    // className="sliderFilter"
                    minValue={0}
                    maxValue={100}
                    type="range"
                    name="range"
                    value={{ max: scoreValue }}
                    onChange={handleChangeRange}
                />
            </div>
            {/* <div className="sliderFilterLableContainer">
                <Label className="sliderFilterLable">
                    0%
                </Label>
                <Label className="sliderFilterLable">
                    100%
                </Label>
            </div> */}
        </div>
    )
}

export default withRouter(connect(state => {
    return {
        ScoringFilters: state.prospectsView.scoringFiltersMeta
    }
})(ScoringFiltersSliderModule))