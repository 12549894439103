import { get, isEmpty, flattenDeep } from 'lodash'
import { callLiApi, callLiWrapperApi } from './index'


const getLocationData = (data) => {

  console.log("DATA IS: ", data)

  const {
    geoLocationName = null,
    geoCountryName,
    geoLocation,
    geoCountryUrn
  } = data

  const locationName = geoLocationName ? `${geoLocationName}, ${geoCountryName}` : geoCountryName

  let city = null, state = null, region = null
  const urn = get(geoLocation, 'geoUrn', '')
  const countryUrn = geoCountryUrn

  if (geoLocationName) {
    const parts = geoLocationName.split(',').map(item => item.trim())
    if (parts.length === 1) {
      region = parts[0]
    } else {
      city = parts[0]
      state = parts[1]
    }
  }

  // console.log({ locationName, city, state, country: geoCountryName, region })
  return {
    locationName,
    city,
    state,
    region,
    country: geoCountryName,
    geoUrn: urn,
    geoCountryUrn: countryUrn
  }
}

//transform data for multiple positions at same company
const transformMultiplePositionsVariant = (item) => {

  const monthObj = { 'null': null, 'jan': 1, 'feb': 2, 'mar': 3, 'apr': 4, 'may': 5, 'jun': 6, 'jul': 7, 'aug': 8, 'sep': 9, 'oct': 10, 'nov': 11, 'dec': 12 }


  const entityComponent = get(item, ['components', 'entityComponent'], {})
  const company_name = get(entityComponent, ['title', 'accessibilityText'], '')
  const urn = get(entityComponent, ['image', 'attributes', '0', 'detailData', 'companyLogo', 'entityUrn'], '')
  const vectorImage = get(entityComponent, ['image', 'attributes', '0', 'detailData', 'companyLogo', 'logoResolutionResult', 'vectorImage'], {})
  const logo = `${get(vectorImage, ['rootUrl'], '')}${get(vectorImage, ['artifacts'], []).length > 0 ? vectorImage.artifacts[vectorImage.artifacts.length - 1].fileIdentifyingUrlPathSegment : ''}`


  const pagedList = get(entityComponent, ['subComponents', 'components', '0', 'components', 'pagedListComponent'], {})
  const pageItems = get(pagedList, ['components', 'elements'], [])

  // console.log('PAGED LIST COMPONENT: ', pagedList)
  // console.log('PAGE ITEMS ARE: ', pageItems)




  // console.log('MULTIPLE EXPERIENCE ITEM: ', item)
  // return {}
  const r = {
    //   title: get(entityComponent, ['title', 'accessibilityText'], get(entityComponent, ['title', 'text'], '')),
    //   description: description,
    company_name: company_name,
    //   location: location,
    //   start: `${monthObj[startMonthName.toLowerCase().trim()]}-${startYear}`,
    //   end: endMonthName.toLowerCase().trim() === 'present' ? '' : `${monthObj[endMonthName.toLowerCase().trim()]}-${endYear}`,
    //   duration: duration,
    urn: urn,
    logo: logo,
    location: null
  }




  const transformed = pageItems.map(item => {

    const dateRange = get(item, ['components', 'entityComponent', 'caption', 'accessibilityText'], '')
    const [range, duration] = dateRange.split('·').map(item => item.trim())
    // const [start, end] = range.split('-').map(item => item.trim())
    // const [startMonthName, startYear] = start.split(" ")
    // const [endMonthName, endYear] = end.split(" ")

    let [start, end] = range.split('-').map(item => item.trim())
    end = end ? end : duration.toLowerCase().trim() === 'less than a year' ? start : ''
    // console.log("DEBUG TEST [1]")
    const [startMonthName, startYear] = start && start.includes(" ") ? start.split(" ") : `null ${start}`.split(" ")
    const [endMonthName, endYear] = end && end.includes(" ") ? end.split(" ") : `null ${end}`.split(" ")
    // console.log("DEBUG TEST [2]")
    const location = get(item, ['components', 'entityComponent', 'metadata', 'accessibilityText'], '')
    if (!r.location && location) {
      r.location = location
    }


    const subComponents = get(item, ['components', 'entityComponent', 'subComponents', 'components'], [])
    // console.log('2')
    const descriptionComp = subComponents.filter(it => !isEmpty(get(it, ['components', 'fixedListComponent'], {})))
    // console.log('3')
    const description = getDescription(descriptionComp)

    const strt = `${monthObj[startMonthName.toLowerCase().trim()] ? `${monthObj[startMonthName.toLowerCase().trim()]}-${startYear}` : `${startYear}`}`
    const ed = endYear.toLowerCase().trim() === 'present' ? '' : `${monthObj[endMonthName.toLowerCase().trim()] ? `${monthObj[endMonthName.toLowerCase().trim()]}-${endYear}` : `${endYear}`}`


    return {
      title: get(item, ['components', 'entityComponent', 'title', 'text'], ''),
      start: strt,
      end: ed.trim().toLowerCase() === 'present' ? '' : ed,
      duration: duration,
      description: description,
    }
  })

  return transformed.map(i => ({ ...i, ...r }))
}


// transform data for single position at a same company
const transformSinglePositionVariant = (item) => {
  try {
    const monthObj = { 'null': null, 'jan': 1, 'feb': 2, 'mar': 3, 'apr': 4, 'may': 5, 'jun': 6, 'jul': 7, 'aug': 8, 'sep': 9, 'oct': 10, 'nov': 11, 'dec': 12 }
    // console.log('EXPERIENCE ITEM: ', item)


    const entityComponent = get(item, ['components', 'entityComponent'], {})
    // console.log('1')
    const subComponents = get(entityComponent, ['subComponents', 'components'], [])
    // console.log('2')
    const descriptionComp = subComponents.filter(it => !isEmpty(get(it, ['components', 'fixedListComponent'], {})))
    // console.log('3')
    const description = getDescription(descriptionComp)
    // console.log('4')

    const subTitle = get(entityComponent, ['subtitle', 'accessibilityText'], '')
    const company_name = subTitle && subTitle.includes('·') ? subTitle.split('·')[0].trim() : subTitle
    const location = get(entityComponent, ['metadata', 'accessibilityText'], '')
    const dateRange = get(entityComponent, ['caption', 'accessibilityText'], '')
    const [range, duration] = dateRange.split('·').map(item => item.trim())




    let [start, end] = range.split('-').map(item => item.trim())
    end = end ? end : duration.toLowerCase().trim() === 'less than a year' ? start : ''
    const [startMonthName, startYear] = start && start.includes(" ") ? start.split(" ") : `null ${start}`.split(" ")
    const [endMonthName, endYear] = end && end.includes(" ") ? end.split(" ") : `null ${end}`.split(" ")

    // if (company_name === 'Citibeats') {
    //   console.log("===================================")
    //   console.log("START AND END ARE: ", { start, end: end ? end : duration.toLowerCase().trim() === 'less than a year' ? start : '', duration: duration })
    //   console.log("RANGE IS: ", range)
    //   console.log("===================================")
    // }

    const urn = get(entityComponent, ['image', 'attributes', '0', 'detailData', 'companyLogo', 'entityUrn'], '')

    const vectorImage = get(entityComponent, ['image', 'attributes', '0', 'detailData', 'companyLogo', 'logoResolutionResult', 'vectorImage'], {})

    const logo = `${get(vectorImage, ['rootUrl'], '')}${get(vectorImage, ['artifacts'], []).length > 0 ? vectorImage.artifacts[vectorImage.artifacts.length - 1].fileIdentifyingUrlPathSegment : ''}`


    const strt = `${monthObj[startMonthName.toLowerCase().trim()] ? `${monthObj[startMonthName.toLowerCase().trim()]}-${startYear}` : `${startYear}`}`
    const ed = endYear.toLowerCase().trim() === 'present' ? '' : `${monthObj[endMonthName.toLowerCase().trim()] ? `${monthObj[endMonthName.toLowerCase().trim()]}-${endYear}` : `${endYear}`}`




    const r = {
      title: get(entityComponent, ['title', 'accessibilityText'], get(entityComponent, ['title', 'text'], '')),
      description: description,
      company_name: company_name,
      location: location,
      start: strt,
      end: ed.trim().toLowerCase() === 'present' ? '' : ed,
      duration: duration,
      urn: urn,
      logo: logo
    }

    // console.log('RETURNING R: ', r)
    return r
  } catch (e) {
    console.log('AN ERROR OCCURED WHILE TRANSFORMING SINGLE POSITION EXP ITEM: ', e.message)
    // console.log(item)
    throw e
  }
}




const transformExperienceItem = (item) => {

  // console.log('EXPERIENCE ITEM: ', item)
  //check if multiple positions at same company
  const subComps = get(item, ['components', 'entityComponent', 'subComponents'], [])
  // console.log('11')
  const isMultiple = !isEmpty(get(subComps, ['components', '0', 'components', 'pagedListComponent'], {}))
  // console.log('22')

  // console.log('IS MULTIPLE: ', isMultiple)

  return isMultiple ? transformMultiplePositionsVariant(item) : transformSinglePositionVariant(item)

  // console.log('IS MULTIPLE: ', isMultiple)

}


const transformSkills = (skillSection) => {

  const skillElements = get(skillSection, ['data', 'identityDashProfileComponentsByPagedListComponent', 'elements'], [])

  const skills = skillElements.map(item => get(item, ['components', 'entityComponent', 'title', 'text'], ''))

  return skills

}

const transformEducation = (educationSection) => {
  // console.log("EDUCATION SECTION IS: ", educationSection)


  const educationItems = get(educationSection, ['data', 'identityDashProfileComponentsBySectionType', 'elements', '0', 'components', 'pagedListComponent', 'components', 'elements'], [])

  // console.log('EUDCATION ITEMS ARE: ', educationItems)


  return educationItems.map(item => {

    const [start, end] = get(item, ['components', 'entityComponent', 'caption', 'text'], '').split('-').map(item => item.trim())
    const [degree, field] = get(item, ['components', 'entityComponent', 'subtitle', 'text'], '').split(',').map(item => item.trim())

    const r = {
      start,
      end,
      degreeName: degree,
      fieldOfStudy: field,
      schoolName: get(item, ['components', 'entityComponent', 'title', 'text'], null)
    }

    return r
  })
}



const getDescription = (descriptionComp) => {
  let description = ''
  if (!isEmpty(descriptionComp)) {
    const fixedListComp = get(descriptionComp[0], ['components', 'fixedListComponent', 'components'], [])
    description = get(fixedListComp[0], ['components', 'textComponent', 'text', 'accessibilityText'], '')
  }
  return description
}

const getProfilePictureUrlNew = (profilePicObj) => {

  const rootUrl = get(profilePicObj, ['displayImageReference', 'vectorImage', 'rootUrl'], null)
  const artifacts = get(profilePicObj, ['displayImageReference', 'vectorImage', 'artifacts'], null)

  return isEmpty(artifacts) ? null : `${rootUrl}${artifacts[artifacts.length - 1].fileIdentifyingUrlPathSegment}`


}


const getLocationSpecificDataNew = (profile) => {
  const locationSpecificData = {}
  const countryName = get(profile, ['geoLocation', 'geo', 'country', 'defaultLocalizedName'], null)
  locationSpecificData.locationName = countryName
  locationSpecificData.geoCountryName = countryName
  locationSpecificData.geoLocationName = get(profile, ['geoLocation', 'geo', 'defaultLocalizedName'], null)
  locationSpecificData.geoCountryUrn = get(profile, ['geoLocation', 'geo', 'countryUrn'], null)
  locationSpecificData.location = {
    basicLocation: {
      countryCode: get(profile, ['location', 'countryCode'], null),
      postalCode: get(profile, ['location', 'postalCode'], null)
    }
  }
  locationSpecificData.geoLocation = {
    geoUrn: get(profile, ['geoLocation', 'geoUrn'], null),
    postalCode: get(profile, ['geoLocation', 'postalCode'], null),
  }
  locationSpecificData.supportedLocales = get(profile, ['supportedLocales'], null)

  return locationSpecificData

}

const getSummary = (resp) => {
  const elements = get(resp, ['data', 'identityDashProfileCardsByInitialCards', 'elements'], [])
  const aboutElement = elements.filter(item => {
    const type = get(item, ['topComponents', '0', 'components', 'headerComponent', 'title', 'text'], '')
    return type.toLowerCase().trim() === 'about'
  })

  // console.log("ABOUT ELEMENT IS: ", aboutElement)

  const summary = isEmpty(aboutElement) ? '' : get(aboutElement[0], ['topComponents', '1', 'components', 'textComponent', 'text', 'text'], '')

  // console.log("SUMMARY IS: ", summary)

  return summary

}




export const visitApiProfileLiNew = async (publicIdentifier) => {
  try {

    // const bFD = await callLiApi(`/voyager/api/identity/dash/profiles?q=memberIdentity&memberIdentity=${publicIdentifier}&decorationId=com.linkedin.voyager.dash.deco.identity.profile.WebTopCardCore-8`, { headers: { "x-restli-protocol-version": "2.0.0" } })
    //==@liWrapper==//
    const bFD = await callLiWrapperApi('BasicProfileDetails',null , {publicIdentifier})
    
    // const bFD = await callLiApi(`/voyager/api/identity/dash/profiles?q=memberIdentity&memberIdentity=${publicIdentifier}&decorationId=com.linkedin.voyager.dash.deco.identity.profile.FullProfile-70`, { headers: {} })  //await callLiLocal(p2p, { m: 'g', h: {}, e: `/voyager/api/identity/dash/profiles?q=memberIdentity&memberIdentity=${publicIdentifier}&decorationId=com.linkedin.voyager.dash.deco.identity.profile.FullProfile-70` })
    const basicProfileData = get(bFD, ['data'], {})

    // console.log("BASIC PROFILE DATA sdfsdf: ", basicProfileData)
    const data = {
      experience: [],
      publications: [],
      education: [],
      skills: [],
      firstName: null, //done
      lastName: null, //done
      full_name: null, //done
      publicIdentifier: null, //done
      picture: null, //done
      industryCode: null, //done
      scrapeType: null, //done
      trackingId: null,
      locationName: null, //done
      postalCode: null, //done
      versionTag: null,
      schoolName: null,
      fieldOfStudy: null,
      title: null,
      companyName: null,
      languages: null,
      entityUrn: null,
      headline: null, //done
      summary: null, //done
      industry: null, //done
      companySize: null,
      image_url: null, //done
      locality: null, //done
      country_code: null, //done
      primary_email: null,
      phone_number: null,
      current_company_website: null,
      company_linkedin_url: null,
      current_company_specialties: null,
      current_company_size: null,
      current_company_name: null,
      current_company_industry: null,
      locationSpecificData: {
        supportedLocales: null, //done
        locationName: null, //done
        geoCountryName: null, //done
        geoLocation: {}, //done
        location: {}, //done
        geoLocationName: null, //done
        geoCountryUrn: null //done
      }
    }

    // let timeout

    // const timeoutPromise = (ms) => new Promise((resolve, reject) => {
    //   timeout = setTimeout(() => {
    //     reject(new Error('timedout'))
    //   }, ms)
    // })






    if (!isEmpty(basicProfileData.elements)) {
      // console.log('BASIC PROFILE: ', basicProfileData)

      const profile = basicProfileData.elements[0]

      // console.log("PROFILE IS [APILINEW]: ", profile)

      data.publicIdentifier = get(profile, 'publicIdentifier', null)
      data.firstName = get(profile, 'firstName', '')
      data.lastName = get(profile, 'lastName', '')
      data.full_name = `${data.firstName} ${data.lastName}`.trim()
      data.summary = get(profile, ['summary'], '')
      data.scrapeType = 'DEEP'
      const picUrl = getProfilePictureUrlNew(get(profile, ['profilePicture'], null))
      data.picture = picUrl
      data.image_url = picUrl
      data.headline = get(profile, ['headline'], null)
      data.industry = get(profile, ['industry', 'name'], null)
      data.industryCode = get(profile, ['industry', 'entityUrn'], '').split(':').pop()
      const locSpecificData = getLocationSpecificDataNew(profile)
      data.locationSpecificData = locSpecificData
      data.locationName = get(locSpecificData, ['locationName'], null)
      data.locality = get(locSpecificData, ['geoLocationName'], null)
      data.country_code = get(profile, ['location', 'countryCode'], null)
      data.postalCode = get(locSpecificData, ['geoLocation', 'postalCode'], null)
      data.entityUrn = get(profile, ['entityUrn'], null)


      // console.log("PROMISE.ALL STUFF [APILINEW]: ", profile)


      const raceResp = await Promise.all([
        //callLiApi(`/voyager/api/graphql?includeWebMetadata=true&variables=(profileUrn:${encodeURIComponent(data.entityUrn)})&&queryId=voyagerIdentityDashProfileCards.bd945c6acd2e6a95b880a5466ddf3bb4`, { headers: { 'x-restli-protocol-version': '2.0.0' } }),
        //==@liWrapper==//
        callLiWrapperApi('ProfileAboutMetadata',null,{entityUrn:data.entityUrn}),
        // callLiApi(`/voyager/api/graphql?includeWebMetadata=true&variables=(profileUrn:${encodeURIComponent(data.entityUrn)},sectionType:experience)&&queryId=voyagerIdentityDashProfileComponents.4d3ec80f0f977cddbf91821bcc641fdd`, { headers: { 'x-restli-protocol-version': '2.0.0' } }),
        //==@liWrapper==//
        callLiWrapperApi('ProfileExperienceMetadata', null, {entityUrn:data.entityUrn}),
        // callLiLocal(p2p, { m: 'g', h: { 'x-restli-protocol-version': '2.0.0' }, e: `/voyager/api/graphql?includeWebMetadata=true&variables=(profileUrn:${encodeURIComponent(data.entityUrn)},sectionType:experience)&&queryId=voyagerIdentityDashProfileComponents.4d3ec80f0f977cddbf91821bcc641fdd` }),
        // callLiApi(`/voyager/api/graphql?includeWebMetadata=true&variables=(start:0,count:100,paginationToken:null,pagedListComponent:urn%3Ali%3Afsd_profilePagedListComponent%3A%28${data.entityUrn.split(':').pop().trim()}%2CSKILLS_VIEW_DETAILS%2Curn%3Ali%3Afsd_profileTabSection%3AALL_SKILLS%2CNONE%2Cen_US%29)&&queryId=voyagerIdentityDashProfileComponents.1a9ef8c4281663ad8ac7561bf80010cc`, { headers: { 'x-restli-protocol-version': '2.0.0' } }),
        //==@liWrapper==//
        callLiWrapperApi('ProfileSkillMetadata', null, {entityUrn:data.entityUrn}),
        // callLiLocal(p2p, { m: 'g', h: { 'x-restli-protocol-version': '2.0.0' }, e: `/voyager/api/graphql?includeWebMetadata=true&variables=(start:0,count:100,paginationToken:null,pagedListComponent:urn%3Ali%3Afsd_profilePagedListComponent%3A%28${data.entityUrn.split(':').pop().trim()}%2CSKILLS_VIEW_DETAILS%2Curn%3Ali%3Afsd_profileTabSection%3AALL_SKILLS%2CNONE%2Cen_US%29)&&queryId=voyagerIdentityDashProfileComponents.1a9ef8c4281663ad8ac7561bf80010cc` }),
        // callLiApi(`/voyager/api/graphql?includeWebMetadata=true&variables=(profileUrn:urn%3Ali%3Afsd_profile%3A${data.entityUrn.split(':').pop()},sectionType:education,locale:en_US)&&queryId=voyagerIdentityDashProfileComponents.4d3ec80f0f977cddbf91821bcc641fdd`, { headers: { 'x-restli-protocol-version': '2.0.0' } }),
        //==@liWrapper==//
        callLiWrapperApi('ProfileEducationMetadata', null, {entityUrn:data.entityUrn}),
        // callLiLocal(p2p, { m: 'g', h: { 'x-restli-protocol-version': '2.0.0' }, e: `/voyager/api/graphql?includeWebMetadata=true&variables=(profileUrn:urn%3Ali%3Afsd_profile%3A${data.entityUrn.split(':').pop()},sectionType:education,locale:en_US)&&queryId=voyagerIdentityDashProfileComponents.4d3ec80f0f977cddbf91821bcc641fdd` }),
        // callLiApi(`/voyager/api/identity/profiles/${data.publicIdentifier}/profileContactInfo`, {})
        //==@liWrapper==//
        callLiWrapperApi('ProfileContactMetadata', null , {publicIdentifier:data.publicIdentifier})
        // callLiLocal(p2p, { m: 'g', h: {}, e: `/voyager/api/identity/profiles/${data.publicIdentifier}/profileContactInfo` })
      ])


      // console.log("PROMISE.ALL RACE RESP [APILINEW]: ", raceResp)

      // clearTimeout(timeout)
      // console.log("RACE RESPPPP: ", raceResp)

      // if (!raceResp) {
      //   // console.log('RACE RESP: ', raceResp)
      //   throw new Error('didnt respond in specified time')
      // }

      const [aboutSection, experienceSection, skillsSection, educationSection, contactInfo] = raceResp.map(item => item.data)
      data.summary = getSummary(aboutSection)
      // const [experienceSection, skillsSection, educationSection, contactInfo] = await Promise.all([
      //   callLiLocal(p2p, { m: 'g', h: { 'x-restli-protocol-version': '2.0.0' }, e: `/voyager/api/graphql?includeWebMetadata=true&variables=(profileUrn:${encodeURIComponent(data.entityUrn)},sectionType:experience)&&queryId=voyagerIdentityDashProfileComponents.4d3ec80f0f977cddbf91821bcc641fdd` }),
      //   callLiLocal(p2p, { m: 'g', h: { 'x-restli-protocol-version': '2.0.0' }, e: `/voyager/api/graphql?includeWebMetadata=true&variables=(start:0,count:100,paginationToken:null,pagedListComponent:urn%3Ali%3Afsd_profilePagedListComponent%3A%28${data.entityUrn.split(':').pop().trim()}%2CSKILLS_VIEW_DETAILS%2Curn%3Ali%3Afsd_profileTabSection%3AALL_SKILLS%2CNONE%2Cen_US%29)&&queryId=voyagerIdentityDashProfileComponents.1a9ef8c4281663ad8ac7561bf80010cc` }),
      //   callLiLocal(p2p, { m: 'g', h: { 'x-restli-protocol-version': '2.0.0' }, e: `/voyager/api/graphql?includeWebMetadata=true&variables=(profileUrn:urn%3Ali%3Afsd_profile%3A${data.entityUrn.split(':').pop()},sectionType:education,locale:en_US)&&queryId=voyagerIdentityDashProfileComponents.4d3ec80f0f977cddbf91821bcc641fdd` }),
      //   callLiLocal(p2p, { m: 'g', h: {}, e: `/voyager/api/identity/profiles/${data.publicIdentifier}/profileContactInfo` })
      // ])


      // const experienceSection = await

      // const skillsSection = await

      // const educationSection = await

      // console.log('experience: ', experienceSection)

      const expElements = get(experienceSection, ['data', 'identityDashProfileComponentsBySectionType', 'elements'], [])
      const expItems = get(expElements[0], ['components', 'pagedListComponent', 'components', 'elements'], [])
      const expItemsTransformed = flattenDeep(expItems.map(item => transformExperienceItem(item)))


      // console.log('EXP ITEMS ARE: ', expItems)
      // console.log("EXP ITEMS TRANSFORMED: ", expItemsTransformed)

      const transformedWithCurrentCompanyDetail = await Promise.all(expItemsTransformed.map(async (item, i) => {
        if (i == 0) {
          // const cD = item.urn.trim() ? await callLiApi(`/voyager/api/voyagerOrganizationDashCompanies/${item.urn.split(':').pop()}?decorationId=com.linkedin.voyager.dash.deco.organization.MemberCompany-58`, { headers: { "x-restli-protocol-version": "2.0.0" } }) : {}
          //==@liWrapper==//
          const cD = item.urn.trim() ? await callLiWrapperApi('CompanyData', null, {urn:item.urn}) :  {}

          console.log("CD ====== === = == ", cD)

          const companyData = get(cD, ['data'], {})

          console.log("COMPANY DATA: ", companyData)
          // callLiLocal(p2p, { m: 'g', h: { "x-restli-protocol-version": "2.0.0" }, e: `/voyager/api/voyagerOrganizationDashCompanies/${item.urn.split(':').pop()}?decorationId=com.linkedin.voyager.dash.deco.organization.MemberCompany-58` })

          return {
            ...item,
            industries: get(companyData, ['industry'], null) ? [companyData.industry[Object.keys(companyData.industry)[0]].name] : [],
            companyWebsite: get(companyData, ['websiteUrl'], ''),
            companyPageUrl: get(companyData, ['url'], ''),
            companyType: '',
            companySize: get(companyData, ['employeeCount'], null),
            companySpecialities: get(companyData, ['specialities'], []),
            companyDescription: get(companyData, ['description'], ''),
            universalName: get(companyData, ['universalName'], '')

          }
        } else {
          return {
            ...item,
            industries: [],
            companyWebsite: '',
            companyPageUrl: '',
            companyType: '',
            companySize: null,
            companySpecialities: [],
            companyDescription: '',
            universalName: ''
          }
        }
      }))


      data.experience = transformedWithCurrentCompanyDetail
      data.skills = transformSkills(skillsSection)
      data.education = transformEducation(educationSection)

      const latestExperience = get(data, ['experience', '0'], {})
      const latestEducation = get(data, ['education', '0'], {})

      data.current_company_industry = get(latestExperience, ['industries', '0'], null)
      data.current_company_name = get(latestExperience, ['company_name'], null)
      data.current_company_size = get(latestExperience, ['companySize'], null) ? `${latestExperience.companySize} employees` : null
      data.current_company_specialties = get(latestExperience, ['companySpecialities'], []).join(', ')
      data.current_company_website = get(latestExperience, ['companyWebsite'], null)
      data.company_linkedin_url = get(latestExperience, ['companyPageUrl'], null)
      data.companySize = get(latestExperience, ['companySize'], null)
      data.companyName = get(latestExperience, ['company_name'], null)
      data.schoolName = get(latestEducation, ['schoolName'], null)
      data.fieldOfStudy = get(latestEducation, ['fieldOfStudy'], null)
      data.title = get(latestExperience, ['title'], null)


      console.log("email:",contactInfo?.emailAddress?.emailAddress)
     
      data.primary_email = contactInfo?.elements[0]?.emailAddress?.emailAddress|| "";
      data.phone_number = contactInfo?.phoneNumbers?.elements[0].map(x => x.number).toString() || "";
      

    }


    // const location = getLocationData(data.locationSpecificData)

    // console.log("FINAL PROFILE DATA: ", data)


    return data

    // console.log("LOCATION DATA IS: ", location)



    // return {
    //   profile: data,
    //   location: location
    // }



  } catch (e) {
    console.log('an error occured: ', e.message)
    throw e
  }
}