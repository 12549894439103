import React from 'react'
import '../style.scss'
const InmailSVG = ({
    selected=false,
    width="24",
    height="24"
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                className={`qluSvgFill${selected ? 'Selected' : ''}`}
                fill-rule="evenodd" clip-rule="evenodd" 
                d="M4 5C3.45228 5 3 5.45228 3 6V18C3 18.5477 3.45228 19 4 19H20C20.5477 19 21 18.5477 21 18V6C21 5.45228 20.5477 5 20 5H4ZM1 6C1 4.34772 2.34772 3 4 3H20C21.6523 3 23 4.34772 23 6V18C23 19.6523 21.6523 21 20 21H4C2.34772 21 1 19.6523 1 18V6Z" 
            />
            <path 
                className={`qluSvgFill${selected ? 'Selected' : ''}`}
                fill-rule="evenodd" clip-rule="evenodd" 
                d="M1.18085 5.42662C1.49757 4.97417 2.1211 4.86414 2.57355 5.18085L7 8.27937V10.7207L1.42662 6.81932C0.974174 6.5026 0.864139 5.87907 1.18085 5.42662ZM17 10.7208L22.5735 6.81932C23.026 6.5026 23.136 5.87907 22.8193 5.42662C22.5026 4.97417 21.8791 4.86414 21.4266 5.18085L17 8.27949V10.7208Z" 
            />
            <path 
                className={`qluSvgFill${selected ? 'Selected' : ''}`}
                d="M9.47757 16V10.3536H8.07956V16H9.47757ZM8.78425 8C8.31825 8 8 8.33623 8 8.77681C8 9.1942 8.30688 9.54203 8.77288 9.54203C9.26162 9.54203 9.5685 9.1942 9.5685 8.77681C9.55713 8.33623 9.26162 8 8.78425 8Z" 
            />
            <path 
                className={`qluSvgFill${selected ? 'Selected' : ''}`}
                d="M10.9876 16H12.3856V12.6725C12.3856 12.5101 12.4084 12.3362 12.4538 12.2203C12.6016 11.7913 12.988 11.3855 13.545 11.3855C14.3065 11.3855 14.602 12 14.602 12.8116V16H16V12.6493C16 10.8638 14.9998 10.2261 14.0337 10.2261C13.1131 10.2261 12.5107 10.7594 12.2606 11.2H12.2265L12.1583 10.3536H10.9422C10.9649 10.8406 10.9876 11.3855 10.9876 12.0348V16Z" 
            />
        </svg>
        
    )
}

export default InmailSVG
