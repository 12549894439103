import React from "react";
import { connect } from "react-redux";
import {
  changeIdealProfileName,
  clearIdealProfileSuggestions,
  fetchIdealProfileData,
  fetchIdealProfileSuggestions,
  removeSelectedIdealProfile,
} from "../../../../actions/QuickSetup";
import IdealProfileViewCard from "../../../../components/IdealProfileViewCard/IdealProfileViewCard";
import NewAutoSuggestion from "../../../../components/NewAutoSuggestion/NewAutoSuggestion";
import ProfileSummaryCard from "../../../../components/ProfileSummaryCard/ProfileSummaryCard";
import Loading from "../../../../uiLibrary/Loading";
import CrossSvg from "../../../../uiLibrary/SVGs/CrossSvg";
import { extractPublicIdentifier, numberWithCommas } from "../../../../utils";
import "./style.scss";
const ProfileAutoSuggest = ({
  dispatch,
  profileUrl,
  profileName,
  profileSuggestions,
  profiles,
  suggestionsLoading,
  isLoading,
  isNerLoading,
}) => {
  const handleRemove = (value) => {
    dispatch(removeSelectedIdealProfile(value));
  };

  const handleChangeInput = (e) =>
    profiles.length > 4
      ? alert("Max Profiles Selected")
      : dispatch(changeIdealProfileName(e.target.value));

  const handleSuggestionsFetchRequested = (value = "") =>
    dispatch(fetchIdealProfileSuggestions(value.trim().toLowerCase()));

  const handleSuggestionsClearRequested = () =>
    dispatch(clearIdealProfileSuggestions());

  const handleSuggestionSelected = ({ url }) => {
    const pubId = extractPublicIdentifier(url);
    if (pubId && profiles.length < 5) {
      dispatch(fetchIdealProfileData(pubId));
    }
    dispatch(changeIdealProfileName(""));
  };

  const renderSuggestion = (suggestion) =>
    typeof suggestion === "string" ? (
      <div>{suggestion}</div>
    ) : (
      <div className="idealProfileSuggestionContainer">
        <ProfileSummaryCard
          key={suggestion.urn}
          name={suggestion.name}
          headline={suggestion.headline}
          imgSrc={suggestion.imgSrc}
        />
      </div>
    );

  const shouldRenderSuggestions = (suggestion) =>
    (typeof suggestion === "string"
      ? suggestion.trim()
      : suggestion?.name.trim()) &&
    !suggestionsLoading &&
    !isLoading;

  return (
    <div className="ProfileAutoSuggest">
      <div>
        {profiles.map((profile, index) => {
          return (
            <IdealProfileViewCard
              key={index}
              onClick={handleRemove}
              onCloseDisabled={isNerLoading}
              publicIdentifier={profile.publicIdentifier}
              profileImage={profile.image_url?profile.image_url:`https://qlu-media-test.s3.amazonaws.com/${profile.publicIdentifier}.jpg`}
              profileName={profile.full_name?`${profile.full_name}`:`${profile.fullName}`}
              profileHeadline={`${profile.title} @ ${profile.current_company_name ?? profile.currentCompanyName}`}
            />
          );
        })}
      </div>
      <div>
        <NewAutoSuggestion
          inputValue={profileName}
          suggestionsArray={profileSuggestions}
          placeholder="Search by name, Keywords or URL"
          handleChangeInput={handleChangeInput}
          handleSuggestionSelected={handleSuggestionSelected}
          handleSuggestionsFetchRequested={handleSuggestionsFetchRequested}
          handleSuggestionsClearRequested={handleSuggestionsClearRequested}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
        />
        {(suggestionsLoading || isLoading) && <Loading />}
      </div>
    </div>
  );
};

export default connect((state) => ({
  profileUrl: state.jobSetup.jobIdealProfile.profileUrl,
  profileName: state.jobSetup.jobIdealProfile.profileName,
  profileSuggestions: state.jobSetup.jobIdealProfile.profileSuggestions,
  profiles: state.jobSetup.jobIdealProfile.profiles,
  suggestionsLoading: state.jobSetup.jobIdealProfile.suggestionsLoading,
  isLoading: state.jobSetup.jobIdealProfile.isLoading,
  isNerLoading: state.jobSetup.jobSetup.isLoading,
}))(ProfileAutoSuggest);

export const ProfileCompanyCard = ({ name, logo, headline, onClick }) => {
  return (
    <div className="ProfileCompanyCard">
      <ProfileSummaryCard imgSrc={logo} name={name} headline={headline} />
      <button onClick={onClick}>
        <CrossSvg width="16" height="16" />
      </button>
    </div>
  );
};
