import { get } from "lodash";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import {
  _resetIsJobEditSuccess,
  addNewJob,
  getIncentives,
  initCompanyDetails,
  initJobCriteria,
  initJobDetails,
  initJobEdit,
  initJobIdealProfile,
  initJobLocation,
  initJobSetupView,
  initJobSpecialities,
  initJobsetup,
  initTribeMembers,
  saveAdditionalSkills,
  saveAdditionalSpecialties,
  saveAdditionalTitles,
  sendJobDescriptionToNer,
  setAddtionalSpecialitiesInJobSetup,
  setCompaniesAndIdealProfileInJobSetup,
  setEducationInJobSetup,
  setExperienceInJobSetup,
  setFunctionsInJobSetup,
  setJobSetupView,
  setMainTitleInJobSetup,
  setSkillsInJobSetup,
  setSpecialitiesInJobSetup,
  setTitlesInJobSetup
} from "../../actions/JobSetup";
import { PER_PAGE_COUNT } from "../../constants/api";
import ProfileLiked from "../../uiLibrary/ProfileLiked/ProfileLiked";
import JobSetupStep01 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep01";
import JobSetupStep02 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep02";
import JobSetupStep03 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep03";
import JobSetupStep04 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep04";
import JobSetupStep05 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep05";
import JobSetupStep06 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep06";
import JobSetupStep07 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep07";
import JobSetupStep09 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep09";
import JobSetupStep10 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep10";
import ScoreAndConnectHeader from "../../uiLibrary/ScoreAndConnectHeader/ScoreAndConnectHeader";
import SetupLayout from "../../uiLibrary/SetupLayout";
import TabularContainer from "../../uiLibrary/TabularContainer";
import { getDefaultFilters } from "../../utils";
import { ActivityTypes, loggingActivity } from "../../utils/activityLogger";
import HiringCompanyDetails from "./CompanyDetails/HiringCompanyDetails";
import SimilarCompaniesDetails from "./CompanyDetails/SimilarCompaniesDetails";
import CriteriaAdditionalTitles from "./CriteriaVerification/CriteriaAdditionalTitles";
import CriteriaEducation from "./CriteriaVerification/CriteriaEducation";
import CriteriaFunctions from "./CriteriaVerification/CriteriaFunctions";
import CriteriaMainTitle from "./CriteriaVerification/CriteriaMainTitle";
import CriteriaSkills from "./CriteriaVerification/CriteriaSkills";
import CriteriaTotalExperience from "./CriteriaVerification/CriteriaTotalExperience";
import EditJobWrapper from "./EditJobWrapper";
import GeneratedCard from "./GeneratedCard";
import AddIdealProfiles from "./IdealProfiles/AddIdealProfiles";
import JobDescriptionCard from "./JobDescriptionCard";
import JobDetailsDescription from "./JobDetails/JobDetailsDescription";
import JobDetailsLocation from "./JobDetails/JobDetailsLocation";
import JobDetailsTitle from "./JobDetails/JobDetailsTitle";
import ProfileCard from "./ProfileCard";
import SpecialityDetails from "./SpecialityDetails/SpecialityDetails";
import Support from "./Support";
import { GenerateJD } from "./Support/FAQs/JobDescription/GenerateJD/GenerateJD";
import { AdditionalJobTitleFAQS } from "./Support/FAQs/Questions/AdditionalJobTitle";
import { BestFitFAQS } from "./Support/FAQs/Questions/BestFit";
import { CompanyDetailsFAQS } from "./Support/FAQs/Questions/CompanyDetails";
import { EducationFAQS } from "./Support/FAQs/Questions/Education";
import { ExperienceFAQS } from "./Support/FAQs/Questions/Experience";
import { IndustryFAQS } from "./Support/FAQs/Questions/Industry";
import { JobDescriptionFAQS } from "./Support/FAQs/Questions/JD";
import { JobTitleFAQS } from "./Support/FAQs/Questions/JobTitle";
import { LocationFAQS } from "./Support/FAQs/Questions/Location";
import { SkillsFAQS } from "./Support/FAQs/Questions/Skills";
import { TribeFAQS } from "./Support/FAQs/Questions/Tribe";
import TribeMembers from "./TribeMembers/TribeMembers";

const JobSetup = ({
  viewController,
  descriptionValue,
  selectedLocations,
  profiles = [],
  isJobSetupLoading,
  similarCompanies,
  companyHiringFor,
  totalExperience,
  titles,
  mainTitle,
  titleInitialising,
  isCompanyProfileLoading,
  similarCompaniesFetching,
  similarCompaniesFetched,
  // isLoadingSimilarCompanyDetails,
  newJobAdding,
  fetchIdealProfile,
  dispatch,
  jobArray,
  location: { pathname, search },
  history,
  jobId,
  jobTitle,
  isReview,
  isEdited,
  isSpecialtyEdited,
  isLocationEdited,
  isJobEditSuccess,
  isQuick,
  jobPosition,
  similarCompaniesLoading,
  generateJD,
  jobSetup,
}) => {
  const view = viewController.view;
  const currentTab = viewController.currentTab;
  const tabs = viewController.tabs;
  const unLoadableTabs = ["Job Description"];
  if (isQuick === "false") {
    isQuick = false;
  }


  const [FAQs, setFAQs] = React.useState([]);
  const [editApplying, setEditApplying] = React.useState(false);

  const isLoadingSimilarCompanyDetails = similarCompanies.some(
    ({ detailsFetching }) => detailsFetching
  );

  const isLoading =
    (isJobSetupLoading && !unLoadableTabs.includes(tabs[currentTab])) ||
    isCompanyProfileLoading ||
    isLoadingSimilarCompanyDetails ||
    titleInitialising ||
    similarCompaniesFetching ||
    newJobAdding ||
    editApplying;


  // useEffect(() => {
  //   if (qParams?.edit && isReview === "0") {
  //     // every time it changes it means the job setup is changed if isEdit and isReview == 1
  //     if (initialRender.current) {
  //       initialRender.current = false
  //     }
  //     else {
  //       // sets to true if the jobSetup state is changed
  //       setJsetupFlag(true)
  //     }
  //   }
  // }, [jobSetup]);

  const steps = [
    "Job Details",
    "Add Best Fit Profiles",
    "Company Details",
    "Business, Specialties or Industry",
    "Main Title",
    "Additional Titles",
    "Total Relevant Experience",
    // "Functions",
    "Skills",
    "Education",
    "Tribe Members",
  ];

  const index = steps.indexOf(view);
  const qParams = qs.parse(search.split("?").pop());
  const { rank = 8 } = jobArray?.job_position?.[0] || { rank: 7 };
  useEffect(() => {
    // console.log("=======================qParams========================", qParams)
    // dispatch(
    //   setJobSetupView({
    //     view: "Skills",
    //   })
    // );
    if (qParams?.edit) {
      dispatch(
        setJobSetupView({
          currentTab: isReview == "1" ? currentTab : currentTab + 1,
        })
      );
    }
    if (qParams?.edit === "true") {
      if (jobId) {
        dispatch(initJobEdit());
      } else {
        history.push("/jobSetup/");
      }
    }
    return () => {
      dispatch(initCompanyDetails());
      dispatch(initJobCriteria());
      dispatch(initJobDetails());
      dispatch(initJobIdealProfile());
      dispatch(initJobLocation());
      dispatch(initJobSpecialities());
      dispatch(initTribeMembers());
      dispatch(initJobsetup());
      dispatch(initJobSetupView());
      dispatch(_resetIsJobEditSuccess());
    };
  }, []);

  useEffect(() => {
    if (qParams?.edit && isReview == "1") {
      dispatch(
        setJobSetupView({
          currentTab: currentTab - 1,
        })
      );
    }
  }, [isReview]);

  useEffect(() => {
    if (isJobEditSuccess && qParams?.edit === "true") {
      history.push(`/job/addingProspects/${qParams.jId}?t=5`);
    }
  }, [isJobEditSuccess]);

  const stepper = [
    {
      step: <JobSetupStep01 selected={view === "Job Details"} />,
      toolTip: "Job Details",
      disabled: true,
      onClick: () => {
        // if (
        //   steps.indexOf("Job Details") < index ||
        //   (qParams?.edit && !onNextDisabled)
        // ) {
        //   dispatch(
        //     setJobSetupView({
        //       view: "Job Details",
        //       currentTab: qParams?.edit ? (isReview == "1" ? 0 : 1) : 0,
        //     })
        //   );
        // }
      },
    },
    {
      step: <JobSetupStep02 selected={view === "Add Best Fit Profiles"} />,
      toolTip: "Add Best Fit Profiles",
      disabled: true,
      onClick: () => {
        // if (
        //   steps.indexOf("Add Best Fit Profiles") < index ||
        //   (qParams?.edit && !onNextDisabled)
        // ) {
        //   dispatch(
        //     setJobSetupView({
        //       view: "Add Best Fit Profiles",
        //     })
        //   );
        // }
      },
    },
    {
      step: <JobSetupStep03 selected={view === "Company Details"} />,
      toolTip: "Company Details",
      disabled: true,
      devider: true,
      onClick: () => {
        // if (
        //   steps.indexOf("Company Details") < index ||
        //   (qParams?.edit && !onNextDisabled)
        // ) {
        //   dispatch(
        //     setJobSetupView({
        //       view: "Company Details",
        //     })
        //   );
        // }
      },
    },
    {
      step: (
        <JobSetupStep04
          selected={view === "Business, Specialties or Industry"}
        />
      ),
      toolTip: "Business, Specialties or Industry",
      disabled: true,
      onClick: () => {
        // if (
        //   steps.indexOf("Business, Specialties or Industry") < index ||
        //   (qParams?.edit && !onNextDisabled)
        // ) {
        //   dispatch(
        //     setJobSetupView({
        //       highlightValue: "",
        //       selectedValues: [],
        //     })
        //   );
        //   dispatch(
        //     setJobSetupView({
        //       view: "Business, Specialties or Industry",
        //     })
        //   );
        // }
      },
    },
    {
      step: <JobSetupStep05 selected={view === "Main Title"} />,
      toolTip: "Main Title",
      disabled: true,
      onClick: () => {
        // if (
        //   steps.indexOf("Main Title") < index ||
        //   (qParams?.edit && !onNextDisabled)
        // ) {
        //   dispatch(
        //     setJobSetupView({
        //       highlightValue: "",
        //       selectedValues: [],
        //     })
        //   );
        //   dispatch(
        //     setJobSetupView({
        //       view: "Main Title",
        //     })
        //   );
        // }
      },
    },
    {
      step: <JobSetupStep06 selected={view === "Additional Titles"} />,
      toolTip: "Additional Titles",
      disabled: true,
      onClick: () => {
        // if (
        //   steps.indexOf("Additional Titles") < index ||
        //   (qParams?.edit && !onNextDisabled)
        // ) {
        //   dispatch(
        //     setJobSetupView({
        //       view: "Additional Titles",
        //     })
        //   );
        // }
      },
    },
    {
      step: <JobSetupStep07 selected={view === "Total Relevant Experience"} />,
      toolTip: "Total Relevant Experience",
      disabled: true,
      onClick: () => {
        // if (
        //   steps.indexOf("Total Relevant Experience") < index ||
        //   (qParams?.edit && !onNextDisabled)
        // ) {
        //   dispatch(
        //     setJobSetupView({
        //       view: "Total Relevant Experience",
        //     })
        //   );
        // }
      },
    },
    // {
    //   step: <JobSetupStep08 selected={view === "Functions"} />,
    //   toolTip: "Functions",
    //   onClick: () => {
    //     // if (
    //     //   steps.indexOf("Functions") < index ||
    //     //   (qParams?.edit && !onNextDisabled)
    //     // ) {
    //     //   dispatch(
    //     //     setJobSetupView({
    //     //       view: "Functions",
    //     //     })
    //     //   );
    //     // }
    //   },
    // },
    {
      step: <JobSetupStep09 selected={view === "Skills"} />,
      toolTip: "Skills",
      disabled: true,
      onClick: () => {
        // if (
        //   steps.indexOf("Skills") < index ||
        //   (qParams?.edit && !onNextDisabled)
        // ) {
        //   dispatch(
        //     setJobSetupView({
        //       highlightValue: "",
        //       profileHighlightValue: [],
        //       selectedValues: [],
        //       profileSelectedValues: [],
        //     })
        //   );
        //   dispatch(
        //     setJobSetupView({
        //       view: "Skills",
        //     })
        //   );
        // }
      },
    },
    {
      step: <JobSetupStep10 selected={view === "Education"} />,
      toolTip: "Education",
      disabled: true,
      onClick: () => {
        // if (
        //   steps.indexOf("Education") < index ||
        //   (qParams?.edit && !onNextDisabled)
        // ) {
        //   dispatch(
        //     setJobSetupView({
        //       view: "Education",
        //     })
        //   );
        // }
      },
    },
  ];

  const viewMapping = {
    "Business, Specialties or Industry": "speciality",
    Skills: "skill",
  };

  // useEffect(()=>{
  //   dispatch(setJobSetupView({
  //     view: "Company Details",
  //     currentTab: 0
  //   }))
  // }, [])

  const checkIsLoading = (() => {
    switch (view) {
      case "Company Details":
        if (currentTab === tabs.length - 1) {
          return profiles.some(({ isScraping }) => isScraping)
        }
        return false
      case "Education": {
        if (qParams?.edit && !qParams?.duplicate && isReview !== "1") {
          return similarCompaniesLoading
        } else {
          return false;
        }
      }
      case "Tribe Members":
        return similarCompaniesLoading
      default:
        return false;
    }
  })()

  const loadingFeedback = (() => {
    if (isJobSetupLoading && !unLoadableTabs.includes(tabs[currentTab])) {
      return <p>Recognizing entities...</p>
    }
    if (isCompanyProfileLoading) {
      return <p>Loading company profile...</p>
    }
    if (isLoadingSimilarCompanyDetails) {
      return <p>Loading similar company details...</p>
    }
    if (titleInitialising) {
      return <p>Initializing title...</p>
    }
    if (similarCompaniesFetching) {
      return <p> Fetching similar companies...</p>
    }
    if (newJobAdding) {
      return <p> Adding new job...</p>
    }
    if (editApplying) {
      return <p> Applying edit...</p>
    }

    switch (view) {
      case "Company Details":
        if (currentTab === tabs.length - 1) {
          return profiles.some(({ isScraping }) => isScraping) ? <p>
            Fetching ideal profiles...
          </p> : undefined
        }
        return undefined
      case "Education": {
        if (qParams?.edit && !qParams?.duplicate && isReview !== "1") {
          return similarCompaniesLoading ? <p>This might take a couple of minutes...</p> : undefined
        } else {
          return undefined;
        }
      }
      case "Tribe Members":
        return similarCompaniesLoading ? <p>This might take a couple of minutes...</p> : undefined
      default:
        return undefined;
    }
  })()

  const onNextDisabled = (() => {
    // if (qParams?.edit) {
    //   return false;
    // }
    switch (view) {
      case "Job Details":
        return tabs[currentTab] === "Job Description"
          ? descriptionValue.trim() === ""
          : tabs[currentTab] === "Location"
            ? selectedLocations.length === 0
            : false;
      case "Main Title":
        return (mainTitle?.name?.trim() || "") === "";
      case "Total Relevant Experience":
        return !totalExperience.every(
          ({ min, max }) =>
            typeof min === "number" &&
            !isNaN(min) &&
            typeof max === "number" &&
            !isNaN(max) &&
            min < max
        );
      default:
        return false;
    }
  })();

  const backButtonLabel = (() => {
    switch (view) {
      case "Job Details":
        return currentTab === 0 ? false : `Back to ${tabs[currentTab - 1]}`;
      case "Add Best Fit Profiles":
        return `Back to ${steps[index - 1]}`;
      case "Company Details":
        return currentTab === 0
          ? `Back to ${steps[index - 2]}`
          : `Back to ${tabs[currentTab - 1]}`;
      case "Business, Specialties or Industry":
        return "Back to Company Details";
      case "Main Title":
        return "Back to Business, Specialties or Industry";
      case "Additional Titles":
        return currentTab === 0
          ? `Back to ${steps[index - 2]}`
          : `Back to ${tabs[currentTab - 1]}`;
      case "Total Relevant Experience":
        return "Back to Additional Titles";
      case "Functions":
        return "Back to Total Relevant Experience";
      case "Skills":
        return "Back to Total Relevant Experience";
      case "Education":
        return "Back to Skills";
      // case "Tribe Members":
      //   return "Back to Education";
      default:
        return undefined;
    }
  })();

  const onBackClick = (() => {
    switch (view) {
      case "Job Details":
        return currentTab === 0 || (qParams?.edit && isReview !== "1")
          ? false
          : () => {
            dispatch(
              setJobSetupView({
                currentTab: currentTab - 1,
              })
            );
          };
      case "Add Best Fit Profiles":
        return () => {
          dispatch(
            setJobSetupView({
              view: "Job Details",
              currentTab: 1,
            })
          );
        };
      case "Company Details":
        return currentTab === 0
          ? () => {
            dispatch(
              setJobSetupView({
                view: "Add Best Fit Profiles",
              })
            );
          }
          : () => {
            dispatch(
              setJobSetupView({
                currentTab: currentTab - 1,
              })
            );
          };
      case "Business, Specialties or Industry":
        return () => {
          if (currentTab === 0) {
            dispatch(
              setJobSetupView({
                view: "Company Details",
                currentTab: similarCompaniesFetched ? 1 : 0,
              })
            );
            // dispatch(initJobSpecialities())
          } else {
            dispatch(
              setJobSetupView({
                currentTab: currentTab - 1,
              })
            );
          }
        };
      case "Main Title":
        return () =>
          dispatch(
            setJobSetupView({
              view: "Business, Specialties or Industry",
              shouldSelectLastTab: true,
            })
          );
      case "Additional Titles":
        return currentTab === 0
          ? () => {
            dispatch(
              setJobSetupView({
                view: "Main Title",
              })
            );
          }
          : () => {
            dispatch(
              setJobSetupView({
                currentTab: currentTab - 1,
              })
            );
          };
      // () => dispatch(setJobSetupView({
      //   view: 'Main Title'
      // }))
      case "Total Relevant Experience":
        return () => {
          dispatch(
            setJobSetupView({
              highlightValue: "",
              selectedValues: [],
            })
          );
          dispatch(
            setJobSetupView({
              view: "Additional Titles",
              shouldSelectLastTab: true,
            })
          );
        };
      case "Functions":
        return () =>
          dispatch(
            setJobSetupView({
              view: "Total Relevant Experience",
            })
          );
      case "Skills":
        return currentTab === 0
          ? () => {
            dispatch(
              setJobSetupView({
                view: "Total Relevant Experience",
              })
            );
          }
          : () => {
            dispatch(
              setJobSetupView({
                currentTab: currentTab - 1,
              })
            );
          };
      case "Education":
        return () =>
          dispatch(
            setJobSetupView({
              view: "Skills",
              shouldSelectLastTab: true,
            })
          );
      case "Tribe Members":
        return () =>
          dispatch(
            setJobSetupView({
              view: "Education",
            })
          );
      default:
        return undefined;
    }
  })();

  const postIdealProfileDummyCall = () =>
    new Promise((resolve, reject) =>
      fetch(`https://qlurec-nlp-warmup-service-bdhweedyzq-uc.a.run.app/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ screenName: "post_ideal_profile" }),
      })
        .then((res) => resolve(true))
        .catch((e) => resolve(true))
    );

  const onNextClick = () => {
    switch (view) {
      case "Job Details":
        if (currentTab === tabs.length - 1) {
          if (qParams?.edit && isReview !== "1") {
            dispatch(setJobSetupView({ view: "Add Best Fit Profiles" }));
          } else if (isReview !== "1") {
            dispatch(setJobSetupView({ view: "Add Best Fit Profiles" }));
          } else {
            dispatch(setJobSetupView({ view: "Add Best Fit Profiles" }));
          }
        } else if (isReview !== "1") {
          dispatch(
            sendJobDescriptionToNer(
              { currentTab: currentTab + 1 },
              { currentTab }
            )
          );
          dispatch(getIncentives());
        } else {
          dispatch(
            setJobSetupView({
              currentTab: currentTab + 1,
            })
          );
        }
        break;
      case "Add Best Fit Profiles":

        dispatch(setJobSetupView({ view: "Company Details" }));
        // if (qParams?.edit && isReview !== "1" && !isEdited) {
        //   dispatch(setJobSetupView({ view: "Company Details" }));
        // } else if (
        //   (profiles.length > 0 || (qParams?.edit && isEdited)) &&
        //   isReview !== "1"
        // ) {
        // dispatch(
        // sendIdealProfilesToNer(
        //     "Company Details",
        //     qParams?.edit && isReview !== "1" && isEdited
        //   )
        // );
        // } else {
        //   dispatch(
        //     setJobSetupView({
        //       view: "Company Details",
        //     })
        //   );
        // }
        break;
      case "Company Details":
        postIdealProfileDummyCall();
        if (currentTab === tabs.length - 1 && (isReview !== "1" && isSpecialtyEdited)) {
          dispatch(setCompaniesAndIdealProfileInJobSetup(
            "Business, Specialties or Industry",
            qParams?.edit && isReview !== "1" &&
            (isSpecialtyEdited || isEdited)))
        } else if (currentTab === 0) {
          dispatch(
            setJobSetupView({
              currentTab: currentTab + 1,
            })
          );
        } else {
          dispatch(
            setJobSetupView({ view: "Business, Specialties or Industry" })
          );
        }
        break;
      case "Business, Specialties or Industry":
        if (tabs[currentTab] === "Details") {
          if (isReview !== "1" && !qParams?.edit) {
            // console.log("===============setSpecialitiesInJobSetup=================")
            dispatch(
              setSpecialitiesInJobSetup(
                "Main Title",
                qParams?.edit && isReview !== "1" && isSpecialtyEdited
              )
            );
          } else {
            // console.log("===============saveAdditionalSpecialties=================")
            dispatch(saveAdditionalSpecialties());
            dispatch(setJobSetupView({ view: "Main Title" }));
          }
        } else if (
          ((tabs[currentTab] === "From Companies" &&
            tabs[currentTab + 1] !== "From Best Fits") ||
            tabs[currentTab] === "From Best Fits") &&
          isReview !== "1" &&
          (!qParams?.edit || (qParams?.edit && (isSpecialtyEdited)))
        ) {
          // console.log("===============setAddtionalSpecialitiesInJobSetup=================")
          dispatch(
            setAddtionalSpecialitiesInJobSetup(
              qParams?.edit && isReview !== "1" && isSpecialtyEdited
            )
          );
        } else {
          dispatch(
            setJobSetupView({
              currentTab: currentTab + 1,
            })
          );
        }
        break;
      case "Main Title":
        if (isReview !== "1") {
          dispatch(
            setMainTitleInJobSetup(
              "Additional Titles",
              qParams?.edit && isReview !== "1" && isEdited
            )
          );
        } else {
          dispatch(setJobSetupView({ view: "Additional Titles" }));
        }
        break;
      case "Additional Titles":
        if (tabs[currentTab] === "Details") {
          if (isReview !== "1" && qParams?.edit) {
            dispatch(saveAdditionalTitles());
            dispatch(setJobSetupView({ view: "Total Relevant Experience" }));
          } else if (isReview !== "1") {
            dispatch(
              setTitlesInJobSetup(
                "Total Relevant Experience",
                qParams?.edit && isReview !== "1" && isEdited
              )
            );
          } else {
            dispatch(setJobSetupView({ view: "Total Relevant Experience" }));
          }
        } else {
          dispatch(
            setJobSetupView({
              currentTab: currentTab + 1,
            })
          );
        }
        break;
      case "Total Relevant Experience":
        // if (isReview !== "1" && qParams?.edit) {
        //   dispatch(setJobSetupView({ view: "Skills" }));
        // } else if (isReview !== "1") {
        //   dispatch(
        //     setExperienceInJobSetup(
        //       "Skills",
        //       qParams?.edit && isReview !== "1"
        //     )
        //   );
        // } else {
        //   dispatch(setJobSetupView({ view: "Skills" }));
        // }
        fetch("https://company-analysis-warmup-bdhweedyzq-uc.a.run.app", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ whenDoYouNeedIt: "I needed it yesterday!" }),
        });
        if (isReview !== "1" && qParams?.edit && !isEdited) {
          dispatch(setJobSetupView({ view: "Skills" }));
        } else if (isReview !== "1") {
          dispatch(
            setExperienceInJobSetup(
              "Skills",
              qParams?.edit && isReview !== "1" && isEdited
            )
          );
        } else {
          dispatch(setJobSetupView({ view: "Skills" }));
        }
        break;
      case "Functions":
        // dispatch(
        //   setJobSetupView({
        //     highlightValue: "",
        //     profileHighlightValue: [],
        //     selectedValues: [],
        //     profileSelectedValues: [],
        //   })
        // );
        if (isReview !== "1" && qParams?.edit && !isEdited) {
          dispatch(setJobSetupView({ view: "Skills" }));
        } else if (isReview !== "1") {
          dispatch(
            setFunctionsInJobSetup(
              "Skills",
              qParams?.edit && isReview !== "1" && isEdited
            )
          );
        } else {
          dispatch(setJobSetupView({ view: "Skills" }));
        }
        break;
      case "Skills":
        dispatch(
          setJobSetupView({
            highlightValue: "",
            profileHighlightValue: [],
            selectedValues: [],
            profileSelectedValues: [],
          })
        );
        if (tabs[currentTab] === "Details") {
          if (isReview !== "1" && qParams?.edit) {
            dispatch(saveAdditionalSkills());
            dispatch(setJobSetupView({ view: "Education" }));
          } else if (isReview !== "1") {
            dispatch(
              setSkillsInJobSetup(
                "Education",
                qParams?.edit && isReview !== "1"
              )
            );
          } else {
            dispatch(setJobSetupView({ view: "Education" }));
          }
        } else {
          dispatch(
            setJobSetupView({
              currentTab: currentTab + 1,
            })
          );
        }
        break;
      case "Education":
        // CHECK:check if previous state has changed
        if (qParams?.edit && !qParams?.duplicate && !isEdited && !isSpecialtyEdited && !isLocationEdited) {
          loggingActivity(ActivityTypes.VIEW_JOB, qParams.jId);
          const { fS, fSO, secS, secSO, hCSR } = getDefaultFilters(jobArray)
          history.push(
            `/job/${qParams.jId}?fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&ac=true&fS=${fS}&fSO=${fSO}&secS=${secS}&secSO=${secSO}&f=a&hCSR=${encodeURIComponent(hCSR)}`
          );
        }
        if (qParams?.edit && isReview !== "1" && !qParams?.duplicate && (isEdited || isSpecialtyEdited || isLocationEdited)) {
          toast.dismiss();
          toast(
            ({ closeToast }) => (
              <ProfileLiked
                header={`Apply Changes?`}
                description="Qlu will rescore existing prospects and add new ones"
              >
                <Button
                  className="reviewProfileBtn"
                  disabled={editApplying}
                  onClick={(e) => {
                    if (editApplying || e?.detail > 1) {
                      return false;
                    } else {
                      setEditApplying((prev) => true);
                      dispatch(
                        setEducationInJobSetup(
                          "Tribe Members",
                          true,
                          qParams.jId,
                          (e) => setEditApplying((prev) => true),
                          (e) => setEditApplying((prev) => false),
                          editApplying
                        )
                      );
                      setTimeout(() => { }, 1500);
                    }
                  }}
                >
                  Yes, Apply Changes
                </Button>
                <Button className="closeButtonPopup" onClick={closeToast}>
                  No, continue editing
                </Button>
              </ProfileLiked>
            ),
            {
              closeButton: false,
              hideProgressBar: true,
              style: {
                backgroundColor: "#A0A0A0",
                borderRadius: "8px",
                width: "514px",
              },
            }
          );
        } else if (qParams?.edit && isReview === "1" && !qParams?.duplicate) {
          const fS = "title_score";
          const fSO = "desc";
          const secS = rank > 8 ? "industry_score" : "skill_score";
          const secSO = "desc";
          history?.push(
            `/job/${qParams.jId
            }?t=5&f=a&fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&fS=${fS}&fSO=${fSO}&secS=${secS}&secSO=${secSO}${qParams.aT ? `&aT=${qParams.aT}` : ""
            }`
          );
          // history.push(`/job/${qParams.jId}/${isQuick ? true : false}`);
        } else {
          dispatch(setEducationInJobSetup("Tribe Members"));
        }
        break;

      case "Tribe Members":
        if (!newJobAdding) {
          const rank = get(jobPosition, ["0", "rank"], 3);
          dispatch(
            addNewJob((id) => {
              history.push(
                `/job/addingProspects/${id}?t=${rank >= 8 ? "3" : "3"}`
              );
            }, !!qParams?.duplicate)
          );
        }
        break;
      default:
        break;
    }
  };

  const onTabClick = (tab) => {
    // switch (view) {
    //   case "Job Details":
    //     if (
    //       (tab.index < currentTab || descriptionValue !== "") &&
    //       (!qParams?.edit || isReview === "1")
    //     ) {
    //       dispatch(
    //         setJobSetupView({
    //           currentTab: tab.index,
    //         })
    //       );
    //     }
    //     break;
    //   case "Company Details":
    //     if (
    //       tab.index < currentTab ||
    //       similarCompanies.length > 0 ||
    //       qParams?.edit
    //     ) {
    //       dispatch(
    //         setJobSetupView({
    //           currentTab: tab.index,
    //         })
    //       );
    //     }
    //     break;
    //   default:
    //     break;
    // }
  };

  const handleCloseButton = () => {
    if (qParams?.edit && isReview !== "1") {
      toast.dismiss();
      toast(
        ({ closeToast }) => (
          <ProfileLiked
            header={`Are you sure?`}
            description="All changes will be discarded"
          >
            <Button
              className="reviewProfileBtn"
              onClick={() => {
                dispatch(initCompanyDetails());
                dispatch(initJobCriteria());
                dispatch(initJobDetails());
                dispatch(initJobIdealProfile());
                dispatch(initJobLocation());
                dispatch(initJobSpecialities());
                dispatch(initTribeMembers());
                dispatch(initJobsetup());
                dispatch(initJobSetupView());
                history.goBack();
              }}
            >
              Yes, Discard Changes
            </Button>
            <Button className="closeButtonPopup" onClick={closeToast}>
              No, continue editing
            </Button>
          </ProfileLiked>
        ),
        {
          closeButton: false,
          hideProgressBar: true,
          style: {
            backgroundColor: "#A0A0A0",
            borderRadius: "8px",
            width: "514px",
          },
        }
      );
    } else if (qParams?.edit && isReview == "1") {
      history.goBack();
    } else {
      if (selectedLocations.length === 0 && descriptionValue === "") {
        dispatch(initCompanyDetails());
        dispatch(initJobCriteria());
        dispatch(initJobDetails());
        dispatch(initJobIdealProfile());
        dispatch(initJobLocation());
        dispatch(initJobSpecialities());
        dispatch(initTribeMembers());
        dispatch(initJobsetup());
        dispatch(initJobSetupView());
        history.goBack();
      } else {
        toast.dismiss();
        toast(
          ({ closeToast }) => (
            <ProfileLiked
              header={`Are you sure?`}
              description="All changes will be discarded"
            >
              <Button
                className="reviewProfileBtn"
                onClick={() => {
                  dispatch(initCompanyDetails());
                  dispatch(initJobCriteria());
                  dispatch(initJobDetails());
                  dispatch(initJobIdealProfile());
                  dispatch(initJobLocation());
                  dispatch(initJobSpecialities());
                  dispatch(initTribeMembers());
                  dispatch(initJobsetup());
                  dispatch(initJobSetupView());
                  history.goBack();
                }}
              >
                Yes, Discard Changes
              </Button>
              <Button className="closeButtonPopup" onClick={closeToast}>
                No, continue editing
              </Button>
            </ProfileLiked>
          ),
          {
            closeButton: false,
            hideProgressBar: true,
            style: {
              backgroundColor: "#A0A0A0",
              borderRadius: "8px",
              width: "514px",
            },
          }
        );
      }
    }
  };

  useEffect(() => {
    if (tabs[currentTab] === "Job Description") {
      if (generateJD) {
        setFAQs(GenerateJD);
      } else {
        console.log("not generated");
        setFAQs(JobDescriptionFAQS);
      }
    }
    if (tabs[currentTab] === "Location") {
      setFAQs(LocationFAQS);
    }
    if (view === "Add Best Fit Profiles") {
      setFAQs(BestFitFAQS);
    }
    if (view === "Company Details") {
      setFAQs(CompanyDetailsFAQS);
    }
    if (view === "Business, Specialties or Industry") {
      setFAQs(IndustryFAQS);
    }
    if (view === "Main Title") {
      setFAQs(JobTitleFAQS);
    }
    if (view === "Additional Titles") {
      setFAQs(AdditionalJobTitleFAQS);
    }
    if (view === "Total Relevant Experience") {
      setFAQs(ExperienceFAQS);
    }
    if (view === "Skills") {
      setFAQs(SkillsFAQS);
    }
    if (view === "Education") {
      setFAQs(EducationFAQS);
    }
    if (view === "Tribe Members") {
      setFAQs(TribeFAQS);
    }
  }, [view, tabs, currentTab, generateJD]);

  const [showPop, setShowPop] = useState(false);

  const handleBlockedNavigation = () => {
    setShowPop(false); // TODO:was true before
    return false;
  };

  const customId = "custom-id-yes";

  useEffect(() => {
    if (showPop === true) {
      toast.dismiss();
      toast(
        ({ closeToast }) => (
          <ProfileLiked
            header={`Are you sure?`}
            description="All changes will be discarded"
          >
            <Button
              className="reviewProfileBtn"
              onClick={() => {
                dispatch(initCompanyDetails());
                dispatch(initJobCriteria());
                dispatch(initJobDetails());
                dispatch(initJobIdealProfile());
                dispatch(initJobLocation());
                dispatch(initJobSpecialities());
                dispatch(initTribeMembers());
                dispatch(initJobsetup());
                dispatch(initJobSetupView());
                history.goBack();
              }}
            >
              Yes, Discard Changes
            </Button>
            <Button
              className="closeButtonPopup"
              onClick={(e) => {
                e.preventDefault();
                closeToast();
                setShowPop(false);
              }}
            // onClick={closeToast}
            >
              No, continue editing
            </Button>
          </ProfileLiked>
        ),
        {
          closeButton: false,
          hideProgressBar: true,
          autoClose: 2000,
          toastId: customId,
          style: {
            backgroundColor: "#A0A0A0",
            borderRadius: "8px",
            width: "514px",
          },
        }
      );
      setTimeout(() => {
        setShowPop(false);
      }, 10000);
    }
  }, [showPop]);

  return (
    <>
      {/* // currentTab is home
        <Support componmenet />

    */}
      <Support data={FAQs} />
      {qParams?.edit && !qParams?.duplicate && (
        <ScoreAndConnectHeader isEdit={true} jobTitle={jobTitle} />
      )}
      <EditJobWrapper
        isEdit={qParams?.edit}
        isDuplicate={qParams?.duplicate}
        search={search}
      >
        <Prompt
          when={
            pathname === "/jobsetup" &&
            (selectedLocations.length !== 0 || descriptionValue !== "")
          }
          message={handleBlockedNavigation}
        // message={(location, action) =>{
        //   return 'Are you sure? All changes will be discarded'
        // }
        // }
        />
        <SetupLayout
          actionBarStyle={{ padding: "0 40px" }}
          steps={stepper}
          onClose={handleCloseButton}
          onPrimaryClick={onNextClick}
          PrimaryLabel={
            view == "Education" && qParams?.edit && !qParams?.duplicate
              ? isReview === "1"
                ? "Finish"
                : "Apply Changes"
              : "Next"
          }
          actionBarchild={loadingFeedback}
          primaryDisabled={onNextDisabled || checkIsLoading || isLoading}
          secondaryDisabled={isLoading}
          onPrimaryLoading={checkIsLoading || isLoading}
          showPrimaryClick={true}
          onSecondaryClick={onBackClick}
          LeftWindow={(() => {
            switch (view) {
              case "Business, Specialties or Industry":
                return <SpecialityDetails />;

              case "Main Title":
                return <CriteriaMainTitle />;

              case "Additional Titles":
                return <CriteriaAdditionalTitles />;

              case "Total Relevant Experience":
                return <CriteriaTotalExperience />;

              case "Functions":
                return <CriteriaFunctions />;

              case "Skills":
                return <CriteriaSkills />;

              case "Education":
                return <CriteriaEducation />;

              default:
                return false;
            }
          })()}
          RightWindow={(() => {
            switch (view) {
              case "Job Details":
                return (
                  <TabularContainer
                    icon={stepper[index].step}
                    heading={steps[index]}
                    currentTab={tabs[currentTab]}
                    onTabClick={onTabClick}
                    tabs={tabs.map((tab, index) => ({
                      tab,
                      name: tab,
                      index,
                      disabled: true,
                    }))}
                  >
                    {(() => {
                      switch (tabs[currentTab]) {
                        case "Title":
                          return <JobDetailsTitle />;
                        case "Location":
                          return <JobDetailsLocation />;
                        case "Job Description":
                          return <JobDetailsDescription />;
                      }
                    })()}
                  </TabularContainer>
                );

              case "Add Best Fit Profiles":
                return (
                  <TabularContainer
                    icon={stepper[index].step}
                    heading={steps[index]}
                  >
                    <AddIdealProfiles />
                  </TabularContainer>
                );

              case "Company Details":
                return (
                  <TabularContainer
                    icon={stepper[index].step}
                    heading={steps[index]}
                    tabs={tabs.map((tab, index) => ({
                      tab,
                      name: tab,
                      index,
                      disabled: true,
                    }))}
                    currentTab={tabs[currentTab]}
                    onTabClick={onTabClick}
                  >
                    {(() => {
                      switch (tabs[currentTab]) {
                        case "Hiring Company":
                          return <HiringCompanyDetails />;
                        case "Similar companies":
                          return <SimilarCompaniesDetails qParams={qParams} />;
                      }
                    })()}
                  </TabularContainer>
                );
              case "Business, Specialties or Industry":
                switch (tabs[currentTab]) {
                  case "From Companies":
                    return false;
                  // <CompanyCard hasSynonym={true} />;
                  case "From Best Fits":
                    return false;
                  // <ProfileSpecialtyCard hasSynonym={true} />;
                  case "From JD":
                    return (
                      <JobDescriptionCard
                        hasSynonym={true}
                        contextMenuDisabled={false}
                        contextMenuLabel="speciality"
                        cardStyle={{
                          height: "auto",
                          padding: "0 12px 52px 12px",
                        }}
                      />
                    );
                  default:
                    return false;
                }
              case "Main Title":
                return (
                  <JobDescriptionCard
                    hasSynonym={true}
                    cardStyle={{
                      height: "auto",
                      padding: "0 12px 52px 12px",
                    }}
                  />
                );
              case "Additional Titles":
                switch (tabs[currentTab]) {
                  case "From best fits":
                    return <ProfileCard />;
                  case "Generated":
                    return (
                      <GeneratedCard message="These titles have been intelligently generated by Qlu" />
                    );
                  default:
                    return false;
                }
              case "Total Relevant Experience":
                return (
                  <React.Fragment>
                    <JobDescriptionCard
                      hasExperience={true}
                      cardStyle={
                        profiles?.length === 0
                          ? { height: "auto", padding: "0 12px 24px 12px" }
                          : {}
                      }
                    />
                    <ProfileCard />
                  </React.Fragment>
                );
              case "Functions":
                return (
                  <GeneratedCard
                    message={`These functions have been intelligently generated from the main title ${titles[
                      Object.keys(titles).filter(
                        (key) => titles[key]?.isMainTitle
                      )?.[0]
                    ]?.name
                      }`}
                  />
                );
              case "Skills":
                switch (tabs[currentTab]) {
                  case "From best fits":
                    return false;
                  case "From JD":
                    return (
                      <JobDescriptionCard
                        contextMenuDisabled={false}
                        contextMenuLabel="skill"
                        hasSynonym={true}
                        cardStyle={{
                          height: "auto",
                          padding: "0 12px 52px 12px",
                        }}
                      />
                    );
                  default:
                    return false;
                }
              case "Education":
                return (
                  <JobDescriptionCard
                    hasSynonym={true}
                    cardStyle={{
                      height: "auto",
                      padding: "0 12px 52px 12px",
                    }}
                  // TODO: add a prop which sends false if there is no degree present
                  />
                );
              case "Tribe Members":
                return <TribeMembers />;
              default:
                return false;
            }
          })()}
        />
      </EditJobWrapper>
    </>
  );
};

export default connect((state) => ({
  viewController: state.jobSetup.viewController,
  descriptionValue: state.jobSetup.jobDetails.description,
  selectedLocations: state.jobSetup.jobLocation.selectedLocations,
  jobArray: state.prospectsView.candidates.job.jobArray,
  jobId: state.prospectsView.candidates.job?.id,
  isQuick: get(
    state,
    ["prospectsView", "candidates", "job", "meta", "isQuick"],
    false
  ),
  jobTitle: state.prospectsView.candidates.job.jobTitle,
  profiles: state.jobSetup.jobIdealProfile.profiles,
  isJobSetupLoading: state.jobSetup.jobSetup.isLoading,
  similarCompanies: state.jobSetup.companyDetails.similarCompanies,
  companyHiringFor: state.jobSetup.companyDetails.companyHiringFor,
  totalExperience: state.jobSetup.jobCriteria.totalExperience,
  titles: state.jobSetup.jobCriteria.titles,
  mainTitle: state.jobSetup.jobCriteria.mainTitle,
  titleInitialising: state.jobSetup.jobCriteria.titleInitialising,
  fetchIdealProfile: state.jobSetup.jobIdealProfile.isLoading,
  isCompanyProfileLoading:
    state.jobSetup.companyDetails.isCompanyProfileLoading,
  similarCompaniesFetching:
    state.jobSetup.companyDetails.similarCompaniesFetching,
  similarCompaniesFetched:
    state.jobSetup.companyDetails.similarCompaniesFetched,
  // isLoadingSimilarCompanyDetails: state.jobSetup.companyDetails.isLoadingSimilarCompanyDetails,
  isReview: state.jobSetup?.jobSetup?.isReview,
  isEdited: state.jobSetup?.jobSetup?.isEdited,
  isSpecialtyEdited: state.jobSetup?.jobSetup?.isSpecialtyEdited,
  isLocationEdited: state.jobSetup?.jobLocation?.isLocationEdited,
  newJobAdding: state.jobSetup?.jobSetup?.newJobAdding,
  isJobEditSuccess: state.jobSetup?.jobSetup?.isJobEditSuccess,
  generateJD: state.jobSetup?.jobSetup?.generateJD,
  jobPosition: state.jobSetup?.jobSetup?.jobSetup?.job_position,
  similarCompaniesLoading: state.jobSetup?.jobSetup?.similarCompaniesLoading,
  jobSetup: state?.jobSetup.jobSetup,
}))(JobSetup);
