import { isEmpty, union } from "lodash";
import React from "react";
import { connect } from "react-redux";
import RevampProspectCard from "../../uiLibrary/RevampProspectCard/RevampProspectCard";
import { getYrsAndMos } from "../../utils";
import "./RevampProspectMidCard.scss";

const RevampProspectMidCard = ({
  addHoverHighlighter,
  handleFullCardRight,
  handleFullCardLeft,
  addingProspects = false,
  handleRecommendedCard,
  handleClickArchiveProspects,
  onClickSave,
  isDetail = false,
  onClickCard,
  showCompanyModal,
  closeCompanyModal,
  removeHoverHighlighter,
  recommended,
  elementIndex,
  isSelected = false,
  onSelect = (e) => false,
  setFullCardTag,
  prospectData,
  onClickComments = () => false,
  selectedProspectIDVariant,
  activeProspectVariant,
  dispatch,
  showLiked,
  showReview,
  isOnScoreAndConnectRecommended = false,
}) => {
  const experiences = prospectData?.experience?.map((experience, index) => ({
    ...experience,
    ...(prospectData?.scoring?.candidate_experience_found?.[index] || {}),
  }));
  //   const education = prospectData?.education?.map(
  //     ({ degreeName, fieldOfStudy, schoolName }, index) => {
  //       if (index > 0) {
  //         return;
  //       }
  //       return `${degreeName} in ${fieldOfStudy} from ${schoolName}`;
  //     }
  //   );
  let must_haves_skills = prospectData.scoring?.skill_importance?.must_haves.map(
    (title) => ({
      title,
      span: getYrsAndMos(
        prospectData?.scoring?.candidate_experience_found?.reduce(
          (total, { skills }) => (skills?.includes(title) ? total + 1 : total),
          0.0
        )
      ),
    })
  );

  let missing_specialities = [];

  let prospectSkills =
    prospectData?.skills?.length > 0
      ? prospectData?.skills?.map((value) => value.toLowerCase().trim())
      : [];
  let prospectSkillsProfile =
    prospectData?.scoring?.skills_profile?.length > 0
      ? prospectData?.scoring?.skills_profile?.map((value) =>
        value.toLowerCase().trim()
      )
      : [];

  const allSkills = union(prospectSkills, prospectSkillsProfile);
  const newAllSkills = allSkills?.map((value) => value.toLowerCase().trim());
  let must_haves_missing = [];
  let important_missing = [];
  let nice_to_haves_missing = [];

  if (prospectData?.scoring?.skills_required["must_haves"]) {
    Object.keys(prospectData?.scoring?.skills_required)?.map((type) => {
      if (typeof prospectData?.scoring?.skills_required[type] !== "undefined")
        prospectData?.scoring?.skills_required[type]?.map((skill) => {
          if (!newAllSkills.includes(skill.toLowerCase().trim())) {
            switch (type) {
              case "must_haves":
                must_haves_missing.push({ title: skill });
                break;
              case "important":
                important_missing.push({ title: skill });
                break;
              case "nice_to_haves":
                nice_to_haves_missing.push({ title: skill });
                break;
              default:
            }
          }
        });
    });
  }

  const specialties_found =
    prospectData?.scoring?.specialties_found &&
      Object.keys(prospectData?.scoring?.specialties_found)?.length
      ? Object.keys(prospectData?.scoring?.specialties_found)?.map((key) => ({
        span: prospectData.scoring.specialties_found[key].found_years,
        speciality: key,
      }))
      : [];

  const newSpecialtiesRequired = prospectData?.scoring?.specialties_required
    ?.length
    ? prospectData?.scoring?.specialties_required.map((value) =>
      value.toLowerCase().trim()
    )
    : [];
  if (!isEmpty(prospectData?.scoring?.specialties_found)) {
    newSpecialtiesRequired?.map((key) => {
      if (
        !Object.keys(prospectData?.scoring?.specialties_found).includes(
          key.toLowerCase().trim()
        )
      ) {
        missing_specialities.push({ speciality: key });
      }
    });
  }
  const removeHighlighter = () => {
    setTimeout(() => {
      const skillHighlightedElements = document.querySelectorAll(
        ".skillHighLight"
      );

      if (skillHighlightedElements?.length) {
        skillHighlightedElements.forEach((el) => {
          el.classList.remove("skillHighLight");
        });
      }
    }, 300);
  };
  return (
    <div
      className="revampProspectMidCard"
      onClick={onClickCard}
      // style={{
      //   animation: `slideUpSmall ${0.5}s cubic-bezier(0.165, 0.84, 0.44, 1) forwards`,
      // }}
      onMouseLeave={removeHighlighter}
    >
      <div className="revampCard">
        <RevampProspectCard
          isFullPage={true}
          handleFullCardRight={handleFullCardRight}
          handleFullCardLeft={handleFullCardLeft}
          addHoverHighlighter={addHoverHighlighter}
          removeHoverHighlighter={removeHoverHighlighter}
          handleRecommendedCard={handleRecommendedCard}
          isDetail={isDetail}
          showLiked={true}
          showReview={showReview}
          elementIndex={elementIndex}
          recommended={recommended}
          handleClickArchiveProspects={handleClickArchiveProspects}
          prospectCardMid={true}
          setFullCardTag={setFullCardTag}
          prospectData={prospectData}
          isSelected={isSelected}
          onClickSave={onClickSave}
          onSelect={onSelect}
          onClickComments={onClickComments}
          addingProspects={addingProspects}
          isOnScoreAndConnectRecommended={isOnScoreAndConnectRecommended}
        />
      </div>
      <div
        className="summaryDetails"
        data-name={`summaryDetails ${elementIndex}`}
      >
        <div
          className="experience"
          // data-name={`experience ${elementIndex}`}
          onClick={() => {
            setFullCardTag && setFullCardTag(`[data-name="experience"]`);
          }}
          onMouseEnter={() => {
            addHoverHighlighter &&
              addHoverHighlighter("experience", elementIndex);
          }}
          onMouseLeave={() => {
            removeHoverHighlighter &&
              removeHoverHighlighter("experience", elementIndex);
          }}
        >
          <div className="mainTitle">Experience</div>
          <div className="experiences">
            {experiences?.map((experience, index) => {
              if (index == 0 || index == 1)
                return (
                  <div className="single-experience">
                    <div className="currentHeadline">
                      {experience?.title && (
                        <span
                          className="currentHeadlineChild"
                          data-name={`experience ${elementIndex}`}
                        >
                          {" "}
                          {experience.title}
                        </span>
                      )}{" "}
                      {experience?.company_name && (
                        <>
                          <span className="currentHeadlineChild">@</span>{" "}
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              showCompanyModal && showCompanyModal(experience);
                            }}
                            className="currentHeadlineChild"
                          >
                            {experience.company_name}
                          </span>
                        </>
                      )}
                    </div>
                    {/* <p className="experienceDate">
                      {moment(
                        `${experience?.start?.split("-")[1]}-${
                          experience?.start?.split("-")[0]
                        }`
                      ).format("MMM YYYY")}{" "}
                      -{" "}
                      {experience.end.length > 0
                        ? moment(
                            `${experience?.end?.split("-")[1]}-${
                              experience?.end?.split("-")[0]
                            }`
                          ).format("MMM YYYY")
                        : "Now"}
                    </p> */}
                  </div>
                );
            })}
          </div>
          {experiences?.length && (
            <div className="lengthMore">
              {experiences?.length > 2 ? experiences?.length - 2 : ""} more
            </div>
          )}
        </div>
        <div
          className="education"
          data-name={`education ${elementIndex}`}
          onClick={() => {
            setFullCardTag && setFullCardTag(`[data-name="education"]`);
          }}
          onMouseEnter={() => {
            addHoverHighlighter &&
              addHoverHighlighter("education", elementIndex);
          }}
          onMouseLeave={() => {
            removeHoverHighlighter &&
              removeHoverHighlighter("education", elementIndex);
          }}
        >
          <div className="mainTitle">Education</div>
          <div className="educations">
            {prospectData?.education?.map(
              ({ degreeName, fieldOfStudy, schoolName, start, end }, id) => {
                if (id > 0) {
                  return;
                }
                return (
                  <p key={id}>
                    {`${degreeName} ${fieldOfStudy ? "in" : ""} ${fieldOfStudy ? fieldOfStudy : ""
                      } `}{" "}
                    {schoolName ? `from ${schoolName}` : ""}{" "}
                    {start || end ? (
                      <span className="duration">
                        {start ? "•" : ""}{" "}
                        {start?.includes("-") ? start.split("-")[1] : start}{" "}
                        {start ? "-" : "•"}{" "}
                        {end?.includes("-") ? end.split("-")[1] : end}
                      </span>
                    ) : (
                      ""
                    )}
                  </p>
                );
              }
            )}
          </div>
        </div>
        <div
          className="skill"
          data-name={`skills ${elementIndex}`}
          onClick={() => {
            setFullCardTag && setFullCardTag(`[data-name="skills"]`);
          }}
          onMouseEnter={() => {
            addHoverHighlighter && addHoverHighlighter("skills", elementIndex);
          }}
          onMouseLeave={() => {
            removeHoverHighlighter &&
              removeHoverHighlighter("skills", elementIndex);
          }}
        >
          <div className="mainTitle">
            High Importance Skills &#183;{" "}
            <span>{must_haves_skills?.length} found </span>
            {must_haves_missing?.length > 0 ? (
              <span className="missing">
                <span className="globalDotStyle">•</span>{" "}
                <span>{must_haves_missing?.length} missing</span>
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="skill-list">
            {must_haves_skills?.map((skill, index) => {
              if (index > 4) return;
              return <span className="single-value">{skill?.title}</span>;
            })}

            {must_haves_missing?.map((skill, index) => {
              if (index > 0) return;
              return (
                <span className="single-value missing">{skill?.title}</span>
              );
            })}
            {must_haves_missing?.length > 1 ? (
              <div className="lengthMore">
                {must_haves_missing.length - 1} more
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className="speciality"
          data-name={`specialities ${elementIndex}`}
          onClick={() => {
            setFullCardTag && setFullCardTag(`[data-name="specialities"]`);
          }}
          onMouseEnter={() => {
            addHoverHighlighter &&
              addHoverHighlighter("specialities", elementIndex);
          }}
          onMouseLeave={() => {
            removeHoverHighlighter &&
              removeHoverHighlighter("specialities", elementIndex);
          }}
        >
          <div className="mainTitle">
            {" "}
            Business <span className="globalDotStyle">•</span>{" "}
            <span>{specialties_found?.length} found</span>{" "}
            {missing_specialities?.length > 0 ? (
              <span className="missing">
                <span className="globalDotStyle">•</span>{" "}
                <span>{missing_specialities?.length} missing</span>
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="speciality-list">
            {specialties_found?.map((speciality, index) => {
              if (index > 2) return;
              return (
                <span className="single-value">{speciality?.speciality}</span>
              );
            })}
            {missing_specialities?.map((speciality, index) => {
              if (index > 0) return;
              return (
                <span className="single-value missing">
                  {speciality?.speciality}
                </span>
              );
            })}
            {missing_specialities?.length > 0 ? (
              <div className="lengthMore">
                {missing_specialities.length - 1} more
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => ({
  selectedProspectIDVariant:
    state.prospectsView.prospectVariant.selectedProspectIDVariant,
  activeProspectVariant:
    state.prospectsView.prospectVariant.activeProspectVariant,
}))(RevampProspectMidCard);
