import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "../../../components/Loading";
import { manualApiCall } from "../../../utils";
import Header from "../Header";
import SectionActionBar from "../SetionActionBar";
import Stats from "./Stats";
import Table from "./Table";
import NotificationDropdownPanel from '../../../views/LandingPage/Home/NotificationDropdownPanel'

export const CampaignsContainer = ({
  isLoading,
  isDataAvailable,
  data,
  onPageChange,
  onSearch,
  label = "Sample Label",
}) => {
  console.log({ isLoading, isDataAvailable, data });
  return (
    <div>
      <SectionActionBar
        label={label}
        onPageChange={onPageChange}
        onSearch={onSearch}
        pageCount={data?.count ? Math.ceil(data.count / 5) : 0}
      />
      {isLoading && (
        <div className="dflex-cc" style={{ width: "100%", height: "350px" }}>
          {" "}
          <Loading />{" "}
        </div>
      )}
      {!isLoading && isDataAvailable && (
        <table className="campaignTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Job</th>
              <th>Recepients</th>
              <th>Rounds</th>
            </tr>
          </thead>
          <tbody>
            {data.campaigns.map((item, i) => (
              <tr key={item.id || i}>
                <td>{item.name}</td>
                <td>{item.jobTitle}</td>
                <td>{item.recepients}</td>
                <td>{item.rounds}</td>
                {/* <td>{item.updates.map(item => <Badge style={{ marginRight: '12px' }} className='pillitem' key={item.id}>{item.name}</Badge>)}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!isLoading && !isDataAvailable && (
        <div className="dflex-cc" style={{ width: "100%", height: "350px" }}>
          {" "}
          No Data
        </div>
      )}
    </div>
  );
};

const Campaigns = ({location}) => {
  const history = useHistory();

  const [jobwiseCampaignData, setJobwiseCampaignData] = React.useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = React.useState([]);
  const [campaignChartData, setCampaignChartData] = React.useState([]);

  useEffect(() => {
    fetchJobwiseCampaigns();
  }, []);

  const fetchJobwiseCampaigns = async () => {
    try {
      const {
        data: {
          campaigns,
          campaignsChartData,
          total_messaged,
          total_replied,
          total_interested,
        },
      } = await manualApiCall("/api/v2/auth/campaigns/dashboard", {
        method: "GET",
      });

      const stats = {
        chart: campaignsChartData,
        elements: [
          {
            date: new Date(Date.now()),
            label: "Prospects Messaged",
            value: total_messaged,
            percentage: total_messaged,
            key: "messaged",
          },
          {
            date: new Date(Date.now()),
            label: "Replied",
            value: total_replied,
            percentage: total_replied,
            key: "replied",
          },
          {
            date: new Date(Date.now()),
            label: "Interested",
            value: total_interested,
            percentage: total_interested,
            key: "interested",
          },
        ],
      };

      setCampaignChartData(stats);
      setJobwiseCampaignData(campaigns);
      setFilteredCampaigns(campaigns);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSearch = (args) => {
    const filtered = jobwiseCampaignData.filter(
      ({ jobTitle, locations }) =>
        jobTitle.toLowerCase().includes(args.toLowerCase()) ||
        locations.some((location) =>
          location.toLowerCase().includes(args.toLowerCase())
        )
    );
    setFilteredCampaigns(filtered);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Header
          label="Campaigns"
          actionText="New Campaign"
          onClickAction={() => {}}
          showAction={false}
        />
        <NotificationDropdownPanel
            historyPush={history.push}
            location={location}
          />
      </div>
      <div className="charts-container" style={{ marginTop: "16px" }}>
        <Stats isLoading={false} data={campaignChartData} />
      </div>
      <SectionActionBar
        label={"Jobwise Campaigns"}
        onPageChange={() => {}}
        onSearch={handleSearch}
        pageCount={0}
      />
      <div className="jobwiseCampaigns">
        {filteredCampaigns &&
          filteredCampaigns?.map((job, key) => (
            <Table
              refreshData={fetchJobwiseCampaigns}
              history={history}
              key={key}
              rows={job}
              columns={[
                "published",
                "rounds",
                "recipients",
                "status",
                "replied",
                "interested",
                "",
                "",
              ]}
            />
          ))}
      </div>
    </div>
  );
};

export default connect((state) => ({
  myCampaigns: state.landingPage.campaigns.myCampaigns,
  tribeCampaigns: state.landingPage.campaigns.tribeCampaigns,
}))(Campaigns);
