import React, { useState, useEffect } from "react";
import "./TribeMembers.scss";
import NewAutoSuggestion from "../../../components/NewAutoSuggestion/NewAutoSuggestion";
import TribeMemberCard from "../../../components/TribeMemberCard/TribeMemberCard";
import Loading from '../../../uiLibrary/Loading'
import { connect } from "react-redux";
import {
  changeSuggestionInput,
  changeTribeMember,
  fetchFavTribeMembers,
  fetchTribeMembersList,
  selectTribeMember,
  setTribeSuggestions
} from "../../../actions/JobSetup";
import { getShortName, validateEmail } from "../../../utils";
import Loader from "react-loader-spinner";
import TickSvg from "../../../uiLibrary/SVGs/TickSvg";
import { Mixpanel } from "../../../utils/Mixpanel";

const TribeMembers = ({
  tribeCards,
  suggestionInput,
  memberList,
  suggestions,
  isLoadingFavTribes,
  titles,
  locations=['New York Metropolitan Area'],
  dispatch,
  userEmail
}) => {

  // const [suggestion, setSuggestion] = useState([])

  const isLoading = isLoadingFavTribes
  const jobPostion = titles[Object.keys(titles).filter(key=>titles[key]?.isMainTitle)?.[0]]?.name || ''

  console.log("===============memberList=================", memberList)

  useEffect(() => {
    Mixpanel.track("Add Tribe")
    dispatch(fetchTribeMembersList())
    dispatch(fetchFavTribeMembers())
  }, [])


  const onAddTribeMember = (key, added) => {
    dispatch(changeTribeMember({
      key,
      values: {
        added
      }
    }))
  }

  const onTogglePermission = (key, values) => {
    dispatch(changeTribeMember({
      key,
      values
    }))
  }

  const handleChangeInput = e => {
    dispatch(changeSuggestionInput(e.target.value))
  } 
 
  const handleSuggestionsClearRequested = () => dispatch(setTribeSuggestions(true))


  const handleSuggestionsFetchRequested = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const isEmail = validateEmail(inputValue)
    dispatch(setTribeSuggestions({isEmpty: inputLength === 0 , isEmail, inputLength, inputValue}))
   
  }

  const handleSuggestionSelected = (value) => {
    Mixpanel.track("Add tribe member select")
    dispatch(changeSuggestionInput(''))
    dispatch(selectTribeMember(value))
  }


  const renderSuggestion = value => {
    return ( value?.type === 'invite' ?
      ( value.email.toLowerCase() !== userEmail && <div>
        Invite <b>{`${value.email}`}</b> to your tribe
      </div>) :
      <div>
        {typeof value === "string" ? 
          value : 
          value?.name} 
      </div>
    )
  }


  return (
    <div className="tribeMemberContainer">
      <div>
        <span
          className='tribeMemberTick'
        >
          <TickSvg width='24' height='16' strokeWidth='2' />
        </span>
        <h3>
          All Done!
        </h3>
      </div>
      <div>
        <h4>
          Job Created
        </h4>
        <h1>
          {jobPostion}
        </h1>
        <span>
          {locations.map(({name}, id)=>(
            <p
              key={id}
            >
              {name}
            </p>
          ))}
        </span>
      </div>
      <div className="tribeMembersRadioCardsContainer">
        <h4>
          Add Tribe Members
        </h4>
        <p>
          Tribe members are those who you want to collaborate with when hiring under this job. <br/>They can see all the prospects, comment on their profiles, and reach out to them.
        </p>
        {console.log('here')}
        <div>
          <NewAutoSuggestion
            inputValue={suggestionInput}
            suggestionsArray={suggestions}
            placeholder='Name/Email'
            handleChangeInput={handleChangeInput}
            handleSuggestionSelected={handleSuggestionSelected}
            handleSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            handleSuggestionsClearRequested={handleSuggestionsClearRequested}
            renderSuggestion={renderSuggestion}
            handleToast={true}
            // shouldRenderSuggestions={shouldRenderSuggestions}
          />
          {isLoading && <Loading/>}
        </div>
        <span>
          {tribeCards.map((item, index) => (
            <TribeMemberCard
              key={item.email}
              shortName={item.name === "" ? getShortName(item.email) : getShortName(item.name)}
              type={item.type}
              name={item.name}
              email={item.email}
              added={item.added}
              onAddClick={e=>onAddTribeMember(item.email, !item.added)}
              reachoutPermission={item.reachoutPermission}
              viewPermission={item.viewPermission}
              onTogglePermission={(e, payload)=>onTogglePermission(item.email, payload)}
            />
          ))}
        </span>
      </div>
    </div>
  );
};

export default connect((state) => ({
  suggestionInput: state?.jobSetup?.tribeMembers?.suggestionInput,
  memberList: state.jobSetup.tribeMembers.memberList,
  suggestions: state.jobSetup.tribeMembers.suggestions,
  tribeCards: state.jobSetup.tribeMembers.tribeCards,
  isLoadingFavTribes: state.jobSetup.tribeMembers.isLoadingFavTribes,
  titles: state.jobSetup.jobCriteria.titles,
  locations: state.jobSetup.jobLocation.selectedLocations,
  userEmail: state.auth.user.email
}))(TribeMembers);










// <h3>Add Tribe Members</h3>
//       <p>
//         Add a member from your team, or invite anyone from outside by entring
//         their email
//       </p>
//       <div
//         className='tribeMemberInputContainer'
//       >
//         <h3>
//           NAME OR EMAIL
//         </h3>
//         <div>
//           <NewAutoSuggestion
//            inputValue={suggestionInput}
//            suggestionsArray={suggestions}
//            placeholder='Name/Email'
//            handleChangeInput={handleChangeInput}
//            handleSuggestionSelected={handleSuggestionSelected}
//            handleSuggestionsFetchRequested={handleSuggestionsFetchRequested}
//            handleSuggestionsClearRequested={handleSuggestionsClearRequested}
//            renderSuggestion={renderSuggestion}
//           //  shouldRenderSuggestions={shouldRenderSuggestions}
//           />
//           <button>
//             Invite/Add
//           </button>
//           {Loading && <Loader
//             type='TailSpin'
//             color='#297AF7'
//             width='24px'
//             height='24px'
//           />}
//         </div>
//       </div>
//       <div className="TribeMembersRadioCardsContainer">
//         {tribeCards.map((item, index) => (
//           <TribeMemberCard
//             key={item.email}
//             shortName={item.name === "" ? getShortName(item.email) : getShortName(item.name)}
//             type={item.type}
//             name={item.name}
//             email={item.email}
//             added={item.added}
//             onAddClick={e=>onAddTribeMember(item.email, !item.added)}
//             reachoutPermission={item.reachoutPermission}
//             viewPermission={item.viewPermission}
//             onTogglePermission={(e, payload)=>onTogglePermission(item.email, payload)}
//           />
//         ))}
//       </div>