import * as ActionTypes from "../../actions/primaryButton";

const initialState = {
  isPrimaryButtonLoading: false,
};

const primaryButtonloading = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_IS_PRIMARY_BUTTON_LOADING:
      return { ...state, isPrimaryButtonLoading: action.payload };
    default:
      return state;
  }
};

export default primaryButtonloading;
