import React from 'react'
import SecondaryButton from '../../../uiLibrary/SecondaryButton'
import './header.scss'

const Header = ({
  label = 'sample label',
  onClickAction = () => { },
  actionText = 'action',
  showAction = true
}) => {
  return (
    <div className='homeCompHeader'>
      <p>{label}</p>
      {showAction &&
        <SecondaryButton onClick={onClickAction}>{actionText}</SecondaryButton>
      }
    </div>
  )
}

export default Header