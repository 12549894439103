import * as ActionTypes from '../../actions/hybridCampaign'
import { v4 } from 'uuid';
const initialState = {
    campaign: [],
    publishing: false,
    publishingSuccess: false,
    publishingFailure: false,
    firstRound: []
}

const initialNode = {
    reviewed: false,
    nodeId: v4(),
    targets: [],
    sendAfterDays: 0,
    sendAfterHours: 0,
    firstDegreeSubject: 'Hi',
    secondDegreeSubject: 'Hello',
    firstDegreeTemplate: `Hi {{.First_Name}}, Hope you and your family are keeping well. I was reaching out to see if you might be interested in exploring a new role? I think you might be the perfect fit for {{.Job_Title}}. Should we connect this week?
    Best,
    {{.My_Name}}`,
    secondDegreeTemplate: `Hi {{.First_Name}}, Hope you and your family are keeping well. I was reaching out to see if you might be interested in exploring a new role? I think you might be the perfect fit for {{.Job_Title}}. Should we connect this week?
    Best,
    {{.My_Name}}`,
    firstDegreeMessageErrors: [],
    secondDegreeMessageErrors: [],
    round: 0,
    stopNode: false,
}

const campaign = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.INIT_HYBRID_CAMPAIGN: 
            return{
                ...state,
                campaign: [
                    {
                        ...initialNode,
                        nodeType: action.payload?.nodeType,
                        nodeLabel: action.payload?.nodeLabel,
                        messageType: action.payload?.messageType,
                    }
                ]
            }
        
        case ActionTypes.UPDATE_HYBRID_CAMPAIGN: 
            return{
                ...state,
                campaign: state.campaign.map((round, index)=>{
                        if(index===action.payload.round)
                            return Object.keys(round).reduce((prev, next) => {
                                    const newValues = action.payload.payload
                                    if(Object.keys(newValues).includes(next)){
                                        prev[next] = newValues[next]   
                                        return prev
                                    }
                                    prev[next] = round[next]   
                                    return prev
                                }, {})
                        return round
                    }, {})
            }
        case ActionTypes.ADD_FOLLOW_UP_HYBRID_CAMPAIGN: 
            const stopNode = v4()
            const nodeId = v4()
            return{
                ...state,
                campaign: [...state.campaign.map((round, index)=>{
                        if(index===state.campaign.length-2)
                            return {
                                ...round,
                                targets: [stopNode, ...round.targets]
                            }
                        if(index===state.campaign.length-1)
                            return {
                                ...round,
                                targets: [nodeId],
                                stopNode
                            }
                        return round
                    }), 
                    {
                        ...initialNode,
                        sendAfterDays: 1, 
                        nodeType: 'Follow Up',
                        nodeId,
                    }
                ]
            }
        case ActionTypes.REMOVE_FOLLOW_UP_HYBRID_CAMPAIGN: 
            return{
                ...state,
                campaign: state.campaign.slice(0, -1).map((round, index)=>{
                        if(index===state.campaign.length-3)
                            return {
                                ...round,
                                targets: [round.targets[1]]
                            }
                        if(index===state.campaign.length-2)
                            return {
                                ...round,
                                targets: [],
                                stopNode: false
                            }
                        return round
                    })
            }

        case ActionTypes.PUBLISH_HYBRID_CAMPAIGN: 
            return {
                ...state,
                publishing: true,
                publishingSuccess: false,
                publishingFailure: false,
            }


        case ActionTypes.PUBLISH_HYBRID_CAMPAIGN_FAILURE: 
            return {
                ...state,
                publishing: false,
                publishingSuccess: false,
                publishingFailure: true,
            }


        case ActionTypes.PUBLISH_HYBRID_CAMPAIGN_SUCCESS: 
            return {
                ...state,
                publishing: false,
                publishingSuccess: true,
                publishingFailure: false,
                firstRound: action.payload
            }

        case ActionTypes.SET_PROSPECTS_TO_SEND: 
            return {
            ...state,
            ...action.payload
        }

        case ActionTypes.DISCARD_HYBRID_CAMPAIGN:
            return initialState

        default:
            return state
    }
}


export default campaign