import { get } from 'lodash'
import { capitalizeCase, getMaxKey } from '.'

export const transformCsvData = (dt) => {

  // return dt.map((item, idx) => ({ fullName: item.fullName }))
  try {
    return dt
      .map((itm, idx) => {
        const {
          fullName,
          age,
          gender,
          ethnicity,
          companyName,
          title,
          headline,
          education,
          experience,
          industry,
          locality,
          location,
          profileUrl,
          publicIdentifier,
          visa_status,
          skills,
          summary,
          buck_score,
          work_email,
          personal_email,
          primaryEmail,
          scoring: {
            experience_found,
            skill_importance,
            education_score,
            education_total,
            experience_score,
            experience_total,
            skill_score,
            skill_total,
            // title_score,
            title_total = 6,
            company_score,
            skills_profile,
            specialty_score,
            specialty_total,
            company_total = 6,
          }

        } = itm

        // console.log("===================transformCsvData===========================",  {
        //   fullName,
        //   age,
        //   gender,
        //   ethnicity,
        //   companyName,
        //   title,
        //   headline,
        //   education,
        //   experience,
        //   industry,
        //   locality,
        //   profileUrl,
        //   publicIdentifier,
        //   visa_status,
        //   skills,
        //   summary,
        //   buck_score,
        //   work_email,
        //   personal_email,
        //   experience_found,
        //   skill_importance,
        //   education_score,
        //   education_total,
        //   experience_score,
        //   experience_total,
        //   skill_score,
        //   skill_total,
        //   // title_score,
        //   title_total,
        // } )

        const title_score = buck_score

        const Total_Education = education ?
          education.map(edObject => {
            const ed = []
            for (const [key, value] of Object.entries(edObject)) {
              switch (key) {
                case ('degreeName'): {
                  ed.push(`Degree Name: ${value}`)
                  break;
                }
                case ('start'): {
                  ed.push(`Start Date: ${value}`)
                  break
                }
                case ('end'): {
                  ed.push(`End Date: ${value}`)
                  break
                }
                case ('fieldOfStudy'): {
                  ed.push(`Field of Study: ${value}`)
                  break
                }
                case ('schoolName'): {
                  ed.push(`School Name: ${value}`)
                  break
                }
                default:
                // ed.push(`${value}`);
              }
            }
            return ed?.join(' ')
          })?.join('/ ') : ''

        const Total_Experience = experience ?
          experience.map(expObject => {
            const exp = []
            for (const [key, value] of Object.entries(expObject)) {
              switch (key) {
                case ('company_name'): {
                  exp.push(`Company Name: ${value
                    .replace(/\n/g, ' ')
                    .replace(/,/g, '\,')
                    .replace(/'/g, '\'')}`)
                  break;
                }
                case ('start'): {
                  exp.push(`Start Date: ${value}`)
                  break
                }
                case ('end'): {
                  exp.push(`End Date: ${value}`)
                  break
                }
                case ('location'): {
                  exp.push(`Location: ${value}`)
                  break
                }
                case ('title'): {
                  exp.push(`Job Title: ${value
                    .replace(/\n/g, ' ')
                    .replace(/,/g, '\,')
                    .replace(/'/g, '\'')}`)
                  break
                }
                case ('industries'): {
                  console.log("INDUSTRIES ARE: ", value)

                  //variant 1 ['i1','i2']
                  //variant 2 {  } 
                  //variant 3 [{  }]

                  let variant = null
                  if (Array.isArray(value)) {
                    if (typeof value[0] == 'string') {
                      variant = 1
                    } else if (typeof value[0] == 'object') {
                      variant = 3
                    }
                  } else {
                    variant = 2
                  }

                  let toPush = ``
                  switch (variant) {
                    case 1:
                      toPush = value?.join('/ ')?.replace(/\n/g, ' ')
                        .replace(/,/g, '\,')
                        .replace(/'/g, '\'')
                      break

                    case 2:
                      toPush = get(value, 'localizedName', get(value, 'name'), '')?.replace(/\n/g, ' ')
                        .replace(/,/g, '\,')
                        .replace(/'/g, '\'')
                      break

                    case 3:
                      toPush = value.map(item => get(item, 'localizedName', get(item, 'name'), ''))?.join('/ ').replace(/\n/g, ' ')
                        .replace(/,/g, '\,')
                        .replace(/'/g, '\'')
                      break
                    default:
                      break
                  }


                  exp.push(`Industry: ${toPush}`)
                  break
                }
                case ('description'): {
                  break
                }
                default:
                // exp.push(`${value}`);
              }
            }
            return exp?.join(' ')
          })?.join('/ ') : ''

        const genderObj = typeof gender === "string" ? JSON.parse(gender) : gender
        const Sex = genderObj ? capitalizeCase(getMaxKey(genderObj)) : ''

        const ethnicityObj = typeof ethnicity === "string" ? JSON.parse(ethnicity) : ethnicity
        const eth = ethnicityObj ? capitalizeCase(getMaxKey(ethnicityObj)) : ''
        const EthnicityString = eth.toLowerCase() == 'latino' ? 'Hispanic' : (eth.toLowerCase() == 'african-american' ? 'Black' : eth)

        const Company = companyName ? companyName : experience?.[0]?.company_name || ""


        return {
          index: idx + 1,
          Full_Name: fullName ? fullName : '',
          Work_Email: work_email ? work_email : "",
          Personal_Email: personal_email ? personal_email : primaryEmail ? primaryEmail : '',
          Age: age ? age : '',
          Gender: Sex,
          Ethnicity: EthnicityString,
          Title: title ? title.replace(/,/g, '\,')
            .replace(/'/g, '\'') : "",
          Company,
          Headline: headline ? headline
            .replace(/\n/g, ' ')
            .replace(/,/g, '\,')
            .replace(/'/g, '\'') : '',

          Education_Score: education_score && education_total ?
            `${education_score.toPrecision(3)} out of ${education_total}` : '',

          Experience_Score: experience_score && experience_total ?
            `${experience_score.toPrecision(3)} out of ${education_total}` : '',

          Skill_Score: skill_score && skill_total ?
            `${skill_score.toPrecision(3)} out of ${skill_total}` : '',

          Title_Score: title_score && title_total ?
            `${title_score.toPrecision(3)} out of ${title_total}` : '',

          Company_Score: company_score && company_total ?
            `${company_score.toPrecision(3)} out of ${company_total}` : '',

          Specialty_Score: specialty_score && specialty_total ?
            `${specialty_score.toPrecision(3)} out of ${specialty_total}` : '',

          Total_Education,
          Total_Experience,

          Relevant_Experience: experience_found ?
            experience_found.map(item => `${parseInt(item.span)} years as ${item.title} at ${item.company}`)?.join('\n ') : '',

          Total_Skills: skills ? skills : [],

          Important_Skills: skill_importance?.important ? skill_importance?.important : [],

          Must_Haves_Skills: skill_importance?.must_haves ? skill_importance?.must_haves : [],

          Nice_To_Haves_Skills: skill_importance?.nice_to_haves ? skill_importance?.nice_to_haves : [],

          Industry: industry ? industry : '',
          Location: locality && locality !== '' ? locality : Location && location !== '' ? location : '',
          ProfileURL: profileUrl ? profileUrl : `https://www.linkedin.com/in/${publicIdentifier}`,
          // Visa: visa_status ? visa_status : '',
          Summary: summary ? summary
            .replace(/\n/g, ' ')
            .replace(/,/g, '\,')
            .replace(/'/g, '\'') : '',
        }
      })
  } catch (e) {
    console.error('ERROR OBJECT:', e)
    console.error('AN ERROR OCCURRED IN TRANSFORMING CSV: ', e.message)
  }
}
