import React from "react";
import StepperContainer from "../StepperContainer";
import ActionBar from "../ActionBar";
import DualContainer from "../DualContainer";
import "./style.scss";
const SetupLayout = ({
  steps,
  cards,
  RightWindow,
  LeftWindow,
  PrimaryLabel,
  onPrimaryClick,
  SecondaryLabel,
  onSecondaryClick,
  primaryDisabled,
  secondaryDisabled,
  onPrimaryLoading,
  actionBarClassName,
  actionBarStyle = {},
  onClose,
  showPrimaryClick,
  actionBarchild,
}) => {
  return (
    <div className="setupLayoutContainer">
      <StepperContainer steps={steps} onClose={onClose} />
      <div>
        <DualContainer LeftWindow={LeftWindow} RightWindow={RightWindow} />
        <ActionBar
          PrimaryLabel={PrimaryLabel}
          onPrimaryClick={onPrimaryClick}
          SecondaryLabel={SecondaryLabel}
          onSecondaryClick={onSecondaryClick}
          primaryDisabled={primaryDisabled}
          secondaryDisabled={secondaryDisabled}
          onPrimaryLoading={onPrimaryLoading}
          className={actionBarClassName}
          style={actionBarStyle}
          showPrimaryClick={showPrimaryClick}
        >
          {actionBarchild}
        </ActionBar>
      </div>
    </div>
  );
};

export default SetupLayout;
