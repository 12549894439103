import React from "react";
import LocationSvg from "../../../uiLibrary/SVGs/LocationSvg";
import UsersIcon from "../../../uiLibrary/SVGs/UsersIcon";
import "./styles.scss";
import TableHeaderStats from "./TableHeaderStats";

export default function TableHeader({ data = {} }) {
  const {
    jobTitle,
    label,
    locations = [],
    tribe_members,
    prospect_count = 0,
    messaged_count = 0,
    replied_count = 0,
    interested_count = 0,
  } = data;

  const calculatePercentage = (numerator = 0, denominator = 0) => {
    if (!Number(denominator) || !Number(numerator)) {
      return `0.00%`;
    }

    return `${((numerator / denominator) * 100).toFixed(2)}%`;
  };

  return (
    <div className="tableHeaderContainer">
      <div className="tableHeaderContainer_left">
        <div className="tableHeaderContainer_heading">
          <p className="title">{jobTitle}</p>

          <div className="projectTitleContainer">
            {label && (
              <div>
                <p>{label}</p>
              </div>
            )}
          </div>
          <div className="tribes">
            <UsersIcon height="18px" width="15px" />
            <p>{tribe_members.length}</p>
          </div>
        </div>
        <div className="tableHeaderContainer_location">
          <LocationSvg />
          <p>{locations.join(", ")}</p>
        </div>
      </div>
      <div className="tableHeaderContainer_right">
        <TableHeaderStats label="Prospects" value={prospect_count} />
        <TableHeaderStats
          label="Messaged"
          value={messaged_count}
          subValue={calculatePercentage(messaged_count, prospect_count)}
        />
        <TableHeaderStats
          label="Replied"
          value={replied_count}
          subValue={calculatePercentage(replied_count, messaged_count)}
        />
        <TableHeaderStats
          label="Interested"
          value={interested_count}
          subValue={calculatePercentage(interested_count, replied_count)}
        />
      </div>
    </div>
  );
}
