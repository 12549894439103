
import { combineReducers } from 'redux'
import * as ActionTypes from '../../actions/prospectsView'
import createReducer from '../createReducer'

const myCampaigns = createReducer([
  ActionTypes.FETCH_JOB_CAMPAIGNS,
  ActionTypes.FETCH_JOB_CAMPAIGNS_SUCCESS,
  ActionTypes.FETCH_JOB_CAMPAIGNS_FAILURE
], (payload) => payload.data.count > 0)

const tribeCampaigns = createReducer([
  ActionTypes.FETCH_JOB_TRIBE_CAMPAIGNS,
  ActionTypes.FETCH_JOB_TRIBE_CAMPAIGNS_SUCCESS,
  ActionTypes.FETCH_JOB_TRIBE_CAMPAIGNS_FAILURE
], (payload) => payload.data.count > 0)

// export default campaigns

export default combineReducers({
  myCampaigns,
  tribeCampaigns
})