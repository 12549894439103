import { combineReducers } from "redux";
import viewController from "./viewController";
import jobDetails from "./jobDetails";
import jobLocation from "./jobLocation";
import companyDetails from "./companyDetails";
import jobIdealProfile from "./jobIdealProfile";
import tribeMembers from "./tribeMembers";
import jobSetup from "./jobSetup";
import calibrationProfiles from "./calibrationProfiles";
import profileFeedback from "./profileFeedback";

export default combineReducers({
  jobLocation,
  jobDetails,
  viewController,
  companyDetails,
  jobIdealProfile,
  tribeMembers,
  jobSetup,
  calibrationProfiles,
  profileFeedback,
});
