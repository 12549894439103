import React from "react";
import "../style.scss";
function UnArchiveSVG({
  color = "#297AF7",
  width = "24",
  height = "24",
  style = {},
  stroke = 2,
  className = "",
  fill = "none",
  selected = false,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={`${className} ${selected ? "qluSvgFillSelected" : ""}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6033 11.5903V19.892C19.6033 20.3323 19.4283 20.7546 19.117 21.066C18.8056 21.3774 18.3833 21.5523 17.9429 21.5523H9.64129C9.20094 21.5523 8.77863 21.3774 8.46726 21.066C8.15588 20.7546 7.98096 20.3323 7.98096 19.892V16.5713"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
      />
      <path
        d="M13.7922 15.7412V12.5904C13.7922 12.15 13.6173 11.7277 13.3059 11.4164C12.9945 11.105 12.5722 10.9301 12.1319 10.9301H4.6604"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
      />
      <path
        d="M6.28589 3.34204L7.86099 3.86715L20.4618 8.06802"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
      />
      <path
        d="M10.4861 3.86734C10.6254 3.4496 10.9249 3.10429 11.3188 2.90738C11.7126 2.71046 12.1686 2.67808 12.5863 2.81735L15.7365 3.86756C16.1543 4.00683 16.4996 4.30634 16.6965 4.70021C16.8934 5.09408 16.9258 5.55003 16.7865 5.96778"
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
      />
      <path
        d="M5.90558 13.8356L3 10.93L5.90558 8.02441"
        sstroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
      />
    </svg>
  );
}

export default UnArchiveSVG;
