import React from 'react'

const EliminateIcon = ({
    color='#A7ABB0',
    style = {}, 
    className='', 
    width="16", height="16", 
}) => {
    return (
        <svg className={className} style={style} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="5" stroke={color} strokeWidth="2"/>
            <path d="M4.18164 11.8184L11.8184 4.18161" stroke={color} strokeWidth="2"/>
        </svg>
    )
}

export default EliminateIcon
