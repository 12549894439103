import * as ActionTypes from '../../actions/QuickSetup'

const initialState = {
  description: '',
  title: '',
  isLoaded: false,
  isLoading: false
}

const jobDetails = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_JOB_DETAILS: {
        return initialState
    }
    case ActionTypes.CHANGE_JOB_DESCRIPTION: {
        return {
            ...state,
            description: action.payload
        }
    }
    case ActionTypes.CHANGE_JOB_DETAIL_TITLE: {
        return {
            ...state,
            title: action.payload
        }
    }
    default:
      return state
  }
}

export default jobDetails
