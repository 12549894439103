import React from 'react'
import { connect } from 'react-redux'
import ProfileSummaryCard from '../../../components/ProfileSummaryCard/ProfileSummaryCard'
import CardContainer from '../../../uiLibrary/CardContainer'
import CarouselTag from '../../../uiLibrary/CarouselTag'
import JobSetupStep03 from '../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep03'
import { numberWithCommas } from '../../../utils'
import './style.scss'
const CompanyCard = ({
    companyHiringFor,
    selectedValues,
    profileHighlightValue,
    isReview
}) => {

    const companyHiringSpecialty = selectedValues.reduce((total, values) => [...total, values, ...values?.synonym.map((synonym) => ({ ...synonym, key: values?.key, selected: values.selected, onClick: values?.onClick }))], []).filter(({ source }) => source?.includes("companyHiring")) || [];
    const companySelectedSecialty = selectedValues.reduce((total, values) => [...total, values, ...values?.synonym.map((synonym) => ({ ...synonym, key: values?.key, selected: values.selected, onClick: values?.onClick }))], []).filter(({ source }) => source?.includes("companySelected")) || []
    // || source?.includes("companyHiring")) || []
    console.log("===============companyHiringSpecialty====================", companyHiringSpecialty)
    console.log("===============companySelectedSecialty====================", companySelectedSecialty)
    return (
        <div
            className='companyCardContainer'
        >
            <CardContainer
                icon={<JobSetupStep03 />}
                heading="Hiring Company"
            >
                <div
                    className='companyCard'
                >
                    <ProfileSummaryCard
                        imgSrc={companyHiringFor?.logo}
                        name={companyHiringFor?.name}
                        headline={`${numberWithCommas(companyHiringFor?.staffCount || 0)} employees`}
                        isCompany={true}
                    />
                    <div
                        className='companyCardBody'
                    >
                        {companyHiringSpecialty.map((speciality, index) => (
                            <CarouselTag
                                key={`${speciality.name}${index}`}
                                greyTheme={selectedValues.reduce((total, values) => [...total, values, ...values?.synonym.map((synonym) => ({ ...synonym, key: values?.key, selected: values.selected, onClick: values?.onClick }))], []).filter(({ selected }) => !selected).map(({ name }) => name.trim().toLowerCase()).includes(speciality.name.trim().toLowerCase())}
                                selected={true}
                                highlight={profileHighlightValue.includes(speciality.name.trim().toLowerCase())}
                                onClick={isReview === '1' ? () => false : selectedValues.reduce((total, values) => [...total, values, ...values?.synonym.map((synonym) => ({ ...synonym, key: values?.key, selected: values.selected, onClick: values?.onClick }))], []).filter(({ name }) => name.trim().toLowerCase() === speciality.name.trim().toLowerCase())?.[0]?.onClick}
                            >
                                {speciality.name}
                            </CarouselTag>
                        ))}
                    </div>
                </div>
            </CardContainer>
            {companySelectedSecialty?.length > 0 &&
                <CardContainer
                    icon={<JobSetupStep03 />}
                    heading="Similar Companies"
                >
                    <div
                        className='companyCard'
                    >
                        <div
                            className='companyCardBody'
                        >
                            {companySelectedSecialty.map((speciality, index) => (
                                <CarouselTag
                                    key={`${speciality.name}${index}`}
                                    greyTheme={selectedValues.reduce((total, values) => [...total, values, ...values?.synonym.map((synonym) => ({ ...synonym, key: values?.key, selected: values.selected, onClick: values?.onClick }))], []).filter(({ selected }) => !selected).map(({ name }) => name.trim().toLowerCase()).includes(speciality.name.trim().toLowerCase())}
                                    selected={true}
                                    highlight={profileHighlightValue.includes(speciality.name.trim().toLowerCase())}
                                    onClick={isReview === '1' ? () => false : selectedValues.reduce((total, values) => [...total, values, ...values?.synonym.map((synonym) => ({ ...synonym, key: values?.key, selected: values.selected, onClick: values?.onClick }))], []).filter(({ name }) => name.trim().toLowerCase() === speciality.name.trim().toLowerCase())?.[0]?.onClick}
                                >
                                    {speciality.name}
                                </CarouselTag>
                            ))}
                        </div>
                    </div>
                </CardContainer>
            }
        </div>
    )
}

export default connect(state => ({
    selectedValues: state.jobSetup.viewController.selectedValues,
    profileHighlightValue: state.jobSetup.viewController.profileHighlightValue,
    companyHiringFor: state.jobSetup.companyDetails.companyHiringFor,
    isReview: state.jobSetup?.jobSetup?.isReview
}))(CompanyCard)
