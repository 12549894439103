import { debounce, get, isEmpty, omit, range } from "lodash";
import moment from "moment";
import qs from "qs";
import React, { useCallback, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { connect, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import {
  Button, ButtonGroup, Dropdown,
  DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroupButtonDropdown, Tooltip
} from "reactstrap";
import {
  setActiveProspectVariant,
  setCurrentBucket,
  setPageNumber
} from "../../actions/prospectsView";
import CsvExport from "../../components/CsvExport";
import FindMoreWidget from "../../components/FindMoreWidget";
import { PER_PAGE_COUNT } from "../../constants/api";
import { commentNotificationList } from "../../constants/notification";
import usePrevious from "../../customHooks/usePrevious";
import OutboxButton from "../OutboxButton";
import MyPagination from "../Pagination/Pagination";
import Bookmark from "../SVGs/BookmarkSvg";
import ChevronDown from "../SVGs/ChevronDownSvg";
import CrossSvg from "../SVGs/CrossSvg";
import DownloadIconSvg from "../SVGs/DownloadIcon";
import FilterSmall from "../SVGs/FilterSmallSvg";
import PlusSvg from "../SVGs/PlusSvg";
import ProspectDetailHigh from "../SVGs/ProspectDetailHighSvg";
import ProspectDetailMid from "../SVGs/ProspectDetailMidSvg";
import SearchSvg from "../SVGs/Search";
import Sort from "../SVGs/SortSmall";
import TrashBinSvg from "../SVGs/TrashBin";
import UnArchiveSVG from "../SVGs/UnArchiveSVG";
import "./RevampActionBar.scss";

const RevampActionBar = ({
  handleClearFilters,
  isLoading,
  expanded,
  dispatch,
  filterCount = 0,
  // selectedBucket,
  buckets,
  currentBucket,
  handleSortToggle,
  handleFilterToggle,
  topMin = 1,
  topMax = 50,
  onSelectAll = (e) => false,
  onDeSelectAll = (e) => false,
  onSelectApply = (e) => false,
  isAllSelected = false,
  showActions = false,
  activeProspectVariant,
  pageNum,
  onClickSave,
  isSavingProspect,
  onClickArchive,
  isArchivingProspect,
  isDownloadingProspect,
  history,
  history: { push },
  location: { search, pathname },
  selectedProspects,
  setShowMoreProspect,
  harryPotterMode,
  shouldShowHarryPotterMode,
  firstBasket,
  firstBucket,
  runInfo,
  deleteCount,
  hasSavedProfiles,
  jobId,
  saveCount,
  allProspects
}) => {
  const qParams = qs.parse(search.split("?").pop());
  const pageNumber = parseInt("p" in qParams ? parseInt(qParams.p) || pageNum : pageNum)
  const [isArchived, setIsArchived] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectDropdownOpen, setSelectDropdownOpen] = useState(false);
  const selectedBucket = buckets.filter(
    (bucket) => bucket.name === currentBucket
  )[0];
  let paginationRange = range(1, selectedBucket?.count, PER_PAGE_COUNT)
  if (paginationRange.length === 0) {
    paginationRange = [1]
  }
  const selectedProspectsCount = isEmpty(selectedProspects)
    ? 0
    : Object.keys(selectedProspects)?.length;
  const sortingToggle = useSelector((state) => state.prospectsView.sorting);
  const sortSelected =
    sortingToggle.fS !== "title_score" || sortingToggle.secS !== "skill_score";

  const [selectTop, setSelectTop] = useState();

  console.log(sortSelected);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const toggleSelectDropDown = () => setSelectDropdownOpen((prev) => !prev);


  const handleSelectTop = (e) => {
    if (e.target.value > topMax) {
      setSelectTop(topMax);
    } else {
      setSelectTop(e.target.value);
    }
  };

  const handleSelectAll = (e) => {
    onSelectAll(e);
  };

  const handleUnSelectAll = (e) => {
    onDeSelectAll(e);
  };

  const handleSelectApply = (e) => {
    onSelectApply(e);
    toggleSelectDropDown();
  };

  // const [candidatesData, setCandidatesData] = useState([])
  // useEffect(async () => {
  //   try {
  //     const qParamsCsv = {
  //       ...omit(
  //         { ...qs.parse(search.split("?").pop()) },
  //         ['b'],
  //       )
  //     }
  //     qParamsCsv['pPP'] = firstBucket?.count ? firstBucket?.count : PER_PAGE_COUNT
  //     qParamsCsv['p'] = 0

  //     prospectsApiCall(jobId, qParamsCsv)
  //       .then(result => {
  //         if (result?.prospects?.candidates)
  //           setCandidatesData(result?.prospects?.candidates)
  //       })
  //   } catch (e) {
  //     console.error("ERROR OCCURRED -> useEffect Catch -> e", e)
  //   }
  // }, [jobId, search]);
  // console.log("SEARCH AND PATHNAME: ", { search, pathname });
  const [csvOptionsList, setCsvOptionsList] = useState([
    { title: "Linkedin URL", selected: false },
    { title: "Headline", selected: false },
    { title: "All Experience", selected: false },
    { title: "Relevant Experience", selected: false },
    { title: "Skills", selected: false },
    { title: "Industry", selected: false },
    { title: "Contact Info", selected: false },
    { title: `"About" Info`, selected: false },
    { title: "Profile Scores", selected: false },
    { title: "Demographics", selected: false },
    { title: "Location", selected: false }
  ])

  const TooltipButtons = ({
    tooltipId,
    item,
    label,
    isActive = true,
    wrapInButton = true,
    onClick = (e) => false,
    className,
    disabled = false,
  }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
      <React.Fragment>
        {wrapInButton ? (
          <Button
            disabled={disabled || isLoading}
            color="primary"
            onClick={onClick}
            className={`${isActive ? "actionbarFilterButton" : "actionbarFilterGreyButton"
              } ${className ? className : ""}`}
            size="sm"
            id={"Action_Bar_Tooltip_" + tooltipId}
          >
            {item}
          </Button>
        ) : (
          item
        )}
        {
          label !== 'Download' ? (
            <Tooltip
              hideArrow={false}
              placement="bottom"
              isOpen={tooltipOpen}
              target={"Action_Bar_Tooltip_" + tooltipId}
              toggle={toggle}
              style={{ zIndex: 9 }}
              className="primaryToolTip"
            >
              {label}
            </Tooltip>
          ) : ''
        }
      </React.Fragment>
    );
  };
  const buttonsActionBarLeft = [
    // {
    //   item: (
    //     <HybridMessagingButton
    //       isLoading={false}
    //       disabled={false}
    //       tooltipId="Action_Bar_Tooltip_0"
    //     />
    //   ),
    //   label: "Hybrid Message Campaign",
    //   wrapInButton: false,
    // },
    {
      item: <OutboxButton className="topMenuIcons-right-actions-buttons" />,
      label: "Outbox",
      wrapInButton: false,
    },
    // {
    //     item: <CalanderSvg width="24" height="24" color="#297AF7" />,
    //     label: 'Calendar',
    //     isActive: true,
    // },
    // {
    //   item: isSavingProspect ? (
    //     <Loader width="16" height="16" type="TailSpin" color="#297AF7" />
    //   ) : (
    //     <Bookmark width="24" height="24" color="#297AF7" />
    //   ),
    //   label: "Save",
    //   isActive: true,
    //   onClick: (e) => {
    //     toast(`Profile "Saved"!`, {
    //       hideProgressBar: true,
    //       style: {
    //         backgroundColor: "#A0A0A0",
    //         borderRadius: "8px",
    //         width: "180px",
    //         color: "black",
    //         fontFamily: "Inter",
    //       },
    //     });
    //     onClickSave();
    //     onDeSelectAll(e);
    //   },
    //   disabled: isSavingProspect,
    // },
    {
      item: isArchivingProspect ? (
        <Loader width="16" height="16" type="TailSpin" color="#297AF7" />
      ) : isArchived ? (
        <UnArchiveSVG width="24" height="24" />
      ) : (
        <TrashBinSvg width="24" height="24" />
      ),
      label: isArchived ? "Unarchive" : "Archive",
      isActive: false,
      onClick: (e) => {
        toast.dismiss();
        toast(`Profile ${isArchived ? "Unarchived" : "Archived"}!`, {
          hideProgressBar: true,
          style: {
            backgroundColor: "#A0A0A0",
            borderRadius: "8px",
            width: "180px",
            color: "black",
            fontFamily: "Inter",
          },
        });
        onClickArchive();
        onDeSelectAll(e);
      },
      disabled: isArchivingProspect,
    },
    {
      item: isDownloadingProspect ? (
        <Loader width="16" height="16" type="TailSpin" color="#297AF7" />
      ) : (
        <CsvExport
          candidatesData={allProspects}
          csvOptionsList={csvOptionsList}
          setCsvOptionsList={setCsvOptionsList}
        >
          {" "}
          <DownloadIconSvg width="24" height="24" color="#297AF7" />
        </CsvExport>
      ),
      label: "Download",
      isActive: true,
      disabled: isDownloadingProspect,
      // onClick: e => false
    },
  ];
  const buttonsActionBarLeftMessage = [
    // {
    //   item: (
    //     <HybridMessagingButton
    //       isLoading={false}
    //       disabled={false}
    //       tooltipId="Action_Bar_Tooltip_0"
    //     />
    //   ),
    //   label: "Hybrid Message Campaign",
    //   wrapInButton: false,
    // },
    // {
    //     item: <CalanderSvg width="24" height="24" color="#297AF7" />,
    //     label: 'Calendar',
    //     isActive: true,
    // },
    {
      item: isSavingProspect ? (
        <Loader width="16" height="16" type="TailSpin" color="#297AF7" />
      ) : (
        <Bookmark width="24" height="24" color="#297AF7" />
      ),
      label: "Save",
      isActive: true,
      onClick: (e) => {
        toast.dismiss();
        toast(`Profile "Saved"!`, {
          hideProgressBar: true,

          style: {
            backgroundColor: "#A0A0A0",
            borderRadius: "8px",
            width: "180px",
            color: "black",
            fontFamily: "Inter",
          },
        });
        onClickSave();
        onDeSelectAll(e);
      },
      disabled: isSavingProspect,
    },
    {
      item: isArchivingProspect ? (
        <Loader width="16" height="16" type="TailSpin" color="#297AF7" />
      ) : isArchived ? (
        <PlusSvg width="24" height="24" />
      ) : (
        <TrashBinSvg width="24" height="24" />
      ),
      label: isArchived ? "Unarchive" : "Archive",
      isActive: false,
      onClick: (e) => {
        toast.dismiss();
        toast(`Profile ${isArchived ? "Unarchived" : "Archived"}!`, {
          hideProgressBar: true,

          style: {
            backgroundColor: "#A0A0A0",
            borderRadius: "8px",
            width: "180px",
            color: "black",
            fontFamily: "Inter",
          },
        });
        onClickArchive();
        onDeSelectAll(e);
      },
      disabled: isArchivingProspect,
    },
    {
      item: isDownloadingProspect ? (
        <Loader width="16" height="16" type="TailSpin" color="#297AF7" />
      ) : (
        <CsvExport
          candidatesData={allProspects}
          csvOptionsList={csvOptionsList}
          setCsvOptionsList={setCsvOptionsList}
        >
          {" "}
          <DownloadIconSvg width="24" height="24" color="#297AF7" />
        </CsvExport>
      ),
      label: "Download",
      isActive: true,
      disabled: isDownloadingProspect,
      // onClick: e => false
    },
  ];
  const secondaryTimeFilters = [
    {
      id: 0,
      name: "All Time",
      value: "",
    },
    ...runInfo.map((item, i) => ({
      id: i + 1,
      name: `${moment(item?.time).format("DD MMM, hh:mm a")} - ${item?.prospectCount == null ? 0 : item?.prospectCount
        } prospect(s)`,
      value: item.runNumber,
    })),
    // {
    //   id: 2,
    //   name: "Today",
    //   value: "0",
    // },
    // {
    //   id: 3,
    //   name: "Yesterday Only",
    //   value: "1",
    // },
    // {
    //   id: 4,
    //   name: "Last 2 days",
    //   value: "2",
    // },
    // {
    //   id: 5,
    //   name: "Added Last Week",
    //   value: "7",
    // },
    // {
    //   id: 6,
    //   name: "Added Last Month",
    //   value: "30",
    // },
  ];

  const params = qs.parse(search.split("?").pop());

  useEffect(() => {
    dispatch(setCurrentBucket(firstBasket));
    const params = qs.parse(search.split("?").pop());
    console.log("params is ", params);
    if (params.sNf) {
      setSearchInputValue(params.sNf);
      setShowSearchInput(true);
    }
    // setTimeout(() => {
    //   push(
    //     `/job/${jId}?fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&fS=title_score&fSO=desc&secS=skill_score&secSO=desc&f=a`
    //   );
    // }, 500);
  }, []);

  useEffect(() => {
    const params = qs.parse(search.split("?").pop());
    if (params?.fF === "ar") {
      setIsArchived(true);
    } else {
      setIsArchived(false);
    }
  }, [search]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [pageNumber, selectedBucket]);
  let { jId, isQuick = false } = useParams();
  if (isQuick === 'false') {
    isQuick = false
  }
  const newParams = {
    ...omit(params, [...commentNotificationList]),
  };
  const handlePaginate = useCallback(
    debounce(
      (page, params) => {
        console.log(params);
        const newParams = {
          ...omit(params, [...commentNotificationList]),
        };
        const p = parseInt(params?.p) || 0;
        setTimeout(() => {
          dispatch(setPageNumber(page));
        });
        // fetchedDebouncedProspects(page);
        push(`/job/${jId}?${qs.stringify({ ...newParams, p: page })}`);
      },
      0,
      []
    ),
    []
  );
  const fetchedDebouncedProspects = useCallback(
    debounce(
      (page) => {
        const params = qs.parse(search.split("?").pop());
        // console.log("params is ", params);
        push(`/job/${jId}?${qs.stringify({ ...newParams, p: page })}`);
      },
      //  dispatch(fetchProspects(jId, { ...params, p: page })),
      0,
      []
    ),
    []
  );
  const handleClickStf = (item) => {
    const newQs = qs.stringify({ ...newParams, rN: item.value, p: 0 });

    history.push(`${pathname}?${newQs}`);
  };

  const activeStf = newParams.sTF
    ? secondaryTimeFilters.filter((item) => item.value == newParams.sTF)[0]
    : {
      id: 1,
      name: "All Time",
      value: "",
    };

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null)
  const prevSearchVal = usePrevious(searchInputValue)

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
      setSearchTimeout(setTimeout(() => sendSearchInput(), 500))
    } else {
      setSearchTimeout(setTimeout(() => sendSearchInput(), 500))
      // clearTimeout(searchTimeout)
    }
  }, [searchInputValue])

  const toggleSearchInput = () => {
    if (showSearchInput === true) {
      setSearchInputValue("");
      sendSearchInput("", search);
    }
    setShowSearchInput(!showSearchInput);
  };
  const sendSearchInput = () => {
    const params = qs.parse(search?.split("?").pop());

    // console.log("params are: ", { params, newParams })
    // alert(JSON.stringify(params))

    // console.log("SEARCH INPUT VLAUE IS: ", { prevSearchVal: newParams.sNf, searchInputValue })
    if (
      (!newParams.sNf?.trim() && searchInputValue?.trim()) ||
      (newParams.sNf?.trim() && !searchInputValue?.trim()) ||
      (newParams.sNf?.trim() && searchInputValue?.trim())
    ) {

      // console.log("PATHNAME IS: ", dpathname)


      const newqs =
        qs.stringify({
          ...newParams,
          sNf: searchInputValue,
          p: 0,
        })




      // const url = `${pathname}?${qs.stringify(searchInputValue.trim() ? {
      //     ...newParams,
      //     sNf: searchInputValue,
      //     p: 0,
      //   } : omit({
      //     ...newParams,
      //     p: 0,
      //   }, 'sNF'))}`

      // console.log("NEW SEARCH URL: ", newqs)


      push(`/job/${jId}?${newqs}`);
    } else {
      push(`/job/${jId}?${qs.stringify({ ...newParams })}`);
    }
  }

  const handleSearchInputChange = (e) => {
    console.log("HANDLE CHANGE: ", e.target.value)
    setSearchInputValue(e.target.value);
    // sendSearchInput(e.target.value.toLowerCase(), search);
  };

  return (
    <div className={`menuContainer ${expanded ? "expanded" : ""}`}>
      <div className="topMenuBar">
        <div className="topMenuIcons-right">
          <div className="checkChevron">
            <Dropdown
              isOpen={selectDropdownOpen}
              toggle={
                isAllSelected || selectedProspectsCount > 0
                  ? handleUnSelectAll
                  : toggleSelectDropDown
              }
            >
              <DropdownToggle color="" size="sm">
                <Input
                  type="checkbox"
                  className="form-control-lg"
                  checked={isAllSelected || selectedProspectsCount > 0}
                />
                <ChevronDown width="16" height="16" stroke="2" />
              </DropdownToggle>
              <DropdownMenu className="selectTopDropDownMenu">
                <DropdownItem onClick={handleSelectAll}>
                  Select All
                </DropdownItem>
                <DropdownItem toggle={false}>
                  Select Top
                  <input
                    name="number"
                    autoFocus={true}
                    style={{
                      width: "64px",
                      height: "30px",
                      margin: "0px 12px 0px 12px",
                    }}
                    placeholder=""
                    min={topMin}
                    max={topMax}
                    type="number"
                    className="topSelection pillInput form-control"
                    value={selectTop}
                    onChange={handleSelectTop}
                  />
                  Prospects
                </DropdownItem>
                <DropdownItem toggle={false}>
                  <Button
                    onClick={handleSelectApply}
                    value={selectTop}
                    color=""
                    size="sm"
                    className="actionbarFilterButton"
                  >
                    Apply
                  </Button>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>

          <div className="topMenuIcons-right-actions-container">
            {selectedProspectsCount > 0 && params.f != "m"
              ? buttonsActionBarLeft.map(
                (
                  { item, label, isActive, wrapInButton, onClick, disabled },
                  i
                ) => {
                  return (
                    <TooltipButtons
                      key={i}
                      tooltipId={i}
                      item={item}
                      label={label}
                      isActive={isActive}
                      wrapInButton={wrapInButton}
                      onClick={onClick}
                      disabled={disabled}
                      className="topMenuIcons-right-actions-buttons"
                    />
                  );
                }
              )
              : selectedProspectsCount > 0 && params.f == "m"
                ? buttonsActionBarLeftMessage.map(
                  (
                    { item, label, isActive, wrapInButton, onClick, disabled },
                    i
                  ) => {
                    return (
                      <TooltipButtons
                        key={i}
                        tooltipId={i}
                        item={item}
                        label={label}
                        isActive={isActive}
                        wrapInButton={wrapInButton}
                        onClick={onClick}
                        disabled={disabled}
                        className="topMenuIcons-right-actions-buttons"
                      />
                    );
                  }
                )
                : ""}
          </div>
          {selectedProspectsCount > 0 ? (
            <div className="selected-prospects-count">
              {selectedProspectsCount} Selected
            </div>
          ) : null}
        </div>

        <div className="topMenuIcons-left">
          {showSearchInput ? (
            <input
              className="primaryInput"
              autoFocus={true}
              placeholder="Search"
              style={{ height: "40px" }}
              value={searchInputValue}
              onChange={(e) => handleSearchInputChange(e)}
            />
          ) : (
            ""
          )}
          <Button
            color="primary"
            className="actionbarFilterButton search"
            size="sm"
            onClick={() => toggleSearchInput()}
          >
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={showSearchInput ? "on" : "off"}
                in={showSearchInput}
                timeout={250}
                addEndListener={(node, done) =>
                  node.addEventListener("transitionend", done, false)
                }
                classNames="rotate"
                unmountOnExit
              >
                {showSearchInput ? (
                  <CrossSvg width="24" height="24" />
                ) : (
                  <SearchSvg width="24" height="24" />
                )}
              </CSSTransition>
            </SwitchTransition>

            {/* {showSearchInput ? (
              <CrossSvg width="24" height="24" />
            ) : (
              <SearchSvg width="24" height="24" />
            )} */}
          </Button>
          <div className="vertical-line"></div>

          <InputGroupButtonDropdown
            addonType="append"
            className="addedfilterButtonDropdown"
            isOpen={dropdownOpen}
            toggle={toggleDropDown}
          >
            <DropdownToggle caret>
              {params.rN
                ? `${moment(runInfo[params.rN]?.time).format(
                  "DD MMM, hh:mm a"
                )} - 
                ${runInfo[params.rN]?.prospectCount == null ? 0 : runInfo[params.rN]?.prospectCount}
                 prospect(s)`
                : "All Time"}
            </DropdownToggle>
            <DropdownMenu>
              {secondaryTimeFilters.map((item, i) => (
                <DropdownItem
                  key={item.id || i}
                  onClick={() => isLoading ? false : handleClickStf(item)}
                  disabled={isLoading}
                >
                  {item.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </InputGroupButtonDropdown>
          {filterCount > 0 && (
            <a className="filterCount" onClick={handleClearFilters}>
              {`Clear ${filterCount}`}
            </a>
          )}
          <Button
            color="primary"
            onClick={handleFilterToggle}
            disabled={isLoading}
            className={`actionbarFilterButton filterOrSort  ${filterCount > 0 ? "selected" : ""
              }`}
            size="sm"
          >
            <FilterSmall width="16" height="16" />
            Filters
          </Button>

          <Button
            color="primary"
            onClick={handleSortToggle}
            disabled={isLoading}
            className={`actionbarFilterButton filterOrSort ${sortSelected ? "selected" : ""
              }`}
            size="sm"
          >
            <Sort width="16" height="16" />
            Sort
          </Button>

          {(!isQuick) && (
            <>
              <div className="vertical-line"></div>

              <ButtonGroup size="sm">
                <Button
                  className={`addedfilterButtonGroup ${activeProspectVariant == 1 ? "active" : ""
                    }`}
                  disabled={search?.includes("rR") || shouldShowHarryPotterMode}
                  onClick={() => dispatch(setActiveProspectVariant(1))}
                >
                  <ProspectDetailMid />
                </Button>
                <Button
                  className={`addedfilterButtonGroup ${activeProspectVariant == 2 ? "active" : ""
                    }`}
                  disabled={shouldShowHarryPotterMode}
                  onClick={() => dispatch(setActiveProspectVariant(2))}
                >
                  <ProspectDetailHigh />
                </Button>
              </ButtonGroup>
              <div className="vertical-line"></div>
              <FindMoreWidget hasSavedProfiles={hasSavedProfiles} saveCount={saveCount} setShowMoreProspect={setShowMoreProspect} />
            </>
          )}
        </div>
      </div>
      <div>
        <div className="revampProspectPagination">
          <div className="paginationStatus">{
            selectedBucket?.count == 0 ? "Showing 0-0 of 0" :
              `Showing ${paginationRange[pageNumber] ? paginationRange[pageNumber] : "1"
              }-${paginationRange[pageNumber + 1] - 1 ?
                paginationRange[pageNumber + 1] - 1 - deleteCount :
                paginationRange[pageNumber] ?
                  selectedBucket?.count - deleteCount || 0 : PER_PAGE_COUNT
              } of ${selectedBucket?.count
                ? selectedBucket?.count - deleteCount || 0
                : PER_PAGE_COUNT
              }`}</div>
          <MyPagination
            disabled={isLoading}
            end={selectedBucket?.count}
            cardPerPage={PER_PAGE_COUNT}
            handleClick={handlePaginate}
            search={search}
            params={params}
            dispatch={dispatch}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    isLoading: state.prospectsView.candidates.isLoading,
    hasSavedProfiles: state.prospectsView.candidates.hasSavedProfiles,
    selectedProspects: state.prospectsView.candidates.selectedProspects,
    pageNum: state.prospectsView.prospectVariant.pageNumber,
    firstBasket: state.prospectsView.candidates.buckets[0],
    firstBucket: state.prospectsView.candidates.firstBucket,
    activeProspectVariant:
      state.prospectsView.prospectVariant.activeProspectVariant,
    // selectedBucket: state.prospectsView.prospectVariant.selectedBucket,
    buckets: state.prospectsView.candidates.buckets,
    currentBucket: state.prospectsView.candidates.currentBucket,
    allProspects: state.prospectsView.candidates.allProspects,
    runInfo: get(state.prospectsView.advFiltersData.data, "runInfo", []),
    harryPotterMode: state.prospectsView.prospectVariant.harryPotterMode,
    shouldShowHarryPotterMode: state.prospectsView.prospectVariant.shouldShowHarryPotterMode,
    deleteCount: state.prospectsView.candidates.deleteCount,
    saveCount: state.prospectsView.candidates.folders.saved,
    jobId: state.prospectsView.candidates.job.id,
  }))(RevampActionBar)
);
