import React from "react";
import '../style.scss'

const ProspectDetailMidSvg = ({ 
    color = "#CDD1D7",
    width = "24",
    height = "24",
    style = {},
    stroke = 1.5, className = '',
    fill = "none",
    selected=false 
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      color={color}
      className={`${className} ${selected ? 'qluSvgFillSelected' : ''}`}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="8" y="5" width="11" height="2" rx="1" className={`qluSvgFill${selected ? 'Selected' : ''}`}
        fill={color}/>
        <rect x="5" y="5" width="2" height="2" rx="1" className={`qluSvgFill${selected ? 'Selected' : ''}`}
        fill={color}/>
        <rect x="5" y="9" width="2" height="2" rx="1" className={`qluSvgFill${selected ? 'Selected' : ''}`}
        fill={color}/>
        <rect x="5" y="13" width="2" height="2" rx="1" className={`qluSvgFill${selected ? 'Selected' : ''}`}
        fill={color}/>
        <rect x="5" y="17" width="2" height="2" rx="1" className={`qluSvgFill${selected ? 'Selected' : ''}`}
        fill={color}/>
        <rect x="8" y="9" width="11" height="2" rx="1" className={`qluSvgFill${selected ? 'Selected' : ''}`}
        fill={color}/>
        <rect x="8" y="13" width="11" height="2" rx="1" className={`qluSvgFill${selected ? 'Selected' : ''}`}
        fill={color}/>
        <rect x="8" y="17" width="11" height="2" rx="1" className={`qluSvgFill${selected ? 'Selected' : ''}`}
        fill={color}/>
    </svg>
  );
};

export default ProspectDetailMidSvg;
