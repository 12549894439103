import React from "react";

const LogoSvg = ({ width = "24", height = "24", style = {} }) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 33 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3272 18.0134C19.4363 18.9043 18.2355 19.4138 16.9783 19.4372C16.9183 19.4383 16.8585 19.4301 16.8006 19.4144L1.3236 15.2315C0.652553 15.0501 0.652551 14.0981 1.32359 13.9168L16.8006 9.73378C16.8585 9.71813 16.9183 9.70992 16.9783 9.71104C18.2355 9.73439 19.4363 10.244 20.3272 11.1348C21.2393 12.047 21.7518 13.2841 21.7518 14.5741C21.7518 15.8641 21.2393 17.1013 20.3272 18.0134Z"
        fill="url(#paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6981 28.4874C17.6981 28.8635 18.0032 29.17 18.3789 29.1525C22.0011 28.9834 25.4404 27.4701 28.016 24.8945C30.7525 22.158 32.2899 18.4466 32.2899 14.5766C32.2899 10.7066 30.7525 6.99515 28.016 4.25867C25.4404 1.68307 22.0011 0.169756 18.3789 0.000716436C18.0032 -0.0168148 17.6981 0.289714 17.6981 0.665791V5.05213C17.6981 5.42821 18.0035 5.73035 18.3784 5.75926C20.475 5.92094 22.454 6.8259 23.9514 8.32328C25.6099 9.98177 26.5416 12.2311 26.5416 14.5766C26.5416 16.922 25.6099 19.1714 23.9514 20.8299C22.454 22.3273 20.475 23.2322 18.3784 23.3939C18.0035 23.4228 17.6981 23.725 17.6981 24.1011L17.6981 28.4874ZM1.04398 18.893C0.248148 18.6307 -0.403102 19.61 0.299362 20.0126L14.064 27.902C14.5454 28.178 15.2315 27.8368 15.2315 27.3214V23.9677C15.2315 23.724 15.0665 23.5137 14.8086 23.4287L1.04398 18.893ZM0.299486 9.23964C-0.402978 9.64227 0.248271 10.6215 1.0441 10.3593L14.8087 5.8235C15.0666 5.73851 15.2317 5.52819 15.2317 5.28449V1.9308C15.2317 1.4154 14.5456 1.07422 14.0641 1.35019L0.299486 9.23964Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="21.8737"
          y1="14.5754"
          x2="-2.15892"
          y2="14.5754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7440F5" />
          <stop offset="1" stopColor="#EF8633" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="35.0383"
          y1="14.6404"
          x2="0.775981"
          y2="14.607"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A17FF" />
          <stop offset="1" stopColor="#4BAFF8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoSvg;
