import React from "react";
import UserIcon from "../../../uiLibrary/SVGs/UserIcon";
import "./styles.scss";

function TableHeaderStats({ label, value = "", subValue }) {
  return (
    <div className="stats">
      <p className="label">{label}</p>
      <div className="stats_values">
        <p className="value">{value}</p>
        {subValue && <p className="subValue">{subValue}</p>}
      </div>
    </div>
  );
}

export default function RoundsHeader({ data = {}, index, icon }) {
  const { messages = [] , prospectCount, sentMessages , repliedMessages , interested} = data;

  const calculatePercentage = (numerator, denominator) => {
    const result = ((numerator / denominator) * 100).toFixed(2);

    if (Number(result)) {
      return `${result}%`;
    }

    return "0%";
  };

  // const sentMessages = messages.filter(({ is_sent }) => !!is_sent);

  // const repliedMessages = messages.filter(({ is_replied }) => !!is_replied);

  // const interested = messages.filter(({ is_interested }) => !!is_interested);

  console.log("icon", icon);

  return (
    <div className="headerContainer">
      <div className="headerContainer_left">
        <div className="headerContainer_heading">
          <span className={"svgRoundIcon"}>{icon}</span>
          <p className="title">Round {index + 1}</p>
        </div>
      </div>
      <div className="tableHeaderContainer_right">
        <TableHeaderStats
          label="Messaged"
          value={sentMessages}
          subValue={calculatePercentage(sentMessages, prospectCount)}
        />
        <TableHeaderStats
          label="Replied"
          value={repliedMessages}
          subValue={calculatePercentage(
            repliedMessages,
            sentMessages
          )}
        />
        <TableHeaderStats
          label="Interested"
          value={interested}
          subValue={calculatePercentage(
            interested,
            repliedMessages
          )}
        />
      </div>
    </div>
  );
}
