import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import AuthLayout from '../../components/AuthLayout'
import './Auth.scss'
import EmailVerificationCallback from './EmailVerificationCallback'
import Enable2FA from './Enable2FA'
import LoginScreen from './LoginScreen'
import ResendVerifyLink from './ResendVerifyLink'
import ResetPassword from './ResetPassword'
import SetNewPassword from './SetNewPassword'
import SignUpScreen from './SignUpScreen'
import SocialLoginCallback from './SocialLoginCallback'
import UserBlockedView from './UserBlockedView'
import Verify2FA from './Verify2FA'
import VerifyEmail from './VerifyEmail'

const Auth = ({ match, isAuthenticated, history, email, location: { pathname } }) => {
  const [removeCTA, setRemoveCTA] = useState(false)
  const exceptionList = [
    '/auth/reset-password/',
    '/auth/reset/password/',
    '/signup/verify/callback/',
    '/signup/verify/callback',
    '/callback',
    '/callback/'
  ]

  useEffect(() => {
    if (isAuthenticated) {
      if (!exceptionList.includes(pathname)) {
        history.push('/')
      }
      if (exceptionList.includes(pathname)) {
        setRemoveCTA(true)
      } else {
        setRemoveCTA(false)
      }
    } else {
      if (['/auth/reset/password/'].includes(pathname)) {
        setRemoveCTA(true)
      } else {
        setRemoveCTA(false)
      }
    }
  }, [isAuthenticated, pathname])
  return (
    <AuthLayout removeHeaderCTA={removeCTA} >
      <Switch>
        <Route path={`${match.url}/signup/verify/callback`} component={EmailVerificationCallback} />
        <Route path={`${match.url}/login`} render={() => <LoginScreen />} />
        <Route path={`${match.url}/signup`} render={() => <SignUpScreen />} />
        <Route path={`${match.url}/reset-password`} render={() => <ResetPassword />} />
        <Route path={`${match.url}/reset/password`} render={() => <SetNewPassword />} />
        <Route path={`${match.url}/resend-link`} render={() => <ResendVerifyLink />} />
        <Route path={`${match.url}/verify-email`} render={() => <VerifyEmail />} />
        <Route path={`${match.url}/enable-2fa`} render={() => <Enable2FA />} />
        <Route path={`${match.url}/verify-2fa`} render={() => <Verify2FA />} />
        <Route path={`${match.url}/callback`} component={SocialLoginCallback} />
        <Route path={`${match.url}/user-blocked-view`} component={UserBlockedView} />
      </Switch>
    </AuthLayout>
  )
}

export default connect(state => ({
  isAuthenticated: state.auth.user.isAuthenticated
}))(Auth)
