/* global chrome */
import "@fontsource/inter";
import "@fontsource/rubik";
import qs from "qs";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {
  Route, Switch
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import useInterval from "use-interval";
import "./App.scss";

import { getAuth } from "firebase/auth";
import { isEmpty } from "lodash";
import { useHistory, withRouter } from "react-router-dom";
import ProtectedRoute from "./HOC/ProtectedRoute";
import { LOGOUT, logout, setUser } from "./actions/auth";
import { getFirebaseApp, manualApiCall, refreshIdToken } from "./utils";
import { Mixpanel } from "./utils/Mixpanel";
import AddingProspects from "./views/AddingProspects";
import AdminRedirect from "./views/AdminRedirect";
import Auth2 from "./views/Auth2";
import ConsentGrant from "./views/Consent/Granted";
import ConsentReject from "./views/Consent/Rejected";
import ConsentGranted from "./views/ConsentGranted";
import DataProtection from "./views/DataProtection";
import DemoChatFlow from "./views/DemoChatFlow";
import DisclosureAndPolicy from "./views/DisclosureAndPolicy";
import FindMore from "./views/FindMore";
import JobSetup from "./views/JobSetup";
import LandingPage from "./views/LandingPage";
import ViewCampaign from "./views/LandingPage/ViewCampaign";
import Optout from "./views/Optout";
import Outbox from "./views/Outbox";
import QuickSetup from "./views/QuickSetup";
import Recruiter from "./views/Recruiter";
import ScoreAndConnect from "./views/ScoreAndConnect";
import Terms from "./views/Terms";
import TestComponent from "./views/TestComponent";
import TribeInviteCallback from "./views/TribeInvite/TribeInviteCallback";
function App({ dispatch, user, location: { search } }) {
  const history = useHistory();
  const themes = {
    clu: {
      "--recommended": "#ffba00",
      "--recommended-secondary": "rgba(255, 195, 33, 0.16)",
      "--primary-1": "#FF8D4E",
      "--primary-2": "#A34D21",
      "--primary-3": "#8A4829",
      "--primary-4": "#402F27",
      "--text-primary-1": "#E0E0E0",
      "--text-primary-2": "#A0A0A0",
      "--text-primary-3": "#6C6C6C",
      "--text-secondary-1": "#121212",
      "--text-highlight-primary": "#FF8D4E",
      "--text-highlight-accent": "#4E95FF",
      "--text-highlight-heart": "#E63B7F",
      "--text-highlight-maybe": "#FFAB2D",
      "--text-highlight-secondary": "#A34D21",
      "--text-negative-primary": "#EF5555",
      "--text-negative-secondary": "#402C2C",
      "--text-positive-primary": "#63B780",
      "--text-positive-secondary": "#29382E",
      "--bg-primary-1": "#121212",
      "--bg-primary-2": "#1D1D1D",
      "--bg-primary-3": "#242424",
      "--bg-primary-4": "#2C2C2C",
      "--bg-primary-5": "#333333",
      "--bg-primary-6": "#383838",
      "--bg-primary-7": "#454545",
      "--bg-highlight-primary": "#FF8D4E",
      "--bg-highlight-hover": "#A34D21",
      "--bg-highlight-disabled": "#8A4829",
      "--bg-heart-primary": "#E63B7F",
      "--bg-heart-secondary": "#331D25",
      "--bg-maybe-primary": "#FFAB2D",
      "--bg-maybe-secondary": "#4D330D",
      "--bg-highlight-secondary": "#402F27",
      "--bg-negative-primary": "#EF5555",
      "--bg-negative-secondary": "#402C2C",
      "--bg-positive-primary": "#63B780",
      "--bg-positive-secondary": "#29382E",
      "--stroke-primary-1": "#1D1D1D",
      "--stroke-primary-2": "#242424",
      "--stroke-primary-3": "#2C2C2C",
      "--stroke-primary-4": "#333333",
      "--stroke-primary-5": "#383838",
      "--stroke-primary-6": "#454545",
      "--stroke-primary-7": "#575757",
      "--stroke-secondary-1": "#6C6C6C",
      "--stroke-secondary-2": "#A0A0A0",
      "--stroke-highlight": "#FF8D4E",
      "--stroke-heart": "#E63B7F",
      "--stroke-maybe": "#FFAB2D",
      "--stroke-positive": "#63B780",
      "--stroke-negative": "#703F3F",
      "--bg-gradient": "linear-gradient(272.57deg, #EF5555 6.72%, #7440F5 92.66%)",
    },
  };

  // const user = JSON.parse(localStorage.getItem("user"))
  const [refreshInterval, setRefreshInterval] = useState(3590000);
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [showMoreProspect, setShowMoreProspect] = useState(false);
  const pParams = qs.parse(search.split("?").pop())
  useEffect(() => {
    const theme = localStorage.getItem("theme") || themes.clu;
    // asyncF();
    localStorage.setItem("token_refreshing", false);
    for (let prop in theme) {
      document.querySelector(":root").style.setProperty(prop, theme[prop]);
    }
    // const user = JSON.parse(localStorage.getItem("user"))
    // // console.log("===========================Mixpanel.identify=============0==============", {user})
    // if(user?.email){
    //   // console.log("===========================Mixpanel.identify===========1================", {user})
    //   Mixpanel.identify(user.email)
    //   Mixpanel.people.set({
    //     email: user.email,
    //     name: user.name
    //   })
    // }
  }, []);

  useEffect(() => {
    if (user?.email) {
      // console.log("===========================Mixpanel.identify===========1================", {user})
      Mixpanel.identify(user.email)
      Mixpanel.people.set({
        email: user.email,
        name: user.name
      })
    }
  }, [user]);

  useInterval(
    () => {
      // console.log('CHECK IF USER IS BLOCKED')
      checkIfUserBlocked();
    },
    user.isAuthenticated ? 5000 : null
  );
  const auth = getAuth(getFirebaseApp());

  useInterval(
    () => {
      handleReAuth()
    },
    // 3590000
    user.isAuthenticated ? refreshInterval : null
  );

  auth.onAuthStateChanged((firebaseUser) => {
    if (!firebaseUser) {
      console.log("SignedOut");
      if (user.isAuthenticated) {
        dispatch(({ type: LOGOUT }));
      }
    } else {
      console.log("SignedIn");
      if (!user.isAuthenticated) {
        const storageUser = JSON.parse(localStorage.getItem("user"));
        if (!isEmpty(storageUser)) {
          dispatch(setUser(storageUser))
        }
      }
    }
  });

  const handleReAuth = async () => {
    try {
      localStorage.setItem("token_refreshing", true)
      let tokens = false
      const user = JSON.parse(localStorage.getItem("user"));
      // console.log("======================user========================", {user})
      console.log("refresh try ", refreshCounter)
      tokens = await refreshIdToken(user?.sw)
      if (tokens?.id_token && tokens?.refresh_token && !isEmpty(user)) {
        localStorage.setItem('user', JSON.stringify({ ...user, token: tokens?.id_token, sw: tokens?.refresh_token }))
        setRefreshInterval(3590000)
        setRefreshCounter(0)
      }
      else if (refreshCounter < 2) {
        setRefreshCounter(prev => prev + 1)
        setRefreshInterval(800)
      } else {
        setRefreshInterval(3590000)
        setRefreshCounter(0)
        dispatch(logout());
      }
    }
    catch (err) {
      console.log("Error: ", err?.message)
    } finally {
      localStorage.setItem("token_refreshing", false)
    }
  }

  const checkIfUserBlocked = async () => {
    if (user.isAuthenticated && !pParams?.aT) {
      const email = JSON.parse(localStorage.getItem("user"))?.email;
      const response = await manualApiCall(`/api/v2/isUserBlocked`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email }),
      });

      if (response?.data?.isBlockedQlu) {
        // alert("You are blocked, contact admin@dnnae.com.")
        dispatch(logout());
        history.push("/auth/user-blocked-view");
      }
    }
    // console.table("rrrrrrrrrrrrrrrrrrrr", response);
  };
  const PortalToast = () =>
    ReactDOM.createPortal(
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      // theme="dark"
      />,
      document.body
    );
  return (
    <>
      <PortalToast />
      <Switch>
        <Route
          path="/test"
          render={(props) => (
            //<AuthWrapper>
            <TestComponent {...props} />
            //</AuthWrapper>
          )}
        />
        {/* chat flow */}
        <Route
          path={`/chat-website/${process.env.REACT_APP_HASH_ROUTE_KEY}`}
          render={(props) => (
            //<AuthWrapper>
            <DemoChatFlow {...props} />
            //</AuthWrapper>
          )}
        />
        <Route
          path="/recruiter/:inviteType/:tribeId/:userId"
          render={(props) => (
            //<AuthWrapper>
            <Recruiter {...props} />
            //</AuthWrapper>
          )}
        />
        <Route
          path="/admin/redirect/:token/:jobId"
          render={(props) => <AdminRedirect {...props} />}
        />
        {/* <Route
            path="/auth/reset/password/"
            exact
            component={ResetPassword}
          /> */}
        <Route path="/optout" exact component={Optout} />
        <Route
          path="/consent/:email/granted"
          exact
          component={ConsentGrant}
        />
        <Route
          path="/consent/:email/rejected"
          exact
          component={ConsentReject}
        />
        <Route path="/terms" exact component={Terms} />
        <Route
          path="/disclosureAndPrivacy"
          exact
          component={DisclosureAndPolicy}
        />
        <Route path="/dataProtection" exact component={DataProtection} />
        {/* <Route path="/auth" component={Auth} /> */}
        <Route path="/auth" component={Auth2} />
        <Route path="/tribe-invite/:tribeId/:userId/:jobId" component={TribeInviteCallback} />
        <ProtectedRoute
          path="/job/addingProspects/:jId"
          render={(props) => (
            //<AuthWrapper>
            <AddingProspects {...props} />
            //</AuthWrapper>
          )}
        />
        <ProtectedRoute
          path="/job/findMore/:jId"
          render={(props) => (
            //<AuthWrapper>
            <FindMore {...props} showMoreProspect={showMoreProspect} setShowMoreProspect={setShowMoreProspect} />
            //</AuthWrapper>
          )}
        />
        <ProtectedRoute
          path="/job/:jId/:isQuick?"
          render={(props) => (
            //<AuthWrapper>
            <ScoreAndConnect {...props} showMoreProspect={showMoreProspect} setShowMoreProspect={setShowMoreProspect} />
            //</AuthWrapper>
          )}
        />
        {/* <ProtectedRoute path="/campaign/:jId" component={HybridCampaign} /> */}
        <ProtectedRoute
          path="/recruiter/consent"
          render={(props) => <ConsentGranted {...props} />}
        />

        <ProtectedRoute
          path="/jobsetup"
          render={(props) => (
            //<AuthWrapper>
            <JobSetup {...props} />
            //</AuthWrapper>
          )}
        />
        <ProtectedRoute
          path="/QuickSetup/:id?/:cc?"
          render={(props) => (
            //<AuthWrapper>
            <QuickSetup {...props} />
            //</AuthWrapper>
          )}
        />
        <ProtectedRoute
          path="/outbox/:jId/:isQuick"
          render={(props) => (
            //<AuthWrapper>
            <Outbox {...props} />
            //</AuthWrapper>
          )}
        />
        <ProtectedRoute
          path="/view-campaign/:id"
          render={(props) => (
            //<AuthWrapper>
            <ViewCampaign {...props} />
            //</AuthWrapper>
          )}
        />
        {/* <Route path="/test" component={TestComponent} /> */}
        {/* <ProtectedRoute
          path="/calendar"
          render={(props) => (
            //<AuthWrapper>
            <CalendarComponent {...props} />
            //</AuthWrapper>
          )}
        /> */}

        <LandingPage />
      </Switch>
    </>
  );
}

export default connect(
  (state) => ({ user: state.auth.user })
)(withRouter(App));
