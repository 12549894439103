import * as ActionTypes from "../../actions/prospectsView";

const initialState = {
  titleScore: {
    active: false,
    value: 70,
  },
  skillScore: {
    active: false,
    value: 70,
  },
  specialtyScore: {
    active: false,
    value: 70,
  },
  experienceScore: {
    active: false,
    value: 70,
  },
  educationScore: {
    active: false,
    value: 70,
  },
  industryScore: {
    active: false,
    value: 70,
  },
};

const tallyFilter = (setFilterCount) => {
  setFilterCount((prev) => prev + 1);
  return true;
};

const scoringFiltersMeta = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_TITLE_SCORE_FILTER:
      return {
        ...state,
        titleScore: {
          ...state.titleScore,
          value: action.payload,
        },
      };
    case ActionTypes.TOGGLE_TITLE_SCORE_FILTER:
      return {
        ...state,
        titleScore: {
          ...state.titleScore,
          active: !state.titleScore.active,
        },
      };

    case ActionTypes.SET_SKILL_SCORE_FILTER:
      return {
        ...state,
        skillScore: {
          ...state.skillScore,
          value: action.payload,
        },
      };
    case ActionTypes.SET_SPECIALTY_SCORE_FILTER:
      return {
        ...state,
        specialtyScore: {
          ...state.specialtyScore,
          value: action.payload,
        },
      };
    case ActionTypes.TOGGLE_SKILL_SCORE_FILTER:
      return {
        ...state,
        skillScore: {
          ...state.skillScore,
          active: !state.skillScore.active,
        },
      };

    case ActionTypes.TOGGLE_SPECIALTY_SCORE_FILTER:
      return {
        ...state,
        specialtyScore: {
          ...state.specialtyScore,
          active: !state.specialtyScore.active,
        },
      };

    case ActionTypes.SET_EXPERIENCE_SCORE_FILTER:
      return {
        ...state,
        experienceScore: {
          ...state.experienceScore,
          value: action.payload,
        },
      };
    case ActionTypes.TOGGLE_EXPERIENCE_SCORE_FILTER:
      return {
        ...state,
        experienceScore: {
          ...state.experienceScore,
          active: !state.experienceScore.active,
        },
      };

    case ActionTypes.SET_EDUCATION_SCORE_FILTER:
      return {
        ...state,
        educationScore: {
          ...state.educationScore,
          value: action.payload,
        },
      };
    case ActionTypes.TOGGLE_EDUCATION_SCORE_FILTER:
      return {
        ...state,
        educationScore: {
          ...state.educationScore,
          active: !state.educationScore.active,
        },
      };

    case ActionTypes.SET_INDUSTRY_SCORE_FILTER:
      return {
        ...state,
        industryScore: {
          ...state.industryScore,
          value: action.payload,
        },
      };
    case ActionTypes.TOGGLE_INDUSTRY_SCORE_FILTER:
      return {
        ...state,
        industryScore: {
          ...state.industryScore,
          active: !state.industryScore.active,
        },
      };

    case ActionTypes.APPLY_ADV_FILTERS_TEMPLATE: {
      const filters = action.payload.template;
      return {
        titleScore: {
          value: filters?.tSF || state.titleScore.value,
          active: filters?.tSF
            ? tallyFilter(action.payload.setFilterCount)
            : state.titleScore.active,
        },
        skillScore: {
          value: filters?.sSF || state.skillScore.value,
          active: filters?.tSF
            ? tallyFilter(action.payload.setFilterCount)
            : state.skillScore.active,
        },
        experienceScore: {
          value: filters?.expSF || state.experienceScore.value,
          active: filters?.expSF
            ? tallyFilter(action.payload.setFilterCount)
            : state.experienceScore.active,
        },
        educationScore: {
          value: filters?.edSF || state.educationScore.value,
          active: filters?.edSF
            ? tallyFilter(action.payload.setFilterCount)
            : state.educationScore.active,
        },
        industryScore: {
          value: filters?.iSF || state.industryScore.value,
          active: filters?.iSF
            ? tallyFilter(action.payload.setFilterCount)
            : state.industryScore.active,
        },
      };
    }

    case ActionTypes.CLEAR_SCORING_FILTERS: {
      return {
        titleScore: {
          active: false,
          value: 70,
        },
        skillScore: {
          active: false,
          value: 70,
        },
        specialtyScore: {
          active: false,
          value: 70,
        },
        experienceScore: {
          active: false,
          value: 70,
        },
        educationScore: {
          active: false,
          value: 70,
        },
        industryScore: {
          active: false,
          value: 70,
        },
        // titleScore: {
        //     ...state.titleScore,
        //     active: false
        // },
        // skillScore:{
        //     ...state.skillScore,
        //     active: false
        // },
        // experienceScore: {
        //     ...state.experienceScore,
        //     active: false
        // },
        // educationScore: {
        //     ...state.educationScore,
        //     active: false
        // },
        // industryScore: {
        //     ...state.industryScore,
        //     active: false
        // },
        // specialtyScore: {
        //   ...state.specialtyScore,
        //   active: false
        // }
      };
    }

    default:
      return state;
  }
};

export default scoringFiltersMeta;
