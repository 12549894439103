import {
  SkillsFAQ1,
  SkillsFAQ2,
  SkillsFAQ3,
  SkillsFAQ4,
  SkillsFAQ5,
  SkillsFAQ6,
  SkillsFAQ7,
  SkillsFAQ8,
  SkillsFAQ9,
  SkillsFAQ10,
  SkillsFAQ11,
  SkillsFAQ12,
  SkillsFAQ13,
  SkillsFAQ14,
  SkillsFAQ15,
  SkillsFAQ16,
  SkillsFAQ17,
} from "../Answers/Skills";

export const SkillsFAQS = [
  {
    heading: "What’s a skill?",
    content: <SkillsFAQ1 />,
  },
  {
    heading:
      "What’s the difference between skills and business specialities and industry?",
    content: <SkillsFAQ2 />,
  },
  {
    heading:
      "What are a few examples of skill versus business speciality and industry?",
    content: <SkillsFAQ3 />,
  },
  {
    heading: "How do you get the skills?",
    content: <SkillsFAQ4 />,
  },
  {
    heading: "How do I see where in the JD a given skill is selected from?",
    content: <SkillsFAQ5 />,
  },
  {
    heading:
      "Some skills were not selected from the JD? How can I add them from the JD?",
    content: <SkillsFAQ6 />,
  },
  {
    heading: "How can I unselect a skill?",
    content: <SkillsFAQ7 />,
  },
  {
    heading: "How many words can a skill be?",
    content: <SkillsFAQ8 />,
  },
  {
    heading: "Should I add a lot of skills?",
    content: <SkillsFAQ9 />,
  },
  {
    heading: "Should I only select the most important skills?",
    content: <SkillsFAQ10 />,
  },
  {
    heading: "Will the tool detect synonyms of a skill?",
    content: <SkillsFAQ11 />,
  },
  {
    heading: "What are best fit profiles skills?",
    content: <SkillsFAQ12 />,
  },
  {
    heading: "How many skills should I select?",
    content: <SkillsFAQ13 />,
  },
  {
    heading: "What is skill importance?",
    content: <SkillsFAQ14 />,
  },
  {
    heading: "How did the tool decide what skill is important?",
    content: <SkillsFAQ15 />,
  },
  {
    heading: "What do the different importance levels mean?",
    content: <SkillsFAQ16 />,
  },
  {
    heading: "How are profiles scored based on skills importance?",
    content: <SkillsFAQ17 />,
  },
];
