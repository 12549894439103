import { get, uniqBy } from 'lodash'
import { v4 } from 'uuid'
import * as ActionTypes from '../../actions/JobSetup'

const initialTitle = {
    initial: true,
    selected: true,
    company_size: [],
    experience: {
        min: 3,
        max: 5
    },
    synonym: [],
    name: "",
    source: [],
    sourceId: [],
    experienceSelected: false,
    results: 0,
    type: [],
    rank: 3,
}

const initialSkill = {
    selected: true,
    importance: {
        Low: false,
        Medium: false,
        High: true
    },
    experience: {
        min: 2,
        max: 5
    },
    min: 0,
    max: 0,
    source: [],
    sourceId: [],
    name: "",
    synonym: [],
    experienceSelected: false,
}

const initialFunctions = {
    selected: true,
    experienceSelected: false,
    experience: {
        min: 2,
        max: 5
    }
}

const initialEducation = {
    selected: true,
    importance: {
        Low: false,
        Medium: false,
        High: true
    }
}


const initialState = {
    totalExperience: [{ min: 3, max: 5 }, { min: 3, max: 5 }],
    titles: {},
    skills: {},
    functions: {},
    educations: {},
    additionalTitles: [],
    additionalSkills: [],
    mainTitle: initialTitle,
    mainTitleResult: 0,
    mainTitleResultLoading: false,
    mainTitleResultLoaded: false,
    isLoaded: false,
    isLoading: false,
    titleInitialising: false,
    titleInitialised: false
}

const jobCriteria = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.ADD_ADDITIONAL_JOB_TITLE: {
            return {
                ...state,
                additionalTitles: [
                    ...state.additionalTitles,
                    {
                        ...initialTitle,
                        ...action.payload
                    }
                ]
            }
        }
        case ActionTypes.REMOVE_ADDITIONAL_JOB_TITLE: {
            return {
                ...state,
                additionalTitles: state.additionalTitles.filter((itm, index) => index !== action.payload)
            }
        }
        case ActionTypes.INIT_ADDITIONAL_JOB_TITLE: {
            return {
                ...state,
                additionalTitles: []
            }
        }
        case ActionTypes.CHANGE_ADDITIONAL_JOB_TITLE: {
            return {
                ...state,
                additionalTitles:
                    state.additionalTitles.map((itm, index) => {
                        if (index === action.payload.index) {
                            const name = get(action, ['payload', 'values', 'name'], get(itm, ['name']))
                            const company_size = get(action, ['payload', 'values', 'company_size'], get(itm, ['company_size']))

                            const [{ min, max }] = company_size
                            return {
                                ...itm,
                                ...action.payload.values,
                                name: name,
                                company_size: company_size,
                                uniqProp: `${name?.toLowerCase()?.trim()}_${min}_${max}`
                            }
                        } else {
                            return itm
                        }
                        // return index === action.payload.index ?  : itm
                    })
            }
        }

        case ActionTypes.ADD_ADDITIONAL_JOB_SKILLS: {
            return {
                ...state,
                additionalSkills: [
                    ...state.additionalSkills,
                    {
                        ...initialSkill,
                        ...action.payload
                    }
                ]
            }
        }
        case ActionTypes.REMOVE_ADDITIONAL_JOB_SKILLS: {
            return {
                ...state,
                additionalSkills: state.additionalSkills.filter((itm, index) => index !== action.payload)
            }
        }
        case ActionTypes.INIT_ADDITIONAL_JOB_SKILLS: {
            return {
                ...state,
                additionalSkills: []
            }
        }
        case ActionTypes.CHANGE_ADDITIONAL_JOB_SKILLS: {
            return {
                ...state,
                additionalSkills: state.additionalSkills.map((itm, index) => index === action.payload.index ? { ...itm, ...action.payload.values } : itm)
            }
        }

        case ActionTypes.INIT_JOB_CRITERIA: {
            return initialState
        }
        case ActionTypes.REMOVE_SELECTED_IDEAL_PROFILE: {
            return {
                ...state,
                titles: Object.keys(state?.titles).reduce((total, key) => {
                  const filteredSourceId = state?.titles[key].sourceId.filter(pid=>action.payload!==pid)
                  return (state?.titles[key].sourceId.includes(action.payload) ? {
                    ...total,
                    // [key]: {
                    //     ...state?.titles[key],
                    //     sourceId: filteredSourceId,
                    //     source: filteredSourceId.length>0 ? state?.titles[key].source :state?.titles[key].source.filter(src=>src!=="idealProfile")
                    // }
                } : {
                  ...total,
                  [key]: state?.titles[key]
                })}, {}),
                skills: Object.keys(state?.skills).reduce((total, key) => {
                  const filteredSourceId = state?.skills[key].sourceId.filter(pid=>action.payload!==pid)
                  return (state?.skills[key].sourceId.includes(action.payload) ? {
                    ...total,
                    // [key]: {
                    //     ...state?.skills[key],
                    //     sourceId: filteredSourceId,
                    //     source: filteredSourceId.length>0 ? state?.skills[key].source :state?.skills[key].source.filter(src=>src!=="idealProfile")
                    // }
                } : {
                  ...total,
                  [key]: state?.skills[key]
                })}, {}),
            }
        }
        case ActionTypes.PREDICT_JOB_TITLE_RESULT: {
            return {
                ...state,
                mainTitleResultLoading: true,
                mainTitleResultLoaded: false,
            }
        }
        case ActionTypes.PREDICT_JOB_TITLE_RESULT_SUCCESS: {
            return {
                ...state,
                mainTitleResult: action.payload,
                mainTitleResultLoading: false,
                mainTitleResultLoaded: true,
            }
        }
        case ActionTypes.PREDICT_JOB_TITLE_RESULT_FAILURE: {
            return {
                ...state,
                mainTitleResult: action.payload,
                mainTitleResultLoading: false,
                mainTitleResultLoaded: false,
            }
        }
        case ActionTypes.PREDICT_GENERIC_JOB_TITLE_RESULT: {
            return {
                ...state,
                mainTitleGenericResultLoading: true,
                mainTitleGenericResultLoaded: false,
            }
        }
        case ActionTypes.PREDICT_GENERIC_JOB_TITLE_RESULT_SUCCESS: {
            return {
                ...state,
                mainTitleGenericResult: action.payload,
                mainTitleGenericResultLoading: false,
                mainTitleGenericResultLoaded: true,
            }
        }
        case ActionTypes.PREDICT_GENERIC_JOB_TITLE_RESULT_FAILURE: {
            return {
                ...state,
                mainTitleGenericResult: action.payload,
                mainTitleGenericResultLoading: false,
                mainTitleGenericResultLoaded: false,
            }
        }
        case ActionTypes.CHANGE_JOB_TOTAL_EXPERIENCE: {
            return {
                ...state,
                totalExperience: action.payload
            }
        }
        case ActionTypes.CHANGE_JOB_TITLE: {

            const name = get(action, ['payload', 'value', 'name'], get(state.titles[action.payload.key], ['name']))
            const company_size = get(action, ['payload', 'value', 'company_size'], get(state.titles[action.payload.key], ['company_size']))
            const [{ min, max }] = company_size

            return {
                ...state,
                titles: {
                    ...state.titles,
                    [action.payload.key]: {
                        ...state.titles[action.payload.key],
                        ...action.payload.value,
                        name: name,
                        company_size: company_size,
                        uniqProp: `${name?.toLowerCase()?.trim()}_${min}_${max}`
                    }
                }
                // Object.keys(state.titles).reduce((prevKeys, nextKey) => {
                //         if(nextKey===action.payload.key){
                //             prevKeys[nextKey] = {
                //                 ...state.titles[nextKey],
                //                 ...action.payload.value
                //             }
                //             return prevKeys
                //         }
                //         else {
                //             prevKeys[nextKey] = state.titles[nextKey]
                //             return prevKeys
                //         }
                // }, {})
            }
        }

        case ActionTypes.CHANGE_MAIN_JOB_TITLE: {
            const company_size = get(action, ['payload', 'company_size'], get(state, ['mainTitle', 'company_size'], [{ min: 200, max: 1000 }]))
            if(company_size){
                const [{ min, max }] = company_size
                return {
                    ...state,
                    mainTitle: {
                        ...state.mainTitle,
                        initial: false,
                        ...action.payload,
                        uniqProp: `${get(action, ['payload', 'name'], get(state, ['mainTitle', 'name']))?.toLowerCase()?.trim()}_${min}_${max}`
                    }
                }
            }
           
        }
        // case ActionTypes.ADD_MAIN_JOB_TITLE: {
        //     return {
        //         ...state,
        //         mainTitle: action.payload
        //     }
        // }
        case ActionTypes.ADD_JOB_TITLE: {
            // console.log("ADD JOB TITLE SHIT")

            const newKey = v4()
            let isReplaced = false
            const payloadCompSizes = get(action, ['payload', 'company_size'], []).reduce((obj, item) => {
                return { ...obj, min: item.min, max: item.max }
            }, { min: null, max: null })
            // && (item.company_size[0].min !== ttlCompSize.min || item.company_size[0].max !== ttlCompSize.max)) || item.name.trim().toLowerCase() !== ttl.name.trim().toLowerCase()


            // console.log("PREVIOUS TITLES asdfsadf: ", state.titles)
            const mainTitle = state.mainTitle

            const newTitles = uniqBy(Object.keys(state.titles).reduce((acc, item) => {
                return [...acc, { ...state.titles[item], uniqProp: `${state.titles[item].name.toLowerCase().trim()}_${state.titles[item].company_size[0].min}_${state.titles[item].company_size[0].max}` }]
            }, []), 'uniqProp')

            console.log("NEW TITLES ARE: ", newTitles)

            console.log("MAIN TITLE: ", mainTitle)


            // let allTitles = [...newTitles, {
            //     ...mainTitle,
            //     uniqProp: `${mainTitle.name.toLowerCase().trim()}_${mainTitle?.company_size?.[0]?.min}_${mainTitle?.company_size?.[0].max}`
            // }]
            let allTitles = newTitles

            console.log("all titles are: ", allTitles)

            let isUpdated = false

            allTitles = allTitles.map(item => {
                if (item.uniqProp === `${action.payload?.name.trim().toLowerCase()}_${payloadCompSizes.min}_${payloadCompSizes.max}`) {
                    return { ...item, selected: true }
                } else {
                    return item
                }
            })




            const checkIfToAdd = !allTitles.filter(item => item.selected).map(item => item.uniqProp).includes(`${action.payload?.name.trim().toLowerCase()}_${payloadCompSizes.min}_${payloadCompSizes.max}`)

            // console.log("CHECK IF TO ADD: ", checkIfToAdd)
            // console.log("NEW TITLES ARE: ", newTitles)


            // const newTitles = Object.keys(state.titles).reduce((prevKeys, nextKey) => {
            //     if (
            //         action.payload?.name.trim().toLowerCase() === state.titles[nextKey].name.trim().toLowerCase() &&
            //         (state.titles[nextKey].company_size[0].min === payloadCompSizes.min && state.titles[nextKey].company_size[0].max === payloadCompSizes.max)
            //     ) {
            //         isReplaced = true
            //         return {
            //             ...prevKeys,
            //             [nextKey]: state.titles[nextKey]
            //         }
            //     }
            //     else {
            //         prevKeys[nextKey] = state.titles[nextKey]
            //         return prevKeys

            //     }
            // }, {})
            // console.log("NEW TITLES: ", newTitles)
            // if (!isReplaced) {
            //     newTitles[newKey] = {
            //         ...initialTitle,
            //         ...action.payload
            //     }
            // }

            // const updatedSelected = `${mainTitle.name.toLowerCase().trim()}_${mainTitle?.company_size?.[0]?.min}_${mainTitle?.company_size?.[0].max}` === `${action.payload?.name.trim().toLowerCase()}_${payloadCompSizes.min}_${payloadCompSizes.max}`
            const ttls = (!isUpdated && checkIfToAdd) ? {
                ...newTitles, [newKey]: {
                    ...initialTitle,
                    ...action.payload,
                    // selected: updatedSelected ? true : action.payload.selected,
                    uniqProp: `${action?.payload?.name?.toLowerCase()?.trim()}_${action.payload?.company_size?.[0]?.min}_${action.payload?.company_size?.[0].max}`
                }
            } : newTitles

            // console.log("ACTION.PAYLOAD.FROMSUGGESTED: ", action?.payload?.fromSuggested)
            const ttlsArray = uniqBy(Object.keys(ttls).map(key => ({
                ...ttls[key], selected: ttls[key]?.selected //action?.payload?.fromSuggested ? false : `${action?.payload?.name?.toLowerCase()?.trim()}_${action.payload?.company_size?.[0]?.min}_${action.payload?.company_size?.[0].max}` === ttls[key].uniqProp ? true : ttls[key]?.selected
            })), 'uniqProp')
            console.log("TITLES FRESH: ", { ttls, ttlsArray })

            const finalTitles = ttlsArray.reduce((obj, item) => {
                return { ...obj, [get(item, ['id'], v4())]: item }
            }, {})

            // console.log("FINAL TITLES ARE: ", finalTitles)

            return {
                ...state,
                titles: finalTitles
            }
        }
        case ActionTypes.REMOVE_JOB_TITLE: {
            const removedTitles = {
                ...state.titles
            }
            delete removedTitles[action.payload]
            return {
                ...state,
                titles: removedTitles
            }
        }
        case ActionTypes.CHANGE_JOB_SKILL: {
            const changededSkills = Object.keys(state.skills).reduce((prevKeys, nextKey) => {
                if ('name' in action.payload.value &&
                    action.payload.value?.name?.toLowerCase() === state.skills[nextKey]?.name?.toLowerCase() &&
                    !(nextKey === action.payload.key)) {
                    return prevKeys
                }
                else {
                    if (nextKey === action.payload.key) {
                        prevKeys[nextKey] = {
                            ...state.skills[nextKey],
                            ...action.payload.value
                        }
                        return prevKeys
                    }
                    else {
                        prevKeys[nextKey] = state.skills[nextKey]
                        return prevKeys
                    }
                }
            }, {})
            return {
                ...state,
                skills: changededSkills
            }
        }
        case ActionTypes.OVERWRITE_JOB_SKILL: {
            const newSkillKey = v4()
            const newSkills = Object.keys(state.skills).reduce((prevKeys, nextKey) => {
                if (action.payload?.name?.trim()?.toLowerCase() === state.skills[nextKey].name?.trim()?.toLowerCase()) {
                    return prevKeys
                }
                else {
                    prevKeys[nextKey] = state.skills[nextKey]
                    return prevKeys

                }
            }, {})
            newSkills[newSkillKey] = {
                ...initialSkill,
                ...action.payload
            }
            return {
                ...state,
                skills: newSkills
            }
        }
        case ActionTypes.ADD_JOB_SKILL: {
            const newSkillKey = v4()
            let isSkillReplaced = false
            const newSkills = Object.keys(state.skills).reduce((prevKeys, nextKey) => {
                if (action.payload?.name?.trim()?.toLowerCase() === state.skills[nextKey].name?.trim()?.toLowerCase()) {
                    isSkillReplaced = true
                    // prevKeys[newSkillKey] = {
                    //     ...initialSkill,
                    //     ...action.payload
                    // }
                    return {
                        ...prevKeys,
                        [nextKey]: {
                            ...state.skills[nextKey],
                            source: action.payload?.source || state.skills[nextKey]?.source || [],
                            sourceId: action.payload?.sourceId || state.skills[nextKey]?.sourceId || []
                        }
                    }
                }
                else {
                    prevKeys[nextKey] = state.skills[nextKey]
                    return prevKeys

                }
            }, {})
            if (!isSkillReplaced && Object.keys(action.payload.importance || {}).filter(key => key === 'High' && action.payload.importance[key]).length > 0) {
                return {
                    ...state,
                    skills: {
                        [newSkillKey]: {
                            ...initialSkill,
                            ...action.payload
                        },
                        ...newSkills
                    }
                }
            }
            if (!isSkillReplaced) {
                newSkills[newSkillKey] = {
                    ...initialSkill,
                    ...action.payload
                }
            }
            return {
                ...state,
                skills: newSkills
            }
        }
        case ActionTypes.REMOVE_JOB_SKILL: {
            const removedSkills = {
                ...state.skills
            }
            delete removedSkills[action.payload]
            return {
                ...state,
                skills: removedSkills
            }
        }
        case ActionTypes.CHANGE_JOB_FUNCTION: {
            const changededFunctions = Object.keys(state.functions).reduce((prevKeys, nextKey) => {
                if ('name' in action.payload.value &&
                    action.payload.value?.name?.toLowerCase() === state.functions[nextKey]?.name?.toLowerCase() &&
                    !(nextKey === action.payload.key)) {
                    return prevKeys
                }
                else {
                    if (nextKey === action.payload.key) {
                        prevKeys[nextKey] = {
                            ...state.functions[nextKey],
                            ...action.payload.value
                        }
                        return prevKeys
                    }
                    else {
                        prevKeys[nextKey] = state.functions[nextKey]
                        return prevKeys
                    }
                }
            }, {})
            return {
                ...state,
                functions: changededFunctions
            }
        }
        case ActionTypes.ADD_JOB_FUNCTION: {
            const newFunctionKey = v4()
            let isFunctionReplaced = false
            const newFunctions = Object.keys(state.functions).reduce((prevKeys, nextKey) => {
                if (action.payload?.name?.trim()?.toLowerCase() === state.functions[nextKey].name?.trim()?.toLowerCase()) {
                    isFunctionReplaced = true
                    prevKeys[newFunctionKey] = {
                        ...initialFunctions,
                        ...action.payload
                    }
                    return prevKeys
                }
                else {
                    prevKeys[nextKey] = state.functions[nextKey]
                    return prevKeys

                }
            }, {})
            if (!isFunctionReplaced) {
                newFunctions[newFunctionKey] = {
                    ...initialFunctions,
                    ...action.payload
                }
            }
            return {
                ...state,
                functions: newFunctions
            }
        }
        case ActionTypes.REMOVE_JOB_FUNCTION: {
            const removedFunctions = {
                ...state.functions
            }
            delete removedFunctions[action.payload]
            return {
                ...state,
                functions: removedFunctions
            }
        }
        case ActionTypes.CHANGE_JOB_EDUCATION: {
            const changedEducations = Object.keys(state.educations).reduce((prevKeys, nextKey) => {
                if ('name' in action.payload.value &&
                    action.payload.value?.name?.toLowerCase() === state.educations[nextKey]?.name?.toLowerCase() &&
                    !(nextKey === action.payload.key)) {
                    return prevKeys
                }
                else {
                    if (nextKey === action.payload.key) {
                        prevKeys[nextKey] = {
                            ...state.educations[nextKey],
                            ...action.payload.value
                        }
                        return prevKeys
                    }
                    else {
                        prevKeys[nextKey] = state.educations[nextKey]
                        return prevKeys
                    }
                }
            }, {})
            return {
                ...state,
                educations: changedEducations
            }
        }
        case ActionTypes.ADD_JOB_EDUCATION: {
            const newEducationKey = v4()
            let isEducationReplaced = false
            const newEducation = Object.keys(state.educations).reduce((prevKeys, nextKey) => {
                if (action.payload?.name === state.educations[nextKey].name) {
                    isEducationReplaced = true
                }
                prevKeys[nextKey] = state.educations[nextKey]
                return prevKeys

            }, {})
            if (!isEducationReplaced) {
                newEducation[newEducationKey] = {
                    ...initialEducation,
                    ...action.payload
                }
            }
            return {
                ...state,
                educations: newEducation
            }
        }
        case ActionTypes.REMOVE_JOB_EDUCATION: {
            const removedEducation = {
                ...state.educations
            }
            delete removedEducation[action.payload]
            return {
                ...state,
                educations: removedEducation
            }
        }
        case ActionTypes.FETCH_TITLE_RESULT: {
            return {
                ...state,
                titleResultLoading: true,
                titleResultLoaded: false
            }
        }
        case ActionTypes.FETCH_TITLE_RESULT_SUCCESS: {
            return {
                ...state,
                titleResultLoading: false,
                titleResultLoaded: true
            }
        }
        case ActionTypes.FETCH_TITLE_RESULT_FAILURE: {
            return {
                ...state,
                titleResultLoading: false,
                titleResultLoaded: false
            }
        }
        case ActionTypes.INITIALISING_JOB_TITLES: {
            return {
                ...state,
                titleInitialising: true,
                titleInitialised: false
            }
        }
        case ActionTypes.INITIALISING_JOB_TITLES_SUCCESS: {
            return {
                ...state,
                titleInitialising: false,
                titleInitialised: true
            }
        }
        case ActionTypes.INITIALISING_JOB_TITLES_FAILURE: {
            return {
                ...state,
                titleInitialising: false,
                titleInitialised: false
            }
        }
        default:
            return state
    }
}

export default jobCriteria
