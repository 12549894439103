import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { clearConsentWindow } from '../../../actions/hybridCampaign'
import { addCampaignRound } from '../../../actions/outbox'
import SecondaryButton from '../../../uiLibrary/SecondaryButton'
import { Mixpanel } from '../../../utils/Mixpanel'
import OutboxRound from './OutboxRound'
import './style.scss'
const OutboxRoundsController = ({
    rounds,
    togglePersonalise,
    channelOptions,
    onTabClick,
    consentWindow,
    dispatch,
    messageTemplates,
}) => {
    // useEffect(() => {
    //     if(consentWindow){
    //         consentWindow.close()
    //         dispatch(clearConsentWindow())
    //     }
    // }, [])

    const handleAddRound = e => {
        dispatch(addCampaignRound({template: messageTemplates.linkedin[rounds.length].message}))
        Mixpanel.track("new round")
    }
    const handleRemoveRound = e => {

    }
    return (
        <div
            className='outboxRoundsContainer'
        >
            {rounds.map((round, index)=>(
                <OutboxRound
                    key={round.id}
                    togglePersonalise={togglePersonalise}
                    channelOptions={channelOptions}
                    onTabClick={onTabClick}
                    round={index}
                    rounds={rounds}
                    messageTemplates={messageTemplates}
                    {...round}
                />
            ))}
            {rounds.length<3 &&
                <SecondaryButton
                    onClick={handleAddRound}
                >
                    New Round
                </SecondaryButton>
            }
        </div>
    )
}

export default connect(state=>({
    rounds: state.outbox.campaigns.rounds,
    consentWindow: state.hybridCampaign.campaignOperations.consentWindow,
}))(OutboxRoundsController)
