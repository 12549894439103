import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { Toast, Tooltip } from 'reactstrap'
import { changeTribeMember } from '../../../../actions/JobSetup'
import { changeCampaignRound, fetchRecruiter, removeCampaignRound, setOutboxView } from '../../../../actions/outbox'
import { selectProspects } from '../../../../actions/prospectsView'
import DropdownComponent from '../../../../uiLibrary/DropdownComponent'
import NumberInput from '../../../../uiLibrary/NumberInput'
import ConnectSvg from '../../../../uiLibrary/SVGs/ConnectSvg'
import CrossSvg from '../../../../uiLibrary/SVGs/CrossSvg'
import InmailSVG from '../../../../uiLibrary/SVGs/InmailSvg'
import MailSVG from '../../../../uiLibrary/SVGs/MailSvg'
import TabButton from '../../../../uiLibrary/TabButton'
import TimeInput from '../../../../uiLibrary/TimeInput'
import { Mixpanel } from '../../../../utils/Mixpanel'
import './style.scss'
const OutboxRound = ({
    userEmail,
    inMailCredits=0,
    recruiterInMailCredits=0,
    emailState,
    emailProvider,
    channelOptions,
    togglePersonalise,
    selectedProspects,
    onTabClick,
    id,
    recipientTab,
    reviewed,
    subject,
    template,
    messageErrors,
    messages,
    messageTemplates,
    round,
    rounds,
    dispatch,
    ...rest
}) => {

    const [isOpen, setIsOpen] = useState(false)
    const [recruiterInMailState, setRecruiterInMailState] = useState(recruiterInMailCredits)
    const [recruiterInterval, setRecruiterInterval] = useState(false)
    const [isGoogleApi, setIsGoogleApi] = useState(false)
    const {
        emailType,
        send_after_days,
        send_after_hours,
        channel,
        sender
    } = recipientTab!=='all' ? 
        messages.filter(({jobprofile_id})=>selectedProspects[recipientTab].jobProfileId===jobprofile_id)?.[0] : 
        rest

    const channelList = recipientTab!=='all' ? rounds.map(rnd=>rnd.messages.filter(({jobprofile_id})=>selectedProspects[recipientTab].jobProfileId===jobprofile_id)?.[0]?.channel) : rounds.map(rnd=>rnd.channel)
    // console.log("====================channelList=========================", channelList)

    const email_address = recipientTab!=='all' ? 
        emailType==='Personal Email' ? selectedProspects[recipientTab]?.primaryEmail || selectedProspects[recipientTab]?.personal_email :
        emailType==='Work Email' ? selectedProspects[recipientTab]?.work_email : 'Email Address Missing' : null
    

    const emailOptions = [
        'Personal Email',
        'Work Email'
    ].filter(opt=>{
        if(recipientTab!=='all' && email_address!=='Email Address Missing'){
            if(opt==='Personal Email'){
                return (selectedProspects[recipientTab]?.primaryEmail || selectedProspects[recipientTab]?.personal_email) && emailType!==opt
            }
            return  selectedProspects[recipientTab]?.work_email &&  emailType!==opt
        }
        return emailType!==opt
    })

    useEffect(()=>{
        if(userEmail){
            setIsGoogleApi(userEmail?.split("@")?.pop()?.toLowerCase()?.includes("gmail") || emailProvider==="GOOGLE")
        }        
    }, [userEmail])

    useEffect(()=>{
        setRecruiterInMailState(recruiterInMailCredits)
        if(recruiterInMailCredits>0 && recruiterInterval){
            clearInterval(recruiterInterval)
            setRecruiterInterval(false)
        }
    }, [recruiterInMailCredits])

    const isRecruiterSignedIn = ()=>{ 
        // console.log("====================isRecruiterSignedIn=========================")
        setRecruiterInMailState(recruiterInMailCredits=>{
            if(recruiterInMailCredits===0){
                dispatch(fetchRecruiter())
            } 
            return recruiterInMailCredits
        })   
    }
  
    const onRecruiterClick = e => {
        // console.log("============onRecruiterClick=============", {recruiterInMailCredits})
        if(!recruiterInterval){
            // console.log("============onRecruiterClick=====recruiterInterval========", {recruiterInterval})
            setRecruiterInterval(setInterval(isRecruiterSignedIn, 5000))
        }
    }
    
    const handleSelectChannel = value => {
        Mixpanel.track("channel")
        const channel = Object.keys(channelOptions).filter(key=>value===`InMail ${inMailCredits} credits` ? key==='inmail' : value===`Recruiter InMail ${recruiterInMailCredits} credits` ? key==='recruiter' : channelOptions[key]?.name===value )?.[0]
        // console.log("=================handleSelectChannel====================", {channel, value})
        if(recipientTab==='all'){

            dispatch(changeCampaignRound({
                id, 
                value:{
                    channel,
                    template: messageTemplates[channel][round].message,
                    subject: messageTemplates[channel][round].subject
                }
            }))
        }
        else{
            dispatch(changeCampaignRound({
                id, 
                value:{
                    messages: messages.map(message=>{
                        if(selectedProspects[recipientTab].jobProfileId===message.jobprofile_id){
                            return {
                                ...message,
                                channel,
                                template: messageTemplates[channel][round].message,
                                subject: messageTemplates[channel][round].subject
                            }
                        }
                        else{
                            return message
                        }
                    })
                }
            }))
        }
    }

    const selectEmailType = type => {
        dispatch(changeCampaignRound({
            id,
            value:{
                messages: messages.map(message=>{
                    if(message.jobprofile_id===selectedProspects[recipientTab].jobProfileId){
                        return{
                            ...message,
                            emailType: type
                        }
                    }
                    return message
                })
            }
        }))
    }

    
    

    const onChangeTime = (e, value, property) => {
        Mixpanel.track("time to send")
        if(recipientTab==='all'){
            dispatch(changeCampaignRound({
                id, 
                value:{
                    [property]: value
                }
            }))
        }
        else{
            dispatch(changeCampaignRound({
                id, 
                value:{
                    messages: messages.map(message=>{
                        if(selectedProspects[recipientTab].jobProfileId===message.jobprofile_id){
                            return {
                                ...message,
                                [property]: value
                            }
                        }
                        else{
                            return message
                        }
                    })
                }
            }))
        }
    }
    const getRoundOptions = key => {
        if(key === 'inmail'){
            return `InMail ${inMailCredits} credits`
        }
        if(key === 'recruiter'){
            return recruiterInMailCredits===0 ? 'recruiter permission flow' : `Recruiter InMail ${recruiterInMailCredits} credits`
        }
        else if(key==='email' && !emailState && isGoogleApi){
            return 'email permission flow'
        }
        else{
            return channelOptions[key]?.name 
        }
    }

    return (
        <div
            className='outboxRoundContainer'
        >
            <div
                className='outboxRoundHeader'
            >
                <span>
                    <span>
                        {channel==='email' ? 
                            <MailSVG
                                selected={true}
                            /> : 
                        ['inmail', 'recruiter'].includes(channel) ? 
                            <InmailSVG
                                selected={true}
                            /> :
                            <ConnectSvg
                                selected={true}
                            />
                        }
                    </span>
                    <h3>
                        {`Round ${round+1}`}
                    </h3>
                    {round>0 &&
                        <button
                            className='outboxRemoveRound'
                            onClick={e=>dispatch(removeCampaignRound(id))}
                        >
                            <CrossSvg
                                width='16'
                                height='16'
                                strokeWidth='3'
                            />
                        </button>}
                </span>
                <span>
                    <TabButton
                        tab={{name: 'Editing for all'}}
                        selected={recipientTab==='all'}
                        onClick={e=>onTabClick('all')}
                    />
                    <TabButton
                        tab={{name: recipientTab==='all'?'Personalize':`Personalize for ${selectedProspects[recipientTab]?.firstName}`}}
                        selected={recipientTab!=='all'}
                        onClick={e=>onTabClick('', messages)}
                    />
                </span>
            </div>
            <div
                className='outboxRoundBody'
            >
                <span>
                    <h4>
                        Channel 
                    </h4>
                    <DropdownComponent
                        onGoogleClick={e=>{
                            Mixpanel.track("Link your mailbox - outbox")
                            dispatch(
                                setOutboxView({
                                    view: "CONSENT",
                                })
                        )}}
                        onRecruiterClick={onRecruiterClick}
                        options={Object.keys(channelOptions).filter( key => ( key !== channel && (
                                (
                                    // inMailCredits<Object.keys(selectedProspects).length ||
                                    key==="email"
                                ) ? isGoogleApi : true) && (
                                    (
                                        // inMailCredits<Object.keys(selectedProspects).length ||
                                        channelList.includes('inmail')
                                    ) ? key !== 'inmail' : true
                                ) && (
                                    (
                                        // recruiterInMailCredits<Object.keys(selectedProspects).length ||
                                        channelList.includes('recruiter')
                                    ) ? key !== 'recruiter' : true
                                ))).map(key=> getRoundOptions(key))}
                        onSelect={handleSelectChannel}
                        disabledOn={v=>['InMail 0 credits'].includes(v)}
                        selected={<span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}
                        >
                            {channelOptions[channel]?.icon}
                            {channel==='inmail' ? `InMail ${inMailCredits} credits` : channel==='recruiter' ? `Recruiter InMail ${recruiterInMailCredits} credits` : channelOptions[channel]?.name}  
                        </span>}
                    />
                </span>
                <span>    
                    <h4>
                        Time to send
                    </h4>
                    {round===0?
                        <h6>
                            When published
                        </h6>:
                        <div>
                            <TimeInput
                                unit='days' 
                                min={1}
                                max={5}
                                value={send_after_days}  
                                // onChange={(e, value)=>onChangeTime(e, value, 'send_after_days')}
                                onIncrement={(e, value)=>value<=5?onChangeTime(e, value, 'send_after_days'):false}
                                onDecrement={(e, value)=>value>=1?onChangeTime(e, value, 'send_after_days'):false}
                            />
                            <TimeInput    
                                unit='hours' 
                                min={0}
                                max={23}
                                value={send_after_hours}
                                // onChange={(e, value)=>onChangeTime(e, value, 'send_after_hours')}
                                onIncrement={(e, value)=>value<=23?onChangeTime(e, value, 'send_after_hours'):false}
                                onDecrement={(e, value)=>value>=0?onChangeTime(e, value, 'send_after_hours'):false}
                            />
                        </div>
                    }
                </span>
            </div>
            <div
                className='outboxRoundBody'
            >
                <span>
                    <h4>
                        From
                    </h4>
                    <DropdownComponent
                        selected='You'
                    />
                </span>
                {channel==='email' && recipientTab!=='all' &&
                    <span>
                        <h4>
                            To
                        </h4>
                        <div
                            style={{
                                flexWrap: 'wrap'
                            }}
                        >
                            <h6
                                className='outboxRoundBodyEmail'
                                id={`emailId${selectedProspects[recipientTab].jobProfileId}${round}`}
                            >
                                {email_address}
                            </h6>
                            <Tooltip
                              placement="bottom"
                              isOpen={isOpen}
                              target={`emailId${selectedProspects[recipientTab].jobProfileId}${round}`}
                              toggle={()=>setIsOpen(prev=>!prev)}
                              fade={false}
                            >
                                {email_address}
                            </Tooltip>
                            {emailType &&
                                <DropdownComponent
                                    options={emailOptions}
                                    selected={emailType}
                                    onSelect={selectEmailType}
                                />  
                            }
                        </div>
                        
                    </span>
                }
            </div>
        </div>
    )
}

export default connect(state=>({
    inMailCredits:  state.hybridCampaign.campaignOperations.inMailCredits,
    recruiterInMailCredits:  state.outbox.campaignOperations.recruiterInMailCredits,
    emailState: state.hybridCampaign.campaignOperations.emailState,
    emailProvider: state.hybridCampaign.campaignOperations.emailProvider,
    selectedProspects: state.prospectsView.candidates.selectedProspects,
    recipientTab: state.outbox.viewController.recipientTab,
    userEmail: state.auth.user.email,
}))(OutboxRound)
