import React, { useState, useRef, useEffect } from "react";
import Loader from "react-loader-spinner";
import DropdownComponent from "../../uiLibrary/DropdownComponent";
import SecondaryButton from "../../uiLibrary/SecondaryButton";
import CalendarHeader from "../../components/CalendarHeader";
import CaretDown from "../../components/svgComponents/CaretDown";

import ProfileDisplayImage from "../../components/ProfileDisplayImage/ProfileDisplayImage";
import Qlu from "../../components/svgComponents/Qlu";
import EventDetails from "./EventDetails";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertModal from "../../components/AlertModal";

import { fetchCalendars } from "../../actions/calendar";
import { fetchCalendarEvents } from "../../actions/calendar";
import { deleteEvent } from "../../actions/calendar";
import { Input } from "reactstrap";

import "./style.scss";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { connect } from "react-redux";
import NewMeeting from "./NewMeeting";
import Header from "../LandingPage/Header";

const moment = require("moment-timezone");

const CalendarComponent = ({ calendar, user, dispatch }) => {
  let [viewState, setViewState] = useState("calendarView");
  let [dayHeaders, setDayHeaders] = useState(false);
  let [calendarView, setCalendarView] = useState("Day");
  let [selectedEvent, setSelectedEvent] = useState(null);
  let [selectedView, setSelectedView] = useState("timeGridDay");
  let [selectedDate, setSelectedDate] = useState();
  let [prevNode, setPrevNode] = useState(null);
  let [allCalendars, setAllCalendars] = useState(true);
  let [isNewMeeting, setIsNewMeeting] = useState(false);
  let [selectedCalendars, setSelectedCalendars] = useState([]);
  let [calendars, setCalendars] = useState([]);
  let [openModel, setOpenModel] = useState(false);
  let [showCalendars, setShowCalendars] = useState(true);
  let todayEvent = [];
  const options = ["Day", "Week", "Month"];
  const calendarRef = useRef();

  if (calendar.events && calendar.events.length == 0) {
    selectedEvent = null;
  }

  useEffect(() => {
    const date = selectedDate ? selectedDate : new Date();
    const { start, end } = getStartEnd(date);
    loadData(start, end);
  }, [!calendar.isUpdating && calendar.isUpdated]);

  const loadData = (start, end) => {
    dispatch(
      fetchCalendarEvents({
        startDate: start,
        endDate: end,
        calendars: calendars,
      })
    );
    dispatch(
      fetchCalendars({
        calendars: calendars,
      })
    );
  };

  if (selectedCalendars.length == 0 && calendar?.calendars) {
    calendar.calendars.map((item) => {
      if (item.selected) {
        selectedCalendars.push(item.id);
      }
    });
    if (calendar.calendars.length == selectedCalendars.length) {
      allCalendars = true;
    } else {
      allCalendars = false;
    }
  }

  const onDeleteClick = () => {
    dispatch(
      deleteEvent(selectedEvent[0].id, selectedEvent[0].organizer.email)
    );
    unselectEvent();
  };

  const getStartEnd = (date) => {
    const startDate = new Date(date);
    const start = formatDate(
      new Date(startDate.setDate(startDate.getDate() - 2))
    );
    const end = new Date(date);

    switch (calendarView) {
      case "Day":
        return {
          start,
          end: formatDate(new Date(end.setDate(end.getDate() + 2))),
        };

      case "Week":
        const weekRange = getWeekRange(date);
        return {
          start: weekRange.firstday,
          end: weekRange.lastday,
        };

      case "Month":
        const monthRange = getMonthRange(date);
        return {
          start: monthRange.firstday,
          end: monthRange.lastday,
        };
    }
  };

  const getSelectedDate = () => {
    return moment(selectedDate ? selectedDate : new Date()).format(
      "YYYY-MM-DD"
    );
  };

  const getMonthRange = (date) => {
    var curr_date = new Date(date);
    var first_day = new Date(curr_date.getFullYear(), curr_date.getMonth(), 1);
    var last_day = new Date(
      curr_date.getFullYear(),
      curr_date.getMonth() + 1,
      0
    );

    return {
      firstday: formatDate(first_day),
      lastday: formatDate(last_day),
    };
  };

  const getWeekRange = (date) => {
    var curr = new Date(date);
    var first = curr.getDate() - curr.getDay();
    var last = first + 7;

    let start = new Date(curr);
    let end = new Date(curr);

    return {
      firstday: formatDate(start.setDate(first)),
      lastday: formatDate(end.setDate(last)),
    };
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const onViewChange = (e) => {
    let calendarApi = calendarRef.current.getApi();
    let value = e;
    let date = selectedDate ? selectedDate : new Date();
    setCalendarView(value);
    let ref = formatDate(new Date(date));
    let start = new Date(ref);
    start = formatDate(new Date(start.setDate(start.getDate() - 2)));
    let end = new Date(ref);
    switch (value) {
      case "Day":
        end = formatDate(new Date(end.setDate(end.getDate() + 3)));
        setSelectedView("timeGridDay");
        calendarApi.changeView("timeGridDay");
        setDayHeaders(false);
        break;
      case "Week":
        const weekRange = getWeekRange(calendarApi.getDate());
        start = weekRange.firstday;
        end = weekRange.lastday;
        setSelectedView("timeGridWeek");
        calendarApi.changeView("timeGridWeek");
        setDayHeaders(true);
        break;
      case "Month":
        const monthRange = getMonthRange(start);
        start = monthRange.firstday;
        end = monthRange.lastday;
        setSelectedView("dayGridMonth");
        calendarApi.changeView("dayGridMonth");
        setDayHeaders(true);
        break;
    }
    loadData(start, end);
  };

  const onToday = () => {
    let todayDate = moment(new Date())._d;
    let calendarApi = calendarRef.current.getApi();
    calendarApi.today();

    if (prevNode != null) {
      if (prevNode.dayEl.classList.contains("highlight-date")) {
        prevNode.dayEl.classList.remove("highlight-date");
      }
      setPrevNode(null);
    }

    let ref = formatDate(new Date(todayDate));
    let start = new Date(ref);
    start = formatDate(new Date(start.setDate(start.getDate() - 2)));
    let end = new Date(ref);
    switch (calendarView) {
      case "Day":
        end = formatDate(new Date(end.setDate(end.getDate() + 3)));
        break;
      case "Week":
        const weekRange = getWeekRange(todayDate);
        start = weekRange.firstday;
        end = weekRange.lastday;
        break;
      case "Month":
        const monthRange = getMonthRange(todayDate);
        start = monthRange.firstday;
        end = monthRange.lastday;
        break;
    }
    loadData(start, end);
    if (
      !selectedDate ||
      selectedDate.toDateString() != new Date(todayDate).toDateString()
    ) {
      unselectEvent();
    }
    setSelectedDate(new Date(todayDate));
  };

  const onPrevMonth = () => {
    let calendarApi = calendarRef.current.getApi();
    let date = calendarApi.getCurrentData().dateProfile.activeRange.start;
    let ref = new Date(date);
    date = new Date(date.setDate(date.getDate() - 1));

    let start = new Date(date);
    start = formatDate(new Date(start.setDate(start.getDate() - 2)));
    let end = new Date(date);

    switch (calendarView) {
      case "Day":
        end = formatDate(new Date(end.setDate(end.getDate() + 3)));
        break;
      case "Week":
        const weekRange = getWeekRange(date);
        start = weekRange.firstday;
        end = weekRange.lastday;
        break;
      case "Month":
        const monthRange = getMonthRange(date);
        start = monthRange.firstday;
        end = monthRange.lastday;
        break;
    }
    loadData(start, end);
    setSelectedDate(ref);
    unselectEvent();
  };

  const onNextMonth = () => {
    let calendarApi = calendarRef.current.getApi();
    let date = calendarApi.getCurrentData().dateProfile.activeRange.end;
    date = new Date(date.setDate(date.getDate() + 1));
    let start = new Date(date);
    start = formatDate(new Date(start.setDate(start.getDate() - 2)));
    let end = new Date(date);

    switch (calendarView) {
      case "Day":
        end = formatDate(new Date(end.setDate(end.getDate() + 3)));
        break;
      case "Week":
        start = date;
        end = formatDate(new Date(end.setDate(end.getDate() + 7)));
        break;
      case "Month":
        const monthRange = getMonthRange(date);
        start = monthRange.firstday;
        end = monthRange.lastday;
        break;
    }
    loadData(start, end);
    setSelectedDate(date);
    unselectEvent();
  };

  const changeDate = (e) => {
    if (prevNode != null) {
      if (prevNode?.dayEl?.classList?.contains("highlight-date")) {
        prevNode?.dayEl?.classList?.remove("highlight-date");
      }
      setPrevNode(e);
    } else {
      setPrevNode(e);
    }
    e?.dayEl?.classList.add("highlight-date");
    let ref = formatDate(new Date(e.date));
    let start = new Date(ref);
    start = formatDate(new Date(start.setDate(start.getDate() - 2)));
    let end = new Date(ref);
    switch (calendarView) {
      case "Day":
        end = formatDate(new Date(end.setDate(end.getDate() + 3)));
        break;
      case "Week":
        const weekRange = getWeekRange(e.date);
        start = weekRange.firstday;
        end = weekRange.lastday;
        break;
      case "Month":
        const monthRange = getMonthRange(e.date);
        start = monthRange.firstday;
        end = monthRange.lastday;
        break;
    }
    loadData(start, end);
    if (!selectedDate || selectedDate.toDateString() != e.date.toDateString()) {
      unselectEvent();
    }
    setSelectedDate(new Date(e.date));
  };

  const renderEventContent = (eventInfo) => {
    let start = moment(eventInfo.event.extendedProps.startMeeting).format(
      "HH:mm"
    );
    let startTime = moment(eventInfo.event.extendedProps.startMeeting).format(
      "HH:mm A"
    );
    let end = moment(eventInfo.event.extendedProps.endMeeting).format("HH:mm");
    let endTime = moment(eventInfo.event.extendedProps.endMeeting).format(
      "HH:mm A"
    );
    let timeDifference = (eventInfo.event.end - eventInfo.event.start) / 60000;

    let title = eventInfo.event.extendedProps.summary;
    let isSelected = false;
    if (selectedEvent && eventInfo.event.id == selectedEvent[0].id) {
      isSelected = true;
    }

    let linkedMailType = "";
    let orgMail = eventInfo.event.extendedProps.organizer.email;
    selectedCalendars.map((item) => {
      if (orgMail == calendar.calendars[item].linkedCalendar) {
        linkedMailType = calendar.calendars[item].linkedCalendarType;
      }
    });
    linkedMailType = linkedMailType == "" ? linkedMailType : "google";

    let attendeeEmail = null;
    if (eventInfo.event.extendedProps.profile) {
      attendeeEmail = eventInfo.event.extendedProps.profile?.full_name;
    } else if (eventInfo.event.extendedProps.attendees) {
      eventInfo.event.extendedProps.attendees.map((item) => {
        if (!item.self) {
          attendeeEmail = item.displayName ? item.displayName : item.email;
        }
      });
    }

    return (
      <>
        {(function () {
          switch (calendarView) {
            default:
            case "Day":
              return (
                <div className={`day ${isSelected ? "selected" : ""}`}>
                  <div>
                    {eventInfo.event.extendedProps?.profile ? <Qlu /> : []}
                    {title}
                    <span className="globalDotStyleSecondary overviewDotStyle">
                      •
                    </span>
                    {start} - {endTime}
                    <span> ( {timeDifference} min) </span>
                    <span className="globalDotStyleSecondary overviewDotStyle">
                      •
                    </span>
                    <ProfileDisplayImage
                      width="24"
                      height="24"
                      src={eventInfo.event.extendedProps.profile?.imageUrl}
                    />
                    {attendeeEmail}
                    <div className="subHeading">
                      {eventInfo.event.extendedProps.primaryEmail}
                    </div>
                  </div>
                  <div className={`calendarBar ${linkedMailType}`}></div>
                </div>
              );
            case "Week":
              return (
                <div className={`week ${isSelected ? "selected" : ""}`}>
                  <div>{title}</div>
                  <div className="flexDisplay">
                    <div>{startTime}</div>
                    <div className={`calendarBar ${linkedMailType}`}></div>
                  </div>
                </div>
              );
            case "Month":
              return (
                <div
                  className={`month flexDisplay ${isSelected ? "selected " : ""
                    }`}
                >
                  <div>
                    {startTime} {title}
                  </div>
                  <div className={`calendarBar ${linkedMailType}`}></div>
                </div>
              );
          }
        })()}
      </>
    );
  };

  const getEventCount = () => {
    let count = null;
    let date = selectedDate ? selectedDate : new Date();
    let endPrefix = "today";

    if (calendarView == "Week") {
      endPrefix = "week";
      count = calendar.events.length;
    } else if (calendarView == "Month") {
      endPrefix = "month";
      count = calendar.events.length;
    } else {
      let now = moment(selectedDate ? selectedDate : new Date()).format(
        "YYYY-MM-DD"
      );
      calendar.events.map((event) => {
        let eventDate = moment(event.startMeeting).format("YYYY-MM-DD");
        if (now == eventDate) {
          todayEvent.push(event);
        }
      });
      count = todayEvent && todayEvent.length > 0 ? todayEvent.length : "no";
    }
    return "You have " + count + " meeting(s) scheduled for " + endPrefix;
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(calendar.events.filter((e) => e.id == clickInfo.event.id));
  };

  const unselectEvent = () => {
    setSelectedEvent(null);
  };

  const onBack = () => {
    setViewState("calendarView");
  };

  const onNewMeeting = () => {
    setIsNewMeeting(true);
  };

  const onContinue = () => {
    setOpenModel(false);
  };

  const onClose = () => {
    toast.dismiss();
    setOpenModel(false);
    toast("Progress discarded.", {
      position: "bottom-center",
      hideProgressBar: true,
      style: {
        background: "#A0A0A0",
        color: "white",
        width: "210px",
        height: "52px",
      },
    });
    setTimeout(() => {
      setIsNewMeeting(false);
    }, 100);
  };

  const onCancelMeeting = () => {
    setOpenModel(true);
  };

  const onSuccessMeeting = () => {
    toast.dismiss();
    toast("Meeting created! Invite sent to the guest.", {
      position: "bottom-center",
      hideProgressBar: true,
      style: {
        background: "#A0A0A0",
        color: "white",
        width: "335px",
        height: "52px",
      },
    });
    setTimeout(() => {
      setIsNewMeeting(false);
    }, 100);
  };

  const onCheckBoxSelect = (e) => {
    let value = parseInt(e.target.id);
    if (!selectedCalendars.includes(value)) {
      selectedCalendars.push(value);
      calendars = [];
      selectedCalendars.map((item) => {
        calendars.push(calendar.calendars[item].linkedCalendar);
      });
      if (calendar.calendars.length == selectedCalendars.length) {
        allCalendars = true;
      } else {
        allCalendars = false;
      }
    } else {
      const index = selectedCalendars.indexOf(value);
      if (index > -1 && selectedCalendars.length > 1) {
        selectedCalendars.splice(index, 1);
        calendars = [];
        selectedCalendars.map((item) => {
          calendars.push(calendar.calendars[item].linkedCalendar);
        });
        if (calendar.calendars.length == selectedCalendars.length) {
          allCalendars = true;
        } else {
          allCalendars = false;
        }
      }
    }

    const date = selectedDate ? selectedDate : new Date();
    const { start, end } = getStartEnd(date);
    setCalendars(JSON.parse(JSON.stringify(calendars)));
    setAllCalendars(allCalendars);
    setSelectedEvent(null);
    loadData(start, end);
  };

  const onToggleCalendars = () => {
    showCalendars = !showCalendars;
    setShowCalendars(showCalendars);
  };

  return (
    <div>
      <Header showAction={false} label='Calender' />
      <div className="calendarComponent">
        {(() => {
          switch (viewState) {
            case "calendarView":
            default:
              return (
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-2 calendarView">
                      <FullCalendar
                        initialView="dayGridMonth"
                        headerToolbar={{
                          left: "title",
                          center: "",
                          right: "prev,next",
                        }}
                        height="auto"
                        contentHeight="200"
                        selectable={true}
                        dayHeaderFormat={{ weekday: "narrow" }}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        dateClick={changeDate}
                        initialDate={selectedDate}
                        views={{
                          dayGridMonth: {
                            titleFormat: { year: "numeric", month: "short" },
                          },
                        }}
                      />
                      <div className="divider"></div>
                      <div className="allEvents">
                        <Input
                          className="checkBox"
                          type="checkbox"
                          checked={allCalendars}
                          onChange={e => setAllCalendars(e.target.checked)}
                        />
                        <span className="allCalendarsLabel">All Calendars</span>
                        <button
                          className="collapseButton"
                          onClick={onToggleCalendars}
                        >
                          <CaretDown
                            style={{
                              transform: showCalendars
                                ? "none"
                                : "rotate(180deg)",
                            }}
                            width="12px"
                            height="6px"
                            color="#A7ABB0"
                          />
                        </button>
                        <div>
                          {showCalendars &&
                            calendar.calendars.map((item, index) => {
                              return (
                                <div
                                  key={item + index}
                                  className="availabilityRow"
                                >
                                  <div>
                                    <Input
                                      className="checkBox"
                                      type="checkbox"
                                      name={item.id}
                                      id={item.id}
                                      checked={selectedCalendars.includes(
                                        item.id
                                      )}
                                      onChange={onCheckBoxSelect}
                                    />
                                    <span className="calendar">
                                      {item.linkedCalendar}
                                    </span>
                                  </div>
                                  <div
                                    className={`calendarBar ${item.linkedCalendarType
                                      ? item.linkedCalendarType
                                      : "google"
                                      }`}
                                  ></div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="col-7">
                      {calendar.isDataAvailable && !calendar.isLoading ? (
                        <div className="eventView">
                          <button className="prevButton" onClick={onPrevMonth}>
                            <CaretDown
                              style={{ transform: "rotate(90deg)" }}
                              width="12px"
                              height="6px"
                              color="#A7ABB0"
                            />
                          </button>
                          <button className="nextButton" onClick={onNextMonth}>
                            <CaretDown
                              style={{ transform: "rotate(270deg)" }}
                              width="12px"
                              height="6px"
                              color="#A7ABB0"
                            />
                          </button>

                          <div className="additionalButtons">
                            <SecondaryButton
                              className="todayButton"
                              onClick={onToday}
                            >
                              Today
                            </SecondaryButton>

                            <div className="viewButton">
                              <DropdownComponent
                                selected={calendarView}
                                options={options}
                                onSelect={onViewChange}
                              ></DropdownComponent>
                            </div>

                            <div className="verticalDivider"></div>

                            <SecondaryButton
                              className="newMeetingButton"
                              onClick={onNewMeeting}
                            >
                              New Meeting
                            </SecondaryButton>
                          </div>
                          <FullCalendar
                            className={calendarView}
                            ref={calendarRef}
                            initialDate={getSelectedDate()}
                            dayHeaders={dayHeaders}
                            initialView={selectedView}
                            headerToolbar={{
                              left: "title",
                              center: "",
                              right: "",
                            }}
                            views={{
                              dayGridMonth: {
                                titleFormat: {
                                  year: "numeric",
                                  month: "short",
                                },
                              },
                              dayGrid: {
                                titleFormat: {
                                  day: "numeric",
                                  year: "numeric",
                                  month: "short",
                                },
                                dayHeaderFormat: {
                                  weekday: "short",
                                },
                              },
                              timeGrid: {
                                titleFormat: {
                                  day: "numeric",
                                  year: "numeric",
                                  month: "short",
                                },
                                dayHeaderFormat: {
                                  weekday: "short",
                                  day: "numeric",
                                  omitCommas: true,
                                },
                              },
                            }}
                            slotLabelFormat={{
                              hour: "2-digit",
                              minute: "2-digit",
                              omitZeroMinute: false,
                              meridiem: "long",
                            }}
                            nowIndicator={true}
                            allDaySlot={false}
                            slotEventOverlap={true}
                            slotLabelInterval="00:30:00"
                            plugins={[
                              dayGridPlugin,
                              timeGridPlugin,
                              interactionPlugin,
                            ]}
                            events={calendar.events}
                            editable={false}
                            selectable={false}
                            selectMirror={false}
                            weekends={true}
                            eventContent={renderEventContent}
                            eventClick={handleEventClick}
                            timeZone={false}
                          />
                        </div>
                      ) : (
                        <div className="loader">
                          <Loader type="TailSpin" />
                        </div>
                      )}
                      <AlertModal
                        toggleSecondary={onClose}
                        togglePrimary={onContinue}
                        secondaryBtnValue="Yes, discard progress"
                        primaryBtnValue="No, continue"
                        modalBodyData="All progress will be discarded"
                        modalHeaderTAg="Are you sure?"
                        modalOpened={openModel}
                      />
                      <ToastContainer />
                    </div>
                    <div className="col-3 eventDetails">
                      {isNewMeeting ? (
                        <NewMeeting
                          selectedCalendar={
                            calendar?.calendars[selectedCalendars[0]]
                              ?.linkedCalendar
                          }
                          onCancelMeeting={onCancelMeeting}
                          onSuccessMeeting={onSuccessMeeting}
                          currentUser={user.user}
                        />
                      ) : selectedEvent == null ? (
                        <div>
                          <div className="headingContainer">
                            <h2 className="eventHeading">Event Details</h2>
                          </div>
                          <div className="contentContainer">
                            <div className="meetingCount">
                              {getEventCount()}
                            </div>
                            <div className="clickEvent">
                              {todayEvent.length > 0
                                ? "Select an event to view details"
                                : ""}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <EventDetails
                          currentUser={user.user}
                          selectedEvent={selectedEvent[0]}
                          onDelete={onDeleteClick}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
          }
        })()}
      </div>
    </div>
  );
};

export default connect((state) => ({
  calendar: state.auth.calendar,
  user: state.auth,
}))(CalendarComponent);
