import React from 'react'
import '../style.scss'
const ThumbFilledSvg = ({
    className='',
    style = {transform: 'rotate(0deg)'},
    selected=false,
    width = '16',
    height = '16',
}) => {
    return (
        <svg 
            className={className}
            style={style} 
            width={width} 
            height={height} 
            viewBox="0 0 16 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg">
            <path 
                className={`qluSvgFill${selected ? 'Selected' : ''}`}
                d="M0 15.2727H2.90909V6.54548H0V15.2727ZM16 7.27275C16 6.47275 15.3455 5.8182 14.5455 5.8182H9.96364L10.6909 2.47275C10.6909 2.40002 10.6909 2.32729 10.6909 2.25457C10.6909 1.96366 10.5455 1.67275 10.4 1.45457L9.6 0.727295L4.8 5.52729C4.50909 5.74548 4.36364 6.10911 4.36364 6.54548V13.8182C4.36364 14.6182 5.01818 15.2727 5.81818 15.2727H12.3636C12.9455 15.2727 13.4545 14.9091 13.6727 14.4L15.8545 9.23636C15.9273 9.0909 15.9273 8.87272 15.9273 8.72727V7.27272L16 7.27275Z" 
            />
        </svg>
    )
}

export default ThumbFilledSvg
