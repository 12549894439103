import React, { useCallback, useEffect } from 'react';
import { useStateRef } from '../../utils/customHooks';
import './DigitInput.scss';

function SingleDigit({
    value = '',
    focus = false,
    error = false
}) {
    return (
        <div className={`single ${focus ? 'focus' : ''} ${error ? 'error' : ''}`}>
            <input type="text" pattern="\d*" maxLength="1"
                value={value} readOnly />
        </div>
    )
}

export default function DigitInput({
    count = 6,
    value = '',
    error = '',
    onChange = () => { }
}) {

    const tempVal = value.toString().split('')
    while (tempVal.length < count) {
        tempVal.push('')
    }

    const [valArr, setValArr, valArrRef] = useStateRef(tempVal)

    const [currIndex, setCurrIndex, currIndexRef] = useStateRef(0)

    const next = () => {
        if (currIndexRef.current < count - 1)
            setCurrIndex(currIndexRef.current + 1)
    }

    const back = () => {
        if (currIndexRef.current > 0)
            setCurrIndex(currIndexRef.current - 1)
    }

    const listener = useCallback((e) => {
        e.preventDefault();
        switch (e.key) {
            case "Enter":
                return
            case 'Backspace':
                setValArr(p => {
                    p[currIndexRef.current] = ''
                    return [...p]
                })
                back()
                return
            case 'Delete':
                setValArr(p => {
                    p[currIndexRef.current] = ''
                    return [...p]
                })
                next()
                return
            case 'ArrowLeft':
                back()
                return
            case 'ArrowRight':
                next()
                return
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                setValArr(p => {
                    p[currIndexRef.current] = e.key
                    return [...p]
                })
                next()
                return
            default:
        }
    }, [currIndex, valArr])

    useEffect(() => {
        window.addEventListener('keydown', listener)
        return () => {
            window.removeEventListener('keydown', listener)
        }
    }, [])

    useEffect(() => {
        onChange(valArr.join(''))
    }, [valArr])


    return (
        <>
            <div className='DigitInput'>
                {
                    valArr.map((v, i) => (
                        <SingleDigit value={v} key={i} focus={i === currIndex} error={error !== ''} />
                    ))
                }

            </div>
            {error !== '' && <p className='error' >{error}</p>}
        </>
    )
}
