import React, { useState, useRef } from 'react'
import { Collapse, Button } from 'reactstrap'
import SecondaryButton from '../../../uiLibrary/SecondaryButton';
import CaretDown from "../../../components/svgComponents/CaretDown";
import CollapsibleComponent from "../../../uiLibrary/RevampProspectPage/CollapsibleComponent";
import { Progress } from 'reactstrap'
import { capitalizeCase, demographicsFormatter, getMaxKey, getYrsAndMos } from '../../../utils';
import { parse } from 'dotenv';
import { useOnScreen } from '../../../utils';
import { union } from 'lodash';

import LinkedInLogo from '../../../img/linkedIn-logo-sm.svg'
import ProfileDisplayImage from '../../../components/ProfileDisplayImage/ProfileDisplayImage'
import './profile.scss'

const moment = require('moment-timezone');
const NA = <a className="notAvailable">Not Available</a>

const Profile = ({ selectedEvent }) => {

    const ref = useRef();

    let [showDetails, setShowDetails] = useState(false);
    const [highSkillsToggle, setHighSkillsToggle] = useState(true);
    const [midSkillsToggle, setMidSkillsToggle] = useState(false);
    const [lowSkillsToggle, setLowSkillsToggle] = useState(false);

    const experiences = selectedEvent?.profile?.experience.map((experience, index) => ({
        ...experience,
        ...(selectedEvent?.profile?.scoring?.candidate_experience_found?.[index] || {})
    }))

    const specialties_found = selectedEvent?.profile?.score?.specialties_found ? Object.keys(selectedEvent?.profile?.score?.specialties_found)?.map(key => ({
        span: selectedEvent?.profile?.score?.specialties_found[key].found_years,
        speciality: key
    })) : []

    let must_haves_skills = selectedEvent?.profile?.score?.skill_importance?.must_haves?.map(title => ({
        title,
        span: getYrsAndMos(selectedEvent?.profile?.score?.candidate_experience_found?.reduce((total, { skills }) => skills && skills.includes(title) ? total + 1 : total, 0.0))
    }))

    let important_skills = selectedEvent?.profile?.score?.skill_importance?.important?.map(title => ({
        title,
        span: getYrsAndMos(selectedEvent?.profile?.score?.candidate_experience_found?.reduce((total, { skills }) => skills && skills.includes(title) ? total + 1 : total, 0.0))
    }))

    let nice_to_haves_skills = selectedEvent?.profile?.score?.skill_importance?.nice_to_haves.map(title => ({
        title,
        span: getYrsAndMos(selectedEvent?.profile?.score?.candidate_experience_found?.reduce((total, { skills }) => skills && skills.includes(title) ? total + 1 : total, 0.0))
    }))

    // const demographicsFormatter = (gender, age, ethnicity) => {
    //     let demographicsText = `${gender ? capitalizeCase(getMaxKey(gender)) : ""}, ${age}, ${ethnicity ? capitalizeCase(getMaxKey(ethnicity)) : ""}`
    //     return [null, 'null', '', undefined, 'undefined'].includes(demographicsText.trim()) ? <p className="noneFound">Not available</p> : <p>{demographicsText}</p>
    // }

    const showHideHighSkills = () => setHighSkillsToggle((prevState) => { reAdjustHeight(); return !prevState })
    const showHideMidSkills = () => setMidSkillsToggle((prevState) => { reAdjustHeight(); return !prevState })
    const showHideLowSkills = () => setLowSkillsToggle((prevState) => { reAdjustHeight(); return !prevState })

    let prospectSkills = selectedEvent?.profile?.skills?.length > 0 ? selectedEvent?.profile?.skills?.map((value) => value.toLowerCase().trim()) : []
    let prospectSkillsProfile = selectedEvent?.profile?.score?.skills_profile?.length > 0 ? selectedEvent?.profile?.score?.skills_profile?.map((value) => value.toLowerCase().trim()) : []

    const allSkills = union(prospectSkills, prospectSkillsProfile)

    let must_haves_missing = []
    let important_missing = []
    let nice_to_haves_missing = []

    if (selectedEvent && selectedEvent.profile && selectedEvent.profile.score && selectedEvent.profile.score.skills_required && Array.isArray(selectedEvent.profile.score.skills_required)) {
        selectedEvent?.profile?.score?.skills_required?.map((skill) => {
            let skillByTag = skill.split(': ');
            if (!allSkills.includes(skillByTag[0])) {
                switch (skillByTag[1].toLowerCase()) {
                    case 'must have':
                        must_haves_missing.push({ title: skillByTag[0], span: 'missing' })
                        break;
                    case 'important':
                        important_missing.push({ title: skillByTag[0], span: 'missing' })
                        break;
                    case 'nice to have':
                        nice_to_haves_missing.push({ title: skillByTag[0], span: 'missing' })
                        break;
                    default:
                }
            }
        })
    }

    const reAdjustHeight = () => {
        setTimeout(() => {
            let height = '1000';
            if (height) {
                ref.current.style.minHeight = `${height}px`;
            }
        })
    }

    const titleScore = ((selectedEvent?.profile?.buck_score || 0) * 100) / 6
    const experienceScore = ((selectedEvent?.profile?.score?.experience_score || 0) * 100) / (selectedEvent?.profile?.score?.experience_total || 1)
    const educationScore = ((selectedEvent?.profile?.score?.education_score || 0) * 100) / (selectedEvent?.profile?.score?.education_total || 1)
    const skillsScore = ((selectedEvent?.profile?.score?.skill_score || 0) * 100) / (selectedEvent?.profile?.score?.skill_total || 1)
    const specialitiesScore = ((selectedEvent?.profile?.score?.speciality_score || 0) * 100) / (selectedEvent?.profile?.score?.speciality_total || 1)
    const companyScore = ((selectedEvent?.profile?.score?.company_score || 0) * 100) / 5

    const onProfileDetails = () => {
        setShowDetails(!showDetails);
    }

    return (
        <div className="profile" ref={ref}>
            {
                selectedEvent ?
                    <div>
                        <div className="paddingBottom">
                            <div className="flexDisplay">
                                <ProfileDisplayImage
                                    width="46"
                                    height="46"
                                    src={selectedEvent?.profile?.imageUrl}
                                />
                                <div>
                                    <div className="heading">
                                        {selectedEvent?.profile?.full_name}
                                        <img
                                            width="20"
                                            height="20"
                                            src={LinkedInLogo}
                                            className="linkedInLogo"
                                        />
                                    </div>
                                    <div className="subHeading">{selectedEvent?.profile?.inviteeEmail}</div>
                                </div>
                            </div>
                        </div>

                        <div className="flexJustifyDisplay marginBottom">
                            <div className="flexDisplay">
                                <div className="tagWidth">
                                    Current Title
                                </div>
                                <div className="marginAuto">
                                    <Progress value={titleScore} />
                                </div>
                            </div>
                            <div className="flexJustifyDisplay">
                                <div className="tagWidth marginLeft">
                                    Experience
                                </div>
                                <div className="marginAuto">
                                    <Progress value={experienceScore} />
                                </div>
                            </div>
                        </div>

                        <div className="flexJustifyDisplay marginBottom">
                            <div className="flexDisplay">
                                <div className="tagWidth">
                                    Education
                                </div>
                                <div className="marginAuto">
                                    <Progress value={educationScore} />
                                </div>
                            </div>
                            <div className="flexDisplay">
                                <div className="tagWidth marginLeft">
                                    Skills
                                </div>
                                <div className="marginAuto">
                                    <Progress value={skillsScore} />
                                </div>
                            </div>
                        </div>

                        <div className="flexJustifyDisplay marginBottom">
                            <div className="flexDisplay">
                                <div className="tagWidth">
                                    Specialities
                                </div>
                                <div className="marginAuto">
                                    <Progress value={specialitiesScore} />
                                </div>
                            </div>
                            <div className="flexDisplay">
                                <div className="tagWidth marginLeft">
                                    Company
                                </div>
                                <div className="marginAuto">
                                    <Progress value={companyScore} />
                                </div>
                            </div>
                        </div>

                        <div className="flexJustifyDisplay paddingTop detailsContainer">
                            <SecondaryButton className="profileBtn" onClick={onProfileDetails}>
                                {showDetails ? "Hide detailed profile" : "View detailed profile"}
                                <CaretDown style={{ transform: showDetails ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                    width="10px"
                                    height="6px"
                                    color="#A7ABB0" />
                            </SecondaryButton>
                        </div>

                        {
                            showDetails ?
                                <div className='detailPanels'>
                                    <div className="detailsContainer">
                                        <div className="heading">About</div>
                                        {
                                            selectedEvent?.profile?.summary && selectedEvent?.profile?.summary.length > 0 ?
                                                <div className="content">
                                                    {selectedEvent?.profile?.summary}
                                                </div> : <div className="noneFound content">Not available</div>
                                        }
                                    </div>

                                    <div className="detailsContainer">
                                        <div className="heading">Experience</div>
                                        <div className="experiences">
                                            {
                                                experiences?.map((experience, index) => (
                                                    <div className="single-experience content">

                                                        <div className="experience-date">
                                                            {/* <p>4yrs</p> */}
                                                            <p className="duration">{experience.duration}</p>
                                                            <p>{moment(`${experience?.start?.split('-')[1]}-${experience?.start?.split('-')[0]}`).format('MMM YY')} - {experience?.end?.length > 0 ? moment(`${experience?.end?.split('-')[1]}-${experience?.end?.split('-')[0]}`).format('MMM YY') : 'Now'}</p>
                                                        </div>
                                                        <div className={index == selectedEvent?.profile?.experience.length - 1 ? "experience-info noBorder" : "experience-info"}>

                                                            <p className="title_company"><span className="titleTablets">{experience.company_name}</span></p>
                                                            <p className="title_exp">{experience.title}</p>
                                                            <p className="title_location">{experience.location}</p>
                                                            {
                                                                experience?.skills?.length > 0 ?
                                                                    <>
                                                                        <div className="experience-skills">
                                                                            <p>Skills</p>
                                                                            <div className="tablets">
                                                                                {
                                                                                    experience?.skills?.map((skill) => (
                                                                                        <p>{skill}</p>
                                                                                    ))
                                                                                }
                                                                            </div>


                                                                        </div>
                                                                    </> : <div className="experience-skills"></div>
                                                            }
                                                            {
                                                                experience?.specialties?.length > 0 ?
                                                                    <>
                                                                        <div className="experience-specialities">

                                                                            <p>Business</p>
                                                                            <div className="tablets">
                                                                                {
                                                                                    experience?.specialties?.map((speciality) => (
                                                                                        <p>{speciality}</p>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>

                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                    <div className="detailsContainer">
                                        <div className="heading">Relevant Experience Summary</div>
                                        {
                                            selectedEvent?.profile?.score?.experience_found?.length > 0 ?
                                                selectedEvent?.profile?.score?.experience_found?.map((exp, idx) => (
                                                    <div key={idx} className="relevant-summary content">
                                                        <p>{getYrsAndMos(exp.span)}</p>
                                                        <p className="title">{exp.title} @ <span className="titleTablets">{exp.company}</span></p>
                                                    </div>
                                                ))
                                                :
                                                <div className="relevant-summary">
                                                    <div className="title noneFound content">Not available</div>
                                                </div>
                                        }
                                    </div>

                                    <div className="detailsContainer flexJustifyDisplay">
                                        <div>
                                            <div className="heading">Total Experience</div>
                                            <div className="content">{getYrsAndMos(selectedEvent?.profile?.score?.total_experience_span)}</div>
                                        </div>

                                        <div className="functionalExperience">
                                            <div className="heading">Functional Experience</div>
                                            {
                                                specialties_found?.length > 0 ?
                                                    <div className="tablets">
                                                        {
                                                            specialties_found.map(({ span, speciality }) => (
                                                                <div className='flexDisplay'>
                                                                    <p>{speciality}</p>
                                                                    <span className="globalDotStyle">•</span>
                                                                    <div className="years">{getYrsAndMos(span)}</div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    :
                                                    <div className="noneFound content">Not available</div>
                                            }
                                        </div>
                                    </div>

                                    <div className="detailsContainer">
                                        <div className="heading">Business <span className="globalDotStyle">•</span> <span>{specialties_found?.length} found</span> </div>
                                        {
                                            specialties_found?.length > 0 ?
                                                <div className="tablets">
                                                    {
                                                        specialties_found?.map(({ speciality, span }) => (
                                                            <p>
                                                                <span>{speciality}</span> <span className="duration"><span className="globalDotStyle">•</span> {getYrsAndMos(span)}</span>
                                                            </p>
                                                        ))
                                                    }
                                                </div>
                                                : ''
                                        }
                                    </div>

                                    <div className="detailsContainer">
                                        <div className="heading">Skills</div>
                                        <div className="subHeading">
                                            <p>High Importance <span className="profileDotStyle">•</span> <span>{must_haves_skills?.length} found</span> {must_haves_missing?.length > 0 ? <span className="missing"><span className="profileDotStyle">•</span> <span>{must_haves_missing?.length} missing</span></span> : ''}
                                                {must_haves_skills?.length > 0 || must_haves_missing?.length > 0 ?
                                                    <Button color="link" onClick={showHideHighSkills}>
                                                        <CaretDown style={{ marginLeft: "8px", transform: highSkillsToggle ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                                            width="10px"
                                                            height="6px"
                                                            color="#A7ABB0" />
                                                    </Button>
                                                    : ''}
                                            </p>
                                            <CollapsibleComponent isOpen={highSkillsToggle} availableSkills={must_haves_skills} missingSkills={must_haves_missing} />
                                        </div>
                                        <div className="subHeading">
                                            <p>Medium Importance <span className="profileDotStyle">•</span> <span>{important_skills?.length} found</span> {important_missing?.length > 0 ? <span className="missing"><span className="profileDotStyle">•</span> <span>{important_missing?.length} missing</span></span> : ''}
                                                {important_skills?.length > 0 || important_missing?.length > 0 ?
                                                    <Button color="link" onClick={showHideMidSkills}>
                                                        <CaretDown style={{ marginLeft: "8px", transform: midSkillsToggle ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                                            width="10px"
                                                            height="6px"
                                                            color="#A7ABB0" />
                                                    </Button>
                                                    : ''}
                                            </p>
                                            <CollapsibleComponent isOpen={midSkillsToggle} availableSkills={important_skills} missingSkills={important_missing} />
                                        </div>
                                        <div className="subHeading">
                                            <p>Low Importance <span className="profileDotStyle">•</span> <span>{nice_to_haves_skills?.length} found</span> {nice_to_haves_missing?.length > 0 ? <span className="missing"><span className="profileDotStyle">•</span> <span>{nice_to_haves_missing?.length} missing</span></span> : ''}
                                                {nice_to_haves_skills?.length > 0 || nice_to_haves_missing?.length > 0 ?
                                                    <Button color="link" onClick={showHideLowSkills}>
                                                        <CaretDown style={{ marginLeft: "8px", transform: lowSkillsToggle ? 'rotate(180deg)' : 'rotate(0deg)' }}
                                                            width="10px"
                                                            height="6px"
                                                            color="#A7ABB0" />
                                                    </Button>
                                                    : ''}
                                            </p>
                                            <CollapsibleComponent isOpen={lowSkillsToggle} availableSkills={nice_to_haves_skills} missingSkills={nice_to_haves_missing} />
                                        </div>
                                    </div>

                                    <div className="detailsContainer">
                                        <div className="heading">Education</div>
                                        {selectedEvent?.profile?.education?.map(({ degreeName, fieldOfStudy, schoolName }, id) => (
                                            <div className="content" key={id} >{`${degreeName} in ${fieldOfStudy} from ${schoolName}`}</div>
                                        ))}
                                    </div>

                                    <div className="detailsContainer flexJustifyDisplay">
                                        <div>
                                            <div className="heading">Visa Status</div>
                                            <div className="content">{selectedEvent?.profile?.visa_status}</div>
                                        </div>

                                        <div>
                                            <div className="heading">Demographics</div>
                                            <div className="content">{demographicsFormatter( selectedEvent?.profile?.age, selectedEvent?.profile?.gender, selectedEvent?.profile?.ethnicity)}</div>
                                        </div>
                                    </div>

                                </div>
                                :
                                []
                        }


                    </div>
                    :
                    []
            }
        </div >
    )
}

export default Profile