import React, {useState} from "react";
import "./IdealProfileViewCard.scss";
import CloseButton from "../CloseButton/CloseButton";
import ProfileDisplayImage from "../ProfileDisplayImage/ProfileDisplayImage";
import { Tooltip } from "reactstrap";
import Loading from "../../uiLibrary/Loading";

const IdealProfileViewCard = ({ profileImage, profileName, profileHeadline, publicIdentifier, onClick, onCloseDisabled=false, isLoading=false }) => {

  const [isOpen, setIsOpen] = useState(false)
  console.log("pffff",profileImage)
  const handleOnclick = () => {
    onClick(publicIdentifier)
  }

  return (
    <div className="idealProfileView">
      <ProfileDisplayImage src={profileImage} width="46" height="46" />
      <div>
        <h3>{profileName}</h3>
        {isLoading ?
          <Loading
            width={16}
            height={16}
          /> :
          <>
            <p
              id={`${profileName}${publicIdentifier}`.replace(/[^a-zA-Z0-9]+/g, '')}
            >{profileHeadline}</p>
            <Tooltip
              placement="bottom"
              isOpen={isOpen}
              target={`${profileName}${publicIdentifier}`.replace(/[^a-zA-Z0-9]+/g, '') }
              toggle={()=>setIsOpen(prev=>!prev)}
              fade={false}
            >
              {profileHeadline}
            </Tooltip>
            </>
          }
      </div>
      <div className="idealProfileViewCloseBtn">
        <CloseButton disabled={onCloseDisabled} onClick={handleOnclick} strokeWidth="3" width="17px" height="17px"/>
      </div>
    </div>
  );
};

export default IdealProfileViewCard;
