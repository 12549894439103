import React from 'react'
import FindMorePillCards from '../components/FindMorePillCards'
import FindMoreSizePillCards from '../components/FindMoreSizePillCards'
import "./style.scss"
const FindMoreTitles = ({
    jobTitles=[],
    profileTitles=[],
    newTitles=[],
    onTitlesChange,
    onAddTitle,
    onRemoveTitle
}) => {
    return (
        <div className='findMoreTitles'>
            <FindMorePillCards
                heading="Previously selected titles"
                pills={[...new Set(jobTitles)]}
                onChange={(e, index, payload )=>onTitlesChange('jobTitles', index, payload)}
            />
            <FindMoreSizePillCards
                heading="New titles with company size"
                duplicateErrorMsg="Title is already added!"
                pills={profileTitles}
                newPills={newTitles}
                onChange={(type, index, payload )=>onTitlesChange(type, index, payload)}
                onAdd={payload=>onAddTitle('newTitles', 0, {...payload, company_size: jobTitles?.[0]?.company_size || [{min:1, max:10001}]})}
                onRemove={(type, index)=>onRemoveTitle(type, index, {})}
                addMoreDisabled={value=>{
                    console.log("jobTitles",jobTitles)
                    console.log("newTitles",newTitles)
                    return jobTitles.some(({name})=>name?.trim()?.toLowerCase()===value?.trim()?.toLowerCase())||newTitles.some(({name})=>name?.trim()?.toLowerCase()===value?.trim()?.toLowerCase())}
                }
            />
        </div>
    )
}

export default FindMoreTitles