import React, { useState } from "react";
import "./EditFlowTabs.scss";
import { Nav, NavItem } from "reactstrap";
import CaretWideRightSvg from "../SVGs/CaretWideRight";
import RectangleSvg from "../../components/svgComponents/RectangleSvg";
import CaretLeft from "../../components/svgComponents/CaretLeft";

const NavProfileItemCustom = ({
  onClick,
  name,
  count,
  id,
  isActive = false,
  tabType,
  onClickMsg,
  showCaret
}) => {
  return (
    <NavItem>
      {!tabType && id === 1 ? (

        <React.Fragment>
          <button onClick={onClick} className="editFlowBackTabsButton">
            <p className="tabsNameCount">
              <CaretLeft />
            </p>
          </button>
          <RectangleSvg style={{ marginLeft: "16" }} />
        </React.Fragment>
      ) : (
          ""
        )}
      <button
        className={isActive ? "editFlowTabsButtonActive" : "editFlowTabsButton"}
        onClick={tabType ? onClick : onClickMsg}
      >
        <div className="tabsNameCount">
          <p>{name}</p>
          <p>{count}</p>
        </div>
      </button>
      {showCaret ? (
        <CaretWideRightSvg width="8" height="26" className="caretWideRight" style={{ marginLeft: "21" }} />
      ) : tabType && id === 1 ? (
        <RectangleSvg style={{ marginLeft: "16" }} />
      ) : (
            ""
          )}
      {id !== 3 && !tabType ? <CaretWideRightSvg style={{ marginLeft: "21" }} /> : ""}
    </NavItem>
  );
};

export default NavProfileItemCustom;
