// import Head from 'next/head'
// import Link from 'next/link'
// import Footer from '../component/Footer'
// import Header from '../component/Header'
// import style from '../styles/Terms.module.scss'
import React from 'react'
import './style.scss'

const Terms = () => {

  return (
    <div className={"termsContainer"}>

      <div className={"termsSection"}>
        <h3>
          Terms of Service
        </h3>
        <p>
          Thank you for your interest in QLU.ai. These Terms of Service are a legally binding agreement between QLU.ai (“QLU.ai”, “the Company”, “we” or “us”) and between you (“user” or “you”) and constitute legal basis for the download, installation, access and general use you (either an individual or entity) make from the QLU.ai browser extension (“Product” or “Plugin”).
        </p>
        <p>
          The Product, QLU.ai website (QLU.ai) (“Site”), including any feature, functionality, data and content therein will be herein referred to as the “Services”.
        </p>
        <p>
          By accessing, using, downloading or installing our Services you acknowledge that you have read these Terms of Service and our Privacy Policy which is incorporated herein by reference, as may be amended from time to time (collectively “the Terms”). These Terms shall govern any and all kind of use and features offered via the Services as may become available from to time to time. You agree to be bound by these Terms and to fully comply with them.
        </p>
        <p>
          If you do not agree to any of the Terms you should immediately stop using the Services. In this case, you may not download, copy, access or install the Product or use any of our Services in any manner whatsoever.
        </p>
        <h3>
          The product and services
        </h3>
        <p>
          Our Services are designed to help users and vendors to find and verify people or businesses they wish to interact with. By using our Product, we allow you to receive insights on relevant Business Profiles (as defined in our privacy policy) when using top social network websites (“Platforms”). The information provided by QLU.ai is not directly created by QLU.ai, but rather is retrieved from the web, public sources or from the contribution of relevant data from other users and business partners.
        </p>
        <p>
          EMAIL SERVICE - Optional
        </p>
        <p>
          If you wish to use the Email Service, we will ask you to grant us access to your email account using the applicable settings in your account or through the Plugin.
        </p>
        <p>
          By granting us access to your email account, you expressly request and authorize the Service to automatically login to the access token provided by your email account, for the purpose of receiving the Email Service will allow you to gain access to additional information on individuals interacting with you via your email account, which will assist you with authenticating and verifying the identity of these individuals.
        </p>
        <p>
          The Service may also update the QLU.ai Database from time to time.
        </p>
        <p>
          The Company maintains a Privacy Policy describing its collection, retention, and use of the data about the users of the Service. The Policy can be accessed at
          {" "}<a
            href={"/disclosureAndPrivacy"}
          ><a>
              https://recruiting.qlu.ai/disclosureAndPrivacy
          </a></a>{" "} and is incorporated herein by reference.
        </p>
        <h3>
          Eligibility
        </h3>
        <p>
          Any use or access by anyone under the age of 13 is prohibited. By accepting the Terms, you declare that you are at least 13 years or older and that you have the legal capacity to enter this agreement, including consent of your parent or guardian (where applicable) to use the Services.
        </p>
        <h3>
          Changes to terms of service
        </h3>
        <p>
          We reserve the right (but we are under no obligation) to modify, correct, amend, enhance, improve, make any other changes to, suspend or discontinue, temporarily or permanently the Product, Services or any portion of which (the “Changes”) with or without notice with no liability, at any time and for any reason, including without limitation any Changes which may be done automatically for the purpose of improving, enhancing or de-bugging versions of the Product or aspects of the Services. We will notify you of any material change via the Site or Services (including without limitation by sending you an email notification) by any other form prior to those material changes becoming effective. Otherwise, any other, non-material change, will be effective upon the “last updated” date stated at the top of these Terms.
        </p>
        <p>
          Your continued use of the Product or Services, following any such revisions, constitutes your complete and irrevocable acceptance of such Changes. If you do not agree with the new/modified Terms, your sole remedy is to discontinue using the Product and the Services and cancel your registration. Each time you use a QLU.ai Product, you reaffirm your acceptance of the then-current Terms of Service. If you do not wish to be bound by these Terms, you may discontinue using QLU.ai Services.
        </p>
        <h3>
          Creating an account
        </h3>
        <p>
          In order to fully use the Services, you must register and create an account. Creating your account can be done by providing specific details (e.g. full name; email address; etc.) through the Site.
        </p>
        <p>
          To learn more about our data collection practices and the specific types of data we may collect, use and disclose, please read our Privacy Policy which is incorporated in these Terms by reference.
        </p>
        <p>
          You agree to keep your account credential secret and secure. You also agree to inform us immediately of any unauthorized use of your account. By accepting the Terms, you declare that you are responsible for all activities taken under your account. Once you create an account, you will automatically join to our mailing list. You can choose to remove your email address from that mailing list by choosing the “unsubscribe” link at the bottom of any email communication we send to you.
        </p>
        <p>
          We may cancel your access to the Services and terminate your account, at our sole consideration, at any time and for any reason, with or without notice to you. Upon any termination, discontinuation or cancellation of Services or your account, all provisions of these Terms which by their nature should survive will survive, including, without limitation, licenses and ownership provisions, warranty disclaimers, limitations of liability, indemnity, and dispute resolution provisions.
        </p>
        <h3>
          Payment and fees
        </h3>
        <p>
          In order to enjoy the full scale of the Services that we offer, you will be required to pay the applicable fees assessed to your account for the purpose of its use. We may offer you from time to time (but not obliged to), at our sole discretion, a version of limited tool set for free. In addition, we may offer you free extra credits when you refer other users to our Product through our Services. Such free tools or credits will be offered to you at our sole discretion and only as a gesture of good will and you acknowledge that we will not have any legal or commercial obligation to grant you such free credits.
        </p>
        <p>
          When using our Services through any of the Platforms which our Product may operate on, you acknowledge and agree that you (and not QLU.ai) are obliged to comply with the applicable terms of use and all associated policies and guidelines of such Platforms. If for any reason:
        </p>
        <p>
          (i) QLU.ai will not be able to provide you its Services through a specific Platform; or
        </p>
        <p>
          (ii) any Platform’s terms of use shall require that you cease using our Product, deny your access to our Product when using the Platform or terminate your Platform’s account, QLU.ai will not have any liability whatsoever and will not have any legal or commercial obligation to refund any paid fees made by you in connection with the Services. However, QLU.ai, at its sole discretion, may offer you other alternatives to use its Product (e.g. through other Platforms).
        </p>
        <p>
          In order to download and use our Product, you need to install it via Chrome Web Store. You acknowledge and agree that QLU.ai cannot be liable to the functionality and availability of the Chrome Web Store. You acknowledge that if our Product will not be available to download, temporarily or permanently, for any reason, from the Chrome Web Store, QLU.ai will not have any liability whatsoever and will not have legal or commercial obligation to refund any paid fees made by you in connection with the Services.
        </p>
        <p>
          Please note that we maintain no refund or cancellation policy of any paid fees.
        </p>
        <h3>
          Intellectual property and license
        </h3>
        <p>
          Subject to the terms and conditions of these Terms, we hereby grant you a personal, limited, non-exclusive, non-transferable, non-assignable, fully revocable license to download, install the Product and use the Services.
        </p>
        <p>
          Except as provided in this license, you may not
        </p>
        <p>
          (i) copy, distribute, modify, translate, reverse engineer, decompile, disassemble, or create derivative works based on the Product or Services;
        </p>
        <p>
          (ii) Access to data not intended for you, such as logging into a server or an account which you are not authorized to access;
        </p>
        <p>
          (iii) Interfere with the Product’s or Services’ operation (or any portion of them) in any manner, including, without limitation, by means of submitting a virus or malicious code of any type;
        </p>
        <p>
          (iv) Delete or modify any attributions, legal notices or other proprietary designations or labels on the Product or Services, or on any third party material contained or otherwise available therein; or
        </p>
        <p>
          (v) use any data in an abusive or illegal manner. Any right that is not explicitly provided to you under these Terms is expressly reserved by us.
        </p>
        <p>
          As between you and us, we are the sole owners of the Product and the Services, including without limitation, all copyrights, patents, patent applications or other inventions, trademarks, trade secrets, databases and other intellectual property rights thereto, including all titles and intellectual property rights in and to the Product, Services and respective content (including that of any third party website which may be linked to or viewed in connection with the Services). These Terms grant you no rights to use such content except as allowed by such third party.
        </p>
        <p>
          The QLU.ai name, logos, and other QLU.ai related properties are trademarks of QLU.ai. All other trademarks appearing on the Product or Services are trademarks of their respective owners.
        </p>
        <h3>
          Privacy
        </h3>
        <p>
          You acknowledge that to the extent you choose to use or access certain features of the Services you may be asked to submit or enable the transmission of certain personal information, which is required for the operability of our Services.
        </p>
        <p>
          At all times your information will be treated in accordance with our Privacy Policy, which describes how we access, use, store and disclose your information when you use the Services, and is incorporated in these Terms by reference.
        </p>
        <p>
          You hereby grant us a worldwide, non-revocable, royalty-free, sub-licensable and transferable license to use information you submit to us as described in our Privacy Policy, operate them and constantly improve them, including for the purpose of introducing new features when they become available, to reproduce, distribute, make derivatives of it and use it in order to promote the Services.
        </p>
        <p>
          By accessing and using the Services, you agree and understand that we will use your information as set forth in our Privacy Policy, and you allow us to do so.
        </p>
        <h3>
          Maintenance and support
        </h3>
        <p>
          We are aiming at providing our users with the best support for our Services and to constantly improve them. We created different tools to help our users address frequently asked questions and additional technical and general support issues. Also, we test frequent updates, maintenance, error shooting and additional means in order to improve the Services.
        </p>
        <p>
          However, we do not undertake to keep operate any of the above, and we reserve the right to change, reduce, limit or terminate our maintenance and support efforts.
        </p>
        <p>
          You may use the Services solely for your personal and lawful purposes.
        </p>
        <p>
          You shall use our Services in complete accordance with the Terms, as amended from time to time, and only for the purposes stipulated in the Terms.
        </p>
        <p>
          You represent and warrant that all information and content that you submit upon the sign-in process (including information submitted from your social network account, if applicable) and all other content which is shared by you when using our Services, is accurate and truthful and that you will promptly update any information or content provided by you that subsequently becomes inaccurate, incomplete, misleading or false.
        </p>
        <p>
          By using the Services with respect to content which is uploaded or used by you (“Content”), you affirm, represent, and warrant that:
        </p>
        <p>
          (i) you own or have the necessary licenses, rights, consents, and permissions to use and authorize us to use all patent, trademark, trade secret, copyright or other proprietary rights in and to your Content in the manner contemplated by the Services and these Terms;
        </p>
        <p>
          (ii) you have the written consent, release, or permission of each identifiable individual person referenced in your Content to use their name, business profiles, etc. as part of the Services;
        </p>
        <p>
          (iii) the Content does not violate any applicable laws, including but not limited to applicable local laws and privacy and data collection laws.
        </p>
        <p>
          You grant the Company a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, sub-licensable and transferable license to use and store your information and Content in connection with the Services.
        </p>
        <p>
          You acknowledge that any unsolicited materials submitted or sent to us will be deemed to be not confidential or secret. By submitting or sending information or other material to us or through the Site or Services you:
        </p>
        <p>
          (i) warrant that you have all rights of any kind to the material and that to the best of your knowledge no other party has any rights to the material;
        </p>
        <p>
          (ii) grant us an unrestricted, perpetual, irrevocable license to use, reproduce, display, perform, modify, transmit and distribute the material, and you further agree that QLU.ai is free to use any ideas, know-how, concepts or techniques you send us for any purpose, without any compensation to you or any other person.
        </p>
        <p>
          You acknowledge that you are responsible for any information or Content that you submit or transmit through the Services and any other communications options available by us, including your responsibility as to the privacy, legality, reliability, appropriateness, originality, and copyright of any such information and Content, whether publicly posted or privately transmitted.
        </p>
        <p>
          You acknowledge that in order to use the Services, you must use WiFi or receive data connectivity services from your service provider. The cost of the WiFi or data connectivity service may vary among service providers. It is your responsibility to review such costs and to determine whether you wish to bear such costs or not. In particular, you further acknowledge that the cost of such data connectivity service may rise significantly when roaming internationally. Therefore, you are advised to consider the cost of using the Services, depending on your location at any particular time.
        </p>
        <h3>
          Limitation of use
        </h3>
        <p>
          You may not use the Services in any manner that is or may be found by as, at our sole discretion as:
        </p>
        <p>
          (i) violating or infringing in any way upon the rights of others;
        </p>
        <p>
          (ii) unlawful, threatening, abusive, defamatory, invasive of privacy or publicity rights, or otherwise objectionable;
        </p>
        <p>
          (iii) encouraging conduct that would constitute a criminal or civil offense;
        </p>
        <p>
          (iv) gives rise to civil liability;
        </p>
        <p>
          (v) collect content or information, or otherwise access the Services using any automated means (such as bots or scrapers) without our prior permission;
        </p>
        <p>
          (vi) access QLU.ai servers and Services through unauthorized means, such as unlicensed software clients;
        </p>
        <p>
          (vii) violates any applicable local, state, federal or international law, ordinance including any regulations requirements, procedures or policies in force from time to time, or any right of any third party, including without limitation, any right of privacy or publicity.
        </p>
        <p>
          Disruption: You may not use the Services in any manner that could disable, overburden, damage, or impair the Services, or interfere with any other party’s use and enjoyment of the Services; including by
        </p>
        <p>
          (a) uploading or otherwise disseminating any virus, adware, spyware, worm or other malicious code, or
        </p>
        <p>
          (b) interfering with or disrupting any network, equipment, or server connected to or used to provide any of the services, or violating any regulation, policy, or procedure of any network, equipment, or server.
        </p>
        <p>
          You may not impersonate another person or entity, or misrepresent your affiliation with a person or entity when using the services;
        </p>
        <p>
          You may not use or attempt to use another’s account or personal information. You may not attempt to gain unauthorized access to data or the Services, or the computer or mobile systems or networks connected to the Services, through hacking password mining or any other means; Otherwise violating these Terms or creating liability for us.
        </p>
        <p>
          You may not use the Services to determine a consumer’s eligibility for credit or insurance for personal, family or household purposes, employment or a government license or benefit or for any other purpose governed by the FCRA (Fair Credit Reporting Act).
        </p>
        <p>
          You may not undertake any conduct that, in our judgment, restricts or inhibits any other user from using or enjoying the Services.
        </p>
        <h3>
          Termination
        </h3>
        <p>
          We reserve the right to terminate your access to the Product or Services for any reason, including, without limitation, if we have good reason to believe your use to be unacceptable, or in the event of any breach by you of the Terms (either directly or through breach of any other terms and conditions or operating rules applicable to you). We may, but shall be under no obligation to, provide you a warning prior to termination of your use of the Product or Services.
        </p>
        <p>
          All provisions which according to their nature shall survive in order to give effect to their meaning, shall survive any expiration or termination of this agreement, including without limitation all of your representations, warranties and indemnification obligations.
        </p>
        <h3>
          Disclaimer of warranty
        </h3>
        <p>
          YOUR USE OF THE PRODUCT OR SERVICES IS AT YOUR SOLE RISK AND RESPONSIBILITY. QLU.ai SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, UNLESS SUCH WARRANTIES ARE LEGALLY INCAPABLE OF EXCLUSION. WITHOUT LIMITATION OF THE FOREGOING, THE COMPANY AND ITS AFFILIATES, OFFICERS AND VENDORS SPECIFICALLY DISCLAIM ANY AND ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO:
        </p>
        <p>
          (I) ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, SECURITY, USEFULNESS, INTEROPERABILITY, OR CONTENT OF THE SERVICES; AND
        </p>
        <p>
          (II) ANY WARRANTIES OF TITLE, WARRANTY OF NON-INFRINGEMENT, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. QLU.ai PARTIES DO NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE PRODUCT OR SERVICES WILL MEET YOUR REQUIREMENTS OR THAT THE OPERATION OF THE PRODUCT OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN THE SERVICES WILL BE CORRECTED. QLU.ai PARTIES DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE PRODUCT OR SERVICES OR RELATED DOCUMENTATION IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY OR OTHERWISE. WE PROVIDE THE SERVICES ON A COMMERCIALLY REASONABLE BASIS AND DO NOT GUARANTEE THAT USERS WILL BE ABLE TO ACCESS OR USE QLU.ai SERVICES AT TIMES OR LOCATIONS OF THEIR CHOOSING, OR THAT WE WILL HAVE ADEQUATE CAPACITY FOR THE SERVICES AS A WHOLE.
        </p>
        <p>
          This disclaimer of liability applies to any damages or injury caused by the Services, including without limitation as a result of any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of record, whether for breach of contract, tort, negligence, or under any other cause of action. QLU.ai does not warrant or guarantee that all versions shall be provided with similar grades and levels of service, features, functionality and the ability to use the service.
        </p>
        <p>
          QLU.ai does not warrant or guarantee that
        </p>
        <p>
          (i) any program or portion of the Services will be free of infection by viruses, worms, trojan horses or anything else manifesting contaminating or destructive properties; or
        </p>
        <p>
          (ii) the functions or services performed by the Product will be uninterrupted or error-free or that defects in the service will be corrected.
        </p>
        <p>
          It is your sole responsibility to isolate the information, execute anti-contamination software and otherwise take steps to ensure that software or other information obtained from the Services or other users, if contaminated or infected, will not damage your information or system.
        </p>
        <h3>
          No liability
        </h3>
        <p>
          IN NO EVENT WILL QLU.ai, ITS OFFICERS, DIRECTORS, EMPLOYEES, PARENTS, AFFILIATES, SUCCESSORS OR ASSIGNS (TOGETHER “QLU.ai PARTIES”), BE LIABLE TO ANY PARTY
        </p>
        <p>
          (1) FOR ANY INDIRECT, DIRECT, SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR DATA, LOSS OF GOODWILL, OR INFORMATION, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES AND THE LIKE), OR ANY OTHER DAMAGES ARISING IN ANY WAY OUT OF THE AVAILABILITY, USE, RELIANCE ON, OR INABILITY TO USE THE PRODUCT OR SERVICES, EVEN IF QLU.ai PARTIES SHALL HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE; OR
        </p>
        <p>
          (2) FOR ANY CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS, OR OTHER INACCURACIES IN, OR DESTRUCTIVE PROPERTIES OF ANY OTHER SOFTWARE OR OTHER CONTENT INCLUDED AS PART OF THE PRODUCT OR SERVICES.
        </p>
        <p>
          Without derogating any of the above, If for any reason:
        </p>
        <p>
          (i) QLU.ai will not be able to provide you its Services through a specific Platform; or if
        </p>
        <p>
          (ii) any Platform’s terms of use shall require that you cease using our Product, deny your access to our Product when using the Platform or terminate your Platform’s account, QLU.ai Parties will not be liable to any damages, as stipulated in this section.
        </p>
        <p>
          Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, QLU.ai Parties’ liability shall be limited to the extent permitted by applicable law. QLU.ai Parties’ entire liability and your exclusive remedy with respect to any dispute with QLU.ai Parties (including without limitation your use of the Product or Services) is to discontinue your use of QLU.ai Services and request for removal of your data as described in detail under the Privacy Policy. We do not endorse, warrant or guarantee any product or service offered through the Services and will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of such products or services.
        </p>
        <h3>
          Indemnification
        </h3>
        <p>
          You agree to defend, indemnify and hold harmless QLU.ai, its directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising from:
        </p>
        <p>
          (i) your use of and access to the Services;
        </p>
        <p>
          (ii) your violation of any term of these Terms;
        </p>
        <p>
          (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or
        </p>
        <p>
          (iv) any claim that one of your act or omission caused damage to a third party.
        </p>
        <p>
          Under no circumstances whatsoever will the Company be liable in any way for any of the Content (including your contact) you share or publish, including, without limitation, for any infringement of third party’s right, loss or damage of any kind incurred as a result of the use or display or performance of any third party content transmitted, displayed or otherwise made available through the Services.
        </p>
        <h3>
          Copyright infringements
        </h3>
        <p>
          Notification: we respect the intellectual property of others, and we ask you to do the same. We may, in appropriate circumstances and at our discretion, terminate the Services (or a portion of them) and/or access to the Site or Services to users who infringe the intellectual property rights of others. If you believe that your date or work is the subject of copyright infringement and/or a trademark infringement and appears on the Site or Services, please send us a proper notification or request for removal at admin@qlu.ai together with the following information:
        </p>
        <p>
          (a) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
        </p>
        <p>
          (b) Identification of the copyrighted work claimed to have been infringed, or if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site.
        </p>
        <p>
          (c) Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material.
        </p>
        <p>
          (d) Information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted.
        </p>
        <p>
          (e) A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.
        </p>
        <p>
          (f) A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
        </p>
        <p>
          Counter-Notification: If you elect to send us a counter-notice, to be effective it must be a written communication provided to our designated agent that includes substantially the following (please consult your legal counsel to confirm these requirements):
        </p>
        <p>
          (a) A physical or electronic signature of the user.
        </p>
        <p>
          (b) Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled.
        </p>
        <p>
          (c) A statement under penalty of perjury that the user has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled.
        </p>
        <p>
          (d) The user’s name, address, and telephone number.
        </p>
        <p>
          Only the intellectual property rights owner may report potentially infringing items through our reporting system as set forth above. If you are not the intellectual property rights owner, you should contact the intellectual property rights owner and they can choose whether to use the procedures set forth in these Terms.
        </p>
        <p>
          by and construed in accordance with the laws of the State of Delaware
        </p>
        <h3>
          General provisions
        </h3>
        <p>
          Jurisdiction and Governmental Law – These Terms, as well as all disputes arising out of or in connection with these Terms, shall be governed by and construed in accordance with the laws of the State of California, regardless of choice of law rules or principles. Any dispute arising out of or in connection with these Terms, or in future agreements resulting therefrom, shall be exclusively resolved before the competent court in California.
        </p>
        <p>
          International Use – Recognizing the global nature of the Internet, you agree to comply with all local rules regarding online conduct and privacy. Specifically, you agree to comply with all applicable laws regarding privacy and privacy invasion which apply in the country in which you reside.
        </p>
        <p>
          No SUPPORT by QLU.ai – You understand that your use of the Product and Services is at your own risk and that we may – but under no obligation to – provide support or assistance other than the information posted on the Site.
        </p>
        <p>
          Electronic Delivery Policy – QLU.ai, as an online business, transacts with its users electronically. When you sign up for any of the Services, you consent to receive electronic communication from us including without limitation any privacy or other notices, agreements, disclosures, reports, documents, communications, or other records (collectively, “Notices”). You agree that generally, we can send you electronic notices in either or both of the following ways:
        </p>
        <p>
          (i) to the e-mail address that you provided to us during registration or
        </p>
        <p>
          (ii) on a welcoming screen or top page of the relevant QLU.ai Product or Service. The delivery of any Notices from QLU.ai is effective when sent by us, regardless of whether you read the Notice when you receive it or whether you actually receive the delivery.
        </p>
        <p>
          Entire Agreement – These Terms (together with the Privacy Policy and with any other incorporated policy) constitute the entire understanding between us and you with respect to the subject matter hereof. There are no understandings, agreements, conditions or representations, oral or written, express or implied, with reference to the subject matter hereof that are not merged herein, expressly referenced herein, or superseded hereby.
        </p>
        <p>
          No Waiver – The failure or delay of us to exercise or enforce any rights or provision of these Terms does not constitute a waiver of such right or provision.
        </p>
        <p>
          Provisions unenforceable or invalid – Should any part of these Terms be held invalid by any court or tribunal, such invalidity shall not affect the validity of any remaining part, which will remain in full force and effect as if the Terms had been executed without that part having been held to be invalid.
        </p>
        <p>
          Assignment – You shall not transfer, assign, sublicense nor pledge in any manner whatsoever, any of your rights or obligations under this agreement. We may transfer, assign, sublicense or pledge in any manner whatsoever, any of our rights and obligations under this agreement to a subsidiary, affiliate, successor thereof or to any third party whatsoever.
        </p>
      </div>
    </div>
  )
}

export default Terms