import React from "react";
import { Progress } from "reactstrap";
import CrossSvg from "../SVGs/CrossSvg";
import QuestionSvg from "../SVGs/QuestionSvg/QuestionSvg";
import TickSvg from "../SVGs/TickSvg";
import "./UnlockRecommendation.scss";
import { useState,useEffect } from "react";

function UnlockRecommendation({ value = 0 }) {
  // initiallizing states for the progress bar
  const [running, setRunning] = useState(false);
  const [progress, setProgress] = useState(0);
  // interval starts running for 3 mins and gets cleared afterwards
  let interval = undefined;
  useEffect(() => {
    if (progress < 100) {
      interval = setInterval(() => {
        setProgress((prev) => prev + 1/1.8);
      }, 1000);
    } else {
      clearInterval(interval);
      setProgress(0);
    }
  }, [progress]);



  return (
    <div className="unlockRecommendation">
      <div className="recommendedProgressContainer">
        <Progress value={progress} className="recommendedProgress" />
      </div>
      <div className="title">Unlock Recommendations</div>
      <div className="iconListContainer">
        <div className="iconList tick">
          <TickSvg />
        </div>
        ,
        <div className="iconList question">
          <QuestionSvg />
        </div>
        or
        <div className="iconList cross">
          <CrossSvg />
        </div>
        on profiles to unlock
      </div>
    </div>
  );
}

export default UnlockRecommendation;
