import { range } from "lodash";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import {
  setPageNumber
} from "../../actions/prospectsView";
import CaretDown from "../SVGs/CaretDown";
import "./Pagination.scss";
const MyPagination = ({ end, handleClick, page, cardPerPage, search, params, dispatch, disabled }) => {
  const totalPages = Math.ceil(end / cardPerPage);

  const [currentPage, setCurrentPage] = useState(page);
  // const [arr, setArr] = useState([0]);
  const arr = range(0, totalPages)

  const [leftVisible, setLeftVisible] = useState(false);
  const [rightVisible, setRightVisible] = useState(true);

  useEffect(() => {
    setRightVisible(totalPages > 0)
  }, [totalPages])

  const checkPagination = (number) => {
    if (arr[currentPage] < 2) return number <= 4
    if (arr[currentPage] > arr.length - 3) return number >= arr.length - 5
    return number >= arr[currentPage] - 2 && number <= arr[currentPage] + 2
  }
  // TODO: fix this
  const handleMainClick = (e, index) => {
    if (index < 0) {
      return;
    }
    if (totalPages === 0) return;
    if (totalPages <= index) {
      return;
    }
    e.preventDefault();

    handleClick(index, params);
    setCurrentPage(index);
  };
  const handleLeftClick = () => {
    console.log("HANDLE CLICK LEFT: ",)
    if (totalPages === 0) return;
    if (currentPage === 0) return;
    // if (currentPage < 4) {
    //   setArr([0, 1, 2, 3]);
    // }
    // if (currentPage === arr[0]) {
    //   setArr([currentPage - 3, currentPage - 2, currentPage - 1, currentPage]);
    // }
    handleClick(currentPage - 1, params);
    setCurrentPage((c) => c - 1);
  };

  // useEffect(() => {
  //   if (totalPages) {
  //     const initArr = []
  //     for (let i = 0; i < totalPages; i++) {
  //       initArr.push(i)
  //     }
  //     setArr(initArr)
  //   }
  // }, [totalPages])

  useEffect(() => {
    if (currentPage !== page) {
      setCurrentPage(page);
      if (page > 0) {
        setLeftVisible(true);
      } else {
        setLeftVisible(false);
      }
      if (page < arr.length - 1) {
        setRightVisible(true)
      } else {
        setRightVisible(false)
      }
    }

    if (currentPage !== parseInt(params?.p)) {
      setCurrentPage(parseInt(params?.p));
      if (parseInt(params?.p) === 0) {
        // setArr([0, 1, 2, 3])
        dispatch(setPageNumber(0))
      }
    }

    window.scrollTo(0, 0);
  }, [page, search, params?.p]);

  const handleRightClick = () => {
    if (totalPages === 0) return;
    if (totalPages <= page + 1) return;

    if (currentPage === arr[arr.length - 1]) {
      setLeftVisible(true);
      // setArr([
      //   arr[arr.length - 1],
      //   arr[arr.length - 1] + 1,
      //   arr[arr.length - 1] + 2,
      //   arr[arr.length - 1] + 3,
      // ]);
    }
    handleClick(currentPage + 1, params);
    setCurrentPage((c) => c + 1);
  };
  return (
    <div className="numbers">
      {/* {arr[0] > 4 && ( */} {/* TODO: Hide Logic */}
      <span
        className={`leftPaginate ${leftVisible ? "show" : ""}`}
        disabled={disabled}
        onClick={() => disabled ? false : handleLeftClick()}
        style={{ cursor: totalPages === 0 ? "no-drop" : "pointer" }}
      >
        <CaretDown height="16" width="16" />
      </span>
      {/* )} */}
      {arr.map((number, index) => (
        checkPagination(number) &&
        <div div
          key={index}
          disabled={disabled}
          onClick={(e) => disabled ? false : handleMainClick(e, number)}
          className={`number ${currentPage === number && "active"}`}
          style={{
            cursor:
              totalPages === 0 || totalPages <= number ? "no-drop" : "pointer",
          }}
        >
          {number + 1}
        </div>
      ))
      }
      <span
        className={`rightPaginate ${rightVisible ? "show" : ""}`}
        disabled={disabled}
        onClick={() => disabled ? false : handleRightClick()}
        style={{
          cursor:
            totalPages === 0 || totalPages <= page + 1 ? "no-drop" : "pointer",
        }}
      >
        <CaretDown height="16" width="16" />
      </span>
    </div >
  );
};

export default connect((state) => ({
  page: state.prospectsView.prospectVariant.pageNumber,
}))(MyPagination);
