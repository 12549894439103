import React from 'react'
import '../style.scss'
const JobSetupStep01 = ({
    strokeWidth='2',
    width='24',
    height='24',
    selected=false
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`} 
                d="M20 7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V9C22 7.89543 21.1046 7 20 7Z" 
                strokeWidth={strokeWidth} 
                strokeLinecap="round" 
                strokeLinejoin="round"/>
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`} 
                d="M16 21V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V21" 
                strokeWidth={strokeWidth} 
                strokeLinecap="round" 
                strokeLinejoin="round"/>
        </svg>
    )
}

export default JobSetupStep01
