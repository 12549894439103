import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getAllNotifications, notificationAppStateHandler, setNotificationRead
} from "../../../actions/notifications";
import UserAvt from '../../../components/UserAvt';
import useComponentVisible from "../../../utils/useComponentVisible";
import "./NotificationDropdownPanel.scss";

const NotificationDropdownPanel = ({
  notifications = {
    notifications: [],
    pageNo: 1,
  },
  historyPush,
  location,
  type = "home",
  jobId,
  polling,
  dispatch,
  style
}) => {
  const {
    ref,
    isComponentVisible: notificationsIsOpened,
    setIsComponentVisible: setNotificationsIsOpened,
  } = useComponentVisible(false);
  useEffect(() => {
    dispatch(getAllNotifications(notifications?.pageNo))

    const polling = setInterval(() => dispatch(getAllNotifications(notifications?.pageNo)), 1 * 60 * 1000);

    return () => {
      clearInterval(polling);
    };

  }, []);

  useEffect(() => {
    console.log(
      // "notifications ====================>",
      parseInt(notifications?.totalCount) %
      (parseInt(notifications?.pageNo) * 10)
    );
  }, [notifications]);

  useEffect(() => {
    if (notificationsIsOpened) {
      dispatch(
        setNotificationRead(
          notifications?.notifications
            ?.map((notification) => {
              return notification.id;
            })
            .join(",")
        )
      );
    }
  }, [notificationsIsOpened]);

  let count = 0;
  if (type === "ScoreAndConnect") {
    notifications.notifications = notifications?.notifications?.filter(
      (notification) => notification.jobId === jobId
    );

    count = notifications?.notifications?.filter(
      (notification) => notification.is_read === false
    )?.length;
  } else {
    count = notifications?.notifications?.filter(
      (notification) => notification.is_read === false
    )?.length;
  }

  const handleClickOnView = (notification) => {
    dispatch(notificationAppStateHandler(notification, historyPush, location));
  };

  return (
    <div
      style={{ ...style, position: "relative", display: "flex" }}
      className="notificationDropdownContainer"
      ref={ref}
    >
      {/* <div className="verticalLine"></div> */}
      <UserAvt style={{ fontWeight: "600", fontSize: "18px" }} />
      {/* <button
        className="notificationDropdownButton"
        onClick={() => setNotificationsIsOpened(!notificationsIsOpened)}
      >
        {<NotificationBtn count={count} />}
        <NotificationIconSvg />
      </button>
      <div
        className={`notificationDropdown ${notificationsIsOpened ? "show" : "hide"
          }`}
        style={{ marginTop: "18px", position: "absolute" }}
      >
        {notificationsIsOpened && <PendingNotificationPanel />}
        {notifications?.notifications?.map((notification, key) => (
          <NotificationItem
            key={key}
            notification={notification}
            handleClickOnView={handleClickOnView}
          />
        ))}

        <div
          className={`showMore ${parseInt(notifications?.totalCount) >
              parseInt(notifications?.pageNo) * 10
              ? ""
              : "disabled"
            }`}
          onClick={
            parseInt(notifications?.totalCount) >
              parseInt(notifications?.pageNo) * 10
              ? () =>
                dispatch(
                  getAllNotifications(parseInt(notifications?.pageNo) + 1)
                )
              : () => false
          }
        >
          <p>View older notifications</p>
        </div>
      </div> */}
    </div>
  );
};

export default connect((state) => ({
  notifications: state.notifications?.notifications?.notifications,
  jobId: state.prospectsView?.candidates?.job?.id,
}))(NotificationDropdownPanel);
