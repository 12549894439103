import React from "react";
import "../style.scss";

const RecommendedSvg = ({
  color = "#FFC321",
  width = "14",
  height = "14",
  style = {},
  stroke = 1.5,
  className = "",
  fill = "none",
  selected = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      color={color}
      className={`${className} ${selected ? "qluSvgFillSelected" : ""}`}
      fill={fill}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0L9.163 4.60778L14 5.35121L10.5 8.93586L11.326 14L7 11.6078L2.674 14L3.5 8.93586L0 5.35121L4.837 4.60778L7 0Z"
        fill={color}
        className={`${className} ${selected ? "qluSvgFillSelected" : ""}`}
      />
    </svg>
  );
};

export default RecommendedSvg;
