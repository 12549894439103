import { get } from "lodash";
import qs from 'qs';
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { CSSTransition } from "react-transition-group";
import { Progress } from "reactstrap";
import { _resetIsJobEditSuccess } from "../../actions/JobSetup";
import {
  clearAllAdvancedFilters,
  clearProspects,
  clearRecommendedCandidates,
  getRecommendationCandidate,
  getRecommendationProbability,
  requestSaveProspects,
  updateRecommendedProspect
} from "../../actions/prospectsView";
import CompanyCard from "../../components/CompanyCard/CompanyCard";
import { PER_PAGE_COUNT } from "../../constants/api";
import RevampProspectFullCard from "../../uiLibrary/RevampProspectFullCard/RevampProspectFullCard";
import CrossSvg from "../../uiLibrary/SVGs/CrossSvg";
import FavouriteSVG from "../../uiLibrary/SVGs/FavoriteSVG";
import QuestionSvg from "../../uiLibrary/SVGs/QuestionSvg/QuestionSvg";
import { getDefaultFilters, manualApiCall } from "../../utils";
import { getCompanyDetailsFromDB } from "../../utils/visitApiProfile";
import QuickDetailProfileCard from '../ScoreAndConnect/components/QuickDetailProfileCard';
import "./addingProspects.scss";

const AddingProspects = ({
  value = 0,
  match: {
    params: { jId }
  },
  history: {
    push,
    location: { search }
  },
  dispatch,
  prospectData,
  recommendedProfiles,
  timeoutInMinutes = 2
}) => {
  const qParams = qs.parse(search.substr(1))
  // console.log("Q PARAMS ARE: ", qParams)
  const [companyData, setCompanyData] = useState(false)
  const [job, setJob] = useState({})
  const [showFullCard, setShowFullCard] = useState(false);
  const [fullCardTag, setFullCardTag] = useState(null);
  const timeout = get(qParams, ['t'], 2)
  const [progress, setProgress] = useState(0);
  const expiryTimestamp = Date.now() + 60 * timeout * 1000; // current time + 5 minutes

  // dynamic progress bar
  let interval = undefined;
  useEffect(() => {
    if (progress < 180) {
      interval = setInterval(() => {
        console.log("current progress : ", progress)
        setProgress((prev) => prev + 1 / 1.8);
      }, 1000);
    } else {
      clearInterval(interval);
      setProgress(0);
    }
  }, []);

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: async () => {
      console.log("ON EXPIRE ON EXPIRE: ", job)

      const resp = await manualApiCall(`/api/v2/auth/jobs/${jId}/advFiltersData`, { method: 'GET' })

      const runInfo = resp?.data?.runInfo || []
      const maxRunNumber = runInfo?.map(item => item.runNumber).sort((a, b) => b - a)[0] || 0

      const jobArray = job?.jobArray
      const isQuick = job?.meta?.isQuick
      const { fS, fSO, secS, secSO, hCSR } = getDefaultFilters(jobArray)


      dispatch(clearAllAdvancedFilters());
      dispatch(getRecommendationProbability(jId));
      dispatch(clearProspects());
      push(
        isQuick ?
          `/job/${jId}/true?fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&fS=${fS}&fSO=${fSO}&f=a&hCSR=${encodeURIComponent(hCSR)}` :
          `/job/${jId}?fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&fS=${fS}&fSO=${fSO}&secS=${secS}&secSO=${secSO}&f=a&hCSR=${encodeURIComponent(hCSR)}&rN=${maxRunNumber || 0}`

      );
      dispatch(clearRecommendedCandidates());
    },
  });

  useEffect(() => {
    const asyncFunction = async () => {
      dispatch(clearRecommendedCandidates());
      start();
      const data = await manualApiCall(`/api/v2/auth/jobs/${jId}`, { method: 'GET' })
      setJob(data.job)

      return () => {
        restart(expiryTimestamp, false);
        dispatch(clearRecommendedCandidates());
        dispatch(_resetIsJobEditSuccess());
      };
    }
    asyncFunction()
  }, []);

  useEffect(() => {
    setCompanyData(false)
  }, [prospectData])

  useEffect(() => {
    if (
      (seconds === 30 ||
        seconds === 50 ||
        seconds === 0 ||
        seconds === 55 ||
        seconds === 45 ||
        seconds === 20) &&
      prospectData == null
    ) {
      // console.log("============getRecommendationCandidate===============", {recommendedProfiles, prospectData})
      dispatch(getRecommendationCandidate(jId));
    }
  }, [seconds]);

  useEffect(() => { }, [prospectData, recommendedProfiles]);



  const handleClickMaybeProspects = (profileId, maybe) => {
    dispatch(updateRecommendedProspect({
      maybe,
      saved: false,
      archived: false
    }))
    setTimeout(() => {
      dispatch(clearRecommendedCandidates());
    }, 300)
  };

  const handleClickSaveProspects = (jobProfileIds = [], rollback = false, profileId, maybe = false) => {
    dispatch(requestSaveProspects(jId, jobProfileIds, rollback));
    dispatch(updateRecommendedProspect({
      saved: !rollback,
      maybe: false,
      archived: false
    }))
    setTimeout(() => {
      dispatch(clearRecommendedCandidates());
    }, 300)
  };

  const showCompanyModal = async ({
    description,
    companyWebsite,
    companyType,
    industries,
    companySpecialities,
    logo,
    companySize,
    company_name,
    companyDescription,
    companyPageUrl,
    urn,
    universalName
  }, init, fin, index) => {
    init()
    setCompanyData(false)
    // console.log("===========universalName===============", {urn,
    //   universalName})
    if (universalName || urn) {
      const uid = universalName || urn?.split(':')?.pop();
      // console.log("=============uid================",  urn?.split(':')?.pop())
      // console.log("=============uid================", uid)
      const resp = await getCompanyDetailsFromDB(uid);
      console.log("=============resp================", resp)
      if (resp) {
        setCompanyData({
          companyLogo: resp?.companyLogoUrl || "",
          companyName: resp?.name || "",
          companyDescription: resp?.companyDescription || "",
          industry: resp?.industries?.localizedName || "",
          specialities: resp?.specialities || [],
          staffCount: resp?.staffCount || 0,
          companyPageUrl: resp?.companyPageUrl || "",
          companyType: resp?.companyType?.localizedName || "",
        })
        return fin()
      }
    }
    setCompanyData({
      companyLogo: logo || "",
      companyName: company_name || "",
      companyDescription: companyDescription || "",
      industry: industries?.[0] || "",
      specialities: companySpecialities || [],
      staffCount: companySize || 0,
      companyPageUrl: companyWebsite || "",
      companyType: companyType || ""
    })
    return fin()
  }


  return (
    <div className="addingProspectsRoot">
      <div
        className={`companyModal ${companyData && prospectData ? "showCompanyModal" : ""
          }`}
      >
        <CompanyCard
          {...companyData}
          onClick={() => setCompanyData(false)}
        />
      </div>
      <div
        className={`fullCardView ${showFullCard && prospectData ? "showFullCard" : ""}`}
        onClick={() => { setShowFullCard(false) }}
      >
        <CSSTransition
          in={showFullCard}
          timeout={500}
          mountOnEnter
          unmountOnExit
        >
          <RevampProspectFullCard
            index={false}
            fullCardTag={fullCardTag}
            setFullCardTag={setFullCardTag}
            showCompanyModal={(e, ...rest) => showCompanyModal(e, e => false, e => false, rest[2])}
            closeCompanyModal={() => setCompanyData(false)}
            tabValue={"Profile"}
            prospectData={prospectData}
            isDetail={true}
            disableColab={true}
            hasSelection={false}
            hasComments={false}
            hasReactions={false}
            isAddingProfile={true}
            showFullCard={showFullCard}
            onClickCard={() => setShowFullCard(false)}
            onClickSave={handleClickSaveProspects}
            onClickMaybe={handleClickMaybeProspects}
          />
        </CSSTransition>
      </div>
      <div className="addingProspectsContainer">
        <div className="unlockRecommendations">
          <div className="recommendedProgressContainer">
            <Progress
              value={progress}
              className="recommendedProgress"
            />
          </div>
          <div className="timerDiv">
            <div>Searching</div>
            <div>
              Time left: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </div>
          </div>
          <div className="horizontalLine"></div>
          <div className="title">Improve your results</div>
          <div className="iconListContainer">
            Give feedback  on profiles using
            <div className="iconList tick">
              <FavouriteSVG stroke={3} />
            </div>
            ,
            <div className="iconList question">
              <QuestionSvg />
            </div>
            or
            <div className="iconList cross">
              <CrossSvg />
            </div>
          </div>
          {/* <div className="recommendedProgressContainer">
            <Progress
              value={progress}
              className="recommendedProgress"
            />
          </div> */}
        </div>

        {prospectData ? (
          <div
            style={{
              width: '50vw',
              margin: '78px 0'
            }}
          >
            <QuickDetailProfileCard
              index={false}
              disableColab={true}
              disableSelect={true}
              profileData={prospectData}
              showCompanyModal={showCompanyModal}
              isAddingProfile={true}
              onClickSave={handleClickSaveProspects}
              onClickMaybe={handleClickMaybeProspects}
              onClickCard={() => {
                setShowFullCard(true)
              }}
            />
          </div>
        ) : (
          <div className="recommendedLoading">
            <Loader type="Grid" color="#FF8D4E" width="168" height="160" />
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    prospectData: state.prospectsView.candidates.currentRecommendedProfile,
    recommendedProfiles: state.prospectsView.candidates.recommendedProfiles,
  }))(AddingProspects)
);
