import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { setSort, toggleSortModal } from "../../../actions/prospectsView";
import SortSection from "./SortSection";

const SortController = ({
  isOpen,
  fS,
  fSO,
  secS,
  secSO,
  handleSortToggle,
  dispatch,
  isQuick = false,
}) => {
  const setFirstSort = ({ sort, order }) => {
    dispatch(
      setSort({
        fS: sort,
        fSO: order,
      })
    );
  };
  const setSecondSort = ({ sort, order }) => {
    // console.log("SET SECOND SORT: ", { sort, order });
    if (!isQuick) {
      dispatch(
        setSort({
          secS: sort,
          secSO: order,
        })
      );
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);
  return (
    <SortSection
      Show={isOpen}
      setShow={handleSortToggle}
      FirstSort={{
        sort: fS,
        order: fSO,
      }}
      setFirstSort={setFirstSort}
      SecondSort={{
        sort: secS,
        order: secSO,
      }}
      setSecondSort={setSecondSort}
    />
  );
};

export default connect((state) => ({
  isOpen: state.prospectsView.sorting.isOpen,
  fS: state.prospectsView.sorting.fS,
  fSO: state.prospectsView.sorting.fSO,
  secS: state.prospectsView.sorting.secS,
  secSO: state.prospectsView.sorting.secSO,
}))(SortController);
