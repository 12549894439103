import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Input, Button } from "reactstrap";
import IdealProfileViewCard from "../../../components/IdealProfileViewCard/IdealProfileViewCard";
import {
  changeIdealProfileName,
  changeJobProfileUrl,
  clearIdealProfileSuggestions,
  fetchIdealProfileData,
  fetchIdealProfileSuggestions,
  initJobCriteria,
  initJobSpecialities,
  quickScrapeIdealProfileData,
  removeSelectedIdealProfile,
  setIsEdited,
  setIsSpecialtyEdited,
} from "../../../actions/JobSetup";
import "./AddIdealProfiles.scss";
import NewAutoSuggestion from "../../../components/NewAutoSuggestion/NewAutoSuggestion";
import ProfileSummaryCard from "../../../components/ProfileSummaryCard/ProfileSummaryCard";
import { extractPublicIdentifier } from "../../../utils";
import Loading from "../../../uiLibrary/Loading";
import { Mixpanel } from "../../../utils/Mixpanel";
import ProfileLiked from "../../../uiLibrary/ProfileLiked/ProfileLiked";
import { toast } from "react-toastify";

const AddIdealProfiles = ({
  profileUrl = "",
  profileName = "",
  profileSuggestions=[],
  dispatch,
  profiles = [],
  idealProspects = [],
  suggestionsLoading,
  isLoading,
  isNerLoading,
  isReview
}) => {

// const handleChange = (e) => profiles.length > 2
//   dispatch(changeJobProfileUrl(e.target. )) :
//   alert("Max Profiles Selected")

  useEffect(()=>{    
    Mixpanel.track("Best Fit Profiles")
  }, [])

  useEffect(()=>{
    const progileToScrape = profiles.filter(({isScraping, isScraped, publicIdentifier})=>(!isScraping && !isScraped && publicIdentifier)) 
    if(progileToScrape.length>0){
      progileToScrape.map(profile=>dispatch(fetchIdealProfileData(profile)))
    }
  }, [profiles])


const handleRemove = (value) => {
    dispatch(removeSelectedIdealProfile(value))
    dispatch(initJobSpecialities())
    dispatch(setIsEdited(true))
    dispatch(setIsSpecialtyEdited(true))
  }

const handleAdd = () =>{

  //  const publicIdentifier =  extractPublicIdentifier(profileUrl)
  //   if( !profiles.map(item => item.publicIdentifier).includes(publicIdentifier)){
  //       console.log('PUBLIC IDENTIFIER IS: ', publicIdentifier)
  //       dispatch(fetchIdealProfileData(publicIdentifier))
  //   } 
  //   else{
  //       console.log(`PUBLIC IDENTIFIER "${publicIdentifier}" IS ALREADY ADDED AS AN IDEAL PROFILE`)
  //   }
}

  const handleChangeInput = (e) => profiles.length > 4 ?
    alert("Max Profiles Selected") :
    dispatch(changeIdealProfileName(e.target.value))
  

  const handleSuggestionsFetchRequested=(value="")=>{
    dispatch(fetchIdealProfileSuggestions(value.trim().toLowerCase()))
  }
  

  const handleSuggestionsClearRequested=()=>dispatch(clearIdealProfileSuggestions())

  const handleSuggestionSelected=(data)=>{
    const url = data.url
    console.log("==============handleSuggestionSelected===============", {data})
    const pubId = extractPublicIdentifier(url)
    if(pubId && profiles.length < 5){
      // console.log("=====================extractPublicIdentifier===========================", pubId)
      if(profiles.map(({ publicIdentifier }) => publicIdentifier).includes(pubId))
      {
        toast(
          ({ closeToast }) => (
            <ProfileLiked
              header="You have already added this best fit profile. Please choose another"
            >
            </ProfileLiked>
          ),
          {
            closeButton: false,
            hideProgressBar: true,
            style: {
              backgroundColor: "#A0A0A0",
              borderRadius: "8px",
              width: "514px",
            },
          }
        );
      }else{
        const full_name = data?.name
        const firstName = data?.name?.split(" ")?.[0]
        const lastName = data?.name?.split(" ")?.slice(1)?.join(" ")
        const quickScrapePayload = {
            firstName,
            lastName,
            full_name,
            image_url: data?.imgSrc,
            publicIdentifier: pubId
        }
        dispatch(quickScrapeIdealProfileData(quickScrapePayload))
        dispatch(setIsEdited(true))
        dispatch(setIsSpecialtyEdited(true))
        Mixpanel.track("Best fit profile select")
      }
    }
    dispatch(changeIdealProfileName(''))
  }

  const renderSuggestion = (suggestion) => (
    typeof suggestion === 'string' ? 
      <div>{suggestion}</div> :
      <div 
        className='idealProfileSuggestionContainer'
      >
        <ProfileSummaryCard
          key={suggestion.urn}
          name={suggestion.name}
          headline={suggestion.headline}
          imgSrc={suggestion.imgSrc}
        />
      </div>
  )

  const shouldRenderSuggestions = suggestion => (
    (typeof suggestion === "string"
    ? suggestion.trim() 
    : suggestion?.name.trim()) && 
    !suggestionsLoading &&
    !isLoading
  )


  return (
    <div className="addIdealProfiles">
      <p>
        Who do you think could be the best fit for this job? The more profiles, the better. This helps Qlu learn about the job requirements. 
      </p>
      <span className="addIdealProfilesSummaryCard">
        {profiles.map((profile, index) => {
          return (
            <IdealProfileViewCard
              key={index}
              onClick={isReview === '1' ? () => false : handleRemove}
              onCloseDisabled={isNerLoading}
              isLoading={profile.isScraping}
              publicIdentifier={profile.publicIdentifier}
              profileImage={profile.image_url?profile.image_url:`https://qlu-media-test.s3.amazonaws.com/${profile.publicIdentifier}.jpg`}
              profileName={profile.full_name?`${profile.full_name}`:`${profile.fullName}`}
              profileHeadline={profile.title && profile.current_company_name ? `${profile.title} @ ${profile.current_company_name}` : profile.headline ? `${profile.headline}` : ""}
            />
          );
        })}
      </span>
      <h5>Search by name, Keywords or URL</h5>

      <div className="addIdealProfilesInputAndButton">
        <NewAutoSuggestion
          inputValue={profileName}
          suggestionsArray={profileSuggestions}
          placeholder='John Doe'
          handleChangeInput={isReview === '1' ? () => false : handleChangeInput}
          handleSuggestionSelected={isReview === '1' ? () => false : handleSuggestionSelected}
          handleSuggestionsFetchRequested={isReview === '1' ? () => false : handleSuggestionsFetchRequested}
          handleSuggestionsClearRequested={isReview === '1' ? () => false : handleSuggestionsClearRequested}
          renderSuggestion={isReview === '1' ? () => false : renderSuggestion}
          shouldRenderSuggestions={isReview === '1' ? () => false : shouldRenderSuggestions}
        />
        {(suggestionsLoading ||
          isLoading) &&
          <Loading/>
        }
        
      </div>
      
    </div>
  );
};

export default connect((state) => ({
  profileUrl: state.jobSetup.jobIdealProfile.profileUrl,
  profileName: state.jobSetup.jobIdealProfile.profileName,
  profileSuggestions: state.jobSetup.jobIdealProfile.profileSuggestions,
  profiles: state.jobSetup.jobIdealProfile.profiles,
  suggestionsLoading: state.jobSetup.jobIdealProfile.suggestionsLoading,
  isLoading: state.jobSetup.jobIdealProfile.isLoading,
  isNerLoading: state.jobSetup.jobSetup.isLoading,
  isReview: state.jobSetup?.jobSetup?.isReview
}))(AddIdealProfiles);
