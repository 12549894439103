import { ErrorMessage, Field, Form, Formik } from 'formik'
import qs from "qs"
import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FETCH_PROVIDER_SUCCESS } from '../../actions/auth'
import FormikInput from '../../components/FormikInput'
import PrimaryButton from '../../uiLibrary/PrimaryButton'
import { manualApiCall, validateEmail } from '../../utils'


function ResetPassword({
    email,
    dispatch,
    history,
    location
}) {

    // console.log("EMAIL IS: ", { email, location })

    // console.log("location.search: ")
    const isDisabled = location.state?.isDisabled
    // console.log('*************', location.state?.isDisabled)

    const params = qs.parse(location.search.substring(1))

    // console.log("params are: ", params)

    // console.log("EMAIL IS: ", params.e)


    const validateForm = values => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!validateEmail(values.email)) {
            errors.email = 'Invalid email address';
        }
        return errors;
    }
    const submitForm = async (values, { props, resetForm, setErrors, setSubmitting }) => {
        try {
            setSubmitting(true)
            const res = await manualApiCall('/api/v2/send/reset/password', {
                method: "POST",
                body: JSON.stringify({ email: values.email })
            })
            if (res?.data) {
                dispatch({ type: FETCH_PROVIDER_SUCCESS, meta: values.email })
                history.push('/auth/verify-email', { from: 'reset' });
            }
            else {
                throw new Error("User not found")
            }
        }
        catch (err) {
            console.log("ERROR: ", err.message)
            setErrors({ email: 'User not found' })
        }
        finally {
            setSubmitting(false)
        }
        return
    }

    const [emaill_address, set_emaill_address] = useState("")
    const [reset_token, set_reset_token] = useState("")
    const [passwordSaved, setPasswordSaved] = useState(false)


    useEffect(async () => {
        try {
            const { token } = qs.parse(location?.search.split("?").pop())
            if (token) {
                const tokenRes = await manualApiCall('/api/v2/validate/reset/token', {
                    method: 'POST',
                    body: JSON.stringify({
                        reset_token: token
                    })
                })
                if (tokenRes?.data?.success) {
                    set_emaill_address(String(tokenRes?.data.email))
                    set_reset_token(String(token))
                }
                else {
                    alert("Password Reset link has expired.")
                    history.push('/auth/login')
                }
            }
        }
        catch (err) {
            console.log("ERROR: ", err.message)
            history.push('/auth/login')
        }
    }, [])

    return (
        <>
            <Formik
                initialValues={{ email: params?.e }}
                validate={validateForm}
                onSubmit={submitForm}
            >
                {({
                    errors,
                    isSubmitting
                }) => (
                    <div className='signin_root authElemContainer'>
                        <h1>Reset Password</h1>
                        <Form>
                            <div className='mb32'>
                                <p className='input_label'>EMAIL</p>
                                <Field disabled={isDisabled ? true : false} type="email" name="email" placeholder={'jon@doe.com'} component={FormikInput} />
                                <ErrorMessage name="email" render={msg => <p className='formik_err_msg'>{msg}</p>} />
                            </div>
                            <PrimaryButton className='auth-submit-btn' type="submit"
                                disabled={isSubmitting || errors.email}>
                                {isSubmitting ?
                                    <Loader
                                        type="TailSpin"
                                        color="white"
                                        height={18}
                                        width={18}
                                    /> :
                                    'Continue'}
                            </PrimaryButton>
                        </Form>
                    </div>
                )}
            </Formik>
        </>
    )
}


export default connect(state => ({
    email: state.auth.emailStep.email
}))(withRouter(ResetPassword))
