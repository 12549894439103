import { combineReducers } from "redux";
import candidates from "./candidates";
import meta from "./meta";
import sorting from "./sorting";
import advFiltersData from "./advFiltersData";
import advFiltersMeta from "./advFiltersMeta";
import scoringFiltersMeta from "./scoringFiltersMeta";
import prospectVariant from "./prospectVariant";
import advBooleanFilters from "./advBooleanFilters";
import subDropDownReducer from "./subDropDownReducer";
import tribeMembers from "./tribeMembers";
import campaigns from "./campaigns";
import recommendations from "./recommendations";
import findMoreProspect from "./findMoreProspect";
export default combineReducers({
  candidates,
  meta,
  sorting,
  advFiltersData,
  advFiltersMeta,
  scoringFiltersMeta,
  prospectVariant,
  advBooleanFilters,
  subDropDownReducer,
  tribeMembers,
  campaigns,
  recommendations,
  findMoreProspect,
});
