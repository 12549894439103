import React, { useState } from "react";
import "./style.scss";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import MoreVerticalIcon from "../SVGs/MoreVerticalIcon";

const OptionsDropdown = ({
  children,
  menuWidth = "480px",
  isOptionsMenu = false,
}) => {
  const [open, setOpen] = useState(false);
  const toggle = (e) => setOpen((prev) => !prev);
  return (
    <Dropdown className="optionsDropdown" toggle={toggle} isOpen={open}>
      <DropdownToggle>
        <MoreVerticalIcon />
      </DropdownToggle>
      <DropdownMenu onClick={toggle} style={{ width: menuWidth }}>
        {children}
      </DropdownMenu>
    </Dropdown>
  );
};

export default OptionsDropdown;
