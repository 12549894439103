import { SET_BOOLEAN_FILTERS, SET_RAW_TO_BOOLEAN_FILTERS, REMOVE_FILTER } from "../../actions/prospectsView";

const initialState = {
  filters: [
    [
      {
        name: "Skills",
        value: "",
        range: { min: 0, max: 5 },
        isStrict: false,
        eliminate: [],
        highlight: [],
        selectedOne: false,
        relation: "",
        outerRelation: "",
        isDropDownOpen: false,
        isOuterDropDownOpen: false,
      },
    ],
  ],
  booleanTerm: "",
  rawBooleanTerm: "",
};

const advBooleanFilters = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOLEAN_FILTERS: {
      let filters = action.payload;
      let booleanTerm = [];
      let rawBooleanTerm = [];
      if (filters.length > 1) {
        booleanTerm.push("(");
        rawBooleanTerm.push("(");
      }
      filters.map((outerFilter, outerIndex) => {
        booleanTerm.push("(");
        rawBooleanTerm.push("(");
        outerFilter.map((innerFilter, innerIndex) => {
          if (innerFilter.selectedOne) {
            let value =
              innerFilter.eliminate?.length > 0
                ? innerFilter.eliminate[0]
                : innerFilter.highlight?.length > 0
                  ? innerFilter.highlight[0]
                  : "";
            let isNot = innerFilter.eliminate?.length > 0 ? true : false;

            if (value !== "") {
              if (isNot) {
                booleanTerm.push(`(`);
                rawBooleanTerm.push(`(`);
                booleanTerm.push(`<span class="not">NOT </span>`);
                rawBooleanTerm.push("NOT");
              }
              rawBooleanTerm.push(`${innerFilter?.name.replace(/ /g, "-")}:`);
              rawBooleanTerm.push(`${innerFilter?.isStrict ? "\""+value+"\"" : value}`);
              booleanTerm.push(
                `<span class="label">${innerFilter?.name.replace(
                  / /g,
                  "-"
                )}:</span>`
              );
              booleanTerm.push(`<span class="value">${innerFilter?.isStrict ? "\""+value+"\"" : value}</span>`);

              if (isNot) {
                booleanTerm.push(`)`);
                rawBooleanTerm.push(`)`);
              }

              if (innerFilter.relation !== "") {
                booleanTerm.push(
                  `<span class="relation"> ${innerFilter?.relation} </span>`
                );
                rawBooleanTerm.push(` ${innerFilter?.relation} `);
              }
            }
          }
        });

        if (outerFilter[0].outerRelation !== "") {
          booleanTerm.push(")");
          rawBooleanTerm.push(")");

          booleanTerm.push(
            `<span class="relation"> ${outerFilter[0]?.outerRelation} </span>`
          );
          rawBooleanTerm.push(` ${outerFilter[0]?.outerRelation} `);
        } else {
          booleanTerm.push(")");
          rawBooleanTerm.push(")");
        }
      });
      if (filters.length > 1) {
        booleanTerm.push(")");
        rawBooleanTerm.push(")");
      }

      if (booleanTerm.join("") === "()") {
        booleanTerm = [];
      }
      if (rawBooleanTerm.join("") === "()") {
        rawBooleanTerm = [];
      }
      return {
        ...state,
        filters: [...filters],
        booleanTerm: booleanTerm.join(""),
        rawBooleanTerm: rawBooleanTerm.join(""),
      };
      break
    }

    case SET_RAW_TO_BOOLEAN_FILTERS: {
      let filters = [

      ];
      let booleanTerm = state.rawBooleanTerm;
      // let booleanMatches = booleanTerm?.match(/[^a-zA-Z]|(\w+)/g)?.filter((term) => term !== ' ')
      // let booleanMatches = booleanTerm?.match(/(([A-Z+]):"([^"]+)"|([^\(\) ]+))/g)

      let booleanItems = booleanTerm.split('')

      let openParenthesis = 0
      let closeParenthesis = 0
      let hierarchy = ['']
      let hierarchyIndex = 0
      booleanItems.map((item, index) => {
        if (item === '(') {
          openParenthesis += 1
          hierarchy.push(item)
          hierarchy.push('')
          hierarchyIndex += 2
        } else if (item === ')') {
          closeParenthesis += 1
          openParenthesis -= 1
          hierarchy.push(item)
          hierarchy.push('')
          hierarchyIndex += 2
        }

        if (item !== '(' && item !== ')') {
          if (openParenthesis > 0) {
            if (typeof hierarchy[hierarchyIndex] !== 'undefined') {
              hierarchy[hierarchyIndex] += item
            } else {
              hierarchy.push(item)
            }
          }
        }
      })

      hierarchy = hierarchy.filter((item) => item !== '')
      hierarchy = hierarchy.map((item) => {
        item = item.replace(/AND/g, '[AND]').replace(/OR/g, '[OR]').replace(/NOT/g, '[NOT]')
        return item.split(/\[|\]/g)
      })
      filters = []
      hierarchy.map((item, index) => {
        if (item.length === 1) {
          if (item[0] == '(' || item[0] == ')') {
            if (item[0] == '(' && typeof hierarchy[index + 1] !== 'undefined' && hierarchy[index + 1][0] === '(') {
              filters.push([])
            }
          }
        } else if (item.length >= 1) {
          if(item.length === 3 && item[0] === " " && item[2] === " " && ['AND', 'OR'].includes(item[1])) {
            filters[filters.length - 1][0].outerRelation = item[1]
            filters.push([])
          } else {
            item.map((element, elementIndex) => {
              if (!['AND', 'OR', 'NOT'].includes(element) && element.trim() !== '') {
                let splittedElement = element.split(':')
                let filterToAppend = {
                  name: splittedElement[0].trim(),
                  value: "",
                  range: { min: 0, max: 5 },
                  isStrict: splittedElement[1].trim().includes('"'),
                  eliminate: [],
                  highlight: [splittedElement[1].trim()],
                  selectedOne: true,
                  relation: typeof item[elementIndex + 1] !== 'undefined' && ['AND', 'OR'].includes(item[elementIndex + 1]) ? item[elementIndex + 1] : "",
                  outerRelation: "",
                  isDropDownOpen: false,
                  isOuterDropDownOpen: false,
                }
                
                if(typeof item[elementIndex - 1] !== 'undefined' && item[elementIndex - 1] === 'NOT') {
                  filterToAppend.highlight = []
                  filterToAppend.eliminate = [splittedElement[1].trim()]
                }

                if (typeof filters[filters.length - 1] !== 'undefined') {
                  filters[filters.length - 1].push(filterToAppend)
                } else {
                  filters[0] = []

                  filters[0].push(filterToAppend)
                }
              }
            })
          }
        }
      })
      filters = filters.filter(filter => filter.length > 0) 
      return {
        ...state
      };
      break
    }

    case REMOVE_FILTER: {
      let {outerFilterIndex, innerFilterIndex} = action.payload
      let filters = state.filters
      if(outerFilterIndex && innerFilterIndex) {
        filters[outerFilterIndex].splice(innerFilterIndex, 1)
      } else {
        filters.splice(outerFilterIndex, 1)
      }
      return {
        ...state,
        filters
      };
      break
    }
    default:
      return state;
  }
};
export default advBooleanFilters;
