import React from 'react'

const CheckSvg = ({
  style,
  className,
  width = "24",
  height = "24"
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      className={className}
      viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="14" cy="14" r="14" fill="#29382E" />
      <path d="M18.5686 10.5713L12.2829 16.857L9.42578 13.9999" stroke="#63B780" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default CheckSvg