import { CALL_API } from "../middlewares/apiMiddleware"
import { manualApiCall, SignoutFirebase } from "../utils"
import { ActivityTypes, loggingActivity } from "../utils/activityLogger"

export const FETCH_PROVIDER = 'FETCH_PROVIDER'
export const FETCH_PROVIDER_SUCCESS = 'FETCH_PROVIDER_SUCCESS'
export const FETCH_PROVIDER_FAILURE = 'FETCH_PROVIDER_FAILURE'


export const SET_BLOCKED_STATUS = 'SET_BLOCKED_STATUS'

export const checkStatus = () => async (dispatch) => {
  console.log('INSIDE CHECK STATUS')
  try {
    const { isBlocked } = await manualApiCall('/check-status', { method: 'GET' })
    dispatch({ type: SET_BLOCKED_STATUS, payload: isBlocked })
  } catch (e) {
    console.log('AN ERROR OCCURED: ', e.message)
  }
}

export const fetchProvider = (email) => ({
  [CALL_API]: {
    types: [FETCH_PROVIDER, FETCH_PROVIDER_SUCCESS, FETCH_PROVIDER_FAILURE],
    endpoint: `/public/email/lookup`,
    options: {
      method: 'POST',
      body: JSON.stringify({ email })
    },
    meta: email
  }
})


export const SET_USER = 'SET_USER'
export const setUser = (payload) => ({
  type: SET_USER,
  payload
})

export const RESET_PROVIDER_STATE = 'RESET_PROVIDER_STATE'
export const resetProviderState = () => ({ type: RESET_PROVIDER_STATE })


export const LOGOUT = 'LOGOUT'
export const logout = () => {
  try {
    SignoutFirebase()
    loggingActivity(ActivityTypes.LOGOUT)
  } catch (err) {
    console.log("Error: ", err.message)
  }
  finally {
    return ({ type: LOGOUT })
  }
}

export const LOGIN_ADMIN_TO_THE_APP = 'LOGIN_ADMIN_TO_THE_APP'
export const loginAdminToTheApp = (jobId, token) => ({
  type: LOGIN_ADMIN_TO_THE_APP,
  payload: { jobId, token }
})
