import React from 'react'
import '../style.scss'

const TickSvg = ({
    className='',
    width = '16',
    height = '10',
    strokeWidth="2",
    selected=false,
    style = {},
}) => {
    return (
        <svg 
            className={className}
            style={style} 
            width={width} 
            height={height} 
            viewBox="0 0 16 10" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}
                d="M12.3337 1.5L5.00033 8.83333L1.66699 5.5" 
                strokeWidth={strokeWidth} 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>

    )
}


export default TickSvg
