import React from "react";
import { useDispatch } from "react-redux";
import { updateFeedbackEntity } from "../../../actions/QuickSetup";
import CloseSVG from "../../../uiLibrary/SVGs/CloseSVG";
import HelpCircleSVG from "../../../uiLibrary/SVGs/HelpCircleSVG";

export default function ActionIcon({
  id,
  type,
  isSelected,
  hasContainer = true,
  onClick,
}) {
  const dispatch = useDispatch();

  const isMaybeIcon = type === "isMaybe";

  const handleClick = (e) => {
    e.stopPropagation();

    if (!!onClick) {
      return onClick(e);
    }

    const inverseType = isMaybeIcon ? "isDisliked" : "isMaybe";
    dispatch(updateFeedbackEntity(id, type, !isSelected));
    dispatch(updateFeedbackEntity(id, "isLiked", { count: 0 }));
    dispatch(updateFeedbackEntity(id, inverseType, false));
  };
  return (
    <div
      style={{
        borderColor:
          isSelected && type === "isMaybe"
            ? "#FFAB2D"
            : isSelected && type === "isDisliked"
            ? "red"
            : "#333333",
        backgroundColor: !hasContainer
          ? "transparent"
          : isSelected && type === "isMaybe"
          ? "#332209"
          : isSelected && type === "isDisliked"
          ? "#401F1F"
          : "#454545",
      }}
      onClick={(e) => handleClick(e)}
      className={hasContainer ? "eclipseContainer" : ""}
    >
      {type === "isMaybe" ? <HelpCircleSVG /> : <CloseSVG />}
    </div>
  );
}
