import React, { useState,useRef } from 'react'

import BigRoundButton from '../../../../uiLibrary/BigRoundButton'
import LabelButton from '../../../../uiLibrary/LabelButton'
import PrimaryInput from '../../../../uiLibrary/PrimaryInput'
import RectanglePillButton from '../../../../uiLibrary/RectanglePillButton'
import CrossSvg from '../../../../uiLibrary/SVGs/CrossSvg'
import PlusSvg from '../../../../uiLibrary/SVGs/PlusSvg'
import TickSvg from '../../../../uiLibrary/SVGs/TickSvg'
import "./style.scss"

const FindMorePillCards = ({
  heading,
  pills = [],
  onChange = e => false,
  onAdd = e => false,
  onRemove = e => false,
  hasAddMore = false,
  addMoreDisabled = e => false
}) => {
  const [openAdd, setOpenAdd] = useState(false)
  const [addMoreText, setAddMoreText] = useState("")
  const spanRef = useRef(null);
  const onChangeHandler = (e) =>{
    setAddMoreText(e.target.value)
    spanRef.current.innerHTML=""
  }
  return (
    <div
      className='findMorePillCards'
    >
      <h1>
        {heading}
      </h1>
      <div>
        {pills.map(({ name, selected, checked = true }, index) => (
          checked ?
            <div
              key={index}
            >
              <RectanglePillButton
                highlight={true}
                selected={selected}
                onClick={e => {
                  e.stopPropagation()
                  onChange(e, index, { selected: !selected })
                }}
              >
                {name}
              </RectanglePillButton>
            </div> :
            <div
              key={index}
            >
              <PrimaryInput
                value={name}
                onChange={e => onChange(e, index, { name: e.target.value })}
              />
              <BigRoundButton
                onClick={e => onChange(e, index, { selected: true, checked: true })}
                disabled={name?.trim() === ""} // if the input is empty the button is disabled and pill will not be added
              >
                <TickSvg />
              </BigRoundButton>
              <BigRoundButton
                onClick={e => onRemove(index)}
              >
                <CrossSvg />
              </BigRoundButton>
            </div>
        ))}
      </div>
      {hasAddMore &&
        <div>
          {openAdd ?
            <div
            >
              <PrimaryInput
                value={addMoreText}
                onChange={e =>onChangeHandler(e) }
              />
              <BigRoundButton
                onClick={e => {
                  if(addMoreDisabled(addMoreText)){
                    spanRef.current.innerHTML="Speciality is already added!"
                  }
                  else{
                  onAdd({
                    checked: true,
                    selected: true,
                    name: addMoreText
                  })
                }
                  setAddMoreText("")
                }}
                disabled={addMoreText?.trim() === ""}
              >
                <TickSvg />
              </BigRoundButton>
              <BigRoundButton
                onClick={e => {
                  setOpenAdd(false)
                  setAddMoreText("")
                }}
              >
                <CrossSvg />
              </BigRoundButton>
              <div ref={spanRef} className='divError'></div>
            </div> :
            <LabelButton
              onClick={e => setOpenAdd(true)}
            >
              <PlusSvg />
              Add more
            </LabelButton>
          }
        </div>
        
      }
       
    </div>
  )
}

export default FindMorePillCards