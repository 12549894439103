import React from 'react'
import '../style.scss'
const JobSetupStep08 = ({
    strokeWidth='2',
    width='24',
    height='24',
    selected=false,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}   
                x1="12" y1="8" x2="12" y2="12"
                strokeWidth={strokeWidth}/>
            <rect
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}   
                x="3" y="15" width="6" height="6" rx="1" 
                strokeWidth={strokeWidth}/>
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}  
                d="M6 16V13C6 12.4477 6.44772 12 7 12H17C17.5523 12 18 12.4477 18 13V16" 
                strokeWidth={strokeWidth}/>
            <rect
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}   
                x="9" y="2" width="6" height="6" rx="1"
                strokeWidth={strokeWidth}/>
            <rect 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}  
                x="15" y="15" width="6" height="6" rx="1" 
                strokeWidth={strokeWidth}/>
        </svg>

    )
}

export default JobSetupStep08
