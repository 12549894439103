// import Head from 'next/head'
import { useState } from 'react'
import Loader from 'react-spinners/ClipLoader'
// import { useRouter } from 'next/router'


// import Header from '../../../component/Header'
// import Footer from '../../../component/Footer'
import PrimaryInput from './components/PrimaryInput'
import PrimaryButton from './components/PrimaryButton'
// import style from '../../../styles/consent.module.scss'
import "./consent.scss"
import { manualApiCall } from '../../utils'


const Rejected = ({ match: { params } }) => {
  const [reason, setReason] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessfull, setIsSuccessfull] = useState(false)

  // const router = useRouter()
  // const { email } = router.query
  const email = params.email


  const registerRejectionReason = async () => {
    setIsLoading(true)

    await manualApiCall(`https://staging.api.qlu.ai/ds/consentRequest/reject`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: email,
        rejectionReason: reason
      })
    })

    setIsLoading(false)
    setIsSuccessfull(true)
  }

  return (
    <div className={'consentContainer'}>
       <div className={'consentSection'}>
        {!isSuccessfull &&
          <>
            <p>Please tell us why you did not grant consent?</p>
            <PrimaryInput
              type='textarea'
              placeholder={'Rejection reason..'}
              value={reason}
              onChange={e => setReason(e.target.value)}
            />
            <div style={{ marginTop: '16px' }} />
            <div>
              <PrimaryButton
                onClick={registerRejectionReason}
                disabled={!reason}
              >
                {isLoading ? <Loader color='white' size={22} /> : 'Submit'}
              </PrimaryButton>
            </div>
          </>
        }
        {isSuccessfull && <p style={{ fontSize: '24px' }}>Thank you for your feedback.</p>}

      </div>

      {/* Consent Granted */}

    </div>
  )
}

export default Rejected