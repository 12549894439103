import React, {useRef, useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { changeCampaignRound } from '../../../actions/outbox'
import PillTabButton from '../../../uiLibrary/PillTabButton'
import CaretLeft from '../../../uiLibrary/SVGs/CaretLeft'
import OutboxMessage from './OutboxMessage'
import './style.scss'

const OutboxMessagesController = ({
    channelOptions,
    recipientTab,
    togglePersonalise,
    selectedProspects,
    variableTranslator,
    onTabClick,
    rounds,
    handleChange,
    isInViewport
}) => {

    const [isInViewPortState, setIsInViewPortState] = useState(0)

    useEffect(() => {
        window.addEventListener('scroll', handleIsInViewPort);

        return () => {
        window.removeEventListener('scroll', handleIsInViewPort);
        };
    }, [])

    const handleIsInViewPort = () => {
        for (let index = 0; index < 3; index++) {
            if(isInViewport(document.getElementById(`${recipientTab}-${index}`))){
                return setIsInViewPortState(index)
            }
        }
    }

    const tabs = recipientTab==='all' ?
        rounds.map(({
            channel, 
            template,
            sender,
            subject,
            send_after_days,
            id
        }, index)=>({
            ...channelOptions[channel],
            label: `Round ${index+1}`,
            template,
            send_after_days,
            id,
            channel,
            subject,
            sender
        })) :
        rounds.map(({messages, id}, index)=>{
            const {
                channel,
                template,
                subject,
                send_after_days,
                sender
            } = messages.filter(({ jobprofile_id })=>jobprofile_id===selectedProspects[recipientTab]?.jobProfileId)?.[0]
            return {
                ...channelOptions[channel],
                label: `Round ${index+1}`,
                id,
                send_after_days,
                channel,
                template,
                subject,
                sender
            }
        })

    const tabClick = index =>{
        // document.getElementById(`${recipientTab}-${index}`).scrollIntoView({behavior: "smooth", alignToTop: true, block:'start'});
        const id = `${recipientTab}-${index}`;
        const yOffset = -100; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    }

    
    return (
        <div
            className='outboxMessagesController'
        >
            <div>
                {tabs.map((tab, index)=>(
                    <React.Fragment>
                        <PillTabButton
                            key={index}
                            tab={tab}
                            selected={isInViewPortState===index}
                            onClick={e=>tabClick(index)}
                        />
                        {index<tabs.length-1 &&
                            <CaretLeft
                                style={{ transform: 'rotate(180deg)' }}
                                width="12"
                                height="24"
                            />
                        }
                    </React.Fragment>
                ))}
            </div>
            <div>
                {tabs.map((tab, index)=>(
                    <div
                        id={`${recipientTab}-${index}`}
                    >
                        <OutboxMessage
                            key={index}
                            onTabClick={tab=>onTabClick(tab, rounds[index]?.messages)}
                            togglePersonalise={togglePersonalise}
                            variableTranslator={variableTranslator}
                            handleChange={handleChange}
                            round={index}
                            {...tab}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default connect(state=>({
    rounds: state.outbox.campaigns.rounds,
    selectedProspects: state.prospectsView.candidates.selectedProspects,
    recipientTab: state.outbox.viewController.recipientTab,
}))(OutboxMessagesController)
