import {
  LocationFAQ1,
  LocationFAQ2,
  LocationFAQ3,
  LocationFAQ4,
  LocationFAQ5,
  LocationFAQ6,
} from "../Answers/Location";

export const LocationFAQS = [
  {
    heading: "What do I add in location?",
    content: <LocationFAQ1 />,
  },
  {
    heading: "How many locations can I add at a time?",
    content: <LocationFAQ2 />,
  },
  {
    heading: "Can I add zip codes?",
    content: <LocationFAQ3 />,
  },
  {
    heading: "Can I add regions (such as Central America, South America)?",
    content: <LocationFAQ3 />,
  },
  {
    heading: "Can I add time zones (such as PST?)",
    content: <LocationFAQ3 />,
  },
  {
    heading: "Can I add continents?",
    content: <LocationFAQ3 />,
  },
  {
    heading: "Can I add just a specific town?",
    content: <LocationFAQ4 />,
  },
  {
    heading: "Can I add a metro area?",
    content: <LocationFAQ4 />,
  },
  {
    heading: "Should I put it in a specific or general location?",
    content: <LocationFAQ5 />,
  },
  {
    heading:
      "This job is remote, I don’t really need to add the location. What should I do?",
    content: <LocationFAQ6 />,
  },
];
