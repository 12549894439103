import * as ActionTypes from "../../actions/QuickSetup";

const initialState = {
  isFetching: false,
  data: [],
  error: null,
  calibrationCycle: 0,
  selectedProfile: {},
  isDetailViewDrawerOpen: false,
  isViewOnly: false,
  timer: 0,
  shouldContinue: true,
  isModalOpen: false,
};

const calibrationProfiles = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CALIBRATION_PROFILES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case ActionTypes.FETCH_CALIBRATION_PROFILES_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        shouldContinue: action.shouldContinue,
        timer: action.timer,
        isFetching: false,
      };
    }
    case ActionTypes.FETCH_CALIBRATION_PROFILES_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    }
    case ActionTypes.SET_SELECTED_PROFILE:
      return {
        ...state,
        selectedProfile: action.profile,
      };
    case ActionTypes.TOGGLE_DETAIL_VIEW:
      return {
        ...state,
        isDetailViewDrawerOpen: action.isOpen,
      };
    case ActionTypes.UPDATE_CALIBRATION_CYCLE: {
      return {
        ...state,
        calibrationCycle: action.calibrationCycle,
      };
    }
    case ActionTypes.SET_TIMER_VALUE: {
      return {
        ...state,
        timer: action.timer,
      };
    }
    case ActionTypes.SET_IS_DISABLE_FEEDBACK: {
      return {
        ...state,
        isViewOnly: action.isViewOnly,
      };
    }
    case ActionTypes.CLEAR_CALIBRATION_DATA: {
      return initialState;
    }
    case "TOGGLE_COMPANY_MODAL": {
      return {
        ...state,
        isModalOpen: action.isModalOpen,
      };
    }
    default:
      return state;
  }
};

export default calibrationProfiles;

export const getCalibrationProfilesData = (state) => state.data;
export const getCalibrationProfilesIsFetching = (state) => state.isFetching;
export const getCalibrationCycle = (state) => state.calibrationCycle;
export const getSelectedProfile = (state) => state.selectedProfile;
export const getIsDetailViewDrawerOpen = (state) =>
  state.isDetailViewDrawerOpen;
export const getIsViewOnly = (state) => state.isViewOnly;
export const getTimerValueinSeconds = (state) => state.timer;
export const getShouldContinue = (state) => state.shouldContinue;
export const getCalibrationData = (state) => state;
