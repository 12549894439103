import * as ActionTypes from "../../actions/QuickSetup";

const initialState = {
  data: [],
};

const profileFeedback = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_FEEDBACK_ENTITY: {
      const { data } = action;
      const initialStructure = data?.map(
        ({ id, jobProfileId, experience = [], education = [] }) => ({
          jobProfileId,
          id,
          data: {
            isLiked: {
              count: 0,
            },
            isMaybe: false,
            isDisliked: false,
            about: {
              isLiked: false,
            },
            experience:
              !!experience &&
              experience?.map(({ urn }, index) => ({
                index,
                companyUrn: urn,
                isOverallLiked: false,
                isCompanyLiked: false,
                isDescriptionLiked: false,
                isTitleLiked: false,
              })),
            education:
              !!education &&
              education?.map(({ schoolName }, index) => ({
                index,
                schoolName,
                isOverallLiked: false,
                isDegreeLiked: false,
                isSchoolLiked: false,
              })),
            skills: {
              isOverallLiked: false,
              skills: [],
            },
            business: {
              isOverallLiked: false,
              industries: [],
            },
            relevantExperience: {
              isLiked: false,
            },
            totalExperience: {
              isLiked: false,
            },
          },
        })
      );

      return {
        data: initialStructure,
      };
    }
    case ActionTypes.UPDATE_FEEDBACK_ENTITY: {
      return {
        data: action.data,
      };
    }
    default:
      return state;
  }
};

export default profileFeedback;

export const getFeedbackEntityData = (state) => state.data;

export const getEntityByProfileID = (state, profileId) =>
  state.data && state.data.find(({ id }) => id === profileId);
