import { useEffect, useState } from 'react'
import GridLoader from 'react-spinners/GridLoader'
import { manualApiCall } from '../../utils'

import "./consent.scss"

const Granted = ({
  match: { params }
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  // const router = useRouter()
  // const { email } = router.query




  const email = params.email
  // const registerConsent = () => new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //         resolve()
  //     }, 500)
  // })

  const registerConsent = async () => {
    try {

      await manualApiCall(`/ds/consentRequest/grant`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
      })

      return true
    } catch (e) {
      console.log("AN ERROR OCCURED: ", e.message)
      throw e
    }
  }


  useEffect(async () => {
    if (email) {
      setIsLoading(true)
      await registerConsent()
      setIsLoading(false)
      setIsSuccessful(true)
    }
  }, [email])


  return (
    <div className={'consentContainer'}>

      <div className={'consentSection'}>
        <div className={'consentContent'}>
          {isLoading && <GridLoader size={15} margin={2} color={'#FF8D4E'} />}
          {!isLoading && isSuccessful && <p> Consent Registered, Thank you for trusting Qlu. </p>}
        </div>
      </div>

    </div>
  )
}

export default Granted