import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { setUser } from '../../../../actions/auth'
import useEffectAsync from '../../../../customHooks/useEffectAsync'
import Loading from '../../../../uiLibrary/Loading'
import PrimaryButton from '../../../../uiLibrary/PrimaryButton'
import PrimaryCheckBox from '../../../../uiLibrary/PrimaryCheckBox'
import PrimaryInput from '../../../../uiLibrary/PrimaryInput'
import { getRecaptcha, isMobileNumberValid, manualApiCall, manualPublicApiCall, send2FACode, signInToFirebase } from '../../../../utils'
import './style.scss'
const MultiFactorAuth = ({
    userData,
    emailProvider,
    dispatch
}) => {

    const [phone_number, set_phone_number] = useState("")
    const [userInfo, setUserInfo] = useState({})
    const [is_2fa_enabled, set_is_2fa_enabled] = useState(false)
    const [verifyMode, setVerifyMode] = useState(false)
    const [code, setCode] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingSave, setIsLoadingSave] = useState(false)

    useEffectAsync(async () => {
        set_is_2fa_enabled(userData.is_2fa_enabled)
        const phone = await manualApiCall("/api/v2/auth/user/phone", {
            method: 'GET'
        })
        set_phone_number((phone?.data && (phone.data?.[0] === "+" ? phone.data : '+' + phone.data)) || "")
    }, [])

    const toggle2FA = e => {
        set_is_2fa_enabled(prev => {
            if (!prev) {
                setVerifyMode(false)
            }
            if (prev) {
                manualApiCall('/api/v2/auth/updateUserData', {
                    method: 'POST',
                    body: JSON.stringify({ is_2fa_enabled: !prev })
                })
            }
            return !prev
        })
    }

    const handleSave = async e => {
        try {
            setIsLoadingSave(true)
            if (verifyMode) {
                window.confirmationResult.confirm(code).then(async (result) => {
                    const user = result?.user
                    // deleteFirebaseUser(user)
                    const newUser = await signInToFirebase({ ...userInfo, phoneNumber: phone_number, is_2fa_enabled })

                    if (newUser) {
                        setVerifyMode(false)
                        const token = await newUser.getIdToken(true)
                        localStorage.setItem('user', JSON.stringify({ ...userInfo, phone_number, is_2fa_enabled, token, sw: newUser?.stsTokenManager?.refreshToken }))
                        dispatch(setUser({ ...userInfo, phone_number, is_2fa_enabled }))
                        manualApiCall('/api/v2/auth/updateUserData', {
                            method: 'POST',
                            body: JSON.stringify({ is_2fa_enabled, phone_number, sw: newUser?.stsTokenManager?.refreshToken })
                        })
                        setCode("")
                        alert(`2 step verification enabled on ${phone_number}`)
                    }
                    else {
                        throw new Error("Re login Failed")
                    }
                }).catch(e => {
                    if (e.message.includes('auth/invalid-verification-code')) {
                        alert("Invalid Verification Code")
                    }
                    console.error("ERROR:", e)
                }).finally(() => {
                    setIsLoadingSave(false)
                })
            }
            else {
                // User token required for this public api call
                const r = await manualApiCall('/public/new/firebase/token',
                    { method: 'POST', body: JSON.stringify({ email: userData.email }) })
                if (r.isSuccess && !isEmpty(r.data)) {
                    setUserInfo(r?.data)
                    try {
                        getRecaptcha('recaptcha-verify-container', (k) => {
                            console.log("getRecaptcha -> k:", k)
                        })
                        send2FACode(phone_number)
                        setVerifyMode(true)
                    } catch (e) {
                        console.log("ERROR:", e)
                    } finally {
                        setIsLoadingSave(false)
                    }
                } else {
                    setIsLoadingSave(false)
                }
            }
        }
        catch (err) {
            console.log("ERROR: ", err.message)
        } finally {
            setIsLoadingSave(false)
        }
    }

    if (emailProvider !== "GENERIC") {
        return <div />
    }


    return (
        <div
            className='MultiFactorAuthContainer'
        >
            <div>
                <PrimaryCheckBox
                    checked={is_2fa_enabled}
                    onChange={toggle2FA}
                />
                <label>
                    {`2 step verification ${is_2fa_enabled ? "enabled" : "disabled"}`}
                </label>
                {isLoading &&
                    <Loading />
                }
            </div>
            {is_2fa_enabled &&
                <React.Fragment>
                    <div>
                        {verifyMode ?
                            <React.Fragment>
                                <label>
                                    Code:
                                </label>
                                <PrimaryInput
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                    placeholder="756489"
                                />
                            </React.Fragment> :
                            <React.Fragment>
                                <label>
                                    Phone Number:
                                </label>
                                <PrimaryInput
                                    placeholder="+534857146452"
                                    value={phone_number}
                                    onChange={e => set_phone_number(e.target.value)}
                                />
                            </React.Fragment>}
                        {isLoadingSave
                            ? <Loading />
                            : <PrimaryButton
                                onClick={handleSave}
                                disabled={verifyMode ? code?.trim() === "" : !isMobileNumberValid(phone_number)}
                            >
                                {verifyMode ? 'Verify' : 'Save Number'}
                            </PrimaryButton>}
                    </div>
                    <div id='recaptcha-verify-container' />
                </React.Fragment>
            }
        </div>
    )
}

export default MultiFactorAuth