import React, { useEffect, useState } from "react";
import { useParams, withRouter } from 'react-router-dom'

import { connect } from 'react-redux'

import './hybridConsentScreen.scss'

import { clearConsentWindow, setConsentWindow, setHybridCamapaignView, validateEmailPermissionToken } from "../../actions/hybridCampaign";
import Loader from "react-loader-spinner";
import SocialLoginButton from "../../components/SocialLoginButton";
import { setOutboxView } from "../../actions/outbox";
import { Mixpanel } from "../../utils/Mixpanel";

const HybridConsentScreen = ({
  dispatch,
  userEmail,
  emailState,
  style={},
  consentWindow,
  fte=false,
  redirectPath,
  history: {
    push
  }
}) => {
  
  const { jId } = useParams()

  useEffect(() => {
    return () => {
      window.removeEventListener('message', windowMessageListner)
    }
  }, [])  

  const handleClickAuthorize = () => {
    const API_HOST = process.env.REACT_APP_API_HOST
    window.addEventListener('message', windowMessageListner, false)
    const myWindow = window.open(`${API_HOST}/public/incAuthTest/permissions`,
      "_blank",
      "top=100,left=500,width=400,height=500");
    dispatch(setConsentWindow(myWindow))
  }

  const handleClickCancel = () => {
    if(fte){
      push(redirectPath)
      dispatch(setOutboxView({
        view: 'Edit Rounds',
        fte: false,
        redirectPath: '/'        
      }))
    }
    else{
      push(`/job/${jId}`)
      dispatch(setOutboxView({
        view: 'Edit Rounds',
        fte: false
      }))
    }
  }


  useEffect(() => {
    if (emailState) {
      if(consentWindow){
        consentWindow.close()
        dispatch(clearConsentWindow())
      }
      if(fte){
        push(redirectPath)
      }
      dispatch(setOutboxView({
        view: 'Edit Rounds',
        fte: false,
        redirectPath: '/'     
      })) 
    }
  }, [emailState])

  const windowMessageListner = async (event) => {
    try {
      // console.log("===========windowMessageListner==================", {event})
      const email = JSON.parse(event.data).query.email
      if (userEmail === email) {
        dispatch(validateEmailPermissionToken())
      }
      else {
        alert("Authenticate the email you are logged in with")
        // console.log("===========consentWindow==================", {consentWindow})
      }
    }
    catch (e) {
      console.log("ERROR IN WINDOW POST MESSAGE: ", e)
    }
  }

  return (
    <div style={style} className='hybridConsentScreenContainer'>
      <div className='auth-consent'>
        <h2>Authorization Requried</h2>
        <p>Sign in and authorize Qlu to send and receive emails on your behalf from your Gmail.</p>
        <div className='consent-actions'>
          <p onClick={handleClickCancel} >Cancel</p>
          <SocialLoginButton onClick={handleClickAuthorize} type='login' provider='google' />
        </div>
      </div>
    </div>
  )
}

export default withRouter(connect(state => ({
  campaignType: state.hybridCampaign.campaignOperations.campaignType,
  userEmail: state.auth.user.email,
  fte: state.outbox.viewController.fte,
  redirectPath: state.outbox.viewController.redirectPath,
  emailState: state.hybridCampaign.campaignOperations.emailState,
  consentWindow: state.hybridCampaign.campaignOperations.consentWindow
}))(HybridConsentScreen))