import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import usePrevious from '../../../customHooks/usePrevious'
import './sectionActionBar.scss'

const JobSectionHeader = ({
  label = 'Sample Label',
  onPageChange,
  pageCount,
  onSearch
}) => {
  const [search, setSearch] = useState('')
  const [searchTimeout, setSearchTimout] = useState(null)


  const prevSearchValue = usePrevious(search)

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    setSearchTimout(setTimeout(() => {
      // console.log('PREVIOUS AND CURRENT SEARCH VALUES: ', { prevSearschValue, search })
      if (prevSearchValue && prevSearchValue !== search) {
        onSearch(search)
      }
    }, 500))
  }, [search])

  return (
    <div className='jobActionHeader'>
      <p>{label}</p>
      <div>
        <ReactPaginate
          className='sectionPagination'
          pageCount={pageCount}
          nextLabel='>'
          previousLabel='<'
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          onPageChange={onPageChange}
          renderOnZeroPageCount={null}
        />
        <input placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} />
      </div>
    </div>
  )
}

export default JobSectionHeader