
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import TextEditor from '../../../components/TextEditor/TextEditor';
import { changeJobDescription, initJobCriteria, initJobsetup, initJobSpecialities, initTribeMembers } from '../../../actions/JobSetup';

import './jobDetailsDescription.scss'
import { Mixpanel } from '../../../utils/Mixpanel';
const JobDetailsDescription = ({
    description,
    isReview,
    dispatch,
    isEditorDisabled=false
}) => {

    useEffect(()=>{    
        Mixpanel.track("JD - Deep Search")
    }, [])

    useEffect(() => {

    }, [isReview])

    const handleChange = (value) => {
        if(isReview !== '1') {
            dispatch(changeJobDescription(value))
            dispatch(initJobsetup())
            dispatch(initJobCriteria());
            dispatch(initJobSpecialities());
            dispatch(initTribeMembers());
        }
    }

    return (
        <TextEditor
            value={description}
            handleChange={handleChange}
            pasteHandler={handleChange}
            isReview={isReview}
            isEditorDisabled={isEditorDisabled}
        />
    )
}

export default connect(state => (
    {
        description: state.jobSetup.jobDetails.description,
        isReview: state.jobSetup?.jobSetup?.isReview
    }
))(JobDetailsDescription)
