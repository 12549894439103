import React, { useEffect } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { Button } from "reactstrap";
import SingleComment from "../../../components/CommentsSection/SingleComment";
import Send from "../../../components/svgComponents/Send";
import NotificationPanel from "../../../views/LandingPage/Home/NotificationPanel";
import "./TribesCommentSection.scss";
const comments = [
  {
    senderImage: "RZ",
    senderName: "Robert Zack",
    time: "7 hrs",
    senderColor: "#63B780",
    senderBackgroundColor: "#29382E",
    messageText: `Do you think we can consider someone like this in our team?\nI believe he’s a bit over-experienced.`,
    emojies: [
      {
        type: "👀",
        count: 5,
      },
    ],
    hasReply: false,
    replyUser: "",
    replyText: ``,
    replyUserColor: "",
    isUser: false,
  },
  {
    senderImage: "ME",
    senderName: "You",
    time: "1 hrs",
    senderColor: "#9CA8E1",
    senderBackgroundColor: "#9CA8E133",
    messageText: `I think we should put him to the next round already.`,
    emojies: [],
    hasReply: false,
    replyUser: "",
    replyText: ``,
    replyUserColor: "",
    isUser: true,
  },
  {
    senderImage: "AR",
    senderName: "Alex Rogers",
    time: "30 mins",
    senderColor: "#63B780",
    senderBackgroundColor: "#29382E",
    messageText: `Yes please <strong>Joe</strong>`,
    emojies: [],
    hasReply: true,
    replyUser: "Robert Zack",
    replyText: `I think we should put him to the next round already.`,
    replyUserColor: "#63B780",
    isUser: false,
  },
  {
    senderImage: "RZ",
    senderName: "Robert Zack",
    time: "7 hrs",
    senderColor: "#63B780",
    senderBackgroundColor: "#29382E",
    messageText: `Do you think we can consider someone like this in our team?\nI believe he’s a bit over-experienced.`,
    emojies: [
      {
        type: "👀",
        count: 5,
      },
    ],
    hasReply: false,
    replyUser: "",
    replyText: ``,
    replyUserColor: "",
    isUser: false,
  },
  {
    senderImage: "ME",
    senderName: "You",
    time: "1 hrs",
    senderColor: "#9CA8E1",
    senderBackgroundColor: "#9CA8E133",
    messageText: `I think we should put him to the next round already.`,
    emojies: [],
    hasReply: false,
    replyUser: "",
    replyText: ``,
    replyUserColor: "",
    isUser: true,
  },
  {
    senderImage: "AR",
    senderName: "Alex Rogers",
    time: "30 mins",
    senderColor: "#63B780",
    senderBackgroundColor: "#29382E",
    messageText: `Yes please <strong>Joe</strong>`,
    emojies: [],
    hasReply: true,
    replyUser: "Robert Zack",
    replyText: `I think we should put him to the next round already.`,
    replyUserColor: "#63B780",
    isUser: false,
  },
];
export default function TribesCommentSection({ shouldShow }) {
  const [newComment, setNewComment] = React.useState("");
  const [newHeight, setNewHeight] = React.useState("");
  const handleCommentInputChange = (e) => {
    setNewComment(e.target.value);
  };
  console.log(shouldShow, "should show??");
  const listenScrollEvent = (e) => {
    if (window.scrollY > 90) {
      setNewHeight("newHeight");
    } else {
      setNewHeight("");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);
  return (
    <div className={`tribesCommentSection ${shouldShow ? "expanded" : ""}`}>
      <div className="head">
        <div>Tribe Notifications</div>
        {/* <div>
          <Button className="btn">View All</Button>
        </div> */}
      </div>
      <div className={`main  ${newHeight ? newHeight : ""}`}>
        {/* {comments.map((comment, index) => {
          return (
            <React.Fragment key={index}>
              <SingleComment
                comment={comment}
                key={index}
                deleteClicked={() => {}}
                replyCommentCreate={() => {}}
                showEmojiPickerOnCommentDispatch={() => {}}
                showEmojiPickerCommentID={() => {}}
                handleEmojiPicked={() => {}}
                deleteEmoji={() => {}}
              />
            </React.Fragment>
          );
        })} */}

        <NotificationPanel isTribeScreen={true} />
      </div>
      <div className="footer">
        {/* <MentionsInput
          //   onKeyDown={(e) => handleShiftEnterSend(e)}
          style={{ width: "320px" }}
          className="inputInput"
          value={newComment}
          placeholder="Type a message or @ others"
          onChange={(e) => handleCommentInputChange(e)}
        >
          <Mention
            trigger="@"
            // data={tribeMembers}
            displayTransform={(id, display) => `@${display}`}
            markup="@[__display__](__id__)"
            appendSpaceOnAdd={true}
          />
        </MentionsInput>

        <Button
          className="messageSend"
          disabled={newComment.length < 1}
          //   onClick={() => handleMessageSend()}
        >
          <Send width="20" height="20" stroke="2" color="#8A4829" />
        </Button> */}
      </div>
    </div>
  );
}
