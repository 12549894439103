import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "reactstrap";
import {
  addAdditionalSpecialty,
  addJobSpeciality,
  changeAdditionalJobSpecialty,
  changeJobSpeciality,
  fetchAdditionalSpecialtyResults,
  fetchSpecialityResults,
  initGeneratedSpecialty,
  removeAdditionalSpecialty,
  removeJobSpeciality, setIsSpecialtyEdited, setJobSetupView
} from "../../../actions/JobSetup";
import CrossSvg from "../../../components/svgComponents/CrossSvg";
import "../../../components/_globalCSSClasses.scss";
import BigRoundButton from "../../../uiLibrary/BigRoundButton";
import CarouselTag from "../../../uiLibrary/CarouselTag";
import LabelButton from "../../../uiLibrary/LabelButton";
import Loading from "../../../uiLibrary/Loading";
import PillButton from "../../../uiLibrary/PillButton";
import SecondaryButton from "../../../uiLibrary/SecondaryButton";
import CaretDown from "../../../uiLibrary/SVGs/CaretDown";
import InfoSvg from "../../../uiLibrary/SVGs/InfoSvg";
import JobSetupStep04 from "../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep04";
import PlusSvg from "../../../uiLibrary/SVGs/PlusSvg";
import TabButton from "../../../uiLibrary/TabButton";
import { Mixpanel } from "../../../utils/Mixpanel";
import CompanyCard from "../CompanyCard";
import JobDescriptionCard from "../JobDescriptionCard";
import JobSetupAutoSuggest from "../JobSetupAutoSuggest";
import ProfileSpecialtyCard from "../ProfileSpecialtyCard";
import SuggestedSpecialtyCard from "../SuggestedSpecialtyCard";
import "./specialityDetails.scss";

const SpecialityDetailsInput = ({
  toggleExperience = (e) => false,
  experienceSelected = false,
  experienceMin,
  experienceMax,
  onChangeRange,
  toggleFocus,
  filter,
  value,
  onChange,
  toggle,
  results,
  variantSuggestions = [],
  isLoading = false,
  isReview
}) => {
  const [shouldFetch, setShouldFetch] = useState(false)
  const [display, setDisplay] = useState(true)

  return (
    <span
      style={{ width: "100%" }}
    >
      {/* <input value={value} onChange={onChange} /> */}
      <span>
        <span>
          <JobSetupAutoSuggest
            apiType='specialty'
            toggleFocus={toggleFocus}
            filter={filter}
            inputValue={value}
            shouldFetch={shouldFetch}
            handleChangeInput={e => {
              setShouldFetch(true)
              onChange(e.target.value)
            }
            }
            handleSuggestionSelected={value => {
              setShouldFetch(false)
              onChange(value)
            }}
          />
          {isLoading && <Loading />}
        </span>
        {/* <div
          className='specialityDetailsExperienceContainer'
        >
        {experienceSelected ? (
          <React.Fragment>
            <RangeInput
              value={{
                min: experienceMin,
                max: experienceMax,
              }}
              onChange={onChangeRange}
              resultLabel={`${experienceMin} to ${experienceMax} years`}
            />
            <button className="addMoreButton" onClick={toggleExperience}>
              <MinusSvg />
              Remove
            </button>
          </React.Fragment>
        ) : (
          <button className="addMoreButton" onClick={toggleExperience}>
            <PlusSvg />
            Add experience
          </button>
        )}
        </div> */}
        <BigRoundButton onClick={toggle}>
          <CrossSvg width="16" height="16" />
        </BigRoundButton>
      </span>
      {results <= 10 && !isLoading && display && // todo: fix this 
        <span>
          <div>
            <InfoSvg />
            <h6>
              Too few results, try:
            </h6>
          </div>
          <div>
            {variantSuggestions?.map(({ variant }, index) => (
              <CarouselTag
                key={index}
                greyTheme={value?.trim()?.toLowerCase() !== variant?.trim()?.toLowerCase()}
                selected={true}
                highlight={value?.trim()?.toLowerCase() === variant?.trim()?.toLowerCase()}
                onClick={isReview === '1' ? () => false : e => { setDisplay(false); onChange(variant) }}
              >
                {variant}
              </CarouselTag>
            ))}
          </div>
        </span>}
    </span>
  );
};

const SpecialityDetails = ({
  company_speciality,
  specialities,
  additionalSpecialties,
  dispatch,
  cards,
  tabs,
  currentTab,
  shouldSelectLastTab,
  isReview
}) => {
  const [editingMode, setEditingMode] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [myTimeout, setMyTimeout] = useState(null);
  const [isJDOpen, setIsJDOpen] = useState(false)
  const [toggleFocusArray, setToggleFocusArray] = useState([]);

  const [customTimeout, setCustomTimeout] = useState(null)
  const [customeTimeout2, setCustomTimeout2] = useState(null)

  const [removeDisplay, setRemoveDisplay] = useState({})


  const selectedSpeciality = Object.keys(specialities).filter(
    (key) => specialities[key].selected
  );
  const unselectedSpeciality = Object.keys(specialities).filter(
    (key) => !specialities[key].selected
  );
  let specialitiesFromJD = []
  let specialitiesFromCompany = []
  let specialitiesFromProfile = []
  let specialitiesSuggested = []
  switch (tabs[currentTab]) {
    case 'From JD':
      specialitiesFromJD = Object.keys(specialities).filter(
        (key) => specialities[key]?.source?.includes("jobDescription")
      );
      break;
    case 'From Companies':
      specialitiesFromCompany = Object.keys(specialities).filter(
        (key) => specialities[key]?.source?.includes("companySelected") || specialities[key]?.source?.includes("companyHiring")
      );
      console.log("specialitiesFromCompany",specialitiesFromCompany,specialities)
      break;
    case 'From Best Fits':
      specialitiesFromProfile = Object.keys(specialities).filter(
        (key) => specialities[key]?.source?.includes("idealProfile")
      );
      break;
    case 'Suggested':
      specialitiesSuggested = Object.keys(specialities).filter(
        (key) => specialities[key]?.source?.includes("generated")
      );
      break;

    default:
      break;
  }

  useEffect(() => {
    dispatch(setJobSetupView({
      highlightValue: "",
      profileHighlightValue: [],
      selectedValues: []

    }))
    const newTabs = { tabs: [...tabs] };
    // console.log("===============SpecialityDetails====================", company_speciality)
    company_speciality.map(
      (
        {
          name,
          min,
          max,
          experienceSelected,
          selected,
          source = [],
          sourceId,
          spans,
          synonym = []
        },
        i
      ) => {
        // console.log("===============SpecialityDetailsnewTabs.tabs====================", newTabs.tabs)
        if (newTabs.tabs.length < 4) {
          source.forEach((src) => {
            // console.log("==============company_speciality=================", {src, newTabs})
            if (newTabs.tabs.length === 4) {
              return false;
            }
            switch (src) {
              case "jobDescription":
                if (!newTabs.tabs.includes("From JD")) {
                  newTabs.tabs = ["From JD", ...newTabs.tabs];
                }
                break;
              case "companyHiring":
              case "companySelected":
                if (!newTabs.tabs.includes("From Companies")) {
                  if (newTabs.tabs.includes("From JD")) {
                    newTabs.tabs = ["From JD", "From Companies", ...tabs];
                  } else {
                    newTabs.tabs = ["From Companies", ...tabs];
                  }
                }
                break;
              case "idealProfile":
                if (!newTabs.tabs.includes("From Best Fits")) {
                  if (newTabs.tabs.includes("From JD") && newTabs.tabs.includes("From Companies")) {
                    newTabs.tabs = ["From JD", "From Companies", "From Best Fits", ...tabs];
                  }
                  else if (newTabs.tabs.includes("From JD")) {
                    newTabs.tabs = ["From JD", "From Best Fits", ...tabs];
                  }
                  else if (newTabs.tabs.includes("From Companies")) {
                    newTabs.tabs = ["From Companies", "From Best Fits", ...tabs];
                  } else {
                    newTabs.tabs = ["From Best Fits", ...tabs];
                  }
                }
                break;

              case "generated":
                if (!newTabs.tabs.includes("Suggested")) {
                  // console.log("==================Suggested==========================", newTabs.tabs)
                  if (newTabs.tabs.includes("From Best Fits")) {
                    newTabs.tabs = [...newTabs.tabs.slice(0, newTabs.tabs.indexOf("From Best Fits") + 1), "Suggested", ...newTabs.tabs.slice(newTabs.tabs.indexOf("From Best Fits") + 1)];
                  }
                  else if (newTabs.tabs.includes("From Companies")) {
                    newTabs.tabs = [...newTabs.tabs.slice(0, newTabs.tabs.indexOf("From Companies") + 1), "Suggested", ...newTabs.tabs.slice(newTabs.tabs.indexOf("From Companies") + 1)];
                  }
                  else {
                    newTabs.tabs = ["Suggested", ...tabs];
                  }
                }
                break
              default:
                break;
            }
          })
        }
        dispatch(
          addJobSpeciality({
            name: name?.trim(),
            spans,
            experienceSelected,
            experience: {
              min,
              max
            },
            selected,
            synonym,
            source,
            sourceId
          })
        )
      })
    if (newTabs.tabs.length > 1) {
      dispatch(
        setJobSetupView({
          ...newTabs,
          currentTab: shouldSelectLastTab ? newTabs.tabs.length - 1 : 0,
          shouldSelectLastTab: false
        })
      );
    }
    else if (shouldSelectLastTab) {
      dispatch(setJobSetupView({
        shouldSelectLastTab: false
      }))
    }
  }, []);

  useEffect(() => {
    
    const selectedValues = tabs[currentTab] === 'Details' ?
      specialitiesFromJD.map(key => ({
        ...specialities[key],
        key,
        onClick: e => {
          dispatch(setIsSpecialtyEdited(true))
          toggleSpecialitySelection(key, false)}
      })) :
      tabs[currentTab] === 'From Companies' ?
        specialitiesFromCompany.map(key => ({
          ...specialities[key],
          key,
          onClick: e => {
            if (tabs.includes("Suggested")) {
              dispatch(initGeneratedSpecialty())
            }
            dispatch(setIsSpecialtyEdited(true))
            toggleSpecialitySelection(key, false)
          }
        })) :
        tabs[currentTab] === 'From Best Fits' ?
          specialitiesFromProfile.map(key => ({
            ...specialities[key],
            key,
            onClick: e => {
              if (tabs.includes("Suggested")) {
                dispatch(initGeneratedSpecialty())
              }
              dispatch(setIsSpecialtyEdited(true))
              toggleSpecialitySelection(key, false)
            }
          })) :
          tabs[currentTab] === 'Suggested' ?
            specialitiesSuggested.map(key => ({
              ...specialities[key],
              key,
              onClick: e => {            
                dispatch(setIsSpecialtyEdited(true))
                toggleSpecialitySelection(key, false)}
            })) : []
            console.log("selected valuess before dispatch",selectedValues,tabs[currentTab])
    
    dispatch(setJobSetupView({
      selectedValues
    }))
  }, [specialities, currentTab, tabs])

  useEffect(() => {
    Mixpanel.track(`Specialties ${tabs[currentTab]}`)
    if (tabs[currentTab] === 'Details') {
      setToggleFocusArray(additionalSpecialties.map(i => false))
      if (isReview !== '1') {
        selectedSpeciality.map(key => {
          dispatch(fetchSpecialityResults(specialities[key], key))
        })
      }
    }
  }, [currentTab])



  const toggleSpecialitySelection = (key, flag = true) => {
    const selected = !specialities[key].selected
    dispatch(setIsSpecialtyEdited(true))
    if (selected && flag) {
      dispatch(setJobSetupView({
        highlightValue: specialities[key].name.toLowerCase().trim(),
        profileHighlightValue: [specialities[key].name.toLowerCase().trim(), ...specialities[key]?.synonym.filter(({ source = [] }) => source.includes("idealProfile") || source.includes("companySelected") || source.includes("companyHiring")).map(({ name }) => name.toLowerCase().trim())],

      }))
    }
    dispatch(
      changeJobSpeciality({
        key,
        value: {
          selected,
        },
      })
    )
  }

  const handleChangeExperience = (value, key) => {
    dispatch(setIsSpecialtyEdited(true))
    dispatch(
      changeJobSpeciality({
        key,
        value: {
          experience: value,
        },
      })
    );
  };

  const toggleExperienceImportance = (e, key) => {

    dispatch(setIsSpecialtyEdited(true))
    dispatch(
      changeJobSpeciality({
        key,
        value: {
          experienceSelected: !specialities[key].experienceSelected,
        },
      })
    );
  };

  const handlePillBlur = (e, key) => {
    if ("" === e.target.value) {
      dispatch(removeJobSpeciality(key));
    }
  };



  const fetchSpecResults = (key) => {

    if (customTimeout) {
      clearTimeout(customTimeout)
      setCustomTimeout(
        setTimeout(() => {
          // console.log('LOGGED ON LINE 432')
          dispatch(fetchSpecialityResults(specialities[key], key))
        }, 500)
      )
    } else {
      setCustomTimeout(
        setTimeout(() => {
          // console.log('LOGGED ON LINE 439')
          dispatch(fetchSpecialityResults(specialities[key], key))
        }, 500)
      )
    }
  }

  const handleNameChange = (name, key) => {
    // console.log("====================handleNameChange========================", specialities[key])
    // dispatch(fetchSpecialityResults(specialities[key], key))
    // setRemoveDisplay({...removeDisplay, [key] : true})

    dispatch(setIsSpecialtyEdited(true))
    fetchSpecResults(key)
    if ((specialities[key]?.source || []).length > 0) {
      dispatch(changeJobSpeciality({
        key,
        value: {
          name,
          synonym: [],
          source: [],
          sourceId: [],
        },
      }))
      dispatch(addJobSpeciality({
        ...specialities[key],
        selected: false
      }))
    }
    else {
      dispatch(
        changeJobSpeciality({
          key,
          value: {
            name,
          },
        })
      );
    }
  };

  const fetchAdditionalSpecResults = (name, index) => {
    const specialty = additionalSpecialties[index]
    console.log("FETCH RESULTS AGAINST: ", { name, index, specialty })

    if (name) {
      dispatch(fetchAdditionalSpecialtyResults({ ...specialty, name: name }))
    }
    // console.log("")
  }

  const handleAdditionalNameChange = (name, index) => {

    if (customeTimeout2) {
      clearTimeout(customeTimeout2)
      setCustomTimeout2(
        setTimeout(() => {
          fetchAdditionalSpecResults(name, index)
        }, 800)
      )
    } else {
      setCustomTimeout2(
        setTimeout(() => {
          fetchAdditionalSpecResults(name, index)
        }, 800)
      )
    }

    console.log("HANDLE ADDITIONAL NAME CHANGE", { name, index })
    dispatch(setIsSpecialtyEdited(true))
    dispatch(changeAdditionalJobSpecialty({ index, values: { name } }))
  }

  const handleRemoveAdditionalSpecialty = (index) => {
    setToggleFocusArray(toggleFocusArray => toggleFocusArray.filter((itm, i) => i !== index))
    dispatch(removeAdditionalSpecialty(index))

    dispatch(setIsSpecialtyEdited(true))
  }


  const handleEdit = (e) => {
    setEditorValue(e.target.value);
  };

  const handleOpenEditor = (e) => {
    if (additionalSpecialties.some(itm => itm.name.trim() === "")) {
      let empytyIndex = additionalSpecialties.length - 1
      let emptyIndexSet = false
      for (let index = additionalSpecialties.length - 1; index >= 0 && !emptyIndexSet; index--) {
        const { name } = additionalSpecialties[index];
        if (name.trim() === "") {
          empytyIndex = index
          emptyIndexSet = true
        }
      }
      setToggleFocusArray(toggleFocusArray => toggleFocusArray.map((itm, i) => i === empytyIndex ? !itm : itm))
    }
    else {
      setToggleFocusArray(toggleFocusArray => [...toggleFocusArray, false])
      dispatch(addAdditionalSpecialty({}))
      setTimeout(() => {
        document.getElementById("addAdditionalBusinessButton").scrollIntoView({ behavior: "smooth" })
      })
    }
  };

  const handleAddSpeciality = (name) => {
    // console.log("=====================handleAddSpeciality======================", Object.keys(specialities).map(key=>Object.keys(specialities)[key]?.name.trim().toLowerCase()), name)
    if (Object.keys(specialities).map(key => specialities[key]?.name.trim().toLowerCase()).includes(name.trim().toLowerCase())) {
      const key = Object.keys(specialities).filter(key => specialities[key]?.name.trim().toLowerCase() === name.trim().toLowerCase())?.[0]
      dispatch(
        changeJobSpeciality({
          key,
          value: {
            selected: true,
          },
        })
      );
    } else {
      //dispatch(setIsSpecialtyEdited(true))
      dispatch(
        addJobSpeciality({
          name,
          selected: true,
        })
      );
    }
    setEditorValue("");
    setEditingMode(false);
  };

  const handleDiscardSpeciality = () => {
    setEditorValue("");
    setEditingMode(false);
  };

  const handleMouseEnter = (key) => {
    if (myTimeout) {
      clearTimeout(myTimeout);
      setMyTimeout(
        setTimeout(
          (e) =>
            dispatch(
              setJobSetupView({
                highlightValue: specialities[key].name.toLowerCase().trim(),
                profileHighlightValue: [specialities[key].name.toLowerCase().trim(), ...specialities[key]?.synonym.filter(({ source = [] }) => source.includes("idealProfile") || source.includes("companySelected") || source.includes("companyHiring")).map(({ name }) => name.toLowerCase().trim())],
              })
            ),
          300
        )
      );
    } else {
      setMyTimeout(
        setTimeout(
          (e) =>
            dispatch(
              setJobSetupView({
                highlightValue: specialities[key].name.toLowerCase().trim(),
                profileHighlightValue: [specialities[key].name.toLowerCase().trim(), ...specialities[key]?.synonym.filter(({ source = [] }) => source.includes("idealProfile") || source.includes("companySelected") || source.includes("companyHiring")).map(({ name }) => name.toLowerCase().trim())],
              })
            ),
          300
        )
      );
    }
  };
  const handleMouseLeave = (key) => {
    clearTimeout(myTimeout);
    dispatch(
      setJobSetupView({
        profileHighlightValue: [],
        highlightValue: "",
      })
    );
  };


  return (
    <div className="specialityDetailsController">
      <div id="specialityDetailsControllerHeader" className="specialityDetailsControllerHeader">
        <span>
          <JobSetupStep04 selected={true} />
          <h2>Business, Specialties or Industry</h2>
        </span>
        <span>
          {tabs.map((tab, index) => (
            <TabButton
              key={tab}
              tab={{ name: tab, index, disabled: true }}
              selected={tab === tabs[currentTab]}
            // onClick={({ index }) =>
            //   dispatch(setJobSetupView({ currentTab: index }))
            // }
            />
          ))}
        </span>
      </div>
      <div className="specialityDetailsDisclaimer">
        <p>
          A Business is a sub-industry which indicates the domain of
          products or services a certain company works in.
        </p>
        <p>
          For example, Meta’s Business are Virtual Reality, Social
          Media, Connectivity and Augmented Reality.
        </p>
      </div>
      {(() => {
        switch (tabs[currentTab]) {
          case "From JD":
            return (
              <div className="specialityDetailsControllerBody">
                <div>
                  <p>
                    A Business is a sub-industry which indicates the domain of
                    products or services a certain company works in.
                  </p>
                  <p>
                    For example, Meta’s Business are Virtual Reality, Social
                    Media, Connectivity and Augmented Reality.
                  </p>
                </div>
                <span>
                  {specialitiesFromJD.map((key) => (
                    <PillButton
                      key={key}
                      selected={specialities[key].selected}
                      highlight={true}
                      onClick={(e) => isReview === '1' ? () => false : toggleSpecialitySelection(key)}
                      onMouseEnter={(e) => handleMouseEnter(key)}
                      onMouseLeave={(e) => handleMouseLeave(key)}
                    >
                      {specialities[key].name}
                    </PillButton>
                  ))}
                </span>
              </div>
            );
          case "From Companies":
            return (
              <div
                className="specialityDetailsControllerBody"
                style={{
                  justifyContent: 'center'
                }}>
                <span
                  style={{
                    width: "min(80%, 1080px)",
                    alignSelf: "center",
                  }}
                >
                  <CompanyCard hasSynonym={true} />;
                  {/* {specialitiesFromCompany.map((key) => (
                    <PillButton
                      key={key}
                      selected={specialities[key].selected}
                      highlight={true}
                      onClick={(e) => isReview === '1' ? () => false : toggleSpecialitySelection(key)}
                      onMouseEnter={(e) => handleMouseEnter(key)}
                      onMouseLeave={(e) => handleMouseLeave(key)}
                    >
                      {specialities[key].name}
                    </PillButton>
                  ))} */}
                </span>
              </div>
            );
          case "From Best Fits":
            return (
              <div
                className="specialityDetailsControllerBody"
                style={{
                  justifyContent: 'center'
                }}>
                <span
                  style={{
                    width: "min(80%, 1080px)",
                    alignSelf: "center",
                  }}
                >
                  <ProfileSpecialtyCard hasSynonym={true} />;
                  {/* {specialitiesFromProfile.map((key) => (
                    <PillButton
                      key={key}
                      selected={specialities[key].selected}
                      highlight={true}
                      onClick={(e) => isReview === '1' ? () => false :  toggleSpecialitySelection(key)}
                      onMouseEnter={(e) => handleMouseEnter(key)}
                      onMouseLeave={(e) => handleMouseLeave(key)}
                    >
                      {specialities[key].name}
                    </PillButton>
                  ))} */}
                </span>
              </div>
            );
          case "Suggested":
            return (
              <div
                className="specialityDetailsControllerBody"
                style={{
                  justifyContent: 'center'
                }}>
                <span
                  style={{
                    width: "min(80%, 1080px)",
                    alignSelf: "center",
                  }}
                >
                  <SuggestedSpecialtyCard />;
                </span>
              </div>
            );
          case "Details":
            return (
              <React.Fragment>
                {tabs.includes('From JD') &&
                  <div className="specialityDetailsJDModalContainer">
                    <Modal
                      className='specialityDetailsJDModal'
                      isOpen={isJDOpen}
                      toggle={e => setIsJDOpen(prev => !prev)}
                    >
                      <LabelButton
                        onClick={e => setIsJDOpen(prev => !prev)}
                      >
                        <CrossSvg />
                      </LabelButton>
                      <JobDescriptionCard
                        // containerStyle
                        cardStyle={{
                          height: 'auto',
                          padding: '0 12px'
                        }}
                      />
                    </Modal>
                    <LabelButton
                      onClick={e => setIsJDOpen(prev => !prev)}
                    >
                      View JD
                      <CaretDown />
                    </LabelButton>
                  </div>}
                <div
                  style={{
                    width: "min(70%, 1080px)",
                    alignSelf: "center",
                    paddingBottom: 0,
                    justifyContent: 'space-between'
                  }}
                  className="specialityDetailsControllerBody"
                >
                  <span
                    style={{ width: "100%" }}
                  >
                    <h5>
                      Business
                    </h5>
                    {/* <h5>
                      Experience
                    </h5> */}
                  </span>
                  {selectedSpeciality.map((key, index) => (
                    <SpecialityDetailsInput
                      // display={removeDisplay[key]}
                      // id={key}
                      // removeDisplay={removeDisplay}
                      key={`sKey${index}`}
                      isReview={isReview}
                      isLoading={specialities[key]?.fetchingResults}
                      results={specialities[key]?.results}
                      variantSuggestions={specialities[key]?.variantSuggestions}
                      filter={d => !([...selectedSpeciality.map(key => specialities[key]?.name.trim().toLowerCase()), ...additionalSpecialties.map(itm => itm?.name.trim().toLowerCase())].includes(d.trim().toLowerCase()))}
                      value={specialities[key].name}
                      onChange={(e) => isReview === '1' ? () => false : handleNameChange(e, key)}
                      toggle={(e) => isReview === '1' ? () => false : toggleSpecialitySelection(key)}
                      experienceSelected={
                        specialities[key].experienceSelected
                      }
                      toggleExperience={isReview === '1' ? () => false : (e) =>
                        toggleExperienceImportance(e, key)
                      }
                      experienceMin={specialities[key].experience.min}
                      experienceMax={specialities[key].experience.max}
                      onChangeRange={isReview === '1' ? () => false : (value) =>
                        handleChangeExperience(value, key)
                      }
                    />
                  ))}
                  {selectedSpeciality.length > 0 || additionalSpecialties.length > 0 ?
                    additionalSpecialties.map((specialty, index) => {
                      return <SpecialityDetailsInput
                        key={`sKey${index}`}
                        isLoading={specialty?.fetchingResults}
                        results={specialty?.results}
                        variantSuggestions={specialty?.variantSuggestions}
                        toggleFocus={toggleFocusArray[index]}
                        filter={d => !([...selectedSpeciality.map(key => specialities[key]?.name.trim().toLowerCase()), ...additionalSpecialties.map(itm => itm?.name.trim().toLowerCase())].includes(d.trim().toLowerCase()))}
                        value={specialty.name}
                        onChange={e => handleAdditionalNameChange(e, index)}
                        toggle={e => handleRemoveAdditionalSpecialty(index)}
                      />
                    }
                    ) : (
                      <h3>No Business detected</h3>
                    )}
                </div>
                <div
                  style={{
                    width: "min(80%, 1080px)",
                    alignSelf: "center",
                  }}
                  className="specialityDetailsControllerFooter"
                >
                  {
                    selectedSpeciality.length > 0 || additionalSpecialties.length > 0 ?
                      isReview !== '1' && <button id="addAdditionalBusinessButton" className="addMorePrimaryButton" onClick={handleOpenEditor} >
                        <PlusSvg selected={true} />
                        Add a Business
                      </button> :
                      isReview !== '1' && <SecondaryButton onClick={handleOpenEditor}>
                        <PlusSvg selected={true} />
                        Add a Business
                      </SecondaryButton>
                  }
                  {/* {editingMode ? (
                    <span> */}
                  {/* <input value={editorValue} onChange={isReview === '1' ? () => false : handleEdit} /> */}
                  {/* <JobSetupAutoSuggest
                        apiType='specialty'
                        filter={d=>d.trim()!==""}
                        inputValue={editorValue}
                        handleChangeInput={isReview === '1' ? () => false : handleEdit}
                        handleSuggestionSelected={handleAddSpeciality}
                      /> */}
                  {/* <BigRoundButton onClick={isReview === '1' ? () => false : handleAddSpeciality}>
                        <PlusSvg />
                      </BigRoundButton> */}
                  {/* <BigRoundButton onClick={isReview === '1' ? () => false : handleDiscardSpeciality}>
                        <CrossSvg width="16" height="16" />
                      </BigRoundButton>
                    </span>
                  ) : selectedSpeciality.length > 0 ? (
                    isReview === '1' ? '' :
                    <button
                      className="addMorePrimaryButton"
                      onClick={handleOpenEditor}
                    >
                      <PlusSvg selected={true} />
                      Add a Business
                    </button>
                  ) : (
                    isReview === '1' ? '' :
                    <SecondaryButton idx="addBusinessButton" onClick={handleOpenEditor}>
                      <PlusSvg selected={true} />
                      Add a Business
                    </SecondaryButton>
                  )} */}
                </div>
              </React.Fragment>
            );
          default:
            break;
        }
      })()}
    </div>
  );
};

export default connect((state) => ({
  company_speciality: state?.jobSetup?.jobSetup?.jobSetup?.company_speciality || [],
  specialities: state.jobSetup.jobSpecialities.specialities,
  additionalSpecialties: state.jobSetup.jobSpecialities.additionalSpecialties,
  cards: state.jobSetup.viewController.cards,
  tabs: state.jobSetup.viewController.tabs,
  currentTab: state.jobSetup.viewController.currentTab,
  companyHiringFor: state.jobSetup.companyDetails.companyHiringFor,
  isReview: state.jobSetup?.jobSetup?.isReview,
  shouldSelectLastTab: state.jobSetup.viewController.shouldSelectLastTab,
}))(SpecialityDetails);
