import * as ActionTypes from '../../actions/auth'

const initialState = {
  isLoadingProvider: false,
  doesEmailExist: false,
  emailProvider: '',
  email: ''

}

export const emailStep = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_PROVIDER:
      return { ...state, isLoadingProvider: true, email: action.meta }

    case ActionTypes.FETCH_PROVIDER_SUCCESS:
      return {
        ...state,
        isLoadingProvider: false,
        emailProvider: action.payload?.data?.provider,
        doesEmailExist: action.payload?.data?.exist,
        email: action.meta,
        phone: action.phone,
        userData: action.userData
      }

    case ActionTypes.RESET_PROVIDER_STATE:
      return initialState
    default:
      return state
  }
}
