import React, { useState } from "react";
import "./EditFlowTabs.scss";
import { Nav } from "reactstrap";
import NavProfileItemCustom from "./NavProfileItemCustom";
import NotificationDropdownPanel from "../../views/LandingPage/Home/NotificationDropdownPanel";
import { Mixpanel } from "../../utils/Mixpanel";

const EditFlowTabs = ({
  profileViewTypes,
  setProfileViewTypes,
  messageType,
  msgfilter,
  backMessageBtn,
  historyPush,
  location,
  activeFunnel,
}) => {
  const [activeTab, setActiveTab] = useState(2);

  return (
    <div className="editTabs">
      <Nav tabs className="editFlowTabs">
        {msgfilter
          ? profileViewTypes.map((item, index) => (
              <NavProfileItemCustom
                key={item.id || index}
                {...item}
                tabType={msgfilter}
                isActive={item.name === activeFunnel}
                onClick={() => {
                  Mixpanel.track(`Campaigns - ${item.name}`)
                  setProfileViewTypes(item.value);
                  // setActiveTab(item.id === 3 ? 1 : item.id);
                }}
                showCaret={index < profileViewTypes.length - 1}
              />
            ))
          : messageType.map((item, index) => (
              <NavProfileItemCustom
                key={item.id || index}
                {...item}
                isActive={item.id === activeTab}
                onClick={() => {
                  backMessageBtn();
                  setActiveTab(2);
                }}
                onClickMsg={() => setActiveTab(item.id)}
                tabType={msgfilter}
              />
            ))}
        <div className="headerRightButtons" style={{ borderRight: "nones" }}>
          <NotificationDropdownPanel
            type={"ScoreAndConnect"}
            historyPush={historyPush}
            location={location}
          />
        </div>
      </Nav>
    </div>
  );
};

export default EditFlowTabs;
