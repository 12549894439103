import * as ActionTypes from '../../actions/JobSetup'

const viewsToTabsMap = {
  'Job Details': {
    tabs: [
      'Job Description',
      'Location',
    ]
  },
  'Add Best Fit Profiles': {
    tabs: []
  },
  'Company Details': {
    tabs: [
      'Hiring Company',
      'Similar companies'
    ]
  },
  'Business, Specialties or Industry': {
    tabs: [
      'Details'
    ]
  },
  'Main Title': {
    tabs: []
  },
  'Additional Titles': {
    tabs: [
      'Details'
    ]
  },
  'Total Relevant Experience': {
    tabs: []
  },
  'Functions': {
    tabs: []
  },
  'Skills': {
    tabs: [
      'Details'
    ]
  },
  'Education': {
    tabs: []
  },
  'Criteria Verification': {
    tabs: [
      'Total Experience',
      'Titles',
      'Functions',
      'Skills',
      'Education'
    ]
  },
  'Tribe Members': {
    tabs: []
  }
}

const initialState = {
  view: 'Job Details',
  currentTab: 0,
  tabs: viewsToTabsMap['Job Details'].tabs,
  isLoading: false,
  isLoaded: false,
  carsouselSortValue: [],
  selectedValues: [],
  highlightValue: '',
  profileSelectedValues: [],
  profileHighlightValue: '',
  // profiles:[],
  shouldSelectLastTab: false,
  cards: [],
  position: [],
  history: ['Job Details'],
  currentTabHistory: [0]
}


const viewController = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SHOULD_SELECT_LAST_TAB: {
      return {
        ...state,
        shouldSelectLastTab: action.payload,
      }
    }
    case ActionTypes.SET_JOB_SETUP_VIEW: {
      const newtabs = {}

      if ('view' in action.payload) {
        newtabs['tabs'] = (viewsToTabsMap[action.payload.view]?.tabs || [])
        newtabs['currentTab'] = 0
      }

      return {
        ...state,
        ...newtabs,
        ...action.payload,
        history: 'view' in action.payload ? [action.payload.view, ...state.history].slice(0, 6) : state.history,
        currentTabHistory: 'currentTab' in action.payload ? [action.payload.currentTab, ...state.currentTabHistory].slice(0, 3) : state.currentTabHistory.slice(0,3)
      }
    }
    case ActionTypes.INIT_JOB_SETUP_VIEW: {
      return initialState
    }
    default:
      return state
  }
}

export default viewController
