import React, { useState } from "react";

import displayPlaceholder from "../../img/displayPlaceholder.svg";
import CompanySvg from "../../uiLibrary/SVGs/CompanySvg";
import "./CompanyDisplayImage.scss";

const CompanyDisplayImage = ({
  width = "46px",
  height = "46px",
  src,
  className,
}) => {
  // console.log('IMAGE SOURCE IS: ', src)
  // // const [displayPicSrc, setDisplayPicSrc] = useState(src)
  const [hasError, setHasError] = useState(false);

  return (
    <div className="companyDisplayImageContainer">
    {hasError ?
    <span className="placeHolderContainer"><CompanySvg width={24} height={24} className="companyDisplayImage"/></span> :
    <img
      className={`companyDisplayImage ${className ? className : ""} ${hasError ? 'placeholder' : ''}`}
      width={width}
      height={height}
      src={hasError ? displayPlaceholder : src || displayPlaceholder}
      onError={() => setHasError(true)}
    /> || <span className="placeHolderContainer"><CompanySvg width={24} height={24} className="companyDisplayImage"/></span>}
    </div>
  );
};

export default CompanyDisplayImage;
