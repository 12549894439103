import React from "react";
import { connect } from "react-redux";
import {
  changeJobCompanyName,
  clearJobCompanySuggestion,
  companyProfileDelete,
  fetchJobCompanySuggestion,
  selectHiringCompany,
} from "../../../../actions/QuickSetup";
import NewAutoSuggestion from "../../../../components/NewAutoSuggestion/NewAutoSuggestion";
import ProfileSummaryCard from "../../../../components/ProfileSummaryCard/ProfileSummaryCard";
import Loading from "../../../../uiLibrary/Loading";
import CrossSvg from "../../../../uiLibrary/SVGs/CrossSvg";
import { numberWithCommas } from "../../../../utils";
import "./style.scss";
const CompanyAutoSuggest = ({
  dispatch,
  companyHiringFor,
  companySuggestions,
  companyName,
  isLoading,
  isLoadedSuggestion,
  isLoadingSuggestion,
  isCompanyProfileLoaded,
  isCompanyProfileLoading,
}) => {
  console.log("==========================================", companyHiringFor);
  const handlerChangeJobCompanyName = (e) => {
    if (!isCompanyProfileLoading)
      dispatch(changeJobCompanyName(e.target.value));
  };

  const handlerGetJobCompanyProfile = (value) => {
    dispatch(selectHiringCompany(value));
  };

  const handlerFetchJobCompanySuggestion = (value) => {
    const companyReg = new RegExp("linkedin.com/company/", "gi");
    const agencyReg = new RegExp("linkedin.com/showcase/", "gi");

    if (companyReg.exec(value) !== null || agencyReg.exec(value) !== null) {
      const valueSplit = value.split(/\//);
      const lastValueSplit = valueSplit?.pop()?.trim();
      const secondlastValueSplit = valueSplit?.pop()?.trim();
      if (typeof lastValueSplit === "string" && lastValueSplit !== "") {
        dispatch(fetchJobCompanySuggestion(lastValueSplit, true, value));
      } else if (
        typeof secondlastValueSplit === "string" &&
        secondlastValueSplit !== ""
      ) {
        dispatch(fetchJobCompanySuggestion(secondlastValueSplit, true, value));
      }
    } else {
      dispatch(fetchJobCompanySuggestion(value));
    }
  };

  const handleSuggestionsClearJobCompany = () => {
    dispatch(clearJobCompanySuggestion());
  };

  const handlerCompanycardCloseBtn = () => {
    dispatch(companyProfileDelete());
  };

  const renderSuggestion = (suggestion) => {
    // console.log("==============renderSuggestion==============", suggestion)
    return typeof suggestion === "string" ? (
      <div>{suggestion}</div>
    ) : suggestion.isUrl ? (
      <div>{suggestion.name}</div>
    ) : (
      <div>
        <ProfileSummaryCard
          key={suggestion.urn}
          name={suggestion.name}
          headline={suggestion.industry}
          imgSrc={suggestion.logo}
        />
      </div>
    );
  };

  const shouldRenderSuggestions = (suggestion) =>
    (typeof suggestion === "string"
      ? suggestion.trim()
      : suggestion?.name.trim()) &&
    !isLoadingSuggestion &&
    !isLoading;

  return (
    <div className="CompanyAutoSuggest">
      {isCompanyProfileLoaded && !isCompanyProfileLoading ? (
        <HiringCompanyCard
          name={companyHiringFor.name}
          industry={companyHiringFor.industry}
          logo={companyHiringFor.logo}
          staffCount={companyHiringFor.staffCount}
          onClick={handlerCompanycardCloseBtn}
        />
      ) : (
        <NewAutoSuggestion
          placeholder="ACME lnc."
          inputValue={companyName}
          suggestionsArray={companySuggestions}
          handleChangeInput={handlerChangeJobCompanyName}
          handleSuggestionsFetchRequested={handlerFetchJobCompanySuggestion}
          handleSuggestionSelected={handlerGetJobCompanyProfile}
          handleSuggestionsClearRequested={handleSuggestionsClearJobCompany}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
        />
      )}
      {(isCompanyProfileLoading || isLoadingSuggestion) && (
        <Loading width="24px" height="24px" />
      )}
    </div>
  );
};

export default connect((state) => ({
  companyHiringFor: state.QuickSetup.companyDetails.companyHiringFor,
  companySuggestions: state.QuickSetup.companyDetails.companySuggestions,
  companyName: state.QuickSetup.companyDetails.companyName,
  isLoading: state.QuickSetup.companyDetails.isLoading,
  isLoadedSuggestion: state.QuickSetup.companyDetails.isLoadedSuggestion,
  isLoadingSuggestion: state.QuickSetup.companyDetails.isLoadingSuggestion,
  isCompanyProfileLoaded:
    state.QuickSetup.companyDetails.isCompanyProfileLoaded,
  isCompanyProfileLoading:
    state.QuickSetup.companyDetails.isCompanyProfileLoading,
}))(CompanyAutoSuggest);

export const HiringCompanyCard = ({
  name,
  logo,
  industry,
  staffCount,
  onClick,
}) => {
  return (
    <div className="HiringCompanyCard">
      <ProfileSummaryCard imgSrc={logo} name={name} headline={industry} />
      {staffCount && <p>{`${numberWithCommas(staffCount)} employees`}</p>}
      <button onClick={onClick}>
        <CrossSvg width="16" height="16" />
      </button>
    </div>
  );
};
