import React from "react";
import "./style.scss"

// import Footer from "../component/Footer";

// import Header from "../component/Header";
// import style from "../styles/DisclosureAndPrivacy.module.scss";

export default function DisclosureAndPrivacy() {
  const [index, setIndex] = React.useState(0);
  return (
    <div className={"disclosureContainer"}>

      <div className={'disclosureSection'}>
        <ul>
          <li style={index === 0 ? { color: '#FF8D4E', background: '#402F27' } : {}} onClick={() => setIndex(0)}>
            Disclosure
          </li>
          <li style={index === 1 ? { color: '#FF8D4E', background: '#402F27' } : {}} onClick={() => setIndex(1)}>
            Privacy
          </li>
        </ul>
        {(() => {
          switch (index) {
            case 0:
              return <Disclosure />;
            case 1:
              return <Privacy />;
          }
        })()}
      </div>

    </div>
  );
}

function Disclosure() {
  return (
    <div>
      <h3>
        Disclosure
      </h3>
      <p>
        To automate conversations initiated by a user, QLU.ai uses the Gmail API to send and read emails through a user's Gmail account on their behalf.
      </p>
      <p>
        The QLU.ai app's use of information received from Google APIs adheres to the Google API Services User Data Policy, including the Limited Use requirements.
      </p>
      <p>
        For more information, please contact admin@qlu.ai with any questions.
      </p>
    </div>
  );
}

function Privacy() {
  return (
    <div>
      <h3>
        Privacy Policy
      </h3>
      <ul>
        <li>
          QLU.ai does not store any personal information not related to a "campaign". All information stored is used for allowing the user to take the next steps and make better decisions on their own campaigns.
        </li>
        <li>
          Similar to other popular services such as Gmail, Linkedin, etc; No personal information is ever shared with any other entity (businesses, individuals or governments), nor do we ever use any personal information to sell any kind of services.
        </li>
        <li>
          No messages (Email/Linkedin/Messaging) are ever stored, except for the ones tied to a particular campaign. Only messages related to a "campaign" are stored for the user to review in a consolidated location for the best user experience and ease of use.
        </li>
        <li>
          Personal data/information may be used to better improve our Natural Language Understanding (NLU) models to help QLU.ai provide a continuously better service.
        </li>
        <li>
          All data collected for a user will be erased 1 month after requested by the user via our support channel.
        </li>
        <li>
          QLU.ai does not collect data from any other website or any other activity.
        </li>
      </ul>
    </div>
  );
}
