import React, { useEffect, useRef, useState } from "react";
import "./RevampProspectCard.scss";

import { isEmpty } from "lodash";
import qs from "qs";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button, Input, Progress, Tooltip
} from "reactstrap";
import {
  appendProspectComment,
  appendProspectEmoji, archiveProspects, deleteCommentProspectSocket, deleteEmoji, deleteProspectEmoji, likedProfile, requestSaveProspects, saveEmoji, setActiveProspectIDVariant, setActiveProspectVariant, showEmojiPickerOnProspect, _deleteCounter, _deleteProspect
} from "../../actions/prospectsView";
import EmojiPicker from "../../components/EmojiPicker/EmojiPicker";
import ProfileDisplayImage from "../../components/ProfileDisplayImage/ProfileDisplayImage";
import Emoji from "../../components/svgComponents/Emoji";
import MessageCircle from "../../components/svgComponents/MessageCircle";
import { PER_PAGE_COUNT } from "../../constants/api";
import { isElementInViewport, smoothScroll } from "../../utils";
import useComponentVisible from "../../utils/useComponentVisible";
import ProfileLiked from "../ProfileLiked/ProfileLiked";
import ProfileLikedSelectors from "../ProfileLikedSelectors/ProfileLikedSelectors";
import FavouriteSVG from "../SVGs/FavoriteSVG";
import LinkedInLogo from "../SVGs/LinkedInSvg";
import RecommendedSvg from "../SVGs/RecommendedSvg";

const RevampProspectCard = ({
  onClickCard,
  handleRecommendedCard,
  showLiked,
  showReview,
  addHoverHighlighter,
  removeHoverHighlighter,
  prospects,
  setFullCardTag,
  recommended = false,
  isDetail = false,
  activeProspectVariant,
  prospectCardMid = false,
  prospectData = {
    name: "Fernando Banchon",
    jobTitle: "Account Manager @ MasterCard",
    location: "New York Metropolitan Area",
    totalScore: 80,
    currentTitleScore: 90,
    experienceScore: 70,
    experienceSpecialitiesScore: 67,
    experienceTitlesScore: 45,
    experienceSkillsScore: 30,
    experienceFunctionsScore: 54,
    educationScore: 50,
    skillsScore: 80,
    specialitiesScore: 30,
    companyScore: 60,
    imageUrl:
      "https://media-exp1.licdn.com/dms/image/C5603AQEgpiK1GfQw-Q/profile-displayphoto-shrink_400_400/0/1604712859393?e=1637798400&v=beta&t=8jv3-xtUEpnpDzZfItBrEfoRUEQyvjsUtGyIuFiDQbs",
    emojies: [],
    noteCount: null,
    scoring: {
      score: 34.62051953552971,
      final_score: 69.62052,
      skill_score: 14.90181,
      skill_total: 20,
      title_score: 6,
      title_total: 6,
      visa_status: "None",
      same_company: false,
      company_score: 0,
      recruiterLink: [],
      industry_score: 11.24991,
      industry_total: 12.4999,
      skills_profile_missing: ["networking"],
      skills_profile: [
        "web",
        "python",
        "microservices",
        "information technology",
      ],
      education_score: 1.2688,
      education_total: 2.5000999999999998,
      skills_required: [
        "backend: Important",
        "front end: Important",
        "web: Important",
        "information technology: Must Have",
        "design patterns: Important",
        "continuous integration: Important",
        "tdd: Important",
        "sales: Nice to Have",
        "issue resolution: Nice to Have",
        "microservices: Important",
        "python: Important",
        "coding: Nice to Have",
      ],
      experience_found: [
        "Full Stack Engineer at Sectem Technologies for 11 month(s)",
        "11 month(s) ",
      ],
      experience_score: 7.2,
      experience_total: 15,
      skill_importance: {
        important: ["web", "microservices", "python"],
        important_missing: ["Information Technology and Services"],
        must_haves: ["information technology"],
        must_haves_missing: ["iOS Development"],
        nice_to_haves: ["Sass"],
        nice_to_haves_missing: ["Next.js"],
      },
      adjustment_factor: 70,
      education_profile: ["The prospect went to COMSATS University Islamabad"],
      competitor_company: false,
      education_required: ["bachelor of information technology: Nice to Have"],
      industries_profile: ["information technology & services"],
      experience_required: [
        "3 years related to full stack architect: Nice to Have",
        "proficiency in one or more backend languages: Nice to Have",
        "proficiency in one or more frontend framework html5 or angular or react: Nice to Have",
        "experience developing on both mobile and web platforms: Nice to Have",
        "three years of progressive experience in the specialty in lieu of every year of education: Nice to Have",
        "3 years of continuous integration continuous delivery pair programming and test driven development: Nice to Have",
      ],
      industries_required: ["Computer Software: Must Have"],
      all_experience_found: [
        "Full Stack Engineer at Sectem Technologies for 11 month(s)",
      ],
      qualification_status: "Under Qualified",
      super_high_reccomend: false,
      all_experience_scores: {
        skills: 0,
        industries: -1,
        job_position: 0.6,
        specialities: 0,
        jd_title_score: 1,
        jd_relevant_title_score: 0.6,
      },
      current_ideal_company: false,
      total_experience_span: 0.92,
      high_profile_reccomend: false,
      previous_ideal_company: false,
      relevant_experience_span: 0.92,
    },
  },
  isFullPage = false,
  isSelected = false,
  onSelect = (e) => false,
  onClickSave,
  onClickComments,
  elementIndex,
  comments = [{}, {}, {}],
  job,
  showEmojiPickerProspectID,
  socket,
  selectedProspectIDVariant,
  pageNumber,
  pageNo,
  permissionsAllowed,
  profileLikedStatus,
  search,
  dispatch,
  addingProspects = false,
  location,
  isOnScoreAndConnectRecommended = false,
  currentType,
  likedStatus,
  deleteCount,
  prospectCardStyle = {}
}) => {
  const [isArchived, setIsArchived] = useState(false);
  const [showFullTitle, setShowFullTitle] = useState(false);
  const [showFullCompany, setShowFullCompany] = useState(false);
  const [allEmojies, setAllEmojies] = useState([]);
  const [bookmarked, setBookmarked] = useState(prospectData?.saved);
  const [fav, setFav] = useState(prospectData?.saved);
  const [reviewProfileTitle, setReviewProfileTitle] = useState(
    "Review Profile"
  );
  const [showEmojiTool, setShowEmojiTool] = useState(false);
  const [currentEmoji, setCurrentEmoji] = useState(null);
  const [currentEmojiIndex, setCurrentEmojiIndex] = useState(null);
  useEffect(() => { }, [setActiveProspectVariant, pageNumber, search]);
  useEffect(() => {
    socketConnections();
  }, [socket]);

  useEffect(() => {
    setAllEmojies(getAllEmojies());
  }, [prospects]);

  useEffect(() => {
    if (ref?.current?.attributes["data-id"]) {
      if (
        ref?.current?.attributes["data-id"]["value"] ==
        selectedProspectIDVariant
      ) {
        setTimeout(() => {
          if (selectedProspectIDVariant != null) {
            try {
              const id = `.prospectCard[data-id="${selectedProspectIDVariant}"]`;
              const yOffset = -100;
              const element = document.querySelector(id);
              const visible = isElementInViewport(element);
              if (element && !visible) {
                smoothScroll(element, { block: "start" }).then(() => {
                  if (activeProspectVariant != 3) {
                    setTimeout(() => {
                      smoothScroll(element, { block: "start" }).then(() => {
                        dispatch(setActiveProspectIDVariant(null));
                        const y =
                          element.getBoundingClientRect().top +
                          window.pageYOffset +
                          yOffset;
                        window.scrollTo({ top: y, behavior: "smooth" });
                        console.log("Done Scrolling!");
                      });
                    }, 1000);
                  }
                });
              }
            } catch (e) {
              console.log(e);
            }
          }
        }, 1000);
      }
    }
  }, [selectedProspectIDVariant]);

  useEffect(() => {
    if (!isEmpty(prospectData?.recommendationObject)) {
      setReviewProfileTitle("Review Profile Again");
    }
  }, [prospectData?.recommendationObject]);

  const {
    ref: emojiRef,
    isComponentVisible: showEmojiMenu,
    setIsComponentVisible: setShowEmojiMenu,
  } = useComponentVisible(false);

  useEffect(() => {
    if (showEmojiMenu) {
      toggleEmoji();
    }
  }, [showEmojiMenu]);
  const ref = useRef();

  const socketConnections = () => {
    socket?.on("delete_comment", function (data) {
      dispatch(deleteCommentProspectSocket(data));
    });

    socket?.on("new_comment", function (resp) {
      if (resp.data.jobProfileId === prospectData?.jobProfileId) {
        dispatch(appendProspectComment({ comment: resp.data.comment }));
      }
    });

    socket?.on("new_emoji", function (resp) {
      if (resp.data.jobProfileId === prospectData?.jobProfileId) {
        dispatch(appendProspectEmoji({ emoji: resp.data.emoji }));
      }
    });

    socket?.on("delete_emoji", function (resp) {
      if (resp?.jobProfileId === prospectData?.jobProfileId) {
        dispatch(deleteProspectEmoji({ emoji: resp, type: "socket" }));
      }
    });

    socket?.on("connect", function () {
      console.log("Connected Socket ProspectCard: ");
    });

    // socket?.on("test_event", function (data) {
    //   console.log(data.data);
    // });
  };
  const { jId } = useParams();

  const qParams = qs.parse(location.search.split("?").pop());
  // console.log(">>>>>>>>>>>>>>>>>>>>>> QPARAMS", qParams);
  const scrollView = () => {
    const element = document.querySelectorAll(
      `[data-name="specialities ${elementIndex}"]`
    );
    if (element[1]) {
      element[1].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const titleScore = ((prospectData?.buck_score || 0) * 100) / 6;
  const experiences = prospectData?.experience?.map((experience, index) => ({
    ...experience,
    ...(prospectData?.scoring?.candidate_experience_found?.[index] || {}),
  }));
  const experienceScore =
    ((prospectData?.scoring?.experience_score || 0) * 100) /
    (prospectData?.scoring?.experience_total || 1);
  const experienceSpecialitiesScore =
    ((prospectData?.scoring?.all_experience_scores?.specialities || 0) * 100) /
    1;
  const experienceTitlesScore =
    ((prospectData?.scoring?.all_experience_scores?.jd_title_score || 0) *
      100) /
    1;
  const experienceSkillsScore =
    ((prospectData?.scoring?.all_experience_scores?.skills || 0) * 100) / 1;
  // const experienceFunctionsScore = ((prospectData?.scoring?.all_experience_scores?.specialities || 0)*100)/1
  const educationScore =
    ((prospectData?.scoring?.education_score || 0) * 100) /
    (prospectData?.scoring?.education_total || 1);
  const skillsScore =
    ((prospectData?.scoring?.skill_score || 0) * 100) /
    (prospectData?.scoring?.skill_total || 1);
  const specialitiesScore =
    ((prospectData?.scoring?.specialty_score || 0) * 100) /
    (prospectData?.scoring?.specialty_total || 1);
  const companyScore = ((prospectData?.scoring?.company_score || 0) * 100) / 5;

  // const isVisible = useOnScreen(ref);
  const isVisible = true;

  // const [showEmojiMenu, setShowEmojiMenu] = useState(false);

  const toggleEmoji = () => {
    // setShowEmojiMenu((prevState) => !prevState);
    dispatch(
      showEmojiPickerOnProspect(
        showEmojiPickerProspectID == prospectData.jobProfileId
          ? null
          : prospectData.jobProfileId
      )
    );
  };

  const headline = prospectData?.headline?.split(" at ");
  const handleEmojiPicked = (emoji, prospect) => {
    // console.log("emoji", emoji);
    // setAllEmojies((prev) => {
    //   return [...prev, emoji];
    // });
    const newEmoji = {
      emojiText: emoji?.emoji,
      emojiAscII: emoji?.unified,
      count: 1,
      isOwner: true,
    };
    setAllEmojies((prev) => {
      return [...prev, newEmoji];
    });
    dispatch(
      saveEmoji({
        noteId: null,
        jobProfileId: prospect?.jobProfileId,
        emojiText: emoji?.emoji,
        emojiAscII: emoji?.unified,
        jobId: job.id,
        pageNo,
      })
    );
  };

  const deleteEmojiHandle = (emoji, prospect) => {
    setAllEmojies((prev) => {
      const newFiltered = prev.filter(
        (item) => item.emojiText !== emoji.emojiText
      );
      return newFiltered;
    });

    dispatch(
      deleteEmoji({
        noteId: null,
        jobProfileId: prospect?.jobProfileId,
        emojiText: emoji?.emojiText,
        jobId: job.id,
      })
    );
  };

  const getAllEmojies = () => {
    let emojies = [];
    prospectData?.emojies?.map((emoji) => {
      let existsIndex = emojies?.findIndex(
        (item) => emoji.emojiText == item.emojiText
      );
      if (existsIndex < 0) {
        emojies.push({
          emojiText: emoji.emojiText,
          emojiASCII: emoji.emojiASCII,
          count: 1,
          isOwner: emoji?.createdBy
            ? emoji?.createdBy?.isOwner
            : emoji?.isOwner,
          names: emoji?.createdBy ? [emoji?.createdBy?.name] : [emoji?.name],
        });
      } else {
        emojies[existsIndex]["count"] += 1;
        if (emoji?.createdBy?.isOwner || emoji?.isOwner == true)
          emojies[existsIndex]["isOwner"] =
            emoji?.createdBy?.isOwner || emoji?.isOwner;
        emojies[existsIndex]["names"].push(
          emoji?.createdBy ? emoji?.createdBy?.name : emoji?.name
        );
      }
    });
    return emojies;
  };

  const selectLikedValues = [
    {
      title: "Yes",
      className: "tick",
      id: "tick",
      val: "RECOMMENDED_YES",
    },
    {
      title: "Maybe",
      className: "question",
      id: "question",
      val: "RECOMMENDED_MAYBE",
    },
    {
      title: "No",
      className: "cross",
      id: "cross",
      val: "RECOMMENDED_NO",
    },
  ];

  const EmojiToolTip = ({ emoji, index }) => {
    return (
      <div
        className="EmojiToolTip"
        style={{
          marginLeft: index < 3 ? `${40 * ((index % 4) + 1)}px` : "100px",
        }}
      >
        {emoji?.names?.map((name, index) => (
          <span key={name}>{name}</span>
        ))}
      </div>
    );
  };

  const showProfileSelectors =
    qParams.fF != "s" && qParams.fF != "mb" && qParams.fF != "ar";
  return (
    <div
      className={`${"prospectCard"} ${prospectCardMid ? "prospectCardMid" : ""}
      `}
      style={{
        borderRadius: isFullPage ? "0px" : "8px",
        height: showLiked ? "680px" : "600px",
        ...prospectCardStyle,
      }}
      onMouseLeave={() => {
        removeHoverHighlighter && removeHoverHighlighter();
      }}
      onClick={onClickCard}
      data-id={
        prospectData.jobProfileId == selectedProspectIDVariant
          ? selectedProspectIDVariant
          : 0
      }
      ref={ref}
    >
      {isVisible ? (
        <>
          <div className="prospectCardAction">
            <div className="prospectIndex">
              <span>{elementIndex + PER_PAGE_COUNT * pageNumber + 1}</span>
              <Input
                type="checkbox"
                className="form-control-lg"
                checked={isSelected}
                hidden={addingProspects || location.search?.includes("rR")}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  onSelect(e);
                }}
              />
            </div>
            <div className="revampProfileImg">
              <ProfileDisplayImage
                width="72"
                height="72"
                src={prospectData.imageUrl}
                className={recommended ? "recommended" : ""}
              />
              {recommended ? (
                <RecommendedSvg className="fa-recommended" />
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              {/* <Button
                onClick={(e) => {
                  e.stopPropagation();
                }}
                color=""
                // width="24"
                // height="24"
                className="bookmark"
                size="sm"
              >
                <Bookmark
                  color={bookmarked ? "#297AF7" : "#CDD1D7"}
                  fill={bookmarked ? "#297AF7" : "none"}
                  height="24"
                  width="24"
                />
              </Button> */}
              {!location.search?.includes("fF=ar") && (
                <Button
                  hidden={addingProspects || location.search?.includes("rR")}
                  onClick={(e) => {
                    toast.dismiss();
                    e.stopPropagation();
                    setFav((c) => !c);

                    if (!fav) {
                      toast(
                        // `Profile ${!fav ? "liked" : "unliked"}!`,
                        (props) => {
                          return (
                            <ProfileLiked
                              header={`Profile ${!fav ? "liked" : "unliked"}!`}
                              description="Review their profile to let Qlu recommend you more relevant ones."
                            >
                              <Button
                                className="reviewProfileBtn"
                                onClick={() => {
                                  handleRecommendedCard(prospectData);
                                  // if (
                                  //   reviewProfileTitle == "Review Profile" &&
                                  //   showReview
                                  // ) {
                                  //   setReviewProfileTitle("Review Profile Again");
                                  // }
                                }}
                              >
                                Review Profile
                              </Button>
                            </ProfileLiked>
                          );
                        },
                        {
                          hideProgressBar: true,

                          style: {
                            backgroundColor: "#A0A0A0",
                            borderRadius: "8px",
                            width: "514px",
                            color: "black",
                            fontFamily: "Inter",
                          },
                        }
                      );
                    } else {
                    }
                    if (!fav) {
                      const data = {
                        index: elementIndex,
                        status: "RECOMMENDED_YES",
                        jobProfileId: prospectData?.jobProfileId,
                      };
                      dispatch(likedProfile(data));
                    }
                    if (qParams.fF == "s") {
                      if (fav) {
                        const data = {
                          index: elementIndex,
                          status: null,
                          jobProfileId: prospectData?.jobProfileId,
                        };
                        dispatch(_deleteProspect(elementIndex));
                        dispatch(likedProfile(data));
                      }
                    }
                    if (qParams.fF == "mb") {
                      if (!fav) {
                        const data = {
                          index: elementIndex,
                          status: "RECOMMENDED_YES",
                          jobProfileId: prospectData?.jobProfileId,
                        };
                        dispatch(likedProfile(data));
                        dispatch(_deleteProspect(elementIndex));
                      }
                    }
                    onClickSave(
                      [prospectData?.jobProfileId],
                      prospectData?.saved
                    );

                    setTimeout(() => {
                      // if (!bookmarked)
                      //   toast("Profile Saved", {
                      //     hideProgressBar: true,

                      //     style: {
                      //       backgroundColor: "#A0A0A0",
                      //       borderRadius: "8px",
                      //       width: "160px",
                      //       color: "black",
                      //       fontFamily: "Inter",
                      //     },
                      //   });
                      // else
                      // toast("Profile Unsaved", {
                      //   hideProgressBar: true,

                      //   style: {
                      //     backgroundColor: "#A0A0A0",
                      //     borderRadius: "8px",

                      //     width: "180px",
                      //     color: "black",
                      //     fontFamily: "Inter",
                      //   },
                      //   });

                      setBookmarked(!bookmarked);
                    }, 100);

                    // handleRecommendedCard(prospectData);
                  }}
                  color=""
                  width="24"
                  height="24"
                  // className="bookmark"
                  size="sm"
                >
                  <FavouriteSVG
                    color={prospectData?.saved ? "#c91010" : "#CDD1D7"}
                    fill={prospectData?.saved ? "#c91010" : "none"}
                    width="28"
                    height="28"
                  />
                </Button>
              )}

              {/* {activeProspectVariant == 1 ? (
                <Button
                  className={`addedfilterButtonGroup`}
                  width="24"
                  height="24"
                  size="sm"
                  onClick={() => {
                    dispatch(
                      setActiveProspectIDVariant(prospectData.jobProfileId)
                    );
                    dispatch(setActiveProspectVariant(2));
                  }}
                >
                  <ProspectDetailMidSvg height="32" width="32" />
                </Button>
              ) : null} */}

              {/*   activeProspectVariant == 2 ? (
                <Button
                  className={`addedfilterButtonGroup`}
                  width="24"
                  height="24"
                  size="sm"
                  onClick={() => {
                    dispatch(
                      setActiveProspectIDVariant(prospectData.jobProfileId)
                    );
                    dispatch(setActiveProspectVariant(3));
                  }}
                >
                  <ProspectDetailHighSvg height="28" width="28" />
                </Button>
              ) : (
                <Button
                  className={`addedfilterButtonGroup`}
                  width="24"
                  height="24"
                  size="sm"
                  onClick={() => {
                    dispatch(
                      setActiveProspectIDVariant(prospectData.jobProfileId)
                    );
                    dispatch(
                      setActiveProspectVariant(1, prospectData.jobProfileId)
                    );
                  }}
                >
                  <ProspectDetailLowSvg height="28" width="28" />
                </Button>
              )} */}
            </div>
          </div>

          <div
            className={`prospectCard-titleInfo text-center ${recommended ? "recommended" : ""
              } `}
          >
            <p
              className="nameTitle"
            // title={`${prospectData?.scoring?.recommendation_details?.probability} ${prospectData?.scoring?.recommendation_details?.prob1} ${prospectData?.scoring?.recommendation_details?.prob2} ${prospectData?.scoring?.recommendation_details?.prob3} ${prospectData?.scoring?.recommendation_details?.prob4}`}
            >
              <div className="fullTitle" id={`fullTitle_${elementIndex}`}>
                {prospectData?.fullName}
              </div>

              <Tooltip
                placement="right"
                isOpen={showFullTitle}
                target={`fullTitle_${elementIndex}`}
                toggle={() => setShowFullTitle((prev) => !prev)}
                style={{ zIndex: 9 }}
                className="primaryToolTip"
              >
                {prospectData?.fullName}
              </Tooltip>

              <span
                onClick={(e) => {
                  e.stopPropagation();

                  window.open(
                    `https://www.linkedin.com/in/${prospectData.publicIdentifier}`,
                    "_blank"
                  );
                  window.focus();
                  // newTab(
                  //   `https://www.linkedin.com/in/${prospectData.publicIdentifier}`
                  // );
                }}
              >
                <LinkedInLogo width="20" height="20" className="linkedInLogo" />
              </span>
            </p>

            <p className="">
              <div className="jobTitle" id={`jobTitle_${elementIndex}`}>
                <Tooltip
                  placement="bottom-end"
                  isOpen={showFullCompany}
                  target={`jobTitle_${elementIndex}`}
                  toggle={() => setShowFullCompany((prev) => !prev)}
                  style={{ zIndex: 9 }}
                  className="primaryToolTip"
                >
                  {headline?.length > 0 && headline[0]}{" "}
                  {headline?.length > 0 && headline[1] && `@ ${headline[1]}`}
                </Tooltip>
                <span
                  data-name={`current-title ${elementIndex}`}
                  onClick={() => {
                    setFullCardTag &&
                      setFullCardTag(`[data-name="current-title"]`);
                  }}
                  onMouseEnter={() => {
                    addHoverHighlighter &&
                      addHoverHighlighter("current-title", elementIndex);
                  }}
                  onMouseLeave={() => {
                    removeHoverHighlighter &&
                      removeHoverHighlighter("current-title", elementIndex);
                  }}
                >
                  {" "}
                  {headline?.length > 0 && headline[0]}
                  {/* {prospectData?.title} */}{" "}
                </span>{" "}
                {headline?.length > 0 && headline[1] ? "@" : ""}{" "}
                <span
                  data-name={`company ${elementIndex}`}
                  onMouseEnter={() => {
                    addHoverHighlighter &&
                      addHoverHighlighter("company", elementIndex, "data-name");
                  }}
                  onMouseLeave={() => {
                    removeHoverHighlighter &&
                      removeHoverHighlighter(
                        "company",
                        elementIndex,
                        "data-name"
                      );
                  }}
                >
                  {" "}
                  {headline?.length > 0 && headline[1] && headline[1]}{" "}
                </span>
              </div>
            </p>

            <p className="location">{prospectData?.locality}</p>
            {recommended ? (
              <p className="recommendedTitle">Recommended</p>
            ) : null}
          </div>
          {/* {prospectData?.scoring?.qualification_status ? (
            <div className="qualificationStatus">
              {prospectData?.scoring?.qualification_status}
            </div>
          ) : null} */}

          {showReview && (
            <div className="reviewProfile">
              <Button
                className={
                  reviewProfileTitle == "Review Profile"
                    ? "reviewProfileBtn"
                    : "reviewProfileBtnAgain"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  // if (reviewProfileTitle == "Review Profile") {
                  //   setReviewProfileTitle("Review Profile Again");
                  // }
                  handleRecommendedCard(prospectData);
                }}
              >
                {reviewProfileTitle}
              </Button>
            </div>
          )}

          <div
            className={`prospectBars ${prospectCardMid ? "" : "removeFlex"}`}
          >
            <div className="titles">
              {/* <div className="row no-gutter">
                        <div className="col-4">
                            <p className="totalScore">Total Score</p>
                        </div>
                        <div className="col-8">
                            <Progress className="barFull totalScore" value={prospectData.totalScore} />
                        </div>
                    </div> */}

              <div
                className="row"
                style={{ marginBottom: isDetail ? "0.8rem" : "" }}
                data-name={`current-title ${elementIndex}`}
                onClick={() => {
                  setFullCardTag &&
                    setFullCardTag(`[data-name="current-title"]`);
                }}
                onMouseEnter={() => {
                  addHoverHighlighter &&
                    addHoverHighlighter("current-title", elementIndex);
                }}
                onMouseLeave={() => {
                  removeHoverHighlighter &&
                    removeHoverHighlighter("current-title", elementIndex);
                }}
              >
                <div className="col-4">
                  <p>Current Title</p>
                </div>
                <div className="col-8">
                  <Progress className="barFull" value={titleScore} />
                </div>
              </div>

              <div className={isDetail ? "horizontalLine" : ""}></div>
              {isDetail ? (
                <div className="experienceScore">
                  <div className="row">
                    <div className="col-4">
                      <p>Experience</p>
                    </div>
                    <div className="col-8">
                      <Progress className="barFull" value={experienceScore} />
                    </div>
                  </div>

                  <div className="experienceSubScores">
                    <div className="row">
                      <div className="col-4">
                        <p>Business</p>
                      </div>
                      <div className="col-8">
                        <Progress
                          className="barFull"
                          value={experienceSpecialitiesScore}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4">
                        <p>Titles</p>
                      </div>
                      <div className="col-8">
                        <Progress
                          className="barFull"
                          value={experienceTitlesScore}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4">
                        <p>Skills</p>
                      </div>
                      <div className="col-8">
                        <Progress
                          className="barFull"
                          value={experienceSkillsScore}
                        />
                      </div>
                    </div>

                    {/* <div className="row">
                                        <div className="col-4">
                                            <p>Functions</p>
                                        </div>
                                        <div className="col-8">
                                            <Progress className="barFull" value={experienceFunctionsScore} />
                                        </div>
                                    </div> */}
                  </div>
                </div>
              ) : (
                <div
                  className="row"
                  data-name={`experience ${elementIndex}`}
                  onClick={() => {
                    setFullCardTag &&
                      setFullCardTag(`[data-name="experience"]`);
                  }}
                  onMouseEnter={() => {
                    addHoverHighlighter &&
                      addHoverHighlighter("experience", elementIndex);
                  }}
                  onMouseLeave={() => {
                    removeHoverHighlighter &&
                      removeHoverHighlighter("experience", elementIndex);
                  }}
                >
                  <div className="col-4">
                    <p>Experience</p>
                  </div>
                  <div className="col-8">
                    <Progress className="barFull" value={experienceScore} />
                  </div>
                </div>
              )}
              <div className={isDetail ? "horizontalLine" : ""}></div>

              <div
                className="row"
                style={{ marginTop: isDetail ? "1.2rem" : "" }}
                data-name={`education ${elementIndex}`}
                onClick={() => {
                  setFullCardTag && setFullCardTag(`[data-name="education"]`);
                }}
                onMouseEnter={() => {
                  addHoverHighlighter &&
                    addHoverHighlighter("education", elementIndex);
                }}
                onMouseLeave={() => {
                  removeHoverHighlighter &&
                    removeHoverHighlighter("education", elementIndex);
                }}
              >
                <div className="col-4">
                  <p>Education</p>
                </div>
                <div className="col-8">
                  <Progress className="barFull" value={educationScore} />
                </div>
              </div>

              <div
                className="row"
                data-name={`skills ${elementIndex}`}
                onClick={() => {
                  setFullCardTag && setFullCardTag(`[data-name="skills"]`);
                }}
                onMouseEnter={() => {
                  addHoverHighlighter &&
                    addHoverHighlighter("skills", elementIndex);
                }}
                onMouseLeave={() => {
                  removeHoverHighlighter &&
                    removeHoverHighlighter("skills", elementIndex);
                }}
              >
                <div className="col-4">
                  <p>Skills</p>
                </div>
                <div className="col-8">
                  <Progress className="barFull" value={skillsScore} />
                </div>
              </div>

              <div
                className="row"
                data-name={`specialities ${elementIndex}`}
                onClick={() => {
                  setFullCardTag &&
                    setFullCardTag(`[data-name="specialities"]`);
                }}
                onMouseEnter={() => {
                  addHoverHighlighter &&
                    addHoverHighlighter("specialities", elementIndex);
                  prospectCardMid && scrollView();
                }}
                onMouseLeave={() => {
                  removeHoverHighlighter &&
                    removeHoverHighlighter("specialities", elementIndex);
                }}
              >
                <div className="col-4">
                  <p>Business</p>
                </div>
                <div className="col-8">
                  <Progress className="barFull" value={specialitiesScore} />
                </div>
              </div>

              <div
                className="row"
                id="company"
                data-name={`company ${elementIndex}`}
                onClick={() => {
                  setFullCardTag && setFullCardTag(`[data-name="company"]`);
                }}
                onMouseEnter={() => {
                  addHoverHighlighter &&
                    addHoverHighlighter("company", elementIndex, "data-name");
                }}
                onMouseLeave={() => {
                  removeHoverHighlighter &&
                    removeHoverHighlighter(
                      "company",
                      elementIndex,
                      "data-name"
                    );
                }}
              >
                <div className="col-4">
                  <p>Company</p>
                </div>
                <div className="col-8">
                  <Progress className="barFull" value={companyScore} />
                </div>
              </div>
              {(addingProspects == false && permissionsAllowed === "*") ||
                (permissionsAllowed?.length &&
                  permissionsAllowed?.length > 0 &&
                  permissionsAllowed?.includes("CREATE_READ_COMMENTS")) ? (
                <div className="collab">
                  <div className="btnP">
                    <Button
                      color=""
                      className="commentIcon"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickComments(e);
                      }}
                    >
                      <MessageCircle width="24" height="24" stroke={2} />{" "}
                      {prospectData.noteCount !== "0" ? (
                        <span className="count">{prospectData.noteCount}</span>
                      ) : (
                        ""
                      )}
                    </Button>
                  </div>
                  <div
                    className="emojiContainer"
                    onClick={(e) => e.stopPropagation()}
                    style={{ maxWidth: prospectCardMid ? "150px" : "180px" }}
                  >
                    {allEmojies?.map((emoji, index) => (
                      <div
                        className={`emojies ${emoji.isOwner ? "emojiOwner" : ""
                          }`}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Button
                          color=""
                          className=""
                          size="sm"
                          onClick={() => {
                            emoji.isOwner
                              ? deleteEmojiHandle(emoji, prospectData)
                              : handleEmojiPicked(emoji, prospectData);
                          }}
                          onMouseOver={() => {
                            setCurrentEmoji(emoji);
                            setCurrentEmojiIndex(index);
                            setShowEmojiTool(true);
                          }}
                          onMouseLeave={() => {
                            setShowEmojiTool(false);
                          }}
                        >
                          <span className="emoji">{emoji.emojiText}</span>{" "}
                          <span className="count">{emoji.count}</span>
                        </Button>
                      </div>
                    ))}
                  </div>

                  <div className="btnP">
                    <Button
                      color=""
                      className=""
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleEmoji();
                        setShowEmojiMenu((prevState) => !prevState);
                      }}
                    >
                      <Emoji
                        width="24"
                        height="24"
                        stroke={2}
                        className="toggleEmoji"
                      />
                    </Button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {showEmojiMenu ? (
                <div
                  className="emoji"
                  onClick={(e) => e.stopPropagation()}
                  ref={emojiRef}
                >
                  <EmojiPicker
                    handleEmojiPicked={handleEmojiPicked}
                    prospect={prospectData}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {showEmojiTool && currentEmoji && (
        <EmojiToolTip emoji={currentEmoji} index={currentEmojiIndex} />
      )}
      {showLiked && showProfileSelectors && (
        <div className="profileLikedStatusSelector">
          Do you like this profile?
          <div>
            {selectLikedValues.map((item, index) => {
              const { title, className, id, val } = item;
              const active = (val, likedStatus) => {
                if (val === "RECOMMENDED_YES") {
                  if (prospectData?.saved) {
                    return true;
                  }
                } else if (val == likedStatus?.[elementIndex]) {
                  return true;
                } else if (likedStatus?.[elementIndex] == "") {
                  return false;
                } else {
                  return false;
                }
              };
              return (
                <ProfileLikedSelectors
                  index={index}
                  title={title}
                  className={className}
                  val={val}
                  id={id}
                  elementIndex={prospectData?.jobProfileId}
                  active={active(val, likedStatus)}
                  onClick={(liked) => {
                    toast.dismiss();
                    if (liked == likedStatus?.[elementIndex]) {
                      const data = {
                        index: elementIndex,
                        status: null,
                        jobProfileId: prospectData?.jobProfileId,
                      };
                      dispatch(likedProfile(data));
                      if (liked == "RECOMMENDED_YES") {
                        setFav(false);
                        if (addingProspects == false) {
                          onClickSave(
                            [prospectData?.jobProfileId],
                            prospectData?.saved
                          );
                        }
                      }
                      return;
                    }
                    if (addingProspects == false) {
                      const data = {
                        index: elementIndex,
                        status: val,
                        jobProfileId: prospectData?.jobProfileId,
                      };
                      dispatch(likedProfile(data));
                    } else {
                      if (isOnScoreAndConnectRecommended) {
                        const data = {
                          index: elementIndex,
                          status: val,
                          jobProfileId: prospectData?.jobProfileId,
                        };
                        dispatch(likedProfile(data, true, jId, currentType));
                      } else {
                        const data = {
                          index: elementIndex,
                          status: val,
                          jobProfileId: prospectData?.jobProfileId,
                        };
                        dispatch(likedProfile(data, true, jId));
                      }
                    }
                    if (liked == "RECOMMENDED_YES") {
                      setFav(true);
                      if (!fav && addingProspects == false) {
                        toast(
                          // `Profile ${!fav ? "liked" : "unliked"}!`,
                          ({ closeToast }) => (
                            <ProfileLiked
                              header={`Profile ${!fav ? "liked" : "unliked"}!`}
                              description="Review their profile to let Qlu recommend you more relevant ones."
                            >
                              <Button
                                className="reviewProfileBtn"
                                onClick={() => {
                                  handleRecommendedCard(prospectData);
                                  closeToast();
                                  // if (
                                  //   reviewProfileTitle == "Review Profile" &&
                                  //   showReview
                                  // ) {
                                  //   setReviewProfileTitle("Review Profile Again");
                                  // }
                                }}
                              >
                                Review Profile
                              </Button>
                            </ProfileLiked>
                          ),
                          {
                            hideProgressBar: true,

                            style: {
                              backgroundColor: "#A0A0A0",
                              borderRadius: "8px",
                              width: "514px",
                              color: "black",
                              fontFamily: "Inter",
                            },
                          }
                        );
                      }
                      if (addingProspects == true) {
                        dispatch(
                          requestSaveProspects(
                            jId,
                            [prospectData?.jobProfileId],
                            prospectData?.saved
                          )
                        );
                      }
                      if (addingProspects == false) {
                        onClickSave(
                          [prospectData?.jobProfileId],
                          prospectData?.saved
                        );
                        setTimeout(() => {
                          setBookmarked(!bookmarked);
                        }, 100);
                      }
                    } else if (liked == "RECOMMENDED_NO") {
                      if (prospectData?.saved) {
                        setFav(false);
                        if (addingProspects == false) {
                          onClickSave(
                            [prospectData?.jobProfileId],
                            prospectData?.saved
                          );
                        }
                      }
                      dispatch(
                        archiveProspects(jId, [prospectData?.jobProfileId])
                      );
                      if (addingProspects == false) {
                        toast(
                          `Profile archived. We will show fewer profiles like this.`,
                          {
                            hideProgressBar: true,

                            style: {
                              backgroundColor: "#A0A0A0",
                              borderRadius: "8px",
                              width: "400px",
                              color: "black",
                              fontFamily: "Inter",
                            },
                          }
                        );
                      }
                      dispatch(_deleteCounter(deleteCount + 1));

                      //  push(`${pathname}?${qs.stringify({ ...newParams, fF: "a" })}`);
                    } else if (liked == "RECOMMENDED_MAYBE") {
                      if (prospectData?.saved) {
                        if (addingProspects == false) {
                          setFav(false);
                          onClickSave(
                            [prospectData?.jobProfileId],
                            prospectData?.saved
                          );
                        }
                      }
                      if (addingProspects == false) {
                        toast("Thanks for the feedback!", {
                          hideProgressBar: true,

                          style: {
                            backgroundColor: "#A0A0A0",
                            borderRadius: "8px",

                            width: "300px",
                            color: "black",
                            fontFamily: "Inter",
                          },
                        });
                      }
                    }
                    if (qParams.fF == "mb") {
                      if (
                        liked == "RECOMMENDED_YES" &&
                        addingProspects == false
                      ) {
                        dispatch(_deleteProspect(elementIndex));
                      }
                    }
                    if (addingProspects == true) {
                      toast("Thanks for the feedback!", {
                        hideProgressBar: true,

                        style: {
                          backgroundColor: "#A0A0A0",
                          borderRadius: "8px",

                          width: "300px",
                          color: "black",
                          fontFamily: "Inter",
                        },
                      });
                    }
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    job: state.prospectsView.candidates.job,
    showEmojiPickerProspectID:
      state.prospectsView.candidates.showEmojiPickerProspectID,
    socket: state.prospectsView.candidates.collabSocket,
    prospects: state.prospectsView.candidates.prospects,
    activeProspectVariant:
      state.prospectsView.prospectVariant.activeProspectVariant,
    selectedProspectIDVariant:
      state.prospectsView.prospectVariant.selectedProspectIDVariant,
    pageNumber: state.prospectsView.prospectVariant.pageNumber,
    permissionsAllowed: state.prospectsView.candidates.permissions,
    pageNo: state.prospectsView.candidates.page,
    profileLikedStatus: state.prospectsView.candidates.profileLikedStatus,
    currentType: state.prospectsView.candidates.recommendationType,

    likedStatus: state.prospectsView.candidates.likedStatus,
    deleteCount: state.prospectsView.candidates.deleteCount,
  }))(RevampProspectCard)
);
