import React from 'react'
import '../style.scss'

const MessageSequenceSVG = ({
    color = "#CDD1D7",
    width = "24",
    height = "24",
    style = {},
    stroke = 2, 
    className = '',
    fill = "none",
    selected=false 
}) => {
    return (
        <svg 
        width={width}
        height={height}
        style={style}
        color={color}
        className={className}
        viewBox="0 0 24 24" 
        fill='none' xmlns="http://www.w3.org/2000/svg">
            <path className={`qluSvgStroke${selected ? 'Selected' : ''}`} d="M19 16C19 16.442 18.8127 16.866 18.4793 17.1785C18.1459 17.4911 17.6937 17.6667 17.2222 17.6667H6.55556L3 21V7.66667C3 7.22464 3.1873 6.80072 3.5207 6.48816C3.8541 6.17559 4.30628 6 4.77778 6H17.2222C17.6937 6 18.1459 6.17559 18.4793 6.48816C18.8127 6.80072 19 7.22464 19 7.66667V16Z" 
                strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path className={`qluSvgStroke${selected ? 'Selected' : ''}`} d="M22.0001 13V4.66667C22.0001 4.22464 21.8128 3.80072 21.4794 3.48816C21.146 3.17559 20.6938 3 20.2223 3H7.77783" 
                strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default MessageSequenceSVG
