import { isEmpty } from "lodash";
import * as ActionTypes from "../../actions/auth";
import { jsonParse } from "../../utils";

const data = JSON.parse(localStorage.getItem("user"));
const initialState = {
  isAuthenticated: !isEmpty(data),
  email: isEmpty(data) ? "" : data.email,
  name: isEmpty(data) ? "" : data.name,
  token: isEmpty(data) ? "" : data.token,
  isBlocked: isEmpty(data) ? false : jsonParse(data.isBlocked),
  is_2fa_enabled: isEmpty(data) ? false : jsonParse(data.is_2fa_enabled),
  color: isEmpty(data) ? null : data.color,
  adminToken: isEmpty(data?.adminToken) ? "" : data?.adminToken,
  adminJobId: isEmpty(data?.adminJobId) ? "" : data?.adminJobId,
  isAdmin: !isEmpty(data?.isAdmin),
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        ...action.payload,
        isBlocked: JSON.parse(action.payload.isBlocked),
        isAuthenticated: true,
      };

    case ActionTypes.SET_BLOCKED_STATUS:
      return {
        ...state,
        isBlocked: action.payload,
      };

    case ActionTypes.LOGOUT: {
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        email: "",
        name: "",
        token: "",
        isBlocked: false,
        isAdmin: false,
      };
    }
    case ActionTypes.LOGIN_ADMIN_TO_THE_APP: {
      localStorage.setItem(
        "user",
        JSON.stringify({
          adminToken: action.payload.token,
          adminJobId: action.payload.jobId,
          token: action.payload.token,
          email: state.email ? state.email : "admin@qlu.com",
          name: state.name ? state.name : "Super Admin",
          color: state.color ? state.color : "ff8d4e",
          isBlocked: false,
          isAdmin: true,
          aot: true,
        })
      );
      return {
        ...state,
        adminToken: action.payload.token,
        adminJobId: action.payload.jobId,
        token: action.payload.token,
        email: state.email ? state.email : "admin@qlu.com",
        name: state.name ? state.name : "Super Admin",
        color: state.color ? state.color : "ff8d4e",
        isBlocked: false,
        isAdmin: true,
        aot: true,
      };
    }

    default:
      return state;
  }
};
