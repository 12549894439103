import React from 'react'
import FindMorePillCards from '../components/FindMorePillCards'
import "./style.scss"
const FindMoreSpecialties = ({
    jobSpecialties=[],
    profileSpecialties=[],
    newSpecialties=[],
    onSpecialtiesChange=e=>false,
    onAddSpecialties=e=>false,
    onRemoveSpecialties=e=>false
}) => {

  console.log("======================FindMoreSpecialties===========================", {jobSpecialties, profileSpecialties, newSpecialties})
    return (
        <div
            className='findMoreSpecialties'
        >
            <FindMorePillCards
                heading="Previously selected specialties"
                pills={jobSpecialties}
                onChange={(e, index, payload)=>onSpecialtiesChange('jobSpecialties', index, payload)}
            />
            <FindMorePillCards
                heading="Newly selected specialties"
                pills={profileSpecialties}
                onChange={(e, index, payload)=>onSpecialtiesChange('profileSpecialties', index, payload)}
            />
            <FindMorePillCards
                heading="similar specialities"
                pills={newSpecialties}
                onChange={(e, index, payload)=>onSpecialtiesChange('newSpecialties', index, payload)}
                onAdd={payload=> onAddSpecialties('newSpecialties', 0, payload)}
                onRemove={(index)=>onRemoveSpecialties("newSpecialties", index, {})}
                hasAddMore={true}
                addMoreDisabled={value=>jobSpecialties.some(({name})=>name?.trim()?.toLowerCase()===value?.trim()?.toLowerCase())||profileSpecialties.some(({name})=>name?.trim()?.toLowerCase()===value?.trim()?.toLowerCase())||newSpecialties.some(({name})=>name?.trim()?.toLowerCase()===value?.trim()?.toLowerCase())}
            />
        </div>
    )
}

export default FindMoreSpecialties