import React from 'react'
import '../style.scss'
const JobSetupStep04 = ({
    strokeWidth='2',
    width='24',
    height='24',
    selected=false,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`} 
                d="M22 12.4286C22 11.6396 21.1046 11 20 11H17.75H15.5L9.5 7V11H7.5L2 7V12.4286V19.5714C2 20.3604 2.89543 21 4 21H20C21.1046 21 22 20.3604 22 19.5714V12.4286Z" 
                strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            <path
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}  
                d="M22 17V4.14286C22 3.57454 21.8946 3.02949 21.7071 2.62763C21.5196 2.22576 21.2652 2 21 2H19C18.7348 2 18.4804 2.22576 18.2929 2.62763C18.1054 3.02949 18 3.57454 18 4.14286V10.5"
                strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default JobSetupStep04
