import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import {
  addJobSkill,
  changeJobSkill,
  changeJobTitle
} from "../../../actions/JobSetup";
import IdealProfileSkills from "../../../components/IdealProfile/IdealProfileSkills/IdealProfileSkills";
import IdealProfileTopContainer from "../../../components/IdealProfile/IdealProfileTopContainer/IdealProfileTopContainer";
import CardContainer from "../../../uiLibrary/CardContainer";
import ProfileLiked from "../../../uiLibrary/ProfileLiked/ProfileLiked";
import JobSetupStep02 from "../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep02";
import "./style.scss";

const ProfileCard = ({
  mainTitle,
  titles,
  profileSelectedValues,
  profileHighlightValue,
  containerStyle = {},
  profiles = [],
  isReview,
  hasSynonym = false,
  view,
  dispatch,
}) => {
  const idealProfile =
    profiles
      .map((item, i) => ({ ...item, index: i + 1 }))
      .filter(({ title = "", total_span = 0 }) => {
        if (view === "Additional Titles") {
          return true;
        } else if (view === "Total Relevant Experience") {
          return true;
        } else if (view === "Skills") {
          return true;
        } else {
          return false;
        }
      }) || [];

  const toggleTitleSelection = (key, value, name) => {
    console.log("titles[key]", titles[key]);
    // IT SHOULD BE ADDED HERE
    if (mainTitle?.name?.toLowerCase() == titles[key]?.name?.toLowerCase()) {
    } else if (
      (titles[key].generic || titles[key].isRare) &&
      titles[key].source?.includes("idealProfile") &&
      !titles[key].selected
    ) {
      const term = titles[key].generic ? "generic" : "rare";
      toast.dismiss();
      toast(
        ({ closeToast }) => (
          <ProfileLiked
            header={`Add title?`}
            description={`This title is ${term} which may return low quality profiles. Do you want to add it?`}
          >
            <Button
              className="closeButtonPopup"
              onClick={(e) => {
                dispatch(
                  changeJobTitle({
                    key,
                    value: {
                      selected: !value,
                    },
                  })
                );
              }}
            >
              Yes, Keep the title
            </Button>
            <Button className="reviewProfileBtn" onClick={closeToast}>
              No, discard it
            </Button>
          </ProfileLiked>
        ),
        {
          closeButton: false,
          hideProgressBar: true,
          style: {
            backgroundColor: "#A0A0A0",
            borderRadius: "8px",
            width: "514px",
          },
        }
      );
    } else {
      dispatch(
        changeJobTitle({
          key,
          value: {
            selected: !value,
          },
        })
      );
    }
  };
  const toggleSkillSelection = (key, value, name) => {
    // console.log("==========================toggleSkillSelection=========================================", {key, value, name})
    dispatch(
      changeJobSkill({
        key,
        value: {
          selected: !value,
        },
      })
    );
  };

  const getState = (value = "", type) => {
    switch (view) {
      case "Additional Titles":
        if (type === "titles") {
          return {
            highlight: value?.trim()?.toLowerCase() === profileHighlightValue,
            selected: profileSelectedValues
              .map(({ name }) => name?.trim()?.toLowerCase())
              .includes(value?.trim()?.toLowerCase()),
            greyTheme: profileSelectedValues
              .filter(({ selected }) => !selected)
              .map(({ name }) => name?.trim()?.toLowerCase())
              .includes(value?.trim()?.toLowerCase()),
          };
        }
        return {
          selected: false,
          highlight: false,
        };
      case "Total Relevant Experience":
        if (type === "experienceSpans") {
          return {
            selected:
              value >= profileSelectedValues?.min &&
              value <= profileSelectedValues?.max,
            highlight:
              value >= profileSelectedValues?.min &&
              value <= profileSelectedValues?.max &&
              profileHighlightValue,
          };
        }
        return {
          select: false,
          highlight: false,
        };
      case "Skills":
        console.log("valueee", value)
        if (type === "skills") {
          // console.log("===============================skills========================================", {value, type})
          // console.log("===========================greyTheme===================================", !profileSelectedValues.reduce((total, {name, synonym=[]})=>[...total, name.trim().toLowerCase(), ...synonym.filter(({source=[]})=>source.includes("idealProfile")).map(({name})=>name.toLowerCase().trim())], []).includes(value.trim().toLowerCase()))
          // console.log("===========================selected===================================", profileSelectedValues.reduce((total, {name, synonym=[]})=>[...total, name.trim().toLowerCase(), ...synonym.filter(({source=[]})=>source.includes("idealProfile")).map(({name})=>name.toLowerCase().trim())], []).includes(value.trim().toLowerCase()))
          return {

            highlight: profileHighlightValue.includes(
              value.trim().toLowerCase()
            ),
            selected: true,
            // profileSelectedValues.reduce((total, {name, synonym=[]})=>[...total, name.trim().toLowerCase(), ...synonym.filter(({source=[]})=>source.includes("idealProfile")).map(({name})=>name.toLowerCase().trim())], []).includes(value.trim().toLowerCase()),
            greyTheme: !profileSelectedValues
              .filter(({ selected }) => selected)
              .reduce(
                (total, { name, synonym = [] }) => [
                  ...total,
                  ...synonym
                    .filter(({ source = [] }) =>
                      source.includes("idealProfile")
                    )
                    .map(({ name }) => name.toLowerCase().trim()),
                  name.trim().toLowerCase(),
                ],
                []
              )
              .includes(value.trim().toLowerCase()),
          };
        }
        return {
          selected: false,
          highlight: false,
        };

      default:
        return {
          select: false,
          highlight: false,
        };
    }
  };

  const toggleState = (currentState, value, type, sourceId) => {
    if (isReview === "1") return false;
    switch (view) {
      case "Additional Titles":
        if (
          type === "titles" &&
          profileSelectedValues.filter(
            ({ name }) =>
              name?.trim()?.toLowerCase() === value?.trim()?.toLowerCase()
          )?.length > 0
        ) {
          toggleTitleSelection(
            profileSelectedValues.filter(
              ({ name }) =>
                name?.trim()?.toLowerCase() === value?.trim()?.toLowerCase()
            )?.[0]?.key,
            !currentState?.greyTheme && currentState?.selected
          );
        }
        break;
      case "Total Relevant Experience":
        if (type === "experienceSpans") {
          return {
            select:
              value >=
              profileSelectedValues.filter(
                ({ name }) =>
                  name.trim().toLowerCase() === value.trim().toLowerCase()
              )?.[0]?.min &&
              value <=
              profileSelectedValues.filter(
                ({ name }) =>
                  name.trim().toLowerCase() === value.trim().toLowerCase()
              )?.[0]?.max,
            highlight:
              value >=
              profileSelectedValues.filter(
                ({ name }) =>
                  name.trim().toLowerCase() === value.trim().toLowerCase()
              )?.[0]?.min &&
              value <=
              profileSelectedValues.filter(
                ({ name }) =>
                  name.trim().toLowerCase() === value.trim().toLowerCase()
              )?.[0]?.max &&
              profileHighlightValue,
          };
        }
        return {
          select: false,
          highlight: false,
        };
      case "Skills":
        if (
          type === "skills" &&
          profileSelectedValues.filter(({ name, synonym = [] }) =>
            [
              name.trim().toLowerCase(),
              ...synonym
                .filter(({ source = [] }) => source.includes("idealProfile"))
                .map(({ name }) => name.toLowerCase().trim()),
            ].includes(value.trim().toLowerCase())
          )?.length > 0
        ) {
          toggleSkillSelection(
            profileSelectedValues.filter(({ name, synonym = [] }) =>
              [
                name.trim().toLowerCase(),
                ...synonym
                  .filter(({ source = [] }) => source.includes("idealProfile"))
                  .map(({ name }) => name.toLowerCase().trim()),
              ].includes(value.trim().toLowerCase())
            )?.[0]?.key,
            !currentState?.greyTheme && currentState?.selected
          );
        } else if (type === "skills") {
          dispatch(
            addJobSkill({
              importance: {
                Low: false,
                Medium: false,
                High: true,
              },
              name: value,
              source: ["idealProfile"],
              sourceId: [sourceId],
              selected: true,
            })
          );
        }
        break;

      default:
        return {
          select: false,
          highlight: false,
        };
    }
  };

  return (
    <div
      className="profileCardContainer"
      style={{
        ...(view === "Total Relevant Experience" ? { paddingTop: 0 } : {}),
        ...containerStyle,
      }}
    >
      {idealProfile.map(
        (profile,
          // {
          //   index,
          //   total_span,
          //   skills = [],
          //   ner_entities = [],
          //   image_url,
          //   full_name,
          //   title,
          //   publicIdentifier,
          //   current_company_name,
          //   locationName,
          //   education,
          // },
          i
        ) => (
          <CardContainer
            key={profile.publicIdentifier}
            icon={<JobSetupStep02 />}
            heading={`Best Fit Profile ${profile.index}`}
          >
            <IdealProfileTopContainer
              image_url={profile.image_url ? profile.image_url : `https://qlu-media-test.s3.amazonaws.com/${profile.publicIdentifier}.jpg`}
              full_name={profile.full_name ? profile.full_name : profile.fullName}
              experience={
                profile.total_span ? profile.total_span
                  : profile.experience.reduce((exp, a, ex = 0) => {
                    // console.log("expppp",a.start,a.end,exp)
                    let d1 = new Date(a.start.split("-").reverse().join("-"))
                    let d2 = new Date(a.end.split("-").reverse().join("-"))
                    // console.log("Dates",d1,d2)
                    ex += Math.floor((d2 - d1) / 31536000000)
                    return ex
                  }, 0)
              }
              title={profile.title}
              current_company_name={profile.current_company_name ? profile.current_company_name : profile.currentCompanyName}
              education={profile.education?.[0]?.schoolName}
              locationName={profile.locationName}
              getState={getState}
              toggleState={(e) => false}
              isMainTitle={
                mainTitle?.name?.toLowerCase() == profile.title?.toLowerCase()
              }
            />
            {view === "Skills" && (
              <IdealProfileSkills
                skills={[
                  ...profile?.skills || [""],
                  ...profile.ner_entities?.filter(
                    ({ type, name }) =>
                      !profile.skills
                        .map((skill) => skill.trim().toLowerCase())
                        .includes((name || "").trim().toLowerCase()) &&
                      type === "technical_skills"
                  )
                    .map(({ name }) => name) || [""], //TODO: fix why isnt it working with []
                ]}
                getState={getState}
                toggleState={
                  (currentState, value, type) =>
                    toggleState(currentState, value, type, profile.publicIdentifier)
                }
              />
            )}
          </CardContainer>
        )
      )}
    </div>
  );
};
export default connect((state) => ({
  mainTitle: state.jobSetup.jobCriteria.mainTitle,
  profileSelectedValues: state.jobSetup.viewController.profileSelectedValues,
  profileHighlightValue: state.jobSetup.viewController.profileHighlightValue,
  view: state.jobSetup.viewController.view,
  profiles: state.jobSetup.jobIdealProfile.profiles,
  isReview: state.jobSetup?.jobSetup?.isReview,
  titles: state.jobSetup.jobCriteria.titles,
}))(ProfileCard);
