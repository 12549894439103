import React from 'react'
import { connect } from 'react-redux'
import { addSelectedJobLocation, clearJobLocationSuggestions, fetchJobLocations, removeSelectedJobLocation, setJobLocation } from '../../../../actions/QuickSetup'
import NewAutoSuggestion from '../../../../components/NewAutoSuggestion/NewAutoSuggestion'
import PillButton from '../../../../uiLibrary/PillButton'
import './style.scss'
const LocationAutoSuggest = ({
    dispatch,
    location,
    suggestions,
    selectedLocations,
}) => {

    const handleChangeInput = (e) => selectedLocations.length<15 ? 
        dispatch(setJobLocation(e.target.value)) : 
        alert("Reached Max location!")


    const handleSuggestionsFetchRequested=(value)=>dispatch(fetchJobLocations(value))

    const handleSuggestionsClearRequested=()=>dispatch(clearJobLocationSuggestions())

    const handleSuggestionSelected=(value)=>{
        if(selectedLocations.every(loc=>parseLocation(loc)!==parseLocation(value))){
            dispatch(addSelectedJobLocation(value))
        }
        dispatch(setJobLocation(''))
    }

    const handlePillClick = (value) => {
        dispatch(removeSelectedJobLocation(value))
    }

    const parseLocation = (value) => (
        typeof value === 'string' ?
            value : 
            value?.name
    )

    return ( 
        <div
            className='quickSetupLocationContainer'
        >
            <NewAutoSuggestion
                inputValue={location}
                suggestionsArray={suggestions}
                placeholder='Pacific'
                handleChangeInput={handleChangeInput}
                handleSuggestionSelected={handleSuggestionSelected}
                handleSuggestionsFetchRequested={handleSuggestionsFetchRequested}
                handleSuggestionsClearRequested={handleSuggestionsClearRequested}
            />
            <div
                className='selectedLocationContainer'
            >
                {selectedLocations.map(location=>(
                    <PillButton
                        key={parseLocation(location)}
                        selected={true}
                        highlight={true}
                        onClick={() => handlePillClick(parseLocation(location))}
                    >
                        {parseLocation(location)}
                    </PillButton>
                ))}
            </div>
        </div>
    )
}

export default connect(state=>({
    location: state.QuickSetup.jobLocation.location,
    suggestions: state.QuickSetup.jobLocation.suggestions,
    selectedLocations: state.QuickSetup.jobLocation.selectedLocations,
}))(LocationAutoSuggest)