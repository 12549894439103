import { disable } from "mixpanel-browser";
import React, { useEffect, useRef, useState } from "react";
import ReactAutosuggest from "react-autosuggest";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../../uiLibrary/Loading";
import ProfileLiked from "../../uiLibrary/ProfileLiked/ProfileLiked";
import "./newAutoSuggestion.scss";
const NewAutoSuggestion = ({
  userEmail,
  handleToast = false,
  hideLoader = false,
  isLoading = false,
  themeInput = "newAutoSuggestionInput",
  themeSuggestion = "newAutoSuggestion",
  themeSuggestionsContainer = "newAutoSuggestionContainer",
  suggestionsArray = [],
  inputValue = "",
  placeholder,
  inputMembersProps,
  appendInputProps = {},
  toggleFocus = false,
  disabled,
  handleChangeInput = () => false,
  handleSuggestionSelected = () => false,
  handleSuggestionsFetchRequested = () => false,
  handleSuggestionsClearRequested = () => false,
  renderSuggestion = (suggestion) => (
    <div>{typeof suggestion === "string" ? suggestion : suggestion?.name}</div>
  ),
  shouldRenderSuggestions = (suggestion) =>
    typeof suggestion === "string"
      ? suggestion.trim()
      : suggestion?.name.trim(),
}) => {
  const [myTimeout, setMyTimeout] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);

  // console.log('THIS IS: ', this)

  useEffect(() => {
    // console.log("=============handleChangeInput=======inputRef.current.selectionStart===========", inputRef.current.selectionStart)
    // console.log("=============handleChangeInput=======inputRef.current.selectionEnd===========", inputRef.current.selectionEnd)
    // console.log("=============handleChangeInput=======cursorPosition===========", cursorPosition)

    inputRef.current.selectionStart = cursorPosition;
    inputRef.current.selectionEnd = cursorPosition;
    if (!hideLoader) {
      if (inputValue !== "") {
        toast.dismiss();
        if (inputValue.toLowerCase() === userEmail && handleToast) {
          toast(
            ({ closeToast }) => (
              <ProfileLiked
                header={`You can't add yourself as tribe member`}
              ></ProfileLiked>
            ),
            {
              closeButton: false,
              hideProgressBar: true,
              style: {
                backgroundColor: "#A0A0A0",
                borderRadius: "8px",
                width: "514px",
              },
            }
          );
        }
        if (myTimeout) {
          clearTimeout(myTimeout);
          setMyTimeout(
            setTimeout(() => handleSuggestionsFetchRequested(inputValue), 500)
          );
        } else {
          setMyTimeout(
            setTimeout(() => handleSuggestionsFetchRequested(inputValue), 500)
          );
        }
      }
    }
  }, [inputValue]);

  // useEffect(()=>{
  //   inputRef.current.focus()
  // }, [toggleFocus])

  const inputRef = useRef(null);

  useEffect(() => {
    // console.log('CURSOR POSITION IS: ', cursorPosition)
    if (cursorPosition) {
      // console.log('CURSOR POSITION IS: ', cursorPosition)
      // console.log('INPUT REF: ', inputRef)
      setTimeout(() => {
        // console.log('CURSOR POSITION SETTING TO: ', cursorPosition)
        inputRef.current.selectionStart = cursorPosition;
        inputRef.current.selectionEnd = cursorPosition;
      });
    }
  }, [cursorPosition]);

  const inputProps = {
    value: typeof inputValue === "string" ? inputValue : inputValue?.name,
    ref: inputRef,
    onChange: (e) => {
      setCursorPosition(e.target.selectionStart);
      handleChangeInput(e);
    },
    onFocus: (e) => {
      if (cursorPosition) {
        inputRef.current.selectionStart = cursorPosition;
        inputRef.current.selectionEnd = cursorPosition;
      } else {
        console.log("ELSE BLOCK");
      }
    },
    placeholder,
    disabled: disabled,
    ...appendInputProps,
  };

  const onSuggestionSelected = (event, { suggestionValue }) => {
    handleSuggestionSelected(suggestionValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {};

  // const timeoutHandler = (value) => {
  //   console.log("=============timeoutHandler================", value)
  //   handleSuggestionsFetchRequested(value);
  // }

  const onsSuggestionsClearRequested = () => {
    handleSuggestionsClearRequested();
  };
 
  return (
    <div
      className={`reactAutosuggestionContainer ${
        hideLoader ? "hide-loader" : ""
      }`}
    >
      <ReactAutosuggest
        theme={{
          input: themeInput,
          suggestion: themeSuggestion,
          suggestionsContainer: themeSuggestionsContainer,
        }}
        inputProps={inputMembersProps ? inputMembersProps : inputProps}
        suggestions={suggestionsArray}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onsSuggestionsClearRequested}
        renderSuggestion={renderSuggestion}
        shouldRenderSuggestions={shouldRenderSuggestions}
        getSuggestionValue={(suggestion) => suggestion}
      />
      {isLoading && <Loading width="24" height="24" />}
    </div>
  );
};

export default connect((state) => ({
  userEmail: state.auth.user.email
}))(NewAutoSuggestion);
