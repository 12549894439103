import React from "react";
import "../style.scss";

const MenuOpenedSvg = ({
  color = "#CDD1D7",
  width = "24",
  height = "24",
  style = {},
  stroke = 1.5,
  className = "",
  fill = "#A7ABB0",
  selected = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      color={color}
      className={`${className} ${selected ? "qluSvgFillSelected" : ""}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12L21 12"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 6H21"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 18H21"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 12L6.5 7.66987L6.5 16.3301L2 12Z" fill={fill} />
    </svg>
  );
};

export default MenuOpenedSvg;
