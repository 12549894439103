import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHiringCompany,
  setSelectedProfile,
  updateFeedbackEntity,
} from "../../../actions/QuickSetup";
import CompanyCard from "../../../components/CompanyCard/CompanyCard";
import { getEntityByProfileID } from "../../../reducers/QuickSetup/profileFeedback";
import Loading from "../../../uiLibrary/Loading";
import LikeableSection from "./LikeableSection";
import Position from "./Position";
import SectionWrapper from "./SectionWrapper";

export default function Experience({ experience = [], id, profile }) {
  const { data = {} } =
    useSelector((state) =>
      getEntityByProfileID(state.QuickSetup.profileFeedback, id)
    ) || {};

  const experienceFeedback = data?.experience;

  const dispatch = useDispatch();

  const isCompanyProfileLoading = useSelector(
    (state) => state.jobSetup.companyDetails.isCompanyProfileLoading
  );

  const handleLike = (liked, index) => {
    const updatedEntity = experienceFeedback.map((exp) =>
      exp.index === index
        ? Object.assign({}, exp, { ...exp, isOverallLiked: Boolean(liked) })
        : exp
    );

    dispatch(updateFeedbackEntity(id, "experience", updatedEntity));
  };

  const getIsExperienceLiked = (expIndex) => {
    const currentExperience = data?.experience?.find(
      ({ index }) => index === expIndex
    );

    return currentExperience?.isOverallLiked;
  };

  const fetchCompanyDetails = (experience) => {
    dispatch(selectHiringCompany(experience));
    dispatch({
      type: "TOGGLE_COMPANY_MODAL",
      isModalOpen: true,
    });
  };

  const convertToDate = (date) => {
    if (!date) {
      return "Now";
    }

    if (!date.includes("-")) {
      return date;
    }

    const [monthNumber, yearNumber] = date.split("-");
    const month = moment(monthNumber, "M").format("MMM");
    const year = moment(yearNumber, "Y").format("YYYY");
    return `${month} ${year}`;
  };
  return (
    <SectionWrapper name={"Experience"}>
      {experience?.map(({ title, company_name, start, end }, key) => (
        <React.Fragment key={key}>
          {key < 3 && (
            <LikeableSection
              isLiked={getIsExperienceLiked(key)}
              handleLike={(liked) => handleLike(liked, key)}
              key={key}
            >
              <Position
                title={title}
                onClick={() => fetchCompanyDetails(experience[key])}
                companyName={company_name}
              />
              <p>
                {convertToDate(start)} to {convertToDate(end)}
              </p>
              {/* {isCompanyProfileLoading && <Loading/>} */}
            </LikeableSection>
          )}
        </React.Fragment>
      ))}
      {experience?.length > 3 && (
        <p
          onClick={() => dispatch(setSelectedProfile(profile))}
          className="hyperlink"
        >
          See all
        </p>
      )}
    </SectionWrapper>
  );
}
