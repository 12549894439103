import React from "react";
import { Transition } from "react-transition-group";
import Backdrop from "../../../uiLibrary/Backdrop";
import "./styles.scss";

const duration = 900;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function Drawer({ isOpen, setIsOpen, children }) {
  return (
    <>
      {isOpen && <Backdrop onClick={() => setIsOpen(false)} />}
      <Transition in={isOpen} timeout={duration}>
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            className={"slide-drawer open"}
          >
            <div className="body">{children}</div>
          </div>
        )}
      </Transition>
    </>
  );
}
