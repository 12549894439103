import React from "react";
import "./styles.scss";

export default function TableHeaderStats({ label, value = "", subValue }) {
  return (
    <div className="stats">
      <p className="label">{label}</p>
      <div className="stats_values">
        <p className="value">{value}</p>
        {subValue && <p className="subValue">{subValue}</p>}
      </div>
    </div>
  );
}
