import * as ActionTypes from '../../actions/outbox'

const initialState = {
    drafts: [],
    personalised: [],
    isQluExtensionAvailable: false,
    isDraftFetching: false,
    isDraftFetched: false,

}


// export const LOAD_HYBRID_CAMPAIGN = 'LOAD_HYBRID_CAMPAIGN'
// export const LOAD_HYBRID_CAMPAIGN_SUCCESS = 'LOAD_HYBRID_CAMPAIGN_SUCCESS'
// export const LOAD_HYBRID_CAMPAIGN_FAILURE = 'LOAD_HYBRID_CAMPAIGN_FAILURE'

const meta = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CAMPAIGN_DRAFTS: {
        return {
            ...state,
            isDraftFetching: true,
            isDraftFetched: false,
        }
    }
    case ActionTypes.FETCH_CAMPAIGN_DRAFTS_SUCCESS: {
        return {
            ...state,
            isDraftFetching: false,
            isDraftFetched: true,
            drafts: action.payload.data
        }
    }
    case ActionTypes.FETCH_CAMPAIGN_DRAFTS_FAILURE: {
        return {
            ...state,
            isDraftFetching: false,
            isDraftFetched: false,
        }
    }
    default:
      return state
  }
}

export default meta
