import React from "react";
import { useState, useEffect } from "react";
import { useCountdown } from "../../../customHooks/useCountDown";
import Horizon from "../../../uiLibrary/SVGs/Horizon";
const horizonBg = require("../../../img/horizon.svg");

const Loader = ({ initialMinute, initialSeconds, onTimerStop }) => {
  const [minutes, seconds] = useCountdown({
    initialMinute,
    initialSeconds,
  });

  useEffect(() => {
    const isTimerStopped = minutes < 1 && seconds < 1;
    if (isTimerStopped) {
      onTimerStop();
    }
  }, [minutes, seconds]);

  return (
    <div>
      <Horizon />
      {minutes === 0 && seconds === 0 ? null : (
        <h1 className="loadingCountDown">
          {" "}
          0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </h1>
      )}
    </div>
  );
};

export default Loader;
