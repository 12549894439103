import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import rootReducer from './reducers/index'
import { apiMiddleware } from './middlewares/apiMiddleware'

const logger = createLogger({
  collapsed: true,
  predicate: () => process.env.REACT_APP_ENV !== 'production'
})

const store = createStore(
  rootReducer,
  applyMiddleware(
    apiMiddleware,
    thunk,
    logger
  )
)

export default store 