import React from 'react'
import '../style.scss'
const PlusSvg = ({
    className='',
    width = '16',
    height = '16',
    strokeWidth='2',
    style = {},
    selected=false
}) => {
    return (
        <svg 
            className={className}
            style={style} 
            width={width} 
            height={height} 
            viewBox="0 0 16 17" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}
                d="M8 3.8335V13.1668" 
                strokeWidth={strokeWidth}
                strokeLinecap="round" 
                strokeLinejoin="round"/>
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}
                d="M3.33301 8.5H12.6663" 
                strokeWidth={strokeWidth}
                strokeLinecap="round" 
                strokeLinejoin="round"/>
        </svg>

    )
}

export default PlusSvg
