import React, { useState, useRef } from 'react'
import ProfileDisplayImage from '../../../components/ProfileDisplayImage/ProfileDisplayImage'
import { connect } from 'react-redux'
import ConnectSvg from '../../../uiLibrary/SVGs/ConnectSvg'
import MailSVG from '../../../uiLibrary/SVGs/MailSvg'
import './messages.scss'

const moment = require('moment-timezone');

const Messages = ({ user, messages, profile }) => {

    const returnTime = (time) => {
        if (time.includes('hours')) {
            time = time.replace('hours', 'hrs')
        } else if (time.includes('minutes')) {
            time = time.replace('minutes', 'mins')
        }
        return time
    }

    const getDate = (date) => {
        return moment(new Date(date)).local().format('DD MMM');
    }

    return (
        <div className="messagesContainer">
            {
                messages.map((message, index) => {
                    return (
                        <div className={message.senderName == "You" ? 'single-comment self' : 'single-comment'} >
                            <div className="header">
                                <div className="commentSender">
                                    <div className="senderImage">
                                        {
                                            message.senderName == "You" ?
                                                <p>{message.senderImage}</p>
                                                :
                                                <ProfileDisplayImage
                                                    width="20"
                                                    height="20"
                                                    src={message.senderImage}
                                                />
                                        }
                                    </div>
                                    <div className="senderName">
                                        <p>{user.user.name}</p>
                                    </div>
                                </div>
                                <div className="flexOnly">
                                    <div className="channel">
                                        {
                                            message.channel === 'email' ?
                                                <MailSVG
                                                    selected={true}
                                                /> :
                                                <ConnectSvg
                                                    selected={true}
                                                />
                                        }
                                        <p>{message.channel === 'linkedin' ? "LinkedIn Message" : message.channel}</p>
                                    </div>
                                    <span className="globalDotStyleSecondary overviewDotStyle">•</span>
                                    <div className="time">
                                        <p>{message.dateTime ? getDate(message.dateTime) : ""}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="messageContainer">
                                <div className="message">
                                    <p dangerouslySetInnerHTML={{ __html: message.sentMessages }} />
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div >
    )
}

export default connect(state => ({
    user: state.auth
}))(Messages)