import React from 'react'

const CornerUpLeft = ({
    color = "#CDD1D7",
    width = "18",
    height = "18",
    style = {},
    stroke = 1.5, className = '',
    fill = "none"
}) => {
    return (
        <div>
            <svg
                width={width}
                height={height}
                className={className}
                viewBox="0 0 16 20"
                fill={fill}
                xmlns="http://www.w3.org/2000/svg"
                style={style}
            >
                <path d="M6.77792 10.4816L3.07422 6.77792L6.77792 3.07422" stroke={color} stroke-width={stroke} stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.9261 14.926V9.7408C14.9261 8.95497 14.6139 8.20133 14.0582 7.64566C13.5026 7.09 12.7489 6.77783 11.9631 6.77783H3.07422" stroke={color} stroke-width={stroke} stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </div>
    )
}

export default CornerUpLeft
