import React, { useState } from 'react'
import NewAutoSuggestion from '../../../components/NewAutoSuggestion/NewAutoSuggestion'
import { manualApiCall } from '../../../utils'
import checkIfWord from 'check-if-word'
import { set, words } from 'lodash'
const JobSetupAutoSuggest = ({
  filter,
  shouldFetch = true,
  apiType = 'specialty',
  inputValue,
  handleChangeInput,
  handleSuggestionSelected,
  toggleFocus
}) => {
  const [suggestionsArray, setSuggestionsArray] = useState([])
  // console.log("==============inputValue==============", inputValue)


  const handleSuggestionsFetchRequested = async value => {
    if (shouldFetch) {
      try {
        let words = checkIfWord("en")
        let { data } = await manualApiCall('/api/v2/auth/suggestion', {
          method: 'POST',
          body: JSON.stringify({
            name: apiType,
            value
          })
        })
        //string.charAt(0).toUpperCase() + string.slice(1)
        // data=data.map((d)=>{
        //   let tmp= d.charAt(0).toUpperCase() + d.slice(1)
        //   return tmp.replace(/[^a-zA-Z ]/g, "")
        // }).map((s)=>s.toLowerCase())
        let suggestion = data
        // suggestion = suggestion.map((sen)=>{
        //   console.log(sen,words.getValidWords(sen.split(" ")))
        //   return (words.getValidWords(sen.split(" ")).join(" "))
        // }
        // ).filter((sen)=>
        //   sen.length>0
        // )
        suggestion=[...new Set([value, ...suggestion])]
        // console.log("Suggestions filtered",suggestion)
        const newSuggestion = suggestion.filter(d => filter(d))
        setSuggestionsArray(newSuggestion)
      }
      catch (err) {
        console.log("Error: ", err.message)
        setSuggestionsArray([])
      }
    }
  }

  return (
    <NewAutoSuggestion
      // isLoading={isFetching}
      suggestionsArray={suggestionsArray}
      inputValue={inputValue}
      placeholder=''
      handleChangeInput={handleChangeInput}
      handleSuggestionSelected={handleSuggestionSelected}
      handleSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      handleSuggestionsClearRequested={e => setSuggestionsArray([])}
      // toggleFocus={toggleFocus}
      // hideLoader={true}
    />

  )
}

export default JobSetupAutoSuggest
