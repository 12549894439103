import { CALL_API } from "../../middlewares/apiMiddleware"

export const FETCH_MY_CAMPAIGNS = 'FETCH_MY_CAMPAIGNS'
export const FETCH_MY_CAMPAIGNS_SUCCESS = 'FETCH_MY_CAMPAIGNS_SUCCESS'
export const FETCH_MY_CAMPAIGNS_FAILURE = 'FETCH_MY_CAMPAIGNS_FAILURE'

export const fetchMyCampaigns = (page = 0, s = null) => ({
  [CALL_API]: {
    types: [FETCH_MY_CAMPAIGNS, FETCH_MY_CAMPAIGNS_SUCCESS, FETCH_MY_CAMPAIGNS_FAILURE],
    endpoint: `/api/v2/auth/campaigns?type=m&p=${page}${s ? `&s=${s}` : ''}`,
    options: {
      method: 'GET'
    }
  }
})


export const FETCH_TRIBE_CAMPAIGNS = 'FETCH_TRIBE_CAMPAIGNS'
export const FETCH_TRIBE_CAMPAIGNS_SUCCESS = 'FETCH_TRIBE_CAMPAIGNS_SUCCESS'
export const FETCH_TRIBE_CAMPAIGNS_FAILURE = 'FETCH_TRIBE_CAMPAIGNS_FAILURE'

export const fetchTribeCampaigns = (page = 0, s = null,) => ({
  [CALL_API]: {
    types: [FETCH_TRIBE_CAMPAIGNS, FETCH_TRIBE_CAMPAIGNS_SUCCESS, FETCH_TRIBE_CAMPAIGNS_FAILURE],
    endpoint: `/api/v2/auth/campaigns?type=t&p=${page}${s ? `&s=${s}` : ''}`,
    options: {
      method: 'GET'
    }
  }
})