import qs from 'qs'
import React, { useState } from 'react'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FETCH_PROVIDER_SUCCESS } from '../../actions/auth'

import PrimaryButton from '../../uiLibrary/PrimaryButton'
import { manualPublicApiCall } from '../../utils'

const ResendVerifyLink = ({
  location,
  history,
  dispatch
}) => {
  const params = qs.parse(location.search.slice(1))
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})

  const onCLick = async () => {
    console.log("INSIDE ON CLICK....")
    try {
      setIsLoading(true)
      const UserData = JSON.parse(window.atob(localStorage.getItem('UserData')))
      const res = await manualPublicApiCall('/public/resend/email', {
        method: "POST",
        body: JSON.stringify({ email: UserData.email, status: 'signup' })
      })
      if (res?.isSuccess) {
        dispatch({ type: FETCH_PROVIDER_SUCCESS, meta: UserData.email })
        history.push('/auth/verify-email', { from: 'resend' });
      }
      else {
        throw new Error("User not found")
      }
      setIsLoading(false) 
    } catch (e) {
      setIsLoading(false)
      setData({})
      console.log('AN ERROR OCCURRED: ', e.message)
    }
  }

  return (
    <div>
      {isLoading ? <Loader type='TailSpin' color='#297AF7' width={40} height={40} /> :
        <>
          <div className='ver_cb_root authElemContainer'>
            <p>You signed up but didn't finish the process </p>
            <div>
              <PrimaryButton onClick={onCLick}>
                Resend confirmation link
              </PrimaryButton>
            </div>
          </div>
        </>}
    </div>
  )
}

export default connect(state => ({ ...state.auth.emailStep }))(withRouter(ResendVerifyLink))