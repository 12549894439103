import { flattenDeep, get } from "lodash";
import { callLiApi, callLiWrapperApi, getTitleProfileCount, manualApiCall, getImgSrcURL } from "./index";

function getCompanyLogo(logo) {
  if (!logo) {
    return null;
  }

  const rootUrl = logo?.vectorImage?.rootUrl;
  const artifacts = logo?.vectorImage?.artifacts.sort(
    (a, b) => b.width - a.width
  );
  const pathSegment = artifacts[0].fileIdentifyingUrlPathSegment;

  return `${rootUrl}${pathSegment}`;
}

export const isArrayFull = (arr) => Array.isArray(arr) && arr.length > 0;

const isRecommenderSuccessful = (data) =>
  data?.elements &&
  Array.isArray(data?.elements) &&
  data?.elements.length > 0 &&
  data.elements[0]?.cards &&
  Array.isArray(data.elements[0]?.cards) &&
  data.elements[0]?.cards.length > 0;

const getUniversalName = (url) =>
  url.match(/\/company\/.+(\/*)/g)
    ? url.match(/\/company\/.+(\/*)/g)[0].split("/")[2]
    : url.match(/\/showcase\/.+(\/*)/g)
      ? url.match(/\/showcase\/.+(\/*)/g)[0].split("/")[2]
      : "";

export const getTitleCount = (name) => {
  console.log("My Titles in getTitle", name);
  const locations = [];

  return new Promise((resolve, reject) =>
    getTitleProfileCount(name, locations)
      .then((totalResultCount) => resolve(totalResultCount))
      .catch(reject)
  )
};

export const callCompanyRecommenderApi = (urn) => {
  return new Promise((resolve, reject) =>
    callLiWrapperApi("CompanyRecommender", null, { urn })
      .then((resp) => {
        const data = resp.data?.data?.organizationDashDiscoverCardGroupsByAllCardsFromGroup;
        if (isRecommenderSuccessful(data)) {
          const response = data.elements[0].cards.map((card) => {
            const entity = card?.value?.entityCard;
            const name = entity?.entityLockupView?.title?.text || "";
            const industry = entity?.entityLockupView?.subtitle?.text || "";
            const url = entity?.entityLockupView?.navigationUrl || "";

            const universalName = url !== "" ? getUniversalName(url) : undefined;
            const followingCount = entity?.secondarySubtitle?.text || "";
            const urn = (isArrayFull(entity?.entityLockupView?.image?.attributes) &&
              entity?.entityLockupView?.image?.attributes[0]?.detailData
                ?.companyLogo?.entityUrn) ||
              "";
            const vectorImage = entity?.entityLockupView?.image?.attributes[0]?.detailData?.companyLogo?.logoResolutionResult?.vectorImage
            const logoURL =
              isArrayFull(entity?.entityLockupView?.image?.attributes) &&
                vectorImage?.rootUrl && isArrayFull(vectorImage?.artifacts)
                ? `${vectorImage?.rootUrl}${vectorImage?.artifacts.filter(
                  (itm) => itm.width > 200
                )[0]?.fileIdentifyingUrlPathSegment
                }`
                : "";
            return {
              name,
              industry,
              url,
              universalName,
              followingCount,
              urn,
              logoURL,
              active: false,
              recommended: false,
              details: {},
            };
          });

          // console.log("==========================response===============================", response)
          resolve(response);
        } else {
          resolve([]);
        }
      })
      .catch((err) => resolve([]))
  );
};
export const getCompanyDetailsFromDB = async (
  universalName,
  companyData = {}
) => {
  //bugfix-NA-s21-getcurrentcompanyData
  console.log("universlName @ FE DB - Function", universalName);
  try {
    const res = await manualApiCall(`/api/v2/auth/getCurrentCompanyInfo`, {
      method: "POST",
      body: JSON.stringify({
        universalName: universalName,
      }),
    });
    console.log(
      "🚀 ~ file: visitApiProfile.js:143 ~ getCompanyDetails ~ res:",
      res
    );

    if (
      res?.response &&
      Array.isArray(res.response) &&
      res?.response?.length > 0
    ) {
      const data = res.response[0];
      console.log(
        "🚀 ~ file: visitApiProfile.js:155 ~ getCompanyDetails ~ data:",
        data
      );
      companyData["isDepreciated"] = false;
      companyData["name"] = data?.name ? data?.name.replace(/'/g, "''") : null;
      companyData["companyEmployeesSearchPageUrl"] = data?.liPage || null;
      companyData["companyPageUrl"] = data?.website || null;
      companyData["companyLogoUrl"] = data?.logo ? data.logo : null;
      companyData["companyType"] = data?.companytype || null;
      companyData["defaultLocale"] = data?.defaultLocale || null; // not availabe
      companyData["companyDescription"] = data?.description
        ? data?.description.replace(/'/g, "''")
        : null;

      console.log(
        "CompanyDescription:",
        companyData["companyDescription"],
        typeof companyData["companyDescription"]
      );
      console.log(
        "CompanyDescription:",
        data.description,
        typeof data.description
      );

      if (
        companyData["companyDescription"] == undefined ||
        companyData["companyDescription"] == "undefined"
      ) {
        companyData["companyDescription"] = null;
      }
      companyData["entityUrn"] = data?.urn || null;
      companyData["followerCount"] =
        parseInt(data?.followingInfo?.followerCount) || null; // not availabe
      companyData["headquarter"] = data?.headquarter
        ? {
          ...data.headquarter,
          city: data.headquarter?.city
            ? data.headquarter?.city.replace(/'/g, "''")
            : null,
          line1: data.headquarter?.line1
            ? data.headquarter?.line1.replace(/'/g, "''")
            : null,
          line2: data.headquarter?.line2
            ? data.headquarter?.line2.replace(/'/g, "''")
            : null,
        }
        : null;
      companyData["specialities"] = data?.specialties
        ? data?.specialties.map((s) => s.replace(/'/g, "''"))
        : null;
      companyData["stockQuotes"] = data?.stockQuotes || null; // not availabe
      companyData["staffCount"] = parseInt(data?.staffcount) || null;
      companyData["staffCountRange"] = data?.staffcountrange || null;
      companyData["trackingInfo"] = data?.trackingInfo || null;
      companyData["type"] = data?.type || null;
      companyData["url"] = data?.liPage || null;
      companyData["industries"] =
        {
          ...(data?.companyindustries?.[0] || {}),
          name: data?.companyindustries?.localizedName,
        } || null;

      console.log("CompanyData===>", companyData);
      return companyData;
    } else {
      return null;
    }
  } catch (e) {
    console.log("Error", e.message);
  }

  ///end
};
export const getCompanyDetails = async (universalName, companyData = {}) => {
  const url = `/voyager/api/organization/companies?decorationId=com.linkedin.voyager.deco.organization.web.WebFullCompanyMain-33&q=universalName&universalName=${encodeURIComponent(
    universalName
  )}`;

  //bugfix-NA-s21-getcurrentcompanyData
  // console.log("universlName @ FE", universalName);
  // try {
  //   const res = await manualApiCall(`/api/v2/auth/getCurrentCompanyInfo`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       universalName: universalName,
  //     }),
  //   });
  //   console.log(
  //     "🚀 ~ file: visitApiProfile.js:143 ~ getCompanyDetails ~ res:",
  //     res
  //   );

  //   if (
  //     res?.response &&
  //     Array.isArray(res.response) &&
  //     res?.response?.length > 0
  //   ) {
  //     const data = res.response[0];
  //     console.log(
  //       "🚀 ~ file: visitApiProfile.js:155 ~ getCompanyDetails ~ data:",
  //       data
  //     );
  //     companyData["isDepreciated"] = false;
  //     companyData["name"] = data?.name ? data?.name.replace(/'/g, "''") : null;
  //     companyData["companyEmployeesSearchPageUrl"] = data?.liPage || null;
  //     companyData["companyPageUrl"] = data?.website || null;
  //     companyData["companyLogoUrl"] = data?.logo ? data.logo : null;
  //     companyData["companyType"] = data?.companytype || null;
  //     companyData["defaultLocale"] = data?.defaultLocale || null; // not availabe
  //     companyData["companyDescription"] = data?.description
  //       ? data?.description.replace(/'/g, "''")
  //       : null;

  //     console.log(
  //       "CompanyDescription:",
  //       companyData["companyDescription"],
  //       typeof companyData["companyDescription"]
  //     );
  //     console.log(
  //       "CompanyDescription:",
  //       data.description,
  //       typeof data.description
  //     );

  //     if (
  //       companyData["companyDescription"] == undefined ||
  //       companyData["companyDescription"] == "undefined"
  //     ) {
  //       companyData["companyDescription"] = null;
  //     }
  //     companyData["entityUrn"] = data?.urn || null;
  //     companyData["followerCount"] =
  //       parseInt(data?.followingInfo?.followerCount) || null; // not availabe
  //     companyData["headquarter"] = data?.headquarter
  //       ? {
  //           ...data.headquarter,
  //           city: data.headquarter?.city
  //             ? data.headquarter?.city.replace(/'/g, "''")
  //             : null,
  //           line1: data.headquarter?.line1
  //             ? data.headquarter?.line1.replace(/'/g, "''")
  //             : null,
  //           line2: data.headquarter?.line2
  //             ? data.headquarter?.line2.replace(/'/g, "''")
  //             : null,
  //         }
  //       : null;
  //     companyData["specialities"] = data?.specialties
  //       ? data?.specialties.map((s) => s.replace(/'/g, "''"))
  //       : null;
  //     companyData["stockQuotes"] = data?.stockQuotes || null; // not availabe
  //     companyData["staffCount"] = parseInt(data?.staffcount) || null;
  //     companyData["staffCountRange"] = data?.staffcountrange || null;
  //     companyData["trackingInfo"] = data?.trackingInfo || null;
  //     companyData["type"] = data?.type || null;
  //     companyData["url"] = data?.liPage || null;
  //     companyData["industries"] =
  //       {
  //         ...(data?.companyindustries?.[0] || {}),
  //         name: data?.companyindustries?.localizedName,
  //       } || null;

  //     console.log("CompanyData===>", companyData);
  //     return companyData;
  //   } else {
  //     return null;
  //   }
  // } catch (e) {
  //   console.log("Error", e.message);
  // }

  ///end

  return new Promise((resolve, reject) =>
    // callLiApi(url,
    //   {
    //     headers: {
    //       "x-restli-protocol-version": "2.0.0"
    //     }
    //   })
    //==@liWrapper==//
    callLiWrapperApi("CompanyDetails", null, { universalName })
      .then((r) => {
        const data = r.data;
        const elements = data?.data?.organizationDashCompaniesByUniversalName?.elements
        if (elements && Array.isArray(elements) && elements.length > 0) {
          const apiData = elements[0];
          console.log(
            "===================callLinkedinApi====================",
            apiData
          );
          companyData["isDepreciated"] = false;
          companyData["name"] = apiData?.name
            ? apiData?.name.replace(/'/g, "''")
            : null;
          companyData["companyPageUrl"] = apiData?.websiteUrl || apiData.callToAction?.type === 'VIEW_WEBSITE' ?
            apiData?.callToAction?.url : null;

          const logoObj = apiData?.logoResolutionResult?.vectorImage
          const imgSrc = getImgSrcURL(
            logoObj?.rootUrl,
            logoObj?.artifacts
          );
          companyData["companyLogoUrl"] = imgSrc
          // logoObj?.artifacts &&
          //   logoObj &&
          //   apiData?.logo?.image[
          //     "com.linkedin.common.VectorImage"
          //   ].artifacts.filter((itm) => itm.width > 200).length > 0
          //   ? `${logoObj
          //     .rootUrl
          //   }${apiData?.logo?.image[
          //     "com.linkedin.common.VectorImage"
          //   ].artifacts.filter((itm) => itm.width > 200)[0]
          //     ?.fileIdentifyingUrlPathSegment
          //   }`
          // : null;

          companyData["companyDescription"] = apiData?.description
            ? apiData?.description.replace(/'/g, "''")
            : null;
          companyData["entityUrn"] = apiData?.entityUrn || null;
          companyData["followerCount"] = parseInt(apiData?.followingState?.followerCount) || null;
          const hqAddress = apiData?.headquarter?.address
          companyData["headquarter"] = hqAddress
            ? {
              ...apiData.headquarter,
              city: hqAddress?.city
                ? hqAddress?.city.replace(/'/g, "''")
                : null,
              line1: hqAddress?.line1
                ? hqAddress?.line1.replace(/'/g, "''")
                : null,
              line2: hqAddress?.line2
                ? hqAddress?.line2.replace(/'/g, "''")
                : null,
            }
            : null;
          companyData["specialities"] = apiData?.specialities
            ? apiData?.specialities.map((s) => s.replace(/'/g, "''"))
            : null;
          companyData["staffCount"] = parseInt(apiData?.employeeCount) || null;
          companyData["staffCountRange"] = apiData?.employeeCountRange || null;
          companyData["url"] = apiData?.url || null;
          companyData["industries"] =
            {
              entityUrn: apiData?.industry[0]?.entityUrn,
              name: apiData?.industry[0]?.name,
            } || null;
          companyData["type"] = apiData?.pageType || null;

          // NOTFOUND:---------------------Data not found in new API-------------------------------------
          companyData["companyEmployeesSearchPageUrl"] =
            apiData?.companyEmployeesSearchPageUrl || null;
          companyData["companyType"] = apiData?.companyType || null;
          companyData["defaultLocale"] = apiData?.defaultLocale || null;
          companyData["stockQuotes"] = apiData?.stockQuotes || null;
          companyData["trackingInfo"] = apiData?.trackingInfo || null;
          // ----------------------------------------------------------


          console.log("CompanyData", companyData);
          resolve(companyData);

          // resolve([data?.code, companyData, (data?.elements && data?.elements.length > 0)])
        } else {
          resolve(null);
          // throw new Error("Api Data Error!")
        }
      })
      .catch((err) => {
        console.log("[ERROR]:");
        console.log(err);
        resolve(null);
        // reject(err)
      })
  );
};

export const visitApiProfileLi = (publicIdentifier) => {
  return new Promise((resolve, reject) => {
    const payload = {
      experience: [],
      publications: [],
      education: [],
      skills: [],
      publicIdentifier: "",
      industryCode: "",
      picture: "",
      scrapeType: "DEEP",
      trackingId: "",
      locationName: "",
      postalCode: "",
      versionTag: "",
      schoolName: "",
      fieldOfStudy: "",
      title: "",
      companyName: "",
      languages: [],
      firstName: "",
      lastName: "",
      full_name: "",
      entityUrn: "",
      headline: "",
      summary: "",
      industry: "",
      image_url: "",
      locality: "",
      country_code: "",
      primary_email: "",
      phone_number: "",
      current_company_website: "",
      company_linkedin_url: "",
      current_company_specialties: "",
      current_company_size: "",
      current_company_name: "",
      current_company_industry: "",
    };

    callLiApi(
      `/voyager/api/identity/profiles/${publicIdentifier}/profileView`,
      { headers: {} }
    )
      .then(({ data }) => {
        console.log("PROFILE IS: ", data);
        const companyCode =
          data.positionView.elements.length > 0 &&
            data.positionView.elements[0].companyUrn
            ? data.positionView.elements[0].companyUrn.split(":").pop()
            : "";

        // console.log('company code is: ', companyCode)
        const profUrn = data.profile.entityUrn.replace(
          "urn:li:fs",
          "urn:li:fsd"
        );
        const profUrl = encodeURIComponent(profUrn);
        console.log("profile url: ", profUrl);

        return Promise.all([
          data,
          callLiApi(
            `/voyager/api/identity/profiles/${publicIdentifier}/profileContactInfo`,
            { headers: {} }
          ).then((r) => r.data),
          companyCode && !["", "null", "NULL"].includes(companyCode.trim())
            ? callLiApi(`/voyager/api/organization/companies/${companyCode}`, {
              headers: {},
            }).then((r) => r.data)
            : Promise.resolve(null),
          callLiApi(
            `/voyager/api/identity/profiles/${publicIdentifier}/skillCategory?includeHiddenEndorsers=true`,
            { headers: {} }
          ).then((r) => r.data),
          callLiApi(
            `/voyager/api/identity/dash/profilePositionGroups?q=viewee&profileUrn=${profUrl}&decorationId=com.linkedin.voyager.dash.deco.identity.profile.FullProfilePositionGroup-27&count=50&start=0`,
            { headers: {} }
          ).then((r) => r.data),
        ]);
      })
      .then(
        ([
          profileView,
          contactInfo,
          company,
          profileSkills,
          profileExperiences,
        ]) => {
          console.log("LAST THEN BLOCK: ", {
            profileView,
            contactInfo,
            company,
            profileSkills,
            profileExperiences,
          });

          const data = profileView.profile;

          payload.publicIdentifier = data.miniProfile.publicIdentifier;
          payload.industryCode = data.industryUrn
            ? data.industryUrn.replace(/urn:li:fs_industry:/, "")
            : "";

          if (data.miniProfile.picture) {
            const config =
              data.miniProfile.picture["com.linkedin.common.VectorImage"];
            payload.picture =
              config.artifacts && 0 < config.artifacts.length
                ? config.rootUrl +
                "" +
                config.artifacts.splice(-1)[0].fileIdentifyingUrlPathSegment
                : "";
          } else {
            payload.picture = "";
          }

          payload.trackingId = data.miniProfile.trackingId;
          payload.locationName = data.locationName;
          payload.postalCode =
            (data.location &&
              data.location.basicLocation &&
              data.location.basicLocation.postalCode) ||
            "";
          payload.versionTag = data.versionTag;

          if (
            profileView.educationView &&
            profileView.educationView.elements &&
            0 < profileView.educationView.elements.length
          ) {
            payload.schoolName =
              profileView.educationView.elements[0].schoolName || "";
            payload.fieldOfStudy =
              profileView.educationView.elements[0].fieldOfStudy || "";
          } else {
            payload.schoolName = "";
            payload.fieldOfStudy = "";
          }

          // left experiences here

          payload.languages =
            profileView.languageView &&
              profileView.languageView.elements &&
              0 < profileView.languageView.elements.length
              ? profileView.languageView.elements
                .map(function (a) {
                  return a.name;
                })
                .toString()
              : "";

          payload.firstName = data.firstName;
          payload.lastName = data.lastName;
          payload.full_name = payload.firstName + " " + payload.lastName;
          payload.entityUrn = data.entityUrn.replace(/urn:li:fs_data:/, "");
          payload.headline = data.headline;
          payload.title = data.headline;
          payload.summary = data.summary;
          payload.industry = data.industryName;

          if (data.miniProfile.picture) {
            var vectorImg =
              data.miniProfile.picture["com.linkedin.common.VectorImage"];
            if (vectorImg.artifacts && vectorImg.artifacts.length > 0) {
              payload.image_url =
                vectorImg["rootUrl"] +
                "" +
                vectorImg.artifacts.splice(-1)[0].fileIdentifyingUrlPathSegment;
            }
          }

          payload.locality = data.geoLocationName;

          if (data.location.basicLocation.countryCode)
            payload.country_code = data.location.basicLocation.countryCode;

          if (profileView.publicationView.elements.length) {
            profileView.publicationView.elements.forEach(function (v) {
              const info = { authors: [] };
              info.url = v.url;
              info.title = v.name;
              info.publisher = v.publisher;
              info.summary = "";
              if (v.authors.length) {
                for (var i = 0; i < v.authors.length; i++) {
                  var authorInfo = {};
                  if (typeof v.authors[i].member !== "undefined") {
                    if (typeof v.authors[i].member.url !== "undefined")
                      authorInfo.url = v.authors[i].member.url;
                    if (typeof v.authors[i].member.firstName !== "undefined")
                      authorInfo.full_name = v.authors[i].member.firstName;
                    if (typeof v.authors[i].member.lastName !== "undefined")
                      authorInfo.full_name +=
                        " " + v.authors[i].member.lastName;
                  }
                  info.authors.push(authorInfo);
                }
              }
              payload.publications.push(info);
            });
          }

          if (profileView.educationView.elements.length) {
            profileView.educationView.elements.forEach(function (v) {
              let fieldOfStudy = v.fieldOfStudy || "";
              let degreeName = v.degreeName || "";

              let start = "",
                end = "";

              if (typeof v.timePeriod != "undefined") {
                if (typeof v.timePeriod.startDate !== "undefined") {
                  if (typeof v.timePeriod.startDate.month !== "undefined") {
                    start = v.timePeriod.startDate.month;
                    if (typeof v.timePeriod.startDate.year !== "undefined") {
                      start += "-" + v.timePeriod.startDate.year;
                    }
                  } else if (
                    typeof v.timePeriod.startDate.year !== "undefined"
                  ) {
                    start += v.timePeriod.startDate.year;
                  }
                }

                if (typeof v.timePeriod.endDate !== "undefined") {
                  if (typeof v.timePeriod.endDate.month !== "undefined") {
                    end = v.timePeriod.endDate.month;
                    if (typeof v.timePeriod.endDate.year !== "undefined") {
                      end += "-" + v.timePeriod.endDate.year;
                    }
                  } else if (typeof v.timePeriod.endDate.year !== "undefined") {
                    end += v.timePeriod.endDate.year;
                  }
                }
              }

              payload.education.push({
                schoolName: v.schoolName,
                fieldOfStudy: fieldOfStudy,
                degreeName: degreeName,
                start: start,
                end: end,
              });
            });
          }

          if (contactInfo && contactInfo.emailAddress) {
            payload.primary_email = contactInfo.emailAddress || "";
          }
          if (contactInfo && contactInfo.phoneNumbers) {
            payload.phone_number =
              contactInfo.phoneNumbers.map((x) => x.number).toString() || "";
          }

          if (company) {
            payload.current_company_website = company.companyPageUrl;
            payload.company_linkedin_url = company.url;
            payload.current_company_specialties = get(
              company,
              "specialities",
              []
            ).join(", ");
            payload.current_company_size =
              get(company, "staffCount", null) + " employees";
            payload.current_company_name = company.name;
            payload.current_company_industry =
              company.industries && company.industries.length
                ? company.industries.join(", ")
                : "";
          }

          if (profileSkills.elements.length) {
            // console.log('ALL SKILLS ARE: ', allSkills)
            // const filteredSkills = profileSkills.elements.filter(item => ["TOP", "INDUSTRY_KNOWLEDGE", "TOOLS_TECHNOLOGIES", "NONE", "INTERPERSONAL"].includes(item.type)) //SPOKEN_LANGUAGES
            //   .map(item => item.endorsedSkills)

            const filteredSkills = profileSkills.elements.map(
              (item) => item.endorsedSkills
            );

            const skillNames = [];
            filteredSkills.forEach((fs) => {
              fs.forEach((sk) => {
                skillNames.push(sk.skill.name);
              });
            });

            payload.skills = skillNames;
          }

          if (profileExperiences.elements.length) {
            const currentCompany = company;
            const profilePositions = [];
            const profilePositionsArray = flattenDeep(
              profileExperiences.elements.map(
                (item) => item.profilePositionInPositionGroup.elements
              )
            );
            // let index = 0
            profilePositionsArray.forEach((item, i) => {
              // console.log('============= ITEM: ===============', item)
              // const currentCompany = company
              const company = item.company;
              const dateRange = get(item, "dateRange", null);

              const start =
                dateRange && dateRange.start
                  ? `${dateRange.start.month
                    ? `${dateRange.start.month}-${dateRange.start.year}`
                    : `${dateRange.start.year
                      ? `${dateRange.start.year}`
                      : ``
                    }`
                  }`
                  : ``;
              const end =
                dateRange && dateRange.end
                  ? `${dateRange.end.month
                    ? `${dateRange.end.month}-${dateRange.end.year}`
                    : `${dateRange.end.year ? `${dateRange.end.year}` : ``}`
                  }`
                  : ``;

              const industryObj = get(company, "industry", {});
              let industries = [];

              for (let prop in industryObj) {
                industries.push(industryObj[prop].name);
              }

              const universalName = get(company, "universalName", null);

              const companyPayload = {
                company_name: get(
                  company,
                  "name",
                  get(item, "companyName", "")
                ),
                urn: get(company, "entityUrn", null),
                universalName,
                logo: getCompanyLogo(get(company, "logo", null)),
                description: get(item, "description", ""),
                end: end,
                industries: industries,
                location: get(item, "locationName", ""),
                companyWebsite: get(
                  i === 0 ? currentCompany : company,
                  "companyPageUrl",
                  null
                ),
                companyPageUrl: get(
                  i === 0 ? currentCompany : company,
                  "url",
                  null
                ),
                companyType: get(
                  i === 0 ? currentCompany : company,
                  "type",
                  null
                ),
                companySize: get(
                  i === 0 ? currentCompany : company,
                  "staffCount",
                  null
                ),
                companySpecialities: get(
                  i === 0 ? currentCompany : company,
                  "specialities",
                  null
                ),
                start: start,
                title: get(item, "title", ""),
              };

              // if(index===0 && universalName){
              //   profilePositions.push(new Promise((res, rej) => getCompanyDetails(universalName, companyPayload).then(r=>r?.[1]).then(r=>res(r)).catch(err=>rej(companyPayload))))
              // }
              // else{
              profilePositions.push(companyPayload);
              // }
              // index+=1
            });

            payload.experience = profilePositions;
            payload.title = profilePositions[0]
              ? profilePositions[0].title
              : payload.title;
          }

          return payload;
        }
      )
      .then((payload) => resolve(payload))
      // .then(payload => {
      //   const universalName = payload?.experience?.[0]?.universalName
      //   return Promise.all([
      //     payload,
      //     getCompanyDetails(universalName)
      //   ])

      // })
      // .then(([
      //   payload, currentCompanyDetails
      // ]) => {
      //   console.log('PYLOA AND CCCCD', { payload, currentCompanyDetails })
      //   const currentCompany = currentCompanyDetails?.[1] || {}
      //   const newPayload = {
      //     ...payload,
      //     experience: payload.experience.map((item, index) => index === 0 ? ({ ...item, ...currentCompany }) : item)
      //   }
      //   resolve(newPayload)
      // })
      .catch((err) => {
        console.log("AN ERROR OCCURED: ", err.message);
        reject(err);
      });
  });
};
