import * as ActionTypes from '../../actions/JobSetup'

const initialState = {
    suggestions: [],
    location: '',
    selectedLocations: [],
    locationSuggestions: [],
    isLoading: false,
    isLoaded: false,
    isLoadedSuggestion: false,
    isLoadingSuggestion: false,
    specificLocation: {},
    similarLocations: [],
    isLoadedSimilar: false,
    isLoadingSimilar: false,
    isLocationEdited:false
}

const jobLocation = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_JOB_LOCATION: {
        return initialState
    } 
    case ActionTypes.SET_JOB_LOCATION: {
        return {
            ...state,
            location: action.payload
        }
    } 
    case ActionTypes.ADD_SELECTED_JOB_LOCATION: {
      return {
          ...state,
          selectedLocations: [...state.selectedLocations, action.payload],
      }
    } 
    case ActionTypes.SET_JOB_LOCATION_EDITED: {
      return {
          ...state,
          isLocationEdited: action.payload,
      }
    } 
    case ActionTypes.REMOVE_SELECTED_JOB_LOCATION: {
      return {
          ...state,
          selectedLocations: state.selectedLocations.filter(location => (
            typeof location === 'string' ?
              location!==action.payload :
              location?.name!==action.payload
            )
          )
      }
    } 
    case ActionTypes.CLEAR_JOB_LOCATION_SUGGESTION: {
        return {
            ...state,
            suggestions: []
        }
    } 
    case ActionTypes.FETCH_JOB_LOCATIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        suggestions: action.payload
      }
    }
    case ActionTypes.FETCH_JOB_LOCATIONS: {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        }
    }
    case ActionTypes.FETCH_JOB_LOCATIONS_FAILURE: {
        return {
          ...state,
          isLoading: false,
          isLoaded: false,
        }
    }
        // SUGGESTED
        case ActionTypes.FETCH_SUGGESTED_JOB_LOCATIONS_SUCCESS: {
          return {
            ...state,
            isLoadedSuggestion: true,
            isLoadingSuggestion: false,
            locationSuggestions:  state.locationSuggestions.concat(action.payload)
          };
        }
        case ActionTypes.FETCH_SUGGESTED_JOB_LOCATIONS: {
          return {
            ...state,
            isLoadingSuggestion: true,
            isLoadedSuggestion: false,
          };
        }
        case ActionTypes.FETCH_SUGGESTED_JOB_LOCATIONS_FAILURE: {
          return {
            ...state,
            isLoadingSuggestion: false,
            isLoadedSuggestion: false,
          };
        }
        case ActionTypes.REMOVE_SUGGESTED_JOB_LOCATION: {
          return {
            ...state,
            locationSuggestions: state.locationSuggestions.filter((location) =>
              typeof location === "string"
                ? location !== action.payload
                : location?.name !== action.payload
            ),
          };
        }
        // SIMILAR
        case ActionTypes.FETCH_SIMILAR_JOB_LOCATIONS: {
          return {
            ...state,
            isLoadingSimilar: true,
            isLoadedSimilar: false,
          };
        }
        case ActionTypes.FETCH_SIMILAR_JOB_LOCATIONS_SUCCESS: {
          return {
            ...state,
            isLoadedSimilar: true,
            isLoadingSuggestion: false,
            similarLocations:  state.similarLocations.concat(action.payload)
          };
        }
        case ActionTypes.SET_SPECIFIC_LOCATION: {
          return {
            ...state,
            specificLocation: {
              ...state.specificLocation,
              [action.payload.key] : action.payload.value
            }
          };
        }
    default:
      return state
  }
}

export default jobLocation
