import React, { useState } from "react";
import { connect } from "react-redux";
import ProfileSummaryCard from "../../../components/ProfileSummaryCard/ProfileSummaryCard";
import UsersIcon from "../../../uiLibrary/SVGs/UsersIcon";
import EmailTag from "../../../uiLibrary/EmailTag";
import "./styles.scss";
import { setOutboxView } from "../../../actions/outbox";
import SecondaryButton from "../../../uiLibrary/SecondaryButton";

const RecipientTab = ({
  disabled,
  recipientTab,
  title,
  jobprofile_id,
  publicIdentifier,
  dispatch,
  email_address,
  name,
  company_name,
  image_url,
  is_personalised,
}) => {
  const handleSelectProspect = () => {
    if (!is_personalised) {
      return;
    }
    if (disabled) {
      return;
    }
    if (recipientTab !== jobprofile_id) {
      dispatch(
        setOutboxView({
          recipientTab: jobprofile_id,
        })
      );
    }
  };

  return (
    <div
      className={`recipientTab ${
        recipientTab === jobprofile_id ? "selectedTab" : ""
      }`}
      onClick={handleSelectProspect}
    >
      <div>
        <ProfileSummaryCard
          imgSrc={image_url}
          name={name}
          headline={`${title} @ ${company_name}`}
          url={`https://www.linkedin.com/in/${publicIdentifier}/`}
        />
      </div>
      <div>
        <EmailTag found={email_address} canAddEmail={false}>
          {!!email_address ? "Email address found" : "Email address not found"}
        </EmailTag>
        {is_personalised && (
          <SecondaryButton
            style={{
              padding: "2px 8px",
            }}
          >
            {"Personalized"}
          </SecondaryButton>
        )}
      </div>
    </div>
  );
};

const RecipientsList = ({
  savedRounds,
  recipientTab,
  dispatch,
  disabled = false,
  recipients = [],
}) => {
  const onAllRecipientTab = (e) => {
    if (disabled) {
      return false;
    } else if (recipientTab !== "all") {
      dispatch(
        setOutboxView({
          recipientTab: "all",
        })
      );
    }
  };

  return (
    <div className="recipientsListContainer">
      <div
        className={`allRecipientsTab ${
          recipientTab === "all" ? "selectedTab" : ""
        }`}
        onClick={onAllRecipientTab}
      >
        <span>
          <span>
            <UsersIcon selected={true} />
          </span>
          <p>All Recipients</p>
        </span>
        <p>{recipients.length}</p>
      </div>
      {recipients.map((recepient, key) => (
        <RecipientTab
          key={key}
          disabled={disabled}
          rounds={savedRounds}
          recipientTab={recipientTab}
          {...recepient}
          dispatch={dispatch}
        />
      ))}
    </div>
  );
};

export default connect((state) => ({
  savedRounds: state.outbox.campaigns.savedRounds,
  recipientTab: state.outbox.viewController.recipientTab,
}))(RecipientsList);
