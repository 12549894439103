import React, { useEffect, useState } from "react";
import CloseButton from "../CloseButton/CloseButton";
import "./CompanyCard.scss";
import "../_globalCSSClasses.scss";
import CompanyPlaceholder from "../../img/company-placeholder.png";
import { numberWithCommas } from "../../utils";
import CompanySvg from "../../uiLibrary/SVGs/CompanySvg";

const CompanyCardDictionary = ({ attribute, value }) => {
  return (
    <div className="companyCardKeyValue">
      <h3>{attribute}</h3>
      <p
        style={{
          maxHeight: '50px',
          overflow: 'auto'
        }}
        dangerouslySetInnerHTML={{
          __html: value ? value : "Not Found",
        }}
      ></p>
    </div>
  );
};

const CompanyCard = ({
  companyLogo = "",
  companyName = "",
  companyHeadline = "",
  companyEmployees = "0 employees",
  companyDescription = "",
  industry = "",
  specialities = [],
  staffCount = 0,
  companyPageUrl = "",
  headquarter = [],
  companyType = "",
  onClick = (e) => false,
  isLoading = false,
}) => {
  
  console.log("===============CompanyCard=================", {companyLogo, companyName})

  const [imgHasError, setImgHasError] = useState(false)
  useEffect(() => {
    setImgHasError(false)
    console.log("===============companyLogo=================", {companyLogo, companyName})
  }, [companyLogo])
  
  return (
    <div className="companyCard">
      <div className="companyCardHeader">
        {companyLogo && !imgHasError ? (
          <img
            src={companyLogo}
            alt={companyName}
            onError={() => setImgHasError(true)}
            width="46"
            height="46"
            loading="lazy"
          />
        ) : (
          <span className="placeHolderContainer">
            <CompanySvg width={32} height={32} />
          </span>
        )}
        <span>
          <h3>{companyName}</h3>
          <div>
            <p dangerouslySetInnerHTML={{
              __html: industry ? industry : "Not Found",
            }}></p>
            {staffCount > 0 && (
              <>
                <span className="dotSeperator" />
                <p>{`${numberWithCommas(staffCount)} employees`}</p>
              </>
            )}
          </div>
        </span>
      </div>
      <p className="companyDescription">{companyDescription}</p>
      <div className="companyCardBody">
        {staffCount === 0 &&
        specialities?.length === 0 &&
        industry.length === 0 &&
        companyPageUrl.length === 0 &&
        headquarter.length === 0 ? (
          <div
            className="companyCardKeyValue"
            style={{ justifyContent: "center" }}
          >
            <p>Company information not available</p>
          </div>
        ) : (
          <>
            <CompanyCardDictionary attribute="Industry" value={industry} />

            <CompanyCardDictionary
              attribute="Business"
              value={specialities?.join(", ")}
            />
            <CompanyCardDictionary
              attribute="Employee Count"
              value={
                staffCount > 10000
                  ? "10,000+"
                  : staffCount
                  ? numberWithCommas(staffCount)
                  : 0
              }
            />
            <CompanyCardDictionary attribute="Website" value={companyPageUrl} />
            <CompanyCardDictionary
              attribute="Headquarters"
              value={headquarter.join(", ")}
            />
            <CompanyCardDictionary attribute="Type" value={companyType} />
          </>
        )}
      </div>
      <div className="companyCardCloseBtn">
        <CloseButton
          strokeWidth="3"
          width="17px"
          height="17px"
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default CompanyCard;

{
  /* 
        <div className="companyCardContent">
          <h3>{companyName}</h3>
          <p>{companyHeadline}</p>
          {!isLoading ? (
            <span>{`${companyEmployees} Employees`}</span>
          ) : (
            <Loader
              type='TailSpin' 
              color="#297AF7" 
              width='24px'
              height='24px'
            />
          )}
        </div>
*/
}
