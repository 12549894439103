import React, { useEffect } from "react";
import Loading from "../Loading";
import { connect } from "react-redux";
import "./style.scss";
import { seIsPrimaryButtonLoading } from "../../actions/primaryButton";
const PrimaryButton = ({
  style = {},
  className = "",
  onClick = (e) => false,
  disabled = false,
  isLoading = false,
  children,
  dispatch,
}) => {
  const handleClick = () => {
    onClick();
    //  //dispatch global state
  };
  useEffect(() => {
    dispatch(seIsPrimaryButtonLoading(isLoading));
  }, [isLoading]);
  return (
    <button
      style={style}
      className={`primaryQluButton ${className}`}
      onClick={handleClick}
      disabled={disabled}
    >
      {isLoading ? <Loading width="16" height="16" invert={true} /> : children}
    </button>
  );
};

export default connect((state) => ({
  isPrimaryButtonLoading: state.isPrimaryButtonLoading,
}))(PrimaryButton);
