import {
  CompanyDetailsFAQ1,
  CompanyDetailsFAQ2,
  CompanyDetailsFAQ3,
  CompanyDetailsFAQ4,
  CompanyDetailsFAQ5,
  CompanyDetailsFAQ6,
  CompanyDetailsFAQ7,
  CompanyDetailsFAQ8,
  CompanyDetailsFAQ9,
  CompanyDetailsFAQ10,
  CompanyDetailsFAQ11,
} from "../Answers/CompanyDetails";

export const CompanyDetailsFAQS = [
  {
    heading: "Why do I need to add the company I am hiring for?",
    content: <CompanyDetailsFAQ1 />,
  },
  {
    heading: "Where do you get the information about companies?",
    content: <CompanyDetailsFAQ2 />,
  },
  {
    heading: "What if I don't add the company I’m hiring from?",
    content: <CompanyDetailsFAQ3 />,
  },
  {
    heading: "Can I add more than one company?",
    content: <CompanyDetailsFAQ4 />,
  },
  {
    heading: "What are companies I would hire from?",
    content: <CompanyDetailsFAQ5 />,
  },
  {
    heading:
      "What if I don't know the companies that are appearing on this screen?",
    content: <CompanyDetailsFAQ6 />,
  },
  {
    heading: "What happens when I select a company?",
    content: <CompanyDetailsFAQ7 />,
  },
  {
    heading: "How many companies can I select?",
    content: <CompanyDetailsFAQ8 />,
  },
  {
    heading:
      "What are all these other companies that show up when I select a company?",
    content: <CompanyDetailsFAQ9 />,
  },
  {
    heading:
      "Will the search be limited to just these companies or other companies as well?",
    content: <CompanyDetailsFAQ10 />,
  },
  {
    heading:
      "Instead of selecting all these companies can I simply add a list of companies?",
    content: <CompanyDetailsFAQ11 />,
  },
];
