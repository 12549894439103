import React from "react";
import { connect } from "react-redux";
import CaretLeft from "../../../uiLibrary/SVGs/CaretLeft";
import CloseIconWhiteSvg from "../../../uiLibrary/SVGs/CloseIconWhiteSvg";
import SupportSvg from "../../../uiLibrary/SVGs/SupportSvg";
import "./style.scss";

export default connect((state => ({
  generateJD: state.jobSetup?.jobSetup?.generateJD,
})))
  (function Header({
    isPrimaryScreen = true,
    setIsSupportOpen,
    setIsPrimaryScreen,
    setIsFullScreenOpen,
    generateJD
  }) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          paddingLeft: "15px",
          paddingTop: "10px"
        }}
      >
        {isPrimaryScreen ? (
          <>
            <span style={{ marginLeft: "5px", alignSelf: "flex-start", paddingTop: "10px" }}><SupportSvg height={30} width={30} /></span>
            <span style={{ paddingTop: '15px', paddingLeft: "10px", paddingBottom: "20px", width: "100%", color: "#e0e0e0" }}>
              {generateJD ? "Describe the person you are you looking for? Or write the skills and responsibilities." :
                "FAQs"}
            </span>
            <div className="windowControls">
              <button
                className="controlButton"
                onClick={() => setIsSupportOpen(false)}
              >
                <CloseIconWhiteSvg />
              </button>
            </div>
          </>
        ) : (
          <>
            <div
              className="controlButton"
              onClick={() => setIsPrimaryScreen(true)}
              style={{ marginLeft: 15 }}
            >
              <CaretLeft />
            </div>
            <p style={{ width: "95%", marginLeft: 10 }} className="headingText">
              Contact Support
            </p>
          </>
        )}
      </div>
    );
  }
  )
