import * as ActionTypes from "../../actions/prospectsView";

const initialState = {
  skills: [],
  specialties: [],
  skillsStrict: false,
  specialtiesStrict: false,
  school: [],
  education: [],
  ethnicity: [],
  educationStrict: false,
  currentTitle: [],
  currentTitleStrict: false,
  pastTitle: [],
  pastTitleStrict: false,
  currentCompany: [],
  pastCompany: [],
  currentIndustry: [],
  pastIndustry: [],
  location: [],
  pastLocation: [],
  languages: [],
  firstNames: [],
  lastNames: [],
  year_of_graduations: {
    active: false,
    value: {
      min: 2015,
      max: 2020,
    },
  },
  locationStrict: false,
  pastLocationStrict: false,
  skillsStatus: null,
  specialtiesStatus: null,
  locationStatus: null,
  pastLocationStatus: null,
  visa: [
    {
      name: "H1b",
      value: 0,
    },
    {
      name: "Green Card",
      value: 0,
    },
    {
      name: "Citizen",
      value: 0,
    },
    {
      name: "OPT",
      value: 0,
    },
  ],
  yearCurrentCompany: {
    active: false,
    value: {
      min: 0.5,
      max: 30,
    },
  },
  yearCurrentPosition: {
    active: false,
    value: {
      min: 1,
      max: 5,
    },
  },
  totalExperience: {
    active: false,
    value: {
      min: 1,
      max: 5,
    },
  },
  relevantExperience: {
    active: false,
    value: {
      min: 1,
      max: 5,
    },
  },
  companySize: [
    {
      value: 0,
      name: "1-10",
    },
    {
      value: 0,
      name: "11-50",
    },
    {
      value: 0,
      name: "51-200",
    },
    {
      value: 0,
      name: "201-500",
    },
    {
      value: 0,
      name: "501-1000",
    },
    {
      value: 0,
      name: "1001-5000",
    },
    {
      value: 0,
      name: "5001-10000",
    },
    {
      value: 0,
      name: "10001+",
    },
  ],
  isOpen: false,
  filterCount: 0,
  appliedFilterCount: 0,
  // companySize:{
  //   active: false,
  //   value: {
  //     min: 501,
  //     max: 1000
  //   }
  // }
};

const tallyFilters = (setFilterCount, value) => {
  setFilterCount((prev) => prev + 1);
  return value;
};

const advFiltersMeta = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_ADV_FILTERS:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case ActionTypes.SET_SKILLS_FILTERS:
      return {
        ...state,
        skills: action.payload,
      };
    case ActionTypes.SET_SPECIALTIES_FILTERS:
      return {
        ...state,
        specialties: action.payload,
      };
    case ActionTypes.TOGGLE_STRICT_SKILLS:
      return {
        ...state,
        skillsStrict: !state.skillsStrict,
      };
    case ActionTypes.TOGGLE_STRICT_SPECIALTIES:
      return {
        ...state,
        specialtiesStrict: !state.specialtiesStrict,
      };
    case ActionTypes.SET_STRICT_SKILLS:
      return {
        ...state,
        skillsStrict: action.payload,
      };
    case ActionTypes.SET_STRICT_SPECIALTIES:
      return {
        ...state,
        specialtiesStrict: action.payload,
      };
    case ActionTypes.SET_SCHOOL_FILTERS:
      return {
        ...state,
        school: action.payload,
      };
    case ActionTypes.SET_EDUCATION_FILTERS:
      return {
        ...state,
        education: action.payload,
      };
    case ActionTypes.SET_ETHNICITY_FILTER:
      return {
        ...state,
        ethnicity: action.payload,
      };
    case ActionTypes.TOGGLE_STRICT_EDUCATION:
      return {
        ...state,
        educationStrict: !state.educationStrict,
      };
    case ActionTypes.SET_CURRENT_TITLE_FILTERS:
      return {
        ...state,
        currentTitle: action.payload,
      };
    case ActionTypes.TOGGLE_STRICT_CURRENT_TITLE:
      return {
        ...state,
        currentTitleStrict: !state.currentTitleStrict,
      };
    case ActionTypes.SET_PAST_TITLE_FILTERS:
      return {
        ...state,
        pastTitle: action.payload,
      };
    case ActionTypes.TOGGLE_STRICT_lOCATION:
      return {
        ...state,
        locationStrict: !state.locationStrict,
      };
    case ActionTypes.TOGGLE_STRICT_PAST_lOCATION:
        return {
          ...state,
          pastLocationStatus: !state.pastLocationStatus,
        };  
    case ActionTypes.TOGGLE_STRICT_PAST_TITLE:
      return {
        ...state,
        pastTitleStrict: !state.pastTitleStrict,
      };
    case ActionTypes.SET_CURRENT_COMPANY_FILTERS:
      return {
        ...state,
        currentCompany: action.payload,
      };
    case ActionTypes.SET_PAST_COMPANY_FILTERS:
      return {
        ...state,
        pastCompany: action.payload,
      };
    case ActionTypes.SET_CURRENT_INDUSTRY_FILTERS:
      return {
        ...state,
        currentIndustry: action.payload,
      };
    case ActionTypes.SET_PAST_INDUSTRY_FILTERS:
      return {
        ...state,
        pastIndustry: action.payload,
      };
    case ActionTypes.SET_LOCATION_FILTERS:
      return {
        ...state,
        location: action.payload,
      };
    case ActionTypes.SET_PAST_LOCATION_FILTERS:
        return {
          ...state,
          pastLocation: action.payload,
        };
    case ActionTypes.SET_VISA_FILTERS:
      return {
        ...state,
        visa: action.payload,
      };
    case ActionTypes.SET_TOTAL_EXPERIENCE_FILTER:
      return {
        ...state,
        totalExperience: {
          ...state.totalExperience,
          value: {
            min: action.payload.min,
            max: action.payload.max,
          },
        },
      };
    case ActionTypes.SET_RELEVANT_EXPERIENCE_FILTER:
      return {
        ...state,
        relevantExperience: {
          ...state.relevantExperience,
          value: {
            min: action.payload.min,
            max: action.payload.max,
          },
        },
      };
    case ActionTypes.TOGGLE_TOTAL_EXPERIENCE_FILTER:
      return {
        ...state,
        totalExperience: {
          ...state.totalExperience,
          active: !state.totalExperience.active,
        },
      };
    case ActionTypes.TOGGLE_RELEVANT_EXPERIENCE_FILTER:
      return {
        ...state,
        relevantExperience: {
          ...state.relevantExperience,
          active: !state.relevantExperience.active,
        },
      };

    case ActionTypes.SET_COMPANY_SIZE_FILTER:
      return {
        ...state,
        companySize: action.payload,
      };

    case ActionTypes.TOGGLE_COMPANY_SIZE_FILTER:
      return {
        ...state,
        companySize: {
          ...state.companySize,
          active: !state.companySize.active,
        },
      };
    case ActionTypes.SET_SKILLS_STATUS:
      return {
        ...state,
        skillsStatus: action.payload,
      };
    case ActionTypes.SET_SPECIALTIES_STATUS:
      return {
        ...state,
        specialtiesStatus: action.payload,
      };
    case ActionTypes.SET_LOCATION_STATUS:
      return {
        ...state,
        locationStatus: action.payload,
      };
    case ActionTypes.SET_LOCATION_STATUS:
        return {
          ...state,
          pastLocationStatus: action.payload,
        };
    case ActionTypes.SET_SPOKEN_LANGUAGES_FILTER:
      return {
        ...state,
        languages: action.payload,
      };
    case ActionTypes.SET_FIRST_NAMES_FILTER:
      return {
        ...state,
        firstNames: action.payload,
      };
    case ActionTypes.SET_LAST_NAMES_FILTER:
      return {
        ...state,
        lastNames: action.payload,
      };
    case ActionTypes.SET_YEAR_OF_GRADUATION_TOGGLE:
      return {
        ...state,
        year_of_graduations: {
          ...state.year_of_graduations,
          active: !state.year_of_graduations.active,
        },
      };
    case ActionTypes.SET_YEAR_OF_GRADUATION:
      return {
        ...state,
        year_of_graduations: {
          ...state.year_of_graduations,
          value: {
            min: action.payload.min,
            max: action.payload.max,
          },
        },
      };

    case ActionTypes.SET_YEAR_CURRENT_COMPANY_TOGGLE:
      return {
        ...state,
        yearCurrentCompany: {
          ...state.yearCurrentCompany,
          active: !state.yearCurrentCompany.active,
        },
      };
    case ActionTypes.SET_YEAR_CURRENT_COMPANY_FILTER:
      return {
        ...state,
        yearCurrentCompany: {
          ...state.yearCurrentCompany,
          value: {
            min: action.payload.min,
            max: action.payload.max,
          },
        },
      };
    case ActionTypes.SET_YEAR_CURRENT_POSITION_TOGGLE:
      return {
        ...state,
        yearCurrentPosition: {
          ...state.yearCurrentPosition,
          active: !state.yearCurrentPosition.active,
        },
      };
    case ActionTypes.SET_YEAR_CURRENT_POSITION_FILTER:
      return {
        ...state,
        yearCurrentPosition: {
          ...state.yearCurrentPosition,
          value: {
            min: action.payload.min,
            max: action.payload.max,
          },
        },
      };

    case ActionTypes.APPLY_ADV_FILTERS_TEMPLATE: {
      const filters = action.payload.template;
      const setCount = action.payload.setFilterCount;
      return {
        ...state,
        skills: [
          ...(filters?.eS
            ? filters.eS
              .split("||")
              .filter(
                (item) => !state.skills.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 2),
              }))
            : []),
          ...(filters?.hS
            ? filters.hS
              .split("||")
              .filter(
                (item) => !state.skills.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 1),
              }))
            : []),
          ...state.skills.map((item) => ({
            ...item,
            value:
              filters?.eS && filters.eS.split("||").includes(item.name)
                ? tallyFilters(setCount, 2)
                : filters?.hS && filters.hS.split("||").includes(item.name)
                  ? tallyFilters(setCount, 1)
                  : 0,
          })),
        ],
        skillsStrict: filters?.sS ? true : false,
        school: [
          ...(filters?.eSS
            ? filters.eSS
              .split("||")
              .filter(
                (item) => !state.school.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 2),
              }))
            : []),
          ...(filters?.hSS
            ? filters.hSS
              .split("||")
              .filter(
                (item) => !state.school.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 1),
              }))
            : []),
          ...state.school.map((item) => ({
            ...item,
            value:
              filters?.eSS && filters.eSS.split("||").includes(item.name)
                ? tallyFilters(setCount, 2)
                : filters?.hSS && filters.hSS.split("||").includes(item.name)
                  ? tallyFilters(setCount, 1)
                  : 0,
          })),
        ],
        education: [
          ...(filters?.eE
            ? filters.eE
              .split("||")
              .filter(
                (item) => !state.education.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 2),
              }))
            : []),
          ...(filters?.hE
            ? filters.hE
              .split("||")
              .filter(
                (item) => !state.education.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 1),
              }))
            : []),
          ...state.education.map((item) => ({
            ...item,
            value:
              filters?.eE && filters.eE.split("||").includes(item.name)
                ? tallyFilters(setCount, 2)
                : filters?.hE && filters.hE.split("||").includes(item.name)
                  ? tallyFilters(setCount, 1)
                  : 0,
          })),
        ],
        educationStrict: filters?.eDS ? true : false,
        currentTitle: [
          ...(filters?.eCT
            ? filters.eCT
              .split("||")
              .filter(
                (item) => !state.currentTitle.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 2),
              }))
            : []),
          ...(filters?.hCT
            ? filters.hCT
              .split("||")
              .filter(
                (item) => !state.currentTitle.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 1),
              }))
            : []),
          ...state.currentTitle.map((item) => ({
            ...item,
            value:
              filters?.eCT && filters.eCT.split("||").includes(item.name)
                ? tallyFilters(setCount, 2)
                : filters?.hCT && filters.hCT.split("||").includes(item.name)
                  ? tallyFilters(setCount, 1)
                  : 0,
          })),
        ],
        currentTitleStrict: filters?.cTS ? true : false,
        pastTitle: [
          ...(filters?.ePT
            ? filters.ePT
              .split("||")
              .filter(
                (item) => !state.pastTitle.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 2),
              }))
            : []),
          ...(filters?.hPT
            ? filters.hPT
              .split("||")
              .filter(
                (item) => !state.pastTitle.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 1),
              }))
            : []),
          ...state.pastTitle.map((item) => ({
            ...item,
            value:
              filters?.ePT && filters.ePT.split("||").includes(item.name)
                ? tallyFilters(setCount, 2)
                : filters?.hPT && filters.hPT.split("||").includes(item.name)
                  ? tallyFilters(setCount, 1)
                  : 0,
          })),
        ],
        pastTitleStrict: filters?.pTS ? true : false,
        currentCompany: [
          ...(filters?.eCC
            ? filters.eCC
              .split("||")
              .filter(
                (item) =>
                  !state.currentCompany.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 2),
              }))
            : []),
          ...(filters?.hCC
            ? filters.hCC
              .split("||")
              .filter(
                (item) =>
                  !state.currentCompany.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 1),
              }))
            : []),
          ...state.currentCompany.map((item) => ({
            ...item,
            value:
              filters?.eCC && filters.eCC.split("||").includes(item.name)
                ? tallyFilters(setCount, 2)
                : filters?.hCC && filters.hCC.split("||").includes(item.name)
                  ? tallyFilters(setCount, 1)
                  : 0,
          })),
        ],
        pastCompany: [
          ...(filters?.ePC
            ? filters.ePC
              .split("||")
              .filter(
                (item) => !state.pastCompany.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 2),
              }))
            : []),
          ...(filters?.hPC
            ? filters.hPC
              .split("||")
              .filter(
                (item) => !state.pastCompany.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 1),
              }))
            : []),
          ...state.pastCompany.map((item) => ({
            ...item,
            value:
              filters?.ePC && filters.ePC.split("||").includes(item.name)
                ? tallyFilters(setCount, 2)
                : filters?.hPC && filters.hPC.split("||").includes(item.name)
                  ? tallyFilters(setCount, 1)
                  : 0,
          })),
        ],
        currentIndustry: [
          ...(filters?.eCI
            ? filters.eCI
              .split("||")
              .filter(
                (item) =>
                  !state.currentIndustry.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 2),
              }))
            : []),
          ...(filters?.hCI
            ? filters.hCI
              .split("||")
              .filter(
                (item) =>
                  !state.currentIndustry.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 1),
              }))
            : []),
          ...state.currentIndustry.map((item) => ({
            ...item,
            value:
              filters?.eCI && filters.eCI.split("||").includes(item.name)
                ? tallyFilters(setCount, 2)
                : filters?.hCI && filters.hCI.split("||").includes(item.name)
                  ? tallyFilters(setCount, 1)
                  : 0,
          })),
        ],
        pastIndustry: [
          ...(filters?.ePI
            ? filters.ePI
              .split("||")
              .filter(
                (item) => !state.pastIndustry.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 2),
              }))
            : []),
          ...(filters?.hPI
            ? filters.hPI
              .split("||")
              .filter(
                (item) => !state.pastIndustry.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 1),
              }))
            : []),
          ...state.pastIndustry.map((item) => ({
            ...item,
            value:
              filters?.ePI && filters.ePI.split("||").includes(item.name)
                ? tallyFilters(setCount, 2)
                : filters?.hPI && filters.hPI.split("||").includes(item.name)
                  ? tallyFilters(setCount, 1)
                  : 0,
          })),
        ],
        location: [
          ...(filters?.eL
            ? filters.eL
              .split("||")
              .filter(
                (item) => !state.location.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 2),
              }))
            : []),
          ...(filters?.hL
            ? filters.hL
              .split("||")
              .filter(
                (item) => !state.location.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 1),
              }))
            : []),
          ...state.location.map((item) => ({
            ...item,
            value:
              filters?.eL && filters.eL.split("||").includes(item.name)
                ? tallyFilters(setCount, 2)
                : filters?.hL && filters.hL.split("||").includes(item.name)
                  ? tallyFilters(setCount, 1)
                  : 0,
          })),
        ],
        pastLocation: [
          ...(filters?.ePL
            ? filters.ePL
              .split("||")
              .filter(
                (item) => !state.pastLocation.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 2),
              }))
            : []),
          ...(filters?.hPL
            ? filters.hPL
              .split("||")
              .filter(
                (item) => !state.pastLocation.some((itm) => itm.name === item)
              )
              .map((item) => ({
                name: item,
                value: tallyFilters(setCount, 1),
              }))
            : []),
          ...state.pastLocation.map((item) => ({
            ...item,
            value:
              filters?.ePL && filters.ePL.split("||").includes(item.name)
                ? tallyFilters(setCount, 2)
                : filters?.hPL && filters.hPL.split("||").includes(item.name)
                  ? tallyFilters(setCount, 1)
                  : 0,
          })),
        ],
        locationStrict: filters?.lS ? true : false,
        pastLocationStrict: filters?.PlS ? true : false,
        visa: state.visa.map((item) => ({
          ...item,
          value:
            filters?.eV && filters.eV.split("||").includes(item.name)
              ? tallyFilters(setCount, 2)
              : filters?.hV && filters.hV.split("||").includes(item.name)
                ? tallyFilters(setCount, 1)
                : 0,
        })),
        totalExperience: {
          value: {
            min: filters?.tE
              ? parseInt(filters?.tE.split("||")[0])
              : state.totalExperience.value.min,
            max: filters?.tE
              ? parseInt(filters?.tE.split("||")[1])
              : state.totalExperience.value.max,
          },
          active: filters?.tE ? tallyFilters(setCount, true) : false,
        },
        relevantExperience: {
          value: {
            min: filters?.rE
              ? parseInt(filters?.rE.split("||")[0])
              : state.relevantExperience.value.min,
            max: filters?.rE
              ? parseInt(filters?.rE.split("||")[1])
              : state.relevantExperience.value.max,
          },
          active: filters?.rE ? tallyFilters(setCount, true) : false,
        },
        companySize: state.companySize.map((item) => ({
          ...item,
          value:
            filters?.eCSR && filters?.eCSR.split("||").includes(item.name)
              ? tallyFilters(setCount, 2)
              : filters?.hCSR && filters?.hCSR.split("||").includes(item.name)
                ? tallyFilters(setCount, 1)
                : 0,
        })),
        // firstNames: [
        //   ...(filters?.eFN
        //     ? filters.eFN
        //         .split("||")
        //         .filter(
        //           (item) =>
        //             !state.firstNames.some((itm) => itm.name === item)
        //         )
        //         .map((item) => ({
        //           name: item,
        //           value: tallyFilters(setCount, 2),
        //         }))
        //     : []),
        //   ...(filters?.hFN
        //     ? filters.hFN
        //         .split("||")
        //         .filter(
        //           (item) =>
        //             !state.firstNames.some((itm) => itm.name === item)
        //         )
        //         .map((item) => ({
        //           name: item,
        //           value: tallyFilters(setCount, 1),
        //         }))
        //     : []),
        //   ...state.firstNames.map((item) => ({
        //     ...item,
        //     value:
        //       filters?.eFN && filters.eFN.split("||").includes(item.name)
        //         ? tallyFilters(setCount, 2)
        //         : filters?.hFN && filters.hFN.split("||").includes(item.name)
        //         ? tallyFilters(setCount, 1)
        //         : 0,
        //   })),
        // ],
        // lastNames: [
        //   ...(filters?.eLN
        //     ? filters.eLN
        //         .split("||")
        //         .filter(
        //           (item) =>
        //             !state.lastNames.some((itm) => itm.name === item)
        //         )
        //         .map((item) => ({
        //           name: item,
        //           value: tallyFilters(setCount, 2),
        //         }))
        //     : []),
        //   ...(filters?.hLN
        //     ? filters.hLN
        //         .split("||")
        //         .filter(
        //           (item) =>
        //             !state.lastNames.some((itm) => itm.name === item)
        //         )
        //         .map((item) => ({
        //           name: item,
        //           value: tallyFilters(setCount, 1),
        //         }))
        //     : []),
        //   ...state.lastNames.map((item) => ({
        //     ...item,
        //     value:
        //       filters?.eLN && filters.eLN.split("||").includes(item.name)
        //         ? tallyFilters(setCount, 2)
        //         : filters?.hLN && filters.hLN.split("||").includes(item.name)
        //         ? tallyFilters(setCount, 1)
        //         : 0,
        //   })),
        // ],
        // languages: [
        //   ...(filters?.eL
        //     ? filters.eL
        //         .split("||")
      };
    }

    case ActionTypes.CLEAR_ADVANCED_FILTERS: {
      return {
        ...state,
        ethnicity: state.ethnicity.map((item)=> ({...item, value:0})),
        skills: state.skills.map((item) => ({ ...item, value: 0 })),
        skillsStrict: false,
        skillsStatus: null,
        specialties: state.specialties.map((item) => ({ ...item, value: 0 })),
        specialtiesStrict: false,
        specialtiesStatus: null,
        school: state.school.map((item) => ({ ...item, value: 0 })),
        education: state.education.map((item) => ({ ...item, value: 0 })),
        educationStrict: false,
        currentTitle: state.currentTitle.map((item) => ({ ...item, value: 0 })),
        currentTitleStrict: false,
        pastTitle: state.pastTitle.map((item) => ({ ...item, value: 0 })),
        pastTitleStrict: false,
        currentCompany: state.currentCompany.map((item) => ({
          ...item,
          value: 0,
        })),
        pastCompany: state.pastCompany.map((item) => ({ ...item, value: 0 })),
        currentIndustry: state.currentIndustry.map((item) => ({
          ...item,
          value: 0,
        })),
        pastIndustry: state.pastIndustry.map((item) => ({ ...item, value: 0 })),
        location: state.location.map((item) => ({ ...item, value: 0 })),
        pastLocation: state.pastLocation.map((item) => ({ ...item, value: 0 })),
        locationStrict: false,
        locationStatus: null,
        pastLocationStrict: false,
        pastLocationStatus: null,
        languages: state.languages.map((item) => ({ ...item, value: 0 })),
        firstNames: state.firstNames.map((item) => ({ ...item, value: 0 })),
        lastNames: state.lastNames.map((item) => ({ ...item, value: 0 })),

        visa: state.visa.map((item) => ({ ...item, value: 0 })),
        year_of_graduations: {
          ...state.year_of_graduations,
          active: false,
        },
        totalExperience: {
          ...state.totalExperience,
          active: false,
        },
        relevantExperience: {
          ...state.relevantExperience,
          active: false,
        },
        yearCurrentCompany: {
          ...state.yearCurrentCompany,
          active: false,
        },
        yearCurrentPosition: {
          ...state.yearCurrentPosition,
          active: false,
        },
        companySize: state.companySize.map((item) => ({ ...item, value: 0 })),
      };
    }

    default:
      return state;
  }
};

export default advFiltersMeta;
