import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { addCampaignRound, changeCampaignRound, initOutbox, publishOutboxCampaign, selectOutboxDraft, setOutboxView } from '../../actions/outbox'
import { updateSelectedProspects } from '../../actions/prospectsView'
import { PER_PAGE_COUNT } from '../../constants/api'
import ActionBar from '../../uiLibrary/ActionBar'
import CaretStepper from '../../uiLibrary/CaretStepper'
import ConnectSvg from '../../uiLibrary/SVGs/ConnectSvg'
import InmailSVG from '../../uiLibrary/SVGs/InmailSvg'
import MailSVG from '../../uiLibrary/SVGs/MailSvg'
import { manualApiCall } from '../../utils'
import { Mixpanel } from '../../utils/Mixpanel'
import HybridConsentScreen from './HybridConsentScreen'
import OutboxHeader from './OutboxHeader'
import OutboxMessagesController from './OutboxMessagesController'
import OutboxReviewController from './OutboxReviewController'
import OutboxRoundsController from './OutboxRoundsController'
import OutboxSummary from './OutboxSummary'
import RecipientsTabs from './RecipientsTabs'
import './style.scss'
const Outbox = ({
    dispatch,
    rounds,
    draft,
    drafts,
    userName,
    companyHiringFor,
    inMailCredits,
    recruiterInMailCredits,
    recipientTab,
    selectedProspects,
    view,
    jobData,
    history: {
        push
    }
}) => {
    const postion = jobData?.jobArray?.job_position?.[0]
    const rank = postion?.rank || 8
    let { jId, isQuick } = useParams()
    if (isQuick === 'false') { isQuick = false }

    // console.log("=============================Outbox=================================", {jId, isQuick}, useParams() )

    const [consentRequested, setConsentRequested] = useState([])
    const anyError = rounds.every(({ messages }) => messages.filter(msg => !msg.isRemoved).every(({
        areRequiredVarsPresent,
        emailPresent,
        hasConsent = true,
        isMessageUnderLimit }) => (areRequiredVarsPresent &&
            emailPresent &&
            isMessageUnderLimit && hasConsent)))

    console.log('anyError', anyError)
    const primaryDisabled = (view == 'Edit Messages' && Object.entries(selectedProspects).every(prospect => prospect[1].isRemoved)) ? true : view !== 'Review' ? false : !anyError

    const isLoading = false

    const [isPaused, setIsPaused] = useState(false)
    const [finish, setFinish] = useState(false)
    const onChangePause = () => {
        setIsPaused(prev => !prev)
    }

    const steps = [
        'Edit Rounds',
        'Edit Messages',
        'Review'
    ]


    const channelOptions = {
        linkedin: {
            name: 'LinkedIn Message',
            icon: <ConnectSvg />
        },
        email: {
            name: 'Email',
            icon: <MailSVG />
        },
        // outlook: {
        //     name: 'Outlook',
        //     icon: <MailSVG />
        // },
        inmail: {
            name: 'InMail',
            icon: <InmailSVG />
        },
        recruiter: {
            name: 'Recruiter InMail',
            icon: <InmailSVG />
        }
    }

    const initialMessages = {
        jobprofile_id: null,
        message: '',
        template: null,
        subject: null,
        send_after_days: null,
        send_after_hours: null,
        channel: null,
        email_address: null,
        is_personalised: false,
        sender: null
    }


    const messageTemplates = {
        linkedin: [
            {
                message: drafts.length > 0 ? drafts?.[0]?.rounds.sort((a, b) => a.rounds - b.rounds)?.[0]?.template : `{{.First_Name}}, extremely impressed by your work experience. I am reaching out regarding {{.Job_Title}} position for {{.Hiring_Company}}.\nGreat benefits and an amazing opportunity.\nAvailable for a quick chat this week?\n\nLook forward to hearing from you\n{{.My_Name}}`
            },
            {
                message: `Hi {{.First_Name}},\nQuick followup regarding the {{.Job_Title}} position for {{.Hiring_Company}} that I messaged you about earlier.\nWould this week work for a quick chat?\n\nLooking forward.\nThank you,\n{{.My_Name}}`
            },
            {
                message: `Hi {{.First_Name}},\nQuick followup regarding the {{.Job_Title}} position for {{.Hiring_Company}} that I messaged you about earlier.\nWould this week work for a quick chat?\n\nLooking forward.\nThank you,\n{{.My_Name}}`
            },
        ],
        inmail: [
            {
                subject: `You are a great fit!`,
                message: drafts.length > 0 ? drafts?.[0]?.rounds.sort((a, b) => a.rounds - b.rounds)?.[0]?.template : `Hi {{.First_Name}},\n\nI found your profile on Linkedin and wanted to reach out regarding the {{.Job_Title}} position for {{.Hiring_Company}}. Your experience at {{.Current_Company}} is the type of skillset we are looking for in the {{.Job_Title}}  role.\n\n{{.Hiring_Company}} offers competitive benefits for full-time employees. Benefits include Medical, Dental, Vision, 401K, Wellness Programs, Commuter Programs and Paid Time Off. Employment Benefits vary by location.\n\nWhen would be a good time to discuss this further?\n\nLooking forward to hearing back from you.\n\nThank you,\n{{.My_First_Name}}`
            },
            {
                subject: `Following Up!`,
                message: `{{.First_Name}} ,\n\nJust a quick follow-up to see if you might be interested in the {{.Job_Title}} position for {{.Hiring_Company}} that I messaged you about earlier. Let me know when we can set up a quick chat to discuss more details.\n\nWhat do you say? Let's connect this week!\n\nHave a great day!\n\nThanks,\n{{.My_First_Name}}`
            },
            {
                subject: `Catching up again!`,
                message: `I just wanted to reach out one last time, in the event you might have not seen or opened my last messages. I am recruiting for {{.Job_Title}} for {{.Hiring_Company}} and wanted to see if you might be interested.\n\nI completely understand if now is not the right time for a change, perhaps we can keep in touch for future opportunities!\n\nAlso, would you happen to know anyone who might have a similar background? If you happen to know anyone who has a similar background to you, that is looking to make a change, please feel free to forward over my contact information. Great people always know other great people!\n\nHave a great week!\n\nBest Regards,\n{{.My_First_Name}}`
            }
        ],
        recruiter: [
            {
                subject: `You are a great fit!`,
                message: drafts.length > 0 ? drafts?.[0]?.rounds.sort((a, b) => a.rounds - b.rounds)?.[0]?.template : `Hi {{.First_Name}},\n\nI found your profile on Linkedin and wanted to reach out regarding the {{.Job_Title}} position for {{.Hiring_Company}}. Your experience at {{.Current_Company}} is the type of skillset we are looking for in the {{.Job_Title}}  role.\n\n{{.Hiring_Company}} offers competitive benefits for full-time employees. Benefits include Medical, Dental, Vision, 401K, Wellness Programs, Commuter Programs and Paid Time Off. Employment Benefits vary by location.\n\nWhen would be a good time to discuss this further?\n\nLooking forward to hearing back from you.\n\nThank you,\n{{.My_First_Name}}`
            },
            {
                subject: `Following Up!`,
                message: `{{.First_Name}} ,\n\nJust a quick follow-up to see if you might be interested in the {{.Job_Title}} position for {{.Hiring_Company}} that I messaged you about earlier. Let me know when we can set up a quick chat to discuss more details.\n\nWhat do you say? Let's connect this week!\n\nHave a great day!\n\nThanks,\n{{.My_First_Name}}`
            },
            {
                subject: `Catching up again!`,
                message: `I just wanted to reach out one last time, in the event you might have not seen or opened my last messages. I am recruiting for {{.Job_Title}} for {{.Hiring_Company}} and wanted to see if you might be interested.\n\nI completely understand if now is not the right time for a change, perhaps we can keep in touch for future opportunities!\n\nAlso, would you happen to know anyone who might have a similar background? If you happen to know anyone who has a similar background to you, that is looking to make a change, please feel free to forward over my contact information. Great people always know other great people!\n\nHave a great week!\n\nBest Regards,\n{{.My_First_Name}}`
            }
        ],
        email: [
            {
                subject: `You are a great fit!`,
                message: drafts.length > 0 ? drafts?.[0]?.rounds.sort((a, b) => a.rounds - b.rounds)?.[0]?.template : `Hi {{.First_Name}},\n\nI found your profile on Linkedin and wanted to reach out regarding the {{.Job_Title}} position for {{.Hiring_Company}}. Your experience at {{.Current_Company}} is the type of skillset we are looking for in the {{.Job_Title}}  role.\n\n{{.Hiring_Company}} offers competitive benefits for full-time employees. Benefits include Medical, Dental, Vision, 401K, Wellness Programs, Commuter Programs and Paid Time Off. Employment Benefits vary by location.\n\nWhen would be a good time to discuss this further?\n\nLooking forward to hearing back from you.\n\nThank you,\n{{.My_First_Name}}`
            },
            {
                subject: `Following Up!`,
                message: `{{.First_Name}} ,\n\nJust a quick follow-up to see if you might be interested in the {{.Job_Title}} position for {{.Hiring_Company}} that I messaged you about earlier. Let me know when we can set up a quick chat to discuss more details.\n\nWhat do you say? Let's connect this week!\n\nHave a great day!\n\nThanks,\n{{.My_First_Name}}`
            },
            {
                subject: `Catching up again!`,
                message: `I just wanted to reach out one last time, in the event you might have not seen or opened my last messages. I am recruiting for {{.Job_Title}} for {{.Hiring_Company}} and wanted to see if you might be interested.\n\nI completely understand if now is not the right time for a change, perhaps we can keep in touch for future opportunities!\n\nAlso, would you happen to know anyone who might have a similar background? If you happen to know anyone who has a similar background to you, that is looking to make a change, please feel free to forward over my contact information. Great people always know other great people!\n\nHave a great week!\n\nBest Regards,\n{{.My_First_Name}}`
            }
        ],
        // outlook: [
        //     {
        //         subject: `You are a great fit!`,
        //         message: drafts.length > 0 ? drafts?.[0]?.rounds.sort((a, b) => a.rounds - b.rounds)?.[0]?.template : `Hi {{.First_Name}},\n\nI found your profile on Linkedin and wanted to reach out regarding the {{.Job_Title}} position for {{.Hiring_Company}}. Your experience at {{.Current_Company}} is the type of skillset we are looking for in the {{.Job_Title}}  role.\n\n{{.Hiring_Company}} offers competitive benefits for full-time employees. Benefits include Medical, Dental, Vision, 401K, Wellness Programs, Commuter Programs and Paid Time Off. Employment Benefits vary by location.\n\nWhen would be a good time to discuss this further?\n\nLooking forward to hearing back from you.\n\nThank you,\n{{.My_First_Name}}`
        //     },
        //     {
        //         subject: `Following Up!`,
        //         message: `{{.First_Name}} ,\n\nJust a quick follow-up to see if you might be interested in the {{.Job_Title}} position for {{.Hiring_Company}} that I messaged you about earlier. Let me know when we can set up a quick chat to discuss more details.\n\nWhat do you say? Let's connect this week!\n\nHave a great day!\n\nThanks,\n{{.My_First_Name}}`
        //     },
        //     {
        //         subject: `Catching up again!`,
        //         message: `I just wanted to reach out one last time, in the event you might have not seen or opened my last messages. I am recruiting for {{.Job_Title}} for {{.Hiring_Company}} and wanted to see if you might be interested.\n\nI completely understand if now is not the right time for a change, perhaps we can keep in touch for future opportunities!\n\nAlso, would you happen to know anyone who might have a similar background? If you happen to know anyone who has a similar background to you, that is looking to make a change, please feel free to forward over my contact information. Great people always know other great people!\n\nHave a great week!\n\nBest Regards,\n{{.My_First_Name}}`
        //     }
        // ]
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (Object.keys(selectedProspects).length === 0) {
            const fS = 'title_score'
            const fSO = 'desc'
            const secS = rank > 8 ? 'industry_score' : 'skill_score'
            const secSO = 'desc'
            push(`/job/${jId}?f=a&fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&fS=${fS}&fSO=${fSO}&secS=${secS}&secSO=${secSO}`)
        }
        else if (draft && draft?.rounds.sort((a, b) => a.rounds - b.rounds)?.length > 0) {
            draft.rounds.map(({
                channel,
                send_after_days,
                send_after_hours,
                subject,
                template,
            }, index) => {
                if (index === 0) {
                    dispatch(changeCampaignRound({
                        id: rounds[0].id,
                        value: {
                            channel,
                            send_after_days,
                            send_after_hours,
                            subject,
                            template,
                            messages: Object.keys(selectedProspects).map(key => ({
                                ...initialMessages,
                                jobprofile_id: selectedProspects[key].jobProfileId
                            }))
                        }
                    }))
                }
                else {
                    dispatch(addCampaignRound({
                        channel,
                        send_after_days,
                        send_after_hours,
                        subject,
                        template,
                    }))
                }
            })
            dispatch(selectOutboxDraft(false))
        }
        else {
            rounds.map(({ id, channel }, index) => {
                dispatch(changeCampaignRound({
                    id,
                    value: {
                        template: messageTemplates[channel][index].message,
                        messages: Object.keys(selectedProspects).map(key => ({
                            ...initialMessages,
                            jobprofile_id: selectedProspects[key].jobProfileId
                        }))
                    }
                }))
            })
        }
        Mixpanel.track("Outbox")
        return () => {
            dispatch(initOutbox())
        }

    }, [])


    useEffect(() => {
        if (consentRequested.length > 0) {
            rounds.map(round => {
                const messages = round.messages.map(m => {
                    const email_address = getEmailAddress(m.jobprofile_id, m.emailType)
                    if (email_address !== "Email Address Missing" && consentRequested.includes(email_address)) {
                        return {
                            ...m,
                            hasConsent: true
                        }
                    }
                    return m
                })
                handleChange(messages, round.id, 'messages')
            })
        }
    }, [consentRequested])

    const getEmailAddress = (jobprofile_id, emailType) => {
        const recipient = selectedProspects[Object.keys(selectedProspects).filter(key => selectedProspects[key].jobProfileId === jobprofile_id)?.[0]]
        return emailType === 'Personal Email' ?
            recipient?.primaryEmail || recipient?.personal_email :
            emailType === 'Work Email' ?
                recipient?.work_email :
                'Email Address Missing'
    }

    // console.log("=========================companyHiringFor================================", companyHiringFor)

    const togglePersonalise = (toggle, jobProfileId) => {
        rounds.map(({
            id,
            messages,
            send_after_days,
            send_after_hours,
            channel,
            template,
            subject,
            sender
        }) => {
            // console.log("===========togglePersonalise=============", {toggle, jobProfileId})
            const profileId = Object.keys(selectedProspects).filter(key => selectedProspects[key]?.jobProfileId === jobProfileId)?.[0]
            const personalEmail = selectedProspects[profileId]?.primaryEmail || selectedProspects[profileId]?.personal_email
            const work_email = selectedProspects[profileId]?.work_email
            dispatch(changeCampaignRound({
                id,
                value: {
                    messages: messages.map(message => {
                        if (message.jobprofile_id === jobProfileId) {
                            return {
                                ...message,
                                is_personalised: toggle,
                                send_after_days: toggle ? send_after_days : null,
                                send_after_hours: toggle ? send_after_hours : null,
                                channel: toggle ? channel : null,
                                sender: toggle ? sender : null,
                                template: toggle ? template : null,
                                subject: toggle ? subject : null,
                                emailType: toggle ?
                                    personalEmail ?
                                        'Personal Email' :
                                        work_email ?
                                            'Work Email' :
                                            null : null
                            }
                        }
                        return message
                    })
                }
            }))
        })
    }

    const onTabClick = (
        tab, messages
    ) => {
        if (recipientTab === 'all' && tab !== 'all') {
            Mixpanel.track("personalize")
            dispatch(setOutboxView({ recipientTab: Object.keys(selectedProspects)?.[0] }))
            const jobProfileId = selectedProspects[Object.keys(selectedProspects)?.[0]]?.jobProfileId
            if (!messages.filter(({ jobprofile_id }) => jobprofile_id === jobProfileId)?.[0]?.is_personalised) {
                togglePersonalise(true, jobProfileId)
            }
        }
        else if (recipientTab !== 'all' && tab === 'all') {
            dispatch(setOutboxView({ recipientTab: 'all' }))
        }
    }

    const isInViewport = (elementRef, offset = 0) => {
        if (!elementRef) return false;
        const top = elementRef.getBoundingClientRect().top;
        const bottom = elementRef.getBoundingClientRect().bottom;
        return ((top + offset) >= 0 && (top - offset) <= window.innerHeight) || ((bottom + offset) >= 0 && (bottom - offset) >= window.innerHeight);
    }

    const variableTranslator = (message, recepient) => {
        const firstName = recepient.firstName
        const lastName = recepient.lastName
        const fullName = recepient.fullName
        const schoolName = Array.isArray(recepient.education) && recepient.education.length > 0 ?
            recepient.education[0].schoolName : undefined
        const currentCompany = recepient.currentCompanyName
        const currentTitle = recepient.headline
        const jobTitle = jobData.jobTitle

        return message.replace(/{{.First_Name}}/g, firstName || '{{.First_Name}}')
            .replace(/{{\.Last_Name}}/g, lastName || '{{.Last_Name}}')
            .replace(/{{\.Full_Name}}/g, fullName || '{{.Full_Name}}')
            .replace(/{{\.School_Name}}/g, schoolName || '{{.School_Name}}')
            .replace(/{{\.Job_Title}}/g, jobTitle || '{{.Job_Title}}')
            .replace(/{{\.My_Name}}/g, userName || '{{.My_Name}}')
            .replace(/{{\.My_First_Name}}/g, userName.split(' ')[0] || '{{.My_First_Name}}')
            .replace(/{{\.My_Full_Name}}/g, userName || '{{.My_Full_Name}}')
            .replace(/{{\.User_Name}}/g, userName || '{{.User_Name}}')
            .replace(/{{\.Current_Company}}/g, currentCompany || '{{.Current_Company}}')
            .replace(/{{\.Current_Title}}/g, currentTitle || '{{.Current_Title}}')
            .replace(/{{\.Hiring_Company}}/g, companyHiringFor.name || '{{.Hiring_Company}}')
    }

    const emailCheck = (jobprofile_id) => {
        const {
            primaryEmail,
            personal_email,
            work_email
        } = selectedProspects[Object.keys(selectedProspects).filter(key => selectedProspects[key].jobProfileId === jobprofile_id)?.[0]]
        const personalEmail = primaryEmail || personal_email

        return (personalEmail || work_email) ? true : false
    }

    const consentCheck = async (jobprofile_id, email) => {
        const profileId = Object.keys(selectedProspects).filter(key => selectedProspects[key].jobProfileId === jobprofile_id)?.[0]
        const {
            country,
            fullName
        } = selectedProspects[profileId]
        // console.log("=================consentCheck=====================", {country, fullName, consentRequested, email})
        const { data } = email && email !== "Email Address Missing" && country && !consentRequested.includes(email) ? await manualApiCall('/ds/consentRequest/status', {
            method: 'POST',
            body: JSON.stringify({
                subjects: [{
                    email,
                    country
                }]
            })
        }) : { data: [{ consent_granted: true }] }

        return data?.[0]?.consent_granted

    }

    const errorCheck = (template, recepient, charLimit = false, email) => {

        const varsUsedInTemplate = template.match(/{{\.(\w+)}}/g) || []

        const varPropMap = {
            '{{.First_Name}}': 'First Name',
            '{{.Last_Name}}': 'Last Name',
            '{{.Full_Name}}': 'Full Name',
            '{{.Current_Title}}': 'Headline',
            '{{.Job_Title}}': 'Job Title',
            '{{.My_Name}}': 'Name',
            '{{.My_First_Name}}': 'Name',
            '{{.My_Full_Name}}': 'Name',
            '{{.User_Name}}': 'Name',
            '{{.School_Name}}': 'School Name',
            '{{.Current_Company}}': 'Current Company',
            '{{.Hiring_Company}}': 'Hiring Company'
        }

        const variables = {
            'First Name': recepient.firstName,
            'Last Name': recepient.lastName,
            'Full Name': recepient.fullName,
            'Job Title': jobData.jobTitle,
            'Headline': recepient.headline,
            'Name': userName,
            'School Name': recepient.education && recepient.education?.[0] ? recepient.education?.[0].schoolName : null,
            'Current Company': recepient.currentCompanyName ? recepient.currentCompanyName : null,
            'Hiring Company': companyHiringFor.name ? companyHiringFor.name : null
        }

        const missingTags = []

        const areRequiredVarsPresent = varsUsedInTemplate.map(key => {
            if (Object.keys(varPropMap).some(vari => vari === key) &&
                !variables[varPropMap[key]] &&
                !missingTags.includes(varPropMap[key])) {
                missingTags.push(varPropMap[key])
            }

            return variables[varPropMap[key]]
        }).every(v => v)

        const message = variableTranslator(template, recepient)

        const isMessageUnderLimit = charLimit ?
            message.length <= 280 :
            true

        return {
            areRequiredVarsPresent,
            message,
            missingTags,
            isMessageUnderLimit
        }
    }

    const updateSelected = (id, property, value) => {
        const prospectsToSelect = Object.keys(selectedProspects).reduce((total, key) => {
            if (key == id) {
                return {
                    ...total,
                    [key]: {
                        ...selectedProspects[key],
                        [property]: value
                    }
                }
            }
            return {
                ...total,
                [key]: selectedProspects[key]
            }
        }, {})
        dispatch(updateSelectedProspects(prospectsToSelect))
    }

    const handleChange = (value, id, property) => {
        const {
            messages
        } = rounds.filter(round => round.id === id)?.[0]
        // console.log("====================handleChange===========================", {value, property})

        Mixpanel.track("Edit Message")

        if (recipientTab === 'all') {
            dispatch(changeCampaignRound({
                id,
                value: {
                    [property]: value
                }
            }))
        }
        else {
            dispatch(changeCampaignRound({
                id,
                value: {
                    messages: messages.map(message => {
                        if (selectedProspects[recipientTab].jobProfileId === message.jobprofile_id) {
                            return {
                                ...message,
                                [property]: value
                            }
                        }
                        else {
                            return message
                        }
                    })
                }
            }))
        }

    }

    const onSecondaryClick = view === 'Edit Rounds' ?
        false :
        e => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            switch (view) {
                case 'Review':
                    dispatch(setOutboxView({ view: 'Edit Messages' }))
                    break;
                case 'Summary':
                    dispatch(setOutboxView({ view: 'Review' }))
                    break;
                case 'Edit Messages':
                    dispatch(setOutboxView({ view: 'Edit Rounds' }))
                    break;
                default:
                    break;
            }
        }
    const onPrimaryClick = e => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        switch (view) {
            case 'Edit Rounds':
                dispatch(setOutboxView({ view: 'Edit Messages' }))
                break;
            case 'Edit Messages':
                dispatch(setOutboxView({ view: 'Review', recipientTab: 'all' }))
                // dispatch(setOutboxView({recipientTab: 'all'}))
                break;
            case 'Review':
                dispatch(publishOutboxCampaign(jId, variableTranslator))
                dispatch(setOutboxView({ view: 'Summary' }))
                break;
            case 'Summary':
                const fS = 'title_score'
                const fSO = 'desc'
                const secS = rank > 8 ? 'industry_score' : 'skill_score'
                const secSO = 'desc'
                if (finish) {
                    setFinish(false)
                    push(`/job/${jId}?f=a&fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&fS=${fS}&fSO=${fSO}&secS=${secS}&secSO=${secSO}`)
                } else {
                    onChangePause()
                }
                break;

            default:
                break;
        }
    }

    if (view === 'CONSENT') {
        return (
            <HybridConsentScreen />
        )
    }

    return (
        <div
            className='outboxContainer'
        >
            <OutboxHeader
                push={push}
                rank={rank}
            />
            <div
                className='outboxBodyContainer'
            >
                <div
                    className='outboxBody'
                    style={{
                        width: 'min(100%, 1440px)'
                    }}
                >
                    <CSSTransition
                        in={view !== 'Review' && view !== 'Summary'}
                        classNames="outboxTabsFade"
                        unmountOnExit={true} timeout={300}
                    >
                        <RecipientsTabs
                            togglePersonalise={togglePersonalise}
                            updateSelected={updateSelected}
                        />

                    </CSSTransition>
                    <SwitchTransition mode="out-in">
                        <CSSTransition
                            key={view === 'Edit Rounds' || view === 'Review'}
                            classNames="outboxCardsFade"
                            unmountOnExit={true} timeout={300}
                        >
                            {(() => {
                                switch (view) {
                                    case 'Edit Rounds':
                                        return <OutboxRoundsController
                                            togglePersonalise={togglePersonalise}
                                            channelOptions={channelOptions}
                                            onTabClick={onTabClick}
                                            disabled={view === 'Review'}
                                            messageTemplates={messageTemplates}
                                        />
                                    case 'Edit Messages':
                                        return <OutboxMessagesController
                                            togglePersonalise={togglePersonalise}
                                            channelOptions={channelOptions}
                                            handleChange={handleChange}
                                            variableTranslator={variableTranslator}
                                            onTabClick={onTabClick}
                                            isInViewport={isInViewport}
                                        />
                                    case 'Review':
                                        return <OutboxReviewController
                                            channelOptions={channelOptions}
                                            handleChange={handleChange}
                                            variableTranslator={variableTranslator}
                                            errorCheck={errorCheck}
                                            emailCheck={emailCheck}
                                            consentCheck={consentCheck}
                                            consentRequested={consentRequested}
                                            setConsentRequested={setConsentRequested}
                                            updateSelected={updateSelected}
                                            isInViewport={isInViewport}
                                            isPaused={isPaused}
                                        />
                                    case 'Summary':
                                        return <OutboxSummary
                                            channelOptions={channelOptions}
                                            handleChange={handleChange}
                                            variableTranslator={variableTranslator}
                                            errorCheck={errorCheck}
                                            consentRequested={consentRequested}
                                            emailCheck={emailCheck}
                                            updateSelected={updateSelected}
                                            isInViewport={isInViewport}
                                            push={push}
                                            isPaused={isPaused}
                                            setFinish={setFinish}
                                        />

                                    default:
                                        return (
                                            <h1
                                                style={{ color: 'white' }}
                                            >
                                                {view}
                                            </h1>
                                        )
                                }
                            })()}
                        </CSSTransition>
                    </SwitchTransition>
                </div>
            </div>
            <ActionBar
                // PrimaryLabel={view==='Review'?'Publish':view==='Summary'? 'Stop' : 'Next'}
                PrimaryLabel={view === 'Review' ? 'Publish' : view === 'Summary' ? finish ? 'Finish' : isPaused ? 'Resume' : 'Stop' : 'Next'}
                primaryDisabled={primaryDisabled}
                onPrimaryLoading={isLoading}
                onPrimaryClick={onPrimaryClick}
                // SecondaryLabel='Cancel'
                onSecondaryClick={view !== 'Summary' ? onSecondaryClick : false}
                onChangePause={onChangePause}
                isPaused={isPaused}
                finish={finish}
                view={view}
            >
                <CSSTransition
                    in={view !== 'Summary'}
                    classNames="outboxCardsFade"
                    unmountOnExit={true} timeout={300}
                >
                    <CaretStepper
                        steps={steps}
                        currentStep={view}
                    />
                </CSSTransition>

            </ActionBar>
        </div>
    )
}

export default connect(state => ({
    inMailCredits: state.hybridCampaign.campaignOperations.inMailCredits,
    recruiterInMailCredits: state.outbox.campaignOperations.recruiterInMailCredits,
    recipientTab: state.outbox.viewController.recipientTab,
    rounds: state.outbox.campaigns.rounds,
    draft: state.outbox.campaigns.draft,
    drafts: state.outbox.meta.drafts,
    selectedProspects: state.prospectsView.candidates.selectedProspects,
    view: state.outbox.viewController.view,
    jobData: state.prospectsView.candidates.job,
    userName: state.auth.user.name,
    companyHiringFor: state.prospectsView.candidates.job?.jobArray?.companyHiringFor?.[0] || {},
}))(Outbox)
