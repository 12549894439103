import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from 'react-router-dom'
import { 
    Label,
    Progress } from 'reactstrap'
import { connect } from 'react-redux'

import './style.scss'
import Loader from "react-loader-spinner";
import Loading from "../Loading";

const MessageClassifierProgress = ({
    labelName,
    progreesValue
}) => {
    return (
        <div className='messageClassifierProgressContainer'>
            <Label>
                {labelName}
            </Label>
            <Progress value={progreesValue}/>
        </div>
    )
}

const MessageClassifier = ({
    message,
    dispatch
  }) => {

    const [myTimeout, setMyTimeout] = useState(null)
    const [isFetching, setisFetching] = useState(false)
    const [isFetched, setisFetched] = useState(false)

    const [classifications, setClassifications] = useState({
        call_to_action: {
            name: 'Call to Action',
            score: 0
        },
        common_ground: {
            name: 'Common Ground',
            score: 0
        },
        establish_credibility: {
            name: 'Build Credibility',
            score: 0
        },
        flattery: {
            name: 'Flattery',
            score: 0

        },
        incentive_for_connecting: {
            name: 'Incentive',
            score: 0

        },
        personalization: {
            name: 'Personalization',
            score: 0

        }
    })


    useEffect(() => {
        // setMessage(isEmail ? 
        //     message :
        //     connectionDegree==='1st' ? 
        //     firstDegreeTemplate : 
        //     secondDegreeTemplate)
        if (myTimeout) {
            clearTimeout(myTimeout)
            setMyTimeout(setTimeout(timeoutHandler, 500))
          } else {
            setMyTimeout(setTimeout(timeoutHandler, 500))
          }
    }, [message])

    const timeoutHandler = () => {
        const body = new FormData()
        
        body.append('message', message)
    
        setisFetching(true)
        setisFetched(false)

        fetch(`${process.env.REACT_APP_MESSAGE_CLASSIFICTAION_HOST}/message_classify`, {
          method: 'POST',
          body
        })
        .then(r => r.json())
        .then(r => {

            setisFetching(false)
            setisFetched(true)

            setClassifications({
                call_to_action: {
                    name: 'Call to Action',
                    score: r.call_to_action || 0
                },
                common_ground: {
                    name: 'Common Ground',
                    score: r.common_ground || 0
                },
                establish_credibility: {
                    name: 'Build Credibility',
                    score: r.establish_credibility
                },
                flattery: {
                    name: 'Flattery',
                    score: r.flattery || 0
        
                },
                incentive_for_connecting: {
                    name: 'Incentive',
                    score: r.incentive_for_connecting || 0
        
                },
                personalization: {
                    name: 'Personalization',
                    score: r.personalization || 0
        
                }
            })
        })
        .catch(e => console.log("ERROR", e))
    }
    

    return (
        <React.Fragment>
            <div className='messageClassifierHeadingContainer'>
                <h2 className='messageClassifierHeading'>
                    Assistant
                </h2>
                {isFetching &&
                    <Loading
                        width='24'
                        height='24' />
                }
            </div>
            <div className='messageClassifierContainer'>
                <MessageClassifierProgress  
                    labelName={classifications.call_to_action.name} 
                    progreesValue={classifications.call_to_action.score/3*100} />
                <MessageClassifierProgress 
                    labelName={classifications.common_ground.name} 
                    progreesValue={classifications.common_ground.score/3*100} />
                <MessageClassifierProgress  
                    labelName={classifications.establish_credibility.name} 
                    progreesValue={classifications.establish_credibility.score/3*100} />
                <MessageClassifierProgress  
                    labelName={classifications.flattery.name} 
                    progreesValue={classifications.flattery.score/3*100} />
                <MessageClassifierProgress  
                    labelName={classifications.incentive_for_connecting.name} 
                    progreesValue={classifications.incentive_for_connecting.score/3*100} />
                <MessageClassifierProgress  
                    labelName={classifications.personalization.name} 
                    progreesValue={classifications.personalization.score/3*100} />
            </div>
        </React.Fragment>
    )
}

export default withRouter(connect(state => ({

}))(MessageClassifier))