import { manualApiCall } from "./index";

export const ActivityTypes = {
    SIGNUP: "SIGNUP",
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    VIEW_JOB: "VIEW_JOB",
    VIEW_PROFILE: "VIEW_PROFILE"
}

export const loggingActivity = (activity, jId = null, meta = {}) => {
    try {
        manualApiCall('/api/v2/activityLogging', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ activity, jId, meta })
        }).then(res => {
        })
    } catch (err) {
        console.error("ERROR OCCUR -> loggingActivity -> err", err)
    }
}
