import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import NewJobSpecialityCard from "../../../components/NewJobSpecialityCard/NewJobSpecialityCard";
import CrossSvg from "../../../components/svgComponents/CrossSvg";
import TickSvg from "../../../components/svgComponents/TickSvg";
import {
  addAdditionalSkills,
  addJobSkill,
  changeAdditionalJobSkills,
  changeJobSkill,
  removeAdditionalSkills,
  removeJobSkill,
  removeJobTitle,
  setIsEdited,
  setJobSetupView,
} from "../../../actions/JobSetup";
import "./criteriaSkills.scss";
import "../../../components/_globalCSSClasses.scss";
import CriteriaVerificationCard from "../../../components/NewJobSpecialityCard/CriteriaVerificationCard";
import JobSetupStep09 from "../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep09";
import BigRoundButton from "../../../uiLibrary/BigRoundButton";
import PlusSvg from "../../../uiLibrary/SVGs/PlusSvg";
import { faSmileBeam } from "@fortawesome/free-solid-svg-icons";
import RangeInput from "../../../uiLibrary/RangeInput";
import ImportanceButton from "../../../uiLibrary/ImportanceButton";
import PillButton from "../../../uiLibrary/PillButton";
import SecondaryButton from "../../../uiLibrary/SecondaryButton";
import TabButton from "../../../uiLibrary/TabButton";
import JobSetupAutoSuggest from "../JobSetupAutoSuggest";
import MinusSvg from "../../../uiLibrary/SVGs/MinusSvg";
import LabelButton from "../../../uiLibrary/LabelButton";
import CaretDown from "../../../uiLibrary/SVGs/CaretDown";
import { Modal } from "reactstrap";
import JobDescriptionCard from "../JobDescriptionCard";
import ProfileCard from "../ProfileCard";
import { Mixpanel } from "../../../utils/Mixpanel";

const CriteriaSkillsInput = ({
  filter,
  value = "",
  toggleFocus,
  onChange = (e) => false,
  toggle = (e) => false,
  experienceSelected = false,
  toggleExperience = (e) => false,
  experienceMin,
  experienceMax,
  onChangeRange,
  onImportanceSelect = (e) => false,
  importanceLevel,
}) => {

  const [shouldFetch, setShouldFetch] = useState(false)

  return (
    <span>
      {/* <input value={value} onChange={onChange} /> */}
      <JobSetupAutoSuggest
        apiType='skill'
        filter={filter}
        toggleFocus={toggleFocus}
        inputValue={value}
        shouldFetch={shouldFetch}
        handleChangeInput={e=>{
          setShouldFetch(true)
          onChange(e.target.value)
        }}
        handleSuggestionSelected={value=>{
          setShouldFetch(false)
          onChange(value)
        }}
      />
      <ImportanceButton
        importanceLevel={importanceLevel}
        onClick={onImportanceSelect}
      />
      {/* <div
        className='skillDetailsExperienceContainer'
      >
        {experienceSelected ? (
          <React.Fragment>
            <RangeInput
              value={{
                min: experienceMin,
                max: experienceMax,
              }}
              onChange={onChangeRange}
              resultLabel={`${experienceMin} to ${experienceMax} years`}
            />
            <button className="addMoreButton" onClick={toggleExperience}>
              <MinusSvg />
              Remove
            </button>
          </React.Fragment>
        ) : (
          <button className="addMoreButton" onClick={toggleExperience}>
            <PlusSvg />
            Add experience
          </button>
        )}
      </div> */}
      <BigRoundButton onClick={toggle}>
        <CrossSvg width="16" height="16" />
      </BigRoundButton>
    </span>
  );
};

const CriteriaSkills = ({
  skills,
  additionalSkills,
  skillsData,
  cards,
  tabs,
  currentTab,
  isReview,
  shouldSelectLastTab,
  dispatch,
}) => {
  const [myTimeout, setMyTimeout] = useState(null);
  const [addSkillMode, setAddSkillMode] = useState(false);
  const [addSkillEditor, setAddSkillEditor] = useState("");
  const [isJDOpen, setIsJDOpen] = useState(false)

  const [toggleFocusArray, setToggleFocusArray] = useState([]);

  const selectedSkills = Object.keys(skills).filter(
    (key) => skills[key].selected
  );
  const unselectedSkills = Object.keys(skills).filter(
    (key) => !skills[key].selected
  );
  const jdSkills = Object.keys(skills).filter((key) =>
    skills[key]?.source?.includes("jobDescription")
  );
  const idealSkills = Object.keys(skills).filter((key) =>
    skills[key]?.source?.includes("idealProfile")
  );
    
    useEffect(() => {
          dispatch(setJobSetupView({
            highlightValue: "",
            profileHighlightValue: [],
            selectedValues: [],
            profileSelectedValues: []
        }))
        const newTabs = {tabs: [...tabs]}
        const importanceMap = {
            '1': 'Low',
            '3': 'Medium',
            '5': 'High'
        }
        skillsData.map(({importance, min, max, name, selected, source=[], sourceId=[], spans=[], experienceSelected, synonym}) => {
            if(newTabs.tabs.length<3){
                source.forEach(src=>{
                  if(newTabs.tabs.length===3){
                    return false
                  }
                  switch (src) {
                    case 'idealProfile':
                      if(!newTabs.tabs.includes('From best fits')){
                        if((newTabs.tabs.includes('From JD'))){
                          newTabs.tabs = [
                            'From JD',
                            'From best fits',
                            ...tabs,
                          ]
                        }
                        else{
                          newTabs.tabs = [
                            'From best fits',
                            ...tabs,
                          ]
                        }
                      }
                      break;
                    case 'jobDescription':
                      if(!newTabs.tabs.includes('From JD')){
                        newTabs.tabs = [
                          'From JD',
                          ...newTabs.tabs,
                        ]
                      }
                      break;
                    default:
                      break;
                  }
                })
            }      
            dispatch(addJobSkill({
                importance: {
                    Low: importanceMap[importance]==='Low',
                    Medium: importanceMap[importance]==='Medium',
                    High: importanceMap[importance]==='High',
                },
                experienceSelected,
                experience:{
                    min,
                    max
                },
                synonym,
                spans,
                name,
                source,
                sourceId,
                selected
            }))
          });
          if(newTabs.tabs.length>1){
            dispatch(
              setJobSetupView({
                ...newTabs,
                currentTab: shouldSelectLastTab ? newTabs.tabs.length - 1 : 0,
                shouldSelectLastTab: false
              })
            )
          }
          else if(shouldSelectLastTab){
            dispatch(setJobSetupView({
              shouldSelectLastTab: false
            }))
          }
  }, []);

  useEffect(() => {
    const selectedValues = tabs[currentTab]==='From JD' || tabs[currentTab]==='Details'  ? 
      jdSkills.map(key=>({
          ...skills[key],
          key,
          onClick: e=>{
            dispatch(setIsEdited(true))
            handleDeleteSkill(key)}
      })) : 
      tabs[currentTab]==='From best fits' ? 
      idealSkills.map(key=>({
          ...skills[key],
          key,
          onClick: e=>{
            dispatch(setIsEdited(true))
            handleDeleteSkill(key)}
    })) : []
    dispatch(setJobSetupView({
      selectedValues,
      profileSelectedValues: selectedValues
    }))   
      
  }, [skills, currentTab])


  useEffect(() => {
    Mixpanel.track(`Skills ${tabs[currentTab]}`)   
  }, [currentTab])

  const handleDeleteSkill = (key) => {
    dispatch(removeJobSkill(key))
  }
  
  const handleToggleSkill = (key, flag=false) => {
      const selected = !skills[key].selected
      if(selected && flag){
        dispatch(setJobSetupView({
          highlightValue: skills[key].name.toLowerCase().trim()
        }))
      }
      dispatch(setIsEdited(true))
      dispatch(changeJobSkill({
          key,
          value: {
              selected: !skills[key].selected
          }
      }))
  }

  const handleRemoveAdditionalSkill = (index) => {
    setToggleFocusArray(toggleFocusArray=>toggleFocusArray.filter((itm, i)=>i!==index))
    dispatch(removeAdditionalSkills(index))
    dispatch(setIsEdited(true))
  }

  // const handleToggleSkill = (key) => {
  //   dispatch(
  //     changeJobSkill({
  //       key,
  //       value: {
  //         selected: !skills[key].selected,
  //       },
  //     })
  //   );
  // };

  const handleSkillChange = (name, key) => {
    dispatch(setIsEdited(true))
    // console.log("====================handleSkillChange========================", skills[key])
    if((skills[key]?.source || []).length>0){
      dispatch(changeJobSkill({
        key,
        value: {
          name,
          synonym:[],
          source: [],
          sourceId: [],
        },
      }))
      dispatch(addJobSkill({
        ...skills[key],
        selected: false
      }))
    }
    else{
      dispatch(
        changeJobSkill({
          key,
          value: {
            name,
          },
        })
      );
    }
    // dispatch(
    //   changeJobSkill({
    //     key,
    //     value: {
    //       name,
    //     },
    //   })
    // );
  };


  const handleAdditionalNameChange = (name, index) => {
    dispatch(changeAdditionalJobSkills({index, values:{name}}))
    dispatch(setIsEdited(true))
  }

  const handleSkillBlur = (e, key) => {
    if (e.target.value === "") {
      dispatch(removeJobTitle(key));
    }
  };

  const handleToggleExperience = (value, key) => {
    dispatch(setIsEdited(true))
    dispatch(
      changeJobSkill({
        key,
        value: {
          experienceSelected: value,
        },
      })
    );
  };

  const handleExperienceChange = (value, key) => {
    dispatch(setIsEdited(true))
    dispatch(
      changeJobSkill({
        key,
        value: {
          experience: value,
        },
      })
    );
  };

  const getImportanceStatus = (skill) =>
    Object.keys(skill.importance)
      .filter((iKey) => skill.importance[iKey])
      .pop();

  const handleImportanceClick = (value, key) => {
    const payload = Object.keys(skills[key].importance).reduce(
      (prevKeys, nextKey) => {
        prevKeys[nextKey] = value === nextKey;
        return prevKeys;
      },
      {}
    );
    dispatch(setIsEdited(true))
    dispatch(
      changeJobSkill({
        key,
        value: {
          importance: payload,
        },
      })
    );
  };


  const handleAdditionalImportanceClick = (value, index) => {
    const payload = Object.keys(additionalSkills[index].importance).reduce(
      (prevKeys, nextKey) => {
        prevKeys[nextKey] = value === nextKey;
        return prevKeys;
      },
      {}
    );
    dispatch(setIsEdited(true))
    dispatch(
      changeAdditionalJobSkills({
        index,
        values: {
          importance: payload,
        },
      })
    );
  };

  const toggleAddSkill = () => {
    // setAddSkillEditor("");
    // setAddSkillMode((prev) => !prev);
    if(additionalSkills.some(itm=>itm.name.trim()==="")){
      let empytyIndex = additionalSkills.length-1
      let emptyIndexSet = false
      for (let index = additionalSkills.length-1; index >= 0 && !emptyIndexSet ; index--) {
        const {name} = additionalSkills[index];
        if(name.trim()===""){
          empytyIndex = index
          emptyIndexSet = true
        }
      }
      setToggleFocusArray(toggleFocusArray=>toggleFocusArray.map((itm, i)=>i===empytyIndex?!itm:itm))
    }
    else{
      setToggleFocusArray(toggleFocusArray=>[...toggleFocusArray, false])
      dispatch(addAdditionalSkills({}))
      setTimeout(()=>{
        document.getElementById("addAdditionalSkillButton")?.scrollIntoView({behavior: "smooth"})
      })
    }
  };

  const handleAddSkill = (name) => {
    if(Object.keys(skills).map(key=>skills[key]?.name.trim().toLowerCase()).includes(name.trim().toLowerCase())){
      const key = Object.keys(skills).filter(key=>skills[key]?.name.trim().toLowerCase()===name.trim().toLowerCase())?.[0]
      dispatch(
        addJobSkill({
            ...skills[key],
            selected: true,
            importance: {
              Low: false,
              Medium: false,
              High: true,
            },
          }, true)
      );

    }else {
      //dispatch(setIsEdited(true))
      dispatch(
        addJobSkill({
          name
        })
      );
    }
    toggleAddSkill();
  };

  const handleChangeAddSkillEditor = (e) => {
    setAddSkillEditor(e.target.value);
  };

  const handleMouseEnter = (key) => {
    if (myTimeout) {
      clearTimeout(myTimeout);
      setMyTimeout(
        setTimeout(
          (e) =>
            dispatch(
              setJobSetupView({
                highlightValue: skills[key].name.toLowerCase().trim(),
                profileHighlightValue: [skills[key].name.toLowerCase().trim(), ...skills[key]?.synonym.filter(({source=[]})=>source.includes("idealProfile")).map(({name})=>name.toLowerCase().trim())],
              })
            ),
          300
        )
      );
    } else {
      setMyTimeout(
        setTimeout(
          (e) =>
            dispatch(
              setJobSetupView({
                highlightValue: skills[key].name.toLowerCase().trim(),
                profileHighlightValue: [skills[key].name.toLowerCase().trim(), ...skills[key]?.synonym.filter(({source=[]})=>source.includes("idealProfile")).map(({name})=>name.toLowerCase().trim())],
              })
            ),
          300
        )
      );
    }
  };
  const handleMouseLeave = (key) => {
    clearTimeout(myTimeout);
    dispatch(
      setJobSetupView({
        highlightValue: "",
        profileHighlightValue: [],
      })
    );
  };

  return (
    <div className="criteriaSkillsContainer">
      <div>
        <span>
          <JobSetupStep09 selected={true} />
          <h2>Verify Skills</h2>
        </span>
        <span>
          {tabs.map((tab, index) => (
            <TabButton
              key={tab}
              tab={{ name: tab, index, disabled: true }}
              selected={tab === tabs[currentTab]}
              // onClick={isReview === '1' ? () => false : ({ index }) =>
              //   dispatch(setJobSetupView({ currentTab: index }))
              // }
            />
          ))}
        </span>
      </div>
      {(() => {
        switch (tabs[currentTab]) {
          case "From JD":
            return (
              <div 
                className="criteriaSkillsBody"
                style={{paddingBottom: '120px'}}  
              >
                <span>
                  {jdSkills.map((key) => (
                    <PillButton
                      key={key}
                      selected={skills[key].selected}
                      highlight={true}
                      onClick={isReview === '1' ? () => false : (e) => handleToggleSkill(key)}
                      onMouseEnter={(e) => handleMouseEnter(key)}
                      onMouseLeave={(e) => handleMouseLeave(key)}
                    >
                      {skills[key].name}
                    </PillButton>
                  ))}
                </span>
              </div>
            );
          case "From best fits":
            return (
              <div 
                className="criteriaSkillsBody"
                style={{paddingBottom: '120px'}}  
              >
                <span
                  style={{
                    width: 'min(80%, 1080px)',
                    alignSelf: "center",
                  }}
                >
                  <ProfileCard hasSynonym={true} />;
                  {/* {idealSkills.map((key) => (
                    <PillButton
                      key={key}
                      selected={skills[key].selected}
                      highlight={true}
                      onClick={isReview === '1' ? () => false : (e) => handleToggleSkill(key)}
                      onMouseEnter={(e) => handleMouseEnter(key)}
                      onMouseLeave={(e) => handleMouseLeave(key)}
                    >
                      {skills[key].name}
                    </PillButton>
                  ))} */}
                </span>
              </div>
            );
          case "Details":
            return (
              <React.Fragment>
                 {tabs.includes('From JD') &&
                  <div className="skillDetailsJDModalContainer">
                    <Modal
                      className='skillDetailsJDModal'
                      isOpen={isJDOpen}
                      toggle={e=>setIsJDOpen(prev=>!prev)}
                    >
                      <LabelButton
                        onClick={e=>setIsJDOpen(prev=>!prev)}
                      >
                        <CrossSvg/>
                      </LabelButton>
                      <JobDescriptionCard
                        // containerStyle
                        cardStyle={{
                          height: 'auto',
                          padding: '0 12px'
                        }}
                      />
                    </Modal>
                    <LabelButton
                      onClick={e=>setIsJDOpen(prev=>!prev)}
                    >
                      View JD
                      <CaretDown/>
                    </LabelButton>
                  </div>}
                <div
                  style={{
                    width: 'min(80%, 1080px)',
                    alignSelf: "center",
                  }}
                  className="criteriaSkillsBody"
                >
                  <span>
                    <h5>
                      Skill
                    </h5>
                    <h5>
                      Importance
                    </h5>
                    {/* <h5>
                      Experience
                    </h5> */}
                  </span>
                  {selectedSkills.map((key) => (
                    <CriteriaSkillsInput
                      key={key}
                      filter={d=>!(selectedSkills.map(key=>skills[key]?.name.trim().toLowerCase()).includes(d.trim().toLowerCase()))}
                      value={skills[key].name}
                      onChange={isReview === '1' ? () => false : e=>handleSkillChange(e, key)}
                      toggle={isReview === '1' ? () => false : (e) => handleToggleSkill(key)}
                      experienceSelected={skills[key].experienceSelected}
                      toggleExperience={isReview === '1' ? () => false : (e) =>
                        handleToggleExperience(
                          !skills[key].experienceSelected,
                          key
                        )
                      }
                      experienceMin={skills[key].experience.min}
                      experienceMax={skills[key].experience.max}
                      onChangeRange={isReview === '1' ? () => false : (value) =>
                        handleExperienceChange(value, key)
                      }
                      onImportanceSelect={isReview === '1' ? () => false : (value) =>
                        handleImportanceClick(value, key)
                      }
                      importanceLevel={getImportanceStatus(skills[key])}
                    />
                  ))}
                  {selectedSkills.length > 0 || additionalSkills.length > 0  ? (
                    additionalSkills.map((skill, index) => (
                      <CriteriaSkillsInput
                        key={`sKey${index}`}
                        toggleFocus={toggleFocusArray[index]}
                        filter={d=>!([...selectedSkills.map(key=>skills[key]?.name.trim().toLowerCase()), ...additionalSkills.map(itm=>itm?.name.trim().toLowerCase())].includes(d.trim().toLowerCase()))}
                        // value={specialty.name}
                        // onChange={e=> handleAdditionalNameChange(e, index)}
                        // toggle={e=>handleRemoveAdditionalSpecialty(index)}
                        // key={key}
                        // filter={d=>!(selectedSkills.map(key=>skills[key]?.name.trim().toLowerCase()).includes(d.trim().toLowerCase()))}
                        value={skill.name}
                        onChange={isReview === '1' ? () => false : e=>handleAdditionalNameChange(e, index)}
                        toggle={isReview === '1' ? () => false : (e) => handleRemoveAdditionalSkill(index)}
                        // experienceSelected={skills[key].experienceSelected}
                        // toggleExperience={isReview === '1' ? () => false : (e) =>
                        //   handleToggleExperience(
                        //     !skills[key].experienceSelected,
                        //     key
                        //   )
                        // }
                        // experienceMin={skills[key].experience.min}
                        // experienceMax={skills[key].experience.max}
                        // onChangeRange={isReview === '1' ? () => false : (value) =>handleExperienceChange(value, index)}
                        onImportanceSelect={isReview === '1' ? () => false : (value) =>handleAdditionalImportanceClick(value, index)}
                        importanceLevel={getImportanceStatus(skill)}
                      />
                  ))
                  ) : (
                    <h3>No skills detected</h3>
                  )}
                  {/* {addSkillMode ? (
                    <span> */}
                      {/* <input
                        value={addSkillEditor}
                        onChange={isReview === '1' ? () => false : handleChangeAddSkillEditor}
                      /> */}
                      {/* <JobSetupAutoSuggest
                        apiType='skill'
                        filter={d=>d.trim()!==""}
                        inputValue={addSkillEditor}
                        handleChangeInput={isReview === '1' ? () => false : handleChangeAddSkillEditor}
                        handleSuggestionSelected={value=>handleAddSkill(value)}
                      /> */}
                      {/* <BigRoundButton onClick={isReview === '1' ? () => false : handleAddSkill}>
                        <PlusSvg />
                      </BigRoundButton> */}
                      {/* <BigRoundButton onClick={isReview === '1' ? () => false : toggleAddSkill}>
                        <CrossSvg width="16" height="16" />
                      </BigRoundButton>
                    </span>
                  ) : selectedSkills.length > 0 ? (
                    isReview === '1' ? '' : 
                    <button
                      className="addMorePrimaryButton"
                      onClick={toggleAddSkill}
                    >
                      <PlusSvg selected={true} />
                      Add a skill
                    </button>
                  ) : (
                    isReview === '1' ? '' : 
                    <SecondaryButton onClick={toggleAddSkill}>
                      <PlusSvg selected={true} />
                      Add a skill
                    </SecondaryButton>
                  )} */}
                </div>
                <div
                  style={{
                      width: 'min(80%, 1080px)',
                      alignSelf: 'center'
                  }}
                  className='criteriaSkillsFooter'
                >
                  {selectedSkills.length > 0 || additionalSkills.length > 0 ?
                      isReview !== '1' && <button id="addAdditionalSkillButton" className="addMorePrimaryButton" onClick={toggleAddSkill} >
                          <PlusSvg selected={true} />
                          Add a skill
                        </button> : 
                      isReview !== '1' && <SecondaryButton onClick={toggleAddSkill}>
                          <PlusSvg selected={true} />
                          Add a skill
                        </SecondaryButton>
                  }
                </div>
              </React.Fragment>
            );
          default:
            break;
        }
      })()}
        </div>
    )
}

export default connect(state => ({
    skills: state.jobSetup.jobCriteria.skills,
    additionalSkills: state.jobSetup.jobCriteria.additionalSkills,
    skillsData: state.jobSetup.jobSetup.jobSetup?.skills || [],
    cards: state.jobSetup.viewController.cards,
    tabs: state.jobSetup.viewController.tabs,
    currentTab: state.jobSetup.viewController.currentTab,
    isReview: state.jobSetup?.jobSetup?.isReview,
    shouldSelectLastTab: state.jobSetup.viewController.shouldSelectLastTab,
}))(CriteriaSkills)
