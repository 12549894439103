import { useState } from 'react'
// import Head from 'next/head'
// import Footer from '../component/Footer'
// import Header from '../component/Header'
import './style.scss'
import FormComponent from './components/FormComponent'

export default function Optout() {
  const [inputText, setInputText] = useState("")
  const [finalMessage, setFinalMessage] = useState("")
  const [payload, setPayload] = useState({})
  const [view, setView] = useState(0)
  const handlePreSubmit = ({
    firstName,
    lastName,
    email,
    location,
    type }) => {
    setPayload({
      firstName,
      lastName,
      email,
      location,
      type,
    })
    switch (type) {
      case 'DELETE':
        if (alert("Are you sure, all your data will be deleted.")) {
          setFinalMessage(`Thank you for submitting the request to ${type.toLowerCase()} your information. We will contact you over email with the confirmation in 72 hours.`)
          fetch('https://staging.api.qlu.ai/ds/dataRequest/add', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              firstName,
              lastName,
              email,
              location,
              type,
            })
          })
          setView(5)
        }
        break;
      default:
        setFinalMessage(`Please specify the personal information you wish to ${type.toLowerCase()}, we will process your request and reply to you through your verified email.`)
        setView(4)
        break;
    }
  }

  const handleSubmit = e => {
    switch (payload.type) {
      case 'VIEW':
        setFinalMessage(`Thank you for submitting the request to ${payload.type.toLowerCase()} your information. We will contact you over email with the confirmation in 72 hours.`)
        fetch('https://staging.api.qlu.ai/ds/dataRequest/add', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...payload, inputText
          })
        })
        setView(5)
        break;
      case 'CORRECT':
        setFinalMessage(`Thank you for submitting the request to ${payload.type.toLowerCase()} your information. We will contact you over email with the confirmation in 72 hours.`)
        fetch('https://staging.api.qlu.ai/ds/dataRequest/add', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...payload, inputText
          })
        })
        setView(5)
        break;
      default:
        break;
    }
  }

  const handleCancel = e => {
    setFinalMessage("")
    setInputText("")
    setPayload({})
    setView(0)
  }

  return (
    <div className={"optoutContainer"}>

      {/* <Header/> */}
      {(() => {
        switch (view) {
          case 1:
            return (<div className={"optoutSection"}>
              <h3>
                Request to view
              </h3>
              <p>
                This form allows you to request to view your personal information from our service under the applicable data privacy laws. Please enter your information below.
              </p>
              <FormComponent
                handleCancel={handleCancel}
                handleSubmit={props => handlePreSubmit({
                  ...props,
                  type: 'VIEW'
                })}
              />
            </div>)
          case 2:
            return (<div className={"optoutSection"}>
              <h3>
                Request to correct
              </h3>
              <p>
                This form allows you to request to correct your personal informationfrom our service under the applicable data privacy laws. Please enter your information below.
              </p>
              <FormComponent
                handleCancel={handleCancel}
                handleSubmit={props => handlePreSubmit({
                  ...props,
                  type: 'CORRECT'
                })}
              />
            </div>)
          case 3:
            return (<div className={"optoutSection"}>
              <h3>
                Request to delete
              </h3>
              <p>
                This form allows you to delete your personal information from our service, and opt-out of the processing or sale of your personal information.
              </p>
              <FormComponent
                handleCancel={handleCancel}
                handleSubmit={props => handlePreSubmit({
                  ...props,
                  type: 'DELETE'
                })}
              />
            </div>)

          case 4:
            return (<div className={"optoutSection"}>
              <h3>
                {`Request to ${payload.type.toLowerCase()}`}
              </h3>
              <p>
                {finalMessage}
              </p>
              <RequestInput
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                inputValue={inputText}
                onChange={e => setInputText(e.target.value)}
              />
            </div>)

          case 5:
            return (<div className={"optoutSection"}>
              <h3>
                {`Request to ${payload.type.toLowerCase()}`}
              </h3>
              <p>
                {finalMessage}
              </p>
            </div>)

          default:
            return (<div className={"optoutSection"}>
              <h3>
                Own your data
              </h3>
              <p>
                Choose the type of the request to submit
              </p>
              <div
                className={"optoutOptions"}
              >
                <button
                  onClick={e => setView(1)}
                >
                  View your personal information
                </button>
                <button
                  onClick={e => setView(2)}
                >
                  Correct your personal information
                </button>
                <button
                  onClick={e => setView(3)}
                >
                  Delete and opt-out of the sale of your personal information
                </button>
              </div>
            </div>)
        }
      })()}

      {/* <Footer/> */}
    </div>
  )
}

const RequestInput = ({
  handleCancel = e => false,
  handleSubmit = e => false,
  inputValue,
  onChange
}) => {

  return (
    <div
      className={"requestInput"}
    >
      <div>
        <h4>
          Write down your detailed request
        </h4>
        <textarea
          rows={5}
          cols={25}
          value={inputValue}
          onChange={onChange}
          placeholder='Enter your request here'
        />
      </div>
      <div>
        <button
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  )
}
