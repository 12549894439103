import React from "react";

function MinimizeIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#A0A0A0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2.667 9.334h4v4M13.333 6.667h-4v-4M9.333 6.667L14 2M2 14l4.667-4.666"
      ></path>
    </svg>
  );
}

export default MinimizeIconSvg;
