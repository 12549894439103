/*global chrome*/
import React, { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";

import {
  Dropdown, DropdownMenu, DropdownToggle
} from "reactstrap";

import "./style.scss";

import MessageSequenceSVG from "../SVGs/MessageSequenceSVG";

import Loader from "react-loader-spinner";

import { useParams, withRouter } from "react-router-dom";
import { setInMailCredits, validateEmailPermissionToken } from "../../actions/hybridCampaign";
import {
  changeCampaignName, fetchRecruiter, selectOutboxDraft,
  setExtensionInstalled,
  setLinkedInLoggedIn, toggleOutboxDropdownOpen
} from "../../actions/outbox";
import { fetchSelectedEmails } from "../../actions/prospectsView";
import { GoogleButton } from "../../components/SocialLoginButton";
import { pingQlu } from "../../utils/MessagePassing";
import { Mixpanel } from "../../utils/Mixpanel";
import DropdownComponent from "../DropdownComponent";
import PrimaryButton from "../PrimaryButton";
import RadioButton from "../RadioButton";

const OutboxButton = ({
  emailState,
  dropdownOpen,
  tokenLoading,
  inMailCreditsLoading,
  disabled = false,
  selectedProspects,
  tooltipId = "Action_Bar_Tooltip_0",
  qluExtensionInstalled = false,
  linkedinLoggedIn,
  drafts,
  shareWithTribe,
  jobTitle = "",
  campaignType,
  areEmailsFetched,
  recruiterInMailCreditsLoading,
  areEmailsFetching,
  history: { push },
  className,
  permissionsAllowed,
  dispatch,
}) => {
  const isLoading = tokenLoading || inMailCreditsLoading || areEmailsFetching || recruiterInMailCreditsLoading;
  const draftNameInputRef = useRef(null);
  const [reuse, setReuse] = useState(false);
  const [qluInterval, setQluInterval] = useState(null)
  const [draftName, setDraftName] = useState(
    `${jobTitle} - ${Date().split(" ")[0]}, ${Date().split(" ")[2]} ${Date().split(" ")[1]
    }, ${Date().split(" ")[3]}, ${Date().split(" ")[4].split(":")[0]}:${Date().split(" ")[4].split(":")[1]
    }`
  );

  const [selectedDraft, setSelectedDraft] = useState(0);

  // const numberOfSelectedProspects = Object.keys(selectedProspects).length;

  let { jId, isQuick } = useParams();
  if (isQuick === 'false') {
    isQuick = false
  }
  // localStorage.setItem('qlu_installed', false)
  // dispatch(setExtensionInstalled(true))

  const loadEmails = () => {
    if (qluExtensionInstalled) {
      dispatch(fetchSelectedEmails());
      dispatch(setInMailCredits());
      dispatch(fetchRecruiter());
      dispatch(validateEmailPermissionToken());
    }
  }

  useEffect(() => {
    // console.log("===============qluExtensionInstalled===================", {
    //   qluExtensionInstalled,
    //   qluInterval,
    // })

    if (qluExtensionInstalled) {
      if (JSON.parse(localStorage.getItem("qlu_installed"))) {
        localStorage.setItem('qlu_installed', false)
      }
      if (qluInterval) {
        clearInterval(qluInterval)
      }
    }
  }, [qluExtensionInstalled])

  const toggle = async () => {
    console.log("==========toggle=============", dropdownOpen);
    try {
      if (!disabled) {
        if (!dropdownOpen) {
          loadEmails()
          const resPingQlu = await new Promise(resolve => pingQlu(r => resolve(r)))
          console.log("==========resPingQlu=============", resPingQlu);
          if (resPingQlu && 'me' in resPingQlu) {
            dispatch(setExtensionInstalled(true))
            dispatch(setLinkedInLoggedIn('miniProfile' in resPingQlu.me))
            // console.log("=================setExtensionInstalled===============================", true)
          }
          else {
            dispatch(setExtensionInstalled(false))
            dispatch(setLinkedInLoggedIn(false))
            // console.log("=================setExtensionInstalled===============================", false)
          }
        }
        // console.log("=================toggleOutboxDropdownOpen===============================")
        dispatch(toggleOutboxDropdownOpen());
      }
    } catch (err) {
      console.log("ERROR: ", err);
    }
  };

  const handleClick = (e) => {
    // if (campaignType === "GOOGLE" && !emailState) {
    //   dispatch(
    //     setOutboxView({
    //       view: "CONSENT",
    //     })
    //   );
    // }
    if (reuse) {
      dispatch(selectOutboxDraft(drafts[selectedDraft]))
    }
    dispatch(toggleOutboxDropdownOpen());
    dispatch(changeCampaignName(draftName));
    push(`/outbox/${jId}/${isQuick ? true : false}`);
  };

  const handleRedirectToExtension = e => {

  }
  // console.log("=======================drafts==================================", {drafts, selectedDraft})


  return (
    <Dropdown isOpen={dropdownOpen && !isLoading} disabled={!(permissionsAllowed === '*' || permissionsAllowed?.length && permissionsAllowed?.length > 0 && (permissionsAllowed?.includes('CAN_REACHOUT')))}
      toggle={toggle}>
      <DropdownToggle
        id={tooltipId}
        color="primary"
        disabled={!(permissionsAllowed === '*' || permissionsAllowed?.length && permissionsAllowed?.length > 0 && (permissionsAllowed?.includes('CAN_REACHOUT')))}
        outline
        className={`outboxButtonToggle ${className ? className : ""}`}
      // onMouseEnter={()=>setDropdownHover(true)}
      // onMouseLeave={()=>setDropdownHover(false)}
      >
        {isLoading ? (
          <Loader type="TailSpin" color="#297AF7" width="24" height="24" />
        ) : (
          <MessageSequenceSVG
            style={disabled ? { opacity: 0.5 } : {}}
            stroke="2"
            color="#297AF7"
          />
        )}
      </DropdownToggle>
      <DropdownMenu className="outboxMessagingButtonMenu">
        {qluExtensionInstalled ?
          linkedinLoggedIn ?
            <div>
              {drafts?.length > 0 && (
                <div className="draftSelectionContainer">
                  <RadioButton checked={reuse} onClick={(e) => setReuse(true)}>
                    Reuse old campaign
                  </RadioButton>
                  <DropdownComponent
                    selected={drafts[selectedDraft].name}
                    options={drafts.map(({ name }) => name)}
                    onSelect={(opt, i) => setSelectedDraft(i)}
                  />
                </div>
              )}
              <div className="draftSelectionContainer">
                <RadioButton checked={!reuse} onClick={(e) => setReuse(false)}>
                  New Campaign
                </RadioButton>
                <input
                  ref={draftNameInputRef}
                  value={draftName}
                  onChange={(e) => setDraftName(e.target.value)}
                />
              </div>
              {/* <div className="shareWithTribeContainer">
                <span>
                  <p>Share with tribe</p>
                  <SwitchComponent
                    checked={shareWithTribe}
                    onChange={(e) => dispatch(toggleShareWithTribe())}
                  />
                </span>
                <h4>
                  Tribe members will be able to view the campaign and it’s messages.
                </h4>
              </div> */}
              <div>
                <PrimaryButton onClick={handleClick}>Next</PrimaryButton>
              </div>
            </div> :
            <div
              className='linkedinLoggedInContainer'
            >
              <h4>
                Login to LinkedIn to use messaging.
              </h4>
              <a onClick={toggle} target='_blank' href='https://www.linkedin.com/login'>
                Click here to Login to LinkedIn
              </a>
            </div> :
          <div
            className='extensionInstallion'
          >
            <h4>
              Get the extension
            </h4>
            <p>
              Qlu needs the extension to be able to send messages through your LinkedIn or Email.
            </p>
            <GoogleButton
              onClick={() => {
                setQluInterval(setInterval(() => {
                  if (JSON.parse(localStorage.getItem("qlu_installed"))) {
                    dispatch(setExtensionInstalled(true))
                  }
                }, 500))
                Mixpanel.track("Install Extension from chrome store")
                window.open(process.env.REACT_APP_QLU_EXT_LINK, "_blank")
              }}
            >
              Get Qlu for Chrome
            </GoogleButton>
          </div>}
      </DropdownMenu>
    </Dropdown>
  );
};

export default withRouter(
  connect((state) => ({
    emailState: state.hybridCampaign.campaignOperations.emailState,
    campaignType: state.hybridCampaign.campaignOperations.campaignType,
    tokenLoading: state.hybridCampaign.campaignOperations.tokenLoading,
    inMailCreditsLoading: state.hybridCampaign.campaignOperations.inMailCreditsLoading,
    recruiterInMailCreditsLoading: state.outbox.campaignOperations.recruiterLoading,
    dropdownOpen: state.outbox.viewController.outboxDropdownOpen,
    drafts: state.outbox.meta.drafts,
    shareWithTribe: state.outbox.campaigns.shareWithTribe,
    jobTitle: state.prospectsView.candidates.job.jobTitle,
    areEmailsFetched: state.prospectsView.candidates.areEmailsFetched,
    areEmailsFetching: state.prospectsView.candidates.areEmailsFetching,
    selectedProspects: state.prospectsView.candidates.selectedProspects,
    qluExtensionInstalled: state.outbox.campaignOperations.qluExtensionInstalled,
    linkedinLoggedIn: state.outbox.campaignOperations.linkedinLoggedIn,
    permissionsAllowed: state.prospectsView.candidates.permissions
  }))(OutboxButton)
);
