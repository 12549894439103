import {
  EducationFAQ1,
  EducationFAQ2,
  EducationFAQ3,
  EducationFAQ4,
  EducationFAQ5,
  EducationFAQ6,
  EducationFAQ7,
  EducationFAQ8,
  EducationFAQ9,
  EducationFAQ10,
} from "../Answers/Education";

export const EducationFAQS = [
  {
    heading: "What’s Education Degree?",
    content: <EducationFAQ1 />,
  },
  {
    heading: "Where did the education degree get selected?",
    content: <EducationFAQ2 />,
  },
  {
    heading: "How do I see where the education got selected?",
    content: <EducationFAQ3 />,
  },
  {
    heading: "Nothing was selected for education, why is that?",
    content: <EducationFAQ4 />,
  },
  {
    heading: "What does importance of High, Medium, Low mean for Education?",
    content: <EducationFAQ5 />,
  },
  {
    heading: "What are majors?",
    content: <EducationFAQ6 />,
  },
  {
    heading: "Where are the majors selected from?",
    content: <EducationFAQ7 />,
  },
  {
    heading: "How can I add majors?",
    content: <EducationFAQ8 />,
  },
  {
    heading: "What if I want to add different majors for different degrees?",
    content: <EducationFAQ9 />,
  },
  {
    heading: "Is it okay if I don’t add education?",
    content: <EducationFAQ10 />,
  },
];
