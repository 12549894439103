import React from 'react'
import '../style.scss'
const JobSetupStep02 = ({
    width='24',
    height='24',
    strokeWidth='2',
    selected=false
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className={`qluSvgStroke${selected ? 'Selected' : ''}`} 
                d="M12 15C15.866 15 19 11.866 19 8C19 4.13401 15.866 1 12 1C8.13401 1 5 4.13401 5 8C5 11.866 8.13401 15 12 15Z" 
                strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`} 
                d="M8.21 13.8899L7 22.9999L12 19.9999L17 22.9999L15.79 13.8799" 
                strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>            
    )
}

export default JobSetupStep02
