import QueryString from 'qs'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { PER_PAGE_COUNT } from '../../constants/api'

const AdminRedirect = ({ match, history, dispatch, location }) => {

    useEffect(() => {
        if (match.params && match.params.jobId && match.params.token) {
            // dispatch(loginAdminToTheApp(match.params.jobId, match.params.token))
            const {
                fS = "title_score",
                fSO = "desc",
                secS = "skill_score",
                secSO = "desc",
            } = QueryString.parse(location.search.split("?").pop())
            // setTimeout(() => {
            history.push('/job/' + match.params.jobId + `?${fS ? `fS=${encodeURIComponent(fS)}&` : ""}${fSO ? `fSO=${encodeURIComponent(fSO)}&` : ""}${secS ? `secS=${encodeURIComponent(secS)}&` : ""}${secSO ? `secSO=${encodeURIComponent(secSO)}&` : ""}aT=${match.params.token}&fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}`)
            // }, 1000)
        }
    }, [])

    return (
        <div className='auth_layout'>
        </div>
    )
}

export default connect(state => ({
}))(AdminRedirect)
