import { isEmpty } from 'lodash'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FETCH_PROVIDER_SUCCESS } from '../../actions/auth'

import PrimaryButton from '../../uiLibrary/PrimaryButton'
import { manualPublicApiCall } from '../../utils'

const EmailVerificationCallback = ({
  location,
  history,
  dispatch
}) => {
  const params = qs.parse(location.search.slice(1))
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(async () => {
    try {
      setIsLoading(true)
      const res = await manualPublicApiCall('/public/check/verify/link', {
        method: 'POST', body: JSON.stringify({
          verificationCode: params.token
        })
      })
      console.log('DATA IS: ', res.data)
      setData(res.data)
      if (!isEmpty(res.data)) {
        const UserData = JSON.parse(window.atob(localStorage.getItem('UserData')))
        const r = await manualPublicApiCall('/public/verify/email', {
          method: 'POST', body: JSON.stringify({
            verificationCode: params.token,
            email: UserData.email,
            password: UserData.password,
          })
        })
        if (r.isSuccess && !isEmpty(r.data) && r.message !== "Email/Password is invalid.") {
          console.log("===============signInToFirebase=================", { r })
          dispatch({ type: FETCH_PROVIDER_SUCCESS, meta: r.data.emailAddress })
          history.push('/auth/enable-2fa')
        } else {
          console.error({ password: 'Incorrect password' })
        }
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      setData({})
      console.log('AN ERROR OCCURRED: ', e.message)
    }
  }, [])

  return (
    <div>
      {isLoading ? <Loader type='TailSpin' color='#297AF7' width={40} height={40} /> :
        <>
          {!isEmpty(data) &&
            <div className='ver_cb_root authElemContainer'>
              <h1>Email Verified!</h1>
              <p>Hi {data.name}, thank you for confirming your email address <b>{data.emailAddress}</b>. Let's log in</p>
              <div>
                <PrimaryButton onClick={() => history.push('/auth/enable-2fa')}>
                  Continue
                </PrimaryButton>
              </div>
            </div>
          }
          {isEmpty(data) &&
            <div className='ver_cb_root authElemContainer'>
              <h1>This link has been used or expired.</h1>
            </div>}
        </>}
    </div>
  )
}

export default connect(state => ({ ...state.auth.emailStep }))(withRouter(EmailVerificationCallback))