import React from 'react'
import FindMorePillCards from '../components/FindMorePillCards'
import "./style.scss"
const FindMoreSkills = ({
    jobSkills=[],
    profileSkills=[],
    newSkills=[],
    onSkillsChange=e=>false,
    onAddSkills=e=>false,
    onRemoveSkills=e=>false
}) => {
    console.log("==============newSkills===================", {newSkills})
    return (
        <div
            className='findMoreSkills'
        >
            <FindMorePillCards
                heading="Previously selected skills"
                pills={jobSkills}
                onChange={(e, index, payload)=>onSkillsChange('jobSkills', index, payload)}
            />
            <FindMorePillCards
                heading="Newly selected skills"
                pills={profileSkills}
                onChange={(e, index, payload)=>onSkillsChange('profileSkills', index, payload)}
            />
            <FindMorePillCards
                heading="similar skills"
                pills={newSkills}
                onChange={(e, index, payload)=>onSkillsChange('newSkills', index, payload)}
                onAdd={payload=>onAddSkills('newSkills', 0, payload)}
                onRemove={(index)=>onRemoveSkills("newSkills", index, {})}
                hasAddMore={true}
                addMoreDisabled={value=>jobSkills.some(({name})=>name?.trim()?.toLowerCase()===value?.trim()?.toLowerCase())||profileSkills.some(({name})=>name?.trim()?.toLowerCase()===value?.trim()?.toLowerCase())||newSkills.some(({name})=>name?.trim()?.toLowerCase()===value?.trim()?.toLowerCase())}
            />
        </div>
    )
}

export default FindMoreSkills