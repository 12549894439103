import React, { useEffect, useState } from "react";
import LinkedInLogo from "../../uiLibrary/SVGs/LinkedInSvg";
import "./RecommendedProspectFullCard.scss";

import { isEmpty, union } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import {
  deleteEmoji,
  favouriteSendAll,
  saveEmoji, _favouriteClear,
  _favouriteEducations,
  _favouriteExperiences,
  _favouriteFunctionalExperience,
  _favouriteHighImportantSkills,
  _favouriteLowImportantSkills,
  _favouriteMediumImportantSkills,
  _favouriteReceiveAll,
  _favouriteRelevantExperience,
  _favouriteSpecialities,
  _favouriteTotalExperience
} from "../../actions/prospectsView";
import ProfileDisplayImage from "../../components/ProfileDisplayImage/ProfileDisplayImage";
import { capitalizeCase, demographicsFormatter, getMaxKey, getYrsAndMos } from "../../utils";
import CaretDown from "../SVGs/CaretDown";

import { toast } from "react-toastify";
import CollapsibleComponent from "../RevampProspectPage/CollapsibleComponent";
import FavouriteSVG from "../SVGs/FavoriteSVG";

const RecommendedProspectFullCard = ({
  selectedProspectComments,
  showCompanyModal,
  elementIndex,
  tabValue = "Profile",
  onClickCard,
  showFullCard,
  isDetail = false,
  showEmojiPickerProspectID,
  isSelected = false,
  onSelect = (e) => false,
  prospectData = {
    totalcurrentcount: "728",
    id: 4064255,
    jobProfileId: 3718869,
    firstName: "Haris",
    lastName: "Khan",
    fullName: "Haris Khan",
    profileUrl: "https://www.linkedin.com/in/mhariskhan55",
    publicIdentifier: "mhariskhan55",
    age: 22,
    gender: {
      male: "1.0",
      female: "2.519815e-08",
    },
    ethnicity: {
      White: "0.8628013",
      Latino: "0.13719867",
    },
    demographics: "Male, 20s, South Asian",
    location: "Pakistan",
    locality: null,
    headline: "Full Stack Engineer at Sectem Technologies",
    title: "Full Stack Engineer",
    imageUrl:
      "https://media-exp1.licdn.com/dms/image/C5603AQEgpiK1GfQw-Q/profile-displayphoto-shrink_400_400/0/1604712859393?e=1637798400&v=beta&t=8jv3-xtUEpnpDzZfItBrEfoRUEQyvjsUtGyIuFiDQbs",
    companyName: null,
    currentCompanyName: "Sectem Technologies",
    currentCompanySize: "18 employees",
    currentCompanyIndustry: "Information Technology & Services",
    currentCompanyWebsite: "http://www.sectem.com",
    comments: [],
    activities: [],
    experience: [
      {
        end: "",
        urn: "urn:li:fsd_company:18295674",
        logo:
          "https://media-exp1.licdn.com/dms/image/C4D0BAQG_rIODkor1dw/company-logo_400_400/0/1545946017464?e=1640217600&v=beta&t=T-3j-fYq8vUhVqZCZjf5g9gdIIXQ9tzsb_1R8Xl1yQ4",
        start: "10-2020",
        title: "Full Stack Engineer",
        location: "Pakistan",
        industries: ["Information Technology & Services"],
        companySize: 18,
        companyType: "Sole Proprietorship",
        description: "",
        skills: ["Web Development", "Sass", "React"],
        duration: "4 yrs",
        company_name: "Sectem Technologies",
        universalName: "sectem-technologies",
        companyPageUrl: "https://www.linkedin.com/company/sectem-technologies",
        companyWebsite: "http://www.sectem.com",
        summary: `Managed accounts for global clientele with average transaction values of over $1 million.
            \n• Spearheaded a new business acquisition project, resulting in an additional $500,000 of consignments as part of $5 million consignment goal.
            \n• Presented and implemented a new marketing plan to senior leadership in order to increase our market presence and brand value.
            \n• Directed an initiative to overhaul our post-sale training system with the global compliance team, resulting in 50% reduction in fraudulent sales activity.
            \n• Coordinated 10+ successful competitive property valuations, each garnering over $500,000 in consignments.
            \n• Led office operations in coordination with global offices, consistently exceeding goals and timelines on projects.`,
        companySpecialities: [
          "Webapps",
          "Andriod Development",
          "DigitalMarketing",
          "IOS Development",
          "WebDesign",
          "Branding",
          "Digital Marketing",
          "Web Development",
          "Javascript",
          "Python",
          "Startup",
          "Consultancy",
        ],
      },
    ],
    education: [
      {
        end: "2021",
        start: "2017",
        degreeName: "Bachelor of Science - BS",
        schoolName: "COMSATS University Islamabad",
        fieldOfStudy: "Computer Science",
      },
    ],
    industry: "Computer Software",
    skills: [
      "MERN Stack",
      "Web Development",
      "Go (Programming Language)",
      "Android Development",
      "Programming",
      "Software Development",
      "Analytical Skills",
      "Web Design",
      "Application Development",
      "Payments",
      "Unit Testing",
      "Python (Programming Language)",
      "Java",
      "Node.js",
      "React.js",
      "C++",
      "Android",
      "Jupyter",
      "MongoDB",
      "Google Cloud Platform (GCP)",
      "PostgreSQL",
      "Redux",
      "JavaScript",
      "SQL",
      "Git",
      "HTML",
      "NoSQL",
      "Management",
      "Problem Solving",
      "English",
      "Web Application Development",
      "Full-Stack Development",
      "Express.js",
      "Golang",
      "Paypal Integration",
      "Concurrent Programming",
      "Microservices",
      "Payment Integration",
      "Finix",
      "Braintree",
    ],
    industryCode: 4,
    summary:
      "Full Stack Engineer working in the information technology, product and services industry. An engineering professional having strong analytical and problem solving skills with a Bachelor of Science - BS focused in Computer Science. Always keen to learn more and take on new challenges.",
    entityUrn: "urn:li:fs_profile:ACoAACKLMkABHbPTI1bjdhQsgmTfKF3kJlSbFFo",
    scrapeType: "DEEP",
    addedAt: "2021-09-21T04:51:21.954Z",
    connectMessageAt: null,
    repliedAt: null,
    downloadedAt: null,
    archivedAt: null,
    visa_status: "None",
    archived: false,
    saved: false,
    followUpFirstMessageAt: null,
    followUpSecondMessageAt: null,
    isDownloaded: false,
    archivedBy: null,
    downloadedBy: null,
    messagedBy: null,
    userId: "941",
    savedBy: null,
    scoringType: "DEEP",
    scoring: {
      score: 34.62051953552971,
      final_score: 69.62052,
      skill_score: 14.90181,
      skill_total: 20,
      title_score: 6,
      title_total: 6,
      visa_status: "None",
      same_company: false,
      company_score: 0,
      recruiterLink: [],
      industry_score: 11.24991,
      industry_total: 12.4999,
      skills_profile_missing: ["networking"],
      skills_profile: [
        "web",
        "python",
        "microservices",
        "information technology",
      ],
      education_score: 1.2688,
      education_total: 2.5000999999999998,
      skills_required: [
        "backend: Important",
        "front end: Important",
        "web: Important",
        "information technology: Must Have",
        "design patterns: Important",
        "continuous integration: Important",
        "tdd: Important",
        "sales: Nice to Have",
        "issue resolution: Nice to Have",
        "microservices: Important",
        "python: Important",
        "coding: Nice to Have",
      ],
      experience_found: [
        "Full Stack Engineer at Sectem Technologies for 11 month(s)",
        "11 month(s) ",
      ],
      experience_score: 7.2,
      experience_total: 15,
      skill_importance: {
        important: ["web", "microservices", "python"],
        important_missing: ["Information Technology and Services"],
        must_haves: ["information technology"],
        must_haves_missing: ["iOS Development"],
        nice_to_haves: ["Sass"],
        nice_to_haves_missing: ["Next.js"],
      },
      adjustment_factor: 70,
      education_profile: ["The prospect went to COMSATS University Islamabad"],
      competitor_company: false,
      education_required: ["bachelor of information technology: Nice to Have"],
      industries_profile: ["information technology & services"],
      experience_required: [
        "3 years related to full stack architect: Nice to Have",
        "proficiency in one or more backend languages: Nice to Have",
        "proficiency in one or more frontend framework html5 or angular or react: Nice to Have",
        "experience developing on both mobile and web platforms: Nice to Have",
        "three years of progressive experience in the specialty in lieu of every year of education: Nice to Have",
        "3 years of continuous integration continuous delivery pair programming and test driven development: Nice to Have",
      ],
      industries_required: ["Computer Software: Must Have"],
      all_experience_found: [
        "Full Stack Engineer at Sectem Technologies for 11 month(s)",
      ],
      qualification_status: "Under Qualified",
      super_high_reccomend: false,
      all_experience_scores: {
        skills: 0,
        industries: -1,
        job_position: 0.6,
        specialities: 0,
        jd_title_score: 1,
        jd_relevant_title_score: 0.6,
      },
      current_ideal_company: false,
      total_experience_span: 0.92,
      high_profile_reccomend: false,
      previous_ideal_company: false,
      relevant_experience_span: 0.92,
    },
    connectionDegree: null,
    messaged: null,
    industries: ["information technology & services"],
    companies: ["sectem technologies"],
    titles: ["full stack engineer"],
    totalExperience: "6 yrs 1 mos",
    functionalExperience: ["Management", "Accounting"],
    noteCount: "0",
    threadMarkers: null,
    activityCount: "1",
    recent_actvities: [
      {
        name: "Raja Zain",
        activityType: "ADDED_PROSPECT",
        activityText: "Added to Prospects",
        activityBy: 941,
        activityAt: "2021-09-21T04:51:21.954954+00:00",
      },
    ],
  },
  job,
  onClickComments = () => false,
  selectedProspectIDVariant,
  activeProspectVariant,
  pageNo,
  dispatch,
  closeCompanyModal,
  favouriteRelevantExperience,
  favouriteExperiences,
  favouriteFunctionalExperience,
  favouriteTotalExperience,
  favouriteSpecialities,
  favouriteHighImportantSkills,
  favouriteMediumImportantSkills,
  favouriteLowImportantSkills,
  favouriteEducations,
  mainData,
}) => {
  const tabBar = ["Profile", "Comments"];
  const [activeTab, setActiveTab] = useState(tabValue);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    console.log("prospectprospect", prospectData);
    if (!isEmpty(prospectData?.recommendationObject)) {
      dispatch(_favouriteReceiveAll(prospectData?.recommendationObject));
    }
    return () => {
      closeCompanyModal();
      document.body.style.overflow = "unset";
      dispatch(_favouriteClear());
    };
  }, []);

  // SCORES FOR PROSPECT
  /*--------------------------------------------*/
  const titleScore = ((prospectData?.buck_score || 0) * 100) / 6;
  const experienceScore =
    ((prospectData?.scoring?.experience_score || 0) * 100) /
    (prospectData?.scoring?.experience_total || 1);
  const experienceSpecialitiesScore =
    ((prospectData?.scoring?.all_experience_scores?.specialities || 0) * 100) /
    1;
  const experienceTitlesScore =
    ((prospectData?.scoring?.all_experience_scores?.jd_title_score || 0) *
      100) /
    1;
  const experienceSkillsScore =
    ((prospectData?.scoring?.all_experience_scores?.skills || 0) * 100) / 1;
  // const experienceFunctionsScore = ((prospectData?.scoring?.all_experience_scores?.specialities || 0)*100)/1
  const educationScore =
    ((prospectData?.scoring?.education_score || 0) * 100) /
    (prospectData?.scoring?.education_total || 1);
  const skillsScore =
    ((prospectData?.scoring?.skill_score || 0) * 100) /
    (prospectData?.scoring?.skill_total || 1);
  const specialitiesScore =
    ((prospectData?.scoring?.speciality_score || 0) * 100) /
    (prospectData?.scoring?.speciality_total || 1);
  const companyScore = ((prospectData?.scoring?.company_score || 0) * 100) / 5;
  /*--------------------------------------------*/

  /*--------------------------------------------*/
  /* Experiences */
  const experiences = React.useMemo(() => {
    return prospectData?.experience?.map((experience, experienceIndex) => {
      const { description } = experience;
      return {
        ...experience,
        description,
        // newDescription,
        ...(prospectData?.scoring?.candidate_experience_found?.[
          experienceIndex
        ] || {}),
      };
    });
  }, []);

  // const new_description = experience.skill.map((skill)=> experi)
  /*--------------------------------------------*/

  const functionalExperience_found = prospectData?.scoring?.functions_found
    ? Object.keys(prospectData?.scoring?.functions_found)?.map((key) => ({
      span: prospectData.scoring.functions_found[key].found_years,
      functionalExperience: key,
    }))
    : [];
  /*--------------------------------------------*/
  /* Specialities Found */

  const specialties_found = Object.keys(
    prospectData?.scoring?.specialties_found
  )?.map((key) => ({
    span: prospectData?.scoring?.specialties_found[key]?.found_years,
    speciality: key,
  }));
  const missing_specialities = [];

  /*--------------------------------------------*/

  /*--------------------------------------------*/
  /* Skills Found */

  const [highSkillsToggle, setHighSkillsToggle] = useState(true);
  const [midSkillsToggle, setMidSkillsToggle] = useState(false);
  const [lowSkillsToggle, setLowSkillsToggle] = useState(false);
  const showHideHighSkills = () =>
    setHighSkillsToggle((prevState) => !prevState);
  const showHideMidSkills = () => setMidSkillsToggle((prevState) => !prevState);
  const showHideLowSkills = () => setLowSkillsToggle((prevState) => !prevState);

  let prospectSkills =
    prospectData?.skills?.length > 0
      ? prospectData?.skills?.map((value) => value.toLowerCase().trim())
      : [];
  let prospectSkillsProfile =
    prospectData?.scoring?.skills_profile?.length > 0
      ? prospectData?.scoring?.skills_profile?.map((value) =>
        value.toLowerCase().trim()
      )
      : [];

  const allSkills = union(prospectSkills, prospectSkillsProfile);

  let must_haves_missing = [];
  let important_missing = [];
  let nice_to_haves_missing = [];

  if (prospectData?.scoring?.skills_required["must_haves"]) {
    Object.keys(prospectData?.scoring?.skills_required)?.map((type) => {
      if (typeof prospectData?.scoring?.skills_required[type] !== "undefined")
        prospectData?.scoring?.skills_required[type]?.map((skill) => {
          if (!allSkills.includes(skill)) {
            switch (type) {
              case "must_haves":
                must_haves_missing.push({ title: skill });
                break;
              case "important":
                important_missing.push({ title: skill });
                break;
              case "nice_to_haves":
                nice_to_haves_missing.push({ title: skill });
                break;
              default:
            }
          }
        });
    });
  }
  let must_haves_skills = prospectData.scoring?.skill_importance?.must_haves.map(
    (title) => ({
      title,
      span: getYrsAndMos(
        prospectData?.scoring?.candidate_experience_found?.reduce(
          (total, { skills }) => (skills?.includes(title) ? total + 1 : total),
          0.0
        )
      ),
    })
  );

  let important_skills = prospectData.scoring?.skill_importance?.important.map(
    (title) => ({
      title,
      span: getYrsAndMos(
        prospectData?.scoring?.candidate_experience_found?.reduce(
          (total, { skills }) => (skills?.includes(title) ? total + 1 : total),
          0.0
        )
      ),
    })
  );

  let nice_to_haves_skills = prospectData.scoring?.skill_importance?.nice_to_haves.map(
    (title) => ({
      title,
      span: getYrsAndMos(
        prospectData?.scoring?.candidate_experience_found?.reduce(
          (total, { skills }) => (skills?.includes(title) ? total + 1 : total),
          0.0
        )
      ),
    })
  );
  /*--------------------------------------------*/

  /*--------------------------------------------*/
  /* demographicFormatter */
  // const demographicsFormatter = (age, gender, ethnicity) => {
  //   let demographicsText = `${age} ${gender ? capitalizeCase(getMaxKey(typeof gender === "string" ? JSON.parse(gender) : gender)) : ""
  //     } ${ethnicity ? capitalizeCase(getMaxKey(typeof ethnicity === "string" ? JSON.parse(ethnicity) : ethnicity)) : ""}`;
  //   return [null, "null", "", undefined, "undefined"].includes(
  //     demographicsText.trim()
  //   ) ? (
  //     <p className="noneFound">Not available</p>
  //   ) : (
  //     <p>{demographicsText}</p>
  //   );
  // };

  /*--------------------------------------------*/

  /*--------------------------------------------*/
  /* emoji */
  const [showEmojiMenu, setShowEmojiMenu] = useState(false);
  const toggleEmoji = () => {
    setShowEmojiMenu((prevState) => !prevState);
    // dispatch(
    //   showEmojiPickerOnProspect(
    //     showEmojiPickerProspectID == prospectData.jobProfileId
    //       ? null
    //       : prospectData.jobProfileId
    //   )
    // );
  };

  const handleEmojiPicked = (emoji, prospect) => {
    setShowEmojiMenu(false);
    dispatch(
      saveEmoji({
        noteId: null,
        jobProfileId: prospect?.jobProfileId,
        emojiText: emoji?.emoji,
        emojiAscII: emoji?.unified,
        jobId: job.id,
        pageNo,
      })
    );
  };

  const deleteEmojiHandle = (emoji, prospect) => {
    dispatch(
      deleteEmoji({
        noteId: null,
        jobProfileId: prospect?.jobProfileId,
        emojiText: emoji?.emojiText,
        jobId: job.id,
      })
    );
  };

  let emojies = [];

  prospectData?.emojies?.map((emoji) => {
    let existsIndex = emojies?.findIndex(
      (item) => emoji.emojiText == item.emojiText
    );
    if (existsIndex < 0) {
      emojies.push({
        emojiText: emoji.emojiText,
        emojiASCII: emoji.emojiASCII,
        count: 1,
        isOwner: emoji?.createdBy ? emoji?.createdBy?.isOwner : emoji?.isOwner,
        names: emoji?.createdBy ? [emoji?.createdBy?.name] : [emoji?.name],
      });
    } else {
      emojies[existsIndex]["count"] += 1;
      if (emoji?.createdBy?.isOwner || emoji?.isOwner == true)
        emojies[existsIndex]["isOwner"] =
          emoji?.createdBy?.isOwner || emoji?.isOwner;
      emojies[existsIndex]["names"].push(
        emoji?.createdBy ? emoji?.createdBy?.name : emoji?.name
      );
    }
  });
  const headline = prospectData.headline.split(" at ");

  const addHoverHighlighter = (index, company_name, type, experienceIndex) => {
    /* 
    index = skillIndex 
    type = type of field (skill, speciality etc)
    company_name = company name to make it unique
    experienceIndex = Global index 
    */
    const element = document.querySelectorAll(
      `[data-name="${type} ${company_name} ${index} ${experienceIndex}"]`
    );
    element.forEach((item) => {
      item.classList.toggle("skillHighLight");
    });
  };

  //new work
  const [favRelevantExperience, setFavRelevantExperience] = useState([]);
  const [favExperiences, setFavExperiences] = useState([]);
  const [favFunctionalExperience, setFavFunctionalExperience] = useState([]);
  const [favTotalExperience, setFavTotalExperience] = useState(false);
  const [favSpecialities, setFavSpecialities] = useState([]);
  const [favHighImportantSkills, setFavHighImportantSkills] = useState([]);
  const [favMediumImportantSkills, setFavMediumImportantSkills] = useState([]);
  const [favLowImportantSkills, setFavLowImportantSkills] = useState([]);
  const [favEducations, setFavEducations] = useState([]);

  const handleSkillsClick = (skill, type) => {
    switch (type) {
      case "high":
        if (!favHighImportantSkills.includes(skill?.title)) {
          setFavHighImportantSkills((prevState) => {
            dispatch(
              _favouriteHighImportantSkills([...prevState, skill?.title])
            );
            return [...prevState, skill?.title];
          });
        } else {
          setFavHighImportantSkills((prevState) => {
            dispatch(
              _favouriteHighImportantSkills([
                ...prevState.filter((item) => item !== skill?.title),
              ])
            );
            return prevState.filter((item) => item != skill?.title);
          });
        }

        break;
      case "medium":
        if (!favMediumImportantSkills.includes(skill?.title)) {
          setFavMediumImportantSkills((prevState) => {
            dispatch(
              _favouriteMediumImportantSkills([...prevState, skill?.title])
            );
            return [...prevState, skill?.title];
          });
        } else {
          setFavMediumImportantSkills((prevState) => {
            dispatch(
              _favouriteMediumImportantSkills([
                ...prevState.filter((item) => item !== skill?.title),
              ])
            );
            return prevState.filter((item) => item != skill?.title);
          });
        }

        break;
      case "low":
        if (!favLowImportantSkills.includes(skill?.title)) {
          setFavLowImportantSkills((prevState) => {
            dispatch(
              _favouriteLowImportantSkills([...prevState, skill?.title])
            );
            return [...prevState, skill?.title];
          });
        } else {
          setFavLowImportantSkills((prevState) => {
            dispatch(
              _favouriteLowImportantSkills([
                ...prevState.filter((item) => item !== skill?.title),
              ])
            );

            return prevState.filter((item) => item !== skill?.title);
          });
        }
        break;
      default:
        break;
    }

    // let favouriteSkill = {
    //   type: `${type}_skill`,
    //   skill: skill,
    // };
    // setNewFavouriteItems((prev) => {
    //   if (prev.includes(skill)) {
    //     return prev.filter((item) => item != skill);
    //   } else {
    //     return [...prev, skill];
    //   }
    // })
  };

  useEffect(() => {
    if (mainData) {
      if (favouriteExperiences) {
        setFavExperiences(favouriteExperiences);
      }
      if (favouriteRelevantExperience) {
        setFavRelevantExperience(favouriteRelevantExperience);
      }
      if (favouriteFunctionalExperience) {
        setFavFunctionalExperience(favouriteFunctionalExperience);
      }
      if (favouriteTotalExperience) {
        setFavTotalExperience(favouriteTotalExperience);
      }
      if (favouriteSpecialities) {
        setFavSpecialities(favouriteSpecialities);
      }
      if (favouriteHighImportantSkills) {
        setFavHighImportantSkills(favouriteHighImportantSkills);
      }
      if (favouriteMediumImportantSkills) {
        setFavMediumImportantSkills(favouriteMediumImportantSkills);
      }
      if (favouriteLowImportantSkills) {
        setFavLowImportantSkills(favouriteLowImportantSkills);
      }
      if (favouriteEducations) {
        setFavEducations(favouriteEducations);
      }
    }
  }, [mainData]);
  console.log("fav", favEducations);
  const MyComponent = () => {
    return (
      <div>
        <div className="recommendedIntro">
          <div className="recommendedMain">
            {prospectData?.scoring?.experience_found?.length > 0 ? (
              <>
                <div
                  className="relevantExperiencePanelSummary"
                  data-name="experience"
                >
                  <div className="columnHeading">
                    Relevant Experience Summary
                  </div>
                  <div className="relevantExperienceSummary">
                    {prospectData?.scoring?.experience_found?.map(
                      (exp, idx) => {
                        const isFoundTitle = favRelevantExperience.some(
                          (item) => {
                            return (
                              item.type == "job" &&
                              item.title == exp?.title &&
                              item.id == idx
                            );
                          }
                        );

                        const isFoundCompany = favRelevantExperience.some(
                          (item) => {
                            return (
                              item.type == "company" &&
                              item.title == exp?.company &&
                              item.id == idx
                            );
                          }
                        );

                        return (
                          <div key={idx} className="relevant-summary">
                            <p>
                              {exp.span ? (
                                getYrsAndMos(exp.span)
                              ) : (
                                <span style={{ color: "gray" }}>No Data</span>
                              )}
                            </p>
                            <p className="title">
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (isFoundTitle) {
                                    setFavRelevantExperience((prev) => {
                                      const prevData = [...prev];
                                      const index = prevData.findIndex(
                                        (item) =>
                                          item.type == "job" &&
                                          item.title == exp?.title &&
                                          item.id == idx
                                      );
                                      prevData.splice(index, 1);
                                      dispatch(
                                        _favouriteRelevantExperience(prevData)
                                      );
                                      return prevData;
                                    });
                                  } else {
                                    setFavRelevantExperience((prev) => {
                                      dispatch(
                                        _favouriteRelevantExperience([
                                          ...prev,
                                          {
                                            type: "job",
                                            title: exp?.title,
                                            id: idx,
                                          },
                                        ])
                                      );
                                      return [
                                        ...prev,
                                        {
                                          type: "job",
                                          title: exp?.title,
                                          id: idx,
                                        },
                                      ];
                                    });
                                  }
                                }}
                              >
                                {" "}
                                {exp.title}{" "}
                                <span>
                                  <FavouriteSVG
                                    color={isFoundTitle ? "#c91010" : "#CDD1D7"}
                                    fill={isFoundTitle ? "#c91010" : "none"}
                                  />
                                </span>
                              </span>{" "}
                              @{" "}
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (isFoundCompany) {
                                    setFavRelevantExperience((prev) => {
                                      const prevData = [...prev];
                                      const index = prevData.findIndex(
                                        (item) =>
                                          item.type == "company" &&
                                          item.title == exp?.company &&
                                          item.id == idx
                                      );
                                      prevData.splice(index, 1);
                                      dispatch(
                                        _favouriteRelevantExperience(prevData)
                                      );
                                      return prevData;
                                    });
                                  } else {
                                    setFavRelevantExperience((prev) => {
                                      dispatch(
                                        _favouriteRelevantExperience([
                                          ...prev,
                                          {
                                            type: "company",
                                            title: exp?.company,
                                            id: idx,
                                          },
                                        ])
                                      );

                                      return [
                                        ...prev,
                                        {
                                          type: "company",
                                          title: exp?.company,
                                          id: idx,
                                        },
                                      ];
                                    });
                                  }
                                }}
                              >
                                {exp.company}{" "}
                                <span>
                                  <FavouriteSVG
                                    color={
                                      isFoundCompany ? "#c91010" : "#CDD1D7"
                                    }
                                    fill={isFoundCompany ? "#c91010" : "none"}
                                  />
                                </span>
                              </span>
                            </p>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="horizontalLine"></div>
              </>
            ) : null}

            <div className="experiencePanel">
              <div className="columnHeading">Experience</div>
              <div className="experienceList">
                {experiences?.map((experience, experienceIndex) => {
                  let isFoundTitle = favExperiences?.findIndex((item) => {
                    return (
                      item.type == "job" &&
                      item.title == experience?.title &&
                      item.id == experienceIndex
                    );
                  });

                  let isFoundCompany = favExperiences.some((item) => {
                    return (
                      item.type == "company" &&
                      item.title == experience?.company_name &&
                      item.id == experienceIndex
                    );
                  });

                  return (
                    <div className="single-experience">
                      <div className="experience-date">
                        {/* <p className="experience-span">
                          {getYrsAndMos(experience.span)}
                        </p> */}
                        <p className="experienceDate">
                          {moment(
                            `${experience?.start?.split("-")[1]}-${experience?.start?.split("-")[0]
                            }`
                          ).format("MMM YYYY")}{" "}
                          -{" "}
                          {experience.end.length > 0
                            ? moment(
                              `${experience?.end?.split("-")[1]}-${experience?.end?.split("-")[0]
                              }`
                            ).format("MMM YYYY")
                            : "Now"}
                        </p>
                      </div>
                      <div
                        className={
                          experienceIndex == prospectData?.experience.length - 1
                            ? "experience-info noBorder"
                            : "experience-info"
                        }
                      >
                        <div className="singleExperienceContainer">
                          <p
                            className="title_exp"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (isFoundTitle >= 0) {
                                setFavExperiences((prev) => {
                                  const prevData = [...prev];
                                  const index = prevData.findIndex(
                                    (item) =>
                                      item.type == "job" &&
                                      item.title == experience?.title &&
                                      item.id == experienceIndex
                                  );
                                  if (
                                    !prevData[index].skills &&
                                    !prevData[index].specialities
                                  ) {
                                    prevData.splice(index, 1);
                                  } else {
                                    prevData[index].liked = !prevData[index]
                                      .liked;
                                  }
                                  dispatch(_favouriteExperiences(prevData));
                                  return prevData;
                                });
                              } else {
                                setFavExperiences((prev) => {
                                  dispatch(
                                    _favouriteExperiences([
                                      ...prev,
                                      {
                                        type: "job",
                                        title: experience?.title,
                                        id: experienceIndex,
                                        liked: true,
                                      },
                                    ])
                                  );

                                  return [
                                    ...prev,
                                    {
                                      type: "job",
                                      title: experience?.title,
                                      id: experienceIndex,
                                      liked: true,
                                    },
                                  ];
                                });
                              }
                            }}
                          >
                            {experience.title}{" "}
                            <span>
                              <FavouriteSVG
                                color={
                                  isFoundTitle >= 0 &&
                                    favExperiences[isFoundTitle]?.liked
                                    ? "#c91010"
                                    : "#CDD1D7"
                                }
                                fill={
                                  isFoundTitle >= 0 &&
                                    favExperiences[isFoundTitle]?.liked
                                    ? "#c91010"
                                    : "none"
                                }
                              />
                            </span>
                          </p>{" "}
                          @{" "}
                          <p className="title_company">
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (isFoundCompany) {
                                  setFavExperiences((prev) => {
                                    const prevData = [...prev];
                                    const index = prevData.findIndex(
                                      (item) =>
                                        item.type == "company" &&
                                        item.title ==
                                        experience?.company_name &&
                                        item.id == experienceIndex
                                    );
                                    prevData.splice(index, 1);
                                    dispatch(_favouriteExperiences(prevData));

                                    return prevData;
                                  });
                                } else {
                                  setFavExperiences((prev) => {
                                    dispatch(
                                      _favouriteExperiences([
                                        ...prev,
                                        {
                                          type: "company",
                                          title: experience?.company_name,
                                          id: experienceIndex,
                                        },
                                      ])
                                    );
                                    return [
                                      ...prev,
                                      {
                                        type: "company",
                                        title: experience?.company_name,
                                        id: experienceIndex,
                                      },
                                    ];
                                  });
                                }
                              }}
                            >
                              {experience.company_name}{" "}
                              <span>
                                <FavouriteSVG
                                  color={isFoundCompany ? "#c91010" : "#CDD1D7"}
                                  fill={isFoundCompany ? "#c91010" : "none"}
                                />
                              </span>
                            </span>
                          </p>
                          {/* <p className="title_location">{experience.location}</p> */}
                        </div>
                        {experience?.skills?.length > 0 ? (
                          <>
                            <div className="experience-skills">
                              <p>Skills</p>
                              <div className="tablets">
                                {experience?.skills?.map((skill, index) => {
                                  let isFoundSkill = favExperiences.some(
                                    (item) => {
                                      return (
                                        item.type == "job" &&
                                        item.title == experience?.title &&
                                        item.id == experienceIndex &&
                                        item.skills?.includes(skill)
                                      );
                                    }
                                  );

                                  return (
                                    <p
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (isFoundSkill) {
                                          setFavExperiences((prev) => {
                                            const prevData = [...prev];
                                            const index = prevData.findIndex(
                                              (item) =>
                                                item.type == "job" &&
                                                item.title ==
                                                experience?.title &&
                                                item.id == experienceIndex
                                            );
                                            const innerIndex = prevData[
                                              index
                                            ].skills.indexOf(skill);

                                            if (innerIndex >= 0) {
                                              prevData[index].skills.splice(
                                                innerIndex,
                                                1
                                              );
                                            }

                                            dispatch(
                                              _favouriteExperiences(prevData)
                                            );
                                            return prevData;
                                          });
                                        } else if (
                                          isFoundTitle >= 0 &&
                                          !isFoundSkill
                                        ) {
                                          setFavExperiences((prev) => {
                                            const prevData = [...prev];
                                            const index = prevData.findIndex(
                                              (item) =>
                                                item.type == "job" &&
                                                item.title ==
                                                experience?.title &&
                                                item.id == experienceIndex
                                            );
                                            if (!prevData[index]?.skills) {
                                              prevData[index].skills = [];
                                            }
                                            prevData[index].skills.push(skill);
                                            dispatch(
                                              _favouriteExperiences(prevData)
                                            );

                                            return prevData;
                                          });
                                        } else if (
                                          isFoundTitle == -1 &&
                                          !isFoundSkill
                                        ) {
                                          setFavExperiences((prev) => {
                                            dispatch(
                                              _favouriteExperiences([
                                                ...prev,
                                                {
                                                  type: "job",
                                                  title: experience?.title,
                                                  id: experienceIndex,
                                                  liked: false,
                                                  skills: [skill],
                                                },
                                              ])
                                            );

                                            return [
                                              ...prev,
                                              {
                                                type: "job",
                                                title: experience?.title,
                                                id: experienceIndex,
                                                liked: false,
                                                skills: [skill],
                                              },
                                            ];
                                          });
                                        }
                                      }}
                                    >
                                      {skill}{" "}
                                      <span>
                                        <FavouriteSVG
                                          color={
                                            isFoundSkill ? "#c91010" : "#CDD1D7"
                                          }
                                          fill={
                                            isFoundSkill ? "#c91010" : "none"
                                          }
                                        />
                                      </span>
                                    </p>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="experience-skills"></div>
                        )}
                        {experience?.specialties?.length > 0 ? (
                          <>
                            <div className="experience-specialities">
                              <p>Business</p>
                              <div className="tablets">
                                {experience?.specialties?.map(
                                  (speciality, index) => {
                                    let isFoundSpeciality = favExperiences.some(
                                      (item) => {
                                        return (
                                          item.type == "job" &&
                                          item.title == experience?.title &&
                                          item.id == experienceIndex &&
                                          item.specialties?.includes(speciality)
                                        );
                                      }
                                    );
                                    let expSpeciality = `Experience speciality:  ${speciality} of ${experience?.title} @ ${experience?.company_name} `;
                                    return (
                                      <p
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          if (isFoundSpeciality) {
                                            setFavExperiences((prev) => {
                                              const prevData = [...prev];
                                              const index = prevData.findIndex(
                                                (item) =>
                                                  item.type == "job" &&
                                                  item.title ==
                                                  experience?.title &&
                                                  item.id == experienceIndex
                                              );
                                              const innerIndex = prevData[
                                                index
                                              ].specialties.indexOf(speciality);

                                              if (innerIndex >= 0) {
                                                prevData[
                                                  index
                                                ].specialties.splice(
                                                  innerIndex,
                                                  1
                                                );
                                              }
                                              dispatch(
                                                _favouriteExperiences(prevData)
                                              );

                                              return prevData;
                                            });
                                          } else if (
                                            isFoundTitle >= 0 &&
                                            !isFoundSpeciality
                                          ) {
                                            setFavExperiences((prev) => {
                                              const prevData = [...prev];
                                              const index = prevData.findIndex(
                                                (item) =>
                                                  item.type == "job" &&
                                                  item.title ==
                                                  experience?.title &&
                                                  item.id == experienceIndex
                                              );
                                              if (
                                                !prevData[index]?.specialties
                                              ) {
                                                prevData[
                                                  index
                                                ].specialties = [];
                                              }
                                              prevData[index].specialties.push(
                                                speciality
                                              );
                                              dispatch(
                                                _favouriteExperiences(prevData)
                                              );

                                              return prevData;
                                            });
                                          } else if (
                                            isFoundTitle == -1 &&
                                            !isFoundSpeciality
                                          ) {
                                            setFavExperiences((prev) => {
                                              dispatch(
                                                _favouriteExperiences([
                                                  ...prev,
                                                  {
                                                    type: "job",
                                                    title: experience?.title,
                                                    id: experienceIndex,
                                                    liked: false,
                                                    specialties: [speciality],
                                                  },
                                                ])
                                              );
                                              return [
                                                ...prev,
                                                {
                                                  type: "job",
                                                  title: experience?.title,
                                                  id: experienceIndex,
                                                  liked: false,
                                                  specialties: [speciality],
                                                },
                                              ];
                                            });
                                          }
                                        }}
                                      >
                                        {speciality}
                                        <span>
                                          <FavouriteSVG
                                            color={
                                              isFoundSpeciality
                                                ? "#c91010"
                                                : "#CDD1D7"
                                            }
                                            fill={
                                              isFoundSpeciality
                                                ? "#c91010"
                                                : "none"
                                            }
                                          />
                                        </span>
                                      </p>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {experienceIndex == experiences.length - 1 ? null : (
                          <div className="horizontalLine"></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="horizontalLine"></div>
            <div className="totalExperiencePanel">
              <div className="functionalExperience">
                <div className="columnHeading">Functional Experience</div>
                {functionalExperience_found?.length > 0 ? (
                  <div className="tablets">
                    {functionalExperience_found.map(
                      ({ span, functionalExperience }) => {
                        let isFoundFunctionalExperience = favFunctionalExperience.includes(
                          functionalExperience
                        );
                        let functionalExp = `Functional experience:  ${functionalExperience}`;
                        return (
                          <div className="functionalExperienceContainer">
                            <p
                              onClick={() => {
                                if (isFoundFunctionalExperience) {
                                  setFavFunctionalExperience((prev) => {
                                    dispatch(
                                      _favouriteFunctionalExperience(
                                        prev.filter(
                                          (item) => item != functionalExperience
                                        )
                                      )
                                    );

                                    return prev.filter(
                                      (item) => item != functionalExperience
                                    );
                                  });
                                } else {
                                  setFavFunctionalExperience((prev) => {
                                    dispatch(
                                      _favouriteFunctionalExperience([
                                        ...prev,
                                        functionalExperience,
                                      ])
                                    );
                                    return [...prev, functionalExperience];
                                  });
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {functionalExperience}{" "}
                              <span>
                                <FavouriteSVG
                                  color={
                                    isFoundFunctionalExperience
                                      ? "#c91010"
                                      : "#CDD1D7"
                                  }
                                  fill={
                                    isFoundFunctionalExperience
                                      ? "#c91010"
                                      : "none"
                                  }
                                />
                              </span>
                            </p>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <p className="noneFound">Not available</p>
                )}
              </div>
              <div className="totalExperience">
                <div className="columnHeading">Total Experience</div>
                <p
                  className="totalExp"
                  onClick={() => {
                    setFavTotalExperience((prev) => {
                      dispatch(_favouriteTotalExperience(!prev));
                      return !prev;
                    });
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {getYrsAndMos(prospectData?.scoring?.total_experience_span)}{" "}
                  <span>
                    <FavouriteSVG
                      color={favTotalExperience ? "#c91010" : "#CDD1D7"}
                      fill={favTotalExperience ? "#c91010" : "none"}
                    />
                  </span>
                </p>
              </div>
            </div>
            <div className="horizontalLine"></div>
            <div className="specialitiesPanel" data-name="specialities">
              <div className="columnHeading">
                Business
                <span className="globalDotStyle">•</span>{" "}
                <span>{specialties_found?.length} found</span>
                {missing_specialities.length > 0 ? (
                  <span className="missing">
                    <span className="globalDotStyle">•</span>{" "}
                    <span>{specialties_found?.length} missing</span>
                  </span>
                ) : null}
              </div>
              <div className="specialities">
                {specialties_found?.map(({ speciality, span }) => {
                  let isSpecialityFound = favSpecialities.includes(speciality);

                  let speciality_found = `Speciality:  ${speciality}`;
                  return (
                    <div>
                      <span className="tablets">
                        <span
                          onClick={() => {
                            if (isSpecialityFound) {
                              setFavSpecialities((prev) => {
                                dispatch(
                                  _favouriteSpecialities(
                                    prev.filter((item) => item != speciality)
                                  )
                                );
                                return prev.filter(
                                  (item) => item != speciality
                                );
                              });
                            } else {
                              setFavSpecialities((prev) => {
                                dispatch(
                                  _favouriteSpecialities([...prev, speciality])
                                );
                                return [...prev, speciality];
                              });
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {speciality}
                          <span>
                            <FavouriteSVG
                              color={isSpecialityFound ? "#c91010" : "#CDD1D7"}
                              fill={isSpecialityFound ? "#c91010" : "none"}
                            />
                          </span>
                        </span>
                      </span>{" "}
                      <span className="duration">
                        <span className="globalDotStyle">•</span>{" "}
                        {getYrsAndMos(span)}
                      </span>
                    </div>
                  );
                })}
                {missing_specialities.length > 0
                  ? missing_specialities?.map(({ speciality, span }) => (
                    <div className="missing">
                      <span className="tablets">
                        <span>{speciality}</span>
                      </span>{" "}
                      <span className="duration">
                        <span className="globalDotStyle">•</span>{" "}
                        {getYrsAndMos(span)}
                      </span>
                    </div>
                  ))
                  : null}
              </div>
            </div>
            <div className="horizontalLine"></div>
            <div className="skillsPanel" data-name="skills">
              <div className="columnHeading">Skills</div>
              {must_haves_skills?.length > 0 ? (
                <>
                  <div className="highSkill">
                    <div className="subHeading">
                      {must_haves_skills?.length > 0 ||
                        must_haves_missing?.length > 0 ? (
                        <Button
                          color=""
                          className=""
                          onClick={showHideHighSkills}
                        >
                          <div className="columnHeading">
                            High Importance{" "}
                            <span className="globalDotStyle">•</span>{" "}
                            <span>{must_haves_skills?.length} found</span>{" "}
                            {must_haves_missing?.length > 0 ? (
                              <span className="missing">
                                <span className="globalDotStyle">•</span>{" "}
                                <span>
                                  {must_haves_missing?.length} missing
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                            <CaretDown
                              style={{
                                marginLeft: "8px",
                                transform: highSkillsToggle
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              }}
                              width="10px"
                              height="6px"
                              color="#A7ABB0"
                            />
                          </div>
                        </Button>
                      ) : (
                        <p>
                          High Importance{" "}
                          <span className="globalDotStyle">•</span>{" "}
                          <span>{must_haves_skills?.length} found</span>{" "}
                          {must_haves_missing?.length > 0 ? (
                            <span className="missing">
                              <span className="globalDotStyle">•</span>{" "}
                              <span>{must_haves_missing?.length} missing</span>
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                      )}

                      <CollapsibleComponent
                        isOpen={highSkillsToggle}
                        availableSkills={must_haves_skills}
                        missingSkills={must_haves_missing}
                        showSpan={false}
                        type={"High"}
                        showFavourite={true}
                        favouriteState={favHighImportantSkills}
                        onClick={(e) => handleSkillsClick(e, "high")}
                      />
                    </div>
                  </div>
                  <div className="horizontalLine"></div>
                </>
              ) : null}
              {/* {important_skills?.length > 0 ?( ) : null} */}

              {important_skills?.length > 0 || important_missing?.length > 0 ? (
                <>
                  <div className="mediumSkill">
                    <div className="subHeading">
                      <Button color="" className="" onClick={showHideMidSkills}>
                        <div className="columnHeading">
                          Medium Importance{" "}
                          <span className="globalDotStyle">•</span>{" "}
                          <span>{important_skills?.length} found</span>{" "}
                          {important_missing?.length > 0 ? (
                            <span className="missing">
                              <span className="globalDotStyle">•</span>{" "}
                              <span>{important_missing?.length} missing</span>
                            </span>
                          ) : (
                            ""
                          )}
                          <CaretDown
                            style={{
                              marginLeft: "8px",
                              transform: midSkillsToggle
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            }}
                            width="10px"
                            height="6px"
                            color="#A7ABB0"
                          />
                        </div>
                      </Button>

                      {/* <p>
              Medium Importance <span className="globalDotStyle">•</span>{" "}
              <span>{important_skills?.length} found</span>{" "}
              {important_missing?.length > 0 ? (
               <span className="missing">
                 <span className="globalDotStyle">•</span>{" "}
                 <span>{important_missing?.length} missing</span>
               </span>
             ) : (
               ""
             )}
           </p> */}

                      <CollapsibleComponent
                        isOpen={midSkillsToggle}
                        availableSkills={important_skills}
                        missingSkills={important_missing}
                        showSpan={false}
                        type={"Medium"}
                        showFavourite={true}
                        favouriteState={favMediumImportantSkills}
                        onClick={(e) => handleSkillsClick(e, "medium")}
                      />
                    </div>
                  </div>
                  <div className="horizontalLine"></div>
                </>
              ) : (
                ""
              )}
              {nice_to_haves_skills?.length > 0 ||
                nice_to_haves_missing?.length > 0 ? (
                <div className="lowSkill">
                  <div className="subHeading">
                    <Button color="" className="" onClick={showHideLowSkills}>
                      <div className="columnHeading">
                        Low Importance <span className="globalDotStyle">•</span>{" "}
                        <span>{nice_to_haves_skills?.length} found</span>{" "}
                        {nice_to_haves_missing?.length > 0 ? (
                          <span className="missing">
                            <span className="globalDotStyle">•</span>{" "}
                            <span>{nice_to_haves_missing?.length} missing</span>
                          </span>
                        ) : (
                          ""
                        )}
                        <CaretDown
                          style={{
                            marginLeft: "8px",
                            transform: lowSkillsToggle
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          }}
                          width="10px"
                          height="6px"
                          color="#A7ABB0"
                        />
                      </div>
                    </Button>

                    {/* <p>
             Low Importance <span className="globalDotStyle">•</span>{" "}
             <span>{nice_to_haves_skills?.length} found</span>{" "}
             {nice_to_haves_missing?.length > 0 ? (
               <span className="missing">
                 <span className="globalDotStyle">•</span>{" "}
                 <span>{nice_to_haves_missing?.length} missing</span>
               </span>
             ) : (
               ""
             )}
           </p> */}

                    <CollapsibleComponent
                      isOpen={lowSkillsToggle}
                      availableSkills={nice_to_haves_skills}
                      missingSkills={nice_to_haves_missing}
                      showSpan={false}
                      type={"Low"}
                      showFavourite={true}
                      favouriteState={favLowImportantSkills}
                      onClick={(e) => handleSkillsClick(e, "low")}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="horizontalLine"></div>
            {prospectData?.education?.length > 0 ? (
              <>
                <div className="educationPanel" data-name="education">
                  <div className="columnHeading">Education</div>
                  <div className="education">
                    {prospectData?.education?.map((education, id) => {
                      let {
                        degreeName,
                        fieldOfStudy,
                        schoolName,
                        start,
                        end,
                      } = education;
                      let educationTitle = `${degreeName} ${degreeName ? "in" : ""
                        } ${fieldOfStudy ? fieldOfStudy : ""}
                        ${degreeName || fieldOfStudy ? "from" : ""} ${schoolName ? schoolName : ""
                        }.
                        `;
                      let isEducationFound = favEducations.some(
                        (item) =>
                          item.degreeName === degreeName &&
                          item.fieldOfStudy === fieldOfStudy &&
                          item.schoolName === schoolName
                      );
                      return (
                        <p
                          key={id}
                          className="SingleEducation"
                          onClick={() => {
                            if (isEducationFound) {
                              setFavEducations((prev) => {
                                dispatch(
                                  _favouriteEducations(
                                    prev.filter(
                                      (item) =>
                                        item.degreeName !== degreeName ||
                                        item.fieldOfStudy !== fieldOfStudy ||
                                        item.schoolName !== schoolName
                                    )
                                  )
                                );

                                return prev.filter(
                                  (item) =>
                                    item.degreeName !== degreeName ||
                                    item.fieldOfStudy !== fieldOfStudy ||
                                    item.schoolName !== schoolName
                                );
                              });
                            } else {
                              setFavEducations((prev) => {
                                dispatch(
                                  _favouriteEducations([...prev, education])
                                );
                                return [...prev, education];
                              });
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {educationTitle}{" "}
                          <span>
                            <FavouriteSVG
                              color={isEducationFound ? "#c91010" : "#CDD1D7"}
                              fill={isEducationFound ? "#c91010" : "none"}
                            />
                          </span>
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className="horizontalLine"></div>
              </>
            ) : null}

            <div className="VisaDemoPanel">
              <div className="visaPanel">
                <div className="columnHeading">Visa Status</div>

                <p>{prospectData?.visa_status}</p>
              </div>

              <div className="demographicsPanel">
                <div className="columnHeading">Demographics</div>

                {demographicsFormatter(
                  prospectData?.age,
                  prospectData?.gender,
                  prospectData?.ethnicity
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div
        className={`recommendedProspectFullCard ${showFullCard ? "expanded" : ""
          }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="headSection">
          <div className="left">
            <ProfileDisplayImage
              width="48"
              height="48"
              src={prospectData.imageUrl}
              className="prospectAvatar"
            />
            <div className="prospectTitleContainer">
              <div className="prospectName">
                {prospectData.fullName}{" "}
                <span>
                  <LinkedInLogo
                    width="20"
                    height="20"
                    className="linkedInLogo"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <MyComponent />
      </div>
      <div className="footerSection" onClick={(e) => e.stopPropagation()}>
        <div className="left">
          <div>Review Profile</div>
        </div>
        <div className="right">
          <div className="prospectFinish">
            <Button className="cancel" onClick={onClickCard}>
              Cancel
            </Button>
            <Button
              className="finishReview"
              onClick={() => {
                dispatch(
                  favouriteSendAll(prospectData?.jobProfileId, elementIndex)
                );
                toast.dismiss();
                toast("Thanks for reviewing the profile!", {
                  hideProgressBar: true,

                  style: {
                    backgroundColor: "#A0A0A0",
                    borderRadius: "8px",

                    width: "300px",
                    color: "black",
                    fontFamily: "Inter",
                  },
                });
                onClickCard && onClickCard();
              }}
            >
              Finish Review
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({
  job: state.prospectsView.candidates.job,
  selectedProspectIDVariant:
    state.prospectsView.prospectVariant.selectedProspectIDVariant,
  activeProspectVariant:
    state.prospectsView.prospectVariant.activeProspectVariant,
  selectedProspectComments:
    state.prospectsView.candidates.selectedProspectComments,
  showEmojiPickerProspectID:
    state.prospectsView.candidates.showEmojiPickerProspectID,
  pageNo: state.prospectsView.candidates.page,
  favouriteRelevantExperience:
    state.prospectsView.recommendations.favouriteRelevantExperience,
  favouriteExperiences:
    state.prospectsView.recommendations.favouriteExperiences,
  favouriteFunctionalExperience:
    state.prospectsView.recommendations.favouriteFunctionalExperience,
  favouriteTotalExperience:
    state.prospectsView.recommendations.favouriteTotalExperience,
  favouriteSpecialities:
    state.prospectsView.recommendations.favouriteSpecialities,
  favouriteHighImportantSkills:
    state.prospectsView.recommendations.favouriteHighImportantSkills,
  favouriteMediumImportantSkills:
    state.prospectsView.recommendations.favouriteMediumImportantSkills,
  favouriteLowImportantSkills:
    state.prospectsView.recommendations.favouriteLowImportantSkills,
  favouriteEducations: state.prospectsView.recommendations.favouriteEducations,
  mainData: state.prospectsView.recommendations.mainData,
}))(RecommendedProspectFullCard);
