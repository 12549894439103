import {
  TribeFAQ1,
  TribeFAQ2,
  TribeFAQ3,
  TribeFAQ4,
  TribeFAQ5,
  TribeFAQ6,
} from "../Answers/Tribe";

export const TribeFAQS = [
  {
    heading: "What’s my Tribe?",
    content: <TribeFAQ1 />,
  },
  {
    heading: "How do I add a tribe member?",
    content: <TribeFAQ2 />,
  },
  {
    heading: "How do I pick tribe member privileges?",
    content: <TribeFAQ3 />,
  },
  {
    heading: "Can I delete a tribe member later? ",
    content: <TribeFAQ4 />,
  },
  {
    heading: "Can I add or change tribe member privileges later?",
    content: <TribeFAQ5 />,
  },
  {
    heading: "Can I add or change tribe member privileges later?",
    content: <TribeFAQ6 />,
  },
];
