import React from 'react'
import ProfileDisplayImage from '../../components/ProfileDisplayImage/ProfileDisplayImage'
import ConnectSvg from '../SVGs/ConnectSvg'
import './style.scss'
const PillTabButton = ({
    tab = {
        name: 'LinkedIn Message',
        label: '',
        icon: null,
        disabled: false
    },
    selected=false,
    onClick=e=>false,
}) => {

    return(
        <button 
            className={`pillTabButton ${selected ? 'pillTabButtonSelected' : ''}`}
            onClick={e=>onClick(tab)}
            disabled={tab.disabled}
        >
            {tab.icon &&
               tab.icon
            }
            <span>
                <p>

                    {tab?.name} 
                </p>
                {tab?.label &&
                    <h6>

                        {tab?.label} 
                    </h6>}
            </span>
        </button>
    )
}

export default PillTabButton
