import React, { useCallback } from "react";
import Autosuggest from "react-autosuggest";
import InputRange from "react-input-range";
import Toggle from "react-toggle";
import { Button, FormGroup, Input, Label } from "reactstrap";
import AdvancedFilterPillButton from "./AdvancedFilterPillButton";

function FilterSection({
  title,
  handleClick = () => { },
  secondClick = () => { },
  suggestions = [],
  onChange = () => { },
  onSuggestionsFetchRequested = () => { },
  onSuggestionsClearRequested = () => { },
  onSuggestionSelected = () => { },
  getSuggestionValue = () => { },
  renderSuggestion = () => { },
  pillArray = [],
  inputProps = {},
  active = false,
  secondActive = false,
  experience = {},
  handleAdvancedFilterPillEliminate,
  handleAdvancedFilterPillHighlight,
  value = { min: 0, max: 1 },
}) {
  const Actions = useCallback(() => {
    switch (title) {
      // or and
      case "Skills":
      case "Specialty":
      case "Business":
      case "Business Unit":
        return (
          <>
            <div className="filterSubHeadingContainer">
              <h3 className="filterSubHeading">{title}</h3>
              <div className="skillStrictButtonContainer">
                <div>
                  <Button
                    outline
                    className={`${!active ? "skillStrictToggle " : ""
                      }skillStrictButton`}
                    onClick={() => {
                      handleClick && handleClick("OR");
                    }}
                  >
                    OR
                  </Button>
                  <Button
                    outline
                    className={`${active ? "skillStrictToggle " : ""
                      }skillStrictButton`}
                    onClick={() => {
                      handleClick && handleClick("AND");
                    }}
                  >
                    AND
                  </Button>
                </div>
                <div>
                  <Button
                    outline
                    className={`${secondActive == false ? "skillStrictToggle " : ""
                      }skillStrictButton`}
                    onClick={() => {
                      secondClick && secondClick("CURRENT");
                    }}
                  >
                    CURRENT
                  </Button>
                  <Button
                    outline
                    className={`${secondActive ? "skillStrictToggle " : ""
                      }skillStrictButton`}
                    onClick={() => {
                      secondClick && secondClick("PAST");
                    }}
                  >
                    PAST
                  </Button>
                </div>
              </div>
            </div>
          </>
        );

      // empty
      case "Diversity":
      case "Schools":
      case "Current Company":
      case "Past Company":
      case "Current Employee Count":
      case "Spoken Language":
      case "First Name":
      case "Last Name":

        return (
          <>
            <div className="filterSubHeadingContainer">
              <h3 className="filterSubHeading">{title}</h3>
            </div>
          </>
        );

      // strict match
      case "Education":
      case "Current Title":
      case "Past Title":
      case "Location":
      return (
          <>
            <span className="advFilterCheckboxContainer">
              <h3 className="filterSubHeading">{title}</h3>
              {!['Current Title', 'Past Title', 'Past Location'].includes(title) ?
                < label className="TitleSwitchContainer">
                  <span>Strict Match</span>
                  <Toggle
                    icons={false}
                    defaultChecked={active}
                    onChange={onChange}
                  />
                </label> : null
              }
            </span>
          </>
        );

      // // strict match
      // case "Location":
      //   return (
      //     <>
      //       <span className="advFilterCheckboxContainer">
      //         <h3 className="filterSubHeading">{title}</h3>

      //         <div style={{ display: "flex" }}>
      //           <Button
      //             outline
      //             className={`${
      //               secondActive == false ? "skillStrictToggle " : ""
      //             }skillStrictButton`}
      //             onClick={() => {
      //               secondClick && secondClick("CURRENT");
      //             }}
      //           >
      //             CURRENT
      //           </Button>
      //           <Button
      //             outline
      //             className={`${
      //               secondActive ? "skillStrictToggle " : ""
      //             }skillStrictButton`}
      //             onClick={() => {
      //               secondClick && secondClick("PAST");
      //             }}
      //           >
      //             PAST
      //           </Button>
      //           <label className="TitleSwitchContainer">
      //             <span>Strict Match</span>
      //             <Toggle
      //               icons={false}
      //               defaultChecked={active}
      //               onChange={onChange}
      //             />
      //           </label>
      //         </div>
      //       </span>
      //     </>
      //   );

      // checkbox years
      case "Total Experience":
      case "Relevant Experience":
      case "Years of Experience":
      case "Years in current company":
      case "Years in current position":
      case "Year of Graduation":
        return (
          <>
            <span className="advFilterCheckboxContainer">
              <Input
                className="checkBox"
                type="checkbox"
                onChange={onChange}
                checked={active}
              />
              <h3 className="filterSubHeading">{title}</h3>
              <Label className="sliderFilterLablePosition sliderFilterLable">
                {`${experience?.min} to ${experience?.max} Years`}
              </Label>
            </span>
          </>
        );

      case "Past Location":
      default:
        return <></>;
    }
  }, [experience, active, handleClick, onChange, title]);

  const AutosuggestBar = useCallback(() => {
    switch (title) {
      case "Diversity":
        return (
          <>
            <FormGroup className="suggestionFormGroupContainer">
              {/* <Autosuggest
                theme={{
                  input: "adv-filter-ip",
                  suggestion: "suggestion-item",
                  suggestionsContainer: "autosuggest-suggestions-container",
                }}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              /> */}
              <div className="AdvancedVisaFilterPillContainer">
                {pillArray.map((itm) => (
                  <AdvancedFilterPillButton
                    key={itm?.name}
                    title={title}
                    activeState={itm?.value}
                    filterName={itm?.name}
                    handleAdvancedFilterPillEliminate={
                      handleAdvancedFilterPillEliminate
                    }
                    handleAdvancedFilterPillHighlight={
                      handleAdvancedFilterPillHighlight
                    }
                  />
                ))}
              </div>
            </FormGroup>
          </>
        );
      case "Skills":
      case "Specialty":
      case "Business":
      case "Business Unit":
      case "Schools":
      case "Education":
      case "Current Title":
      case "Past Title":
      case "Location":
      case "Current Company":
      case "Past Company":
      case "Spoken Language":
      case "First Name":
      case "Last Name":
        return (
          <>
            <FormGroup className="suggestionFormGroupContainer">
              <Autosuggest
                theme={{
                  input: "adv-filter-ip",
                  suggestion: "suggestion-item",
                  suggestionsContainer: "autosuggest-suggestions-container",
                }}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              />
              <div className="AdvancedVisaFilterPillContainer">
                {pillArray.map((itm) => (
                  <AdvancedFilterPillButton
                    key={itm?.name}
                    activeState={itm?.value}
                    filterName={itm?.name}
                    handleAdvancedFilterPillEliminate={
                      handleAdvancedFilterPillEliminate
                    }
                    handleAdvancedFilterPillHighlight={
                      handleAdvancedFilterPillHighlight
                    }
                  />
                ))}
              </div>
            </FormGroup>
          </>
        );
      case "Current Employee Count":
        return (
          <FormGroup className="suggestionFormGroupContainer">
            <div className="AdvancedVisaFilterPillContainer">
              {pillArray.map((itm) => (
                <AdvancedFilterPillButton
                  key={itm?.name}
                  activeState={itm?.value}
                  filterName={itm?.name}
                  handleAdvancedFilterPillEliminate={
                    handleAdvancedFilterPillEliminate
                  }
                  handleAdvancedFilterPillHighlight={
                    handleAdvancedFilterPillHighlight
                  }
                />
              ))}
            </div>
          </FormGroup>
        );
      case "Total Experience":
      case "Relevant Experience":
      case "Years in current position":
        return (
          <>
            <FormGroup className="AdvancedVisaFilterPillContainer">
              <div className="advExpSliderContainer">
                <InputRange
                  maxValue={30}
                  minValue={0}
                  value={value}
                  onChange={handleClick}
                />
              </div>
            </FormGroup>
          </>
        );
      case "Years in current company":
        return (
          <>
            <FormGroup className="AdvancedVisaFilterPillContainer">
              <div className="advExpSliderContainer">
                <InputRange
                  maxValue={30}
                  minValue={0}
                  step={0.5}
                  value={value}
                  onChange={handleClick}
                />
              </div>
            </FormGroup>
          </>
        );
      case "Year of Graduation":
        return (
          <>
            <FormGroup className="AdvancedVisaFilterPillContainer">
              <div className="advExpSliderContainer">
                <InputRange
                  maxValue={new Date().getFullYear()}
                  minValue={1972}
                  value={value}
                  onChange={handleClick}
                />
              </div>
            </FormGroup>
          </>
        );

      case "Past Location":
      default:
        return <></>;
    }
  }, [
    pillArray,
    handleClick,
    value,
    handleAdvancedFilterPillEliminate,
    handleAdvancedFilterPillHighlight,
    suggestions,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    getSuggestionValue,
    renderSuggestion,
    inputProps,
    title,
  ]);

  return (
    <div className="suggestionMainSpanContainer">
      {Actions(title)}
      {AutosuggestBar(title)}
    </div>
  );
}

export default React.memo(FilterSection);
