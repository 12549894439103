import React, { useEffect } from "react";
import ProfileCard from "../ProfileCard";
import ProfileDrawer from "../ProfileDrawer";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  initCompanyDetails,
  selectHiringCompany,
  setSelectedProfile,
  toggleDetailView,
} from "../../../actions/QuickSetup";
import {
  getCalibrationProfilesIsFetching,
  getCalibrationProfilesData,
  getIsDetailViewDrawerOpen,
  getSelectedProfile,
} from "../../../reducers/QuickSetup/calibrationProfiles";
import Loading from "../../../uiLibrary/Loading";
import CompanyCard from "../../../components/CompanyCard/CompanyCard";
import Backdrop from "../../../uiLibrary/Backdrop";
import QuickDetailProfileCard from '../../ScoreAndConnect/components/QuickDetailProfileCard'
import RevampProspectFullCard from "../../../uiLibrary/RevampProspectFullCard/RevampProspectFullCard";
import { Transition, TransitionGroup } from "react-transition-group";

const TransitionalProspects = ({
  currentTab=0,
  profiles=[],
  showCompanyModal=e=>false,
  onClickCard=e=>false
}) => {

  useEffect(()=>{
    // console.log("====================TransitionalProspects======================", {currentTab})
  }, [currentTab])

  const duration = 500;

  const defaultStyle = {
    width: "80%",
    transition: `all ${duration}ms ease-out`,
    transitionProperty: 'opacity, transform',
    opacity: 0,
  }

  const transitionStyles = {
    entering: { 
      opacity: 1,
      transform: 'translate(-100vw,0)'
    },
    entered:  { 
      opacity: 1, 
      transform: 'translate(0,0)'
    },
    exiting:  { 
      opacity: 0 ,
      transform: 'translate(100vw,0)'
    },
    exited:  { 
      opacity: 0, 
      transform: 'translate(100vw,0)'
    },
  };

  return (
    <React.Fragment>  
      {profiles.map((profileData, index) => {
        return (
          <Transition
            // key={index}
            in={index===currentTab}
            timeout={duration}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            {state=>{
              return (
              <QuickDetailProfileCard
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state]
                }}
                index={index}
                profileData={profileData}
                isCalibrating={true}
                showCompanyModal={showCompanyModal}
                onClickCard={onClickCard}
                disableLogo={false}
              />)}}
          </Transition>
        )})}
      </React.Fragment>
  )

}


export default function Calibration({currentTab}) {

  useEffect(() => {
    return () => {
      dispatch(initCompanyDetails())
    }
  }, [])
  

  const dispatch = useDispatch();

  const companyHiringFor = useSelector(
    (state) => state.jobSetup.companyDetails.companyHiringFor
  );

  const isModalOpen = useSelector(
    (state) => state.QuickSetup.calibrationProfiles.isModalOpen
  );

  const isCompanyProfileLoaded = useSelector(
    (state) => state.jobSetup.companyDetails.isCompanyProfileLoaded
  );

  const isCompanyProfileLoading = useSelector(
    (state) => state.jobSetup.companyDetails.isCompanyProfileLoading
  );
  
  const calibrationProfiles = useSelector((state) =>
    getCalibrationProfilesData(state.QuickSetup.calibrationProfiles)
  );
  const isFetching = useSelector((state) =>
    getCalibrationProfilesIsFetching(state.QuickSetup.calibrationProfiles)
  );
  const isDetailView = useSelector((state) =>
    getIsDetailViewDrawerOpen(state.QuickSetup.calibrationProfiles)
  );
  const selectedProfile = useSelector((state) =>
    getSelectedProfile(state.QuickSetup.calibrationProfiles)
  );

  const setIsDetailView = (isOpen) => dispatch(toggleDetailView(isOpen));
  const updateSelectedProfile = (profile) =>
    dispatch(setSelectedProfile(profile));


  const openDetailView = (toggle) => {
    // console.log("===============openDetailView=========================")
    setIsDetailView(true);
    dispatch(setSelectedProfile(calibrationProfiles[currentTab]))
  };

  // console.log("===============Calibration=========================")

  return (
    <div className="calibrationContainer">
      {isModalOpen && !isCompanyProfileLoading && (
        <Backdrop
          onClick={() =>
            dispatch({ type: "TOGGLE_COMPANY_MODAL", isModalOpen: false })
          }
        />
      )}
      {isModalOpen && !isCompanyProfileLoading && (
        <div
          className={`companyModal ${isModalOpen ? "showCompanyModal" : ""}`}
        >
          <CompanyCard
            companyLogo={companyHiringFor?.logo}
            companyName={companyHiringFor?.name}
            companyHeadline={companyHiringFor?.industry}
            companyEmployees={`${companyHiringFor?.staffCount || 0} Employees`}
            companyDescription={companyHiringFor?.companyDescription}
            industry={companyHiringFor?.industry}
            specialities={companyHiringFor?.specialities}
            staffCount={companyHiringFor?.staffCount}
            companyPageUrl={companyHiringFor?.companyPageUrl}
            headquarter={[
              companyHiringFor?.headquarter?.country || "",
              companyHiringFor?.headquarter?.geographicArea || "",
              companyHiringFor?.headquarter?.city || "",
            ].filter((itm) => itm !== "")}
            companyType={companyHiringFor?.companyType?.localizedName}
            onClick={() =>{
              dispatch(initCompanyDetails())
              dispatch({ type: "TOGGLE_COMPANY_MODAL", isModalOpen: false })
            }}
          />
        </div>
      )}
      <p className="calibrationHeading">Calibration</p>
      <p className="calibrationSubHeading">
        Calibrate your job definition by giving your feedback in the form of
        likes or dislikes on these profiles.
      </p>
      {!isFetching && calibrationProfiles.length>0 && <p className="calibrationSubHeading">
        {`${currentTab+1} of ${calibrationProfiles.length}`}
      </p>}
      {isFetching ? (
        <div className="loadingContainer">
          <Loading />
        </div>
      ) : (
        <div 
          className="calibrationProfiles">
          {/* {calibrationProfiles?.map((profile, index) => (
            <ProfileCard
              setIsDetailView={setIsDetailView}
              isDetailView={isDetailView}
              selectedProfile={selectedProfile}
              setSelectedProfile={updateSelectedProfile}
              profile={profile}
              key={index}
            />
          ))} */}
          {calibrationProfiles.length>0 && 
            <TransitionalProspects     
              currentTab={currentTab}
              profiles={calibrationProfiles}
              showCompanyModal={(experience, init, fin) => {
                dispatch(selectHiringCompany(experience, init, fin));
                dispatch({
                  type: "TOGGLE_COMPANY_MODAL",
                  isModalOpen: true,
                });
              }}
              onClickCard={openDetailView}
            />
          }
        </div>
      )}
      {isDetailView && (
        <ProfileDrawer
          isDetailView={isDetailView}
          setIsDetailView={setIsDetailView}
          profile={calibrationProfiles[currentTab]}
          showCompanyModal={(experience, init, fin) => {
            dispatch(selectHiringCompany(experience, init, fin));
            dispatch({
              type: "TOGGLE_COMPANY_MODAL",
              isModalOpen: true,
            });
          }}
        />
      )}
    </div>
  );
}
