import React from 'react'
import {
  Label,
  Input,
  FormGroup,
} from 'reactstrap'


const SortModule = ({
  dispatch,
  inputName,
  inputLabel,
  SortState,
  setSortState,
  sort,
  order,

}) => {

  const handleRadioChange = (e) => {
    // console.log("HANDLE RADIO CHANGE")
    setSortState({ sort, order })
  }

  console.log('SORTING MODULE', { sort, order, SortState })


  return (
    <div className="SortModuleContainer" >
      <FormGroup tag="fieldset">
        <FormGroup
          className="SortMuduleFormGroup"
          check>
          <Label
            className="SortMuduleRadioLabel"
            check>
            <Input
              checked={sort === SortState.sort && order === SortState.order}
              className="SortMuduleRadioInput"
              type="radio"
              onChange={handleRadioChange}
              name={inputName} />{' '}
            {inputLabel}
          </Label>
        </FormGroup>
      </FormGroup>
    </div>
  )
}

export default SortModule