import React from 'react'
import { connect } from 'react-redux'
import CardContainer from '../../../uiLibrary/CardContainer'
import CarouselTag from '../../../uiLibrary/CarouselTag'
import './style.scss'
const SuggestedSpecialtyCard = ({
    profiles,
    selectedValues,
    profileHighlightValue,
    isReview
}) => {

    const detectedProfile = []
    // const profilesSpecialties = selectedValues.filter(({source=[], sourceId=[]}) => {
    //     profiles.filter(({publicIdentifier})=>sourceId.includes(publicIdentifier) && !detectedProfile.map(({publicIdentifier})=>publicIdentifier).includes(publicIdentifier)).map(profile=>detectedProfile.push(profile))
    //     return source?.includes("publicIdentifier")
    // });

    return (
        <div
            className='profileSpecialtyCardContainer'
        >
            <CardContainer
                // key={`pid${publicIdentifier}`}
                // icon={<JobSetupStep02/>}
                heading={`Suggested`}
            >
                <div
                    className='profileSpecialtyCard'
                >
                    <div
                        className='profileSpecialtyCardBody'
                    >
                        {selectedValues.reduce((total, values)=>[...total, values, ...values?.synonym.map((synonym)=>({ ...synonym,  key: values?.key, selected: values.selected, onClick: values?.onClick }))], []).map((speciality, index)=>(
                            <CarouselTag
                                key={`${speciality.name}${index}`}
                                greyTheme={selectedValues.filter(({selected})=>!selected).reduce((total, {name, synonym=[]})=>[...total, name.trim().toLowerCase(), ...synonym.map(({name})=>name.toLowerCase().trim())], []).includes(speciality.name.trim().toLowerCase())}
                                selected={true}
                                highlight={profileHighlightValue.includes(speciality.name.trim().toLowerCase())}
                                onClick={isReview === '1' ? () => false : selectedValues.reduce((total, values)=>[...total, values, ...values?.synonym.map((synonym)=>({ ...synonym,  key: values?.key, selected: values.selected, onClick: values?.onClick }))], []).filter(({name, sourceId=[]})=>name===speciality.name.trim().toLowerCase())?.[0]?.onClick}
                            >
                                {speciality.name}
                            </CarouselTag>
                        ))}
                    </div>
                </div>
    
            </CardContainer>
        </div>
    )
}

export default connect(state=>({
    selectedValues: state.jobSetup.viewController.selectedValues,
    profileHighlightValue: state.jobSetup.viewController.profileHighlightValue,
    profiles: state.jobSetup.jobIdealProfile.profiles,
    isReview: state.jobSetup?.jobSetup?.isReview
}))(SuggestedSpecialtyCard)
