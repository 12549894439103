import React, { useEffect } from "react";
import "./RightOnClickModal.scss";
import ReactReadMoreReadLess from "react-read-more-read-less";
import Loader from "../../../components/Loader";
import { connect } from "react-redux";

const RightOnClickModal = ({
  profileImage,
  profileName,
  profileIndustry,
  overview,
  website,
  industry,
  specialities,
  company_size,
  headquarters,
  type,
  isSuggestion,
  staffCount,
  style={}
}) => {
  return (
    <div style={style} className="RightOnClickModalBody">
      <div className="RightOnClickModalHeader">
        <img src={profileImage} width="36px" height="36px" />
        <div className="RightOnClickModalCardContent">
          <h3>{profileName}</h3>
          <p>{profileIndustry}</p>
          <span>{`${company_size} Employees`}</span>
        </div>
      </div>
      <div className="RightOnClickModaloverview">
        <span>Overview</span>
        <br />
        <ReactReadMoreReadLess
          charLimit={200}
          readMoreText={"Read more ▼"}
          readLessText={"Read less ▲"}
        >
          {overview}
        </ReactReadMoreReadLess>
      </div>
      <div className="rightOnClickModalcompanyInfo">
        {/* <div>
          <h6>Website</h6>
          <a href={website} target="_blank">
            {website}
          </a>
        </div> */}
        {website && <div>
          <h6>Website</h6>
          <a href={website} target="_blank">{website}</a>
        </div>}
        <div>
          <h6>Industry</h6>
          <p>{industry}</p>
        </div>
        {specialities && <div>
          <h6>Business</h6>
          <p>
            <ReactReadMoreReadLess
              charLimit={80}
              readMoreText={"Read more ▼"}
              readLessText={"Read less ▲"}
            >
              {specialities}
            </ReactReadMoreReadLess>
          </p>
        </div>}
        {company_size && <div>
          <h6>Employee Count</h6>
          <p>{company_size}</p>
        </div>}
        {headquarters && <div>
          <h6>Headquarters</h6>
          <p>{headquarters}</p>
        </div>}
        {/* <div>
          <h6>Type</h6>
          <p>{type}</p>
        </div> */}
      </div>
    </div>
  );
};

export default connect((state) => ({
  isSuggestion: state.jobSetup.companyDetails.isLoadingSuggestion,
}))(RightOnClickModal);
