import * as ActionTypes from "../../actions/QuickSetup";

const initialState = {
  jobSetup: {
    competitorCompanies: [],
    companyHiringFor: [],
    company_speciality: [],
    likedCompanies: [],
    education: [],
    functions: [],
    ideal_company: [],
    ideal_education: [],
    ideal_experience: [],
    ideal_skill: [],
    ideal_title: [],
    job_location: [],
    job_position: [],
    skill: [],
    skills: [],
    speciality: [],
    suggested_skills: [],
    suggested_titles: [],
    technology_work_experience: [],
    total_experience: [],
    suggested_titles_copy: [],
    ideal_specialities: [],
    incentive_section: "",
    company_section: ""
  },
  isLoaded: false,
  isLoading: false,
  isIncentiveLoading: false,
  isIncentiveLoaded: false,
  newJobAdding: false,
  newJobAdded: false,
  addedJobId: null,
  isReview: "",
  isSpecialtyEdited: false,
  isJobEditSuccess: false,
  isEdited: false,
};

const jobSetup = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_JOBSETUP: {
      return initialState;
    }
    case ActionTypes.SENDING_TO_INCENTIVE: {
      return {
        ...state,
        isIncentiveLoading: true,
        isIncentiveLoaded: false,
      };
    }
    case ActionTypes.SENDING_TO_INCENTIVE_SUCCESS: {
      return {
        ...state,
        isIncentiveLoading: false,
        isIncentiveLoaded: true,
        jobSetup: {
          ...state.jobSetup,
          ...action.payload,
        },
      };
    }
    case ActionTypes.SENDING_TO_INCENTIVE_FAILURE: {
      return {
        ...state,
        isIncentiveLoading: false,
        isIncentiveLoaded: false,
      };
    }
    case ActionTypes.SENDING_TO_NER: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    }
    case ActionTypes.SENDING_TO_NER_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        jobSetup: Object.keys(state.jobSetup).reduce((total, key) => {
          if (
            [
              "total_experience",
              "companyHiringFor",
              "job_position",
              "suggested_titles",
              "competitorCompanies",
              "likedCompanies",
              "suggested_titles_copy",
              "skill",
              "ideal_specialities",
              "speciality",
              "technology_work_experience",
              "company_speciality",
              "functions",
              "job_location",
              "job_position",
              "skill",
            ].includes(key) &&
            action.payload?.[key]?.length > 0
          ) {
            return {
              ...total,
              [key]: action.payload?.[key],
            };
          }
          // else if([
          //   // 'suggested_titles',
          // ].includes(key)){
          //   return{
          //     ...total,
          //     [key]: [...state.jobSetup?.[key], ...((action.payload?.[key] || []).filter(({name})=>!state.jobSetup?.[key].map(({name})=>name).includes(name)))]
          //       .map((itm)=>{
          //         if((action.payload?.[key] || []).map(({name})=>name).includes(itm.name)){
          //           return {
          //             ...itm,
          //             selected: action.payload?.[key].filter(({name})=>name===itm.name)?.[0]?.selected
          //           }
          //         }
          //         else{
          //           return itm
          //         }
          //       })
          //   }
          // }
          else if (key in action.payload) {
            return {
              ...total,
              [key]: action.payload?.[key],
            };
          }
          return {
            ...total,
            [key]: state.jobSetup?.[key],
          };
        }, {}),
      };
    }
    case ActionTypes.SENDING_TO_NER_FAILURE: {
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
      };
    }
    case ActionTypes.SET_IDEAL_PROFILE_IN_JOB_SETUP: {
      return {
        ...state,
        jobSetup: {
          ...state.jobSetup,
          ...action.payload,
        },
      };
    }
    case ActionTypes.ADD_NEW_JOB: {
      return {
        ...state,
        newJobAdded: false,
        newJobAdding: true,
      };
    }
    case ActionTypes.ADD_NEW_JOB_SUCCESS: {
      return {
        ...state,
        newJobAdded: true,
        newJobAdding: false,
        addedJobId: action.payload.data.id,
      };
    }
    case ActionTypes.ADD_NEW_JOB_FAILURE: {
      return {
        ...state,
        newJobAdded: false,
        newJobAdding: false,
      };
    }
    case ActionTypes.SET_IS_REVIEW: {
      return {
        ...state,
        isReview: action.payload,
      };
    }
    case ActionTypes.SET_IS_EDITED: {
      return {
        ...state,
        isEdited: action.payload,
      };
    }
    case ActionTypes.SET_IS_SPECIALTY_EDITED: {
      return {
        ...state,
        isSpecialtyEdited: action.payload,
      };
    }
    case ActionTypes.SET_IS_JOB_EDIT_SUCCESS: {
      return {
        ...state,
        isJobEditSuccess: action.payload,
      };
    }
    default:
      return state;
  }
};

export default jobSetup;
