import * as ActionTypes from "../../actions/prospectsView";

const initialState = {
  fS: null,
  fSO: null,
  secS: null,
  secSO: null,
  isOpen: false,
};

const meta = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_SORT_MODAL:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case ActionTypes.SET_SORT: {
      // console.log('INSIDE SET_SORT ACTION REDUCER: ', action.payload)
      // console.log('UPDATED STATE SHOULD bE: ', { ...state, ...action.payload })
      return {
        ...state,
        ...action.payload
        // fS: action.payload.fS || "title_score",
        // fSO: action.payload.fSO || "desc",
        // secS: action.payload.secS || "industry_score",
        // secSO: action.payload.secSO || "desc",
      };
    }
    case ActionTypes.INIT_SORT:
      if (action.payload) {
        return {
          ...state,
          ...action.payload
          // fS: action.payload.fS || "title_score",
          // fSO: action.payload.fSO || "desc",
          // secS: action.payload.secS || "industry_score",
          // secSO: action.payload.secSO || "desc",

          // ...action.payload,
        };
      } else {
        return {
          ...state,
          fS: "title_score",
          fSO: "desc",
          secS: null,
          secSO: null,
        };
      }

    default:
      return state;
  }
};

export default meta;
