import { combineReducers } from "redux";
// import login from './login'
import auth from "./auth";
import jobSetup from "./jobSetup";
import QuickSetup from "./QuickSetup";
import landingPage from "./landingPage";
import outbox from "./outbox";
import hybridCampaign from "./hybridCampaign";
import prospectsView from "./prospectsView";
import home from "./home";
// import jobEdit from "./jobEdit";
import recruiter from "./recruiter";
import notifications from "./notifications";
import meta from "./meta";
import messaging from "./messaging";
import primaryButtonloading from "./primaryButton/primaryButton";
export default combineReducers({
  auth,
  outbox,
  jobSetup,
  QuickSetup,
  // jobEdit,
  home,
  hybridCampaign,
  prospectsView,
  recruiter,
  landingPage,
  notifications,
  meta,
  messaging,
  primaryButtonloading,
});
