import React, { useState, useEffect, memo } from "react";
import { withRouter } from "react-router-dom";
import { FormGroup } from "reactstrap";

import { connect } from "react-redux";

import "./styleableTextArea.scss";
import TagButtonComponent from "../TagButtonComponent/TagButtonComponent";
import StyleableTextArea from "./StyleableTextArea";

import {
  convertFromRaw,
  convertToRaw,
  ContentState,
  EditorState,
  CompositeDecorator,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { getReverseTagTranslation, getTagTranslation } from "../../utils";

import tags from "./tags";

const StyleableTextAreaContainer = memo(
  ({
    dispatch,
    message = "",
    skillsReq = [],
    charLimits = false,
    onChange = () => false,
    shouldShowTagButtons = true,
    canDeleteSkill = true,
    disabled = false,
  }) => {
    const [emailCharcterCounter, setemailCharcterCounter] = useState(
      message.length || 0
    );
    const [emailCharcterLimitFlag, setemailCharcterLimitFlag] = useState(0);

    useEffect(() => {
      setemailCharcterCounter(message.length || 0);
    }, [message]);

    useEffect(() => {
      addNewEditorStateFromValue(message);
    }, [message]);

    const addNewEditorStateFromValue = (value) => {
      const newContent = ContentState.createFromText(getTagTranslation(value));

      setEditorState(EditorState.push(editorState, newContent, "insert-text"));
    };

    const tagRegex = `(${tags.map(({ name }) => `${name} ✕`).join("|")})`;

    const HANDLE_REGEX = new RegExp(tagRegex, "g");

    const handleStrategy = (contentBlock, callback, contentState) => {
      findWithRegex(HANDLE_REGEX, contentBlock, callback);
    };

    const findWithRegex = (regex, contentBlock, callback) => {
      const text = contentBlock.getText();
      let matchArr, start;
      while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
      }
    };

    const handleDeleteTag = ({
      offsetKey,
      blockKey,
      decoratedText,
      start,
      end,
      contentState,
    }) => {
      const raw = convertToRaw(contentState);

      const newRaw = {
        ...raw,
        blocks: raw.blocks.map((block) =>
          block.key === blockKey
            ? {
                ...block,
                text:
                  block.text.slice(0, start) +
                  block.text.slice(end, block.text.length),
              }
            : block
        ),
      };

      setEditorState(
        EditorState.push(editorState, convertFromRaw(newRaw), "remove-text")
      );
    };

    const HandleSpan = (props) => {
      return (
        <span
          data-offset-key={props.offsetKey}
          data-block-key={props.blockKey}
          data-decorated-text={props.decoratedText}
          contentEditable={false}
          onClick={(e) => canDeleteSkill && handleDeleteTag(props)}
          className="styledTags"
        >
          {props.children}
        </span>
      );
    };

    const decorator = new CompositeDecorator([
      {
        strategy: handleStrategy,
        component: HandleSpan,
      },
    ]);

    const [editorState, setEditorState] = useState(
      EditorState.createEmpty(decorator)
    );

    const handleChange = (newEditorState) => {
      const newTextValue = getReverseTagTranslation(
        newEditorState.getCurrentContent().getPlainText()
      );
      if (charLimits && newTextValue.length > 280) {
        setemailCharcterLimitFlag(true);
        setTimeout(() => {
          setemailCharcterLimitFlag(false);
        }, 500);
      }
      setEditorState(newEditorState);
      onChange(newTextValue);
    };

    const addTags = (newTag) => {
      // console.log("============addTags=============", newTag )
      const raw = convertToRaw(editorState.getCurrentContent());
      const focusKey = editorState.getSelection().getFocusKey();
      const focusOffset = editorState.getSelection().getFocusOffset();

      // console.log("============addTags=============", {
      //   raw,
      //   focusKey,
      //   focusOffset
      // })

      const newBlocks = raw.blocks.map((block) =>
        block.key === focusKey
          ? {
              ...block,
              text:
                block.text.slice(0, focusOffset) +
                getTagTranslation(newTag) +
                block.text.slice(focusOffset, block.text.length),
            }
          : block
      );

      const newRaw = {
        ...raw,
        blocks: newBlocks,
      };

      const newTextValue = getReverseTagTranslation(
        newBlocks
          .map((block) => (!block.text.trim() && "\n") || block.text)
          .join("\n")
      );

      onChange(newTextValue);

      setEditorState(
        EditorState.push(editorState, convertFromRaw(newRaw), "insert-text")
      );
    };

    return (
      <FormGroup disabled={disabled} className="styleableTextAreaContainer">
        <StyleableTextArea
          value={message || ""}
          onChange={handleChange}
          editorState={editorState}
        />
        {charLimits ? (
          <p
            className={
              message.length > 280
                ? "emailCharcterCounter emailCharcterCounterFlash"
                : "emailCharcterCounter"
            }
          >
            {`${emailCharcterCounter}/280`}
          </p>
        ) : (
          <div style={{ height: "15px", margin: "8px" }} />
        )}
        {shouldShowTagButtons && (
          <TagButtonComponent
            handleChange={addTags}
            styleableTags={true}
            skillsReq={skillsReq}
          />
        )}
      </FormGroup>
    );
  },
  (pp, np) => {
    return pp.message === np.message;
  }
);

export default withRouter(connect((state) => ({}))(StyleableTextAreaContainer));
