import * as ActionTypes from "../../actions/QuickSetup";

const initialState = {
  companyName: "",
  companySuggestions: [],
  companyHiringFor: {},
  isLoaded: false,
  isLoading: false,
  isCompanyProfileLoading: false,
  isCompanyProfileLoaded: false,
  isLoadedSuggestion: false,
  isLoadingSuggestion: false
};


const companyDetails = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_COMPANY_DETAILS: {
      return initialState;
    }
    case ActionTypes.CHANGE_JOB_COMPANY_NAME: {
      return {
        ...state,
        companyName: action.payload,
      };
    }
    case ActionTypes.CLEAR_JOB_COMPANY_SUGGESTION: {
      return {
        ...state,
        companySuggestions: [],
      };
    }
    case ActionTypes.FETCH_JOB_COMPANY_SUGGESTION: {
      return {
        ...state,
        isLoadedSuggestion: false,
        isLoadingSuggestion: true,
      };
    }
    case ActionTypes.FETCH_JOB_COMPANY_SUGGESTION_FAILURE: {
      return {
        ...state,
        isLoadedSuggestion: false,
        isLoadingSuggestion: false,
      };
    }
    case ActionTypes.FETCH_JOB_COMPANY_SUGGESTION_SUCCESS: {
      return {
        ...state,
        companySuggestions: action.payload,
        isLoadedSuggestion: true,
        isLoadingSuggestion: false,
      };
    }

    case ActionTypes.SELECT_COMPANY: {
      return {
        ...state,
        isCompanyProfileLoading: true,
        isCompanyProfileLoaded: false,
      };
    }
    case ActionTypes.SELECT_COMPANY_SUCCESS: {
      return {
        ...state,
        isCompanyProfileLoading: false,
        isCompanyProfileLoaded: true,
        companyHiringFor: action.payload,
      };
    }
    case ActionTypes.SELECT_COMPANY_FAILURE: {
      return {
        ...state,
        isCompanyProfileLoading: false,
        isCompanyProfileLoaded: false,
      };
    }
    case ActionTypes.JOB_COMPANY_PROFILE: {
      return {
        ...state,
        companyHiringFor: action.payload,
      };
    }
    case ActionTypes.JOB_COMPANY_PROFILE_DELETE: {
      return {
        ...state,
        companyHiringFor: {},
        similarCompanies: [],
        similarCompaniesFetched: false,
        isCompanyProfileLoading: false,
        isCompanyProfileLoaded: false,
      };
    }
    case ActionTypes.INIT_JOB_EDIT_SIMILAR_COMPANY: {
      return {
        ...state,
        similarCompanies: action.payload,
      };
    }
    // case ActionTypes.FETCH_JOB_SIMILAR_COMPANY:{
    //   return {
    //     ...state,
    //     similarCompaniesFetching: true,
    //     similarCompaniesFetched: false,
    //   };
    // }
    // case ActionTypes.FETCH_JOB_SIMILAR_COMPANY_FAILURE:{
    //   return {
    //     ...state,
    //     similarCompaniesFetching: false,
    //     similarCompaniesFetched: false,
    //   };
    // }
    // case ActionTypes.FETCH_JOB_SIMILAR_COMPANY_SUCCESS: {

    //   // console.log("==================FETCH_JOB_SIMILAR_COMPANY_SUCCESS====================", action.payload)
    //   const newCompanies = action.payload.similarComp
    //     .filter(
    //       (newcomp) =>
    //         newcomp.universalName !== state.companyHiringFor.universalName &&
    //         state.similarCompanies.every(
    //           (simComp) => newcomp.universalName !== simComp.universalName
    //         )
    //     )
    //     .slice(0, 5)
    //     .map((newComp) => ({
    //       ...newComp,
    //       liked: false,
    //       detailFetched: false,
    //       fetched: false,
    //     }));
    //   // console.log("==================newCompanies====================", newCompanies)
    //   const simpComp = [
    //     ...state.similarCompanies.reduce((prev, next) => {
    //       if (next.universalName === action.payload.universalName)
    //         return [
    //           ...prev,
    //           action.payload.universalName ? { ...next, fetched: true } : next,
    //           // ...newCompanies
    //         ];
    //       return [...prev, next];
    //     }, []),
    //     ...newCompanies,
    //   ];

    //   // console.log("==================simpComp====================", simpComp)
    //   return {
    //     ...state,
    //     similarCompanies: simpComp,
    //     similarCompaniesFetching: false,
    //     similarCompaniesFetched: true,
    //   };
    // }
    // case ActionTypes.ADD_SIMILAR_COMPANY_DETAILS: {
    //   return {
    //     ...state,
    //     addingSimilarCompany: true,
    //     addedSimilarCompany: false,
    //   };
    // }
    // case ActionTypes.ADD_SIMILAR_COMPANY_DETAILS_FAILURE: {
    //   return {
    //     ...state,
    //     addingSimilarCompany: false,
    //     addedSimilarCompany: false,
    //   };
    // }
    // case ActionTypes.ADD_SIMILAR_COMPANY_DETAILS_SUCCESS: {
    //   return {
    //     ...state,
    //     addingSimilarCompany: false,
    //     addedSimilarCompany: true,
    //     similarCompanies: [...state.similarCompanies, action.payload],
    //   };
    // }
    // case ActionTypes.FETCH_SIMILAR_COMPANY_DETAILS: {
    //   return {
    //     ...state,
    //     similarCompanies: state.similarCompanies.map(details=>(details.universalName === action.payload ? {...details, detailsFetching: true} : details)),
    //     isLoadingSimilarCompanyDetails: true,
    //     isLoadedSimilarCompanyDetails: false,
    //   };
    // }
    // case ActionTypes.FETCH_SIMILAR_COMPANY_DETAILS_SUCCESS: {
    //   return {
    //     ...state,
    //     similarCompanies: state.similarCompanies.map((item) =>
    //       item.universalName === action.payload.universalName
    //         ? { ...action.payload, ...item, detailFetched: true, detailsFetching: false }
    //         : item
    //     ),
    //     isLoadingSimilarCompanyDetails: false,
    //     isLoadedSimilarCompanyDetails: true,
    //   };
    // }
    // case ActionTypes.FETCH_SIMILAR_COMPANY_DETAILS_FAILURE: {
    //   return {
    //     ...state,
    //     similarCompanies: state.similarCompanies.map(details=>(details.universalName === action.payload ? {...details, detailsFetching: false} : details)),
    //     isLoadingSimilarCompanyDetails: false,
    //     isLoadedSimilarCompanyDetails: false,
    //   };
    // }

    // case ActionTypes.FETCH_COMPANY_STAFF_COUNT: {
    //   return {
    //     ...state,
    //     isLoaded: false,
    //     isLoading: true,
    //   };
    // }
    // case ActionTypes.FETCH_COMPANY_STAFF_COUNT_SUCCESS: {
    //   return {
    //     ...state,
    //     companyProfileDetails: action.payload,
    //     //similarComapaniesOnclickProfile: action.payload,
    //     isLoaded: true,
    //     isLoading: false,
    //   };
    // }
    // case ActionTypes.FETCH_COMPANY_SUGGESTION_PROFILE_SUCCESS: {
    //   return {
    //     ...state,
    //     similarComapaniesOnclickProfile: action.payload,
    //     isLoadingSimilarCompaniesOnclickProfile: false,
    //     isLoadedSimilarCompaniesOnclickProfile: true,
    //   };
    // }
    // case ActionTypes.FETCH_COMPANY_SUGGESTION_PROFILE: {
    //   return {
    //     ...state,
    //     isLoadingSimilarCompaniesOnclickProfile: true,
    //     isLoadedSimilarCompaniesOnclickProfile: false,
    //   };
    // }
    // case ActionTypes.SIMILAR_COMPANY_THUMBS_DOWN_HANDLER: {
    //   const thumbsDownCompany = state.similarCompanies.filter(
    //     (item) => item.universalName === action.payload
    //   );
    //   // console.log(
    //   //   "--------------thumbsDownCompany-----------",
    //   //   thumbsDownCompany
    //   // );
    //   return {
    //     ...state,
    //     similarCompanies: state.similarCompanies.filter(
    //       (item) => item.universalName !== action.payload
    //     ),
    //     unLikedSimilarCompanies: [
    //       ...state.unLikedSimilarCompanies,
    //       ...thumbsDownCompany,
    //     ],
    //   };
    // }
    // case ActionTypes.SIMILAR_COMPANY_THUMBS_UP_HANDLER: {
    //   const thumbsUpCompanies = state.similarCompanies.map((item) =>
    //     item.universalName === action.payload
    //       ? { ...item, liked: !item?.liked }
    //       : item
    //   );

    //   return {
    //     ...state,
    //     similarCompanies: thumbsUpCompanies,
    //   };
    // }
    // case ActionTypes.ADD_TO_SIMILAR_COMPANIES_FROM_LIKED: {
    //   return {
    //     ...state,
    //     similarCompanies: [...state.similarCompanies, action.payload],
    //     likedSimilarCompanies: state.likedSimilarCompanies.filter(
    //       (item) => item.universalName !== action.payload.universalName
    //     ),
    //   };
    // }
    // case ActionTypes.ADD_TO_SIMILAR_COMPANIES_FROM_UNLIKED: {
    //   return {
    //     ...state,
    //     similarCompanies: [...state.similarCompanies, action.payload],
    //     unLikedSimilarCompanies: state.unLikedSimilarCompanies.filter(
    //       (item) => item.universalName !== action.payload.universalName
    //     ),
    //   };
    // }
    // case ActionTypes.ADD_TO_UNLIKED_COMPANIES_FROM_LIKED: {
    //   return {
    //     ...state,
    //     unLikedSimilarCompanies: [
    //       ...state.unLikedSimilarCompanies,
    //       action.payload,
    //     ],
    //     likedSimilarCompanies: state.likedSimilarCompanies.filter(
    //       (item) => item.universalName !== action.payload.universalName
    //     ),
    //   };
    // }
    // case ActionTypes.ADD_TO_LIKED_COMPANIES_FROM_UNLIKED: {
    //   return {
    //     ...state,
    //     likedSimilarCompanies: [...state.likedSimilarCompanies, action.payload],
    //     unLikedSimilarCompanies: state.unLikedSimilarCompanies.filter(
    //       (item) => item.universalName !== action.payload.universalName
    //     ),
    //   };
    // }
    // case ActionTypes.ADD_MORE_JOB_COMPANY_TO_SIMILAR_COMPANIES: {
    //   return {
    //     ...state,
    //     similarCompanies: [...state.similarCompanies, action.payload],
    //   };
    // }
    default:
      return state;
  }
};

export default companyDetails;
