import React from "react";
import { useSelector } from "react-redux";
import { getIsViewOnly } from "../../../reducers/QuickSetup/calibrationProfiles";
import SuperLike from "../../../uiLibrary/SuperLike";

export default function Tag({
  children,
  handleLike = () => {},
  value,
  isLiked = false,
}) {
  const isViewOnly = useSelector((state) =>
    getIsViewOnly(state.QuickSetup.calibrationProfiles)
  );
  const onLike = (e) => {
    e.stopPropagation();
    handleLike(value);
  };
  const [hover, setHover] = React.useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "7px" }}>
      <span
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        onClick={onLike}
        style={{
          borderColor: isLiked ? "#E63B7F" : "#454545",
          color: isLiked && "#E63B7F",
          pointerEvents: isViewOnly ? "none" : "all",
        }}
        className="tag-element"
      >
        {children}
      </span>
      {(hover || isLiked) && (
        <SuperLike
          toggleLike={() => {}}
          likedHearts={isLiked}
          totalHearts={1}
        />
      )}
    </div>
  );
}
