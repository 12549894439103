import * as ActionTypes from '../../actions/meta'

const initialState = {
  isQluInstalled: !(Date.now() - localStorage.getItem('qlu_install_time') > 2 * 60 * 60 * 1000)
}

const meta = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_QLU_EXT_INSTALL_STATUS:
      return { ...state, isQluInstalled: action.payload }
    default:
      return state
  }
}

export default meta