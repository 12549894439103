// import { Button } from 'reactstrap'
import React from "react";
import TabsComponent from "../../uiLibrary/TabsComponent/TabsComponent";
import ScoreAndConnectHeader from "../../uiLibrary/ScoreAndConnectHeader/ScoreAndConnectHeader";
import JobCampaigns from "../../components/JobCampaigns";
import "./ProspectLayout.scss";
import CommentsSection from "../../components/CommentsSection/CommentsSection";
import { connect } from "react-redux";
import Tribes from "../../uiLibrary/Tribes";
// import JobEdit from "../../uiLibrary/JobEdit";
const ProspectLayout = ({
  showFullCard,
  children,
  filterCount,
  handleClearFilters,
  handleFilterToggle,
  handleSortToggle,
  funnel,
  buckets,
  currentBucket,
  onClickBucket,
  folders,
  topMin,
  topMax,
  onSelectAll,
  onDeSelectAll,
  isAllSelected,
  showActions,
  onSelectApply,
  onClickSave,
  isSavingProspect,
  onClickArchive,
  isArchivingProspect,
  isDownloadingProspect,
  jobTitle,
  commentsExpanded,
  onClickComments,
  selectedProspectComments,
  activeProspectVariant,
  currentTab,
  recommended,
  recommendedCount,
  setShowMoreProspect,
  _fetchProspects,
  applyInitialFilterSort,
}) => {
  return (
    <div className={`prospectLayout `}>
      <ScoreAndConnectHeader funnel={funnel} jobTitle={jobTitle} />
      {currentTab === "Prospects" ? (
        <>
          <TabsComponent
            showFullCard={showFullCard}
            applyInitialFilterSort={applyInitialFilterSort}
            filterCount={filterCount}
            setShowMoreProspect={setShowMoreProspect}
            handleClearFilters={handleClearFilters}
            handleFilterToggle={handleFilterToggle}
            handleSortToggle={handleSortToggle}
            isAllSelected={isAllSelected}
            topMin={topMin}
            topMax={topMax}
            _fetchProspects={_fetchProspects}
            onSelectAll={onSelectAll}
            onDeSelectAll={onDeSelectAll}
            onSelectApply={onSelectApply}
            showActions={showActions}
            buckets={buckets}
            onClickBucket={onClickBucket}
            currentBucket={currentBucket}
            folders={folders}
            onClickSave={onClickSave}
            isSavingProspect={isSavingProspect}
            onClickArchive={onClickArchive}
            isArchivingProspect={isArchivingProspect}
            isDownloadingProspect={isDownloadingProspect}
            commentsExpanded={commentsExpanded}
            onClickComments={onClickComments}
            recommended={recommended}
            selectedProspectComments={selectedProspectComments}
            activeProspectVariant={activeProspectVariant}
            recommendedCount={recommendedCount}
          >
            {children}
          </TabsComponent>
          {/* <div className="commentContainer">
            <div className={`comments ${commentsExpanded ? "show" : "hide"}`}>
              <CommentsSection
                shouldShow={commentsExpanded}
                clickedProspectsDetails={selectedProspectComments}
                onClickComments={onClickComments}
              />
            </div>
          </div> */}
        </>
      ) : currentTab === "My Tribe" ? (
        <Tribes />
      ) : currentTab === "Campaigns" ? (
        <JobCampaigns />
      ) : null}
    </div>
  );
};

export default connect((state) => ({
  currentTab: state.prospectsView.subDropDownReducer.currentTab,
  recommended: state.prospectsView.prospectVariant.recommended,
  activeProspectVariant:
    state.prospectsView.prospectVariant.activeProspectVariant,
  recommendedCount: state.prospectsView.candidates.recommendedCount,
}))(ProspectLayout);
