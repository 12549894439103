import React from "react";
import RangeInput from "../RangeInput";
import { connect } from "react-redux";
import "./style.scss";
const DiscreteRangeSlider = ({
  discreteSteps = [],
  value,
  onChange = (e) => false,
  label,
  getLabel = false,
  resultLabel,
  disabled = false,
  isPrimaryButtonLoading,
}) => {
  const maxDiscrete = discreteSteps.length - 1;
  const discreteEncode = {};
  const disctereDecode = {};

  discreteSteps.map((stepValue, index) => {
    discreteEncode[stepValue] = index;
    disctereDecode[index] = stepValue;
  });

  const handleChange = (value) => {
    if (disabled) {
      return false;
    }
    onChange({
      min: disctereDecode[value?.min],
      max: disctereDecode[value?.max],
    });
  };

  const setLabel = (labelValue) =>
    getLabel
      ? getLabel(disctereDecode[labelValue])
      : disctereDecode[labelValue];

  return (
    <RangeInput
      // className='discreteRangeSlider'
      disabled={isPrimaryButtonLoading ? true : disabled}
      maxValue={maxDiscrete}
      staticMax={true}
      value={{
        min: discreteEncode[value?.min],
        max: discreteEncode[value?.max],
      }}
      onChange={handleChange}
      getLabel={setLabel}
      resultLabel={resultLabel}
    />
  );
};
export default connect((state) => ({
  isPrimaryButtonLoading: state.primaryButtonloading.isPrimaryButtonLoading,
}))(DiscreteRangeSlider);
