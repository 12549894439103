import React from "react";

const CaretDown = ({
  style = {},
  selected = false,
  className = "",
  strokeWidth = "3",
  width = "12",
  height = "6",
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={`qluSvgStroke${selected ? "Selected" : ""} ${className}`}
        d="M1 1L7 7L13 1"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CaretDown;
