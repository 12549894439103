import React from 'react'
import SecondaryButton from '../../../uiLibrary/SecondaryButton'

const BlankSlate = ({
  label = 'notfound',
  ctaText = 'action',
  onClickCta
}) => {
  return (
    <div className='blankSlateRoot'>
      <div>
        <p>{label}</p>
        <SecondaryButton
          onClick={onClickCta}
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
            height: '36px',
            margin: 'auto'
          }}
        >
          {ctaText}
        </SecondaryButton>
      </div>
    </div>
  )
}

export default BlankSlate