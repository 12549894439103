import React, { useState } from "react";
import { Button } from 'reactstrap'
import { getHTMLTagTranslation, getReverseTranslation } from "../../utils";
import LabelButton from "../LabelButton";

import './style.scss'

const TagButtonComponent = ({
  handleChange,
  tagType,
  message,
  skillsReq,
  cursorPos,
  styleableTags = false
}) => {

  const [tagTab, setTagTab] = useState("Prospect Info")

  // const mustHaves = skillsReq
  //   .filter(skl => skl.includes("Must Have"))
  //   .map(skl => skl.slice(0, -11))


  const TagButton = ({ cursorPos, name, skillFlag = false }) => {

    const tagName = skillFlag ? name : name.split(" ").join("_")

    const handleClick = (e) => {
      e.preventDefault()
      // console.log("=========handleClick==============", tagName)
      if (styleableTags && !skillFlag) {
        handleChange(`{{.${tagName}}}`)
        // console.log("=========handleClick==============styleableTags", styleableTags)
      }
      else if(skillFlag){
        handleChange(tagName)
      }
      else {
        const value = message.slice(0, cursorPos) +
          `${skillFlag ? tagName : `{{.${tagName}}}`}` +
          message.slice(cursorPos)

        // console.log("=========handleClick==============message", message)

        handleChange(value)
      }
    }


    return (
      <button outline
        color='primary'
        className="tags"
        onMouseDown={handleClick}>
        {name} +
      </button>
    )
  }

  return (
    <React.Fragment>
      <div className="tagTabsContainer" >
        <LabelButton
          className={tagTab === "Prospect Info" ? "tagTabsSelected" : ""}
          onClick={(e) => { setTagTab("Prospect Info") }}>
          Prospect Info
        </LabelButton>
        <LabelButton
          className={tagTab === "Job Info" ? "tagTabsSelected" : ""}
          onClick={(e) => { setTagTab("Job Info") }}>
          Job Info
        </LabelButton>
        <LabelButton
          className={tagTab === "Must Have Skills" ? "tagTabsSelected" : ""}
          onClick={(e) => { setTagTab("Must Have Skills") }}>
          Must Have Skills
        </LabelButton>
        <LabelButton
          className={tagTab === "My Info" ? "tagTabsSelected" : ""}
          onClick={(e) => { setTagTab("My Info") }}>
          My Info
        </LabelButton>
        {/* <Button 
          outline 
          color='secondary' 
          className="tagTabs" 
          onClick={(e)=>{setTagTab("Meeting Info")}}>
          Meeting Info
        </Button> */}
      </div>
      <div className="tagButtonsContainer">

        {tagTab === "Prospect Info" &&
          <React.Fragment>

            <TagButton cursorPos={cursorPos} name={"First Name"} />

            <TagButton cursorPos={cursorPos} name={"Last Name"} />

            <TagButton cursorPos={cursorPos} name={"Full Name"} />

            <TagButton cursorPos={cursorPos} name={"School Name"} />

            <TagButton cursorPos={cursorPos} name={"Current Company"} />

            <TagButton cursorPos={cursorPos} name={"Current Title"} />

          </React.Fragment>}

        {tagTab === "Job Info" &&
          <React.Fragment>

            <TagButton cursorPos={cursorPos} name={"Job Title"} />

          </React.Fragment>}

        {tagTab === "Must Have Skills" &&
          <React.Fragment>

            {skillsReq.map((skl, idx) => <TagButton key={idx} cursorPos={cursorPos} name={skl} skillFlag={true} />)}

          </React.Fragment>}

        {tagTab === "My Info" &&
          <React.Fragment>

            <TagButton cursorPos={cursorPos} name={"My First Name"} />

            <TagButton cursorPos={cursorPos} name={"My Full Name"} />

            <TagButton cursorPos={cursorPos} name={"Hiring Company"} />

          </React.Fragment>}

        {tagTab === "Meeting Info" &&
          <React.Fragment>

            <TagButton cursorPos={cursorPos} name={"Meeting Link"} />

          </React.Fragment>}

      </div>
    </React.Fragment>
  )

}

export default TagButtonComponent
