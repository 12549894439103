import * as ActionTypes from '../../../actions/landingPage/home'

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: []
}

const campaigns = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CAMPAIGNS_HOME:
      return { ...state, isLoading: true }

    case ActionTypes.FETCH_CAMPAIGNS_HOME_SUCCESS:
      return { ...state, isLoading: false, isDataAvailable: action?.payload?.data?.campaigns?.length > 0, data: action.payload.data }

    case ActionTypes.FETCH_CAMPAIGNS_HOME_FAILURE:
      return initialState

    default:
      return state
  }
}

export { campaigns }