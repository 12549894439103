import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import NotificationItem from '../../../components/NotificationItem'
import SectionHeader from '../../../components/SectionHeader'
import { getAllNotifications, setNotificationRead } from '../../../actions/notifications'

const NotificationPanel = ({
  notifications = {
    notifications: [],
    pageNo: 1
  },
  isTribeScreen = false,
  dispatch
}) => {

  useEffect(() => {
    dispatch(getAllNotifications(notifications?.pageNo))
    
    const polling = setInterval(() => dispatch(getAllNotifications(notifications?.pageNo)), 1 * 60 * 1000 );

    return () => {
      clearInterval(polling);
    };
  }, [])

  useEffect(() => {
    // console.log('notifications ====================>', parseInt(notifications?.totalCount) % ((parseInt(notifications?.pageNo)) * 10))
  }, [notifications])

  notifications.notifications = notifications?.notifications?.filter((notification) => notification.is_read === false)

  return (
    <div>
      { isTribeScreen ? '' :
        <SectionHeader
        label='Notifications'
        onClickViewAll={() => { }}
        showActions={false}
        />
      }
      { isTribeScreen ? notifications?.notifications?.length === 0 ? isTribeScreen ? <p style={{marginTop: 'calc(50vh - 150px)', color: 'grey'}}>No comments</p> : 'No new notifications' : '' : '' }
      <div style={{ marginTop: '32px' }}>
        {
          notifications?.notifications?.map((notification, key) => <div style={{marginBottom: '20px'}}><NotificationItem key={key} notification={notification} /></div>)
        }
      </div>
    </div>
  )
}

export default connect(state => ({
  notifications: state.notifications?.notifications?.notifications
}))(NotificationPanel)