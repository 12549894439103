import React from "react";
import CrossSvg from "../../uiLibrary/SVGs/CrossSvg";
import "./closeButton.scss";

const CloseButton = ({
  onClick = () => console.log("close button clicked"),
  style={},
  strokeWidth,
  width,
  height,
  disabled=false,
}) => {
  return (
    <button disabled={disabled} style={style} onClick={onClick} className="closeButton">
      <CrossSvg strokeWidth={strokeWidth} width={width} height={height}/>
    </button>
  );
};

export default CloseButton;
