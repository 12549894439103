import * as ActionTypes from '../../actions/home'

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: [],
  count: null
}

const home = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_JOBS:
      return { ...state, isLoading: true }

    case ActionTypes.FETCH_JOBS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data.jobs,
        isDataAvailable: action.payload.data.jobs.length > 0,
        count: parseInt(action.payload.data.count)
      }

    case ActionTypes.FETCH_JOBS_FAILURE:
      return initialState
    default:
      return state
  }
}

export default home