import React from "react";
import ChartCard from "../../../components/ChartCard";
import Loading from "../../../uiLibrary/Loading";

const getTransformedChartData = (chartData, key) => {
  return chartData?.map((item) => ({
    date: item.date,
    point: item[key],
  }));
};

const Stats = ({ isLoading, data: { elements, chart } }) => {
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {elements?.map(({ label, percentage, key }, index) => (
            <ChartCard
              key={index}
              label={label}
              stat={percentage || "N/A"}
              chart={getTransformedChartData(chart, key)}
            />
          ))}
        </>
      )}
    </>
  );
};

export default Stats;
