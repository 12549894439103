import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import {
  createTribeMember,
  getMemberList, setTribesUpdate
} from "../../actions/prospectsView";
import "./Tribes.scss";
import TribesCard from "./TribesCard/TribesCard";
import TribesCommentSection from "./TribesCommentSection/TribesCommentSection";
import TribesHeader from "./TribesHeader/TribesHeader";

function Tribes({
  tribeMembers,
  permissions,
  isPermissionsChanged,
  suggestionMembersList,
  isLoading,
  tribeId,
  permissionsAllowed,
  dispatch,
  jobId,
}) {
  const [show, setShow] = React.useState(false);
  const tribeCards = [];
  tribeMembers?.map((member) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
    let initials = ["INV"];
    let isInvited = false;
    if (member.tribeUser.tribeUserName) {
      initials = [...member.tribeUser.tribeUserName.matchAll(rgx)] || [];

      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();
    } else {
      member.tribeUser.tribeUserName = member.tribeUser.tribeUserEmail;
      member.tribeUser.tribeUserEmail = "Invited";
      isInvited = true;
    }
    tribeCards?.push({
      name: member.tribeUser.tribeUserName,
      email: member.tribeUser.tribeUserEmail,
      isOwner: member.isOwner,
      userPermissions: member.permissionNames,
      avatar: initials,
      isInvited,
    });
  });

  const toggleCheck = (permission, tribe, currentState) => {
    let oldState = JSON.parse(JSON.stringify(tribeMembers));
    let tribeIndex = tribeMembers.findIndex(
      (member) => member.tribeUser.tribeUserName === tribe.name
    );
    let allPermissions = permissions.filter(
      (permission) => permission.label !== null
    );
    let selectedPermission = allPermissions.find(
      (item) => item.name === permission.name
    );

    let permissionFoundIndex = tribeMembers[
      tribeIndex
    ].permissionNames.findIndex((item) => item === selectedPermission.name);

    // if (tribeMembers[tribeIndex].isOwner == true) {
    if (permissionFoundIndex > -1) {
      tribeMembers[tribeIndex].permissionNames.splice(
        permissionFoundIndex,
        1
      );
    } else {
      tribeMembers[tribeIndex].permissionNames.push(permission.name);
    }

    dispatch(
      setTribesUpdate(
        tribeMembers,
        tribeMembers[tribeIndex],
        selectedPermission.id,
        oldState,
        'update',
        jobId
      )
    );
    // }
  };

  const onDeleteTribeMember = (tribe) => {
    let oldState = JSON.parse(JSON.stringify(tribeMembers));
    let tribeIndex = tribeMembers.findIndex(
      (member) => member.tribeUser.tribeUserName === tribe.name
    );
    let deletedTribeMember;

    if (tribeIndex > -1) {
      deletedTribeMember = tribeMembers[tribeIndex];
      tribeMembers.splice(tribeIndex, 1);
    }

    dispatch(
      setTribesUpdate(
        tribeMembers,
        deletedTribeMember,
        null,
        oldState,
        "delete",
        jobId
      )
    );
  };

  const [showInput, setShowInput] = useState(false);
  const [membersTextBox, setMembersTextBox] = useState("");

  const handleInputChange = (e) => {
    setMembersTextBox(e.target.value);
  };

  const handleInputSubmit = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      // if (tribeId)
      dispatch(createTribeMember(email, tribeId ? tribeId : tribeMembers.length && tribeMembers[0]?.tribeId ? tribeMembers[0]?.tribeId : null));
      setShowInput(false);
      setMembersTextBox("");
      // }
    } else {
    }
  };

  useEffect(() => { }, [isPermissionsChanged, isLoading]);

  useEffect(() => {
    dispatch(getMemberList());
  }, []);

  return (
    <div className="tribesContainer">
      <TabContent className={`tribesContent ${show ? "expanded" : ""}`}>
        <TabPane>
          <TribesHeader
            handleInputSubmit={handleInputSubmit}
            handleInputChange={handleInputChange}
            showInput={showInput}
            setShowInput={setShowInput}
            membersTextBox={membersTextBox}
            setMembersTextBox={setMembersTextBox}
            suggestionMembersList={suggestionMembersList}
            permissionsAllowed={permissionsAllowed}
          />

          <div className="tribesCardContainer">
            {
              tribeCards?.length > 0 ? '' : <p className="noMembers">No members added</p>
            }
            {tribeCards?.map((tribeCard, index) => {
              const {
                avatar,
                name,
                email,
                userPermissions,
                isOwner,
                isInvited,
              } = tribeCard;
              return (
                <TribesCard
                  key={index}
                  avatar={avatar}
                  name={name}
                  email={email}
                  isInvited={isInvited}
                  permissions={permissions}
                  userPermissions={userPermissions}
                  isOwner={isOwner}
                  tribe={tribeCard}
                  toggleCheck={toggleCheck}
                  onDeleteTribeMember={onDeleteTribeMember}
                  permissionsAllowed={permissionsAllowed}
                  onClickComment={() => setShow(!show)}
                />
              );
            })}
            {isLoading ? (
              <Loader width="62" height="62" type="TailSpin" color="#FF8D4E" />
            ) : (
              ""
            )}
          </div>
        </TabPane>
      </TabContent>
      {permissionsAllowed === '*' || permissionsAllowed?.length && permissionsAllowed?.length > 0 && (permissionsAllowed?.includes('VIEW_TRIBE_ACTIVITY')) ?
        <TribesCommentSection shouldShow={show} /> : ''
      }
    </div>
  );
}

export default connect((state) => {
  return {
    tribeMembers: state.prospectsView.tribeMembers.tribeMembers.tribeMembers,
    isLoading: state.prospectsView.tribeMembers.isLoading,
    permissions: state.prospectsView.tribeMembers.tribeMembers.permissions,
    isPermissionsChanged: state.prospectsView.tribeMembers.isPermissionsChanged,
    suggestionMembersList:
      state.prospectsView.tribeMembers.suggestionMembersList,
    tribeId: state.prospectsView.candidates.job.tribeId,
    permissionsAllowed: state.prospectsView.candidates.permissions,
    jobId: state.prospectsView.candidates.job.id,
  };
})(withRouter(Tribes));
