import { combineReducers } from 'redux'
import viewController from './viewController'
import jobDetails from './jobDetails'
import jobLocation from './jobLocation'
import companyDetails from './companyDetails'
import jobSpecialities from './jobSpecialities'
import jobCriteria from './jobCriteria'
import jobIdealProfile from './jobIdealProfile'
import tribeMembers from './tribeMembers'
import jobSetup from './jobSetup'

export default combineReducers({
    jobCriteria,
    jobSpecialities,
    jobLocation,
    jobDetails,
    viewController,
    companyDetails,
    jobIdealProfile,
    tribeMembers,
    jobSetup,
})