import React from 'react'
import './style.scss'

import magic from '../../../img/magic.svg'
import { connect } from 'react-redux'

const GeneratedCard = ({
    message
}) => {
    return (
        <div
            className='generatedCard'
        >
            <div>
                <img
                    src={magic}
                />
                <h3>
                    Generated
                </h3>
            </div>
            <p>
                {message}
            </p>
        </div>
    )
}

export default GeneratedCard
