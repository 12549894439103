import React from 'react'
import Loader from 'react-loader-spinner'
import ChartCard from '../../../components/ChartCard'


const getTransformedChartData = (chartData, key) => {
  return chartData?.map(item => ({ date: item.date, point: item[key] }))
}

const Stats = ({
  isLoading,
  isDataAvailable,
  data
}) => {
  return (
    <>
      {!isLoading && isDataAvailable &&
        (data?.messagedCount && parseFloat(data?.messagedCount) !== 0 ||
          data?.crAcceptanceRate && parseFloat(data?.crAcceptanceRate) !== 0 ||
          data?.conversionRate && parseFloat(data?.conversionRate) !== 0) &&
        <>
          {isLoading && <div className='dflex-cc' style={{ height: '120px', width: '100%' }}><Loader type='TailSpin' color='#FF8D4E' width={36} height={36} /></div>}
          {!isLoading && isDataAvailable &&
            (parseFloat(data?.messagedCount) !== 0 ||
              parseFloat(data?.crAcceptanceRate) !== 0 ||
              parseFloat(data?.conversionRate) !== 0) &&
            <>
              <ChartCard label='prospects messaged' stat={data?.messagedCount || 'N/A'} chart={getTransformedChartData(data?.chart, 'messaged')} />
              <ChartCard label='conversion' stat={`${data?.conversionRate ? `${data?.conversionRate}%` : 'N/A'}`} chart={getTransformedChartData(data?.chart, 'replied')} />
              <ChartCard label='li acceptance rate' stat={`${data?.crAcceptanceRate ? `${data.crAcceptanceRate}%` : 'N/A'}`} chart={getTransformedChartData(data?.chart, 'interested')} />
            </>
          }
        </>
      }

    </>
  )
}

export default Stats