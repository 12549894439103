import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  changeGenericTitle,
  changeMainJobTitle,
  fetchMainTitleSuggestions,
  initialiseTitles,
  initTitleResult,
  setIsEdited,
  setJobSetupView,
  updateJobTitleResults,
} from "../../../actions/JobSetup";
import DiscreteRangeSlider from "../../../uiLibrary/DiscreteRangeSlider";
import Loading from "../../../uiLibrary/Loading";
import PillButton from "../../../uiLibrary/PillButton";
import InfoSvg from "../../../uiLibrary/SVGs/InfoSvg";
import JobSetupStep05 from "../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep05";
import { kiloConversion, numberWithCommas } from "../../../utils";
import { Mixpanel } from "../../../utils/Mixpanel";
import JobSetupAutoSuggest from "../JobSetupAutoSuggest";
import "./criteriaMainTitle.scss";

const MainTitleInput = ({
  name = "",
  handleChangeInput = () => false,
  handleSuggestionSelected = () => false,
  shouldFetch,
}) => {
  return (
    <JobSetupAutoSuggest
      apiType="title"
      filter={(d) => name?.trim()?.toLowerCase() !== d.trim().toLowerCase()}
      inputValue={name}
      shouldFetch={shouldFetch}
      handleChangeInput={handleChangeInput}
      handleSuggestionSelected={handleSuggestionSelected}
    />
  );
};

const CriteriaMainTitle = ({
  job_position,
  titleInitialising,
  titleInitialised,
  highlightValue,
  // titles,
  mainTitle,
  cards,
  isReview,
  mainTitleResult,
  mainTitleResultLoaded,
  mainTitleResultLoading,
  mainTitleGenericResult,
  mainTitleGenericResultLoaded,
  mainTitleGenericResultLoading,
  maintitleSuggestions = [],
  // mainTitleKey,
  dispatch,
}) => {
  // const mainTitleKey = Object.keys(titles).filter((key) => titles[key].isMainTitle)?.[0]
  // const mainTitle = titles[mainTitleKey] || {};
  // const mainTitlePredicting = titles[mainTitleKey]?.isPredicting;

  const [state, setstate] = useState("initialState");

  const [myTimeout, setMyTimeout] = useState(null);
  const [highlightKey, setHighlightKey] = useState("");
  const [suggestionInput, setSuggestionInput] = useState(mainTitle?.name || "");
  const [suggestionsArray, setSuggestionsArray] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const companySizeOptions = [0, 1, 10, 50, 200, 500, 1000, 5000, 10000, 10001];

  useEffect(() => {
    Mixpanel.track("Job Title");
    dispatch(
      setJobSetupView({
        highlightValue: "",
        selectedValues: [],
      })
    );
    dispatch(initialiseTitles());
    dispatch(fetchMainTitleSuggestions());
  }, []);

  useEffect(() => {
    mainTitleResult !== 0 && setInitialLoad(false);
  }, [mainTitleResult]);

  // useEffect(() => {
  //     if(titleInitialised){
  //         Object.keys(titles).forEach(key => {
  //             dispatch(predictTitleResult(key))
  //         });
  //     }
  // }, [titleInitialised])

  // useEffect(() => {
  //     if(Object.keys(titles).length>0){
  //         dispatch(setJobSetupView({
  //             selectedValues: [mainTitle],
  //         }))
  //     }
  //     if(!titleInitialising && typeof titles?.[Object.keys(titles)?.[0]]?.name === 'string' && titles?.[Object.keys(titles)?.[0]]?.name !==''){
  //         if (myTimeout) {
  //             clearTimeout(myTimeout)
  //             setMyTimeout(setTimeout(timeoutHandler, 800))
  //         } else {
  //             setMyTimeout(setTimeout(timeoutHandler, 800))
  //         }
  //     }
  // }, [titles?.[Object.keys(titles)?.[0]]?.name])

  useEffect(() => {
    if (highlightValue !== "") {
      dispatch(
        setJobSetupView({
          highlightValue: (mainTitle?.name || "").trim().toLowerCase(),
        })
      );
    }
    if (mainTitle?.name !== "") {
      dispatch(
        setJobSetupView({
          selectedValues: [mainTitle],
        })
      );
    }
    if (myTimeout) {
      clearTimeout(myTimeout);
      setMyTimeout(setTimeout(timeoutHandler, 800));
    } else {
      setMyTimeout(setTimeout(timeoutHandler, 800));
    }
  }, [mainTitle?.name]);

  // const getTitleQuality = (key) => 1000 < (titles[key]?.predictedResult || 0);

  // const getTitleResultOutCome = (key) => {
  //   if (getTitleQuality(key)) {
  //     return "Good Title";
  //   }
  //   return "Bad Title";
  // };

  const handleChangeCompanySize = (value = [], key) => {
    dispatch(
      changeMainJobTitle({
        company_size: value,
      })
    );
    dispatch(setIsEdited(true))
  };

  const getSizeLabel = (min, max) => {
    if (max) {
      return `${min}-${max}`;
    }
    return "10,001+";
  };

  const timeoutHandler = (title) => {
    if (mainTitle.name !== "") {
      dispatch(updateJobTitleResults(mainTitle));
      // dispatch(updateJobTitleGenericResults(mainTitle))
    } else {
      dispatch(initTitleResult());
    }
  };

  const handleNameChange = async (name, key, flag = true) => {
    dispatch(
      changeMainJobTitle({
        name,
        spans: [],
      })
    );
    dispatch(fetchMainTitleSuggestions());
    dispatch(changeGenericTitle());
    dispatch(setIsEdited(true))
  };

  // const handleNameSelect = (name) => {
  //   dispatch(
  //     changeJobTitle({
  //       key: mainTitleKey,
  //       value: {
  //         name,
  //         spans: [],
  //       },
  //     })
  //   );
  // };

  return (
    <div className="criteriaMainTitle">
      <div className="criteriaMainTitleHeader">
        <JobSetupStep05 selected={true} />
        <h2>Job Title</h2>
      </div>
      <div className="criteriaMainTitleBody">
        <span>
          <JobSetupAutoSuggest
            apiType="title"
            filter={(d) =>
              mainTitle?.name?.trim()?.toLowerCase() !== d.trim().toLowerCase()
            }
            inputValue={mainTitle?.name}
            shouldFetch={shouldFetch}
            handleChangeInput={(e) => {
              handleNameChange(e.target.value);
              setShouldFetch(true);
            }}
            handleSuggestionSelected={(value) => {
              handleNameChange(value);
              setShouldFetch(false);
            }}
          />
          {isReview === "1" ? (
            <></>
          ) : (
            <>
              {mainTitleResultLoading || titleInitialising ? (
                <Loading width="24" height="24" />
              ) : mainTitleResult !== -1 &&
                mainTitle.name !== "" &&
                !initialLoad ? (
                <>
                  {!mainTitle?.generic ? (
                    mainTitleResult > 100 ? (
                      <p className="goodTitle">Good title</p>
                    ) : (
                      <p className="badTitle">Bad title</p>
                    )
                  ) : null}
                  <p className="titleResult">
                    {`${numberWithCommas(mainTitleResult || 0)} results`}
                  </p>
                </>
              ) : null}
            </>
          )}
        </span>
      </div>
      {mainTitle?.generic ? (
        <div>
          <div className="genericTitle" style={{ display: "flex" }}>
            <InfoSvg Recommended={true} width="32" height="32" />
            <h6
              style={{
                lineHeight: "23px",
                marginLeft: "8px",
                marginBottom: "0",
                color: "#A0A0A0",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              The title is too vague, which may return low quality profiles.
              Please add a more specific title for accurate results.
            </h6>
          </div>
        </div>
      ) : null}
      {/* <div>
        {mainTitle?.generic ? <div className="genericTitle" style={{ display: 'flex' }} >
          <InfoSvg 
          Recommended={true}
          width='32'
          height='32' />
          <h6 style={{ lineHeight: '23px', marginLeft: '8px', marginBottom: '0', color: "#A0A0A0", justifyContent: 'center', alignItems: 'center' }}>The title is too vague, which may return low quality profiles. Please add a more specific title for accurate results.</h6>
        </div> : null}
      </div> */}
      <div>
        {mainTitleResult < 100 &&
        mainTitleResult != -1 &&
        !mainTitle?.generic &&
        !mainTitleResultLoading ? (
          <div style={{ display: "flex" }}>
            <h6 style={{ lineHeight: "23px", color: "#A0A0A0" }}>
              Not enough results for this title. Try rephrasing by editing the
              title
              {maintitleSuggestions.length > 0 && (
                <span>, or by replacing it with the suggestions below:</span>
              )}
            </h6>
          </div>
        ) : null}
      </div>
      {!mainTitleResultLoading &&
      mainTitleResult != -1 &&
      mainTitleResult < 100 &&
      maintitleSuggestions.length > 0 ? (
        <div className="criteriaMainTitleBody">
          <h3>Replace the title with</h3>
          <div className="titleSuggestionContainer">
            {maintitleSuggestions.map((title, index) => (
              <PillButton key={index} onClick={() => handleNameChange(title)}>
                {title}
              </PillButton>
            ))}
          </div>
        </div>
      ) : null}
      <div className="criteriaMainTitleBody">
        <h3>Employee Count</h3>
        <span>
          {(mainTitle?.company_size || []).map(({ min, max }, i) => (
            <DiscreteRangeSlider
              key={i}
              value={{ min, max }}
              discreteSteps={companySizeOptions}
              onChange={(e) =>
                isReview === "1" ? () => false : handleChangeCompanySize([e])
              }
              resultLabel={`${kiloConversion(min)} to ${
                max === 10001 ? "10K+" : kiloConversion(max)
              }`}
              getLabel={(value) =>
                value === 10001 ? "10K+" : kiloConversion(value)
              }
            />
          ))}
        </span>
      </div>
    </div>
  );
};

export default connect((state) => ({
  job_position: state?.jobSetup?.jobSetup?.jobSetup?.job_position || [],
  highlightValue: state.jobSetup.viewController.highlightValue,
  titleInitialising: state.jobSetup.jobCriteria.titleInitialising,
  titleInitialised: state.jobSetup.jobCriteria.titleInitialised,
  mainTitleResult: state.jobSetup.jobCriteria.mainTitleResult,
  mainTitleResultLoaded: state.jobSetup.jobCriteria.mainTitleResultLoaded,
  mainTitleResultLoading: state.jobSetup.jobCriteria.mainTitleResultLoading,
  mainTitleGenericResult: state.jobSetup.jobCriteria.mainTitleGenericResult,
  mainTitleGenericResultLoading:
    state.jobSetup.jobCriteria.mainTitleGenericResultLoading,
  mainTitleGenericResultLoaded:
    state.jobSetup.jobCriteria.mainTitleGenericResultLoaded,
  // titles: state.jobSetup.jobCriteria.titles,
  mainTitle: state.jobSetup.jobCriteria.mainTitle,
  cards: state.jobSetup.viewController.cards,
  isReview: state.jobSetup?.jobSetup?.isReview,
  maintitleSuggestions:
    state.jobSetup.jobCriteria.mainTitle.maintitleSuggestions,
}))(CriteriaMainTitle);
