import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux';
import CaretLeft from '../../../uiLibrary/SVGs/CaretLeft';
import PillTabButton from '../../../uiLibrary/PillTabButton';
import { toWordsconver } from '../../../utils';
import './style.scss'
import OutboxReviewMessage from './OutboxReviewMessage';
import TickSvg from '../../../uiLibrary/SVGs/TickSvg';
import CrossSvg from '../../../uiLibrary/SVGs/CrossSvg';
import InfoSvg from '../../../uiLibrary/SVGs/InfoSvg';
import LabelButton from '../../../uiLibrary/LabelButton';
import { Mixpanel } from '../../../utils/Mixpanel';
const OutboxReviewController = ({
    rounds,
    selectedProspects,
    handleChange,
    variableTranslator,
    recipientTab,
    updateSelected,
    errorCheck,
    emailCheck,
    consentRequested,
    consentCheck,
    setConsentRequested,
    channelOptions,
    isInViewport,
}) => {

    const [isInViewPortState, setIsInViewPortState] = useState(0)
    const [hasError, setHasError] = useState(null)
    const [erroredMessages, setErroredMessages] = useState([])
    const [isAffordanceOpen, setIsAffordanceOpen] = useState(true)
    const channelRequireSignin = ['linkedin', 'inmail']
    const linkedinSigninRequired = rounds.length>1 && rounds.some(({messages, channel})=>messages.some(message=>message.is_personalised?channelRequireSignin.includes(message.channel):channelRequireSignin.includes(channel)))
    const recruiterSigninRequired = rounds.length>1 && rounds.some(({messages, channel})=>messages.some(message=>message.is_personalised?'recruiter'===message.channel:'recruiter'===channel))

    useEffect(async () => {
        console.log("MESSAGE", Object.values(selectedProspects).map(prospect => prospect.isRemoved))
        window.addEventListener('scroll', handleIsInViewPort);
        let isSet = false
        await Promise.all(rounds.map(async(round, index)=>{
            const messages = await Promise.all(round.messages
                // .filter(message => 
                //    {
                //     const selectedProspect = Object.values(selectedProspects).find(prospect => prospect.jobProfileId === message.jobprofile_id)
                //     console.log("selectedProspect.isRemoved messages", !selectedProspect.isRemoved, selectedProspect)
                //     return !selectedProspect.isRemoved
                //    }
                // )
                .map(async message=>{
                const recipient = selectedProspects[Object.keys(selectedProspects).filter(key=>selectedProspects[key].jobProfileId===message.jobprofile_id)?.[0]]
                let translatedMessage = ''
                const email_address = recipient?.primaryEmail || recipient?.personal_email || recipient?.work_email 
                if(message.is_personalised){
                    const errorCheckPayload = errorCheck(message.template, recipient, message.channel==='linkedin')

                    const emailPresent = message.channel==='email'?
                        emailCheck(message.jobprofile_id) : true

                    const hasConsent = message.channel==='email' && email_address && !consentRequested.includes(email_address)? await consentCheck(message.jobprofile_id, email_address) : true
                    
                    if(!(errorCheckPayload.areRequiredVarsPresent &&
                    errorCheckPayload.isMessageUnderLimit &&
                    emailPresent 
                    && hasConsent
                    )){
                        isSet=true
                        setErroredMessages(prev=>[...prev, {
                            id: round.id,
                            jobprofile_id: message.jobprofile_id
                        }])
                    }
                    return {
                        ...message,
                        ...errorCheckPayload,
                        emailType: message.emailType ? message.emailType : recipient?.primaryEmail || recipient?.personal_email ? 'Personal Email' : recipient?.work_email ? 'Work Email' : null,
                        hasConsent,
                        emailPresent,
                        isRemoved: recipient.isRemoved
                    }
                }
                const errorCheckPayload = errorCheck(round.template, recipient, round.channel==='linkedin')
                
                const emailPresent = round.channel==='email'?
                    emailCheck(message.jobprofile_id) : true

                const hasConsent = round.channel==='email' && email_address && !consentRequested.includes(email_address) ? await consentCheck(message.jobprofile_id, email_address) : true
                

                if(!(errorCheckPayload.areRequiredVarsPresent &&
                errorCheckPayload.isMessageUnderLimit &&
                emailPresent 
                && hasConsent
                )){
                    isSet=true
                    setErroredMessages(prev=>[...prev, {
                        id: round.id,
                        jobprofile_id: message.jobprofile_id
                    }])
                }
                return {
                    ...message,
                    ...errorCheckPayload,
                    emailType: message.emailType ? message.emailType : recipient?.primaryEmail || recipient?.personal_email ? 'Personal Email' : recipient?.work_email ? 'Work Email' : null,
                    hasConsent,
                    emailPresent,
                    isRemoved: recipient.isRemoved
                }
            }))
            console.log("messages", messages)
            handleChange(messages, round.id,  'messages')
        }))
        if(!isSet){
            setHasError(0)
        }
        else{
            setHasError(1)
        }
        Mixpanel.track("Review Message Campaign")
        return () => {
            window.removeEventListener('scroll', handleIsInViewPort);
        };
    }, [selectedProspects])

    const handleIsInViewPort = () => {
        for (let index = 0; index < 3; index++) {
            if(isInViewport(document.getElementById(`Round ${index+1}`))){
                return setIsInViewPortState(index)
            }
        }
        return false
    }

    const tabs = rounds.map((round, index)=>{
        return {
            ...channelOptions[round.channel],
            label: `Round ${index+1}`,
            ...round,
        }
    })

    const tabClick = index =>{
        const id = `Round ${index}`;
        const yOffset = -100; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    }

    const subHeading = (round, send_after_days) => round===0 ? 
        `To be sent when published` :
        `To be sent in ${toWordsconver(send_after_days||0)} ${send_after_days>1 ? 'days' : 'day'}`

    return (
        <div
            className='outboxReviewController'
        >
            {(linkedinSigninRequired || recruiterSigninRequired) && isAffordanceOpen &&
                <div
                    className='outboxReviewAffordance'
                >
                    <InfoSvg
                        Recommended={true}
                        width='32'
                        height='32'
                    />
                    <p>
                        {`Your follow ups will not be sent if you are signed out of${linkedinSigninRequired?' LinkedIn':''}${linkedinSigninRequired && recruiterSigninRequired?' &':''}${recruiterSigninRequired?' Recruiter':''}`}
                    </p>
                    <LabelButton
                        onClick={e=>setIsAffordanceOpen(false)}
                    >
                        <CrossSvg
                            width='16'
                            height='16'
                        />
                    </LabelButton>
                </div>   
            }
            {hasError===1 &&
                <div
                    className='pillTabHeader'
                >
                    {rounds.some(({messages, id})=>messages.every(message => erroredMessages.map(eror=>eror.id).includes(id) && erroredMessages.map(eror=>eror.jobprofile_id).includes(message.jobprofile_id))) &&
                        tabs.map((tab, index)=>(
                            <React.Fragment>
                                <PillTabButton
                                    key={index}
                                    tab={tab}
                                    selected={isInViewPortState===index}
                                    onClick={e=>tabClick(index+1)}
                                />
                                {index<tabs.length-1 &&
                                    <CaretLeft
                                        style={{ transform: 'rotate(180deg)' }}
                                        width="12"
                                        height="24"
                                    />
                                }
                          </React.Fragment>
                        ))
                    }
                </div>
            }
            <div
                className='outboxReviewBody'
            >
                {rounds.map((round, index)=>(
                    <div
                        key={index}
                        id={`Round ${index+1}`}
                    >
                        <div
                            className='outboxReviewRoundHeader'
                        >
                            <span>
                                <span
                                    className={!['inmail', 'recruiter'].includes(round.channel) ? 'svgStrokeContainer' : 'svgFillContainer'}
                                >
                                    {tabs[index].icon}
                                </span>
                                <h3>
                                    {`Round ${index+1}`}
                                </h3>
                            </span>
                            <span>
                                <p>
                                    {subHeading(index, round.send_after_days)}
                                </p>
                            </span>
                        </div>
                        {round.messages.filter(message => !message.isRemoved).every(message => !erroredMessages.map(eror=>eror.id).includes(round.id) || !erroredMessages.map(eror=>eror.jobprofile_id).includes(message.jobprofile_id)) ?
                            <div
                                className='outboxReviewRoundSummary'
                            >
                                <span>
                                    <TickSvg/>
                                </span>
                                <p>
                                    All messages are good to go!
                                </p>
                            </div> : round.messages.filter(message => !message.isRemoved).filter(message => erroredMessages.map(eror=>eror.id).includes(round.id) && erroredMessages.map(eror=>eror.jobprofile_id).includes(message.jobprofile_id)).map((message, index)=>(
                                <OutboxReviewMessage
                                    key={`${round.id}-${message.jobprofile_id}`}
                                    handleChange={handleChange}
                                    errorCheck={errorCheck}
                                    emailCheck={emailCheck}
                                    consentCheck={consentCheck}
                                    round={round}
                                    consentRequested={consentRequested}
                                    updateSelected={updateSelected}
                                    setConsentRequested={setConsentRequested}
                                    {...message}
                                />
                            ))
                        }
                        
                    </div>
                ))
                }
            </div>
        </div>
    )
}

export default connect(state=>({
    rounds: state.outbox.campaigns.rounds,
    selectedProspects: state.prospectsView.candidates.selectedProspects,
    recipientTab: state.outbox.viewController.recipientTab,
}))(OutboxReviewController)
