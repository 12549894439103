import React from "react";
import "./TribeMemberCard.scss";
import CloseButton from "../CloseButton/CloseButton";
import UserIcon from "../svgComponents/UserIcon";
import SecondaryButton from "../../uiLibrary/SecondaryButton";
import SwitchComponent from "../../uiLibrary/Switch";

const TribeMemberCard = ({
  shortName,
  type='tribe member',
  name,
  email,
  added,
  onAddClick,
  reachoutPermission,
  viewPermission,
  onTogglePermission
}) => {
  const addedState = added ?
    'Added' :
    'Add'
  
  return (
    <div className="TribeCardContainer">
      <div 
        className='TribeCardHeader'
      >
        <span>
          <h3>
            {name}
          </h3>
          {type==='invite' ?
            <p
              className='tribeStatusInvited'
            >
              invited to tribe
            </p> :          
            <p>
              {email}
            </p>
          }
        </span>
        {type!=='invite' &&
          <SecondaryButton
            className={ `tribe${addedState}` }
            onClick={onAddClick}
          >
            { addedState }
          </SecondaryButton>
        }
      </div>
      {added &&
        <div
          className='tribePermissionContainer'
        >
          <h4>PERMISSIONS</h4>
            <div className="setTribeAttributes">
              <h5>Reachout To Prospects</h5>
              <label>
                <SwitchComponent
                  onChange={e=>onTogglePermission(e, {reachoutPermission: !reachoutPermission})}
                  checked={reachoutPermission}
                />
              </label>
            </div>
            <div className="setTribeAttributes">
              <h5>View Tribe Activity</h5>
              <label>
                <SwitchComponent
                  onChange={e=>onTogglePermission(e, {viewPermission: !viewPermission})}
                  checked={viewPermission}
                />
              </label>
            </div>
        </div>
      }
    </div>
  );
};

export default TribeMemberCard;
