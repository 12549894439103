import React from "react";

export const SkillsFAQ1 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>The qualities and expertise you require a prospect to possess</li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ2 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            Skills is what a prospect possesses themself and business/specialty
            is the field of the companies that they have worked in and have
            experience in
          </li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ3 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            For example javascript could be a skill of a prospect whereas,
            retail could be the business/specialty of the company the prospect
            worked in and has experience of
          </li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ4 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Job description</li>
          <li>Best fit profiles</li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ5 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            You hover on the pill on left and Qlu will highlight and scroll to
            where it is in the JD
          </li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ6 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            Highlight and right click a word that you would like to add as a
            skill
          </li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ7 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            In the left section, hover on the skill that you would like to
            unselect
          </li>
          <li>The tick will turn into a cross </li>
          <li>Click on the cross</li>
          <li>In the JD, hover on the skill that you would like to unselect</li>
          <li>A cross will appear </li>
          <li>Click on the cross</li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ8 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>5 words</li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ9 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            Add however many skills you find relevant. Please avoid adding
            skills that are not relevant to what you’re looking for
          </li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ10 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>No, you can also select skills of lesser importance</li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ11 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Yes</li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ12 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            Skills that your best fit profile has mentioned in their Linkedin
            Profile’s skill section, experience description and the About Me
            section
          </li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ13 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>However many are relevant to your search</li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ14 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            It is a weightage assigned to how important a skill is for that
            particular job
          </li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ15 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            A statistical model is used to decide the importance of a skill
          </li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ16 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            High importance skill means that it is a crucial must have skill
          </li>
          <li>
            Medium importance skill means that it is a plus if a prospect
            possesses this skill but not an issue if they don’t. These are
            usually broader terms of high important skills
          </li>
          <li>
            Low importance skill means that it can be either a soft skill or a
            general skill{" "}
          </li>
        </ul>
      </p>
    </div>
  );
};

export const SkillsFAQ17 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            If the skill has high importance it must be detected 3 times to
            contribute full score for that skill, for medium importance it
            should be detected at least 2 times and for low importance it should
            be detected one time in the prospects profile
          </li>
        </ul>
      </p>
    </div>
  );
};
