import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Divider from '../../components/Divider'
import FormikInput from '../../components/FormikInput'
import SocialLoginButton from '../../components/SocialLoginButton'
import eyeOffIcon from '../../img/eye-off.svg'
import eyeIcon from '../../img/eye.svg'
import PrimaryButton from '../../uiLibrary/PrimaryButton'
import { manualPublicApiCall, validateEmail, validatePassword } from '../../utils'
import { ActivityTypes, loggingActivity } from '../../utils/activityLogger'
import { Mixpanel } from '../../utils/Mixpanel'


function SignUpScreen({
    history,
    location,
    dispatch,
}) {

    useEffect(() => {
        localStorage.removeItem('UserData')
    }, [])

    const validateForm = async (values) => {

        // const checkExistense = await fetch(`${process.env.REACT_APP_API_HOST}/public/email/lookup`, {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ email: values.email })
        // })

        // console.log("CHECK EXISTENCE RESPONSE: ", checkExistense)

        const errors = {};
        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!validateEmail(values.email)) {
            errors.email = 'Invalid email address';
        }

        if (!values.fullName.trim()) {
            errors.fullName = 'Name is required';
        }

        if (!values.password) {
            errors.password = 'Password is required';
        } else if (!validatePassword(values.password)) {
            errors.password = 'Minimum length of Password is 8 characters including at least 1 upper case letter and 1 special symbol.'
        }

        return errors;
    }
    const submitForm = async (values, { props, resetForm, setErrors, setSubmitting }) => {
        try {
            setSubmitting(true)

            const res = await manualPublicApiCall('/public/email/lookup',
                { method: 'POST', body: JSON.stringify({ email: values.email }) })

            if (res.data.exist && res.data.exist.trim().toLowerCase() === values.email.trim().toLowerCase()) {
                setErrors({ email: 'An account with this email already exists' })
                localStorage.setItem('UserData', window.btoa(JSON.stringify({
                    name: values.fullName,
                    email: values.email,
                    password: values.password,
                })))
                // history.push('/auth/resend-link')
            }
            else {
                const r = await manualPublicApiCall('/public/register/user', {
                    method: 'POST', body: JSON.stringify({
                        name: values.fullName,
                        email: values.email,
                        password: values.password,
                        // phone_number: is_2fa_enabled ? phone_number.trim() : null,
                        // is_2fa_enabled
                    })
                })
                loggingActivity(ActivityTypes.SIGNUP)
                if (r.data.email === values.email) {
                    Mixpanel.track("Signup with password")
                    localStorage.setItem('UserData', window.btoa(JSON.stringify({
                        name: values.fullName,
                        email: values.email,
                        password: values.password,

                    })))
                    history.push('/auth/verify-email', { from: 'signup' });
                }
            }
        } catch (e) {
            console.log('AN ERROR OCCURRED: ', e.message)
        }
        finally {
            setSubmitting(false)
        }
    }

    const [isVisiblePassword, setIsVisiblePassword] = useState(false)
    return (
        <>
            <Formik
                initialValues={{ email: '', password: '', fullName: '' }}
                validate={validateForm}
                onSubmit={submitForm}
            >
                {({
                    errors,
                    isSubmitting
                }) => (
                    <div className='signin_root authElemContainer'>
                        <h1>Sign up with your work email</h1>
                        <Form>
                            <div className='mb32'>
                                <p className='input_label'>EMAIL</p>
                                <Field type="email" name="email" placeholder={'john@doe.com'} component={FormikInput} />
                                <ErrorMessage name="email" render={msg => <p className='formik_err_msg'>{msg}</p>} />
                            </div>
                            <div className='mb32'>
                                <p className='input_label'>FULL NAME</p>
                                <Field type="fullName" name="fullName" placeholder={'John Doe'} component={FormikInput} />
                                <ErrorMessage name="fullName" render={msg => <p className='formik_err_msg'>{msg}</p>} />
                            </div>
                            <div className='mb32 '>
                                <p className='input_label'>PASSWORD</p>
                                <Field
                                    type={isVisiblePassword ? 'text' : 'password'}
                                    name="password" placeholder={'At least 8 characters'}
                                    component={FormikInput} />
                                <span className='password_show_hide' onClick={() => setIsVisiblePassword(p => !p)}>
                                    {
                                        isVisiblePassword
                                            ? <img src={eyeOffIcon} alt='hide' />
                                            : <img src={eyeIcon} alt='show' />
                                    }
                                </span>
                                <ErrorMessage name="password" render={msg => <p className='formik_err_msg'>{msg}</p>} />
                            </div>
                            <PrimaryButton className='auth-submit-btn' type="submit"
                                disabled={isSubmitting}>
                                {isSubmitting ?
                                    <Loader
                                        type="TailSpin"
                                        color="white"
                                        height={18}
                                        width={18}
                                    /> :
                                    'Continue'}
                            </PrimaryButton>
                        </Form>
                        <Divider />
                        <a style={{ textDecoration: 'none' }} href={`${process.env.REACT_APP_API_HOST}/google/login`}>
                            <SocialLoginButton provider={'google'} />
                        </a>
                        <a style={{ textDecoration: 'none' }} href={process.env.REACT_APP_OUTLOOK_AUTH}>
                            <SocialLoginButton provider={'outlook'} />
                        </a>
                    </div>
                )}
            </Formik>
        </>
    )
}

export default connect(state => ({ ...state.auth.emailStep }))(withRouter(SignUpScreen))
