import {
  ADD_RECOMMENDED_COUNT,
  FETCH_HARRY_POTTER_COUNT,
  FETCH_HARRY_POTTER_LOADER,
  HARRY_POTTER_INIT_STATE,
  HARRY_POTTER_MODE,
  INIT_RECOMMENDED_COUNT,
  OPEN_COMMENTS_AND_NAVIGATE,
  SET_ABORT_CONTROLLER,
  SET_ACTIVE_PROSPECT_ID_VARIANT_SCROLL,
  SET_ACTIVE_PROSPECT_VARIANT,
  SET_CURRENT_BUCKET,
  SET_PAGE_NUMBER,
  SHOULD_SHOW_HARRY_POTTER_MODE,
  GET_ACTIVITIES_OF_CURRENTPROSPECT,
} from "../../actions/prospectsView";

const initialState = {
  activeProspectVariant: 2,
  selectedProspectIDVariant: null,
  pageNumber: 0,
  selectedBucket: {},
  recommended: 0,
  controller: null,
  selectedCommentID: null,
  harryPotterMode: false,
  shouldShowHarryPotterMode: false,
  harryPotterCount: null,
  harryPotterLoader: false,

  ActivitiviesOfCurrentPorspet: [], // related to //bugfix-NA-s21-1625 & bugfix-NA-s21-1624
};

const prospectVariant = (state = initialState, action) => {
  switch (action.type) {
    case SET_ABORT_CONTROLLER: {
      return {
        ...state,
        controller: action.payload,
      };
    }
    case SET_ACTIVE_PROSPECT_VARIANT:
      return {
        ...state,
        activeProspectVariant: action.payload.data,
      };
    case SET_ACTIVE_PROSPECT_ID_VARIANT_SCROLL:
      return {
        ...state,
        selectedProspectIDVariant: action.payload.jobProfileId,
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case SET_CURRENT_BUCKET:
      return {
        ...state,
        selectedBucket: action.payload,
      };
    case INIT_RECOMMENDED_COUNT:
      return {
        ...state,
        recommended: 0,
      };
    case ADD_RECOMMENDED_COUNT:
      return {
        ...state,
        recommended: state.recommended + 1,
      };
    case OPEN_COMMENTS_AND_NAVIGATE:
      return {
        ...state,
        selectedProspectIDVariant: action.payload.prospectID,
        selectedCommentID: action.payload.commentID,
      };
    case HARRY_POTTER_MODE:
      return {
        ...state,
        harryPotterMode: action.payload,
      };
    case SHOULD_SHOW_HARRY_POTTER_MODE:
      return {
        ...state,
        shouldShowHarryPotterMode: action.payload,
      };
    case FETCH_HARRY_POTTER_COUNT:
      return {
        ...state,
        harryPotterCount: action.payload,
      };
    case FETCH_HARRY_POTTER_LOADER:
      return {
        ...state,
        harryPotterLoader: action.payload,
      };
    case HARRY_POTTER_INIT_STATE:
      return {
        ...state,
        harryPotterMode: false,
        shouldShowHarryPotterMode: false,
        harryPotterCount: null,
        harryPotterLoader: false,
      };
    //bugfix-NA-s21-1625 & bugfix-NA-s21-1624
    case GET_ACTIVITIES_OF_CURRENTPROSPECT:
      return {
        ...state,
        ActivitiviesOfCurrentPorspet: action.payload,
      };
    default:
      return state;
  }
};
export default prospectVariant;
