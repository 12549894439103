import React from "react";
import "../style.scss";

const BookmarkSvg = ({
  color = "#CDD1D7",
  width = "16",
  height = "20",
  style = {},
  stroke = 1.5,
  className = "",
  fill = "none",
  selected = false,
  onClick,
}) => {
  return (
    <div onClick={onClick}>
      <svg
        width={width}
        height={height}
        className={`${className} ${selected ? "qluSvgFillSelected" : ""}`}
        viewBox="0 0 16 20"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          className={`qluSvgStroke${selected ? "Selected" : ""}`}
          d="M15 19L8 14L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H13C13.5304 1 14.0391 1.21071 14.4142 1.58579C14.7893 1.96086 15 2.46957 15 3V19Z"
          stroke={color}
          strokeWidth={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default BookmarkSvg;
