import React from 'react'
import { connect } from 'react-redux'
import { changeCampaignRound, setOutboxView } from '../../../../actions/outbox'
import { selectProspects } from '../../../../actions/prospectsView'
import DropdownComponent from '../../../../uiLibrary/DropdownComponent'
import NumberInput from '../../../../uiLibrary/NumberInput'
import OutboxMessaging from '../../../../uiLibrary/OutboxMessaging'
import ConnectSvg from '../../../../uiLibrary/SVGs/ConnectSvg'
import InmailSVG from '../../../../uiLibrary/SVGs/InmailSvg'
import MailSVG from '../../../../uiLibrary/SVGs/MailSvg'
import TabButton from '../../../../uiLibrary/TabButton'
import TimeInput from '../../../../uiLibrary/TimeInput'
import { toWordsconver } from '../../../../utils'
import './style.scss'
const OutboxMessage = ({
    channelOptions,
    togglePersonalise,
    selectedProspects,
    variableTranslator,
    onTabClick,
    id,
    icon,
    recipientTab,
    send_after_days,
    sender,
    subject,
    channel,
    handleChange,
    messageErrors,
    template,
    round,
    sendersEmail,
    dispatch,
}) => {
   
    
    const subHeading = round===0 ? 
        `To be sent when published by You` :
        `To be sent in ${toWordsconver(send_after_days||0)} ${send_after_days>1 ? 'days' : 'day'} by You`

    const recepient = recipientTab==='all' ?
        selectedProspects[Object.keys(selectedProspects)?.[0]] :
        selectedProspects[recipientTab]

    const {important=[], must_haves=[], nice_to_haves=[]} = recepient?.scoring?.skills_required

    // console.log("====================recepient?.scoring?.skills_required==============================", recepient?.scoring?.skills_required)    
    
    // console.log("====================important==============================", important)
    // console.log("====================must_haves==============================", must_haves)
    // console.log("====================nice_to_haves==============================", nice_to_haves)

    const skillsReq = [...must_haves]
    // console.log("====================skillsReq==============================", skillsReq)

    return (
        <div
            className='outboxMessageContainer'
        >   <div>
                <div
                    className='outboxMessageHeader'
                >
                    <span>
                        <span
                            className={!['inmail', 'recruiter'].includes(channel) ? 'svgStrokeContainer' : 'svgFillContainer'}
                        >
                            {icon}
                        </span>
                        <h3>
                            {`Round ${round+1}`}
                        </h3>
                    </span>
                    <span>
                        <TabButton
                            tab={{name: 'Editing for all'}}
                            selected={recipientTab==='all'}
                            onClick={e=>onTabClick('all')}
                        />
                        <TabButton
                            tab={{name: recipientTab==='all'?'Personalize':`Personalize for ${selectedProspects[recipientTab]?.firstName}`}}
                            selected={recipientTab!=='all'}
                            onClick={e=>onTabClick('')}
                        />
                    </span>
                </div>
                <p>
                    {subHeading}
                </p>
                {/* <DropdownComponent
                     selected={"piipi"}
                     options={["scooby", "dooby"]}
                     onSelect={e=>false}
                /> */}
            </div>
            <div
                className='outboxMessageBody'
            >
                <OutboxMessaging
                    key={`${recipientTab}${round}`}
                    message={template}
                    onChange={value=>value===template ? e=>false : handleChange(value, id, 'template')}
                    hasSubject={['email', 'inmail', 'recruiter'].includes(channel)}
                    hasEmail={'email'===channel}
                    subject={subject}
                    onSubjectChange={value=>handleChange(value, id, 'subject')}
                    email={sendersEmail}
                    charLimits={channel==='linkedin'}
                    variableTranslator={variableTranslator}
                    recepient={recepient}
                    skillsReq={skillsReq}
                />
            </div>
               
        </div>
    )
}

export default connect(state=>({
    selectedProspects: state.prospectsView.candidates.selectedProspects,
    recipientTab: state.outbox.viewController.recipientTab,
    sendersEmail: state.auth.user.email,
}))(OutboxMessage)
