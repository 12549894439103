import React, { useEffect, useState } from "react";
import LinkedInLogo from "../../uiLibrary/SVGs/LinkedInSvg";
import "./RevampProspectFullCard.scss";

import { union } from "lodash";
import moment from "moment";
import qs from "qs";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { Button, Collapse, Input, Progress, Tooltip } from "reactstrap";
import {
  deleteEmoji,
  onHoverChangeProspectElement,
  saveEmoji,
} from "../../actions/prospectsView";
import CommentsSection from "../../components/CommentsSection/CommentsSection";
import CompanyDisplayImage from "../../components/CompanyDisplayImage/CompanyDisplayImage";
import EmojiPicker from "../../components/EmojiPicker/EmojiPicker";
import ProfileDisplayImage from "../../components/ProfileDisplayImage/ProfileDisplayImage";
import Emoji from "../../components/svgComponents/Emoji";
import MessageCircle from "../../components/svgComponents/MessageCircle";
import { PER_PAGE_COUNT } from "../../constants/api";
import {
  capitalizeCase,
  demographicsFormatter,
  getMaxKey,
  getTaggedText,
  getValidDate,
  getYrsAndMos,
  smoothScroll,
} from "../../utils";
import { Mixpanel } from "../../utils/Mixpanel";
import useComponentVisible from "../../utils/useComponentVisible";
import Loading from "../Loading";
import CollapsibleComponent from "../RevampProspectPage/CollapsibleComponent";
import CaretDown from "../SVGs/CaretDown";
import CompanySvg from "../SVGs/CompanySvg";
import CrossSvg from "../SVGs/CrossSvg";
import JobSetupStep10 from "../SVGs/JobSetupStepSvgs/JobSetupStep10";
import SocialHeartButton from "../SocialHeartButton";
import SocialMaybeButton from "../SocialMaybeButton";
import SocialRejectButton from "../SocialRejectButton";
const RevampProspectFullCard = ({
  selectedProspectComments,
  deleteCount,
  currentHigLighted,
  handleRecommendedCard,
  profileIndex,
  handleFullCardRight,
  handleFullCardLeft,
  showCompanyModal,
  prospects,
  elementIndex,
  tabValue = "Profile",
  closeCompanyModal = (e) => false,
  onClickCard = (e) => false,
  showFullCard,
  onClickSave = (e) => false,
  onClickMaybe = (e) => false,
  isDetail = false,
  showEmojiPickerProspectID,
  isSelected = false,
  onSelect = (e) => false,
  selectedProspects,
  onActivitiesofCurrentProspect = (e) => false, //bugfix-NA-s21-1625 & bugfix-NA-s21-1624
  ActivitiviesOfCurrentPorspet = [], //bugfix-NA-s21-1625 & bugfix-NA-s21-1624
  comments = [], //bugfix-NA-s21-1625 & bugfix-NA-s21-1624
  prospectData = {
    totalcurrentcount: "728",
    id: 4064255,
    jobProfileId: 3718869,
    firstName: "Haris",
    lastName: "Khan",
    fullName: "Haris Khan",
    profileUrl: "https://www.linkedin.com/in/mhariskhan55",
    publicIdentifier: "mhariskhan55",
    age: 22,
    gender: {
      male: "1.0",
      female: "2.519815e-08",
    },
    ethnicity: {
      White: "0.8628013",
      Latino: "0.13719867",
    },
    demographics: "Male, 20s, South Asian",
    location: "Pakistan",
    locality: null,
    headline: "Full Stack Engineer at Sectem Technologies",
    title: "Full Stack Engineer",
    imageUrl:
      "https://media-exp1.licdn.com/dms/image/C5603AQEgpiK1GfQw-Q/profile-displayphoto-shrink_400_400/0/1604712859393?e=1637798400&v=beta&t=8jv3-xtUEpnpDzZfItBrEfoRUEQyvjsUtGyIuFiDQbs",
    companyName: null,
    currentCompanyName: "Sectem Technologies",
    currentCompanySize: "18 employees",
    currentCompanyIndustry: "Information Technology & Services",
    currentCompanyWebsite: "http://www.sectem.com",
    comments: [],
    activities: [],
    experience: [
      {
        end: "",
        urn: "urn:li:fsd_company:18295674",
        logo:
          "https://media-exp1.licdn.com/dms/image/C4D0BAQG_rIODkor1dw/company-logo_400_400/0/1545946017464?e=1640217600&v=beta&t=T-3j-fYq8vUhVqZCZjf5g9gdIIXQ9tzsb_1R8Xl1yQ4",
        start: "10-2020",
        title: "Full Stack Engineer",
        location: "Pakistan",
        industries: ["Information Technology & Services"],
        companySize: 18,
        companyType: "Sole Proprietorship",
        description: "",
        skills: ["Web Development", "Sass", "React"],
        duration: "4 yrs",
        company_name: "Sectem Technologies",
        universalName: "sectem-technologies",
        companyPageUrl: "https://www.linkedin.com/company/sectem-technologies",
        companyWebsite: "http://www.sectem.com",
        summary: `Managed accounts for global clientele with average transaction values of over $1 million.
            \n• Spearheaded a new business acquisition project, resulting in an additional $500,000 of consignments as part of $5 million consignment goal.
            \n• Presented and implemented a new marketing plan to senior leadership in order to increase our market presence and brand value.
            \n• Directed an initiative to overhaul our post-sale training system with the global compliance team, resulting in 50% reduction in fraudulent sales activity.
            \n• Coordinated 10+ successful competitive property valuations, each garnering over $500,000 in consignments.
            \n• Led office operations in coordination with global offices, consistently exceeding goals and timelines on projects.`,
        companySpecialities: [
          "Webapps",
          "Andriod Development",
          "DigitalMarketing",
          "IOS Development",
          "WebDesign",
          "Branding",
          "Digital Marketing",
          "Web Development",
          "Javascript",
          "Python",
          "Startup",
          "Consultancy",
        ],
      },
    ],
    education: [
      {
        end: "2021",
        start: "2017",
        degreeName: "Bachelor of Science - BS",
        schoolName: "COMSATS University Islamabad",
        fieldOfStudy: "Computer Science",
      },
    ],
    industry: "Computer Software",
    skills: [
      "MERN Stack",
      "Web Development",
      "Go (Programming Language)",
      "Android Development",
      "Programming",
      "Software Development",
      "Analytical Skills",
      "Web Design",
      "Application Development",
      "Payments",
      "Unit Testing",
      "Python (Programming Language)",
      "Java",
      "Node.js",
      "React.js",
      "C++",
      "Android",
      "Jupyter",
      "MongoDB",
      "Google Cloud Platform (GCP)",
      "PostgreSQL",
      "Redux",
      "JavaScript",
      "SQL",
      "Git",
      "HTML",
      "NoSQL",
      "Management",
      "Problem Solving",
      "English",
      "Web Application Development",
      "Full-Stack Development",
      "Express.js",
      "Golang",
      "Paypal Integration",
      "Concurrent Programming",
      "Microservices",
      "Payment Integration",
      "Finix",
      "Braintree",
    ],
    industryCode: 4,
    summary:
      "Full Stack Engineer working in the information technology, product and services industry. An engineering professional having strong analytical and problem solving skills with a Bachelor of Science - BS focused in Computer Science. Always keen to learn more and take on new challenges.",
    entityUrn: "urn:li:fs_profile:ACoAACKLMkABHbPTI1bjdhQsgmTfKF3kJlSbFFo",
    scrapeType: "DEEP",
    addedAt: "2021-09-21T04:51:21.954Z",
    connectMessageAt: null,
    repliedAt: null,
    downloadedAt: null,
    archivedAt: null,
    visa_status: "None",
    archived: false,
    saved: false,
    followUpFirstMessageAt: null,
    followUpSecondMessageAt: null,
    isDownloaded: false,
    archivedBy: null,
    downloadedBy: null,
    messagedBy: null,
    userId: "941",
    savedBy: null,
    scoringType: "DEEP",
    scoring: {
      score: 34.62051953552971,
      final_score: 69.62052,
      skill_score: 14.90181,
      skill_total: 20,
      title_score: 6,
      title_total: 6,
      visa_status: "None",
      same_company: false,
      company_score: 0,
      recruiterLink: [],
      industry_score: 11.24991,
      industry_total: 12.4999,
      skills_profile_missing: ["networking"],
      skills_profile: [
        "web",
        "python",
        "microservices",
        "information technology",
      ],
      education_score: 1.2688,
      education_total: 2.5000999999999998,
      skills_required: [
        "backend: Important",
        "front end: Important",
        "web: Important",
        "information technology: Must Have",
        "design patterns: Important",
        "continuous integration: Important",
        "tdd: Important",
        "sales: Nice to Have",
        "issue resolution: Nice to Have",
        "microservices: Important",
        "python: Important",
        "coding: Nice to Have",
      ],
      experience_found: [
        "Full Stack Engineer at Sectem Technologies for 11 month(s)",
        "11 month(s) ",
      ],
      experience_score: 7.2,
      experience_total: 15,
      skill_importance: {
        important: ["web", "microservices", "python"],
        important_missing: ["Information Technology and Services"],
        must_haves: ["information technology"],
        must_haves_missing: ["iOS Development"],
        nice_to_haves: ["Sass"],
        nice_to_haves_missing: ["Next.js"],
      },
      adjustment_factor: 70,
      education_profile: ["The prospect went to COMSATS University Islamabad"],
      competitor_company: false,
      education_required: ["bachelor of information technology: Nice to Have"],
      industries_profile: ["information technology & services"],
      experience_required: [
        "3 years related to full stack architect: Nice to Have",
        "proficiency in one or more backend languages: Nice to Have",
        "proficiency in one or more frontend framework html5 or angular or react: Nice to Have",
        "experience developing on both mobile and web platforms: Nice to Have",
        "three years of progressive experience in the specialty in lieu of every year of education: Nice to Have",
        "3 years of continuous integration continuous delivery pair programming and test driven development: Nice to Have",
      ],
      industries_required: ["Computer Software: Must Have"],
      all_experience_found: [
        "Full Stack Engineer at Sectem Technologies for 11 month(s)",
      ],
      qualification_status: "Under Qualified",
      super_high_reccomend: false,
      all_experience_scores: {
        skills: 0,
        industries: -1,
        job_position: 0.6,
        specialities: 0,
        jd_title_score: 1,
        jd_relevant_title_score: 0.6,
      },
      current_ideal_company: false,
      total_experience_span: 0.92,
      high_profile_reccomend: false,
      previous_ideal_company: false,
      relevant_experience_span: 0.92,
    },
    connectionDegree: null,
    messaged: null,
    industries: ["information technology & services"],
    companies: ["sectem technologies"],
    titles: ["full stack engineer"],
    totalExperience: "6 yrs 1 mos",
    functionalExperience: ["Management", "Accounting"],
    noteCount: "0",
    threadMarkers: null,
    activityCount: "1",
    recent_actvities: [
      {
        name: "Raja Zain",
        activityType: "ADDED_PROSPECT",
        activityText: "Added to Prospects",
        activityBy: 941,
        activityAt: "2021-09-21T04:51:21.954954+00:00",
      },
    ],
  },
  job,
  onClickComments = () => false,
  selectedProspectIDVariant,
  activeProspectVariant,
  dispatch,
  fullCardTag,
  setFullCardTag = (e) => false,
  pageNo,
  permissionsAllowed,
  currentHoverElement,
  location,
  hasComments = true,
  hasReactions = true,
  hasFeedback = true,
  hasSelection = true,
  hasScoring = true,
  disableFeedback = false,
  isAddingProfile = false,
  likedStatus,
  isLoading = false,
}) => {
  const qParams = qs.parse(location.search.split("?").pop());
  const pageNumber = parseInt("p" in qParams ? parseInt(qParams.p) : 0);
  const totalPages = Math.ceil(
    prospectData?.totalcurrentcount / PER_PAGE_COUNT
  );
  // console.log("=====================RevampProspectFullCard=========================", {
  //   hasComments,
  //   hasReactions,
  //   hasFeedback,
  //   hasSelection})

  const tabBar = ["Profile", "Comments"].filter((tab) =>
    !hasComments ? tab !== "Comments" : true
  );
  const [activeTab, setActiveTab] = useState(tabValue);
  const [highSkillsToggle, setHighSkillsToggle] = useState(true);
  const [midSkillsToggle, setMidSkillsToggle] = useState(false);
  const [lowSkillsToggle, setLowSkillsToggle] = useState(false);
  const [showActivities, setShowActivities] = useState(false);
  const {
    ref: emojiRef,
    isComponentVisible: showEmojiMenu,
    setIsComponentVisible: setShowEmojiMenu,
  } = useComponentVisible(false);

  const [allEmojies, setAllEmojies] = useState([]);
  const [oldDescription, setOldDescription] = useState();

  const [oldExperienceTitle, setOldExperienceTitle] = useState();
  const [showOtherSkills, setShowOtherSkills] = useState(false);
  const [showEmojiTool, setShowEmojiTool] = useState(false);
  const [currentEmoji, setCurrentEmoji] = useState(null);
  const [currentEmojiIndex, setCurrentEmojiIndex] = useState(null);

  const [highlightDescriptionTags, sethighlightDescriptionTags] = useState({});
  // const [tooltipText, setTooltipText] = useState('')

  const specSourceMap = {
    // "0": experience_title,
    // "1": experience_description,
    2: "From Company Industries", //company_industries,
    2: "From Company Industries", //company_industries,
    3: "From Company Specialties", //company_specialties,
    3: "From Company Specialties", //company_specialties,
    4: "From Company Description", //company_description
    4: "From Company Description", //company_description
  };

  const EmojiToolTip = ({ emoji, index }) => {
    return (
      <div
        className="EmojiToolTip"
        style={{
          marginLeft: index < 3 ? `${40 * ((index % 4) + 1)}px` : "100px",
        }}
      >
        {emoji?.names?.map((name, index) => (
          <span key={name}>{name}</span>
        ))}
      </div>
    );
  };
  const [fav, setFav] = useState(prospectData?.saved);
  const { jId } = useParams();
  useEffect(() => {
    setOldDescription(
      prospectData?.experience?.map((exp, index) => {
        return { id: index, description: exp?.description };
      })
    );
    setOldExperienceTitle(
      prospectData?.experience?.map((exp, index) => {
        return { id: index, title: exp?.title };
      })
    );
  }, [prospectData]);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      closeCompanyModal();
      document.body.style.overflow = "unset";
    };
  }, []);
  useEffect(() => {
    setAllEmojies(getAllEmojies());
  }, [prospects, prospectData]);

  /* Experiences */
  let experiences = React.useCallback(() => {
    if (
      prospectData?.experience?.length === 1 &&
      prospectData?.experience[0] === null
    ) {
      return [];
    }
    return prospectData?.experience?.map((experience, experienceIndex) => {
      const { description } = experience;
      return {
        ...experience,
        description,
        // newDescription,
        ...(prospectData?.scoring?.candidate_experience_found?.[
          experienceIndex
        ] || {}),
      };
    });
  }, [currentHoverElement, prospectData])();
  useEffect(() => { }, [currentHoverElement]);
  const innerScroll = (elem) => {
    const element = document.querySelector(`${elem}`);
    const yOffset = 120;
    let main = document.querySelector(".fullCardView");

    if (element) {
      smoothScroll(element, { block: "start" }).then(() => {
        main?.scrollTo({ top: main.scrollTop - yOffset, behavior: "smooth" });
      });
    }

    // element.scrollIntoView({ behavior: "smooth" });

    // const y =
    //   element.getBoundingClientRect().top - exMain.scrollTop + yOffset;
    // console.log(
    //   "element.getBoundingClientRect().top",
    //   element.getBoundingClientRect().top
    // );
    // console.log("exMain.scrollTop", exMain.scrollTop);
    // console.log("yyy", y);

    // element.scrollIntoView({ behavior: "smooth", block: "start" });
    // setTimeout(() => {
    //   main.scrollTop -= 120;
    // }, 500);

    // element.scrollTo()
  };

  //bugfix-NA-s21-1625 & bugfix-NA-s21-1624
  useEffect(() => {
    onActivitiesofCurrentProspect(prospectData?.jobProfileId);
  }, [prospectData, comments]);
  //end

  useEffect(() => {
    if (fullCardTag) {
      setTimeout(() => {
        innerScroll(`${fullCardTag}`);
        // const element = document.querySelector(`${fullCardTag}`);
        // const yOffset = -120;
        // if (element) {
        //   const y =
        //     element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //   let main = document.querySelector(".fullCardView");
        //   // main.scrollTo({ top: y, behavior: "smooth" });
        //   element.scrollIntoView({ behavior: "smooth", block: "start" });
        //   // element.scrollTo()
        // }
      }, 1000);
    }
    return () => {
      setFullCardTag(null);
    };
  }, [fullCardTag]);

  // SCORES FOR PROSPECT
  /*--------------------------------------------*/
  const titleScore = ((prospectData?.buck_score || 0) * 100) / 6;
  const experienceScore =
    ((prospectData?.scoring?.experience_score || 0) * 100) /
    (prospectData?.scoring?.experience_total || 1);
  const experienceSpecialitiesScore =
    ((prospectData?.scoring?.all_experience_scores?.specialities || 0) * 100) /
    1;
  const experienceTitlesScore =
    ((prospectData?.scoring?.all_experience_scores?.jd_title_score || 0) *
      100) /
    1;
  const experienceSkillsScore =
    ((prospectData?.scoring?.all_experience_scores?.skills || 0) * 100) / 1;
  // const experienceFunctionsScore = ((prospectData?.scoring?.all_experience_scores?.specialities || 0)*100)/1
  const educationScore =
    ((prospectData?.scoring?.education_score || 0) * 100) /
    (prospectData?.scoring?.education_total || 1);
  const skillsScore =
    ((prospectData?.scoring?.skill_score || 0) * 100) /
    (prospectData?.scoring?.skill_total || 1);
  const specialitiesScore =
    ((prospectData?.scoring?.specialty_score || 0) * 100) /
    (prospectData?.scoring?.specialty_total || 1);
  const companyScore = ((prospectData?.scoring?.company_score || 0) * 100) / 5;
  /*--------------------------------------------*/

  /*--------------------------------------------*/

  // const new_description = experience.skill.map((skill)=> experi)
  /*--------------------------------------------*/

  const functionalExperience_found =
    prospectData?.scoring?.functions_found &&
      Object.keys(prospectData?.scoring?.functions_found)?.length
      ? Object.keys(prospectData?.scoring?.functions_found)?.map((key) => ({
        span: prospectData?.scoring.functions_found[key].found_years,
        functionalExperience: key,
      }))
      : [];
  /*--------------------------------------------*/
  /* Specialities Found */
  const specialties_found =
    prospectData?.scoring?.specialties_found &&
      Object.keys(prospectData?.scoring?.specialties_found)?.length
      ? Object.keys(prospectData?.scoring?.specialties_found)?.map((key) => ({
        span: prospectData?.scoring?.specialties_found[key]?.found_years,
        speciality: key,
      }))
      : [];

  const newSpecialtiesRequired =
    prospectData?.scoring?.specialties_required?.length > 0
      ? prospectData?.scoring?.specialties_required.map((value) =>
        value.toLowerCase().trim()
      )
      : [];
  const missing_specialities = [];
  // if (!isEmpty(prospectData?.scoring?.specialties_found)) {
  newSpecialtiesRequired?.map((key) => {
    if (
      !Object.keys(prospectData?.scoring?.specialties_found).includes(
        key.toLowerCase().trim()
      )
    ) {
      missing_specialities.push({ speciality: key });
    }
  });
  // }

  /*--------------------------------------------*/

  /*--------------------------------------------*/
  /* Skills Found */

  const showHideHighSkills = () =>
    setHighSkillsToggle((prevState) => !prevState);
  const showHideMidSkills = () => setMidSkillsToggle((prevState) => !prevState);
  const showHideLowSkills = () => setLowSkillsToggle((prevState) => !prevState);

  const showActivitiesToggle = () =>
    setShowActivities((prevState) => !prevState);

  let prospectSkills =
    prospectData?.skills?.length > 0
      ? prospectData?.skills?.map((value) => value?.toLowerCase()?.trim())
      : [];
  let prospectSkillsProfile =
    prospectData?.scoring?.skills_profile?.length > 0
      ? prospectData?.scoring?.skills_profile?.map((value) =>
        value?.toLowerCase()?.trim()
      )
      : [];

  const allSkills = union(prospectSkills, prospectSkillsProfile);
  const newAllSkills = allSkills?.map((value) => value?.toLowerCase()?.trim());

  let must_haves_missing = [];
  let important_missing = [];
  let nice_to_haves_missing = [];

  if (prospectData?.scoring?.skills_required["must_haves"]) {
    Object.keys(prospectData?.scoring?.skills_required)?.map((type) => {
      if (typeof prospectData?.scoring?.skills_required[type] !== "undefined")
        prospectData?.scoring?.skills_required[type]?.map((skill) => {
          if (!newAllSkills.includes(skill?.toLowerCase()?.trim())) {
            switch (type) {
              case "must_haves":
                must_haves_missing?.push({ title: skill });
                break;
              case "important":
                important_missing?.push({ title: skill });
                break;
              case "nice_to_haves":
                nice_to_haves_missing?.push({ title: skill });
                break;
              default:
            }
          }
        });
    });
  }
  // ['skills', 'headline', 'summary', 'experience']
  const experienceSourceMap = ({ experienceIndex }) => {
    const exp = prospectData?.experience?.[experienceIndex];
    return exp?.title ? exp?.title : "";
  };

  const otherSourceMap = {
    skills: "From Profile",
    headline: "From Headline",
    summary: "From Summary",
  };

  let must_haves_skills = prospectData?.scoring?.skill_importance?.must_haves.map(
    (title) => ({
      title,
      source: (
        prospectData?.scoring?.skills_profile_location
          ?.filter(
            ({ name = "" }) =>
              name.trim().toLocaleLowerCase() ===
              title.trim().toLocaleLowerCase()
          )
          ?.pop()?.source || []
      ).map((itm) => {
        if (itm.type === "experience") {
          return experienceSourceMap(itm);
        } else {
          return otherSourceMap[itm.type];
        }
      }),
      span: getYrsAndMos(
        prospectData?.scoring?.candidate_experience_found?.reduce(
          (total, { skills }) => (skills?.includes(title) ? total + 1 : total),
          0.0
        )
      ),
    })
  );

  let important_skills = prospectData?.scoring?.skill_importance?.important.map(
    (title) => ({
      title,
      source: (
        prospectData?.scoring?.skills_profile_location
          ?.filter(
            ({ name = "" }) =>
              name.trim().toLocaleLowerCase() ===
              title.trim().toLocaleLowerCase()
          )
          ?.pop()?.source || []
      ).map((itm) => {
        if (itm.type === "experience") {
          return experienceSourceMap(itm);
        } else {
          return otherSourceMap[itm.type];
        }
      }),
      span: getYrsAndMos(
        prospectData?.scoring?.candidate_experience_found?.reduce(
          (total, { skills }) => (skills?.includes(title) ? total + 1 : total),
          0.0
        )
      ),
    })
  );

  let nice_to_haves_skills = prospectData?.scoring?.skill_importance?.nice_to_haves.map(
    (title) => ({
      title,
      source: (
        prospectData?.scoring?.skills_profile_location
          ?.filter(
            ({ name = "" }) =>
              name.trim().toLocaleLowerCase() ===
              title.trim().toLocaleLowerCase()
          )
          ?.pop()?.source || []
      ).map((itm) => {
        if (itm.type === "experience") {
          return experienceSourceMap(itm);
        } else {
          return otherSourceMap[itm.type];
        }
      }),
      span: getYrsAndMos(
        prospectData?.scoring?.candidate_experience_found?.reduce(
          (total, { skills }) => (skills?.includes(title) ? total + 1 : total),
          0.0
        )
      ),
    })
  );
  /*--------------------------------------------*/

  /*--------------------------------------------*/
  /* demographicFormatter */


  /*--------------------------------------------*/

  /*--------------------------------------------*/

  /* emoji */

  const toggleEmoji = () => {
    // setShowEmojiMenu((prevState) => !prevState);
    // dispatch(
    //   showEmojiPickerOnProspect(
    //     showEmojiPickerProspectID == prospectData.jobProfileId
    //       ? null
    //       : prospectData.jobProfileId
    //   )
    // );
  };

  const handleEmojiPicked = (emoji, prospect) => {
    const newEmoji = {
      emojiText: emoji?.emoji,
      emojiAscII: emoji?.unified,
      count: 1,
      isOwner: true,
    };
    setAllEmojies((prev) => {
      return [...prev, newEmoji];
    });
    dispatch(
      saveEmoji({
        noteId: null,
        jobProfileId: prospect?.jobProfileId,
        emojiText: emoji?.emoji,
        emojiAscII: emoji?.unified,
        jobId: job?.id,
        pageNo,
      })
    );
  };

  const deleteEmojiHandle = (emoji, prospect) => {
    setAllEmojies((prev) => {
      const newFiltered = prev?.filter(
        (item) => item?.emojiText !== emoji?.emojiText
      );
      return newFiltered;
    });

    dispatch(
      deleteEmoji({
        noteId: null,
        jobProfileId: prospect?.jobProfileId,
        emojiText: emoji?.emojiText,
        jobId: job?.id,
      })
    );
  };

  const getAllEmojies = () => {
    let emojies = [];
    prospectData?.emojies?.map((emoji) => {
      let existsIndex = emojies?.findIndex(
        (item) => emoji?.emojiText == item?.emojiText
      );
      if (existsIndex < 0) {
        emojies.push({
          emojiText: emoji?.emojiText,
          emojiASCII: emoji?.emojiASCII,
          count: 1,
          isOwner: emoji?.createdBy
            ? emoji?.createdBy?.isOwner
            : emoji?.isOwner,
          names: emoji?.createdBy ? [emoji?.createdBy?.name] : [emoji?.name],
        });
      } else {
        emojies[existsIndex]["count"] += 1;
        if (emoji?.createdBy?.isOwner || emoji?.isOwner == true)
          emojies[existsIndex]["isOwner"] =
            emoji?.createdBy?.isOwner || emoji?.isOwner;
        emojies[existsIndex]["names"].push(
          emoji?.createdBy ? emoji?.createdBy?.name : emoji?.name
        );
      }
    });
    return emojies;
  };

  const headline = prospectData?.headline?.split(" at ");

  const removeHoverHighlighter = (
    index,
    company_name,
    type,
    experienceIndex
  ) => {
    if (
      prospectData?.experience[experienceIndex]?.description ===
      oldDescription[experienceIndex]?.description
    ) {
      return;
    }
    prospectData.experience[experienceIndex].description =
      oldDescription[experienceIndex]?.description;
    dispatch(onHoverChangeProspectElement(prospectData, null, null, null));
  };

  const addHoverHighlighter = (index, company_name, type, experienceIndex) => {
    if (
      type === "speciality" &&
      (prospectData?.scoring?.candidate_experience_found?.[experienceIndex]
        ?.specialty_detected[index] === "4" ||
        prospectData?.scoring?.candidate_experience_found?.[experienceIndex]
          ?.specialty_detected[index] === "3")
    ) {
      return;
    }
    let item = "";
    prospectData.experience[experienceIndex].description =
      oldDescription[experienceIndex]?.description;
    if (type === "skill") {
      item =
        prospectData?.scoring?.candidate_experience_found?.[experienceIndex]
          ?.skills[index];
      let indices =
        prospectData?.scoring?.candidate_experience_found?.[experienceIndex]
          ?.text_spans[index];
      let newDescription =
        prospectData?.experience[experienceIndex]?.description;

      let adjustingIndexOffset = 0;
      // let description = prospectData.experience[experienceIndex].description;
      indices.map((indice) => {
        let startIndex = indice[0];
        let endIndex = indice[1];
        newDescription =
          newDescription.slice(0, parseInt(startIndex) + adjustingIndexOffset) +
          `<span>` +
          newDescription.slice(
            parseInt(startIndex) + adjustingIndexOffset,
            parseInt(endIndex) + adjustingIndexOffset
          ) +
          "</span>" +
          newDescription.slice(parseInt(endIndex) + adjustingIndexOffset);
        // newDescription = description.slice(0, parseInt(startIndex)+adjustingIndexOffset) + `<span id="S${experienceIndex}${indexSkill}">` + newDescription.slice(parseInt(startIndex)+adjustingIndexOffset, parseInt(endIndex)+adjustingIndexOffset) + "</span>" + newDescription.slice(parseInt(endIndex)+adjustingIndexOffset)

        // console.log(newDescription);
        adjustingIndexOffset += `<span>`.length + `</span>`.length;
        // console.log("adjustingIndexOffset", adjustingIndexOffset);
        // debugger;
      });

      prospectData.experience[experienceIndex].description = newDescription;
      dispatch(
        onHoverChangeProspectElement(prospectData, index, type, experienceIndex)
      );
    } else if (type === "speciality") {
      item =
        prospectData?.scoring?.candidate_experience_found?.[experienceIndex]
          ?.specialties[index];
      let indices =
        prospectData?.scoring?.candidate_experience_found?.[experienceIndex]
          ?.specialty_text_spans[index];
      let newDescription =
        prospectData?.experience[experienceIndex]?.description;
      let adjustingIndexOffset = 0;
      // let description = prospectData.experience[experienceIndex].description;
      indices.map((indice) => {
        let startIndex = indice[0];
        let endIndex = indice[1];
        newDescription =
          newDescription.slice(0, parseInt(startIndex) + adjustingIndexOffset) +
          `<span>` +
          newDescription.slice(
            parseInt(startIndex) + adjustingIndexOffset,
            parseInt(endIndex) + adjustingIndexOffset
          ) +
          "</span>" +
          newDescription.slice(parseInt(endIndex) + adjustingIndexOffset);
        // newDescription = description.slice(0, parseInt(startIndex)+adjustingIndexOffset) + `<span id="S${experienceIndex}${indexSkill}">` + newDescription.slice(parseInt(startIndex)+adjustingIndexOffset, parseInt(endIndex)+adjustingIndexOffset) + "</span>" + newDescription.slice(parseInt(endIndex)+adjustingIndexOffset)

        // console.log(newDescription);
        adjustingIndexOffset += `<span>`.length + `</span>`.length;
        // console.log("adjustingIndexOffset", adjustingIndexOffset);
        // debugger;
      });

      prospectData.experience[experienceIndex].description = newDescription;
      dispatch(
        onHoverChangeProspectElement(prospectData, index, type, experienceIndex)
      );
    }
  };
  const addHoverExpTitle = (index, company_name, type, experienceIndex) => {
    let item = "";
    prospectData.experience[experienceIndex].title =
      oldExperienceTitle[experienceIndex]?.title;
    if (type === "skill") {
      item =
        prospectData?.scoring?.candidate_experience_found?.[experienceIndex]
          ?.skills[index];
      let indices =
        prospectData?.scoring?.candidate_experience_found?.[experienceIndex]
          ?.text_spans[index];
      let newTitle = prospectData?.experience[experienceIndex]?.title;

      let adjustingIndexOffset = 0;

      indices.map((indice) => {
        let startIndex = indice[0];
        let endIndex = indice[1];
        newTitle =
          newTitle.slice(0, parseInt(startIndex) + adjustingIndexOffset) +
          `<span>` +
          newTitle.slice(
            parseInt(startIndex) + adjustingIndexOffset,
            parseInt(endIndex) + adjustingIndexOffset
          ) +
          "</span>" +
          newTitle.slice(parseInt(endIndex) + adjustingIndexOffset);

        // console.log(newTitle);
        adjustingIndexOffset += `<span>`.length + `</span>`.length;
        // console.log("adjustingIndexOffset", adjustingIndexOffset);
      });

      prospectData.experience[experienceIndex].title = newTitle;
      dispatch(
        onHoverChangeProspectElement(prospectData, index, type, experienceIndex)
      );
    } else if (type === "speciality") {
      item =
        prospectData?.scoring?.candidate_experience_found?.[experienceIndex]
          ?.specialties[index];
      let indices =
        prospectData?.scoring?.candidate_experience_found?.[experienceIndex]
          ?.specialty_text_spans[index];
      let newTitle = prospectData?.experience[experienceIndex]?.title;
      let adjustingIndexOffset = 0;

      indices.map((indice) => {
        let startIndex = indice[0];
        let endIndex = indice[1];
        newTitle =
          newTitle.slice(0, parseInt(startIndex) + adjustingIndexOffset) +
          `<span>` +
          newTitle.slice(
            parseInt(startIndex) + adjustingIndexOffset,
            parseInt(endIndex) + adjustingIndexOffset
          ) +
          "</span>" +
          newTitle.slice(parseInt(endIndex) + adjustingIndexOffset);

        // console.log(newTitle);
        adjustingIndexOffset += `<span>`.length + `</span>`.length;
        // console.log("adjustingIndexOffset", adjustingIndexOffset);
      });

      prospectData.experience[experienceIndex].title = newTitle;
      dispatch(
        onHoverChangeProspectElement(prospectData, index, type, experienceIndex)
      );
    }
  };

  const removeHoverExpTitle = (index, company_name, type, experienceIndex) => {
    if (
      prospectData.experience[experienceIndex].title ===
      oldExperienceTitle[experienceIndex]?.title
    ) {
      return;
    }
    prospectData.experience[experienceIndex].title =
      oldExperienceTitle[experienceIndex]?.title;
    dispatch(onHoverChangeProspectElement(prospectData, null, null, null));
  };

  // const element = document.querySelectorAll(
  //   `[data-name="${type} ${company_name} ${index} ${experienceIndex}"]`
  // );
  // element.forEach((item) => {
  //   item.classList.toggle("skillHighLight");
  // });

  const OtherSkillsRender = ({ title, index, favouriteState = [] }) => {
    const [isFoundOpenState, setIsFoundOpen] = useState(false);
    return (
      <p key={index} id={`otherSkillsRender_${index}`}>
        <span
          style={{
            padding: "1px 4px",
            cursor: "default",
          }}
        >
          {title?.length > 3 ? title : title?.toUpperCase()}{" "}
          <Tooltip
            hideArrow={false}
            placement="bottom"
            isOpen={isFoundOpenState}
            target={`otherSkillsRender_${index}`}
            toggle={() => setIsFoundOpen(!isFoundOpenState)}
          >
            from profile
          </Tooltip>
        </span>
      </p>
    );
  };

  const hasNextProspect = (profileIndex) => {
    return profileIndex < prospects?.length - 1 || pageNumber < totalPages - 1;
  };

  const hasPrevProspect = (profileIndex) => {
    return profileIndex > 0 || pageNumber > 0;
  };

  const getActivityText = (activity) => {
    switch (activity?.activityType) {
      case "EMOJI_ADDED":
        return "Emoji added";
      case "EMOJI_DELETED":
        return "Emoji deleted";
      case "COMMENT_ADDED":
        return "Comment added";
      case "COMMENT_DELETED":
        return "Comment deleted";
      case "CONNECT_MESSAGE_SENT":
        return "Messaged";
      case "SAVED_PROSPECTS":
        return "Liked";
      case "UNSAVED_PROSPECTS":
        return "Unliked";
      // case 'ARCHIVED_PROSPECTS':
      //   return "";
      // case 'UNARCHIVED_PROSPECTS':
      //   return "";
      // case 'ADDED_PROSPECT':
      //   return "";
      // case 'DOWNLOAD_PROSPECTS':
      //   return "";

      default:
        return activity?.activityText;
    }
  };

  return (
    <div
      className={`revampProspectFullCard ${showFullCard ? "expanded" : ""}`}
      onClick={(e) => e.stopPropagation()}
    >
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
          }}
        >
          <Loading />
        </div>
      ) : (
        <div>
          <div className="revampIntro" data-name={`current-title`}>
            <div className="revampSideCardContainer">
              <div className="revampSkillbarContainer">
                <div className="revampAction">
                  <p>
                    {profileIndex || profileIndex === 0
                      ? pageNumber
                        ? profileIndex + 1 + pageNumber * PER_PAGE_COUNT
                        : profileIndex + 1
                      : ""}
                  </p>
                  {hasSelection && (
                    <Input
                      type="checkbox"
                      className="form-control-lg"
                      checked={prospectData?.id in selectedProspects}
                      hidden={location.search?.includes("rR")}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        onSelect(e);
                      }}
                    />
                  )}
                </div>
                {/* <div className="revampFav">
                {hasFeedback && <Button
                  hidden={location.search?.includes("rR")}
                  onClick={(e) => {
                    toast.dismiss();
                    e.stopPropagation();
                    setFav((c) => !c);
                    if (!fav) {
                      toast(
                        // `Profile ${!fav ? "liked" : "unliked"}!`,
                        (props) => {
                          return (
                            <ProfileLiked
                              header={`Profile ${!fav ? "liked" : "unliked"}!`}
                              description="Review their profile to let Qlu recommend you more relevant ones."
                            >
                              <Button
                                className="reviewProfileBtn"
                                onClick={() => {
                                  handleRecommendedCard(prospectData);
                                  // if (
                                  //   reviewProfileTitle == "Review Profile" &&
                                  //   showReview
                                  // ) {
                                  //   setReviewProfileTitle("Review Profile Again");
                                  // }
                                }}
                              >
                                Review Profile
                              </Button>
                            </ProfileLiked>
                          );
                        },
                        {
                          hideProgressBar: true,
                          position: "top-left",
                          style: {
                            backgroundColor: "#A0A0A0",
                            borderRadius: "8px",
                            width: "514px",
                            color: "black",
                            fontFamily: "Inter",
                          },
                        }
                      );
                    } else {
                    }

                    if (!fav) {
                      const data = {
                        index: elementIndex,
                        status: "RECOMMENDED_YES",
                        jobProfileId: prospectData?.jobProfileId,
                      };
                      dispatch(likedProfile(data));
                    }
                    if (qParams.fF == "s") {
                      if (fav) {
                        const data = {
                          index: elementIndex,
                          status: null,
                          jobProfileId: prospectData?.jobProfileId,
                        };
                        dispatch(_deleteProspect(elementIndex));
                        dispatch(likedProfile(data));
                      }
                    }
                    if (qParams.fF == "mb") {
                      if (!fav) {
                        const data = {
                          index: elementIndex,
                          status: "RECOMMENDED_YES",
                          jobProfileId: prospectData?.jobProfileId,
                        };
                        dispatch(likedProfile(data));
                        dispatch(_deleteProspect(elementIndex));
                      }
                    }
                    onClickSave(
                      [prospectData?.jobProfileId],
                      prospectData?.saved
                    );
                  }}
                  color=""
                  width="24"
                  height="24"
                  // className="bookmark"
                  size="sm"
                >
                  <FavouriteSVG
                    color={prospectData?.saved ? "#c91010" : "#CDD1D7"}
                    fill={prospectData?.saved ? "#c91010" : "none"}
                    width="28"
                    height="28"
                  />
                </Button>}
              </div> */}
                <div className="revampIntroSection">
                  <div className="revampImageSection">
                    <div
                      style={{
                        marginRight: hasPrevProspect(profileIndex)
                          ? "0"
                          : "31px",
                      }}
                      onClick={() => {
                        if (hasPrevProspect(profileIndex))
                          handleFullCardLeft && handleFullCardLeft();
                      }}
                    >
                      {hasPrevProspect(profileIndex) && (
                        <React.Fragment>
                          <CaretDown
                            width="30"
                            height="15"
                            style={{
                              transform: "rotate(90deg)",
                              cursor: "pointer",
                            }}
                          />
                        </React.Fragment>
                      )}
                    </div>
                    <div>
                      <ProfileDisplayImage
                        width="110"
                        height="110"
                        src={prospectData?.imageUrl}
                        className="profileImg"
                      />
                    </div>
                    <div
                      style={{
                        marginLeft:
                          hasNextProspect(profileIndex) &&
                            !prospects[profileIndex + 1]?.hidden
                            ? "0px"
                            : "31px",
                      }}
                      onClick={() => {
                        Mixpanel.track(`next profile button - detailed view`);
                        if (
                          hasNextProspect(profileIndex) &&
                          !prospects[profileIndex + 1]?.hidden
                        )
                          handleFullCardRight && handleFullCardRight();
                      }}
                    >
                      {hasNextProspect(profileIndex) &&
                        !prospects[profileIndex + 1]?.hidden && (
                          <CaretDown
                            width="30"
                            height="15"
                            style={{
                              transform: "translateY(2px) rotate(-90deg)",
                              cursor: "pointer",
                            }}
                          />
                        )}
                    </div>
                  </div>
                  <div
                    className="prospectCard-titleInfo text-center"
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <p className="nameTitle">
                      {prospectData?.fullName}
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(
                            `https://www.linkedin.com/in/${prospectData?.publicIdentifier}`
                          );
                        }}
                      >
                        <LinkedInLogo
                          width="20"
                          height="20"
                          className="linkedInLogo"
                        />
                      </span>
                    </p>

                    <p className="" data-name={`current-title`}>
                      <div className="jobTitle">
                        {prospectData?.headline}
                        {prospectData?.experience?.[0]?.company_name &&
                          !prospectData?.headline?.includes(
                            prospectData?.experience?.[0]?.company_name
                          ) && (
                            <span>
                              {prospectData?.experience?.[0]?.company_name
                                ? `@ ${prospectData?.experience?.[0]?.company_name}`
                                : ""}
                            </span>
                          )}
                        {/* {`${prospectData?.experience?.[0]?.title ? prospectData?.experience?.[0]?.title : ""}${prospectData?.experience?.[0]?.title && prospectData?.experience?.[0]?.company_name ? " @ " : ""}`}
                      <span>{prospectData?.experience?.[0]?.company_name ? prospectData?.experience?.[0]?.company_name : ""}</span> */}
                      </div>
                    </p>

                    <p className="location">{prospectData?.locality}</p>
                  </div>
                  <div className="commentsAndReactionsContainer">
                    {(permissionsAllowed === "*" ||
                      (permissionsAllowed?.length &&
                        permissionsAllowed?.length > 0 &&
                        permissionsAllowed?.includes(
                          "CREATE_READ_COMMENTS"
                        ))) &&
                      hasComments ? (
                      <div className="collab">
                        <div className="btnP">
                          <Button
                            color=""
                            className="commentIcon"
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              Mixpanel.track(`Comments Icon - detailed view`);
                              setActiveTab("Comments");
                            }}
                          >
                            <MessageCircle width="24" height="24" stroke={2} />{" "}
                            {/* //bugfix-NA-s21-1625 & bugfix-NA-s21-1624s */}
                            <span className="count">
                              {
                                ActivitiviesOfCurrentPorspet.filter((i) => {
                                  return i?.activityType == "COMMENT_ADDED";
                                }).length
                              }
                            </span>
                            {/* {countOfComments != 0 ? (
                              <span className="count">{countOfComments}</span>
                            ) : (
                              <span></span>
                            )} */}
                            {/* {prospectData?.noteCount !== "0" ? (
                              <span className="count">
                                {comments?.length == 0
                                  ? prospectData?.noteCount
                                  : comments?.length}
                              </span>
                            ) : (
                              <span className="count">
                                {comments?.length == 0 ? "" : comments?.length}
                              </span>
                            )} */}
                          </Button>
                        </div>

                        {hasReactions && (
                          <div
                            className="emojiContainer"
                            onClick={(e) => e.stopPropagation()}
                            style={{ maxWidth: "200px" }}
                          >
                            {allEmojies?.map((emoji, index) => (
                              <div
                                className={`emojies ${emoji.isOwner ? "emojiOwner" : ""
                                  }`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Button
                                  color=""
                                  className=""
                                  size="sm"
                                  onClick={() => {
                                    emoji.isOwner
                                      ? deleteEmojiHandle(emoji, prospectData)
                                      : handleEmojiPicked(emoji, prospectData);
                                    setCurrentEmoji({});
                                    setCurrentEmojiIndex(null);
                                  }}
                                  onMouseOver={() => {
                                    setCurrentEmoji(emoji);
                                    setCurrentEmojiIndex(index);
                                    setShowEmojiTool(true);
                                  }}
                                  onMouseLeave={() => {
                                    setShowEmojiTool(false);
                                  }}
                                >
                                  <span className="emoji">
                                    {emoji.emojiText}
                                  </span>{" "}
                                  <span className="count">{emoji.count}</span>
                                </Button>
                              </div>
                            ))}
                          </div>
                        )}

                        <div className="">
                          {hasReactions && (
                            <Button
                              color=""
                              className="btnP"
                              size="sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                Mixpanel.track(`React box - detailed view`);
                                setShowEmojiMenu((prevState) => !prevState);
                              }}
                            >
                              <Emoji
                                width="24"
                                height="24"
                                stroke={2}
                                className="toggleEmoji"
                              />
                            </Button>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {showEmojiTool && currentEmoji && hasReactions && (
                      <EmojiToolTip
                        emoji={currentEmoji}
                        index={currentEmojiIndex}
                      />
                    )}
                  </div>
                </div>
                {hasScoring && (
                  <div className="revampSkillBars">
                    <div
                      className="row"
                      style={{
                        marginBottom: "",
                        cursor: "pointer",
                      }}
                      onClick={() => innerScroll(`[data-name="current-title"]`)}
                    >
                      <div className="col-4">
                        <p>Current Title</p>
                      </div>
                      <div className="col-8">
                        <Progress className="barFull" value={titleScore} />
                      </div>
                    </div>
                    {/* <div className="revampSkillBorder"></div> */}
                    {/* <div className={isDetail ? "horizontalLine" : ""}></div> */}
                    {isDetail ? (
                      <div className="experienceScore">
                        <div
                          className="row"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            innerScroll(`[data-name="experience"]`);
                          }}
                        >
                          <div className="col-4">
                            <p>Experience</p>
                          </div>
                          <div className="col-8">
                            <Progress
                              className="barFull"
                              value={experienceScore}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="row"
                        onClick={(e) => {
                          e.stopPropagation();
                          // innerScroll(`[data-name="experience"]`);
                        }}
                      >
                        <div className="col-4">
                          <p>Experience</p>
                        </div>
                        <div className="col-8">
                          <Progress
                            className="barFull"
                            value={experienceScore}
                          />
                        </div>
                      </div>
                    )}
                    {/* <div className="revampSkillBorder"></div> */}
                    <div
                      className="row"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        innerScroll(`[data-name="education"]`);
                      }}
                    >
                      <div className="col-4">
                        <p>Education</p>
                      </div>
                      <div className="col-8">
                        <Progress className="barFull" value={educationScore} />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        innerScroll(`[data-name="skills"]`);
                      }}
                    >
                      <div className="col-4">
                        <p>Skills</p>
                      </div>
                      <div className="col-8">
                        <Progress className="barFull" value={skillsScore} />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        innerScroll(`[data-name="specialities"]`);
                      }}
                    >
                      <div className="col-4">
                        <p>Business</p>
                      </div>
                      <div className="col-8">
                        <Progress
                          className="barFull"
                          value={specialitiesScore}
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ cursor: "pointer" }}
                      onClick={() => innerScroll(`[data-name="current-title"]`)}
                    >
                      <div className="col-4">
                        <p>Company</p>
                      </div>
                      <div className="col-8">
                        <Progress className="barFull" value={companyScore} />
                      </div>
                    </div>
                    {qParams.fF != "ar" && hasFeedback && (
                      <div
                        className="profileLikedStatusSelector"
                        style={{ marginTop: "20px" }}
                      >
                        <div
                          className="revampSkillBorder"
                          style={{ marginBottom: "1.2rem" }}
                        ></div>
                        <div>
                          {!disableFeedback && (
                            <SocialHeartButton
                              index={profileIndex}
                              jobProfileId={prospectData?.jobProfileId}
                              profileId={prospectData?.id}
                              maybe={prospectData?.maybe}
                              saved={prospectData?.saved}
                              onClickSave={onClickSave}
                              mixpanelActionType={"detailed view"}
                              style={{
                                width: "calc(33.333% - 6.7px)",
                              }}
                            />
                          )}
                          {!disableFeedback && (
                            <SocialMaybeButton
                              index={profileIndex}
                              jobProfileId={prospectData?.jobProfileId}
                              profileId={prospectData?.id}
                              maybe={prospectData?.maybe}
                              saved={prospectData?.saved}
                              onClickSave={onClickSave}
                              onClickMaybe={onClickMaybe}
                              mixpanelActionType={"detailed view"}
                              style={{
                                width: "calc(33.333% - 6.7px)",
                              }}
                            />
                          )}
                          {!disableFeedback && (
                            <SocialRejectButton
                              index={profileIndex}
                              jobProfileId={prospectData?.jobProfileId}
                              archived={prospectData?.archived}
                              saved={prospectData?.saved}
                              onClickSave={onClickSave}
                              mixpanelActionType={"detailed view"}
                              isAddingProfile={isAddingProfile}
                              style={{
                                width: "calc(33.333% - 6.7px)",
                              }}
                            />
                          )}
                          {/* {selectLikedValues.map((item, index) => {
                        const { title, className, id, val } = item;
                        const active = (val, likedStatus) => {
                          // debugger;
                          if (
                            val == likedStatus?.[elementIndex] &&
                            likedStatus?.[elementIndex] != "RECOMMENDED_YES"
                          ) {
                            return true;
                          } else if (val === "RECOMMENDED_YES") {
                            if (prospectData?.saved) {
                              return true;
                            }
                          } else if (likedStatus?.[elementIndex] == "") {
                            return false;
                          } else {
                            return false;
                          }
                        };
                        return (
                          <ProfileLikedSelectors
                            index={index}
                            title={title}
                            className={className}
                            val={val}
                            id={id}
                            elementIndex={prospectData?.jobProfileId}
                            active={active(val, likedStatus)}
                            onClick={(liked) => {
                              toast.dismiss();
                              const likedMap={
                                RECOMMENDED_YES: 'Like',
                                RECOMMENDED_MAYBE: 'Maybe',
                                RECOMMENDED_NO: 'Dislike',
                              }
                              if(Object.keys(likedMap).includes(liked)){
                                Mixpanel.track(`${likedMap[liked]} - detailed view`)
                              }
                              if (
                                liked == "RECOMMENDED_MAYBE" &&
                                likedStatus?.[elementIndex] ==
                                  "RECOMMENDED_MAYBE"
                              ) {
                                const data = {
                                  index: elementIndex,
                                  status: null,
                                  jobProfileId: prospectData?.jobProfileId,
                                };
                                dispatch(likedProfile(data));
                                return;
                              }
                              if (
                                liked == "RECOMMENDED_YES" &&
                                likedStatus?.[elementIndex] == "RECOMMENDED_YES"
                              ) {
                                const data = {
                                  index: elementIndex,
                                  status: null,
                                  jobProfileId: prospectData?.jobProfileId,
                                };
                                dispatch(likedProfile(data));
                                setFav(false);
                                onClickSave(
                                  [prospectData?.jobProfileId],
                                  prospectData?.saved
                                );
                                return;
                              }
                              const data = {
                                index: elementIndex,
                                status: val,
                                jobProfileId: prospectData?.jobProfileId,
                              };

                              dispatch(likedProfile(data));

                              if (liked == "RECOMMENDED_YES") {
                                toast(
                                  // `Profile ${!fav ? "liked" : "unliked"}!`,
                                  ({ closeToast }) => (
                                    <ProfileLiked
                                      header={`Profile liked!`}
                                      description="Review their profile to let Qlu recommend you more relevant ones."
                                    >
                                      <Button
                                        className="reviewProfileBtn"
                                        onClick={() => {
                                          handleRecommendedCard(prospectData);
                                          closeToast();
                                          // if (
                                          //   reviewProfileTitle == "Review Profile" &&
                                          //   showReview
                                          // ) {
                                          //   setReviewProfileTitle("Review Profile Again");
                                          // }
                                        }}
                                      >
                                        Review Profile
                                      </Button>
                                    </ProfileLiked>
                                  ),
                                  {
                                    position: "top-left",
                                    hideProgressBar: true,
                                    style: {
                                      backgroundColor: "#A0A0A0",
                                      borderRadius: "8px",
                                      width: "514px",
                                      color: "black",
                                      fontFamily: "Inter",
                                    },
                                  }
                                );

                                onClickSave(
                                  [prospectData?.jobProfileId],
                                  prospectData?.saved
                                );
                              } else if (liked == "RECOMMENDED_NO") {
                                if (prospectData?.saved) {
                                  setFav(false);
                                  onClickSave(
                                    [prospectData?.jobProfileId],
                                    prospectData?.saved
                                  );
                                }
                                dispatch(
                                  archiveProspects(jId, [
                                    prospectData?.jobProfileId,
                                  ])
                                );
                                dispatch(_deleteCounter(deleteCount + 1));
                                toast(
                                  `Prospect moved to disliked folder`,
                                  {
                                    hideProgressBar: true,
                                    position: "top-left",
                                    style: {
                                      backgroundColor: "#A0A0A0",
                                      borderRadius: "8px",
                                      width: "400px",
                                      color: "black",
                                      fontFamily: "Inter",
                                    },
                                  }
                                );
                                // onClickCard();
                                //  push(`${pathname}?${qs.stringify({ ...newParams, fF: "a" })}`);
                              } else if (liked == "RECOMMENDED_MAYBE") {
                                if (prospectData?.saved) {
                                  setFav(false);
                                  onClickSave(
                                    [prospectData?.jobProfileId],
                                    prospectData?.saved
                                  );
                                }
                                toast("Thanks for the feedback!", {
                                  hideProgressBar: true,
                                  position: "top-left",
                                  // containerId: "top-left",
                                  style: {
                                    backgroundColor: "#A0A0A0",
                                    borderRadius: "8px",

                                    width: "300px",
                                    color: "black",
                                    fontFamily: "Inter",
                                  },
                                });
                              }
                              if (liked != "RECOMMENDED_NO") {
                              }
                            }}
                          />
                        );
                      })} */}
                        </div>
                      </div>
                    )}
                    <div
                      className="revampSkillBorder"
                      style={{ marginTop: "1.2rem" }}
                    ></div>
                    {(permissionsAllowed === "*" ||
                      (permissionsAllowed?.length &&
                        permissionsAllowed?.length > 0 &&
                        permissionsAllowed?.includes("VIEW_TRIBE_ACTIVITY"))) &&
                      hasComments ? (
                      <>
                        <div className="revampActivities">
                          <div className="activityTitle">
                            Activity <span className="globalDotStyle">•</span>
                            <span>
                              {prospectData?.recent_actvities?.length}
                            </span>
                          </div>
                          <div
                            className="activityView"
                            onClick={showActivitiesToggle}
                          >
                            View
                          </div>
                        </div>
                        <Collapse
                          className={`${showActivities ? "activitiesExpanded" : ""
                            } `}
                          isOpen={showActivities}
                        >
                          <div className="activitiesSection">
                            {/*bugfix-NA-s21-1625 & bugfix-NA-s21-1624 */}

                            {ActivitiviesOfCurrentPorspet?.map(
                              (activity, key) =>
                                key >= 4 ? (
                                  ""
                                ) : (
                                  <p key={key}>
                                    <span>
                                      {getActivityText(activity)} by{" "}
                                      <span className="activityBy">
                                        {activity?.activityBy
                                          ? activity?.name
                                          : "Qlu"}
                                      </span>
                                    </span>
                                    <span className="time">
                                      {
                                        moment(activity?.activityAt).fromNow()
                                        // .format("MMM YYYY")
                                      }
                                    </span>
                                  </p>
                                )
                            )}
                          </div>
                        </Collapse>
                      </>
                    ) : (
                      ""
                    )}
                    {showEmojiMenu && hasFeedback ? (
                      <div
                        className="emojiMenuContainer"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        ref={emojiRef}
                      >
                        <EmojiPicker
                          handleEmojiPicked={handleEmojiPicked}
                          prospect={prospectData}
                        />
                      </div>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                )}
              </div>
            </div>
            <div className="revampMainContainer">
              <div className="headStickyContainer">
                <div className="headSection">
                  {/* <div className="left">
          <ProfileDisplayImage
            width="72"
            height="72"
            src={prospectData.imageUrl}
            className="prospectAvatar"
          />
          <div className="prospectTitleContainer">
            <div className="prospectName">
              {prospectData.fullName}{" "}
              <span>
                <img
                  width="20"
                  height="20"
                  src={LinkedInLogo}
                  className="linkedInLogo"
                />
              </span>
            </div>
            <div className="prospectJobTitle">
              {headline[0]}
              {headline[1] ? (
                <>
                  {" "}
                  @ <span className="prospectJobCompany">{headline[1]}</span>
                </>
              ) : null}
            </div>
            <div className="prospectLocation">{prospectData.location}</div>
          </div>
        </div> */}
                  <div className="right">
                    <div className="prospectTabBar">
                      {tabBar.map((tab, index) =>
                        tab.toLowerCase().trim() === "profile" ||
                          (tab.toLowerCase().trim() === "comments" &&
                            permissionsAllowed === "*") ||
                          (permissionsAllowed?.length &&
                            permissionsAllowed?.length > 0 &&
                            permissionsAllowed?.includes(
                              "CREATE_READ_COMMENTS"
                            )) ? (
                          <div
                            key={index}
                            className={`tab ${activeTab == tab ? "active" : null
                              }`}
                            onClick={() => {
                              Mixpanel.track(`${tab} - detailed view`);
                              setActiveTab(tab);
                            }}
                          >
                            {tab}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </div>
                    <div className="prospectClose" onClick={onClickCard}>
                      <CrossSvg height="16" width="16" />
                    </div>
                  </div>
                </div>
              </div>
              {activeTab == "Profile" ? (
                <>
                  {prospectData?.summary?.length ? (
                    <div
                      className="revampAbout"
                      data-company="company"
                      data-name="current-title"
                    >
                      <div className="columnHeading">About</div>
                      <div className="summary">
                        {/* <ReactReadMoreReadLess
                        charLimit={500}
                        readMoreStyle={{
                          color: "#A0A0A0",
                        }}
                        readLessStyle={{
                          color: "#A0A0A0",
                        }}
                        readMoreText={"See more ▼"}
                        readLessText={"See less ▲"}
                      >
                        {getTaggedText(highlightDescriptionTags?.tags, highlightDescriptionTags.indices, prospectData?.summary)}
                      </ReactReadMoreReadLess> */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: getTaggedText(
                              highlightDescriptionTags?.tags,
                              highlightDescriptionTags.indices,
                              prospectData?.summary
                            ),
                          }}
                        ></div>
                      </div>
                      <div className="tagsContainer">
                        <p>Skills</p>
                        <div>
                          {prospectData?.scoring?.detections_in_summary?.skills.map(
                            (tag, index) => (
                              <p
                                key={index}
                                onMouseEnter={(e) => {
                                  sethighlightDescriptionTags({
                                    tags: tag ? [tag] : [],
                                    indices:
                                      prospectData?.scoring
                                        ?.detections_in_summary?.text_spans[
                                      index
                                      ] || [],
                                  });
                                }}
                                onMouseLeave={(e) => {
                                  sethighlightDescriptionTags({});
                                }}
                              >
                                {tag}
                              </p>
                            )
                          )}
                        </div>
                      </div>
                      <div className="tagsContainer">
                        <p>Business</p>
                        <div>
                          {prospectData?.scoring?.detections_in_summary?.specialties.map(
                            (tag, index) => (
                              <p
                                key={index}
                                onMouseEnter={(e) => {
                                  sethighlightDescriptionTags({
                                    tags: tag ? [tag] : [],
                                    indices:
                                      prospectData?.scoring
                                        ?.detections_in_summary
                                        ?.specialty_text_spans[index] || [],
                                  });
                                }}
                                onMouseLeave={(e) => {
                                  sethighlightDescriptionTags({});
                                }}
                              >
                                {tag}
                              </p>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="revampMain">
                    <div className="experiencePanel" data-name="experience">
                      <div className="columnHeading">Experience</div>
                      <div className="experienceList">
                        {experiences?.map((experience, experienceIndex) => {
                          let duplicate;
                          duplicate =
                            experienceIndex > 0 &&
                            experience?.company_name ===
                            experiences[experienceIndex - 1]?.company_name;
                          // console.log("duplicate : ", duplicate);
                          return (
                            <div
                              className="single-experience"
                              key={experienceIndex}
                              onMouseEnter={() => {
                                removeHoverHighlighter(
                                  1,
                                  2,
                                  1,
                                  experienceIndex
                                );
                                removeHoverExpTitle(1, 2, 1, experienceIndex);
                              }}
                              onMouseLeave={() => {
                                removeHoverHighlighter(
                                  1,
                                  2,
                                  1,
                                  experienceIndex
                                );
                                removeHoverExpTitle(1, 2, 1, experienceIndex);
                              }}
                            >
                              <div
                                className="experience-date"
                                style={{ marginTop: duplicate && "1.1em" }}
                              >
                                {!duplicate &&
                                  (experience?.logo ? (
                                    // <img
                                    //   src={experience?.logo}
                                    //   alt={experience?.logo}
                                    //   width="46"
                                    //   height="46"
                                    //   loading="lazy"
                                    //   style={{
                                    //     marginBottom: "24px",
                                    //     borderRadius: "8px",
                                    //   }}
                                    // />
                                    <CompanyDisplayImage
                                      width="46"
                                      height="46"
                                      src={experience?.logo}
                                    />
                                  ) : (
                                    <span
                                      className="placeHolderContainer"
                                      style={{
                                        marginBottom: "24px",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <CompanySvg width={24} height={24} />
                                    </span>
                                  ))}
                                <p
                                  className="experience-span"
                                  style={{
                                    marginBottom: "14px",
                                  }}
                                >
                                  {/* {moment(
                                  `${experience?.start?.split("-")[1]}-${
                                    experience?.start?.split("-")[0]
                                  }`
                                ).format("MMM YYYY")} */}
                                  {getValidDate(experience?.start)} -{" "}
                                  {/* {experience?.end?.length > 0
                                  ? moment(
                                      `${experience?.end?.split("-")[1]}-${
                                        experience?.end?.split("-")[0]
                                      }`
                                    ).format("MMM YYYY")
                                  : "Now"} */}
                                  {getValidDate(experience?.end) === ""
                                    ? "Now"
                                    : getValidDate(experience?.end)}
                                </p>
                                <p>{getYrsAndMos(experience?.span)}</p>
                              </div>
                              <div
                                className={
                                  experienceIndex ==
                                    prospectData?.experience?.length - 1
                                    ? "experience-info noBorder"
                                    : "experience-info"
                                }
                              >
                                {!duplicate && (
                                  <p
                                    className="title_company"
                                    style={{
                                      cursor: "pointer",
                                      width: "fit-content",
                                    }}
                                    onClick={() => {
                                      Mixpanel.track(
                                        `company name - detailed view`
                                      );
                                      showCompanyModal(
                                        experience,
                                        (e) => false,
                                        (e) => false,
                                        experienceIndex
                                      );
                                    }}
                                    onMouseEnter={() => {
                                      removeHoverHighlighter(
                                        1,
                                        2,
                                        1,
                                        experienceIndex
                                      );
                                      removeHoverExpTitle(
                                        1,
                                        2,
                                        1,
                                        experienceIndex
                                      );
                                    }}
                                    onMouseLeave={() => {
                                      removeHoverHighlighter(
                                        1,
                                        2,
                                        1,
                                        experienceIndex
                                      );
                                      removeHoverExpTitle(
                                        1,
                                        2,
                                        1,
                                        experienceIndex
                                      );
                                    }}
                                  >
                                    <span>{experience?.company_name}</span>
                                  </p>
                                )}

                                <p
                                  className="title_exp"
                                  dangerouslySetInnerHTML={{
                                    __html: experience?.title,
                                  }}
                                  onMouseEnter={() => {
                                    removeHoverHighlighter(
                                      1,
                                      2,
                                      1,
                                      experienceIndex
                                    );
                                    removeHoverExpTitle(
                                      1,
                                      2,
                                      1,
                                      experienceIndex
                                    );
                                  }}
                                  onMouseLeave={() => {
                                    removeHoverHighlighter(
                                      1,
                                      2,
                                      1,
                                      experienceIndex
                                    );
                                    removeHoverExpTitle(
                                      1,
                                      2,
                                      1,
                                      experienceIndex
                                    );
                                  }}
                                >
                                  {/* <span className="duration"><span className="globalDotStyle">•</span> {getYrsAndMos(experience.span)}</span> */}
                                </p>
                                <p className="title_location">
                                  {experience?.location}
                                </p>
                                <p
                                  className="summary"
                                  dangerouslySetInnerHTML={{
                                    __html: experience?.description,
                                  }}
                                  onMouseEnter={() => {
                                    removeHoverHighlighter(
                                      1,
                                      2,
                                      1,
                                      experienceIndex
                                    );
                                    removeHoverExpTitle(
                                      1,
                                      2,
                                      1,
                                      experienceIndex
                                    );
                                  }}
                                ></p>
                                {experience?.skills?.length > 0 ? (
                                  <>
                                    <div
                                      className="experience-skills"
                                      onMouseEnter={() => {
                                        removeHoverHighlighter(
                                          1,
                                          2,
                                          1,
                                          experienceIndex
                                        );
                                        removeHoverExpTitle(
                                          1,
                                          2,
                                          1,
                                          experienceIndex
                                        );
                                      }}
                                      onMouseLeave={() => {
                                        removeHoverHighlighter(
                                          1,
                                          2,
                                          1,
                                          experienceIndex
                                        );
                                        removeHoverExpTitle(
                                          1,
                                          2,
                                          1,
                                          experienceIndex
                                        );
                                      }}
                                    >
                                      <p>Skills</p>
                                      <div className="tablets">
                                        {experience?.skills?.map(
                                          (skill, index) => {
                                            const detected =
                                              prospectData?.scoring
                                                ?.candidate_experience_found
                                                ?.length &&
                                              typeof prospectData?.scoring
                                                ?.candidate_experience_found?.[
                                                experienceIndex
                                              ]?.detected !== "undefined" &&
                                              prospectData?.scoring
                                                ?.candidate_experience_found?.[
                                                experienceIndex
                                              ]?.detected[index] === "0";
                                            return (
                                              <>
                                                <p
                                                  onMouseOver={(e) => {
                                                    e.stopPropagation();
                                                    !detected
                                                      ? addHoverHighlighter(
                                                        index,
                                                        experience?.company_name,
                                                        "skill",
                                                        experienceIndex
                                                      )
                                                      : addHoverExpTitle(
                                                        index,
                                                        experience?.company_name,
                                                        "skill",
                                                        experienceIndex
                                                      );
                                                  }}
                                                  onMouseLeave={() => {
                                                    !detected
                                                      ? removeHoverHighlighter(
                                                        index,
                                                        experience?.company_name,
                                                        "skill",
                                                        experienceIndex
                                                      )
                                                      : removeHoverExpTitle(
                                                        index,
                                                        experience?.company_name,
                                                        "skill",
                                                        experienceIndex
                                                      );
                                                  }}
                                                  key={index}
                                                // data-tooltip={
                                                //   "From Title"
                                                // }
                                                >
                                                  {skill}
                                                </p>
                                              </>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="experience-skills"></div>
                                )}
                                {experience?.specialties?.length > 0 ? (
                                  <>
                                    <div
                                      className="experience-specialities"
                                      onMouseEnter={() => {
                                        removeHoverHighlighter(
                                          1,
                                          2,
                                          1,
                                          experienceIndex
                                        );
                                        removeHoverExpTitle(
                                          1,
                                          2,
                                          1,
                                          experienceIndex
                                        );
                                      }}
                                      onMouseLeave={() => {
                                        removeHoverHighlighter(
                                          1,
                                          2,
                                          1,
                                          experienceIndex
                                        );
                                        removeHoverExpTitle(
                                          1,
                                          2,
                                          1,
                                          experienceIndex
                                        );
                                      }}
                                    >
                                      <p>Business</p>
                                      <div className="tablets">
                                        {experience?.specialties?.map(
                                          (speciality, index) => {
                                            const detected =
                                              prospectData?.scoring
                                                ?.candidate_experience_found
                                                ?.length &&
                                              typeof prospectData?.scoring
                                                ?.candidate_experience_found?.[
                                                experienceIndex
                                              ]?.specialty_detected !==
                                              "undefined" &&
                                              prospectData?.scoring
                                                ?.candidate_experience_found?.[
                                                experienceIndex
                                              ]?.specialty_detected[index] ===
                                              "0";
                                            return (
                                              <>
                                                <p
                                                  onMouseEnter={(e) => {
                                                    e.stopPropagation();

                                                    !detected
                                                      ? addHoverHighlighter(
                                                        index,
                                                        experience?.company_name,
                                                        "speciality",
                                                        experienceIndex
                                                      )
                                                      : addHoverExpTitle(
                                                        index,
                                                        experience?.company_name,
                                                        "speciality",
                                                        experienceIndex
                                                      );
                                                    (prospectData?.scoring
                                                      ?.candidate_experience_found?.[
                                                      experienceIndex
                                                    ]?.specialty_detected[
                                                      index
                                                    ] === "2" ||
                                                      prospectData?.scoring
                                                        ?.candidate_experience_found?.[
                                                        experienceIndex
                                                      ]?.specialty_detected[
                                                      index
                                                      ] === "3") &&
                                                      removeHoverHighlighter(
                                                        1,
                                                        1,
                                                        1,
                                                        experienceIndex
                                                      );
                                                  }}
                                                  onMouseLeave={() => {
                                                    !detected
                                                      ? removeHoverHighlighter(
                                                        index,
                                                        experience?.company_name,
                                                        "speciality",
                                                        experienceIndex
                                                      )
                                                      : addHoverExpTitle(
                                                        index,
                                                        experience?.company_name,
                                                        "speciality",
                                                        experienceIndex
                                                      );
                                                    (prospectData?.scoring
                                                      ?.candidate_experience_found?.[
                                                      experienceIndex
                                                    ]?.specialty_detected[
                                                      index
                                                    ] === "2" ||
                                                      prospectData?.scoring
                                                        ?.candidate_experience_found?.[
                                                        experienceIndex
                                                      ]?.specialty_detected[
                                                      index
                                                      ] === "3") &&
                                                      removeHoverHighlighter(
                                                        1,
                                                        1,
                                                        1,
                                                        experienceIndex
                                                      );
                                                  }}
                                                  onClick={() => {
                                                    if (
                                                      prospectData?.scoring
                                                        ?.candidate_experience_found?.[
                                                        experienceIndex
                                                      ]?.specialty_detected[
                                                      index
                                                      ] === "2" ||
                                                      prospectData?.scoring
                                                        ?.candidate_experience_found?.[
                                                        experienceIndex
                                                      ]?.specialty_detected[
                                                      index
                                                      ] === "3"
                                                    ) {
                                                      Mixpanel.track(
                                                        `company name - detailed view`
                                                      );
                                                      showCompanyModal(
                                                        experience,
                                                        prospectData?.scoring
                                                          ?.candidate_experience_found?.[
                                                          experienceIndex
                                                        ]?.specialty_detected[
                                                        index
                                                        ],
                                                        prospectData?.scoring
                                                          ?.candidate_experience_found?.[
                                                          experienceIndex
                                                        ]?.specialty_text_spans[
                                                        index
                                                        ],
                                                        speciality
                                                      );
                                                    }
                                                  }}
                                                  key={index}
                                                  data-tooltip={
                                                    [
                                                      "2",
                                                      "3",
                                                      "4",
                                                      2,
                                                      3,
                                                      4,
                                                    ].includes(
                                                      prospectData?.scoring
                                                        ?.candidate_experience_found?.[
                                                        experienceIndex
                                                      ]?.specialty_detected[
                                                      index
                                                      ]
                                                    )
                                                      ? specSourceMap[
                                                      prospectData?.scoring
                                                        ?.candidate_experience_found?.[
                                                        experienceIndex
                                                      ]?.specialty_detected[
                                                      index
                                                      ]
                                                      ]
                                                      : ""
                                                  }
                                                >
                                                  {speciality}
                                                </p>
                                                { }
                                              </>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {prospectData?.scoring?.experience_found?.length > 0 ? (
                      <div className="relevantExperiencePanelSummary">
                        <div className="columnHeading">
                          Relevant Experience Summary
                        </div>
                        <div className="relevantExperienceSummary">
                          {prospectData?.scoring?.experience_found?.map(
                            (exp, idx) => (
                              <div key={idx} className="relevant-summary">
                                <p>{getYrsAndMos(exp?.span)}</p>
                                <p className="title">
                                  {exp?.title} @{" "}
                                  <span
                                  // onClick={() => {
                                  //   Mixpanel.track(`company name - detailed view`)
                                  //   showCompanyModal(exp);
                                  // }}
                                  >
                                    {exp?.company}
                                  </span>
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ) : null}
                    <div className="totalExperiencePanel">
                      <div className="totalExperience">
                        <div className="columnHeading">Total Experience</div>
                        <div className="yearSpans">
                          {getYrsAndMos(
                            prospectData?.scoring?.total_experience_span
                          )}
                        </div>
                        <div className="columnHeading">
                          Total Relevant Experience
                        </div>
                        <div className="yearSpans">
                          {getYrsAndMos(
                            prospectData?.scoring?.relevant_experience_span
                          )}
                        </div>
                      </div>
                      <div className="functionalExperience">
                        <div className="columnHeading">
                          Functional Experience
                        </div>
                        {prospectData?.scoring?.functional_role ? (
                          <div className="tablets">
                            {/* {functionalExperience_found.map(
                            ({ span, functionalExperience }, index) => ( */}
                            <div
                              className="functionalExperienceContainer"
                            // key={index}
                            >
                              <p>{prospectData?.scoring?.functional_role}</p>
                              <span className="globalDotStyle">•</span>{" "}
                              <span className="years">
                                {getYrsAndMos(
                                  prospectData?.scoring?.functional_experience
                                )}
                              </span>
                            </div>
                            {/* )
                          )} */}
                          </div>
                        ) : (
                          <p className="noneFound">Not available</p>
                        )}
                      </div>
                    </div>
                    <div
                      className="specialitiesPanel"
                      data-name={`specialities`}
                    >
                      <div className="columnHeading">
                        Business
                        <span className="globalDotStyle">•</span>{" "}
                        <span>{specialties_found?.length} found</span>
                        {missing_specialities?.length > 0 ? (
                          <span className="missing">
                            <span className="globalDotStyle">•</span>{" "}
                            <span>{missing_specialities?.length} missing</span>
                          </span>
                        ) : null}
                      </div>
                      <div className="specialities">
                        {specialties_found?.map(
                          ({ speciality, span }, index) => (
                            <SpecialtiesFoundComponent
                              key={index}
                              index={index}
                              speciality={speciality}
                              span={span}
                              pId={prospectData?.id}
                              tooltipLabel={(
                                prospectData?.scoring?.specialities_profile_location
                                  ?.filter(
                                    ({ name = "" }) =>
                                      name?.trim()?.toLocaleLowerCase() ===
                                      speciality?.trim()?.toLocaleLowerCase()
                                  )
                                  ?.pop()?.source || []
                              )
                                .map((itm) => {
                                  if (itm.type === "experience") {
                                    return experienceSourceMap(itm);
                                  } else {
                                    return otherSourceMap[itm.type];
                                  }
                                })
                                ?.join("\n")}
                            />
                          )
                        )}
                        {missing_specialities?.length > 0
                          ? missing_specialities?.map(
                            ({ speciality, span }, index) => (
                              <div className="missing" key={index}>
                                <span className="tablets">
                                  <span>{speciality}</span>
                                </span>{" "}
                                {/* <span className="duration">
                                  <span className="globalDotStyle">•</span>{" "}
                                  {getYrsAndMos(span)}
                                </span> */}
                              </div>
                            )
                          )
                          : null}
                      </div>
                    </div>
                    <div className="skillsPanel" data-name={`skills`}>
                      <div className="columnHeading">Skills</div>
                      {must_haves_skills?.length > 0 ? (
                        <div className="highSkill">
                          <div className="subHeading">
                            {must_haves_skills?.length > 0 ||
                              must_haves_missing?.length > 0 ? (
                              <Button
                                color=""
                                className=""
                                onClick={showHideHighSkills}
                              >
                                <div className="columnHeading">
                                  High Importance{" "}
                                  <span className="globalDotStyle">•</span>{" "}
                                  <span>{must_haves_skills?.length} found</span>{" "}
                                  {must_haves_missing?.length > 0 ? (
                                    <span className="missing">
                                      <span className="globalDotStyle">•</span>{" "}
                                      <span>
                                        {must_haves_missing?.length} missing
                                      </span>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <CaretDown
                                    style={{
                                      marginLeft: "8px",
                                      transform: highSkillsToggle
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                    }}
                                    width="10px"
                                    height="6px"
                                    color="#A7ABB0"
                                  />
                                </div>
                              </Button>
                            ) : (
                              <p>
                                High Importance{" "}
                                <span className="globalDotStyle">•</span>{" "}
                                <span>{must_haves_skills?.length} found</span>{" "}
                                {must_haves_missing?.length > 0 ? (
                                  <span className="missing">
                                    <span className="globalDotStyle">•</span>{" "}
                                    <span>
                                      {must_haves_missing?.length} missing
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            )}

                            <CollapsibleComponent
                              isOpen={highSkillsToggle}
                              availableSkills={must_haves_skills}
                              missingSkills={must_haves_missing}
                            />
                          </div>
                        </div>
                      ) : null}
                      {/* {important_skills?.length > 0 ?( ) : null} */}

                      {important_skills?.length > 0 ||
                        important_missing?.length > 0 ? (
                        <div className="mediumSkill">
                          <div className="subHeading">
                            <Button
                              color=""
                              className=""
                              onClick={showHideMidSkills}
                            >
                              <div className="columnHeading">
                                Medium Importance{" "}
                                <span className="globalDotStyle">•</span>{" "}
                                <span>{important_skills?.length} found</span>{" "}
                                {important_missing?.length > 0 ? (
                                  <span className="missing">
                                    <span className="globalDotStyle">•</span>{" "}
                                    <span>
                                      {important_missing?.length} missing
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                                <CaretDown
                                  style={{
                                    marginLeft: "8px",
                                    transform: midSkillsToggle
                                      ? "rotate(180deg)"
                                      : "rotate(0deg)",
                                  }}
                                  width="10px"
                                  height="6px"
                                  color="#A7ABB0"
                                />
                              </div>
                            </Button>

                            {/* <p>
              Medium Importance <span className="globalDotStyle">•</span>{" "}
              <span>{important_skills?.length} found</span>{" "}
              {important_missing?.length > 0 ? (
               <span className="missing">
                 <span className="globalDotStyle">•</span>{" "}
                 <span>{important_missing?.length} missing</span>
               </span>
             ) : (
               ""
             )}
           </p> */}

                            <CollapsibleComponent
                              isOpen={midSkillsToggle}
                              availableSkills={important_skills}
                              missingSkills={important_missing}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {nice_to_haves_skills?.length > 0 ||
                        nice_to_haves_missing?.length > 0 ? (
                        <div className="lowSkill">
                          <div className="subHeading">
                            <Button
                              color=""
                              className=""
                              onClick={showHideLowSkills}
                            >
                              <div className="columnHeading">
                                Low Importance{" "}
                                <span className="globalDotStyle">•</span>{" "}
                                <span>
                                  {nice_to_haves_skills?.length} found
                                </span>{" "}
                                {nice_to_haves_missing?.length > 0 ? (
                                  <span className="missing">
                                    <span className="globalDotStyle">•</span>{" "}
                                    <span>
                                      {nice_to_haves_missing?.length} missing
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                                <CaretDown
                                  style={{
                                    marginLeft: "8px",
                                    transform: lowSkillsToggle
                                      ? "rotate(180deg)"
                                      : "rotate(0deg)",
                                  }}
                                  width="10px"
                                  height="6px"
                                  color="#A7ABB0"
                                />
                              </div>
                            </Button>

                            {/* <p>
             Low Importance <span className="globalDotStyle">•</span>{" "}
             <span>{nice_to_haves_skills?.length} found</span>{" "}
             {nice_to_haves_missing?.length > 0 ? (
               <span className="missing">
                 <span className="globalDotStyle">•</span>{" "}
                 <span>{nice_to_haves_missing?.length} missing</span>
               </span>
             ) : (
               ""
             )}
           </p> */}

                            <CollapsibleComponent
                              isOpen={lowSkillsToggle}
                              availableSkills={nice_to_haves_skills}
                              missingSkills={nice_to_haves_missing}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="otherSkillsPanel" data-name={`otherSkills`}>
                      <div
                        className="columnHeading"
                        style={{ cursor: "pointer", width: "fit-content" }}
                        onClick={() => setShowOtherSkills((p) => !p)}
                      >
                        Other Skills{" "}
                        <span
                          style={{ transition: "all 0.2s ease-in-out" }}
                        // onClick={() => setShowOtherSkills((p) => !p)}
                        >
                          {" "}
                          <CaretDown
                            className={`caretDown`}
                            style={{
                              transform: showOtherSkills
                                ? "rotate(180deg) translateY(1px)"
                                : "rotate(0deg)",
                            }}
                          />
                        </span>
                      </div>
                      <div className="otherSkills">
                        {showOtherSkills &&
                          prospectData?.skills?.map((skills, index) => (
                            <div key={index}>
                              <span className="tablets">
                                <OtherSkillsRender
                                  title={skills}
                                  index={index}
                                />
                              </span>{" "}
                            </div>
                          ))}
                      </div>
                    </div>
                    {prospectData?.education?.length > 0 ? (
                      <div className="educationPanel" data-name={`education`}>
                        <div className="columnHeading">Education</div>
                        <div className="education">
                          {prospectData?.education?.map(
                            (
                              {
                                degreeName,
                                fieldOfStudy,
                                schoolName,
                                start,
                                end,
                                logo,
                              },
                              id
                            ) => (
                              <div className="single-education" key={id}>
                                {console.log("education item: ", {
                                  start,
                                  end,
                                  logo,
                                  schoolName,
                                })}
                                <div className="education-date">
                                  {logo ? (
                                    <img
                                      src={logo}
                                      alt={logo}
                                      width="46"
                                      height="46"
                                      loading="lazy"
                                      style={{
                                        marginBottom: "24px",
                                        borderRadius: "8px",
                                      }}
                                    />
                                  ) : (
                                    <span
                                      className="placeHolderContainer"
                                      style={{
                                        marginBottom: "24px",
                                        borderRadius: "8px",
                                      }}
                                    >
                                      <JobSetupStep10 width={24} height={24} />
                                    </span>
                                  )}
                                  <p
                                    className="education-span"
                                    style={{ marginBottom: "14px" }}
                                  >
                                    {start || end ? (
                                      <span className="duration">
                                        {/* {start?.includes("-")
                                          ? start?.split("-")[1]
                                          : start} */}
                                        {getValidDate(start)}{" "}
                                        {start ? "-" : "•"}{" "}
                                        {getValidDate(end) === "" && id === 0
                                          ? "Now"
                                          : getValidDate(end)}
                                        {/* {end?.includes("-")
                                          ? end?.split("-")[1]
                                          : end} */}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                </div>
                                <div>
                                  {schoolName ? (
                                    <p
                                      className="title_education"
                                      style={{
                                        cursor: "pointer",
                                        width: "fit-content",
                                      }}
                                    // onClick={() => {
                                    //   schoolName &&
                                    //     showCompanyModal(schoolName);
                                    // }}
                                    >
                                      <span>{schoolName}</span>
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {`${degreeName} ${degreeName ? "in" : ""} ${fieldOfStudy ? fieldOfStudy : ""
                                    } ${degreeName || fieldOfStudy ? "from" : ""
                                    } ${schoolName ? schoolName : ""}`}{" "}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ) : null}
                    {prospectData?.languages ? (
                      <div className="educationPanel" data-name={`education`}>
                        <div className="columnHeading">Spoken Languages</div>
                        <p>{prospectData?.languages.join(", ")}</p>
                      </div>
                    ) : null}
                    <div className="VisaDemoPanel">
                      <div className="visaPanel">
                        <div className="columnHeading">Visa Status</div>

                        <p>{prospectData?.visa_status}</p>
                      </div>

                      <div className="demographicsPanel">
                        <div className="columnHeading">Demographics</div>

                        {demographicsFormatter(
                          prospectData?.age,
                          prospectData?.gender,
                          prospectData?.ethnicity
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <CommentsSection
                    shouldShow={true}
                    clickedProspectsDetails={prospectData}
                  // onClickComments={onClickComments}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {/* <MyComponent /> */}
    </div>
  );
};

export default withRouter(
  connect((state) => ({
    job: state?.prospectsView?.candidates?.job,
    selectedProspectIDVariant:
      state?.prospectsView?.prospectVariant?.selectedProspectIDVariant,
    prospects: state?.prospectsView?.candidates?.prospects,
    activeProspectVariant:
      state?.prospectsView?.prospectVariant?.activeProspectVariant,
    selectedProspectComments:
      state?.prospectsView?.candidates?.selectedProspectComments,
    showEmojiPickerProspectID:
      state?.prospectsView?.candidates?.showEmojiPickerProspectID,
    permissionsAllowed: state?.prospectsView?.candidates?.permissions,
    pageNo: state?.prospectsView?.candidates?.page,
    currentHoverElement: state?.prospectsView?.candidates?.currentHoverElement,
    selectedProspects: state.prospectsView.candidates.selectedProspects,
    likedStatus: state.prospectsView.candidates.likedStatus,
    deleteCount: state.prospectsView.candidates.deleteCount,

    comments: state.prospectsView.candidates.comments, //bugfix-NA-s21-1625 & bugfix-NA-s21-1624
    ActivitiviesOfCurrentPorspet:
      state.prospectsView?.prospectVariant?.ActivitiviesOfCurrentPorspet, //bugfix-NA-s21-1625 & bugfix-NA-s21-1624
  }))(RevampProspectFullCard)
);

const SpecialtiesFoundComponent = ({
  index = 0,
  speciality,
  span,
  pId = 0,
  tooltipLabel,
}) => {
  const [onHover, setOnHover] = useState(false);
  return (
    <React.Fragment>
      <div
        id={`speciality${pId}${index}`}
        onMouseEnter={(e) => setOnHover(true)}
        onMouseLeave={(e) => setOnHover(false)}
      >
        <span className="tablets">
          <span>{speciality}</span>
        </span>{" "}
        {getYrsAndMos(span) && (
          <span className="duration">
            <span className="globalDotStyle">•</span> {getYrsAndMos(span)}
          </span>
        )}
      </div>
      {onHover && `speciality${pId}${index}` && tooltipLabel && (
        <Tooltip
          hideArrow={false}
          placement="bottom"
          isOpen={onHover}
          target={`speciality${pId}${index}`}
          toggle={() => setOnHover((prev) => !prev)}
          style={{ zIndex: 9 }}
          className="primaryToolTip"
        >
          {tooltipLabel
            ? tooltipLabel.split("\n").map((itm, i) => <p key={i}>{itm}</p>)
            : "found"}
        </Tooltip>
      )}
    </React.Fragment>
  );
};
