import * as ActionTypes from '../../actions/messaging'

const initialState = {
    messages: [],
    isUpdating: false,
    isUpdated: false,
    isDataAvailable: false,
    campaigns: {},
    jobsWithCampaigns: []
}

const messaging = (state = initialState, action) => {
    switch (action.type) {

        case ActionTypes.FETCH_LINKEDIN_MESSAGES:
            return { ...state, isDataAvailable: false }

        case ActionTypes.FETCH_LINKEDIN_MESSAGES_SUCCESS:
            return { ...state, messages: action.payload, isDataAvailable: true }

        case ActionTypes.FETCH_LINKEDIN_MESSAGES_FAILURE:
            return { ...state, messages: action.payload }


        // case ActionTypes.FETCH_MESSAGING_JOB_CAMPAIGNS:
        //     return { ...state, isDataAvailable: false }

        // case ActionTypes.FETCH_MESSAGING_JOB_CAMPAIGNS_SUCCESS:
        //     return { ...state, campaigns: action.payload, isDataAvailable: true }

        // case ActionTypes.FETCH_MESSAGING_JOB_CAMPAIGNS_FAILURE:
        //     return { ...initialState }



        case ActionTypes.FETCH_JOBS_WITH_CAMPAIGNS:
            return { ...state, isDataAvailable: false }

        case ActionTypes.FETCH_JOBS_WITH_CAMPAIGNS_SUCCESS:
            return { ...state, jobsWithCampaigns: action.payload?.data?.jobs, isDataAvailable: true }

        case ActionTypes.FETCH_JOBS_WITH_CAMPAIGNS_FAILURE:
            return { ...state }



        default:
            return state
    }
}

export default messaging
