import React from 'react'
import "./style.scss"

const PrimaryButton = ({
  className = '',
  onClick = e => false,
  disabled = false,
  isLoading = false,
  children
}) => {
  return (
    <button
      className={'consentButton'}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </ button>
  )
}

export default PrimaryButton
