import React, {useState} from 'react'
import { connect } from 'react-redux'
import ProfileSummaryCard from '../../../components/ProfileSummaryCard/ProfileSummaryCard'
import UsersIcon from '../../../uiLibrary/SVGs/UsersIcon'
import EmailTag from '../../../uiLibrary/EmailTag'
import './style.scss'
import {  updateSelectedProspects } from '../../../actions/prospectsView'
import { changeCampaignRound, setOutboxView } from '../../../actions/outbox'
import SecondaryButton from '../../../uiLibrary/SecondaryButton'
import { Mixpanel } from '../../../utils/Mixpanel'

const RecipientTab = ({
    currentProspect,
    disabled,
    rounds,
    togglePersonalise,
    recipientTab,
    primaryEmail,
    personal_email,
    work_email,
    imageUrl,
    fullName,
    title,
    id,
    jobProfileId,
    currentCompanyName,
    publicIdentifier,
    updateSelected,
    dispatch
})=>{
    const [personalisedButtonHover, setPersonalisedButtonHover] = useState(false)
    const personalEmail = primaryEmail || personal_email
    const isPersonalised = rounds?.[0]?.messages.filter(({jobprofile_id})=>jobprofile_id===jobProfileId)?.[0]?.is_personalised
    const handleAdd = (e, value, property, type) => {
        e.stopPropagation()
        console.log("=================handleAdd==================", {e, value, property, type})
        const regexMatch = value.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi)
        if(value==='' || !regexMatch){
            alert('Invalid Email!')
        }
        else{
            updateSelected(id, property, value)
            if(!personalEmail && !work_email){
                rounds.map(round => {
                    if(round.messages.filter(({is_personalised, jobprofile_id, emailType})=>is_personalised && jobprofile_id===jobProfileId && !emailType).length>0){
                        dispatch(changeCampaignRound({
                            id: round.id,
                            value:{
                                messages: round.messages.map(message=>{
                                    if(message.jobprofile_id===jobProfileId && !message.emailType){
                                        return{
                                            ...message,
                                            emailType: type
                                        }
                                    }
                                    return message
                                })
                            }
                        }))
                    }
                })
            }
        }
    }
    const handleSelectProspect = e => {
        if(disabled){
            return false
        }
        else if(recipientTab!==id){
            Mixpanel.track("personalize")
            dispatch(setOutboxView({
                recipientTab: id
            }))
            if(!isPersonalised){
                togglePersonalise(true, jobProfileId)
            }
        }
    }
    const onUnpersonalise = e => {
        e.stopPropagation()
        togglePersonalise(false, jobProfileId)
        dispatch(setOutboxView({
            recipientTab: 'all'
        }))
    }
    
    return (
        <div
            className={`recipientTab ${recipientTab===id ? 'selectedTab' : ''}`}
            // onClick={handleSelectProspect}
        >
            <div>
                <ProfileSummaryCard
                    displayCheckBox={true}
                    handleSelectProspect={handleSelectProspect}
                    currentProspect={currentProspect}
                    imgSrc={imageUrl}
                    name={fullName}
                    headline={`${title} @ ${currentCompanyName}`}
                    url={`https://www.linkedin.com/in/${publicIdentifier}/`}
                />
            </div>
            <div onClick={handleSelectProspect}>
                <EmailTag
                    found={personalEmail}
                    onAdd={(e, value)=>handleAdd(e, value, 'personal_email', 'Personal Email')}
                >
                    Personal email
                </EmailTag>
                <EmailTag
                    found={work_email}
                    onAdd={(e, value)=>handleAdd(e, value, 'work_email', 'Work Email')}
                >
                    Work email
                </EmailTag>
                {isPersonalised &&
                    <SecondaryButton
                        style={{
                            padding: '2px 8px'
                        }}
                        onClick={onUnpersonalise}
                        onMouseEnter={e=>setPersonalisedButtonHover(true)}
                        onMouseLeave={e=>setPersonalisedButtonHover(false)}
                    >
                        {personalisedButtonHover ?
                            'Unpersonalize' :
                            'Personalized'
                        }
                    </SecondaryButton>
                }
            </div>
        </div>
    )
}

const RecipientsTabs = ({
    rounds,
    updateSelected,
    selectedProspects,
    togglePersonalise,
    recipientTab,
    dispatch,
    disabled=false
}) => {
   
    
    const onAllRecipientTab = e => {
        if(disabled){
            return false
        }
        else if(recipientTab!=='all'){
            dispatch(setOutboxView({
                recipientTab: 'all'
            }))
        }
    }
    
    return (
        <div
            className='recipientsTabsContainer'
        >
            <div
                className={`allRecipientsTab ${recipientTab==='all' ? 'selectedTab' : ''}`}
                onClick={onAllRecipientTab}
            >
                <span>
                    <span>

                        <UsersIcon selected={true}/>
                    </span>
                    <p>
                        All Recipients
                    </p>
                </span>
                <p>
                    {
                        Object.keys(selectedProspects).length
                    }
                </p>
            </div>
            {Object.keys(selectedProspects).map(key=>(
                <RecipientTab
                    currentProspect={selectedProspects[key]}
                    disabled={disabled}
                    rounds={rounds}
                    recipientTab={recipientTab}
                    togglePersonalise={togglePersonalise}
                    updateSelected={updateSelected}
                    {...selectedProspects[key]}
                    dispatch={dispatch}
                />
            ))}
        </div>
    )
}

export default connect(state=>({
    selectedProspects: state.prospectsView.candidates.selectedProspects,
    rounds: state.outbox.campaigns.rounds,
    recipientTab: state.outbox.viewController.recipientTab
}))(RecipientsTabs)
