import { manualApiCall } from "../utils";

export const GET_INVITED_USER_DETAILS = 'GET_INVITED_USER_DETAILS'
export const CONFIRMED_USER = 'CONFIRMED_USER'

export const getInvitedUserDetails = (tribeId, userId) => async (dispatch, getState) => {
  try {
    console.log("==============getInvitedUserDetails=================", dispatch);

    const res = await manualApiCall(`/api/auth/tribes/userDetails?uId=${userId}&tId=${tribeId}`, {
      method: "GET"
    });

    if(res.status && res.status == 'invited') {
      dispatch({
        type: GET_INVITED_USER_DETAILS,
        payload: res.userDetails,
      });
    } else if(res.status && res.status == 'confirmed') {
      dispatch({
        type: CONFIRMED_USER
      });
    }
    
  } catch (e) {
    console.log(e);
  }
};

export const CONFIRM_INVITED_USER_DETAILS = 'GET_INVITED_USER_DETAILS'

export const confirmInvitation = (values) => async (dispatch, getState) => {
  try {
    console.log("==============confirmInvitation=================", dispatch);

    const res = await manualApiCall(`/api/auth/tribes/confirmInvitation`, {
      method: "POST",
      body: JSON.stringify({...values}),
    });

    if(res.success) {
      dispatch({
        type: CONFIRMED_USER
      });
    }
    
  } catch (e) {
    console.log(e);
  }
};

