import * as ActionTypes from '../../actions/outbox'
import { v4 } from 'uuid';

const initialRound = {
    id: v4(),
    reviewed: false,
    send_after_days: 0,
    send_after_hours: 0,
    sender: '',
    subject: 'You are a great fit!',
    template: `{{.First_Name}}, extremely impressed by your work experience. I am reaching out regarding {{.Job_Title}} position for {{.Hiring_Company}}. 
    Great benefits and an amazing opportunity.
    Available for a quick chat this week?
    
    Look forward to hearing from you
    {{.My_Name}}`,
    channel: 'linkedin',
    messageErrors: [],
    messages: []
}


const initialState = {
    name: '',
    shareWithTribe: false,
    rounds: [ initialRound ],
    publishing: false,
    publishingSuccess: false,
    publishingFailure: false,
    draft: false,
    firstRound: []
}

const campaigns = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SELECT_OUTBOX_DRAFT:{
            return {
                ...state,
                draft: action.payload
            }
        }
        case ActionTypes.INIT_OUTBOX:{
            return initialState
        }
        case ActionTypes.PUBLISH_OUTBOX_CAMPAIGN: {
            return {
                ...state,
                publishing: true,
                publishingSuccess: false,
                publishingFailure: false
            }
        }
        case ActionTypes.PUBLISH_OUTBOX_CAMPAIGN_SUCCESS: {
            return {
                ...state,
                publishing: false,
                publishingSuccess: true,
                publishingFailure: false,
                firstRound: action.payload
            }
        }
        case ActionTypes.PUBLISH_OUTBOX_CAMPAIGN_FAILURE: {
            return {
                ...state,
                publishing: false,
                publishingSuccess: false,
                publishingFailure: true
            }
        }
        
        
        case ActionTypes.CHANGE_CAMPAIGN_NAME: {
            return {
                ...state,
                name: action.payload
            }
        }
        case ActionTypes.CHANGE_CAMPAIGN_ROUND:{
            console.log("==============CHANGE_CAMPAIGN_ROUND==================", action.payload)
            return {
                ...state,
                rounds: state.rounds.map(round=>{
                    if(round.id===action.payload.id){
                        return{
                            ...round,
                            ...action.payload.value
                        }
                    }
                    return round
                })
            }
        }
        case ActionTypes.REMOVE_CAMPAIGN_ROUND: {
            return{
                ...state,
                rounds: state.rounds.filter(({id})=>id!==action.payload)
            }
        }
        case ActionTypes.ADD_CAMPAIGN_ROUND: {
            const newId = v4()
            return{
                ...state,
                rounds: [
                    ...state.rounds,
                    {
                        ...initialRound,
                        messages: state.rounds[state.rounds.length-1].messages,
                        id: newId,
                        send_after_days: 1,
                        ...action.payload,
                    }
                ]
            }
        }
        case ActionTypes.TOGGLE_SHARE_WITH_TRIBE: {
            return {
                ...state,
                shareWithTribe: !state.shareWithTribe
            }
        }
       

        default:
            return state
    }
}


export default campaigns