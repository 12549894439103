import React from 'react'
import '../style.scss'
const CrossSvg = ({
    className='',
    width='24', 
    height='24',
    strokeWidth="2",
    selected=false,
    style={}
}) => {
    return (
        <svg 
            className={className} 
            style={style} 
            width={width} 
            height={height} 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`} 
                d="M18 6L6 18" 
                strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`} 
                d="M6 6L18 18" 
                strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default CrossSvg
