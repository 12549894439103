import React from 'react'
import './style.scss'
import qluLogo from '../../assets/qlulogo.svg'
import TextField from '../TextField'

function DefualtScreen({ onSubmit }) {
    return (
        <div className='DefualtScreen'>
            <img src={qluLogo} className="qluLogo" />
            <TextField placeholder='What does Spencer Stuart do?' onSubmit={onSubmit} />
        </div>
    )
}

export default DefualtScreen