const { default: Loader } = require("react-loader-spinner")


const Loading = () => {

  return (
    <Loader type='TailSpin' color='#FF8D4E' width={36} height={36} />
  )
}

export default Loading