import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import SecondaryButton from "../../../../uiLibrary/SecondaryButton";
import DropdownComponent from "../../../../uiLibrary/DropdownComponent";
import Cross from "../../../../components/svgComponents/CrossSvg";
import LabelButton from "../../../../uiLibrary/LabelButton";
import GoogleLogo from "../../../../img/googleLogo.svg";
import AddNew from "../../../../img/plus-small.svg";
import Close from "../../../../img/close.svg";
import Zoom from "../../../../img/zoom.svg";

import Select from "react-dropdown-select";
import "react-datepicker/dist/react-datepicker.css";
import "./calendarSettings.scss";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { updateCalendarSettings } from "../../../../actions/calendar";
import { postCalendarSettings } from "../../../../actions/calendar";
import { connect } from "react-redux";
import { validateEmail } from "../../../../utils";

const moment = require("moment-timezone");

const timeZones = moment.tz.names();
var timeZoneOptions = [];
timeZones.forEach(function (element) {
  timeZoneOptions.push({ label: element, value: element });
});

const weekdays = [
  { id: "sun", value: "Sun" },
  { id: "mon", value: "Mon" },
  { id: "tue", value: "Tue" },
  { id: "wed", value: "Wed" },
  { id: "thu", value: "Thu" },
  { id: "fri", value: "Fri" },
  { id: "sat", value: "Sat" },
];

const CalendarSettings = ({ settings, onChange, dispatch }) => {
  let [timeZone, setTimeZone] = useState(
    settings && settings?.timeZone ? settings?.timeZone : "America/Los_Angeles"
  );
  let [selectedDays, setSelectedDays] = useState([]);
  let [selectedIndex, setSelectedIndex] = useState(0);
  let [availablility, setAvailablility] = useState(
    settings && settings?.availablility ? settings?.availablility : {}
  );
  let [linkedCalendar, setLinkedCalendar] = useState(
    settings && settings?.linkedCalendar
      ? settings?.linkedCalendar.split(",")
      : []
  );
  let [linkedCalendarType, setLinkedCalendarType] = useState(
    settings && settings?.linkedCalendarType
      ? settings?.linkedCalendarType.split(",")
      : []
  );
  let [newCalendar, setNewCalendar] = useState("");
  let [isAddNew, setIsAddNew] = useState(false);
  let [isAddNewChannel, setIsAddNewChannel] = useState(false);
  let [newChannel, setNewChannel] = useState("");
  let [linkedMeetingChannel, setLinkedMeetingChannel] = useState(
    settings && settings?.linkedMeetingChannel
      ? settings?.linkedMeetingChannel
      : ""
  );
  const toastId = "toast-id";

  if (settings && settings?.availablility) {
    selectedDays = [];
    availablility = settings?.availablility;
    for (const key in settings?.availablility) {
      if (settings?.availablility.hasOwnProperty(key)) {
        availablility[key].startTime = new Date(availablility[key].startTime);
        availablility[key].endTime = new Date(availablility[key].endTime);
        selectedDays.push(key);
      }
    }
  } else {
    selectedDays = [];
    availablility = {};
  }

  if (settings && settings?.linkedCalendar) {
    linkedCalendar = [];
    linkedCalendarType = [];
    let _array = settings?.linkedCalendar.split(",");
    let _arrayType = settings?.linkedCalendarType.split(",");
    _array.map((item, index) => {
      if (linkedCalendar.indexOf(item) == -1) {
        linkedCalendar.push(item);
        linkedCalendarType.push(_arrayType[index]);
      }
    });
  } else if (!settings) {
    linkedCalendar = [];
    linkedCalendarType = [];
  }

  if (settings && settings?.timeZone) {
    timeZone = settings.timeZone;
  }

  if (settings && settings?.linkedMeetingChannel != null) {
    linkedMeetingChannel = settings.linkedMeetingChannel;
  } else if (!settings) {
    linkedMeetingChannel = "";
  }

  const endFilterPassedTime = (time) => {
    return true;
  };

  const onCalendarChange = () => {
    let payload = {
      linkedCalendar: linkedCalendar.toString(),
      linkedCalendarType: linkedCalendarType.toString(),
      linkedMeetingChannel: linkedMeetingChannel,
      linkedMeetingChannelType:
        settings && settings.linkedMeetingChannelType
          ? settings.linkedMeetingChannelType
          : "zoom",
      timeZone: timeZone,
      availablility: availablility,
    };
    onChange(payload, "calendar");
  };

  const startTimeChange = (e, weekDay) => {
    availablility[weekDay].startTime = e;
    setAvailablility(JSON.parse(JSON.stringify(availablility)));
    onCalendarChange();
  };

  const endTimeChange = (e, weekDay) => {
    availablility[weekDay].endTime = e;
    setAvailablility(JSON.parse(JSON.stringify(availablility)));
    onCalendarChange();
  };

  const onTimeZoneChange = (e) => {
    let value = e && e[0] ? e[0].value : timeZone;
    timeZone = value;
    setTimeZone(value);
    onCalendarChange();
  };

  const onCheckBoxSelect = (e) => {
    if (e.target.checked && !selectedDays.includes(e.target.id)) {
      selectedDays.push(e.target.id);
      availablility[e.target.id] = {
        startTime: new Date().setHours(9),
        endTime: new Date().setHours(17),
      };
    } else {
      const index = selectedDays.indexOf(e.target.id);
      if (index > -1) {
        selectedDays.splice(index, 1);
        delete availablility[e.target.id];
      }
    }
    setSelectedDays(JSON.parse(JSON.stringify(selectedDays)));
    onCalendarChange();
  };

  const onToggleAvailability = (week, remove) => {
    if (remove) {
      const index = selectedDays.indexOf(week.id);
      if (index > -1) {
        selectedDays.splice(index, 1);
      }
      delete availablility[week.id];
    } else {
      selectedDays.push(week.id);
      availablility[week.id] = {
        startTime: new Date().setHours(9),
        endTime: new Date().setHours(17),
      };
    }
    setSelectedDays(JSON.parse(JSON.stringify(selectedDays)));
    onCalendarChange();
  };

  const onAddAnotherCalendar = () => {
    setIsAddNew(true);
  };

  const onUnlink = (index) => {
    linkedCalendar.splice(index, 1);
    setLinkedCalendar(JSON.parse(JSON.stringify(linkedCalendar)));
    linkedCalendarType.splice(index, 1);
    setLinkedCalendarType(JSON.parse(JSON.stringify(linkedCalendarType)));
    onCalendarChange();
  };

  const onUnlinkChannel = () => {
    setLinkedMeetingChannel("");

    let payload = {
      linkedCalendar: linkedCalendar.toString(),
      linkedCalendarType:
        settings && settings.linkedCalendarType
          ? settings.linkedCalendarType
          : "google",
      linkedMeetingChannel: "",
      linkedMeetingChannelType:
        settings && settings.linkedMeetingChannelType
          ? settings.linkedMeetingChannelType
          : "zoom",
      timeZone: timeZone,
      availablility: availablility,
    };
    onChange(payload, "calendar");
  };

  const handleSubjectChange = (e) => {
    setNewCalendar(e.target.value);
  };

  const handleChannelChange = (e) => {
    setNewChannel(e.target.value);
  };

  const onDiscardCalendar = () => {
    setNewCalendar("");
    setIsAddNew(false);
  };

  const onDiscardChannel = () => {
    setNewChannel("");
    setIsAddNewChannel(false);
  };

  const onAddCalendar = () => {
    const inputValue = newCalendar.trim().toLowerCase();
    const isEmail = validateEmail(inputValue);

    if (!isEmail) {
      showDialog("This email is invalid.");
    } else if (linkedCalendar.includes(newCalendar)) {
      showDialog("This email is already added.");
    } else if (newCalendar != "") {
      linkedCalendar.push(newCalendar);
      linkedCalendarType.push("google");
      setLinkedCalendar(JSON.parse(JSON.stringify(linkedCalendar)));
      setNewCalendar("");

      let payload = {
        linkedCalendar: linkedCalendar.toString(),
        linkedCalendarType: linkedCalendarType.toString(),
        linkedMeetingChannel: linkedMeetingChannel,
        linkedMeetingChannelType:
          settings && settings.linkedMeetingChannelType
            ? settings.linkedMeetingChannelType
            : "zoom",
        timeZone: timeZone,
        availablility: availablility,
      };
      if (Object.keys(settings).length === 0) {
        dispatch(postCalendarSettings(payload));
      } else {
        dispatch(updateCalendarSettings(payload));
      }

      window.location.href = `${process.env.REACT_APP_API_HOST}/googleCalendar/login`;
      setIsAddNew(false);
    }
  };

  const showDialog = (message) => {
    toast.dismiss();
    toast(message, {
      toastId: toastId,
      position: "bottom-center",
      hideProgressBar: true,
      style: {
        background: "#A0A0A0",
        color: "black",
        width: "260px",
        height: "52px",
      },
    });
  };

  const onClickOutsideChannel = (e) => {
    if (
      e &&
      e.relatedTarget &&
      e.relatedTarget.className == "crossIconButton"
    ) {
      onDiscardChannel();
    } else {
      if (newChannel != "") {
        setLinkedMeetingChannel(newChannel);

        let payload = {
          linkedCalendar: linkedCalendar.toString(),
          linkedCalendarType:
            settings && settings.linkedCalendarType
              ? settings.linkedCalendarType
              : "google",
          linkedMeetingChannel: newChannel,
          linkedMeetingChannelType:
            settings && settings.linkedMeetingChannelType
              ? settings.linkedMeetingChannelType
              : "zoom",
          timeZone: timeZone,
          availablility: availablility,
        };
        onChange(payload, "calendar");
        setNewChannel("");
      }
      setIsAddNewChannel(false);
    }
  };

  const getTimeZone = () => {
    return [{ label: timeZone, value: timeZone }];
  };

  return (
    <div className="calendarSettings">
      <div className="heading">Calendar</div>

      <div className="flexAlignDisplay">
        <div className="header">Linked Calendar</div>
        <div className="content">
          {linkedCalendar.map((item, index) => (
            <div className="flexDisplay">
              <SecondaryButton className="googleBtn">
                <img width="20" height="20" src={GoogleLogo} />
                Google
              </SecondaryButton>
              <div className="email">{item}</div>
              <LabelButton className="link" onClick={() => onUnlink(index)}>
                Unlink
              </LabelButton>
            </div>
          ))}
          {isAddNew ? (
            <div className="flexDisplay">
              <SecondaryButton className="googleBtn">
                <img width="20" height="20" src={GoogleLogo} />
                Google
              </SecondaryButton>
              <div className="calendarInputText flexAlignDisplay">
                <input
                  value={newCalendar}
                  onChange={handleSubjectChange}
                  type="text"
                  placeholder="Enter Email Address"
                />
                <LabelButton className="link" onClick={onAddCalendar}>
                  Add
                </LabelButton>
                <LabelButton className="link" onClick={onDiscardCalendar}>
                  Cancel
                </LabelButton>
              </div>
            </div>
          ) : (
            []
          )}
        </div>
      </div>
      <ToastContainer />
      {isAddNew ? (
        []
      ) : (
        <div className="flexAlignDisplay">
          <div className="header"></div>
          <div className="content">
            <LabelButton className="addNew" onClick={onAddAnotherCalendar}>
              <img width="16" height="16" src={AddNew} />
              Add another calendar
            </LabelButton>
          </div>
        </div>
      )}

      <div className="flexAlignDisplay">
        <div className="header">Linked Meeting Channel</div>
        <div className="content">
          {isAddNewChannel || linkedMeetingChannel == "" ? (
            <div className="flexDisplay">
              <SecondaryButton className="googleBtn">
                <img width="20" height="20" src={GoogleLogo} />
                Google
              </SecondaryButton>
              <div className="calendarInputText">
                <input
                  value={newChannel}
                  onChange={handleChannelChange}
                  onBlur={onClickOutsideChannel}
                  type="text"
                  placeholder="Enter Email Address"
                />
                <button className="crossIconButton" onClick={onDiscardChannel}>
                  <Cross
                    style={{ transform: "rotate(90deg)" }}
                    color="#A7ABB0"
                  />
                </button>
              </div>
            </div>
          ) : (
            <div className="flexDisplay">
              <SecondaryButton className="googleBtn">
                <img width="20" height="20" src={GoogleLogo} />
                Google
              </SecondaryButton>
              <div className="email">{linkedMeetingChannel}</div>
              <LabelButton className="link" onClick={() => onUnlinkChannel()}>
                Unlink
              </LabelButton>
            </div>
          )}
        </div>
      </div>

      <div className="flexAlignDisplay timezone">
        <div className="header">Timezone</div>
        <div className="content">
          <Select
            values={getTimeZone()}
            options={timeZoneOptions}
            onChange={onTimeZoneChange}
            placeholder="Select Timezone..."
          />
        </div>
      </div>

      <div className="meetingAvailability">
        <div className="flexAlignDisplay">
          <div className="header">I am available for meetings at</div>
          <div className="content availability">
            <div>
              {weekdays.map((week, index) => {
                return (
                  <div key={week + index} className="availabilityRow">
                    <Input
                      className="checkBox"
                      type="checkbox"
                      name={week.id}
                      id={week.id}
                      checked={selectedDays.includes(week.id)}
                      onChange={onCheckBoxSelect}
                    />
                    <span className="weekDay">{week.value}</span>
                    {selectedDays.includes(week.id) ? (
                      <div className="flexAlignDisplay">
                        <div className="timeSettingsInput">
                          <DatePicker
                            selected={availablility[week.id].startTime}
                            onChange={(e) => startTimeChange(e, week.id)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={selectedIndex == 0 ? 15 : 30}
                            dateFormat="h:mm aa"
                          />
                        </div>
                        <span className="hyphen"> - </span>
                        <div className="timeSettingsInput">
                          <DatePicker
                            selected={availablility[week.id].endTime}
                            onChange={(e) => endTimeChange(e, week.id)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={selectedIndex == 0 ? 15 : 30}
                            dateFormat="h:mm aa"
                            filterTime={endFilterPassedTime}
                          />
                        </div>
                        <div>
                          <img
                            width="16"
                            height="16"
                            src={Close}
                            className="closeIcon"
                            onClick={() => onToggleAvailability(week, true)}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flexAlignDisplay">
                        <span className="unavailable">Unavailable</span>
                        <div>
                          <img
                            width="16"
                            height="16"
                            src={AddNew}
                            className="closeIcon"
                            onClick={() => onToggleAvailability(week, false)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(CalendarSettings);
