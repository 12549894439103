import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import PrimaryButton from "../../uiLibrary/PrimaryButton";
import PillTabButton from "../../uiLibrary/PillTabButton";
import "./findMoreWidget.scss";
import { isEmpty } from "lodash";
import { manualApiCall } from "../../utils";

const FindMoreWidget = ({
  history,
  match: { params },
  setShowMoreProspect = () => false,
  hasSavedProfiles,
  saveCount = 0,
  prospectsLoading
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const [view, setView] = useState(1);
  const [selectedCirteria, setSelectedCriteria] = useState([]);

  const [consumed, setConsumed] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const options = [
    { id: 1, count: 50 },
    { id: 2, count: 100 },
    { id: 3, count: 200 },
    { id: 4, count: 500 },
  ];

  const criteria = [
    { id: 1, name: "skills", value: "s" },
    { id: 2, name: "education", value: "ed" },
    { id: 3, name: "experience", value: "ex" },
    { id: 4, name: "specialties", value: "sp" },
    { id: 5, name: "companies", value: "c" },
    // { id: 6, name: 'company size', value: 'cs' },
  ];

  useEffect(async () => {
    setIsLoading(true);
    const response = await manualApiCall("/api/v2/auth/user/limit", {
      method: "GET",
    });
    if (isEmpty(response?.consumed)) {
      setConsumed(0);
    } else {
      setConsumed(response?.consumed?.[0]?.consumed);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      setSelected({});
      setView(1);
      setSelectedCriteria([]);
    }
  }, [isModalOpen]);

  const handleClickNext = () => {
    if (1000 - consumed <= 0) {
      alert("Limit exhausted for today");
    } else if (selected.count > 1000 - consumed) {
      alert("Number exceeds remaining limit");
    } else {
      setView(2);
    }
  };

  const handleClickStartSearch = () => {
    // console.log('SELECTED CRITERIA IS: ', selectedCirteria)
    const qs = `?count=${selected.count}${selectedCirteria.length > 0
      ? `&p=${JSON.stringify(selectedCirteria.map((item) => item.value))}`
      : ""
      }`;
    // console.log('QUERY STRING IS: ', qs)

    manualApiCall(`/api/v2/auth/jobs/${params.jId}/addMore${qs}`, {
      method: "GET",
    });
    history.push(`/job/addingProspects/${params.jId}?t=5`);
  };

  return (
    <div className="findmore-root">
      <PrimaryButton
        disabled={prospectsLoading}
        style={{ fontSize: "14px", padding: "8px 12px" }}
        onClick={() => {
          // setModalOpen(!isModalOpen);
          console.log("=================onClick===========================", { saveCount, hasSavedProfiles })
          // if (saveCount > 0 || hasSavedProfiles) {
          history.push(`/job/findMore/${params.jId}`);
          // }
          // else {
          //   setShowMoreProspect((prev) => !prev);
          // }
        }}
      >
        Find More Prospects
      </PrimaryButton>
      {isModalOpen && view === 1 && (
        <div className="findmore-modal">
          <p>Show More Profiles</p>
          <div className="fm-opts">
            {options.map((item) => (
              <PillTabButton
                selected={selected?.id === item.id}
                key={item.id}
                tab={{ name: item.count }}
                onClick={() => setSelected(item)}
              />
            ))}
          </div>
          <p style={{ marginBottom: "24px" }}>
            Daily limit left: {1000 - consumed} profiles
          </p>
          <PrimaryButton
            style={{ float: "right", fontSize: "14px", padding: "12px 12px" }}
            disabled={isEmpty(selected)}
            onClick={handleClickNext}
          >
            Next
          </PrimaryButton>
        </div>
      )}

      {isModalOpen && view === 2 && (
        <div className="findmore-modal">
          <p>Select criteria in decreasing order of priority</p>
          <div className="fm-opts">
            {criteria.map((item) => (
              <PillTabButton
                selected={selectedCirteria.map((it) => it.id).includes(item.id)}
                key={item.id}
                tab={{ name: item.name }}
                onClick={() =>
                  selectedCirteria.map((it) => it.id).includes(item.id)
                    ? setSelectedCriteria(
                      selectedCirteria.filter((it) => it.id !== item.id)
                    )
                    : setSelectedCriteria([...selectedCirteria, item])
                }
              />
            ))}
          </div>
          <PrimaryButton
            style={{ float: "right", fontSize: "14px", padding: "12px 12px" }}
            disabled={isEmpty(selected)}
            onClick={handleClickStartSearch}
          >
            Start Search
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default withRouter(connect(state => ({
  prospectsLoading: state.prospectsView.candidates.isLoading
}))(FindMoreWidget));
