import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { changeJobDescription, changeJobDetailTitle, initCompanyDetails, initJobDetails, initJobIdealProfile, initJobLocation, initJobsetup, initTribeMembers } from '../../../actions/QuickSetup'
import TextEditor from '../../../components/TextEditor/TextEditor'
import CompanyAutoSuggest from './CompanyAutoSuggest'
import LocationAutoSuggest from './LocationAutoSuggest'
import ProfileAutoSuggest from './ProfileAutoSuggest'
import './style.scss'
const QuickNerSetup = ({
    dispatch,
    description,
    title
}) => {

    useEffect(() => {
      return () => {
        dispatch(initCompanyDetails())
        dispatch(initJobDetails())
        dispatch(initJobLocation())
        dispatch(initJobIdealProfile())
      }
    }, [])
    
  
    const handleChange = (value) => {
        dispatch(changeJobDescription(value));
        dispatch(initJobsetup());
        dispatch(initTribeMembers());
    }
    return (
        <div
            className='QuickNerSetupContainer'
        >
            <div
                className='QuickNerSetupSection'
            >
                <span>
                    <h3>
                        Job Title
                    </h3>
                    <input
                        placeholder='Enter job title'
                        value={title}
                        onChange={e=>dispatch(changeJobDetailTitle(e.target.value))}
                    />
                </span>
            </div>
            <div
                className='QuickNerSetupSection'
            >
                <span>
                    <h3>
                        Job Description
                    </h3>
                    <TextEditor
                        value={description}
                        handleChange={handleChange}
                        pasteHandler={handleChange}
                    />
                </span>
            </div>
            <div
                className='QuickNerSetupSection'
            >
                <span>
                    <h3>
                        Company
                    </h3>
                    <CompanyAutoSuggest/>
                </span>
                <span>
                    <h3>
                        Location
                    </h3>
                    <LocationAutoSuggest/>
                </span>
            </div>
            <div
                className='QuickNerSetupSection'
            >
                <span>
                    <h3>
                        Best Fit Profile
                    </h3>
                    <ProfileAutoSuggest/>
                </span>
            </div>
        </div>
    )
}

export default connect(state=>({
    description: state.QuickSetup.jobDetails.description,
    title: state.QuickSetup.jobDetails.title,
}))(QuickNerSetup)