import React from 'react'
import Loader from 'react-loader-spinner'
import './style.scss'
const Loading = ({
    invert=false,
    width='32',
    height='32'
}) => {
    return (
        <Loader
            className={`qluLoader${invert ? 'Inverted' : ''}`}
            type='TailSpin'
            width={width}
            height={height}
        />
    )
}

export default Loading
