import {
  BestFitFAQ1,
  BestFitFAQ2,
  BestFitFAQ3,
  BestFitFAQ4,
  BestFitFAQ5,
  BestFitFAQ6,
} from "../Answers/BestFit";

export const BestFitFAQS = [
  {
    heading: "How can I add ‘best fit’ profiles?",
    content: <BestFitFAQ1 />,
  },
  {
    heading: "What’s a best fit profile?",
    content: <BestFitFAQ2 />,
  },
  {
    heading: "What if I dont have ‘best fit’ profiles? ",
    content: <BestFitFAQ3 />,
  },
  {
    heading: "How many ‘best fit’ profiles can I add?",
    content: <BestFitFAQ4 />,
  },
  {
    heading: "How does a best fit profile help my search?",
    content: <BestFitFAQ5 />,
  },
  {
    heading: "Can I get an example of who can be a best fit profile?",
    content: <BestFitFAQ6 />,
  },
];
