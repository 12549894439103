import React from "react";

function HelpCircleSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#FFAB2D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7 8.338a5.032 5.032 0 012.247-2.65 5.276 5.276 0 013.477-.616c1.198.2 2.284.804 3.067 1.707A4.91 4.91 0 0117 10.004C17 13.334 11.854 15 11.854 15"
      ></path>
      <path
        stroke="#FFAB2D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M12 20h.01"
      ></path>
    </svg>
  );
}

export default HelpCircleSVG;
