import { get } from 'lodash'
import * as ActionTypes from '../../actions/prospectsView'

const initialState = {
  isSavingProspect: false,
  isArchivingProspect: false,
  isDownloadingProspect: false,
  archRequestCount: 0
}

const meta = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RESET_ARCH_COUNT:
      return { ...state, archRequestCount: 0 }

    case ActionTypes.REQUEST_SAVE_PROSPECTS:
      return { ...state, isSavingProspect: true }

    case ActionTypes.REQUEST_SAVE_PROSPECTS_SUCCESS:
    case ActionTypes.REQUEST_SAVE_PROSPECTS_FAILURE:
      return {
        ...state,
        isSavingProspect: false
      }


    case ActionTypes.REQUEST_ARCHIVE_PROSPECTS:
      return { ...state, isArchivingProspect: true, archRequestCount: state.archRequestCount + get(action, ['meta', 'profiles', 'length'], 0) }

    case ActionTypes.REQUEST_ARCHIVE_PROSPECTS_SUCCESS:
    case ActionTypes.REQUEST_ARCHIVE_PROSPECTS_FAILURE:
      return { ...state, isArchivingProspect: false }


    case ActionTypes.REQUEST_DOWNLOAD_PROSPECTS:
      return { ...state, isDownloadingProspect: true }

    case ActionTypes.REQUEST_DOWNLOAD_PROSPECTS_SUCCESS:
    case ActionTypes.REQUEST_DOWNLOAD_PROSPECTS_FAILURE:
      return { ...state, isDownloadingProspect: false }

    default:
      return state
  }
}

export default meta