import React from 'react'
import './style.scss'

// import {profiles} from "../../../TestComponent/testProfilesData"
import { connect } from 'react-redux'
import schooldPlaceholder from '../../../../img/edu_placeholder.svg'
import SocialHeartButton from '../../../../uiLibrary/SocialHeartButton'
import SocialMaybeButton from '../../../../uiLibrary/SocialMaybeButton'
import SocialRejectButton from '../../../../uiLibrary/SocialRejectButton'
import { getValidDate } from '../../../../utils'
import { ActivityTypes, loggingActivity } from '../../../../utils/activityLogger'
import ProfileCardExperienceDetails from '../ProfileCardExperienceDetails'
import ProfileCardTagsDetails from '../ProfileCardTagsDetails'
import ProfileScoring from '../ProfileScoring'
import ProfileSummary from '../ProfileSummary'
const DeepDetailProfileCard = ({
  index = 0,
  profileData,
  isSelected,
  onSelect,
  recommended,
  showReview,
  showCompanyModal,
  closeCompanyModal,
  handleRecommendedCard,
  removeHoverHighlighter,
  likedStatus,
  onClickCard,
  onClickComments,
  onClickSave = e => false,
  onClickMaybe = e => false,
  disableFeedback = false,
  isQuick,
  qParams,
  jId,
  archRequestCount
}) => {
  // const profileData = profiles[0]
  // console.log("========================DeepDetailProfileCard=============================", {profileData})
  const { skill_importance, skills_required, specialties_found, specialties_required } = profileData?.scoring


  const skillsFound = [
    ...(skill_importance?.must_haves || []),
    ...(skill_importance?.important || []),
    ...(skill_importance?.nice_to_haves || [])
  ]

  const skillsMissing = [
    ...(skills_required?.must_haves || []),
    ...(skills_required?.important || []),
    ...(skills_required?.nice_to_haves || [])
  ].filter(skl => !skillsFound.includes(skl))

  const specialtiesFound = Object.keys(specialties_found)

  const specialtiesMissing = specialties_required.filter(itm => !specialtiesFound.includes(itm))

  const validEduDate = (start = '', end = '') => {
    let s = getValidDate(start)
    let e = getValidDate(end)
    if (s === '' && e === '') return { start: "", end: "" }
    if (e === '') return { start: s, end: "Now" }
    return { start: s, end: e }
  }

  return (
    <div
      className='deepDetailProfileCard'
    >
      {profileData?.fromEs && process.env.REACT_APP_ENV !== 'production' &&
        <div style={{ position: 'absolute', top: 0, left: 0, color: 'yellow' }}>ES
          {profileData?.scoring?.intelligentTitleScoring && "*"
          }</div>
      }
      <span>
        <ProfileSummary
          qParams={qParams}
          archRequestCount={archRequestCount}
          isSelected={isSelected}
          onSelect={onSelect}
          name={profileData.fullName}
          title={profileData.title}
          company={profileData.currentCompanyName || profileData.companyName}
          location={profileData.locality || profileData.location}
          src={profileData.imageUrl}
          emojies={profileData?.emojies}
          index={index}
          onClickComments={onClickComments}
          jobProfileId={profileData?.jobProfileId}
          onClickCompany={(e, ...props) => showCompanyModal(profileData?.experience?.[0], ...props)}
          profileData={profileData}
          mixpanelActionType={'detailed view'}

        />
        <div
          className='socialButtonContainer'
          style={disableFeedback ? { borderBottom: 'none', padding: '0' } : {}}
        >
          {!disableFeedback && <SocialHeartButton
            index={index}
            jobProfileId={profileData?.jobProfileId}
            profileId={profileData?.id}
            maybe={profileData?.maybe}
            saved={profileData?.saved}
            onClickSave={onClickSave}
            mixpanelActionType={'detailed view'}

          />}
          {!disableFeedback && <SocialMaybeButton
            index={index}
            jobProfileId={profileData?.jobProfileId}
            profileId={profileData?.id}
            maybe={profileData?.maybe}
            saved={profileData?.saved}
            onClickSave={onClickSave}
            onClickMaybe={onClickMaybe}
            mixpanelActionType={'detailed view'}

          />}
          {!disableFeedback && <SocialRejectButton
            index={index}
            jobProfileId={profileData?.jobProfileId}
            archived={profileData?.archived}
            saved={profileData?.saved}
            onClickSave={onClickSave}
            mixpanelActionType={'detailed view'}
          />}
        </div>
        <ProfileScoring
          // containerStyle={{width: '350px'}}
          buck_score={profileData.buck_score}
          scoring={profileData.scoring}
          isQuick={isQuick}
        />
      </span>
      <span>
        <span>
          <h6
            onClick={(e) => {
              loggingActivity(ActivityTypes.VIEW_PROFILE, jId, { profileId: profileData.id })
              onClickCard(e)
            }}
          >
            View details
          </h6>
        </span>
        <div>
          <h3>
            Experience
          </h3>
          <span>
            <ProfileCardExperienceDetails
              experience={profileData?.experience?.slice(0, 3).map((exp, i) => ({ title: exp?.title, onClick: (e, init, fin) => showCompanyModal(exp, init, fin), name: exp?.company_name, logo: exp?.logo, start: getValidDate(exp?.start), end: i === 0 ? "Now" : getValidDate(exp?.end) }))}
              seeMore={profileData?.experience?.slice(3).map((exp, i) => ({ title: exp?.title, onClick: (e, init, fin) => showCompanyModal(exp, init, fin), name: exp?.company_name, logo: exp?.logo, start: getValidDate(exp?.start), end: i === 0 ? "Now" : getValidDate(exp?.end) }))}
            />
          </span>
        </div>
        <div>
          <h3>
            Education
          </h3>
          <span>
            <ProfileCardExperienceDetails
              delimiter="from"
              defaultPlaceholder={schooldPlaceholder}
              experience={profileData?.education?.slice(0, 3).map((ed, i) => ({ title: `${ed.degreeName || ""}${ed.degreeName && ed.fieldOfStudy ? " in " : ""}${ed.fieldOfStudy || ""}`, name: ed.schoolName, logo: ed.logo, start: validEduDate(ed.start, ed.end).start, end: validEduDate(ed.start, ed.end).end }))}
              seeMore={profileData?.education?.slice(3).map((ed, i) => ({ title: `${ed.degreeName || ""}${ed.degreeName && ed.fieldOfStudy ? " in " : ""}${ed.fieldOfStudy || ""}`, name: ed.schoolName, logo: ed.logo, start: validEduDate(ed.start, ed.end).start, end: validEduDate(ed.start, ed.end).end }))}
            />
          </span>
        </div>
        <div>
          <h3
            style={{ padding: '20px 32px' }}
          >
            Skills
          </h3>
          <span>
            <ProfileCardTagsDetails
              found={skillsFound}
              missing={skillsMissing}
            />
          </span>
        </div>
        <div>
          <h3
            style={{ padding: '20px 32px 40px 32px' }}
          >
            Business
          </h3>
          <span
            style={{ paddingBottom: '20px' }}
          >
            <ProfileCardTagsDetails
              found={specialtiesFound}
              missing={specialtiesMissing}
            />
          </span>
        </div>
      </span>
    </div >
  )
}

export default connect(state => ({
  likedStatus: state.prospectsView.candidates.likedStatus,
}))(DeepDetailProfileCard)
