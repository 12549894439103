import { combineReducers } from 'redux'
import campaigns from './campaigns'
import viewController from './viewController'
import campaignOperations from './campaignOperations'
import meta from './meta'
export default combineReducers({
    campaigns,
    viewController,
    campaignOperations,
    meta
})