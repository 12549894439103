import React from "react";
import { useSelector } from "react-redux";
import StyleableTextAreaContainer from "../../../components/StyleableTextArea/StyleableTextAreaContainer";
import MessageClassifier from "../../../uiLibrary/MessageClassifier";
import ConnectSvg from "../../../uiLibrary/SVGs/ConnectSvg";
import InmailSVG from "../../../uiLibrary/SVGs/InmailSvg";
import MailSVG from "../../../uiLibrary/SVGs/MailSvg";
import RoundsHeader from "./RoundsHeader";
import "./styles.scss";

export default function Round({
  messages,
  channel,
  template,
  subject,
  index,
  ...round
}) {
  const channelOptions = {
    linkedin: {
      name: "LinkedIn Message",
      icon: <ConnectSvg />,
    },
    email: {
      name: "Email",
      icon: <MailSVG />,
    },
    inmail: {
      name: "InMail",
      icon: <InmailSVG />,
    },
    recruiter: {
      name: "Recruiter InMail",
      icon: <InmailSVG />,
    },
  };
  const userEmail = useSelector((state) => state.auth.user.email);
  const selectedProspect = useSelector(
    (state) => state.outbox.viewController.recipientTab
  );

  const [activeMessage, setActiveMessage] = React.useState("");

  const getActiveMessage = (messages, defaultTemplate, key) => {
    const recipient = messages.filter(
      ({ jobprofile_id }) => jobprofile_id === selectedProspect
    );
    if (!!recipient.length) {
      const [selected] = recipient;
      return selected[key];
    }

    return defaultTemplate;
  };

  const hasSubject = ["email", "inmail", "recruiter"].includes(channel);

  return (
    <div className="round">
      <div className="roundsHeader">
        <RoundsHeader
          icon={channelOptions[channel].icon}
          index={index}
          data={round}
        />
      </div>
      <div className="roundsBody">
        <div className="message">
          <div className="hybridMessagingHeadlineContainer">
            <h3>Message</h3>
          </div>
          {hasSubject && (
            <div className="outboxMessagingSubjectContainer">
              <div>
                <h3>From</h3>
                <p>{userEmail}</p>
              </div>
              <div>
                <h3>Subject</h3>
                <input
                  value={getActiveMessage(messages, subject, "subject")}
                  onChange={(e) => {}}
                />
              </div>
            </div>
          )}
          <StyleableTextAreaContainer
            message={getActiveMessage(messages, template, "message")}
            onChange={() => {}}
            skillsReq={[]}
            charLimits={false}
            shouldShowTagButtons={false}
            canDeleteSkill={false}
          />
        </div>
        <div className="assistant">
          <MessageClassifier message={template} />
        </div>
      </div>
    </div>
  );
}
