import React from "react";
import { Transition } from "react-transition-group";
import Backdrop from "../Backdrop";
import CaretLeft from "../SVGs/CaretLeft";
import CloseIconWhiteSvg from "../SVGs/CloseIconWhiteSvg";
import MinimizeIconSvg from "../SVGs/MinimizeIconSvg";
import "./style.scss";

const duration = 900;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function SlideDrawer({
  isOpen,
  setIsOpen,
  heading = "",
  canMinimize,
  handleClose,
  isPrimaryScreen = true,
  setIsPrimaryScreen,
  children,
  secondaryChildren,
}) {
  const drawerClasses = isOpen ? "side-drawer open" : "side-drawer";
  return (
    <>
      {isOpen && <Backdrop onClick={() => setIsOpen(false)} />}
      <Transition in={isOpen} timeout={duration}>
        {(state) => (
          <div
            style={{ ...defaultStyle, ...transitionStyles[state] }}
            className={"side-drawer open"}
          >
            <div
              style={{
                height: 60,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {isPrimaryScreen ? (
                <>
                  <span className="headingText" style={{ width: "80%" }}>
                    {heading}
                  </span>
                  <div className="windowControls">
                    <button onClick={handleClose} className="controlButton">
                      <CloseIconWhiteSvg />
                    </button>
                    {canMinimize && (
                      <button
                        onClick={() => setIsOpen(false)}
                        className="controlButton"
                      >
                        <MinimizeIconSvg />
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="controlButton" onClick={() => setIsPrimaryScreen(true)} style={{ marginLeft: 15 }}>
                    <CaretLeft />
                  </div>
                  <p
                    style={{ width: "95%", marginLeft: 10 }}
                    className="headingText"
                  >
                    Contact Support
                  </p>
                </>
              )}
            </div>
            <div className="body">
              {isPrimaryScreen ? children : secondaryChildren}
            </div>
          </div>
        )}
      </Transition>
    </>
  );
}
