import { Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as Yup from "yup"
import { confirmInvitation, getInvitedUserDetails } from '../../actions/recruiter'
import dnIcon from '../../img/dnicon.svg'
import dnnae from '../../img/dnnae.svg'
import './Recruiter.scss'

const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string().email('Invalid email'),
    password: Yup.string().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ).required("This field is required"),
    confirmPassword: Yup.string().matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ).when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
        )
    })
});

const Recruiter = ({ match, firstName, lastName, password, confirmPassword, history, email, isAuthenticated, isConfirmed, dispatch }) => {
    useEffect(() => {
        if (isAuthenticated) {
            history.push('/')
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (match.params && match.params.tribeId && match.params.userId) {
            dispatch(getInvitedUserDetails(match.params.tribeId, match.params.userId))
        }
    }, [])

    useEffect(() => {
    }, [email])

    useEffect(() => {

    }, [isConfirmed])

    return (
        <div className='auth_layout'>
            <div className='auth_layout_content'>
                <div className='auth_header_root'>
                    <div>
                        <img src={dnIcon} alt='N/A' />
                        <img src={dnnae} alt='N/A' />
                    </div>
                </div>
                <div>
                    {isConfirmed ?
                        <div className="invite-confirmed">
                            <p>You have already used this link</p>

                            <button type="submit" className="primaryButton" onClick={() => history.push('/')}>View My Jobs</button>
                        </div>
                        :
                        <Formik
                            initialValues={{
                                firstName,
                                lastName,
                                password,
                                confirmPassword,
                                email
                            }}
                            validationSchema={SignupSchema}
                            onSubmit={async (values) => {
                                dispatch(confirmInvitation({ ...values, email, "tId": match?.params?.tribeId, "uId": match?.params?.userId }, history))
                            }}
                        >
                            {({ errors, touched }) => (
                                <Form className="recruiter-form-invite">
                                    <label htmlFor="email">Email</label>
                                    <Field
                                        id="email"
                                        name="email"
                                        placeholder="jane@acme.com"
                                        type="email"
                                        disabled
                                        className="primaryInput"
                                        value={email}
                                    />
                                    {errors.email && touched.email ? <div className="error-labels">{errors.email}</div> : null}
                                    <label htmlFor="firstName">First Name</label>
                                    <Field id="firstName" name="firstName" placeholder="Jane" className="primaryInput" />
                                    {errors.firstName && touched.firstName ? (
                                        <div className="error-labels">{errors.firstName}</div>
                                    ) : null}
                                    <label htmlFor="lastName">Last Name</label>
                                    <Field id="lastName" name="lastName" placeholder="Doe" className="primaryInput" />
                                    {errors.lastName && touched.lastName ? (
                                        <div className="error-labels">{errors.lastName}</div>
                                    ) : null}
                                    <label htmlFor="password">Password</label>
                                    <Field id="password" name="password" className="primaryInput" type="password" />
                                    {errors.password && touched.password ? (
                                        <div className="error-labels">{errors.password}</div>
                                    ) : null}
                                    <label htmlFor="confirmPassword">Repeat Password</label>
                                    <Field id="confirmPassword" name="confirmPassword" className="primaryInput" type="password" />
                                    {errors.confirmPassword && touched.confirmPassword ? (
                                        <div className="error-labels">{errors.confirmPassword}</div>
                                    ) : null}
                                    <button type="submit" className="primaryButton">Continue</button>
                                </Form>
                            )}
                        </Formik>
                    }
                </div>
            </div>
            <div className='auth_layout_curtain' />
        </div>
    )
}

export default connect(state => ({
    lastName: state.recruiter.invite.lastName,
    firstName: state.recruiter.invite.firstName,
    email: state.recruiter.invite.email,
    password: state.recruiter.invite.password,
    confirmPassword: state.recruiter.invite.confirmPassword,
    isConfirmed: state.recruiter.invite.isConfirmed,
    isAuthenticated: state.auth.user.isAuthenticated,
}))(Recruiter)
