
import qs from 'query-string'
import React, { useEffect } from 'react'
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom'
import './style.scss'
const ConsentGranted = ({
    location:{
        search
    }
}) => {
    useEffect(() => {
        if (window.opener) {
            const query = qs.parse(search)
            window.opener.postMessage(JSON.stringify({ emailIntegrated: true, query: query }), '*')
            window.close()
        }
    }, [])
    return (
        <div
            className='ConsentGrantedContainer'
        >
            <Loader
                color='#FF8D4E'
                type='TailSpin'
                width='50vh'
                height='50vh'
            />
        </div>
    )
}

export default withRouter(ConsentGranted)
