import { React, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import PrimaryButton from '../../../uiLibrary/PrimaryButton';
import Cross from "../../../components/svgComponents/CrossSvg";
import LabelButton from '../../../uiLibrary/LabelButton';

import CalendarSettings from './CalendarSettings'
import MessagingSettings from './MessagingSettings'
import AccountSettings from './AccountSettings'
import './settings.scss'

import { fetchCalendarSettings } from '../../../actions/calendar';
import { updateCalendarSettings } from '../../../actions/calendar';
import { postCalendarSettings } from '../../../actions/calendar';
import { validateEmailPermissionToken } from '../../../actions/hybridCampaign';
import qs from 'query-string'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HybridConsentScreen from '../../Outbox/HybridConsentScreen';
import MultiFactorAuth from './MultiFactorAuth';
import NotificationDropdownPanel from '../../../views/LandingPage/Home/NotificationDropdownPanel'


const Settings = ({ settings, userData, outboxView, dispatch, history, emailProvider, mailboxLinked, emailaddress, location: {
  search,
}, location }) => {

  const toastId = "toast-id";
  let [calendar, setCalendar] = useState(null);
  let [showToast, setShowToast] = useState(false);
  let account = null;
  let messaging = null;

  const params = qs.parse(search);
  if (params.calendar && params.calendar == "added" && !showToast) {
    setShowToast(true);
    toast("Calendar added successfully!", {
      toastId: toastId,
      position: "top-center",
      hideProgressBar: true,
      style: {
        background: '#A0A0A0',
        color: 'black',
        width: '260px',
        height: '52px'
      }
    });
  }

  useEffect(() => {
    getSettings();
    dispatch(validateEmailPermissionToken());

  }, [])

  const getSettings = () => {
    dispatch(fetchCalendarSettings())
  }

  const setSettings = () => {
    if (Object.keys(settings).length === 0) {
      dispatch(postCalendarSettings(calendar))
    }
    else {
      dispatch(updateCalendarSettings(calendar))
    }
    history.push("/")
  }

  const onCancelSettings = () => {
    calendar = null
    setCalendar(JSON.parse(JSON.stringify(calendar)));
    history.push("/")
  }

  const onResetSettings = () => {
    calendar = null
    setCalendar(JSON.parse(JSON.stringify(calendar)));
  }

  const onChange = (payload, type) => {
    if (type == "calendar") {
      calendar = payload
      setCalendar(JSON.parse(JSON.stringify(calendar)));
    }
    else if (type == "messaging") {
      messaging = payload;
    }
    else if (type == "account") {
      account = payload;
    }
  }


  // console.log("===============Settings=========================", outboxView)
  if (outboxView === 'CONSENT') {
    // console.log("===============Settings=========================", outboxView)
    return (
      <HybridConsentScreen
        style={{ width: '100%' }}
      />
    )
  }

  return (
    <div className="settings">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="mainHeading">Settings</div>
        <NotificationDropdownPanel
          historyPush={history.push}
          location={location}
        />
      </div>
      <div className="mainContent">
        <ToastContainer />
        <MultiFactorAuth
          push={history.push}
          dispatch={dispatch}
          emailProvider={emailProvider}
          userData={userData} />
        <MessagingSettings push={history.push} mailboxLinked={mailboxLinked} dispatch={dispatch} emailaddress={emailaddress} settings={settings} />
        {/* <CalendarSettings settings={calendar == null ? JSON.parse(JSON.stringify(settings)) : calendar} onChange={onChange} /> */}
        <AccountSettings settings={{ settings }} onChange={onChange} />
        <div className="footer">
          <div className="flexOnlyRow">
            <div className="flexOnly">
              <button className="crossIconButton" onClick={onCancelSettings}>
                <Cross style={{ transform: "rotate(90deg)" }}
                  color="#A7ABB0" />
              </button>
              <LabelButton className="cancelButton" onClick={onCancelSettings}>
                Cancel
              </LabelButton>
            </div>
            <div className="flexOnly">
              <LabelButton className="resetButton" onClick={onResetSettings}>
                Reset to Default
              </LabelButton>
              <PrimaryButton className="saveButton" onClick={setSettings}>
                Save Changes
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(state => ({
  settings: state.auth.calendar.settings,
  emailProvider: state.outbox.campaignOperations.emailProvider,
  mailboxLinked: state.outbox.campaignOperations.emailState,
  emailaddress: state.auth.user.email,
  outboxView: state.outbox.viewController.view,
  userData: state.auth.user
}))(Settings)