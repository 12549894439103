import React, { useEffect, useState } from "react";

import displayPlaceholder from "../../img/displayPlaceholder.svg";
import companyPlaceholder from "../../img/company_placeholder.svg";

import "./ProfileDisplayImage.scss";

const ProfileDisplayImage = ({
  width = "46px",
  height = "46px",
  src,
  className,
  style = {},
  defaultPlaceholder = null,
  isCompany = false
}) => {
  // console.log('IMAGE SOURCE IS: ', src)
  // // const [displayPicSrc, setDisplayPicSrc] = useState(src)

  if (!(defaultPlaceholder)) {
    defaultPlaceholder = isCompany ? companyPlaceholder : displayPlaceholder
  }

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false)
  }, [src])


  return (
    <img
      style={style}
      className={`profileDisplayImage ${className ? className : ""}`}
      width={width}
      height={height}
      src={hasError ? defaultPlaceholder : src || defaultPlaceholder}
      onError={() => setHasError(true)}
    />
  );
};

export default ProfileDisplayImage;
