import React from "react";
import { connect } from "react-redux";
import Accordion from "../../../uiLibrary/Accordion";
import "./style.scss";
export default connect((state) => ({
  generateJD: state.jobSetup?.jobSetup?.generateJD,
}))
  (function FAQScreen({ fullScreen = false, setIsPrimaryScreen, data = [], generateJD }) {
    const marginLeft = fullScreen ? 180 : 60;
    const marginTop = 15;
    return (
      <div>
        <div className={fullScreen ? "fullScreenAccordion" : "accordion"}>
          {data?.map(({ heading, content }, key) => (
            <Accordion heading={heading} content={content} key={key} generateJD={generateJD} />
          ))}
        </div>
        {/* <GreyButton
        style={{ marginLeft, marginTop }}
        onClick={() => {
          setIsPrimaryScreen(false);
        }}
      >
        Contact Support
      </GreyButton> */}
      </div>
    );
  }
  )