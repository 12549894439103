import React from 'react'

const Send = ({
    color = "#CDD1D7",
    width = "16",
    height = "20",
    style = {},
    stroke = 1.5, className = '',
    fill = "none"
}) => {

    return (
        <div>
            <svg
                width={width}
                height={height}
                className={className}
                viewBox="0 0 24 24"
                fill={fill}
                xmlns="http://www.w3.org/2000/svg"
                style={style}
            >
                <path d="M22 2L11 13" stroke={color} stroke-width={stroke} stroke-linecap="round" stroke-linejoin="round" />
                <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke={color} stroke-width={stroke} stroke-linecap="round" stroke-linejoin="round" />

            </svg>

        </div>
    )
}

export default Send
