import React from "react";

export const CompanyDetailsFAQ1 = () => {
  return (
    <div>
      <p>
        It helps Qlu understand your search better and provide you with more
        precise results. The following information is taken from hiring company:
        <ul>
          <li>Business, Specialty or Industry</li>
          <li>Employee count for Main title</li>
        </ul>
      </p>
    </div>
  );
};

export const CompanyDetailsFAQ2 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>From their respective Linkedin Pages</li>
        </ul>
      </p>
    </div>
  );
};

export const CompanyDetailsFAQ3 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>It's not mandatory to add a hiring company you can skip it </li>
        </ul>
      </p>
    </div>
  );
};

export const CompanyDetailsFAQ4 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>No, you would have to create another job for that</li>
        </ul>
      </p>
    </div>
  );
};

export const CompanyDetailsFAQ5 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            Companies from the same Business, Specialty or Industry with a
            similar employee count are mostly relevant
          </li>
        </ul>
      </p>
    </div>
  );
};

export const CompanyDetailsFAQ6 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            You can hover on the company you would like to learn about and its
            information will load on the right
          </li>
        </ul>
      </p>
    </div>
  );
};

export const CompanyDetailsFAQ7 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            Business, Specialty or Industry are extracted from the similar
            companies for you to choose from in the job set up
          </li>
          <li>
            There will be an option on the results page to show prospects only
            from the companies that you have selected, which is the All
            companies tab
          </li>
        </ul>
      </p>
    </div>
  );
};

export const CompanyDetailsFAQ8 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Upto 30</li>
        </ul>
      </p>
    </div>
  );
};

export const CompanyDetailsFAQ9 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            They are suggested companies similar to the one that you selected
          </li>
        </ul>
      </p>
    </div>
  );
};

export const CompanyDetailsFAQ10 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            The search will include prospects from the companies that you have
            selected and other companies as well
          </li>
        </ul>
      </p>
    </div>
  );
};

export const CompanyDetailsFAQ11 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>No, for now you would have to select the companies you like</li>
        </ul>
      </p>
    </div>
  );
};
