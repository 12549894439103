import React from "react";
import "./RenderInlineStyles.scss";
import { RichUtils } from "draft-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBold,
  faUnderline,
  faItalic,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import SecondaryButton from '../../uiLibrary/SecondaryButton'

const RenderInlineStyles = ({ editorState, updateEditorState, value }) => {
  const inlineStyles = [
    {
      label: "bold",
      style: "BOLD",
      icon: <FontAwesomeIcon icon={faBold} size="sm" />,
    },
    {
      label: "italic",
      style: "ITALIC",
      icon: <FontAwesomeIcon icon={faItalic} size="sm" />,
    },
    {
      label: "Underline",
      style: "UNDERLINE",
      icon: <FontAwesomeIcon icon={faUnderline} size="sm" />,
    },
  ];
  const blockStyle = [
    {
      label: "list",
      style: "unordered-list-item",
      icon: <FontAwesomeIcon icon={faListUl} size="sm" />,
    },
  ];
  const applyStyle = (e, style) => {
    e.preventDefault();
    updateEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const applyBlockStyle = (e, style) => {
    updateEditorState(RichUtils.toggleBlockType(editorState, style));
  };

  const isActive = (style) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return currentStyle.has(style);
  };

  const isActiveBlock = (style) => {
    const currentStyle = RichUtils.getCurrentBlockType(editorState);
    return style === currentStyle;
  };

  return (
    <div className="renderInlineEditorContainer">
      {inlineStyles.map((item, idx) => {
        return (
          <div
            className={`renderInlineEditortoolBarItem${
              isActive(item.style) ? " renderInlineEditortoolBarItemIsActive" : ""
            }`}
            key={`${item.label}-${idx}`}
            onMouseDown={(e) => {
              e.preventDefault();
              applyStyle(e, item.style);
            }}
          >
            {item.icon || item.label}
          </div>
        );
      })}
      {blockStyle.map((item, index) => {
        return (
          <div
            className={`renderInlineEditortoolBarItem${
              isActiveBlock(item.style) ? "renderInlineEditortoolBarItemIsActive" : ""
            }`}
            key={`${item.label}-${index}`}
            onMouseDown={(e) => {
              e.preventDefault();
              applyBlockStyle(e, item.style)}}
          >
            {item.icon || item.label}
          </div>
        );
      })}
     
    </div>
  );
};

export default RenderInlineStyles;
