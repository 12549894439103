const createReducer = (
  [FETCH, SUCCESS, FAILURE],
  availabilityPredicate = (payload) => {
    return payload.length > 0;
  }
) => {
  const initialState = {
    isLoading: false,
    isDataAvailable: false,
    data: [],
  };

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH:
        return { ...state, isLoading: true };

      case SUCCESS:
        return {
          ...state,
          isLoading: false,
          isDataAvailable: availabilityPredicate(action.payload),
          data: action.payload.data,
        };

      case FAILURE:
        return initialState;

      default:
        return state;
    }
  };

  return reducer;
};

export default createReducer;
