import { manualApiCall } from "../utils";
import qs from "qs";
import { omit } from "lodash";
import { setSubDropdownView } from "./prospectsView";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_ERROR = "FETCH_NOTIFICATIONS_ERROR";

export const getAllNotifications = (pageNo = 1) => async (
  dispatch,
  getState
) => {
  try {
    console.log("==============getAllNotifications=================", dispatch);
    dispatch({
      type: FETCH_NOTIFICATIONS,
    });

    const res = await manualApiCall(
      `/api/v2/auth/notifications?pageNo=${pageNo}`,
      {
        method: "GET",
      }
    );

    if (res.isSuccess) {
      dispatch({
        type: FETCH_NOTIFICATIONS_SUCCESS,
        payload: { ...res, polling: false, pageNo: pageNo === 1 ? 1 : pageNo },
      });
    } else {
      dispatch({
        type: FETCH_NOTIFICATIONS_ERROR,
      });
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: FETCH_NOTIFICATIONS_ERROR,
    });
  }
};

export const setNotificationRead = (ids) => async (dispatch, getState) => {
  try {
    console.log("==============setNotificationRead=================", dispatch);
    if (ids?.length > 0) {
      const res = await manualApiCall(`/api/v2/auth/notifications/set`, {
        method: "POST",
        body: JSON.stringify({
          id: ids,
        }),
      });

      if (res.isSuccess) {
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const notificationAppStateHandler = (
  notification,
  history,
  location
) => async (dispatch, getState) => {
  try {
    console.log(
      "==============notificationAppStateHandler=================",
      notification,
      location,
      dispatch
    );
    let meta = JSON.parse(notification?.meta);
    const qParams = qs.parse(location.search.split("?").pop());
    const newParams = {
      ...omit(
        { ...qParams, fF: "a", b: "all", nT: "", nPs: "", nM: "", p: 0 },
        ["cm", "rR"]
      ),
    };

    history(
      `${location.pathname}?${qs.stringify(
        omit(
          { ...newParams, fF: "a", b: "all", nT: "", nPs: "", nM: "", p: 0 },
          "cm",
          "rR"
        )
      )}`
    );
    setTimeout(() => {
      if (
        location.pathname !== `/job/${notification?.jobId}` &&
        notification?.jobId
      ) {
        history(`/job/${notification?.jobId}`);
      }
    }, 100);
    switch (notification.type) {
      case "REACTED_TO_COMMENT":
        dispatch(setSubDropdownView("Prospects"));

        setTimeout(() => {
          history(
            `${location.pathname}?${qs.stringify({
              ...newParams,
              nT: notification?.type,
              nPs: notification?.jobProfileIds?.join(","),
              nM: meta?.threadId,
              p: meta?.pageNo ? meta?.pageNo : 0,
            })}`
          );
        }, 500);

        break;
      case "REPLIED_TO_COMMENT":
        dispatch(setSubDropdownView("Prospects"));
        setTimeout(() => {
          history(
            `${location.pathname}?${qs.stringify({
              ...newParams,
              nT: notification?.type,
              nPs: notification?.jobProfileIds?.join(","),
              nM: meta?.threadId,
              p: meta?.pageNo ? meta?.pageNo : 0,
            })}`
          );
        }, 1000);
        break;
      case "COMMENT_ADD":
        dispatch(setSubDropdownView("Prospects"));
        setTimeout(() => {
          history(
            `${location.pathname}?${qs.stringify({
              ...newParams,
              nT: notification?.type,
              nPs: notification?.jobProfileIds?.join(","),
              nM: meta?.threadId,
              p: meta?.pageNo ? meta?.pageNo : 0,
            })}`
          );
        }, 1000);
        break;
      case "COMMENT_ADD_WITH_MENTIONS":
        dispatch(setSubDropdownView("Prospects"));
        setTimeout(() => {
          history(
            `${location.pathname}?${qs.stringify({
              ...newParams,
              nT: notification?.type,
              nPs: notification?.jobProfileIds?.join(","),
              nM: meta?.threadId,
              p: meta?.pageNo ? meta?.pageNo : 0,
            })}`
          );
        }, 1000);
        break;
      case "EMOJI_ADD":
        dispatch(setSubDropdownView("Prospects"));
        setTimeout(() => {
          history(
            `${location.pathname}?${qs.stringify({
              ...newParams,
              nT: notification?.type,
              nPs: notification?.jobProfileIds?.join(","),
              nM: meta?.threadId,
              p: meta?.pageNo ? meta?.pageNo : 0,
            })}`
          );
        }, 1000);
        break;

      default:
        break;
    }
  } catch (e) {
    console.log(e);
  }
};
