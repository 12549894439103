import React from "react";

export const JobTitleFAQ1 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>It is the title of the job you are hiring for</li>
        </ul>
      </p>
    </div>
  );
};

export const JobTitleFAQ2 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>The title of the job you are hiring for</li>
        </ul>
      </p>
    </div>
  );
};

export const JobTitleFAQ3 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>From the job description that you provided</li>
        </ul>
      </p>
    </div>
  );
};

export const JobTitleFAQ4 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            That could be because it was not placed on the top of the job
            description. Remember to do next time so it is detected
          </li>
        </ul>
      </p>
    </div>
  );
};

export const JobTitleFAQ5 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Yes</li>
        </ul>
      </p>
    </div>
  );
};

export const JobTitleFAQ6 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Click on the title</li>
          <li>Now type whatever you would like to change it too</li>
          <li>Select from drop down</li>
        </ul>
      </p>
    </div>
  );
};

export const JobTitleFAQ7 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            The number of employees of the company, from which you are
            interested in hiring a prospect from of a specific job title
          </li>
        </ul>
      </p>
    </div>
  );
};

export const JobTitleFAQ8 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Press and hold on the slider</li>
          <li>Now set the lower limit</li>
          <li>After that set the upper limit</li>
        </ul>
      </p>
    </div>
  );
};