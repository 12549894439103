// import { keys } from "lodash"

import moment from "moment";
import { BATCH_SIZE } from "../constants/api";
import { CALL_API } from "../middlewares/apiMiddleware";
import { manualApiCall } from "../utils";

export const NEXT_PAGE = "NEXT_PAGE";
export const nextPage = () => ({
  type: NEXT_PAGE,
});

export const INIT_PAGE = "INIT_PAGE";
export const initPage = () => ({
  type: INIT_PAGE,
});

export const RESET_ARCH_COUNT = "RESET_ARCH_COUNT";
export const resetArchCount = () => ({
  type: RESET_ARCH_COUNT,
});

export const FETCH_JOB_CAMPAIGNS = "FETCH_JOB_CAMPAIGNS";
export const FETCH_JOB_CAMPAIGNS_SUCCESS = "FETCH_JOB_CAMPAIGNS_SUCCESS";
export const FETCH_JOB_CAMPAIGNS_FAILURE = "FETCH_JOB_CAMPAIGNS_FAILURE";

export const fetchJobCampaigns = (jobId = null, search = null) => ({
  [CALL_API]: {
    types: [
      FETCH_JOB_CAMPAIGNS,
      FETCH_JOB_CAMPAIGNS_SUCCESS,
      FETCH_JOB_CAMPAIGNS_FAILURE,
    ],
    endpoint: `/api/v2/auth/campaigns?jobId=${jobId}&type=m${
      search ? `&s=${search}` : ""
    }`,
    options: {
      method: "GET",
    },
  },
});
export const SET_ABORT_CONTROLLER = "SET_ABORT_CONTROLLER";
export const FETCH_JOB_TRIBE_CAMPAIGNS = "FETCH_JOB_TRIBE_CAMPAIGNS";
export const FETCH_JOB_TRIBE_CAMPAIGNS_SUCCESS =
  "FETCH_JOB_TRIBE_CAMPAIGNS_SUCCESS";
export const FETCH_JOB_TRIBE_CAMPAIGNS_FAILURE =
  "FETCH_JOB_TRIBE_CAMPAIGNS_FAILURE";

export const fetchJobTribeCampaigns = (jobId, search = null) => ({
  [CALL_API]: {
    types: [
      FETCH_JOB_TRIBE_CAMPAIGNS,
      FETCH_JOB_TRIBE_CAMPAIGNS_SUCCESS,
      FETCH_JOB_TRIBE_CAMPAIGNS_FAILURE,
    ],
    endpoint: `/api/v2/auth/campaigns?jobId=${jobId}&type=t${
      search ? `&s=${search}` : ""
    }`,
    options: {
      method: "GET",
    },
  },
});

export const FETCH_PROSPECTS = "FETCH_PROSPECTS";
export const FETCH_PROSPECTS_SUCCESS = "FETCH_PROSPECTS_SUCCESS";
export const FETCH_PROSPECTS_FAILURE = "FETCH_PROSPECTS_FAILURE";
export const FETCH_TRIBE_MEMBERS_SUCCESS = "FETCH_TRIBE_MEMBERS_SUCCESS";
export const prospectsApiCall = async (
  jobId,
  params,
  signal = null,
  synonym,
  aT
) => {
  try {
    // console.log("=========prospectsApiCall=================", {
    //   jobId,
    //   params,
    //   signal,
    // });
    if (!jobId) throw new Error("jobId undefined!");

    const qStr = `?jId=${jobId}&${Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&")}`;

    const prospects = await manualApiCall(
      `/api/v2/auth/candidates${qStr}`,
      {
        method: "POST",
        body: JSON.stringify({
          jobId,
          params: {
            jId: jobId,
            ...params,
            ...synonym,
            negOff: params.p == 0 ? 0 : params.negOff,
          },
        }),
        signal,
      },
      aT
    );
    let tribeMembers;
    try {
      tribeMembers = await manualApiCall(
        `/api/v2/auth/tribes?jobId=${jobId}`,
        {
          method: "GET",
          signal,
        },
        aT
      );
    } catch {
      console.log("Tribes get error");
    }

    if (prospects.success) {
      if (typeof tribeMembers !== "undefined" && tribeMembers.success) {
        return { prospects, tribeMembers };
      } else {
        return { prospects };
      }
    } else {
      // console.log("==============Propsect Api Failed!================", {prospects})
      throw new Error("Prospect Api Failed!");
    }
  } catch (e) {
    console.log("prospectsApiCall -> Prospect Api Failed!", e);
  }
};

export const fetchProspects = (jobId, params, aT) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: FETCH_PROSPECTS });
    const skills_synonyms =
      getState().prospectsView.advFiltersData.skills_synonyms || [];
    const titles_synonyms =
      getState().prospectsView.advFiltersData.titles_synonyms || [];
    const educations_synonyms =
      getState().prospectsView.advFiltersData.educations_synonyms || [];
    const specialties_synonyms =
      getState().prospectsView.advFiltersData.specialties_synonyms || [];
    const synonym = {
      skills_synonyms,
      titles_synonyms,
      educations_synonyms,
      specialties_synonyms,
    };
    const oldController = getState()?.prospectsView?.prospectVariant
      ?.controller;
    if (oldController) {
      oldController.abort();
      dispatch({
        type: SET_ABORT_CONTROLLER,
        payload: null,
      });
    }

    let newController = new AbortController();
    let signal = newController.signal;
    dispatch({
      type: SET_ABORT_CONTROLLER,
      payload: newController,
    });

    const negOff = getState().prospectsView.meta.archRequestCount;

    const batchCount = Math.ceil(parseInt(params["pPP"]) / BATCH_SIZE);
    const { p, pPP } = params;

    const rev = localStorage.getItem("rev");
    localStorage.removeItem("rev");
    if (rev) {
      const result = await prospectsApiCall(
        jobId,
        { ...params, negOff },
        signal,
        synonym,
        aT
      );
      dispatch({
        type: FETCH_PROSPECTS_SUCCESS,
        payload: result.prospects,
      });
      dispatch({
        type: DELETE_COUNTER,
        payload: 0,
      });
      dispatch({
        type: CREATE_TRIBE_MEMBER,
      });

      if (typeof result !== "undefined" && result.tribeMembers) {
        dispatch({
          type: FETCH_TRIBE_MEMBERS_SUCCESS,
          payload: result.tribeMembers,
        });
      }
    } else {
      for (let i = 0; i < batchCount; i++) {
        const pageNum = (pPP * p) / BATCH_SIZE + (rev ? batchCount - i - 1 : i);
        const result = await prospectsApiCall(
          jobId,
          { ...params, negOff, pPP: BATCH_SIZE, p: pageNum },
          signal,
          synonym,
          aT
        );
        dispatch({
          type: FETCH_PROSPECTS_SUCCESS,
          payload: result.prospects,
          batch: i !== 0,
          rev: i > 0 ? rev : null,
        });
        dispatch({
          type: DELETE_COUNTER,
          payload: 0,
        });
        dispatch({
          type: CREATE_TRIBE_MEMBER,
        });

        if (typeof result !== "undefined" && result.tribeMembers) {
          dispatch({
            type: FETCH_TRIBE_MEMBERS_SUCCESS,
            payload: result.tribeMembers,
          });
        }
        // if (result.prospects.candidates.length < BATCH_SIZE) break;

        // bug-fix-NA-1091
        if (typeof result !== "undefined") {
          if (typeof result?.prospects !== "undefined") {
            if (typeof result?.prospects?.candidates !== "undefined") {
              if (result?.prospects?.candidates?.length < BATCH_SIZE) {
                break;
              }
            }
          }
        }
        //   if (
        //     typeof result !== "undefined" &&
        //     typeof result.prospects !== "undefined" &&
        //     typeof result.prospects.candidates !== "undefined" &&
        //     result?.prospects?.candidates?.length < BATCH_SIZE
        //   )
        //     break;
      }
    }
  } catch (e) {
    console.log(e?.message);
    console.log(e?.message);
    if (e?.message === "AbortError: The user aborted a request.") {
      return;
    }
    // if(!alreadyLoading){
    // bug-fix-NA-1091 : user is cancelling the fetch reqeust for prospect.
    // dispatch({
    //   type: FETCH_PROSPECTS_FAILURE,
    //   error: e.message,
    // });
    // }
  }
};

export const FETCH_ALL_PROSPECTS_SUCCESS = "FETCH_ALL_PROSPECTS_SUCCESS";
export const FETCH_ALL_PROSPECTS_FAILURE = "FETCH_ALL_PROSPECTS_FAILURE";
export const FETCH_ALL_TRIBE_MEMBERS_SUCCESS =
  "FETCH_ALL_TRIBE_MEMBERS_SUCCESS";
export const fetchAllProspects = (jobId, params, aT) => async (
  dispatch,
  getState
) => {
  try {
    // dispatch({ type: FETCH_PROSPECTS });

    const nParams = { ...params };
    // const count = getState().prospectsView.candidates.firstBucket.count || 50
    // nParams['pPP'] = count
    nParams["pFlag"] = false;
    if (![0, "0"].includes(nParams["p"])) return;

    const result = await prospectsApiCall(jobId, nParams);
    dispatch({ type: FETCH_ALL_PROSPECTS_SUCCESS, payload: result.prospects });
    // dispatch({
    //   type: DELETE_COUNTER,
    //   payload: 0,
    // });
    // dispatch({
    //   type: CREATE_TRIBE_MEMBER,
    // });

    // if (typeof result !== "undefined" && result.tribeMembers) {
    //   dispatch({
    //     type: FETCH_TRIBE_MEMBERS_SUCCESS,
    //     payload: result.tribeMembers,
    //   });
    // }
  } catch (e) {
    console.log(e?.message);

    // const loading = getState().prospectsView.candidates.isLoading;
    // console.log("================fetchProspects===========5=============", {alreadyLoading, loading})
    if (e?.message === "AbortError: The user aborted a request.") {
      return;
    }
    // if(!alreadyLoading){
    dispatch({
      type: FETCH_ALL_PROSPECTS_FAILURE,
      error: e.message,
    });
    // }
  }
};

export const ADD_PROSPECTS = "ADD_PROSPECTS";
export const addProspects = (payload) => {
  return {
    type: ADD_PROSPECTS,
    payload,
  };
};

export const CLEAR_PROSPECTS = "CLEAR_PROSPECTS";
export const clearProspects = () => ({
  type: CLEAR_PROSPECTS,
});

export const INITIALIZE_PROSPECTS_CONTROLLER =
  "INITIALIZE_PROSPECTS_CONTROLLER";
export const initializeProspectsController = () => ({
  type: INITIALIZE_PROSPECTS_CONTROLLER,
});

export const CLEAR_SELECTED_PROSPECTS = "CLEAR_SELECTED_PROSPECTS";
export const clearSelectedProspects = (
  prospectsToSelect,
  overWrite = false
) => ({
  type: CLEAR_SELECTED_PROSPECTS,
});

//https://dnnae-api-prod-bdhweedyzq-uc.a.run.app/api/auth/job/profile/downloaded
// {"jobId":3348,"profiles":[4063153],"newJob":true}

export const REQUEST_DOWNLOAD_PROSPECTS = "REQUEST_DOWNLOAD_PROSPECTS";
export const REQUEST_DOWNLOAD_PROSPECTS_SUCCESS =
  "REQUEST_DOWNLOAD_PROSPECTS_SUCCESS";
export const REQUEST_DOWNLOAD_PROSPECTS_FAILURE =
  "REQUEST_DOWNLOAD_PROSPECTS_FAILURE";

export const downloadProspects = (jobId) => async (dispatch, getState) => {
  let profileIds;
  try {
    const selected = getState().prospectsView.candidates.selectedProspects;
    profileIds = Object.keys(selected).map((key) => selected[key].jobProfileId);

    dispatch({
      type: REQUEST_DOWNLOAD_PROSPECTS,
      meta: {
        profiles: profileIds,
      },
    });

    await manualApiCall(`/api/auth/job/profile/downloaded`, {
      method: "POST",
      body: JSON.stringify({
        jobId,
        profiles: profileIds,
        newJob: true,
      }),
    });

    dispatch({
      type: REQUEST_DOWNLOAD_PROSPECTS_SUCCESS,
      meta: {
        profiles: profileIds,
      },
    });
  } catch (e) {
    dispatch({
      type: REQUEST_DOWNLOAD_PROSPECTS_FAILURE,
      meta: {
        profiles: profileIds,
      },
    });
  }
};

// https://dnnae-api-prod-bdhweedyzq-uc.a.run.app/api/auth/job/profile/archived for archiving post call
//{"jobId":"3348","profileId":[4063160],"newJob":true,"action":"DELETE"}

export const REQUEST_ARCHIVE_PROSPECTS = "REQUEST_ARCHIVE_PROSPECTS";
export const REQUEST_ARCHIVE_PROSPECTS_SUCCESS =
  "REQUEST_ARCHIVE_PROSPECTS_SUCCESS";
export const REQUEST_ARCHIVE_PROSPECTS_FAILURE =
  "REQUEST_ARCHIVE_PROSPECTS_FAILURE";

export const archiveProspects = (jobId, jobProfileIds = []) => async (
  dispatch,
  getState
) => {
  let profileIds = [];
  try {
    const selected = getState().prospectsView.candidates.selectedProspects;
    profileIds =
      jobProfileIds.length > 0
        ? jobProfileIds
        : Object.keys(selected).map((key) => selected[key].jobProfileId);

    dispatch({
      type: REQUEST_ARCHIVE_PROSPECTS,
      meta: {
        profiles: profileIds,
      },
    });
    await manualApiCall(`/api/auth/job/profile/archived`, {
      method: "POST",
      body: JSON.stringify({
        jobId: jobId,
        profileId: profileIds,
        newJob: true,
        action:
          Object.keys(selected).filter((key) => selected[key].archived === true)
            ?.length > 0
            ? "ROLLBACK"
            : "DELETE",
      }),
    });

    dispatch({
      type: REQUEST_ARCHIVE_PROSPECTS_SUCCESS,
      meta: {
        profiles: profileIds,
      },
    });
  } catch (e) {
    dispatch({
      type: REQUEST_ARCHIVE_PROSPECTS_FAILURE,
      meta: {
        profiles: profileIds,
      },
    });
  }
};

//bugfix-NA-s21-1625 & bugfix-NA-s21-1624
// getActivitiesofCurrentProspect will request to backend to get Activites of Current prospect on the basis of JobpofileId
export const GET_ACTIVITIES_OF_CURRENTPROSPECT =
  "GET_ACTIVITIES_OF_CURRENTPROSPECT";
export const getActivitiesOfCurrentProspect = (value) => async (
  dispatch,
  getState
) => {
  try {
    console.log("===========getActivitesofCurrentProspect==========="); // dispatch);
    // console.log("values", value);

    const res = await manualApiCall(`/api/v2/auth/getCurrentCandidate`, {
      method: "POST",
      body: JSON.stringify({
        value: value,
      }),
    });

    console.log("🚀 ~ file: prospectsView.js:445 ~ res:", res);
    dispatch({ type: GET_ACTIVITIES_OF_CURRENTPROSPECT, payload: res.resp });
  } catch (e) {
    console.log(e.message);
  }
};
//end

export const REQUEST_SAVE_PROSPECTS = "REQUEST_SAVE_PROSPECTS";
export const REQUEST_SAVE_PROSPECTS_SUCCESS = "REQUEST_SAVE_PROSPECTS_SUCCESS";
export const REQUEST_SAVE_PROSPECTS_FAILURE = "REQUEST_SAVE_PROSPECTS_FAILURE";

export const requestSaveProspects = (
  jobId,
  profiles,
  rollback = false
) => async (dispatch, getState) => {
  let jobProfileIds;
  try {
    const selected = getState().prospectsView.candidates.selectedProspects;
    const activeFolder = getState().prospectsView.meta.activeFolder;

    jobProfileIds =
      profiles.length > 0
        ? profiles
        : Object.keys(selected).map((key) => selected[key].jobProfileId);

    dispatch({
      type: REQUEST_SAVE_PROSPECTS,
      meta: { profiles: jobProfileIds, rollback },
    });
    await manualApiCall(`/api/auth/job/profile/saved`, {
      method: "POST",
      body: JSON.stringify({
        action: rollback ? "ROLLBACK" : "SAVE",
        currentTab: activeFolder,
        jobId: jobId,
        profiles: jobProfileIds,
      }),
    });

    dispatch({
      type: REQUEST_SAVE_PROSPECTS_SUCCESS,
      meta: { profiles: jobProfileIds, rollback },
    });
  } catch (e) {
    console.log("AN ERROR OCCURRED: ", e.message);

    dispatch({
      type: REQUEST_SAVE_PROSPECTS_FAILURE,
      meta: { profiles: jobProfileIds, rollback },
      error: e.message,
    });
  }
};

export const updateSelectedProspects = (payload) => ({
  type: SELECT_PROSPECTS,
  payload,
});

export const UPDATE_PROSPECT = "UPDATE_PROSPECT";
export const updateProspect = (payload) => ({
  type: UPDATE_PROSPECT,
  payload,
});

export const UPDATE_RECOMMENDED_PROSPECT = "UPDATE_RECOMMENDED_PROSPECT";
export const updateRecommendedProspect = (payload) => ({
  type: UPDATE_RECOMMENDED_PROSPECT,
  payload,
});

export const SELECT_PROSPECTS = "SELECT_PROSPECTS";

export const toggleProspect = (prospectToSelect) => (dispatch, getState) => {
  const payload = { ...getState().prospectsView.candidates.selectedProspects };
  if (payload[prospectToSelect.id].isRemoved) {
    payload[prospectToSelect.id].isRemoved = false;
  } else {
    payload[prospectToSelect.id].isRemoved = true;
  }
  dispatch({
    type: SELECT_PROSPECTS,
    payload,
  });
};

export const removeProspects = (prospects) => (dispatch) => {
  dispatch({
    type: SELECT_PROSPECTS,
    payload: prospects,
  });
};

export const selectProspects = (prospectsToSelect, overWrite = false) => (
  dispatch,
  getState
) => {
  const payload = { ...getState().prospectsView.candidates.selectedProspects };
  const prospects = getState().prospectsView.candidates.prospects;
  const overWritePayload = {};

  prospectsToSelect.forEach((prospectToSelect) => {
    if (overWrite) {
      overWritePayload[prospectToSelect.id] = prospectToSelect;
    } else {
      if (prospectToSelect.id in payload) {
        delete payload[prospectToSelect.id];
      } else {
        payload[prospectToSelect.id] = prospectToSelect;
      }
    }
  });
  if (overWrite) {
    dispatch({
      type: SELECT_PROSPECTS,
      payload: overWritePayload,
    });
  } else {
    dispatch({
      type: SELECT_PROSPECTS,
      payload,
    });
  }
};

export const TOGGLE_SORT_MODAL = "TOGGLE_SORT_MODAL";
export const toggleSortModal = () => ({
  type: TOGGLE_SORT_MODAL,
});

export const SET_SORT = "SET_SORT";
export const setSort = (payload) => ({
  type: SET_SORT,
  payload,
});

export const INIT_SORT = "INIT_SORT";
export const initSort = (payload = null) => ({
  type: INIT_SORT,
  payload,
});

export const FETCH_ADVANCED_FILTER = "FETCH_ADVANCED_FILTER";
export const FETCH_ADVANCED_FILTER_SUCCESS = "FETCH_ADVANCED_FILTER_SUCCESS";
export const FETCH_ADVANCED_FILTER_FAILURE = "FETCH_ADVANCED_FILTER_FAILURE";

export const fetchAdvancedFilter = (jobId, aT) => {
  return {
    [CALL_API]: {
      types: [
        FETCH_ADVANCED_FILTER,
        FETCH_ADVANCED_FILTER_SUCCESS,
        FETCH_ADVANCED_FILTER_FAILURE,
      ],
      endpoint: `/api/v2/auth/jobs/${jobId}/advFiltersData`,
      options: {
        method: "GET",
      },
      aT,
    },
  };
};

export const FETCH_ADVANCED_FILTER_SYNONYM = "FETCH_ADVANCED_FILTER_SYNONYM";
export const FETCH_ADVANCED_FILTER_SYNONYM_SUCCESS =
  "FETCH_ADVANCED_FILTER_SYNONYM_SUCCESS";
export const FETCH_ADVANCED_FILTER_SYNONYM_FAILURE =
  "FETCH_ADVANCED_FILTER_SYNONYM_FAILURE";

export const fetchAdvancedFilterSynonym = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_ADVANCED_FILTER_SYNONYM,
    });

    // console.log("===============FETCH_ADVANCED_FILTER_SYNONYM===================", getState().prospectsView.advFiltersData.data.skills)

    const [skills, educations, specialties, titles] = await Promise.all([
      fetch(process.env.REACT_APP_NER_FILTER_SYNONYMS_HOST_SKILLS_SYNONYMS,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            getState().prospectsView.advFiltersData.data.skills || []
          ),
        }
      ),
      fetch(process.env.REACT_APP_NER_FILTER_SYNONYMS_HOST_EDUCATION_SYNONYMS,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            getState().prospectsView.advFiltersData.data.educations || []
          ),
        }
      ),
      fetch(process.env.REACT_APP_NER_FILTER_SYNONYMS_HOST_SPECIALITY_SYNONYMS,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            getState().prospectsView.advFiltersData.data.specialties || []
          ),
        }
      ),
      fetch(process.env.REACT_APP_NER_FILTER_SYNONYMS_HOST_TITLE_SYNONYMS,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            getState().prospectsView.advFiltersData.data.titles || []
          ),
        }
      ),
    ]).then(async (res) => await Promise.all(res.map((r) => r.json())));

    const payload = {
      skills: skills || [],
      titles: titles || [],
      educations: educations || [],
      specialties: specialties || [],
    };

    // console.log("===============FETCH_ADVANCED_FILTER_SYNONYM_SUCCESS===================", payload)

    if (
      payload.skills.length > 0 ||
      payload.titles.length > 0 ||
      payload.educations.length > 0 ||
      payload.specialties.length > 0
    ) {
      dispatch({
        type: FETCH_ADVANCED_FILTER_SYNONYM_SUCCESS,
        payload,
      });
    } else {
      throw new Error("empty synonym return");
    }
  } catch (err) {
    console.log("ERROR:", err.message);
    dispatch({
      type: FETCH_ADVANCED_FILTER_SYNONYM_FAILURE,
    });
  }
};

export const APPLY_ADV_FILTERS_TEMPLATE = "APPLY_ADV_FILTERS_TEMPLATE";
export const applyADVFiltersTemplate = (template, setFilterCount) => {
  return {
    type: APPLY_ADV_FILTERS_TEMPLATE,
    payload: {
      template,
      setFilterCount,
    },
  };
};

export const TOGGLE_ADV_FILTERS = "TOGGLE_ADV_FILTERS";
export const toggleAdvFiters = () => ({
  type: TOGGLE_ADV_FILTERS,
});

export const SET_SKILLS_FILTERS = "SET_SKILLS_FILTERS";
export const setSkillsFilters = (skillsFilters) => {
  return {
    type: SET_SKILLS_FILTERS,
    payload: skillsFilters,
  };
};

export const SET_SPECIALTIES_FILTERS = "SET_SPECIALTIES_FILTERS";
export const setSpecialtiesFilters = (specialtiesFilters) => {
  return {
    type: SET_SPECIALTIES_FILTERS,
    payload: specialtiesFilters,
  };
};

export const TOGGLE_STRICT_SKILLS = "TOGGLE_STRICT_SKILLS";
export const toggleStrictSkills = () => {
  return {
    type: TOGGLE_STRICT_SKILLS,
  };
};

export const TOGGLE_STRICT_SPECIALTIES = "TOGGLE_STRICT_SPECIALTIES";
export const toggleStrictSpecialties = () => {
  return {
    type: TOGGLE_STRICT_SPECIALTIES,
  };
};

export const SET_STRICT_SKILLS = "SET_STRICT_SKILLS";
export const setStrictSkills = (flag) => {
  return {
    type: SET_STRICT_SKILLS,
    payload: flag,
  };
};

export const SET_STRICT_SPECIALTIES = "SET_STRICT_SPECIALTIES";
export const setStrictSpecialties = (flag) => {
  return {
    type: SET_STRICT_SPECIALTIES,
    payload: flag,
  };
};

export const SET_SCHOOL_FILTERS = "SET_SCHOOL_FILTERS";
export const setSchoolFilters = (schoolFilters) => {
  return {
    type: SET_SCHOOL_FILTERS,
    payload: schoolFilters,
  };
};

export const SET_EDUCATION_FILTERS = "SET_EDUCATION_FILTERS";
export const setEducationFilters = (filters) => {
  return {
    type: SET_EDUCATION_FILTERS,
    payload: filters,
  };
};

export const SET_ETHNICITY_FILTER = "SET_ETHNICITY_FILTER";
export const setEthnicityFilter = (filters) => {
  return {
    type: SET_ETHNICITY_FILTER,
    payload: filters,
  };
};

export const TOGGLE_STRICT_EDUCATION = "TOGGLE_STRICT_EDUCATION";
export const toggleStrictEducation = () => {
  return {
    type: TOGGLE_STRICT_EDUCATION,
  };
};

export const SET_CURRENT_TITLE_FILTERS = "SET_CURRENT_TITLE_FILTERS";
export const setCurrentTitleFilters = (currentTitleFilters) => {
  return {
    type: SET_CURRENT_TITLE_FILTERS,
    payload: currentTitleFilters,
  };
};
export const TOGGLE_STRICT_CURRENT_TITLE = "TOGGLE_STRICT_CURRENT_TITLE";
export const toggleStrictCurrentTitle = () => {
  return {
    type: TOGGLE_STRICT_CURRENT_TITLE,
  };
};

export const SET_PAST_TITLE_FILTERS = "SET_PAST_TITLE_FILTERS";
export const setPastTitleFilters = (pastTitleFilters) => {
  return {
    type: SET_PAST_TITLE_FILTERS,
    payload: pastTitleFilters,
  };
};
export const TOGGLE_STRICT_lOCATION = "TOGGLE_STRICT_lOCATION";
export const toggleStrictLocation = () => {
  return {
    type: TOGGLE_STRICT_lOCATION,
  };
};
export const TOGGLE_STRICT_PAST_lOCATION = "TOGGLE_STRICT_PAST_lOCATION";
export const toggleStrictPastLocation = () => {
  return {
    type: TOGGLE_STRICT_PAST_lOCATION,
  };
};
export const TOGGLE_STRICT_PAST_TITLE = "TOGGLE_STRICT_PAST_TITLE";
export const toggleStrictPastTitle = () => {
  return {
    type: TOGGLE_STRICT_PAST_TITLE,
  };
};

export const SET_CURRENT_COMPANY_FILTERS = "SET_CURRENT_COMPANY_FILTERS";
export const setCurrentCompanyFilters = (currentCompanyFilters) => {
  return {
    type: SET_CURRENT_COMPANY_FILTERS,
    payload: currentCompanyFilters,
  };
};

export const SET_PAST_COMPANY_FILTERS = "SET_PAST_COMPANY_FILTERS";
export const setPastCompanyFilters = (pastCompanyFilters) => {
  return {
    type: SET_PAST_COMPANY_FILTERS,
    payload: pastCompanyFilters,
  };
};

export const SET_CURRENT_INDUSTRY_FILTERS = "SET_CURRENT_INDUSTRY_FILTERS";
export const setCurrentIndustryFilters = (currentIndustryFilters) => {
  return {
    type: SET_CURRENT_INDUSTRY_FILTERS,
    payload: currentIndustryFilters,
  };
};

export const SET_PAST_INDUSTRY_FILTERS = "SET_PAST_INDUSTRY_FILTERS";
export const setPastIndustryFilters = (pastIndustryFilters) => {
  return {
    type: SET_PAST_INDUSTRY_FILTERS,
    payload: pastIndustryFilters,
  };
};

export const SET_LOCATION_FILTERS = "SET_LOCATION_FILTERS";
export const setLocationFilters = (locationFilters) => {
  return {
    type: SET_LOCATION_FILTERS,
    payload: locationFilters,
  };
};

export const SET_PAST_LOCATION_FILTERS = "SET_PAST_LOCATION_FILTERS";
export const setPastLocationFilters = (pastLocationFilters) => {
  return {
    type: SET_PAST_LOCATION_FILTERS,
    payload: pastLocationFilters,
  };
};

export const SET_VISA_FILTERS = "SET_VISA_FILTERS";
export const setVisaFilters = (visaFilters) => {
  return {
    type: SET_VISA_FILTERS,
    payload: visaFilters,
  };
};

export const SET_TOTAL_EXPERIENCE_FILTER = "SET_TOTAL_EXPERIENCE_FILTER";
export const setTotalExperienceFilter = (filter) => {
  return {
    type: SET_TOTAL_EXPERIENCE_FILTER,
    payload: filter,
  };
};

export const SET_RELEVANT_EXPERIENCE_FILTER = "SET_RELEVANT_EXPERIENCE_FILTER";
export const setRelevantExperienceFilter = (filter) => {
  return {
    type: SET_RELEVANT_EXPERIENCE_FILTER,
    payload: filter,
  };
};

export const TOGGLE_TOTAL_EXPERIENCE_FILTER = "TOGGLE_TOTAL_EXPERIENCE_FILTER";
export const toggleTotalExperienceFilter = () => {
  return {
    type: TOGGLE_TOTAL_EXPERIENCE_FILTER,
  };
};

export const TOGGLE_RELEVANT_EXPERIENCE_FILTER =
  "TOGGLE_RELEVANT_EXPERIENCE_FILTER";
export const toggleRelevantExperienceFilter = () => {
  return {
    type: TOGGLE_RELEVANT_EXPERIENCE_FILTER,
  };
};

export const SET_COMPANY_SIZE_FILTER = "SET_COMPANY_SIZE_FILTER";
export const setCompanySizeFilter = (filter) => {
  return {
    type: SET_COMPANY_SIZE_FILTER,
    payload: filter,
  };
};

export const TOGGLE_COMPANY_SIZE_FILTER = "TOGGLE_COMPANY_SIZE_FILTER";
export const toggleCompanySizeFilter = () => {
  return {
    type: TOGGLE_COMPANY_SIZE_FILTER,
  };
};

export const SET_TITLE_SCORE_FILTER = "SET_TITLE_SCORE_FILTER";
export const setTitleScoringFilter = (filter) => {
  return {
    type: SET_TITLE_SCORE_FILTER,
    payload: filter,
  };
};

export const TOGGLE_TITLE_SCORE_FILTER = "TOGGLE_TITLE_SCORE_FILTER";
export const toggleTitleScoringFilter = () => {
  return {
    type: TOGGLE_TITLE_SCORE_FILTER,
  };
};

export const SET_SKILL_SCORE_FILTER = "SET_SKILL_SCORE_FILTER";
export const setSkillScoringFilter = (filter) => {
  return {
    type: SET_SKILL_SCORE_FILTER,
    payload: filter,
  };
};

export const TOGGLE_SKILL_SCORE_FILTER = "TOGGLE_SKILL_SCORE_FILTER";
export const toggleSkillScoringFilter = () => {
  return {
    type: TOGGLE_SKILL_SCORE_FILTER,
  };
};

export const TOGGLE_SPECIALTY_SCORE_FILTER = "TOGGLE_SPECIALTY_SCORE_FILTER";
export const toggleSpecialtyScoringFilter = () => {
  return {
    type: TOGGLE_SPECIALTY_SCORE_FILTER,
  };
};

export const SET_EXPERIENCE_SCORE_FILTER = "SET_EXPERIENCE_SCORE_FILTER";
export const setExperienceScoringFilter = (filter) => {
  return {
    type: SET_EXPERIENCE_SCORE_FILTER,
    payload: filter,
  };
};

export const TOGGLE_EXPERIENCE_SCORE_FILTER = "TOGGLE_EXPERIENCE_SCORE_FILTER";
export const toggleExperienceScoringFilter = () => {
  return {
    type: TOGGLE_EXPERIENCE_SCORE_FILTER,
  };
};

export const SET_EDUCATION_SCORE_FILTER = "SET_EDUCATION_SCORE_FILTER";
export const setEducationScoringFilter = (filter) => {
  return {
    type: SET_EDUCATION_SCORE_FILTER,
    payload: filter,
  };
};

export const TOGGLE_EDUCATION_SCORE_FILTER = "TOGGLE_EDUCATION_SCORE_FILTER";
export const toggleEducationScoringFilter = () => {
  return {
    type: TOGGLE_EDUCATION_SCORE_FILTER,
  };
};

export const SET_INDUSTRY_SCORE_FILTER = "SET_INDUSTRY_SCORE_FILTER";
export const setIndustryScoringFilter = (filter) => {
  return {
    type: SET_INDUSTRY_SCORE_FILTER,
    payload: filter,
  };
};

export const SET_SPECIALTY_SCORE_FILTER = "SET_SPECIALTY_SCORE_FILTER";
export const setSpecialtyScoringFilter = (filter) => {
  return {
    type: SET_SPECIALTY_SCORE_FILTER,
    payload: filter,
  };
};

export const TOGGLE_INDUSTRY_SCORE_FILTER = "TOGGLE_INDUSTRY_SCORE_FILTER";
export const toggleIndustryScoringFilter = () => {
  return {
    type: TOGGLE_INDUSTRY_SCORE_FILTER,
  };
};

export const CLEAR_SCORING_FILTERS = "CLEAR_SCORING_FILTERS";
export const clearScoringFilters = () => {
  return {
    type: CLEAR_SCORING_FILTERS,
  };
};
export const CLEAR_ALL_ADVANCED_FILTERS = "CLEAR_ALL_ADVANCED_FILTERS";
export const clearAllAdvancedFilters = () => {
  return {
    type: CLEAR_ALL_ADVANCED_FILTERS,
  };
};

export const CLEAR_ADVANCED_FILTERS = "CLEAR_ADVANCED_FILTERS";
export const clearAdvancedFilters = () => ({ type: CLEAR_ADVANCED_FILTERS });

export const SET_TEMPLATE = "SET_TEMPLATE";
export const setTemplate = (name) => ({
  type: SET_TEMPLATE,
  payload: name,
});

export const SET_SKILLS_STATUS = "SET_SKILLS_STATUS";
export const setSkillsStatus = (status) => ({
  type: SET_SKILLS_STATUS,
  payload: status,
});

export const SET_SPECIALTIES_STATUS = "SET_SPECIALTIES_STATUS";
export const setSpecialtiesStatus = (status) => ({
  type: SET_SPECIALTIES_STATUS,
  payload: status,
});

export const SET_LOCATION_STATUS = "SET_LOCATION_STATUS";
export const setLocationStatus = (status) => ({
  type: SET_LOCATION_STATUS,
  payload: status,
});

export const SET_PAST_LOCATION_STATUS = "SET_PAST_LOCATION_STATUS";
export const setPastLocationStatus = (status) => ({
  type: SET_PAST_LOCATION_STATUS,
  payload: status,
});

export const SET_SPOKEN_LANGUAGES_FILTER = "SET_SPOKEN_LANGUAGES_FILTER";
export const setSpokenLanguagesFilter = (filter) => ({
  type: SET_SPOKEN_LANGUAGES_FILTER,
  payload: filter,
});

export const SET_FIRST_NAMES_FILTER = "SET_FIRST_NAMES_FILTER";
export const setFirstNamesFilter = (filter) => ({
  type: SET_FIRST_NAMES_FILTER,
  payload: filter,
});

export const SET_LAST_NAMES_FILTER = "SET_LAST_NAMES_FILTER";
export const setLastNamesFilter = (filter) => ({
  type: SET_LAST_NAMES_FILTER,
  payload: filter,
});

export const SET_YEAR_OF_GRADUATION_TOGGLE = "SET_YEAR_OF_GRADUATION_TOGGLE";
export const setYearOfGraduationToggle = () => ({
  type: SET_YEAR_OF_GRADUATION_TOGGLE,
});

export const SET_YEAR_OF_GRADUATION = "SET_YEAR_OF_GRADUATION";
export const setYearOfGraduationFilter = (filter) => ({
  type: SET_YEAR_OF_GRADUATION,
  payload: filter,
});

export const SET_YEAR_CURRENT_COMPANY_FILTER =
  "SET_YEAR_CURRENT_COMPANY_FILTER";
export const setYearCurrentCompanyFilter = (filter) => ({
  type: SET_YEAR_CURRENT_COMPANY_FILTER,
  payload: filter,
});

export const SET_YEAR_CURRENT_COMPANY_TOGGLE =
  "SET_YEAR_CURRENT_COMPANY_TOGGLE";
export const setYearCurrentCompanyToggle = () => ({
  type: SET_YEAR_CURRENT_COMPANY_TOGGLE,
});

export const SET_YEAR_CURRENT_POSITION_FILTER =
  "SET_YEAR_CURRENT_POSITION_FILTER";
export const setYearCurrentPositionFilter = (filter) => ({
  type: SET_YEAR_CURRENT_POSITION_FILTER,
  payload: filter,
});

export const SET_YEAR_CURRENT_POSITION_TOGGLE =
  "SET_YEAR_CURRENT_POSITION_TOGGLE";
export const setYearCurrentPositionToggle = () => ({
  type: SET_YEAR_CURRENT_POSITION_TOGGLE,
});

export const FETCH_COMMENTS = "FETCH_COMMENTS";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";

export const getCommentsApiCall = async (jobProfileId, tribeId) => {
  try {
    console.log("==============getCommentsApiCall=================", {
      jobProfileId,
      tribeId,
    });
    const qStr = `?jobProfileId=${jobProfileId}&tribeId=${tribeId}`;
    const comments = await manualApiCall(`/api/v2/auth/collab${qStr}`, {
      method: "GET",
    });
    if (comments.success) {
      return comments;
    } else {
      throw new Error("Comments Api Failed!");
    }
  } catch (e) {
    throw new Error(e);
  }
};

export function getUniqueEmojiCounts(emojies) {
  let returnData = [];
  let newEmojies = [...emojies];
  newEmojies?.map((emoji) => {
    if (returnData.length < 1)
      returnData.push({
        emojiText: emoji.emojiText,
        originals: [emoji],
        count: 1,
      });
    else {
      let index = returnData.findIndex(
        (item) => item.emojiText === emoji.emojiText
      );
      if (index > -1) {
        returnData[index].count += 1;
        returnData[index].originals.push(emoji);
      } else
        returnData.push({
          emojiText: emoji.emojiText,
          originals: [emoji],
          count: 1,
        });
    }
  });

  return returnData;
}

export const getComments = (jobProfileId, tribeId) => async (
  dispatch,
  getState
) => {
  try {
    console.log("==============getComments=================", dispatch);
    dispatch({ type: FETCH_COMMENTS });
    let comments = await getCommentsApiCall(jobProfileId, tribeId);

    let v2MappedComments = [];

    comments.comments.sort(function (a, b) {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });

    comments.comments.map((comment) => {
      let name = comment?.createdBy?.name;
      let initials;
      if (name) {
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

        initials = [...name.matchAll(rgx)] || [];

        initials = (
          (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
        ).toUpperCase();
      } else {
        name = comment?.createdBy?.email;
        initials = name.charAt(0).toUpperCase();
      }
      let messageText = comment?.note?.elements?.map((element) => {
        if (element.type === "user") {
          return `<strong>${element?.display}</strong>`;
        } else {
          return `${element.value}`;
        }
      });

      messageText = messageText?.join(" ");
      let replyUser = "";
      let replyText = "";
      let replyUserColor = "";
      if (comment?.parentId) {
        let parentComment = comments.comments.find(
          (parent) => parent.id === comment.parentId
        );

        if (parentComment?.id) {
          replyUser = parentComment?.createdBy?.name;
          replyText = parentComment?.note?.elements?.map((element) => {
            if (element.type === "user") {
              return `<strong>${element?.display}</strong>`;
            } else {
              return `${element.value}`;
            }
          });
          replyText = replyText?.join(" ");
          replyUserColor = parentComment?.createdBy?.color;
        }
      }

      // let emojies = getUniqueEmojiCounts(comment.emojies)

      v2MappedComments.push({
        id: comment?.id,
        parentId: comment?.parentId,
        senderImage: initials,
        senderName: name,
        time: moment(comment?.createdAt).fromNow(true),
        senderColor: comment?.createdBy?.color,
        senderBackgroundColor: "#29382E",
        messageText,
        rawEmojiData: comment?.emojies,
        emojies: comment?.emojies,
        hasReply: comment?.parentId ? true : false,
        replyUser,
        replyText,
        replyUserColor,
        isUser: comment?.createdBy?.isOwner,
        jobProfileId: comment?.jobProfileId,
      });
    });

    console.log("==============COMMENTS=================", v2MappedComments);
    dispatch({
      type: FETCH_COMMENTS_SUCCESS,
      payload: v2MappedComments,
      tribeMembers: comments.tribeMembers,
    });
  } catch (e) {
    dispatch({
      type: FETCH_COMMENTS_FAILURE,
      error: e.message,
    });
  }
};

export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAILURE = "ADD_COMMENT_FAILURE";

export const addCommentApiCall = async (data) => {
  try {
    console.log("==============addCommentApiCall=================", { data });
    const comment = await manualApiCall(`/api/v2/auth/collab/add`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
    });
    if (comment.success) {
      return comment;
    } else {
      throw new Error("Comment Add Api Failed!");
    }
  } catch (e) {
    throw new Error(e);
  }
};

function commentsParser(comment) {
  let name = comment?.createdBy?.name;
  let initials;
  if (name) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
    initials = [...name.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();
  } else {
    name = comment?.createdBy?.email;
    initials = name.charAt(0).toUpperCase();
  }

  let messageText = comment?.note?.elements?.map((element) => {
    if (element.type === "user") {
      return `<strong>${element?.display}</strong> `;
    } else {
      return `${element.value}`;
    }
  });

  messageText = messageText?.join(" ");

  // let emojies = getUniqueEmojiCounts(comment?.emojies)

  let v2CommentMapped = {
    id: comment?.id,
    parentId: comment?.parent_id,
    senderImage: initials,
    senderName: name,
    time: moment(comment?.createdAt).fromNow(true),
    senderColor: comment?.createdBy?.color,
    senderBackgroundColor: "#29382E",
    messageText,
    rawEmojiData: comment?.emojies,
    emojies: comment?.emojies,
    hasReply: comment?.parent_id ? true : false,
    replyUser: "",
    replyText: "",
    replyUserColor: "",
    isUser: comment?.createdBy?.isOwner,
    jobProfileId: comment?.jobProfileId,
  };

  return v2CommentMapped;
}

export const addComment = (data) => async (dispatch, getState) => {
  try {
    console.log("==============addComment=================", dispatch);
    dispatch({ type: ADD_COMMENT });
    const res = await addCommentApiCall(data);
    const comment = commentsParser(res.data.comment);

    dispatch({ type: ADD_COMMENT_SUCCESS, payload: { comment } });
  } catch (e) {
    dispatch({
      type: ADD_COMMENT_FAILURE,
      error: e.message,
    });
  }
};

export const appendComment = (data) => async (dispatch, getState) => {
  const comment = commentsParser(data.comment);

  console.log("==============COMMENTS=================", comment);
  dispatch({ type: ADD_COMMENT_SUCCESS, payload: comment });
};

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

export const deleteComment = (data) => async (dispatch, getState) => {
  try {
    console.log("==============deleteComment=================", dispatch);
    dispatch({ type: DELETE_COMMENT });
    const res = await manualApiCall(`/api/v2/auth/collab/delete`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
    });
    // dispatch(getComments(data?.jobProfileId))
    dispatch({ type: DELETE_COMMENT_SUCCESS, payload: data.id });
  } catch (e) {
    dispatch({
      type: DELETE_COMMENT_FAILURE,
      error: e.message,
    });
  }
};

export const deleteCommentSocket = (data) => async (dispatch, getState) => {
  try {
    console.log(
      "==============deleteComment SOCKETR=================",
      dispatch
    );
    dispatch({ type: DELETE_COMMENT });
    dispatch({ type: DELETE_COMMENT_SUCCESS, payload: data.id });
    // dispatch(getComments(data?.jobProfileId))
  } catch (e) {
    dispatch({
      type: DELETE_COMMENT_FAILURE,
      error: e.message,
    });
  }
};

export const SHOW_EMOJI_COMMENT = "SHOW_EMOJI_COMMENT";
export const SHOW_EMOJI_PROSPECT = "SHOW_EMOJI_PROSPECT";

export const showEmojiPickerOnComment = (id) => async (dispatch, getState) => {
  dispatch({ type: SHOW_EMOJI_COMMENT, payload: { id } });
};

export const showEmojiPickerOnProspect = (id) => async (dispatch, getState) => {
  dispatch({ type: SHOW_EMOJI_PROSPECT, payload: { id } });
};

export const SAVE_NEW_EMOJI = "SAVE_NEW_EMOJI";
export const SAVE_NEW_EMOJI_SUCCESS = "SAVE_NEW_EMOJI_SUCCESS";
export const SAVE_NEW_EMOJI_FAILURE = "SAVE_NEW_EMOJI_FAILURE";

export const saveEmoji = (data) => async (dispatch, getState) => {
  try {
    console.log("==============addEmoji=================", dispatch);
    dispatch({ type: SAVE_NEW_EMOJI });
    const res = await manualApiCall(`/api/v2/auth/emoji/add`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
    });
    if (res.success) {
      dispatch({ type: APPEND_NEW_EMOJI_SUCCESS, payload: res.data });
    } else {
      dispatch({
        type: SAVE_NEW_EMOJI_FAILURE,
        error: res,
      });
    }
  } catch (e) {
    dispatch({
      type: SAVE_NEW_EMOJI_FAILURE,
      error: e.message,
    });
  }
};

export const APPEND_NEW_EMOJI_SUCCESS = "APPEND_NEW_EMOJI_SUCCESS";

export const appendEmoji = (data) => async (dispatch, getState) => {
  try {
    console.log("==============appendEmoji=================", dispatch);

    dispatch({ type: APPEND_NEW_EMOJI_SUCCESS, payload: data });
  } catch (e) {
    dispatch({
      type: SAVE_NEW_EMOJI_FAILURE,
      error: e.message,
    });
  }
};

export const DELETE_EMOJI_SUCCESS = "DELETE_EMOJI_SUCCESS";
export const DELETE_EMOJI_FAILURE = "DELETE_EMOJI_DELETE";

export const deleteEmoji = (data) => async (dispatch, getState) => {
  try {
    console.log("==============deleteEmoji=================", dispatch);

    if (data?.type == "socket") {
      dispatch({ type: DELETE_EMOJI_SUCCESS, payload: data?.emoji });
    } else {
      const res = await manualApiCall(`/api/v2/auth/emoji/delete`, {
        method: "POST",
        body: JSON.stringify({
          ...data,
        }),
      });

      if (res.success) {
        dispatch({ type: DELETE_EMOJI_SUCCESS, payload: res.data });
      } else {
        dispatch({
          type: DELETE_EMOJI_FAILURE,
          error: res,
        });
      }
    }
  } catch (e) {
    dispatch({
      type: DELETE_EMOJI_FAILURE,
      error: e.message,
    });
  }
};

export const DELETE_COMMENT_PROSPECT_SUCCESS =
  "DELETE_COMMENT_PROSPECT_SUCCESS";
export const APPEND_PROSPECT_COMMENT_SUCCESS =
  "APPEND_PROSPECT_COMMENT_SUCCESS";
export const APPEND_PROSPECT_EMOJI_SUCCESS = "APPEND_PROSPECT_EMOJI_SUCCESS";
export const DELETE_PROSPECT_EMOJI_SUCCESS = "DELETE_PROSPECT_EMOJI_SUCCESS";

export const deleteCommentProspectSocket = (data) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============deleteCommentProspectSocket=================",
      dispatch
    );
    dispatch({ type: DELETE_COMMENT_PROSPECT_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
  }
};
export const appendProspectComment = (data) => async (dispatch, getState) => {
  try {
    console.log(
      "==============appendProspectComment=================",
      dispatch
    );
    dispatch({ type: APPEND_PROSPECT_COMMENT_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
  }
};

export const appendProspectEmoji = (data) => async (dispatch, getState) => {
  try {
    console.log("==============appendProspectEmoji=================", dispatch);
    dispatch({ type: APPEND_NEW_EMOJI_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
  }
};

export const deleteProspectEmoji = (data) => async (dispatch, getState) => {
  try {
    console.log("==============deleteProspectEmoji=================", dispatch);
    dispatch({ type: DELETE_EMOJI_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
  }
};

export const CONNECT_SOCKET = "CONNECT_SOCKET";

export const connectSocket = (data) => async (dispatch, getState) => {
  try {
    console.log("==============connectSocket=================", dispatch);
    dispatch({ type: CONNECT_SOCKET, payload: { id: data } });
  } catch (e) {
    console.log(e);
  }
};

export const SET_SELECTED_PROSPECT_COMMENTS = "SET_SELECTED_PROSPECT_COMMENTS";

export const setSelectedProspectComments = (data) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============setSelectedProspectComments=================",
      dispatch
    );
    dispatch({ type: SET_SELECTED_PROSPECT_COMMENTS, payload: data });
  } catch (e) {
    console.log(e);
  }
};

export const SET_ACTIVE_PROSPECT_VARIANT = "SET_ACTIVE_PROSPECT_VARIANT";

export const setActiveProspectVariant = (data) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============setActiveProspectVariant=================",
      dispatch
    );
    dispatch({ type: SET_ACTIVE_PROSPECT_VARIANT, payload: { data } });
  } catch (e) {
    console.log(e);
  }
};

export const SET_ACTIVE_PROSPECT_ID_VARIANT_SCROLL =
  "SET_ACTIVE_PROSPECT_ID_VARIANT_SCROLL";

export const setActiveProspectIDVariant = (jobProfileId) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============setActiveProspectIDVariant=================",
      dispatch
    );
    dispatch({
      type: SET_ACTIVE_PROSPECT_ID_VARIANT_SCROLL,
      payload: { jobProfileId },
    });
  } catch (e) {
    console.log(e);
  }
};

export const SET_SUB_DROPDOWN_VIEW = "SET_SUB_DROPDOWN_VIEW";
export const setSubDropdownView = (data) => async (dispatch, getState) => {
  try {
    console.log("==============setSubDropdownView=================", dispatch);
    dispatch({ type: SET_SUB_DROPDOWN_VIEW, payload: data });
  } catch (e) {
    console.log(e);
  }
};

export const SET_BOOLEAN_FILTERS = "SET_BOOLEAN_FILTERS";
export const SET_RAW_TO_BOOLEAN_FILTERS = "SET_RAW_TO_BOOLEAN_FILTERS";
export const setBooleanFilters = (filters) => async (dispatch, getState) => {
  try {
    console.log("==============setBooleanFilters=================", dispatch);
    dispatch({ type: SET_BOOLEAN_FILTERS, payload: filters });

    setTimeout(() => {
      dispatch({ type: SET_RAW_TO_BOOLEAN_FILTERS });
    }, 1000);
  } catch (e) {
    console.log(e);
  }
};

export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
export const setPageNumber = (pageNumber) => async (dispatch, getState) => {
  try {
    console.log("==============setPageNumber=================", dispatch);
    dispatch({ type: SET_PAGE_NUMBER, payload: pageNumber });
  } catch (e) {
    console.log(e);
  }
};

export const REMOVE_FILTER = "REMOVE_FILTER";
export const removeBooleanFilters = (
  outerFilterIndex = null,
  innerFilterIndex = null
) => async (dispatch, getState) => {
  try {
    console.log("==============setBooleanFilters=================", dispatch);
    dispatch({
      type: REMOVE_FILTER,
      payload: { outerFilterIndex, innerFilterIndex },
    });
  } catch (e) {
    console.log(e);
  }
};

export const SET_CURRENT_BUCKET = "SET_CURRENT_BUCKET";
export const setCurrentBucket = (bucket) => async (dispatch, getState) => {
  if (bucket) {
    try {
      console.log("==============setCurrentBucket=================", dispatch);
      dispatch({ type: SET_CURRENT_BUCKET, payload: bucket });
    } catch (e) {
      console.log(e);
    }
  } else {
    console.error("ERROR OCCURRED: BUCKET is undefined");
  }
};

export const SET_TRIBES_UPDATE = "SET_TRIBES_UPDATE";
export const REVERT_TRIBES_UPDATE = "REVERT_TRIBES_UPDATE";

export const setTribesUpdate = (
  tribeMembers,
  tribe,
  permissionId = null,
  revertTribeMembers,
  type = "update",
  jId
) => async (dispatch, getState) => {
  try {
    console.log(
      "==============setTribesUpdate=================",
      dispatch,
      tribe,
      jId
    );

    dispatch({ type: SET_TRIBES_UPDATE, payload: tribeMembers });
    if (type == "update") {
      const res = await manualApiCall(`/api/v2/auth/tribes/edit`, {
        method: "POST",
        body: JSON.stringify({
          tribe: {
            id: tribe.tribeMemberId,
            permissionId: permissionId,
            jobId: jId,
          },
        }),
      });

      if (res.success == false || res.edited == false) {
        dispatch({ type: REVERT_TRIBES_UPDATE, payload: revertTribeMembers });
      }
    } else if (type == "delete") {
      const res = await manualApiCall(`/api/v2/auth/tribes/delete`, {
        method: "POST",
        body: JSON.stringify({
          tribe: {
            id: tribe.tribeMemberId,
            jobId: jId,
          },
        }),
      });

      if (res.success == false || res.deleted == false) {
        dispatch({ type: REVERT_TRIBES_UPDATE, payload: revertTribeMembers });
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const CREATE_TRIBE_MEMBER = "CREATE_TRIBE_MEMBER";

export const createTribeMember = (email, tribeId) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============createTribeMember=================",
      dispatch,
      email
    );
    const jobId = getState().prospectsView.candidates.job.id;
    dispatch({
      type: CREATE_TRIBE_MEMBER,
    });

    const res = await manualApiCall(`/api/v2/auth/tribes/add`, {
      method: "POST",
      body: JSON.stringify({
        email,
        tribeId,
        jobId,
      }),
    });

    if (res.success && res.added) {
      dispatch({
        type: FETCH_TRIBE_MEMBERS_SUCCESS,
        payload: res,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const GET_MEMBERS_LIST = "GET_MEMBERS_LIST";

export const getMemberList = () => async (dispatch, getState) => {
  try {
    console.log("==============getMemberList=================", dispatch);

    const res = await manualApiCall(`/api/v2/auth/team/list`, {
      method: "GET",
    });

    if (res.isSuccess) {
      dispatch({
        type: GET_MEMBERS_LIST,
        payload: res.data,
      });
    }
  } catch (e) {
    console.log(e);
  }
};
export const FETCH_EMAILS = "FETCH_EMAILS";
export const FETCH_EMAILS_SUCCESS = "FETCH_EMAILS_SUCCESS";
export const FETCH_EMAILS_FAILURE = "FETCH_EMAILS_FAILURE";
export const fetchSelectedEmails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_EMAILS,
    });
    const {
      prospects,
      allProspects,
      selectedProspects,
    } = getState().prospectsView.candidates;

    // const prospectsList = selectedProspects?.length > 50 ? allProspects : prospects
    const prospectsList = allProspects;

    // console.log("=========selectedProspects=========", selectedProspects)
    const ids = Object.keys(selectedProspects).filter((key) => {
      return !(
        "work_email" in selectedProspects[key] ||
        "personal_email" in selectedProspects[key] ||
        "work_email" in selectedProspects[key]
      );
    });

    // console.log("=========ids=========", ids)

    if (ids.length == 0) throw new Error("Emails already fetched");

    const { success, data } = await manualApiCall(
      "/api/v2/auth/candidates/emails",
      {
        method: "POST",
        body: JSON.stringify({
          ids: ids.map((id) => ({
            id,
            firstName: selectedProspects[id]?.firstName,
            lastName: selectedProspects[id]?.lastName,
            fullName: selectedProspects[id]?.fullName,
            companyName: selectedProspects[id]?.experience?.[0]?.company_name,
            companyDomain:
              selectedProspects[id]?.experience?.[0]?.companyWebsite,
          })),
        }),
      }
    );

    if (!success) throw new Error("Fetch Email Api Failed");
    if (data.length == 0) throw new Error("Fetch Email Api Returned Empty");
    // console.log("==================data======================", data)
    const prospectEmails = prospectsList.map((profile) => {
      // console.log("==================data.filter(({id})=>profile.id===id)=====================", data.filter(({id})=>profile.id===id))
      return {
        ...profile,
        ...(data.filter(({ id }) => profile.id == id)?.[0] || {}),
      };
    });

    const selectedProspectsEmails = prospectEmails.reduce((total, next) => {
      if (next.id in selectedProspects) {
        return {
          ...total,
          [next.id]: next,
        };
      }
      return total;
    }, {});
    if (selectedProspectsEmails.length == 0)
      throw new Error("Fetch Email Array is Empty");

    return dispatch({
      type: FETCH_EMAILS_SUCCESS,
      payload: {
        prospectEmails,
        selectedProspectsEmails,
      },
    });
  } catch (err) {
    console.log("ERROR: ", err.message);
    dispatch({
      type: FETCH_EMAILS_FAILURE,
    });
  }
};

export const INIT_RECOMMENDED_COUNT = "INIT_RECOMMENDED_COUNT";
export const initRecommendedCount = () => async (dispatch, getState) => {
  try {
    console.log(
      "==============initRecommendedCount=================",
      dispatch
    );
    dispatch({ type: INIT_RECOMMENDED_COUNT });
  } catch (e) {
    console.log(e);
  }
};

export const ADD_RECOMMENDED_COUNT = "ADD_RECOMMENDED_COUNT";
export const addRecommendedCount = () => async (dispatch, getState) => {
  try {
    console.log("==============addRecommendedCount=================", dispatch);
    dispatch({ type: ADD_RECOMMENDED_COUNT });
  } catch (e) {
    console.log(e);
  }
};

export const OPEN_COMMENTS_AND_NAVIGATE = "OPEN_COMMENTS_AND_NAVIGATE";
export const openCommentsAndNavigate = (prospectID, commentID) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============openCommentsAndNavigate=================",
      dispatch
    );
    dispatch({
      type: OPEN_COMMENTS_AND_NAVIGATE,
      payload: { prospectID, commentID },
    });
  } catch (e) {
    console.log(e);
  }
};

export const ADD_LABEL_SUCCESS = "ADD_LABEL_SUCCESS";
export const ADD_LABEL_LOADING = "ADD_LABEL_LOADING";

export const addLabel = (label) => async (dispatch, getState) => {
  try {
    console.log("==============addLabel=================", dispatch, label);
    dispatch({
      type: ADD_LABEL_LOADING,
    });
    const jobId = getState().prospectsView.candidates.job.id;

    const res = await manualApiCall(`/api/auth/job/addLabel`, {
      method: "POST",
      body: JSON.stringify({
        label,
        jobId,
      }),
    });

    if (res.job) {
      dispatch({
        type: ADD_LABEL_SUCCESS,
        payload: res.job[0][0],
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const REMOVE_JOB = "REMOVE_JOB";
export const deleteJob = (push) => async (dispatch, getState) => {
  try {
    console.log("==============deleteJob=================", dispatch);
    const jobId = getState().prospectsView.candidates.job.id;

    const res = await manualApiCall(`/api/auth/job/delete`, {
      method: "POST",
      body: JSON.stringify({
        jobID: jobId,
      }),
    });

    if (res.isSuccess) {
      dispatch({
        type: REMOVE_JOB,
        payload: res,
      });

      push("/");
    }
  } catch (e) {
    console.log(e);
  }
};

export const LIKED_PROFILE = "LIKED_PROFILE";
export const LIKED_PROFILE_ADDING_PROSPECTS = "LIKED_PROFILE_ADDING_PROSPECTS";

export const likedProfile = (
  { index, status, jobProfileId },
  addingProspects = false,
  jId = null,
  currentType = null
) => async (dispatch, getState) => {
  try {
    console.log("==============likedProfile=================", dispatch);
    console.log(">>>jid", jId);
    if (addingProspects) {
      const res = await manualApiCall(`/api/v2/auth/recommendations/status`, {
        method: "POST",
        body: JSON.stringify({
          jobId: jId,
          jobProfileId: jobProfileId,
          type: status,
        }),
      });
      let newData = {
        index,
        status,
      };
      dispatch({
        type: LIKED_PROFILE_ADDING_PROSPECTS,
        payload: newData,
      });

      if (currentType !== null) {
        dispatch(getRecommendationCandidate(jId));
      }
    } else {
      const jobId = getState().prospectsView.candidates.job.id;

      const res = await manualApiCall(`/api/v2/auth/recommendations/status`, {
        method: "POST",
        body: JSON.stringify({
          jobId: jobId,
          jobProfileId: jobProfileId,
          type: status,
        }),
      });
      let newData = {
        index,
        status,
      };
      dispatch({
        type: LIKED_PROFILE,
        payload: newData,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const GET_RECOMMENDATION_CANDIDATE = "GET_RECOMMENDATION_CANDIDATE";
export const getRecommendationCandidate = (jobId, limit = 1) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============getRecommendationCandidate=================",
      dispatch
    );
    const type = getState().prospectsView.candidates.recommendationType;
    const allProspects = getState().prospectsView.candidates.allProspects;

    const res = await manualApiCall(
      `/api/v2/auth/candidates/recommending?jId=${jobId}&t=${type}&l=${limit}`,
      {
        method: "GET",
      }
    );

    if (
      res.success &&
      !allProspects.some(
        (elem) =>
          elem.publicIdentifier?.trim()?.toLowerCase() ==
          res.data?.[0]?.publicIdentifier?.trim()?.toLowerCase()
      )
    ) {
      console.log("This is true");
      dispatch({
        type: GET_RECOMMENDATION_CANDIDATE,
        payload: res,
      });
    }
  } catch (e) {
    console.log(e);
  }
};
export const GET_RECOMMENDATION_PROBABILITY = "GET_RECOMMENDATION_PROBABILITY";
export const getRecommendationProbability = (jobId) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============getRecommendationProbabilities=================",
      dispatch
    );
    // const type = getState().prospectsView.candidates.recommendationType;

    const res = await manualApiCall(
      `/api/v2/auth/recommendations/probabilities`,
      {
        method: "POST",
        body: JSON.stringify({
          jobId: jobId,
        }),
      }
    );

    // if (res.success) {
    //   dispatch({
    //     type: GET_RECOMMENDATION_CANDIDATE,
    //     payload: res,
    //   });
    // }
  } catch (e) {
    console.log(e);
  }
};

export const CLEAR_RECOMMENDED_CANDIDATES = "CLEAR_RECOMMENDED_CANDIDATES";
export const clearRecommendedCandidates = () => async (dispatch, getState) => {
  try {
    console.log(
      "==============clearRecommendedCandidates=================",

      dispatch
    );
    dispatch({
      type: CLEAR_RECOMMENDED_CANDIDATES,
    });
  } catch (e) {
    console.log(e);
  }
};

export const FAVOURITE_RELEVANT_EXPERIENCE = "FAVOURITE_RELEVANT_EXPERIENCE";
export const _favouriteRelevantExperience = (data) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============favouriteRelevantExperience=================",
      dispatch
    );
    dispatch({
      type: FAVOURITE_RELEVANT_EXPERIENCE,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const FAVOURITE_EXPERIENCES = "FAVOURITE_EXPERIENCES";
export const _favouriteExperiences = (data) => async (dispatch, getState) => {
  try {
    console.log(
      "==============favouriteExperiences=================",
      dispatch
    );
    dispatch({
      type: FAVOURITE_EXPERIENCES,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const FAVOURITE_FUNCTIONAL_EXPERIENCE =
  "FAVOURITE_FUNCTIONAL_EXPERIENCE";
export const _favouriteFunctionalExperience = (data) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============favouriteFunctionalExperience=================",
      dispatch
    );
    dispatch({
      type: FAVOURITE_FUNCTIONAL_EXPERIENCE,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const FAVOURITE_TOTAL_EXPERIENCE = "FAVOURITE_TOTAL_EXPERIENCE";
export const _favouriteTotalExperience = (data) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============favouriteTotalExperience=================",
      dispatch
    );
    dispatch({
      type: FAVOURITE_TOTAL_EXPERIENCE,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const FAVOURITE_SPECIALITIES = "FAVOURITE_SPECIALITIES";
export const _favouriteSpecialities = (data) => async (dispatch, getState) => {
  try {
    console.log(
      "==============favouriteSpecialities=================",
      dispatch
    );
    dispatch({
      type: FAVOURITE_SPECIALITIES,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const FAVOURITE_HIGH_IMPORTANT_SKILLS =
  "FAVOURITE_HIGH_IMPORTANT_SKILLS";
export const _favouriteHighImportantSkills = (data) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============favouriteHighImportantSkills=================",
      dispatch
    );
    dispatch({
      type: FAVOURITE_HIGH_IMPORTANT_SKILLS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const FAVOURITE_MEDIUM_IMPORTANT_SKILLS =
  "FAVOURITE_MEDIUM_IMPORTANT_SKILLS";
export const _favouriteMediumImportantSkills = (data) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============favouriteMediumImportantSkills=================",
      dispatch
    );
    dispatch({
      type: FAVOURITE_MEDIUM_IMPORTANT_SKILLS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const FAVOURITE_LOW_IMPORTANT_SKILLS = "FAVOURITE_LOW_IMPORTANT_SKILLS";
export const _favouriteLowImportantSkills = (data) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============favouriteLowImportantSkills=================",
      dispatch
    );
    dispatch({
      type: FAVOURITE_LOW_IMPORTANT_SKILLS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const FAVOURITE_EDUCATIONS = "FAVOURITE_EDUCATIONS";
export const _favouriteEducations = (data) => async (dispatch, getState) => {
  try {
    console.log("==============favouriteEducations=================", dispatch);
    dispatch({
      type: FAVOURITE_EDUCATIONS,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const FAVOURITE_CLEAR = "FAVOURITE_CLEAR";
export const _favouriteClear = () => async (dispatch, getState) => {
  try {
    console.log("==============favouriteClear=================", dispatch);
    dispatch({
      type: FAVOURITE_CLEAR,
    });
  } catch (e) {
    console.log(e);
  }
};
export const FAVOURITE_SEND_ALL = "FAVOURITE_SEND_ALL";

export const favouriteSendAll = (jobProfileId) => async (
  dispatch,
  getState
) => {
  try {
    console.log("==============likedProfile=================", dispatch);
    const jobId = getState().prospectsView.candidates.job.id;
    // index = prospectIndex

    const recommendedObject = {
      favouriteRelevantExperience: getState().prospectsView.recommendations
        .favouriteRelevantExperience,
      favouriteExperiences: getState().prospectsView.recommendations
        .favouriteExperiences,
      favouriteFunctionalExperience: getState().prospectsView.recommendations
        .favouriteFunctionalExperience,
      favouriteTotalExperience: getState().prospectsView.recommendations
        .favouriteTotalExperience,
      favouriteSpecialities: getState().prospectsView.recommendations
        .favouriteSpecialities,
      favouriteHighImportantSkills: getState().prospectsView.recommendations
        .favouriteHighImportantSkills,
      favouriteMediumImportantSkills: getState().prospectsView.recommendations
        .favouriteMediumImportantSkills,
      favouriteLowImportantSkills: getState().prospectsView.recommendations
        .favouriteLowImportantSkills,
      favouriteEducations: getState().prospectsView.recommendations
        .favouriteEducations,
    };

    dispatch({
      type: PROSPECT_RECOMMENDED_OBJECT,
      payload: { recommendationObject: recommendedObject, jobProfileId },
    });
    const res = await manualApiCall(`/api/v2/auth/recommendations/object`, {
      method: "POST",
      body: JSON.stringify({
        jobId: jobId,
        jobProfileId: jobProfileId,
        recommendedObject: recommendedObject,
      }),
    });
    if (res.status === 200) {
      dispatch({
        type: FAVOURITE_SEND_ALL,
        payload: res.data,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const FAVOURITE_RECEIVE_ALL = "FAVOURITE_RECEIVE_ALL";
export const PROSPECT_RECOMMENDED_OBJECT = "PROSPECT_RECOMMENDED_OBJECT";
export const _favouriteReceiveAll = (recommendationObject) => async (
  dispatch,
  getState
) => {
  try {
    console.log("==============favouriteReceiveAll=================", dispatch);
    dispatch({
      type: FAVOURITE_RECEIVE_ALL,
      payload: recommendationObject,
    });
  } catch (e) {
    console.log(e);
  }
};

export const ON_HOVER_CHANGE_PROSPECT_ELEMENT =
  "ON_HOVER_CHANGE_PROSPECT_ELEMENT";
export const onHoverChangeProspectElement = (
  newProspectElement,
  itemIndex,
  type,
  experienceIndex
) => async (dispatch, getState) => {
  try {
    console.log(
      "==============onHoverChangeProspectElement=================",
      dispatch
    );
    dispatch({
      type: ON_HOVER_CHANGE_PROSPECT_ELEMENT,
      payload: {
        newProspectElement,
        currentHoverElement: `${itemIndex}-${type}-${experienceIndex}`,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

/// Find More Prospects
export const SET_BATCH_SIZE = "SET_BATCH_SIZE";
export const setBatchSize = (batchSize) => async (dispatch, getState) => {
  try {
    console.log("==============setBatchSize=================", dispatch);
    dispatch({
      type: SET_BATCH_SIZE,
      payload: batchSize,
    });
  } catch (e) {
    console.log(e);
  }
};

export const SET_PRIORITY_ARRAY = "SET_PRIORITY_ARRAY";
export const setPriorityArray = (data) => async (dispatch, getState) => {
  try {
    console.log("==============setPriorityArray=================", dispatch);
    dispatch({
      type: SET_PRIORITY_ARRAY,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};
export const SET_PRIORITIZE_ARRAY = "SET_PRIORITIZE_ARRAY";
export const _setPrioritizeArray = (data) => async (dispatch, getState) => {
  try {
    console.log("==============setPrioritizeArray=================", dispatch);
    dispatch({
      type: SET_PRIORITIZE_ARRAY,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const START_SEARCH = "START_SEARCH";
export const _startSearch = () => async (dispatch, getState) => {
  try {
    console.log("==============startSearch=================", dispatch);
    dispatch({
      type: START_SEARCH,
    });
  } catch (e) {
    console.log(e);
  }
};

export const SEARCH_PROSPECTS_DATA = "SEARCH_PROSPECTS_DATA";
export const _searchProspectsData = (data) => async (dispatch, getState) => {
  try {
    console.log("==============searchProspectsData=================", dispatch);
    dispatch({
      type: SEARCH_PROSPECTS_DATA,
      payload: data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const SET_MORE_PROSPECT_CURRENT_TAB = "SET_MORE_PROSPECT_CURRENT_TAB";
export const setMoreProspectCurrentTab = (currentTab) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============setMoreProspectCurrentTab=================",
      dispatch
    );
    dispatch({
      type: SET_MORE_PROSPECT_CURRENT_TAB,
      payload: currentTab,
    });
  } catch (e) {
    console.log(e);
  }
};

export const SET_MORE_PROSPECT_SKILLS_ARRAY = "SET_MORE_PROSPECT_SKILLS_ARRAY";
export const setMoreProspectSkillsArray = (skillsArray) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============setMoreProspectSkillsArray=================",
      dispatch
    );
    dispatch({
      type: SET_MORE_PROSPECT_SKILLS_ARRAY,
      payload: skillsArray,
    });
  } catch (e) {
    console.log(e);
  }
};
export const SET_MORE_PROSPECT_SPECIALITIES_ARRAY =
  "SET_MORE_PROSPECT_SPECIALITIES_ARRAY";
export const setMoreProspectSpecialitiesArray = (SpecialitiesArray) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============setMoreProspectSpecialitiesArray=================",
      dispatch
    );
    dispatch({
      type: SET_MORE_PROSPECT_SPECIALITIES_ARRAY,
      payload: SpecialitiesArray,
    });
  } catch (e) {
    console.log(e);
  }
};
export const DELETE_PROSPECT = "DELETE_PROSPECT";
export const SET_LOADING = "SET_LOADING";
export const _deleteProspect = (index) => async (dispatch, getState) => {
  try {
    console.log("==============deleteProspect=================", dispatch);
    // dispatch({
    //   type: SET_LOADING,
    //   payload: true,
    // })
    dispatch({
      type: DELETE_PROSPECT,
      payload: index,
    });
    // setTimeout(() => {
    //   dispatch({
    //     type: SET_LOADING,
    //     payload: false,
    //   })
    // }, 2000);
  } catch (e) {
    console.log(e);
  }
};

// Harry Potter Mode
export const HARRY_POTTER_MODE = "HARRY_POTTER_MODE";
export const _harryPotterMode = (boolState) => async (dispatch, getState) => {
  try {
    console.log("==============harryPotterMode=================", dispatch);
    // const { controller } = getState().prospectsView.prospectVariant;
    // if (controller) {
    //   controller.abort();
    // }
    // let newController = new AbortController();
    // let signal = newController.signal;
    // dispatch({
    //   type: SET_ABORT_CONTROLLER,
    //   payload: newController,
    // });
    // const prospects = getState().prospectsView.candidates.prospects;
    // const newData = prospects.map((item) => {
    //   const { imageUrl, id, publicIdentifier, isHarryPotter } = item;
    //   return {
    //     imageUrl,
    //     profileId: id,
    //     publicIdentifier,
    //     isHarryPotter,
    //   };
    // });
    // console.log("newData", newData);
    // if (boolState === false) {
    //   const response = await manualApiCall(`/api/v2/auth/harryPotter`, {
    //     method: "POST",
    //     body: JSON.stringify({
    //       data: newData,
    //     }),
    //     signal,
    //   });
    // }

    dispatch({
      type: HARRY_POTTER_MODE,
      payload: boolState,
    });
  } catch (e) {
    console.log(e);
  }
};
export const FETCH_HARRY_POTTER_MODE = "FETCH_HARRY_POTTER_MODE";
export const _fetchHarryPotterMode = () => async (dispatch, getState) => {
  try {
    console.log(
      "==============fetchHarryPotterMode=================",
      dispatch
    );
    const { controller } = getState().prospectsView.prospectVariant;
    if (controller) {
      controller.abort();
    }
    let newController = new AbortController();
    let signal = newController.signal;
    dispatch({
      type: SET_ABORT_CONTROLLER,
      payload: newController,
    });
    const prospects = getState().prospectsView.candidates.prospects;
    const newData = prospects.map((item) => {
      const { imageUrl, id, publicIdentifier, isHarryPotter } = item;
      return {
        imageUrl,
        profileId: id,
        publicIdentifier,
        isHarryPotter,
      };
    });
    const response = await manualApiCall(`/api/v2/auth/harryPotter`, {
      method: "POST",
      body: JSON.stringify({
        data: newData,
      }),
      signal,
    });
    console.log("response", response);
    dispatch({
      type: FETCH_HARRY_POTTER_MODE,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
};
export const SHOULD_SHOW_HARRY_POTTER_MODE = "SHOULD_SHOW_HARRY_POTTER_MODE";
export const _shouldShowHarryPotterMode = (boolState) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============shouldShowHarryPotterMode=================",
      dispatch
    );
    dispatch({
      type: SHOULD_SHOW_HARRY_POTTER_MODE,
      payload: boolState,
    });
  } catch (e) {
    console.log(e);
  }
};
export const FETCH_HARRY_POTTER_COUNT = "FETCH_HARRY_POTTER_COUNT";
export const _fetchHarryPotterCount = (jId) => async (dispatch, getState) => {
  try {
    console.log(
      "==============fetchHarryPotterCount=================",
      dispatch
    );
    // const { controller } = getState().prospectsView.prospectVariant;
    // if (controller) {
    //   controller.abort();
    // }
    // let newController = new AbortController();
    // let signal = newController.signal;
    // dispatch({
    //   type: SET_ABORT_CONTROLLER,
    //   payload: newController,
    // });
    const response = await manualApiCall(`/api/v2/auth/harryPotter/count`, {
      method: "POST",
      body: JSON.stringify({
        jobId: jId,
      }),
      // signal,
    });
    console.log(
      "🚀 ~ file: prospectsView.js ~ line 2223 ~ const_fetchHarryPotterCount= ~ response",
      response
    );

    dispatch({
      type: FETCH_HARRY_POTTER_COUNT,
      payload: response?.harryPotterCount,
    });
  } catch (e) {
    console.log(
      "🚀 ~ file: prospectsView.js ~ line 2233 ~ const_fetchHarryPotterCount= ~ e",
      e
    );
  }
};
export const HARRY_POTTER_INIT_STATE = "HARRY_POTTER_INIT_STATE";
export const _harryPotterInitState = () => async (dispatch, getState) => {
  try {
    console.log(
      "==============harryPotterInitState=================",
      dispatch
    );
    dispatch({
      type: HARRY_POTTER_INIT_STATE,
    });
  } catch (e) {
    console.log(e);
  }
};
export const FETCH_HARRY_POTTER_LOADER = "FETCH_HARRY_POTTER_LOADER";
export const _fetchHarryPotterLoader = (boolState) => async (
  dispatch,
  getState
) => {
  try {
    console.log(
      "==============fetchHarryPotterLoader=================",
      dispatch
    );
    dispatch({
      type: FETCH_HARRY_POTTER_LOADER,
      payload: boolState,
    });
  } catch (e) {
    console.log(e);
  }
};

export const DELETE_COUNTER = "DELETE_COUNTER";
export const _deleteCounter = (count) => async (dispatch, getState) => {
  try {
    console.log("==============deleteCounter=================", dispatch);
    dispatch({
      type: DELETE_COUNTER,
      payload: count,
    });
  } catch (e) {
    console.log(e);
  }
};
