import { ContentState, Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsJDGenerated } from "../../actions/JobSetup";
import Loading from "../../uiLibrary/Loading";
import SecondaryButton from "../../uiLibrary/SecondaryButton";
import "./TextEditor.scss";
import Toolbar from "./Toolbar";
const TextEditor = ({
  value = "",
  handleChange = () => false,
  pasteHandler = () => false,
  // disabled,
  isReview
}) => {

  const dispatch = useDispatch()
  const { jobDescription, isEnterBotPressed, generateJD } = useSelector(state => ({
    jobDescription: state.jobSetup.jobSetup.jobDescription,
    isEnterBotPressed: state.jobSetup.jobSetup.isEnterBotPressed,
    generateJD: state.jobSetup?.jobSetup?.generateJD
  }))

  const [showJDBtn, setShowJDBtn] = useState(true)
  const [showClipboard, setShowClipboard] = useState(true)
  const [editorState, updateEditorState] = useState(
    EditorState.createEmpty()
  );
  const isEditorDisabled = isEnterBotPressed

  useEffect(() => {
    if (generateJD) {
      setShowJDBtn(false)
      setShowClipboard(false)
    } else {

      console.log("jobDescription", jobDescription)
      if (jobDescription) {
        setShowJDBtn(false)
        setShowClipboard(false)
        addNewEditorStateFromValue(jobDescription)
      }
      else if (value !== "") {
        setShowJDBtn(false)
        setShowClipboard(false)
        addNewEditorStateFromValue(value)
      }
      else {
        setShowJDBtn(true)
        setShowClipboard(true)
      }
    }
  }, [jobDescription, generateJD]);
  const addNewEditorStateFromValue = (value) => {
    const newContent = ContentState.createFromText(value);
    updateEditorState(EditorState.push(editorState, newContent, "insert-text"));
  };

  const onChange = (newEditorState) => {
    if (isReview === '1') return;
    const newTextValue = newEditorState.getCurrentContent().getPlainText();
    updateEditorState(newEditorState);
    handleChange(newTextValue);
    if (!newTextValue) {
      setShowJDBtn(true)
      setShowClipboard(true)
    }
  };

  const onPaste = async () => {
    if (isReview === '1') return;
    const newValue = await navigator.clipboard.readText();
    addNewEditorStateFromValue(newValue);
    pasteHandler(newValue);
  };

  const generateJobDescription = () => {
    // make an input to get Job description
    //seShowJDBtn(true)
    // console.log("setting jd description to true")
    setShowJDBtn(false)
    setShowClipboard(false)
    dispatch(setIsJDGenerated(true))
  }


  return (
    <div className="textEditorWrapper">
      <Toolbar
        editorState={editorState}
        updateEditorState={updateEditorState}
        value={value}
      />
      <div className="textEditorContainer">
        <Editor editorState={editorState} onChange={onChange} readOnly={isReview === '1' || isEditorDisabled} />
      </div>

      {value === "" && (
        <span className="pasteButtonContainer">
          {isEnterBotPressed ? <div style={{}}><Loading /></div> : <></>}
          {showClipboard ?
            (<SecondaryButton onClick={onPaste} className="paste-btn">
              Paste from Clipboard
            </SecondaryButton>) : <></>
          }
          <br />
          {showJDBtn ? (
            <div className="generate-btn-wrapper">
              <div>Don’t have a Job Description?</div>
              <SecondaryButton onClick={generateJobDescription} className="generate-btn">
                ✨ Generate one for me
              </SecondaryButton>
            </div>) : <></>}
        </span>
      )}
    </div>
  );
}

export default TextEditor
