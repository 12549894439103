import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deleteEmoji, saveEmoji } from '../../../../actions/prospectsView';
import EmojiPicker from '../../../../components/EmojiPicker/EmojiPicker';
import Emoji from '../../../../uiLibrary/SVGs/Emoji';
import MessageCircle from '../../../../uiLibrary/SVGs/MessageCircle';
import { Mixpanel } from '../../../../utils/Mixpanel';
import './style.scss';

const ProfileColabActions = ({
    emojies = [],
    jobProfileId,
    prospectData,
    profileData,
    commentsCount,
    onClickComments = e => false,
    job,
    selectedProspectComments,
    showEmojiPickerProspectID,
    permissionsAllowed,
    pageNo,
    likedStatus,
    deleteCount,
    mixpanelActionType = false,
    dispatch
}) => {
    const [showEmojiMenu, setShowEmojiMenu] = useState(false);
    const [listener, setListener] = useState(0)
    const toggleEmoji = () => {
        setShowEmojiMenu((prevState) => !prevState);
    };
    useEffect(() => {
        if (showEmojiMenu) {
            document.addEventListener("click", eventFunction);
        }
        else {
            // console.log("======================removeEventListener================================")
            setListener(0)
            document.removeEventListener("click", eventFunction)
        }
        return () => document.removeEventListener("click", eventFunction);
    }, [showEmojiMenu])

    const eventFunction = (e) => {
        if (!e.target.closest(".emoji-picker-react")) {
            setListener(prev => {
                if (prev === 0) {
                    return 1
                }
                // console.log("======================emoji-picker-react================================")
                setShowEmojiMenu(false)
                return prev
            })
        }
    }

    const getAllEmojies = (emojies) => {
        const emoteCount = {}
        return emojies?.map((emoji) => {
            // console.log("==========================getAllEmojies====================================", {emoji, emoteCount})
            if (emoji?.emojiText) {
                emoteCount[emoji?.emojiText] = (emoteCount[emoji?.emojiText] || 0) + 1
                return ({
                    emojiText: emoji?.emojiText,
                    emojiASCII: emoji?.emojiASCII,
                    count: emoteCount[emoji?.emojiText],
                    isOwner: emoji?.createdBy
                        ? emoji?.createdBy?.isOwner
                        : emoji?.isOwner,
                    names: emoji?.createdBy ? [emoji?.createdBy?.name] : [emoji?.name],
                });
            }
        });
    }

    let allEmojiesData = getAllEmojies(emojies)

    if (allEmojiesData?.length > 0) {
        allEmojiesData = allEmojiesData
            ?.sort((a, b) => b.emojiText - a.emojiText || a.count - b.count)
        allEmojiesData = allEmojiesData?.map(t => {
            const temp = allEmojiesData?.filter(i => i.emojiText === t.emojiText)
            if (temp?.length > 1) {
                t.isOwner = temp.some(i => i?.isOwner === true)
                t.count = temp?.length// temp.filter(k => k.emojiASCII !== 'undefined').map(k => k.count).reduce((p, c) => p + c, 0)
            }
            return t
        }).filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.emojiText === value.emojiText
            ))
        )
    }

    const allEmojies = allEmojiesData
    const deleteEmojiHandle = (emoji) => {
        dispatch(deleteEmoji({
            noteId: null,
            jobProfileId: jobProfileId,
            emojiText: emoji?.emojiText,
            jobId: job?.id,
        }));
    };

    const handleEmojiPicked = (emoji) => {
        setShowEmojiMenu(false)
        dispatch(saveEmoji({
            noteId: null,
            jobProfileId: jobProfileId,
            emojiText: emoji?.emoji || emoji?.emojiText,
            emojiAscII: emoji?.unified,
            jobId: job?.id,
            pageNo,
        }));
    };


    return (
        <div
            className='profileColabActions'
        >
            {showEmojiMenu && (
                <div className="emoji">
                    <EmojiPicker
                        handleEmojiPicked={handleEmojiPicked}
                        prospect={prospectData}
                    />
                </div>
            )}
            <button
                onClick={onClickComments}
            >
                <MessageCircle selected={true} />{" "}
                {profileData?.noteCount !== "0" ? (
                    <span className="count">
                        {profileData?.noteCount}
                    </span>
                ) : (
                    ""
                )}
            </button>
            <div
                className='emojiContainer'
            >
                {allEmojies?.map((emoji) => {
                    return (<div
                        className={`emojies ${emoji.isOwner ? "emojiOwner" : ""
                            }`}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <button
                            onClick={() => {
                                emoji.isOwner
                                    ? deleteEmojiHandle(emoji, jobProfileId)
                                    : handleEmojiPicked(emoji, jobProfileId);
                                // setCurrentEmoji({});
                                // setCurrentEmojiIndex(null);
                            }}
                            onMouseOver={() => {
                                // setCurrentEmoji(emoji);
                                // setCurrentEmojiIndex(index);
                                // setShowEmojiTool(true);
                            }}
                            onMouseLeave={() => {
                                // setShowEmojiTool(false);
                            }}
                        >
                            <span className="emoji">{emoji.emojiText}</span>{" "}
                            <span className="count">{emoji.count || 1}</span>
                        </button>
                    </div>
                    )
                })}
            </div>
            <button
                onClick={() => {
                    if (mixpanelActionType) {
                        Mixpanel.track(`React box - ${mixpanelActionType}`)
                    }
                    toggleEmoji()
                }}
            >
                <Emoji selected={true} />
            </button>
        </div>
    )
}

export default withRouter(
    connect((state) => ({
        job: state?.prospectsView?.candidates?.job,
        selectedProspectIDVariant:
            state?.prospectsView?.prospectVariant?.selectedProspectIDVariant,
        prospects: state?.prospectsView?.candidates?.prospects,
        activeProspectVariant:
            state?.prospectsView?.prospectVariant?.activeProspectVariant,
        selectedProspectComments:
            state?.prospectsView?.candidates?.selectedProspectComments,
        showEmojiPickerProspectID:
            state?.prospectsView?.candidates?.showEmojiPickerProspectID,
        permissionsAllowed: state?.prospectsView?.candidates?.permissions,
        pageNo: state?.prospectsView?.candidates?.page,
        currentHoverElement: state?.prospectsView?.candidates?.currentHoverElement,
        selectedProspects: state.prospectsView.candidates.selectedProspects,
        likedStatus: state.prospectsView.candidates.likedStatus,
        deleteCount: state.prospectsView.candidates.deleteCount,
    }))(ProfileColabActions)
);