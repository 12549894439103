import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedProfile,
  updateFeedbackEntity,
} from "../../../actions/QuickSetup";
import { getEntityByProfileID } from "../../../reducers/QuickSetup/profileFeedback";
import Tag from "../ProfileDrawer/Tag";
import SectionWrapper from "./SectionWrapper";

export default function TagSection({
  name = "",
  id,
  sectionData = [],
  profile,
}) {
  const dispatch = useDispatch();
  const currentFeedback = useSelector((state) =>
    getEntityByProfileID(state.QuickSetup.profileFeedback, id)
  );

  const field = name === "Skills" ? "skills" : "industries";
  const sectionName = name === "Skills" ? "skills" : "business";
  const sectionFeedback = currentFeedback?.data?.[sectionName];

  const items =
    sectionData?.length && sectionData?.slice(0, 5)?.filter((item) => !!item);

  const handleLikeTag = (value) => {
    if (name === "Skills") {
      const skillsFeedback = currentFeedback?.data?.skills;
      const allSkills = skillsFeedback?.skills;
      if (
        allSkills?.some((skill) => skill.toLowerCase() === value.toLowerCase())
      ) {
        return dispatch(
          updateFeedbackEntity(id, "skills", {
            ...skillsFeedback,
            skills: allSkills?.filter(
              (skill) => skill.toLowerCase() !== value.toLowerCase()
            ),
          })
        );
      }

      return dispatch(
        updateFeedbackEntity(id, "skills", {
          ...skillsFeedback,
          skills: [...allSkills, value],
        })
      );
    }

    const businessFeedback = currentFeedback?.data?.business;
    const allIndustries = businessFeedback?.industries;
    if (
      allIndustries.some(
        (industry) => industry.toLowerCase() === value.toLowerCase()
      )
    ) {
      return dispatch(
        updateFeedbackEntity(id, "business", {
          ...businessFeedback,
          industries: allIndustries?.filter(
            (industry) => industry.toLowerCase() !== value.toLowerCase()
          ),
        })
      );
    }
    return dispatch(
      updateFeedbackEntity(id, "business", {
        ...businessFeedback,
        industries: [...allIndustries, value],
      })
    );
  };

  return (
    <SectionWrapper name={name}>
      <div className="skills">
        {items?.map((item, key) => (
          <div key={key}>
            <Tag
              key={key}
              value={item}
              isLiked={sectionFeedback?.[field]?.includes(item)}
              handleLike={handleLikeTag}
            >
              {item}
            </Tag>
          </div>
        ))}
        {sectionData?.length > 6 && (
          <p
            onClick={() => dispatch(setSelectedProfile(profile))}
            className="hyperlink"
          >
            See all
          </p>
        )}
      </div>
    </SectionWrapper>
  );
}
