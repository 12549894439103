import React from 'react'
import InputRange from 'react-input-range'
import "react-input-range/lib/css/index.css";
import './style.scss'
const RangeInput = ({
    className='',
    value={
        min:3,
        max:5
    },
    onChange=e=>e,
    label='',
    minValue=0,
    maxValue=100,
    staticMax=false,
    getLabel=false,
    step=1,
    resultLabel=false,
    onMouseEnter=e=>false,
    onMouseLeave=e=>false,
    disabled=false
}) => {
    const getMax = () => {
        if(value.max+5>=maxValue || staticMax){
            return maxValue
        }
        else{
            return value.max+5
        }
    }
    // const getMin = () => {
    //     if(value.min-5<=minValue){
    //         return minValue
    //     }
    //     else{
    //         return value.min-5
    //     }
    // }

    return (
        <div
            className={`rangeInputContainer${resultLabel ? ' rangeInputContainerWithResult' : ''} ${className}`}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{
                opacity:disabled?0.5:1,
                transition: 'opacity 100ms linear'
            }}
        >
            <InputRange
                minValue={minValue}
                maxValue={getMax()}
                formatLabel={(value) => disabled?'':getLabel ? getLabel(value) : `${value} ${label}`}
                value={value}
                step={step}
                onChange={onChange}
            />
            {resultLabel && 
                <p>
                    {resultLabel}
                </p>
            }
        </div>
    )
}

export default RangeInput
