import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import NewJobSpecialityCard from '../../../components/NewJobSpecialityCard/NewJobSpecialityCard'
import PillButton from '../../../components/PillComponents/PillButton'
import CrossSvg from '../../../components/svgComponents/CrossSvg'
import TickSvg from '../../../components/svgComponents/TickSvg'
import { addJobFunction, changeJobFunction, removeJobFunction, setIsEdited, setJobSetupView } from '../../../actions/JobSetup'
import '../../../components/_globalCSSClasses.scss'
import './criteriaFunctions.scss'
import JobSetupStep08 from '../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep08'
import BigRoundButton from '../../../uiLibrary/BigRoundButton'
import PlusSvg from '../../../uiLibrary/SVGs/PlusSvg'
import RangeInput from '../../../uiLibrary/RangeInput'
import DropdownComponent from '../../../uiLibrary/DropdownComponent'
import { set } from 'lodash'
const CriteriaFunctions = ({
    functions,
    functionsData,
    cards,
    isReview,
    dispatch
}) => {

    const [addFunctionMode, setaddFunctionMode] = useState(false)
    const [addFunctionEditor, setaddFunctionEditor] = useState('Select a function')

    useEffect(() => {
        dispatch(setJobSetupView({
            highlightValue: "",
            profileHighlightValue: [],
            selectedValues: [],
            profileSelectedValues: []
        }))
        functionsData.map(payload => dispatch(addJobFunction({
            ...payload,
            experienceSelected: (payload.min !== 0 || payload.max !== 0),
            experience: {
                min: payload.min,
                max: payload.max,
            }
        })))
        dispatch(setJobSetupView({
            carsouselSortValue: [],
            position: cards.map((card, i) => i+1)
        }))
    }, [])

    const selectedFunctions = Object.keys(functions).filter(key => functions[key].selected)
    const unselectedFunctions = Object.keys(functions).filter(key => !functions[key].selected)

    const availableOptions = [
        'Operations',
        'Finance, Accounting',
        'Finance, Corporate Finance',
        'Legal',
        'Engineering',
        'Engineering, Research and Development',
        'Education',
        'Arts',
        'HR',
        'HR>Recruiting',
        'Sales',
        'Sales, Sales Engineering',
        'Customer Support',
        'Medicine',
        'Product',
        'Marketing',
        'Research',
        'Management'
    ].filter(opt=>!selectedFunctions.map(key=>functions[key].name).includes(opt))

    const handleToggleFunction = key => {
        //dispatch(setIsEdited(true))
        dispatch(changeJobFunction({
            key,
            value: {
                selected: !functions[key].selected
            }
        }))
    }

    const handleFunctionChange = (value, key) => {
        //dispatch(setIsEdited(true))
        dispatch(changeJobFunction({
            key, 
            value: {
                name: value
            }
        }))
    }

    const handleFunctionBlur = (e, key) => {
       if(e.target.value===''){
           dispatch(removeJobFunction(key))
       }
    }

    const handleToggleExperience = (experienceSelected, key) => {
        //dispatch(setIsEdited(true))
        dispatch(changeJobFunction({
            key, 
            value: {
                experienceSelected,
            }
        }))
    }

    const handleExperienceChange = (value, key) => {
        //dispatch(setIsEdited(true))
        dispatch(changeJobFunction({
            key, 
            value: {
                experience: value
            }
        }))
    }

    const handleFunctionEditorChange = value => {
        // if(addFunctionEditor.trim()==='Select a function'){
        //     alert('Select a function please')
        // }
        // else{
            //dispatch(setIsEdited(true))
            dispatch(addJobFunction({
                name: value
            }))
            toggleAddFunctionMode()
        // }
    }

    const toggleAddFunctionMode = e => {
        setaddFunctionMode(prev => !prev)
        setaddFunctionEditor('Select a function')
    }

    const handAddFunction = e => {
        if(addFunctionEditor.trim()==='Select a function'){
            alert('Select a function please')
        }
        else{
            dispatch(addJobFunction({
                name: addFunctionEditor
            }))
            toggleAddFunctionMode()
        }
    }

    return (
        <div
            className='criteriaFunctionsContainer'
        >
            <div>
                <JobSetupStep08 selected={true} />
                <h2>
                    Functional Role
                </h2>
            </div>
            <div
                className='criteriaFunctionsBody'
            >
                {selectedFunctions.map(key=>(
                    <CriteriaFunctionsInput
                        selected={functions[key].name}
                        onSelect={isReview === '1' ? () => false : e=>handleFunctionChange(e, key)}
                        options={availableOptions}
                        toggleExperience={e=>handleToggleExperience(!functions[key].experienceSelected, key)}
                        experienceSelected={functions[key].experienceSelected}
                        experienceMin={functions[key].experience.min}
                        experienceMax={functions[key].experience.max}
                        onChangeRange={isReview === '1' ? () => false : value=>handleExperienceChange(value, key)}
                        toggle={isReview === '1' ? () => false : e=>handleToggleFunction(key)}
                    />
                ))}
            </div>
            <div
                className='criteriaFunctionsBody'
            >
                {addFunctionMode ?
                    <span>
                        
                        <DropdownComponent
                            selected={addFunctionEditor}
                            onSelect={isReview === '1' ? () => false : e=>handleFunctionEditorChange(e)}
                            options={availableOptions}
                        />
                        {/* <BigRoundButton 
                            plus={true}
                            onClick={handAddFunction}  
                        /> */}
                        <BigRoundButton
                            onClick={isReview === '1' ? () => false : toggleAddFunctionMode}
                        >

                            <CrossSvg
                                width='16'
                                height='16'
                            />
                        </BigRoundButton>
                    </span>:
                    isReview === '1' ? '' : 
                    <button
                        className='addMorePrimaryButton'
                        onClick={toggleAddFunctionMode}
                    >
                        <PlusSvg selected={true}/>
                        Add a function
                    </button>}
            </div>
        </div>
    )
}

export default connect(state=>({
    functions: state.jobSetup.jobCriteria.functions,
    functionsData: state.jobSetup.jobSetup.jobSetup?.functions || [],
    cards: state.jobSetup.viewController.cards,
    isReview: state.jobSetup?.jobSetup?.isReview
}))(CriteriaFunctions)

const CriteriaFunctionsInput = ({
    toggleExperience=e=>false,
    experienceSelected=false,
    experienceMin,
    experienceMax,
    onChangeRange,
    selected,
    onSelect,
    options,
    toggle,
}) => {
    return(
        <span>
            <DropdownComponent
                selected={selected}
                onSelect={onSelect}
                options={options}
            />
            <BigRoundButton
                onClick={toggle}
            >
                <CrossSvg
                    width='16'
                    height='16'
                />
            </BigRoundButton>
            {experienceSelected ?
                <React.Fragment>
                <button
                    className='addMoreButton'
                    onClick={toggleExperience}
                >
                    <CrossSvg/>
                    Remove experience
                </button>
                <RangeInput
                    value={{
                    min: experienceMin,
                    max: experienceMax
                    }}
                    onChange={onChangeRange}
                    resultLabel={`${experienceMin} to ${experienceMax} years`}
                /> 
                </React.Fragment> :
                <button
                className='addMoreButton'
                onClick={toggleExperience}
                >
                <PlusSvg/>
                Add experience
                </button>
            }
        </span>
    )
}