import React from 'react';
import { Progress } from 'reactstrap';
import './style.scss';
const ProfileScoring = ({
  buck_score,
  scoring,
  containerStyle = {},
  isQuick
}) => {

  const titleScore = ((buck_score || 0) * 100) / 6;
  const experienceScore = ((scoring?.experience_score || 0) * 100) / (scoring?.experience_total || 1);
  const educationScore = ((scoring?.education_score || 0) * 100) / (scoring?.education_total || 1);
  const skillsScore = ((scoring?.skill_score || 0) * 100) / (scoring?.skill_total || 1);
  const specialitiesScore = ((scoring?.specialty_score || 0) * 100) / (scoring?.specialty_total || 1);
  const companyScore = isQuick ? (scoring?.company_score || 0) * 100 : ((scoring?.company_score || 0) * 100) / 5;


  return (
    <div
      style={containerStyle}
      className='profileScoringContainer'
    >
      <span>
        <p>Current Title</p>
        <Progress value={titleScore} />
      </span>
      <span>
        <p>Experience</p>
        <Progress value={experienceScore} />
      </span>
      <span>
        <p>Education</p>
        <Progress value={educationScore} />
      </span>
      <span>
        <p>Skills</p>
        <Progress value={skillsScore} />
      </span>
      <span>
        <p>Business</p>
        <Progress value={specialitiesScore} />
      </span>
      <span>
        <p>Company</p>
        <Progress value={companyScore} />
      </span>
    </div>

  )
}

export default ProfileScoring