import React from "react";
import { connect } from "react-redux";
import ThumbFilledSvg from "../SVGs/ThumbFilledSvg";
import "./style.scss";
const ThumbPillButton = ({
  className = "",
  style = {},
  onClick = (e) => false,
  disabled = false,
  liked = false,
  handleOnMouseEnter = (e) => false,
  handleOnMouseLeave = (e) => false,
  children,
  dispatch,
  isPrimaryButtonLoading,
}) => {
  return (
    <button
      className={`thumbPillQluButton ${liked ? "thumbPillQluButtonLiked" : ""
        } ${className}`}
      style={style}
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {children}
      <ThumbFilledSvg selected={liked} />
    </button>
  );
};

export default connect((state) => ({
  isPrimaryButtonLoading: state.primaryButtonloading.isPrimaryButtonLoading,
}))(ThumbPillButton);
