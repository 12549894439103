import React from "react";
import "../style.scss";

const DownloadIcon = ({
  color = "#CDD1D7",
  width = "16",
  height = "16",
  style = {},
  stroke = 1.5,
  className = "",
  fill = "none",
  selected = false,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      className={`${className} ${selected ? "qluSvgFillSelected" : ""}`}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6665 6.66699L7.99984 10.0003L11.3332 6.66699"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10V2"
        className={`qluSvgStroke${selected ? "Selected" : ""}`}
        stroke={color}
        strokeWidth={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
