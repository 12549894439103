import React from "react";
import HeartBorderSVG from "../SVGs/HeartBorderSVG";
import HeartFillSVG from "../SVGs/HeartFillSVG";
import "./styles.scss";

export default function SuperLike({
  likedHearts = 0,
  totalHearts = 3,
  toggleLike = () => {},
}) {
  const onClick = (e, heartIndex) => {
    e.stopPropagation();
    if (heartIndex + 1 === likedHearts) {
      return toggleLike(0, e);
    }

    return toggleLike(heartIndex + 1, e);
  };

  return (
    <div className="heartsContainer">
      {[...new Array(totalHearts)].map((arr, index) => {
        return index < likedHearts ? (
          <HeartFillSVG
            onClick={(e) => onClick(e, index)}
            className="heart"
            key={index}
          />
        ) : (
          <HeartBorderSVG
            onClick={(e) => onClick(e, index)}
            className="heart"
            key={index}
          />
        );
      })}
    </div>
  );
}
