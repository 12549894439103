import {
  FAVOURITE_CLEAR,
  FAVOURITE_EDUCATIONS,
  FAVOURITE_EXPERIENCES,
  FAVOURITE_FUNCTIONAL_EXPERIENCE,
  FAVOURITE_HIGH_IMPORTANT_SKILLS,
  FAVOURITE_LOW_IMPORTANT_SKILLS,
  FAVOURITE_MEDIUM_IMPORTANT_SKILLS,
  FAVOURITE_RECEIVE_ALL,
  FAVOURITE_RELEVANT_EXPERIENCE,
  FAVOURITE_SPECIALITIES,
  FAVOURITE_TOTAL_EXPERIENCE,
} from "../../actions/prospectsView";

const initialState = {
  favouriteRelevantExperience: [],
  favouriteExperiences: [],
  favouriteFunctionalExperience: [],
  favouriteTotalExperience: false,
  favouriteSpecialities: [],
  favouriteHighImportantSkills: [],
  favouriteMediumImportantSkills: [],
  favouriteLowImportantSkills: [],
  favouriteEducations: [],
  mainData: false,
};

const recommendations = (state = initialState, action) => {
  switch (action.type) {
    case FAVOURITE_RECEIVE_ALL: {
      return {
        ...state,
        mainData: true,
        ...action.payload,
      };
    }
    case FAVOURITE_CLEAR: {
      return {
        favouriteRelevantExperience: [],
        favouriteExperiences: [],
        favouriteFunctionalExperience: [],
        favouriteTotalExperience: false,
        favouriteSpecialities: [],
        favouriteHighImportantSkills: [],
        favouriteMediumImportantSkills: [],
        favouriteLowImportantSkills: [],
        favouriteEducations: [],
        mainData: false,
      };
    }
    case FAVOURITE_RELEVANT_EXPERIENCE: {
      return {
        ...state,
        favouriteRelevantExperience: action.payload,
      };
    }
    case FAVOURITE_EXPERIENCES: {
      return {
        ...state,
        favouriteExperiences: action.payload,
      };
    }
    case FAVOURITE_FUNCTIONAL_EXPERIENCE: {
      return {
        ...state,
        favouriteFunctionalExperience: action.payload,
      };
    }
    case FAVOURITE_TOTAL_EXPERIENCE: {
      return {
        ...state,
        favouriteTotalExperience: action.payload,
      };
    }
    case FAVOURITE_SPECIALITIES: {
      return {
        ...state,
        favouriteSpecialities: action.payload,
      };
    }
    case FAVOURITE_HIGH_IMPORTANT_SKILLS: {
      return {
        ...state,
        favouriteHighImportantSkills: action.payload,
      };
    }
    case FAVOURITE_MEDIUM_IMPORTANT_SKILLS: {
      return {
        ...state,
        favouriteMediumImportantSkills: action.payload,
      };
    }
    case FAVOURITE_LOW_IMPORTANT_SKILLS: {
      return {
        ...state,
        favouriteLowImportantSkills: action.payload,
      };
    }
    case FAVOURITE_EDUCATIONS: {
      return {
        ...state,
        favouriteEducations: action.payload,
      };
    }
    // case FAVOURITE_MAIN_DATA: {
    //   return {
    //     ...state,
    //     mainData: action.payload,
    //   };
    // }
    default:
      return state;
  }
};
export default recommendations;
