import React, { useState } from "react";
import { Button, ButtonGroup } from "reactstrap";
import "./style.scss";

const ImportanceButton = ({
  lowLevel='Low',
  midLevel='Medium',
  hiLevel='High',
  importanceLevel='',
  onClick=e=>false
}) => {
  return (
    <ButtonGroup className="importanceQluButtonContainer">
      <button
        className={`buttonGroupButton${importanceLevel === lowLevel ? " buttonStateToggle" : ""}`}
        onClick={e=>onClick(lowLevel)}
      >
        {lowLevel}
      </button>

      <button
        className={`buttonGroupButton${importanceLevel === midLevel ? " buttonStateToggle" : ""}`}
        onClick={e=>onClick(midLevel)}
      >
        {midLevel}
      </button>

      <button
        className={`buttonGroupButton${importanceLevel === hiLevel ? " buttonStateToggle" : ""}`}
        onClick={e=>onClick(hiLevel)}
      >
        {hiLevel}
      </button>
    </ButtonGroup>
  );
};

export default ImportanceButton;
