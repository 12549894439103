import React from 'react'
import QluIcon from "../../img/qlu-icon.png"
import './CalendarHeader.scss'

import SecondaryButton from "../../uiLibrary/SecondaryButton";
import BriefcaseSvg from "../svgComponents/BriefcaseSvg";
import NotificationIconSvg from "../svgComponents/NotificationIconSvg";
import { withRouter } from "react-router-dom";
import NotificationDropdownPanel from '../../views/LandingPage/Home/NotificationDropdownPanel'

const CalendarHeader = ({
    history,
    onDnnaeClick = () => { history.push("/") },
    onClickJobSetUp = () => { history.push("/jobsetup") }
}) => {
    return (
        <div className='calendar_header_root'>
            <div onClick={onDnnaeClick} >
                <img src={QluIcon} alt='N/A' />
                <span>My Calendar</span>
            </div>
            <div className='calendarHeaderRightButtons'>
                <SecondaryButton className="headerIcon" onClick={onClickJobSetUp}>
                    <BriefcaseSvg />
                </SecondaryButton>
                <SecondaryButton className="headerIcon">
                    {/* <NotificationIconSvg /> */}
                    <NotificationDropdownPanel historyPush={history.push}/>
                </SecondaryButton>
            </div>
        </div>
    )
}

export default withRouter(CalendarHeader)