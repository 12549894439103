import React, { useEffect, useRef, useState } from 'react'
import "./style.scss"
import sendIcond from "../../assets/send.svg"

function TextField({ placeholder = '', onSubmit = () => false, isLoading }) {

    const textRef = useRef()
    const [value, setValue] = useState();

    useEffect(() => {
        // setTimeout(() => {
        if (value == '') {
            console.log("inside :", value)
            textRef.current.style.height = "35px";
        }
        if (textRef && textRef.current) {
            textRef.current.style.height = "0px";
            const taHeight = textRef.current.scrollHeight;
            textRef.current.style.height = taHeight + "px";
        }
        // }, 100)
    }, [value]);


    const handleClick = () => {
        !isLoading && setTimeout(() => {
            onSubmit(value)
            setValue('')
        }, 50)
    }

    return (
        <div className='textField' onKeyDown={(e) => e.keyCode === 13 && handleClick()}
        >
            <textarea placeholder={placeholder} ref={textRef} value={value} onChange={(e) => setValue(e.target.value)} />
            <img
                src={sendIcond}
                onClick={handleClick}
            />
        </div>
    )
}

export default TextField