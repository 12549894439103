import React from 'react'

const ExtensionIcon = ({
  style,
  className,
  width = "24",
  height = "24"
}) => {
  return (
    <svg style={style} className={className} width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7861 0.85C8.65401 0.85 6.91402 2.59448 6.91402 4.73065V5.28792H1.71071C1.23512 5.28792 0.85 5.67442 0.85 6.15026V12.2007C0.85 12.6765 1.23512 13.063 1.71071 13.063H3.12707C4.30763 13.063 5.27775 14.0348 5.27775 15.219C5.27775 16.4034 4.30765 17.3749 3.12707 17.3749H1.71071C1.23512 17.3749 0.85 17.7614 0.85 18.2373V24.2877C0.85 24.7639 1.23517 25.15 1.71071 25.15H7.77473C8.25028 25.15 8.63544 24.7639 8.63544 24.2877V22.868C8.63544 21.6839 9.60522 20.7121 10.7861 20.7121C11.9667 20.7121 12.9368 21.6839 12.9368 22.868V24.2877C12.9368 24.7639 13.322 25.15 13.7975 25.15H19.8615C20.3371 25.15 20.7222 24.7639 20.7222 24.2877V19.0996H21.2779C23.41 19.0996 25.15 17.3551 25.15 15.219C25.15 13.0832 23.41 11.3383 21.2779 11.3383H20.7222V6.15026C20.7222 5.67442 20.3371 5.28792 19.8615 5.28792H14.6582V4.73065C14.6582 2.59447 12.9179 0.85 10.7861 0.85ZM6.99918 15.219C6.99918 13.0832 5.25883 11.3383 3.12707 11.3383H2.57142V7.01261H7.77473C8.25032 7.01261 8.63544 6.62611 8.63544 6.15026V4.73065C8.63544 3.54618 9.6052 2.57469 10.7861 2.57469C11.9667 2.57469 12.9368 3.54619 12.9368 4.73065V6.15026C12.9368 6.62611 13.3219 7.01261 13.7975 7.01261H19.0008V12.2007C19.0008 12.6765 19.3859 13.063 19.8615 13.063H21.2779C22.4588 13.063 23.4286 14.0348 23.4286 15.219C23.4286 16.4034 22.4588 17.3749 21.2779 17.3749H19.8615C19.3859 17.3749 19.0008 17.7614 19.0008 18.2373V23.4253H14.6582V22.868C14.6582 20.7322 12.9179 18.9874 10.7861 18.9874C8.654 18.9874 6.91402 20.7322 6.91402 22.868V23.4253H2.57142V19.0996H3.12707C5.25882 19.0996 6.99918 17.3551 6.99918 15.219Z" fill="#FF8D4E" stroke="#FF8D4E" stroke-width="0.3" />
    </svg>
  )
}

export default ExtensionIcon