import * as ActionTypes from '../../actions/notifications'

const initialState = {
    notifications: {},
    notificationsLoading: false,
    notificationsSuccess: false,
    polling: false,
    pageNo: 1
}

export const notifications = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_NOTIFICATIONS:
            return { ...state, notificationsLoading: true }

        case ActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notificationsLoading: false,
                polling: action.payload.polling,
                pageNo: action.payload.pageNo,
                notifications: { ...state.notifications, ...action.payload },
            }

        case ActionTypes.FETCH_NOTIFICATIONS_ERROR:
            return initialState
        default:
            return state
    }
}
