import React from "react";

const CaretDown = ({
  strokeWidth = "3",
  color = "#A7ABB0",
  width = "12",
  height = "6",
  style = {},
}) => {
  return (
    <svg
      color={color}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L7 7L13 1"
        stroke="#A7ABB0"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CaretDown;
