import React from 'react'
import { Input } from "reactstrap";
import './style.scss'


const PrimaryCheckBox = (
    props
) => {
  return (
    <Input
        {...props}
        className="checkBox"
        type="checkbox"
    />
  )
}

export default PrimaryCheckBox