import React from 'react'
import Loading from '../Loading'
import './style.scss'

const WarningButton = ({
    style={},
    className='',
    onClick=e=>false,
    disabled=false,
    isLoading=false,
    children
}) => {
  return (
         <button
            style={style}
            className={`warningQluButton ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {isLoading ? 
                <Loading
                    width='16'
                    height='16'
                    invert={true}
                />:
                children
            }
        </ button>
  )
}

export default WarningButton