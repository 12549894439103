import { CALL_API } from '../middlewares/apiMiddleware'
import { manualApiCall } from '../utils'
export const CALENDAR_REFRESH_TOKEN_VALIDITY = 'CALENDAR_REFRESH_TOKEN_VALIDITY'
export const CALENDAR_REFRESH_TOKEN_VALIDITY_SUCCESS = 'CALENDAR_REFRESH_TOKEN_VALIDITY_SUCCESS'
export const CALENDAR_REFRESH_TOKEN_VALIDITY_FAILURE = 'CALENDAR_REFRESH_TOKEN_VALIDITY_FAILURE'
export const validateCalendarToken = () => async (dispatch) => {
    try {
      dispatch({
          type: CALENDAR_REFRESH_TOKEN_VALIDITY
      })
      const result = await manualApiCall('/api/v2/auth/events/calendarValidityOfTokens',
        {
          method: 'GET',
        })
      if (result.success)
        dispatch({
            type: CALENDAR_REFRESH_TOKEN_VALIDITY_SUCCESS,
            payload: {...result}
        })
      else
        throw new Error
    } catch {
        dispatch({
            type: CALENDAR_REFRESH_TOKEN_VALIDITY_FAILURE
        })
    }
}

export const FETCH_CALENDAR_EVENTS = 'FETCH_CALENDAR_EVENTS'
export const FETCH_CALENDAR_EVENTS_SUCCESS = 'FETCH_CALENDAR_EVENTS_SUCCESS'
export const FETCH_CALENDAR_EVENTS_FAILURE = 'FETCH_CALENDAR_EVENTS_FAILURE'

export const fetchCalendarEvents = ({ startDate, endDate, calendars }) => {
    return {
        [CALL_API]: {
            types: [FETCH_CALENDAR_EVENTS, FETCH_CALENDAR_EVENTS_SUCCESS, FETCH_CALENDAR_EVENTS_FAILURE],
            endpoint: `/api/v2/auth/events/fetch/events`,
            options: {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify({
                    calendars,
                    startDate,
                    endDate
                })
            }
        }
    }
}

export const POST_CALENDAR_EVENTS = 'POST_CALENDAR_EVENTS'
export const POST_CALENDAR_EVENTS_SUCCESS = 'POST_CALENDAR_EVENTS_SUCCESS'
export const POST_CALENDAR_EVENTS_FAILURE = 'POST_CALENDAR_EVENTS_FAILURE'

export const postCalendarEvents = ({ event, user, calendar, sendNotifications = false }) => {
    return {
        [CALL_API]: {
            types: [POST_CALENDAR_EVENTS, POST_CALENDAR_EVENTS_SUCCESS, POST_CALENDAR_EVENTS_FAILURE],
            endpoint: `/api/v2/auth/events/post/events`,
            options: {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify({
                    event,
                    user,
                    calendar,
                    sendNotifications
                })
            }
        }
    }
}

export const FETCH_CALENDARS = 'FETCH_CALENDARS'
export const FETCH_CALENDARS_SUCCESS = 'FETCH_CALENDARS_SUCCESS'
export const FETCH_CALENDARS_FAILURE = 'FETCH_CALENDARS_FAILURE'

export const fetchCalendars = ({ calendars }) => {

    return {
        [CALL_API]: {
            types: [FETCH_CALENDARS, FETCH_CALENDARS_SUCCESS, FETCH_CALENDARS_FAILURE],
            endpoint: `/api/v2/auth/events/fetch/calendars`,
            options: {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify({
                    calendars: calendars
                })
            }
        }
    }
}

export const FETCH_CALENDAR_SETTINGS = 'FETCH_CALENDAR_SETTINGS'
export const FETCH_CALENDAR_SETTINGS_SUCCESS = 'FETCH_CALENDAR_SETTINGS_SUCCESS'
export const FETCH_CALENDAR_SETTINGS_FAILURE = 'FETCH_CALENDAR_SETTINGS_FAILURE'

export const fetchCalendarSettings = () => {

    return {
        [CALL_API]: {
            types: [FETCH_CALENDAR_SETTINGS, FETCH_CALENDAR_SETTINGS_SUCCESS, FETCH_CALENDAR_SETTINGS_FAILURE],
            endpoint: `/api/v2/auth/calendar/fetch/settings`,
            options: {
                method: 'GET'
            }
        }
    }
}

export const POST_CALENDAR_SETTINGS = 'POST_CALENDAR_SETTINGS'
export const POST_CALENDAR_SETTINGS_SUCCESS = 'POST_CALENDAR_SETTINGS_SUCCESS'
export const POST_CALENDAR_SETTINGS_FAILURE = 'POST_CALENDAR_SETTINGS_FAILURE'

export const postCalendarSettings = (payload) => {

    return {
        [CALL_API]: {
            types: [POST_CALENDAR_SETTINGS, POST_CALENDAR_SETTINGS_SUCCESS, POST_CALENDAR_SETTINGS_FAILURE],
            endpoint: `/api/v2/auth/calendar/save/settings`,
            options: {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify(payload)
            }
        }
    }
}

export const UPDATE_CALENDAR_SETTINGS = 'UPDATE_CALENDAR_SETTINGS'
export const UPDATE_CALENDAR_SETTINGS_SUCCESS = 'UPDATE_CALENDAR_SETTINGS_SUCCESS'
export const UPDATE_CALENDAR_SETTINGS_FAILURE = 'UPDATE_CALENDAR_SETTINGS_FAILURE'

export const updateCalendarSettings = (payload) => {

    return {
        [CALL_API]: {
            types: [UPDATE_CALENDAR_SETTINGS, UPDATE_CALENDAR_SETTINGS_SUCCESS, UPDATE_CALENDAR_SETTINGS_FAILURE],
            endpoint: `/api/v2/auth/calendar/update`,
            options: {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify(payload)
            }
        }
    }
}

export const DELETE_EVENT = 'DELETE_EVENT'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE'

export const deleteEvent = (eventID, calendar) => {
    return {
        [CALL_API]: {
            types: [DELETE_EVENT, DELETE_EVENT_SUCCESS, DELETE_EVENT_FAILURE],
            endpoint: `/api/v2/auth/events/delete/events`,
            options: {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                body: JSON.stringify({
                    eventID,
                    calendar
                })
            }
        }
    }
}


