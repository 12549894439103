import React, { useState } from 'react'
import { Cursor, useTypewriter } from 'react-simple-typewriter'
import UserAvt from '../../../../../../components/UserAvt'
import PrimaryButton from '../../../../../../uiLibrary/PrimaryButton'
import SupportSvg from '../../../../../../uiLibrary/SVGs/SupportSvg'
import './ChatBubble.scss'
function ChatBubble({
    text,
    fromBot = true,
    onFinish = () => { },
    onTyping = () => { },
    intro = false,
    isGibberish = false,
    setIsAnimating = () => { },
    loader = false
}) {
    console.log("content",text)
    const [showCursor, setShowCursor] = useState(true)
    const [typedText, helper] = useTypewriter({
        words: [text?.trim()],
        typeSpeed: 20,
        cursor: showCursor,
        onLoopDone: () => {
            setShowCursor(false)
            onTyping()
        },
        onType: onTyping,
    })
    const { isType, isDelete, isDelay, isDone } = helper

    setIsAnimating(!isDone)

    const showBtn = fromBot && !(intro || isGibberish || showCursor || loader)
    return (
        <div className={`ChatBubble ${fromBot ? 'bot' : 'user'}`}>
            <div className='chat-avatar'>
                {
                    fromBot ?
                        <SupportSvg /> :
                        <UserAvt style={{ fontWeight: "600", fontSize: "18px", margin: '0px' }} />
                }
            </div>
            <span>
                <span>
                    {fromBot ? (
                        <>
                            <span>{typedText}</span>
                            {(!isDone || loader) && <Cursor
                                cursorStyle={'█'}
                                cursorBlinking={!isDone || loader}
                            />}
                        </>
                    )
                        : text?.trim()
                    }
                </span>
                {
                    showBtn && (<PrimaryButton
                        className='use-jd-btn'
                        onClick={() => {
                            onFinish(text)
                        }}
                    >
                        Use this JD
                    </PrimaryButton>)
                }
            </span>
        </div>
    )
}

export default ChatBubble