import React from 'react'
import './style.scss'
const PrimaryInput = (props) => {
  return (
      <input
        {...props}
        className='primaryInput'
      />
  )
}

export default PrimaryInput