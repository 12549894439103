import React from 'react'
import './style.scss'
const LabelButton = ({
    style={},
    className='',
    disabled=false,
    onClick=e=>false,
    children
}) => {
    return (
        <button
            style={style}
            className={`labelQluButton ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {
                children
            }
        </button>
    )
}

export default LabelButton
