import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedProfile,
  updateFeedbackEntity,
} from "../../../actions/QuickSetup";
import { getEntityByProfileID } from "../../../reducers/QuickSetup/profileFeedback";
import LikeableSection from "./LikeableSection";
import SectionWrapper from "./SectionWrapper";

export default function Education({ data = [], id, profile }) {
  const dispatch = useDispatch();

  const currentFeedback = useSelector((state) =>
    getEntityByProfileID(state.QuickSetup.profileFeedback, id)
  );
  const educationFeedback = currentFeedback?.data?.education;

  const handleLike = (index, liked) => {
    const updatedEntity = educationFeedback.map((edu) =>
      edu.index === index
        ? Object.assign({}, edu, { ...edu, isOverallLiked: Boolean(liked) })
        : edu
    );

    dispatch(updateFeedbackEntity(id, "education", updatedEntity));
  };

  const getIsEducationLiked = (expIndex) => {
    const currentEducation = educationFeedback?.find(
      ({ index }) => index === expIndex
    );

    return currentEducation?.isOverallLiked;
  };

  return (
    <SectionWrapper isHideBorder name={"Education"}>
      {data.map(({ degreeName, schoolName, start, end }, index) => (
        <React.Fragment key={index}>
          {index < 1 && (
            <LikeableSection
              isLiked={getIsEducationLiked(index)}
              handleLike={(liked) => handleLike(index, liked)}
              key={index}
            >
              <span className="education">
                {degreeName} from {schoolName}
              </span>
              {start && end && (
                <p>
                  {start} to {end}
                </p>
              )}
            </LikeableSection>
          )}
        </React.Fragment>
      ))}
      {data?.length > 1 && (
        <p
          onClick={() => dispatch(setSelectedProfile(profile))}
          className="hyperlink"
        >
          See all
        </p>
      )}
    </SectionWrapper>
  );
}
