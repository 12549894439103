// import { truncate } from 'lodash'
import * as ActionTypes from '../../actions/hybridCampaign'
import {FETCH_CAMPAIGN_PENDING_STATUS, FETCH_CAMPAIGN_PENDING_STATUS_FAILURE, FETCH_CAMPAIGN_PENDING_STATUS_SUCCESS, FETCH_RECRUITER, FETCH_RECRUITER_FAILED, FETCH_RECRUITER_SUCCESS, SET_CAMPAIGN_PENDING_STATUS, SET_EXTENSION_INSTALLED, SET_LINKEDIN_LOGGED_IN} from '../../actions/outbox'
import {FETCH_ADVANCED_FILTER_SUCCESS} from '../../actions/prospectsView'

const initialState = { 
    emailState: false,
    emailProvider: '',
    tokenLoading: false,
    tokenLoaded: false,
    consentWindow: null,
    inMailCredits: 0,
    inMailCreditsLoading: false,
    inMailCreditsAvailable: false, 
    qluExtensionInstalled: false,  
    recruiterLoading: false,
    recruiterLoaded: false,
    recruiterInMailCredits: 0, 
    campaignType: "",
    channelsPendingLoading: false,
    channelsPendingLoaded: false,
    channelsPending: [],
    sent: 0,
    limit: 100,
    
}

const campaignOperations = (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_PENDING_STATUS: {
      return{
        ...state,
        channelsPending: action.payload,
      }
    }
    case FETCH_CAMPAIGN_PENDING_STATUS: {
      return{
        ...state,
        channelsPendingLoading: true,
        channelsPendingLoaded: false,
        channelsPending: [],
      }
    }
    case FETCH_CAMPAIGN_PENDING_STATUS_SUCCESS: {
      return{
        ...state,
        channelsPendingLoading: false,
        channelsPendingLoaded: true,
        channelsPending: action.payload,
      }
    }
    case FETCH_CAMPAIGN_PENDING_STATUS_FAILURE: {
      return{
        ...state,
        channelsPendingLoading: false,
        channelsPendingLoaded: false,
        channelsPending: [],
      }
    }
    case FETCH_RECRUITER: {
      return{
        ...state,
        recruiterLoading: true,
        recruiterLoaded: false,
        recruiterInMailCredits: 0, 
      }
    }
    case FETCH_RECRUITER_SUCCESS: {
      return{
        ...state,
        recruiterLoading: false,
        recruiterLoaded: true,
        recruiterInMailCredits: action.payload, 
      }
    }
    case FETCH_RECRUITER_FAILED: {
      return{
        ...state,
        recruiterLoading: false,
        recruiterLoaded: false,
        recruiterInMailCredits: 0, 
      }
    }
    case SET_LINKEDIN_LOGGED_IN: {
      return{
        ...state,
        linkedinLoggedIn: action.payload
      }
    }
    case SET_EXTENSION_INSTALLED: {
      return{
        ...state,
        qluExtensionInstalled: action.payload
      }
    }
    case FETCH_ADVANCED_FILTER_SUCCESS:
      return {
        ...state,
        campaignType: action.payload.data.provider
    }
    case ActionTypes.CLEAR_CONSENT_WINDOW: 
      return {
        ...state,
        consentWindow: null
    }

    case ActionTypes.SET_CONSENT_WINDOW:
      return {
        ...state,
        consentWindow: action.payload
    }
    case ActionTypes.REFRESH_TOKEN_VALIDITY:
      return {
        ...state,
        tokenLoading: true,
        tokenLoaded: false,
        emailState: false,
    }
    case ActionTypes.FETCH_EMAIL_PROVIDER: {
      return{
        ...state,
        emailProvider: action.payload
      }
    }

    case ActionTypes.REFRESH_TOKEN_VALIDITY_SUCCESS:
      return{
        ...state,
        tokenLoading: false,
        tokenLoaded: true,
        emailState: action.payload.data,
        emailProvider: action.payload.provider
    }
    case ActionTypes.REFRESH_TOKEN_VALIDITY_FAILURE:
      return{
        ...state,
        tokenLoading: false,
        tokenLoaded: false,
        emailState: false,
    }
    case ActionTypes.INMAIL_CREDITS_LOADING:
    return{
        ...state,
        inMailCreditsLoading: true,
        inMailCreditsAvailable: false
    }
    case ActionTypes.INMAIL_CREDITS_SUCCESS:
    return{
        ...state,
        inMailCreditsLoading: false,
        inMailCreditsAvailable: true,
        inMailCredits: action.payload
    }
    case ActionTypes.INMAIL_CREDITS_FAILIURE:
    return{
        ...state,
        inMailCreditsLoading: false,
        inMailCreditsAvailable: false,
    }
    case ActionTypes.INCREMENT_CONNECT_SENT:
      return { ...state, sent: state.sent + 1 }

    case ActionTypes.SET_CONNECT_LIMIT:
      return { ...state, limit: action.payload }

    case ActionTypes.INITIALIZE_CONNECT_SENT:
      return { ...state, sent: action.payload }

    default:
      return state
  }
}

export default campaignOperations
