import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import EditablePillButton from "../../../components/EditablePillButton/EditablePillButton";
import ImportanceButton from "../../../uiLibrary/ImportanceButton";
import CrossSvg from "../../../components/svgComponents/CrossSvg";
import TickSvg from "../../../components/svgComponents/TickSvg";
import {
  addJobEducation,
  changeJobEducation,
  removeJobEducation,
  setIsEdited,
  setJobSetupView,
} from "../../../actions/JobSetup";
import "./criteriaEducation.scss";
import "../../../components/_globalCSSClasses.scss";
import JobSetupStep10 from "../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep10";
import PlusSvg from "../../../uiLibrary/SVGs/PlusSvg";
import BigRoundButton from "../../../uiLibrary/BigRoundButton";
import DropdownComponent from "../../../uiLibrary/DropdownComponent";
import PillButton from "../../../uiLibrary/PillButton";
import LabelButton from "../../../uiLibrary/LabelButton";
import JobSetupAutoSuggest from "../JobSetupAutoSuggest";
import { Mixpanel } from "../../../utils/Mixpanel";

const EducationInput = ({
  isReview,
  value,
  onChange,
  importance,
  options,
  handleEducationToggle,
  onMouseEnter,
  onMouseLeave
}) => {

  return (
    <span>
      <DropdownComponent
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        selected={value}
        onSelect={isReview === '1' ? () => false : (value) => onChange({value, name: value, spans: []})}
        options={options}
      />
      <ImportanceButton
        importanceLevel={Object.keys(importance).filter(key=>importance[key])[0]}
        onClick={isReview === '1' ? () => false : imp=>onChange({importance:{
          Low: false,
          Medium: false,
          High: false,
          [imp]: true
        }})}
      />
      <BigRoundButton onClick={isReview === '1' ? () => false : ()=>handleEducationToggle()}>
        <CrossSvg width="16" height="16" />
      </BigRoundButton>
    </span>
  )
}


const CriteriaEducation = ({ educations, educationsData, cards, isReview, dispatch }) => {

  const selectedDegrees = Object.keys(educations).filter(
    (key) => educations[key].selected && educations[key].type==='degree'
  );
  const selectedDegreeMajors = Object.keys(educations).filter(
    (key) => educations[key].selected && educations[key].type==='degree_major'
  );

  const importanceMap = {
    '1': 'Low',
    '3': 'Medium',
    '5': 'High'
  }

  const options = [
    "Bachelors",
    "High school diploma",
    "Masters",
    "Doctor of philosophy",
    "Doctor of medicine",
    "Juris doctor",
  ].filter(opt => selectedDegrees.every(key=>opt.toLowerCase() !== educations[key].name.trim().toLowerCase()))

  const [myTimeout, setMyTimeout] = useState(null);
  const [addEducationMode, setaddEducationMode] = useState(false);
  const [addMajorMode, setaddMajorMode] = useState(false);
  const [addEducationEditor, setaddEducationEditor] = useState("");
  const [addEducationSelected, setaddEducationSelected] = useState(options[0] || "");




  useEffect(() => {
    Mixpanel.track("Education")
    educationsData.map((education) => {
      const importance = {
          Low: importanceMap[education.importance]==='Low',
          Medium: importanceMap[education.importance]==='Medium',
          High: importanceMap[education.importance]==='High',
      }
      dispatch(addJobEducation({
        ...education,
        selected: true,
        importance
      }))  
    })
  }, [])

  useEffect(() => {
      const selectedDegreesSyn = selectedDegrees.reduce((total, key)=>{
        if(Array.isArray(educations[key].synonym) && educations[key].synonym.length>0){
          return [
            ...total,
            {
              ...educations[key],
              synonym: educations[key].synonym.map(syn=>({
                ...syn,
                source:['jobDescription']
              })),
              spans:[],
              key,
              onClick: e=>handleEducationToggle(key, educations[key].type==='degree')
            }
          ]
        }
        return total
      }, [])
      const selectedValues = [...selectedDegreesSyn, ...selectedDegreeMajors.reduce((total, key)=>{
          const entities = [
              ...total,
              {
                  ...educations[key],
                  key,
                  onClick: e=>handleEducationToggle(key, educations[key].type==='degree')
              }
          ]
          return entities
      }, [])]
      dispatch(setJobSetupView({
          selectedValues
      }))
      
    }, [educations])
    
  const handleEducationChange = (value, key) => {
    dispatch(setIsEdited(true))
    dispatch(
      changeJobEducation({
        key,
        value,
      })
    );
  };

  const handleEducationBlur = (e, key) => {
    if (e.target.value === "") {
      handleEducationToggle(key);
    }
  };

  const handleEducationToggle = (key, flag=false) => {
    if(selectedDegrees.length===1 && flag){
      selectedDegreeMajors.map(id=>dispatch(removeJobEducation(id)))
    }
    dispatch(removeJobEducation(key));
    dispatch(setIsEdited(true))
  };

  const toggleAddEducation = (e) => {
    setaddEducationMode((prev) => !prev);
    setaddEducationSelected(options[0]);
  };

  const toggleAddMajor = (e) => {
    setaddMajorMode((prev) => !prev);
    setaddEducationEditor("");
  };

  const handleAddFunction = (name, type='degree') => {
    const payload = {
      type,
      selected: true,
      spans:[],
      importance: {
        Low: false,
        Medium: false,
        High: true,
      },
      name
    }
    if(type==='degree'){
      toggleAddEducation();
    }
    else{
      if(name===""){
        alert("Can not add empty major")
        return false
      }
      if(name.length<2){
        alert("Major is too small")
        return false
      }
      else if(selectedDegreeMajors.some(key=>educations[key].name.trim().toLowerCase()===name.trim().toLowerCase())){
        alert("Major is already selected")
        return false
      }
      toggleAddMajor()
    }
    dispatch(setIsEdited(true))
    dispatch(
      addJobEducation(payload)
    );
  };

  const onMouseEnter = (key) => {
    if (myTimeout) {
      clearTimeout(myTimeout);
      setMyTimeout(
        setTimeout(
          (e) =>
            dispatch(
              setJobSetupView({
                highlightValue: educations[key].name.toLowerCase().trim(),
              })
            ),
          300
        )
      );
    } else {
      setMyTimeout(
        setTimeout(
          (e) =>
            dispatch(
              setJobSetupView({
                highlightValue: educations[key].name.toLowerCase().trim(),
              })
            ),
          300
        )
      );
    }
  };
  const onMouseLeave = (key) => {
    clearTimeout(myTimeout);
    dispatch(
      setJobSetupView({
        highlightValue: "",
      })
    );
  };
  return (
    <div className="criteriaEducationContainer">
      <div>
        <JobSetupStep10 selected={true} />
        <h2>Education</h2>
      </div>
      <div className="criteriaEducationBody">
        {(selectedDegrees.length>0 || addEducationMode) &&
          <span>
            <h3>
              Degree
            </h3>
            {selectedDegrees.length>0 &&
            <h3>
              Importance
            </h3>}
          </span>}
        {selectedDegrees.map(key=>(
          <EducationInput
            key={key}
            value={educations[key].name}
            onChange={value=>handleEducationChange(value, key)}
            importance={educations[key].importance}
            options={options}
            handleEducationToggle={e=>handleEducationToggle(key, true)}
            isReview={isReview}
            onMouseEnter={e=>onMouseEnter(key)}
            onMouseLeave={e=>onMouseLeave(key)}
          />
        ))}
        {addEducationMode ? (
          <span>
            <DropdownComponent
              selected={"Select a degree"}
              onSelect={isReview === '1' ? () => false : (value) => handleAddFunction(value, 'degree')}
              options={options}
            />
            {/* <BigRoundButton onClick={isReview === '1' ? () => false : ()=>handleAddFunction(addEducationSelected, 'degree')}>
              <PlusSvg />
            </BigRoundButton> */}
            <BigRoundButton onClick={isReview === '1' ? () => false : toggleAddEducation}>
              <CrossSvg width="16" height="16" />
            </BigRoundButton>
          </span>
        ) : (
          isReview === '1' || options.length===0 ? '' : 
          <LabelButton onClick={toggleAddEducation}>
            <PlusSvg selected={true} />
            Add a degree
          </LabelButton>
        )}
      </div>
      {selectedDegrees.length>0 && 
        <div className="criteriaEducationBody">
          {(selectedDegrees.length>0 || addEducationMode) &&
            <span>
              <h3>
                Majors
              </h3>
            </span>}
            <span>
              {selectedDegreeMajors.map(key=>(
                <PillButton
                  key={key}
                  value={educations[key].name}
                  selected={true}
                  highlight={true}
                  onMouseEnter={e=>onMouseEnter(key)}
                  onMouseLeave={e=>onMouseLeave(key)}
                  // onClick={value=>handleEducationChange(value, key)}
                  onClick={e=>isReview === '1' ? () => false : handleEducationToggle(key)}
                >
                  {educations[key].name}
                </PillButton>
              ))}

            </span>
          {(addMajorMode ? (
            <span>
              {/* <input
                value={addEducationEditor}
                onChange={e=>setaddEducationEditor(e.target.value)}
              /> */}
              
              <JobSetupAutoSuggest
                apiType='major'
                filter={d=>!(selectedDegreeMajors.map(key=>educations[key]?.name.trim().toLowerCase()).includes(d.trim().toLowerCase()))}
                inputValue={addEducationEditor}
                handleChangeInput={isReview === '1' ? () => false : e=>setaddEducationEditor(e.target.value)}
                handleSuggestionSelected={isReview === '1' ? () => false : value=>handleAddFunction(value, 'degree_major')}
              />
              {/* <BigRoundButton onClick={isReview === '1' ? () => false : ()=>handleAddFunction('degree_major')}>
                <PlusSvg />
              </BigRoundButton> */}
              <BigRoundButton onClick={isReview === '1' ? () => false : toggleAddMajor}>
                <CrossSvg width="16" height="16" />
              </BigRoundButton>
            </span>
          ) : (
            isReview === '1' ? '' : 
            <LabelButton onClick={toggleAddMajor}>
              <PlusSvg selected={true} />
              Add a major
            </LabelButton>
          ))}
        </div>}
    </div>
  );
};

export default connect((state) => ({
  educations: state.jobSetup.jobCriteria.educations,
  educationsData: state.jobSetup.jobSetup?.jobSetup?.education || [],
  cards: state.jobSetup.viewController.cards,
  isReview: state.jobSetup?.jobSetup?.isReview
}))(CriteriaEducation);
