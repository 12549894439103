import React from 'react'
import '../style.scss'
const InfoSvg = ({
    Recommended=false,
    width="24",
    height="24"
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={`qluSvgStroke${Recommended ? 'Recommended' : ''}`} d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path className={`qluSvgStroke${Recommended ? 'Recommended' : ''}`} d="M12 16V12"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path className={`qluSvgStroke${Recommended ? 'Recommended' : ''}`} d="M12 8H12.01" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default InfoSvg
