import React from 'react'
import '../style.scss'
const JobSetupStep05 = ({
    strokeWidth='2',
    width='24',
    height='24',
    selected=false,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}  
                x="3" y="5" width="18" height="18" rx="2"
                strokeWidth={strokeWidth}/>
            <path
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}  
                d="M16.545 19.4547V18.2426C16.545 17.5997 16.2896 16.983 15.835 16.5284C15.3803 16.0738 14.7637 15.8184 14.1208 15.8184H9.87834C9.23539 15.8184 8.61878 16.0738 8.16415 16.5284C7.70951 16.983 7.4541 17.5997 7.4541 18.2426V19.4547"
                strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`} 
                d="M15 5V4C15 3.46957 14.8314 2.96086 14.5314 2.58579C14.2313 2.21071 13.8243 2 13.4 2H10.6C10.1757 2 9.76869 2.21071 9.46863 2.58579C9.16857 2.96086 9 3.46957 9 4V5"
                strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`} 
                d="M11.9993 13.3934C13.3381 13.3934 14.4235 12.308 14.4235 10.9692C14.4235 9.63029 13.3381 8.54492 11.9993 8.54492C10.6604 8.54492 9.57501 9.63029 9.57501 10.9692C9.57501 12.308 10.6604 13.3934 11.9993 13.3934Z"
                strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    )
}

export default JobSetupStep05
