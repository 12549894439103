import React from "react";

export const TribeFAQ1 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>My tribe consists of your tribe members</li>
          <li>
            Tribe members are those who you want to collaborate with when hiring
            under a job
          </li>
          <li>
            They can see all the prospects, comment on their profiles, reach out
            to them and have some other privileges if you allow them to do so
          </li>
        </ul>
      </p>
    </div>
  );
};

export const TribeFAQ2 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>You type their name in the search box</li>
          <li>Click on their name when it appears in the drop down</li>
          <li>
            If it's for the first time, enter their email with which they have
            signed in on Qlu
          </li>
          <li>Click on “Invite xyz@abc.com to your tribe”</li>
        </ul>
      </p>
    </div>
  );
};

export const TribeFAQ3 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Click on the name of the tribe member</li>
          <li>Two privileges will appear</li>
          <li>Click on the toggle to activate them</li>
        </ul>
      </p>
    </div>
  );
};

export const TribeFAQ4 = () => {
  return (
    <div>
      <p>Yes you can by doing the following:
        <ul>
          <li>
            On the result page’s top there is a section “My tribe” under title
          </li>
          <li>Click on it</li>
          <li>It will show you all the tribe members that you have added</li>
          <li>Click on the three dots in front of the name of the prospect</li>
          <li>It will say “Remove tribe member”</li>
          <li>Click on it and the tribe member will be removed</li>
        </ul>
      </p>
    </div>
  );
};

export const TribeFAQ5 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            Yes you can later add or remove tribe members according to your
            preference
          </li>
        </ul>
      </p>
    </div>
  );
};

export const TribeFAQ6 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            On the result page’s there is a section “My tribe” under title of
            the job created
          </li>
          <li>Click on it</li>
          <li>It will show you all the tribe members that you have added</li>
          <li>Click on “Permissions” it is under the tribe member’s name</li>
          <li>You can now turn any permissions on or off as you would like</li>
          <li>Orange means on and gray means off</li>
        </ul>
      </p>
    </div>
  );
};
