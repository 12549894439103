import React from 'react'

const EyeFilledSvg = ({
    className='',
    width='24', 
    height='24',
    selected=false,
    style={}
}) => {
    return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path
                
                className={`qluSvgFill${selected ? 'Selected' : ''}`}  
                d="M0.667969 8.00033C0.667969 8.00033 3.33464 2.66699 8.0013 2.66699C12.668 2.66699 15.3346 8.00033 15.3346 8.00033C15.3346 8.00033 12.668 13.3337 8.0013 13.3337C3.33464 13.3337 0.667969 8.00033 0.667969 8.00033Z" 
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                className={`qluSvgFill${!selected ? 'Selected' : ''}`}  
                // style={{fill: 'transparent'}}
                d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" 
                strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width={width} height={height} />
            </clipPath>
        </defs> 
    </svg>
    
    )
}

export default EyeFilledSvg
