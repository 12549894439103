import React, { useEffect } from "react";
import "./AddDetails.scss";
import { connect } from "react-redux";
import {
  setMoreProspectCurrentTab,
  setMoreProspectSkillsArray,
  setMoreProspectSpecialitiesArray,
} from "../../../actions/prospectsView";
import { Mixpanel } from "../../../utils/Mixpanel";

function AddDetails({
  priorityArray,
  currentTab,
  skillsFromJob,
  company_specialityFromJob,
  skills,
  specialities,
  dispatch,
}) {
  useEffect(() => {
    if (priorityArray.length) {
      dispatch(setMoreProspectCurrentTab(priorityArray[0]));
    }
  }, []);
  const [skillsArray, setSkillsArray] = React.useState(skills);
  const [specialitiesArray, setSpecialitiesArray] = React.useState(
    specialities
  );
  const currentContent = (currentTab) => {
    switch (currentTab) {
      case "skills":
        return (
          <>
            <div className="content">
              {skillsFromJob?.map((item, index) => {
                let exist = skillsArray?.filter((e) => e?.name == item?.name);
                return (
                  <div
                    className={`step ${exist?.length > 0 ? "active" : ""}`}
                    key={item?.id}
                    onClick={() => {
                      Mixpanel.track(`add details - find more prospects`)
                      if (exist?.length > 0) {
                        setSkillsArray((prev) => {
                          const prevItems = [...prev];
                          const index = prevItems.findIndex(
                            (e) => e?.name == item?.name
                          );
                          prevItems.splice(index, 1);
                          dispatch(setMoreProspectSkillsArray(prevItems));
                          return prevItems;
                        });
                      } else {
                        setSkillsArray((prev) => {
                          dispatch(setMoreProspectSkillsArray([...prev, item]));
                          // dispatch(setPriorityArray([...prev, item]));
                          return [...prev, item];
                        });
                      }
                    }}
                  >
                    {exist?.length > 0 ? (
                      <div className="step-item-index">
                        {skillsArray.indexOf(item) + 1}
                      </div>
                    ) : (
                      <div className="step-item-icon">
                        <div className="icon">{item?.icon}</div>
                      </div>
                    )}

                    <div
                      className={`step-item-name ${
                        exist?.length > 0 ? "active-name" : ""
                      }`}
                    >
                      {item?.name}
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={`clear  ${skillsArray.length > 0 ? "active" : ""}`}
              onClick={() => {
                if (skillsArray.length) {
                  setSkillsArray([]);
                  dispatch(setMoreProspectSkillsArray([]));
                }
              }}
            >
              clear
            </div>
          </>
        );
      case "business":
        return (
          <>
            <div className="content">
              {company_specialityFromJob?.map((item, index) => {
                let exist = specialitiesArray.filter(
                  (e) => e?.name == item?.name
                );
                let { name } = item;
                return (
                  <div
                    className={`step ${exist?.length > 0 ? "active" : ""}`}
                    key={index}
                    onClick={() => {
                      Mixpanel.track(`add details - find more prospects`)
                      if (exist?.length > 0) {
                        setSpecialitiesArray((prev) => {
                          const prevItems = [...prev];
                          const index = prevItems.findIndex(
                            (e) => e?.name == item?.name
                          );
                          prevItems.splice(index, 1);
                          dispatch(setMoreProspectSpecialitiesArray(prevItems));
                          return prevItems;
                        });
                      } else {
                        setSpecialitiesArray((prev) => {
                          dispatch(
                            setMoreProspectSpecialitiesArray([...prev, item])
                          );

                          return [...prev, item];
                        });
                      }
                    }}
                  >
                    {exist?.length > 0 && (
                      <div className="step-item-index">
                        {specialitiesArray?.indexOf(item) + 1}
                      </div>
                    )}

                    <div
                      className={`step-item-name ${
                        exist?.length > 0 ? "active-name" : ""
                      }`}
                    >
                      {name}
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              className={`clear  ${
                specialitiesArray?.length > 0 ? "active" : ""
              }`}
              onClick={() => {
                if (specialitiesArray?.length) {
                  setSpecialitiesArray([]);
                  dispatch(setMoreProspectSpecialitiesArray([]));
                }
              }}
            >
              clear
            </div>
          </>
        );
      case "company size":
        return (
          <div className="normalContent">
            <div>Same as size of hiring and liked companies.</div>
          </div>
        );
      case "company score":
        return (
          <div className="normalContent">
            <div>In order of high to low.</div>
          </div>
        );
      default:
        return (
          <div className="normalContent">Please select your priorities.</div>
        );
    }
  };

  return (
    <div className="add-details">
      <div className="header">
        <div className="title">Find more prospects</div>
        <div className="sub-title">
          Add priority within the attributes here, by clicking in order of
          importance
        </div>
      </div>
      <div className="tabs">
        {priorityArray?.map((item, index) => {
          return (
            <>
              <div
                className={`tab ${currentTab == item ? "active" : ""}`}
                key={item?.id}
                onClick={() =>{dispatch(setMoreProspectCurrentTab(item))}}
              >
                <span className="tab-index">{index + 1}. </span>
                <span className="tab-title">
                  {" "}
                  {item?.name == "company size" ? "employee count" : item?.name}
                </span>
                {currentTab === item && <div className="horizontalLine"></div>}
              </div>
            </>
          );
        })}
      </div>
      <div className="tabData">{currentContent(currentTab?.name)}</div>
    </div>
  );
}

export default connect((state) => ({
  priorityArray: state.prospectsView.findMoreProspect.priorityArray,
  currentTab: state.prospectsView.findMoreProspect.currentTab,
  skillsFromJob: state.prospectsView.candidates.job.jobArray.skills,
  company_specialityFromJob:
    state.prospectsView.candidates.job.jobArray.company_speciality,
  skills: state.prospectsView.findMoreProspect.skills,
  specialities: state.prospectsView.findMoreProspect.specialities,
}))(AddDetails);
