import * as ActionTypes from '../../../actions/landingPage/jobs'

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: []
}

export const tribeJobs = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TRIBE_JOBS:
      return { ...state, isLoading: true }

    case ActionTypes.FETCH_TRIBE_JOBS_SUCCESS:
      return { ...state, isLoading: false, isDataAvailable: action.payload.data.count > 0, data: action.payload.data }

    case ActionTypes.FETCH_TRIBE_JOBS_FAILURE:
      return initialState

    default:
      return state
  }
}

