import React from 'react'
import PillButton from '../../../uiLibrary/PillButton'
import "./style.scss"
const FindMoreProspectsCount = ({
    batch,
    setBatch
}) => {

    const countOptions = [
        25,
        50,
        100,
        150,
        200
    ]

    return (
        <div
            className='findMoreProspectsCount'
        >
            {countOptions.map(opt=>(
                <PillButton
                    key={opt}
                    selected={batch===opt}
                    highlight={batch===opt}
                    onClick={e=>setBatch(opt)}
                >
                    {opt}
                </PillButton>
            ))}
        </div>
    )
}

export default FindMoreProspectsCount