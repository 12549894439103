import React, { useState } from "react";
import { connect } from "react-redux";
import {
  clearCalibrationData,
  fetchCalibrationProfilesV2,
  findNextCalibrationCycle,
  initJobSetupView,
  sendToNer,
  updateViewPort,
  setJobSetupView,
  updateFeedbackEntity
} from "../../actions/QuickSetup";
import ActionBar from "../../uiLibrary/ActionBar";
import Calibration from "./Calibration";
import QuickNerSetup from "./QuickNerSetup";
import CountDown from "./components/Loader";
import "./style.scss";
import { useParams } from "react-router-dom";
import {
  getCalibrationCycle,
  getCalibrationProfilesIsFetching,
  getShouldContinue,
  getTimerValueinSeconds,
} from "../../reducers/QuickSetup/calibrationProfiles";
import ViewLikedProfiles from "./ViewLikedProfiles";
import Loader from "./components/Loader";
import Loading from "../../uiLibrary/Loading";
import { useEffect } from "react";
import SocialHeartButton from '../../uiLibrary/SocialHeartButton'
import SocialMaybeButton from '../../uiLibrary/SocialMaybeButton'
import SocialRejectButton from '../../uiLibrary/SocialRejectButton'
import { manualApiCall } from "../../utils";
import { Mixpanel } from "../../utils/Mixpanel";
const QuickSetup = ({
  dispatch,
  viewController,
  descriptionValue,
  titleValue,
  selectedLocations,
  isJobSetupLoading,
  companyHiringFor,
  calibrationCycle,
  timer,
  profiles,
  shouldContinue,
  isFetching,
  history,
}) => {
  const [isCancelling, setIsCancelling] = useState(false)
  const [isReviewing, setIsReviewing] = useState("")
  const { view, currentTab } = viewController;
  const { id } = useParams();

  const minutes = Math.floor(timer / 60);
  const seconds = timer - minutes * 60;

  useEffect(() => {
    return () => {
      dispatch(initJobSetupView())
    }
  }, [])


  useEffect(() => {
    const viewMap = {
      setup: 'Job Setup',
      calibration: 'Calibration Screen'
    }
    if(Object.keys(viewMap).includes(view)){
      Mixpanel.track(`Quick Search - ${viewMap[view]}`)
    }
  }, [view])

  const getIsDisabled = (currentView) => {
    switch (currentView) {
      case "setup":
        return (
          Object.keys(companyHiringFor).length === 0 ||
          isJobSetupLoading ||
          descriptionValue.trim() === "" ||
          selectedLocations.length === 0 ||
          titleValue.trim() === ""
        );
        break;
      case "count-down":
        return true;
        break;
      case "calibration":
        return !shouldContinue;
      case "liked-profiles":
        return true;
      default:
        break;
    }
  };

  const onSecondaryClick = async () => {
    // await manualApiCall()
    if (id) {
      setIsCancelling(true)
      await manualApiCall(`/api/v2/auth/jobs/${id}`, { method: 'DELETE' })
      setIsCancelling(false)
    }
    dispatch(updateViewPort({ view: "setup" }));
    dispatch(clearCalibrationData());
    history.push("/");
  };

  const onPrimaryClick = async () => {
    switch (view) {
      case "setup":
        dispatch(setJobSetupView({ currentTab: 0 }))
        const callBack = (id) => {
          const isDeep = false;
          history.push(`/QuickSetup/${id}`, { id });
          dispatch(fetchCalibrationProfilesV2(id, isDeep, history));
        };
        dispatch(
          sendToNer((id) => callBack(id), {
            view: "calibration",
          })
        );
        break;
      case "calibration":
        const isDeep = true;
        dispatch(fetchCalibrationProfilesV2(id, isDeep, history));
        break;

      default:
        break;
    }
  };

  const getButtonLabel = () => {
    if (view === "calibration") {
      return "Next";
    }

    return "Next";
  };

  const handleFeedBack = (review) => {
    if (isFetching || isReviewing!=="") {
      return false
    }
    
    setIsReviewing(review)
    setTimeout(()=>{
      const feedback = review === 'isLiked' ? { count: 3 } : true
  
      dispatch(updateFeedbackEntity(profiles[currentTab].id, review, feedback))
  
      if (currentTab < profiles.length - 1) {
        dispatch(setJobSetupView({ currentTab: currentTab + 1 }))
      }
      else {
        const isDeep = true;
        dispatch(fetchCalibrationProfilesV2(id, isDeep, history));
      }
      setIsReviewing("")
    }, 800)

  }

  return (
    <div className="QuickSetupContainer">
      {(() => {
        switch (view) {
          case "setup":
            return isFetching ? <Loading /> : <QuickNerSetup />;

          case "calibration":
            return <Calibration currentTab={currentTab} />;

          case "count-down":
            return (
              <CountDown
                initialMinute={minutes}
                initialSeconds={seconds}
                onTimerStop={() => {
                  dispatch(updateViewPort({ view: "calibration" }));
                  return dispatch(
                    fetchCalibrationProfilesV2(id, true, history)
                  );
                }}
              />
            );

          case "liked-profiles":
            return <ViewLikedProfiles />;
          default:
            return <h1>Default</h1>;
        }
      })()}
      <ActionBar
        PrimaryLabel={getButtonLabel()}
        onPrimaryClick={onPrimaryClick}
        SecondaryLabel={isCancelling ? "..." : "Cancel"}
        onSecondaryClick={onSecondaryClick}
        primaryDisabled={getIsDisabled(view)}
        secondaryDisabled={isJobSetupLoading || isCancelling}
        onPrimaryLoading={isJobSetupLoading || isCancelling}
        primaryButton={
          ((isFetching || isCancelling) && view === 'calibration') ? <div />
            : view === 'calibration' ? <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px'
              }}
            >
              <SocialHeartButton
                style={{
                  width: '96px',
                  height: '48px'
                }}
                saved={isReviewing==="isLiked"}
                onClick={e => handleFeedBack("isLiked")}
              />
              <SocialMaybeButton
                style={{
                  width: '96px',
                  height: '48px'
                }}
                maybe={isReviewing==="isMaybe"}
                onClick={e => handleFeedBack("isMaybe")}
              />
              <SocialRejectButton
                style={{
                  width: '96px',
                  height: '48px'
                }}
                archived={isReviewing==="isDisliked"}
                onClick={e => handleFeedBack("isDisliked")}
              />
            </div> : false
        }
      />
    </div>
  );
};

export default connect((state) => ({
  viewController: state.QuickSetup.viewController,
  descriptionValue: state.jobSetup.jobDetails.description,
  titleValue: state.jobSetup.jobDetails.title,
  selectedLocations: state.jobSetup.jobLocation.selectedLocations,
  isJobSetupLoading: state.jobSetup.jobSetup.isLoading,
  similarCompanies: state.jobSetup.companyDetails.similarCompanies,
  companyHiringFor: state.jobSetup.companyDetails.companyHiringFor,
  calibrationCycle: getCalibrationCycle(state.QuickSetup.calibrationProfiles),
  timer: getTimerValueinSeconds(state.QuickSetup.calibrationProfiles),
  shouldContinue: getShouldContinue(state.QuickSetup.calibrationProfiles),
  profiles: state.QuickSetup.calibrationProfiles.data,
  isFetching: getCalibrationProfilesIsFetching(
    state.QuickSetup.calibrationProfiles
  ),
}))(QuickSetup);
