import * as ActionTypes from '../../actions/QuickSetup'

const initialState = {
    suggestions: [],
    location: '',
    selectedLocations: [],
    isLoading: false,
    isLoaded: false,
}

const jobLocation = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_JOB_LOCATION: {
        return initialState
    } 
    case ActionTypes.SET_JOB_LOCATION: {
        return {
            ...state,
            location: action.payload
        }
    } 
    case ActionTypes.ADD_SELECTED_JOB_LOCATION: {
      return {
          ...state,
          selectedLocations: [...state.selectedLocations, action.payload],
      }
    } 
    case ActionTypes.REMOVE_SELECTED_JOB_LOCATION: {
      return {
          ...state,
          selectedLocations: state.selectedLocations.filter(location => (
            typeof location === 'string' ?
              location!==action.payload :
              location?.name!==action.payload
            )
          )
      }
    } 
    case ActionTypes.CLEAR_JOB_LOCATION_SUGGESTION: {
        return {
            ...state,
            suggestions: []
        }
    } 
    case ActionTypes.FETCH_JOB_LOCATIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        suggestions: action.payload
      }
    }
    case ActionTypes.FETCH_JOB_LOCATIONS: {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        }
    }
    case ActionTypes.FETCH_JOB_LOCATIONS_FAILURE: {
        return {
          ...state,
          isLoading: false,
          isLoaded: false,
        }
    }
    default:
      return state
  }
}

export default jobLocation
