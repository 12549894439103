import * as ActionTypes from '../../actions/recruiter'

const initialState = {
  email: '',
  lastName: '',
  firstName: '',
  password: '',
  matchPassword: '',
  isConfirmed: ''
}

export const invite = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_INVITED_USER_DETAILS:
      return {
        ...state,
        email: action.payload.email
      }

    case ActionTypes.CONFIRM_INVITED_USER_DETAILS: 
      return {
        ...state
      }

    case ActionTypes.CONFIRMED_USER: 
    return {
      ...state,
      isConfirmed: true
    }
    default:
      return state
  }
}