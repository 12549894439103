import React, { useCallback, useEffect, useState } from "react";
import "react-input-range/lib/css/index.css";
import "react-toggle/style.css";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody
} from "reactstrap";

import ScoringFilterSection from "./ScoringFilterSection";
// import FilterTemplate from "./FilterTemplate"

import "./advanceFilterSection.scss";

import { uniq, uniqBy } from "lodash";
import qs from "query-string";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  clearAdvancedFilters,
  clearProspects,
  clearScoringFilters, setBooleanFilters, setCompanySizeFilter, setCurrentCompanyFilters, setCurrentIndustryFilters, setCurrentTitleFilters, setEducationFilters, setEthnicityFilter, setFirstNamesFilter,
  setLastNamesFilter, setLocationFilters, setLocationStatus, setPastCompanyFilters, setPastIndustryFilters, setPastLocationFilters, setPastTitleFilters, setRelevantExperienceFilter, setSchoolFilters, setSkillsFilters, setSkillsStatus, setSpecialtiesFilters, setSpecialtiesStatus, setSpokenLanguagesFilter, setStrictSkills,
  setStrictSpecialties, setTotalExperienceFilter, setYearCurrentCompanyFilter, setYearCurrentCompanyToggle, setYearCurrentPositionFilter,
  setYearCurrentPositionToggle,
  setYearOfGraduationFilter,
  setYearOfGraduationToggle, toggleAdvFiters, toggleRelevantExperienceFilter, toggleStrictCurrentTitle, toggleStrictEducation, toggleStrictLocation, toggleStrictPastTitle, toggleTotalExperienceFilter
} from "../../../actions/prospectsView";

import { debounce, omit } from "lodash";
import { PER_PAGE_COUNT } from "../../../constants/api";
import { commentNotificationList } from "../../../constants/notification";
import { callLiApi, manualApiCall } from "../../../utils";
import {
  advFilterList,
  getSuggestionValue,
  renderSuggestion,
  scrFilterList
} from "./AdvanceFilterConstants";
import FilterByBoolean from "./FilterByBoolean";
import FilterSection from "./filterSection";

const AdvanceFilterSection = ({
  Show,
  setShow,
  history: { push, replace },
  location: { pathname, search },
  dispatch,
  setSelectAllProspectsFlag,
  FilterCount,
  setFilterCount = () => false,
  // props
  languages,
  firstNames,
  lastNames,
  year_of_graduations,
  locations = [],
  pastLocations = [],
  skills,
  specialties,
  industries,
  educations,
  companies,
  schools,
  titles,
  templates,
  ActiveTemplate,
  skills_synonyms,
  titles_synonyms,
  educations_synonyms,
  specialties_synonyms,
  AdvFilters,
  activeTab,
  ScoringFilters,
  titleFlag,
  skillFlag,
  industryFlag,
  experienceFlag,
  educationFlag,
  specialtyFlag,
  adminFlag,
  filters,
  rawBooleanTerm,
  priorityArray = false,
}) => {
  const [schoolsTextBox, setSchoolsTextBox] = useState("");
  const [schoolsSuggestions, setSchoolsSuggestions] = useState([]);
  const [skillsTextBox, setSkillsTextBox] = useState("");
  const [specialtiesTextBox, setSpecialtiesTextBox] = useState("");
  const [titleCurrentTextBox, setCurrentTitleTextBox] = useState("");
  const [titlePastTextBox, setPastTitleTextBox] = useState("");
  const [companyCurrentTextBox, setCurrentCompanyTextBox] = useState("");
  const [companyPastTextBox, setPastCompanyTextBox] = useState("");
  const [LocationTextBox, setLocationTextBox] = useState("");
  const [pastLocationTextBox, setPastLocationTextBox] = useState("");
  const [EducationTextBox, setEducationTextBox] = useState("");
  const [EthnicityTextBox, setEthnicityTextBox] = useState("")
  const [languageTextBox, setLanguageTextBox] = useState("");
  const [firstNameTextBox, setFirstNameTextBox] = useState("");
  const [lastNameTextBox, setLastNameTextBox] = useState("");
  const [yearOfGraduationTextBox, setYearOfGraduationTextBox] = useState("");
  const [skillsSuggestions, setSkillsSuggestions] = useState([]);
  const [specialtiesSuggestions, setSpecialtiesSuggestions] = useState([]);
  const [CurrentTitleSuggestions, setCurrentTitleSuggestions] = useState([]);
  const [PastTitleSuggestions, setPastTitleSuggestions] = useState([]);
  const [CurrentCompanySuggestions, setCurrentCompanySuggestions] = useState(
    []
  );
  const [PastCompanySuggestions, setPastCompanySuggestions] = useState([]);
  const [EducationSuggestions, setEducationSuggestions] = useState([]);
  const [EthnicitySuggestions, setEthnicitySuggestions] = useState([]);
  const [LocationSuggestions, setLocationSuggestions] = useState([]);
  const [pastLocationSuggestions, setPastLocationSuggestions] = useState([]);
  const [languagesSuggestions, setLanguagesSuggestions] = useState([]);
  const [firstNamesSuggestions, setFirstNamesSuggestions] = useState([]);
  const [lastNamesSuggestions, setLastNamesSuggestions] = useState([]);
  const [
    yearOfGraduationSuggestions,
    setYearOfGraduationSuggestions,
  ] = useState([]);
  const [showBooleanFilters, setShowBooleanFilters] = useState(false);

  const ethnicities = ['Female', 'Hispanic', 'Black', 'Asian', 'South-Asian']
  useEffect(() => {
    dispatch(
      setLocationFilters(
        locations.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setPastLocationFilters(
        pastLocations.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setSkillsFilters(
        skills.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(setSchoolFilters(schools.map((itm) => ({ name: itm, value: 0 }))));
    dispatch(
      setEducationFilters(
        educations.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setCurrentTitleFilters(
        titles.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setEthnicityFilter(
        ethnicities.slice(0, 5).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setPastTitleFilters(
        titles.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setCurrentCompanyFilters(
        companies.map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setPastCompanyFilters(companies.map((itm) => ({ name: itm, value: 0 })))
    );
    dispatch(
      setCurrentIndustryFilters(
        industries.map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setPastIndustryFilters(industries.map((itm) => ({ name: itm, value: 0 })))
    );
    dispatch(
      setSpecialtiesFilters(
        specialties.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setSpokenLanguagesFilter(
        languages.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setFirstNamesFilter(
        firstNames.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
    dispatch(
      setLastNamesFilter(
        lastNames.slice(0, 4).map((itm) => ({ name: itm, value: 0 }))
      )
    );
  }, []);

  const getTitleSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : [
        value,
        ...titles.filter(
          (itm) =>
            itm.trim().toLowerCase().startsWith(inputValue) &&
            itm.trim().toLowerCase() !== inputValue
        ),
      ];
  };

  const getSkillsSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : skills_synonyms
        .filter((itm) => itm.name.trim().toLowerCase().startsWith(inputValue))
        .reduce(
          (total, { name, synonym }) => [...total, name, ...synonym],
          []
        );
  };

  const getSpecialtiesSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : specialties.filter((itm) =>
        itm.trim().toLowerCase().startsWith(inputValue)
      );
  };

  const getLocationSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : [
        value,
        ...locations.filter(
          (itm) =>
            itm.trim().toLowerCase().startsWith(inputValue) &&
            itm.trim().toLowerCase() !== inputValue
        ),
      ];
  };

  const getPastLocationSuggestions = (value) => {

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    console.log("getting past locations", inputLength === 0
      ? []
      : [
        value,
        ...pastLocations.filter(
          (itm) =>
            itm.trim().toLowerCase().startsWith(inputValue) &&
            itm.trim().toLowerCase() !== inputValue
        ),
      ])

    return inputLength === 0
      ? []
      : [
        value,
        ...pastLocations.filter(
          (itm) =>
            itm.trim().toLowerCase().startsWith(inputValue) &&
            itm.trim().toLowerCase() !== inputValue
        ),
      ];
  };

  const getLanguagesSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : [
        value,
        ...languages.filter(
          (itm) =>
            itm.trim().toLowerCase().startsWith(inputValue) &&
            itm.trim().toLowerCase() !== inputValue
        ),
      ];
  };
  const onLanguagesSuggestionsFetchRequested = ({ value }) => {
    setLanguagesSuggestions(getLanguagesSuggestions(value));
  };

  const getfirstNamesSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : [
        value,
        ...firstNames.filter(
          (itm) =>
            itm.trim().toLowerCase().startsWith(inputValue) &&
            itm.trim().toLowerCase() !== inputValue
        ),
      ];
  };
  const onFirstNamesSuggestionsFetchRequested = ({ value }) => {
    setFirstNamesSuggestions(getfirstNamesSuggestions(value));
  };

  const onLastNamesSuggestionsFetchRequested = ({ value }) => {
    setLastNamesSuggestions(getlastNamesSuggestions(value));
  };
  const onYearOfGraduationSuggestionsFetchRequested = ({ value }) => {
    setYearOfGraduationSuggestions(getYearOfGraduationSuggestions(value));
  };

  const getYearOfGraduationSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : year_of_graduations.filter(
        (itm) =>
          itm.trim().toLowerCase().startsWith(inputValue) &&
          itm.trim().toLowerCase() !== inputValue
      );
  };

  const getlastNamesSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : [
        value,
        ...lastNames.filter(
          (itm) =>
            itm.trim().toLowerCase().startsWith(inputValue) &&
            itm.trim().toLowerCase() !== inputValue
        ),
      ];
  };

  const onlastNamesSuggestionsFetchRequested = ({ value }) => {
    setLastNamesSuggestions(getlastNamesSuggestions(value));
  };

  const getEducationSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : [
        value,
        ...educations.filter(
          (itm) =>
            itm.trim().toLowerCase().startsWith(inputValue) &&
            itm.trim().toLowerCase() !== inputValue
        ),
      ];
  };

  const getEthnicitySuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : [
        value,
        ...ethnicities.filter(
          (itm) =>
            itm.trim().toLowerCase().startsWith(inputValue) &&
            itm.trim().toLowerCase() !== inputValue
        ),
      ];
  };

  const onSkillsSuggestionsFetchRequested = ({ value }) => {
    setSkillsSuggestions(getSkillsSuggestions(value));
  };
  const onSkillsSuggestionsClearRequested = () => setSkillsSuggestions([]);

  const onSkillsSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setSkillsFilters([
        // ...skills_synonyms.filter(({name})=>suggestionValue===name).map(({name})=>({name, value: 1})),
        // ...skills_synonyms.filter(itm=>itm.name===suggestionValue || itm.synonym.includes(suggestionValue)).reduce((total, {name, synonym})=>[...total, {name, value: 1}, ...synonym.map(name=>({name, value: 1}))], []),
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.skills.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    // dispatch(
    //   setSkillsFilters([
    //     ...skills_synonyms
    //       .filter(
    //         (itm) =>
    //           itm.name.trim().toLowerCase() ===
    //             suggestionValue.trim().toLowerCase() ||
    //           itm.synonym
    //             .map((syn) => syn.trim().toLowerCase())
    //             .includes(suggestionValue.trim().toLowerCase())
    //       )
    //       .reduce(
    //         (total, { name, synonym }) => [
    //           ...total,
    //           { name, value: 1 },
    //           ...synonym.map((name) => ({ name, value: 0 })),
    //         ],
    //         []
    //       ),
    //     ...AdvFilters.skills.filter((itm) => itm.name !== suggestionValue),
    //   ])
    // );

    setSkillsTextBox("");
  };

  const inputSkillsProps = {
    placeholder: `Search for a ${"Skill"}`,
    value: skillsTextBox,
    onChange: (e, { newValue }) => setSkillsTextBox(newValue),
  };

  // SPECIALTY

  const onSpecialtiesSuggestionsFetchRequested = ({ value }) =>
    setSpecialtiesSuggestions(getSpecialtiesSuggestions(value));
  const onSpecialtiesSuggestionsClearRequested = () =>
    setSpecialtiesSuggestions([]);

  const onSpecialtiesSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setSpecialtiesFilters([
        // ...specialties_synonyms.filter(itm=>itm.name===suggestionValue || itm.synonym.includes(suggestionValue)).reduce((total, {name, synonym})=>[...total, {name, value: 1}, ...synonym.map(name=>({name, value: 1}))], []),
        // ...AdvFilters.specialties.filter((itm) => itm.name !== suggestionValue),
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.specialties.filter((itm) => itm.name !== suggestionValue),
      ])
    );

    // dispatch(
    //   setSpecialtiesFilters([
    //     ...specialties_synonyms
    //       .filter(
    //         (itm) =>
    //           itm.name.trim().toLowerCase() ===
    //             suggestionValue.trim().toLowerCase() ||
    //           itm.synonym
    //             .map((syn) => syn.trim().toLowerCase())
    //             .includes(suggestionValue.trim().toLowerCase())
    //       )
    //       .reduce(
    //         (total, { name, synonym }) => [
    //           ...total,
    //           { name, value: 1 },
    //           ...synonym.map((name) => ({ name, value: 0 })),
    //         ],
    //         []
    //       ),
    //     // {
    //     //   name: suggestionValue,
    //     //   value: 1,
    //     // },
    //     ...AdvFilters.specialties.filter((itm) => itm.name !== suggestionValue),
    //   ])
    // );

    setSpecialtiesTextBox("");
  };
  const inputSpecialtiesProps = {
    placeholder: `Search for a ${"Specialties"}`,
    value: specialtiesTextBox,
    onChange: (e, { newValue }) => setSpecialtiesTextBox(newValue),
  };

  const onLocationSuggestionsFetchRequested = ({ value }) =>
    setLocationSuggestions(getLocationSuggestions(value));

  const onLocationSuggestionsClearRequested = () => setLocationSuggestions([]);

  const onPastLocationSuggestionsFetchRequested = ({ value }) => {
    const pastLocationsFromSearch = getPastLocationSuggestions(value);
    setLocationSuggestions(pastLocationsFromSearch);
  }

  const onPastLocationSuggestionsClearRequested = () => setPastLocationSuggestions([]);
  const onLanguagesSuggestionsClearRequested = () =>
    setLanguagesSuggestions([]);
  const onFirstNamesSuggestionsClearRequested = () =>
    setFirstNamesSuggestions([]);
  const onLastNamesSuggestionsClearRequested = () =>
    setLastNamesSuggestions([]);
  const onYearOfGraduationSuggestionsClearRequested = () =>
    setYearOfGraduationSuggestions([]);
  const onLocationSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setLocationFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.location.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    setLocationTextBox("");
  };
  const onPastLocationSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    // TODO: create a reducer for past location 
    dispatch(
      setPastLocationFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.pastLocation.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    setPastLocationTextBox("");
  };

  const onLanguagesSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setSpokenLanguagesFilter([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.languages.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    setLanguageTextBox("");
  };
  const onFirstNamesSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setFirstNamesFilter([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.firstNames.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    setFirstNameTextBox("");
  };
  const onLastNamesSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setLastNamesFilter([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.lastNames.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    setLastNameTextBox("");
  };
  const onYearOfGraduationSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setYearOfGraduationFilter([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.year_of_graduations.filter(
          (itm) => itm.name !== suggestionValue
        ),
      ])
    );
    setYearOfGraduationTextBox("");
  };

  const inputLocationProps = {
    placeholder: `Search for a ${"Location"}`,
    value: LocationTextBox,
    onChange: (e, { newValue }) => setLocationTextBox(newValue),
  };
  const inputPastLocationProps = {
    placeholder: `Search for a ${"Past Location"}`,
    value: pastLocationTextBox,
    onChange: (e, { newValue }) => setPastLocationTextBox(newValue),
  };
  const inputLanguageProps = {
    placeholder: `Search for a ${"Spoken Language"}`,
    value: languageTextBox,
    onChange: (e, { newValue }) => setLanguageTextBox(newValue),
  };
  const inputFirstProps = {
    placeholder: `Search for a ${"First Name"}`,
    value: firstNameTextBox,
    onChange: (e, { newValue }) => setFirstNameTextBox(newValue),
  };
  const inputLastProps = {
    placeholder: `Search for a ${"Last Name"}`,
    value: lastNameTextBox,
    onChange: (e, { newValue }) => setLastNameTextBox(newValue),
  };

  const inputYearOfGraduationProps = {
    placeholder: `Search for a ${"Year of Graduation"}`,
    value: yearOfGraduationTextBox,
    onChange: (e, { newValue }) => setYearOfGraduationTextBox(newValue),
  };
  const onEducationSuggestionsFetchRequested = ({ value }) =>
    setEducationSuggestions(getEducationSuggestions(value));
  const onEducationSuggestionsClearRequested = () =>
    setEducationSuggestions([]);

  const onEthnicitySuggestionsFetchRequested = ({ value }) =>
    setEthnicitySuggestions(getEthnicitySuggestions(value));
  const onEthnicitySuggestionsClearRequested = () =>
    setEthnicitySuggestions([]);

  const onEducationSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setEducationFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.education.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    // const unDetectedsynonym = educations_synonyms.every(
    //   (itm) =>
    //     itm.name.trim().toLowerCase() !==
    //       suggestionValue.trim().toLowerCase() ||
    //     !itm.synonym
    //       .map((syn) => syn.trim().toLowerCase())
    //       .includes(suggestionValue.trim().toLowerCase())
    // );
    // dispatch(
    //   setEducationFilters([
    //     ...educations_synonyms
    //       .filter(
    //         (itm) =>
    //           itm.name.trim().toLowerCase() ===
    //             suggestionValue.trim().toLowerCase() ||
    //           itm.synonym
    //             .map((syn) => syn.trim().toLowerCase())
    //             .includes(suggestionValue.trim().toLowerCase())
    //       )
    //       .reduce(
    //         (total, { name, synonym }) => [
    //           ...total,
    //           // { name, value: 1 },
    //           ...synonym.map((name) => ({ name, value: 0 })),
    //         ],
    //         []
    //       ),
    //     ...(unDetectedsynonym
    //       ? [
    //           {
    //             name: suggestionValue,
    //             value: 1,
    //           },
    //         ]
    //       : []),
    //     ...AdvFilters.education.filter(
    //       (itm) =>
    //         itm.name.trim().toLowerCase() !==
    //         suggestionValue.trim().toLowerCase()
    //     ),
    //   ])
    // );
    setEducationTextBox("");
  };

  const inputEducationProps = {
    placeholder: `Search for a ${"Education Degree"}`,
    value: EducationTextBox,
    onChange: (e, { newValue }) => setEducationTextBox(newValue),
  };

  const inputEthnicityProps = {
    placeholder: `Search for an ${"Ethnicity"}`,
    value: EthnicityTextBox,
    onChange: (e, { newValue }) => setEthnicityTextBox(newValue),
  };

  const onEthnicitySuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setEthnicityFilter([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.ethnicity.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    setEthnicityTextBox("");
  };

  const onSchoolsSuggestionsFetchRequested = useCallback(
    debounce(async ({ value }) => {
      try {
        const response = await callLiApi(
          `/voyager/api/typeahead/hitsV2?keywords=${encodeURIComponent(
            value
          )}&origin=OTHER&q=type&type=SCHOOL`,
          {
            headers: {
              "x-li-lang": "en_US",
              "x-restli-protocol-version": "2.0.0",
            },
          }
        ).then((res) => res.data);

        const schoolSuggestions = response.elements.map(
          (item) => item.text.text
        );
        setSchoolsSuggestions(schoolSuggestions);
      } catch (e) {
        throw e;
      }
    }, 200),
    []
  );

  const onSchoolsSuggestionsClearRequested = () => setSchoolsSuggestions([]);

  const onSchoolsSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setSchoolFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.school.filter((itm) => itm.name !== suggestionValue),
      ])
    );

    setSchoolsTextBox("");
  };
  const inputSchoolsProps = {
    placeholder: `Search for a ${"school"}`,
    value: schoolsTextBox,
    onChange: (e, { newValue }) => setSchoolsTextBox(newValue),
  };

  const onCurrentCompanySuggestionsFetchRequested = useCallback(
    debounce(async ({ value }) => {
      try {
        const response = await callLiApi(
          `/voyager/api/typeahead/hitsV2?keywords=${encodeURIComponent(
            value
          )}&origin=OTHER&q=type&type=COMPANY`,
          {
            headers: {
              "x-li-lang": "en_US",
              "x-restli-protocol-version": "2.0.0",
            },
          }
        ).then((r) => r.data);

        const companySuggestions = response.elements.map(
          (item) => item.text.text
        );
        setCurrentCompanySuggestions(companySuggestions);
      } catch (e) {
        throw e;
      }
    }, 200),
    []
  );

  const onPastCompanySuggestionsFetchRequested = useCallback(
    debounce(async ({ value }) => {
      try {
        const response = await callLiApi(
          `/voyager/api/typeahead/hitsV2?keywords=${encodeURIComponent(
            value
          )}&origin=OTHER&q=type&type=COMPANY`,
          {
            headers: {
              "x-li-lang": "en_US",
              "x-restli-protocol-version": "2.0.0",
            },
          }
        ).then((r) => r.data);

        const companySuggestions = response.elements.map(
          (item) => item.text.text
        );
        setPastCompanySuggestions(companySuggestions);
      } catch (e) {
        throw e;
      }
    }, 200),
    []
  );

  const onCurrentCompanySuggestionsClearRequested = () =>
    setCurrentCompanySuggestions([]);

  const onCurrentCompanySuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setCurrentCompanyFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.currentCompany.filter(
          (itm) => itm.name !== suggestionValue
        ),
      ])
    );
    setCurrentCompanyTextBox("");
  };

  const onPastCompanySuggestionsClearRequested = () =>
    setPastCompanySuggestions([]);

  const onPastCompanySuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setPastCompanyFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.pastCompany.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    setPastCompanyTextBox("");
  };

  const inputCurrentCompanyProps = {
    placeholder: `Search for a ${"company"}`,
    value: companyCurrentTextBox,
    onChange: (e, { newValue }) => setCurrentCompanyTextBox(newValue),
  };

  const inputPastCompanyProps = {
    placeholder: `Search for a ${"company"}`,
    value: companyPastTextBox,
    onChange: (e, { newValue }) => setPastCompanyTextBox(newValue),
  };

  const handleAdvancedFilterSkillsEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    // const synonym = skills_synonyms
    //   .filter(
    //     (itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       itm.synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(name.trim().toLowerCase())
    //   )
    //   .reduce(
    //     (total, skillSynonym) => [
    //       ...total,
    //       skillSynonym.name,
    //       ...skillSynonym.synonym,
    //     ],
    //     []
    //   );
    dispatch(
      setSkillsFilters([
        ...AdvFilters.skills.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
    // dispatch(
    //   setSkillsFilters([
    //     ...synonym
    //       .filter(
    //         (skillSynonym) =>
    //           !AdvFilters.skills
    //             .map(({ name }) => name.trim().toLowerCase())
    //             .includes(skillSynonym.trim().toLowerCase())
    //       )
    //       .map((name) => ({
    //         name,
    //         value: activeState === 2 ? 0 : 2,
    //       })),
    //     ...AdvFilters.skills.map((itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(itm.name.trim().toLowerCase())
    //         ? {
    //             name: itm.name,
    //             value: activeState === 2 ? 0 : 2,
    //           }
    //         : itm
    //     ),
    //   ])
    // );
  };

  const handleAdvancedFilterSkillsHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    // const synonym = skills_synonyms
    //   .filter(
    //     (itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       itm.synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(name.trim().toLowerCase())
    //   )
    //   .reduce(
    //     (total, skillSynonym) => [
    //       ...total,
    //       skillSynonym.name,
    //       ...skillSynonym.synonym,
    //     ],
    //     []
    //   );
    dispatch(
      setSkillsFilters([
        ...AdvFilters.skills.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
    // dispatch(
    //   setSkillsFilters([
    //     ...synonym
    //       .filter(
    //         (skillSynonym) =>
    //           !AdvFilters.skills
    //             .map(({ name }) => name.trim().toLowerCase())
    //             .includes(skillSynonym.trim().toLowerCase())
    //       )
    //       .map((name) => ({
    //         name,
    //         value: activeState === 1 ? 0 : 1,
    //       })),
    //     ...AdvFilters.skills.map((itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(itm.name.trim().toLowerCase())
    //         ? {
    //             name: itm.name,
    //             value: activeState === 1 ? 0 : 1,
    //           }
    //         : itm
    //     ),
    //   ])
    // );
  };

  // SPECIALTY

  const handleAdvancedFilterSpecialtiesEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setSpecialtiesFilters([
        ...AdvFilters.specialties.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
    // const synonym = specialties_synonyms
    //   .filter(
    //     (itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       itm.synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(name.trim().toLowerCase())
    //   )
    //   .reduce(
    //     (total, specialtySynonym) => [
    //       ...total,
    //       specialtySynonym.name,
    //       ...specialtySynonym.synonym,
    //     ],
    //     []
    //   );
    // dispatch(
    //   setSpecialtiesFilters([
    //     ...synonym
    //       .filter(
    //         (specialtySynonym) =>
    //           !AdvFilters.specialties
    //             .map(({ name }) => name.trim().toLowerCase())
    //             .includes(specialtySynonym.trim().toLowerCase())
    //       )
    //       .map((name) => ({
    //         name,
    //         value: activeState === 2 ? 0 : 2,
    //       })),
    //     ...AdvFilters.specialties.map((itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(itm.name.trim().toLowerCase())
    //         ? {
    //             name: itm.name,
    //             value: activeState === 2 ? 0 : 2,
    //           }
    //         : itm
    //     ),
    //   ])
    // );
  };

  const handleAdvancedFilterSpecialtiesHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setSpecialtiesFilters([
        ...AdvFilters.specialties.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
    // const synonym = specialties_synonyms
    //   .filter(
    //     (itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       itm.synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(name.trim().toLowerCase())
    //   )
    //   .reduce(
    //     (total, specialtySynonym) => [
    //       ...total,
    //       specialtySynonym.name,
    //       ...specialtySynonym.synonym,
    //     ],
    //     []
    //   );
    // dispatch(
    //   setSpecialtiesFilters([
    //     ...synonym
    //       .filter(
    //         (specialtySynonym) =>
    //           !AdvFilters.specialties
    //             .map(({ name }) => name.trim().toLowerCase())
    //             .includes(specialtySynonym.trim().toLowerCase())
    //       )
    //       .map((name) => ({
    //         name,
    //         value: activeState === 1 ? 0 : 1,
    //       })),
    //     ...AdvFilters.specialties.map((itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(itm.name.trim().toLowerCase())
    //         ? {
    //             name: itm.name,
    //             value: activeState === 1 ? 0 : 1,
    //           }
    //         : itm
    //     ),
    //   ])
    // );
  };

  const handleAdvancedFilterSchoolsEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );

    dispatch(
      setSchoolFilters([
        ...AdvFilters.school.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
  };
  const handleAdvancedFilterSchoolsHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setSchoolFilters([
        ...AdvFilters.school.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterEducationEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setEducationFilters([
        ...AdvFilters.education.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
    // const synonym = specialties_synonyms.filter(itm=>itm.name===name || itm.synonym.includes(name)).reduce((total, {name, synonym})=>[...total, name, ...synonym], [])
    // const synonym = educations_synonyms
    //   .filter(
    //     (itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       itm.synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(name.trim().toLowerCase())
    //   )
    //   .reduce(
    //     (total, educationSynonym) => [
    //       ...total,
    //       educationSynonym.name,
    //       ...educationSynonym.synonym,
    //     ],
    //     []
    //   );
    // dispatch(
    //   setEducationFilters([
    //     ...synonym
    //       .filter(
    //         (educationSynonym) =>
    //           !AdvFilters.education
    //             .map(({ name }) => name.trim().toLowerCase())
    //             .includes(educationSynonym.trim().toLowerCase())
    //       )
    //       .map((name) => ({
    //         name,
    //         value: activeState === 2 ? 0 : 2,
    //       })),
    //     ...AdvFilters.education.map((itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(itm.name.trim().toLowerCase())
    //         ? {
    //             name: itm.name,
    //             value: activeState === 2 ? 0 : 2,
    //           }
    //         : itm
    //     ),
    //   ])
    // );
  };
  const handleAdvancedFilterEducationHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setEducationFilters([
        ...AdvFilters.education.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
    // const synonym = specialties_synonyms.filter(itm=>itm.name===name || itm.synonym.includes(name)).reduce((total, {name, synonym})=>[...total, name, ...synonym], [])
    // const synonym = educations_synonyms
    //   .filter(
    //     (itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       itm.synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(name.trim().toLowerCase())
    //   )
    //   .reduce(
    //     (total, educationSynonym) => [
    //       ...total,
    //       educationSynonym.name,
    //       ...educationSynonym.synonym,
    //     ],
    //     []
    //   );
    // dispatch(
    //   setEducationFilters([
    //     ...synonym
    //       .filter(
    //         (educationSynonym) =>
    //           !AdvFilters.education
    //             .map(({ name }) => name.trim().toLowerCase())
    //             .includes(educationSynonym.trim().toLowerCase())
    //       )
    //       .map((name) => ({
    //         name,
    //         value: activeState === 1 ? 0 : 1,
    //       })),
    //     ...AdvFilters.education.map((itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(itm.name.trim().toLowerCase())
    //         ? {
    //             name: itm.name,
    //             value: activeState === 1 ? 0 : 1,
    //           }
    //         : itm
    //     ),
    //   ])
    // );
  };

  const onCurrentTitleSuggestionsFetchRequested = ({ value }) =>
    setCurrentTitleSuggestions(getTitleSuggestions(value));
  const onCurrentTitleSuggestionsClearRequested = () =>
    setCurrentTitleSuggestions([]);

  const handleAdvancedFilterEthnicityEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setEthnicityFilter([
        ...AdvFilters.ethnicity.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
  };
  const handleAdvancedFilterEthnicityHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setEthnicityFilter([
        ...AdvFilters.ethnicity.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
  };

  const onCurrentTitleSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setCurrentTitleFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.currentTitle.filter(
          (itm) => itm.name !== suggestionValue
        ),
      ])
    );
    // const unDetectedsynonym = titles_synonyms.every(
    //   (itm) =>
    //     itm.name.trim().toLowerCase() !==
    //       suggestionValue.trim().toLowerCase() ||
    //     !itm.synonym
    //       .map((syn) => syn.trim().toLowerCase())
    //       .includes(suggestionValue.trim().toLowerCase())
    // );
    // dispatch(
    //   setCurrentTitleFilters([
    //     ...titles_synonyms
    //       .filter(
    //         (itm) =>
    //           itm.name.trim().toLowerCase() ===
    //             suggestionValue.trim().toLowerCase() ||
    //           itm.synonym
    //             .map((syn) => syn.trim().toLowerCase())
    //             .includes(suggestionValue.trim().toLowerCase())
    //       )
    //       .reduce(
    //         (total, { name, synonym }) => [
    //           ...total,
    //           // { name, value: 1 },
    //           ...synonym.map((name) => ({ name, value: 0 })),
    //         ],
    //         []
    //       ),
    //     ...(unDetectedsynonym
    //       ? [
    //           {
    //             name: suggestionValue,
    //             value: 1,
    //           },
    //         ]
    //       : []),
    //     ...AdvFilters.currentTitle.filter(
    //       (itm) =>
    //         itm.name.trim().toLowerCase() !==
    //         suggestionValue.trim().toLowerCase()
    //     ),
    //   ])
    // );
    setCurrentTitleTextBox("");
  };
  const onPastTitleSuggestionsFetchRequested = ({ value }) =>
    setPastTitleSuggestions(getTitleSuggestions(value));
  const onPastTitleSuggestionsClearRequested = () =>
    setPastTitleSuggestions([]);

  const onPastTitleSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    setFilterCount((prev) => prev + 1);
    dispatch(
      setPastTitleFilters([
        {
          name: suggestionValue,
          value: 1,
        },
        ...AdvFilters.pastTitle.filter((itm) => itm.name !== suggestionValue),
      ])
    );
    // const unDetectedsynonym = titles_synonyms.every(
    //   (itm) =>
    //     itm.name.trim().toLowerCase() !==
    //       suggestionValue.trim().toLowerCase() ||
    //     !itm.synonym
    //       .map((syn) => syn.trim().toLowerCase())
    //       .includes(suggestionValue.trim().toLowerCase())
    // );
    // dispatch(
    //   setPastTitleFilters([
    //     ...titles_synonyms
    //       .filter(
    //         (itm) =>
    //           itm.name.trim().toLowerCase() ===
    //             suggestionValue.trim().toLowerCase() ||
    //           itm.synonym
    //             .map((syn) => syn.trim().toLowerCase())
    //             .includes(suggestionValue.trim().toLowerCase())
    //       )
    //       .reduce(
    //         (total, { name, synonym }) => [
    //           ...total,
    //           // { name, value: 1 },
    //           ...synonym.map((name) => ({ name, value: 0 })),
    //         ],
    //         []
    //       ),
    //     ...(unDetectedsynonym
    //       ? [
    //           {
    //             name: suggestionValue,
    //             value: 1,
    //           },
    //         ]
    //       : []),
    //     ...AdvFilters.pastTitle.filter(
    //       (itm) =>
    //         itm.name.trim().toLowerCase() !==
    //         suggestionValue.trim().toLowerCase()
    //     ),
    //   ])
    // );
    setPastTitleTextBox("");
  };
  const inputCurrentTitleProps = {
    placeholder: `Search for a ${"title"}`,
    value: titleCurrentTextBox,
    onChange: (e, { newValue }) => setCurrentTitleTextBox(newValue),
  };
  const inputPastTitleProps = {
    placeholder: `Search for a ${"title"}`,
    value: titlePastTextBox,
    onChange: (e, { newValue }) => setPastTitleTextBox(newValue),
  };

  const handleAdvancedFilterCurrentCompanyEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setCurrentCompanyFilters([
        ...AdvFilters.currentCompany.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterCurrentCompanyHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setCurrentCompanyFilters([
        ...AdvFilters.currentCompany.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterLanguagesEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setSpokenLanguagesFilter([
        ...AdvFilters.languages.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterLanguagesHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setSpokenLanguagesFilter([
        ...AdvFilters.languages.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterFirstNamesEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setFirstNamesFilter([
        ...AdvFilters.firstNames.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterFirstNamesHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setFirstNamesFilter([
        ...AdvFilters.firstNames.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterLastNamesEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setLastNamesFilter([
        ...AdvFilters.lastNames.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterLastNamesHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setLastNamesFilter([
        ...AdvFilters.lastNames.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterYearOfGraduationHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setYearOfGraduationFilter([
        ...AdvFilters.year_of_graduations.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
  };
  const handleAdvancedFilterYearOfGraduationEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setYearOfGraduationFilter([
        ...AdvFilters.year_of_graduations.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterPastCompanyEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setPastCompanyFilters([
        ...AdvFilters.pastCompany.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterPastCompanyHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setPastCompanyFilters([
        ...AdvFilters.pastCompany.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterCurrentTitleEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setCurrentTitleFilters([
        ...AdvFilters.currentTitle.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );

    // const synonym = titles_synonyms
    //   .filter(
    //     (itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       itm.synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(name.trim().toLowerCase())
    //   )
    //   .reduce(
    //     (total, titleSynonym) => [
    //       ...total,
    //       titleSynonym.name,
    //       ...titleSynonym.synonym,
    //     ],
    //     []
    //   );
    // dispatch(
    //   setCurrentTitleFilters([
    //     ...synonym
    //       .filter(
    //         (titleSynonym) =>
    //           !AdvFilters.currentTitle
    //             .map(({ name }) => name.trim().toLowerCase())
    //             .includes(titleSynonym.trim().toLowerCase())
    //       )
    //       .map((name) => ({
    //         name,
    //         value: activeState === 2 ? 0 : 2,
    //       })),
    //     ...AdvFilters.currentTitle.map((itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(itm.name.trim().toLowerCase())
    //         ? {
    //             name: itm.name,
    //             value: activeState === 2 ? 0 : 2,
    //           }
    //         : itm
    //     ),
    //   ])
    // );
  };
  const handleAdvancedFilterCurrentTitleHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setCurrentTitleFilters([
        ...AdvFilters.currentTitle.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
    // const synonym = titles_synonyms
    //   .filter(
    //     (itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       itm.synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(name.trim().toLowerCase())
    //   )
    //   .reduce(
    //     (total, titleSynonym) => [
    //       ...total,
    //       titleSynonym.name,
    //       ...titleSynonym.synonym,
    //     ],
    //     []
    //   );
    // dispatch(
    //   setCurrentTitleFilters([
    //     ...synonym
    //       .filter(
    //         (titleSynonym) =>
    //           !AdvFilters.currentTitle
    //             .map(({ name }) => name.trim().toLowerCase())
    //             .includes(titleSynonym.trim().toLowerCase())
    //       )
    //       .map((name) => ({
    //         name,
    //         value: activeState === 1 ? 0 : 1,
    //       })),
    //     ...AdvFilters.currentTitle.map((itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(itm.name.trim().toLowerCase())
    //         ? {
    //             name: itm.name,
    //             value: activeState === 1 ? 0 : 1,
    //           }
    //         : itm
    //     ),
    //   ])
    // );
  };
  const handleAdvancedFilterPastTitleEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setPastTitleFilters([
        ...AdvFilters.pastTitle.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
    // const synonym = titles_synonyms
    //   .filter(
    //     (itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       itm.synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(name.trim().toLowerCase())
    //   )
    //   .reduce(
    //     (total, titleSynonym) => [
    //       ...total,
    //       titleSynonym.name,
    //       ...titleSynonym.synonym,
    //     ],
    //     []
    //   );
    // dispatch(
    //   setPastTitleFilters([
    //     ...synonym
    //       .filter(
    //         (titleSynonym) =>
    //           !AdvFilters.pastTitle
    //             .map(({ name }) => name.trim().toLowerCase())
    //             .includes(titleSynonym.trim().toLowerCase())
    //       )
    //       .map((name) => ({
    //         name,
    //         value: activeState === 2 ? 0 : 2,
    //       })),
    //     ...AdvFilters.pastTitle.map((itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(itm.name.trim().toLowerCase())
    //         ? {
    //             name: itm.name,
    //             value: activeState === 2 ? 0 : 2,
    //           }
    //         : itm
    //     ),
    //   ])
    // );
  };
  const handleAdvancedFilterPastTitleHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setPastTitleFilters([
        ...AdvFilters.pastTitle.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
    // const synonym = titles_synonyms
    //   .filter(
    //     (itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       itm.synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(name.trim().toLowerCase())
    //   )
    //   .reduce(
    //     (total, titleSynonym) => [
    //       ...total,
    //       titleSynonym.name,
    //       ...titleSynonym.synonym,
    //     ],
    //     []
    //   );
    // dispatch(
    //   setPastTitleFilters([
    //     ...synonym
    //       .filter(
    //         (titleSynonym) =>
    //           !AdvFilters.pastTitle
    //             .map(({ name }) => name.trim().toLowerCase())
    //             .includes(titleSynonym.trim().toLowerCase())
    //       )
    //       .map((name) => ({
    //         name,
    //         value: activeState === 1 ? 0 : 1,
    //       })),
    //     ...AdvFilters.pastTitle.map((itm) =>
    //       itm.name.trim().toLowerCase() === name.trim().toLowerCase() ||
    //       synonym
    //         .map((syn) => syn.trim().toLowerCase())
    //         .includes(itm.name.trim().toLowerCase())
    //         ? {
    //             name: itm.name,
    //             value: activeState === 1 ? 0 : 1,
    //           }
    //         : itm
    //     ),
    //   ])
    // );
  };

  const handleAdvancedFilterLocationEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setLocationFilters([
        ...AdvFilters.location.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
  };
  const handleAdvancedFilterLocationHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setLocationFilters([
        ...AdvFilters.location.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterPastLocationEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setPastLocationFilters([
        ...AdvFilters.pastLocation.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
  };
  const handleAdvancedFilterPastLocationHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setPastLocationFilters([
        ...AdvFilters.pastLocation.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedBooleanFilterClear = () => {
    dispatch(
      setBooleanFilters([
        [
          {
            name: "Skills",
            value: "",
            range: { min: 0, max: 5 },
            isStrict: false,
            eliminate: [],
            highlight: [],
            selectedOne: false,
            relation: "",
            outerRelation: "",
          },
        ],
      ])
    );
  };

  const handleAdvancedFilterClear = () => {
    setFilterCount(0);
    dispatch(clearAdvancedFilters());
    dispatch(clearScoringFilters());

    manualApiCall("/api/auth/user/activity/store", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ actionName: "CLEAR_ADVANCED_FILTER" }),
    });

    const params = qs.parse(search);

    const newParams = omit(params, [
      ...advFilterList,
      ...scrFilterList,
      ...commentNotificationList,
    ]);
    newParams.p = 0;
    newParams.pPP = PER_PAGE_COUNT;
    dispatch(clearProspects());

    replace(`${pathname}?${qs.stringify(newParams)}`);
  };

  const actionApiAdvFilter = (actionType, flag = false) => {
    manualApiCall("/api/auth/user/activity/store", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        actionName: flag
          ? `APPLY_SCORING_FILTER_${actionType}_FILTER`
          : `APPLY_ADVANCED_FILTER_${actionType}_FILTER`,
      }),
    });
  };

  const handleAdvancedFilterCancel = () => {
    const qParams = qs.parse(search.split("?").pop());

    const eVCount = qParams.eV ? qParams.eV.split("||").length : 0;
    const hVCount = qParams.hV ? qParams.hV.split("||").length : 0;
    const eLCount = qParams.eL ? qParams.eL.split("||").length : 0;
    const hLCount = qParams.hL ? qParams.hL.split("||").length : 0;
    const ePLCount = qParams.ePL ? qParams.ePL.split("||").length : 0;
    const hPLCount = qParams.hPL ? qParams.hPL.split("||").length : 0;
    const eSCount = qParams.eS ? qParams.eS.split("||").length : 0;
    const hSCount = qParams.hS ? qParams.hS.split("||").length : 0;
    const eSPCount = qParams.eSP ? qParams.eSP.split("||").length : 0;
    const hSPCount = qParams.hSP ? qParams.hSP.split("||").length : 0;
    const eSSCount = qParams.eSS ? qParams.eSS.split("||").length : 0;
    const hSSCount = qParams.hSS ? qParams.hSS.split("||").length : 0;
    const eECount = qParams.eE ? qParams.eE.split("||").length : 0;
    const hECount = qParams.hE ? qParams.hE.split("||").length : 0;
    const eCICount = qParams.eCI ? qParams.eCI.split("||").length : 0;
    const ePICount = qParams.ePI ? qParams.ePI.split("||").length : 0;
    const hCICount = qParams.hCI ? qParams.hCI.split("||").length : 0;
    const hPICount = qParams.hPI ? qParams.hPI.split("||").length : 0;
    const eCTCount = qParams.eCT ? qParams.eCT.split("||").length : 0;
    const ePTCount = qParams.ePT ? qParams.ePT.split("||").length : 0;
    const hCTCount = qParams.hCT ? qParams.hCT.split("||").length : 0;
    const hPTCount = qParams.hPT ? qParams.hPT.split("||").length : 0;
    const hEtCount = qParams.hEt ? qParams.hEt.split("||").length : 0;
    const eCCCount = qParams.eCC ? qParams.eCC.split("||").length : 0;
    const ePCCount = qParams.ePC ? qParams.ePC.split("||").length : 0;
    const hCCCount = qParams.hCC ? qParams.hCC.split("||").length : 0;
    const hPCCount = qParams.hPC ? qParams.hPC.split("||").length : 0;
    const eCSRCount = qParams.eCSR ? qParams.eCSR.split("||").length : 0;
    const hCSRCount = qParams.hCSR ? qParams.hCSR.split("||").length : 0;
    const hSLCount = qParams.hSL ? qParams.hSL.split("||").length : 0;
    const eSLCount = qParams.eSL ? qParams.eSL.split("||").length : 0;
    const hFNCount = qParams.hFN ? qParams.hFN.split("||").length : 0;
    const eFNCount = qParams.eFN ? qParams.eFN.split("||").length : 0;
    const hLNCount = qParams.hLN ? qParams.hLN.split("||").length : 0;
    const eLNCount = qParams.eLN ? qParams.eLN.split("||").length : 0;
    const yCCCount = qParams.yCC ? 1 : 0;
    const yCPCount = qParams.yCP ? 1 : 0;
    const YOGCount = qParams.YOG ? 1 : 0;
    const tECount = qParams.tE ? 1 : 0;
    const rECount = qParams.rE ? 1 : 0;
    const tCount = qParams.tSF ? 1 : 0;
    const sCount = qParams.sSF ? 1 : 0;
    const spCount = qParams.spSF ? 1 : 0;
    const expCount = qParams.expSF ? 1 : 0;
    const edCount = qParams.edSF ? 1 : 0;
    const iCount = qParams.iSF ? 1 : 0;

    const sum =
      hSLCount +
      eSLCount +
      hFNCount +
      eFNCount +
      hLNCount +
      eLNCount +
      yCCCount +
      yCPCount +
      YOGCount +
      eVCount +
      hVCount +
      eLCount +
      hLCount +
      ePLCount + 
      hPLCount +
      eSCount +
      hSCount +
      eSPCount +
      hSPCount +
      eSSCount +
      hSSCount +
      eCICount +
      ePICount +
      eECount +
      hECount +
      hCICount +
      hPICount +
      eCTCount +
      ePTCount +
      hCTCount +
      hPTCount +
      hEtCount +
      eCCCount +
      ePCCount +
      hCCCount +
      hPCCount +
      eCSRCount +
      hCSRCount +
      tECount +
      rECount +
      tCount +
      sCount +
      spCount +
      expCount +
      edCount +
      iCount;

    dispatch(clearAdvancedFilters());
    dispatch(clearScoringFilters());
    dispatch(toggleAdvFiters());
    setShowBooleanFilters(false);

    setFilterCount(sum);
  };

  const getADVFiltersObject = (actionLogFlag) => {
    const newParams = {};

    const filterValueGenerator = ({
      paramName,
      array,
      val,
      actionName,
      actionLogFlag,
      strict = false,
      scoring = false,
      status = null,
      actionFlag = false,
    }) => {
      if (strict) {
        newParams[paramName] = "strict";
        actionLogFlag && actionApiAdvFilter(actionName);
      } else if (scoring) {
        newParams[paramName] = `${val}`;
        actionLogFlag && actionApiAdvFilter(actionName, actionFlag);
      } else if (status != null) {
        newParams[paramName] = status;
        actionLogFlag && actionApiAdvFilter(actionName);
      } else {
        newParams[paramName] = array
          ?.filter((item) => item.value === val)
          .map((item) => item.name)
          .join("||");
        actionLogFlag && actionApiAdvFilter(actionName);
      }
    };
    const filterValueGeneratorForScoring = [
      {
        values: {
          paramName: "hV",
          array: AdvFilters.visa,
          val: 1,
          actionName: "HIGHLIGHT_VISA",
          actionLogFlag,
        },
        condition:
          AdvFilters.visa.filter((item) => item.value === 1).length > 0,
      },
      {
        values: {
          paramName: "eV",
          array: AdvFilters.visa,
          val: 2,
          actionName: "ELIMINATE_VISA",
          actionLogFlag,
        },
        condition:
          AdvFilters.visa.filter((item) => item.value === 2).length > 0,
      },
      {
        values: {
          paramName: "hL",
          array: AdvFilters.location,
          val: 1,
          actionName: "HIGHLIGHT_LOCATION",
          actionLogFlag,
        },
        condition:
          AdvFilters.location.filter((item) => item.value === 1).length > 0,
      },
      {
        values: {
          paramName: "eL",
          array: AdvFilters.location,
          val: 2,
          actionName: "ELIMINATE_LOCATION",
          actionLogFlag,
        },
        condition:
          AdvFilters.location.filter((item) => item.value === 2).length > 0,
      },
      {
        values: {
          paramName: "hPL",
          array: AdvFilters.pastLocation,
          val: 1,
          actionName: "HIGHLIGHT_PAST_LOCATION",
          actionLogFlag,
        },
        condition:
          AdvFilters.pastLocation.filter((item) => item.value === 1).length > 0,
      },
      {
        values: {
          paramName: "ePL",
          array: AdvFilters.pastLocation,
          val: 2,
          actionName: "ELIMINATE_PAST_LOCATION",
          actionLogFlag,
        },
        condition:
          AdvFilters.pastLocation.filter((item) => item.value === 2).length > 0,
      },
      {
        values: {
          paramName: "sS",
          strict: true,
          actionName: "STRICT_SKILLS",
          actionLogFlag,
        },
        condition: AdvFilters.skillsStrict,
      },
      {
        values: {
          paramName: "sST",
          status: AdvFilters.skillsStatus,
          actionName: "SKILLS_STATUS",
          actionLogFlag,
        },
        condition: AdvFilters.skillsStatus !== null,
      },
      {
        values: {
          paramName: "sPST",
          status: AdvFilters.specialtiesStatus,
          actionName: "SPECIALTIES_STATUS",
          actionLogFlag,
        },
        condition: AdvFilters.specialtiesStatus !== null,
      },
      {
        values: {
          paramName: "LTS",
          status: AdvFilters.locationStatus,
          actionName: "LOCATION_STATUS",
          actionLogFlag,
        },
        condition: AdvFilters.locationStatus !== null,
      },
      {
        values: {
          paramName: "sSP",
          strict: true,
          actionName: "STRICT_SPECIALTIES",
          actionLogFlag,
        },
        condition: AdvFilters.specialtiesStrict,
      },
      {
        values: {
          paramName: "hS",
          array: AdvFilters.skills,
          val: 1,
          actionName: "HIGHLIGHT_SKILLS",
          actionLogFlag,
        },
        condition:
          AdvFilters.skills.filter((item) => item.value === 1).length > 0,
      },
      {
        values: {
          paramName: "eS",
          array: AdvFilters.skills,
          val: 2,
          actionName: "ELIMINATE_SKILLS",
          actionLogFlag,
        },
        condition:
          AdvFilters.skills.filter((item) => item.value === 2).length > 0,
      },
      {
        values: {
          paramName: "hSP",
          array: AdvFilters.specialties,
          val: 1,
          actionName: "HIGHLIGHT_SPECIALTIES",
          actionLogFlag,
        },
        condition:
          AdvFilters.specialties.filter((item) => item.value === 1).length >
          0,
      },
      {
        values: {
          paramName: "eSP",
          array: AdvFilters.specialties,
          val: 2,
          actionName: "ELIMINATE_SPECIALTIES",
          actionLogFlag,
        },
        condition:
          AdvFilters.specialties.filter((item) => item.value === 2).length >
          0,
      },
      {
        values: {
          paramName: "hSS",
          array: AdvFilters.school,
          val: 1,
          actionName: "HIGHLIGHT_SCHOOLS",
          actionLogFlag,
        },
        condition:
          AdvFilters.school.filter((item) => item.value === 1).length > 0,
      },
      {
        values: {
          paramName: "eSS",
          array: AdvFilters.school,
          val: 2,
          actionName: "ELIMINATE_SCHOOLS",
          actionLogFlag,
        },
        condition:
          AdvFilters.school.filter((item) => item.value === 2).length > 0,
      },
      {
        values: {
          paramName: "hE",
          array: AdvFilters.education,
          val: 1,
          actionName: "HIGHLIGHT_EDUCATION",
          actionLogFlag,
        },
        condition:
          AdvFilters.education.filter((item) => item.value === 1).length > 0,
      },
      {
        values: {
          paramName: "eE",
          array: AdvFilters.education,
          val: 2,
          actionName: "ELIMINATE_EDUCATION",
          actionLogFlag,
        },
        condition:
          AdvFilters.education.filter((item) => item.value === 2).length > 0,
      },
      {
        values: {
          paramName: "hEt",
          array: AdvFilters.ethnicity,
          val: 1,
          actionName: "HIGHLIGHT_ETHNICITY",
          actionLogFlag,
        },
        condition:
          AdvFilters.ethnicity.filter((item) => item.value === 1).length > 0,
      },
      {
        values: {
          paramName: "eDS",
          strict: true,
          actionName: "STRICT_EDUCATION",
          actionLogFlag,
        },
        condition: AdvFilters.educationStrict,
      },
      {
        values: {
          paramName: "hCC",
          array: AdvFilters.currentCompany,
          val: 1,
          actionName: "HIGHLIGHT_CURRENT_COMPANY",
          actionLogFlag,
        },
        condition:
          AdvFilters.currentCompany.filter((item) => item.value === 1)
            .length > 0,
      },
      {
        values: {
          paramName: "eCC",
          array: AdvFilters.currentCompany,
          val: 2,
          actionName: "ELIMINATE_CURRENT_COMPANY",
          actionLogFlag,
        },
        condition:
          AdvFilters.currentCompany.filter((item) => item.value === 2)
            .length > 0,
      },
      {
        values: {
          paramName: "hPC",
          array: AdvFilters.pastCompany,
          val: 1,
          actionName: "HIGHLIGHT_PAST_COMPANY",
          actionLogFlag,
        },
        condition:
          AdvFilters.pastCompany.filter((item) => item.value === 1).length >
          0,
      },
      {
        values: {
          paramName: "ePC",
          array: AdvFilters.pastCompany,
          val: 2,
          actionName: "ELIMINATE_PAST_COMPANY",
          actionLogFlag,
        },
        condition:
          AdvFilters.pastCompany.filter((item) => item.value === 2).length >
          0,
      },
      {
        values: {
          paramName: "eCSR",
          array: AdvFilters.companySize,
          val: 2,
          actionName: "ELIMINATE_COMPANY_SIZE",
          actionLogFlag,
        },
        condition:
          AdvFilters.companySize.filter((item) => item.value === 2).length >
          0,
      },
      {
        values: {
          paramName: "hCSR",
          array: AdvFilters.companySize,
          val: 1,
          actionName: "HIGHLIGHT_COMPANY_SIZE",
          actionLogFlag,
        },
        condition:
          AdvFilters.companySize.filter((item) => item.value === 1).length >
          0,
      },
      {
        values: {
          paramName: "hCT",
          array: AdvFilters.currentTitle,
          val: 1,
          actionName: "HIGHLIGHT_CURRENT_TITLE",
          actionLogFlag,
        },
        condition:
          AdvFilters.currentTitle.filter((item) => item.value === 1).length >
          0,
      },
      {
        values: {
          paramName: "eCT",
          array: AdvFilters.currentTitle,
          val: 2,
          actionName: "ELIMINATE_CURRENT_TITLE",
          actionLogFlag,
        },
        condition:
          AdvFilters.currentTitle.filter((item) => item.value === 2).length >
          0,
      },
      {
        values: {
          paramName: "hPT",
          array: AdvFilters.pastTitle,
          val: 1,
          actionName: "HIGHLIGHT_PAST_TITLE",
          actionLogFlag,
        },
        condition:
          AdvFilters.pastTitle.filter((item) => item.value === 1).length > 0,
      },
      {
        values: {
          paramName: "ePT",
          array: AdvFilters.pastTitle,
          val: 2,
          actionName: "ELIMINATE_PAST_TITLE",
          actionLogFlag,
        },
        condition:
          AdvFilters.pastTitle.filter((item) => item.value === 2).length > 0,
      },
      {
        values: {
          paramName: "cTS",
          strict: true,
          actionName: "CURRENT_TITLE_STRICT",
          actionLogFlag,
        },
        condition: AdvFilters.currentTitleStrict,
      },
      {
        values: {
          paramName: "pTS",
          strict: true,
          actionName: "PAST_TITLE_STRICT",
        },
        condition: AdvFilters.pastTitleStrict,
      },
      {
        values: {
          paramName: "lS",
          strict: true,
          actionName: "LOCATION_STRICT",
        },
        condition: AdvFilters.locationStrict,
      },
      {
        values: {
          paramName: "hCI",
          array: AdvFilters.currentIndustry,
          val: 1,
          actionName: "HIGHLIGHT_CURRENT_INDUSTRY",
          actionLogFlag,
        },
        condition:
          AdvFilters.currentIndustry.filter((item) => item.value === 1)
            .length > 0,
      },
      {
        values: {
          paramName: "eCI",
          array: AdvFilters.currentIndustry,
          val: 2,
          actionName: "ELIMINATE_CURRENT_INDUSTRY",
          actionLogFlag,
        },
        condition:
          AdvFilters.currentIndustry.filter((item) => item.value === 2)
            .length > 0,
      },
      {
        values: {
          paramName: "hPI",
          array: AdvFilters.pastIndustry,
          val: 1,
          actionName: "HIGHLIGHT_PAST_INDUSTRY",
          actionLogFlag,
        },
        condition:
          AdvFilters.pastIndustry.filter((item) => item.value === 1).length >
          0,
      },
      {
        values: {
          paramName: "ePI",
          array: AdvFilters.pastIndustry,
          val: 2,
          actionName: "ELIMINATE_PAST_INDUSTRY",
          actionLogFlag,
        },
        condition:
          AdvFilters.pastIndustry.filter((item) => item.value === 2).length >
          0,
      },
      {
        values: {
          paramName: "tE",
          scoring: true,
          val: `${AdvFilters.totalExperience.value.min}||${AdvFilters.totalExperience.value.max}`,
          actionName: "TOTAL_EXPERIENCE",
          actionLogFlag,
        },
        condition: AdvFilters.totalExperience.active,
      },
      {
        values: {
          paramName: "yCC",
          scoring: true,
          val: `${AdvFilters.yearCurrentCompany.value.min}||${AdvFilters.yearCurrentCompany.value.max}`,
          actionName: "YEARS_CURRENT_COMPANY",
          actionLogFlag,
        },
        condition: AdvFilters.yearCurrentCompany.active,
      },
      {
        values: {
          paramName: "yCP",
          scoring: true,
          val: `${AdvFilters.yearCurrentPosition.value.min}||${AdvFilters.yearCurrentPosition.value.max}`,
          actionName: "YEARS_CURRENT_POSITION",
          actionLogFlag,
        },
        condition: AdvFilters.yearCurrentPosition.active,
      },
      {
        values: {
          paramName: "rE",
          scoring: true,
          val: `${AdvFilters.relevantExperience.value.min}||${AdvFilters.relevantExperience.value.max}`,
          actionName: "RELEVANT_EXPERIENCE",
          actionLogFlag,
        },
        condition: AdvFilters.relevantExperience.active,
      },
      {
        values: {
          paramName: "YOG",
          scoring: true,
          val: `${AdvFilters.year_of_graduations.value.min}||${AdvFilters.year_of_graduations.value.max}`,
          actionName: "YEAR_OF_GRADUATION",
          actionLogFlag,
        },
        condition: AdvFilters.year_of_graduations.active,
      },
      {
        values: {
          paramName: "tSF",
          scoring: true,
          val: `${ScoringFilters.titleScore.value}`,
          actionName: "TITLE",
          actionFlag: true,
          actionLogFlag,
        },
        condition: ScoringFilters.titleScore.active,
      },
      {
        values: {
          paramName: "sSF",
          scoring: true,
          val: `${ScoringFilters.skillScore.value}`,
          actionName: "SKILL",
          actionFlag: true,
          actionLogFlag,
        },
        condition: ScoringFilters.skillScore.active,
      },
      {
        values: {
          paramName: "spSF",
          val: `${ScoringFilters.specialtyScore.value}`,
          scoring: true,
          actionName: "SPECIALTY",
          actionFlag: true,
          actionLogFlag,
        },
        condition: ScoringFilters.specialtyScore.active,
      },
      {
        values: {
          paramName: "expSF",
          val: `${ScoringFilters.experienceScore.value}`,
          scoring: true,
          actionName: "EXPERIENCE",
          actionFlag: true,
          actionLogFlag,
        },
        condition: ScoringFilters.experienceScore.active,
      },
      {
        values: {
          paramName: "edSF",
          val: `${ScoringFilters.educationScore.value}`,
          scoring: true,
          actionName: "EDUCATION",
          actionFlag: true,
          actionLogFlag,
        },
        condition: ScoringFilters.educationScore.active,
      },
      {
        values: {
          paramName: "iSF",
          val: `${ScoringFilters.industryScore.value}`,
          scoring: true,
          actionName: "INDUSTRY",
          actionFlag: true,
          actionLogFlag,
        },
        condition: ScoringFilters.industryScore.active,
      },
      {
        values: {
          paramName: "hSL",
          array: AdvFilters.languages,
          val: 1,
          actionName: "HIGHLIGHT_SPOKEN_LANGUAGE",
          actionLogFlag,
        },
        condition:
          AdvFilters.languages.filter((item) => item.value === 1).length > 0,
      },
      {
        values: {
          paramName: "eSL",
          array: AdvFilters.languages,
          val: 2,
          actionName: "ELIMINATE_SPOKEN_LANGUAGE",
          actionLogFlag,
        },
        condition:
          AdvFilters.languages.filter((item) => item.value === 2).length > 0,
      },
      {
        values: {
          paramName: "hFN",
          array: AdvFilters.firstNames,
          val: 1,
          actionName: "HIGHLIGHT_FIRST_NAME",
          actionLogFlag,
        },
        condition:
          AdvFilters.firstNames.filter((item) => item.value === 1).length > 0,
      },
      {
        values: {
          paramName: "eFN",
          array: AdvFilters.firstNames,
          val: 2,
          actionName: "ELIMINATE_FIRST_NAME",
          actionLogFlag,
        },
        condition:
          AdvFilters.firstNames.filter((item) => item.value === 2).length > 0,
      },
      {
        values: {
          paramName: "hLN",
          array: AdvFilters.lastNames,
          val: 1,
          actionName: "HIGHLIGHT_LAST_NAME",
          actionLogFlag,
        },
        condition:
          AdvFilters.lastNames.filter((item) => item.value === 1).length > 0,
      },
      {
        values: {
          paramName: "eLN",
          array: AdvFilters.lastNames,
          val: 2,
          actionName: "ELIMINATE_LAST_NAME",
          actionLogFlag,
        },
        condition:
          AdvFilters.lastNames.filter((item) => item.value === 2).length > 0,
      },
    ];
    filterValueGeneratorForScoring.map((item) => {
      const { values, condition } = item;
      return condition && filterValueGenerator(values);
    });

    return newParams;
  }

  // apply default filters on load
  // useEffect(() => {
  //   handleClickApply()
  //   if (FilterCount == 0)
  //     setFilterCount(1)
  //   setShow(true);
  // }, [])

  const handleClickApply = () => {
    const params = qs.parse(search);
    const allBooleanFilter = JSON.stringify(rawBooleanTerm);
    const newParams = {
      ...omit(params, [
        ...advFilterList,
        ...scrFilterList,
        ...commentNotificationList,
      ]),
      ...getADVFiltersObject(true),
      p: 0,
    };
    newParams.p = 0;
    replace(`${pathname}?${qs.stringify(newParams)}`);

    window.scrollTo({ top: 0, behavior: "smooth" });

    setShow(false);
  };

  const handleAdvancedFilterCompanySizeEliminate = (activeState, name) => {
    setFilterCount(
      activeState === 2
        ? FilterCount - 1
        : activeState === 1
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setCompanySizeFilter([
        ...AdvFilters.companySize.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 2 ? 0 : 2,
            }
            : itm
        ),
      ])
    );
  };

  const handleAdvancedFilterCompanySizeHighlight = (activeState, name) => {
    setFilterCount(
      activeState === 1
        ? FilterCount - 1
        : activeState === 2
          ? FilterCount
          : FilterCount + 1
    );
    dispatch(
      setCompanySizeFilter([
        ...AdvFilters.companySize.map((itm) =>
          itm.name === name
            ? {
              name: itm.name,
              value: activeState === 1 ? 0 : 1,
            }
            : itm
        ),
      ])
    );
  };
  const handleStrictSkill = (val) => {
    if (val === "OR" && AdvFilters.skillsStrict)
      dispatch(setStrictSkills(false));
    else if (val === "AND" && !AdvFilters.skillsStrict)
      dispatch(setStrictSkills(true));
  };
  const handleSkillStatus = (val) => {
    if (val === "CURRENT") {
      if (AdvFilters.skillsStatus == false) {
        dispatch(setSkillsStatus(null))
      } else {
        dispatch(setSkillsStatus(false))
      }
    }
    else if (val === "PAST") {
      if (!AdvFilters.skillsStatus) {
        dispatch(setSkillsStatus(true));
      } else {
        dispatch(setSkillsStatus(null));
      }
    }

    // else if (val === "PAST" && !AdvFilters.skillsStatus)
    //   dispatch(setSkillsStatus(true));

  };

  const handleStrictSpecialty = (val) => {
    if (val === "OR" && AdvFilters.specialtiesStrict)
      dispatch(setStrictSpecialties(false));
    else if (val === "AND" && !AdvFilters.specialtiesStrict)
      dispatch(setStrictSpecialties(true));
  };
  const handleSpecialtyStatus = (val) => {
    // if (val === "CURRENT") dispatch(setSpecialtiesStatus(false));
    // else if (val === "PAST" && !AdvFilters.specialtiesStatus)
    //   dispatch(setSpecialtiesStatus(true));
    if (val === "CURRENT") {
      if (AdvFilters.specialtiesStatus == false) {
        dispatch(setSpecialtiesStatus(null))
      } else {
        dispatch(setSpecialtiesStatus(false))
      }
    }
    else if (val === "PAST") {
      if (!AdvFilters.specialtiesStatus) {
        dispatch(setSpecialtiesStatus(true));
      } else {
        dispatch(setSpecialtiesStatus(null));
      }
    }
  };
  const handleLocationStatus = (val) => {
    if (val === "CURRENT") dispatch(setLocationStatus(false));
    else if (val === "PAST" && !AdvFilters.locationStatus)
      dispatch(setLocationStatus(true));
  };

  const handleTotalExpChangeRange = (value) => {
    if (
      AdvFilters.totalExperience.active === false &&
      (value.min !== 1 || value.max !== 5)
    ) {
      setFilterCount((prev) => prev + 1);
      dispatch(toggleTotalExperienceFilter());
    }
    dispatch(setTotalExperienceFilter(value));
  };
  const handleYearCurrentCompanyRange = (value) => {
    if (
      AdvFilters.yearCurrentCompany.active === false &&
      (value.min !== 1 || value.max !== 5)
    ) {
      setFilterCount((prev) => prev + 1);
      dispatch(setYearCurrentCompanyToggle());
    }
    dispatch(setYearCurrentCompanyFilter(value));
  };
  const handleYearCurrentPositionRange = (value) => {
    if (
      AdvFilters.yearCurrentPosition.active === false &&
      (value.min !== 1 || value.max !== 5)
    ) {
      setFilterCount((prev) => prev + 1);
      dispatch(setYearCurrentPositionToggle());
    }
    dispatch(setYearCurrentPositionFilter(value));
  };

  const handleYearOfGraduationRange = (value) => {
    if (
      AdvFilters.year_of_graduations.active === false &&
      (value.min !== 2015 || value.max !== 2020)
    ) {
      setFilterCount((prev) => prev + 1);
      dispatch(setYearOfGraduationToggle());
    }
    dispatch(setYearOfGraduationFilter(value));
  };

  const handleRelevantExpChangeRange = (value) => {
    if (
      AdvFilters.relevantExperience.active === false &&
      (value.min !== 1 || value.max !== 5)
    ) {
      setFilterCount((prev) => prev + 1);
      dispatch(toggleRelevantExperienceFilter());
    }
    dispatch(setRelevantExperienceFilter(value));
  };

  const handleCurrentTitleStrict = (e) => {
    dispatch(toggleStrictCurrentTitle());
  };
  const handleLocationStrict = (e) => {
    dispatch(toggleStrictLocation());
  };
  const handlePastLocationStrict = (e) => {
    console.log(e)
    //TODO:
    // dispatch(toggleStrictLocation());
  };

  const handlePastTitleStrict = (e) => {
    dispatch(toggleStrictPastTitle());
  };

  const handleEducationStrict = (e) => {
    dispatch(toggleStrictEducation());
  };

  const handleClickBoolean = (e) => {
    setShowBooleanFilters(!showBooleanFilters);
  };
  const totalExperience = {
    min: AdvFilters.totalExperience.value.min,
    max: AdvFilters.totalExperience.value.max,
  };
  const yearCurrentCompany = {
    min: AdvFilters.yearCurrentCompany.value.min,
    max: AdvFilters.yearCurrentCompany.value.max,
  };
  const yearCurrentPosition = {
    min: AdvFilters.yearCurrentPosition.value.min,
    max: AdvFilters.yearCurrentPosition.value.max,
  };
  const yearOfGraduation = {
    min: AdvFilters.year_of_graduations.value.min,
    max: AdvFilters.year_of_graduations.value.max,
  };
  const relevantExperience = {
    min: AdvFilters.relevantExperience.value.min,
    max: AdvFilters.relevantExperience.value.max,
  };

  AdvFilters.skills = uniqBy(AdvFilters?.skills, "name");

  return (
    <React.Fragment>
      <Modal className="advancedFilterSection" isOpen={Show}>
        <ModalBody>
          <Card>
            <CardHeader className="filterNavHeader" style={{ padding: "16px" }}>
              <nav className="filterNav">
                <div className="leftFilterNav">
                  <h4 className="filterNavHeading">Advanced Filters</h4>
                </div>
                <span className="rightFilterNav">
                  {FilterCount > 0 && (
                    <a
                      className="filterClear"
                      onClick={handleAdvancedFilterClear}
                    >
                      Clear({`${FilterCount}`})
                    </a>
                  )}

                  {filters[0].length > 1 || filters[0][0]?.selectedOne ? (
                    <a
                      className="filterClear"
                      onClick={handleAdvancedBooleanFilterClear}
                    >
                      Clear
                    </a>
                  ) : (
                    ""
                  )}
                  <span className="filterBorder" />
                  <Button
                    outline
                    color="primary"
                    className="filterCancelButton"
                    onClick={handleAdvancedFilterCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    className="filterApplyButton"
                    onClick={handleClickApply}
                  >
                    Apply
                  </Button>
                </span>
              </nav>
            </CardHeader>
            {showBooleanFilters ? (
              <FilterByBoolean />
            ) : (
              <CardBody>
                <React.Suspense fallback={<div></div>}>
                  <div className="bottomFilterBodyContainer">
                    <span className="bottomFilterBody">
                      <FilterSection
                        title="Skills"
                        suggestions={uniq(skillsSuggestions)}
                        onSuggestionsFetchRequested={
                          onSkillsSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onSkillsSuggestionsClearRequested
                        }
                        handleClick={handleStrictSkill}
                        onSuggestionSelected={onSkillsSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputSkillsProps}
                        pillArray={AdvFilters.skills}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterSkillsEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterSkillsHighlight
                        }
                        active={AdvFilters.skillsStrict}
                        secondClick={handleSkillStatus}
                        secondActive={AdvFilters.skillsStatus}
                      />
                      <FilterSection
                        title="Schools"
                        suggestions={uniq(schoolsSuggestions)}
                        onSuggestionsFetchRequested={
                          onSchoolsSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onSchoolsSuggestionsClearRequested
                        }
                        onSuggestionSelected={onSchoolsSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputSchoolsProps}
                        pillArray={AdvFilters.school}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterSchoolsEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterSchoolsHighlight
                        }
                        active={AdvFilters.skillsStrict}
                      />
                      <hr className="advFilterNewLine" />
                      <FilterSection
                        title="Business"
                        suggestions={uniq(specialtiesSuggestions)}
                        onSuggestionsFetchRequested={
                          onSpecialtiesSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onSpecialtiesSuggestionsClearRequested
                        }
                        onSuggestionSelected={onSpecialtiesSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputSpecialtiesProps}
                        pillArray={AdvFilters.specialties}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterSpecialtiesEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterSpecialtiesHighlight
                        }
                        active={AdvFilters.specialtiesStrict}
                        handleClick={handleStrictSpecialty}
                        secondActive={AdvFilters.specialtiesStatus}
                        secondClick={handleSpecialtyStatus}
                      />
                      <FilterSection
                        title="Year of Graduation"
                        onChange={(e) => {
                          if (e.target.checked)
                            setFilterCount((prev) => prev + 1);
                          else setFilterCount((prev) => prev - 1);
                          dispatch(setYearOfGraduationToggle());
                        }}
                        active={AdvFilters.year_of_graduations.active}
                        experience={yearOfGraduation}
                        handleClick={handleYearOfGraduationRange}
                        value={AdvFilters.year_of_graduations.value}
                      />
                      <hr className="advFilterNewLine" />
                      <FilterSection
                        title="Education"
                        suggestions={uniq(EducationSuggestions)}
                        onSuggestionsFetchRequested={
                          onEducationSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onEducationSuggestionsClearRequested
                        }
                        onSuggestionSelected={onEducationSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputEducationProps}
                        active={AdvFilters.educationStrict}
                        pillArray={AdvFilters.education}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterEducationEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterEducationHighlight
                        }
                        onChange={handleEducationStrict}
                      />
                      {/* ETHNICITY */}
                      <FilterSection
                        title="Diversity"
                        pillArray={AdvFilters.ethnicity}
                        suggestions={uniq(EthnicitySuggestions)}
                        onSuggestionsFetchRequested={
                          onEthnicitySuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onEthnicitySuggestionsClearRequested
                        }
                        onSuggestionSelected={onEthnicitySuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputEthnicityProps}
                        // active={AdvFilters.educationStrict}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterEthnicityEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterEthnicityHighlight
                        }
                      />
                      <hr className="advFilterDevider" />
                      <FilterSection
                        title="Current Title"
                        suggestions={uniq(CurrentTitleSuggestions)}
                        onSuggestionsFetchRequested={
                          onCurrentTitleSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onCurrentTitleSuggestionsClearRequested
                        }
                        onSuggestionSelected={onCurrentTitleSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputCurrentTitleProps}
                        active={AdvFilters.currentTitleStrict}
                        pillArray={AdvFilters.currentTitle}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterCurrentTitleEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterCurrentTitleHighlight
                        }
                        onChange={handleCurrentTitleStrict}
                      />
                      <FilterSection
                        title="Past Title"
                        suggestions={uniq(PastTitleSuggestions)}
                        onSuggestionsFetchRequested={
                          onPastTitleSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onPastTitleSuggestionsClearRequested
                        }
                        onSuggestionSelected={onPastTitleSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputPastTitleProps}
                        active={AdvFilters.pastTitleStrict}
                        pillArray={AdvFilters.pastTitle}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterPastTitleEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterPastTitleHighlight
                        }
                        onChange={handlePastTitleStrict}
                      />
                      <hr className="advFilterDevider" />
                      <FilterSection
                        title="Current Company"
                        suggestions={uniq(CurrentCompanySuggestions)}
                        onSuggestionsFetchRequested={
                          onCurrentCompanySuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onCurrentCompanySuggestionsClearRequested
                        }
                        onSuggestionSelected={
                          onCurrentCompanySuggestionSelected
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputCurrentCompanyProps}
                        pillArray={AdvFilters.currentCompany}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterCurrentCompanyEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterCurrentCompanyHighlight
                        }
                      />
                      <FilterSection
                        title="Past Company"
                        suggestions={uniq(PastCompanySuggestions)}
                        onSuggestionsFetchRequested={
                          onPastCompanySuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onPastCompanySuggestionsClearRequested
                        }
                        onSuggestionSelected={onPastCompanySuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputPastCompanyProps}
                        pillArray={AdvFilters.pastCompany}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterPastCompanyEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterPastCompanyHighlight
                        }
                      />

                      <hr className="advFilterNewLine" />
                      <FilterSection
                        title="Current Employee Count"
                        pillArray={AdvFilters.companySize}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterCompanySizeEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterCompanySizeHighlight
                        }
                      />
                      <hr className="advFilterDevider" />
                      <FilterSection
                        title="Total Experience"
                        onChange={(e) => {
                          if (e.target.checked)
                            setFilterCount((prev) => prev + 1);
                          else setFilterCount((prev) => prev - 1);
                          dispatch(toggleTotalExperienceFilter());
                        }}
                        active={AdvFilters.totalExperience.active}
                        experience={totalExperience}
                        handleClick={handleTotalExpChangeRange}
                        value={AdvFilters.totalExperience.value}
                      />
                      <FilterSection
                        title="Relevant Experience"
                        onChange={(e) => {
                          if (e.target.checked)
                            setFilterCount((prev) => prev + 1);
                          else setFilterCount((prev) => prev - 1);
                          dispatch(toggleRelevantExperienceFilter());
                        }}
                        active={AdvFilters.relevantExperience.active}
                        experience={relevantExperience}
                        handleClick={handleRelevantExpChangeRange}
                        value={AdvFilters.relevantExperience.value}
                      />
                      <hr className="advFilterDevider" />
                      <FilterSection
                        title="Location"
                        suggestions={uniq(LocationSuggestions)}
                        onSuggestionsFetchRequested={
                          onLocationSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onLocationSuggestionsClearRequested
                        }
                        onSuggestionSelected={onLocationSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputLocationProps}
                        active={AdvFilters.locationStrict}
                        pillArray={AdvFilters.location}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterLocationEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterLocationHighlight
                        }
                        onChange={handleLocationStrict}
                        secondActive={AdvFilters.locationStatus}
                        secondClick={handleLocationStatus}
                      />
                      <FilterSection
                        title="Past Location"
                        suggestions={uniq(LocationSuggestions)}
                        onSuggestionsFetchRequested={
                          onPastLocationSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onPastLocationSuggestionsClearRequested
                        }
                        onSuggestionSelected={onPastLocationSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputPastLocationProps}
                        active={AdvFilters.pastLocationStrict}
                        pillArray={AdvFilters.pastLocation}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterPastLocationEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterPastLocationHighlight
                        }
                        onChange={handlePastLocationStrict}
                        secondActive={AdvFilters.pastLocationStatus}
                        secondClick={handleLocationStatus}
                      />

                      <hr className="advFilterDevider" />
                      <FilterSection
                        title="Spoken Language"
                        suggestions={uniq(languagesSuggestions)}
                        onSuggestionsFetchRequested={
                          onLanguagesSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onLanguagesSuggestionsClearRequested
                        }
                        onSuggestionSelected={onLanguagesSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputLanguageProps}
                        pillArray={AdvFilters.languages}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterLanguagesEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterLanguagesHighlight
                        }
                      />
                      <hr className="advFilterDevider" />
                      <FilterSection
                        title="First Name"
                        suggestions={uniq(firstNamesSuggestions)}
                        onSuggestionsFetchRequested={
                          onFirstNamesSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onFirstNamesSuggestionsClearRequested
                        }
                        onSuggestionSelected={onFirstNamesSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputFirstProps}
                        pillArray={AdvFilters.firstNames}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterFirstNamesEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterFirstNamesHighlight
                        }
                      />
                      <FilterSection
                        title="Last Name"
                        suggestions={uniq(lastNamesSuggestions)}
                        onSuggestionsFetchRequested={
                          onLastNamesSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          onLastNamesSuggestionsClearRequested
                        }
                        onSuggestionSelected={onLastNamesSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputLastProps}
                        pillArray={AdvFilters.lastNames}
                        handleAdvancedFilterPillEliminate={
                          handleAdvancedFilterLastNamesEliminate
                        }
                        handleAdvancedFilterPillHighlight={
                          handleAdvancedFilterLastNamesHighlight
                        }
                      />
                      <hr className="advFilterDevider" />
                      <FilterSection
                        title="Years in current company"
                        onChange={(e) => {
                          if (e.target.checked)
                            setFilterCount((prev) => prev + 1);
                          else setFilterCount((prev) => prev - 1);
                          dispatch(setYearCurrentCompanyToggle());
                        }}
                        active={AdvFilters.yearCurrentCompany.active}
                        experience={yearCurrentCompany}
                        handleClick={handleYearCurrentCompanyRange}
                        value={AdvFilters.yearCurrentCompany.value}
                      />
                      <FilterSection
                        title="Years in current position"
                        onChange={(e) => {
                          if (e.target.checked)
                            setFilterCount((prev) => prev + 1);
                          else setFilterCount((prev) => prev - 1);
                          dispatch(setYearCurrentPositionToggle());
                        }}
                        active={AdvFilters.yearCurrentPosition.active}
                        experience={yearCurrentPosition}
                        handleClick={handleYearCurrentPositionRange}
                        value={AdvFilters.yearCurrentPosition.value}
                      />
                    </span>
                    <span className="scoringFilterSpan">
                      <ScoringFilterSection
                        titleFlag={titleFlag}
                        skillFlag={skillFlag}
                        industryFlag={industryFlag}
                        experienceFlag={experienceFlag}
                        educationFlag={educationFlag}
                        specialtyFlag={specialtyFlag}
                        FilterCount={FilterCount}
                        setFilterCount={setFilterCount}
                        Show={Show}
                        setShow={setShow}
                        setSelectAllProspectsFlag={setSelectAllProspectsFlag}
                        search={search}
                        push={push}
                        activeTab={activeTab}
                      />
                    </span>
                  </div>
                </React.Suspense>
              </CardBody>
            )}
          </Card>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(
  connect((state) => {
    // console.log("check state to check state", state)
    return {
      ...state.prospectsView.advFiltersData.data,
      pastLocations: state.prospectsView.advFiltersData.data.pastlocations,
      skills_synonyms: state.prospectsView.advFiltersData.skills_synonyms,
      titles_synonyms: state.prospectsView.advFiltersData.titles_synonyms,
      educations_synonyms:
        state.prospectsView.advFiltersData.educations_synonyms,
      specialties_synonyms:
        state.prospectsView.advFiltersData.specialties_synonyms,
      AdvFilters: state.prospectsView.advFiltersMeta,
      ScoringFilters: state.prospectsView.scoringFiltersMeta,
      rawBooleanTerm: state.prospectsView.advBooleanFilters.rawBooleanTerm,
      filters: state.prospectsView.advBooleanFilters.filters,
      startSearch: state.prospectsView.findMoreProspect.startSearch,
    };
  })(AdvanceFilterSection)
);
