import React from "react";

export const IndustryFAQ1 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            A Business is a sub-industry which indicates the domain of products
            or services a certain company works in.
          </li>
          <li>
            For example, Meta’s businesses are Virtual Reality, Social Media,
            Connectivity and Augmented Reality.
          </li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ2 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            The gray pills are the suggested but not selected
            business/specialties
          </li>
          <li>The orange pills are the selected business/specialties</li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ3 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            The business/specialty of the company that you have chosen to hire
            for
          </li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ4 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>From their respective Websites and Linkedin Pages</li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ5 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            The business/specialty of the company that you have chosen to hire
            from
          </li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ6 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            The business/specialty of the companies that your best fit has
            worked in and has experience of
          </li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ7 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>They are the logos of companies your best fit has worked in</li>
          <li>Business/Specialty displayed is of these companies</li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ8 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            They are suggested on the basis of the specialties you select from
            hiring and similar companies
          </li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ9 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            It is a list of all the business/specialties that you have selected
          </li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ10 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Yes</li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ11 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>You should not add skills or titles in here</li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ12 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            Business/Specialty such as; artificial intelligence, ecommerce,
            internet, retail, etc.
          </li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ13 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            It means that specific Business/Specialty already exists in your
            list
          </li>
        </ul>
      </p>
    </div>
  );
};

export const IndustryFAQ14 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>To investigate the domain of the prospect you are looking for</li>
        </ul>
      </p>
    </div>
  );
};
