import React from 'react'

import './NumberedDetails.scss'

const NumberedDetails = ({
    detailName='',
    detailNumber='',
}) => {
    return (
        <div
            className="numberedDetails"
        >
            <h1>{detailName}</h1>
            <h3>{detailNumber}</h3>
        </div>
    )
}

export default NumberedDetails
