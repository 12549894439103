import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import InfoSvg from "../../../../src/uiLibrary/SVGs/InfoSvg";
import {
  addAdditionalTitle,
  addJobTitle,
  changeAdditionalGenericTitle,
  changeAdditionalJobTitle,
  changeJobTitle,
  removeAdditionalTitle,
  setIsEdited,
  setJobSetupView,
} from "../../../actions/JobSetup";
import BigRoundButton from "../../../uiLibrary/BigRoundButton";
import DiscreteRangeSlider from "../../../uiLibrary/DiscreteRangeSlider";
import PillButton from "../../../uiLibrary/PillButton";
import ProfileLiked from "../../../uiLibrary/ProfileLiked/ProfileLiked";
import SecondaryButton from "../../../uiLibrary/SecondaryButton";
import CrossSvg from "../../../uiLibrary/SVGs/CrossSvg";
import JobSetupStep06 from "../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep06";
import PlusSvg from "../../../uiLibrary/SVGs/PlusSvg";
import TabButton from "../../../uiLibrary/TabButton";
import { kiloConversion } from "../../../utils";
import { Mixpanel } from "../../../utils/Mixpanel";
import JobSetupAutoSuggest from "../JobSetupAutoSuggest";
import "./criteriaAdditionalTitles.scss";

const TitleInput = ({
  filter,
  inputValue,
  onChange,
  toggleFocus,
  onSliderChange,
  companySizeOptions,
  company_size,
  onCrossClick,
  onApplyAllClick,
  isReview,
}) => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [opacity, setOpacity] = useState(0);
  return (
    <span
      onMouseEnter={(e) => setOpacity(1)}
      onMouseLeave={(e) => setOpacity(0)}
    >
      <JobSetupAutoSuggest
        apiType="title"
        shouldFetch={shouldFetch}
        toggleFocus={toggleFocus}
        filter={filter}
        inputValue={inputValue}
        handleChangeInput={(e) => {
          setShouldFetch(true);
          onChange(e.target.value);
        }}
        handleSuggestionSelected={(e) => {
          setShouldFetch(false);
          onChange(e);
        }}
      />
      {company_size.map(({ min, max }, i) => (
        <DiscreteRangeSlider
          key={i}
          value={{ min, max }}
          discreteSteps={companySizeOptions}
          onChange={onSliderChange}
          resultLabel={`${kiloConversion(min)} to ${
            max === 10001 ? "10K+" : kiloConversion(max)
          }`}
          getLabel={(value) =>
            value === 10001 ? "10K+" : kiloConversion(value)
          }
        />
      ))}
      {isReview === "1" ? (
        <></>
      ) : (
        <>
          <button
            className="titleInputApplyToAll"
            style={{ opacity }}
            onClick={(e) => onApplyAllClick(company_size)}
          >
            Apply to all
          </button>
          <BigRoundButton onClick={onCrossClick}>
            <CrossSvg />
          </BigRoundButton>
        </>
      )}
    </span>
  );
};

const CriteriaAdditionalTitles = ({
  mainTitle,
  titles,
  additionalTitles,
  suggested_titles,
  cards,
  tabs,
  currentTab,
  isReview,
  shouldSelectLastTab,
  dispatch,
  viewHistory,
  currentTabHistory,
}) => {
  const [editingMode, setEditingMode] = useState(false);
  const [editorValue, setEditorValue] = useState("");

  const [myTimeout, setMyTimeout] = useState(null);

  const [toggleFocusArray, setToggleFocusArray] = useState([]);

  // const suggestedTitles = Object.keys(titles).filter(
  //   (key) => !titles[key].isMainTitle
  // );
  const suggestedTitles = Object.keys(titles);

  const selectedTitles = [...new Set(suggestedTitles.filter((key) => titles[key].selected))];
  const unselectedTitles = suggestedTitles.filter(
    (key) => !titles[key].selected
  );
  const generatedTitles = [...new Set(suggestedTitles.filter((key) => titles[key]?.source?.includes("generated")
  ))];
  const idealTitles = [...new Set(suggestedTitles.filter((key) =>titles[key]?.source?.includes("idealProfile")))];

  const companySizeOptions = [0, 1, 10, 50, 200, 500, 1000, 5000, 10000, 10001];

  useEffect(() => {
    const newTabs = { tabs: [...tabs] };
    suggested_titles
      .filter(({ name, ...rest }) => {
        const company_size = rest.company_size
          ? rest.company_size
          : [{ min: rest.company_size_min, max: rest.company_size_max }];
        // console.log("company_size", { rest, company_size })
        const mainTitle =
          titles[
            Object.keys(titles).filter((key) => titles[key].isMainTitle)?.[0]
          ];
        return (
          mainTitle?.name?.trim()?.toLowerCase() !==
            name?.trim()?.toLowerCase() &&
          mainTitle?.company_size?.[0].min !== company_size?.[0].min &&
          mainTitle?.company_size?.[0].max !== company_size?.[0].max
        );
      })
      .map((ttl) => {
        const {
          name,
          company_size,
          company_size_min,
          company_size_max,
          source = [],
          sourceId = [],
          selected = false,
        } = ttl;
        if (newTabs.tabs.length < 3) {
          source.forEach((src) => {
            if (newTabs.tabs.length === 3) {
              return false;
            }
            switch (src) {
              case "idealProfile":
                if (!newTabs.tabs.includes("From best fits")) {
                  if (newTabs.tabs.includes("Generated")) {
                    newTabs.tabs = ["Generated", "From best fits", ...tabs];
                  } else {
                    newTabs.tabs = ["From best fits", ...tabs];
                  }
                }
                break;
              case "generated":
                if (!newTabs.tabs.includes("Generated")) {
                  newTabs.tabs = ["Generated", ...newTabs.tabs];
                }
                break;
              default:
                break;
            }
          });
        }

        const isViewHistoryEqual = isEqual(viewHistory, [
          "Additional Titles",
          "Main Title",
          "Business, Specialties or Industry",
          "Company Details",
          "Add Best Fit Profiles",
          "Job Details",
        ]);

        const isCurrentTabHistoryEqual = isEqual(currentTabHistory, [1, 0]);

        if (Array.isArray(company_size) && company_size.length > 0) {
          console.log("HERHEHREHRHEHHREHRE 3", {
            viewHistory,
            currentTabHistory,
            isViewHistoryEqual,
            isCurrentTabHistoryEqual,
          });
          console.log("DISPATCH [3]");

          dispatch(
            addJobTitle({
              ...ttl,
              name,
              company_size,
              selected,
              source,
              sourceId,
              fromSuggested: false, //isViewHistoryEqual && isCurrentTabHistoryEqual
            })
          );
        } else {
          const min =
            company_size_min >= 10000
              ? 10000
              : companySizeOptions.includes(company_size_min)
              ? companySizeOptions.filter((n) => company_size_min === n)?.[0]
              : 0;
          
          console.log("DISPATCH [2]");

          dispatch(
            addJobTitle({
              ...ttl,
              name,
              company_size: [
                {
                  min,
                  max:
                    company_size_max > 10000
                      ? 10001
                      : companySizeOptions.includes(company_size_max)
                      ? companySizeOptions.filter(
                          (n) => company_size_max === n
                        )?.[0]
                      : companySizeOptions.includes(company_size_min)
                      ? companySizeOptions[companySizeOptions.indexOf(min) + 1]
                      : 10001,
                },
              ],
              selected,
              source,
              sourceId,
              fromSuggested: true, //isViewHistoryEqual && isCurrentTabHistoryEqual
            })
          );
        }
      });
    if (newTabs.tabs.length > 1) {
      dispatch(
        setJobSetupView({
          ...newTabs,
          currentTab: shouldSelectLastTab ? newTabs.tabs.length - 1 : 0,
          shouldSelectLastTab: false,
        })
      );
    } else if (shouldSelectLastTab) {
      dispatch(
        setJobSetupView({
          shouldSelectLastTab: false,
        })
      );
    }
  }, []);

  useEffect(() => {
    const profileSelectedValues =
      tabs[currentTab] === "From best fits"
        ? idealTitles.map((key) => ({
            ...titles[key],
            key,
          }))
        : [];
    dispatch(
      setJobSetupView({
        profileSelectedValues,
      })
    );
  }, [titles, currentTab]);

  useEffect(() => {
    Mixpanel.track(`Additional Titles ${tabs[currentTab]}`);
  }, [currentTab]);

  const handleChangeCompanySize = (value, key) => {
    dispatch(setIsEdited(true))
    dispatch(
      changeJobTitle({
        key,
        value: {
          company_size: value,
        },
      })
    );
  };

  const handleChangeAdditionalCompanySize = (value, index) => {
    dispatch(setIsEdited(true))
    dispatch(
      changeAdditionalJobTitle({
        index,
        values: {
          company_size: value,
        },
      })
    );
  };

  const handleNameChange = (name, key) => {
    dispatch(setIsEdited(true))
    dispatch(
      changeJobTitle({
        key,
        value: {
          name,
        },
      })
    );
  };

  const handleAdditionalNameChange = (name, index) => {
    dispatch(setIsEdited(true))
    dispatch(changeAdditionalJobTitle({ index, values: { name } }));
    dispatch(changeAdditionalGenericTitle({ i: index }));
  };

  const toggleTitleSelection = (key) => {
    if (mainTitle?.name?.toLowerCase() == titles[key]?.name?.toLowerCase()) {
      // if(titles[key].isMaintitle && titles[key].source?.includes("idealProfile") && titles[key].selected ){
      // toast.dismiss();
      // toast(
      //   ({ closeToast }) => (
      //     <ProfileLiked
      //       header={`This title can not be unselected as this is your main title`}
      //       // description={`This title and can result in poor results. Do you want to add it?`}
      //     >
      //     </ProfileLiked>
      //   ),
      //   {
      //     closeButton: false,
      //     hideProgressBar: true,
      //     style: {
      //       backgroundColor: "#A0A0A0",
      //       borderRadius: "8px",
      //       width: "514px",
      //     },
      //   }
      // )
      // if(titles[key].isMainTitle && titles[key].source?.includes("idealProfile") ){
      //   dispatch(changeJobTitle({
      //     key,
      //     value: {
      //       selected: true
      //     }
      //   }))
      // }
      // if (titles[key].isMaintitle && titles[key].source?.includes("idealProfile") && titles[key].selected) {
      //   toast.dismiss();
      //   toast(
      //     ({ closeToast }) => (
      //       <ProfileLiked
      //         header={`This title can not be unselected as this is your main title`}
      //       // description={`This title and can result in poor results. Do you want to add it?`}
      //       >
      //       </ProfileLiked>
      //     ),
      //     {
      //       closeButton: false,
      //       hideProgressBar: true,
      //       style: {
      //         backgroundColor: "#A0A0A0",
      //         borderRadius: "8px",
      //         width: "514px",
      //       },
      //     }
      //   )
    } else if (
      (titles[key].generic || titles[key].isRare) &&
      titles[key].source?.includes("idealProfile") &&
      !titles[key].selected
    ) {
      const term = titles[key].generic ? "generic" : "rare";
      toast.dismiss();
      toast(
        ({ closeToast }) => (
          <ProfileLiked
            header={`Add title?`}
            description={`This title is ${term} which may return low quality profiles. Do you want to add it?`}
          >
            <Button
              className="closeButtonPopup"
              onClick={(e) => {
                dispatch(
                  setJobSetupView({
                    profileHighlightValue: titles[key].name
                      .toLowerCase()
                      .trim(),
                  })
                );
                dispatch(setIsEdited(true))
                dispatch(
                  changeJobTitle({
                    key,
                    value: {
                      selected: !titles[key].selected,
                    },
                  })
                );
              }}
            >
              Yes, Keep the title
            </Button>
            <Button className="reviewProfileBtn" onClick={closeToast}>
              No, discard it
            </Button>
          </ProfileLiked>
        ),
        {
          closeButton: false,
          hideProgressBar: true,
          style: {
            backgroundColor: "#A0A0A0",
            borderRadius: "8px",
            width: "514px",
          },
        }
      );
    } else {
      if (!titles[key].selected) {
        dispatch(
          setJobSetupView({
            profileHighlightValue: titles[key].name.toLowerCase().trim(),
          })
        );
      }
      dispatch(setIsEdited(true))
      dispatch(
        changeJobTitle({
          key,
          value: {
            selected: !titles[key].selected,
          },
        })
      );
    }
  };

  const handleRemoveAdditionalTitle = (index) => {           
    dispatch(setIsEdited(true))
    setToggleFocusArray((toggleFocusArray) =>
      toggleFocusArray.filter((itm, i) => i !== index)
    );
    dispatch(removeAdditionalTitle(index));
  };

  const handleAddTitle = (name) => {
    if (
      Object.keys(titles)
        .map((key) => titles[key]?.name.trim().toLowerCase())
        .includes(name.trim().toLowerCase())
    ) {
      const key = Object.keys(titles).filter(
        (key) =>
          titles[key]?.name.trim().toLowerCase() === name.trim().toLowerCase()
      )?.[0];
      dispatch(
        changeJobTitle({
          key,
          value: {
            selected: true,
          },
        })
      );
    } else {
      //dispatch(setIsEdited(true))
      console.log("DISPATCH [1]");
      dispatch(
        addJobTitle({
          name,
          selected: true,
          company_size: [
            {
              min: 0,
              max: 10001,
            },
          ],
        })
      );
    }
    setEditorValue("");
    setEditingMode(false);
  };

  const handleDiscardTitle = () => {
    setEditorValue("");
    setEditingMode(false);
  };

  const handleOpenEditor = (e) => {
    // if (editorValue !== "") {
    //   setEditingMode("");
    // }
    // setEditingMode(true);
    if (additionalTitles.some((itm) => itm.name.trim() === "")) {
      let empytyIndex = additionalTitles.length - 1;
      let emptyIndexSet = false;
      for (
        let index = additionalTitles.length - 1;
        index >= 0 && !emptyIndexSet;
        index--
      ) {
        const { name } = additionalTitles[index];
        if (name.trim() === "") {
          empytyIndex = index;
          emptyIndexSet = true;
        }
      }
      setToggleFocusArray((toggleFocusArray) =>
        toggleFocusArray.map((itm, i) => (i === empytyIndex ? !itm : itm))
      );
    } else {
      setToggleFocusArray((toggleFocusArray) => [...toggleFocusArray, false]);
      dispatch(addAdditionalTitle({ company_size: [{ min: 0, max: 10001 }] }));
      setTimeout(() => {
        document
          .getElementById("addAdditionalTitleButton")
          ?.scrollIntoView({ behavior: "smooth" });
      });
    }
  };

  const handleMouseEnter = (key) => {
    if (myTimeout) {
      clearTimeout(myTimeout);
      setMyTimeout(
        setTimeout(
          (e) =>
            dispatch(
              setJobSetupView({
                profileHighlightValue: titles[key].name.toLowerCase().trim(),
              })
            ),
          300
        )
      );
    } else {
      setMyTimeout(
        setTimeout(
          (e) =>
            dispatch(
              setJobSetupView({
                profileHighlightValue: titles[key].name.toLowerCase().trim(),
              })
            ),
          300
        )
      );
    }
  };

  const handleMouseLeave = (key) => {
    clearTimeout(myTimeout);
    dispatch(
      setJobSetupView({
        profileHighlightValue: "",
      })
    );
  };

  const onApplyAllClick = (company_size) => {
    selectedTitles.forEach((key) => handleChangeCompanySize(company_size, key));
    additionalTitles.forEach((x, index) =>
      handleChangeAdditionalCompanySize(company_size, index)
    );
    dispatch(setIsEdited(true))
  };

  return (
    <div className="criteriaAdditionalTitle">
      <div className="criteriaAdditionalTitleHeader">
        <span>
          <JobSetupStep06 selected={true} />
          <h2>Additional Job Titles</h2>
        </span>
        <span>
          {tabs.map((tab, index) => (
            <TabButton
              key={tab}
              tab={{ name: tab, index, disabled: true }}
              selected={tab === tabs[currentTab]}
              // onClick={({ index }) =>
              //   dispatch(setJobSetupView({ currentTab: index }))
              // }
            />
          ))}
        </span>
      </div>
      {(() => {
        switch (tabs[currentTab]) {
          case "Generated":
            return (
              <div className="criteriaAdditionalTitleBody">
                <span>
                  {generatedTitles.map((key) => (
                    <PillButton
                      key={key}
                      selected={titles[key].selected}
                      highlight={true}
                      onClick={(e) =>
                        isReview === "1"
                          ? () => false
                          : toggleTitleSelection(key)
                      }
                      onMouseEnter={(e) => handleMouseEnter(key)}
                      onMouseLeave={(e) => handleMouseLeave(key)}
                    >
                      {titles[key].name}
                    </PillButton>
                  ))}
                </span>
              </div>
            );
          case "From best fits":
            return (
              <div className="criteriaAdditionalTitleBody">
                <span>
                  {idealTitles
                    .filter(
                      (key) =>
                        mainTitle.name?.toLowerCase() !==
                        titles[key].name?.toLowerCase()
                    )
                    .map((key, index) => (
                      <PillButton
                        key={key}
                        selected={titles[key].selected}
                        highlight={true}
                        onClick={(e) =>
                          isReview === "1"
                            ? () => false
                            : toggleTitleSelection(key)
                        }
                        onMouseEnter={(e) => handleMouseEnter(key)}
                        onMouseLeave={(e) => handleMouseLeave(key)}
                      >
                        {titles[key].name}
                      </PillButton>
                    ))}
                </span>
              </div>
            );
          case "Details":
            return (
              <React.Fragment>
                <div
                  style={{
                    width: "min(80%, 1080px)",
                    alignSelf: "center",
                  }}
                  className="criteriaAdditionalTitleBody"
                >
                  <span>
                    <h5>Additional Title</h5>
                    <h5>Employee Count</h5>
                  </span>
                  {selectedTitles.map((key) => (
                    <TitleInput
                      filter={(d) =>
                        !selectedTitles
                          .map((key) => titles[key]?.name.trim().toLowerCase())
                          .includes(d.trim().toLowerCase())
                      }
                      inputValue={titles[key].name}
                      onChange={
                        isReview === "1"
                          ? () => false
                          : (value) => handleNameChange(value, key)
                      }
                      onSliderChange={
                        isReview === "1"
                          ? () => false
                          : (e) => handleChangeCompanySize([e], key)
                      }
                      companySizeOptions={companySizeOptions}
                      company_size={titles[key]?.company_size || []}
                      onCrossClick={
                        isReview === "1"
                          ? () => false
                          : (e) => toggleTitleSelection(key)
                      }
                      onApplyAllClick={onApplyAllClick}
                      isReview={isReview}
                    />
                  ))}
                  {additionalTitles.map((ttl, index) => (
                    <>
                      <TitleInput
                        toggleFocus={toggleFocusArray[index]}
                        filter={(d) =>
                          ![
                            ...selectedTitles.map((key) =>
                              titles[key]?.name.trim().toLowerCase()
                            ),
                            ...additionalTitles.map((itm) =>
                              itm?.name.trim().toLowerCase()
                            ),
                          ].includes(d.trim().toLowerCase())
                        }
                        inputValue={ttl.name}
                        onChange={
                          isReview === "1"
                            ? () => false
                            : (value) =>
                                handleAdditionalNameChange(value, index)
                        }
                        onSliderChange={
                          isReview === "1"
                            ? () => false
                            : (e) =>
                                handleChangeAdditionalCompanySize([e], index)
                        }
                        companySizeOptions={companySizeOptions}
                        company_size={ttl?.company_size || []}
                        onCrossClick={
                          isReview === "1"
                            ? () => false
                            : (e) => handleRemoveAdditionalTitle(index)
                        }
                        onApplyAllClick={onApplyAllClick}
                      />
                      {ttl.generic ? (
                        <div
                          className="genericTitle"
                          style={{ display: "flex" }}
                        >
                          <InfoSvg Recommended={true} width="32" height="32" />
                          <h6
                            style={{
                              lineHeight: "23px",
                              marginLeft: "8px",
                              marginBottom: "0",
                              color: "#A0A0A0",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            The title is too vague, which may return low quality
                            profiles. Please add a more specific title for
                            accurate results.
                          </h6>
                        </div>
                      ) : null}
                    </>
                  ))}
                  {selectedTitles.length === 0 &&
                    additionalTitles.length === 0 && (
                      <h3>No additional titles detected</h3>
                    )}
                </div>
                <div
                  style={{
                    width: "min(80%, 1080px)",
                    alignSelf: "center",
                  }}
                  className="criteriaAdditionalTitleFooter"
                >
                  {selectedTitles.length > 0 || additionalTitles.length > 0
                    ? isReview !== "1" && (
                        <button
                          id="addAdditionalTitleButton"
                          className="addMorePrimaryButton"
                          onClick={handleOpenEditor}
                        >
                          <PlusSvg selected={true} />
                          Add a title
                        </button>
                      )
                    : isReview !== "1" && (
                        <SecondaryButton onClick={handleOpenEditor}>
                          <PlusSvg selected={true} />
                          Add a title
                        </SecondaryButton>
                      )}
                  {/* {editingMode ?
                            <span> */}
                  {/* <input
                                    value={editorValue}
                                    onChange={isReview === '1' ? () => false : e=>setEditorValue(e.target.value)}
                                /> */}
                  {/* <JobSetupAutoSuggest
                                  apiType='title'
                                  filter={d=>d.trim()!==""}
                                  inputValue={editorValue}
                                  handleChangeInput={isReview === '1' ? () => false : e=>setEditorValue(e.target.value)}
                                  handleSuggestionSelected={value=>handleAddTitle(value)}
                                /> */}
                  {/* <BigRoundButton 
                                    onClick={isReview === '1' ? () => false : handleAddTitle}  >
                                    <PlusSvg/>
                                </BigRoundButton> */}
                  {/* <BigRoundButton 
                                    onClick={isReview === '1' ? () => false : handleDiscardTitle}
                                >
                                    <CrossSvg
                                        width='16'
                                        height='16'
                                    />
                                </BigRoundButton>
                            </span>:
                            selectedTitles.length>0 ?
                            isReview === '1' ? '' : <button
                                className='addMorePrimaryButton'
                                onClick={isReview === '1' ? () => false : handleOpenEditor}
                            >
                                <PlusSvg selected={true}/>
                                Add a title
                            </button> : 
                            isReview === '1' ? '' : <SecondaryButton
                                onClick={isReview === '1' ? () => false : handleOpenEditor}
                            >
                                <PlusSvg selected={true}/>
                                Add a title
                            </SecondaryButton>
                            } */}
                </div>
              </React.Fragment>
            );
          default:
            break;
        }
      })()}
    </div>
  );
};

export default connect((state) => ({
  mainTitle: state.jobSetup.jobCriteria.mainTitle,
  titles: state.jobSetup.jobCriteria.titles,
  additionalTitles: state.jobSetup.jobCriteria.additionalTitles,
  suggested_titles: state.jobSetup.jobSetup.jobSetup.suggested_titles || [],
  cards: state.jobSetup.viewController.cards,
  tabs: state.jobSetup.viewController.tabs,
  currentTab: state.jobSetup.viewController.currentTab,
  isReview: state.jobSetup?.jobSetup?.isReview,
  shouldSelectLastTab: state.jobSetup.viewController.shouldSelectLastTab,
  viewHistory: state.jobSetup.viewController.history,
  currentTabHistory: state.jobSetup.viewController.currentTabHistory,
}))(CriteriaAdditionalTitles);
