import React, { useState } from 'react'
import SocialHeartButton from '../../uiLibrary/SocialHeartButton'
import SocialMaybeButton from '../../uiLibrary/SocialMaybeButton'
import SocialRejectButton from '../../uiLibrary/SocialRejectButton'
import DeepDetailProfileCard from '../ScoreAndConnect/components/DeepDetailProfileCard'
import QuickDetailProfileCard from '../ScoreAndConnect/components/QuickDetailProfileCard'

const TestComponent = ({history}) => {
    return (

        <div
            style={{
                width: '100vw',
                minHeight: '100vh',
                color: 'white',
                display: 'flex',
                alignItems: 'self-start',
                justifyContent: 'center'
            }}
           
        >
            <div
                style={{
                    width: '50%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <QuickDetailProfileCard/>
            </div>
        </div>
      
    )
}

export default TestComponent