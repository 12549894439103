import React from "react";
import Drawer from "../components/Drawer";
import About from "./About";
import TagSection from "./TagSection";
import OtherExperience from "./OtherExperience";
import "./styles.scss";
import Education from "./Education";
import Experience from "./Experience";
import Header from "./Header";
import { getEntityByProfileID } from "../../../reducers/QuickSetup/profileFeedback";
import { useSelector } from "react-redux";
import RevampProspectFullCardVarient1 from "../../../uiLibrary/RevampProspectFullCardVarient1/RevampProspectFullCardVarient1";

export default function ProfileDrawer({
  isDetailView = true,
  setIsDetailView,
  profile,
  isViewOnly = false,
  showCompanyModal=e=>false
}) {
  // const prospectExperience = experience;
  // const prospectSkills = skills;
  // const prospectEducation = education;
  // const allSpecialties =
  //   prospectExperience?.map(
  //     ({ companySpecialities = [] }) => companySpecialities
  //   ) || [];
  // const prospectSpecialties = [...new Set(allSpecialties?.flat())] || [];

  const currentFeedback = useSelector((state) =>
    getEntityByProfileID(state.QuickSetup.profileFeedback, profile?.id)
  );

  return (
    <div>
      {isDetailView && (
        <Drawer
          isViewOnly={isViewOnly}
          isOpen={isDetailView}
          setIsOpen={setIsDetailView}
        >
          {/* <Header
            id={id}
            setIsDetailView={setIsDetailView}
            profile={profile}
            experience={prospectExperience}
            isDetailView={isDetailView}
            isDrawerHeader
            data={currentFeedback?.data}
          />
          {summary && <About id={id} summary={summary} />}
          <Experience experience={prospectExperience} id={id} />
          <OtherExperience id={id} experience={prospectExperience} />
          {!!prospectSkills && !!prospectSkills?.length && (
            <TagSection id={id} title="Skills" tags={prospectSkills} />
          )}
          {!!prospectSpecialties && !!prospectSpecialties?.length && (
            <TagSection id={id} title="Business" tags={prospectSpecialties} />
          )}
          {!!prospectEducation && !!prospectEducation?.length && (
            <Education id={id} data={prospectEducation} />
          )} */}
          <RevampProspectFullCardVarient1
            onClickCard={e=>setIsDetailView(false)}
            showCompanyModal={(exp, init, fin)=>showCompanyModal(exp, init, fin)}
            prospectData={profile}
            showFullCard={true}
            hasComments={false}
            hasReactions={false}
            hasFeedback={false}
            hasSelection={false}
            hasScoring={false}
          />
        </Drawer>
      )}
    </div>
  );
}
