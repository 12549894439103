import {
  JobTitleFAQ1,
  JobTitleFAQ2,
  JobTitleFAQ3,
  JobTitleFAQ4,
  JobTitleFAQ5,
  JobTitleFAQ6,
  JobTitleFAQ7,
  JobTitleFAQ8,
} from "../Answers/JobTitle";

export const JobTitleFAQS = [
  {
    heading: "What is the Job Title?",
    content: <JobTitleFAQ1 />,
  },
  {
    heading: "What should I put for job Title?",
    content: <JobTitleFAQ2 />,
  },
  {
    heading: "Where did you get the job title from?",
    content: <JobTitleFAQ3 />,
  },
  {
    heading: "The job title was not accurately captured?",
    content: <JobTitleFAQ4 />,
  },
  {
    heading: "Can I change the job title selected?",
    content: <JobTitleFAQ5 />,
  },
  {
    heading: "How do I change the job title?",
    content: <JobTitleFAQ6 />,
  },
  {
    heading: "What is employee count?",
    content: <JobTitleFAQ7 />,
  },
  {
    heading: "How did you set the employee count number?",
    content: <JobTitleFAQ8 />,
  },
];
