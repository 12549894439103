import * as ActionTypes from "../../actions/prospectsView";

const initialState = {
  currentTab: "Prospects",
};

const subDropDownReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SUB_DROPDOWN_VIEW:
      return {
        ...state,
        currentTab: action.payload,
      };
    default:
      return state;
  }
};

export default subDropDownReducer;
