import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import "./style.scss";
const CarouselTag = ({
  isMainTitle = false,
  greyTheme = false,
  highlight = false,
  selected = false,
  hasHover = false,
  onClick = (e) => false,
  children,
}) => {
  const [isHover, setIsHover] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  return (
    <>
      <div
        id={isMainTitle ? "idealProfileMaintitle" : ""}
        className={`${
          greyTheme
            ? highlight
              ? "carouselTagGreyHighlight"
              : selected
              ? isMainTitle
                ? "carouselTagGreyDisabled"
                : "carouselTagGreySelected"
              : "carouselTagGrey"
            : highlight
            ? "carouselTagHighlight"
            : selected
            ? "carouselTagSelected"
            : "carouselTag"
        } ${isHover && hasHover ? "carouselTagHover" : ""}`}
        onMouseEnter={(e) => {
          if (!isMainTitle) setIsHover(true);
          else setIsTooltipOpen(true);
        }}
        onMouseLeave={(e) => {
          if (!isMainTitle) setIsHover(false);
          else setIsTooltipOpen(false);
        }}
        onClick={onClick}
      >
        {children}
        {/* {isHover && hasHover && " ✖"} */}
      </div>
      {isMainTitle && (
        <Tooltip
          autohide={false}
          target="idealProfileMaintitle"
          toggle={function noRefCheck() {}}
          isOpen={isTooltipOpen}
          style={{
            maxWidth: "100%",
            padding: "8px",
            margin: "8px",
            borderRadius: "6px",
            backgroundColor: "#454545",
          }}
          placement="bottom"
        >
          Already assigned as main title
        </Tooltip>
      )}
    </>
  );
};

export default CarouselTag;
