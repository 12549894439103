import React from "react";
import "./styles.scss";

export default function Position({ title, companyName, onClick = () => {} }) {
  const onCompanyClick = (e) => {
    e.stopPropagation();
    onClick();
  };
  return (
    <div className="role">
      <p className="position">{title}</p>
      <p className="at">at</p>
      <p onClick={onCompanyClick} className="companyName">
        {companyName}
      </p>
    </div>
  );
}
