import React from 'react'
import TabButton from '../TabButton'
import './style.scss'
const TabularContainer = ({
    icon,
    heading,
    currentTab,
    onTabClick,
    tabs=[],
    children
}) => {
    return (
        <div
            className='tabularContainer'
        >
            <div
                className='tabularHeader'
            >
                <span
                    className='headingContainer'
                >
                    {icon}
                    <h2>
                        {heading}
                    </h2>
                </span>
                <span
                    className='tabsContainer'
                >
                    {tabs.map(tab=>(
                        <TabButton
                            key={tab.tab}
                            selected={currentTab===tab.tab}
                            tab={tab}
                            onClick={onTabClick}
                        />
                    ))}
                </span>
            </div>
            <div
                className='tabularBody'
            >
                {children}
            </div>
        </div>
    )
}

export default TabularContainer
