import { CALL_API } from "../../middlewares/apiMiddleware"

export const FETCH_STATS = 'FETCH_STATS'
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS'
export const FETCH_STATS_FAILURE = 'FETCH_STATS_FAILURE'

export const FETCH_JOBS_HOME = 'FETCH_JOBS_HOME'
export const FETCH_JOBS_HOME_SUCCESS = 'FETCH_JOBS_HOME_SUCCESS'
export const FETCH_JOBS_HOME_FAILURE = 'FETCH_JOBS_HOME_FAILURE'

export const  FETCH_CAMPAIGNS_HOME = 'FETCH_CAMPAIGNS_HOME'
export const FETCH_CAMPAIGNS_HOME_SUCCESS = 'FETCH_CAMPAIGNS_HOME_SUCCESS'
export const FETCH_CAMPAIGNS_HOME_FAILURE = 'FETCH_CAMPAIGNS_HOME_FAILURE'

export const fetchCampaignsHome = () => ({
  [CALL_API]: {
    types: [FETCH_CAMPAIGNS_HOME, FETCH_CAMPAIGNS_HOME_SUCCESS, FETCH_CAMPAIGNS_HOME_FAILURE],
    endpoint : `/api/v2/auth/campaigns`,
    options: {
      method: 'GET'
    }
  }
})

export const fetchStats = () => ({
  [CALL_API]: {
    types: [FETCH_STATS, FETCH_STATS_SUCCESS, FETCH_STATS_FAILURE],
    endpoint: '/api/v2/auth/stats',
    options: {
      method: 'GET'
    }
  }
})


export const fetchJobsHome = () => ({
  [CALL_API]: {
    types: [FETCH_JOBS_HOME, FETCH_JOBS_HOME_SUCCESS, FETCH_JOBS_HOME_FAILURE],
    endpoint: `/api/v2/auth/jobs?p=1&pP=2`,
    options: {
      method: 'GET'
    }
  }
})