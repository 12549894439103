import React, { useState } from 'react'
import { connect } from 'react-redux'
import DatePicker from "react-datepicker";
import Cross from "../../../components/svgComponents/CrossSvg";
import LabelButton from '../../../uiLibrary/LabelButton';
import AddNew from '../../../img/plus-small.svg'
import ProfileDisplayImage from '../../../components/ProfileDisplayImage/ProfileDisplayImage'
import './newMeeting.scss'
import GoogleLogo from '../../../img/googleLogo.svg'
import Zoom from '../../../img/zoom.svg'
import SecondaryButton from '../../../uiLibrary/SecondaryButton';
import PrimaryButton from '../../../uiLibrary/PrimaryButton';
import { postCalendarEvents } from '../../../actions/calendar';

const googleMeetsTempLink = "https://meet.google.com/zov-rbtx-hyy";
const NewMeeting = ({ calendar, selectedCalendar, onCancelMeeting, onSuccessMeeting, currentUser, dispatch }) => {

    let [selectedIndex, setSelectedIndex] = useState(0);
    let [guests, setGuests] = useState([]);
    let [newGuest, setNewGuest] = useState("");
    let [title, setTitle] = useState("");
    let [isAddNew, setIsAddNew] = useState(false);
    let [date, setDate] = useState(null);
    let [startTime, setStartTime] = useState(null);
    let [endTime, setEndTime] = useState(null);
    let [isLocation, setIsLocation] = useState(false);
    let [isEnabled, setIsEnabled] = useState(true);
    let [meeting, setMeeting] = useState(null)
    let [created, setCreated] = useState(null);
    let isDisplayButtons = false;

    if (date != null && startTime != null && endTime != null) {
        isDisplayButtons = true;
    }

    const removeMeetingLink = () => {
        setMeeting(null);
    }

    const onCancel = () => {
        onCancelMeeting();
    }

    const onCreate = () => {
        let start = new Date(startTime)
        let end = new Date(endTime)
        start = replaceDate(start, date)
        end = replaceDate(end, date)
        dispatch(postCalendarEvents({
            event: {
                title: title,
                startDateTime: start,
                endDateTime: end,
                attendees: guests
            },
            user: currentUser,
            calendar: selectedCalendar,
            sendNotifications: true
        }))
        onSuccessMeeting();
    }

    const startTimeChange = (e) => {
        setStartTime(e);
    }

    const endTimeChange = (e) => {
        setEndTime(e);
    }

    const handleDateSelect = (e) => {
        setDate(e);
    }

    const onAddNewLocation = () => {
        setIsLocation(true);
    }

    const onAddNewGuests = () => {
        guests.push("");
        setGuests(JSON.parse(JSON.stringify(guests)));
    }

    const handleChange = (e) => {
        setNewGuest(e.target.value);
    }

    const onAppendNewGuests = () => {
        setIsAddNew(true);
    }

    const onTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const onDiscardGuest = () => {
        setNewGuest("");
        setIsAddNew(false);
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (newGuest != "") {
                let user = {
                    email: newGuest
                }
                guests.push(user);
                setGuests(JSON.parse(JSON.stringify(guests)));
                setNewGuest("");
            }
            setIsAddNew(false);
        }
    }
    const onClickOutside = (e) => {
        if (e && e.relatedTarget && e.relatedTarget.className == "guestIconButton") {
            onDiscardGuest();
        }
        else {
            if (newGuest != "") {
                let user = {
                    email: newGuest
                }
                guests.push(user);
                setGuests(JSON.parse(JSON.stringify(guests)));
                setNewGuest("");
            }
            setIsAddNew(false);
        }
    }

    const onRemoveGuest = (index) => {
        guests.splice(index, 1);
        setGuests(JSON.parse(JSON.stringify(guests)))
    }

    const replaceDate = (date1, date2) => {
        date1.setDate(date2.getDate())
        date1.setFullYear(date2.getFullYear())
        date1.setMonth(date2.getMonth())
        return date1;
    }

    const onLocationSelect = (type) => {
        if (isLocation) {
            setIsLocation(false);
            return;
        }
        if (type == "meet") {
            setMeeting(googleMeetsTempLink)
        }
        else if (type == "zoom") {

        }
    }

    const onClickOutsideLocation = () => {
        setIsLocation(false);
    }

    const onClose = () => {
        onCancelMeeting();
    }

    return (
        <div className="newMeetingContainer">
            <div>
                <div className="mainHeading">
                    <h2 className="">New Meeting</h2>
                    {
                        isDisplayButtons ?
                            <div className="flexOnly">
                                <SecondaryButton className="cancelButton" onClick={onCancel}>
                                    Cancel
                                </SecondaryButton>
                                <PrimaryButton className="createButton" onClick={onCreate}>
                                    Create
                                </PrimaryButton>
                            </div> :
                            <div>
                                <button className="iconButton" onClick={onClose}>
                                    <Cross style={{ transform: "rotate(90deg)" }}
                                        color="#A7ABB0" />
                                </button>
                            </div>
                    }

                </div>
                <div className="flexAlignDisplay">
                    <div className="header">
                        Title
                    </div>
                    <div className="content">
                        <div className="inputText">
                            <input
                                value={title}
                                onChange={onTitleChange}
                                type="text"
                                placeholder="Add Title"
                            />
                        </div>
                    </div>
                </div>
                <div className="flexAlignDisplay">
                    <div className="header">
                        When
                    </div>
                    <div className="content">
                        <div className="flexAlignDisplay">
                            <div className="datePicker">
                                <DatePicker
                                    selected={date}
                                    onSelect={handleDateSelect}
                                    onChange={handleDateSelect}
                                />
                            </div>
                            <div className="timeSettingsInput">
                                <DatePicker
                                    selected={startTime}
                                    onChange={(e) => startTimeChange(e)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={selectedIndex == 0 ? 15 : 30}
                                    dateFormat="h:mm aa"
                                />
                            </div>
                            <span className="hyphen"> - </span>
                            <div className="timeSettingsInput">
                                <DatePicker
                                    selected={endTime}
                                    onChange={(e) => endTimeChange(e)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={selectedIndex == 0 ? 15 : 30}
                                    dateFormat="h:mm aa"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flexAlignDisplay">
                    <div className="header">
                        Where
                    </div>
                    {
                        meeting ?
                            <div className="content">
                                <div className="meeting">
                                    <div className='meetingIcon'>
                                        <div className='flexOnly gbt_svg_cntr'>
                                            <img
                                                width="18"
                                                height="18"
                                                src={GoogleLogo}
                                            />
                                            <span>Meet</span>
                                        </div>
                                        <button className="cross" onClick={() => removeMeetingLink()}>
                                            <Cross width="8"
                                                height="8" color="#A7ABB0" />
                                        </button>
                                    </div>
                                    <div className="meetingLink">{meeting}</div>
                                </div>
                            </div> :
                            []
                    }
                    {
                        isEnabled && meeting == null ?
                            <div className="flexOnly">
                                <div className='addLocationBtn' onClick={() => onLocationSelect("meet")}>
                                    <img
                                        width="18"
                                        height="18"
                                        src={GoogleLogo}
                                    />
                                    <span>+ Add Meet</span>
                                </div>
                                <div className='addLocationBtn disabled' onClick={() => onLocationSelect("zoom")}>
                                    <img
                                        width="18"
                                        height="18"
                                        src={Zoom}
                                    />
                                    <span>+ Add Zoom</span>
                                </div>
                            </div> :
                            []
                    }
                    {
                        !isEnabled && meeting == null ?
                            <div className="content">
                                {
                                    isLocation ?

                                        <div>
                                            <LabelButton className="addNew">
                                                <img
                                                    width="16"
                                                    height="16"
                                                    src={AddNew}
                                                    onClick={onAddNewLocation}
                                                />
                                                Add location
                                            </LabelButton>
                                            <div className="addLocation" onBlur={onClickOutsideLocation} tabIndex={5}>
                                                <div className="meetingChannelHeader">Link your meeting channel</div>
                                                <div className="meetingChannelDescription">Qlu needs access to your meeting channel to add it as location.</div>
                                                <div className='locationButton' onClick={() => onLocationSelect("meet")}>
                                                    <div className='gbt_wrapper'>
                                                        <div className='gbt_icon'>
                                                            <div className='gbt_svg_cntr'>
                                                                <img
                                                                    width="18"
                                                                    height="18"
                                                                    src={GoogleLogo}
                                                                />
                                                            </div>
                                                        </div>
                                                        <span className='gbt_text'>Continue with Meet</span>
                                                    </div>
                                                </div>
                                                <div className='locationButton' onClick={() => onLocationSelect("zoom")}>
                                                    <div className='gbt_wrapper'>
                                                        <div className='gbt_icon'>
                                                            <div className='gbt_svg_cntr'>
                                                                <img
                                                                    width="18"
                                                                    height="18"
                                                                    src={Zoom}
                                                                />
                                                            </div>
                                                        </div>
                                                        <span className='gbt_text'>Continue with Zoom</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        :
                                        <LabelButton className="addNew">
                                            <img
                                                width="16"
                                                height="16"
                                                src={AddNew}
                                                onClick={onAddNewLocation}
                                            />
                                            Add location
                                        </LabelButton>
                                }
                            </div> :
                            []
                    }
                </div>
                <div className="flexAlignDisplay">
                    <div className="header">
                        Guests
                    </div>
                    <div className="content">
                        <div className="flexDisplay">
                            <ProfileDisplayImage
                                width="46"
                                height="46"
                                className="profile"
                                src={currentUser?.imageUrl}
                            />
                            <div>
                                <div>{currentUser?.name}<span className="you">(you)</span></div>
                                <div className="subHeading">{selectedCalendar}</div>
                            </div>
                        </div>
                        {
                            guests.map((item, index) => (
                                <div className="flexDisplay">
                                    <ProfileDisplayImage
                                        width="46"
                                        height="46"
                                        className="profile"
                                        src={item?.imageUrl}
                                    />
                                    <div>
                                        <div className="flexOnly">
                                            <div>{item?.email}</div>
                                            <button className="guestIconButton" onClick={() => onRemoveGuest(index)}>
                                                <Cross style={{ transform: "rotate(90deg)" }}
                                                    color="#A7ABB0" />
                                            </button>
                                        </div>
                                        <div className="subHeading">Guest</div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            isAddNew ?
                                <div className="guestInputText">
                                    <input
                                        value={newGuest}
                                        onChange={handleChange}
                                        onKeyDown={onKeyDown}
                                        onBlur={onClickOutside}
                                        type="text"
                                    />
                                    <button className="guestIconButton" onClick={onDiscardGuest}>
                                        <Cross style={{ transform: "rotate(90deg)" }}
                                            color="#A7ABB0" />
                                    </button>
                                </div> :
                                <LabelButton className="addNew" onClick={onAppendNewGuests}>
                                    <img
                                        width="16"
                                        height="16"
                                        src={AddNew}
                                    />
                                    Add Guests
                                </LabelButton>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(state => ({
    calendar: state.auth.calendar,
    user: state.auth
}))(NewMeeting)
