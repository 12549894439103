import React from 'react'
import ProfileDisplayImage from '../../components/ProfileDisplayImage/ProfileDisplayImage'
import './style.scss'
const TabButton = ({
    tab = {
        name: 'John Doe',
        disabled: false
    },
    selected=false,
    onClick=e=>false,
}) => {

    return(
        <button 
            className={`${selected ? 'tabButtonSelected' : ''} tabButton`}
            onClick={e=>onClick(tab)}
            disabled={tab.disabled}
        >
            {tab.image &&
                <ProfileDisplayImage
                    src={tab.image}
                /> 
            }
            {tab?.name} 
        </button>
    )
}

export default TabButton
