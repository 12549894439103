import { React, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Button } from "reactstrap";
import SearchSvg from "../../../uiLibrary/SVGs/Search";
import Sort from "../../../uiLibrary/SVGs/SortSmall";
import ConnectSvg from '../../../uiLibrary/SVGs/ConnectSvg'
import MailSVG from '../../../uiLibrary/SVGs/MailSvg'
import DropdownComponent from '../../../uiLibrary/DropdownComponent';
import ProfileDisplayImage from '../../../components/ProfileDisplayImage/ProfileDisplayImage'
import { MentionsInput, Mention } from "react-mentions";
import Send from "../../../components/svgComponents/Send";

import { getLinkedInMessages, fetchJobCampaigns } from '../../../actions/messaging'
import './messaging.scss'

const moment = require('moment-timezone');

const Messaging = ({ user, tribeMembers, messaging, campaigns, jobsWithCampaigns, dispatch }) => {

    // let messages = [{ "sender": 427, "sentAt": "2021-12-16T20:26:39.972426-08:00", "channel": "linkedin", "subject": "Hello", "dateTime": "2021-12-16T20:26:32.604235-08:00", "sentMessages": "Hi Farhan, Hope you and your family are keeping well. I was reaching out to see if you might be interested in exploring a new role? I think you might be the perfect fit for speech processing engineer. Should we connect this week?\n    Best,\n    Khurram Junejo", "email_address": "null", "campaign_sender": 427, "is_personalised": false }, { "sender": 427, "sentAt": "2021-12-16T05:07:03.983451-08:00", "channel": "linkedin", "subject": "Hello", "dateTime": "2021-12-16T05:06:55.573381-08:00", "sentMessages": "Hi Farhan, Hope you and your family are keeping well. I was reaching out to see if you might be interested in exploring a new role? I think you might be the perfect fit for speech processing engineer. Should we connect this week?\n    Best,\n    Khurram Junejo", "email_address": "null", "campaign_sender": 427, "is_personalised": true }, { "sender": 427, "sentAt": "2021-12-16T05:06:21.020168-08:00", "channel": "linkedin", "subject": "Hello", "dateTime": "2021-12-16T05:06:12.213381-08:00", "sentMessages": "Hi Farhan, Hope you and your family are keeping well. I was reaching out to see if you might be interested in exploring a new role? I think you might be the perfect fit for speech processing engineer. Should we connect this week?\n    Best,\n    Khurram Junejo", "email_address": "null", "campaign_sender": 427, "is_personalised": true }];

    const messagesOptions = ['My Messages', 'All Messages']
    const campaignsOptions = ['My Campaings', 'All Campaigns']
    const channelsOptions = ['My Channels', 'All Channels']
    const sendMessageChannelOptions = ['Email', 'LinkedIn', 'InMail']

    const [newMessage, setNewMessage] = useState("");
    let [messagesFilter, setMessagesFilter] = useState(messagesOptions[0]);
    let [campaignsFilter, setCampaignsFilter] = useState(campaignsOptions[0]);
    let [channelsFilter, setChannelsFilter] = useState(channelsOptions[0]);
    let [sendMessageChannel, setSendMessageChannel] = useState(sendMessageChannelOptions[0]);
    let [selectedJob, setSelectedJob] = useState(null);
    let [selectedRecepient, setSelectedRecepient] = useState(null);
    let [sortSelected, setSortSelected] = useState(false);

    console.log("jobsWithCampaigns")
    console.log(jobsWithCampaigns);

    tribeMembers = tribeMembers.map((member) => {
        member.display = member.name;
        member.id = member.userId;

        return member;
    });

    const getNames = (name) => {
        let temp = name.split(" ");
        return {
            firstName: temp[0].toLowerCase(),
            lastName: temp[1].toLowerCase()
        }
    }

    const pullLinkedInMessages = (name) => {
        let _name = getNames(name);
        dispatch(getLinkedInMessages({
            firstName: _name.firstName,
            lastName: _name.lastName
        }));
    }

    useEffect(() => {
        dispatch(fetchJobCampaigns())
        // pullLinkedInMessages("mark snover");
    }, [!messaging.isUpdating && messaging.isUpdated])

    const getDate = (date) => {
        return moment(new Date(date)).local().format('DD MMM');
    }

    const toggleSearchInput = () => {

    }

    const handleSortToggle = () => {

    }

    const onSendMessageChannelChange = (e) => {
        setSendMessageChannel(e);
    }

    const onMessagesFilterChange = (e) => {
        setMessagesFilter(e);
        if (e == "My Messages") {

        }
    }

    const onCampaignsFilterChange = (e) => {
        setCampaignsFilter(e);
    }

    const onChannelsFilterChange = (e) => {
        setChannelsFilter(e);
    }

    const handleShiftEnterSend = (e) => {
        if (e.code == "Enter" && e?.target?.value.length > 0) {
            handleMessageSend();
        }
    };

    const handleCommentInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleMessageSend = () => {
        // let textObj = newComment?.split(/(?=@\[)(.*?)(?<=\))| /g);
        // textObj = textObj.filter((txt) => txt != "" && typeof txt != "undefined");

        // textObj?.map((txt) => {
        //     if (txt.match(/(?=@\[)(.*?)(?<=\))/g)) {
        //         let txtList = txt.match(/\[.*\]|\(.*\)/g);
        //         textCommentObject.elements.push({
        //             type: "user",
        //             value: txtList[1].replace(/\(|\)/g, ""),
        //             display: txtList[0].replace(/\[|\]/g, ""),
        //         });
        //     } else {
        //         textCommentObject.elements.push({ type: "text", value: txt });
        //     }
        // });

        // let parentId = null;
        // if (newReplyComment.id) {
        //     parentId = newReplyComment.id;
        // }

        // dispatch(
        //     addComment({
        //         jobProfileId: clickedProspectsDetails?.jobProfileId,
        //         type: "TEXT",
        //         voiceNote: null,
        //         note: textCommentObject,
        //         parentId,
        //         jobId: job.id,
        //     })
        // );

        // setTimeout(() => {
        //     setNewComment("");
        //     setNewReplyComment({});
        // }, 500);
    }

    const getInitials = (name) => {
        let userName = name.split(" ");
        let initials = userName[0][0] + userName[1][0];
        return initials;
    }

    const onClickJob = (index) => {
        setSelectedJob(jobsWithCampaigns[index]);
    }

    const onClickRecepient = (index) => {
        setSelectedRecepient(selectedJob.profiles[index])
        // pullLinkedInMessages(selectedJob.profiles[index].name)
    }

    return (
        <div className="messaging">
            <div className="row">
                <div className="col-3 jobsView">
                    <div className="header">
                        <div className="heading">
                            JOBS
                        </div>
                        <div className="filters">
                            <button
                                className="actionbarFilterButton searchJob"
                                onClick={() => toggleSearchInput()}>
                                <SearchSvg width="24" height="24" />
                            </button>
                            <button
                                onClick={handleSortToggle}
                                className={`actionbarFilterButton searchJob filterOrSort ${sortSelected ? "selected" : ""}`}>
                                <Sort width="24" height="24" />
                            </button>
                        </div>
                    </div>
                    {
                        jobsWithCampaigns?.map((job, index) => (
                            selectedJob != null && selectedJob.id == job.id ?
                                <div className="selectedJob">
                                    <div className="jobTitle">{selectedJob?.jobTitle}</div>
                                    {/* <div className="jobContent">AWS</div> */}
                                </div>
                                :
                                <div className="jobs" onClick={() => onClickJob(index)}>{job.jobTitle}</div>
                        ))
                    }

                </div>
                <div className="col-3 recepientView">
                    <div className="header">
                        <div className="heading">
                            RECIPIENTS
                        </div>
                        <div className="filters">
                            <button
                                className="actionbarFilterButton searchRecipient"
                                onClick={() => toggleSearchInput()}>
                                <SearchSvg width="24" height="24" />
                            </button>
                        </div>
                    </div>
                    {
                        selectedJob?.profiles.map((profile, index) => (
                            selectedRecepient != null && selectedRecepient.id == profile.id ?
                                <div className="selectedRecipient">
                                    <ProfileDisplayImage
                                        width="46"
                                        height="46"
                                        src=""
                                    />
                                    <div>
                                        <div className="recipientName">{profile.name}</div>
                                        <div className="recipientDescription">
                                            <span>{profile.title}</span> {profile.company ? "@" : ''} <span>{profile.company}</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="recipient" onClick={() => onClickRecepient(index)}>
                                    <ProfileDisplayImage
                                        width="46"
                                        height="46"
                                        src=""
                                    />
                                    <div>
                                        <div className="recipientName">{profile.name}</div>
                                        <div className="recipientDescription">
                                            <span>{profile.title}</span> {profile.company ? "@" : ''} <span>{profile.company}</span>
                                        </div>
                                    </div>
                                </div>
                        ))
                    }
                </div>
                <div className="col-6 messagesView">
                    <div className="header">
                        <div className="heading">
                            MESSAGES
                        </div>
                        <div className="dropdownFilters">
                            <DropdownComponent
                                className="messagesDropDown"
                                selected={messagesFilter}
                                options={messagesOptions}
                                onSelect={onMessagesFilterChange}>
                            </DropdownComponent>
                            <DropdownComponent
                                className="campaignsDropDown"
                                selected={campaignsFilter}
                                options={campaignsOptions}
                                onSelect={onCampaignsFilterChange}>
                            </DropdownComponent>
                            <DropdownComponent
                                className="channelsDropDown"
                                selected={channelsFilter}
                                options={channelsOptions}
                                onSelect={onChannelsFilterChange}>
                            </DropdownComponent>
                        </div>
                    </div>
                    <div className="messagesContent">
                        {
                            messaging?.messages.map((message, index) => (
                                <div className={message.senderName == user.name ? 'single-comment self' : 'single-comment'} >
                                    <div className="messagesHeader">
                                        <div className="commentSender">
                                            <div className="senderImage">
                                                <div className="profileDisplayInitials">
                                                    {getInitials(message.senderName)}
                                                </div>
                                            </div>
                                            <div className="senderName">
                                                <p>{message.senderName == user.name ? "You" : message.senderName}</p>
                                            </div>
                                        </div>
                                        <div className="flexOnly">
                                            <div className="channel">
                                                {
                                                    message.channel === 'email' ?
                                                        <MailSVG
                                                            selected={true}
                                                        /> :
                                                        <ConnectSvg
                                                            selected={true}
                                                        />
                                                }
                                                <p>{message.channel === 'linkedin' ? "LinkedIn Message" : message.channel}</p>
                                            </div>
                                            <span className="globalDotStyleSecondary overviewDotStyle">•</span>
                                            <div className="time">
                                                <p>{message.dateTime ? getDate(message.dateTime) : ""}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="messageContainer">
                                        <div className="message">
                                            <p dangerouslySetInnerHTML={{ __html: message.message }} />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="inputBox">
                        <MentionsInput
                            onKeyDown={(e) => handleShiftEnterSend(e)}
                            style={{ width: "320px" }}
                            className="inputInput"
                            value={newMessage}
                            placeholder="Type a message. Sending a message will cancel scheduled messages."
                            onChange={(e) => handleCommentInputChange(e)}>
                            <Mention
                                trigger="@"
                                data={tribeMembers}
                                displayTransform={(id, display) => `@${display}`}
                                markup="@[__display__](__id__)"
                                appendSpaceOnAdd={true} />
                        </MentionsInput>
                        <Button className="sendMessageChannel">
                            {
                                sendMessageChannel === 'Email' ?
                                    <MailSVG width="16" height="16" stroke="2" color="#242424"
                                        selected={true}
                                    /> :
                                    <ConnectSvg width="16" height="16" stroke="2" color="#242424"
                                        selected={true}
                                    />
                            }
                            <DropdownComponent
                                selected={sendMessageChannel}
                                options={sendMessageChannelOptions}
                                onSelect={onSendMessageChannelChange}>
                            </DropdownComponent>
                        </Button>
                        <Button
                            className="messageSend"
                            disabled={newMessage.length < 1}
                            onClick={() => handleMessageSend()}>
                            <Send width="20" height="20" stroke="2" color="#8A4829" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(state => ({
    user: state.auth.user,
    messaging: state.messaging,
    jobsWithCampaigns: state.messaging.jobsWithCampaigns,
    campaigns: state.messaging.campaigns,
    tribeMembers: state.prospectsView.candidates.tribeMembers,
    job: state.prospectsView.candidates.job
}))(Messaging)