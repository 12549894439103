import React from "react";

const CaretLeft = ({
  color = "#A7ABB0",
  width = "8",
  height = "14",

  style = {},
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 13L1 7L7 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CaretLeft;
