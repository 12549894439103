import React, { useState, useEffect } from 'react'

import { Button, Modal } from 'reactstrap'
import StyleableTextAreaContainer from '../StyleableTextArea'
import MessageClassifier from '../MessageClassifier'

import CrossSvg from '../SVGs/CrossSvg'

import './style.scss'
import EyeFilledSvg from '../SVGs/EyeFilledSvg'
import LabelButton from '../LabelButton'
import SeconadryInput from '../SecondaryInput'
const OutboxMessaging = ({
  message,
  onChange,
  skillsReq,
  charLimits,
  variableTranslator=e=>'',
  recepient={},
  hasSubject=false,
  hasEmail=false,
  subject='',
  onSubjectChange=e=>false,
  email='abcdefg@zyzz.qlu'

}) => {

  const  [eyeHoverState, setEyeHoverState] = useState(false)
  const  [isOpen, setIsOpen] = useState(false)

  // useEffect(() => {
    // console.log("=======================OutboxMessaging==========================", skillsReq)
  // }, [])
  const previewMessageComponents = []
  const previewMessageArray = variableTranslator(message, recepient).split(/\n/g) || []
  if(hasSubject){
    previewMessageComponents.push(subject)
    previewMessageComponents.push(<br />)
    previewMessageComponents.push(<br />)
  }
  previewMessageArray.map((elem, index)=>{
    previewMessageComponents.push(elem)
    if(index<previewMessageArray.length-1) {
      previewMessageComponents.push(<br />)
    }
  })
    return (
        <div
            className='hybridMessaging'
        >
          <Modal
            className='previewModalContainer'
            isOpen={isOpen}
            toggle={e=>setIsOpen(prev=>!prev)}
          >
            <div>
              <p>
                Message Preview
              </p>
              <button
                onClick={e=>setIsOpen(prev=>!prev)}
              >
                <CrossSvg/>
              </button>
            </div>
            <p>
              {previewMessageComponents}
            </p>
          </Modal>
          <div>
              <div
                  className='hybridMessagingHeadlineContainer'
              >
                  <h3>
                      Message
                  </h3>
                  <span
                      className='hybridMessagingCtaContainer'
                  >
                      <button
                          onClick={e=>setIsOpen(prev=>!prev)}
                          onMouseLeave={e=>setEyeHoverState(false)}
                          onMouseEnter={e=>setEyeHoverState(true)}
                      >
                          <EyeFilledSvg
                            selected={eyeHoverState}
                          />
                      </button>
                      
                  </span>
              </div>
              {hasSubject &&
                <div
                  className='outboxMessagingSubjectContainer'
                >
                  {hasEmail &&
                    <div>
                      <h3>
                        From
                      </h3>
                      <p>
                        {email}
                      </p>
                    </div>
                  }
                  <div>
                    <h3>
                      Subject
                    </h3>
                    <input
                      value={subject}
                      onChange={e=>onSubjectChange(e.target.value)}
                    />
                  </div>
                </div>
              }
              <StyleableTextAreaContainer
                className={hasSubject ? 'outboxMessagingSubjectExtension' : ''}
                message={message}
                onChange={onChange}
                skillsReq={skillsReq}
                charLimits={charLimits}
              />
          </div>
          <div>
              <MessageClassifier
                message={message}
              />
          </div>
        </div>
    )
}

export default OutboxMessaging
