import React, { useState } from "react";
import "./RevampProspectPage.scss";
import { Collapse, Tooltip } from "reactstrap";
import FavouriteSVG from "../SVGs/FavoriteSVG";

const CollapsibleComponent = ({
  isOpen = false,
  availableSkills = [],
  missingSkills = [],
  showSpan = true,
  showFavourite,
  favouriteState,
  type,
  onClick,
}) => {
  const MissingValues = ({ exp, index }) => {
    const [isOpenState, setIsOpen] = useState(false);
    return (
      <p className="missing" key={index} id={`missing_${index}`}>
        <span>{exp.title}</span>
        {isOpen && (
          <Tooltip
            hideArrow={false}
            placement="bottom"
            isOpen={isOpenState}
            target={`missing_${index}`}
            toggle={() => setIsOpen(!isOpenState)}
            style={{ zIndex: 9 }}
            className="primaryToolTip"
          >
            missing
          </Tooltip>
        )}
      </p>
    );
  };

  const FoundValues = ({ exp, index, favouriteState = [] }) => {
    const [isFoundOpenState, setIsFoundOpen] = useState(false);
    const getToolTipLabel = () => {

    }
    return (
      <p key={index} id={`found_${index}`}>
        <span
          style={{
            padding: showFavourite ? "4px" : "1px 4px",
            cursor: showFavourite ? "pointer" : "auto",
          }}
          onClick={() => {
            onClick && onClick(exp);
          }}
        >
          {exp.title?.length > 3 ? exp.title : exp.title?.toUpperCase()}{" "}
          {isOpen && (
            <Tooltip
              hideArrow={false}
              placement="bottom"
              isOpen={isFoundOpenState}
              target={`found_${index}`}
              toggle={() => setIsFoundOpen(!isFoundOpenState)}
              style={{ zIndex: 9 }}
              className="primaryToolTip"
            >
              {exp?.source?.length > 0 ? 
                exp?.source?.map((itm,i) => <p key={i}>
                {itm}
              </p>)  :'found'}
            </Tooltip>
          )}
          {showFavourite ? (
            <FavouriteSVG
              height="20"
              width="30"
              color={favouriteState.includes(exp.title) ? "#c91010" : "#CDD1D7"}
              fill={favouriteState.includes(exp.title) ? "#c91010" : "none"}
            />
          ) : null}
        </span>
        {exp.span && showSpan ? (
          <span className="duration">
            <span className="globalDotStyle">•</span> {exp.span}
          </span>
        ) : (
          ""
        )}
      </p>
    );
  };

  return (
    <Collapse className={`${isOpen ? "expanded" : ""} `} isOpen={isOpen}>
      <div className={`tablets`}>
        {availableSkills.map((exp, key) => (
          <FoundValues exp={exp} index={key} favouriteState={favouriteState} />
        ))}
        {missingSkills.length > 0 &&
          missingSkills.map((exp, key) => (
            <MissingValues exp={exp} index={key} />
          ))}
      </div>
    </Collapse>
  );
};

export default CollapsibleComponent;
