import React from 'react'
import { connect } from 'react-redux'
import ProfileSummaryCard from '../../../components/ProfileSummaryCard/ProfileSummaryCard'
import CardContainer from '../../../uiLibrary/CardContainer'
import CarouselTag from '../../../uiLibrary/CarouselTag'
import JobSetupStep02 from '../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep02'
import JobSetupStep03 from '../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep03'
import { numberWithCommas } from '../../../utils'
import './style.scss'
const ProfileSpecialtyCard = ({
    profiles,
    selectedValues,
    profileHighlightValue,
    isReview
}) => {

    const detectedProfile = []
    const profilesSpecialties = selectedValues.filter(({ source = [], sourceId = [] }) => {
        profiles.filter(({ publicIdentifier }) => sourceId.includes(publicIdentifier) && !detectedProfile.map(({ publicIdentifier }) => publicIdentifier).includes(publicIdentifier)).map(profile => detectedProfile.push(profile))
        return source?.includes("publicIdentifier")
    });
    // profileSelectedValues.reduce((total, {name, synonym=[]})=>[...total, name.trim().toLowerCase(), ...synonym.filter(({source=[]})=>source.includes("idealProfile")).map(({name})=>name.toLowerCase().trim())], []).includes(value.trim().toLowerCase()),
    // console.log("=================selectedValues===================", selectedValues.reduce((total, values)=>[...total, values,...values?.synonym.map((synonym)=>({ ...synonym,  key: values?.key, selected: values.selected, onClick: values?.onClick }))], []))
    // console.log("=================selectedValuesFilter===================", selectedValues.reduce((total, values)=>[...total, values, ...values?.synonym.map((synonym)=>({ ...synonym,  key: values?.key, selected: values.selected, onClick: values?.onClick }))], []).filter(({sourceId=[]})=>sourceId.some(id=>detectedProfile.map(({publicIdentifier})=>publicIdentifier).includes(id))))

    const getExperienceData = (experience = []) => {
        return experience.reduce((total, exp) => (total.map(({ company_name }) => company_name).includes(exp.company_name) ? total : [...total, exp]), [])
    }
    console.log("selected values",selectedValues,"profiles",profiles,"detected profiles",detectedProfile,"profilesSpecialties",profilesSpecialties)
    console.log()

    return (
        <div
            className='profileSpecialtyCardContainer'
        >
            {detectedProfile.map((profile,
            // { image_url, full_name, headline, publicIdentifier, experience = [] }, 
            index) => (
                <CardContainer
                    key={`pid${profile.publicIdentifier}`}
                    icon={<JobSetupStep02 />}
                    heading={`Best Fit ${index + 1}`}
                >
                    <div
                        className='profileSpecialtyCard'
                    >
                        <ProfileSummaryCard
                            imgSrc={profile.image_url  ?? `https://qlu-media-test.s3.amazonaws.com/${profile.publicIdentifier}.jpg`}
                            name={profile.full_name ?? profile.fullName}
                            headline={(profile.headline || '').replace(/\sat\s/g, "@")}
                        />
                        <h3>
                            Companies
                        </h3>
                        <span>
                            {getExperienceData(profile.experience).map(exp => (
                                <ProfileSummaryCard
                                    width='32px'
                                    height='32px'
                                    imgSrc={profile.full_name?exp.logo:`https://qlu-media-cmp.s3.amazonaws.com/${exp.universalName}.jpg`}
                                    name={exp?.company_name ?? exp?.companyName}
                                    headline={``}
                                    isCompany={true}
                            exp={exp}

                                />))
                            }
                        </span>
                        <h3>
                            Business
                        </h3>
                        <div
                            className='profileSpecialtyCardBody'
                        >
                            {selectedValues.reduce((total, values) => [...total, values, ...values?.synonym.map((synonym) => ({ ...synonym, key: values?.key, selected: values.selected, onClick: values?.onClick }))], []).filter(({ sourceId = [] }) => sourceId.includes(profile.publicIdentifier)).map((speciality, index) => (
                                <CarouselTag
                                    key={`${speciality.name}${index}`}
                                    greyTheme={selectedValues.filter(({ selected }) => !selected).reduce((total, { name, synonym = [] }) => [...total, name.trim().toLowerCase(), ...synonym.filter(({ sourceId = [] }) => sourceId.includes(profile.publicIdentifier)).map(({ name }) => name.toLowerCase().trim())], []).includes(speciality.name.trim().toLowerCase())}
                                    selected={true}
                                    highlight={profileHighlightValue.includes(speciality.name.trim().toLowerCase())}
                                    onClick={isReview === '1' ? () => false : selectedValues.reduce((total, values) => [...total, values, ...values?.synonym.map((synonym) => ({ ...synonym, key: values?.key, selected: values.selected, onClick: values?.onClick }))], []).filter(({ name, sourceId = [] }) => name === speciality.name.trim().toLowerCase() && sourceId.includes(profile.publicIdentifier))?.[0]?.onClick}
                                >
                                    {speciality.name}

                                </CarouselTag>
                            ))}
                        </div>
                    </div>
                </CardContainer>))
            }
        </div>
    )
}

export default connect(state => ({
    selectedValues: state.jobSetup.viewController.selectedValues,
    profileHighlightValue: state.jobSetup.viewController.profileHighlightValue,
    profiles: state.jobSetup.jobIdealProfile.profiles,
    isReview: state.jobSetup?.jobSetup?.isReview
}))(ProfileSpecialtyCard)
