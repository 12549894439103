import React, {useState, useRef, useEffect} from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'reactstrap'
import { changeCampaignName, toggleShareWithTribe } from '../../../actions/outbox'
import Loading from '../../../uiLibrary/Loading'
import PenSvg from '../../../uiLibrary/SVGs/PenSvg'
import TickSvg from '../../../uiLibrary/SVGs/TickSvg'
import SwitchComponent from '../../../uiLibrary/Switch'
import { Mixpanel } from '../../../utils/Mixpanel'
import OutboxMessageSending from '../OutboxMessageSending'
import './style.scss'
const OutboxSummary = ({
    name,
    rounds,
    channelOptions,
    selectedProspects,
    shareWithTribe,
    consentRequested,
    publishing,
    publishingSuccess,
    publishingFailure,
    push,
    isPaused,
    setFinish,
    dispatch
}) => {
    const camapaignNameInputRef = useRef(null)
    const [editMode, setEditMode] = useState(false)
    const [nameToolTip, setNameToolTip] = useState(false)


    const [sending, setSending] = useState(false)
    const [sent, setSent] = useState(false)    
    const [CurrentlySending, setCurrentlySending] = useState(0)
    const [sentCount, setSentCount] = useState(0)
    const [requestRateExceeded, setRequestRateExceeded] = useState(false)
    const [sendMessageInterval, setSendMessageInterval] = useState(5000)

    useEffect(()=>{
        Mixpanel.track("Publish Message Campaign")
    }, [])

    useEffect(() => {
        if(editMode){
            camapaignNameInputRef.current.focus()
        }
    }, [editMode])

    return (
        <div
            className='outboxSummaryContainer'
        >
            <div>
                <div
                    className='outboxSummaryHeader'
                >
                    <span>
                        <TickSvg
                            width='18'
                            height='13'
                        />
                    </span>
                    <h3>
                        Campaign published!
                    </h3>
                </div>
                <div    
                    className='outboxSummaryNameContainer'
                >
                    <h4>
                        Campaign name
                    </h4>
                    {editMode ?
                        <input
                            ref={camapaignNameInputRef}
                            value={name}
                            onChange={e=>dispatch(changeCampaignName(e.target.value))}
                        /> :
                        <span>
                            <h2
                                id='campaignNameToolTip'
                            >
                                {name}
                            </h2>
                            <Tooltip
                                placement="bottom" 
                                isOpen={nameToolTip} 
                                target="campaignNameToolTip" 
                                toggle={() => setNameToolTip(prev => !prev)}
                                // style={{ zIndex: 10 }}
                                // className='primaryToolTip'
                                // innerClassName='primaryInnerToolTip'
                                // arrowClassName='primaryArrowToolTip'
                            >
                                {name}
                            </Tooltip>
                            {/* <button
                                onClick={e=>setEditMode(prev=>!prev)}
                            >
                                <PenSvg/>
                            </button> */}
                        </span>

                    }
                </div>
                <div
                    className='outboxSummaryStatsContainer'
                >
                    <span>
                        <h4>
                            recipients
                        </h4>
                        <h2>
                            {Object.keys(selectedProspects).length}
                        </h2>
                    </span>
                    <span>
                        <h4>
                            rounds
                        </h4>
                        <h2>
                            {rounds.length}
                        </h2>
                    </span>
                    <span>
                        <h4>
                            status
                        </h4>
                        <h2>
                            {publishing ? 'Publishing' : ''}
                            {publishingSuccess ? 'Published' : ''}
                            {publishingFailure ? 'Publishing Failed' : ''}
                        </h2>
                    </span>
                </div>
                {/* <div
                    className='outboxSummaryShareWithTribe'
                >
                    <span>
                        <p>
                            Share with tribe
                        </p>
                        <SwitchComponent
                            checked={shareWithTribe}
                            onChange={e=>dispatch(toggleShareWithTribe())}
                        />
                    </span>
                    <h4>
                        Tribe members will be able to view the campaign and it’s messages.
                    </h4>
                </div> */}
                {publishing &&
                    <Loading/>
                }
                {publishingSuccess &&
                    <OutboxMessageSending
                        isPaused={isPaused}
                        setFinish={setFinish}
                        push={push}
                        channelOptions={channelOptions}
                        sending={sending}
                        setSending={setSending}
                        consentRequested={consentRequested}
                        sent={sent} 
                        setSent={setSent}
                        CurrentlySending={CurrentlySending}
                        setCurrentlySending={setCurrentlySending}
                        sentCount={sentCount}
                        setSentCount={setSentCount}
                        requestRateExceeded={requestRateExceeded}
                        setRequestRateExceeded={setRequestRateExceeded}
                        sendMessageInterval={sendMessageInterval}
                        setSendMessageInterval={setSendMessageInterval}
                    />
                }
            </div>
        </div>
    )
}

export default connect(state=>({
    name: state.outbox.campaigns.name,
    publishing: state.outbox.campaigns.publishing,
    publishingSuccess: state.outbox.campaigns.publishingSuccess,
    publishingFailure: state.outbox.campaigns.publishingFailure,
    rounds: state.outbox.campaigns.rounds,
    selectedProspects: state.prospectsView.candidates.selectedProspects,
    shareWithTribe: state.outbox.campaigns.shareWithTribe,
}))(OutboxSummary)
