import React from "react";

const Qlu = ({
    width = '16',
    height = '16',
    style = {} }) => {
    return (
        <svg
            style={style}
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.2928 4.24999H5.82162C4.58551 4.24999 3.44311 5.25735 3.27 6.49999L2.78244 9.99997C2.60934 11.2426 3.47109 12.25 4.70721 12.25H7.94006H7.94334C9.17727 12.2517 10.0372 13.2575 9.86525 14.4983C9.82375 14.4994 9.78216 14.5 9.74052 14.5L7.62667 14.5L5.38862 14.5L4.39378 14.5C1.92153 14.5 0.198031 12.4853 0.544239 9.99997L1.0318 6.49999C1.378 4.01472 3.66281 2 6.13506 2H11.6062C14.0785 2 15.802 4.01472 15.4558 6.49999L14.9682 9.99997C14.9669 10.0097 14.9655 10.0193 14.9641 10.0291L15.1233 8.8859C15.0317 9.50219 14.4635 9.99997 13.8491 9.99997C13.231 9.99997 12.8001 9.4963 12.8867 8.87497L12.9911 8.12498C12.9916 8.12174 12.9921 8.11855 12.9925 8.11531L13.2176 6.49999C13.3907 5.25735 12.5289 4.24999 11.2928 4.24999ZM12.8491 14.5C13.6732 14.5 14.4348 13.8284 14.5502 13C14.6656 12.1716 14.0911 11.5 13.267 11.5C12.443 11.5 11.6814 12.1716 11.566 13C11.4505 13.8284 12.025 14.5 12.8491 14.5Z"
                fill="#6C6C6C" />
        </svg>
    );
};

export default Qlu;
