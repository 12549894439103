import React, { useState, useRef } from "react";
import "./CommentsSection.scss";
import {
  Button,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import CornerUpLeft from "../svgComponents/CornerUpLeft";
import Emoji from "../svgComponents/Emoji";
import DottedVerticalSvg from "../svgComponents/DottedVerticalSvg";
import EmojiPicker from "../EmojiPicker/EmojiPicker";
import { useEffect } from "react";
import { connect } from "react-redux";
import useComponentVisible from "../../utils/useComponentVisible";

const SingleComment = ({
  comment = {
    senderImage: "RZ",
    senderName: "Robert Zack",
    time: "7 hrs",
    senderColor: "#63B780",
    senderBackgroundColor: "#29382E",
    messageText: `Do you think we can consider someone like this in our team?\nI believe he’s a bit <strong>over-experienced.</strong>`,
    emojies: [
      {
        type: "👀",
        count: 5,
      },
    ],
    hasReply: false,
    replyUser: "Alex Rogers",
    replyText: `I think we should put him to the next round already.`,
    replyUserColor: "#63B780",
    isUser: false,
  },
  deleteClicked,
  replyCommentCreate,
  showEmojiPickerCommentID,
  showEmojiPickerOnCommentDispatch,
  handleEmojiPicked,
  deleteEmoji,
  comments,
}) => {
  const {
    ref: emojiRef,
    isComponentVisible: showEmojiMenu,
    setIsComponentVisible: setShowEmojiMenu,
  } = useComponentVisible(false);

  const toggleEmoji = (ref) => {
    showEmojiPickerOnCommentDispatch(
      comment.id == showEmojiPickerCommentID ? null : comment.id
    );
    setShowEmojiMenu((prevState) => !prevState);
  };


  const returnTime = (time) => {
    if (time.includes("hours")) {
      time = time.replace("hours", "hrs");
    } else if (time.includes("minutes")) {
      time = time.replace("minutes", "mins");
    }
    return time;
  };

  const getIfUserEmoji = (comment, emoji) => {
    let exists = comment?.rawEmojiData?.find(
      (raw) => raw?.emojiText === emoji?.emojiText
    );

    return exists?.isOwner ? "owner" : "";
  };

  const [showDropdownToggle, setShowDropdownToggle] = useState(false);

  //   let emojies = [];

  //   comment?.emojies?.map((emoji) => {
  //     let existsIndex = emojies?.findIndex(
  //       (item) => emoji.emojiText == item.emojiText
  //     );
  //     if (existsIndex < 0) {
  //       emojies.push({
  //         emojiText: emoji.emojiText,
  //         emojiASCII: emoji.emojiASCII,
  //         count: 1,
  //         isOwner: emoji?.createdBy ? emoji?.createdBy?.isOwner : emoji?.isOwner,
  //         names: emoji?.createdBy ? [emoji?.createdBy?.name] : [emoji?.name],
  //       });
  //     } else {
  //       emojies[existsIndex]["count"] += 1;
  //       emojies[existsIndex]["isOwner"] =
  //         emoji?.createdBy?.isOwner || emoji?.isOwner;
  //       emojies[existsIndex]["names"].push(
  //         emoji?.createdBy ? emoji?.createdBy?.name : emoji?.name
  //       );
  //     }
  //   });
  const [allEmojies, setAllEmojies] = useState([]);

  const getAllEmojies = () => {
    let emojies = [];

    comment?.emojies?.map((emoji) => {
      let existsIndex = emojies?.findIndex(
        (item) => emoji.emojiText == item.emojiText
      );
      if (existsIndex < 0) {
        emojies.push({
          emojiText: emoji.emojiText,
          emojiASCII: emoji.emojiASCII,
          count: 1,
          isOwner: emoji?.createdBy
            ? emoji?.createdBy?.isOwner
            : emoji?.isOwner,
          names: emoji?.createdBy ? [emoji?.createdBy?.name] : [emoji?.name],
        });
      } else {
        emojies[existsIndex]["count"] += 1;
        emojies[existsIndex]["isOwner"] =
          emoji?.createdBy?.isOwner || emoji?.isOwner;
        emojies[existsIndex]["names"].push(
          emoji?.createdBy ? emoji?.createdBy?.name : emoji?.name
        );
      }
    });
    return emojies;
  };

  useEffect(() => {
    setAllEmojies(getAllEmojies());
  }, [comments]);
  useEffect(() => {
    const elem = document.querySelector(".inputBox");
    if (elem) {
      //scroll into view
      elem.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [allEmojies]);
  const deleteEmojiHandle = (emoji, comment) => {
    setAllEmojies((prev) => {
      const newFiltered = prev.filter(
        (item) => item.emojiText !== emoji.emojiText
      );
      return newFiltered;
    });
    deleteEmoji(emoji, comment);
  };
  const emojiHandlePicked = (emoji, comment) => {
    const newEmoji = {
      emojiText: emoji?.emoji,
      emojiAscII: emoji?.unified,
      count: 1,
      isOwner: true,
    };
    setAllEmojies((prev) => {
      return [...prev, newEmoji];
    });
    handleEmojiPicked(emoji, comment);
  };
  console.log("allEmoji", allEmojies);
  return (
    <>
      <div className="single-comment">
        <div className="header">
          <div className="commentSender">
            <div
              className="senderImage"
              style={{ backgroundColor: comment.senderBackgroundColor, textAlign:'center' }}
            >
              <p style={{ color: comment.senderColor }}>
                {comment.senderImage}
              </p>
            </div>

            <div className="senderName">
              <p style={{ color: comment.senderColor }}>{comment.senderName}</p>
            </div>
          </div>
          <div className="time">
            <p>{returnTime(comment.time)}</p>
            <div className="more">
              {comment.isUser ? (
                <UncontrolledButtonDropdown>
                  <DropdownToggle>
                    <DottedVerticalSvg />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => deleteClicked(comment)}>
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="messageContainer">
          <div className="message">
            {comment.hasReply ? (
              <div className="replyBox">
                <div className="replyUser">
                  <p style={{ color: comment.replyUserColor }}>
                    {comment.replyUser}
                  </p>
                </div>
                <div className="replyText">
                  <p dangerouslySetInnerHTML={{ __html: comment.replyText }} />
                </div>
              </div>
            ) : (
              ""
            )}

            <p dangerouslySetInnerHTML={{ __html: comment.messageText }} />

            <div className="reactions">
              {allEmojies?.map((emoji, index) => (
                <div
                  className={`emoji ${emoji.isOwner ? "emojiOwner" : ""}`}
                  key={index}
                  onClick={() =>
                    emoji.isOwner
                      ? deleteEmojiHandle(emoji, comment)
                      : emojiHandlePicked(emoji, comment)
                  }
                >
                  <span className="tooltiptext">
                    {emoji?.names?.map((name) => (
                      <span>{name}</span>
                    ))}
                  </span>
                  {emoji?.emojiText}{" "}
                  <span className="count">{emoji?.count}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="actions">
            <Button
              className="reply"
              onClick={() => replyCommentCreate(comment)}
            >
              <CornerUpLeft stroke="2" />
            </Button>
            <Button className="reply" onClick={() => toggleEmoji(emojiRef)}>
              <Emoji width="20" height="20" stroke="2" />
            </Button>
          </div>
        </div>

        {showEmojiMenu ? (
          <div ref={emojiRef}>
            <EmojiPicker
              handleEmojiPicked={emojiHandlePicked}
              comment={comment}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default connect((state) => ({
  comments: state.prospectsView.candidates.comments,
}))(SingleComment);
