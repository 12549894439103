import React from 'react'


import SecondaryButton from '../../uiLibrary/SecondaryButton'
import LabelButton from '../../uiLibrary/LabelButton'
import './SectionHeader.scss'

const SectionHeader = ({
  label = 'Sample Label',
  onClickViewAll,
  actions = [],
  showAction = true,
  showViewAll = true
}) => {

  return (
    <div className='sectionHeaderRoot'>
      <p>{label}</p>
      <div>
        {showViewAll &&
          <LabelButton style={{
            backgroundColor: '#242424',
            fontSize: '14px',
            borderRadius: '8px',
            fontWeight: 'normal',
            height: '36px',
          }} onClick={onClickViewAll}>View All</LabelButton>
        }
        {showAction &&
          actions.map(({text, onClick})=>(
            <SecondaryButton
              key={text}
              style={{
                fontSize: '14px',
                marginLeft: '10px',
                fontWeight: 'normal',
                height: '36px'
              }}
              onClick={onClick}
            >{text}</SecondaryButton>
          ))
        }
      </div>
    </div>
  )
}

export default SectionHeader