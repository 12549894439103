import React from 'react'
import './index.scss'

export default function Divider({ text = 'OR' }) {
    return (
        <div className='Divider'>
            <span> {text} </span>
        </div>
    )
}
