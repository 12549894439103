import React from "react";
import ReactPlayer from "react-player";
import myVideo4 from "../../img/test.mp4";
import LinkedInLogo from "../SVGs/LinkedInSvg";
import displayPlaceholder from "../../img/displayPlaceholder.svg";

import "./RevampHarryPotterCard.scss";
import ProfileDisplayImage from "../../components/ProfileDisplayImage/ProfileDisplayImage";
function RevampHarryPotterCard({
  elementIndex,
  onClickCard,
  prospectData = {
    name: "Fernando Banchon",
    jobTitle: "Account Manager @ MasterCard",
    location: "New York Metropolitan Area",
    totalScore: 80,
    currentTitleScore: 90,
    experienceScore: 70,
    experienceSpecialitiesScore: 67,
    experienceTitlesScore: 45,
    experienceSkillsScore: 30,
    experienceFunctionsScore: 54,
    educationScore: 50,
    skillsScore: 80,
    specialitiesScore: 30,
    companyScore: 60,
    imageUrl:
      "https://media-exp1.licdn.com/dms/image/C5603AQEgpiK1GfQw-Q/profile-displayphoto-shrink_400_400/0/1604712859393?e=1637798400&v=beta&t=8jv3-xtUEpnpDzZfItBrEfoRUEQyvjsUtGyIuFiDQbs",
    emojies: [],
    noteCount: null,
    scoring: {
      score: 34.62051953552971,
      final_score: 69.62052,
      skill_score: 14.90181,
      skill_total: 20,
      title_score: 6,
      title_total: 6,
      visa_status: "None",
      same_company: false,
      company_score: 0,
      recruiterLink: [],
      industry_score: 11.24991,
      industry_total: 12.4999,
      skills_profile_missing: ["networking"],
      skills_profile: [
        "web",
        "python",
        "microservices",
        "information technology",
      ],
      education_score: 1.2688,
      education_total: 2.5000999999999998,
      skills_required: [
        "backend: Important",
        "front end: Important",
        "web: Important",
        "information technology: Must Have",
        "design patterns: Important",
        "continuous integration: Important",
        "tdd: Important",
        "sales: Nice to Have",
        "issue resolution: Nice to Have",
        "microservices: Important",
        "python: Important",
        "coding: Nice to Have",
      ],
      experience_found: [
        "Full Stack Engineer at Sectem Technologies for 11 month(s)",
        "11 month(s) ",
      ],
      experience_score: 7.2,
      experience_total: 15,
      skill_importance: {
        important: ["web", "microservices", "python"],
        important_missing: ["Information Technology and Services"],
        must_haves: ["information technology"],
        must_haves_missing: ["iOS Development"],
        nice_to_haves: ["Sass"],
        nice_to_haves_missing: ["Next.js"],
      },
      adjustment_factor: 70,
      education_profile: ["The prospect went to COMSATS University Islamabad"],
      competitor_company: false,
      education_required: ["bachelor of information technology: Nice to Have"],
      industries_profile: ["information technology & services"],
      experience_required: [
        "3 years related to full stack architect: Nice to Have",
        "proficiency in one or more backend languages: Nice to Have",
        "proficiency in one or more frontend framework html5 or angular or react: Nice to Have",
        "experience developing on both mobile and web platforms: Nice to Have",
        "three years of progressive experience in the specialty in lieu of every year of education: Nice to Have",
        "3 years of continuous integration continuous delivery pair programming and test driven development: Nice to Have",
      ],
      industries_required: ["Computer Software: Must Have"],
      all_experience_found: [
        "Full Stack Engineer at Sectem Technologies for 11 month(s)",
      ],
      qualification_status: "Under Qualified",
      super_high_reccomend: false,
      all_experience_scores: {
        skills: 0,
        industries: -1,
        job_position: 0.6,
        specialities: 0,
        jd_title_score: 1,
        jd_relevant_title_score: 0.6,
      },
      current_ideal_company: false,
      total_experience_span: 0.92,
      high_profile_reccomend: false,
      previous_ideal_company: false,
      relevant_experience_span: 0.92,
    },
  },
}) {
  const headline = prospectData?.headline?.split(" at ");
  const [error, setError] = React.useState(false);
  return (
    <div onClick={onClickCard}>
      {prospectData?.isHarryPotter && !error ? (
        <ReactPlayer
          width="349"
          style={{
            borderRadius: "5%",
            objectFit: "cover",
          }}
          autoplay={true}
          loop={true}
          playing={true}
          className="video-circle"
          height="349"
          url={`${process.env.REACT_APP_HARRY_POTTER}/${prospectData?.publicIdentifier}.mp4`}
          onError={() => {
            setError(true);
            console.log("error loading video", prospectData?.publicIdentifier);
          }}
        />
      ) : (
        <ProfileDisplayImage
          width="349"
          height="291"
          src={prospectData.imageUrl}
          className="prospectAvatar"
        />
      )}

      <div className="overlay">
        <div className="MainHeading">
          <div className="fullName">{prospectData?.fullName}</div>

          <div
            onClick={(e) => {
              e.stopPropagation();

              window.open(
                `https://www.linkedin.com/in/${prospectData.publicIdentifier}`,
                "_blank"
              );
              window.focus();
              // newTab(
              //   `https://www.linkedin.com/in/${prospectData.publicIdentifier}`
              // );
            }}
          >
            <LinkedInLogo width="20" height="20" className="linkedInLogo" />
          </div>
        </div>
        <div className="jobTitle">{headline?.[1]}</div>
      </div>
    </div>
  );
}

export default RevampHarryPotterCard;
