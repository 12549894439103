import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addSimilarCompanyDetails,
  changeJobCompanyName,
  clearJobCompanySuggestion,
  fetchJobCompanySuggestion,
  fetchJobSimilarCompanies,
  fetchSimilarCompanyDetails,
  resetSimilarCompaniesLoading,
  setIsSpecialtyEdited,
  similarCompanyThumbsUpHandler
} from "../../../actions/JobSetup";
import NewAutoSuggestion from "../../../components/NewAutoSuggestion/NewAutoSuggestion";
import ProfileSummaryCard from "../../../components/ProfileSummaryCard/ProfileSummaryCard";
import '../../../components/_globalCSSClasses.scss';
import LabelButton from "../../../uiLibrary/LabelButton";
import Loading from "../../../uiLibrary/Loading";
import CrossSvg from "../../../uiLibrary/SVGs/CrossSvg";
import JobSetupStep03 from "../../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep03";
import PlusSvg from "../../../uiLibrary/SVGs/PlusSvg";
import ThumbPillButton from '../../../uiLibrary/ThumbPillButton';
import { numberWithCommas } from "../../../utils";
import { Mixpanel } from "../../../utils/Mixpanel";
import RightOnClickModal from "./RightOnClickModal";
import "./style.scss";
const SimilarCompaniesDetails = ({
  companyHiringFor,
  similarCompanies,
  companySuggestions,
  companyName,
  addingSimilarCompany,
  isLoadingSuggestion,
  isLoadedSimilarCompanyDetails,
  addedSimilarCompany,
  similarComapaniesOnclickProfile,
  dispatch,
  isLoadedSimilarCompaniesOnclickProfile,
  isLoadingSimilarCompaniesOnclickProfile,
  likedSimilarCompanies,
  unLikedSimilarCompanies,
  isReview,
  qParams = {}
}) => {
  const [pillBtnHoverProfile, setPillBtnHoverProfile] = useState('');
  const [hoverTimeout, setHoverTimeout] = useState(null)
  const [addMore, setAddMore] = useState(false)
  const currentHoverCompany = similarCompanies.filter(simComp => simComp.universalName === pillBtnHoverProfile)?.[0] || {}

  useEffect(() => {
    Mixpanel.track("Similar Companies")
  }, [])

  const toggleAddMore = e => {
    dispatch(changeJobCompanyName(''))
    setAddMore(prev => !prev)
  }

  const handleChangeCompanyName = (e) => {
    dispatch(changeJobCompanyName(e.target.value));
  };

  const handlerFetchJobCompanySuggestion = (value) => {
    const companyReg = new RegExp('linkedin.com\/company\/', 'gi')
    const agencyReg = new RegExp('linkedin.com\/showcase\/', 'gi')
    if (companyReg.exec(value) !== null || agencyReg.exec(value) !== null) {
      const fetchedCompanies = similarCompanies.map(({ universalName }) => universalName)
      if (companyHiringFor?.universalName) {
        fetchedCompanies.push(companyHiringFor?.universalName);
      }
      const valueSplit = value.split(/\//)
      const lastValueSplit = valueSplit?.pop()?.trim()
      const secondlastValueSplit = valueSplit?.pop()?.trim()
      if (typeof lastValueSplit === 'string' && lastValueSplit !== "" && !fetchedCompanies.includes(lastValueSplit)) {
        dispatch(fetchJobCompanySuggestion(lastValueSplit, true, value));
      }
      else if (typeof secondlastValueSplit === 'string' && secondlastValueSplit !== "" && !fetchedCompanies.includes(secondlastValueSplit)) {
        dispatch(fetchJobCompanySuggestion(secondlastValueSplit, true, value));
      }
    }
    else {
      dispatch(fetchJobCompanySuggestion(value));
    }
    // dispatch(fetchJobCompanySuggestion(value));
  };

  const handleSuggestionsClearJobCompany = () => {
    dispatch(clearJobCompanySuggestion());
  }

  const handleAddSimilarCompany = (value) => {
    dispatch(resetSimilarCompaniesLoading());
    if (similarCompanies.filter(({ liked }) => liked).length > 29) {
      alert("Can not select more than 30 compnaies")
    }
    else {
      dispatch(addSimilarCompanyDetails(value))
      dispatch(setIsSpecialtyEdited(true))
    }
    toggleAddMore()
  }

  const handleThumbsUp = (items) => {
    dispatch(resetSimilarCompaniesLoading());
    console.log("==========handleThumbsUp==============", { items })
    if (similarCompanies.filter(({ liked }) => liked).length > 29 && !items.liked) {
      alert("Can not select more than 30 compnaies")
    }
    else {
      dispatch(setIsSpecialtyEdited(true))
      if (!items.detailFetched) {
        dispatch(fetchSimilarCompanyDetails(items.universalName))
      }
      // if(!similarCompanies.filter(simComp=>simComp.universalName===items.universalName)?.[0].fetched && !items.liked){
      if ((!items.fetched || qParams?.edit) && !items.liked) {
        dispatch(fetchJobSimilarCompanies(items.urn, items.universalName))
      }
      dispatch(similarCompanyThumbsUpHandler(items.universalName));
      setPillBtnHoverProfile(items.universalName)
    }
  };

  const handleOnMouseEnter = (value) => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout)
      setHoverTimeout(setTimeout(() => {
        setPillBtnHoverProfile(value.universalName)
        if (!value.detailFetched) {
          dispatch(fetchSimilarCompanyDetails(value.universalName))
        }
      }, 800))
    } else {
      setHoverTimeout(setTimeout(() => {
        setPillBtnHoverProfile(value.universalName)
        if (!value.detailFetched) {
          dispatch(fetchSimilarCompanyDetails(value.universalName))
        }
      }, 800))
    }
  };

  const handleOnMouseLeave = () => {
    clearTimeout(hoverTimeout)
    // setPillBtnHoverProfile('')
  };


  const renderSuggestion = (suggestion) => {
    console.log("==============renderSuggestion==============", suggestion)
    return (
      typeof suggestion === 'string' ?
        <div>{suggestion}</div> :
        <div>
          <ProfileSummaryCard
            key={suggestion.urn}
            name={suggestion.name}
            headline={suggestion.industry}
            imgSrc={suggestion.logo}
          />
        </div>
    )
  }

  const shouldRenderSuggestions = suggestion => (
    (typeof suggestion === "string"
      ? suggestion.trim()
      : suggestion?.name.trim()) &&
    !isLoadingSuggestion
  )


  return (
    <div className="similarCompaniesDetailsMainDiv">
      <div className="similarCompaniesDetails">
        <p>Thumbs up companies you are interested in hiring from</p>
        <div className="SimilarCompaniesBtn">
          {similarCompanies.map((items, index) => (
            <ThumbPillButton
              buttonLabel={items.universalName}
              similarCompanysUrn={items.urn}
              key={index}
              name={items.name}
              liked={items.liked}
              handleOnMouseEnter={e => handleOnMouseEnter(items)}
              handleOnMouseLeave={e => handleOnMouseLeave(items)}
              onClick={isReview === '1' ? () => false : () => handleThumbsUp(items)}
            // handleThumbsUp={()=>handleThumbsUp(items)}
            >
              {items.name}
            </ThumbPillButton>
          ))}
          {similarCompanies.filter(({ liked }) => liked).length < 30 && (addMore ?
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px'
              }}
            >
              <NewAutoSuggestion
                isLoading={isLoadingSuggestion}
                placeholder="ACME lnc."
                inputValue={companyName}
                suggestionsArray={companySuggestions}
                renderSuggestion={isReview === '1' ? () => false : renderSuggestion}
                shouldRenderSuggestions={isReview === '1' ? () => false : shouldRenderSuggestions}
                handleChangeInput={isReview === '1' ? () => false : handleChangeCompanyName}
                handleSuggestionsFetchRequested={isReview === '1' ? () => false : handlerFetchJobCompanySuggestion}
                handleSuggestionSelected={isReview === '1' ? () => false : handleAddSimilarCompany}
                handleSuggestionsClearRequested={isReview === '1' ? () => false : handleSuggestionsClearJobCompany}
              />
              <LabelButton
                className='addMoreButton'
                onClick={toggleAddMore}
              >
                <CrossSvg

                />
              </LabelButton>
            </span>
            :
            <React.Fragment>
              {addingSimilarCompany ?
                <Loading
                  width='24px'
                  height='24px'
                /> :
                isReview === '1' ? '' :
                  <button
                    className='addMoreButton'
                    onClick={toggleAddMore}
                  >
                    <PlusSvg /> Add More
                  </button>
              }
            </React.Fragment>)
          }
          {/* <CompanyPillSuggetions /> */}
        </div>

      </div>
      <div
        className='rightCompanyDetailsModal'
      >
        {currentHoverCompany?.detailFetched ?
          <RightOnClickModal
            style={{
              position: 'sticky',
              top: '0'
            }}
            profileImage={currentHoverCompany?.companyLogoUrl}
            profileName={currentHoverCompany?.name}
            profileIndustry={currentHoverCompany?.industry}
            overview={currentHoverCompany?.companyDescription || ""}
            website={currentHoverCompany?.companyPageUrl}
            industry={currentHoverCompany?.industry}
            specialities={currentHoverCompany?.specialities.join(", ")}
            // companySize={500}
            company_size={(currentHoverCompany?.staffCount || 0) > 10001 ?
              '10,000+' : numberWithCommas(currentHoverCompany?.staffCount || 0)
            }
            headquarters={`${currentHoverCompany?.headquarter?.city ?
              `${currentHoverCompany?.headquarter?.city}` : ''}${currentHoverCompany?.headquarter?.city && currentHoverCompany?.headquarter?.country ?
                ', ' : ""}${currentHoverCompany?.headquarter?.country ?
                  `${currentHoverCompany?.headquarter?.country}` : ''}`
            }
            type={currentHoverCompany?.companyType?.localizedName}
          /> :
          currentHoverCompany?.name ?
            <div className="hoverProfileSummaryCard">
              <ProfileSummaryCard
                width='64px'
                height='64px'
                imgSrc={currentHoverCompany?.logoURL}
                name={currentHoverCompany?.name}
                headline={currentHoverCompany?.industry}
              />
              {isLoadedSimilarCompanyDetails &&
                <Loading />
              }
            </div> :
            <div className="hoverProfiletag">
              <div>
                <JobSetupStep03 />
              </div>
              <p>Hover on a company for a second to view its details.</p>
            </div>
        }
      </div>
    </div>
  );
};

export default connect((state) => ({
  companyHiringFor: state.jobSetup.companyDetails.companyHiringFor,
  similarCompanies: state.jobSetup.companyDetails.similarCompanies,
  similarComapaniesOnclickProfile:
    state.jobSetup.companyDetails.similarComapaniesOnclickProfile,
  isLoadingSimilarCompaniesOnclickProfile:
    state.jobSetup.companyDetails.isLoadingSimilarCompaniesOnclickProfile,
  isLoadedSimilarCompaniesOnclickProfile:
    state.jobSetup.companyDetails.isLoadedSimilarCompaniesOnclickProfile,
  isLoadedSimilarCompanyDetails: state.jobSetup.companyDetails.isLoadedSimilarCompanyDetails,
  addingSimilarCompany: state.jobSetup.companyDetails.addingSimilarCompany,
  addedSimilarCompany: state.jobSetup.companyDetails.addedSimilarCompany,
  isLoadingSuggestion: state.jobSetup.companyDetails.isLoadingSuggestion,
  unLikedSimilarCompanies:
    state.jobSetup.companyDetails.unLikedSimilarCompanies,
  likedSimilarCompanies: state.jobSetup.companyDetails.likedSimilarCompanies,
  companySuggestions: state.jobSetup.companyDetails.companySuggestions,
  companyName: state.jobSetup.companyDetails.companyName,
  isReview: state.jobSetup?.jobSetup?.isReview
}))(SimilarCompaniesDetails);
