import React, { useEffect, useRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./style.scss";
import "./style.css";
import { connect } from "react-redux";
const DualWindowContainer = ({ LeftWindow, RightWindow, currentTab }) => {
  
  useEffect(() => {
    if(LeftWindow){
      setTimeout(()=>leftWindowRef?.current?.scrollTo({ top: 0, behavior: 'smooth' }), 600);
    }
    if(RightWindow){
      setTimeout(()=>rightWindowRef?.current?.scrollTo({ top: 0, behavior: 'smooth' }), 700);
    }
  }, [currentTab])

  const rightWindowRef = useRef(null)  
  const leftWindowRef = useRef(null)


  return (
    <div className="dualContainer">
      {/* <SwitchTransition> */}
      <CSSTransition
        in={LeftWindow}
        unmountOnExit={true}
        appear={true}
        timeout={500}
        classNames="leftWindow"
      >
        <span ref={rightWindowRef} className={`leftWindow ${RightWindow ? "" : ""}`}>
          {LeftWindow}
        </span>
      </CSSTransition>
      {/* </SwitchTransition>
            <SwitchTransition> */}
      <CSSTransition
        in={RightWindow}
        unmountOnExit={true}
        appear={true}
        timeout={500}
        classNames="rightWindow"
      >
        <span ref={leftWindowRef}  className={`rightWindow ${LeftWindow ? "" : "soloWindow"}`}>
          {RightWindow}
        </span>
      </CSSTransition>
      {/* </SwitchTransition> */}
    </div>
  );
};

export default connect(state => ({
  currentTab: state.jobSetup.viewController.currentTab,
}))(DualWindowContainer);

