import React from 'react'
import '../style.scss'
const JobSetupStep06 = ({
    strokeWidth='2',
    width='24',
    height='24',
    selected=false,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}  
                x="1" y="5" width="18" height="18" rx="2" 
                strokeWidth={strokeWidth}/>
            <rect
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}   
                x="1" y="5" width="22" height="18" rx="2" 
                strokeWidth={strokeWidth}/>
            <path
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}   
                d="M14.545 19.4547V18.2426C14.545 17.5997 14.2896 16.983 13.835 16.5284C13.3803 16.0738 12.7637 15.8184 12.1208 15.8184H7.87834C7.23539 15.8184 6.61878 16.0738 6.16415 16.5284C5.70951 16.983 5.4541 17.5997 5.4541 18.2426V19.4547"
                strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            <path
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}   
                d="M13 5V4C13 3.46957 12.8314 2.96086 12.5314 2.58579C12.2313 2.21071 11.8243 2 11.4 2H8.6C8.17565 2 7.76869 2.21071 7.46863 2.58579C7.16857 2.96086 7 3.46957 7 4V5"
                strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
            <path
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}   
                d="M9.99925 13.3934C11.3381 13.3934 12.4235 12.308 12.4235 10.9692C12.4235 9.63029 11.3381 8.54492 9.99925 8.54492C8.66038 8.54492 7.57501 9.63029 7.57501 10.9692C7.57501 12.308 8.66038 13.3934 9.99925 13.3934Z"
                strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default JobSetupStep06
