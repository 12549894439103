import React from 'react'
import CarouselTag from '../../../uiLibrary/CarouselTag'
import ThumbButton from '../ThumbButton/ThumbButton'

import './IdealProfileSkills.scss'


const IdealProfileSkills = ({
    skills,
    getState,
    toggleState
}) => {

    return (
        <div
            className='idealProfileSkills'
        >
            <h1>
                Skills
            </h1>
            <span
                className='idealProfileSkills_Container'
            >
                {
                    skills.map((skill , index) => {

                        const skillState = getState(skill, 'skills')

                        return(
                            <CarouselTag
                                key={index}
                                type='skill'
                                description={skill}
                                greyTheme={skillState?.greyTheme}
                                selected={skillState?.selected}
                                highlight={skillState?.highlight}
                                onClick={e => toggleState(skillState, skill, 'skills')}
                            >
                                {skill}
                            </CarouselTag>
                        )
                    })
                }
            </span>
        </div>
    )
}

export default IdealProfileSkills
