import { React, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import './home.scss'

import CalendarSvg from '../../../uiLibrary/SVGs/CalSvg'
import CaretLeft from '../../../uiLibrary/SVGs/CaretLeft'
import CaretWideRightSvg from '../../../uiLibrary/SVGs/CaretWideRight'
import MailSvg from '../../../uiLibrary/SVGs/MailSvg'
import BriefcaseSvg from '../../../uiLibrary/SVGs/BriefcaseSvg'
import ExtensionIcon from '../../../uiLibrary/SVGs/ExtensionIcon'
import CheckIcon from '../../../uiLibrary/SVGs/Checkicon'
import { pingQlu } from '../../../utils/MessagePassing'
import { setQluExtInstallStatus } from '../../../actions/meta'
import { clearConsentWindow, validateEmailPermissionToken } from '../../../actions/hybridCampaign'
import { validateCalendarToken } from '../../../actions/calendar'
import Loading from '../../../uiLibrary/Loading'
import { setOutboxView } from '../../../actions/outbox'
import { Mixpanel } from '../../../utils/Mixpanel'


const ActionItem = ({
  title,
  subtitle,
  icon,
  onClick,
  slim
}) => {
  return (
    <div className='actionitem' style={slim ? { width: '100%' } : {}} onClick={onClick}>
      {icon}
      <div style={slim ? { width: '275px' } : {}}>
        <div>
          <p>{title}</p>
          {!slim && <p>{subtitle}</p>}
        </div>
        <CaretLeft style={{ transform: 'rotate(180deg)' }} />
      </div>
    </div>
  )
}

const Fte = ({
  showHeader = true,
  isJobCreated = false,
  emailState,
  emailProvider,
  tokenLoading,
  tokenLoaded,
  history,
  dispatch,
  consentWindow,
  meta: { isQluInstalled },
  calendar,
  slim = false
}) => {
  useEffect(async () => {
    try {
      if (consentWindow) {
        consentWindow.close()
        dispatch(clearConsentWindow())
      }
      dispatch(validateEmailPermissionToken());
      // dispatch(validateCalendarToken());
      if (!isQluInstalled) {
        const r = await new Promise((resolve, reject) => {
          pingQlu(res => {
            // console.log("====================pingQlu=====================", res)
            resolve(res)
          })
        })

        if (!isEmpty(r)) {
          dispatch(setQluExtInstallStatus(true))
        }
      }
      // console.log('PING QLU RESPONSE: ', r)
    } catch (e) {
      console.log('AN ERROR OCCURED: ', e.message)
    }

  }, [])
  const onMailBoxClick = () => {
    if (tokenLoaded && !tokenLoading && !emailState && emailProvider === "GOOGLE") {
      Mixpanel.track("Link your mailbox - home")
      dispatch(
        setOutboxView({
          view: "CONSENT",
          fte: true
        })
      )
    }
  }
  const onCalendarClick = () => {
    // if (calendar.tokenLoaded && !calendar.tokenLoading && !calendar.valid) {
    //   window.location.href = `${process.env.REACT_APP_API_HOST}/googleCalendar/login`
    // }
    // else {
    //   history.push('/calendar')
    // }
  }
  return (
    <div className='fteroot'>
      {showHeader &&
        <>
          <p>Getting Started</p>
          <p>Welcome, Let's get things rolling to get the most out of Qlu</p>
        </>
      }
      <div className='ai-ico-container' style={slim ? { width: '100%' } : {}}>
        <ActionItem
          title='Create your first job'
          subtitle='Get started with your first job on Qlu'
          onClick={() => { if (!isJobCreated) { history.push('/jobsetup') } }}
          icon={isJobCreated ?
            <CheckIcon /> :
            <BriefcaseSvg className='ai-ico' />
          }
          slim={slim}
        />
        <div className='hor-div' />
        <ActionItem
          title='Install the extension from Chrome Store'
          subtitle='Needed to automate linkedin outreach'
          icon={isQluInstalled ? <CheckIcon /> : <ExtensionIcon className='ai-ico' />}
          onClick={() => { if (!isQluInstalled) { 
            Mixpanel.track("Install Extension from chrome store")
            window.open(process.env.REACT_APP_QLU_EXT_LINK, "_blank") } }}
          slim={slim}
        />
        <div className='hor-div' />
        <ActionItem
          title='Link your mailbox'
          subtitle='Grant permissions to automate email messages'
          icon={tokenLoading ? <span><Loading width='24' height='24' /></span> : emailState ? <CheckIcon /> : <MailSvg className='ai-ico' />}
          onClick={onMailBoxClick}
          slim={slim}
        />
        <div className='hor-div' />
        {/* <a style={{ textDecoration: 'none' }}>
          <ActionItem
            title='Link your Calendar'
            subtitle='Needed to sync all your events with Qlu'
            icon={calendar.valid ? <CheckIcon /> : <CalendarSvg className='ai-ico' />}
            onClick={onCalendarClick}
            slim={slim}
          />
        </a> */}
      </div>
    </div>
  )
}

export default connect(state => ({
  emailState: state.hybridCampaign.campaignOperations.emailState,
  emailProvider: state.hybridCampaign.campaignOperations.emailProvider,
  tokenLoading: state.hybridCampaign.campaignOperations.tokenLoading,
  tokenLoaded: state.hybridCampaign.campaignOperations.tokenLoaded,
  calendar: state.auth.calendar,
  consentWindow: state.hybridCampaign.campaignOperations.consentWindow,
  meta: state.meta
}))(withRouter(Fte))