import React from "react";
import LabelButton from "../LabelButton";
import PrimaryButton from "../PrimaryButton";
import CaretLeft from "../SVGs/CaretLeft";
import CrossSvg from "../SVGs/CrossSvg";
import "./style.css";
const ActionBar = ({
  containerClassName = "",
  className = "",
  PrimaryLabel = "Next",
  onPrimaryClick,
  SecondaryLabel = "Back",
  onSecondaryClick = false,
  primaryDisabled,
  secondaryDisabled,
  onPrimaryLoading,
  style = {},
  showPrimaryClick = true,
  primaryButton=false,
  isPaused,
  onFinish,
  children,
  finish,
  view
}) => {
  const bg = view=="Summary" ? finish?"var(--bg-highlight-primary)": isPaused?  "#63B780" : "#EF5555" : "var(--bg-highlight-primary)"
  return (
    <div style={style} className={`qluActionBarContainer  ${className}`}>
      <div>
        <span className={`qluActionBar ` 
        }>
          <span className="qluActionBarButtonContainer">
            {onSecondaryClick && (
              <LabelButton
                onClick={onSecondaryClick}
                disabled={secondaryDisabled}
              >
                <CaretLeft /> {SecondaryLabel}
              </LabelButton>
            )}
          </span>
          {children}

          <span className="qluActionBarButtonContainer">
            {showPrimaryClick && !primaryButton && (
              <PrimaryButton
                style={{backgroundColor: bg}}
                onClick={onPrimaryClick}
                disabled={primaryDisabled}
                isLoading={onPrimaryLoading}
              >
                {PrimaryLabel}
              </PrimaryButton>
            )}
            {primaryButton && primaryButton}
          </span>          
        </span>
      </div>
    </div>
  );
};

export default ActionBar;
