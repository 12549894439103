import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { _deleteCounter, archiveProspects, clearRecommendedCandidates, likedProfile, updateRecommendedProspect } from '../../actions/prospectsView';
import { Mixpanel } from '../../utils/Mixpanel';
import './style.scss';
const SocialRejectButton = ({
  dispatch,
  permissionsAllowed,
  index,
  jobProfileId,
  archived = false,
  saved,
  onClickSave,
  jId,
  deleteCount,
  mixpanelActionType = false,
  isAddingProfile = false,
  onClick = e => {
    if (permissionsAllowed === "*" || (permissionsAllowed?.length && permissionsAllowed?.length > 0 && (permissionsAllowed?.includes("GIVE_FEEDBACK") || permissionsAllowed?.includes("CAN_ARCHIVE")))) {
      const data = {
        index: index,
        status: archived ? null : "RECOMMENDED_NO",
        jobProfileId: jobProfileId,
      };
      if (mixpanelActionType) {
        Mixpanel.track(`Dislike - ${mixpanelActionType}`)
      }
      dispatch(likedProfile(data));
      if (isAddingProfile) {
        dispatch(updateRecommendedProspect({
          saved: false,
          maybe: false,
          archived: !archived
        }))
        setTimeout(() => {
          dispatch(clearRecommendedCandidates());
        }, 300)
      }
      else {
        if (saved) {
          onClickSave(
            [jobProfileId],
            saved
          );
        }
        dispatch(
          archiveProspects(jId, [
            jobProfileId,
          ])
        );
        dispatch(_deleteCounter(deleteCount + 1));
        toast(
          `Prospect ${archived ? 're' : ''}moved ${archived ? 'from' : 'to'} disliked folder`,
          {
            hideProgressBar: true,
            position: "top-left",
            style: {
              backgroundColor: "#A0A0A0",
              borderRadius: "8px",
              width: "400px",
              color: "black",
              fontFamily: "Inter",
            },
          }
        );
      }
    }
  },
  ...props
}) => {
  const [disabled, setDisabled] = useState(false)
  return (
    <button
      className={`socialRejectButton ${archived ? 'socialRejectButton-activate' : ''}`}
      onClick={(e) => {
        if (isAddingProfile) {
          setDisabled(true)
        }
        onClick(e)
      }}
      disabled={disabled}
      {...props}
    >
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 7L7 21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 7L21 21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  )
}

export default connect(state => ({
  jId: state?.prospectsView?.candidates?.job?.id,
  deleteCount: state.prospectsView.candidates.deleteCount,
  permissionsAllowed: state?.prospectsView?.candidates?.permissions,
}))(SocialRejectButton)