import React from "react";
import ProfileCard from "../ProfileCard";
import ProfileDrawer from "../ProfileDrawer";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLikedProfiles,
  setSelectedProfile,
  toggleDetailView,
} from "../../../actions/QuickSetup";
import {
  getCalibrationProfilesIsFetching,
  getCalibrationProfilesData,
  getIsDetailViewDrawerOpen,
  getSelectedProfile,
} from "../../../reducers/QuickSetup/calibrationProfiles";
import Loading from "../../../uiLibrary/Loading";

export default function ViewLikedProfiles() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const calibrationProfiles = useSelector((state) =>
    getCalibrationProfilesData(state.QuickSetup.calibrationProfiles)
  );
  const isFetching = useSelector((state) =>
    getCalibrationProfilesIsFetching(state.QuickSetup.calibrationProfiles)
  );
  const isDetailView = useSelector((state) =>
    getIsDetailViewDrawerOpen(state.QuickSetup.calibrationProfiles)
  );
  const selectedProfile = useSelector((state) =>
    getSelectedProfile(state.QuickSetup.calibrationProfiles)
  );

  const setIsDetailView = (isOpen) => dispatch(toggleDetailView(isOpen));
  const updateSelectedProfile = (profile) =>
    dispatch(setSelectedProfile(profile));

  return (
    <div className="calibrationContainer">
      {isFetching ? (
        <div className="loadingContainer">
          <Loading />
        </div>
      ) : (
        <div className="calibrationProfiles">
          {calibrationProfiles?.map((profile, index) => (
            <ProfileCard
              setIsDetailView={setIsDetailView}
              isDetailView={isDetailView}
              selectedProfile={selectedProfile}
              setSelectedProfile={updateSelectedProfile}
              profile={profile}
              key={index}
            />
          ))}
        </div>
      )}
      {isDetailView && (
        <ProfileDrawer
          isDetailView={isDetailView}
          setIsDetailView={setIsDetailView}
          profile={selectedProfile}
          isViewOnly
        />
      )}
    </div>
  );
}
