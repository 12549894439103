import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getIsViewOnly } from "../../../reducers/QuickSetup/calibrationProfiles";
import SuperLike from "../../../uiLibrary/SuperLike";

export default function LikeableSection({
  name,
  handleLike = () => {},
  children,
  isLiked = false,
}) {
  const isViewOnly = useSelector((state) =>
    getIsViewOnly(state.QuickSetup.calibrationProfiles)
  );

  const [hover, setHover] = React.useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  const onLike = (liked) => {
    handleLike(liked);
  };
  return (
    <div
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      className="likeableSectionContainer"
      style={{ pointerEvents: isViewOnly ? "none" : "all" }}
    >
      <div className="likeableSection">{children}</div>
      {hover || Boolean(isLiked) ? (
        <div className="eclipseContainer">
          <SuperLike
            likedHearts={Number(isLiked)}
            toggleLike={onLike}
            totalHearts={1}
          />
        </div>
      ) : null}
    </div>
  );
}
