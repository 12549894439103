import React, {useState} from 'react'
import { Tooltip } from 'reactstrap'
import { v4 } from 'uuid'
import StepperButton from '../StepperButton'
import CrossSvg from '../SVGs/CrossSvg'
import './style.scss'

const Step = ({
  step,
  devider,
  disabled,
  onClick,
  selected,
  toolTip='tt'
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return  (
    <React.Fragment>
      {toolTip && toolTip!=='tt' ? 
        <React.Fragment>    
          <div
            id={toolTip.replace(/\W/g, '') }
          >
            <StepperButton
              disabled={disabled}
              onClick={onClick}
              selected={selected}
            >
              {step}
            </StepperButton>
          </div>
          <Tooltip
            innerClassName={'stepToolTipInner'}
            placement="right"
            isOpen={isOpen}
            target={toolTip.replace(/\W/g, '')}
            toggle={()=>setIsOpen(prev=>!prev)}
            fade={false}
          >
            {toolTip}
          </Tooltip> 
        </React.Fragment>:
        <div>
          <StepperButton
            disabled={disabled}
            onClick={onClick}
            selected={selected}
          >
            {step}
          </StepperButton>
        </div>

      }
      
      {devider && <hr />}
    </React.Fragment>
  )
}

const StepperContainer = ({
  steps = [],
  onClose = e => false,
  showCloseIcon = true,
  className = ''
}) => {

  const stepper = showCloseIcon ? [
    {
      step: <CrossSvg />,
      devider: true,
      onClick: onClose
    },
    ...steps
  ] : steps

  return (
    <div
      className={`qluStepperContainer ${className}`}
    >
      {stepper.map(({ step, devider = false, disabled, onClick, selected, toolTip }, i) => (
        <Step
          key={i}
          step={step}
          devider={devider}
          disabled={disabled}
          onClick={onClick}
          selected={selected}
          toolTip={toolTip}
        />  
      ))}
    </div>
  )
}

export default StepperContainer
