import React, {useRef, useState, useEffect} from 'react'
import CrossSvg from '../SVGs/CrossSvg'
import TickSvg from '../SVGs/TickSvg'
import './style.scss'
const SeconadryInput = ({
    value='',
    onTickClick=e=>false,
    onCrossClick=e=>false
}) => {
    const [state, setstate] = useState(value)
    
  const inputRef = useRef(null);

    useEffect(() => {
      inputRef.current.focus();
    }, [])

    return (
        <div
            className='seconadryInputContainer'
        >
            <input
                ref={inputRef}
                value={state}
                onChange={e=>setstate(e.target.value)}
            />
            <button
                onClick={e=>onTickClick(e, state)}
            >
                <TickSvg/>
            </button>
            <button    
                onClick={e=>onCrossClick(e, state)}
            >
                <CrossSvg
                    width='16' 
                    height='16'
                    strokeWidth='3'
                />
            </button>
        </div>
    )
}

export default SeconadryInput
