import { isEmpty, result, uniqueId } from "lodash";
import React, { useState, useEffect } from "react";
import "./Prioritize.scss";
import { v4 } from "uuid";
import { connect } from "react-redux";
import {
  setMoreProspectCurrentTab,
  setMoreProspectSkillsArray,
  setMoreProspectSpecialitiesArray,
  setPriorityArray,
  _setPrioritizeArray,
} from "../../../actions/prospectsView";
import MoveSvg from "../../SVGs/MoveSvg";
import { Mixpanel } from "../../../utils/Mixpanel";
function Prioritize({ priorityArray, prioritizeArray, dispatch }) {
  const [prioritizeItems, setPrioritizeItems] = useState(prioritizeArray);
  const [priorityIndex, setPriorityIndex] = useState([
    { id: v4(), val: 1 },
    { id: v4(), val: 2 },
    { id: v4(), val: 3 },
    { id: v4(), val: 4 },
  ]);
  const [changeCount, setChangeCount] = useState(0);

  useEffect(() => {}, [priorityIndex, changeCount]);

  const [priorityItems, setPriorityItems] = useState(priorityArray);

  return (
    <div className="set-prioritize-size">
      <div className="title">Find more prospects</div>
      <div className="sub-title">
        What do you care more about in prospects for this job? Click in order of
        importance.
      </div>
      <div className="steps">
        {prioritizeItems.map((item, index) => {
          let exist = priorityItems.filter((e) => e.name == item.name);
          return (
            <div
              className={`step ${exist?.length > 0 ? "active" : ""}`}
              key={item.id}
              onClick={() => {
                Mixpanel.track(`set prioritization - find more prospects`)
                if (exist?.length > 0) {
                  dispatch(setMoreProspectSkillsArray([]));
                  dispatch(setMoreProspectSpecialitiesArray([]));
                  setPriorityItems((prev) => {
                    const prevItems = [...prev];
                    const index = prevItems.findIndex(
                      (e) => e.name == item.name
                    );
                    prevItems.splice(index, 1);
                    dispatch(setPriorityArray(prevItems));
                    return prevItems;
                  });
                } else {
                  setPriorityItems((prev) => {
                    dispatch(setPriorityArray([...prev, item]));
                    return [...prev, item];
                  });
                }
              }}
            >
              {exist?.length > 0 ? (
                <div className="step-item-index">
                  {priorityItems.indexOf(item) + 1}
                </div>
              ) : (
                <div className="step-item-icon">
                  <div className="icon">{item.icon}</div>
                </div>
              )}

              <div
                className={`step-item-name ${
                  exist?.length > 0 ? "active-name" : ""
                }`}
              >
                {item.name == "company size" ? "employee count" : item.name}
              </div>
            </div>
          );
        })}
      </div>

      <div
        className={`clear  ${priorityItems.length > 0 ? "active" : ""}`}
        onClick={() => {
          if (priorityItems.length) {
            setPriorityItems([]);
            dispatch(setPriorityArray([]));
            dispatch(setMoreProspectSkillsArray([]));
            dispatch(setMoreProspectSpecialitiesArray([]));
            dispatch(setMoreProspectCurrentTab(null));
          }
        }}
      >
        clear
      </div>
    </div>
  );
}

export default connect((state) => ({
  priorityArray: state.prospectsView.findMoreProspect.priorityArray,
  prioritizeArray: state.prospectsView.findMoreProspect.prioritizeArray,
}))(Prioritize);
