import React, { useState } from 'react'
import ProfileDisplayImage from '../ProfileDisplayImage/ProfileDisplayImage'
import linkedinLogo from '../../img/linkedin-icon.svg'
import PrimaryCheckBox from '../../uiLibrary/PrimaryCheckBox'
import { toggleProspect } from '../../actions/prospectsView'
import { useDispatch } from 'react-redux';
import './profileSummaryCard.scss'
import { Tooltip } from 'reactstrap'
const ProfileSummaryCard = ({
    displayCheckBox = false,
    handleSelectProspect = () => {},
    currentProspect,
    className,
    cardId,
    width = '46px',
    height = '46px',
    imgSrc,
    name = "John",
    url = false,
    headline,
    degree = false,
    isCompany = false,
    exp
}) => {
    console.log("exppp",exp)
    const [isOpen, setIsOpen] = useState(false)
    const [isNameOpen, setIsNameOpen] = useState(false)
    const dispatch = useDispatch()
    const onSelect = () => {
        dispatch(toggleProspect(currentProspect));
      };
    return (
        <div
            id={cardId}
            className={`profileSummaryCard ${className}`}
        >
            {displayCheckBox && <PrimaryCheckBox
                style={{ width: '16px', height: '16px', flexShrink:0 }}
                onChange={onSelect}
                checked={currentProspect?.isRemoved ? false : true}
            />}
            <ProfileDisplayImage
                onClick={handleSelectProspect}
                width={width}
                height={height}
                src={imgSrc}
                isCompany={isCompany}
            // onError={()=>setDisplayPicSrc(displayPlaceholder)}
            />
            <span onClick={handleSelectProspect}>
                <div>
                    <p
                        id={`nameToolTipTarget${cardId}`}
                        className='profileName'
                    >
                        {name}
                    </p>
                    {cardId &&
                        <Tooltip
                            placement="bottom"
                            isOpen={isNameOpen}
                            target={`nameToolTipTarget${cardId}`}
                            toggle={() => setIsNameOpen(prev => !prev)}
                            fade={false}
                        >
                            {name}
                        </Tooltip>}
                    {url &&
                        <a
                            href={url} target='_blank'
                        >
                            <img src={linkedinLogo} />
                        </a>
                    }

                    {degree &&
                        <React.Fragment>
                            <span className='profileSummaryCardSeperator' />
                            <p
                                className='profileHeadline'
                            >
                                {degree}
                            </p>
                        </React.Fragment>
                    }
                </div>
                <div>
                    {headline && <p
                        id={`headlineToolTipTarget${cardId}`}
                        className='profileHeadline'
                    >
                        {(headline || "")?.replace(/\n/g, "\s")?.replace(/\sat\s/g, "@")}
                    </p>}
                    {cardId && headline &&
                        <Tooltip
                            placement="top"
                            isOpen={isOpen}
                            target={`headlineToolTipTarget${cardId}`}
                            toggle={() => setIsOpen(prev => !prev)}
                            fade={false}
                        >
                            {headline}
                        </Tooltip>

                    }
                </div>
            </span>
        </div>
    )
}

export default ProfileSummaryCard
