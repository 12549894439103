import { v4 } from "uuid";
import {
  SEARCH_PROSPECTS_DATA,
  SET_BATCH_SIZE,
  SET_MORE_PROSPECT_CURRENT_TAB,
  SET_MORE_PROSPECT_SKILLS_ARRAY,
  SET_MORE_PROSPECT_SPECIALITIES_ARRAY,
  SET_PRIORITIZE_ARRAY,
  SET_PRIORITY_ARRAY,
  START_SEARCH,
} from "../../actions/prospectsView";
import JobSetupStep03 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep03";
import JobSetupStep04 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep04";
import JobSetupStep07 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep07";
import JobSetupStep09 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep09";
import JobSetupStep10 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep10";

const initialState = {
  batchSize: 50,
  priorityArray: [],
  prioritizeArray: [
    {
      id: v4(),
      icon: <JobSetupStep09 />,
      name: "skills",
      currentSource: 0,
    },
    // {
    //   id: v4(),
    //   icon: <JobSetupStep10 />,
    //   name: "education",
    //   currentSource: 1,
    // },
    // {
    //   id: v4(),
    //   icon: <JobSetupStep07 />,
    //   name: "experience",
    //   currentSource: 2,
    // },
    {
      id: v4(),
      icon: <JobSetupStep04 />,
      name: "business",
      currentSource: 3,
    },
    {
      id: v4(),
      icon: <JobSetupStep03 />,
      name: "company score",
      currentSource: 4,
    },
    {
      id: v4(),
      icon: <JobSetupStep03 />,
      name: "company size",
      currentSource: 5,
    },
  ],
  startSearch: false,
  searchProspects: {},
  currentTab: "",
  skills: [],
  specialities: [],
};

const findMoreProspect = (state = initialState, action) => {
  switch (action.type) {
    case SET_BATCH_SIZE:
      return {
        ...state,
        batchSize: action.payload,
      };
    case SET_PRIORITY_ARRAY:
      return {
        ...state,
        priorityArray: action.payload,
      };
    case START_SEARCH:
      return {
        ...state,
        startSearch: !state.startSearch,
      };
    case SEARCH_PROSPECTS_DATA:
      return {
        ...state,
        searchProspects: action.payload,
      };
    case SET_PRIORITIZE_ARRAY:
      return {
        ...state,
        prioritizeArray: action.payload,
      };
    case SET_MORE_PROSPECT_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    case SET_MORE_PROSPECT_SKILLS_ARRAY:
      return {
        ...state,
        skills: action.payload,
      };
    case SET_MORE_PROSPECT_SPECIALITIES_ARRAY:
      return {
        ...state,
        specialities: action.payload,
      };
    default:
      return state;
  }
};
export default findMoreProspect;
