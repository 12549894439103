import React, { useEffect } from 'react'
import CommentsSection from '../../../components/CommentsSection/CommentsSection';
import './comments.scss'
import { connect } from 'react-redux'

const Comments = ({ clickedProspectDetails }) => {

    return (
        <div className="commentsContainer">
            <CommentsSection shouldShow={true} clickedProspectsDetails={clickedProspectDetails} />
        </div >
    )
}

export default connect(
    state => ({
        comments: state.auth.calendar.comments,
        user: state.auth
    })
)(Comments)