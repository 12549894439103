import React from 'react'
import './style.scss'
const StepperButton = ({
    selected=false,
    disabled=false,
    onClick=e=>false,
    children
}) => {
    return (
        <button
            className={`stepperButton ${selected ? 'stepperButtonSelected' : ''}`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

export default StepperButton
