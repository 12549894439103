import { manualApiCall } from "../utils"
import { getInmailCredits } from "../utils/MessagePassing"

export const SET_HYBRID_CAMPAIGN_VIEW = 'SET_HYBRID_CAMPAIGN_VIEW'
export const setHybridCamapaignView = (payload) => ({
    type: SET_HYBRID_CAMPAIGN_VIEW,
    payload
})

export const INIT_HYBRID_CAMPAIGN_VIEW = 'INIT_HYBRID_CAMPAIGN_VIEW'
export const initHybridCampaignView = () => ({
    type: INIT_HYBRID_CAMPAIGN_VIEW
})

export const INIT_HYBRID_CAMPAIGN = 'INIT_HYBRID_CAMPAIGN'
export const initHybridCampaign = (payload) => ({
    type: INIT_HYBRID_CAMPAIGN,
    payload
}) 

export const UPDATE_HYBRID_CAMPAIGN = 'UPDATE_HYBRID_CAMPAIGN'
export const updateHybridCampaign = (payload) => ({
    type: UPDATE_HYBRID_CAMPAIGN,
    payload
})

export const LOAD_HYBRID_CAMPAIGN = 'LOAD_HYBRID_CAMPAIGN'
export const LOAD_HYBRID_CAMPAIGN_SUCCESS = 'LOAD_HYBRID_CAMPAIGN_SUCCESS'
export const LOAD_HYBRID_CAMPAIGN_FAILURE = 'LOAD_HYBRID_CAMPAIGN_FAILURE'

export const hybridCampaignLoading = () => ({
    type: LOAD_HYBRID_CAMPAIGN
})
export const hybridCampaignLoaded = () => ({
    type: LOAD_HYBRID_CAMPAIGN_SUCCESS
})
export const hybridCampaignFaliure = () => ({
    type: LOAD_HYBRID_CAMPAIGN_FAILURE
})

export const ADD_FOLLOW_UP_HYBRID_CAMPAIGN = 'ADD_FOLLOW_UP_HYBRID_CAMPAIGN'
export const addFollowUpHybridCampaign = () => ({
    type: ADD_FOLLOW_UP_HYBRID_CAMPAIGN
})

export const REMOVE_FOLLOW_UP_HYBRID_CAMPAIGN = 'REMOVE_FOLLOW_UP_HYBRID_CAMPAIGN'
export const removeFollowUpHybridCampaign = () => ({
    type: REMOVE_FOLLOW_UP_HYBRID_CAMPAIGN
})

export const DISCARD_HYBRID_CAMPAIGN = 'DISCARD_HYBRID_CAMPAIGN'
export const discardHybridCampaign = () => ({
    type: DISCARD_HYBRID_CAMPAIGN
})


export const PUBLISH_HYBRID_CAMPAIGN = 'PUBLISH_HYBRID_CAMPAIGN'
export const PUBLISH_HYBRID_CAMPAIGN_SUCCESS = 'PUBLISH_HYBRID_CAMPAIGN_SUCCESS'
export const PUBLISH_HYBRID_CAMPAIGN_FAILURE = 'PUBLISH_HYBRID_CAMPAIGN_FAILURE'
export const publishHybridCampaign = ( jId, variableTranslator,  userName, jobData) => async (dispatch, getState) => { 
    try{    
        dispatch({
            type: PUBLISH_HYBRID_CAMPAIGN,
        })


        const campaign = getState().hybridCampaign.campaign.campaign
        const prospects = getState().prospectsView.candidates.selectedProspects


        const channels = {
            "Connect Message": {
                first_degree_channel:   'LinkedIn',
                second_degree_channel:  'LinkedIn'
            },
            "InMail": {
                first_degree_channel:   'LinkedIn',
                second_degree_channel:  'InMail'
            },
            "Email": {
                first_degree_channel:   'LinkedIn',
                second_degree_channel:  'Email'
            },
            "Follow Up": {
                first_degree_channel:   'LinkedIn',
                second_degree_channel:  'Email'
            }
        }


        const reachoutRounds = campaign.map(({
            nodeType,
            firstDegreeSubject,
            firstDegreeTemplate,
            firstDegreeMessageErrors,
            secondDegreeSubject,
            secondDegreeTemplate,
            secondDegreeMessageErrors,
            sendAfterDays,
            sendAfterHours
        }, round ) => {

            const campaignMessages = Object.keys(prospects).map(key => {
                const firstDegreeMessageErrorsIds = firstDegreeMessageErrors.map(({recepient}) => recepient.id)
                const secondDegreeMessageErrorsIds =  secondDegreeMessageErrors.map(({recepient}) => recepient.id)

                const first_degree_message = firstDegreeMessageErrorsIds.includes(key) ?
                    firstDegreeMessageErrors.filter(({recepient}) => recepient.id===key).pop()?.message.replace(/'/g, "''") :
                    variableTranslator(firstDegreeTemplate, prospects[key], userName, jobData).replace(/'/g, "''") 

                const second_degree_message = secondDegreeMessageErrorsIds.includes(key) ?
                    secondDegreeMessageErrors.filter(({recepient}) => recepient.id===key).pop()?.message.replace(/'/g, "''") :
                    variableTranslator(secondDegreeTemplate, prospects[key], userName, jobData).replace(/'/g, "''") 

                return(
                    {
                        jobProfileId: prospects[key].jobProfileId,
                        first_degree_message,
                        second_degree_message,
                        key
                    }
                )
            })

            const reachoutRoundsPayload = {
                type: nodeType,
                round,
                sendAfterDays,
                sendAfterHours,
                first_degree_template: firstDegreeTemplate.replace(/'/g, "''") ,
                second_degree_template: secondDegreeTemplate.replace(/'/g, "''") ,
                campaignMessages,
                ...channels[nodeType]
            }
            
            // ['first_degree_subject']= firstDegreeSubject.replace(/'/g, "''") ,
            if(['Follow Up', 'Email', 'InMail'].includes(nodeType)){
                reachoutRoundsPayload['second_degree_subject']= secondDegreeSubject.replace(/'/g, "''")
                reachoutRoundsPayload['first_degree_subject']= firstDegreeSubject.replace(/'/g, "''")
            }
            
            return reachoutRoundsPayload
        })

        console.log("=============reachoutRounds=================", reachoutRounds)

        const res = await manualApiCall('/api/auth/hybridCampaign/publish', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                reachoutRounds,
                jId 
            })
        })
            
        if(res?.isSuccess && Array.isArray(res?.campaignMessageIds) && res?.campaignMessageIds.length>0){
            const campaignMessageIds = res.campaignMessageIds
            dispatch({
                type: PUBLISH_HYBRID_CAMPAIGN_SUCCESS, 
                payload: reachoutRounds[0]?.campaignMessages.map((prev, idx)=>({
                    ...prev,
                    second_degree_subject: reachoutRounds[0]?.second_degree_subject,
                    first_degree_subject: reachoutRounds[0]?.first_degree_subject,
                    recepient: prospects[prev.key],
                    campaignMessageId: campaignMessageIds.filter(({jobProfileId})=>prev.jobProfileId===jobProfileId).pop()?.campaignMessageId
                }))
            })
        }
        else
            throw new Error("Publishing api failed!")
    }
    catch(e) {
        console.log("ERROR: ", e)
        dispatch({
            type: PUBLISH_HYBRID_CAMPAIGN_FAILURE
        })
    }
}

export const SET_CONSENT_WINDOW = "SET_CONSENT_WINDOW"
export const setConsentWindow = (win) => ({
  type: SET_CONSENT_WINDOW,
  payload: win
})

export const CLEAR_CONSENT_WINDOW = "CLEAR_CONSENT_WINDOW"
export const clearConsentWindow = () => ({
  type: CLEAR_CONSENT_WINDOW
})


export const FETCH_EMAIL_PROVIDER = 'FETCH_EMAIL_PROVIDER'
export const fetchEmailProvider = () => async (dispatch) => {
  try{
    const resp = await manualApiCall('/api/auth/emailing/emailProvider', {method: "GET"})
    if(resp?.isSuccess && resp?.data){
      dispatch({
        type: FETCH_EMAIL_PROVIDER,
        payload: resp.data
      })
    }
    else{
      throw new Error
    }
  }
  catch(err) {
    console.log("email provider api failed!", err)
  }
}

export const REFRESH_TOKEN_VALIDITY = 'REFRESH_TOKEN_VALIDITY'
export const REFRESH_TOKEN_VALIDITY_SUCCESS = 'REFRESH_TOKEN_VALIDITY_SUCCESS'
export const REFRESH_TOKEN_VALIDITY_FAILURE = 'REFRESH_TOKEN_VALIDITY_FAILURE'
export const validateEmailPermissionToken = () => async (dispatch) => {
    try {
      dispatch({
          type: REFRESH_TOKEN_VALIDITY
      })
      const result = await manualApiCall('/api/auth/emailing/validityOfTokens',
        {
          method: 'GET',
        })
      if (result.success)
        dispatch({
            type: REFRESH_TOKEN_VALIDITY_SUCCESS,
            payload: {...result}
        })
      else
        throw new Error
    } catch {
        dispatch({
            type: REFRESH_TOKEN_VALIDITY_FAILURE
        })
    }
}


export const INMAIL_CREDITS_LOADING = 'INMAIL_CREDITS_LOADING'
export const INMAIL_CREDITS_SUCCESS = 'INMAIL_CREDITS_SUCCESS'
export const INMAIL_CREDITS_FAILIURE = 'INMAIL_CREDITS_FAILIURE'
export const setInMailCredits = () => async (dispatch) => {
  try {
    dispatch({
      type: INMAIL_CREDITS_LOADING
    })
    const result = await new Promise((resolve, reject)=>getInmailCredits(r=>resolve(r)))
    if(result.isSuccess){
      dispatch({
        type: INMAIL_CREDITS_SUCCESS,
        payload: result.credits
      })
    }
    else
      throw Error("Inmail credit api Failed")
  } catch(e) {
    console.log("ERROR :", e)
    dispatch({
      type: INMAIL_CREDITS_FAILIURE
    })
  }
}


export const SET_PROSPECTS_TO_SEND = 'SET_PROSPECTS_TO_SEND'
export const setProspectsToSend = (prospects) => ({
    type: SET_PROSPECTS_TO_SEND, 
    payload: { prospects }
})


export const INCREMENT_CONNECT_SENT = 'INCREMENT_CONNECT_SENT'
export const incrementConnectSent = () => ({
    type: INCREMENT_CONNECT_SENT
})
export const SET_CONNECT_LIMIT = 'SET_CONNECT_LIMIT'
export const setConnectLimit = (limit) => ({
    type: SET_CONNECT_LIMIT,
    payload: limit
})
export const INITIALIZE_CONNECT_SENT = 'INITIALIZE_CONNECT_SENT'
export const initializeConnectSent = (payload) => ({
    type: INITIALIZE_CONNECT_SENT,
    payload: payload
})
  