import {
  JDFAQ1,
  JDFAQ2,
  JDFAQ3,
} from "../Answers/JD";

export const JobDescriptionFAQS = [
  {
    heading: "What’s needed to set up a new search?",
    content: <JDFAQ1 />,
  },
  {
    heading: "What if I don't have a Job Description?",
    content: <JDFAQ2 />,
  },
  {
    heading: "Is there a specific format that I may need for a JD?",
    content: <JDFAQ3 />,
  }
];
