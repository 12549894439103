import React from "react";
import { Badge } from "reactstrap";
// import PrimaryButton from '../../uiLibrary/PrimaryButton'
import SecondaryButton from "../../uiLibrary/SecondaryButton";

import "./JobCard.scss";

import CalanderSvg from "../svgComponents/CalanderSvg";
import LocationSvg from "../svgComponents/LocationSvg";
import NumberedDetails from "./NumberedDetails/NumberedDetails";
import SvgAndDescription from "./SvgAndDescription/SvgAndDescription";

import JobSetupStep03 from "../../uiLibrary/SVGs/JobSetupStepSvgs/JobSetupStep03";

const JobCard = ({
  jobTitle = "",
  jobDescription = "",
  jobLocationsArray = [],
  createdDate = "",
  jobCreatorName = "",
  totalProspects = 0,
  messagedProspects = 0,
  repliedProspects = 0,
  jobActive = true,
  jobRunning = false,
  tribeCount = 1,
  companyHiringFor = "Not specified",
  // addProspectsOnClick,
  onClickViewJob,
  ctaText = "View Prospects",
  label = "",
  isQuick = false,
}) => {
  const jobCreatorNameArray = jobCreatorName?.split(" ");
  var jobCreatorInitials = [];
  jobCreatorNameArray?.map((name) => jobCreatorInitials.push(name.slice(0, 1)));
  var jobCreatorInitialsCombined = jobCreatorInitials.join().replace(",", "");

  // jobCreatorInitials.map( initial => jobCreatorInitialsCombined.concat( " 12" , initial) )

  // console.log(jobCreatorInitialsCombined)

  return (
    <div className="jobCard">
      <div className="jobCardGeneralDetails">
        <span className="jobCardNameAndTribeCount">
          <span>
            <h1
              style={{
                marginBottom: label ? "0" : "16px",
                textTransform: "capitalize",
              }}
            >
              {jobTitle} {isQuick ? "⚡" : ""}
            </h1>
          </span>
        </span>
        {label && (
          <Badge
            style={{ marginBottom: "16px" }}
            className="pillitem"
            color="primary"
          >
            {label}
          </Badge>
        )}
      </div>
      <div className="jobCardSvgAndDescriptionContainer">
        <SvgAndDescription
          svg={<LocationSvg />}
          description={
            jobLocationsArray.length > 1
              ? [jobLocationsArray[0], "..."].join(", ")
              : jobLocationsArray.join().replace(",", ", ")
          }
          tooltipDescription={
            jobLocationsArray.length > 1 ? jobLocationsArray.join(", ") : false
          }
        />
        <SvgAndDescription
          svg={<JobSetupStep03 />}
          description={companyHiringFor}
        />
        <SvgAndDescription
          svg={<CalanderSvg stroke="#CDD1D7" />}
          description={`Created ${createdDate} by ${jobCreatorInitialsCombined} ${jobCreatorName}`}
          initials={jobCreatorInitialsCombined}
          createdDate={createdDate}
          jobCreatorName={jobCreatorName}
        />
      </div>
      <span className="numberedDetailsContainer">
        <NumberedDetails
          detailName="ALL PROSPECTS"
          detailNumber={totalProspects}
        />
        <NumberedDetails
          detailName="Messaged"
          detailNumber={messagedProspects}
        />
        <NumberedDetails detailName="Replied" detailNumber={repliedProspects} />
      </span>

      <span className="jobCardButtonContainer">
        <SecondaryButton
          onClick={onClickViewJob}
          style={{ width: "100%", display: "grid", placeContent: "center" }}
        >
          <span>{ctaText}</span>
        </SecondaryButton>
        {/* <Button
          className='secondaryButton'
          style={{ width: '100%' }}
          onClick={onClickViewJob}
        // onClick={viewJobOnClick}
        >
          View Job
          </Button> */}
      </span>
    </div>
  );
};

export default JobCard;
