import { Form, Formik, useField, useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react'
import './style.scss'

const countries = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Republic of Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain and Sweden',
  'United Kingdom',
  'California in United States',
  'Others'
]

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div
      className={"formComponentTextInput"}
    >
      <label htmlFor={props.id || props.name}>{label}</label>
      <span>
        <input className="text-input" {...field} {...props} />
        {props.getCodeButton &&
          <CodeButton
            {...props}
          />
        }
      </span>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};


const SelectInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div
      className={"formComponentSelectInput"}
    >
      <label htmlFor={props.id || props.name}>{label}</label>
      <select {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

const CodeButton = ({ verificationEmail, setVerificationEmail, ...props }) => {
  const emailField = useField({ ...props, name: 'email' });
  const firstNameField = useField({ ...props, name: 'firstName' });
  const lastNameField = useField({ ...props, name: 'lastName' });

  const invalid = emailField[1].error || firstNameField[1].error || lastNameField[1].error

  const getCode = e => {
    fetch('https://staging.api.qlu.ai/ds/dataRequest/getVerificationCode', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "firstName": firstNameField[1].value,
        "lastName": lastNameField[1].value,
        "email": emailField[1].value

      })
    })
    setVerificationEmail(emailField[1].value)
  }

  return (
    <React.Fragment>
      <button
        className={"formComponentPrimaryButton"}
        onClick={getCode}
        disabled={invalid}
      >
        Get code
            </button>
      {verificationEmail && <p>
        {`Verification code is sent to ${verificationEmail}`}
      </p>}
    </React.Fragment>
  );
};


const FormComponent = ({
  handleCancel = e => false,
  handleSubmit = e => false
}) => {
  const [verificationEmail, setVerificationEmail] = useState(false)
  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        location: '',
        code: ''
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .max(15, 'Must be 15 characters or less')
          .required('Required'),
        lastName: Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
        location: Yup.string()
          .required('Required'),
        code: Yup.string()
          .max(6, 'Must be 6 characters')
          .min(6, 'Must be 6 characters')
      })}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        setSubmitting(true)
        if (!values.email || !values.code) {
          setSubmitting(false)
          return false
        }
        const { message, success } = await fetch('https://staging.api.qlu.ai/ds/dataRequest/verifyCode', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "email": values.email,
            "code": values.code

          })
        }).then(r => r.json())

        if (success && message === "Code Verified") {
          await handleSubmit(values)
        }
        else {
          setFieldValue("code", "")
          alert("Invalid Code")
        }
        setSubmitting(false)
      }}
    >
      <Form
        className={"formComponentContainer"}
      >
        <div>
          <TextInput
            label="First Name *"
            name="firstName"
            type="text"
            placeholder="Enter your first name"
          />
          <TextInput
            label="Last Name *"
            name="lastName"
            type="text"
            placeholder="Enter your last name"
          />

        </div>
        <div>
          <SelectInput
            label="Current location *"
            name="location"
            placeholder="Select your location"
          >
            {countries.map(itm => <option value={itm}>{itm}</option>)}
          </SelectInput>
        </div>
        <div>
          <TextInput
            label="Email Address *"
            name="email"
            type="text"
            placeholder="Enter your email address"
            getCodeButton={true}
            verificationEmail={verificationEmail}
            setVerificationEmail={setVerificationEmail}
          />
        </div>
        <div>
          <TextInput
            label="Verification code"
            name="code"
            type="text"
            placeholder="Enter verification code"
            disabled={!verificationEmail}
          />
        </div>
        <div>
          <button
            className={"formComponentSecondaryButton"}
            onClick={handleCancel}
          >
            Cancel
                    </button>
          <button
            className={"formComponentPrimaryButton"}
            type="submit"
          >
            Submit
                    </button>
        </div>
      </Form>
    </Formik>
  )
}

export default FormComponent