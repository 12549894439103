import React, {useEffect} from "react";
import Switch from "../../Switch";
import "./TribesPermission.scss";
import { connect } from 'react-redux'

function TribesPermission({ item, isChecked, userPermissions, permission, toggleCheck, tribe, tribeMembers, dispatch }) {
  useEffect(() => {

  }, [userPermissions])
  
  return (
    <div className="main">
      <div>{item}</div>

      <Switch checked={userPermissions.includes(permission.name)} onChange={() => toggleCheck(permission, tribe, isChecked)} />
    </div>
  );
}

export default connect(state => {
  return {
      tribeMembers: state.prospectsView.tribeMembers.tribeMembers.tribeMembers,
      permissions: state.prospectsView.tribeMembers.tribeMembers.permissions
  }
})(TribesPermission);
