import React, { useEffect, useState, useRef } from "react";
import "./CommentsSection.scss";
import ProfileDisplayImage from "../ProfileDisplayImage/ProfileDisplayImage";
import { Button, Input } from "reactstrap";
import Send from "../svgComponents/Send";
import CrossSvg from "../svgComponents/CrossSvg";
import SingleComment from "./SingleComment";
import { connect } from "react-redux";
import {
  getComments,
  addComment,
  appendComment,
  deleteComment,
  deleteCommentSocket,
  showEmojiPickerOnComment,
  saveEmoji,
  appendEmoji,
  deleteEmoji,
} from "../../actions/prospectsView";
import Loader from "react-loader-spinner";
import { MentionsInput, Mention } from "react-mentions";
import { io } from "socket.io-client";

const CommentsSection = ({
  children,
  shouldShow = false,
  clickedProspectsDetails,
  isLoading,
  isLoaded,
  job,
  showEmojiPickerCommentID,
  tribeMembers,
  comments = [
    {
      senderImage: "RZ",
      senderName: "Robert Zack",
      time: "7 hrs",
      senderColor: "#63B780",
      senderBackgroundColor: "#29382E",
      messageText: `Do you think we can consider someone like this in our team?\nI believe he’s a bit over-experienced.`,
      emojies: [
        {
          type: "👀",
          count: 5,
        },
      ],
      hasReply: false,
      replyUser: "",
      replyText: ``,
      replyUserColor: "",
      isUser: false,
    },
    {
      senderImage: "ME",
      senderName: "You",
      time: "1 hrs",
      senderColor: "#9CA8E1",
      senderBackgroundColor: "#9CA8E133",
      messageText: `I think we should put him to the next round already.`,
      emojies: [],
      hasReply: false,
      replyUser: "",
      replyText: ``,
      replyUserColor: "",
      isUser: true,
    },
    {
      senderImage: "AR",
      senderName: "Alex Rogers",
      time: "30 mins",
      senderColor: "#63B780",
      senderBackgroundColor: "#29382E",
      messageText: `Yes please <strong>Joe</strong>`,
      emojies: [],
      hasReply: true,
      replyUser: "Robert Zack",
      replyText: `I think we should put him to the next round already.`,
      replyUserColor: "#63B780",
      isUser: false,
    },
  ],
  socket,
  onClickComments = () => false,
  pageNo,
  dispatch,
}) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    const scrollPosition = document.querySelector("#scrollPosition");
    if (scrollPosition) {
      scrollPosition.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  const socketConnections = () => {
    socket?.on("connect", () => {
      console.log("CONNECTED TO SOCKET COMMENTS...");
    });

    socket?.on("broadcast", (data) => {
      // console.log('BROAD CASTED DATA: ', data)
    });

    socket?.on("delete_comment", (data) => {
      dispatch(deleteCommentSocket(data));

      setTimeout(() => scrollToBottom(), 1000);
    });

    socket?.on("new_comment", (resp) => {
      if (resp.data.jobProfileId === clickedProspectsDetails?.jobProfileId) {
        console.log("DISPATCH APPEND COMMENT");
        dispatch(appendComment({ comment: resp.data.comment }));
        setTimeout(() => scrollToBottom(), 1000);
      }
    });

    socket?.on("new_emoji", (resp) => {
      if (resp.data.jobProfileId === clickedProspectsDetails?.jobProfileId) {
        console.log("DISPATCH APPEND EMOJI");
        dispatch(appendEmoji({ emoji: resp.data.emoji }));
      }
    });

    socket?.on("delete_emoji", (resp) => {
      if (resp?.jobProfileId === clickedProspectsDetails?.jobProfileId) {
        console.log("DISPATCH DELETE EMOJI");
        dispatch(deleteEmoji({ emoji: resp, type: "socket" }));
      }
    });

    // socket.on('test_event', function (data) {
    //   console.log('TEST EVENT: ', data)
    // })
  };

  useEffect(() => {
    console.log("HERE", clickedProspectsDetails);
    dispatch(getComments(clickedProspectsDetails?.jobProfileId, job?.tribeId));
  }, [shouldShow, clickedProspectsDetails]);

  useEffect(() => {
    if (isLoaded) {
      setTimeout(() => scrollToBottom(), 1000);
      setNewComment("");
      setNewReplyComment({});
    }
  }, [isLoading]);

  useEffect(() => {
    socketConnections();
  }, [socket, clickedProspectsDetails]);

  const [newComment, setNewComment] = useState("");
  const textCommentObject = { type: "text", elements: [] };

  const [timeoutRef, setTimeoutRef] = useState(setTimeout(() => {}));

  const handleCommentInputChange = (e) => {
    
    if(e.target.value.trim().length > 0){
      setNewComment(e.target.value);
    }
    else {
    setNewComment(e.target.value?.trim());
    }
  };

  const deleteClicked = (comment) => {
    dispatch(
      deleteComment({
        jobProfileId: clickedProspectsDetails?.jobProfileId,
        id: comment.id,
        parentId: comment.parentId,
        jobId: job.id,
      })
    );
  };

  const showEmojiPickerOnCommentDispatch = (id) => {
    dispatch(showEmojiPickerOnComment(id));
  };

  const handleMessageSend = () => {
    let textObj = newComment?.split(/(?=@\[)(.*?)(?<=\))| /g);
    
   
    textObj = textObj.filter((txt) => txt != "" && typeof txt != "undefined");

    textObj?.map((txt) => {
      if (txt.match(/(?=@\[)(.*?)(?<=\))/g)) {
        let txtList = txt.match(/\[.*\]|\(.*\)/g);
       
        textCommentObject.elements.push({
          type: "user",
          value: txtList[1].replace(/\(|\)/g, ""),
          display: txtList[0].replace(/\[|\]/g, ""),
        });
      } else {
        textCommentObject.elements.push({ type: "text", value: txt });
      }
    });
    
    let parentId = null;
    if (newReplyComment.id) {
      parentId = newReplyComment.id;
    }
    if(textCommentObject.elements.length > 0){
      dispatch(
        addComment({
          jobProfileId: clickedProspectsDetails?.jobProfileId,
          type: "TEXT",
          voiceNote: null,
          note: textCommentObject,
          parentId,
          jobId: job.id,
          pageNo,
        })
      );
      clearTimeout(timeoutRef);
      setTimeoutRef(
        setTimeout(() => {
          setNewComment("");
          setNewReplyComment({});
        }, 500)
      );
    }
  };

  const [newReplyComment, setNewReplyComment] = useState({});

  const replyCommentCreate = (comment) => {
    setNewReplyComment(comment);
  };

  const handleEmojiPicked = (emoji, comment) => {
    dispatch(
      saveEmoji({
        noteId: comment.id,
        jobProfileId: clickedProspectsDetails?.jobProfileId,
        emojiText: emoji?.emoji,
        emojiAscII: emoji?.unified,
        jobId: job.id,
        pageNo,
      })
    );
  };

  const deleteEmojiHandle = (emoji, comment) => {
    dispatch(
      deleteEmoji({
        noteId: comment.id,
        jobProfileId: clickedProspectsDetails?.jobProfileId,
        emojiText: emoji?.emojiText,
        jobId: job.id,
      })
    );
  };

  const handleShiftEnterSend = (e) => {
    if (e.code == "Enter" && e?.target?.value.length > 0) {
      handleMessageSend();
    }
  };

  tribeMembers = tribeMembers.map((member) => {
    member.display = member.name ? member.name : member.email;
    member.id = member.userId;

    return member;
  });

  return (
    <div className={`prospectComments ${shouldShow ? "show" : "hide"}`}>
      {/* <div className="prospectInfo">
        <div style={{display: 'flex'}}>
          <div className="prospectImage">
            <ProfileDisplayImage
              width="42"
              height="42"
              src={clickedProspectsDetails?.imageUrl}
              className="profileImg"
            />
          </div>
          <div className="prospectTitle">
            <p>{clickedProspectsDetails?.fullName}</p>
            <p>{clickedProspectsDetails?.headline?.replace(' at ', ' @ ')}</p>
          </div>
        </div>
        <div>
        <Button onClick={() => onClickComments(false)}><CrossSvg width="14" /></Button>
        </div>
      </div> */}

      <div
        className="commentsList"
        style={{
          alignItems: comments?.length > 0 ? "flex-end" : "center",
          height: newReplyComment?.id
            ? "calc(100vh - 270px"
            : "calc(100vh - 225px)",
          transition: "height 0.2s ease-in",
        }}
      >
        {comments.map((comment, index) =>
          comment.jobProfileId === clickedProspectsDetails?.jobProfileId ? (
            <SingleComment
              comment={comment}
              key={index}
              deleteClicked={deleteClicked}
              replyCommentCreate={replyCommentCreate}
              showEmojiPickerOnCommentDispatch={
                showEmojiPickerOnCommentDispatch
              }
              showEmojiPickerCommentID={showEmojiPickerCommentID}
              handleEmojiPicked={handleEmojiPicked}
              deleteEmoji={deleteEmojiHandle}
            />
          ) : (
            ""
          )
        )}
        <div style={{ paddingTop: 20 }} className="loader">
          {isLoading && isLoaded ? (
            <Loader type="TailSpin" color="#FF8D4E" width={32} height={32} />
          ) : comments.length > 0 ? (
            ""
          ) : (
            <p>No Messages</p>
          )}
        </div>
        <div id="scrollPosition" ref={messagesEndRef} />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginLeft: "20px",
        }}
      >
        {newReplyComment?.id ? (
          <div className="replyInputBox">
            <div className="replyUser">
              {newReplyComment.senderName}{" "}
              <span className="cancel">
                <Button onClick={() => setNewReplyComment({})}>
                  <CrossSvg width="14" />
                </Button>
              </span>
            </div>
            <div
              className="replyMessage"
              dangerouslySetInnerHTML={{ __html: newReplyComment.messageText }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="inputBox">
        {/* <Input
          className="inputInput"
          type="textarea"
          style={{
            width: "320px",
          }}
          value={newComment}
          onChange={(e) => handleCommentInputChange(e)}
          placeholder="Type a message or @ others"
        /> */}

        <MentionsInput
          onKeyDown={(e) => handleShiftEnterSend(e)}
          style={{ width: "320px" }}
          className="inputInput"
          value={newComment}
          placeholder="Type a message or @ others"
          onChange={(e) => handleCommentInputChange(e)}
        >
          <Mention
            trigger="@"
            data={tribeMembers}
            displayTransform={(id, display) => `@${display}`}
            markup="@[__display__](__id__)"
            appendSpaceOnAdd={true}
          />
        </MentionsInput>

        <Button
          className="messageSend"
          disabled={newComment.length < 1}
          onClick={() => handleMessageSend()}
        >
          <Send width="20" height="20" stroke="2" color="#FF8D4E" />
        </Button>

        {/* Tribe Members
        { tribeMembers?.map((member) => <p>{member.name}</p>) } */}
      </div>
    </div>
  );
};

export default connect((state) => ({
  comments: state.prospectsView.candidates.comments,
  tribeMembers: state.prospectsView.candidates.tribeMembers,
  isLoading: state.prospectsView.candidates.commentsLoading,
  isLoaded: state.prospectsView.candidates.commentsLoaded,
  job: state.prospectsView.candidates.job,
  showEmojiPickerCommentID:
    state.prospectsView.candidates.showEmojiPickerCommentID,
  socket: state.prospectsView.candidates.collabSocket,
  pageNo: state.prospectsView.candidates.page,
}))(CommentsSection);
