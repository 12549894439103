import { flatten } from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import NewAutoSuggestion from '../../../../components/NewAutoSuggestion/NewAutoSuggestion'
import ProfileSummaryCard from '../../../../components/ProfileSummaryCard/ProfileSummaryCard'
import BigRoundButton from '../../../../uiLibrary/BigRoundButton'
import LabelButton from '../../../../uiLibrary/LabelButton'
import Loading from '../../../../uiLibrary/Loading'
import RectanglePillButton from '../../../../uiLibrary/RectanglePillButton'
import CrossSvg from '../../../../uiLibrary/SVGs/CrossSvg'
import PlusSvg from '../../../../uiLibrary/SVGs/PlusSvg'
import { callLiWrapperApi, numberWithCommas } from '../../../../utils'
import { getCompanyDetailsFromDB } from '../../../../utils/visitApiProfile'
import RightOnClickModal from '../../../JobSetup/CompanyDetails/RightOnClickModal'
import "./style.scss"
const FindMoreCompaniesCards = ({
  companyHiringFor = [],
  heading,
  companies = [],
  previousCompanies = [],
  onUpdate = e => false,
  onAdd = e => false,
  addMoreDisabled = e => false,
  hasAddMore = false,
  disabled = false
}) => {

  const [companyHoverProfile, setCompanyHoverProfile] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null)
  const [isLoadedSimilarCompanyDetails, setIsLoadedSimilarCompanyDetails] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const [addMoreText, setAddMoreText] = useState("")
  const [companySuggestions, setCompanySuggestions] = useState([])
  const [isCompanyLoading, setIsCompanyLoading] = useState(false)
  const [isLoadingSuggestion, setIsLoadingSuggestion] = useState(false)

  const handleOnMouseEnter = (value, index) => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout)
      setHoverTimeout(setTimeout(e => onHoverCompanyCallback(companies[index], index), 800))
    } else {
      setHoverTimeout(setTimeout(e => onHoverCompanyCallback(companies[index], index), 800))
    }
  };

  const handleOnMouseLeave = () => {
    clearTimeout(hoverTimeout)
    // setPillBtnHoverProfile('')
  };

  const onHoverCompanyCallback = async (company, index) => {
    try {
      setIsLoadedSimilarCompanyDetails(true)
      // console.log("================onHoverCompanyCallback==========0==========", {company,companies, companyHoverProfile, index, isLoadedSimilarCompanyDetails})
      if (company?.name === companyHoverProfile?.name && company?.urn === companyHoverProfile?.urn && company?.url === companyHoverProfile?.url && company?.universalName === companyHoverProfile?.universalName) return false
      // setCompanyHoverProfile(companies.filter(simComp=>simComp.universalName===company.universalName)?.[0] || false)
      if (!company.detailFetched) {
        setCompanyHoverProfile(company)
        // console.log("================onHoverCompanyCallback=======1=============", {company,companies, companyHoverProfile, index})
        const data = await getCompanyDetailsFromDB(company?.urn?.split(':')?.pop() ?? company?.universalName)
        // console.log("================getCompanyDetails====================", {data,companies, index})
        if (data) {
          setCompanyHoverProfile(prev => ({
            ...(data || {}),
            detailFetched: true
          }))
          onUpdate(index, {
            ...company,
            ...(data || {}),
            detailFetched: true,
            fromHover: true
          })
        }
        // dispatch(fetchSimilarCompanyDetails(value.universalName))
      }
      else {
        setCompanyHoverProfile(company)
      }
    }
    catch (err) {
      console.log("Error: ", err.message)
    }
    finally {
      setIsLoadedSimilarCompanyDetails(false)
    }
  }

  const handlerChangeCompanyName = (e) => {
    if (!isCompanyLoading) {
      setAddMoreText(e.target.value)
    }
  };

  const renderSuggestion = (suggestion) => {
    // console.log("==============renderSuggestion==============", suggestion)
    return (
      typeof suggestion === 'string' ?
        <div>{suggestion}</div> :
        suggestion.isUrl ?
          <div>{suggestion.name}</div> :
          <div>
            <ProfileSummaryCard
              key={suggestion.urn}
              name={suggestion.name}
              headline={suggestion.industry}
              imgSrc={suggestion.logo}
            />
          </div>
    )
  }

  const shouldRenderSuggestions = suggestion => (
    (typeof suggestion === "string"
      ? suggestion.trim()
      : suggestion?.name.trim()) &&
    !isLoadingSuggestion
  )


  const handleSuggestionsClearCompany = () => {
    setCompanySuggestions([])
  }

  const handlerFetchJobCompanySuggestion = async (value) => {
    setIsLoadingSuggestion(true)
    const companyReg = new RegExp('linkedin.com\/company\/', 'gi')
    const agencyReg = new RegExp('linkedin.com\/showcase\/', 'gi')
    if (companyReg.exec(value) !== null || agencyReg.exec(value) !== null) {
      const valueSplit = value.split(/\//)
      const lastValueSplit = valueSplit?.pop()?.trim()
      const secondlastValueSplit = valueSplit?.pop()?.trim()
      if (typeof lastValueSplit === 'string' && lastValueSplit !== "") {
        await fetchCompanySuggestion(lastValueSplit);
      }
      else if (typeof secondlastValueSplit === 'string' && secondlastValueSplit !== "") {
        await fetchCompanySuggestion(secondlastValueSplit);
      }
      else {
        setIsLoadingSuggestion(false)
      }
    }
    else {
      await fetchCompanySuggestion(value);
    }
  };

  const handlerGetCompanyDetail = async (company) => {
    try {
      if (previousCompanies.every(comp => comp.name !== company.name)) {
        setIsCompanyLoading(true)
        const data = await getCompanyDetailsFromDB(company?.urn?.split(':')?.pop() ?? company?.universalName)
        if (data) {
          onAdd({
            ...(data || {}),
            detailFetched: true,
            liked: true
          })
        }
      }
    }
    catch (err) {
      console.log("Error: ", err.message)
    }
    finally {
      setIsCompanyLoading(false)
    }
  }

  const fetchCompanySuggestion = async (value) => {
    try {
      const resp = await callLiWrapperApi('SearchCompanySuggestion', null, { value })

      const data = resp?.data?.data?.searchDashClustersByAll
      let companySuggestionsArray = []

      if (data?.paging?.total > 0) {
        let items = data?.elements?.map(k => k.items.map(l => l.item?.entityResult))
        items = flatten(items)
        items = items.filter(k => k)
        companySuggestionsArray = items
          .map((item) => {
            const obj = item
            const url = obj?.navigationUrl
            const nonEntityCompanyLogo = obj?.image?.attributes[0]?.detailData?.nonEntityCompanyLogo
            const vectorImage = nonEntityCompanyLogo?.vectorImage?.artifacts[0]?.fileIdentifyingUrlPathSegment
            const industry = obj?.primarySubtitle?.text.split("•").filter(k => k.trim() !== '')[0]
            return {
              active: true,
              recommeded: true,
              details: {},
              name: obj?.title?.text ? obj?.title?.text : false,
              industry: industry || "",
              logo: vectorImage || "",
              entityURN: obj?.trackingUrn || "",
              urn: nonEntityCompanyLogo?.company?.entityUrn || "",
              universalName: url.split("/").filter(k => k.trim() !== '').pop(),
            }
          })
          .filter(
            (itm) => itm.name && itm.urn && itm.entityURN && itm.universalName
          )
          .filter((itm) => !companies.some(({ name, urn, universalName }) => name.trim().toLowerCase() === itm.name.trim().toLowerCase() && urn === itm.urn))
          .filter((itm) => !previousCompanies.some(({ name, urn }) => name.trim().toLowerCase() === itm.name.trim().toLowerCase() && urn === itm.urn))
          .filter((itm) => companyHiringFor?.[0]?.name?.trim()?.toLowerCase() !== itm.name.trim().toLowerCase() && companyHiringFor?.[0]?.urn !== itm.urn)
          .slice(0, 5);
      }

      setCompanySuggestions(companySuggestionsArray)
    } catch (e) {
      console.log("ERROR: ", e);
    }
    finally {
      setIsLoadingSuggestion(false)
    }
  };

  return (
    <div
      className='findMoreCompaniesCards'
    >
      <span>
        <h1>
          {heading}
        </h1>
        <div>
          {/* {companies.filter(comp => !previousCompanies.some(comp2 => comp.name==comp2.name)).map(({ name, liked, ...rest }, index) => ( */}
          {companies.map(({ name, liked, ...rest }, index) => {
            return !previousCompanies.some(comp => name == comp.name) &&
              <RectanglePillButton
                key={index}
                highlight={true}
                // disabled={disabled && !liked}
                // style={disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}}
                selected={liked}
                onMouseEnter={e => handleOnMouseEnter({ name, liked, ...rest }, index)}
                onMouseLeave={handleOnMouseLeave}
                onClick={e => {
                  e.stopPropagation()
                  onUpdate(index, { name, liked: !liked, fromHover: false, ...rest })
                  // onHoverCompanyCallback({ name, liked: !liked, fromHover: false, ...rest }, index)
                }}
              >
                {name}
              </RectanglePillButton>
          })}
        </div>
        {hasAddMore && <div>
          {openAdd ?
            <div
            >
              {/* <PrimaryInput
                                value={addMoreText}
                                onChange={e=>setAddMoreText(e.target.value)}
                            /> */}
              <NewAutoSuggestion
                placeholder="ACME lnc."
                inputValue={addMoreText}
                suggestionsArray={companySuggestions}
                handleChangeInput={handlerChangeCompanyName}
                handleSuggestionsFetchRequested={handlerFetchJobCompanySuggestion}
                handleSuggestionSelected={handlerGetCompanyDetail}
                handleSuggestionsClearRequested={handleSuggestionsClearCompany}
                renderSuggestion={renderSuggestion}
                shouldRenderSuggestions={shouldRenderSuggestions}
              />
              {/* <BigRoundButton
                                onClick={e=>{
                                    onAdd({
                                        checked: true,
                                        selected: true,
                                        name: addMoreText
                                    })
                                    setAddMoreText("")
                                }}
                                disabled={addMoreText?.trim()==="" || addMoreDisabled(addMoreText)}
                            >
                                <TickSvg/>
                            </BigRoundButton> */}
              <BigRoundButton
                onClick={e => {
                  setOpenAdd(false)
                  setAddMoreText("")
                }}
              >
                <CrossSvg />
              </BigRoundButton>
              {(isCompanyLoading || isLoadingSuggestion) &&
                <Loading
                  width='24px'
                  height='24px'
                />}
            </div> :
            <LabelButton
              onClick={e => setOpenAdd(true)}
            >
              <PlusSvg />
              Add more
            </LabelButton>
          }
        </div>}
      </span>
      <span>
        {companyHoverProfile ?
          companyHoverProfile?.detailFetched ?
            <RightOnClickModal
              style={{
                position: 'sticky',
                top: '0'
              }}
              profileImage={companyHoverProfile?.companyLogoUrl}
              profileName={companyHoverProfile?.name}
              profileIndustry={companyHoverProfile?.industry}
              overview={companyHoverProfile?.companyDescription || ""}
              website={companyHoverProfile?.companyPageUrl}
              industry={companyHoverProfile?.industry}
              specialities={companyHoverProfile?.specialities.join(", ")}
              // companySize={500}
              company_size={(companyHoverProfile?.staffCount || 0) > 10001 ?
                '10,000+' : numberWithCommas(companyHoverProfile?.staffCount || 0)
              }
              headquarters={`${companyHoverProfile?.headquarter?.city ?
                `${companyHoverProfile?.headquarter?.city}` : ''}${companyHoverProfile?.headquarter?.city && companyHoverProfile?.headquarter?.country ?
                  ', ' : ""}${companyHoverProfile?.headquarter?.country ?
                    `${companyHoverProfile?.headquarter?.country}` : ''}`
              }
              type={companyHoverProfile?.companyType?.localizedName}
            /> :
            <div
              className="companyHoverLabel"
            >
              <ProfileSummaryCard
                width='64px'
                height='64px'
                imgSrc={companyHoverProfile?.companyLogoUrl}
                name={companyHoverProfile?.name}
                headline={companyHoverProfile?.industry}
              />
              {isLoadedSimilarCompanyDetails &&
                <Loading />
              }
            </div> :
          <div
            className='companyHoverLabel'
            style={{
              maxWidth: '172px'
            }}
          >
            <h3>
              Hover on a company to view details
            </h3>
          </div>

        }
      </span>
    </div>
  )
}

export default connect(state => ({
  companyHiringFor: state.prospectsView?.candidates?.job?.jobArray?.companyHiringFor
}))(FindMoreCompaniesCards)