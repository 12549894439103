import React from 'react'
import '../style.scss'
const ConnectSvg = ({
    selected=false,
    width="24", 
    height="24",
    strokeWidth="2"
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}
                d="M18 21V19C18 17.9391 17.5786 16.9217 16.8284 16.1716C16.0783 15.4214 15.0609 15 14 15H6C4.93913 15 3.92172 15.4214 3.17157 16.1716C2.42143 16.9217 2 17.9391 2 19V21" 
                strokeWidth={strokeWidth} 
                stroke-linecap="round" 
                stroke-linejoin="round"/>
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}
                d="M10 11C12.2091 11 14 9.20914 14 7C14 4.79086 12.2091 3 10 3C7.79086 3 6 4.79086 6 7C6 9.20914 7.79086 11 10 11Z" 
                strokeWidth={strokeWidth} 
                strokeLinecap="round" 
                strokeLinejoin="round"/>
            <path 
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}
                d="M19.5 8V13"
                strokeWidth={strokeWidth} 
                strokeLinecap="round" 
                strokeLinejoin="round"/>
            <path   
                className={`qluSvgStroke${selected ? 'Selected' : ''}`}
                d="M17 10.5H22"
                strokeWidth={strokeWidth} 
                strokeLinecap="round" 
                strokeLinejoin="round"/>
        </svg>

    )
}

export default ConnectSvg
