import React, { useRef, useState } from 'react'
import BigRoundButton from '../../../../uiLibrary/BigRoundButton'
import DiscreteRangeSlider from '../../../../uiLibrary/DiscreteRangeSlider'
import LabelButton from '../../../../uiLibrary/LabelButton'
import PrimaryInput from '../../../../uiLibrary/PrimaryInput'
import RectanglePillButton from '../../../../uiLibrary/RectanglePillButton'
import CrossSvg from '../../../../uiLibrary/SVGs/CrossSvg'
import PlusSvg from '../../../../uiLibrary/SVGs/PlusSvg'
import TickSvg from '../../../../uiLibrary/SVGs/TickSvg'
import { kiloConversion } from '../../../../utils'
import "./style.scss"
const FindMoreSizePillCards = ({
  heading,
  firstSectionHeading = "Title",
  secondSectionHeading = "company size",
  duplicateErrorMsg = "Specialty is already added!",
  pills = [],
  newPills = [],
  onChange = e => false,
  onAdd = e => false,
  onRemove = e => false,
  addMoreDisabled = e => false
}) => {
  const [allowAddNew, setAllowAddNew] = useState(true)

  const companySizeOptions = [0, 1, 10, 50, 200, 500, 1000, 5000, 10000, 10001];
  const spanRef = useRef();
  const inputRef = useRef();
  const [addMoreText, setAddMoreText] = useState("")
  const onChangeHandler = (e) => {
    setAddMoreText(e.target.value)
    spanRef.current.innerHTML = ""
  }
  return (
    <div
      className='findMoreSizePillCards'
    >
      <h1>
        {heading}
      </h1>
      <div>
        <span>
          <h3>
            {firstSectionHeading}
          </h3>
          {pills.map(({ name, selected }, index) => (
            <div
              key={index}
            >
              <RectanglePillButton
                highlight={true}
                selected={selected}
                onClick={e => {
                  e.stopPropagation()
                  onChange('profileTitles', index, { selected: !selected })
                }}
              >
                {name}
              </RectanglePillButton>
            </div>
          ))}
        </span>
        <span>
          <h3>
            {secondSectionHeading}
          </h3>
          {pills.map(({ name, selected, company_size }, index) => (
            <div
              key={index}
            >
              <DiscreteRangeSlider
                disabled={!selected}
                value={{ min: company_size?.[0]?.min, max: company_size?.[0]?.max }}
                discreteSteps={companySizeOptions}
                onChange={e => onChange('profileTitles', index, { company_size: [e] })}
                resultLabel={`${kiloConversion(company_size?.[0]?.min)} to ${company_size?.[0]?.max === 10001 ? '10K+' : kiloConversion(company_size?.[0]?.max)}`}
                getLabel={value => value === 10001 ? '10K+' : kiloConversion(value)}
              />
              <BigRoundButton
                onClick={e => onRemove('profileTitles', index, {})}
              >
                <CrossSvg />
              </BigRoundButton>
            </div>
          ))}
        </span>
      </div>
      <div>
        <span>
          {newPills.map(({ name, selected, checked }, index) => (
            checked ?
              <div
                key={index}
              >
                <RectanglePillButton
                  highlight={true}
                  selected={selected}
                  onClick={e => {
                    e.stopPropagation()
                    onChange('newTitles', index, { selected: !selected })
                  }}
                >
                  {name}
                </RectanglePillButton>
              </div> :
              <div
                key={index}
              >
                <PrimaryInput
                  id='inputId'
                  ref={inputRef}
                  value={addMoreText}
                  onChange={e => onChangeHandler(e)}
                />
                <BigRoundButton
                  onClick={e => {
                    if (addMoreDisabled(addMoreText)) {
                      spanRef.current.innerHTML = duplicateErrorMsg
                    }
                    else {
                      onChange('newTitles', index, { checked: true, selected: true, name: addMoreText })
                    }
                    setAddMoreText("")
                    setAllowAddNew(true)
                    console.log("addMoreDisabled", addMoreDisabled(name))
                  }
                  }
                  disabled={addMoreText?.trim() === ""}
                >
                  <TickSvg />
                </BigRoundButton>
                <div ref={spanRef} className='divError'></div>
              </div>
          ))}
        </span>
        <span>
          {newPills.map(({ name, selected, company_size }, index) => (
            <div
              key={index}
              style={{
                opacity: selected ? 1 : 0.5,
                transition: 'opacity 100ms linear'
              }}
            >
              <DiscreteRangeSlider
                disabled={!selected}
                value={{ min: company_size?.[0]?.min, max: company_size?.[0]?.max }}
                discreteSteps={companySizeOptions}
                onChange={e => onChange('newTitles', index, { company_size: [e] })}
                resultLabel={`${kiloConversion(company_size?.[0]?.min)} to ${company_size?.[0]?.max === 10001 ? '10K+' : kiloConversion(company_size?.[0]?.max)}`}
                getLabel={value => value === 10001 ? '10K+' : kiloConversion(value)}
              />
              <BigRoundButton
                onClick={e => {
                  setAllowAddNew(true)
                  setAddMoreText("")
                  onRemove('newTitles', index, {})
                }}
              >
                <CrossSvg />
              </BigRoundButton>
            </div>
          ))}
        </span>
      </div>
      <div>
        <LabelButton
          style={{ paddingLeft: 0 }}
          disabled={!allowAddNew}
          onClick={e => {
            setAllowAddNew(false)
            onAdd({
              name: "",
              selected: false,
              checked: false
            })
          }}
        >
          <PlusSvg /> Add more
        </LabelButton>
      </div>
    </div>
  )
}

export default FindMoreSizePillCards