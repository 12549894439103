import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Badge } from "reactstrap";
import ChartCard from "../../../components/ChartCard";
import Loading from "../../../components/Loading";
import SectionHeader from "../../../components/SectionHeader";
import PrimaryButton from "../../../uiLibrary/PrimaryButton";
import SecondaryButton from "../../../uiLibrary/SecondaryButton";
import BlankSlate from "./BlankSlate";
import "./home.scss";
import Stats from "./Stats";

const RecentCampaigns = ({ isLoading, isDataAvailable, data, history }) => {
  const stats = useSelector((state) => state.landingPage.home.stats);
  
  return (
    <div>
      {!isLoading && isDataAvailable && (
        <>
          <SectionHeader
            label="Recent Campaigns"
            onClickViewAll={() => {
              history.push("/campaigns");
            }}
            actions={[{
              text: "New Campaign",
              onClick: () => {
                history.push("/jobs?c=true");
              }
            }]}
            showAction={false}
            showViewAll={!isLoading && isDataAvailable}
          />

          {isLoading && (
            <div
              className="dflex-cc"
              style={{ width: "100%", height: "200px" }}
            >
              <Loading />
            </div>
          )}

          <div style={{ marginTop: "16px" }} className="charts-container">
            <Stats {...stats} />
          </div>

          {!isLoading && isDataAvailable && (
            <table className="campaignTable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Job</th>
                  <th>Recepients</th>
                  <th>Rounds</th>
                </tr>
              </thead>
              <tbody>
                {data.campaigns.map((item, i) => (
                  <tr key={item.id || i}>
                    <td>{item.name}</td>
                    <td>{item.jobTitle}</td>
                    <td>{item.recepients}</td>
                    <td>{item.rounds}</td>
                    {/* <td>{item.updates.map(item => <Badge style={{ marginRight: '12px' }} className='pillitem' key={item.id}>{item.name}</Badge>)}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {!isLoading && !isDataAvailable && (
            <BlankSlate
              label="No campaign created yet"
              ctaText="New Campaign"
              onClickCta={() => {
                history.push("/jobs?c=true");
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(RecentCampaigns);
