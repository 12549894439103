import React from 'react'

const EyeSvg = ({
    className='',
    width='24', 
    height='24',
    selected=false,
    style={}
}) => {
    return (
    <svg className={`qluSvgFill${selected ? 'Selected' : ''}`} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#A7ABB0" stroke-width="2" fill='none' stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill='none' stroke="#A7ABB0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}

export default EyeSvg
