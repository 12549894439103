import React from 'react'
import './FormikInput.scss'

const FormikInput = ({ field, form, ...props }) => {
  return <input
    className={form.touched[field.name] && form.errors[field.name] ?
      'formik_input_custom input_error' :
      'formik_input_custom'}
    {...field}
    {...props}
  />
}

export default FormikInput