import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../actions/auth";
import { manualApiCall } from "../utils";

function AuthWrapper({ children }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserBlocked = async () => {
    const email = JSON.parse(localStorage.getItem("user"))?.email;
    const response = await manualApiCall(
      `/api/v2/isUserBlocked`,
      {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
      }
    );
  
    if (response?.data?.isBlockedQlu || response?.isAuthorized == false ) {
      localStorage.removeItem("user");
      dispatch(logout());
      history.push("/auth/user-blocked-view");
    }
  };
  useEffect(() => {
    isUserBlocked();
  }, []);

  return <>{children}</>;
}

export default AuthWrapper;
