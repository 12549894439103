import React from "react";

function Horizon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="506"
      height="507"
      fill="none"
      viewBox="0 0 506 507"
    >
      <g filter="url(#filter0_d_4395_85125)">
        <circle
          cx="253"
          cy="265.808"
          r="184"
          fill="url(#paint0_linear_4395_85125)"
        ></circle>
      </g>
      <g filter="url(#filter1_f_4395_85125)">
        <path
          fill="#FF8D4E"
          fillOpacity="0.6"
          fillRule="evenodd"
          d="M436.728 255.739h.019c.168-3.225.253-6.472.253-9.739 0-101.62-82.38-184-184-184S69 144.38 69 246c0 3.267.085 6.514.253 9.739h.019C74.509 158.81 154.764 81.826 253 81.826S431.491 158.81 436.728 255.74z"
          clipRule="evenodd"
        ></path>
      </g>
      <circle
        cx="253"
        cy="265.808"
        r="184"
        fill="url(#paint1_linear_4395_85125)"
        fillOpacity="0.5"
      ></circle>
      <defs>
        <filter
          id="filter0_d_4395_85125"
          width="506"
          height="506"
          x="0"
          y="0.808"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feMorphology
            in="SourceAlpha"
            radius="5"
            result="effect1_dropShadow_4395_85125"
          ></feMorphology>
          <feOffset dy="-12"></feOffset>
          <feGaussianBlur stdDeviation="37"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.552941 0 0 0 0 0.305882 0 0 0 0.23 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4395_85125"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_4395_85125"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_f_4395_85125"
          width="444"
          height="269.739"
          x="31"
          y="24"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_4395_85125"
            stdDeviation="19"
          ></feGaussianBlur>
        </filter>
        <linearGradient
          id="paint0_linear_4395_85125"
          x1="253"
          x2="253"
          y1="81.808"
          y2="232.154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212121"></stop>
          <stop offset="1" stopColor="#121212"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_4395_85125"
          x1="253"
          x2="253"
          y1="81.808"
          y2="232.154"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#212121"></stop>
          <stop offset="1" stopColor="#121212"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Horizon;
