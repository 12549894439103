import { flattenDeep, get, isEmpty, uniq, uniqBy } from 'lodash';
import qs from "qs";
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { v4 } from 'uuid';
import { fetchAllProspects, fetchProspects } from '../../actions/prospectsView';
import { PER_PAGE_COUNT } from '../../constants/api';
import FooterActionBar from '../../uiLibrary/FooterActionBar';
import Loading from '../../uiLibrary/Loading';
import TabButton from '../../uiLibrary/TabButton';
import { manualApiCall } from '../../utils';
import { callCompanyRecommenderApi, getCompanyDetails, getTitleCount } from '../../utils/visitApiProfile';
import FindMoreCompanies from './FindMoreCompanies';
import FindMoreProspectsCount from './FindMoreProspectsCount';
import FindMoreSkills from './FindMoreSkills';
import FindMoreSpecialties from './FindMoreSpecialties';
import FindMoreTitles from './FindMoreTitles';
import './style.scss';

const FindMore = ({
  companyHiringFor = [],
  meta,
  location: {
    search
  },
  history: {
    push
  },
  showMoreProspect,
  setShowMoreProspect
}) => {

  console.log("META IN FIND MORE: ", meta)

  const initialSpecialty = {
    selected: false,
    synonym: [],
    experienceSelected: false,
    experience: {
      min: 2,
      max: 5,
    },
    min: 0,
    max: 0,
    source: [],
    sourceId: [],
    synonym: [],
    name: ""
  }

  const initialTitle = {
    initial: true,
    selected: true,
    company_size: [],
    experience: {
      min: 3,
      max: 5
    },
    synonym: [],
    name: "",
    source: [],
    sourceId: [],
    experienceSelected: false,
    results: 0,
    type: [],
    rank: 3,
  }

  const initialSkill = {
    selected: true,
    importance: "5",
    experience: {
      min: 2,
      max: 5
    },
    min: 0,
    max: 0,
    source: [],
    sourceId: [],
    name: "",
    synonym: [],
    experienceSelected: false,
  }

  const initialCompany = {
    name: "",
    specialities: [],
    companyDescription: "",
    companyPageUrl: "",
    companyLogoUrl: "",
    staffCount: 0,
    url: "",
    universalName: undefined,
    urn: undefined,
    industry: "",
    selected: false,
    source: [],
    sourceId: []
  }
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [nerPayload, setNerPayload] = useState({})
  const [profiles, setProfiles] = useState([])
  const [batch, setBatch] = useState(25)
  const [jobSetup, setJobSetup] = useState({})
  const [jobTitles, setJobTitles] = useState([])
  const [profileTitles, setProfileTitles] = useState([])
  const [newTitles, setNewTitles] = useState([])
  const [jobSpecialties, setJobSpecialties] = useState([])
  const [profileSpecialties, setProfileSpecialties] = useState([])
  const [newSpecialties, setNewSpecialties] = useState([])
  const [jobSkills, setJobSkills] = useState([])
  const [profileSkills, setProfileSkills] = useState([])
  const [newSkills, setNewSkills] = useState([])
  const [jobCompanies, setJobCompanies] = useState([])
  const [profileCompanies, setProfileCompanies] = useState([])
  const [newCompanies, setNewCompanies] = useState([])
  const [unselectedSpecialties, setUnselectedSpecialties] = useState(get(meta, ['unselectedSpecialties'], []))
  const [unselectedSkills, setUnselectedSkills] = useState(get(meta, ['unselectedSkills'], []))
  const [unselectedTitles, setUnselectedTitles] = useState(get(meta, ['unselectedTitles'], []))
  const [unselectedCompanies, setUnselectedCompanies] = useState(get(meta, ['unselectedCompanies'], []))


  const [newlySelected, setNewlySelected] = useState({ skills: [], specialties: [] })

  const [lastLikedProfileCompany, setLastLikedProfileCompany] = useState({ name: null })
  const [similarCompaniesProfile, setSimilarCompaniesProfile] = useState([])

  useEffect(() => {
    setJobSpecialties(jobSpecialties.map(
      item => unselectedSpecialties.includes(item.name.toLowerCase().trim()) ? { ...item, selected: false } : item
    ))
    setProfileSpecialties(profileSpecialties.map(
      item => unselectedSpecialties.includes(item.name.toLowerCase().trim()) ? { ...item, selected: false } : item
    ))
    setNewSpecialties(newSpecialties.map(
      item => unselectedSpecialties.includes(item.name.toLowerCase().trim()) ? { ...item, selected: false } : item
    ))
  }, [unselectedSpecialties])


  useEffect(() => {
    setJobSkills(jobSkills.map(
      item => unselectedSkills.includes(item.name.toLowerCase().trim()) ? { ...item, selected: false } : item
    ))
    setProfileSkills(profileSkills.map(
      item => unselectedSkills.includes(item.name.toLowerCase().trim()) ? { ...item, selected: false } : item
    ))
    setNewSkills(newSkills.map(
      item => unselectedSkills.includes(item.name.toLowerCase().trim()) ? { ...item, selected: false } : item
    ))
  }, [unselectedSkills])

  useEffect(() => {
    if (unselectedTitles.length > 0) {
      setJobTitles(jobTitles.map(item => unselectedTitles.includes(item.name.toLowerCase().trim()) ? { ...item, selected: false } : item))
    }
  }, [unselectedTitles])

  const onFetchSimilarCompaniesProfile = (companies) => {
    const newCompanies = companies.filter(item => profileCompanies.map(pc => pc.name.toLowerCase().trim()).includes(item.name.toLowerCase().trim()))
    setProfileCompanies([...profileCompanies, ...newCompanies])
  }
  // check includes


  const onChange = (type = 'jobTitles', index = 0, payload = {}, updateType = "change") => {
    console.log("ON CHANGE: ", { type, index, payload, updateType })

    if (['change', 'add'].includes(updateType) && ['profileSpecialties', 'newSpecialties', 'profileSkills', 'newSkills'].includes(type)) {
      const hashMap = {
        'jobSpecialties': jobSpecialties,
        'profileSpecialties': profileSpecialties,
        'newSpecialties': newSpecialties,
        'jobSkills': jobSkills,
        'profileSkills': profileSkills,
        'newSkills': newSkills
      }
      // console.log("CHECK PAYLOAD: ", { payload, specialty: hashMap[type][index] })

      const valueMap = {
        profileSpecialties: 'specialties',
        newSpecialties: 'specialties',
        profileSkills: 'skills',
        newSkills: 'skills'
      }
      if (payload.selected) {
        console.log("ye wali", payload.selected)
        setNewlySelected({
          ...newlySelected, [valueMap[type]]: [
            ...newlySelected[valueMap[type]], updateType === 'change' ? hashMap[type][index].name : payload.name]
        })
      } else if (!payload.selected) {
        console.log("ye wali unselected", payload.selected)
        setNewlySelected({
          ...newlySelected,
          [valueMap[type]]: newlySelected[valueMap[type]].filter(item => item !== hashMap[type][index].name)  //[...newlySelected.specialties, hashMap[type][index].name ] 
        })
      }
    }
    const updateTypeMap = {
      "change": prev => prev.map((item, i) => {
        return i === index ? { ...item, ...payload } : item
      }),
      "add": prev => {
        return [...prev, payload]
      },
      "remove": prev => {
        return [...prev.filter((itm, i) => i !== index)]
      }
    }
    const updateFunction = updateTypeMap[updateType]
    console.log("=================onChange==========================", {
      type, index, payload, updateType
    })

    if (['jobSpecialties', 'profileSpecialties', 'newSpecialties'].includes(type) && "selected" in payload) {
      const hashMap = {
        'jobSpecialties': jobSpecialties,
        'profileSpecialties': profileSpecialties,
        'newSpecialties': newSpecialties
      }

      if (payload?.selected == true) {
        setUnselectedSpecialties(unselectedSpecialties.filter(item => item != hashMap[type][index]?.name?.trim()?.toLowerCase().trim()))
      } else if (payload?.selected == false) {
        setUnselectedSpecialties(uniq([...unselectedSpecialties, hashMap[type][index]?.name?.trim()?.toLowerCase().trim()]))
      }

    }

    if (['jobSkills', 'profileSkills', 'newSkills'].includes(type) && "selected" in payload) {
      const hashMap = {
        'jobSkills': jobSkills,
        'profileSkills': profileSkills,
        'newSkills': newSkills
      }
      if (payload?.selected == true) {
        setUnselectedSkills(unselectedSkills.filter(item => item != hashMap[type][index]?.name?.trim()?.toLowerCase().trim()))
      } else if (payload?.selected == false) {
        setUnselectedSkills(uniq([...unselectedSkills, hashMap[type][index]?.name?.trim()?.toLowerCase().trim()]))
      }
    }

    if (['jobTitles', 'profileTitles', 'newTitles'].includes(type) && "selected" in payload) {
      const hashMap = {
        'jobTitles': jobTitles,
        'profileTitles': profileTitles,
        'newTitles': newTitles
      }

      if (payload?.selected == true) {
        setUnselectedTitles(unselectedTitles.filter(item => item != hashMap[type][index]?.name?.trim()?.toLowerCase().trim()))
      } else if (payload?.selected == false) {
        if (type != 'newTitles' && index != 0 && payload.name != '') {
          setUnselectedTitles(uniq([...unselectedTitles, hashMap[type][index]?.name?.trim()?.toLowerCase().trim()]))
        }
        // setUnselectedTitles(uniq([...unselectedTitles, hashMap[type][index]?.name?.trim()?.toLowerCase().trim()]))
      }
    }


    if (['jobCompanies', 'profileCompanies', 'newCompanies'].includes(type) && (("selected" in payload) || ("liked" in payload))) {
      const hashMap = {
        'jobCompanies': jobCompanies,
        'profileCompanies': profileCompanies,
        'newCompanies': newCompanies
      }


      // console.log("----- payload.liked: ", payload?.liked)

      const fH = get(payload, ['fromHover'], false)

      // console.log("FROM HOVER: ", fH)

      const condition = payload?.liked == true && ((get(payload, ['fromHover'], false) && index > 0) == false)

      console.log("CONDITION IS: ", {
        cond1: payload?.liked == true,
        cond2: ((get(payload, ['fromHover'], false) && index > 0) == false),
        result: condition,
        payload: payload
      })

      if (
        condition
        // (payload?.liked == true) && (get(payload, ['fromHover'], false) == false)
      ) {
        setUnselectedCompanies(unselectedCompanies.filter(item => item != hashMap[type][index]?.name?.trim()?.toLowerCase().trim()))
        if (type === 'profileCompanies') {
          // console.log("SETTING LAST LIKED PROFILE COMPANY TO: ", payload)
          setLastLikedProfileCompany(payload)
        }
      } else if (payload?.liked == false) {
        setUnselectedCompanies(uniq([...unselectedCompanies, hashMap[type][index]?.name?.trim()?.toLowerCase().trim()]))
      }

    }


    switch (type) {
      case 'jobTitles':
        setJobTitles(updateFunction)
        break;
      case 'jobSpecialties':
        setJobSpecialties(updateFunction)
        break;
      case 'jobSkills':
        setJobSkills(updateFunction)
        break;
      case 'jobCompanies':
        setJobCompanies(updateFunction)
        break;
      case 'profileTitles':
        setProfileTitles(updateFunction)
        break;
      case 'profileSpecialties':
        setProfileSpecialties(updateFunction)
        break;
      case 'profileSkills':
        setProfileSkills(updateFunction)
        break;
      case 'profileCompanies':
        setProfileCompanies(updateFunction)
        break;
      case 'newTitles':
        setNewTitles(updateFunction)
        break;
      case 'newSpecialties':
        setNewSpecialties(updateFunction)
        break;
      case 'newSkills':
        setNewSkills(updateFunction)
        break;
      case 'newCompanies':
        setNewCompanies(updateFunction)
        break;
      default:
        break;
    }
  }

  useEffect(async () => {
    console.log("LAST LIKED PROFILEC COMPANY: ", lastLikedProfileCompany)
    if (lastLikedProfileCompany?.urn || lastLikedProfileCompany?.entityUrn) {
      console.log("LAST LIKED PROFILE COMPANIES: ", { lastLikedProfileCompany, profileCompanies })

      const urn = get(lastLikedProfileCompany, ['urn'], get(lastLikedProfileCompany, ['entityUrn'], ''))

      console.log("URN IS: ", urn)

      populateLikedProfileCompanySpecialties(urn, lastLikedProfileCompany?.name)

      const similarCompanies = await callCompanyRecommenderApi(urn.split(':').pop())

      // filter out companies that are in newly companies
      // console.log('New companies', profileCompanies)
      // const filteredSimilarCompanies = similarCompanies.filter(comp => !profileCompanies.some(comp2 => comp.name==comp2.name))
      // console.log('Filtered similar companies',filteredSimilarCompanies)
      setSimilarCompaniesProfile(similarCompanies)

    }
  }, [lastLikedProfileCompany])

  const populateLikedProfileCompanySpecialties = async (urn, companyName) => {
    const liId = urn.split(':').pop()

    console.log("LINKEDIN ID IS: ", liId)
    const companyDetails = await getCompanyDetails(liId)
    console.log("COMPANY DETAILS ARE: ", companyDetails)

    const newProfileSpecialties = uniqBy([...profileSpecialties, ...get(companyDetails, ['specialities'], []).map(item => ({
      "company_name": [companyName],
      "selected": false,
      "liked": false,
      "synonym": [],
      "experienceSelected": false,
      "experience": {
        "min": 2,
        "max": 5
      },
      "min": 0,
      "max": 0,
      "source": [],
      "sourceId": [],
      "name": item

    }))], 'name')


    console.log("SETTING PROFILE SPECIALTIES TO: ", newProfileSpecialties)

    setProfileSpecialties(newProfileSpecialties)

    //   {
    //     "selected": false,
    //     "synonym": [],
    //     "experienceSelected": false,
    //     "experience": {
    //         "min": 2,
    //         "max": 5
    //     },
    //     "min": 0,
    //     "max": 0,
    //     "source": [
    //         "saved_profile"
    //     ],
    //     "sourceId": [
    //         "mattwootten",
    //         "vincent-lawrence-ii-☁️-3433901b9",
    //         "dennissinople",
    //         "john-mcmahon-"
    //     ],
    //     "name": "Enterprise Cloud Computing"
    // }

  }

  useEffect(() => {
    if (!isEmpty(similarCompaniesProfile)) {
      const newCompanies = similarCompaniesProfile.filter(it => !profileCompanies.map(item => item.name.toLowerCase().trim()).includes(it.name.toLowerCase().trim()))
        .filter((itm) => companyHiringFor?.[0]?.name?.trim()?.toLowerCase() !== itm.name.trim().toLowerCase() && companyHiringFor?.[0]?.urn !== itm.urn)
      const updated = [...profileCompanies, ...newCompanies]
      console.log('profileCompanies===', profileCompanies)
      // .filter((itm) => companyHiringFor?.[0]?.name?.trim()?.toLowerCase() !== itm.name.trim().toLowerCase() && companyHiringFor?.[0]?.urn !== itm.urn)

      setProfileCompanies(updated)
    }
  }, [similarCompaniesProfile])



  const tabs = [
    {
      name: "Prospect count",
      subHeading: "How many profiles do you want to see in the new batch?",
      disabled: true,
      view: <FindMoreProspectsCount
        batch={batch}
        setBatch={setBatch}
      />
    },
    {
      name: "Titles",
      subHeading: "Please select the titles you would want in the next batch of prospects",
      disabled: true,
      view: <FindMoreTitles
        jobTitles={jobTitles}
        profileTitles={profileTitles}
        newTitles={newTitles}
        onTitlesChange={(...rest) => onChange(...rest, 'change')}
        onAddTitle={(...rest) => onChange(...rest, 'add')}
        onRemoveTitle={(...rest) => onChange(...rest, 'remove')}
      />
    },
    {
      name: "Companies",
      subHeading: "What companies you would want to get more prospects from?",
      disabled: true,
      view: <FindMoreCompanies
        jobCompanies={jobCompanies}
        profileCompanies={profileCompanies}
        newCompanies={newCompanies}
        onFetchSimilarCompaniesProfile={onFetchSimilarCompaniesProfile}
        onCompaniesChange={(...rest) => onChange(...rest, 'change')}
        onAddCompanies={(...rest) => onChange(...rest, 'add')}
        onRemoveCompanies={(...rest) => onChange(...rest, 'remove')}
      />
    },
    {
      name: "Specialties",
      subHeading: "Please select the specialties you would want in the next batch of prospects",
      disabled: true,
      view: <FindMoreSpecialties
        jobSpecialties={jobSpecialties}
        profileSpecialties={profileSpecialties}
        newSpecialties={newSpecialties}
        onSpecialtiesChange={(...rest) => onChange(...rest, 'change')}
        onAddSpecialties={(...rest) => onChange(...rest, 'add')}
        onRemoveSpecialties={(...rest) => onChange(...rest, 'remove')}
      />
    },
    {
      name: "Skills",
      subHeading: "Please select the skills you would want in the next batch of prospects",
      disabled: true,
      view: <FindMoreSkills
        jobSkills={jobSkills}
        profileSkills={profileSkills}
        newSkills={newSkills}
        onSkillsChange={(...rest) => onChange(...rest, 'change')}
        onAddSkills={(...rest) => onChange(...rest, 'add')}
        onRemoveSkills={(...rest) => onChange(...rest, 'remove')}
      />
    }
  ]

  const [currentTab, setCurrentTab] = useState(tabs[0])
  const [fetchingSavedProfile, setFetchingSavedProfile] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    switch (currentTab.name) {
      case "Companies":
        console.log("unselected companies: ", unselectedCompanies)
        console.log("=======================Companies: ", { newCompanies, profileCompanies, jobCompanies })
        setJobCompanies(prev => prev.map(itm => ({ ...itm, selected: unselectedCompanies.includes(itm?.name?.toLowerCase()?.trim()) ? false : !!itm?.liked })))
        break;
      case "Specialties":
        console.log("INSELECTED SPECIALTIES", unselectedSpecialties)
        console.log("=======================Specialties: ", { newSpecialties, profileSpecialties, jobSpecialties })
        setJobSpecialties(prev => prev.map(itm => ({ ...itm, selected: unselectedSpecialties.includes(itm?.name?.toLowerCase()?.trim()) ? false : !!itm?.selected })))
        setProfileSpecialties(prev => prev.map(itm => ({ ...itm, selected: unselectedSpecialties.includes(itm?.name?.toLowerCase()?.trim()) ? false : !!itm?.selected })))
        setNewSpecialties(prev => prev.map(itm => ({ ...itm, selected: unselectedSpecialties.includes(itm?.name?.toLowerCase()?.trim()) ? false : !!itm?.selected })))
        break;
      case "Skills":
        console.log("UNSELECTED SKILLS: ", unselectedSkills)
        console.log("=======================SKILLS: ", { newSkills, profileSkills, jobSkills })
        setJobSkills(prev => prev.map(itm => ({ ...itm, selected: unselectedSkills.includes(itm?.name?.toLowerCase()?.trim()) ? false : !!itm?.selected })))
        setProfileSkills(prev => prev.map(itm => ({ ...itm, selected: unselectedSkills.includes(itm?.name?.toLowerCase()?.trim()) ? false : !!itm?.selected })))
        setNewSkills(prev => prev.map(itm => ({ ...itm, selected: unselectedSkills.includes(itm?.name?.toLowerCase()?.trim()) ? false : !!itm?.selected })))
        break;
      default:
        break;
    }

  }, [currentTab])


  let { jId } = useParams();
  const qParams = qs.parse(search.split("?").pop());
  const rank = jobSetup?.job_position?.[0]?.rank
  const currentIndex = tabs.reduce((i, t, id) => t.name === currentTab.name ? id : i, 0)

  const fetchingData = (fetchingSavedProfile && currentTab.name !== "Prospect count")
  const isBackDisabled = isLoading || fetchingData || currentIndex === 0
  const isCancelDisabled = isLoading || fetchingData
  const isNextDisabled = isLoading || fetchingData

  const duration = 150;

  const defaultStyle = {
    transition: `all ${duration}ms ease-out`,
    transitionProperty: 'opacity, transform',
    opacity: 0,
  }

  const transitionStyles = {
    entering: {
      opacity: 0,
      //   transform: 'translate(-100vw,0)'
    },
    entered: {
      opacity: 1,
      //   transform: 'translate(0,0)'
    },
    exiting: {
      opacity: 0,
      //   transform: 'translate(100vw,0)'
    },
    exited: {
      opacity: 0,
      //   transform: 'translate(100vw,0)'
    },
  };


  const fetchSimilarCompaniesForProfileCompanies = async (companies) => {
    try {
      console.log("fetch similar companies against: ", companies)

      const urns = companies.map(item => item.urn)
      const universalNames = companies.map(item => item.universalName)

      console.log("FETCHING")
      const similarCompanies = await Promise.all(urns.map(item => callCompanyRecommenderApi(item)))
      const flattenedAndUnique = uniqBy(flattenDeep(similarCompanies), 'universalName').filter(item => !universalNames.includes(item.universalName))



      console.log("SIMILAR COMPANIES ARE: ", flattenedAndUnique)

      setProfileCompanies([...companies, ...flattenedAndUnique])



    } catch (e) {
      console.log("An error occured: ", e.message)
    }
  }



  const componentDidMount = async () => {
    fetch('https://speciality-similarity-api-bdhweedyzq-uc.a.run.app/similar_specialities', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({})
    })
    fetch('https://company-analysis-warmup-bdhweedyzq-uc.a.run.app/', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({})
    })
    try {
      setFetchingSavedProfile(true)
      const qStr = `?jId=${jId}&fF=as&p=0&b=all&pPP=10000&f=a`;
      const { candidates = [], job = {} } = await manualApiCall(`/api/v2/auth/candidates${qStr}`, {
        method: "POST",
        body: JSON.stringify({
          jobId: jId,
          params: {
            jId,
            fF: 'as',
            p: 0,
            b: 'all',
            pPP: 10000,
            f: 'a'
          },
        })
      }) || {};

      let jobTitles = []
      let jobCompanies = []
      // let newCompanies = []
      let jobSpecialties = []
      let jobSkills = []
      console.log("==============jobArray==================", { candidates, jobArray: { ...job?.jobArray } })
      if (Object.keys(job?.jobArray || {}).length > 0) {
        setJobSetup(job?.jobArray)
        setNerPayload((prev = {}) => ({ ...prev, jobArray: job?.jobArray }))
        if (job?.jobArray?.job_position?.[0]) {
          jobTitles.push({ ...job?.jobArray?.job_position?.[0], selected: true })
        }
        const ideal_titles = job?.benchmarkProspects?.ideal.map(t => ({
          "name": t?.current_title,
          selected: true
        })).filter(t => t.name) || []
        // const unselected = profileTitles
        jobTitles = [...jobTitles, ...job?.jobArray?.suggested_titles?.map(itm => ({ ...initialTitle, ...itm, selected: !unselectedTitles.includes(itm.name.toLowerCase().trim()) })),
        ...ideal_titles]

        jobCompanies = [
          // ...job?.jobArray?.companyHiringFor?.map(itm =>
          //   ({ ...itm, detailFetched: true, liked: !unselectedCompanies.includes(itm.name.toLowerCase().trim()) })),
          ...job?.jobArray?.likedCompanies?.map(itm => ({ ...itm, liked: !unselectedCompanies.includes(itm.name.toLowerCase().trim()) }))
        ]
        jobSpecialties = job?.jobArray?.company_speciality?.map(itm => ({ ...initialSpecialty, ...itm, selected: !unselectedSpecialties.includes(itm.name.toLowerCase().trim()) }))
        jobSkills = job.jobArray?.skills?.filter(({ importance }) => importance == 5)?.map(itm => ({ ...initialSkill, ...itm, selected: !unselectedSkills.includes(itm.name.toLowerCase().trim()) }))
        setJobTitles(jobTitles)
        setJobCompanies(jobCompanies)
        setJobSpecialties(jobSpecialties)
        setJobSkills(jobSkills)

        // setNewCompanies(newCompanies)
      }
      else {
        handleCancelClick()
      }

      // if (candidates.length > 0) {
      setProfiles(candidates)
      let titles = []
      let companies = []
      let specialties = []
      let candidatesSkills = []
      // const profilePayload = []

      candidates.map(({ experience = [], skills = [], scoring, publicIdentifier }) => {
        // const titlePayload = []
        // const companyPayload = []
        // const specialtyPayload = []
        // const skillPayload = []

        let company_size = [{
          min: 1,
          max: 10001
        }]
        let maxScore = 0

        scoring?.Bucket?.forEach(element => {
          if (element?.score > maxScore) {
            maxScore = element.score
            company_size = element?.title_comp_size || [{
              min: 1,
              max: 10001
            }]
          }
        });

        skills?.forEach(skill => {
          if (!jobSkills.some(({ name, synonym = [] }) => name?.toLowerCase()?.trim() === skill?.toLowerCase()?.trim() || synonym?.some(({ name }) => name?.toLowerCase()?.trim() === skill?.toLowerCase()?.trim()))) {
            const payload = {
              ...initialSkill,
              name: skill,
              selected: false,
              source: ["saved_profile"],
              sourceId: [publicIdentifier]
            }
            if (!candidatesSkills.map(({ name }) => name?.toLowerCase()?.trim()).includes(skill?.toLowerCase()?.trim())) {
              candidatesSkills.push(payload)
            }
            else {
              candidatesSkills = candidatesSkills.map(itm => (
                itm?.name?.toLowerCase()?.trim() === skill?.toLowerCase()?.trim() ?
                  {
                    ...itm,
                    sourceId: [...(itm?.sourceId || []), publicIdentifier]
                  } : itm))
            }
            // skillPayload.push(payload)

          }
        })

        experience?.forEach(({ title, company_name, companySpecialities = [], industries = [], logo, companyDescription, companyPageUrl, companySize, companyType, companyWebsite, universalName, urn }, index) => {
          // console.log("===============companySpecialities===================", {companySpecialities, industries})
          if (index < 1) {
            companySpecialities?.forEach(specialty => {
              if (!jobSpecialties.some(({ name, synonym = [] }) => name?.toLowerCase()?.trim() === specialty?.toLowerCase()?.trim() || synonym?.some(({ name }) => name?.toLowerCase()?.trim() === specialty?.toLowerCase()?.trim()))) {
                const payload = {
                  ...initialSpecialty,
                  name: specialty,
                  selected: false,
                  source: ["saved_profile"],
                  sourceId: [publicIdentifier]
                }
                if (!specialties.map(({ name }) => name?.toLowerCase()?.trim()).includes(specialty?.toLowerCase()?.trim())) {
                  specialties.push(payload)
                }
                else {
                  specialties = specialties.map(itm => (
                    itm?.name?.toLowerCase()?.trim() === specialty?.toLowerCase()?.trim() ?
                      {
                        ...itm,
                        sourceId: [...(itm?.sourceId || []), publicIdentifier]
                      } : itm))
                }
              }
            })

            industries?.forEach(specialty => {
              if (!jobSpecialties.some(({ name, synonym = [] }) => name?.toLowerCase()?.trim() === specialty?.toLowerCase()?.trim() || synonym?.some(({ name }) => name?.toLowerCase()?.trim() === specialty?.toLowerCase()?.trim()))) {
                const payload = {
                  ...initialSpecialty,
                  name: specialty,
                  selected: false,
                  source: ["saved_profile"],
                  sourceId: [publicIdentifier]
                }
                if (!specialties.map(({ name }) => name?.toLowerCase()?.trim()).includes(specialty?.toLowerCase()?.trim())) {
                  specialties.push(payload)
                }
                else {
                  specialties = specialties.map(itm => (
                    itm?.name?.toLowerCase()?.trim() === specialty?.toLowerCase()?.trim() ?
                      {
                        ...itm,
                        sourceId: [...(itm?.sourceId || []), publicIdentifier]
                      } : itm))
                }
                // specialtyPayload.push(payload)
              }
            })

            if (!jobCompanies.some((itm) => itm.urn === urn && itm.universalName === universalName && itm.name === company_name) && (universalName || urn)) {
              const payload = {
                name: company_name,
                specialities: companySpecialities,
                companyDescription,
                companyPageUrl: companyWebsite,
                companyLogoUrl: logo,
                staffCount: companySize,
                url: companyPageUrl,
                universalName,
                urn,
                industry: industries?.[0],
                selected: false,
                source: ["saved_profile"],
                sourceId: [publicIdentifier]
              }
              if (!companies.some((itm) => itm.urn === urn && itm.universalName === universalName && itm.name === company_name)) {
                companies.push(payload)
              }
              else {
                companies = companies.map(itm => (
                  itm.urn === urn && itm.universalName === universalName && itm.name === company_name ?
                    {
                      ...itm,
                      sourceId: [...(itm?.sourceId || []), publicIdentifier]
                    } : itm))
              }
              // companyPayload.push(payload)

            }
            if (!jobTitles.some(({ name, synonym = [] }) => name?.toLowerCase()?.trim() === title?.toLowerCase()?.trim() || synonym?.some(({ name }) => name?.toLowerCase()?.trim() === title?.toLowerCase()?.trim()))) {
              const payload = {
                ...initialTitle,
                name: title,
                company_size,
                selected: true,
                source: ["saved_profile"],
                sourceId: [publicIdentifier]
              }
              if (!titles.map(({ name }) => name?.toLowerCase()?.trim()).includes(title?.toLowerCase()?.trim())) {
                titles.push(payload)
              }
              else {
                titles = titles.map(itm => (
                  itm?.name?.toLowerCase()?.trim() === title?.toLowerCase()?.trim() ?
                    {
                      ...itm,
                      sourceId: [...(itm?.sourceId || []), publicIdentifier]
                    } : itm))
              }
              // titlePayload.push(payload)
            }
          }
        })
        // profilePayload.push({
        //     id: publicIdentifier,
        //     titles: titlePayload,
        //     companies: companyPayload,
        //     specialties: specialtyPayload,
        //     skills: skillPayload
        // })
      })

      const resp = await Promise.all(titles.map(title => getTitleCount(title.name)))
      titles = titles.map((title, index) => ({
        ...title,
        count: resp[index]
      }))
      // job titles count was being added
      // const results = await Promise.all(jobTitles.map(title => getTitleCount(title.name)))
      // const updatedJobTitles = jobTitles
      //   .map((title, index) => ({
      //     ...title,
      //     count: results[index]
      //   }))
      // setJobTitles(updatedJobTitles)

      const payload = {
        jobArray: job?.jobArray,
        entity: {
          titles: {
            job: jobTitles,
            profile: titles,
            new: []
          }
        },
        unselectedCompanies: unselectedCompanies,
        unselectedSkills: unselectedSkills,
        unselectedSpecialties: unselectedSpecialties,
        unselectedTitles: unselectedTitles
      }
      // prospects call should be added here
      const params = {
        "f": "a",
        "fF": "a",
        "p": "0",
        "b": "all",
        "pPP": "50",
        "fS": "title_score",
        "fSO": "desc",
        "secS": "",
        "secSO": "",
        "pFlag": false
      }
      dispatch(fetchProspects(jId, params, qParams?.aT))
      dispatch(fetchAllProspects(jId, params, qParams?.aT))
      console.log('My Titles', payload, true)
      const res = await fetch(process.env.REACT_APP_NER_FIND_MORE_HOST_TITLE_PROCESSING, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      }).then(r => r.json())

      const allTitles = [...get(res, ['entity', 'titles', 'job'], []), ...get(res, ['entity', 'titles', 'profile'], []), ...get(res, ['entity', 'titles', 'new'], [])]

      const unselected = allTitles.filter(item => !item.selected)

      setUnselectedTitles(unselected.map(item => item.name.toLowerCase().trim()))


      if (res?.entity?.titles?.profile) {
        setProfileTitles(res?.entity?.titles?.profile)
      }
      else {
        setProfileTitles(titles)
      }
      // console.log("==================companies========================", {companies})
      // console.log("==================specialties========================", {specialties})
      // console.log("==================candidatesSkills========================", {candidatesSkills})

      const profileCompaniesTransformed = companies.map(item => ({
        ...item,
        liked: !unselectedCompanies.includes(item.name.toLowerCase().trim()),
        selected: !unselectedCompanies.includes(item.name.toLowerCase().trim())
      }))
      setProfileCompanies(profileCompaniesTransformed)

      fetchSimilarCompaniesForProfileCompanies(profileCompaniesTransformed)

      // setProfileCompanies(companies.map(item => ({
      //   ...item,
      //   liked: !unselectedCompanies.includes(item.name.toLowerCase().trim()),
      //   selected: !unselectedCompanies.includes(item.name.toLowerCase().trim())
      // })))


      setProfileSpecialties(specialties)
      setProfileSkills(candidatesSkills)
      // setNerPayload(prev=>({
      //     ...prev, 
      //     profiles: profilePayload
      // }))
      // }
      // else {
      //   handleCancelClick()
      //   setShowMoreProspect(prev => !prev)
      // }
    }
    catch (err) {
      console.log("Error: ", err.message)
    }
    finally {
      setFetchingSavedProfile(false)
    }

  }

  useEffect(() => {
    componentDidMount()
  }, [])

  const handleTabClick = tab => {
    setCurrentTab(tab)
  }

  const handleBackClick = e => {
    // console.log("=========================", {currentIndex, currentIndex, tabs})
    if (currentIndex > 0) {
      setCurrentTab(tabs[currentIndex - 1])
    }

  }

  const handleCancelClick = e => {
    const fS = 'title_score'
    const fSO = 'desc'
    const secS = ''//rank > 8 ? 'industry_score' : 'skill_score'
    const secSO = '' //'desc'
    push(`/job/${jId}?f=a&fF=a&p=0&b=all&pPP=${PER_PAGE_COUNT}&fS=${fS}&fSO=${fSO}&secS=${secS}&secSO=${secSO}`)
  }

  const handleNextClick = e => {
    switch (currentTab?.name) {
      case 'Titles':
        nextOnTitle()
        break;
      case 'Companies':
        nextOnCompany()
        break;
      case 'Specialties':
        nextOnSpecialty()
        break;
      case 'Skills':
        nextOnSkill()
        break;
      default:
        setCurrentTab(tabs[currentIndex + 1])
        break;
    }
  }

  const nextOnTitle = async () => {
    try {
      setIsLoading(true)

      setNewTitles(prev => prev?.filter(({ name, checked = false }) => checked && name?.trim() !== "" && !jobTitles?.map(({ name }) => name?.trim()?.toLowerCase()).includes(name?.trim()?.toLowerCase()) && !profileTitles?.map(({ name }) => name?.trim()?.toLowerCase()).includes(name?.trim()?.toLowerCase())))

      console.log("================nextOnTitle================", { unselectedTitles })
      const payload = {
        jobArray: jobSetup,
        entity: {
          titles: {
            job: jobTitles.filter(({ selected }) => selected),
            profile: profileTitles.filter(({ selected }) => selected),
            new: newTitles.filter(({ selected, checked }) => checked && selected)
          },
          companies: {
            job: jobCompanies,
            profile: profileCompanies,
            new: []
          }
        },
        unselectedCompanies: unselectedCompanies,
        unselectedSkills: unselectedSkills,
        unselectedSpecialties: unselectedSpecialties,
        unselectedTitles: unselectedTitles
      }
      // const res = await fetch(process.env.REACT_APP_NER_FILTER_SYNONYMS_HOST_TITLE_SYNONYMS, {
      //   method: 'POST',
      //   headers: {
      //     "Content-Type": "application/json"
      //   },
      //   body: JSON.stringify(payload)
      // }).then(r => r.json())
      // if (res.suggested) {
      //   setNewTitles(prev => prev.map((elem) => ({ ...elem, ...(res?.entity?.titles?.new?.filter?.(itm => itm?.name?.trim()?.toLowerCase() === elem?.name?.trim()?.toLowerCase())?.[0] || {}) })))
      //   setProfileTitles(prev => prev.map((elem) => ({ ...elem, ...(res?.entity?.titles?.profile?.filter?.(itm => itm?.name?.trim()?.toLowerCase() === elem?.name?.trim()?.toLowerCase())?.[0] || {}) })))
      //   setProfileCompanies(res?.entity?.companies?.profile || [])
      //   setNewCompanies((res?.suggested?.companies || [])?.slice(0, 15)?.map(itm => ({
      //     ...initialCompany,
      //     ...itm,
      //     liked: unselectedCompanies.includes(itm?.name?.toLowerCase().trim()),
      //     selected: unselectedCompanies.includes(itm?.name?.toLowerCase().trim())

      //   })))
      // }
      // else {
      //   throw new Error("Ner returned empty")
      // }
      setCurrentTab(tabs[currentIndex + 1])
      // console.log("==============nextOnTitle====================", {res})
    }
    catch (err) {
      console.log("Error: ", err.message)
    }
    finally {
      setCurrentTab(tabs[currentIndex + 1])
      setIsLoading(false)
    }
  }

  const nextOnCompany = async () => {
    try {
      setIsLoading(true)

      // setNewCompanies(prev=>prev?.filter(({urn, universalName, name, checked=false})=>checked&&name?.trim()!==""&&!jobCompanies?.some((itm)=>itm.urn===urn&&itm.universalName===universalName&&itm.name?.trim()?.toLowerCase()===name?.trim()?.toLowerCase())&&!profileCompanies?.some((itm)=>itm.urn===urn&&itm.universalName===universalName&&itm.name?.trim()?.toLowerCase()===name?.trim()?.toLowerCase())))

      console.log({ jobCompanies, profileCompanies, newCompanies })
      const payload = {
        jobArray: jobSetup,
        entity: {
          specialties: {
            job: jobSpecialties,
            profile: profileSpecialties,
            new: []
          },
          companies: {
            job: jobCompanies.filter(({ selected, liked = false }) => (selected || liked)),
            profile: profileCompanies.filter(({ selected, liked = false }) => (selected || liked)),
            new: newCompanies.filter(({ selected, liked = false }) => (selected || liked))
          }
        },
        likedProfiles: profiles.reduce((obj, item) => {
          return { ...obj, [item.publicIdentifier]: item.experience }
        }, {}),
        unselectedCompanies: unselectedCompanies,
        unselectedSkills: unselectedSkills,
        unselectedSpecialties: unselectedSpecialties,
        unselectedTitles: unselectedTitles
      }
      const res = await fetch(process.env.REACT_APP_NER_FIND_MORE_HOST_FIND_MORE_PROCESPECTS_SPECILITIES, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      }).then(r => r.json())
      if (res.suggested) {
        // setNewTitles(prev=>prev.map((elem)=>({...elem, ...(res?.entity?.titles?.new?.filter?.(itm=>itm?.name?.trim()?.toLowerCase()===elem?.name?.trim()?.toLowerCase())?.[0] || {})})))
        // setProfileSpecialties(prev => uniqBy([...get(res, ['entity', 'specialties', 'profile'], []), ...profileSpecialties], 'name') || []
        setProfileSpecialties(prev => uniqBy([...get(res, ['entity', 'specialties', 'profile'], [])], 'name') || []
          // prev.map((elem) => ({ ...elem, ...(res?.entity?.specialties?.profile?.filter?.(itm => itm?.name?.trim()?.toLowerCase() === elem?.name?.trim()?.toLowerCase())?.[0] || {}) }))
        )
        console.log('After newSpecialties', newSpecialties, res.suggested?.specialties)
        setNewSpecialties(res.suggested?.specialties)

        const allSpeciaties = [...get(res, ['entity', 'companies', 'job'], []), ...get(res, ['entity', 'companies', 'profile'], []), ...get(res, ['entity', 'companies', 'new'], [])]

        const unselected = allSpeciaties.filter(item => !item.selected)

        setUnselectedSpecialties(unselected.map(item => item.name.toLowerCase().trim()))
      }
      else {
        throw new Error("Ner returned empty")
      }
      setCurrentTab(tabs[currentIndex + 1])
    }
    catch (err) {
      console.log("Error: ", err.message)
    }
    finally {
      setCurrentTab(tabs[currentIndex + 1])
      setIsLoading(false)
    }
  }

  const nextOnSpecialty = async () => {
    try {
      setIsLoading(true)

      // setNewSpecialties(prev=>prev?.filter(({name, checked=false})=>checked&&name?.trim()!==""&&!jobSpecialties?.map(({name})=>name?.trim()?.toLowerCase()).includes(name?.trim()?.toLowerCase())&&!profileSpecialties?.map(({name})=>name?.trim()?.toLowerCase()).includes(name?.trim()?.toLowerCase())))

      console.log("UNSELECTED SPECIALTIES ARE: ", unselectedSpecialties)
      const payload = {
        jobArray: jobSetup,
        entity: {
          specialties: {
            job: jobSpecialties.filter(({ selected }) => selected),
            profile: profileSpecialties.filter(({ selected }) => selected),
            new: newSpecialties.filter(({ selected }) => selected)
          },
          skills: {
            job: jobSkills,
            profile: profileSkills,
            new: []
          }
        },
        unselectedCompanies: unselectedCompanies,
        unselectedSkills: unselectedSkills,
        unselectedSpecialties: unselectedSpecialties,
        unselectedTitles: unselectedTitles
      }

      const res = await fetch(process.env.REACT_APP_NER_FIND_MORE_HOST_FIND_MORE_PROCESPECTS_SKILLS
        , {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payload)
        }).then(r => r.json())
      if (res.suggested) {
        // setNewTitles(prev=>prev.map((elem)=>({...elem, ...(res?.entity?.titles?.new?.filter?.(itm=>itm?.name?.trim()?.toLowerCase()===elem?.name?.trim()?.toLowerCase())?.[0] || {})})))
        // setProfileTitles(prev=>prev.map((elem)=>({...elem, ...(res?.entity?.titles?.profile?.filter?.(itm=>itm?.name?.trim()?.toLowerCase()===elem?.name?.trim()?.toLowerCase())?.[0] || {})})))
        setProfileSkills(prev => res?.entity?.skills?.profile || [])
        setNewSkills(res.suggested?.skills.map(itm => ({
          ...initialSkill,
          ...itm,
          selected: false
        })))

        const allSkills = [...get(res, ['entity', 'companies', 'job'], []), ...get(res, ['entity', 'companies', 'profile'], []), ...get(res, ['entity', 'companies', 'new'], [])]

        const unselected = allSkills.filter(item => !item.selected)

        setUnselectedSkills(unselected.map(item => item.name.toLowerCase().trim()))
      }
      else {
        throw new Error("Ner returned empty")
      }
    }
    catch (err) {
      console.log("Error: ", err.message)
    }
    finally {
      setCurrentTab(tabs[currentIndex + 1])
      setIsLoading(false)
    }
  }

  const nextOnSkill = async () => {
    try {
      console.log("UNSELECTED SKILLS ARE: ", unselectedSkills)
      setIsLoading(true)
      const entity = {
        titles: {
          job: jobTitles.filter(({ selected }) => selected),
          profile: profileTitles.filter(({ selected }) => selected),
          new: newTitles.filter(({ selected }) => selected)
        },
        companies: {
          job: jobCompanies.filter(({ liked }) => liked),
          profile: profileCompanies.filter(({ liked }) => liked),
          new: newCompanies.filter(({ liked }) => liked)
        },
        specialties: {
          job: jobSpecialties.filter(({ selected }) => selected),
          profile: profileSpecialties.filter(({ selected }) => selected),
          new: newSpecialties.filter(({ selected }) => selected)
        },
        skills: {
          job: jobSkills.filter(({ selected }) => selected),
          profile: profileSkills.filter(({ selected }) => selected),
          new: newSkills.filter(({ selected }) => selected)
        }
      }

      const payload = {
        jobArray: {
          ...jobSetup,
          suggested_titles: [
            ...(jobSetup?.suggested_titles || []),
            ...entity.titles.profile,
            ...entity.titles.new,
          ].map(item => item.id ? item : { ...item, id: v4() }),
          likedCompanies: [
            ...(jobSetup?.likedCompanies || []),
            ...entity.companies.profile,
            ...entity.companies.new,
          ],
          company_speciality: [
            ...(jobSetup?.company_speciality || []),
            ...entity.specialties.profile,
            ...entity.specialties.new,
          ],
          skills: [
            ...(jobSetup?.skills || []),
            ...entity.skills.profile,
            ...entity.skills.new,
          ].map(itm => {
            if ('initialSkill' in itm) {
              delete itm["initialSkill"]
              return {
                ...initialSkill,
                ...itm
              }
            } else {
              return itm
            }

          })
        },
        ...entity,
        unselectedSpecialties,
        unselectedSkills,
        unselectedCompanies,
        unselectedTitles,
        batch,
        newlySelected
      }

      // console.log("====================PAYLOAD===========================", { payload, entity })
      await manualApiCall(`/api/v2/auth/jobs/${jId}/findNew`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      })
      push(`/job/addingProspects/${jId}?t=5`)

    }
    catch (err) {
      console.log("Error: ", err.message)
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      className='findMore'
    >
      <div>
        <div>
          <h1>
            Find more prospects
          </h1>
          <div
            className='findMoreTabsConatiner'
          >
            {currentTab.name !== "Prospect count" && tabs.map((tab, index) => (
              index === 0 ? "" :
                <TabButton
                  key={tab.name}
                  tab={tab}
                  selected={currentTab.name === tab.name}
                  disabled={true}
                  onClick={handleTabClick}
                >
                  {tab.name}
                </TabButton>

            ))}
          </div>
          <span>
            {tabs.map((tab, index) => (
              <Transition
                key={index}
                in={currentTab.name === tab.name}
                timeout={duration}
                mountOnEnter={true}
                unmountOnExit={true}
              >
                {state => (
                  <h3
                    style={{
                      ...defaultStyle,
                      ...transitionStyles[state]
                    }}
                  >
                    {tab.subHeading}
                  </h3>
                )}
              </Transition>
            ))}
          </span>
        </div>
        {(fetchingSavedProfile && currentTab.name !== "Prospect count") ?
          <div
            className='findMoreProfileLoaderContainer'
          >
            <Loading />
          </div> :
          tabs.map((tab, index) => (
            <Transition
              key={index}
              in={currentTab.name === tab.name}
              timeout={duration}
              mountOnEnter={true}
              unmountOnExit={true}
            >
              {state => (
                <span
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                  }}
                >
                  {tab.view}
                </span>
              )}
            </Transition>
          ))
        }
      </div>
      <FooterActionBar
        handleBackClick={handleBackClick}
        handleCancelClick={handleCancelClick}
        handleNextClick={handleNextClick}
        isBackDisabled={isBackDisabled}
        isCancelDisabled={isCancelDisabled}
        isNextDisabled={isNextDisabled}
        isLoading={isLoading}
      />
    </div>
  )
}

export default connect(state => ({
  meta: state.prospectsView.candidates.job.meta,
  companyHiringFor: state.prospectsView?.candidates?.job.jobArray?.companyHiringFor,
}))(FindMore)