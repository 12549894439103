import * as ActionTypes from '../../../actions/calendar'
import moment from 'moment'

const initialState = {
    isLoading: false,
    isDataAvailable: false,
    isCalendarAvailable: false,
    isUpdating: false,
    isUpdated: false,
    events: [],
    settings: {},
    calendars: [],
    comments: {},
    profileDetails: [],
    tokenLoading: false,
    tokenLoaded: false,
    valid: false
}

const calendar = (state = initialState, action) => {
    switch (action.type) {

        case ActionTypes.CALENDAR_REFRESH_TOKEN_VALIDITY:
            return {
                ...state,
                tokenLoading: true,
                tokenLoaded: false
            }

        case ActionTypes.CALENDAR_REFRESH_TOKEN_VALIDITY_SUCCESS:
            return {
                ...state,
                tokenLoading: false,
                tokenLoaded: true,
                valid: action.payload.valid
            }
        case ActionTypes.CALENDAR_REFRESH_TOKEN_VALIDITY_FAILURE:
            return {
                ...state,
                tokenLoading: false,
                tokenLoaded: false
            }

        case ActionTypes.FETCH_CALENDARS:
            return { ...state }

        case ActionTypes.FETCH_CALENDARS_SUCCESS:
            return {
                ...state, calendars: action.payload.data ? action.payload.data.calendars : []
            }

        case ActionTypes.FETCH_CALENDARS_FAILURE:
            return { ...initialState }


        case ActionTypes.FETCH_CALENDAR_EVENTS:
            return { ...state, isLoading: true, isDataAvailable: false }

        case ActionTypes.FETCH_CALENDAR_EVENTS_SUCCESS:
            console.log(action.payload);
            if (action.payload && action.payload.data && action.payload.data.events) {
                action.payload.data.events.map(event => {
                    event.startMeeting = moment(event.start.dateTime).format('YYYY-MM-DD HH:mmZZ');
                    event.endMeeting = moment(event.end.dateTime).format('YYYY-MM-DD HH:mmZZ');
                    event["start"] = event.startMeeting;
                    event["end"] = event.endMeeting;
                });
                return { ...state, isLoading: false, isDataAvailable: true, events: action.payload.data.events }
            }
            else {
                return { ...state, isLoading: false, isDataAvailable: true, events: [] }
            }


        case ActionTypes.FETCH_CALENDAR_SETTINGS_FAILURE:
            return { ...initialState }


        case ActionTypes.POST_CALENDAR_EVENTS:
            return { ...state, isUpdating: true }

        case ActionTypes.POST_CALENDAR_EVENTS_SUCCESS:
            return { ...state, isUpdating: false, isUpdated: true }

        case ActionTypes.POST_CALENDAR_EVENTS_FAILURE:
            return { ...state, isUpdating: false, isUpdated: false }


        case ActionTypes.FETCH_CALENDAR_SETTINGS:
            return { ...state, isLoading: true, isDataAvailable: false }

        case ActionTypes.FETCH_CALENDAR_SETTINGS_SUCCESS:
            return { ...state, isLoading: false, isDataAvailable: true, settings: action.payload.data && action.payload.data.settings && action.payload.data.settings[0] ? action.payload.data.settings[0] : {} }

        case ActionTypes.FETCH_CALENDAR_SETTINGS_FAILURE:
            return { ...initialState }


        case ActionTypes.POST_CALENDAR_SETTINGS:
            return { ...state, isUpdating: true }

        case ActionTypes.POST_CALENDAR_SETTINGS_SUCCESS:
            return { ...state, isUpdating: false, isUpdated: true }

        case ActionTypes.POST_CALENDAR_SETTINGS_FAILURE:
            return { ...state, isUpdating: false, isUpdated: false }



        case ActionTypes.UPDATE_CALENDAR_SETTINGS:
            return { ...state, isUpdating: true }

        case ActionTypes.UPDATE_CALENDAR_SETTINGS_SUCCESS:
            return { ...state, isUpdating: false, isUpdated: true }

        case ActionTypes.UPDATE_CALENDAR_SETTINGS_FAILURE:
            return { ...state, isUpdating: false, isUpdated: false }


        case ActionTypes.DELETE_EVENT:
            return { ...state, isUpdating: true }

        case ActionTypes.DELETE_EVENT_SUCCESS:
            return { ...state, isUpdating: false, isUpdated: true }

        case ActionTypes.DELETE_EVENT_FAILURE:
            return { ...state, isUpdating: false, isUpdated: false }

        default:
            return state
    }
}

export default calendar
