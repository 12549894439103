import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { manualPublicApiCall } from '../../utils';

function TribeInviteCallback({ isAuthenticated, history, location: { search } }) {
    let { tribeId, jobId, userId } = useParams();
    useEffect(async () => {
        const res = await manualPublicApiCall("/api/auth/tribes/confirmInvite", {
            method: "POST",
            body: JSON.stringify({
                tId: tribeId,
                uId: userId,
            })
        })

        if (isAuthenticated) {
            history.push(`/job/${jobId}${search}`)
        } else {
            localStorage.setItem('inviteTribe', JSON.stringify({
                jobId, search
            }))
            history.push('/auth/login')
        }
    }, [isAuthenticated])


    return (
        <div>TribeInviteCallback</div>
    )
}

export default connect(state => ({
    isAuthenticated: state.auth.user.isAuthenticated,
    email: state.auth.emailStep.email
}))(TribeInviteCallback)