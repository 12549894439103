import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addCampaignRound,
  changeCampaignRound,
  initOutbox,
  selectOutboxDraft,
} from "../../../actions/outbox";
import IconHeader from "../../../uiLibrary/IconHeader/IconHeader";
import Loading from "../../../uiLibrary/Loading";
import { manualApiCall, sortBy } from "../../../utils";
import RecipientsList from "./RecipientsList";
import Round from "./Round";
import "./styles.scss";

export default function ViewCampaign() {
  const dispatch = useDispatch();
  const savedRounds = useSelector((state) => state.outbox.campaigns.rounds);

  const { id: ID } = useParams();

  const [data, setData] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const messageTemplates = {
    linkedin: [
      {
        message:
          data.length > 0
            ? data?.rounds?.sort((a, b) => a.rounds - b.rounds)?.[0]?.template
            : `{{.First_Name}}, extremely impressed by your work experience. I am reaching out regarding {{.Job_Title}} position for {{.Hiring_Company}}.\nGreat benefits and an amazing opportunity.\nAvailable for a quick chat this week?\n\nLook forward to hearing from you\n{{.My_Name}}`,
      },
      {
        message: `Hi {{.First_Name}},\nQuick followup regarding the {{.Job_Title}} position for {{.Hiring_Company}} that I messaged you about earlier.\nWould this week work for a quick chat?\n\nLooking forward.\nThank you,\n{{.My_Name}}`,
      },
      {
        message: `Hi {{.First_Name}},\nQuick followup regarding the {{.Job_Title}} position for {{.Hiring_Company}} that I messaged you about earlier.\nWould this week work for a quick chat?\n\nLooking forward.\nThank you,\n{{.My_Name}}`,
      },
    ],
    inmail: [
      {
        subject: `You are a great fit!`,
        message:
          data.length > 0
            ? data?.rounds?.sort((a, b) => a.rounds - b.rounds)?.[0]?.template
            : `Hi {{.First_Name}},\n\nI found your profile on Linkedin and wanted to reach out regarding the {{.Job_Title}} position for {{.Hiring_Company}}. Your experience at {{.Current_Company}} is the type of skillset we are looking for in the {{.Job_Title}}  role.\n\n{{.Hiring_Company}} offers competitive benefits for full-time employees. Benefits include Medical, Dental, Vision, 401K, Wellness Programs, Commuter Programs and Paid Time Off. Employment Benefits vary by location.\n\nWhen would be a good time to discuss this further?\n\nLooking forward to hearing back from you.\n\nThank you,\n{{.My_First_Name}}`,
      },
      {
        subject: `Following Up!`,
        message: `{{.First_Name}} ,\n\nJust a quick follow-up to see if you might be interested in the {{.Job_Title}} position for {{.Hiring_Company}} that I messaged you about earlier. Let me know when we can set up a quick chat to discuss more details.\n\nWhat do you say? Let's connect this week!\n\nHave a great day!\n\nThanks,\n{{.My_First_Name}}`,
      },
      {
        subject: `Catching up again!`,
        message: `I just wanted to reach out one last time, in the event you might have not seen or opened my last messages. I am recruiting for {{.Job_Title}} for {{.Hiring_Company}} and wanted to see if you might be interested.\n\nI completely understand if now is not the right time for a change, perhaps we can keep in touch for future opportunities!\n\nAlso, would you happen to know anyone who might have a similar background? If you happen to know anyone who has a similar background to you, that is looking to make a change, please feel free to forward over my contact information. Great people always know other great people!\n\nHave a great week!\n\nBest Regards,\n{{.My_First_Name}}`,
      },
    ],
    recruiter: [
      {
        subject: `You are a great fit!`,
        message:
          data.length > 0
            ? data?.rounds?.sort((a, b) => a.rounds - b.rounds)?.[0]?.template
            : `Hi {{.First_Name}},\n\nI found your profile on Linkedin and wanted to reach out regarding the {{.Job_Title}} position for {{.Hiring_Company}}. Your experience at {{.Current_Company}} is the type of skillset we are looking for in the {{.Job_Title}}  role.\n\n{{.Hiring_Company}} offers competitive benefits for full-time employees. Benefits include Medical, Dental, Vision, 401K, Wellness Programs, Commuter Programs and Paid Time Off. Employment Benefits vary by location.\n\nWhen would be a good time to discuss this further?\n\nLooking forward to hearing back from you.\n\nThank you,\n{{.My_First_Name}}`,
      },
      {
        subject: `Following Up!`,
        message: `{{.First_Name}} ,\n\nJust a quick follow-up to see if you might be interested in the {{.Job_Title}} position for {{.Hiring_Company}} that I messaged you about earlier. Let me know when we can set up a quick chat to discuss more details.\n\nWhat do you say? Let's connect this week!\n\nHave a great day!\n\nThanks,\n{{.My_First_Name}}`,
      },
      {
        subject: `Catching up again!`,
        message: `I just wanted to reach out one last time, in the event you might have not seen or opened my last messages. I am recruiting for {{.Job_Title}} for {{.Hiring_Company}} and wanted to see if you might be interested.\n\nI completely understand if now is not the right time for a change, perhaps we can keep in touch for future opportunities!\n\nAlso, would you happen to know anyone who might have a similar background? If you happen to know anyone who has a similar background to you, that is looking to make a change, please feel free to forward over my contact information. Great people always know other great people!\n\nHave a great week!\n\nBest Regards,\n{{.My_First_Name}}`,
      },
    ],
    email: [
      {
        subject: `You are a great fit!`,
        message:
          data.length > 0
            ? data?.rounds?.sort((a, b) => a.rounds - b.rounds)?.[0]?.template
            : `Hi {{.First_Name}},\n\nI found your profile on Linkedin and wanted to reach out regarding the {{.Job_Title}} position for {{.Hiring_Company}}. Your experience at {{.Current_Company}} is the type of skillset we are looking for in the {{.Job_Title}}  role.\n\n{{.Hiring_Company}} offers competitive benefits for full-time employees. Benefits include Medical, Dental, Vision, 401K, Wellness Programs, Commuter Programs and Paid Time Off. Employment Benefits vary by location.\n\nWhen would be a good time to discuss this further?\n\nLooking forward to hearing back from you.\n\nThank you,\n{{.My_First_Name}}`,
      },
      {
        subject: `Following Up!`,
        message: `{{.First_Name}} ,\n\nJust a quick follow-up to see if you might be interested in the {{.Job_Title}} position for {{.Hiring_Company}} that I messaged you about earlier. Let me know when we can set up a quick chat to discuss more details.\n\nWhat do you say? Let's connect this week!\n\nHave a great day!\n\nThanks,\n{{.My_First_Name}}`,
      },
      {
        subject: `Catching up again!`,
        message: `I just wanted to reach out one last time, in the event you might have not seen or opened my last messages. I am recruiting for {{.Job_Title}} for {{.Hiring_Company}} and wanted to see if you might be interested.\n\nI completely understand if now is not the right time for a change, perhaps we can keep in touch for future opportunities!\n\nAlso, would you happen to know anyone who might have a similar background? If you happen to know anyone who has a similar background to you, that is looking to make a change, please feel free to forward over my contact information. Great people always know other great people!\n\nHave a great week!\n\nBest Regards,\n{{.My_First_Name}}`,
      },
    ],
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (!!data) {
      if (
        data &&
        data?.rounds?.sort((a, b) => a.rounds - b.rounds)?.length > 0
      ) {
        data.rounds.map(
          (
            {
              channel,
              send_after_days,
              send_after_hours,
              subject,
              template,
              messages,
              prospectCount,
              sentMessages, 
              repliedMessages, 
              interested
            },
            index
          ) => {
            if (index === 0) {
              dispatch(
                changeCampaignRound({
                  id: savedRounds[0].id,
                  value: {
                    channel,
                    send_after_days,
                    send_after_hours,
                    subject,
                    template,
                    messages,
                    prospectCount,
                    sentMessages, 
                    repliedMessages, 
                    interested
                  },
                })
              );
            } else {
              dispatch(
                addCampaignRound({
                  channel,
                  send_after_days,
                  send_after_hours,
                  subject,
                  template,
                  messages,
                })
              );
            }
          }
        );
        dispatch(selectOutboxDraft(false));
      } else {
        savedRounds.map(({ id, channel, messages }, index) => {
          dispatch(
            changeCampaignRound({
              id,
              value: {
                template: messageTemplates[channel][index].message,
                messages,
              },
            })
          );
        });
      }
    }
    return () => {
      dispatch(initOutbox());
    };
  }, [data]);

  React.useEffect(() => {
    fetchCampaignData();
  }, []);

  const fetchCampaignData = async () => {
    try {
      setIsLoading(true);
      const { data } = await manualApiCall(
        `/api/v2/auth/campaigns/campaign/data?id=${ID}`,
        {
          method: "GET",
        }
      );

      const recipients = sortBy(data?.recipients, "is_personalised");
      setData({ recipients, ...data });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <div className="loader">
      <Loading />
    </div>
  ) : (
    <div className="fullContainer">
      <IconHeader />
      <div className="mainContainer">
        <div className="recipientsList">
          <RecipientsList recipients={data?.recipients} />
        </div>
        <div className="rounds">
          {savedRounds?.map((round, key) => (
            <Round {...round} index={key} key={key} />
          ))}
        </div>
      </div>
      {/* {rounds !== roundsSent && (
        <div className="footer">
          <div
            onClick={() => history.push("/campaigns")}
            className="footer-back"
          >
            <CaretLeft />
            <p>Back</p>
          </div>
          <div className="footer-actions">
            <PrimaryButton
              style={{
                width: 150,
                justifyContent: "center",
                height: 50,
                paddingBottom: 10,
                backgroundColor: null,
              }}
              disabled={!stopped}
              onClick={() => {}}
            >
              Resume
            </PrimaryButton>
            <PrimaryButton
              className="stop"
              disabled={rounds === roundsSent || stopped}
              style={{
                width: 150,
                justifyContent: "center",
                height: 50,
                paddingBottom: 10,
              }}
              onClick={() => {}}
            >
              Stop
            </PrimaryButton>
          </div>
        </div>
      )} */}
    </div>
  );
}
