import React from "react";

export const ExperienceFAQ1 = () => {
  return (
    <div>
      <p>
        Years of experience is taken from the following:
        <ul>
          <li>If mentioned in the job description</li>
          <li>
            If not mentioned in the job description then it is decided on the
            basis of the rank of the main title. A higher rank will require more
            experience and vice versa
          </li>
          <li>
            If an ideal profile is selected then the upper bond of experience is
            taken from it
          </li>
        </ul>
      </p>
    </div>
  );
};
