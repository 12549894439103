import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import useInterval from 'use-interval'
import { FETCH_PROVIDER_SUCCESS } from '../../actions/auth'
import externalLink from '../../img/external-link.svg'
import gmailIcon from '../../img/GmailIcon.svg'
import outlookIcon from '../../img/OutlookIcon.svg'
import yahooIcon from '../../img/YahooIcon.svg'
import { manualPublicApiCall } from '../../utils'


const yahooDomains = ['yahoo', 'yahoomail']
const outlookDomains = ['outlook', 'hotmail', 'live']
const googleDomains = ['google', 'gmail', 'googlemail']

const getDomain = (email) => {
    try {
        const domain = email.split('@')[1].split('.')[0]
        if (yahooDomains.includes(domain)) {
            return 'yahoo'
        }
        if (outlookDomains.includes(domain)) {
            return 'outlook'
        }
        if (googleDomains.includes(domain)) {
            return 'google'
        }
        return 'google'
    } catch {
        return 'google'
    }
}

const VerifyButton = ({
    provider = 'google',
    style = {}
}) => {
    const iconMap = {
        'google': gmailIcon,
        'yahoo': yahooIcon,
        'outlook': outlookIcon
    }
    const linkMap = {
        'google': 'https://mail.google.com/mail/',
        'yahoo': 'https://mail.yahoo.com/',
        'outlook': 'https://outlook.live.com/'
    }

    const providerLink = linkMap[provider]
    return (
        <>
            <a style={style} className='bt_wrapper' href={providerLink} target='_blank'>
                <div className='bt_icon'>
                    <img src={iconMap[provider]} alt={provider} />
                </div>
                <span className='bt_text'>
                    Continue with {provider}
                </span>
                <div className='bt_link' >
                    <img src={externalLink} alt='external link' />
                </div>
            </a>
        </>
    )
}



function VerifyEmail({
    email,
    dispatch,
    history,
}) {
    const prev = history?.location?.state?.from
    const provider = getDomain(email)
    const WAIT_TIME = 1000 * 60 * 2
    const [error, setError] = useState('')
    const resendRef = useRef()
    const [initTime, setInitTime] = useState(Date.now())
    const [time, setTime] = useState()

    useInterval(() => {
        let t = WAIT_TIME - (Date.now() - initTime)
        if (t < 0)
            t = 0
        setTime(t)
    }, 1000)

    const sendEmail = async () => {
        if (time > 0) return
        const email =  JSON.parse(window.atob(localStorage.getItem('UserData')))?.email || ""
        if (email == '') {
            return alert('Invalid Email')
        }
        try {
            const res = await manualPublicApiCall('/public/resend/email', {
                method: "POST",
                body: JSON.stringify({ email, status: 'signup' })
            })
            if (res?.isSuccess) {
                dispatch({ type: FETCH_PROVIDER_SUCCESS, meta: email })
                alert('Email Sent')
                resendRef.current.textContent = "Email sent successfully!"
                //document.getElementById("resend-span").textContent = "Email sent successfully!"
            }
            else {
                alert("User not found")
                throw new Error("User not found")
            }
        } catch (e) {
            console.log("ERROR OCCURRED", e)
        } finally {
            setInitTime(Date.now())
        }
    }
    return (
        <>
            <div className='authElemContainer verifyEmailRoot'>
                <h1>
                    {
                        prev === 'reset'
                            ? 'Check your email'
                            : 'Verify your email'
                    }
                </h1>
                <p>
                    {
                        prev === 'reset'
                            ? 'We have sent you an email with a link to reset your password.'
                            : 'Before continuing, we need to verify your email address. Please check your inbox for a confirmation link.'
                    }
                </p>
                <p>
                    If you did not receive the email at {email} within {parseInt(time / 1000)} seconds, we can
                    <br />
                    <span id='resend-span' ref={resendRef} onClick={sendEmail} className={`${time <= 0 ? 'resend' : ''}`}>
                        send it again
                    </span>
                </p>

                {/* <VerifyButton provider={provider} /> TODO:  get provider first  */}
            </div>
        </>
    )
}


export default connect(state => ({
    email: state.auth.emailStep.email,
}))(withRouter(VerifyEmail))

