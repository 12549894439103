    /* global chrome */
export const CALL_LINKEDIN_API_V2 = 'CALL_LINKEDIN_API_V2'
export const callLinkedinApi = (
    endpoint,
    options,
    callBack=r=>true
) => {
    const extId = process.env.REACT_APP_EXT_ID
    chrome.runtime.sendMessage(extId, { 
        type: CALL_LINKEDIN_API_V2,
        endpoint,
        options
    }, callBack)
    return true
}
export const VISIT_API_PROFILE_V2 = 'VISIT_API_PROFILE_V2'
export const visitApiProfile = (
    pubId,
    callBack=r=>true
) => {

    // console.log("==============visitApiProfile=================", pubId)
    const extId = process.env.REACT_APP_EXT_ID
    chrome.runtime.sendMessage(extId, { 
        type: VISIT_API_PROFILE_V2,
        pubId
    }, callBack)
    return true
}

export const GET_COMPANY_DETAILS_V2 = 'GET_COMPANY_DETAILS_V2'
export const getCompanyDetails = (
    uniName,
    callBack=r=>true
) => {
    // console.log("=========================GET_COMPANY_DETAILS_V2===============================", uniName)
    const extId = process.env.REACT_APP_EXT_ID
    chrome.runtime.sendMessage(extId, { 
        type: GET_COMPANY_DETAILS_V2,
        uniName
    }, callBack)
    return true
}

export const CALL_COMPANY_RECOMMENDER_API_V2 = 'CALL_COMPANY_RECOMMENDER_API_V2'
export const callCompanyRecommenderApi = (
    urn,
    callBack=r=>true
) => {
    // console.log("====================CALL_COMPANY_RECOMMENDER_API_V2=================================", urn )
    const extId = process.env.REACT_APP_EXT_ID
    chrome.runtime.sendMessage(extId, { 
        type: CALL_COMPANY_RECOMMENDER_API_V2,
        urn
    }, callBack)
    return true
}
export const INCREMENT_CONNECT_COUNT = 'INCREMENT_CONNECT_COUNT'
export const incrementConnectCount = (
    callBack=r=>true
) => {
    // console.log("====================incrementConnectCount=================================")
    const extId = process.env.REACT_APP_EXT_ID
    chrome.runtime.sendMessage(extId, { 
        type: INCREMENT_CONNECT_COUNT,
    }, callBack)
    return true
}

export const SEND_MESSAGE_FIRST_CONNECTION = 'SEND_MESSAGE_FIRST_CONNECTION'
export const sendMessageFirstConnection = (
    fullName,
    template,
    publicIdentifier,
    callBack=r=>true
) => {
    // console.log("====================sendMessageFirstConnection=================================")
    const extId = process.env.REACT_APP_QLU_EXT_ID
    console.log("==============extId====================", extId)
    chrome.runtime.sendMessage(extId, { 
        type: SEND_MESSAGE_FIRST_CONNECTION,
        fullName,
        template,
        publicIdentifier,
    }, callBack)
    return true
}

export const SEND_INMAIL_MESSAGE = 'SEND_INMAIL_MESSAGE'
export const sendInMailMessage = (
    subject,
    message,
    publicIdentifier,
    callBack=r=>true
) => {
    // console.log("====================sendInMailMessage=================================")
    const extId = process.env.REACT_APP_QLU_EXT_ID
    console.log("==============extId====================", extId)
    chrome.runtime.sendMessage(extId, { 
        type: SEND_INMAIL_MESSAGE,
        subject,
        message,
        publicIdentifier,
    }, callBack)
    return true
}

export const GET_INMAIL_CREDITS = 'GET_INMAIL_CREDITS'
export const getInmailCredits = (
    callBack=r=>true
) => {
    // console.log("====================getInmailCredits=================================")
    const extId = process.env.REACT_APP_QLU_EXT_ID
    chrome.runtime.sendMessage(extId, { 
    type: GET_INMAIL_CREDITS
    }, callBack)
    return true
}

export const CALL_QLU_LINKEDIN_API = 'CALL_QLU_LINKEDIN_API'
export const callQluLinkedinApi = (
    endpoint,
    options,
    callBack=r=>true
) => {
    const extId = process.env.REACT_APP_QLU_EXT_ID
    console.log("==============CALL_QLU_LINKEDIN_API====================", {
        extId, 
        endpoint,
        options})
    chrome.runtime.sendMessage(extId, { 
        type: CALL_QLU_LINKEDIN_API,
        endpoint,
        options
    }, callBack)
    return true
}
export const PING_QLU = 'PING_QLU'
export const pingQlu = (callBack=r=>true) => {
    const extId = process.env.REACT_APP_QLU_EXT_ID
    console.log("==============PING_QLU====================", extId)
    if(extId && chrome.runtime){
        chrome.runtime.sendMessage(extId, { 
            type: PING_QLU
        }, callBack)
        return true
    }
    return callBack({})
}