import React, { useState } from 'react';
import { connect } from 'react-redux';
import { likedProfile } from '../../actions/prospectsView';
import { Mixpanel } from '../../utils/Mixpanel';
import './style.scss';
const SocialHeartButton = ({
    dispatch,
    permissionsAllowed,
    index,
    onClickSave = e => false,
    jobProfileId,
    profileId,
    maybe = false,
    saved = false,
    mixpanelActionType = false,
    isAddingProfile = false,
    onClick = e => {
        if (permissionsAllowed === "*" || (permissionsAllowed?.length && permissionsAllowed?.length > 0 && (permissionsAllowed?.includes("GIVE_FEEDBACK") || permissionsAllowed?.includes("CAN_SAVE")))) {
            const data = {
                index: index,
                status: saved ? null : "RECOMMENDED_YES",
                jobProfileId: jobProfileId,
            };
            if (mixpanelActionType) {
                Mixpanel.track(`Like - ${mixpanelActionType}`)
            }
            dispatch(likedProfile(data));
            onClickSave(
                [jobProfileId],
                saved,
                profileId,
                maybe
            );
        }
    },
    ...props
}) => {
    const [disabled, setDisabled] = useState(false)
    return (
        <button
            className={`socialHeartButton ${saved ? 'socialHeartButton-activate' : ''}`}
            onClick={(e) => {
                if (isAddingProfile) {
                    setDisabled(true)
                }
                onClick(e)
            }}
            disabled={disabled}
            {...props}
        >
            <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.4578 2.67974C18.9691 2.14721 18.3889 1.72477 17.7503 1.43656C17.1117 1.14834 16.4272 1 15.7359 1C15.0446 1 14.3601 1.14834 13.7215 1.43656C13.0829 1.72477 12.5026 2.14721 12.0139 2.67974L10.9997 3.7844L9.98554 2.67974C8.99842 1.60458 7.6596 1.00056 6.26361 1.00056C4.86761 1.00056 3.52879 1.60458 2.54168 2.67974C1.55456 3.7549 1 5.21313 1 6.73364C1 8.25415 1.55456 9.71238 2.54168 10.7875L3.55588 11.8922L10.9997 20L18.4436 11.8922L19.4578 10.7875C19.9467 10.2553 20.3346 9.62328 20.5992 8.9277C20.8638 8.23212 21 7.48657 21 6.73364C21 5.98071 20.8638 5.23516 20.5992 4.53958C20.3346 3.84399 19.9467 3.21201 19.4578 2.67974V2.67974Z"
                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </button>
    )
}

export default connect(state => ({
    permissionsAllowed: state?.prospectsView?.candidates?.permissions,
}))(SocialHeartButton)