import React from 'react'
import { Switch } from 'react-router-dom'

import Layout from './Layout'
import Home from './Home'
import Jobs from './Jobs'
import Campaigns from './Campaigns'
import Calendar from '../CalendarComponent'
import Settings from './Settings'
import Messaging from './Messaging'

import './LandingPage.scss'
import ProtectedRoute from '../../HOC/ProtectedRoute'



const LandingPage = () => {
  return (
    <Layout>
      <ProtectedRoute path={`/jobs`} component={Jobs} />
      <ProtectedRoute path={`/campaigns`} component={Campaigns} />
      <ProtectedRoute path={`/settings`} component={Settings} />
      {/* <ProtectedRoute path={`/calendar`} component={Calendar} /> */}
      <ProtectedRoute path={`/messaging`} component={Messaging} />
      <ProtectedRoute path={`/`} exact component={Home} />
    </Layout>
  )
}

export default LandingPage