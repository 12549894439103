import React from "react";
import { connect } from "react-redux";
import { setBatchSize } from "../../../actions/prospectsView";
import { Mixpanel } from "../../../utils/Mixpanel";
import JobSetupStep03 from "../../SVGs/JobSetupStepSvgs/JobSetupStep03";
import JobSetupStep04 from "../../SVGs/JobSetupStepSvgs/JobSetupStep04";
import JobSetupStep07 from "../../SVGs/JobSetupStepSvgs/JobSetupStep07";
import JobSetupStep09 from "../../SVGs/JobSetupStepSvgs/JobSetupStep09";
import JobSetupStep10 from "../../SVGs/JobSetupStepSvgs/JobSetupStep10";
import "./SetBatchSize.scss";
function SetBatchSize({ batchSize, dispatch }) {
  const items = [50, 100, 200, 500, 635];
  const [active, setActive] = React.useState(batchSize);
  return (
    <div className="set-batch-size">
      <div className="header">
        <div className="title">Find more prospects</div>
        <div className="sub-title">
          How many more prospects do you want to see?
        </div>
      </div>
      <div className="content">
        {items.map((item, index) => {
          return (
            <div
              className={`step ${active == item ? "active" : ""}`}
              onClick={() => {
                Mixpanel.track(`set batch size - find more prospects`)
                setActive(item);
                dispatch(setBatchSize(item));
              }}
              key={index}
            >
              {item}
            </div>
          );
        })}
      </div>
      {/* <div className="subFooter">Daily limit left: 635 profiles</div> */}
    </div>
  );
}

export default connect((state) => ({
  batchSize: state.prospectsView.findMoreProspect.batchSize,
}))(SetBatchSize);
