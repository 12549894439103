import React, { useState } from "react";
import "./ScoreAndConnectHeader.scss";
import CaretDown from "../../components/svgComponents/CaretDown";
import DottedVerticalSvg from "../../components/svgComponents/DottedVerticalSvg";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { connect } from "react-redux";
import {
  setSubDropdownView,
  addLabel,
  deleteJob,
} from "../../actions/prospectsView";
// import { isJobEdit } from "../../actions/JobEdit";
import { withRouter } from "react-router";
import TickSvg from "../SVGs/TickSvg";
import CrossSvg from "../SVGs/CrossSvg";
import {toast} from 'react-toastify'
import ProfileLiked from "../ProfileLiked/ProfileLiked";
import { Button } from "reactstrap";
import { setIsReview } from "../../actions/JobSetup";

const ScoreAndConnectDropdown = ({
  currValue,
  dropDownMenu,
  setcurrValue,
  isDotted,
  dispatch,
  qParams,
  history: { push },
  permissionsAllowed,
  jId,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [openLabelField, setOpenLabelField] = useState(false);
  const [labelValue, setLabelValue] = useState("");

  const handleLabelChange = (val) => {
    setLabelValue(val);
  };

  const handleCloseLabel = () => {
    setLabelValue("");
    setOpenLabelField(false);
  };

  const handleSendLabel = () => {
    dispatch(addLabel(labelValue));

    setLabelValue("");
    setOpenLabelField(false);
  };

  const handleDeleteJob = () => {
    toast.dismiss();
      toast(
        ({ closeToast }) => (
          <ProfileLiked
            header={`Are you sure?`}
            description="This job will be deleted permanently"
          >
            <Button
              className="reviewProfileBtn"
              onClick={() => {
                dispatch(deleteJob(push));
              }}
            >
              Yes, Delete Job
            </Button>
            <Button className="closeButtonPopup" onClick={closeToast}>
              No
            </Button>
          </ProfileLiked>
        ),
        {
          closeButton: false,
          hideProgressBar: true,
          style: {
            backgroundColor: "#A0A0A0",
            borderRadius: "8px",
            width: "514px",
          },
        }
      );
  };

  return (
    <Dropdown className="mainDropdown" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        className={`secondaryGreyButton tempDropdown ${
          isDotted ? "dotted" : ""
        }`}
      >
        {isDotted ? (
          <DottedVerticalSvg style={{ marginRight: "132px" }} />
        ) : (
          currValue
        )}
        {isDotted ? (
          ""
        ) : (
          <CaretDown
            style={{ marginLeft: "8px" }}
            width="10px"
            height="6px"
            color="#A7ABB0"
          />
        )}
      </DropdownToggle>
      <DropdownMenu className="dropdownMenu">
        {dropDownMenu.map((item, index) => {
          const isDeleteAllowed =
            item !== "Delete" ||
            permissionsAllowed === "*" ||
            (permissionsAllowed?.length &&
              permissionsAllowed?.length > 0 &&
              permissionsAllowed?.includes("DELETE_JOB"));
          const isDuplicateAllowed =
            item !== "Duplicate" ||
            permissionsAllowed === "*" ||
            (permissionsAllowed?.length &&
              permissionsAllowed?.length > 0 &&
              permissionsAllowed?.includes("EDIT_JOB_SETUP"));
          if (item == "Delete" || item == "Duplicate") {
            if (item == "Delete" && !isDeleteAllowed) {
              return "";
            }
            if (item == "Duplicate" && !isDuplicateAllowed) {
              return "";
            }
          }
          return (
            <DropdownItem
              key={index}
              className={`${isDotted ? "dropDownItemDotted" : "dropDownItem"}${
                item === currValue && !isDotted ? " dropDownItemActive" : ""
              }${item === "Delete" ? " deleteProstects" : ""}`}
              value={item}
              onClick={(e) => {
                setcurrValue(e.target.value);
                if (item.match(/edit/i)) {
                  // dispatch(isJobEdit(true));
                  push(`/jobSetup?edit=true&jId=${jId}${qParams?.aT?`&aT=${qParams?.aT}`:""}`);
                  dispatch(setSubDropdownView("Job Setup"))
                  dispatch(setIsReview("0"));
                } else if (item === "Add a label") {
                  setOpenLabelField(true);
                } else if (item === "Duplicate") {
                  push(`/jobsetup?edit=true&duplicate=true&jId=${jId}`);
                  dispatch(setSubDropdownView("Job Setup"))
                  dispatch(setIsReview(""));
                } else if (item === "Delete") {
                  handleDeleteJob();
                }
              }}
            >
              {item}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
      {openLabelField ? (
        <div
          style={{
            position: "absolute",
            zIndex: 200,
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            className="primaryInput"
            placeholder="Enter Label"
            value={labelValue}
            onChange={(e) => handleLabelChange(e.target.value)}
          />
          <button
            className="btn primaryGreyButton tickButtonLabel"
            disabled={labelValue.length == 0}
            onClick={() => handleSendLabel()}
          >
            <TickSvg />
          </button>
          <button
            className="btn primaryGreyButton crossButtonLabel"
            onClick={() => handleCloseLabel()}
          >
            <CrossSvg />
          </button>
        </div>
      ) : (
        ""
      )}
    </Dropdown>
  );
};

export default withRouter(
  connect((state) => ({
    jId: state.prospectsView.candidates.job.id,
  }))(ScoreAndConnectDropdown)
);
