import React from "react";
import { Breadcrumb, BreadcrumbItem, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import "./MoreProspectsFooter.scss";
import CaretLeft from "../../SVGs/CaretLeft";
import LabelButton from "../../LabelButton";
import CrossSvg from "../../SVGs/CrossSvg";
import { connect } from "react-redux";
import {
  setMoreProspectCurrentTab,
  _startSearch,
} from "../../../actions/prospectsView";
import { manualApiCall } from "../../../utils";
function MoreProspectsFooter({
  onCancel,
  onNext,
  active,
  setActive,
  dispatch,
  findMore,
  match: { params },
  history,
  priorityArray,
  currentTab,
}) {
  const breadCrumbItems = ["Set Batch Size", "Prioritize", "Add Details"];

  console.log("PARAMSSS: ", params);

  return (
    <div className="MoreProspectsFooter">
      <div className="backBtn">
        <LabelButton onClick={() => onCancel && onCancel()}>
          <CrossSvg />
          Cancel
        </LabelButton>
      </div>
      {/* <div className="breadCrumbs"></div> */}
      <div className="breadCrumb">
        {breadCrumbItems?.map((item, index) => {
          return (
            <React.Fragment key={`breadCrumbId-${index}`}>
              <span
                className={active == item && "active"}
                onClick={() => setActive(item)}
              >
                {item}
              </span>{" "}
              {index == breadCrumbItems.length - 1 ? null : (
                <CaretLeft
                  height="16px"
                  width="16px"
                  style={{ transform: "rotate(180deg) translate(0,1px)" }}
                />
              )}{" "}
            </React.Fragment>
          );
        })}
      </div>
      <div className="nextBtn">
        <Button
          disabled={active == "Add Details" && priorityArray.length == 0}
          onClick={() => {
            if (
              active == "Add Details" &&
              priorityArray[priorityArray?.length - 1] == currentTab
            ) {
              console.log("STARING SEARCH: ", findMore);
              const basePriorities = findMore.priorityArray.map(
                (item) => item.name
              );
              const skillPriorities = findMore.skills.map((item) => item.name);
              const specialtyPriorities = findMore.specialities.map(
                (item) => item.name
              );

              manualApiCall(`/api/v2/auth/jobs/${params.jId}/findMore`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  basePriorities,
                  skillPriorities,
                  specialtyPriorities,
                  batch: findMore.batchSize,
                }),
              });

              history.push(`/job/addingProspects/${params.jId}?t=5`);
            } else {
              const index = priorityArray?.findIndex(
                (item) => item?.name == currentTab?.name
              );
              dispatch(setMoreProspectCurrentTab(priorityArray[index + 1]));
            }
            setActive &&
              setActive((prev) => {
                const index = breadCrumbItems.indexOf(prev);
                if (index < breadCrumbItems.length - 1) {
                  return breadCrumbItems[index + 1];
                }
                return prev;
              });
          }}
        >
          {active == "Add Details" &&
          priorityArray[priorityArray?.length - 1] == currentTab
            ? "Start Search"
            : "Next"}
        </Button>
      </div>
    </div>
  );
}

export default connect((state) => ({
  findMore: state.prospectsView.findMoreProspect,
  priorityArray: state.prospectsView.findMoreProspect.priorityArray,
  currentTab: state.prospectsView.findMoreProspect.currentTab,
}))(withRouter(MoreProspectsFooter));
