import React from 'react'
import { withRouter } from 'react-router-dom'

import AuthHeader from '../AuthHeader'
import './AuthLayout.scss'

const AuthLayout = ({
  children,
  location: { pathname },
  history,
  removeHeaderCTA=false
}) => {
  const variant = pathname.includes('signup') ? 'signup' : 'login'

  const handleClickHeaderCta = () => {
    if (variant === 'signup') {
      history.push('/auth/login')
    } else if (variant === 'login') {
      history.push('/auth/signup')
    }
  }

  return (
    <div className='auth_layout'>
      <div className='auth_layout_content'>
        <AuthHeader
          variant={variant}
          onClickCta={handleClickHeaderCta}
          onClickIconHeader={() => history.push('/')}
          removeCTA={removeHeaderCTA}
        />
        <div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default withRouter(AuthLayout)