import * as ActionTypes from '../../actions/QuickSetup'


const initialState = {
  view: 'setup',
  currentTab: 0,
  tabs: [],
  isLoading: false,
  isLoaded: false,
  carsouselSortValue: [],
  selectedValues: [],
  highlightValue: '',
  profileSelectedValues: [],
  profileHighlightValue: '',
  shouldSelectLastTab: false,
  cards: [],
  position: []
}


const viewController = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SHOULD_SELECT_LAST_TAB: {
      return{
        ...state,
        shouldSelectLastTab: action.payload,
      }
    }
    case ActionTypes.SET_JOB_SETUP_VIEW: {
      return {
          ...state,
          ...action.payload
      }
    }
    case ActionTypes.INIT_JOB_SETUP_VIEW: {
      return initialState
    }
    default:
      return state
  }
}

export default viewController
