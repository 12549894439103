import React, { useState, useEffect, memo } from "react";
import { withRouter } from 'react-router-dom'
import { FormGroup } from 'reactstrap'

import { connect } from 'react-redux'

import './style.scss'
import TagButtonComponent from "../TagButtonComponent";
import StyleableTextArea from './StyleableTextArea'

import {
    convertFromRaw,
    convertToRaw,
    ContentState,
    EditorState,
    CompositeDecorator
} from 'draft-js'
import 'draft-js/dist/Draft.css'
import { getReverseTagTranslation, getTagTranslation } from '../../utils'

import tags from './tags'

const StyleableTextAreaContainer = memo(({
    dispatch,
    message = '',
    skillsReq = [],
    charLimits = false,
    className = "",
    onChange = () => false
}) => {


    const tagRegex = `(${tags.map(({ name }) => `${name} ✕`).join('|')})`

    const HANDLE_REGEX = new RegExp(tagRegex, "g")

    const handleStrategy = (contentBlock, callback, contentState) => {
        findWithRegex(HANDLE_REGEX, contentBlock, callback);
    }

    const findWithRegex = (regex, contentBlock, callback) => {
        const text = contentBlock.getText();
        // console.log("============findWithRegex[text]==================", text)
        let matchArr, start;
        while ((matchArr = regex.exec(text)) !== null) {
            start = matchArr.index;
            // console.log("============findWithRegex[matchArr]==================", matchArr, { start, end: start + matchArr[0].length })
            callback(start, start + matchArr[0].length);
        }
    }


    const HandleSpan = props => {

        return (
            <span
                data-offset-key={props.offsetKey}
                data-block-key={props.blockKey}
                data-decorated-text={props.decoratedText}
                contentEditable={false}
                onClick={(e) => handleDeleteTag(props)}
                className='styledTags'
            >
                {props.children}
            </span>
        )
    }

    const decorator = new CompositeDecorator([
        {
            strategy: handleStrategy,
            component: HandleSpan,
        },
    ]);

    const [emailCharcterCounter, setemailCharcterCounter] = useState(message.length || 0)
    const [emailCharcterLimitFlag, setemailCharcterLimitFlag] = useState(0)
    const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator))


    useEffect(() => {
        setemailCharcterCounter(message.length || 0)
    }, [message])

    useEffect(() => {
        // console.log("==============StyleableTextAreaContainer================", message)
        addNewEditorStateFromValue(message)
    }, [])

    const addNewEditorStateFromValue = (value) => {
        const newContent = ContentState.createFromText(getTagTranslation(value))

        setEditorState(
            EditorState.push(
                editorState,
                newContent,
                'insert-text',
            )
        )
    }


    const handleDeleteTag = ({
        offsetKey,
        blockKey,
        decoratedText,
        start,
        end,
        contentState
    }) => {

        const raw = convertToRaw(contentState)

        const newRaw = {
            ...raw,
            blocks: raw.blocks.map(block => block.key === blockKey ? {
                ...block,
                text: block.text.slice(0, start) + block.text.slice(end, block.text.length)
            } : block
            )
        }

        const newEditorState = EditorState.push(
            editorState,
            convertFromRaw(newRaw),
            'remove-text',
        )
        const _cRaw = convertFromRaw(newRaw);
        var selection = _cRaw.getSelectionAfter().merge({
            focusOffset: start,
            anchorOffset: start,
            focusKey: blockKey,
            anchorKey: blockKey
        });
        setEditorState(
            EditorState.forceSelection(newEditorState, selection)
        )

    }

    const handleChange = (newEditorState) => {
        const newTextValue = getReverseTagTranslation(newEditorState.getCurrentContent().getPlainText())
        if (charLimits && newTextValue.length > 280) {
            setemailCharcterLimitFlag(true)
            setTimeout(() => {
                setemailCharcterLimitFlag(false)
            }, 500)
        }
        setEditorState(newEditorState)
        onChange(newTextValue)
    }

    const addTags = (newTag) => {
        // console.log("============addTags=============", newTag)
        const raw = convertToRaw(editorState.getCurrentContent())
        const focusKey = editorState.getSelection().getFocusKey()
        const focusOffset = editorState.getSelection().getFocusOffset()


        // console.log("============addTags=============", {
        //     raw,
        //     focusKey,
        //     focusOffset
        // })

        const tagTrans = getTagTranslation(newTag) + " ";
        const newBlocks = raw.blocks.map(block => block.key === focusKey ? {
            ...block,
            text: block.text.slice(0, focusOffset) + getTagTranslation(newTag) + " " + block.text.slice(focusOffset, block.text.length + 1)
        } : block)

        // console.log("============newBlocks=============", newBlocks)

        const newRaw = {
            ...raw,
            blocks: newBlocks
        }

        // console.log("============newRaw=============", newRaw)

        const newTextValue = getReverseTagTranslation(newBlocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n'))

        onChange(newTextValue)

        const _cRaw = convertFromRaw(newRaw);
        const newEditorState = EditorState.push(editorState, _cRaw, 'insert-text');
        let _selectionContent = _cRaw.getSelectionAfter();
        var selection = _selectionContent.merge({
            focusOffset: focusOffset,
            anchorOffset: focusOffset + tagTrans.length,
            focusKey: focusKey,
            anchorKey: focusKey
        });
        setEditorState(
            EditorState.forceSelection(newEditorState, selection)
        )
    }

    return (
        <FormGroup className={`styleableTextAreaContainer ${className}`}>
            <StyleableTextArea
                value={message || ''}
                onChange={handleChange}
                editorState={editorState}
            />
            {charLimits ? <p className={
                message.length > 280 ?
                    "emailCharcterCounter emailCharcterCounterFlash" :
                    "emailCharcterCounter"}>
                {`${emailCharcterCounter}/280`}
            </p> :
                <div style={{ height: '15px', margin: '8px' }} />}
            <TagButtonComponent
                handleChange={addTags}
                styleableTags={true}
                skillsReq={skillsReq}
            />
        </FormGroup>
    )
}, (pp, np) => {
    return pp.message === np.message
})

export default withRouter(connect(state => ({

}))(StyleableTextAreaContainer))