import React from "react";

export const AdditionalJobTitleFAQ1 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            This helps expand our search as it’s not necessary the right
            prospect will have the exact same title that you have selected
          </li>
        </ul>
      </p>
    </div>
  );
};

export const AdditionalJobTitleFAQ2 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            They are generated based on the job description provided by you and
            the main title
          </li>
        </ul>
      </p>
    </div>
  );
};

export const AdditionalJobTitleFAQ3 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            It is usually preferred to select some titles similar to your main
            title in order to increase the pool of prospects
          </li>
        </ul>
      </p>
    </div>
  );
};

export const AdditionalJobTitleFAQ4 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            The employee count that you have added was for the main title only
          </li>
          <li>
            Every title needs its corresponding employee count. This is because
            you would prefer to hire a prospect with a junior title from a
            bigger company and a prospect with a senior title from a smaller
            company
          </li>
        </ul>
      </p>
    </div>
  );
};

export const AdditionalJobTitleFAQ5 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>No only select the titles that are relevant</li>
        </ul>
      </p>
    </div>
  );
};

export const AdditionalJobTitleFAQ6 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>These are the titles of your best fit’s profile</li>
        </ul>
      </p>
    </div>
  );
};

export const AdditionalJobTitleFAQ7 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>Click on the title in the profile card</li>
          <li>Click on the corresponding pill on the left</li>
        </ul>
      </p>
    </div>
  );
};

export const AdditionalJobTitleFAQ8 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            This happens when the best fit title is same as the main title
            selected
          </li>
        </ul>
      </p>
    </div>
  );
};

export const AdditionalJobTitleFAQ9 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>
            It is selected on the basis of the main title and its employee count
            and the respective rank for the additional titles
          </li>
          <li>
            It is preferred to hire a prospect with a junior title from a bigger
            company and a prospect with a senior title from a smaller company
          </li>
        </ul>
      </p>
    </div>
  );
};

export const AdditionalJobTitleFAQ10 = () => {
  return (
    <div>
      <p>
        <ul>
          <li>There is no defined limit</li>
        </ul>
      </p>
    </div>
  );
};
