import React, {useState} from 'react'
import CrossSvg from '../SVGs/CrossSvg'
import PlusSvg from '../SVGs/PlusSvg'
import TickSvg from '../SVGs/TickSvg'
import './style.scss'
const PillButton = ({
    style={},
    selected=false,
    highlight=false,
    onClick=e=>false,
    disabled=false,
    onMouseEnter=e=>false,
    onMouseLeave=e=>false,
    children
}) => {
    const [hover, setHover] = useState(false)
    const handleMouseEnter = e => {
        setHover(true)
        onMouseEnter(e)
    }
    const handleMouseLeave = e => {
        setHover(false)
        onMouseLeave(e)
    }
    return (
        <button
            style={style}
            className={`pillButton ${highlight ? 'pillButtonHighlight' : ''} ${selected ? highlight ? 'pillButtonHighlightSelected' : 'pillButtonSelected' : ''}`}
            onClick={onClick}
            disabled={disabled}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {selected ?
                hover ?
                <CrossSvg
                    width='16'
                    height='16'
                    selected={selected && highlight}
                /> :
                <TickSvg selected={selected && highlight} /> :
                hover ? 
                <TickSvg selected={highlight} /> :
                <PlusSvg/>
            }
        </button>
    )
}

export default PillButton
