import * as ActionTypes from '../../actions/outbox'

const initialState = {
    outboxDropdownOpen: false,
    recipientTab: 'all',
    view: 'Edit Rounds',
    connected: false,
    isLoading: false,
    isLoaded: false,
    redirectPath: '/',
}

const viewController = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_OUTBOX:{
        return initialState
    }
    case ActionTypes.SET_OUTBOX_VIEW: {
        return {
            ...state,
            ...action.payload
        }
    }
    case ActionTypes.TOGGLE_OUTBOX_DROPDOWN_OPEN: {
        return {
            ...state,
            outboxDropdownOpen: !state.outboxDropdownOpen
        }
    }
    case ActionTypes.INIT_OUTBOX_VIEW: {
        return initialState
    }

    default:
      return state
  }
}

export default viewController
