import * as ActionTypes from "../../actions/prospectsView";

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  isSaving: false,
  isSaved: false,
  skills_synonyms: [],
  titles_synonyms: [],
  educations_synonyms: [],
  specialties_synonyms: [],
  synonyms_loading: false,
  synonyms_loaded: false,
  data: {
    industries: [],
    companies: [],
    schools: [],
    titles: [],
    locations: [],
    educations: [],
    skills: [],
    specialties: [],
    languages: [],
    firstNames: [],
    lastNames: [],
    year_of_graduations: [],
    runInfo: [],
    templates: {},
  },
  ActiveTemplate: "",
  template: {
    lS: false,
    sS: false,
    eS: false,
    eSS: false,
    eCT: false,
    ePT: false,
    eCC: false,
    ePC: false,
    eCI: false,
    ePI: false,
    eL: false,
    ePL: false,
    hPL: false,
    eV: false,
    hS: false,
    hSS: false,
    eE: false,
    hE: false,
    hCT: false,
    hPT: false,
    hCC: false,
    hPC: false,
    hCI: false,
    hPI: false,
    hL: false,
    hV: false,
    tE: false,
    rE: false,
    eCSR: false,
    hCSR: false,
    cTS: false,
    pTS: false,
    eDS: false,
    tSF: false,
    sSF: false,
    expSF: false,
    edxSF: false,
    iSF: false,

    hSL: false,
    eSL: false,
    hFN: false,
    eFN: false,
    hLN: false,
    eLN: false,
    yCC: false,
    yPC: false,
    sST: false,
    sPST: false,
    LTS: false,
    hYOG: false,
    eYOG: false,
  },
};

const advFiltersData = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ADVANCED_FILTER_SYNONYM:
      return {
        ...state,
        synonyms_loading: true,
        synonyms_loaded: false,
      };
    case ActionTypes.FETCH_ADVANCED_FILTER_SYNONYM_SUCCESS:
      return {
        ...state,
        skills_synonyms: action.payload.skills,
        titles_synonyms: action.payload.titles,
        educations_synonyms: action.payload.educations,
        specialties_synonyms: action.payload.specialties,
        synonyms_loading: false,
        synonyms_loaded: true,
      };
    case ActionTypes.FETCH_ADVANCED_FILTER_SYNONYM_SUCCESS:
      return {
        ...state,
        synonyms_loading: false,
        synonyms_loaded: false,
      };

    case ActionTypes.FETCH_ADVANCED_FILTER:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.FETCH_ADVANCED_FILTER_SUCCESS:
      delete action.payload.data.provider;
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          ...action.payload.data,
          templates: action.payload.data?.templates
            ? action.payload.data.templates
            : {},
        },
        isDataAvailable: true,
      };

    case ActionTypes.FETCH_ADVANCED_FILTER_FAILURE:
      return { ...state, isLoading: false, isDataAvailable: false };
    case ActionTypes.CLEAR_ALL_ADVANCED_FILTERS:
      return {
        ...state,
        data: {
          industries: [],
          companies: [],
          schools: [],
          titles: [],
          locations: [],
          educations: [],
          skills: [],
          specialties: [],
          languages: [],
          firstNames: [],
          lastNames: [],
          year_of_graduations: [],
          runInfo: [],
          templates: {},
        },
        isDataAvailable: false,
      };
    // case ActionTypes.SAVE_ADVANCED_FILTER_TEMPLATE:
    //   return {
    //     ...state,
    //     isSaving: true
    //   }

    // case ActionTypes.SAVE_ADVANCED_FILTER_TEMPLATE_SUCCESS:
    //   return {
    //     ...state,
    //     isSaving: false,
    //     isSaved: true
    //   }

    // case ActionTypes.SAVE_ADVANCED_FILTER_TEMPLATE_FAILURE:
    //   return {
    //     ...state,
    //     isSaving: false,
    //     isSaved: false
    //   }

    // case ActionTypes.ADD_TEMPLATE:
    //   const newPayload = {}
    //   newPayload[action.payload.name] = action.payload.template
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       templates: {...state.data.templates, ...newPayload}
    //     }
    //   }

    // case ActionTypes.SAVE_TEMPLATE:
    //   const newTemplate = {}
    //   newTemplate[action.payload.newName] = action.payload.template
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       templates: {
    //         ...Object.entries(state.data.templates).reduce((object, [key, value]) => (
    //           key !== action.payload.name ?
    //             {
    //               ...object,
    //               [key]: value
    //             } : {...object}
    //         ), {}),
    //         ...newTemplate
    //       }
    //     }
    //   }

    // case ActionTypes.DELETE_TEMPLATE:
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       templates: {
    //         ...Object.entries(state.data.templates).reduce((object, [key, value]) => (
    //           key !== action.payload ?
    //             { ...object,
    //               [key]: value } :
    //             {...object}
    //         ), {}),
    //       }
    //     }
    //   }
    // case ActionTypes.CLEAR_TEMPLATE:
    //   return initialState

    // case ActionTypes.SET_TEMPLATE:
    //   return {
    //     ...state,
    //     ActiveTemplate: action.payload
    //   }

    default:
      return state;
  }
};

export default advFiltersData;
