import React from 'react'
import CrossSvg from '../SVGs/CrossSvg'
import PlusSvg from '../SVGs/PlusSvg'
import './style.scss'
const BigRoundButton = ({
    style={},
    children,
    onClick=e=>false,
    disabled=false
}) => {
    return (
        <button
            style={style}
            className='bigRoundButton'
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

export default BigRoundButton
