import React from 'react'
import './style.scss'
const CardContainer = ({
    icon,
    heading,
    subHeading,
    children
}) => {
    return (
        <div
            className='cardContainer'
        >
            <div>
                <span>
                    {icon}
                    <h2>
                        {heading}
                    </h2>
                </span>
            </div>
            {subHeading &&
                <h3>
                    {subHeading}
                </h3>
            }
            <span>
                {
                    children
                }
            </span>
        </div>
    )
}

export default CardContainer
