import React from "react"

import './scoringFilterSection.scss'

import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import ScoringFiltersSliderModule from './ScoringFiltersSliderModule'


const ScoringFilterSection = ({
       
    dispatch,
    
    FilterCount,
    setFilterCount,

    ScoringFilters,


    titleFlag,
    skillFlag,
    industryFlag,
    experienceFlag,
    educationFlag,
    specialtyFlag

}) => {


    return (
        <React.Fragment>
            <h3 className="ScoringFiltersSliderModuleHeading">
                Show prospects that have scoring greater than: 
            </h3>
            <ScoringFiltersSliderModule 
                scoreFlag={titleFlag}
                dispatch={dispatch}
                scoreValue={ScoringFilters.titleScore.value}
                scoreActive={ScoringFilters.titleScore.active} 
                heading={"Current Title"}
                FilterCount={FilterCount}
                setFilterCount={setFilterCount}
            />
            <ScoringFiltersSliderModule 
                scoreFlag={skillFlag}
                dispatch={dispatch}
                scoreValue={ScoringFilters.skillScore.value}
                scoreActive={ScoringFilters.skillScore.active} 
                heading={"Skills"}
                FilterCount={FilterCount}
                setFilterCount={setFilterCount}
            />
            <ScoringFiltersSliderModule 
                scoreFlag={experienceFlag}
                dispatch={dispatch}
                scoreValue={ScoringFilters.experienceScore.value}
                scoreActive={ScoringFilters.experienceScore.active} 
                heading={"Experience"}
                FilterCount={FilterCount}
                setFilterCount={setFilterCount}
            />
            <ScoringFiltersSliderModule 
                scoreFlag={educationFlag} 
                dispatch={dispatch}
                scoreValue={ScoringFilters.educationScore.value}
                scoreActive={ScoringFilters.educationScore.active} 
                heading={"Education"}
                FilterCount={FilterCount}
                setFilterCount={setFilterCount}
            />
            <ScoringFiltersSliderModule 
                scoreFlag={specialtyFlag}
                dispatch={dispatch}
                scoreValue={ScoringFilters.specialtyScore.value}
                scoreActive={ScoringFilters.specialtyScore.active} 
                heading={"Business"}
                FilterCount={FilterCount}
                setFilterCount={setFilterCount}
            />
        </React.Fragment>
  )
}

export default withRouter(connect(state => {
    return {
        ScoringFilters: state.prospectsView.scoringFiltersMeta    
    }
})(ScoringFilterSection))

