import React from 'react'
import qluIcon from '../../img/qluiconfull.svg'
import SecondaryButton from '../../uiLibrary/SecondaryButton'
import './AuthHeader.scss'

const AuthHeader = ({
  variant = 'login', // variant can be: login or signup 
  onClickCta = () => { console.log("you clicked the cta") },
  onClickIconHeader = () => { console.log('Dnnae logo clicked') },
  removeCTA = false
}) => {
  const spanText = variant === 'login' ? `Don't have an account?` : variant === 'signup' ? 'Already a user?' : ''
  const ctaText = variant === 'login' ? 'Sign Up' : 'Log In'
  return (
    <div className='auth_header_root'>
      <div onClick={onClickIconHeader} >
        <img src={qluIcon} alt='N/A' />
        {/* <img src={dnnae} alt='N/A' /> */}
      </div>
      <div>
        <span>{removeCTA ? "" : spanText}</span>
        {['login', 'signup'].includes(variant) && !removeCTA &&
          <SecondaryButton onClick={onClickCta}>{ctaText}</SecondaryButton>
        }
      </div>
    </div>
  )
}

export default AuthHeader