import React from 'react'
import './NotificationItem.scss'
import moment from 'moment'
import LogoSvg from '../svgComponents/LogoSvg'

const NotificationItem = ({
  notification = {},
  handleClickOnView
}) => {

  const getInitials = (name) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

      let initials = [...name.matchAll(rgx)] || [];

      return initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();
  }

  return (
    <div className='notification-item' onClick={() => handleClickOnView(notification)}>
      <div className='notif-avt'>{notification?.name ? getInitials(notification?.name) : <LogoSvg width='28' height='28'/>}</div>
      <div className='notif-content'>
        <p>{notification.text}</p>
        <p>{notification.jobTitle} - {moment(notification.createdAt).fromNow()}</p>
      </div>
      {
        notification.is_navigatable ?
        <p className='notif-link'>View</p>
        : ''
      }
    </div>
  )
}

export default NotificationItem