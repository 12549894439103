import React from "react";

function HeartFillSVG({ className, onClick }) {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="#E63B7F"
      viewBox="0 0 18 17"
      className={className}
    >
      <path
        stroke="#E63B7F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.766 2.326a4.204 4.204 0 00-1.366-.981A3.957 3.957 0 0012.79 1c-.553 0-1.1.117-1.612.345-.51.227-.975.56-1.366.981L9 3.198l-.812-.872C7.398 1.477 6.328 1 5.211 1c-1.117 0-2.188.477-2.978 1.326-.79.849-1.233 2-1.233 3.2 0 1.201.444 2.352 1.233 3.201l.812.872L9 16l5.955-6.4.811-.873c.391-.42.702-.92.913-1.468a4.823 4.823 0 000-3.465 4.562 4.562 0 00-.913-1.468v0z"
      ></path>
    </svg>
  );
}

export default HeartFillSVG;
