import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import StepperContainer from "../../uiLibrary/StepperContainer";
import HomeSvg from "../../uiLibrary/SVGs/HomeSvg";
import BriefCaseSvg from "../../uiLibrary/SVGs/BriefCaseSvg/index";
import CalendarSvg from "../../uiLibrary/SVGs/CalSvg";
import SettingSvg from "../../uiLibrary/SVGs/SettingsSvg";
import MailSVG from "../../uiLibrary/SVGs/MailSvg";
import MessageSequenceSvg from "../../uiLibrary/SVGs/MessageSequenceSvg/index";
// import DnnaeIcon from "../../img/dnicon.svg";
import QluIcon from "../../img/qlu-icon.png";

import "./LandingPage.scss";
import { getInitials } from "../../utils";
import { Container } from "reactstrap";
import UserAvt from "../../components/UserAvt";
import AuthWrapper from "../../wrappers/authWrapper";
import { Mixpanel } from "../../utils/Mixpanel";

const Layout = ({ match, history, user, children, ...rest }) => {
  // {
  //   step: <CrossSvg />,
  //   devider: true,
  //   onClick: onClose

  // console.log('MATHC AND HISTORY ARE: ', { match, history, rest })
  const [selectedStep, setSelectedStep] = useState(1);

  // const handleClickStep = (stepId) => {
  //   console.log('SETTING STEP ID: ', stepId)
  //   setSelectedStep(stepId)
  // }

  useEffect(() => {
    console.log("PATHNAME IS: ", history.location.pathname);
  }, [history.location.pathname]);

  console.log("I AM RERENDERING", { match, history });

  const steps = [
    {
      id: 1,
      step: (
        <HomeSvg
          className={history.location.pathname == "/" ? "navitemactive" : ""}
        />
      ),
      devider: false,
      onClick: () => history.push("/"),
      selected: history.location.pathname == "/",
    },
    {
      id: 2,
      step: (
        <BriefCaseSvg
          className={
            history.location.pathname == "/jobs" ? "navitemactive" : ""
          }
        />
      ),
      devider: false,
      onClick: () => history.push("/jobs"),
      selected: history.location.pathname == "/jobs",
    },
    {
      id: 3,
      step: (
        <MessageSequenceSvg
          className={
            history.location.pathname === "/campaigns" ? "navitemactive" : ""
          }
        />
      ),
      devider: false,
      onClick: () => {
        Mixpanel.track("Campaigns")
        history.push("/campaigns")
      },
      selected: history.location.pathname === "/campaigns",
    },
    // {
    //   id: 4,
    //   step: <MailSVG className={selectedStep === 4 ? 'navitemactive' : ''} />,
    //   devider: false,
    //   onClick: () => history.push('/messaging'),
    //   selected: history.location.pathname === '/messaging'
    // },
    // {
    //   id: 5,
    //   step: (
    //     <CalendarSvg className={history.location.pathname === "/calendar" ? "navitemactive" : ""} />
    //   ),
    //   devider: false,
    //   onClick: () => history.push("/calendar"),
    //   selected: history.location.pathname === "/calendar",
    // },
    {
      id: 6,
      step: (
        <SettingSvg className={history.location.pathname === "/settings" ? "navitemactive" : ""} />
      ),
      devider: false,
      onClick: () => history.push("/settings"),
      selected: history.location.pathname === "/settings",
    },
  ];

  console.log("STEPS ARE: ", steps);

  return (
    <div className="landing-root">
      <div className="leftnav">
        <div className="navicon">
          <img
            onClick={() => {
              history.push("/");
            }}
            src={QluIcon}
            alt="N/A"
          />
        </div>
        {/* <h1>hello world</h1> */}
        <StepperContainer
          className="stepperOverride"
          showCloseIcon={false}
          steps={steps}
        />
        {/* <UserAvt style={{position: "absolute",
          bottom: "12px",
          left: "25%",
          marginLeft:'0px',
          width:"32px",
          height:'32px'
          }}/> */}
          {/* } */}
      </div>
      <Container>
        <div className="landingcontent">{children}</div>
      </Container>
    </div>
  );
};

export default connect((state) => ({
  user: state.auth.user,
}))(withRouter(Layout));
