import React from 'react'
import './style.scss'

const PrimaryInput = ({
    label = '',
    value = '',
    onChange = e => false,
    placeholder,
    type = 'input',
}) => {
    return (
        <div
            className={'consentInputContainer'}
        >
            {label !== '' &&
                <p>
                    {label}
                </p>
            }
            {['input', '', null].includes(type) && <input
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />}
            {type === 'textarea' && <textarea
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />}
        </div>
    )
}

export default PrimaryInput
