import InfoSvg from "../../uiLibrary/SVGs/InfoSvg"

const UserBlockedView = ()=>{
    return (
        <>
            <div className='authElemContainer verifyEmailRoot'>
                <InfoSvg Recommended={true} width='35' height="35"></InfoSvg>
                <h5>
                   Access Restricted
                </h5>
                <p>
                   You are not allowed to access Qlu for now. Do not worry, our customer success team has been notified and they will get back to you within <b>24 hours</b>if you are eligible to access Qlu.
                </p>
            </div>
        </>
    )
}
export default UserBlockedView
