import React from "react";
import TableHeader from "./TableHeader";
import "./styles.scss";
import moment from "moment";
import SecondaryButton from "../../../uiLibrary/SecondaryButton";
import BigRoundDropdown from "../../../uiLibrary/BigRoundDropdown";
import { manualApiCall } from "../../../utils";
import AlertModal from "../../../components/AlertModal";
import OptionsDropdown from "../../../uiLibrary/OptionsDropdown";

export default function Table({ columns = [], rows, history, refreshData }) {
  const { campaigns = [] } = rows;

  const [selectedCampaign, setSelectedCampaign] = React.useState();
  const [
    isToggleCampaignAlertOpen,
    setIsToggleCampaignAlertOpen,
  ] = React.useState(false);

  const getStatusValue = (rounds, roundsSent, stopped) => {
    if (!!stopped) {
      return "Stopped";
    }

    if (rounds === roundsSent) {
      return "All rounds sent";
    }
    
    if(roundsSent>0){
      return `${roundsSent}${nth(roundsSent)} sent`;
    }

    return '0 rounds sent'
    
  };

  const getCurrentStatus = (rounds, roundsSent, stopped) => {
    if (!!stopped) {
      return "stopped";
    }

    if (rounds === roundsSent) {
      return "complete";
    }

    return "active";
  };

  const nth = (num) => {
    if (num > 3 && num < 21) return "th";
    switch (num % 10) {
      case 1:
        return "st round";
      case 2:
        return "nd rounds";
      case 3:
        return "rd rounds";
      default:
        return "th rounds";
    }
  };

  const canStopCampaign = (rounds, roundsSent) => {
    if (rounds === roundsSent) {
      return false;
    }

    return true;
  };

  const handleToggleCampaign = async () => {
    setIsToggleCampaignAlertOpen(false);
    try {
      await manualApiCall(`/api/v2/auth/campaigns/updateCampaigns`, {
        method: "POST",
        body: JSON.stringify({
          campaigns_id: selectedCampaign.id,
          is_stopped: !selectedCampaign.stopped,
        }),
      });
      setSelectedCampaign({});
      refreshData();
    } catch (error) {
      console.log("error", error);
    }
  };

  const onClose = () => {
    setIsToggleCampaignAlertOpen(false);
    setSelectedCampaign({});
  };

  return (
    <div>
      {isToggleCampaignAlertOpen && (
        <AlertModal
          toggleSecondary={handleToggleCampaign}
          togglePrimary={onClose}
          secondaryBtnValue={`Yes, ${
            selectedCampaign?.stopped ? "resume" : "stop"
          }`}
          primaryBtnValue={`No, do not ${
            selectedCampaign?.stopped ? "resume" : "stop"
          }`}
          modalBodyData={`All messages scheduled to be sent will be ${
            selectedCampaign?.stopped ? "resumed" : "stopped"
          }.`}
          modalHeaderTAg="Are you sure?"
          modalOpened={isToggleCampaignAlertOpen}
        />
      )}
      <TableHeader data={rows} />
      <table className="table">
        <thead>
          <tr>
            {columns.map((column, i) => (
              <th key={i}>{column.toUpperCase()}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {campaigns.map(
            (
              {
                id,
                sent,
                rounds,
                replied,
                stopped,
                roundsSent,
                published_at,
                interested,
              },
              key
            ) => (
              <tr key={key}>
                <td>{moment(published_at).format("hh:mm a, MMM DD, YY")}</td>
                <td>{rounds}</td>
                <td>{sent}</td>
                <td>
                  <span
                    className={`status-${getCurrentStatus(
                      rounds,
                      roundsSent,
                      stopped
                    )}`}
                  >
                    {getStatusValue(rounds, roundsSent, stopped)}
                  </span>
                </td>
                <td>{replied}</td>
                <td>{interested}</td>
                <td>
                  <SecondaryButton
                    style={{
                      width: 58,
                      justifyContent: "center",
                      height: 35,
                      paddingBottom: 10,
                    }}
                    onClick={() => {
                      history.push(`view-campaign/${id}`, {
                        campaign: campaigns[key],
                      });
                    }}
                  >
                    View
                  </SecondaryButton>
                </td>
                <td style={{ width: 100 }}>
                  {canStopCampaign(rounds, roundsSent) && (
                    <OptionsDropdown isOptionsMenu menuWidth="50px">
                      {
                        <p
                          onClick={() => {
                            setSelectedCampaign(campaigns[key]);
                            setIsToggleCampaignAlertOpen(true);
                          }}
                          style={{
                            textAlign: "center",
                            color: stopped ? "#FF8D4E" : "#EF5555",
                            cursor: "pointer",
                            fontSize: 14,
                          }}
                        >
                          {!!stopped ? "Resume Campaign" : "Stop Campaign"}
                        </p>
                      }
                    </OptionsDropdown>
                  )}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}
