/* global chrome */
import "@fontsource/inter";
import "@fontsource/roboto";
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import App from './App';
import './index.scss';

import CacheBuster from 'react-cache-buster';
import { Provider } from 'react-redux';
import { version } from '../package.json';
import store from './store';
import Loading from './uiLibrary/Loading';

if (process.env.REACT_APP_ENV === 'production') {
  console.log = function () { }
}

const isProduction = process.env.NODE_ENV !== 'development';
ReactDOM.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={isProduction}
    reloadOnDowngrade={true}
    isVerboseMode={true}
    loadingComponent={<Loading />}
  >
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </CacheBuster>,
  document.getElementById('root')
);