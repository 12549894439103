import React, { forwardRef, useEffect, useRef, useState } from 'react'
import TextField from '../TextField'
import qluLogo from "../../assets/qlulogo.svg"
import qluPlaceHolder from "../../assets/qluplaceholder.svg"
import userPlaceHolder from "../../assets/user.svg"
import typing from "../../assets/typing.json"


import "./style.scss"
import { Player } from '@lottiefiles/react-lottie-player'

function Home({ onSubmit, chatLogs, isLoading }) {

    const [images, setImages] = useState([userPlaceHolder, qluPlaceHolder])
    const bottomRef = useRef()

    const FunctionalArticle = forwardRef(({ ind, log }, ref) => (
        <div ref={ref} className={`chatrow ${!log?.q && 'gptResponse'} ${log?.value == '' && 'chatrowAnim'}`} key={ind} >
            <img src={log?.q ? images[0] : images[1]} className="imgPlaceholder" />
            <p>{log?.value}</p>
            {log?.value == '' && <div className='chatrow' style={{ width: "10px" }}>
                <Player
                    loop={true}
                    autoplay={true}
                    controls={false}
                    src={typing}
                    style={{ height: '45px', width: '70px' }}
                ></Player>
            </div>
            }
        </div>
    ))

    useEffect(() => {
        setTimeout(() => {
            bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 1000)
    }, [chatLogs])


    return (
        <div className='Home'>
            <div className='Header'>
                <img src={qluLogo} />
            </div>

            <div className='chatLogs'>
                {
                    chatLogs?.map((log, ind) => {
                        return <FunctionalArticle key={ind} ind={ind} log={log} />
                    })

                }
                <div ref={bottomRef} />
            </div>


            <div className='Footer'>
                <TextField onSubmit={onSubmit} isLoading={isLoading} />
            </div>
        </div>
    )
}

export default Home