import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toggleAdvFiters } from "../../../actions/prospectsView";
import AdvanceFilterSection from "./AdvanceFilterSection";

const FilterController = ({
  Show,
  prospects,
  FilterCount,
  setFilterCount,
  dispatch,
}) => {
  const numberOfProspects = prospects?.length || 0;

  const setShow = () => {
    dispatch(toggleAdvFiters());
  };
  useEffect(() => {
    if (Show) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [Show]);

  const titleFlag = prospects?.[0]?.buck_score;
  const skillFlag =
    prospects?.[0]?.scoring?.skill_total > 0 || numberOfProspects === 0;
  const industryFlag =
    prospects?.[0]?.scoring?.industry_total > 0 || numberOfProspects === 0;
  const experienceFlag =
    prospects?.[0]?.scoring?.experience_total > 0 || numberOfProspects === 0;
  const educationFlag =
    prospects?.[0]?.scoring?.education_total > 0 || numberOfProspects === 0;
  const specialtyFlag = 
    prospects?.[0]?.scoring?.specialty_total > 0 || numberOfProspects === 0;

  return (
    <AdvanceFilterSection
      Show={Show}
      setShow={setShow}
      FilterCount={FilterCount}
      setFilterCount={setFilterCount}
      titleFlag={titleFlag}
      skillFlag={skillFlag}
      specialtyFlag={specialtyFlag}
      industryFlag={industryFlag}
      experienceFlag={experienceFlag}
      educationFlag={educationFlag}
    />
  );
};

export default withRouter(
  connect((state) => ({
    Show: state.prospectsView.advFiltersMeta.isOpen,
    prospects: state.prospectsView.candidates.prospects,
  }))(FilterController)
);
