import { manualApiCall } from "../utils"

export const FETCH_JOBS = 'FETCH_JOBS'
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS'
export const FETCH_JOBS_FAILURE = 'FETCH_JOBS_FAILURE'

export const fetchJobs = (searchVal = '') => async (dispatch) => {
  try {
    dispatch({ type: FETCH_JOBS })
    const jobs = await manualApiCall(`/api/v2/auth/jobs?p=1&s=${searchVal}`, { method: 'GET' })
    dispatch({
      type: FETCH_JOBS_SUCCESS,
      payload: jobs
    })
  } catch (e) {
    dispatch({
      type: FETCH_JOBS_FAILURE,
      error: e.message
    })
  }
}