
  const tags = [
    {
      name: "First Name",
      value: "{{.First_Name}}",
    },
    {
      name: "Last Name",
      value: "{{.Last_Name}}",
    },
    {
      name: "Full Name",
      value: "{{.Full_Name}}",
    },
    {
      name: "Current Company",
      value: "{{.Current_Company}}",
    },
    {
      name: "Current Title",
      value: "{{.Current_Title}}",
    },
    {
      name: "Job Title",
      value: "{{.Job_Title}}",
    },
    {
      name: "My Name",
      value: "{{.My_Name}}",
    },
    {
      name: "My First Name",
      value: "{{.My_First_Name}}",
    },
    {
      name: "My Full Name",
      value: "{{.My_Full_Name}}",
    },
    {
      name: "User Name",
      value: "{{.User_Name}}",
    },
    {
      name: "School Name",
      value: "{{.School_Name}}",
    },
  ];
  
  export default tags;
  