import React from "react";
import "../style.scss";

const CompanySvg = ({
    color = "#CDD1D7",
    width = "16",
    height = "20",
    style = {},
    stroke = 1.5,
    className = "",
    fill = "none",
    selected = false,
    onClick,
}) => {
    return (
        <div onClick={onClick}>

            <svg width={width}
                height={height}
                className={`${className} ${selected ? "" : ""}`}
                viewBox="0 0 24 24"
                fill={fill}
                xmlns="http://www.w3.org/2000/svg"
                style={style}>
                <path d="M6 5C6 3.89543 6.89543 3 8 3H16C17.1046 3 18 3.89543 18 5V20H6V5Z" className={`qluSvgStroke${selected ? "Selected" : ""}`} stroke={color}
                    strokeWidth={stroke} />
                <path d="M10 17C10 15.8954 10.8954 15 12 15C13.1046 15 14 15.8954 14 17V20H10V17Z" className={`qluSvgStroke${selected ? "Selected" : ""}`} stroke={color}
                    strokeWidth={stroke} />
                <line x1="4" y1="20" x2="20" y2="20" stroke={color}
                    strokeWidth={stroke} className={`qluSvgStroke${selected ? "Selected" : ""}`} stroke-linecap="round" />
                <line x1="10" y1="11" x2="14" y2="11" stroke={color}
                    strokeWidth={stroke} className={`qluSvgStroke${selected ? "Selected" : ""}`} stroke-linecap="round" />
                <line x1="10" y1="7" x2="14" y2="7" stroke={color}
                    strokeWidth={stroke} className={`qluSvgStroke${selected ? "Selected" : ""}`} stroke-linecap="round" />
            </svg>
        </div>
    );
};

export default CompanySvg;
