import * as ActionTypes from '../../actions/QuickSetup'
import viewController from './viewController'

const initialState = {
  profileUrl: '',
  profileName: '',
  profileSuggestions: [],
  profiles:[],
  removePublicIdentifier: '',
  likedAttributes: {
    experienceSpans: [],
    titles: [],
    companies:[],
    skills: [],
    specialities: [],
    educations: []
  },
  suggestionsLoaded: false,
  suggestionsLoading: false,
  isLoaded: false,
  isLoading: false,
  viewController:{
    currentTab: 0
  }
}


const jobIdealProfile = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_JOB_IDEAL_PROFILE: {
      return initialState
    }
    case ActionTypes.TOOGLE_lIKE_ATTRIBUTES: {
      return {
        ...state,
        likedAttributes: action.payload
      }
    }
    case ActionTypes.CHANGE_JOB_PROFILE_URL: {
        return {
          ...state,
          profileUrl: action.payload
        }
    }
    case ActionTypes.SET_IDEAL_PROFILE_VIEW_CONTROLLER: {
        return {
            ...state,
            viewController: action.payload
        }
    }
    case ActionTypes.CHANGE_JOB_PROFILE_NAME: {
        return {
            ...state,
            profileName: action.payload
        }
    }
    case ActionTypes.FETCH_IDEAL_PROFILE_SUGGESTION: {
      return {
          ...state,
          suggestionsLoading: true,
          suggestionsLoaded: false
      }
    }
    case ActionTypes.FETCH_IDEAL_PROFILE_SUGGESTION_FAILURE: {
      return {
          ...state,
          suggestionsLoading: false,
          suggestionsLoaded: false
      }
    }
    case ActionTypes.FETCH_IDEAL_PROFILE_SUGGESTION_SUCCESS: {
      return {
        ...state,
        suggestionsLoaded: true,
        suggestionsLoading: false,
        profileSuggestions: action.payload
      }
    }
    case ActionTypes.CLEAR_IDEAL_PROFILE_SUGGESTIONS: {
      return {
        ...state,
        profileSuggestions: []
      }
    }

    case ActionTypes.FETCH_IDEAL_PROFILE_DATA: {
        return {
            ...state,
            isLoading: true,
            isLoaded: false
        }
    }
    case ActionTypes.FETCH_IDEAL_PROFILE_DATA_FAILURE: {
        return {
            ...state,
            isLoading: false,
            isLoaded: false
        }
    }
    case ActionTypes.FETCH_IDEAL_PROFILE_DATA_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        profileUrl: '',
        profiles: [
          ...state.profiles,
          action.payload
        ]
      }
    }
    case ActionTypes.SET_IDEAL_PROFILES:{

      return {
        ...state,
        profiles: action.payload
      }
    }
    case ActionTypes.REMOVE_SELECTED_IDEAL_PROFILE: {
      return {
        ...state,
        profiles: state.profiles.filter(item => item.publicIdentifier !== action.payload)
        
      }
    }

    // case ActionTypes.ADD_LIKED_ATTRIBUTES: {
    //   return {
    //     ...state,
    //     likedAttributes: [
    //       ...state.likedAttributes,
    //       action.payload
    //     ]
    //   }
    // }

    default:
      return state
  }
}

export default jobIdealProfile
