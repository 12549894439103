import { Button } from "reactstrap";
import EliminateIcon from "../../../components/svgComponents/EliminateIcon";
import TickSvg from "../../../components/svgComponents/TickSvg";

const AdvancedFilterPillButton = (props) => {
  const {
    keyId,
    title,
    activeState = 0,
    filterName = "",
    handleAdvancedFilterPillHighlight,
    handleAdvancedFilterPillEliminate,
  } = props;

  return (
    <div
      key={keyId}
      className={`${
        activeState === 2 ? "AdvancedFilterPillContainerEliminate " : ""
      }AdvancedFilterPillButtonContainer`}
    >
      <Button
        className={`${
          activeState === 1
            ? "AdvancedFilterPillHighlight "
            : activeState === 2
            ? "AdvancedFilterPillEliminate "
            : ""
        }AdvancedFilterPillButtonText`}
        outline
        color="primary"
        onClick={(e) =>
          handleAdvancedFilterPillHighlight(activeState, filterName)
        }
      >
        {filterName}
      </Button>
      {title !== 'Diversity' && 
        <Button
          className={`${
            activeState === 1
              ? "AdvancedFilterEliminateInactive "
              : activeState === 2
              ? "AdvancedFilterEliminateActive "
              : ""
          }AdvancedFilterPillButtonEliminate`}
          outline
          color="danger"
          onClick={(e) =>
            handleAdvancedFilterPillEliminate(activeState, filterName)
          }
        >
          {activeState === 2 ? (
            <EliminateIcon color="white" />
          ) : (
            <EliminateIcon />
          )}
        </Button>
      }
      <Button
        className={`${
          activeState === 1
            ? "AdvancedFilterPillHighlight "
            : activeState === 2
            ? "AdvancedFilterPillEliminate "
            : ""
        }AdvancedFilterPillButtonHighlight`}
        outline
        color="primary"
        onClick={(e) =>
          handleAdvancedFilterPillHighlight(activeState, filterName)
        }
      >
        {activeState === 1 ? <TickSvg /> : <TickSvg color="#A7ABB0" />}
      </Button>
    </div>
  );
};
export default AdvancedFilterPillButton;
