import React from "react";
import { Editor, EditorState } from "draft-js";
import SecondaryButton from "../../../uiLibrary/SecondaryButton";
import "./style.scss";

export default function Chat({ fullScreen = false, setIsPrimaryScreen }) {
  const [editorState, updateEditorState] = React.useState(
    EditorState.createEmpty()
  );
  return (
    <div className="messagePanel">
      <p>
        Send us a message and we’ll get back to you via email as soon as
        possible.
      </p>
      <div
        className={
          fullScreen ? "messageContainers" : "messageContainerSecondary"
        }
      >
        <Editor
          editorState={editorState}
          onChange={() => {}}
          readOnly
          placeholder="Enter your query..."
        />
      </div>
      <SecondaryButton
        style={{ marginTop: 20 }}
        onClick={() => setIsPrimaryScreen(true)}
      >
        Send Message
      </SecondaryButton>
    </div>
  );
}
