import React from 'react'
import LabelButton from '../LabelButton'
import CaretLeft from '../SVGs/CaretLeft'
import CrossSvg from '../SVGs/CrossSvg'
import './style.scss'
const CaretStepper = ({
    steps=[],
    currentStep='',
    onClose=e=>false
}) => {


    return (
        <div
            className='CaretStepperContainer'
        >
            {steps.map((step, index)=>(
                <React.Fragment>
                    <LabelButton
                        className={currentStep===step? 'CaretStep' : ''}
                        // disabled={currentStep===step}
                        // onClick={onClick}
                    >
                        {step}
                    </LabelButton>
                    {index<steps.length-1 &&
                        <CaretLeft
                            style={{
                                transform: 'rotate(180deg)'
                            }}
                        />
                    }
                </React.Fragment>
            ))}
        </div>
    )
}

export default CaretStepper
